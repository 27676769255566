var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Бонусы корпусов")]),_vm._v(" "),_c('p',[_vm._v("Некоторые корпуса могут иметь дополнительные бонусы если заработаны необходимые навыки.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('skills_3', 'png')+ ')',
          width: '350px',
          height: '572px',
          marginLeft: 'calc(50% - 175px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Chassis Bonuses")]),_vm._v(" "),_c('p',[_vm._v("Some chassis may have additional bonuses if the necessary skills are earned.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('skills_3', 'png')+ ')',
          width: '350px',
          height: '572px',
          marginLeft: 'calc(50% - 175px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }