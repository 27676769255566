var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Чат")]),_vm._v(" "),_c('p',[_vm._v("Чат позволяет обмениваться сообщениями между игроками, в чате всегда есть локальный канал соответствующей\n      текущему\n      сектору. Блок слева показывает всех игроков подписанных на этот канал, если нажать на игрока то\n      будут доступны различные действия, например создать приватный канал, посмотреть подробную информацию или\n      пригласить группу.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_1', 'png')+ ')',
          width: '301px',
          height: '237px',
           marginLeft: 'calc(50% - 150px)',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_2', 'png')+ ')',
          width: '331px',
          height: '237px',
         marginLeft: 'calc(50% - 165px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('p',[_vm._v("Некоторые каналы создаются автоматически например канал группы когда создается группа, канал кластера когда\n      игрок\n      состоит в кластере. Помимо основных каналов можно создавать свои каналы и ставить на них пароли.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_3', 'png')+ ')',
          width: '242px',
          height: '327px',
          marginLeft: 'calc(50% - 121px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Chat")]),_vm._v(" "),_c('p',[_vm._v("The chat allows players to exchange messages with each other, and there is always a local channel corresponding\n      to the current sector in the chat. The block on the left shows all players subscribed to this channel; clicking\n      on a player will provide various actions, such as creating a private channel, viewing detailed information, or\n      inviting to a group.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_1', 'png')+ ')',
          width: '301px',
          height: '237px',
           marginLeft: 'calc(50% - 150px)',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_2', 'png')+ ')',
          width: '331px',
          height: '237px',
         marginLeft: 'calc(50% - 165px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('p',[_vm._v("Some channels are created automatically, for example, a group channel when a group is created, or a cluster\n      channel when a player is part of a cluster. In addition to the main channels, you can create your own channels\n      and set passwords on them.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('chat_3', 'png')+ ')',
          width: '242px',
          height: '327px',
          marginLeft: 'calc(50% - 121px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }