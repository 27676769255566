//** т.к. нам не нужна реактивность вью для игры то у нас свой стор с блекджеком и филдами **//
const gameStore = {
  init: {
    items: false,
    interface: false,
    inventory: false,
  },
  time: {h: 11, m: 0, init: false},
  exitTab: false,
  gameSettings: {},
  noLoader: false,
  gameInit: false,
  radarWork: false,
  gameTypes: {},
  mapBinItems: {},
  unitSpriteSize: 256,
  gameDataInit: {
    data: false,
    sendRequest: false,
  },
  mapEditor: false,
  gameReady: false,
  unitReady: false,
  playerUnitID: 0,
  reload: false,
  mapDrawing: false,
  relationBonuses: null,
  HoldAttackMouse: false,
  map: null, //** тут лежит карта с глобальной коорджинатной позициией 0:0, говорит о том что игрок живет тут **//
  maps: null,
  mapsState: {},
  // графияеские уровне для рисования статики
  FogOfWar: {
    back: null,
    brush: null,
  },
  StatusLayer: {
    healBoxes: {},
    bars: {},
    barsCache: {},
    barsCacheSprites: {},
  },
  bmdTerrain: null,
  flore: null,
  cashTextures: {}, // движовский checkKey() работает через задницу, поэтому это тут

  // игровые обьекты
  objects: {},
  staticObjects: [],
  removeObjects: {}, // если нужно получить доступ к обьекту после удаления

  user_squad_id: null,
  playerID: 0,

  units: {},
  bullets: {},
  drones: {},
  radar_marks: {},
  boxes: {},
  ropes: {},
  mapItems: {},
  reservoirs: {},
  pollens: {},
  bases: {},
  shields: {},
  clouds: {},
  lights: [],
  cacheAnimate: {},
  missionHelpersCanvas: null,

  mouseInGameChecker: {
    updater: null,
    time: 0
  },
  /** что бы не создавать постоянно новые спрайты после смерти пули попадают сюда **/
  /** [bullet_sprite_name] []bullets **/
  cacheSpriteBullets: {},

  /** всякие методанные редактора карт **/
  mapEditorState: {
    mpID: 0,
    placeObj: null,
    placeTerrain: null,
    selectSprites: {},
    metaText: {},
    labels: {},
    radiusLvl: 50,
    typeCoordinateEdit: null,
    objSpriteNewGeoData: null,
  },
  spawns: {},
  violators: {},
  distressSignals: {},
  inputType: "wasd", // rts_single_unit, wasd
  HoldKeys: false,
  selectUnits: [],
  MouseMoveInterface: false,
  fireState: {
    target: {
      x: 0, y: 0,
    },
    firePosition: {
      x: 0, y: 0,
    }
  },
  unitState: {
    hp: 0,
    power: 0,
    update: false,
    body: {},
    weapon_slots: {},
    equip_slots: {},
  },
  equipPanel: {
    slots: {},
    update: false,
    select: 0,
    selectApplicable: '',
  },
  groupState: {
    state: {},
    members: [],
    leader: 0,
  },
  globalPath: {
    points: [],
    mapID: 0,
    baseID: 0,
  },
  missionPath: {
    actions: {},
  },
  oldTargetPos: {x: 0, y: 0},
  sectorWarState: {
    owner: "",
    phase: false,
    maxPoints: 0,
    fractionPoints: [],
    bases: [],
  },
  sectorSiegeState: {
    active: false,
    endTime: 0,
    hostiles: {},
    hp: 0,
    shield: 0,
  },
  corporationContacts: {},
  corporationLogoKeys: {},
  cutScene: {active: false},
  OtherFollowSprite: false,
};

export {gameStore}
