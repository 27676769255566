var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: выход с базы")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_1', 'png')+ ')',
          width: '450px',
          height: '141px',
          marginLeft: 'calc(50% - 225px)',
          })}),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Exiting the Base")]),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_1', 'png')+ ')',
          width: '450px',
          height: '141px',
          marginLeft: 'calc(50% - 225px)',
          })}),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы добраться до добывающей установки сначала надо покинуть текущую базу. Для этого надо нажать пункт меню\n      "),_c('span',{staticClass:"importantly"},[_vm._v("\"Выйти с базы\"")]),_vm._v(" а затем кнопку "),_c('span',{staticClass:"importantly"},[_vm._v("\"Покинуть базу\"")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.")]),_vm._v(" Управление транспортом осуществляется с помощью клавиш "),_c('span',{staticClass:"importantly"},[_vm._v("WASD")]),_vm._v(" (если вы играете за зеленых то еще и "),_c('span',{staticClass:"importantly"},[_vm._v("мышкой")]),_vm._v(")\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.2 Shift")]),_vm._v(" - ускорение.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.3 Space")]),_vm._v(" - тормоз.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To reach the mining installation, you must first leave the current base. To do this, press the menu item\n      "),_c('span',{staticClass:"importantly"},[_vm._v("\"Exit the Base\"")]),_vm._v(" and then the button "),_c('span',{staticClass:"importantly"},[_vm._v("\"Leave the Base\"")]),_vm._v(".\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.")]),_vm._v(" Vehicle control is carried out using the "),_c('span',{staticClass:"importantly"},[_vm._v("WASD")]),_vm._v(" keys (if you play for the greens, then also with the "),_c('span',{staticClass:"importantly"},[_vm._v("mouse")]),_vm._v(").\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.2 Shift")]),_vm._v(" - acceleration.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("P.S.3 Space")]),_vm._v(" - brake.")])
}]

export { render, staticRenderFns }