<template>
  <div class="UsersStatus" :id="windowID" :ref="windowID"
       @mousedown="toUp"
       v-if="users[player_id]">

    <app-control
      v-bind:head="headers.user + ': ' + users[player_id].login"
      v-bind:move="true"
      v-bind:close="true"
      v-bind:refWindow="windowID"
      v-bind:resizeFunc="resize"
      v-bind:minSize="{height: 128, width: 400}"
      v-bind:noHeight="true"
      v-bind:noWidth="true"/>

    <div class="usersStatusTabs">
      <div class="TabsLeftArrow" onclick="document.getElementById('usersStatusTabsGroup').scrollLeft -= 20;">&lt;</div>
      <div class="tabsWrapper">
        <div class="usersStatusTabsGroup">

          <div class="commonUserStat cursor_hover"
               v-bind:class="{actionChatTab : tab === 'common'}"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeTab('common')">
            {{ headers.common }}
          </div>
          <div
            v-if="player_id === currentPlayer.id"
            v-bind:class="{actionChatTab : tab === 'skill', select: selectSkill}"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="changeTab('skill')"
            class="skillUserStat cursor_hover">
            {{ headers.skills }}
          </div>
          <div
            v-if="player_id === currentPlayer.id"
            v-bind:class="{actionChatTab : tab === 'missions'}"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="changeTab('missions')"
            class="StatUserStat cursor_hover">
            {{ headers.missions }}
          </div>

          <div
            v-if="player_id === currentPlayer.id"
            v-bind:class="{actionChatTab : tab === 'relations'}"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="changeTab('relations')"
            class="StatUserStat cursor_hover">
            {{ headers.relations }}
          </div>
        </div>
      </div>
      <div class="TabsRightArrow" onclick="document.getElementById('usersStatusTabsGroup').scrollLeft += 20;">&gt;</div>
    </div>

    <app-my-user v-if="tab === 'common' && player_id === currentPlayer.id" v-bind:user="users[player_id]"/>
    <app-other-user v-if="tab === 'common' && player_id !== currentPlayer.id" v-bind:user="users[player_id]"/>
    <app-skills v-if="tab === 'skill'&& player_id === currentPlayer.id"/>
    <app-user-summary v-if="tab === 'summary'&& player_id === currentPlayer.id" v-bind:user="users[player_id]"/>
    <app-relations v-if="tab === 'relations'&& player_id === currentPlayer.id" v-bind:user="users[player_id]"/>
    <app-missions v-if="tab === 'missions'&& player_id === currentPlayer.id" v-bind:user="users[player_id]"/>
  </div>
</template>

<script>
import Control from '../Window/Control';
import MyUser from './MyUser';
import OtherUser from './OtherUser';
import Skills from './Skills';
import UserSummary from './UserSummary';
import Relations from './Relations';
import Missions from './Missions';

export default {
  name: "UserStat",
  props: ['player_id', 'windowID', 'openTab'],
  data() {
    return {
      tab: 'common',
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "OpenUserStat",
        ID: Number(this.$props.player_id),
      }
    }));

    if (this.$props.openTab) {
      this.tab = this.$props.openTab
    }

    this.resize(null, null, $(this.$el))
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    resize(event, ui, el) {
      try {
        el.find('.usersStatusWrapper').css("height", el.height() - 22);
        el.find('.UserSummaryWrapper').css("height", el.height() - 29);
        el.find('.userBiography').css("height", el.height() - 147);
        el.find('.skillContent').css("width", el.width() - 165);
        el.find('.skillContent').css("height", el.height() - 29);
        el.find('#HelpSections').css("height", el.height() - 17);
        el.find('.relations').css("height", el.height() - 23);
      } catch (e) {

      }
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)
      this.tab = tab;
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
  },
  computed: {
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    users() {
      return this.$store.getters.getUsers.users
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('UserStat')
    },
    headers() {
      return {
        common: this.texts['tab_1'][this.language],
        skills: this.texts['tab_2'][this.language],
        missions: this.texts['tab_3'][this.language],
        user: this.texts['tab_4'][this.language],
        summary: this.texts['tab_5'][this.language],
        history: this.texts['tab_6'][this.language],
        relations: this.texts['tab_7'][this.language],
      }
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectSkill() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "learn_skill";
    }
  },
  components: {
    AppControl: Control,
    AppMyUser: MyUser,
    AppOtherUser: OtherUser,
    AppSkills: Skills,
    AppUserSummary: UserSummary,
    AppRelations: Relations,
    AppMissions: Missions,
  }
}
</script>

<style scoped>
.actionChatTab {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.select {
  box-shadow: 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
}
</style>

<style>
.UsersStatus {
  top: 50px;
  left: calc(50% - 200px);
  min-height: 105px;
  width: 400px;
  position: absolute !important;
  border-radius: 5px;
  padding: 20px 3px 3px;
  z-index: 11;
  box-shadow: 0 0 2px black;
  height: 128px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.usersStatusTabs {
  position: relative;
  width: 100%;
}

.tabsWrapper {
  height: 15px;
  width: 100%;
}

.usersStatusTabsGroup {
  height: 15px;
  width: calc(100% - 35px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 14px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.usersStatusTabsGroup div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.usersStatusTabsGroup div:hover {
  color: #ffb60f;
  background: rgb(235, 98, 44);
}

.usersStatusTabsGroup div:active {
  transform: scale(0.98);
}

.TabsLeftArrow, .TabsRightArrow {
  height: 15px;
  width: 15px;
  position: absolute;
  color: #f9ff00;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  font-size: 15px;
  line-height: 15px;
  font-weight: 900;
  top: 0;
  left: 2px;
  opacity: 0.3;
}

.TabsRightArrow {
  right: 0;
  left: unset;
}

.TabsLeftArrow:hover, .TabsRightArrow:hover {
  color: #00ffdf;
  opacity: 1;
}

.usersStatusWrapper {
  width: calc(100% - 7px);
  background: rgba(137, 150, 156, 0.3);
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: calc(100% - 12px);
  padding: 7px 0 0 7px;
  font-size: 12px;
}

.userAvatarWrapper input {
  margin-top: 5px;
  margin-left: 19px;
}

.userAvatarWrapper input:active {
  transform: scale(0.95);
}
</style>
