var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Полиция")]),_vm._v(" "),_c('p',[_vm._v("Помимо стражей в безопасных секторах еще действует полиция. Их легко узнать по значку рядом с именем над\n      транспортом.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('police_1', 'png')+ ')',
          width: '378px',
          height: '147px',
          marginLeft: 'calc(50% - 189px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Цель полиции защищать мирные транспорты от агрессии со стороны пиратов/преступников/врагов и тд.")]),_vm._v(" "),_c('p',[_vm._v("Если страж видит что кто то атакует \"белого\"(не пвп и не пк) игрока/бота то он за него заступится и если это\n      возможно позовет подкрепление.")]),_vm._v(" "),_c('p',[_vm._v("Если полиция видит конфликт 2х \"фиолетовых\" то он не вмешается, поэтому если хотите помощи полиции старайтесь\n      оставаться \"белыми\". Однако если один из этих двух станет белым то полиция ему поможет.")]),_vm._v(" "),_c('p',[_vm._v("В безопасных секторах так же есть турели, они тоже выполняют функции полиции и если на вас напал пират\n      отступайте\n      к такой и вам будет оказана помощь.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('police_2', 'png')+ ')',
          width: '386px',
          height: '352px',
          marginLeft: 'calc(50% - 193px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Police")]),_vm._v(" "),_c('p',[_vm._v("In addition to the guards in the safe sectors, there is also the police. They can be easily identified by the\n      badge next to the name above the transport.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('police_1', 'png')+ ')',
      width: '378px',
      height: '147px',
      marginLeft: 'calc(50% - 189px)',
      })}),_vm._v(" "),_c('p',[_vm._v("The goal of the police is to protect peaceful transports from aggression by pirates/criminals/enemies, etc.")]),_vm._v(" "),_c('p',[_vm._v("If a guard sees someone attacking a \"white\" (non-PvP and non-PK) player/bot, they will intervene on their\n      behalf and, if possible, call for reinforcements.")]),_vm._v(" "),_c('p',[_vm._v("If the police see a conflict between two \"purples,\" they will not interfere, so if you want the help of the\n      police, try to stay \"white.\" However, if one of the two becomes white, the police will help them.")]),_vm._v(" "),_c('p',[_vm._v("In the safe sectors, there are also turrets that perform the functions of the police, and if a pirate attacks\n      you, retreat to such a turret, and you will be assisted.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('police_2', 'png')+ ')',
      width: '386px',
      height: '352px',
      marginLeft: 'calc(50% - 193px)',
      })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }