<template>
  <div class="out" :style="addStyle">
    <div class="mask"/>

    <div class="wrapper">
      <div>
        <div class="item_icon">
          <app-background-item-cell v-bind:slotItem="slotItem"/>
        </div>

        <div class="text_wrapper">
          <template v-if="load">

            <template v-if="price > 0">
              {{ texts['text_18'][language] }} <span class="importantly">{{ itemName }}</span>
              <span v-if="slotItem.quantity && slotItem.quantity > 1">
              x{{ slotItem.quantity }},
              </span>
              {{ texts['for'][language] }} <span class="credits">{{ (price / 100).toFixed(2) }}</span>?
            </template>

            <template v-else>
              {{ texts['text_19'][language] }}
            </template>

          </template>
          <app-preloader v-else/>
        </div>
      </div>

      <input type="button"
             :value="texts['button_27'][language]"
             class="button cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="close">
      <input type="button"
             :value="texts['button_28'][language]"
             class="button cursor_hover"
             :class="{disable: price === 0 || market.my_credits < price}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="repair" style=" float: right">
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from './BackgroundItemCell'
import Preloader from "../Preloader/Preloader";
import {urls} from "../../const";

export default {
  name: "RepairModal",
  props: ["slotItem", "source", "slotInv", "addStyle"],
  data() {
    return {
      price: -1,
      load: false,
    }
  },
  mounted() {
    let app = this;
    app.$api.get(urls.getRepairPrice + '?source=' + this.$props.source + '&slot=' + this.$props.slotInv + '&player_id=' + this.currentPlayer.id).then(function (response) {
      if (response.data.hasOwnProperty('price')) {
        app.price = response.data.price;
        app.load = true;
      }
    })
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    repair() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "Repair",
          source: this.$props.source,
          inventory_slot: this.$props.slotInv,
        }
      }));
      this.close();
    },
    close() {
      this.$parent.repairDialog.open = false
    }
  },
  computed: {
    itemName() {
      return this.handbook[this.$props.slotItem.type][this.language][this.$props.slotItem.item.name].name
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    market() {
      return this.$store.getters.getMarket
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
    AppPreloader: Preloader,
  }
}
</script>

<style scoped>
.out {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
}

.item_icon {
  height: 38px;
  width: 38px;
  float: left;
  margin: 9px 5px 0 1px;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
}

.wrapper {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 80px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}

.text_wrapper {
  height: 54px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 13px;
  font-weight: bold;
}
</style>
