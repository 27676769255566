import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {Explosion, SmokeExplosion} from "../weapon/explosion";

function PumpAnimate(x, y, r) {
  let pos = GetGlobalPos(x, y, gameStore.map.id);
  r = r - 180

  // todo PlayPositionSound(['capsule_flight'], null, pos.x, pos.y);
  Explosion(pos.x, pos.y, 1000, 3, 5, ["oil"], 10, r, r, 30, null, true, null)
}

export {PumpAnimate}
