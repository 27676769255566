import {CreateSelectSprite, destroyAllSelectedSprite} from "./create_select_sprite";
import {Scene} from "../create";
import {gameStore} from "../store";
import store from "../../store/store";
import {MapSize} from "../map/createMap";
import {GetGlobalPos} from "../map/gep_global_pos";

function MoveExitBase() {

  let callBack = function (x, y, r, id) {
    destroyAllSelectedSprite();

    let selectSprite = Scene.make.image({
      x: x,
      y: y,
      key: "baseResp",
      add: true
    });

    selectSprite.setOrigin(0.5);
    selectSprite.setDepth(1000);
    selectSprite.setScale(0.05);
    selectSprite.setInteractive();
    selectSprite.setAngle(r);
    gameStore.mapEditorState.placeObj = selectSprite;

    let updatePos = setInterval(function () {
      selectSprite.x = Math.round(Scene.input.mousePointer.worldX);
      selectSprite.y = Math.round(Scene.input.mousePointer.worldY);
    }, 10);

    selectSprite.on('pointerdown', function (pointer) {
      if (pointer.leftButtonDown()) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "map_editor",
          data: {
            event: "moveBaseExit",
            id: gameStore.mapEditorState.mpID,
            x: Number(x),
            y: Number(y),
            to_x: Number(selectSprite.x - MapSize),
            to_y: Number(selectSprite.y - MapSize),
            to_base_id: Number(id),
            rotate: Number(selectSprite.angle),
          }
        }));
      }

      selectSprite.destroy()
      clearInterval(updatePos)
      gameStore.mapEditorState.placeObj = null;
    });
  };

  SelectedBase(callBack)
}

function SelectedBase(callBack, type) {
  if (gameStore.gameReady) {
    for (let i in gameStore.bases) {
      for (let j in gameStore.bases[i].respawns) {
        let respPount = gameStore.bases[i].respawns[j];
        let pos = GetGlobalPos(respPount.x, respPount.y, gameStore.map.id);

        if (!respPount.resp_rotate) {
          respPount.resp_rotate = 0;
        }

        CreateSelectSprite(pos.x, pos.y, callBack, true, respPount.resp_rotate, gameStore.bases[i].id);
      }
    }
  }
}

export {MoveExitBase}
