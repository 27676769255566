<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Гранулы</h2>
        <p>Гранулы — это вид ресурсов, которые используются в химической промышленности для создания топлива и
          бустеров.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('pellet_1', 'png')+ ')',
          width: '400px',
          height: '107px',
          marginLeft: 'calc(50% - 200px)',
          }"/>

        <p>Гранулы можно добыть из некоторых растений и штормов с помощью специального устройства.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('pellet_2', 'gif')+ ')',
          width: '400px',
          height: '211px',
          marginLeft: 'calc(50% - 218px)',
          }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Pellets</h2>
        <p>Pellets are a type of resource used in the chemical industry for creating fuel and boosters.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('pellet_1', 'png')+ ')',
          width: '400px',
          height: '211px',
          marginLeft: 'calc(50% - 200px)',
          }"/>

        <p>Pellets can be extracted from certain plants and storms using a special device.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('pellet_2', 'gif')+ ')',
          width: '400px',
          height: '107px',
          marginLeft: 'calc(50% - 218px)',
          }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Pellet",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
