<template>
  <div>
    <div class="skillRow" v-for="requirement in requirements">
      <span>{{ handbook["skill"][language][requirement.name].name }}</span>

      <div :class="'imgPassRequirement' + String(checkReq(requirement))"/>

      <span class="level">{{ requirement.level }}</span>
    </div>
    <div class="button cursor_hover"
         @click="openService('userStat' + currentPlayer.id, {id: currentPlayer.id, tab: 'skill'}, 'userStat', false)">
      {{ texts['text_23'][language] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Requirements",
  props: ['requirements'],
  created() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetMySkills",
      }
    }));
  },
  methods: {
    checkReq(req) {
      return this.skillStore.skills.hasOwnProperty(req.name) && this.skillStore.skills[req.name].level >= req.level
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
    skillStore() {
      return this.$store.getters.getSkills
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
  },
}
</script>

<style scoped>
.skillRow {
  position: relative;
  float: left;
  width: calc(100% - 10px);
  height: 17px;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px 5px;
  margin-bottom: 3px;
  font-size: 11px;
  line-height: 20px;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.level {
  float: right;
  color: yellow;
}

.imgPassRequirementtrue, .imgPassRequirementfalse {
  height: 16px;
  width: 16px;
  float: right;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url('../../assets/icons/ready.png');
  background-size: contain;
  filter: drop-shadow(1px 1px 0px black);
  margin-left: 10px;
}

.imgPassRequirementfalse {
  background-image: url('../../assets/icons/dont.png');
}

.button {
  text-align: center;
  display: block;
  width: 79px;
  margin: 6px 0 0 6px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: right;
  font-size: 11px;
  pointer-events: auto;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}
</style>
