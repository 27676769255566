var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Установка оружия")]),_vm._v(" "),_c('p',[_vm._v("Оружие как и снаряжение устанавливается в свой слот. Что бы оружие стреляло ему нужны еще боеприпасы они\n      вставляются в небольшой слот под слотом оружия.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_weapon_1', 'gif')+ ')',
          width: '433px',
          height: '190px',
          marginLeft: 'calc(50% - 216px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Weapon Installation")]),_vm._v(" "),_c('p',[_vm._v("Weapons, like equipment, are installed in their own slot. For the weapon to fire, it also needs ammunition\n      which is inserted into a small slot below the weapon slot.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_weapon_1', 'gif')+ ')',
          width: '433px',
          height: '190px',
          marginLeft: 'calc(50% - 216px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }