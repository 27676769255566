<template>
  <div class="GroupMenu" id="GroupMenu" ref="GroupMenu" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'GroupMenu'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"/>

    <div class="group">
      <template v-if="Number(groupState.uuid) !== 0">
        <template v-for="member in groupState.members">

          <app-user-line v-bind:user="member"
                         :key="member.id"
                         v-bind:parent="'GroupMenu'"
                         v-bind:crown="groupState.leaderID === Number(member.id)"
                         v-bind:buttonExit="groupState.leaderID === Number(currentPlayer.id) || member.id === currentPlayer.id"
                         v-bind:noActive="!groupState.online[member.id]"
                         v-bind:meta="{id: member.id}"
                         v-bind:ready="null"
                         v-bind:exitFunc="leaveFromGroup"
          />
        </template>
      </template>

      <template v-if="Number(groupState.uuid) === 0">
        <h4 class="noGroup">
          {{ texts['text_1'][language] }}</h4>
      </template>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import UserLine from '../Chat/UserLine';

export default {
  name: "GroupMenu",
  data() {
    return {
      updater: null,
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater)
  },
  created() {
    let app = this;
    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetGroupState",
      }
    }));
  },
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    leaveFromGroup(id) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "LeaveGroup",
          id: Number(id),
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('GroupMenu')
    },
    groupState() {
      return this.$store.getters.getGroupState;
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
  },
  components: {
    AppControl: Control,
    AppUserLine: UserLine,
  }
}
</script>

<style scoped>
.GroupMenu {
  position: absolute;
  background-size: 100% 2px;
  border-radius: 5px;
  height: 100px;
  width: 200px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.group {
  width: 100%;
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: calc(100% - 0px);
  background: rgba(137, 150, 156, 0.3);
}

.noGroup {
  font-size: 10px;
  text-align: center;
}
</style>
