import {gameStore} from "../store";
import {Scene} from "../create";
import {hoursTint} from "../day_cycle/day_cycle";
import {addIgnoreObject} from "./mini_map";

function createBox(scene, width, height, boxKey, x, y, color, lineStyle = {
  width: 2,
  color: 0x000000,
  alpha: 1
}, fillStyle = {
  color: color,
  alpha: 1
}) {
  if (!gameStore.StatusLayer.healBoxes.hasOwnProperty(boxKey)) {
    let graphics = scene.add.graphics();
    graphics.setDefaultStyles({
      lineStyle: lineStyle,
      fillStyle: fillStyle,
    });

    graphics.fillRect(0, 0, width * 2, height * 2);
    graphics.strokeRect(0, 0, width * 2, height * 2);
    graphics.generateTexture(boxKey, width * 2, height * 2);
    graphics.destroy();

    gameStore.StatusLayer.healBoxes[boxKey] = true
  }
}

function ClearBars(type, id, typeBar, height = 4) {
  let oldBar = getBar(type, id, typeBar, height);
  if (oldBar) {
    oldBar.bar.setVisible(false);

    if (!gameStore.StatusLayer.barsCacheSprites[oldBar.key]) {
      gameStore.StatusLayer.barsCacheSprites[oldBar.key] = []
    }

    gameStore.StatusLayer.barsCacheSprites[oldBar.key].push(oldBar.bar)

    delete gameStore.StatusLayer.bars[type + id + typeBar + height];
  }
}

function cacheBars() {

  for (let j in gameStore.gameTypes.bodies) {
    for (let i = 50; i < gameStore.gameTypes.bodies[j].max_hp; i += 25) {
      let body = gameStore.gameTypes.bodies[j]
      let barState = CreateMapBar(null, body.max_hp, i, 10, null, Scene, 'unit', 0, 'hp', 50);
      if (body.max_shield_hp > 0) {
        CreateMapBar(null, body.max_shield_hp, i, 7, 0x89bcff, Scene, 'unit', 0, 'shield', Math.ceil(body.max_shield_hp / barState.countBoxes), null, 3);
      }
    }
  }

  for (let i = 0; i < 500; i += 25) {
    CreateMapBar(null, 500, i, 7, 0x0070ff, Scene, 'unit', 0, 'shield', 50);
    CreateMapBar(null, 500, i, 7, 0x0070ff, Scene, 'object', 0, 'shield', 50);
  }

  for (let i = 0; i < 250; i += 25) {
    CreateMapBar(null, 250, i, 7, 0x0070ff, Scene, 'unit', 0, 'shield', 50);
    CreateMapBar(null, 250, i, 7, 0x0070ff, Scene, 'object', 0, 'shield', 50);
  }

  for (let i = 0; i < 250; i += 25) {
    CreateMapBar(null, 500, i, 7, null, Scene, 'object', 0, 'hp', 50);
  }

  for (let i = 0; i < 5; i++) {
    CreateMapBar(null, 5, i, 10, null, Scene, 'drone', 0, 'hp', 1);
    CreateMapBar(null, 3, i, 10, null, Scene, 'drone', 0, 'hp', 1);
  }

  for (let i = 0; i < 5; i++) {
    CreateMapBar(null, 250, i * 50, 10, null, Scene, 'drone', 0, 'hp', 50);
  }

  ClearBars('unit', 0, 'hp')
  ClearBars('unit', 0, 'shield')
  ClearBars('unit', 0, 'shield', 3)
  ClearBars('object', 0, 'hp')
  ClearBars('object', 0, 'shield')
  ClearBars('drone', 0, 'hp')
}

function CreateUnitHP(unit) {
  let barState = CreateMapBar(unit.sprite, unit.body.max_hp, unit.hp, 10, null, Scene, 'unit', unit.id, 'hp', 50, unit.ghost);
  if (barState && unit.body.max_shield_hp > 0) {
    CreateMapBar(unit.sprite, unit.body.max_shield_hp, unit.shield_hp, 7, 0x89bcff, Scene, 'unit', unit.id, 'shield', Math.ceil(unit.body.max_shield_hp / barState.countBoxes), unit.ghost, 3);
  }
}

function PosUpdateUnitHP(unit) {
  let barState = UpdatePosBars(unit.sprite, unit.body.max_hp, unit.hp, 10, null, Scene, 'unit', unit.id, 'hp', 50, unit.ghost);
  if (barState && unit.body.max_shield_hp > 0) {
    UpdatePosBars(unit.sprite, unit.body.max_shield_hp, unit.shield_hp, 7, 0x89bcff, Scene, 'unit', unit.id, 'shield', Math.ceil(unit.body.max_shield_hp / barState.countBoxes), unit.ghost, 3);
  }
}

function CreateMapBar(sprite, maxHP, hp, offsetY, color, scene, type, id, typeBar, hpInBox, ghost, height = 4) {
  if (ghost) {
    ClearBars(type, id, typeBar, height);
    return;
  }

  let width = 4;
  let interval = 0; // промеж уток между квадратиками

  let countBoxes = Math.ceil(maxHP / hpInBox);
  // для особо жирных
  if (countBoxes > 10) {
    countBoxes = 10;
    hpInBox = Math.ceil(maxHP / countBoxes);
  }

  if (countBoxes < 1) {
    countBoxes = 1;
  }

  if (!sprite) {
    sprite = {displayHeight: 0, originY: 0, x: 0, y: 0}
  }
  let displayHeight = sprite.displayHeight;
  if (type === 'unit') {
    displayHeight = sprite.displayHeight;
  }

  let boxY = Math.round(offsetY + displayHeight * sprite.originY);
  let sizeBar = (width * countBoxes * 2) + (interval * countBoxes)
  let startX = 0;
  let percentHP = Math.round(100 / (maxHP / hp));

  let barKey = getBarKey(countBoxes, color, hp, percentHP, hpInBox, startX, width, height, interval)

  let oldBar = gameStore.StatusLayer.bars[type + id + typeBar + height];
  if (oldBar) {
    if (oldBar.key === barKey) {
      return oldBar
    }
    ClearBars(type, id, typeBar, height);
  }

  if (!gameStore.StatusLayer.barsCache[barKey]) {
    let bar = Scene.add.renderTexture(0, 0, sizeBar, height * 2);
    getBarKey(countBoxes, color, hp, percentHP, hpInBox, startX, width, height, interval, bar, scene, boxY)
    bar.saveTexture(barKey)
    bar.destroy();
    gameStore.StatusLayer.barsCache[barKey] = true
  }

  let barSprite
  if (gameStore.StatusLayer.barsCacheSprites[barKey] && gameStore.StatusLayer.barsCacheSprites[barKey].length > 0) {
    barSprite = gameStore.StatusLayer.barsCacheSprites[barKey].shift();
    barSprite.setPosition(sprite.x, sprite.y + boxY);
    barSprite.setVisible(true)
  } else {
    barSprite = Scene.make.image({
      x: sprite.x,
      y: sprite.y + boxY,
      key: barKey,
      add: true,
    });
    addIgnoreObject(barSprite);
  }

  gameStore.StatusLayer.bars[type + id + typeBar + height] = {
    bar: barSprite,
    key: barKey,
    countBoxes: countBoxes,
  }

  barSprite.setOrigin(0.5);
  barSprite.setScale(0.5 * getBarScale());
  barSprite.setDepth(900);

  return gameStore.StatusLayer.bars[type + id + typeBar + height]
}

function getBarKey(countBoxes, color, hp, percentHP, hpInBox, startX, width, height, interval, bar, scene, boxY) {

  let fillBox = 0;
  let notFillBox = 0;
  let fillColor = ""
  for (let i = 0; i < countBoxes; i++) {

    if (hp > 0) {
      fillBox++

      if (!color) {
        color = Phaser.Display.Color.HexStringToColor(GetColorDamage(percentHP).color).color;
      }

      fillColor = color
    } else {
      notFillBox++
      color = 0x999b9f;
    }

    if (bar) {
      let boxKey = 'box_' + color + "" + width + "" + height;
      createBox(scene, width, height, boxKey, startX, boxY, color)
      bar.drawFrame(boxKey, undefined, startX, 0);
    }

    hp -= hpInBox;
    startX += (width * 2) + interval
  }

  return fillColor + "" + fillBox + "" + notFillBox + "" + height
}

function UpdatePosBars(sprite, maxHP, hp, offsetY, color, scene, type, id, typeBar, hpInBox, ghost, height = 4) {
  if (ghost) {
    ClearBars(type, id, typeBar, height);
    return;
  }

  let bar = getBar(type, id, typeBar, height);
  if (bar) {

    if (!Scene.cameras.main.worldView.contains(sprite.x, sprite.y)) {
      if (bar.bar.visible) bar.bar.setVisible(false)
      return bar
    } else {
      if (!bar.bar.visible) bar.bar.setVisible(true)
    }

    bar.bar.setScale(0.5 * getBarScale())

    let displayHeight = sprite.displayHeight;
    let boxY = Math.round((offsetY * getBarScale()) + displayHeight * sprite.originY);
    bar.bar.setPosition(sprite.x, sprite.y + boxY);
    bar.bar.setAlpha(hoursTint[gameStore.time.h].s)
    return bar;
  } else {
    return CreateMapBar(sprite, maxHP, hp, offsetY, color, scene, type, id, typeBar, hpInBox, ghost, height);
  }
}

function getBar(type, id, typeBar, height = 4) {
  return gameStore.StatusLayer.bars[type + id + typeBar + height]
}

function createObjectBars(id) {
  let obj = gameStore.objects[id];
  if (obj && obj.hp > -2) {

    if ((obj.build && obj.complete >= 100) || !obj.build) {

      ClearBars('object', obj.id, 'build');

      if (obj.hp !== obj.max_hp) {
        if (obj.hp >= 0) {
          CreateMapBar(obj.objectSprite, obj.max_hp, obj.hp, 0, null, Scene, 'object', obj.id, 'hp', 50);
        } else {
          CreateMapBar(obj.objectSprite, 250, 250, 0, null, Scene, 'object', obj.id, 'hp', 50);
        }
      }

      if (obj.max_energy > 0) {
        CreateMapBar(obj.objectSprite, obj.max_energy / 100, obj.current_energy / 100, 7,
          0x00ffd6, Scene, 'object', obj.id, 'energy', 5);
      }

    } else if (obj.build && obj.complete < 100) {
      if (obj.max_hp > 0) CreateMapBar(obj.objectSprite, obj.max_hp, obj.hp, 0, null, Scene, 'object', obj.id, 'hp', 50);
      CreateMapBar(obj.objectSprite, 100, obj.complete, 7, 0xff00e1, Scene, 'object', obj.id, 'build', 5);
    }
  }
}

function GetColorDamage(percentHP) {
  if (percentHP >= 85) {
    return {key: 'g', color: "#00ff0f"}
  } else if (percentHP < 85 && percentHP >= 80) {
    return {key: 'u', color: "#fff326"}
  } else if (percentHP < 80 && percentHP >= 60) {
    return {key: 'y', color: "#fac227"}
  } else if (percentHP < 60 && percentHP >= 35) {
    return {key: 'o', color: "#fa7b31"}
  } else if (percentHP < 35) {
    return {key: 'r', color: "#ff2615"}
  }
}

function getBarScale() {
  let z = Scene.cameras.main.zoom

  if (z >= 1.9) z = 1
  else if (z < 1.9 && z >= 1.5) z = 1.52
  else if (z < 1.5 && z >= 1) z = 2
  else z = 3

  return z
}

export {
  CreateMapBar,
  ClearBars,
  createObjectBars,
  UpdatePosBars,
  cacheBars,
  createBox,
  GetColorDamage,
  CreateUnitHP,
  PosUpdateUnitHP,
  getBar,
  getBarScale
}
