<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Аномалии</h2>

      <p>Аномалии аномалии, это странной природы сигналы, в большинство случаев представляющий из себя спрятанные
        посреди
        пустошей планеты запечатанные ящики. Подобные клады хранят как весомую награду, так и могут быть засадой для
        незадачливых синтетов.</p>

      <p>Типы аномалий:</p>
      <ul>
        <li>Ящик с ресурсами и деталями</li>
        <li>Ящик с чертежами</li>
        <li>Ящик с залежами руды</li>
        <li>Ящик с патронами</li>
        <li>Ящик с артефактами</li>
        <li>Ящик с мусором</li>
        <li>Ловушка: бомба</li>
        <li>Ловушка: засада</li>
      </ul>

      <h3 class="head">Поиск аномалий</h3>

      <p>Поиск аномалий - дело непростое. Игрок, не желающий тратить собственное время ради случайного обнаружения
        аномалий, обязан будет обзавестись особым устройством поиска - Сканером. </p>
      <p>Сканер, обнаруживает испускаемые аномалиями сигналы, сигнализируя об этом на дисплее устройства. Интерфейс
        сканера представлен в виде кругового радара, изображающего расстояние, направление и, даже приблизительный тип
        аномалии. </p>
      <p>Едва вы окажитесь совсем близко к аномалии, как метка сканера точно укажет вам местоположение. </p>
      <p>Помимо поиска аномалий, сам сканер можно использовать и как устройство дальней разведки - улавливающее
        окружающие
        в большей дистанции руды, транспорты и прочее. </p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('scanner_1', 'gif')+ ')',
            width: '420px',
            height: '342px',
            marginLeft: 'calc(50% - 210px)',
            }"/>

      <h3 class="head">Открытие аномалии</h3>

      <p>Открытие найденной аномалии производится посредством специализированного снаряжения - дрона-копателя. </p>
      <p>Данное устройство ведёт раскопки на поверхности планеты, отыскивая испускающий сигнал предмет. </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('scanner_2', 'gif')+ ')',
            width: '420px',
            height: '342px',
            marginLeft: 'calc(50% - 210px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Anomalies</h2>

      <p>Anomalies are signals of a strange nature, which in most cases represent sealed boxes hidden amidst the
        wastelands of the planet. Such treasures can hold a significant reward, but they can also be a trap for unlucky
        synthetics.</p>

      <p>Types of anomalies:</p>
      <ul>
        <li>Box with resources and parts</li>
        <li>Box with blueprints</li>
        <li>Box with ore deposits</li>
        <li>Box with ammunition</li>
        <li>Box with artifacts</li>
        <li>Box with trash</li>
        <li>Trap: bomb</li>
        <li>Trap: ambush</li>
      </ul>

      <h3 class="head">Searching for Anomalies</h3>

      <p>Searching for anomalies is not an easy task. A player who does not wish to waste their own time for the random
        discovery of anomalies will have to acquire a special search device - a Scanner.</p>
      <p>The Scanner detects signals emitted by anomalies, signaling this on the device's display. The scanner interface
        is presented in the form of a circular radar, depicting the distance, direction, and even the approximate type
        of anomaly.</p>
      <p>As soon as you are very close to an anomaly, the scanner's marker will accurately indicate its location.</p>
      <p>In addition to searching for anomalies, the scanner itself can be used as a device for further reconnaissance -
        capturing surrounding ores, transports, and more at greater distances.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('scanner_1', 'gif')+ ')',
            width: '420px',
            height: '342px',
            marginLeft: 'calc(50% - 210px)',
            }"/>

      <h3 class="head">Anomaly Discovery</h3>

      <p>The discovery of a found anomaly is carried out using specialized equipment - a digger drone.</p>
      <p>This device conducts excavations on the planet's surface, searching for the object emitting the signal.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('scanner_2', 'gif')+ ')',
            width: '420px',
            height: '342px',
            marginLeft: 'calc(50% - 210px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Anomaly",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
