var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h3',{staticClass:"head"},[_vm._v("Задания")]),_vm._v(" "),_c('p',[_vm._v("Задания - одна из возможностей по развитию игрока, приобретению им денег, репутации и опыта. ")]),_vm._v(" "),_c('p',[_vm._v("Получить задание игрок может на любой базе, посредством меню базы в разделе - \"Связь\"->\"Терминал\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_3', 'png')+ ')',
          width: '226px',
          height: '85px',
          marginLeft: 'calc(50% - 113px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Сами задания представляют из себя набор диалогов и требований, которые игроку необходимо выполнить, прежде, чем\n      получить свою награду. При желании, более подробно о заданиях (активной части), игрок может узнать в меню -\n      \"Личное дело\" и вкладке \"Задания\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_4', 'png')+ ')',
      width: '480px',
      height: '507px',
      marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Чтобы отслеживать цели задания, игроку следует использовать меню миссий и пользоваться подсказками на\n      мини-карте,\n      если таковые имеются.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_2', 'png')+ ')',
          width: '459px',
          height: '225px',
          marginLeft: 'calc(50% - 230px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h3',{staticClass:"head"},[_vm._v("Missions")]),_vm._v(" "),_c('p',[_vm._v("Missions are one of the opportunities for player development, acquiring money, reputation, and experience.")]),_vm._v(" "),_c('p',[_vm._v("A player can receive a mission at any base, through the base menu in the section -\n      \"Communication\"->\"Terminal\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_3', 'png')+ ')',
    width: '226px',
    height: '85px',
    marginLeft: 'calc(50% - 113px)',
    })}),_vm._v(" "),_c('p',[_vm._v("The missions themselves consist of a set of dialogues and requirements that the player must fulfill before\n      receiving their reward. If desired, more details about the missions (active part) can be found in the menu -\n      \"Personal File\" and the \"Missions\" tab.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
  backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_4', 'png')+ ')',
  width: '480px',
  height: '507px',
  marginLeft: 'calc(50% - 240px)',
  })}),_vm._v(" "),_c('p',[_vm._v("To track mission objectives, the player should use the mission menu and use hints on the mini-map, if\n      available.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('mission_2', 'png')+ ')',
    width: '459px',
    height: '225px',
    marginLeft: 'calc(50% - 230px)',
    })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }