var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Штрафы")]),_vm._v(" "),_c('p',[_vm._v("Если вы используете снаряжение к которому нет допуска по скилам то вы получаете штрафы на его\n      использование:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('skills_2', 'png')+ ')',
          width: '335px',
          height: '335px',
          marginLeft: 'calc(50% - 167px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Penalties")]),_vm._v(" "),_c('p',[_vm._v("If you use equipment for which you do not have the necessary skills, you will receive penalties for its\n      use:")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('skills_2', 'png')+ ')',
          width: '335px',
          height: '335px',
          marginLeft: 'calc(50% - 167px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Корпуса - корпус едет медленнее, скорость не постоянная, повороты корпуса происходят рывками.")]),_vm._v(" "),_c('li',[_vm._v("Оружие - станонвится очень не точным, поворот оружия медленнее и происходит рывками.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Hulls - the hull moves slower, the speed is not constant, and the hull's turns occur in jerks.")]),_vm._v(" "),_c('li',[_vm._v("Weapons - become very inaccurate, the weapon's rotation is slower and occurs in jerks.")])])
}]

export { render, staticRenderFns }