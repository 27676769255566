var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Добыча руды")]),_vm._v(" "),_c('p',[_vm._v("В этой части задания надо прокатиться по карте в поиска источника руды. На мини карте и радаре источники руды\n      помечаются иконкой в виде ромбика. В данном задание нужен торий и такие залежи дополнительно будут помечаться на\n      мини карте зеленым крестом.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_mining_1', 'png')+ ')',
          width: '472px',
          height: '118px',
          marginLeft: 'calc(50% - 236px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Что начать добывать руду надо: ")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_mining_2', 'gif')+ ')',
          width: '405px',
          height: '393px',
          marginLeft: 'calc(50% - 202px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Ore Mining")]),_vm._v(" "),_c('p',[_vm._v("In this part of the task, you need to travel around the map in search of an ore source. On the mini-map and\n      radar, ore sources\n      are marked with a diamond icon. In this task, thorium is required and such deposits will additionally be marked\n      on the\n      mini-map with a green cross.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_mining_1', 'png')+ ')',
          width: '472px',
          height: '118px',
          marginLeft: 'calc(50% - 236px)',
          })}),_vm._v(" "),_c('p',[_vm._v("To start mining ore you need to: ")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_mining_2', 'gif')+ ')',
          width: '405px',
          height: '393px',
          marginLeft: 'calc(50% - 202px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("выбрать добывающий лазер на панели снаряжения")]),_vm._v(" "),_c('li',[_vm._v("навести курсов на руду и нажать лкм")]),_vm._v(" "),_c('li',[_vm._v("дождаться заполнения шкалы после чего руда упадет в трюм или отсек для руды если такой имеется")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("select the mining laser on the equipment panel")]),_vm._v(" "),_c('li',[_vm._v("aim the cursor at the ore and click LMB")]),_vm._v(" "),_c('li',[_vm._v("wait for the scale to fill up after which the ore will fall into the hold or the ore compartment if there is\n        one\n      ")])])
}]

export { render, staticRenderFns }