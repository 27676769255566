<template>
  <div class="tip" :style="{left: x + 'px', bottom:  'calc(100% - ' + (y-10) + 'px)'}"
       v-if="effects.skill.length > 0 || effects.bonus.length > 0 || effects.equip.length > 0 ||
       effects.fine.length > 0 || effects.weapon.length > 0 || effects.fuel.length > 0 || effects.sync.length > 0 ||
       effects.additional.length > 0">

    <template v-for="(v, k) in effects">
      <div v-if="v.length > 0">
        <table class="effects_table">
          <tr>
            <td>{{ getTitle(k) }}:</td>
            <td></td>
          </tr>
          <tr v-for="e in v" :class="{[getEffectString(e).cl]: true}">
            <td></td>
            <td>{{ getEffectString(e).str }}</td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "StateTip",
  props: ['title', 'param', 'x', 'y', 'wt', 'ss', 'bt'],
  methods: {
    getTitle(t) {
      if (t === 'skill') return this.texts['tittle_1'][this.language];
      if (t === 'bonus') return this.texts['tittle_2'][this.language];
      if (t === 'equip') return this.texts['tittle_3'][this.language];
      if (t === 'fine') return this.texts['tittle_4'][this.language];
      if (t === 'weapon') return this.texts['tittle_5'][this.language];
      if (t === 'fuel') return this.texts['tittle_6'][this.language];
      if (t === 'night') return this.texts['tittle_7'][this.language];
      if (t === 'sync') return this.texts['tittle_8'][this.language];
      if (t === 'additional') return this.texts['tittle_9'][this.language];
    },
    getEffectString(e) {

      let str = e.st ? "-" : "+";
      let cl = e.st ? "fine" : "bonus";

      if (this.$props.param === "reload") {
        if (str === "+") {
          str = "-";
        } else {
          str = "+";
        }
      }

      str += e.q
      if (e.pr) {
        str += "%"
      }

      if (this.$props.param === "reload" || this.$props.param === "reload_ammo" || this.$props.param === "accuracy") {
        if (cl === "fine") {
          cl = "bonus";
        } else {
          cl = "fine";
        }
      }

      return {str: str, cl: cl}
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    weapon() {
      if (this.inventory.unit && this.inventory.unit.weapon_slots) {
        for (let i in this.inventory.unit.weapon_slots) {
          let wSlot = this.inventory.unit.weapon_slots[i]
          if (wSlot.weapon) {
            return wSlot.weapon
          }
        }
      }

      return false
    },
    effects() {
      let allEffects = this.inventory.effects

      let parameterEffects = {
        skill: [],
        bonus: [],
        equip: [],
        fine: [],
        weapon: [],
        fuel: [],
        sync: [],
        additional: [],
      };

      for (let e of allEffects) {

        if (e.p !== this.$props.param) {
          continue
        }

        if (this.$props.wt !== "" && e.wt !== "" && this.$props.wt !== e.wt) {
          continue
        }

        if (this.$props.bt !== "" && e.bt !== "" && this.$props.bt !== e.bt) {
          continue
        }

        if (this.$props.ss > 0 && e.ss > 0 && this.$props.ss !== e.ss) {
          continue
        }

        if (e.u.includes('skill')) {
          parameterEffects.skill.push(e);
        }

        if (e.u.includes('body_bonus')) {
          parameterEffects.bonus.push(e);
        }

        if (e.u.includes('fine')) {
          parameterEffects.fine.push(e);
        }

        if (e.u.includes('equip')) {
          parameterEffects.equip.push(e);
        }

        if (e.u.includes("weapon_damage_modifier")) {
          parameterEffects.weapon.push(e);
        }

        if (e.u.includes("fuel_") || e.u.includes("fuelBonus_")) {
          parameterEffects.fuel.push(e);
        }

        if (e.u.includes("sync_")) {
          parameterEffects.sync.push(e);
        }

        if (e.u.includes("additional_slot_")) {
          parameterEffects.additional.push(e);
        }
      }

      for (let i in parameterEffects) {
        parameterEffects[i].sort((a, b) => b.q - a.q);
      }

      return parameterEffects
    }
  },
}
</script>

<style scoped>
.up {
  color: #abff00;
}

.fine {
  color: red;
}

.tip {
  display: block;
  position: absolute;
  min-height: 20px;
  min-width: 110px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 2px black;
  background-color: rgba(7, 41, 60, 0.8);
  color: white;
  z-index: 999;
  font-size: 12px;
  text-align: justify;
  margin: 5px 0;
  text-indent: 1.5em;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.tip span:first-child {
  float: left;
}

.tip span:last-child {
  float: right;
}

.tip div {
  clear: both;
}

.effects_table {
  width: 100%;
}

.fine {
  color: rgb(238, 108, 47);
  font-weight: bold;
  text-align: right;
}

.bonus {
  color: greenyellow;
  text-align: right;
}
</style>
