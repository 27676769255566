import {gameStore} from "../store";
import {SelectSprite} from "../utils/select_sprite";

let initCombineRemoveTarget = false;

function CombineTargets(data) {

  if (!initCombineRemoveTarget) {
    let closeTarget = setInterval(function () {
      if (initCombineRemoveTarget < 0) {
        clearInterval(closeTarget);
        RemoveAllSelectSpriteCombineTargets();
        initCombineRemoveTarget = false;
      } else {
        initCombineRemoveTarget -= 10
      }
    }, 10)
  }

  initCombineRemoveTarget = 100;

  for (let id in data) {
    if (!data.hasOwnProperty(id)) continue;

    let obj = gameStore.objects[id];
    if (!obj) continue;

    if (data[id]) {

      if (obj.noPassMiningSelectSprite) {
        obj.noPassMiningSelectSprite.setVisible(false);
      }

      if (obj.passMiningSelectSprite) {
        obj.passMiningSelectSprite.setVisible(true);
        continue
      }

      obj.passMiningSelectSprite = SelectSprite(obj.objectSprite.x, obj.objectSprite.y, null, 0xffffff, 0x00ff00, obj.objectSprite.displayHeight + 5);
    } else {

      if (obj.passMiningSelectSprite) {
        obj.passMiningSelectSprite.setVisible(false);
      }

      if (obj.noPassMiningSelectSprite) {
        obj.noPassMiningSelectSprite.setVisible(true);
        continue
      }

      obj.noPassMiningSelectSprite = SelectSprite(obj.objectSprite.x, obj.objectSprite.y, null, 0xff0000, 0xff0000, obj.objectSprite.displayHeight + 5);
    }
  }
}

function RemoveAllSelectSpriteCombineTargets() {
  for (let i in gameStore.objects) {
    if (!gameStore.objects.hasOwnProperty(i) || !gameStore.objects[i]) continue;
    RemoveSelectSpriteCombineTargets(gameStore.objects[i].objectSprite);
  }
}

function RemoveSelectSpriteCombineTargets(obj) {
  if (!obj) return;

  if (obj.noPassMiningSelectSprite) {
    obj.noPassMiningSelectSprite.setVisible(false);
  }

  if (obj.passMiningSelectSprite) {
    obj.passMiningSelectSprite.setVisible(false);
  }
}

export {CombineTargets, RemoveAllSelectSpriteCombineTargets, RemoveSelectSpriteCombineTargets}
