<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Типы строений</h2>
      <p>Сооружения служат разным целям от складов до турелей.</p>
      <ul>
        <li class="imageLi" style="">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('turrents', 'png')+ ')',
            width: '200px',
            height: '75px',
            float: 'left',
            marginRight: '10px',
            }"/>

          <p>Турели - атакуют врагов находящиеся в радиусе атаки, очень не любят кусты поэтому отстреливают их при
            возможности ¯\_(ツ)_/¯.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('shield', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Щиты - не пропускают снаряды внутрь, но зато выпускает их из под себя.</p>
          <p>Для работы необходимо питание.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('radar', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Радар - расширяет радар транспорта, показывает все что попадает в радиус радара.</p>
          <p>Для работы необходимо питание.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('jammer', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Генератор помех - делает в области помех бесполезным радар, но не только вражеский а вообще.</p>
          <p>Для работы необходимо питание.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('missile_defense', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>ПРО - сбивает вражеские ракеты на подлете.</p>
          <p>Для работы необходимо питание.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('storage', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Склад - как ящик, только склад ¯\_(ツ)_/¯.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('energy_generator', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Генератор - имеет расширеную топливную ячейку и способен самостоятельно питать все строения в радиусе
            действия.</p>

          <p>Для работы необходимо питание, которое он будет раздавать.</p>
        </li>

        <li class="imageLi">

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('extractor', 'png')+ ')',
            width: '75px',
            height: '75px',
            float: 'left',
            }"/>

          <p>Добывающее строение - строится на минерале и добывает ресурсы в автоматическом режиме, что бы строение
            работало ему ноеобходимо иметь ящик, транспорт или строение с инвентарем в точке сброса (красный/зеленый
            квадратик у здания).</p>

          <p>Для работы необходимо питание, которое он будет потреблять в каждом цикле добычи.</p>
        </li>
      </ul>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Types of Structures</h2>
      <p>Structures serve various purposes from warehouses to turrets.</p>
      <ul>
        <li class="imageLi" style="">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('turrents', 'png')+ ')',
      width: '200px',
      height: '75px',
      float: 'left',
      marginRight: '10px',
      }"/>
          <p>Turrets - attack enemies within the attack radius, they really don't like bushes so they shoot them
            whenever possible ¯\_(ツ)_/¯.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('shield', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Shields - do not let projectiles inside, but they do let them out from underneath.</p>
          <p>Power supply is required for operation.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('radar', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Radar - expands the vehicle's radar, shows everything that falls within the radar radius.</p>
          <p>Power supply is required for operation.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('jammer', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Jammer - makes the radar useless in the jamming area, not just the enemy's but any radar.</p>
          <p>Power supply is required for operation.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('missile_defense', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Missile Defense - intercepts enemy missiles on approach.</p>
          <p>Power supply is required for operation.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('storage', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Warehouse - like a box, only a warehouse ¯\_(ツ)_/¯.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('energy_generator', 'png')+ ')',
      width: '75px',
      height: '75px',
      float: 'left',
      }"/>
          <p>Generator - has an extended fuel cell and is capable of independently powering all structures within its
            range of action.</p>
          <p>Power supply is necessary for operation, which it will distribute.</p>
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('extractor', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          }"/>
          <p>Resource Extractor - built on a mineral and extracts resources in automatic mode, to operate it must have a
            box, transport, or a structure with inventory at the drop point (red/green square at the building).</p>
          <p>Power supply is required for operation, which it will consume in each extraction cycle.</p>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "StructureTypes",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
