<template>
  <div class="out" :style="addStyle">
    <div class="mask"/>

    <div class="wrapper">
      <div>
        <div class="item_icon">
          <app-background-item-cell v-bind:slotItem="{type: 'fuel', item:{name: 'gas_fuel'}}"/>
        </div>

        <div class="input_number_row">
          <div class="title_row">{{ texts['text_9'][language] }}</div>

          <div class="total">
            {{ fillUpState.price ? (fillUpState.price / 100).toFixed(2) : 0 }} cr.
          </div>
        </div>

        <div class="input_number_row">
          <div class="title_row">{{ texts['text_10'][language] }}</div>

          <div class="total">
            {{ fillUpState.price ? ((fillUpState.price * count) / 100).toFixed(2) : 0 }} cr.
          </div>
        </div>

        <div class="input_number_row" style="width: calc(100% - 10px); margin-left: 5px;">
          <div class="title_row">{{ texts['text_11'][language] }}</div>

          <div style="float: right; width: 50%">
            <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeCount(-1)">
              -1
            </div>
            <input type="number"
                   class="cursor_hover"
                   style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                   v-model="count" min="1" max="9999">
            <div class="range_button cursor_hover" style="float: right; margin-right: 5px;"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeCount(1)">+1
            </div>
          </div>
        </div>

        <input type="button"
               :value="texts['button_23'][language]"
               class="button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="close">
        <input type="button"
               :value="texts['button_24'][language]"
               class="button cursor_hover"
               :class="{disable: count === 0 || market.my_credits < fillUpState.price * count}"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="fill" style="right: 5px; left: unset;">
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from './BackgroundItemCell'

export default {
  name: "FillUpModal",
  props: ["addStyle"],
  data() {
    return {
      load: false,
      count: 0,
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "GetFillUpPriceAndCount",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    fill() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "BuyFillUp",
          count: Number(this.count),
        }
      }));

      this.close();
    },
    close() {
      this.$parent.fillDialog.open = false
    },
    changeCount(change) {
      this.count = this.count + change;
      if (this.count < 0) this.count = 0;
      if (this.count > this.fillUpState.need) this.count = this.fillUpState.need;
    }
  },
  computed: {
    fillUpState() {
      let s = this.$store.getters.getFillUpState
      this.count = s.need;
      return s
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    market() {
      return this.$store.getters.getMarket
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.out {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  pointer-events: auto;
}

.item_icon {
  height: 38px;
  width: 38px;
  float: left;
  margin: 9px 5px 0 1px;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
}

.wrapper {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 100px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  padding: 0 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  backdrop-filter: blur(4px);
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.title_row {
  float: left;
  font-size: 10px;
  color: bisque;
  margin-right: 5px;
  line-height: 15px;
  text-shadow: 1px 1px 1px black;
}

.input_number_row {
  float: left;
  width: calc(100% - 48px);
  margin-top: 7px;
}

.total {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  margin: 0;
  float: right;
  text-align: right;
  background-size: 5px 2px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  line-height: 15px;
  box-shadow: inset 0 0 1px 1px rgb(118 118 118);
  border: 0;
  font-size: 11px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

input[type=number] {
  float: right;
  margin-top: 10px;
  margin-right: 5px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  width: 27px;
  height: 14px;
  text-indent: 1px;
  text-align: right;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

</style>
