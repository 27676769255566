<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Ящики</h2>

      <p>На карте вы можете устанавливать ящики, которые служат для хранения предметов. Ящики имеют разную прочность,
        вместимость и тип. Вы можете выбрать ящик в зависимости от ваших целей и предпочтений. Вот три основных типа
        ящиков:</p>
      <ul>
        <li>
          <p>Обычный контейнер - это самый простой и дешевый ящик, который не имеет никаких особых свойств. Он может
            быть
            легко поврежден или украден другими синтетами. Но зато его можно толкать и перемещать по карте, если вам
            нужно
            изменить место хранения.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_1', 'gif')+ ')',
            width: '250px',
            height: '200px',
            marginLeft: 'calc(50% - 125px)',
            }"/>
        </li>
        <li>
          <p>Подземный ящик - более дорогая вариация “стандартного контейнера”, чья единственная особенность -
            возможность
            спрятать ящик под землю. Тем самым, скрыв его от визуального обнаружения. Обнаружить его можно с помощью
            "сканера аномалий" или владелец/группа. (не релиз.)</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_2', 'gif')+ ')',
            width: '400px',
            height: '200px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
        </li>
        <li>
          <p>Ящик защищенный паролем - это самый продвинутый и дорогой ящик, который имеет электронный замок. Он может
            быть открыт только игроками, которые знают пароль, который вы задаете при установке ящика. Он также имеет
            большую вместимость и прочность, чем другие ящики. Так же защищен от визуального обнаружения.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_3', 'gif')+ ')',
            width: '320px',
            height: '200px',
            marginLeft: 'calc(50% - 160px)',
            }"/>
        </li>
      </ul>

      <p>Если ящик был уничтожен то все его содержимое теряется.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Boxes</h2>

      <p>On the map, you can place boxes that serve to store items. Boxes have different durability, capacity, and type.
        You can choose a box depending on your goals and preferences. Here are the three main types of boxes:</p>
      <ul>
        <li>
          <p>A standard container is the simplest and cheapest box, which has no special properties. It can be easily
            damaged or stolen by other synthetics. But it can be pushed and moved around the map if you need to change
            the storage location.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_1', 'gif')+ ')',
            width: '250px',
            height: '200px',
            marginLeft: 'calc(50% - 125px)',
            }"/>
        </li>
        <li>
          <p>An underground box is a more expensive variation of the "standard container," whose only feature is the
            ability to hide the box underground. Thus, hiding it from visual detection. It can be detected using an
            "anomaly scanner" or by the owner/group. (not released.)</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_2', 'gif')+ ')',
            width: '400px',
            height: '200px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
        </li>
        <li>
          <p>A password-protected box is the most advanced and expensive box, which has an electronic lock. It can only
            be opened by players who know the password, which you set when installing the box. It also has greater
            capacity and durability than other boxes. Also protected from visual detection.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('box_3', 'gif')+ ')',
            width: '320px',
            height: '200px',
            marginLeft: 'calc(50% - 160px)',
            }"/>
        </li>
      </ul>

      <p>If the box was destroyed, all its contents are lost.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Boxes",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
