<template>
  <div id="chatSubMenu" class="chatSubMenu"
       v-bind:style="{ left: userSubMenuProps.x + 'px', top: userSubMenuProps.y + 'px' }">

    <div class="wrapper">

      <div class="chatSubMenuUserHead" v-if="!noHead">
        <div class="chatUserLine">
          <div class="chatUserIcon" v-bind:style="{backgroundImage: userSubMenuProps.avatar}"></div>
          <div>
            <div class="chatUserName">{{ userSubMenuProps.user.login }}</div>
            <div class="chatUserTitle">{{ userSubMenuProps.user.title }}</div>
          </div>
        </div>
      </div>

      <div class="chatSubMenuUserAction">
        <input v-if="!noDetail"
               class="cursor_hover"
               type="button"
               :value="texts['sub_1'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="information">

        <input v-if="userSubMenuProps.user.id !== currentPlayer.id"
               type="button"
               class="cursor_hover"
               :value="texts['sub_2'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="createPrivateChatGroup">

        <input v-if="userSubMenuProps.user.id !== currentPlayer.id"
               type="button"
               class="cursor_hover"
               :value="texts['sub_3'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="openService('Mail', {player_id: userSubMenuProps.user.id})">

        <template v-if="userSubMenuProps.additionalTabs">
          <input v-if="userSubMenuProps.additionalTabs.includes('makeLeaderGroup')"
                 type="button"
                 class="cursor_hover"
                 :value="texts['sub_4'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="makeLeaderGroup">
        </template>

        <input v-if="userSubMenuProps.user.id !== currentPlayer.id &&
        (!groupState.state || !groupState.state.hasOwnProperty(userSubMenuProps.user.id) || Number(groupState.uuid) === 0)"
               type="button"
               class="cursor_hover"
               :value="texts['sub_5'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="sendInviteGroup">
        <input v-if="mayInviteFriend"
               type="button"
               class="cursor_hover"
               :value="texts['sub_6'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="sendInviteFriend">
        <input v-if="mayInviteFriend"
               type="button"
               class="cursor_hover"
               :value="texts['sub_7'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="addContact">
        <input v-if="mayKickCorp"
               type="button"
               class="cursor_hover"
               :value="texts['sub_8'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="kickCorp">
        <input v-if="mayLeaveCorp"
               type="button"
               class="cursor_hover"
               :value="texts['sub_9'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="kickCorp">
        <input v-if="userSubMenuProps.user.id !== currentPlayer.id"
               type="button"
               class="cursor_hover"
               :value="texts['sub_10'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="openService('SendCredits', {user: userSubMenuProps.user})">
        <input type="button"
               class="cursor_hover"
               :value="texts['sub_11'][language]"
               v-if="!noClose"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="close">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserSubMenu",
  props: ['userSubMenuProps', 'noHead', 'noDetail', 'noClose'],
  data() {
    return {
      closeHandler: null,
      ignoreFirstClick: true,
    }
  },
  mounted() {
    this.closeHandler = this.closeSubMenu(this)
    window.addEventListener("click", this.closeHandler);
    setTimeout(function () {
      this.ignoreFirstClick = false
    }.bind(this), 200)
  },
  destroyed() {
    window.removeEventListener("click", this.closeHandler)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });

      this.close();
    },
    closeSubMenu(vue) {
      return function wrapperCloseSellSubMenu(event) {
        if (vue.ignoreFirstClick) {
          vue.ignoreFirstClick = false
          return;
        }

        let a = event.target
        let els = [];
        while (a) {
          els.unshift(a);
          a = a.parentNode;

          if (a && a.className === 'chatSubMenu') return;
        }

        vue.close()
      }
    },
    createPrivateChatGroup() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "chat",
        data: {
          event: "CreateNewPrivateGroup",
          id: Number(this.$props.userSubMenuProps.user.id),
        }
      }));

      this.close();
    },
    information() {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'userStat' + this.$props.userSubMenuProps.user.id,
        component: 'userStat',
        meta: {id: this.$props.userSubMenuProps.user.id},
        forceOpen: true,
      });
      this.close();
    },
    close() {
      this.playSound('button_press.mp3', 0.3)
      this.$props.userSubMenuProps.user = null;
    },
    makeLeaderGroup() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "ChangeLeaderGroup",
          id: Number(this.$props.userSubMenuProps.user.id),
        }
      }));

      this.close();
    },
    sendInviteGroup() {

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "InviteToGroup",
          name: this.$props.userSubMenuProps.user.login,
        }
      }));

      this.close();
    },
    sendInviteCorp() {
      return
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "InviteToCorp",
          name: this.$props.userSubMenuProps.user.login, // TODO ID?
        }
      }));

      this.close();
    },
    sendInviteFriend() {

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "AddFriend",
          data: this.$props.userSubMenuProps.user.login, // TODO ID?
        }
      }));

      this.close();
    },
    addContact() {

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "AddContact",
          data: this.$props.userSubMenuProps.user.login, // TODO ID?
        }
      }));

      this.close();
    },
    kickCorp() {

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "KickFromCorp",
          name: this.$props.userSubMenuProps.user.login,
        }
      }));

      this.close();
    },
    changeRoleCorp() {
      this.$parent.userSubMenuProps.dialogChangeRole = true;
      this.close();
    },
    getMemberRoleCorp(id) {
      for (let i in this.corporation.members) {
        if (Number(id) === Number(i)) {
          let member = this.corporation.members[i];
          if (member) {
            return this.corporation.roles[member.role_id];
          }
        }
      }
    }
  },
  computed: {
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    groupState() {
      return this.$store.getters.getGroupState;
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    contacts() {
      return this.$store.getters.getChatState.friends;
    },
    mayInviteCorp() {
      let props = this.$props.userSubMenuProps;
      if (!this.corporation || props.user.corporation_id > 0 || props.user.id === this.currentPlayer.id) {
        return false
      }

      let role = this.getMemberRoleCorp(this.currentPlayer.id);
      if (role) {
        return role.invite_users;
      }

      return false
    },
    mayInviteFriend() {

      if (this.currentPlayer.id === this.$props.userSubMenuProps.user.id) {
        return false
      }

      for (let c of this.contacts) {
        if (c.type === 'friend' && c.player_id === this.$props.userSubMenuProps.user.id) {
          return false
        }
      }

      return true
    },
    mayAddContact() {

      if (this.currentPlayer.id === this.$props.userSubMenuProps.user.id) {
        return false
      }

      for (let c of this.contacts) {
        if ((c.type === 'friend' || c.type === 'contact') && c.player_id === this.$props.userSubMenuProps.user.id) {
          return false
        }
      }

      return true
    },
    mayKickCorp() {
      if (!this.corporation) {
        return false
      }

      let props = this.$props.userSubMenuProps;

      if (props.parent && props.parent === 'corporation' &&
        props.user.id !== this.currentPlayer.id &&
        props.user.corporation_id === this.currentPlayer.corporation_id) {

        let role = this.getMemberRoleCorp(this.currentPlayer.id);
        if (role) {
          return role.kick_users;
        }
      }

      return false
    },
    mayLeaveCorp() {
      if (!this.corporation) {
        return false
      }

      let props = this.$props.userSubMenuProps;
      let role = this.getMemberRoleCorp(this.currentPlayer.id);
      if (role && role.name === "Leader") {
        return false
      }

      return props.parent && props.parent === 'corporation' && props.user.id === this.currentPlayer.id
    },
    mayChangeRoleCorp() {
      if (!this.corporation) {
        return false
      }

      let props = this.$props.userSubMenuProps;
      let role = this.getMemberRoleCorp(this.currentPlayer.id);
      if (role) {
        return role.change_role_user && props.user.id !== this.currentPlayer.id &&
          props.user.corporation_id === this.currentPlayer.corporation_id &&
          props.parent && props.parent === 'corporation'
      }

      return false
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
  }
}
</script>

<style scoped>

.chatUserLine {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  height: 25px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.chatUserIcon {
  background: rgba(0, 0, 0, 0.3);
  float: left;
  box-shadow: inset 0 0 5px black;
  background-size: cover;
}

#chatSubMenu {
  width: fit-content;
  position: absolute;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  border-radius: 5px;
  min-height: 20px;
  min-width: 80px;
  padding: 3px;
  text-shadow: 1px 1px 2px black, 0 0 1em #696969;
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
}

#chatSubMenu .chatUserLine {
  height: 35px;
  background: #5a5a5aa1;
  box-shadow: inset 0 0 2px black;
}

#chatSubMenu .chatUserIcon {
  width: 35px;
  height: 35px;
  background-size: cover;
}

#chatSubMenu .chatUserName {
  display: block;
  line-height: 18px;
  text-align: left;
  padding-right: 4px;
}

#chatSubMenu .chatUserTitle {
  display: block;
  line-height: 15px;
  text-align: left;
  font-size: 12px;
  text-indent: 8px;
  color: #efd800;
  padding-right: 4px;
}

.chatSubMenuUserAction input {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chatSubMenuUserAction input:active {
  transform: scale(0.98);
}

.chatSubMenuUserAction input:hover {
  background: rgba(255, 129, 0, 1);
}

.chatUserName {
  margin-left: 2px;
}

.chatUserLine div {
  display: inline-block;
}

.wrapper {
  height: 100%;
  width: calc(100% - 2px);
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
}

#chatSubMenu .wrapper {
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  padding: 3px;
}
</style>
