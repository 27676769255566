import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {createBox} from "./status_layer";
import {PlayPositionSound} from "../sound/play_sound";
import {movePlugin} from "../utils/move_sprite";
import {addIgnoreObject} from "./mini_map";

let Designators = {
  init: {}
}

function createCircleTexture(scene, styles, key) {
  if (Designators.init[key]) {
    return
  }
  Designators.init[key] = true

  let graphics = scene.add.graphics();
  graphics.setDefaultStyles(styles);
  let circle = {x: 312, y: 312, radius: 300};
  graphics.fillCircleShape(circle);
  graphics.strokeCircleShape(circle);
  graphics.generateTexture(key, 624, 624);
  graphics.destroy();
}

function initGunDesignator() {
  let newDesignator = {
    targetCircle: null,
    chaseCircle: null,
    ammoState: {
      container: null,
      ammoMsg: {currentMsg: '', sprite: null},
      reloadIcon: null,
      powerBar: null,
    },
    aimBoxes: {},
  };

  if (!newDesignator.targetCircle) {
    createCircleTexture(Scene, {
      lineStyle: {
        width: 12, color: 0xFF0000, alpha: 0.5
      }, fillStyle: {
        color: 0xFF4444, alpha: 0.2
      }
    }, "GunDesignator")

    newDesignator.targetCircle = Scene.make.image({
      x: 0, y: 0, key: "GunDesignator", add: true
    });
    newDesignator.targetCircle.setOrigin(0.5);
    newDesignator.targetCircle.setVisible(false);
    newDesignator.targetCircle.setDepth(900);
    addIgnoreObject(newDesignator.targetCircle)
  }

  if (!newDesignator.chaseCircle) {
    createCircleTexture(Scene, {
      lineStyle: {
        width: 12, color: 0x0000FF, alpha: 0.5
      }, fillStyle: {
        color: 0xFF4444, alpha: 0
      }
    }, "GunChaseDesignator")

    newDesignator.chaseCircle = Scene.make.image({
      x: 0, y: 0, key: "GunChaseDesignator", add: true
    });
    newDesignator.chaseCircle.setOrigin(0.5);
    newDesignator.chaseCircle.setVisible(false);
    newDesignator.chaseCircle.setDepth(900);
    addIgnoreObject(newDesignator.chaseCircle)
  }

  initAmmoStateSprite(newDesignator)
  return newDesignator
}

function initAmmoStateSprite(d) {

  if (d.ammoState.container) d.ammoState.container.destroy();
  if (d.ammoState.ammoMsg && d.ammoState.ammoMsg.sprite) {
    console.log("remove old ammo state")
    d.ammoState.ammoMsg.sprite.destroy();
  }
  if (d.ammoState.reloadIcon) d.ammoState.reloadIcon.destroy();
  if (d.ammoState.powerBar) d.ammoState.powerBar.destroy();

  d.ammoState.container = Scene.add.container(0, 0);
  d.ammoState.container.setVisible(false);
  d.ammoState.container.setDepth(999)
  d.ammoState.container.setScale(0.35)
  addIgnoreObject(d.ammoState.container)

  let reload = Scene.make.image({
    x: 0, y: 0, key: 'reload_ammo_icon', add: true,
  });
  reload.setScale(0.5)
  reload.setOrigin(0.5);
  reload.setVisible(false);
  d.ammoState.container.add(reload);
  d.ammoState.reloadIcon = reload

  Scene.tweens.add({
    targets: reload, props: {
      angle: {value: 360, duration: 1500},
    }, repeat: -1,
  });
}

function ServerGunDesignator(data) {

  if (!Designators[data.weaponSlot]) {
    Designators[data.weaponSlot] = initGunDesignator();
  }

  let d = Designators[data.weaponSlot]

  let key = "targetCircle";
  if (data.hide) {

    if (d.targetCircle.visible) d.targetCircle.setVisible(false);
    if (d.chaseCircle.visible) d.chaseCircle.setVisible(false);

    if (d.ammoState.container.visible) {
      d.ammoState.container.setVisible(false);
    }

    if (d.ammoState.ammoMsg.sprite) {
      d.ammoState.ammoMsg.currentMsg = '';
      d.ammoState.ammoMsg.sprite.destroy();
    }

    d.hide = true
    return
  } else {
    d.hide = false

    if (data.chase) {
      if (d.targetCircle.visible) d.targetCircle.setVisible(false);
      if (!d.chaseCircle.visible) d.chaseCircle.setVisible(true);
      key = "chaseCircle"

      if (data.t) {
        aimBox(d, data.t, data.id)
      } else {
        removeAimBoxes(d)
      }

    } else {
      if (!d.targetCircle.visible) d.targetCircle.setVisible(true);
      if (d.chaseCircle.visible) d.chaseCircle.setVisible(false);
    }
    if (!d.ammoState.container.visible) d.ammoState.container.setVisible(true);
  }

  if (d.ammoState.powerBar) d.ammoState.powerBar.destroy()
  if (data.ap > 0) {
    d.ammoState.powerBar = powerBarDraw(data.ap)
    d.ammoState.container.add(d.ammoState.powerBar);
  }

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);

  if (d[key].displayHeight !== data.rd || d[key].displayWidth !== data.rd) {
    Scene.tweens.add({
      targets: d[key], props: {
        displayHeight: {value: data.rd, duration: 48},
        displayWidth: {value: data.rd, duration: 48},
      }, repeat: 0,
    });
  }
  movePlugin(d[key], pos.x, pos.y, 48)
  // MoveSprite(Designators[key], pos.x, pos.y, 48, null, true, false);


  if (data.r) {

    //console.log(d.ammoState.reloadIcon)
    if (!d.ammoState.reloadIcon.visible) {
      d.ammoState.reloadIcon.setVisible(true)
    }

    if (d.ammoState.ammoMsg.sprite) {
      d.ammoState.ammoMsg.currentMsg = '';
      d.ammoState.ammoMsg.sprite.destroy();
    }

  } else {

    if (d.ammoState.reloadIcon.visible) {
      d.ammoState.reloadIcon.setVisible(false)
    }

    let ammoMsg = data.ac + " / " + data.aa;

    if (d.ammoState.ammoMsg.currentMsg !== ammoMsg) {
      d.ammoState.ammoMsg.currentMsg = ammoMsg

      if (d.ammoState.ammoMsg.sprite) {
        d.ammoState.ammoMsg.sprite.destroy();
      }

      d.ammoState.ammoMsg.sprite = Scene.add.bitmapText(0, 0, 'bit_text', ammoMsg, 32);
      d.ammoState.ammoMsg.sprite.setOrigin(0.5);
      d.ammoState.container.add(d.ammoState.ammoMsg.sprite);
    }
  }

  movePlugin(d.ammoState.container, pos.x + 25, pos.y - 25, 48)
  // MoveSprite(ammoState.container, pos.x + 25, pos.y - 25, 48, null, true, false);
}

function powerBarDraw(percent) {
  let bar = new Phaser.GameObjects.Graphics(Scene);
  bar.fillStyle(0x000000);
  bar.fillRect(0, 0, 80, 16);

  bar.fillStyle(0x919191);
  bar.fillRect(2, 2, 76, 12);

  let c = '#919191'

  bar.fillStyle(0xfff600);
  if (percent > 25 && percent < 50) bar.fillStyle(0xe7a12c);
  if (percent > 50 && percent < 75) bar.fillStyle(0xef6e09);
  if (percent > 75 && percent < 100) bar.fillStyle(0xc42727);
  if (percent === 100) bar.fillStyle(0xff0000);

  let width = 76 * (percent / 100)
  bar.fillRect(2, 2, width, 12);
  bar.setPosition(-40, 20)
  addIgnoreObject(bar)

  return bar
}

function aimBox(d, t, id) {

  if (d.aimBoxes[t + id]) {
    return
  }
  removeAimBoxes(d)

  if (t === 'unit' && !d.aimBoxes[t + id]) {
    let u = gameStore.units[id]
    if (u) {
      d.aimBoxes[t + id] = {box: createAimBox(), parent: u.sprite}
      d.aimBoxes[t + id].box.x = u.sprite.x
      d.aimBoxes[t + id].box.y = u.sprite.y

      PlayPositionSound(['missile_aim'], null, u.sprite.x, u.sprite.y, false, 0.5);
    }
  }

  if (t === 'object' && !d.aimBoxes[t + id]) {
    let o = gameStore.objects[id]
    if (o) {
      d.aimBoxes[t + id] = {box: createAimBox(), parent: o.objectSprite}
      d.aimBoxes[t + id].box.x = o.objectSprite.x
      d.aimBoxes[t + id].box.y = o.objectSprite.y

      PlayPositionSound(['missile_aim'], null, o.objectSprite.x, o.objectSprite.y, false, 0.5);
    }
  }

  if (t === 'mark' && !d.aimBoxes[t + id]) {
    let m = gameStore.radar_marks[id]
    if (m) {
      d.aimBoxes[t + id] = {box: createAimBox(), parent: m.sprite}
      d.aimBoxes[t + id].box.x = m.sprite.x
      d.aimBoxes[t + id].box.y = m.sprite.y

      PlayPositionSound(['missile_aim'], null, m.sprite.x, m.sprite.y, false, 0.5);
    }
  }
}

function updateAimBoxPos() {
  for (let i in Designators) {

    if (i === 'init') continue;

    for (let j in Designators[i].aimBoxes) {
      Designators[i].aimBoxes[j].box.x = Designators[i].aimBoxes[j].parent.x
      Designators[i].aimBoxes[j].box.y = Designators[i].aimBoxes[j].parent.y
    }
  }
}

function removeAimBoxes(d) {
  if (d.aimBoxes) {
    for (let i in d.aimBoxes) {
      d.aimBoxes[i].box.destroy()
      delete d.aimBoxes[i]
    }
  }
}

function createAimBox() {
  createBox(Scene, 15, 15, "aim_box", 0, 0, 0, {
    width: 5, color: 0xfff600, alpha: 1
  }, {
    color: 0x000000, alpha: 0
  })

  createBox(Scene, 16, 16, "aim_box_shadow", 0, 0, 0, {
    width: 8, color: 0x000000, alpha: 1
  }, {
    color: 0x000000, alpha: 0
  })

  let box = Scene.add.container(0, 0);

  let aimBoxShadowSprite = Scene.make.image({
    x: 0, y: 0, key: "aim_box_shadow", add: true
  });
  aimBoxShadowSprite.setOrigin(0.5);
  aimBoxShadowSprite.setVisible(true);

  let aimBoxSprite = Scene.make.image({
    x: 0, y: 0, key: "aim_box", add: true
  });
  aimBoxSprite.setOrigin(0.5);
  aimBoxSprite.setVisible(true);

  box.add(aimBoxShadowSprite)
  box.add(aimBoxSprite)
  box.setDepth(900);
  box.setAngle(45)
  addIgnoreObject(box)

  return box
}

function GetCircle() {
  for (let i in Designators) {

    if (i === 'init' || Designators[i].hide) continue;

    if (Designators[i].targetCircle && Designators[i].targetCircle.visible) return Designators[i].targetCircle;
    if (Designators[i].chaseCircle && Designators[i].chaseCircle.visible) return Designators[i].chaseCircle;
  }
}

export {
  initGunDesignator,
  ServerGunDesignator,
  updateAimBoxPos,
  removeAimBoxes,
  createCircleTexture,
  GetCircle,
  Designators
}
