import {gameStore} from "../store";
import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {PlayPositionSound} from "../sound/play_sound";

function Invisibility(id) {
  let unit = gameStore.units[id]
  if (!unit) return;

  unit.sprite.setAlpha(0.3);
}

function ActiveInvisibility(id, time) {
  let unit = gameStore.units[id]
  if (!unit) return;

  let hideDetails = [unit.sprite, unit.sprite.bodyBottom, unit.sprite.bodyBottomRight,
    unit.sprite.bodyBottomLeft, unit.sprite.antigravityPlace, unit.sprite.shadow, unit.sprite.bodyBottomLeftShadow,
    unit.sprite.bodyBottomRightShadow]

  for (let i in unit.weapons) {
    hideDetails.push(unit.weapons[i].weapon)
  }

  for (let i in unit.equips) {
    hideDetails.push(unit.equips[i].sprite)
  }

  unit.sprite.invisibilityTween = Scene.tweens.add({
    targets: hideDetails,
    ease: 'Linear', duration: time, alpha: 0.2,
    onComplete: function () {
      invisibilityPuff(unit.sprite.x, unit.sprite.y)
      PlayPositionSound(['invisibility_on'], null, unit.sprite.x, unit.sprite.y);
    }
  });
}

function DeactivateInvisibility(id, x, y) {
  let unit = gameStore.units[id]
  if (unit) {
    unit.sprite.setAlpha(1);
    if (unit.sprite.bodyBottom) unit.sprite.bodyBottom.setAlpha(1);
    if (unit.sprite.bodyBottomRight) unit.sprite.bodyBottomRight.setAlpha(1);
    if (unit.sprite.bodyBottomLeft) unit.sprite.bodyBottomLeft.setAlpha(1);
    if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.setAlpha(1);
    if (unit.sprite.shadow) unit.sprite.shadow.setAlpha(0.3);
    if (unit.sprite.bodyBottomLeftShadow) unit.sprite.bodyBottomLeftShadow.setAlpha(0.3);
    if (unit.sprite.bodyBottomRightShadow) unit.sprite.bodyBottomRightShadow.setAlpha(0.3);
    for (let i in unit.weapons) {
      unit.weapons[i].weapon.setAlpha(1);
    }

    for (let i in unit.equips) {
      unit.equips[i].sprite.setAlpha(1);
    }

    if (unit.sprite.invisibilityTween) {
      unit.sprite.invisibilityTween.remove();
      unit.sprite.invisibilityTween = null;
    }
  }

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  invisibilityPuff(pos.x, pos.y)
  PlayPositionSound(['invisibility_off'], null, pos.x, pos.y);
}

let invisibilityPuffStore = []

function invisibilityPuff(x, y) {
  let lifespan = 500

  let emitter
  if (invisibilityPuffStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: "blue",
      gravityY: 0,
      quantity: 100,
      speed: {min: 75, max: 150},
      scale: {min: 0.2, max: 0.4},
      angle: {min: 0, max: 360},
      alpha: {start: 0.1, end: 0, ease: 'Quad.easeIn'},
      lifespan: {min: lifespan / 2, max: lifespan},
      blendMode: 'SCREEN',
    });
    emitter.setDepth(400);
  } else {
    emitter = invisibilityPuffStore.shift();
  }

  emitter.explode(100, x, y);

  Scene.time.addEvent({
    delay: lifespan,
    callback: function () {
      emitter.stop()
      invisibilityPuffStore.push(emitter);
    },
  });
}

export {Invisibility, ActiveInvisibility, DeactivateInvisibility}
