<template>
  <div class="MissionPoints">

    <div class="missionUpdate" v-if="update">
      <div style="margin-top: 35%">{{ texts['text_1'][language] }}</div>
    </div>

    <table class="MissionPointsTable">
      <template v-if="missionPoints && point" v-for="(point, number) in missionPoints">
        <tr class="missionActions">
          <td class="actionNumber" v-if="!point.hide_number">{{ number }}</td>
          <td class="actionNumber" style="text-align: right" v-if="point.hide_number"> --</td>

          <td class="actionShortDescription" v-if="point.short_description">

            <div v-html="getPointText(point.short_description[language])"/>

            <template
              v-if="helpButton(point.short_description[language]) && missions.currentAction.number === point.number">
              <div class="help_button cursor_hover"
                   @click.stop="openService('helpWrapper', {component: helpButton(point.short_description[language])})">
                {{ texts['button_2'][language] }}
              </div>
            </template>
          </td>

          <td style="width: 15px;">
            <div :class="'actionComplete' + point.complete"></div>
          </td>
        </tr>

        <tr
          v-if="missions.currentAction && missions.currentAction.number === point.number && timeString && !point.complete"
          style="font-size: 9px">
          <td></td>
          <td colspan="2">{{ timeString }}</td>
        </tr>

        <tr v-if="point.type_func_monitor === 'destroy_npc' && point.count > 0">
          <td colspan="3">{{ point.current_count }} / {{ point.count }}</td>
        </tr>

        <template v-if="point.type_func_monitor !== 'get_item_on_base' && !point.complete">
          <tr v-for="slot in point.need_items_slots">
            <td class="missionItemCount" colspan="3">
              <app-item-cell v-bind:itemSlot="slot" v-bind:size="35" v-bind:noQuantity="true"/>
              <span v-if="slot.find_count <= slot.quantity">{{ slot.find_count }}/{{ slot.quantity }}</span>
              <span v-else>{{ slot.quantity }}/{{ slot.quantity }}</span>

              <div class="help_button cursor_hover" style="height: 30px; margin-top: 5px; opacity: 0.5"
                   @click.stop="openService('helpWrapper', {component: 'LostItem'})">
                {{ texts['button_3'][language] }}
              </div>
            </td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import ItemCell from '../Inventory/ItemCell';
import Vue from "vue";

export default {
  name: "Points",
  props: ["setCurrentAction", "actions", "last"],
  data() {
    return {
      timer: null,
      timeString: null,
      update: false,
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }

    this.timer = null;
    this.timeString = null;
  },
  methods: {
    getTimeString(sec) {
      let date = new Date(0);
      date.setSeconds(sec);
      return date.toISOString().substr(11, 8);
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    getPointText(description) {

      description = description.replace('[help_button:hangar]', '')
      description = description.replace('[help_button:body]', '')
      description = description.replace('[help_button:fuel]', '')
      description = description.replace('[help_button:equip]', '')
      description = description.replace('[help_button:quick_panel]', '')
      description = description.replace('[help_button:terminal]', '')
      description = description.replace('[help_button:weapon]', '')
      description = description.replace('[help_button:map]', '')
      description = description.replace('[help_button:open_object]', '')
      description = description.replace('[help_button:open_special_cell]', '')
      description = description.replace('[help_button:ammo]', '')
      description = description.replace('[help_button:exit_base]', '')
      description = description.replace('[help_button:exit_base]', '')
      description = description.replace('[help_button:mining]', '')
      description = description.replace('[help_button:process]', '')
      description = description.replace('[help_button:skill]', '')
      description = description.replace('[help_button:mini_map]', '')
      description = description.replace('[help_button:anomaly]', '')

      return description
    },
    helpButton(description) {
      if (description.includes('[help_button:hangar]')) {
        return "EducationHangar"
      }

      if (description.includes('[help_button:body]')) {
        return "EducationBody"
      }

      if (description.includes('[help_button:fuel]')) {
        return "EducationFuel"
      }

      if (description.includes('[help_button:equip]')) {
        return "EducationEquip"
      }

      if (description.includes('[help_button:quick_panel]')) {
        return "EducationQuickPanel"
      }

      if (description.includes('[help_button:terminal]')) {
        return "EducationTerminal"
      }

      if (description.includes('[help_button:weapon]')) {
        return "EducationWeapon"
      }

      if (description.includes('[help_button:map]')) {
        return "EducationMap"
      }

      if (description.includes('[help_button:open_object]')) {
        return "EducationOpenObject"
      }

      if (description.includes('[help_button:open_special_cell]')) {
        return "EducationOpenSpecialCell"
      }

      if (description.includes('[help_button:ammo]')) {
        return "EducationAmmo"
      }

      if (description.includes('[help_button:exit_base]')) {
        return "EducationExitBase"
      }

      if (description.includes('[help_button:mining]')) {
        return "EducationMining"
      }

      if (description.includes('[help_button:process]')) {
        return "EducationProcess"
      }

      if (description.includes('[help_button:skill]')) {
        return "EducationSkill"
      }

      if (description.includes('[help_button:mini_map]')) {
        return "EducationMiniMap"
      }

      if (description.includes('[help_button:anomaly]')) {
        return "Anomaly"
      }
    },
    findCurrentAction(actionSort) {

      let currentAction;

      for (let i in actionSort) {
        if (actionSort[i]) {
          if (!actionSort[i].complete && !currentAction) {
            currentAction = actionSort[i];
          }
        }
      }

      if (this.timer) {
        clearInterval(this.timer);
        this.timeString = null;
      }

      if (currentAction && currentAction.end_time > 0) {
        this.getTimer(currentAction)
        this.timer = setInterval(function () {
          this.getTimer(currentAction)
        }.bind(this), 1000)
      }

      if (currentAction && this.missions.currentAction && (this.missions.currentAction.number !== currentAction.number)) {
        this.update = true;
        setTimeout(function () {
          this.update = false;
        }.bind(this), 1500)
      }

      if (this.$props.setCurrentAction) {
        this.$store.commit({
          type: 'setCurrentAction',
          currentAction: currentAction,
        });
      }

      return currentAction
    },
    getTimer(a) {
      let currentSeconds = new Date().getTime() / 1000;

      let sec = a.end_time - currentSeconds
      if (sec < 0) {
        sec = 0;
        clearInterval(this.timer)
      }

      let t = this.getTimeString(a.end_time - currentSeconds)

      Vue.set(this, 'timeString', t);
    },
    getParent(actions, act) {
      for (let a of actions) {
        if (a && a.type_func_monitor && a.type_func_monitor === 'wait_async' && a.end_text && a.end_text['RU']) {
          let data = JSON.parse(a.end_text['RU'])
          if (data && data.actions && data.actions.includes(act.number)) {
            return a
          }
        }
      }
    },
    getChildren(actions, act) {
      let data = JSON.parse(act.end_text['RU'])
      let children = []
      if (data && data.actions) {

        for (let a of actions) {
          if (a && data.actions.includes(a.number)) {
            //for (let number of data.actions) {
            children.push(a)
            //}
          }
        }
      }

      return children
    }
  },
  computed: {
    missionPoints() {
      let actionSort = {};

      let sortActions = [];
      let actions = this.$props.actions;

      for (let i in actions) {
        sortActions[actions[i].number] = actions[i];
      }

      let realActions = {};
      let number = 1;
      for (let i in sortActions) {

        if (sortActions[i].number < 0) {
          continue
        }

        if (sortActions[i].async && this.getParent(sortActions, sortActions[i])) {
          continue
        }

        if (!(sortActions[i].description["RU"].includes('server_hidden') || sortActions[i].short_description["RU"].includes('server_hidden'))) {
          realActions[number] = sortActions[i];
          number++;
        }
      }

      for (let i in realActions) {
        // сортируем так что бы в масив не попали не открытые участки задания
        let append = false;

        if (Number(i) === 1 || realActions[i].complete) {
          actionSort[i] = realActions[i];
          number = i
        } else {
          for (let j in realActions) {
            // если впереди есть выполненые таски то прошлые даже не выполненые можно добавить
            if (i > i && realActions[j].complete) {
              append = true
            }

            // если текущая не выполнена, а позади да, то добавляем
            if (Number(j) === Number(i - 1) && realActions[j].complete) {
              append = true
            }
          }
        }

        if (append) {
          actionSort[i] = realActions[i];
          number = i;
        }
      }


      if (Object.keys(actionSort).length > this.$props.last) {
        let lastNElements = {}
        for (let el of Object.keys(actionSort).slice(1).slice(-this.$props.last)) {
          lastNElements[el] = actionSort[el]
        }

        actionSort = lastNElements
      }

      let currentAction = this.findCurrentAction(actionSort);
      if (currentAction) {
        if (currentAction.type_func_monitor === 'wait_async') {
          for (let a of this.getChildren(sortActions, currentAction)) {
            number++
            actionSort[number] = a;
            actionSort[number].hide_number = true
          }
        }

        if (currentAction.need_end && currentAction.need_end.length > 0) {
          for (let num of currentAction.need_end) {
            let need = sortActions[num]
            if (!need.description["RU"].includes('server_hidden') && !need.short_description["RU"].includes('server_hidden')) {
              if (!need.complete) {
                currentAction.description = need.description
                currentAction.short_description = need.short_description
              }
            }
          }
        }
      }

      return actionSort
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Missions')
    },
  },
  components: {
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>

.MissionPoints {
  height: calc(100% - 21px);
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.missionActions {
  width: 100%;
  font-size: 12px;
  clear: both;
}

.actionShortDescription {
  text-align: left;
}

.missionActions div {
  float: left;
}

.actionNumber {
  color: yellow;
  vertical-align: top;
}

.MissionPoints .InventoryCell {
  height: 35px;
  width: 35px;
}

.MissionPoints .QuantityItems {
  display: none;
}

.MissionPoints .missionItemCount {
  padding-left: 5px;
  text-align: left;
  line-height: 39px;
}

.MissionPoints .missionItemCount span {
  margin-left: 10px;
}

.actionCompletefalse, .actionCompletetrue {
  height: 10px;
  width: 10px;
  float: left;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-left: calc(50% - 10px);
}

.actionCompletetrue {
  background: url('../../assets/icons/ready.png');
  background-size: contain;
  filter: drop-shadow(1px 1px 0px black);
}

.MissionPointsTable {
  width: 100%;
}

@media (max-width: 1000px) {
  .missionActions {
    font-size: 9px;
  }

  #SelectMission {
    font-size: 10px;
  }
}

.help_button {
  display: block;
  width: 50px;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 13px;
  border-radius: 5px;
  color: #fff;
  line-height: 14px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: right;
  margin-left: 2px;
}

.help_button:active {
  transform: scale(.98);
}

.help_button:hover {
  background: #ff8100;
  opacity: 1 !important;
}

.missionUpdate {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  opacity: 0.3;
  line-height: 100%;
  text-align: center;
  border-radius: 5px;
  animation: blink 1.5s ease-out infinite;
  text-shadow: 1px 1px 1px black;
}

@keyframes blink {
  from {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}
</style>
