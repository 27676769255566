import {gameStore} from "../store";
import {Scene} from "../create";
import {RotateUnitGun} from "./rotate"
import {CreateUnitHP} from "../interface/status_layer";
import {GetGlobalPos} from "../map/gep_global_pos";
import {mouseBodyOver} from "./mouse_body_over";
import {GetMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {intFromBytes} from "../../utils";
import {processUnitBottom, processUnitEquip, processUnitWeapon} from "../update";
import {ActiveInvisibility} from "../equip/invisibility";
import {antigravityEngages, cacheBody, cacheEquip, cacheWeapon} from "./remove";
import store from "../../store/store";
import {AnimationMove} from "./move";
import {SetDayCycleUnit} from "../day_cycle/day_cycle";
import {AddDecal, DrawDecals} from "./decal";
import {addIgnoreObject} from "../interface/mini_map";

let UnitHeight = 25;
let bodyInit = false;

function CreateNewUnit(newUnit) {
  if (!gameStore.gameReady) return;

  if (!bodyInit) {
    for (let i in gameStore.gameTypes.bodies) {

      if (['MasherShip_1', 'MasherShip_2', 'MasherShip_3'].includes(gameStore.gameTypes.bodies[i].name)) {
        continue
      }

      bodyAnimate(gameStore.gameTypes.bodies[i])
      createShadowsCache(gameStore.gameTypes.bodies[i])
    }
    bodyInit = true
  }

  newUnit = parseData(newUnit)
  let unit = gameStore.units[newUnit.id];
  if (!unit || !unit.sprite) {

    let pos = GetGlobalPos(newUnit.position_data.x, newUnit.position_data.y, gameStore.map.id);
    let shadows = true

    let bodyState = gameStore.gameTypes.bodies[newUnit.body_state.id]
    newUnit.body = JSON.parse(JSON.stringify(bodyState));
    newUnit.body.max_hp = newUnit.body_state.max_hp;
    newUnit.body.max_power = newUnit.body_state.max_power;
    newUnit.body.range_view = newUnit.body_state.range_view;
    newUnit.body.range_radar = newUnit.body_state.range_radar;
    newUnit.body.max_shield_hp = newUnit.body_state.max_shield_hp;

    unit = createUnit(newUnit, pos.x, pos.y, newUnit.body, shadows);
    gameStore.units[newUnit.id] = unit;

    CreateEquip(unit)
    createWeapons(unit, shadows)

    if (newUnit.body.max_hp > 50) {
      CreateUnitHP(unit)
    }

    if (newUnit.interactive) {
      unitInteractive(newUnit.owner_id, unit)
    }

    processUnitWeapon(unit)
    processUnitEquip(unit)
    processUnitBottom(unit)
    SetDayCycleUnit(unit)
    DrawDecals(unit)

    if (newUnit.invisibility) {
      ActiveInvisibility(newUnit.id, 1000)
    }

    if (unit.ghost) {
      AnimationMove(unit, false, false, false, false, stop, Scene.shadowXOffset * 1.5)
    }
  }
}

function unitInteractive(owner_id, unit) {
  unit.sprite.setInteractive({useHandCursor: true, pixelPerfect: true})

  let postFxPlugin = Scene.plugins.get('rexoutlinepipelineplugin');

  unit.sprite.on('pointerover', function () {
    postFxPlugin.add(unit.sprite, {
      thickness: 1,
      outlineColor: 0xffffff
    });
  });

  unit.sprite.on('pointerout', function () {
    postFxPlugin.remove(unit.sprite);
  });

  unit.sprite.on('pointerdown', function (pointer) {
    if (!gameStore.mapEditor && pointer.leftButtonDown() && gameStore.unitReady && (!gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface)) {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "UnitInteractive",
          id: owner_id,
        }
      }));
    }
  });
}

function parseData(unitData) {
  let unit = {
    id: intFromBytes(unitData.slice(0, 4)),
    owner_id: intFromBytes(unitData.slice(4, 8)),
    hp: intFromBytes(unitData.slice(8, 12)),
    shield_hp: intFromBytes(unitData.slice(12, 16)),
    body_state: {
      id: intFromBytes(unitData.slice(16, 20)),
      texture: "",
      max_hp: intFromBytes(unitData.slice(32, 36)),
      max_shield_hp: intFromBytes(unitData.slice(36, 40)),
      max_power: intFromBytes(unitData.slice(50, 54)),
      range_view: intFromBytes(unitData.slice(40, 44)),
      range_radar: intFromBytes(unitData.slice(44, 48)),
    },
    position_data: {
      x: intFromBytes(unitData.slice(20, 24)),
      y: intFromBytes(unitData.slice(24, 28)),
      rotate: intFromBytes(unitData.slice(28, 32)),
    },
    warrior: intFromBytes(unitData.slice(48, 49)) === 1,
    fraction: intFromBytes(unitData.slice(49, 50)),
    weapon_slots_data: [],
    equip_slots_data: [],
    invisibility: intFromBytes(unitData.slice(54, 55)) === 1,
    interactive: intFromBytes(unitData.slice(55, 56)) === 1,
    corporation_id: intFromBytes(unitData.slice(56, 60)),
    ghost: intFromBytes(unitData.slice(60, 61)) === 1,
    police: intFromBytes(unitData.slice(61, 62)) === 1,
    light: intFromBytes(unitData.slice(62, 63)) === 1,
  };

  let textureLength = intFromBytes(unitData.slice(63, 64))
  unit.body_state.texture = String.fromCharCode(...unitData.subarray(64, 64 + textureLength))
  let stopByte = 64 + textureLength;

  let ownerLength = intFromBytes(unitData.slice(stopByte, stopByte + 1))
  let ownerBytesUTF8 = new Uint8Array(unitData.subarray(stopByte + 1, stopByte + 1 + ownerLength));
  unit.owner = new TextDecoder().decode(ownerBytesUTF8)
  stopByte = stopByte + 1 + ownerLength

  let decalDataSize = intFromBytes(unitData.slice(stopByte, stopByte + 4))
  stopByte = stopByte + 4
  let startByte = stopByte
  for (; stopByte < startByte + decalDataSize;) {
    let decal = {
      x: intFromBytes(unitData.slice(stopByte, stopByte + 1)),
      y: intFromBytes(unitData.slice(stopByte + 1, stopByte + 2)),
      id: intFromBytes(unitData.slice(stopByte + 2, stopByte + 3)),
      angle: intFromBytes(unitData.slice(stopByte + 3, stopByte + 4)) * 2,
    }

    AddDecal(unit, decal, false)
    stopByte = stopByte + 4
  }

  let weaponDataSize = intFromBytes(unitData.slice(stopByte, stopByte + 4))
  stopByte = stopByte + 4

  startByte = stopByte
  for (; stopByte < startByte + weaponDataSize;) {
    let slotData = {
      number: intFromBytes(unitData.slice(stopByte, stopByte + 1)),
      real_x_attach: intFromBytes(unitData.slice(stopByte + 1, stopByte + 5)),
      real_y_attach: intFromBytes(unitData.slice(stopByte + 5, stopByte + 9)),
      x_anchor: intFromBytes(unitData.slice(stopByte + 9, stopByte + 10)) / 100,
      y_anchor: intFromBytes(unitData.slice(stopByte + 10, stopByte + 11)) / 100,
      gun_rotate: intFromBytes(unitData.slice(stopByte + 11, stopByte + 15)),
      weapon_texture: "",
      weapon: false,
    }

    let textureLength = intFromBytes(unitData.slice(stopByte + 15, stopByte + 16));
    if (textureLength > 0) {
      slotData.weapon_texture = String.fromCharCode.apply(String, unitData.subarray(stopByte + 16, stopByte + 16 + textureLength))
      slotData.weapon = slotData.weapon_texture !== ""
    }

    stopByte = stopByte + 16 + textureLength
    unit.weapon_slots_data.push(slotData)
  }

  let equipDataSize = intFromBytes(unitData.slice(stopByte, stopByte + 4))
  stopByte = stopByte + 4;

  startByte = stopByte
  for (; stopByte < startByte + equipDataSize;) {
    let slotData = {
      number_slot: intFromBytes(unitData.slice(stopByte + 0, stopByte + 1)),
      type_slot: intFromBytes(unitData.slice(stopByte + 1, stopByte + 2)),
      real_x_attach: intFromBytes(unitData.slice(stopByte + 2, stopByte + 6)),
      real_y_attach: intFromBytes(unitData.slice(stopByte + 6, stopByte + 10)),
      x_anchor: intFromBytes(unitData.slice(stopByte + 10, stopByte + 11)) / 100,
      y_anchor: intFromBytes(unitData.slice(stopByte + 11, stopByte + 12)) / 100,
      equip_texture: "",
    }

    let textureLength = intFromBytes(unitData.slice(stopByte + 12, stopByte + 13));
    if (textureLength > 0) {
      slotData.equip_texture = String.fromCharCode.apply(String, unitData.subarray(stopByte + 13, stopByte + 13 + textureLength))
    }

    stopByte = stopByte + 13 + textureLength
    unit.equip_slots_data.push(slotData)
  }

  return unit
}

let bodyShadowCache = {}

function createShadowsCache(body) {

  if (bodyShadowCache.hasOwnProperty(body.name + "_shadow")) {
    return
  }

  let shadow = Scene.add.renderTexture(0, 0, 128, 128);

  if (body.name === "bug_1" || body.name === "bug_2" || body.name === "bug_3" || body.name === "bug_4" || body.name === "bug_5") {

  } else {
    if (body.chassis_type === "caterpillars") {
      shadow.drawFrame(body.name + "_bottom_animate_left", undefined, 0, 0);
      shadow.drawFrame(body.name + "_bottom_animate_right", undefined, 0, 0);
    }

    if (body.chassis_type === "wheels") {
      shadow.drawFrame(body.name + "_bottom_animate", undefined, 0, 0);
    }
  }

  bodyShadowCache[body.name + "_shadow"] = true
  shadow.drawFrame('bodies', body.name + '_skin_1', 0, 0);
  shadow.saveTexture(body.name + "_shadow");
  shadow.destroy()
}

function createUnit(unit, x, y, bodyState, shadows) {

  bodyAnimate(bodyState)

  let bodyBottomLeft, bodyBottomRight
  if (bodyState.chassis_type === "wheels" || bodyState.chassis_type === "caterpillars") {

    if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].bodyBottomLeft.length === 0) {
      bodyBottomLeft = Scene.make.sprite({
        x: x, y: y, key: bodyState.name + "_bottom_animate_left", add: true
      });
      bodyBottomLeft.setOrigin(0.5);
      bodyBottomLeft.play(bodyState.name + "_bottom_animate_left");
      bodyBottomLeft.anims.pause();
      addIgnoreObject(bodyBottomLeft);
    } else {
      bodyBottomLeft = cacheBody[unit.body_state.texture].bodyBottomLeft.shift();
      bodyBottomLeft.setPosition(x, y)
    }

    if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].bodyBottomRight.length === 0) {
      bodyBottomRight = Scene.make.sprite({
        x: x, y: y, key: bodyState.name + "_bottom_animate_right", add: true
      });
      bodyBottomRight.setOrigin(0.5);
      bodyBottomRight.play(bodyState.name + "_bottom_animate_right");
      bodyBottomRight.anims.pause();
      addIgnoreObject(bodyBottomRight);
    } else {
      bodyBottomRight = cacheBody[unit.body_state.texture].bodyBottomRight.shift();
      bodyBottomRight.setPosition(x, y)
    }
  }

  let bodyBottom
  if (bodyState.chassis_type === "wheels") {
    if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].bodyBottom.length === 0) {
      bodyBottom = Scene.make.sprite({
        x: x, y: y, key: bodyState.name + "_bottom_animate", add: true
      });
      bodyBottom.setOrigin(0.5);
      bodyBottom.play(bodyState.name + "_bottom_animate");
      bodyBottom.anims.pause();
      addIgnoreObject(bodyBottom);
    } else {
      bodyBottom = cacheBody[unit.body_state.texture].bodyBottom.shift();
      bodyBottom.setPosition(x, y)
    }

    bodyBottomRight.x = bodyState.wheel_anchors["right_front_wheel_attach"].real_x_attach
    bodyBottomRight.y = bodyState.wheel_anchors["right_front_wheel_attach"].real_y_attach
    bodyBottomRight.setOrigin(bodyState.wheel_anchors["right_front_wheel_attach"].x_anchor, bodyState.wheel_anchors["right_front_wheel_attach"].y_anchor);

    bodyBottomLeft.x = bodyState.wheel_anchors["left_front_wheel_attach"].real_x_attach
    bodyBottomLeft.y = bodyState.wheel_anchors["left_front_wheel_attach"].real_y_attach
    bodyBottomLeft.setOrigin(bodyState.wheel_anchors["left_front_wheel_attach"].x_anchor, bodyState.wheel_anchors["left_front_wheel_attach"].y_anchor);
  }

  // body sprite
  let body
  if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].sprite.length === 0) {
    body = Scene.make.image({key: 'bodies', x: x, y: y, frame: unit.body_state.texture, add: true});
    body.setOrigin(0.5);
    body.setDepth(UnitHeight);
    addIgnoreObject(body);
  } else {
    body = cacheBody[unit.body_state.texture].sprite.shift();
    body.setPosition(x, y)
  }

  body.setScale(GetSpriteSizeByMapLvl(GetMapLvl(body.x, body.y, gameStore.map.id), bodyState.scale / 100, 0.02).x);

  unit.sprite = body;
  unit.sprite.setAngle(unit.position_data.rotate);
  if (unit.sprite.RadarMark) {
    unit.sprite.RadarMark.setVisible(true)
    unit.sprite.RadarMark.NeedUpdate = true
  }

  // DAMAGE MASK
  if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].damage.length === 0) {
    unit.sprite.damage = Scene.add.renderTexture(x, y, 128, 128);
    unit.sprite.damage.setOrigin(0.5);
    unit.sprite.damage.setDepth(UnitHeight + 1);
    addIgnoreObject(unit.sprite.damage);
  } else {
    unit.sprite.damage = cacheBody[unit.body_state.texture].damage.shift();
    unit.sprite.damage.setPosition(x, y)
  }

  unit.sprite.damage.setScale(body.scale);

  // wheels, caterpillars bottom
  if (bodyState.chassis_type === "wheels" || bodyState.chassis_type === "caterpillars") {
    bodyBottomLeft.setAngle(unit.position_data.rotate)
    unit.sprite.bodyBottomLeft = bodyBottomLeft;

    bodyBottomRight.setAngle(unit.position_data.rotate)
    unit.sprite.bodyBottomRight = bodyBottomRight;
  }

  // wheels bottom
  if (bodyState.chassis_type === "wheels") {
    bodyBottom.setAngle(unit.position_data.rotate)
    unit.sprite.bodyBottom = bodyBottom;
  }

  // antigravity bottom
  let emitters
  if (bodyState.chassis_type === "antigravity") {
    emitters = createAntigravityEmitters(bodyState)
    unit.sprite.antigravityPlace = emitters.antigravityPlace;
    unit.sprite.backEngine = emitters.backEngine;
    unit.sprite.leftEngine = emitters.leftEngine;
    unit.sprite.rightEngine = emitters.rightEngine;
  }

  // shadow
  if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].shadow.length === 0) {
    unit.sprite.shadow = Scene.make.image({
      key: bodyState.name + '_shadow', x: x + Scene.shadowXOffset / 2, y: y + Scene.shadowYOffset / 2, add: true
    });
    unit.sprite.shadow.setOrigin(0.5);
    unit.sprite.shadow.setTint(0x000000);
    unit.sprite.shadow.setAlpha(0.5);
    addIgnoreObject(unit.sprite.shadow);
  } else {
    unit.sprite.shadow = cacheBody[unit.body_state.texture].shadow.shift();
    unit.sprite.shadow.setPosition(x + Scene.shadowXOffset / 2, y + Scene.shadowYOffset / 2)
  }

  unit.sprite.shadow.setScale(body.scale)
  unit.sprite.shadow.setAngle(body.angle)

  // wheels shadow
  if (bodyState.chassis_type === "wheels") {
    if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].bodyBottomLeftShadow.length === 0) {
      unit.sprite.bodyBottomLeftShadow = Scene.make.image({
        x: x, y: y, key: bodyState.name + "_bottom_animate_left", add: true
      });
      unit.sprite.bodyBottomLeftShadow.setOrigin(0.5);
      unit.sprite.bodyBottomLeftShadow.setTint(0x000000);
      unit.sprite.bodyBottomLeftShadow.setAlpha(0.3);
      addIgnoreObject(unit.sprite.bodyBottomLeftShadow);
    } else {
      unit.sprite.bodyBottomLeftShadow = cacheBody[unit.body_state.texture].bodyBottomLeftShadow.shift();
      unit.sprite.bodyBottomLeftShadow.setPosition(x, y)
    }

    unit.sprite.bodyBottomLeftShadow.setScale(body.scale)
    unit.sprite.bodyBottomLeftShadow.setAngle(body.angle)

    if (!cacheBody[unit.body_state.texture] || cacheBody[unit.body_state.texture].bodyBottomRightShadow.length === 0) {
      unit.sprite.bodyBottomRightShadow = Scene.make.image({
        x: x, y: y, key: bodyState.name + "_bottom_animate_right", add: true
      });
      unit.sprite.bodyBottomRightShadow.setOrigin(0.5);
      unit.sprite.bodyBottomRightShadow.setTint(0x000000);
      unit.sprite.bodyBottomRightShadow.setAlpha(0.3);
      addIgnoreObject(unit.sprite.bodyBottomRightShadow);
    } else {
      unit.sprite.bodyBottomRightShadow = cacheBody[unit.body_state.texture].bodyBottomRightShadow.shift();
      unit.sprite.bodyBottomRightShadow.setPosition(x, y)
    }

    unit.sprite.bodyBottomRightShadow.setScale(body.scale)
    unit.sprite.bodyBottomRightShadow.setAngle(body.angle)
  }

  let maskRadius = (unit.body.radius - (unit.body.radius / 50)) * unit.sprite.scale;
  let mask = Scene.make.graphics({x: unit.sprite.x, y: unit.sprite.y});
  mask.fillStyle(0xffffff);
  mask.fillCircle(0, 0, maskRadius);
  addIgnoreObject(mask);

  unit.sprite.explode_mask = {
    mask: mask.createGeometryMask(),
    graphics: mask,
    radius: maskRadius,
  };

  createUnitName(unit)
  mouseBodyOver(body, unit);
  return unit
}

function createShadow(sprite, shadows) {
  if (!shadows) return;
  sprite.shadow = Scene.plugins.get('rexdropshadowpipelineplugin').add(sprite, {
    distance: 5,
    angle: 325,
    shadowColor: 0x000000,
    alpha: 0.4,
    blur: 2,
    pixelWidth: 1,
    pixelHeight: 1,
    quality: 1,
  });
}

function createWeapons(unit, shadows) {
  unit.weapons = {};

  for (let weaponSlot of unit.weapon_slots_data) {

    if (weaponSlot && weaponSlot.weapon) {

      let weapon
      if (!cacheWeapon[weaponSlot.weapon_texture] || cacheWeapon[weaponSlot.weapon_texture].length === 0) {
        weapon = Scene.make.image({
          x: unit.sprite.x + (weaponSlot.real_x_attach * unit.sprite.scale),
          y: unit.sprite.y + (weaponSlot.real_y_attach * unit.sprite.scale),
          frame: weaponSlot.weapon_texture,
          add: true,
          key: "equips",
        });
        addIgnoreObject(weapon);
      } else {
        weapon = cacheWeapon[weaponSlot.weapon_texture].shift();
        weapon.setPosition(unit.sprite.x + (weaponSlot.real_x_attach * unit.sprite.scale), unit.sprite.y + (weaponSlot.real_y_attach * unit.sprite.scale))
        weapon.setVisible(true);
      }

      weapon.xAttach = weaponSlot.real_x_attach;
      weapon.yAttach = weaponSlot.real_y_attach;
      weapon.setOrigin(weaponSlot.x_anchor, weaponSlot.y_anchor);

      unit.weapons[weaponSlot.number] = {
        weapon: weapon,
        name: weaponSlot.weapon_texture,
      }

      if (weaponSlot && weaponSlot.weapon) {
        RotateUnitGun(unit.id, 64, weaponSlot.gun_rotate, weaponSlot.number);
      }
    }
  }
}

function CreateEquip(unit) {

  unit.equips = [];

  let createSprite = function (slot) {
    let slotSprite

    if (!cacheEquip[slot.equip_texture] || cacheEquip[slot.equip_texture].length === 0) {
      slotSprite = Scene.make.image({
        x: unit.sprite.x + (slot.real_x_attach * unit.sprite.scale),
        y: unit.sprite.y + (slot.real_y_attach * unit.sprite.scale),
        key: 'equips',
        add: true,
        frame: slot.equip_texture,
      });
      addIgnoreObject(slotSprite);
    } else {
      slotSprite = cacheEquip[slot.equip_texture].shift();
      slotSprite.setPosition(unit.sprite.x + (slot.real_x_attach * unit.sprite.scale), unit.sprite.y + (slot.real_y_attach * unit.sprite.scale))
      slotSprite.setVisible(true);
    }

    slotSprite.setOrigin(slot.x_anchor, slot.y_anchor);

    unit.equips.push({
      sprite: slotSprite,
      xAttach: slot.real_x_attach,
      yAttach: slot.real_y_attach,
      slot: slot,
      name: slot.equip_texture,
    });
  };

  let createSlots = function (slots) {
    for (let slot in slots) {
      createSprite(slots[slot])
    }
  };

  createSlots(unit.equip_slots_data);
}


function bodyAnimate(bodyState) {
  if (!gameStore.cacheAnimate.hasOwnProperty(bodyState.name)) {
    gameStore.cacheAnimate[bodyState.name] = true;

    if (bodyState.chassis_type === "antigravity") {
      return
    }

    Scene.textures.addSpriteSheetFromAtlas(bodyState.name + "_bottom_animate_left", {
      atlas: 'bodies',
      frame: bodyState.name + "_bottom_animate_left",
      frameWidth: gameStore.unitSpriteSize,
      frameHeight: gameStore.unitSpriteSize,
    });

    Scene.textures.addSpriteSheetFromAtlas(bodyState.name + "_bottom_animate_right", {
      atlas: 'bodies',
      frame: bodyState.name + "_bottom_animate_right",
      frameWidth: gameStore.unitSpriteSize,
      frameHeight: gameStore.unitSpriteSize,
    });

    Scene.anims.create({
      key: bodyState.name + "_bottom_animate_left",
      frames: Scene.anims.generateFrameNumbers(bodyState.name + "_bottom_animate_left"),
      frameRate: 16,
      repeat: -1
    });

    Scene.anims.create({
      key: bodyState.name + "_bottom_animate_right",
      frames: Scene.anims.generateFrameNumbers(bodyState.name + "_bottom_animate_right"),
      frameRate: 16,
      repeat: -1
    });

    if (bodyState.chassis_type === "wheels") {
      Scene.textures.addSpriteSheetFromAtlas(bodyState.name + "_bottom_animate", {
        atlas: 'bodies',
        frame: bodyState.name + "_bottom_animate",
        frameWidth: gameStore.unitSpriteSize,
        frameHeight: gameStore.unitSpriteSize,
      });

      Scene.anims.create({
        key: bodyState.name + "_bottom_animate",
        frames: Scene.anims.generateFrameNumbers(bodyState.name + "_bottom_animate"),
        frameRate: 16,
        repeat: -1
      });
    }
  }
}

function createUnitName(unit) {

  if (unit.fraction === 5) {
    return;
  }

  if (unit.sprite.userName) {
    if (unit.sprite.userName.login) unit.sprite.userName.login.destroy();
    if (unit.sprite.userName.pointer) unit.sprite.userName.pointer.destroy()
    if (unit.sprite.userName.loginShadow) unit.sprite.userName.loginShadow.destroy()
  }

  unit.sprite.userName = {login: null, loginShadow: null, pointer: null};

  if (unit.owner) {
    unit.sprite.userName.login = Scene.add.bitmapText(unit.sprite.x, unit.sprite.y, 'bit_text', unit.owner, 10)
    unit.sprite.userName.login.setDepth(900);
    unit.sprite.userName.login.setOrigin(0.5);
    unit.sprite.userName.login.setLetterSpacing(1)
    addIgnoreObject(unit.sprite.userName.login)
  }

  unit.sprite.userName.pointer = Scene.make.sprite({
    x: unit.sprite.x,
    y: unit.sprite.y,
    key: 'equips',
    frame: "unit_pointer",
    add: true
  });
  unit.sprite.userName.pointer.setDepth(900);
  unit.sprite.userName.pointer.setOrigin(0.5);
  unit.sprite.userName.pointer.setScale(0.2);
  addIgnoreObject(unit.sprite.userName.pointer)

  if (unit.corporation_id !== 0 && gameStore.corporationContacts.hasOwnProperty(unit.corporation_id)) {
    if (gameStore.corporationContacts[unit.corporation_id] === 1000) {
      unit.sprite.userName.attitude = Scene.make.image({
        x: unit.sprite.x,
        y: unit.sprite.y,
        key: 'equips',
        frame: "war_icon",
        add: true
      });

      unit.sprite.userName.attitude.setDisplaySize(12, 12);
    } else {
      unit.sprite.userName.attitude = Scene.make.image({
        x: unit.sprite.x,
        y: unit.sprite.y,
        key: 'equips',
        frame: "attitude_icon",
        add: true
      });
      unit.sprite.userName.attitude.setScale(0.32);

      if (gameStore.corporationContacts[unit.corporation_id] < 0) {
        unit.sprite.userName.attitude.setTint(0x9ACD32);
      } else {
        unit.sprite.userName.attitude.setTint(0xFF5100)
        unit.sprite.userName.attitude.setAngle(180)
      }
    }

    addIgnoreObject(unit.sprite.userName.attitude)
    unit.sprite.userName.attitude.setDepth(999)
  }

  if (unit.police) {
    unit.sprite.userName.attitude = Scene.make.image({
      x: unit.sprite.x,
      y: unit.sprite.y,
      key: 'equips',
      frame: "strike_icon",
      add: true
    });

    unit.sprite.userName.attitude.setDisplaySize(10, 10);
    unit.sprite.userName.attitude.setDepth(999)
    addIgnoreObject(unit.sprite.userName.attitude)
  }

  colorName(unit)
}

function colorName(unit) {

  if (unit.fraction === 5) {
    return;
  }

  if (unit.police) {
    if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xcfD8dc);
    unit.sprite.userName.pointer.setTint(0xcfD8dc)
    return;
  }

  if (unit.fraction === 4 || unit.fraction === 6) {
    if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xff0000);
    unit.sprite.userName.pointer.setTint(0xff0000)
    return;
  }

  let vio = gameStore.violators[unit.owner_id];

  if (unit.warrior && unit.owner_id < 0) {

    if (gameStore.map.possible_battle) {
      if (unit.fraction === 1) {
        if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xbb5153);
        unit.sprite.userName.pointer.setTint(0xbb5153)
      }

      if (unit.fraction === 2) {
        if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0x57bb51);
        unit.sprite.userName.pointer.setTint(0x57bb51)
      }

      if (unit.fraction === 3) {
        if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0x8095d9);
        unit.sprite.userName.pointer.setTint(0x8095d9)
      }
    } else {
      if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xffcd00);
      unit.sprite.userName.pointer.setTint(0xffcd00)
    }

    return;
  }

  if (gameStore.playerID === unit.owner_id) {
    if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0x15FF19);
    if (vio) {
      if (vio === 1) unit.sprite.userName.pointer.setTint(0xff0000);
      if (vio === 2) unit.sprite.userName.pointer.setTint(0xcc5ffd);
      if (vio === 3) unit.sprite.userName.pointer.setTint(0xeacef6);
    } else {
      unit.sprite.userName.pointer.setTint(0x15FF19);
    }
    return
  }

  if (gameStore.groupState.state[unit.owner_id]) {
    if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0x659DFF);
    if (vio) {
      if (vio === 1) unit.sprite.userName.pointer.setTint(0xff0000);
      if (vio === 2) unit.sprite.userName.pointer.setTint(0xcc5ffd);
      if (vio === 3) unit.sprite.userName.pointer.setTint(0xeacef6);
    } else {
      unit.sprite.userName.pointer.setTint(0x659DFF);
    }
    return
  }

  if (vio) {

    if (vio === 1) {
      if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xff0000);
      unit.sprite.userName.pointer.setTint(0xff0000);
    }

    if (vio === 2) {
      if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xcc5ffd);
      unit.sprite.userName.pointer.setTint(0xcc5ffd);
    }

    if (vio === 3) {
      if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xe6bdf8);
      unit.sprite.userName.pointer.setTint(0xeacef6);
    }

    return;
  }

  if (unit.sprite.userName.login) unit.sprite.userName.login.setTint(0xFFFFFF);
  unit.sprite.userName.pointer.setTint(0xFFFFFF)
}

let scaleAntigravityEngages = {start: 0.4, end: 0.8}

function createAntigravityEmitters(bodyState) {

  let antigravityPlace
  if (antigravityEngages.antigravityPlace.length === 0) {
    antigravityPlace = Scene.add.particles(0, 0, 'flares', {
      frame: 'yellow',
      speed: 50,
      lifespan: 2000,
      quantity: 1,
      scale: {start: 0.7, end: 1.3},
      alpha: {start: 0.2, end: 0},
      blendMode: 'ADD',
      frequency: 128,
    });
    antigravityPlace.setDepth(UnitHeight - 1);
    addIgnoreObject(antigravityPlace);
  } else {
    antigravityPlace = antigravityEngages.antigravityPlace.shift();
    antigravityPlace.setVisible(true)
    antigravityPlace.start();
  }

  let backEngine
  if (antigravityEngages.backEngine.length === 0) {
    backEngine = Scene.add.particles(0, 0, 'flares', {
      x: 0,
      y: 0,
      frame: 'blue',
      speed: {min: 40, max: 70},
      lifespan: 256,
      quantity: 1,
      scale: scaleAntigravityEngages,
      alpha: {start: 0.3, end: 0},
      angle: {min: 180 - 15, max: 180 + 15},
      blendMode: 'ADD',
      frequency: 48,
    });
    backEngine.setDepth(UnitHeight - 1);
    addIgnoreObject(backEngine);
  } else {
    backEngine = antigravityEngages.backEngine.shift();
    backEngine.setVisible(true)
    backEngine.start();
  }

  let leftEngine
  if (antigravityEngages.leftEngine.length === 0) {
    leftEngine = Scene.add.particles(0, 0, 'flares', {
      x: 0,
      y: 0,
      frame: 'blue',
      speed: {min: 40, max: 70},
      lifespan: 256,
      quantity: 1,
      scale: scaleAntigravityEngages,
      alpha: {start: 0.4, end: 0},
      angle: {min: -90 - 15, max: -90 + 15},
      blendMode: 'ADD',
      frequency: 48,
    });
    leftEngine.setDepth(UnitHeight - 1);
    addIgnoreObject(leftEngine);
  } else {
    leftEngine = antigravityEngages.leftEngine.shift();
    leftEngine.setVisible(true)
    leftEngine.start();
  }

  let rightEngine
  if (antigravityEngages.rightEngine.length === 0) {
    rightEngine = Scene.add.particles(0, 0, 'flares', {
      x: 0,
      y: 0,
      frame: 'blue',
      speed: {min: 40, max: 70},
      lifespan: 256,
      quantity: 1,
      scale: scaleAntigravityEngages,
      alpha: {start: 0.4, end: 0},
      angle: {min: 90 - 15, max: 90 + 15},
      blendMode: 'ADD',
      frequency: 48,
    });
    rightEngine.setDepth(UnitHeight - 1);
    addIgnoreObject(rightEngine);
  } else {
    rightEngine = antigravityEngages.rightEngine.shift();
    rightEngine.setVisible(true)
    rightEngine.start();
  }

  return {antigravityPlace: antigravityPlace, backEngine: backEngine, leftEngine: leftEngine, rightEngine: rightEngine}
}

export {CreateNewUnit, UnitHeight, colorName, createUnitName, scaleAntigravityEngages}
