<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Счет кластера</h2>
      <div class="content_section">
        <p>Счета кластера - это банальное хранилище денежных сумм кластера. Финансовая система игры настроена таким
          образом, что у каждого кластера имеется пять (5) счетов, позволяющих более тонко настроить затраты и права на
          них.</p>

        <p>Помимо прочего, сами счета можно переименовывать, посредством меню - “О кластере”, что только добавит
          контроля
          за денежными суммами.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_1', 'jpg')+ ')',
            width: '400px',
            height: '126px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <p>Зачислить деньги на счёт кластера может любой игрок, даже не состоящий в самом кластере. Осуществлено это
          может
          быть через меню - “перевода кредитов”. </p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_2', 'jpg')+ ')',
            width: '319px',
            height: '288px',
            marginLeft: 'calc(50% - 159px)',
            }"/>

        <p>По-умолчанию, доступ к снятию средств со счетов кластера имеет только лидер, впрочем, подобными права можно
          наделить и остальных членов кластера. </p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_3', 'jpg')+ ')',
            width: '369px',
            height: '361px',
            marginLeft: 'calc(50% - 184px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Cluster Account</h2>
      <div class="content_section">
        <p>Cluster accounts are a simple storage for the cluster's funds. The game's financial system is set up in such
          a way that each cluster has five (5) accounts, allowing for finer tuning of expenses and rights to them.</p>

        <p>In addition, the accounts themselves can be renamed through the "About Cluster" menu, which only adds control
          over the funds.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_1', 'jpg')+ ')',
            width: '400px',
            height: '126px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <p>Any player, even those not in the cluster, can credit money to the cluster's account. This can be done
          through the "credit transfer" menu.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_2', 'jpg')+ ')',
            width: '319px',
            height: '288px',
            marginLeft: 'calc(50% - 159px)',
            }"/>

        <p>By default, only the leader has access to withdraw funds from the cluster accounts, however, such rights can
          also be granted to other cluster members.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('deposit_3', 'jpg')+ ')',
            width: '369px',
            height: '361px',
            marginLeft: 'calc(50% - 184px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ClusterDeposit",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
