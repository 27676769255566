<template>
  <div id="NPCTrade" ref="NPCTrade" @mousedown="toUp">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'NPCTrade'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 273, width: 428}"/>

    <div class="fail_trade_event" :class="{fail_trade_event_visible: errorTrade}">
      <div style="margin-top: 30%">{{ texts['error_1'][language] }}</div>
    </div>

    <!--    <div class="not_allow" v-if="relationBonuses && relationBonuses[base.fraction].locked_up_npc_trade">-->
    <!--      <div class="not_allow_text">-->
    <!--        Мы не доверяем вам и поэтому этот сервис не доступен.-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="dialogWrapper" v-if="dialog">
      <div class="dialog">

        <div class="dialogText">
          <app-item-cell @click.native="openDetail('product', dialog.product.id)"
                         v-bind:itemSlot="{type: 'product', item: dialog.product}"
                         v-bind:noShowName="false"
                         v-bind:size="50"/>

          <div style="margin-left: 4px; margin-top: 10px; float: left;">
            <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeQuantity(-1, 1, dialog.max)">
              -1
            </div>
            <input type="range" class="cursor_hover" v-model="dialog.quantity" min="1" :max="dialog.max">
            <div class="range_button cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeQuantity(1, 1, dialog.max)">+1
            </div>
          </div>

          <input type="number" v-model="dialog.quantity" min="1" :max="dialog.max"/>

          <div style="width: calc(100% - 58px); float: left; margin-bottom: 3px; margin-top:5px; font-size: 1px;">
            <template v-if="dialog.size">
              <div class="inventory_icon">{{ dialog.quantity * dialog.size }}</div>
              <span class="total"
                    style="width: 30px; float: left">{{ ((dialog.quantity * dialog.size) / 1000).toFixed(2) }} </span>
            </template>

            <span class="total"
                  :class="{few_credits: dialog.type === 'buy' && market.my_credits < dialog.quantity * dialog.price}"
                  style="width: 65px">{{ ((dialog.quantity * dialog.price) / 100).toFixed(2) }} cr.</span>
          </div>
        </div>

        <div style="float: left; width: 100%;">
          <input class="dialogButton cursor_hover"
                 type="button"
                 :value="texts['button_1'][language]"
                 @click="dialog = null"/>
          <input style="float: right; margin-right: 10px" class="dialogButton cursor_hover" type="button"
                 :value="dialog.buttonName"
                 :class="{disable:
                   dialog.type === 'buy' && market.my_credits < dialog.quantity * dialog.price ||
                   dialog.type === 'sell' && dialog.max < dialog.quantity
                 }"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="dialogAction"/>
        </div>
      </div>
    </div>

    <div class="wrapper" v-if="products && productStatus.price && productStatus.count">
      <div class="trade_state base">

        <div class="head">
          <div class="icon"
               :style="{backgroundImage: 'url(' + baseIcon + ')'}">
            <div class="icon fraction" v-if="base.state.fraction !== ''"
                 :style="{backgroundImage: 'url(' + require('../../assets/' + getFractionLogo(base.state.fraction)).default+')'}"/>
          </div>

          <div class="name">
            <p>{{ getBaseTypeName(base.state.type) }}</p>
            <p>{{ handbook['base'][language][base.state.name].name }}</p>
            <p v-if="relationBonuses && relationBonuses[base.fraction].locked_up_npc_trade"
               style="font-size: 9px; color: red; font-weight: bold;">{{ texts['text_1'][language] }}</p>
          </div>
        </div>

        <div class="trade_table_wrapper">
          <table class="trade_table">
            <tr>
              <th style="width: 40px;">{{ texts['qty'][language] }}</th>
              <th></th>
              <th style="width: 50px;">{{ texts['price'][language] }}</th>
            </tr>

            <tr v-for="product in products" class="product_row cursor_hover"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click="openDialog('buy', product)"
                :class="{
                  fail: productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id),
                  row_disable: relationBonuses && relationBonuses[base.fraction].locked_up_npc_trade && !(productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id)),
                  }">
              <td>
                <div class="fail_trade"
                     v-if="productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id)"/>


                <div class="help_point" @click.stop="openDetail('product', product.id)"></div>

                {{ productStatus.count[product.id] ? productStatus.count[product.id] : 0 }}
              </td>
              <td>
                <div class="product_icons">
                  <div class="product_icon" v-for="n in 10" :style="{zIndex: 10-n}"
                       :class="{disable_product: !productStatus.count[product.id] || productStatus.count[product.id] < (n - 1) * (baseMaxCount/10) }">
                    <app-background-item-cell v-bind:noShowName="true"
                                              v-bind:slotItem="{type: 'product', item: product}"/>
                  </div>
                </div>
              </td>
              <td>{{ (productStatus.price[product.id].sell / 100).toFixed(2) }} cr.</td>
            </tr>

          </table>
        </div>
      </div>

      <div class="trade_state player">

        <div class="head">
          <div class="icon"
               :style="{backgroundImage: avatars[currentPlayer.id] ? avatars[currentPlayer.id].avatar : undefined}">
          </div>

          <div class="name">
            <p>{{ texts['text_2'][language] }}</p>
            <p>{{ currentPlayer.login }}</p>
          </div>
        </div>

        <div class="trade_table_wrapper">
          <table class="trade_table">
            <tr>
              <th style="width: 40px;">{{ texts['qty'][language] }}</th>
              <th></th>
              <th style="width: 50px;">{{ texts['price'][language] }}</th>
            </tr>

            <tr v-for="product in products" class="product_row cursor_hover"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click="openDialog('sell', product, productStatus.count[product.id])"
                :class="{
                  fail: productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id),
                  row_disable: relationBonuses && relationBonuses[base.fraction].locked_up_npc_trade && !(productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id)),
                  }">
              <td>

                <div v-if="productStatus.count[product.id] >= baseMaxCount" class="product_overhead"
                     style="margin: -14px 1px 0 0;"></div>

                <div class="fail_trade"
                     v-if="productStatus.fail_trade && productStatus.fail_trade.length > 0 && productStatus.fail_trade.includes(product.id)"/>

                <div class="help_point" @click.stop="openDetail('product', product.id)"></div>

                {{ playerProductsCount[product.id] }}
              </td>

              <td>

                <div v-if="productStatus.count[product.id] >= baseMaxCount" class="product_overhead">Склад переполнен</div>

                <div class="product_icons">
                  <div class="product_icon" v-for="n in 10" :style="{zIndex: 10-n}"
                       :class="{disable_product: !playerProductsCount[product.id] || playerProductsCount[product.id] < (n - 1) * (baseMaxCount/10)}">
                    <app-background-item-cell v-bind:noShowName="true"
                                              v-bind:slotItem="{type: 'product', item: product}"/>
                  </div>
                </div>
              </td>
              <td>

                <div v-if="productStatus.count[product.id] >= baseMaxCount" class="product_overhead"
                     style="margin: -14px 1px 0 -1px;"></div>

                {{ (productStatus.price[product.id].buy / 100).toFixed(2) }} cr.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Control from "../Window/Control";
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import ItemCell from "../Inventory/ItemCell";

export default {
  name: "NPCTrade",
  data() {
    return {
      updater: null,
      dialog: null,
      baseMaxCount: 10000,
    }
  },
  destroyed() {
    clearInterval(this.updater)
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "lobby",
      data: {
        event: "gps"
      },
    }))

    this.updater = setInterval(function () {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "gps"
        },
      }))
    }.bind(this), 1000)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    resize(event, ui, el) {

    },
    getFractionLogo(fraction) {

      if (fraction === '') {
        fraction = 'Free'
      }

      return `logo/${fraction}.png`
    },
    getBaseTypeName(baseType) {
      return this.$store.getters.getBaseTypeName(baseType, this.language)
    },
    getAvatar(userID) {
      this.$store.dispatch('getAvatar', {
        userID: userID,
        vm: this
      });
    },
    openDialog(dialogType, product, currentCount) {

      if (dialogType === 'sell' && currentCount >= 10000) {
        return
      }

      this.playSound('button_press.mp3', 0.3)
      this.dialog = {
        type: dialogType,
        product: product,
        quantity: 1,
        max: 0,
        price: 0,
        buttonName: "",
      }

      if (dialogType === "sell") {

        let max = this.baseMaxCount - this.productStatus.count[product.id]
        if (this.playerProductsCount[product.id] < max || isNaN(max)) {
          max = this.playerProductsCount[product.id]
        }

        this.dialog.price = this.productStatus.price[product.id].buy
        this.dialog.max = max
        this.dialog.buttonName = this.texts['sell'][this.language]
      }

      if (dialogType === "buy") {
        this.dialog.price = this.productStatus.price[product.id].sell
        this.dialog.max = this.productStatus.count[product.id]
        this.dialog.buttonName = this.texts['buy'][this.language]
        this.dialog.size = product.size
      }
    },
    changeQuantity(change, min, max) {
      this.playSound('button_press.mp3', 0.3)

      this.dialog.quantity = Number(this.dialog.quantity)
      if (isNaN(this.dialog.quantity)) {
        this.dialog.quantity = 0
      }

      this.dialog.quantity += change

      if (this.dialog.quantity > max) {
        this.dialog.quantity = max
      }

      if (this.dialog.quantity < min) {
        this.dialog.quantity = min
      }
    },
    dialogAction() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "ProductMarketDeal",
          id: Number(this.dialog.product.id),
          price: Number(this.dialog.price),
          count: Number(this.dialog.quantity),
          type: this.dialog.type,
        },
      }))
      this.dialog = null
    },
    openDetail(type, id) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('NPCTrade')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    base() {
      return this.$store.getters.getBaseStatus
    },
    baseIcon() {
      if (this.base.state.sprite_texture !== "") {
        return require('../../assets/map/objects/sector_structure/' + this.base.state.sprite_texture + '.png').default
      }

      if (this.base.state.type === "replic_bunker") {
        return require('../../assets/map/structures/icon/replic_gauss_gun.png').default
      }

      return require('../../assets/icons/cancelIcon.png').default
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    avatars() {
      return this.$store.getters.getChatState.avatars;
    },
    productStatus() {
      return this.$store.getters.getBaseProductStatus;
    },
    products() {
      let sortProducts = [];
      let products = this.$store.getters.getProducts
      for (let i in products) {
        sortProducts.push(products[i])
      }

      sortProducts.sort(function (a, b) {
        return a.default_price - b.default_price;
      });

      return sortProducts
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    playerProductsCount() {
      let products = {};

      if (!this.inventory || !this.inventory.warehouses) return products

      for (let p of this.products) {
        products[p.id] = 0

        if (this.inventory.warehouses["squadInventory"]) {
          for (let i in this.inventory.warehouses["squadInventory"].inventory) {
            let slot = this.inventory.warehouses["squadInventory"].inventory[i];
            if (slot && slot.quantity > 0 && slot.type === "product" && slot.item_id === p.id) {
              products[p.id] += slot.quantity
            }
          }
        }

        if (this.inventory.warehouses["storage"]) {
          for (let i in this.inventory.warehouses["storage"].inventory) {
            let slot = this.inventory.warehouses["storage"].inventory[i];
            if (slot && slot.quantity > 0 && slot.type === "product" && slot.item_id === p.id) {
              products[p.id] += slot.quantity
            }
          }
        }
      }

      return products
    },
    market() {
      return this.$store.getters.getMarket
    },
    errorTrade() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('npc_trade_fail') && errors['npc_trade_fail'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'npc_trade_fail',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    }
  },
  components: {
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
#NPCTrade {
  pointer-events: auto;
  position: absolute;
  top: 35%;
  left: calc(50% - 170px);
  z-index: 11;
  width: 428px;
  height: 273px;
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.wrapper {
  height: calc(100% - 3px);
  width: 100%;
}

.trade_state {
  box-shadow: inset 0 0 3px black;
  background: rgba(137, 150, 156, 0.3);
  height: calc(100% + 3px);
  width: calc(50% - 3px);
  border-radius: 5px;
  overflow: hidden;
  float: left;
}

.trade_state.player {
  float: right;
}

.head {
  width: calc(100% - 4px);
  padding: 3px 2px;
  height: 39px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.icon {
  float: left;
  height: 40px;
  width: 40px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 2px black;
}

.icon.fraction {
  height: 20px;
  width: 20px;
  box-shadow: none;
  background-color: unset;
  filter: drop-shadow(0px 0px 1px black);
  margin: 1px;
}

.name {
  color: yellow;
  float: left;
  font-size: 10px;
  margin-left: 5px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  width: calc(100% - 45px);
  margin-top: 6px;
}

.name p:first-child {
  font-size: 10px;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.name p {
  margin: 0;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-size: 12px;
}

.trade_table_wrapper {
  box-shadow: inset 0 0 2px black;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  height: calc(100% - 47px);
  width: 100%;
}

.trade_table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
}

.trade_table tr:first-child {
  box-shadow: 0 0 2px rgb(0 0 0);
}

.trade_table tr {
  text-align: center;
}

.trade_table tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 8px;
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 13;
}

.trade_table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  padding-top: 4px;
  font-size: 11px;
  position: relative;
}

.trade_table td {

}

.product_icons {
  height: 30px;
  width: 120px;
  position: relative;
}

.product_icon {
  float: left;
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: -20px;
  filter: drop-shadow(0px 0px black);
}

.product_icon.disable_product {
  filter: grayscale(1) drop-shadow(0px 0px black);
  opacity: 0.05;
}

.product_icon:first-child {
  margin-left: 0;
  opacity: 1;
}

.product_row {
  transition: 200ms;
}

.product_row:hover {
  background: rgba(0, 0, 0, 0.2);
}

.product_row:active {
  background: rgba(0, 0, 0, 0.4);
}

.dialogWrapper {
  position: absolute;
  left: calc(50% - 137px);
  top: calc(50% - 50px);
  width: 275px;
  height: 80px;
  display: block;
  background-size: 100% 2px;
  border-radius: 5px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
  padding: 3px 3px 5px;
}

.dialog {
  height: 100%;
  width: calc(100% - 2px);
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
}

.title_row {
  float: left;
  font-size: 10px;
  color: bisque;
  margin-right: 5px;
  line-height: 15px;
}

.dialog input[type=number] {
  float: right;
  margin-top: 9px;
  margin-right: 5px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  width: 27px;
  height: 14px;
  text-indent: 1px;
  text-align: right;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.dialog input[type=number]:disabled {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  background: rgba(127, 127, 127, 0.5);
}

.dialog input[type=number], #sellDialog input[type=number] {
  -moz-appearance: textfield;
}

.dialog input[type=number]::-webkit-inner-spin-button, #sellDialog input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_number_row {
  float: left;
  width: calc(100% - 48px);
  margin-top: 7px;
}

.dialogButton {
  width: 40%;
  margin-top: 3px;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin-left: 10px;
}

.dialogButton:hover {
  background: rgba(255, 129, 0, 1);
}

.dialogButton:active {
  transform: scale(0.98);
}

.total {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  margin: 0;
  float: right;
  text-align: right;
  background-size: 5px 2px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  line-height: 15px;
  box-shadow: inset 0 0 1px 1px rgb(118 118 118);
  border: 0;
  font-size: 11px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.dialog span {
  font-size: 10px;
  color: bisque;
  text-shadow: 1px 1px 1px black;
}

.dialog input[type=range] {
  float: left;
  margin-top: -1px;
  width: 133px;
  margin-bottom: 5px;
  padding: 0;
}

.dialog input[type=range]::-webkit-slider-thumb {
  height: 15px;
}

.fail {
  /*background: rgba(235, 79, 79, 0.4);*/
}

.fail_trade {
  height: 16px;
  width: 16px;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../../assets/icons/fail_trade.png");
  background-size: contain;
}

.inventory_icon {
  background-image: url("../../assets/icons/inventory_size_icon.png");
  display: inline-block;
  height: 20px;
  width: 20px;
  background-size: 90%;
  background-position: center;
  float: left;
  background-repeat: no-repeat;
}

.not_allow {
  height: calc(100% - 16px);
  width: 100%;
  position: absolute;
  left: 0;
  top: 17px;
  background: rgba(125, 125, 125, 0.3);
  z-index: 20;
  backdrop-filter: blur(10px);
}

.not_allow_text {
  margin-top: 5%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  opacity: 0.8;
}

.few_credits {
  outline: 1px solid red;
}

.product_overhead {
  position: absolute;
  z-index: 12;
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 17px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: -4px 1px 0 -1px;
  font-size: 14px;
}

.fail_trade_event {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: -20px 0 0 -3px;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
}

.fail_trade_event_visible {
  opacity: 1;
}

.help_point {
  height: 13px;
  width: 13px;
  position: absolute;
  right: 0;
  top: 1px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}

.row_disable {
  filter: grayscale(1);
  opacity: 0.5;
  background: grey !important;
  pointer-events: none !important;
}
</style>

<style>
#NPCTrade .chatUserLine {
  min-height: 39px !important;
}
</style>
