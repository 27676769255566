import {BlueExplosion, FireExplosion, GreenExplosionSCREEN, SmokeExplosion, WhiteExplosion} from "./explosion";
import {GetGlobalPos} from "../map/gep_global_pos";
import {PlayPositionMarket, PlayPositionSound} from "../sound/play_sound";
import {gameStore} from "../store";
import {Scene} from "../create";

function FireWeapon(data) {
  if (!gameStore.gameReady) return;

  let weaponInfo = gameStore.gameTypes.weapons[data.type_id]
  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  if (!data.f && !Scene.cameras.main.worldView.contains(pos.x, pos.y)) {
    return;
  }

  if (!weaponInfo) {
    console.log("unknown weapon: ", data.type_id)
  }

  data.z += 2

  if ((weaponInfo.name === "explores_weapon_1" || weaponInfo.name === "big_laser" || weaponInfo.name === "far_god_1" || weaponInfo.name === "explores_weapon_6" ||
    weaponInfo.name === "explores_weapon_5") && data.ap !== 0) {

    let power = data.ap
    if (data.ap > 100) {
      power = 100
      if (weaponInfo.name === "explores_weapon_5") {
        // TODO звук
        BlueExplosion(pos.x, pos.y, 350, 5, 55, 350, data.r - 5, data.r + 5, data.z);
      }
    }

    PlayPositionMarket("laser_load", 0.75 + (power / 100), 0.096, pos.x, pos.y, 0.04)
    BlueExplosion(pos.x, pos.y, 200, 1, 15, 100, data.r - 10, data.r + 10, data.z, null, {
      start: power / 100,
      end: 0,
      ease: 'Quad.easeIn'
    });
  }

  if (((weaponInfo.name === "explores_weapon_1" || weaponInfo.name === "big_laser" || weaponInfo.name === "far_god_1" || weaponInfo.name === "explores_weapon_6" || weaponInfo.name === "explores_weapon_5") && data.ap === 0) || weaponInfo.name === "laser_turret_weapon") {
    PlayPositionSound(['big_laser_1_fire', 'big_laser_2_fire', 'big_laser_3_fire'], null, pos.x, pos.y, false, 0.5, data.f);
    BlueExplosion(pos.x, pos.y, 250, 3, 40, 350, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name === "explores_weapon_3" || weaponInfo.name === "explores_weapon_3" || weaponInfo.name === "explores_weapon_4" || weaponInfo.name === "small_laser") {
    if (weaponInfo.name === "explores_weapon_3" || weaponInfo.name === "small_laser") {
      PlayPositionSound(['laser_1_fire_1', 'laser_1_fire_2', 'laser_1_fire_3'], null, pos.x, pos.y, false, 0.5, data.f);
    } else {
      PlayPositionSound(['laser_2_fire_1', 'laser_2_fire_2', 'laser_2_fire_3', 'laser_2_fire_4'], null, pos.x, pos.y, false, 0.5, data.f);
    }
    FireExplosion(pos.x, pos.y, 700, 3, 20, 10, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name === "replic_weapon_1" || weaponInfo.name === "artillery" || weaponInfo.name === "far_god_4") {
    PlayPositionSound(['firearms_6', 'firearms_4'], null, pos.x, pos.y, false, 0.5, data.f);
    SmokeExplosion(pos.x, pos.y, 1000, 7, 35, 30, data.r - 15, data.r + 15, data.z);
    FireExplosion(pos.x, pos.y, 200, 2, 40, 300, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name === "replic_weapon_2" || weaponInfo.name === "drone_weapon_1" ||
    weaponInfo.name === "mini_turret_1_weapon" || weaponInfo.name === "far_god_3") {

    PlayPositionSound(['firearms_1', 'firearms_2'], null, pos.x, pos.y, false, 0.5, data.f);
    FireExplosion(pos.x, pos.y, 150, 2, 30, 15, data.r - 25, data.r + 25, data.z);
  }

  if (weaponInfo.name === "replic_weapon_3" || weaponInfo.name === "replic_weapon_4" || weaponInfo.name === "tank_gun") {
    if (weaponInfo.name === "replic_weapon_4") {
      PlayPositionSound(['firearms_5'], null, pos.x, pos.y, false, 0.5, data.f);
    } else {
      PlayPositionSound(['firearms_3'], null, pos.x, pos.y, false, 0.5, data.f);
    }

    SmokeExplosion(pos.x, pos.y, 500, 3, 30, 30, data.r - 15, data.r + 15, data.z);
    FireExplosion(pos.x, pos.y, 300, 3, 30, 300, data.r - 15, data.r + 15, data.z);
  }

  if (weaponInfo.name === 'reverses_weapon_1' || weaponInfo.name === 'reverses_weapon_2' ||
    weaponInfo.name === 'reverses_weapon_3' || weaponInfo.name === 'reverses_weapon_4' ||
    weaponInfo.name === 'big_missile' || weaponInfo.name === 'small_missile' ||
    weaponInfo.name === "far_god_2") {
    PlayPositionSound(['missile_1', 'missile_2', 'missile_3'], null, pos.x, pos.y, false, 0.5, data.f);
    SmokeExplosion(pos.x, pos.y, 500, 3, 20, 100, data.r - 5, data.r + 5, data.z);
    SmokeExplosion(pos.x, pos.y, 500, 3, 10, 75, data.r - 185, data.r - 185, data.z);
  }

  if (weaponInfo.name === 'reverses_weapon_5') {
    PlayPositionSound(['missile_1', 'missile_2', 'missile_3'], null, pos.x, pos.y, false, 0.5, data.f);
    SmokeExplosion(pos.x, pos.y, 700, 3, 10, 50, data.r - 5, data.r + 5, data.z);
    SmokeExplosion(pos.x, pos.y, 700, 3, 5, 37, data.r - 185, data.r - 185, data.z);
  }

  if (weaponInfo.name.includes('replic_gauss_gun_weapon')) {
    PlayPositionSound(['structure_4', 'structure_5'], {seek: 1}, pos.x, pos.y, false, 0.5, data.f);
    BlueExplosion(pos.x, pos.y, 450, 25, 20, 550, data.r - 5, data.r + 5, data.z);
    SmokeExplosion(pos.x, pos.y, 1500, 25, 20, 200, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name.includes('tank_turret_weapon')) {
    PlayPositionSound(['firearms_3'], null, pos.x, pos.y, false, 0.5, data.f);
    SmokeExplosion(pos.x, pos.y, 500, 25, 20, 15, data.r - 65, data.r + 65, data.z);
    FireExplosion(pos.x, pos.y, 200, 25, 20, 100, data.r - 35, data.r + 35, data.z);
  }

  if (weaponInfo.name.includes('artillery_turret_weapon')) {
    PlayPositionSound(['firearms_6', 'firearms_4'], null, pos.x, pos.y, false, 0.5, data.f);
    SmokeExplosion(pos.x, pos.y, 500, 25, 20, 15, data.r - 65, data.r + 65, data.z);
    FireExplosion(pos.x, pos.y, 200, 25, 20, 100, data.r - 35, data.r + 35, data.z);
  }

  if (weaponInfo.name === "fauna_drone_weapon_1") {
    // todo звук плевка
    GreenExplosionSCREEN(pos.x, pos.y, 500, 5, 25, 100, data.r - 35, data.r + 35, data.z);
  }

  if (weaponInfo.name === "explores_weapon_3") {
  }

  if (weaponInfo.name === "fake_combine_weapon") {
    SmokeExplosion(pos.x, pos.y, 256, 3, 3, 25, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name === "fake_mining_weapon") {
    BlueExplosion(pos.x, pos.y, 250, 3, 5, 50, data.r - 5, data.r + 5, data.z);
  }

  if (weaponInfo.name === "fake_build_weapon") {
    WhiteExplosion(pos.x, pos.y, 128, 3, 5, 50, data.r - 5, data.r + 5, data.z);
  }
}

export {FireWeapon}
