import {gameStore} from "../store";
import {GetMapLvl, GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {ShortDirectionRotateTween} from "../utils/utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";

function AddDroneMoveBufferData(data) {
  let sprite = gameStore.drones[data.id];
  if (sprite) {
    AddMoveBufferData(data, sprite)
  }
}

function DroneTo(drone, ms) {

  if (!drone || !drone.sprite) return;

  let data = drone.bufferMoveTick.shift();
  if (!data) {
    return;
  }

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  setDroneSpeed(drone, pos, data)

  let size = GetSpriteSizeByMapLvl(data.z, GetSpriteSizeByMapLvl(GetMapLvl(pos.x, pos.y, gameStore.map.id), drone.scale / 100, 0.02).x, 0.02);
  MoveSprite(drone.sprite, pos.x, pos.y, ms, size.x);

  let shadowPos = GetOffsetShadowByMapLvl(data.z, pos.x, pos.y, 0.5, 2.5, gameStore.map.id);
  MoveSprite(drone.sprite.shadow, shadowPos.x, shadowPos.y, ms, size.x);

  if (data.z !== drone.sprite.depth) {
    drone.sprite.setDepth(902);
    drone.sprite.shadow.setDepth(901);
  }

  ShortDirectionRotateTween(drone.sprite, data.r, ms);
  ShortDirectionRotateTween(drone.sprite.shadow, data.r, ms);
}

function setDroneSpeed(drone, pos, data) {
  let xVelocity = drone.sprite.x - pos.x;
  if (xVelocity < 0) xVelocity *= -1;
  let yVelocity = drone.sprite.y - pos.y;
  if (yVelocity < 0) yVelocity *= -1;

  drone.speed = Math.sqrt(xVelocity * xVelocity + yVelocity * yVelocity)
  drone.angularVelocity = drone.sprite.angle - data.r;
  if ((drone.angularVelocity < 0 && drone.angularVelocity < -357) || (drone.angularVelocity > 0 && drone.angularVelocity < 3)) {
    drone.angularVelocity = 0
  }
}

export {DroneTo, AddDroneMoveBufferData}
