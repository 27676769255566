<template>
  <div>
    <table
      v-if="type === 'resource' || type === 'recycle' || type === 'detail' || type === 'trash' || type === 'product'">
      <tr>
        <td>{{ texts['ch_1'][language] }}</td>
        <td>{{ (item.size / 1000).toFixed(3) }} {{ texts['m3'][language] }}</td>
      </tr>
    </table>

    <table v-if="type === 'fuel'">
      <tr>
        <td>{{ texts['ch_167'][language] }}</td>
        <td>{{ (item.energy_cap / 500).toFixed(2) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_1'][language] }}</td>
        <td>{{ (item.size / 1000).toFixed(3) }} {{ texts['m3'][language] }}</td>
      </tr>
    </table>

    <table v-if="type === 'boxes'">
      <tr>
        <td>{{ texts['ch_2'][language] }}</td>
        <td>{{ (item.capacity_size / 1000).toFixed(2) }} {{ texts['m3'][language] }}</td>
      </tr>

      <tr>
        <td>{{ texts['ch_3'][language] }}</td>
        <td>{{ item.hp }}</td>
      </tr>

      <tr>
        <td>{{ texts['ch_4'][language] }}</td>
        <td>{{ item.fold_size }} {{ texts['m3'][language] }}</td>
      </tr>

      <tr v-if="item.protect">
        <td colspan="2">{{ texts['ch_5'][language] }}</td>
      </tr>

      <tr v-if="item.underground">
        <td colspan="2">{{ texts['ch_6'][language] }}</td>
      </tr>
    </table>

    <table v-if="type === 'body'">
      <tr>
        <td>{{ texts['ch_7'][language] }}</td>
        <td>{{ getStandardSizeName(item.standard_size) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_8'][language] }}</td>
        <td>{{ (item.capacity_size / 1000).toFixed(2) }} {{ texts['m3'][language] }}</td>
      </tr>
      <template v-if="Object.keys(item.additional_inventory).length > 0">
        <tr>
          <td colspan="2" style="text-align: left">
            {{ texts['ch_9'][language] }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <table>
              <tr>
                <td style="color: white; text-align: center">{{ texts['ch_10'][language] }}</td>
                <td style="color: white; text-align: left">{{ texts['ch_11'][language] }}</td>
              </tr>
              <tr v-for="aInv in item.additional_inventory">
                <td style="color: white; text-align: center">{{ (aInv.capacity_size / 1000).toFixed(2) }} м^3</td>
                <td>
                  <template v-for="(ids, type) in aInv.filter">
                    <div class="filter_item" v-for="id in ids">
                      <app-background-item-cell
                        v-bind:slotItem="{item: getItemByTypeAndID(type, id), type: type, id: id}"
                        v-bind:noShowName="true"/>
                    </div>
                  </template>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
      <tr>
        <td colspan="2" style="text-align: left">
          {{ texts['ch_12'][language] }}
        </td>
      </tr>
      <tr>
        <!--        15 это округленная доля от секунды которую состовляет серсвер тик, смотри пакет на бекенде _const.ServerTickSecPart-->
        <td>{{ texts['ch_13'][language] }}</td>
        <td>{{ Math.round(item.attributes.max_speed / 60) }} {{ texts['km'][language] }}</td>
      </tr>
      <tr>
        <!--        15 это округленная доля от секунды которую состовляет серсвер тик, смотри пакет на бекенде _const.ServerTickSecPart-->
        <td>Разгон</td>
        <td>+{{ Math.round((item.attributes.max_speed * ((1000 / item.attributes.time_racing) * 100) / 100) / 60) }}
          {{ texts['km'][language] }}
        </td>
      </tr>
      <tr>
        <!--        15 это округленная доля от секунды которую состовляет серсвер тик, смотри пакет на бекенде _const.ServerTickSecPart-->
        <td>{{ texts['ch_14'][language] }}</td>
        <td>{{ Math.round(item.attributes.turn_speed) }} {{ texts['deg'][language] }}</td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left">
          {{ texts['ch_15'][language] }}
        </td>
      </tr>
      <tr>
        <td>{{ texts['ch_16'][language] }}</td>
        <td>{{ item.range_view }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_17'][language] }}</td>
        <td>{{ item.range_radar }}</td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left">
          {{ texts['ch_18'][language] }}
        </td>
      </tr>
      <tr>
        <td>{{ texts['ch_19'][language] }}</td>
        <td>{{ item.max_power }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_20'][language] }}</td>
        <td>{{ Math.round((1000 / item.recovery_power_cycle) * item.recovery_power) }}
          {{ texts['insec'][language] }}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left;">
          {{ texts['ch_21'][language] }}
        </td>
      </tr>
      <tr>
        <td>{{ texts['ch_22'][language] }}</td>
        <td>{{ item.max_hp }}</td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left;">
          {{ texts['ch_23'][language] }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="vul">
            <div :style="{background: '#666666', width: item.protection_to_kinetics + '%'}"></div>
            <span>{{ item.protection_to_kinetics }} %</span>
          </div>
          <div class="vul">
            <div :style="{background: '#de5011', width: item.protection_to_thermo + '%'}"></div>
            <span>{{ item.protection_to_thermo }} %</span>
          </div>
          <div class="vul">
            <div :style="{background: '#f8ff0d', width: item.protection_to_explosion + '%'} "></div>
            <span>{{ item.protection_to_explosion }} %</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ texts['ch_164'][language] }}</td>
        <td>{{ item.max_shield_hp > 0 ? item.max_shield_hp : texts['ch_166'][language] }}</td>
      </tr>
      <tr v-if="item.max_shield_hp > 0">
        <td colspan="2">
          <div class="vul">
            <div :style="{background: '#666666', width: item.shield_protection_to_kinetics + '%'}"></div>
            <span>{{ item.shield_protection_to_kinetics }} %</span>
          </div>
          <div class="vul">
            <div :style="{background: '#de5011', width: item.shield_protection_to_thermo + '%'}"></div>
            <span>{{ item.shield_protection_to_thermo }} %</span>
          </div>
          <div class="vul">
            <div :style="{background: '#f8ff0d', width: item.shield_protection_to_explosion + '%'} "></div>
            <span>{{ item.shield_protection_to_explosion }} %</span>
          </div>
        </td>
      </tr>
    </table>

    <h3 class="body_damage_zone_head" v-if="type === 'body'" style="margin-top: 5px">{{ texts['ch_24'][language] }}</h3>
    <div class="body_damage_zone_wrapper" v-if="type === 'body'">
      <canvas class="body_damage_zone" height="200" width="200" ref="body_damage_zone"></canvas>

      <div class="body_damage_zone_body_icon">
        <app-background-item-cell v-bind:slotItem="{item: item, type: type, id: item.id}" v-bind:noShowName="true"/>
      </div>

      <div class="damage_zone_label" v-for="z in zones"
           :style="{left: (z.x + 10) + 'px', top: (z.y) + 'px',
                 backgroundImage: z.damage_k > 1 ? 'url(' + require('../../assets/icons/target.png').default + ')':'url(' + require('../../assets/icons/shield_2.png').default + ')'}">
        <div class="damage_zone_label_text">{{ z.damage_k }}</div>
      </div>
    </div>

    <table v-if="type === 'ammo'">
      <tr>
        <td>{{ texts['ch_25'][language] }}</td>
        <td>{{ getStandardSizeName(item.standard_size) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_26'][language] }}</td>
        <td>{{ getWeaponTypeName(item.type) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_27'][language] }}</td>
        <td>{{ item.min_damage }} - {{ item.max_damage }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_28'][language] }}</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="type_damage_section" style="color: lightgrey">
            <div class="type_damage_icon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_kinetics.png').default}"></div>
            {{ item.kinetics_damage + '%' }}
          </div>
          <div class="type_damage_section" style="color: rgb(255 156 2)">
            <div class="type_damage_icon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_thermo.png').default}"></div>
            {{ item.thermo_damage + '%' }}
          </div>
          <div class="type_damage_section" style="border-right: 0; color: rgb(248, 255, 13)">
            <div class="type_damage_icon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_explosion.png').default}"></div>
            {{ item.explosion_damage + '%' }}
          </div>
        </td>
      </tr>
      <tr>
        <td>{{ texts['ch_29'][language] }}</td>
        <td>{{ item.area_covers }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_30'][language] }}</td>
        <td>{{ item.bullet_speed }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_31'][language] }}</td>
        <td>{{ (item.size / 1000).toFixed(3) }} {{ texts['m3'][language] }}</td>
      </tr>

      <tr v-if="item.chase_target">
        <td>{{ texts['ch_32'][language] }}</td>
        <td v-if="item.chase_option==='always_to_target'">
          {{ texts['ch_33'][language] }}
        </td>
        <td v-if="item.chase_option==='always_to_mouse_target'">
          {{ texts['ch_34'][language] }}
        </td>
        <td v-if="item.chase_option==='distance_chase'">
          {{ texts['ch_35'][language] }}
        </td>
      </tr>
    </table>

    <table v-if="type === 'equip'">
      <template v-if="item.applicable === 'passive'">
        <tr v-for="effect in item.effects">
          <td colspan="2" style="color: white; text-align: left;"
              v-if="getBonusDescription(effect) !== ''">
            <div class="passive_effect"
                 :class="{reduced: effect.n.includes('reduced')}"
                 v-html="getBonusDescription(effect)"></div>
          </td>
        </tr>
      </template>

      <template v-if="item.applicable === 'energy_shield'">
        <tr>
          <td>{{ texts['ch_36'][language] }}</td>
          <td>{{ item.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_37'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_38'][language] }}</td>
          <td>{{ Math.round(item.recovery * (1000 / item.recovery_cycle)) }} {{ texts['unitsec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_39'][language] }}</td>
          <td>{{ Math.round((item.use_power * (1000 / item.recovery_cycle) * 2)) }} {{
              texts['unitsec'][language]
            }}
          </td>
        </tr>
        <tr>
          <td>{{ texts['ch_40'][language] }}</td>
          <td>{{ Math.round(item.use_power * (1000 / item.recovery_cycle)) }} {{ texts['unitsec'][language] }}</td>
        </tr>
        <!--        <tr>-->
        <!--          <td>Время такта</td>-->
        <!--          <td>{{ item.recovery_cycle / 1000 }} сек.</td>-->
        <!--        </tr>-->
        <tr>
          <td>{{ texts['ch_41'][language] }}</td>
          <td>{{ item.reload }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'hide_radar'">
        <tr>
          <td>{{ texts['ch_42'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_43'][language] }}</td>
          <td>{{ Math.round(item.use_power * (1000 / item.recovery_cycle)) }} {{ texts['unitsec'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'missile_defense'">
        <tr>
          <td>{{ texts['ch_44'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_45'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_46'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'repair'">
        <tr>
          <td>{{ texts['ch_47'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_48'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_49'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_50'][language] }}</td>
          <td>{{ item.count }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'ore'">
        <tr>
          <td>{{ texts['ch_51'][language] }}</td>
          <td>{{ item.region }} {{ texts['m3'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_52'][language] }}</td>
          <td>{{ ((item.count / 100) * (1000 / item.reload)).toFixed(1) }} {{ texts['percentsec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_53'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_54'][language] }}</td>
          <td>{{ Math.round((item.use_power) * (1000 / item.reload)) }} {{ texts['unitsec'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'pump_extracted'">
        <tr>
          <td>{{ texts['ch_55'][language] }}</td>
          <td>{{ ((item.count / 100) * (1000 / item.reload) * 10).toFixed(1) }} {{ texts['percentsec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_56'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_57'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_58'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'digger'">
        <tr>
          <td>{{ texts['ch_59'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_60'][language] }}</td>
          <td>{{ ammo.AreaCovers }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_61'][language] }}</td>
          <td>{{ item.reload }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_62'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'dismantling'">
        <tr>
          <td>{{ texts['ch_63'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_64'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_65'][language] }}</td>
          <td>{{ Math.round(item.use_power * (1000 / item.reload)) }} {{ texts['unitsec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_66'][language] }}</td>
          <td>{{ item.count }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'generator'">
        <tr>
          <td>{{ texts['ch_67'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_68'][language] }}</td>
          <td>{{ item.count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_69'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'geo_scan'">
        <tr>
          <td>{{ texts['ch_70'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_71'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_72'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'mine_bomb'">
        <tr>
          <td>{{ texts['ch_73'][language] }}</td>
          <td>{{ Math.round(ammo.min_damage) }} - {{ Math.round(ammo.max_damage) }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_74'][language] }}</td>
          <td>{{ ammo.area_covers }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_75'][language] }}</td>
          <td>{{ (ammo.detonation_start_time_out / 1000).toFixed(1) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_76'][language] }}</td>
          <td>{{ (ammo.detonation_time_out / 1000).toFixed(1) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_77'][language] }}</td>
          <td>{{ ammo.detonation_distance }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_78'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_79'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_80'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'mini_turret'">
        <tr>
          <td>{{ texts['ch_81'][language] }}</td>
          <td>{{ object.range_view }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_82'][language] }}</td>
          <td>{{ object.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_83'][language] }}</td>
          <td>{{ (item.life_time / 1000) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_84'][language] }}</td>
          <td>100</td>
        </tr>
        <tr>
          <td>{{ texts['ch_85'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_86'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_87'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'smoke_screen_local'">
        <tr>
          <td>{{ texts['ch_88'][language] }}</td>
          <td>100</td>
        </tr>
        <tr>
          <td>{{ texts['ch_89'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_90'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_91'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'smoke_screen_destination'">
        <tr>
          <td>{{ texts['ch_92'][language] }}</td>
          <td>100</td>
        </tr>
        <tr>
          <td>{{ texts['ch_93'][language] }}</td>
          <td>300</td>
        </tr>
        <tr>
          <td>{{ texts['ch_94'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_95'][language] }}</td>
          <td>3</td>
        </tr>
        <tr>
          <td>{{ texts['ch_96'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_97'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'drone_scout'">
        <tr>
          <td>{{ texts['ch_98'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_99'][language] }}</td>
          <td>{{ (item.life_time / 1000) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_100'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_101'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'drone_defender'">
        <tr>
          <td>{{ texts['ch_102'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_103'][language] }}</td>
          <td>{{ (item.life_time / 1000) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_104'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_105'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'rope_trap'">
        <tr>
          <td>{{ texts['ch_106'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_107'][language] }}</td>
          <td>{{ object.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_108'][language] }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ texts['ch_109'][language] }}</td>
          <td>{{ (item.life_time / 1000) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_110'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_111'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_112'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'grenade'">
        <tr>
          <td>{{ texts['ch_113'][language] }}</td>
          <td>300</td>
        </tr>
        <tr>
          <td>{{ texts['ch_114'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_115'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_116'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'gravity_grenade'">
        <tr>
          <td>{{ texts['ch_117'][language] }}</td>
          <td>300</td>
        </tr>
        <tr>
          <td>{{ texts['ch_118'][language] }}</td>
          <td>{{ item.count_1 }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_119'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_120'][language] }}</td>
          <td>{{
              ((item.attributes.obj_max_power / (item.attributes.use_structure_power / (1000 / 32))) / 1000).toFixed(1)
            }} {{ texts['s'][language] }}
          </td>
        </tr>
        <tr>
          <td>{{ texts['ch_121'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_122'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_123'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'jump_drive'">
        <tr>
          <td>{{ texts['ch_124'][language] }}</td>
          <td>{{ item.attributes.power / 100 }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_125'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_126'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'invisibility'">
        <tr>
          <td>{{ texts['ch_127'][language] }}</td>
          <td>{{ Math.round(item.attributes.activation_time / 1000) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_128'][language] }}</td>
          <td>{{ Math.round(item.use_power * (1000 / 32)) }} {{ texts['insec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_129'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'wall'">
        <tr>
          <td>{{ texts['ch_130'][language] }}</td>
          <td>{{ object.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_131'][language] }}</td>
          <td>100</td>
        </tr>
        <tr>
          <td>{{ texts['ch_132'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_133'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_134'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'energy_shield_mini_structure'">
        <tr>
          <td>{{ texts['ch_135'][language] }}</td>
          <td>{{ item.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_136'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_137'][language] }}</td>
          <td>{{
              ((object.max_energy / (item.attributes.use_structure_power / item.recovery_cycle)) / 1000).toFixed(1)
            }} {{ texts['s'][language] }}
          </td>
        </tr>
        <tr>
          <td>{{ texts['ch_138'][language] }}</td>
          <td>{{ Math.round(item.recovery * (1000 / item.recovery_cycle)) }} {{ texts['insec'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_139'][language] }}</td>
          <td>{{ (item.attributes.shield_reload / 1000).toFixed(1) }} {{ texts['s'][language] }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_140'][language] }}</td>
          <td>{{ object.max_hp }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_141'][language] }}</td>
          <td>300</td>
        </tr>
        <tr>
          <td>{{ texts['ch_142'][language] }}</td>
          <td>{{ item.max_ammo_count }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_143'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_144'][language] }}</td>
          <td>{{ item.reload / 1000 }} {{ texts['s'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.applicable === 'inventory_scanner'">
        <tr>
          <td>{{ texts['ch_145'][language] }}</td>
          <td>{{ item.radius }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_146'][language] }}</td>
          <td>{{ item.use_power }}</td>
        </tr>
      </template>

      <tr v-if="item.power > 0">
        <td>{{ texts['ch_147'][language] }}</td>
        <td>{{ item.power }}</td>
      </tr>

      <tr>
        <td>{{ texts['ch_148'][language] }}</td>
        <td>{{ (item.size / 1000).toFixed(3) }} {{ texts['m3'][language] }}</td>
      </tr>
    </table>

    <table v-if="type === 'weapon'">
      <tr>
        <td>{{ texts['ch_149'][language] }}</td>
        <td>{{ getStandardSizeName(item.standard_size) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_150'][language] }}</td>
        <td>{{ getWeaponTypeName(item.type) }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_151'][language] }}</td>
        <td>{{ item.rotate_speed }} {{ texts['deg'][language] }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_152'][language] }}</td>
        <td>x {{ item.damage_modifier }}</td>
      </tr>

      <template v-if="item.type === 'firearms'">
        <tr>
          <td>{{ texts['ch_153'][language] }}</td>
          <td>{{ item.min_angle }} - {{ item.max_angle }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_154'][language] }}</td>
          <td>{{ item.bullet_speed }}</td>
        </tr>
        <tr>
          <td>{{ texts['ch_155'][language] }}</td>
          <td>{{ texts['ch_156'][language] }}</td>
        </tr>
      </template>

      <template v-if="item.type !== 'firearms'">
        <tr>
          <td>{{ texts['ch_155'][language] }}</td>
          <td>{{ item.max_range }}</td>
        </tr>
      </template>

      <tr>
        <td>{{ texts['ch_157'][language] }}</td>
        <td>{{ item.count_fire_bullet }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_158'][language] }}</td>
        <td>{{ Math.round(60000 / item.reload_time) }} {{ texts['rate_fire'][language] }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_159'][language] }}</td>
        <td>{{ item.ammo_capacity }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_160'][language] }}</td>
        <td>{{ item.reload_ammo_time / 1000 }} {{ texts['s'][language] }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_161'][language] }}</td>
        <td>{{ item.power }}</td>
      </tr>
      <tr>
        <td>{{ texts['ch_162'][language] }}</td>
        <td>{{ (item.size / 1000).toFixed(3) }} {{ texts['m3'][language] }}</td>
      </tr>

      <tr v-if="item.artillery">
        <td colspan="2">{{ texts['ch_163'][language] }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import {GetItemByTypeAndID} from "../../game/utils/get_item_by_type_and_id";

export default {
  name: "CharacteristicTab",
  props: ['type', 'item', 'ammo', 'weapon', 'object'],
  methods: {
    getItemByTypeAndID(type, id) {
      return GetItemByTypeAndID(type, id)
    },
    getBonusDescription(e) {
      return this.handbook.effects[this.language][e.n].description
    },
    getStandardSizeName(size) {
      if (size === 1) return this.texts['small'][this.language];
      if (size === 2) return this.texts['average'][this.language];
      if (size === 3) return this.texts['large'][this.language];
    },
    getWeaponTypeName(type) {
      return this.texts[type][this.language];
    },
    drawDamageZone() {

      let draw = function (canvas, zones) {
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.lineWidth = 2;
        for (let z of zones) {

          if (z.damage_k < 1) {
            ctx.fillStyle = "rgba(128,128,128,0.6)";
            ctx.strokeStyle = 'rgba(128, 128, 128, 0.9)';
          } else {
            ctx.fillStyle = "rgba(255,0,0,0.3)";
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.6)';
          }

          ctx.beginPath();
          ctx.moveTo(100, 100);
          ctx.arc(100, 100, 99, z.start_angle * (Math.PI / 180), z.end_angle * (Math.PI / 180));
          ctx.lineTo(100, 100);
          ctx.fill();
          ctx.stroke();
        }
      }

      let wait = setInterval(function () {
        let canvas = this.$refs['body_damage_zone'];
        if (canvas) {
          clearInterval(wait)
          draw(canvas, this.zones)
        }
      }.bind(this), 100)
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
    zones() {
      let zones = [];
      let body = this.$props.item;
      if (body && body.damage_zones && body.damage_zones.length > 0) {
        let boundaryZone360 = null;
        let boundaryZone0 = null

        for (let z of body.damage_zones) {
          // todo костыль
          if (z.end_angle === 360 || z.start_angle === 0) {
            if (z.end_angle === 360) boundaryZone360 = z;
            if (z.start_angle === 0) boundaryZone0 = z;
          } else {
            zones.push(z)
          }
        }

        if (boundaryZone360 && boundaryZone0 && boundaryZone360.damage_k === boundaryZone0.damage_k) {
          zones.push({
            start_angle: boundaryZone360.start_angle,
            end_angle: boundaryZone0.end_angle,
            damage_k: boundaryZone360.damage_k
          })
        } else {
          if (boundaryZone360) zones.push(boundaryZone360);
          if (boundaryZone0) zones.push(boundaryZone0);
        }
      }

      let ConstructorBackGroundSize = 283;
      let dist = 110;

      for (let z of zones) {
        if (z.start_angle > z.end_angle) z.end_angle += 360;
        z.angle = z.start_angle + ((z.end_angle - z.start_angle) / 2);
        z.x = (dist * Math.cos(z.angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2);
        z.y = (dist * Math.sin(z.angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2);
      }

      this.drawDamageZone()
      return zones
    },
  }
}
</script>

<style scoped>
.detailItemInfoBody table {
  font-size: 11px;
  width: 100%;
  border-collapse: collapse;
}

.detailItemInfoBody tr {
  transition: 200ms;
}

.detailItemInfoBody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detailItemInfoBody tr td:last-child {
  color: #ffef0f;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  text-align: right;
}

.vul {
  width: calc(33% - 3px);
  height: 20px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  margin-left: 3px;
  box-shadow: inset 0 0 2px black;
  position: relative;
}

.vul div {
  height: 100%;
  box-shadow: inset 0 0 2px 1px black;
}

.vul span {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  color: white;
}

.detailItemInfoBody tr:hover {
  background: rgba(179, 179, 179, 0.5);
}

.body_damage_zone_wrapper {
  height: 280px;
  width: 280px;
  position: relative;
  border-radius: 5px;
  background-image: linear-gradient(0deg, rgba(7, 41, 60, 0.6) 50%, rgba(0, 62, 95, 0.5) 30%);
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-size: 100% 2px;
  left: calc(50% - 140px);
}

.body_damage_zone {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.damage_zone_label, .damage_zone_label_text {
  position: absolute;
  height: 20px;
  width: 20px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  border-radius: 5px;
  background-size: contain;
  text-align: center;
}

.damage_zone_label_text {
  color: white;
  left: calc(-50% + 2px);
  top: calc(100% - 2px);
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  width: 34px;
}

.body_damage_zone_body_icon {
  height: 128px;
  width: 128px;
  position: absolute;
  opacity: 0.4;
  left: calc(50% - 64px);
  top: calc(50% - 64px);
}

.body_damage_zone_head {
  margin: 0 0 3px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
  text-align: left;
  clear: both;
}

.filter_item {
  position: relative;
  height: 20px;
  width: 20px;
  float: left;
}

.type_damage_section {
  width: calc(33% - 6px);
  height: 15px;
  float: left;
  margin-left: 3px;
  position: relative;
  line-height: 18px;
  text-align: left;
  border-right: 1px solid black;
  font-weight: bold;
}

.type_damage_icon {
  height: 15px;
  width: 15px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  box-shadow: inset 0 0 2px black;
  margin-right: 10px;
  margin-left: 2px;
}

.passive_effect {
  width: calc(100% - 13px);
  float: left;
  font-size: 12px;
  padding: 5px;
  clear: both;
  border: 1px solid transparent;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 3px;
  margin: 3px 1px;
  color: white;
  box-shadow: 0 0 2px 1px black;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.passive_effect.reduced {
  background: rgba(255, 0, 0, 0.1)
}
</style>
