<template>
  <div class="relations">
    <template v-if="tab === ''">
      <h4 class="head">{{ texts['head_1'][language] }}</h4>

      <div class="fraction_row" v-if="relations && ['Explores', 'Replics', 'Reverses'].includes(fraction)"
           v-for="(points, fraction) in relations">

        <div class="history_button cursor_hover" @click="setTab('history', fraction)">
          {{ texts['button_1'][language] }}
        </div>
        <div class="history_button cursor_hover" style="right: 65px" @click="setTab('bonuses', fraction)">
          {{ texts['button_2'][language] }}
        </div>

        <div class="fraction_logo"
             :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(fraction)).default+')'}"></div>
        <div class="fraction_bar_wrapper">
          <div class="fraction_name">
            {{ fraction }} - <span class="fraction_status">{{ getRelationName(points) }}</span>
          </div>
          <div class="fraction_out_bar">
            <div class="fraction_in_bar">
              <div :style="{width: (((points + 200)/400) * 100) + '%'}"></div>
            </div>
            <div class="fraction_bar_center"></div>
          </div>
          <div class="fraction_bar_metric">
            <div style="float: left">-10</div>
            <div style="float: left; width: calc(100% - 40px); text-align: center;">{{ getPoints(points) }}</div>
            <div style="float: right">+10</div>
          </div>
        </div>
      </div>

      <h4 class="head">{{ texts['head_2'][language] }}</h4>
      <div class="fraction_row" v-if="relations && fraction === 'APD'" v-for="(points, fraction) in relations">
        <div class="fraction_logo"
             :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(fraction)).default+')'}"></div>
        <div class="fraction_bar_wrapper">
          <div class="fraction_name">
            {{ fraction }} - <span class="fraction_status">{{ getRelationName(points) }}</span>
          </div>
          <div class="fraction_out_bar">
            <div class="fraction_in_bar">
              <div :style="{width: (((points + 200)/400) * 100) + '%'}"></div>
            </div>
            <div class="fraction_bar_center"></div>
          </div>
          <div class="fraction_bar_metric">
            <div style="float: left">-10</div>
            <div style="float: left; width: calc(100% - 40px); text-align: center;">{{ getPoints(points) }}</div>
            <div style="float: right">+10</div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="tab === 'history'">
      <div class="fraction_logo" style="float: left; height: 30px; width: 30px;"
           :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(fraction)).default+')'}"></div>

      <h4 class="head" style="
      position: relative;
      width: calc(100% - 60px);
      float: right;
      margin-top: 0;">
        {{ fraction }}
        <div class="history_button cursor_hover" @click="tab=''">{{ texts['button_3'][language] }}</div>
      </h4>

      <div class="history_table_wrapper">
        <table class="history_table">
          <tr>
            <th>{{ texts['head_3'][language] }}</th>
            <th>{{ texts['head_4'][language] }}</th>
          </tr>
          <template v-for="(logRows, fr) in log">
            <tr v-for="row in logRows" v-if="fr === fraction">
              <td>{{ getEventName(row.event) }}</td>
              <td v-html="getDiff(row)"></td>
            </tr>
          </template>
        </table>
      </div>

    </template>

    <template v-if="tab === 'bonuses'">
      <div class="fraction_logo" style="float: left; height: 30px; width: 30px;"
           :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(fraction)).default+')'}"></div>

      <h4 class="head" style="
      position: relative;
      width: calc(100% - 60px);
      float: right;
      margin-top: 0;">
        {{ fraction }}
        <div class="history_button cursor_hover" @click="tab=''">{{ texts['button_3'][language] }}</div>
      </h4>

      <div class="history_table_wrapper" v-if="relationBonuses && relationBonuses[fraction]">
        <table class="history_table">
          <tr style="box-shadow: none">
            <td>{{ texts['b_1'][language] }}</td>
            <td
              v-html="relationBonuses[fraction].fraction_drone_view ? '<span style=\'color: #adff2f; font-weight: bold\'>+</span>' : '<span style=\'color: red; font-weight: bold\'>-</span>'"></td>
          </tr>
          <tr>
            <td>{{ texts['b_2'][language] }}</td>
            <td>{{ relationBonuses[fraction].reduced_recycle_tax }} %</td>
          </tr>
          <tr>
            <td>{{ texts['b_3'][language] }}</td>
            <td>{{ relationBonuses[fraction].reduced_work_tax }} %</td>
          </tr>
          <tr>
            <td>{{ texts['b_4'][language] }}</td>
            <td>{{ relationBonuses[fraction].reduced_detail_work_tax }} %</td>
          </tr>
          <tr>
            <td>{{ texts['b_5'][language] }}</td>
            <td>{{ relationBonuses[fraction].reduced_market_tax }} %</td>
          </tr>
          <tr>
            <td>{{ texts['b_6'][language] }}</td>
            <td>{{ relationBonuses[fraction].additional_mission_credits }} %</td>
          </tr>
<!--          <tr>-->
<!--            <td>{{ texts['b_7'][language] }}</td>-->
<!--            <td-->
<!--              v-html="relationBonuses[fraction].locked_up_npc_trade ? '<span style=\'color: red; font-weight: bold\'>+</span>' : '<span style=\'color: #adff2f; font-weight: bold\'>-</span>'"></td>-->
<!--          </tr>-->
          <tr>
            <td>{{ texts['b_8'][language] }}</td>
            <td
              v-html="relationBonuses[fraction].locked_up_work ? '<span style=\'color: red; font-weight: bold\'>+</span>' : '<span style=\'color: #adff2f; font-weight: bold\'>-</span>'"></td>
          </tr>
          <tr>
            <td>{{ texts['b_9'][language] }}</td>
            <td
              v-html="relationBonuses[fraction].locked_up_recycle ? '<span style=\'color: red; font-weight: bold\'>+</span>' : '<span style=\'color: #adff2f; font-weight: bold\'>-</span>'"></td>
          </tr>
          <tr>
            <td>{{ texts['b_10'][language] }}</td>
            <td
              v-html="relationBonuses[fraction].fraction_hostile ? '<span style=\'color: red; font-weight: bold\'>+</span>' : '<span style=\'color: #adff2f; font-weight: bold\'>-</span>'"></td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import {urls} from "../../const";

export default {
  name: "Relations",
  props: ['user'],
  data() {
    return {
      relations: null,
      log: null,
      fraction: "",
      tab: "",
    }
  },
  mounted() {
    this.$parent.resize(null, null, $(this.$parent.$el))
    this.getRelations()
  },
  methods: {
    getRelations() {
      let app = this;
      app.$api.get(urls.userRelationsURL + '?user_id=' + app.$props.user.id).then(function (response) {
        app.$set(app, 'relations', response.data.points);

        for (let i in response.data.log) {
          response.data.log[i] = response.data.log[i].reverse();
        }
        app.$set(app, 'log', response.data.log);
      });
    },
    getEventName(e) {
      if (e === 'trade') return this.texts['chane_event_1'][this.language];
      if (e === 'recycler') return this.texts['chane_event_2'][this.language];
      if (e === 'mission_complete') return this.texts['chane_event_3'][this.language];
      if (e === 'mission_fail') return this.texts['chane_event_4'][this.language];
      if (e === 'kill_pirate') return this.texts['chane_event_5'][this.language];
      if (e === 'kill_peaceful') return this.texts['chane_event_6'][this.language];
      if (e === 'capture_sector') return this.texts['chane_event_7'][this.language];
      return e
    },
    getDiff(row) {
      let v = (row.new_value - row.start_value) / 20
      if (v > 0) {
        return `<span style="color: yellowgreen"> + ${v.toFixed(3)} </span>`
      } else {
        return `<span style="color: red"> - ${-v.toFixed(3)} </span>`
      }
    },
    getPoints(points) {
      let p = points / 20
      if (p > 0) {
        return "+" + p.toFixed(2)
      }
      return p.toFixed(2)
    },
    getRelationName(points) {
      if (points <= -100) return this.texts['enmity'][this.language]
      if (points > -100 && points < 0) return this.texts['bad'][this.language]
      if (points === 0) return this.texts['neutral'][this.language]
      if (points > 0 && points < 100) return this.texts['good'][this.language]
      if (points > 100) return this.texts['excellent'][this.language]
    },
    getLogo(fraction) {
      if (['Explores', 'Replics', 'Reverses'].includes(fraction)) {
        return `logo/${fraction}.png`
      } else {
        return `logo/Free.png`
      }
    },
    setTab(tab, fraction) {
      this.tab = tab;
      this.fraction = fraction;
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('UserStat')
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    }
  }
}
</script>

<style scoped>
.relations {
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  margin-right: 3px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  width: calc(100% - 8px);
  padding: 4px;
  background: rgba(137, 150, 156, 0.3);
}

.head {
  margin: 7px;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fraction_row {
  padding: 4px 5px 4px 5px;
  background: rgba(0, 0, 0, 0.17);
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  position: relative;
  height: 40px;
}

.fraction_logo {
  background-color: rgba(8, 138, 210, 0.3);
  float: left;
  margin: -1px 5px 5px -1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
  height: 40px;
  width: 40px;
}

.fraction_bar_wrapper {
  width: calc(100% - 48px);
  float: left;
  position: relative;
}

.fraction_name {
  color: white;
  font-size: 12px;
  line-height: 15px;
  text-shadow: 1px 1px 1px black;
}

.fraction_out_bar {
  height: 14px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
}

.fraction_out_bar .fraction_in_bar {
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  background: rgb(145, 145, 145);
  float: left;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  overflow: hidden;
}

.fraction_out_bar > div > div {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 50%;
  box-shadow: inset 0 0 4px black;
  background: #f19506;
}

.fraction_status {
  color: yellow;
  font-size: 11px;
}

.fraction_bar_center {
  z-index: 999;
  height: 22px;
  width: 2px;
  background: #e84e4e;
  box-shadow: 0 0 2px black;
  position: absolute;
  border-radius: 100%;
  left: calc(50% - 2px);
  margin-top: -4px;
  float: left;
}

.fraction_bar_metric {
  color: white;
  margin-top: 3px;
  font-size: 10px;
  text-shadow: 1px 1px 1px black;
}

.history_button {
  display: block;
  width: 56px;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  right: 2px;
  top: 0;
  z-index: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-shadow: 1px 1px 1px black;
}

.history_button:hover {
  background: rgba(255, 129, 0, 1);
}

.history_button:active {
  transform: scale(0.98);
}

.history_table_wrapper {
  box-shadow: inset 0 0 2px black;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  height: calc(100% - 39px);
  width: 100%;
}

.history_table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
}

.history_table tr:first-child {
  box-shadow: 0 0 2px rgb(0 0 0);
}

.history_table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  padding-top: 4px;
  font-size: 13px;
  position: relative;
  text-indent: 5px;
  font-weight: bold;
}

.history_table tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 8px;
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 2;
}
</style>
