<template>
  <div class="content_section">
    <h2 class="head">Инвентарь и специальные ячейки строений</h2>

    <p>Некоторые структуры имеют внутри себя инвентарь и специальные ячейки для топлива и
      заданий.</p>

    <p>Некоторые структуры не могут работать без топлива поэтому им необходимо после установки устанавливать в
      ячейку топливо или что бы был рядом генератор с топливом или союзный транспорт с дистанционной передачей
      энергии.</p>

    <p>Для взаимодействия со структурой нужно быть ее владельцем и находясь рядом нажать на нее манипулятором.</p>

    <div class="content_section">
      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('open_object_1', 'gif')+ ')',
              width: '286px',
              height: '200px',
              marginLeft: 'calc(50% - 143px)',
            }"/>
      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('open_object_2', 'gif')+ ')',
              width: '286px',
              height: '200px',
              marginLeft: 'calc(50% - 143px)',
              marginTop: '10px',
            }"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialCell",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
