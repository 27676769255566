import {GetGlobalPos} from "../gep_global_pos";
import {gameStore} from "../../store";
import {Scene} from "../../create";
import {rotatePoint} from "../../utils/utils";

function createCorporationProcessing(state, base, scene) {
  let pos = GetGlobalPos(state.x, state.y, gameStore.map.id);

  let posTop1 = rotatePoint((86 * state.scale / 100), (73 * state.scale / 100), base.angle * -1);

  let object = scene.make.image({
    x: pos.x + posTop1.x,
    y: pos.y - posTop1.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top",
  });
  object.setOrigin(0.5);
  object.setScale(state.scale / 100);
  object.setDepth(state.height + 1);

  base.top = object;

  let object2 = scene.make.image({
    x: pos.x + posTop1.x,
    y: pos.y - posTop1.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top_2",
  });
  object2.setOrigin(0.5);
  object2.setScale(state.scale / 100);
  object2.setDepth(state.height + 2);

  let posTop2 = rotatePoint((75 * state.scale / 100), (25 * state.scale / 100), base.angle * -1);

  let particles = Scene.add.particles(
    pos.x - posTop2.x,
    pos.y + posTop2.y,
    'flares',
    {
      frame: 'smoke_puff',
      speed: {min: -5, max: 5},
      lifespan: {min: 6000, max: 12000},
      quantity: 7,
      frequency: 1000,
      scale: {start: 0, end: 2},
      angle: {min: -180, max: 180},
      alpha: {start: 0.4, end: 0},
      blendMode: 'SCREEN',
      moveToX: {min: 0, max: 0},
      moveToY: {min: 0, max: 0},
      emitZone: {type: 'random', source: new Phaser.Geom.Circle(0, 0, 5)}
    });
  particles.setDepth(state.height + 2);

  base.emitter = particles;

  base.top2 = object2;
}

function initTweenProcessing(sprite) {
  sprite.initTween = true;

  let speed = 5000
  let ease = 'Linear'

  setTimeout(function () {
    sprite.top.tween = Scene.tweens.add({
      targets: sprite.top,
      props: {
        angle: {value: '+=' + 360, duration: speed, ease: ease},
      },
      repeat: -1,
      loop: true
    });
  }, 1000)
}

export {createCorporationProcessing, initTweenProcessing}
