import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {MoveSprite} from "../utils/move_sprite";

let droneTarget = {
  sprite: null,
  timeOut: 128,
  callBack: null,
  tween: null,
};

function DroneTarget(data) {

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);

  if (!droneTarget.sprite) {
    droneTarget.sprite = Scene.make.image({
      x: pos.x,
      y: pos.y,
      key: "select_sprite",
      add: true
    });
    droneTarget.sprite.setDisplaySize(data.r, data.r)

    droneTarget.callBack = Scene.time.addEvent({
      delay: 32,
      callback: function () {
        droneTarget.timeOut -= 32
        if (droneTarget.timeOut <= 0) {
          droneTarget.sprite.destroy();
          droneTarget.callBack.remove();
          droneTarget.sprite = null;
        }
      },
      loop: true
    });
  }

  droneTarget.timeOut = 128
  MoveSprite(droneTarget.sprite, pos.x, pos.y, 64, null, true);
}

export {DroneTarget}
