let WS = null

self.onmessage = (e) => {
  if (e.data.event === 'create_ws') {
    WS = new WebSocket(e.data.url);
    readSocket()
  }

  if (!e.data.hasOwnProperty('event')) {
    WS.send(e.data)
  }
};

let count = 0
function readSocket() {
  WS.binaryType = "arraybuffer";
  WS.onopen = function () {
    console.log("socket opened..." + this.readyState);

    self.postMessage({
      msgType: 'system',
      data: {
        type: "", connect: true, error: false, reconnect: false, pending: false, uuid: "",
      }
    });
  };

  WS.onclose = function (i) {
    console.log("socket close: " + JSON.stringify(i));

    self.postMessage({
      msgType: 'system',
      data: {
        type: "", connect: false, error: true, pending: false, uuid: "",
      }
    });
  };

  WS.onmessage = function (msg) {
    if (msg.data instanceof ArrayBuffer) {
      self.postMessage({
        msgType: 'bin_game',
        data: new Uint8Array((msg.data)),
      });
    } else {
      self.postMessage({
        msgType: 'json_game',
        data: JSON.parse(msg.data),
      });
    }
  };
}
