<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h3 class="head" id="weapons">Оружие</h3>
      <p>Оружие состоит из 2х компонентов, непосредственно оружие и боеприпас которым оно будет стрелять. Без боеприпаса
        оружие стрелять не может.</p>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_1', 'png')+ ')',
        width: '480px',
        height: '252px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

      <p>Оружие определяет такие характеристики: </p>
      <ul>
        <li><span class="headSection">Тип оружия</span> - определяет тип оружия.</li>
        <li><span class="headSection">Скорость поворота</span> - скорость поворота оружия гр/сек</li>
        <li><span class="headSection">Модификатор урона</span> - множитель урона боеприпаса, (урон боеприпаса *
          множитель)
        </li>
        <li><span class="headSection">Доступные углы атаки</span> - доступные вертикальные углы атаки, для
          баллистического
          оружия это напрямую определяет на дальность полета и скорость боеприпаса. Для лазерного оружия это означает
          доступность или нет стрелять в низ или в вверх.
        </li>
        <li><span class="headSection">Влияние на скорость пули</span> - влияние на скорость полета боеприпаса. (скорость
          боеп. + влияние)
        </li>
        <li><span class="headSection">Дальность стрельбы</span> - для лазерного и ракетного оружия определяет дальность
          оружия, для огнестрельного дальность определяет за счет углов и скорости пули и летит до тех пор пока не
          упадет
          на землю, то есть на неровной поверхности дальность может быть разной.
        </li>
        <li><span class="headSection">Очередь</span> - кол-во пуль выпускаемых за 1 выстрел.</li>
        <li><span class="headSection">Скорострельность</span> - сколько выстрелов может совершить оружие, без учета
          перезарядки.
        </li>
        <li><span class="headSection">Боезапас</span> - макс. кол-во боеприпаса в магазине.</li>
        <li><span class="headSection">Время перезарядки</span> - время перезарядки магазина.</li>
        <li><span class="headSection">Потребляемая мощность</span> - сколько будет зарезервировано энергии в корпусе.
        </li>
        <li><span class="headSection">Занимаемый объем</span> - сколько места занимает 1 единица в трюме.</li>
      </ul>

      <p>Боеприпас определяет такие характеристики: </p>
      <ul>
        <li><span class="headSection">Тип атаки</span> - определяет вид урона который будет наносить боеприпас
          (кинетический, термо, взрывной)
        </li>
        <li><span class="headSection">Урон</span> - мин. и макс. базовый урон боеприпаса.</li>
        <li><span class="headSection">Радиус поражения</span> - дальность после разрыва боеприпаса от которого
          все получат урон, чем дальше от эпицентра взрыва тем меньше урон (на самой границе будет 1% урона, в самом
          центре
          100%).
        </li>
        <li><span class="headSection">Скорость полета пули</span> - скорость с которой летит пуля из ствола оружия.</li>
        <li><span class="headSection">Занимаемый объем</span> - сколько места занимает 1 еденица боеприпаса в трюме.
        </li>
      </ul>

      <br>
      <h3 class="head">Типы оружия</h3>
      <p>Оружие в игре делится на 3 основных типа:</p>

      <ul class="content_list" style="margin-top: 5px">
        <li><span class="headSection" style="font-size: 10px">Ракетное вооружение</span>

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('weapon_2', 'png')+ ')',
            width: '380px',
            height: '180px',
            marginLeft: 'calc(50% - 190px)',
            }"/>

          <p>Ракетное вооружение, отличается фиксированной скорость полета пули, высоким уроном и точностью (для
            некоторый ракет управляемостью и самонаведением).</p>
          <p>Ракетное вооружение имеет строго фиксированную дальность полёта, после чего, не достигнув цели -
            самоуничтожаются. Также, против ракетного вооружения себя отлично зарекомендует ПРО - уничтожающее
            реактивные снаряды ещё на подлёте. </p>
          <p>Громадным минусом ракетного вооружения является его заметность - все выпущенные ракеты, отмечаются
            радарными системами как быстро движущиеся объекты. </p>
        </li>

        <li><span class="headSection" style="font-size: 10px">Лазерное вооружение</span>

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('weapon_3', 'png')+ ')',
            width: '380px',
            height: '180px',
            marginLeft: 'calc(50% - 190px)',
            }"/>

          <p>Излучатели и проекторы, эти боевые энергетические установки отличаются мгновенной способностью поражения
            цели. </p>
          <p>Впрочем, лазерное оружие не способно вести огонь “навесом”, а дальность поражения менее доступная нежели у
            прочих типов вооружения.</p>
        </li>

        <li><span class="headSection" style="font-size: 10px">Огнестрельное вооружение</span>

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('weapon_4', 'png')+ ')',
            width: '380px',
            height: '200px',
            marginLeft: 'calc(50% - 190px)',
            }"/>

          <p>пулемёты, автопушки, стволы малой и большой артиллерии - классика и прямое наследие предтеч.</p>
          <p>Характеризуется баллистической траекторией полёта снаряда/пули, что позволяет даже поражать противника
            “навесом” (параболой), попадая в того через низкие препятствия. </p>
          <p>Боеприпасы огнестрельного оружия имеют начальную скорость полёта пули/снаряда. Это влияет на дальность
            полета
            пули. </p>
        </li>
      </ul>

      <br>
      <h3 class="head">Навыки и штраф: </h3>
      <p>Навык владения боеприпасом определяет по его размеру и типу. Если использовать боеприпас на который нет допуска
        по навыку то урон боеприпаса понижается.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_2', 'png')+ ')',
        width: '368px',
        height: '348px',
        marginLeft: 'calc(50% - 184px)',
    }"/>

      <p>Навык владения оружием определяет по его размеру и типу. Если использовать оружие на который нет допуска
        по навыку то падает скорость поворота оружия.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_3', 'png')+ ')',
        width: '368px',
        height: '372px',
        marginLeft: 'calc(50% - 184px)',
    }"/>
    </template>
    <template v-if="lang==='EN'">
      <h3 class="head" id="weapons">Weapons</h3>
      <p>Weapons consist of 2 components: the weapon itself and the ammunition it fires. Without ammunition, the weapon
        cannot fire.</p>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_1', 'png')+ ')',
      width: '480px',
      height: '252px',
      marginLeft: 'calc(50% - 240px)',
      }"/>

      <p>Weapons determine the following characteristics:</p>
      <ul>
        <li><span class="headSection">Type of weapon</span> - determines the type of weapon.</li>
        <li><span class="headSection">Rotation speed</span> - the rotation speed of the weapon in degrees/sec</li>
        <li><span class="headSection">Damage modifier</span> - the multiplier of the ammunition's damage (ammunition
          damage * multiplier)
        </li>
        <li><span class="headSection">Available angles of attack</span> - available vertical angles of attack, for
          ballistic weapons this directly determines the range and speed of the ammunition. For laser weapons, this
          means the ability or inability to shoot down or up.
        </li>
        <li><span class="headSection">Effect on bullet speed</span> - the effect on the speed of the ammunition's flight
          (ammunition speed + effect)
        </li>
        <li><span class="headSection">Shooting range</span> - for laser and missile weapons it determines the range of
          the weapon, for firearms the range is determined by the angles and speed of the bullet and it flies until it
          falls to the ground, so on uneven surfaces the range can be different.
        </li>
        <li><span class="headSection">Burst</span> - the number of bullets fired in one shot.</li>
        <li><span class="headSection">Rate of fire</span> - how many shots the weapon can make without reloading.</li>
        <li><span class="headSection">Ammunition capacity</span> - max. amount of ammunition in the magazine.</li>
        <li><span class="headSection">Reload time</span> - the time it takes to reload the magazine.</li>
        <li><span class="headSection">Power consumption</span> - how much energy will be reserved in the chassis.</li>
        <li><span class="headSection">Occupied volume</span> - how much space one unit occupies in the hold.</li>
      </ul>

      <p>Ammunition determines the following characteristics:</p>
      <ul>
        <li><span class="headSection">Type of attack</span> - determines the type of damage the ammunition will inflict
          (kinetic, thermal, explosive)
        </li>
        <li><span class="headSection">Damage</span> - min. and max. base damage of the ammunition.</li>
        <li><span class="headSection">Blast radius</span> - the range after the ammunition explodes from which everyone
          will take damage, the farther from the epicenter of the explosion the less damage (at the very edge there will
          be 1% damage, in the center 100%).
        </li>
        <li><span class="headSection">Bullet flight speed</span> - the speed at which the bullet flies out of the
          weapon's barrel.
        </li>
        <li><span class="headSection">Occupied volume</span> - how much space one unit of ammunition occupies in the
          hold.
        </li>
      </ul>

      <br>
      <h3 class="head">Types of weapons</h3>
      <p>Weapons in the game are divided into 3 main types:</p>

      <ul class="content_list" style="margin-top: 5px">
        <li><span class="headSection" style="font-size: 10px">Missile weapons</span>

          <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('weapon_2', 'png')+ ')',
          width: '380px',
          height: '180px',
          marginLeft: 'calc(50% - 190px)',
          }"/>

          <p>Missile weapons are characterized by a fixed bullet flight speed, high damage, and accuracy (for some
            missiles, controllability and homing).</p>
          <p>Missile weapons have a strictly fixed flight range, after which, if they do not reach the target, they
            self-destruct. Also, missile defense systems (anti-missile systems) are excellent against missile weapons,
            destroying the missiles still in flight.</p>
          <p>A huge downside of missile weapons is their visibility - all launched missiles are marked by radar systems
            as fast-moving objects.</p>
        </li>

        <li><span class="headSection" style="font-size: 10px">Laser weapons</span>

          <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('weapon_3', 'png')+ ')',
          width: '380px',
          height: '180px',
          marginLeft: 'calc(50% - 190px)',
          }"/>

          <p>Emitters and projectors, these combat energy installations are characterized by the instantaneous ability
            to hit a target.</p>
          <p>However, laser weapons cannot fire "overhead", and the range of damage is less accessible than other types
            of weapons.</p>
        </li>

        <li><span class="headSection" style="font-size: 10px">Firearms</span>

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('weapon_4', 'png')+ ')',
            width: '380px',
            height: '200px',
            marginLeft: 'calc(50% - 190px)',
            }"/>

          <p>Machine guns, autocannons, small and large artillery barrels - classics and direct heritage of the
            precursors.</p>
          <p>Characterized by a ballistic trajectory of the projectile/bullet's flight, which allows even hitting the
            enemy "overhead" (parabola), hitting them through low obstacles.</p>
          <p>The ammunition of firearms has an initial bullet/projectile flight speed. This affects the range of the
            bullet's flight.</p>
        </li>
      </ul>

      <br>
      <h3 class="head">Skills and penalties:</h3>
      <p>The skill of handling ammunition is determined by its size and type. If you use ammunition for which there is
        no skill access, the damage of the ammunition is reduced.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_2', 'png')+ ')',
        width: '368px',
        height: '348px',
        marginLeft: 'calc(50% - 184px)',
      }"/>

      <p>The skill of handling weapons is determined by their size and type. If you use a weapon for which there is no
        skill access, the weapon's rotation speed decreases.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_weapon_3', 'png')+ ')',
        width: '368px',
        height: '372px',
        marginLeft: 'calc(50% - 184px)',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Weapons",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
