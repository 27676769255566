<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h3 class="head">Топливо </h3>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('transport_2', 'png')+ ')',
          width: '113px',
          height: '151px',
          float: 'left',
          marginRight: '10px',
        }"/>

        <p>Топливо - это кровь вашего транспорта. Оно влияет на то, как хорошо работает ваш корпус. Каждый корпус имеет
          от
          одной до трех ячеек для топлива, которые нужно заполнять торием - специальным видом ресурса. Если вы не
          заполните все ячейки, то ваш корпус будет работать хуже. Если у вас кончится топливо, то ваш корпус будет
          работать очень плохо.</p>
        <p>Чем больше ячеек в транспорте тем быстрее расходуется топливо. (1 ячейка потребляет х1, 3 потребляет х3)</p>
      </div>
      <div class="content_section">
        <p>Топливо бывает разных видов, главное их отличие это его ресурс, однако некоторые виды топлива могут давать
          бонусы за использование.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('fuel_1', 'png')+ ')',
          width: '410px',
          height: '154px',
          marginLeft: 'calc(50% - 205px)',
        }"/>
      </div>
      <div class="content_section">
        Базовый вариант топлива продается на любой базе, остальные варианты надо производить на
        <router-link to="base">заводе полуфабрикатов</router-link>
        .
      </div>

      <div class="content_section" style="margin-top: 15px">
        <h3 class="head">Использование</h3>
        <p>Что бы установить топливо перетащите его на корпус или ячейку.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>

        <p>Остатки топлива надо погрузить в инвентарь с названием "топливо", когда кончится энергия у корпуса то он
          автоматически начнет использовать топливо из этого инвентаря.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_2', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h3 class="head">Fuel</h3>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('transport_2', 'png')+ ')',
          width: '113px',
          height: '151px',
          float: 'left',
          marginRight: '10px',
        }"/>
        <p>Fuel is the blood of your transport. It affects how well your chassis works. Each chassis has from one to
          three
          fuel cells that need to be filled with thorium - a special type of resource. If you do not fill all the cells,
          your chassis will perform worse. If you run out of fuel, your chassis will perform very poorly.</p>
        <p>The more cells in the transport, the faster the fuel is consumed. (1 cell consumes x1, 3 consumes x3)</p>
      </div>

      <div class="content_section">
        <p>Fuel comes in different types, the main thing is that they differ from this resource, however, some types of
          fuel can give bonuses for use.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('fuel_1_en', 'png')+ ')',
          width: '410px',
          height: '154px',
          marginLeft: 'calc(50% - 205px)',
        }"/>

        <div class="content_section">
          The basic version of the fuel is sold at any base, the other variants must be produced at the
          <router-link to="parts_production">semi-finished products plant</router-link>
          .
        </div>

        <div class="content_section" style="margin-top: 15px">
          <h3 class="head">Usage</h3>

          <p>What is needed to install the fuel is to drag it onto the hull or cell.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>

          <p>The remaining fuel should be loaded into the inventory called «fuel», when the hull runs out of energy, it
            will automatically start using fuel from this inventory.</p>
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_2', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Fuel",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
