<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: Навык</h2>

      <p>Навыки позволяют немного улучшить характеристики используемого оборудования.</p>
      <p>Что бы открыть меню навыков надо открыть "Личное дело".</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_skill_1', 'png')+ ')',
            width: '343px',
            height: '213px',
            marginLeft: 'calc(50% - 171px)',
            }"/>

      <p>Откроется меню вашего персонажа, надо перейти во вкладку "Навыки". Найдите в меню необходимый вам навык и нажми
        кнопку со стрелкой вверх.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_skill_2', 'png')+ ')',
            width: '491px',
            height: '292px',
            marginLeft: 'calc(50% - 245px)',
            }"/>

      <p>Вы великолепны.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Skill</h2>

      <p>Skills allow you to slightly improve the characteristics of the equipment used.</p>
      <p>To open the skills menu, you need to open "Personal File".</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_skill_1', 'png')+ ')',
            width: '343px',
            height: '213px',
            marginLeft: 'calc(50% - 171px)',
            }"/>

      <p>Your character's menu will open, you need to go to the "Skills" tab. Find the skill you need in the menu and
        press
        the up arrow button.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_skill_2', 'png')+ ')',
            width: '491px',
            height: '292px',
            marginLeft: 'calc(50% - 245px)',
            }"/>

      <p>You are magnificent.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationSkill",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
