<template>
  <div class="place_dialog">
    <h3 class="head">{{ texts['text_17'][language] }}</h3>

    <div class="src_block head">
      {{ warehouseTitle(inventory.placeDialog.source) }}
    </div>
    <div class="arrow"/>
    <div class="dst_block head">
      {{ warehouseTitle(inventory.placeDialog.destination) }}
    </div>

    <div class="items">
      <div class="item" v-for="item in slots">

        <div class="iconItem"
             v-if="source && source.inventory[item.slot]">
          <app-background-item-cell
            v-bind:slotItem="source.inventory[item.slot]"/>
        </div>

        <div class="item_control">
          <div style="margin-top: 3px; width: calc(100% - 20px); margin-left: 10px;">
            <app-range :min="0"
                       @change="changeCount(item.slot, $event, true)"
                       :max="item.slot_quantity"
                       :possible-max="item.count_may_put"
                       :start-value="item.count_current"
                       :step="1"/>
          </div>
        </div>
      </div>
    </div>

    <div class="exit_block">
      <input type="button" value="Закрыть"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="closeDialog">
      <input type="button" value="Перенести"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeCount(0, $event, false)" style=" float: right">
    </div>
  </div>
</template>

<script>

import Range from './Range'
import BackgroundItemCell from './BackgroundItemCell'

export default {
  name: "PlaceDialog",
  data() {
    return {
      changeItems: {},
      first: true,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeCount(slot, newCount, noPlace) {
      this.playSound('button_press.mp3', 0.3)

      this.changeItems[slot] = newCount

      let slots = {}
      for (let item of this.inventory.placeDialog.place_items) {
        if (this.changeItems[item.slot]) {
          slots[item.slot] = Number(this.changeItems[item.slot])
        } else {
          if (noPlace) slots[item.slot] = 0
        }
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "itemsToDst",
          source: this.inventory.placeDialog.source,
          destination: this.inventory.placeDialog.destination,
          storage_slots: slots,
          no_place: noPlace,
          strategy: "",
        }
      }));

      if (!noPlace) this.closeDialog();
    },
    closeDialog() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.commit({
        type: 'setPlaceDialog',
        data: null,
      });
    },
    warehouseTitle(title) {
      if (this.language === 'RU') {
        if (title === 'storage') return 'Склад';
        if (title.includes('box')) return 'Ящик';
        if (title === 'squadInventory') return 'Трюм';
      }
      return title
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    slots() {
      for (let item of this.inventory.placeDialog.place_items) {
        if (this.changeItems.hasOwnProperty(item.slot)) {
          if (this.changeItems[item.slot] > item.count_may_put) {
            this.changeItems[item.slot] = item.count_may_put
          }
        }

        if (this.first) {
          this.changeItems[item.slot] = item.count_current
        }
      }

      if (this.first) {
        this.first = false
      }

      return this.inventory.placeDialog.place_items.sort(function (a, b) {
        if (a.slot > b.slot) return 1;
        if (b.slot > a.slot) return -1;
        return 0;
      });
    },
    objs() {
      return this.$store.getters.getOpenObjects
    },
    source() {
      if (this.inventory.warehouses[this.inventory.placeDialog.source] && this.inventory.warehouses[this.inventory.placeDialog.source].inventory) {
        return this.inventory.warehouses[this.inventory.placeDialog.source]
      } else {
        let s = this.inventory.placeDialog.source.split(':')
        if (s.length > 1) {
          let boxID = Number(s[1])

          for (let i in this.objs) {
            if (this.objs.hasOwnProperty(i) && this.objs[i].box && this.objs[i].box.id === boxID) {
              return this.objs[i]
            }
          }
        }
      }
    }
  },
  components: {
    AppRange: Range,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.place_dialog {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 16;
  width: calc(100% - 18px);
  height: calc(100% - 18px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.head {
  margin: 0 0 7px;
  background: #3486dd;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  user-select: none;
  box-shadow: 0 0 2px #000;
  text-indent: 10px;
}

.exit_block input {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.exit_block input:hover {
  background: rgba(255, 129, 0, 1);
}

.exit_block input:active {
  transform: scale(0.98);
}

.exit_block {
  width: calc(100% - 6px);
  position: absolute;
  bottom: 2px;
}

.items {
  box-shadow: inset 0 0 2px black;
  width: 100%;
  height: calc(100% - 62px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 2px 0 0 0;
  background-color: rgba(7, 41, 60, 0.4);
}

.item {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 12px;
  color: #9bc9f1;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  height: 30px;
  line-height: 26px;
  transition: 0.1s;
  min-width: 168px;
  position: relative;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.2);
  margin: 1px 3px 0px 3px;
}

.iconItem {
  position: relative;
  border-radius: 2px;
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: top;
  margin: 2px;
  box-shadow: 0 0 2px #000;
  float: left;
}

.item_control {
  width: calc(100% - 30px);
  height: 100%;
  float: left;
}

.src_block, .dst_block {
  float: left;
  width: 100px;
  height: 14px;
  line-height: 14px;
  margin-bottom: 2px;
  overflow: hidden;
  padding: 2px 0 0 0;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  text-indent: unset;
}

.dst_block {
  float: right;
}

.arrow {
  background-image: url('../../assets/icons/place.png');
  height: 25px;
  width: 25px;
  background-size: contain;
  float: left;
  margin-left: calc(50% - 112px);
  margin-top: -7px;
  position: relative;
  top: 2px;
  filter: drop-shadow(1px 1px 1px black);
}
</style>
