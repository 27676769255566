<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Фракционная война</h2>

      <div class="content_section">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('fraction_war', 'png')+ ')',
              width: '270px',
              height: '300px',
              float: 'left',
              marginLeft: '10px',
              marginRight: '10px',
            }"/>

        <p>Конфликт, это нынешняя реальность для общества синетов на планете Veliri-5. Спорные территории данного
          захудалого мира стали театром действий для сотен тысяч баталий, где теперь каждому синтету предстоит выбрать
          собственную сторону.</p>

        <p>Игрок, желающий принять участие в “войне фракций”, должен быть в составе “ополчения”. Получая таким образом
          особый статус участника конфликта на планете и тем самым зарабатывая фракционные очки, в дальнейшем
          продвигаясь
          по ранговой системе. </p>

        <p>Сами же заработанные очки фракции, игрок может и должен обменивать на базе своей фракции, получая взамен
          различные ценные предметы. </p>

        <p>Ранг - показывает уровень вашей славы и допуск в более ценным наградам. </p>

        <p style="clear: both; padding-top: 15px">Война имеет две фазы: активную и пассивную.</p>
        <ul>
          <li>
            <p><span class="importantly">Активная фаза войны</span> - событие, что происходит каждый день. По временному
              промежутку, данная фаза длится
              от 16:00 до 21:00 по МСК. </p>
            <p>Во время данной фазы, силы сторон захватывают сектора, атакуют базы оппонентов и производят рейды на их
              защитные позиции. </p>
            <p>Во время активной фазы, посильную помощь вам составят армии ботов, следующие к своим целям и активно
              поддерживающие вас огнём. </p>
            <p>Но не стоит забывать, что помимо вас, в данной фазе аналогично себя будут вести боты и игроки из иных
              фракций. Вы столкнётесь с сильным сопротивлением, и никто не гарантирует, что поход за контролем над
              очередным сектором сумеет увенчаться успехом.</p>
          </li>
          <li>
            <p><span class="importantly">Пассивная фаза войны</span> - событие, наступающее каждый раз, едва стихает
              Активная фаза войны. </p>
            <p>Во время данной фазы, стороны прекращают своё наступление, планируя действия к началу новой активной
              фазы.
              Силы сторон принимаются к патрулированию захваченных/удержанных территорий и укреплению рубежей. </p>
            <p>Игрок таким образом, получает ограниченное время для посещения спорных секторов, добычи там ресурсов,
              исследования аномалий или выполнения связанных заданий. </p>
          </li>
        </ul>

        <p>Война - это рисковое мероприятие, во время которой синтеты борются за спорную территорию, богатые залежи
          ресурсов и конечно же славу. </p>
      </div>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Faction War</h2>

      <div class="content_section">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('fraction_war', 'png')+ ')',
              width: '270px',
              height: '300px',
              float: 'left',
              marginLeft: '10px',
              marginRight: '10px',
            }"/>

        <p>Conflict is the current reality for the society of synthetics on the planet Veliri-5. The disputed
          territories of this
          dilapidated world have become the theater of action for hundreds of thousands of battles, where now every
          synthetic must choose
          their own side.</p>

        <p>A player wishing to participate in the "faction war" must be part of the "militia". Thus obtaining
          a special status of a participant in the conflict on the planet and thereby earning faction points,
          subsequently
          advancing
          through the ranking system. </p>

        <p>The earned faction points can and should be exchanged at the base of their faction, receiving in return
          various valuable items. </p>

        <p>Rank - shows the level of your glory and access to more valuable rewards. </p>

        <p style="clear: both; padding-top: 15px">The war has two phases: active and passive.</p>
        <ul>
          <li>
            <p><span class="importantly">Active phase of the war</span> - an event that happens every day. This phase
              lasts
              from 16:00 to 21:00 MSK. </p>
            <p>During this phase, the forces of the sides capture sectors, attack the bases of opponents, and conduct
              raids on their
              defensive positions. </p>
            <p>During the active phase, armies of bots following their objectives and actively supporting you with fire
              will assist you. </p>
            <p>But do not forget that besides you, bots and players from other factions will behave similarly during
              this phase. You will face strong resistance, and no one guarantees that the campaign for control over the
              next sector will be crowned with success.</p>
          </li>
          <li>
            <p><span class="importantly">Passive phase of the war</span> - an event that occurs every time the Active
              phase of the war subsides. </p>
            <p>During this phase, the sides cease their offensive, planning actions for the start of a new active phase.
              The forces of the sides take to patrolling captured/held territories and strengthening the frontiers. </p>
            <p>Thus, the player gets limited time to visit disputed sectors, extract resources there,
              research anomalies, or perform related tasks. </p>
          </li>
        </ul>

        <p>War is a risky event during which synthetics fight for disputed territory, rich deposits
          of resources, and of course, glory. </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "FractionWar",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
