import {initTweenJammer} from "./jammer";
import {initTweenRadar} from "./radar";
import {initTweenShield} from "./shield";
import {gameStore} from "../../store";
import {initTweenBase} from "./corporation_base";
import {initTweenProcessing} from "./corporation_processing";
import {SetDayCycleObject} from "../../day_cycle/day_cycle";

function NoCompleteStructure(obj) {

  if (!obj.build) {
    return
  }

  if (obj.work || obj.type === 'plants' || obj.type === 'base' || obj.type === 'rope_trap' || obj.type === 'zone_repair_station') {
    obj.disable = false;
    obj.objectSprite.setTint(0xffffff)

    // todo костыль из за того что твины при загрузке игры сразу не работают
    // todo еще если код с инитом твинов будет выше их включения то небудет ошибки но и работать не будет хз почему
    if (obj.objectSprite.top && obj.objectSprite.top.tween) {
      obj.objectSprite.top.tween.resume();
    }

    if (obj.objectSprite.top2 && obj.objectSprite.top2.tween) {
      obj.objectSprite.top2.tween.resume();
    }

    if (obj.objectSprite.shadowTop && obj.objectSprite.shadowTop.tween) {
      obj.objectSprite.shadowTop.tween.resume();
    }

    if (obj.objectSprite.shadowTop2 && obj.objectSprite.shadowTop2.tween) {
      obj.objectSprite.shadowTop2.tween.resume();
    }

    if (obj.objectSprite.emitter) {
      try {
        obj.objectSprite.emitter.start()
      } catch (e) {
      }
    }

    SetDayCycleObject(obj)

    if (obj.objectSprite.light) obj.objectSprite.light.visible = true;

    if (!obj.objectSprite.initTween) {
      if (obj.type === "shield") initTweenShield(obj.objectSprite);
      if (obj.type === "jammer") initTweenJammer(obj.objectSprite);
      if (obj.type === "radar" || obj.type === "expensive_tower") initTweenRadar(obj.objectSprite);

      if (!obj.texture.includes('build') && obj.type !== 'structure_wreckage' && !obj.texture.includes('destroy')) {
        if (obj.texture.includes('corporation_base')) initTweenBase(obj.objectSprite);
        if (obj.texture.includes('corporation_processing')) initTweenProcessing(obj.objectSprite);
      }
    }

  } else {
    DisableObj(obj)
  }

  if (obj.complete < 100) {

    // обьект находится на стадии разработки
    if (obj.objectSprite.shadow) obj.objectSprite.shadow.visible = false;
    if (obj.objectSprite.shadowTop) obj.objectSprite.shadowTop.visible = false;
    if (obj.objectSprite.shadowTop2) obj.objectSprite.shadowTop2.visible = false;
    if (obj.objectSprite.weaponShadow) obj.objectSprite.weaponShadow.visible = false;
    if (obj.objectSprite.equipShadow) obj.objectSprite.equipShadow.visible = false;
    if (obj.objectSprite.light) obj.objectSprite.light.visible = false;

    if (obj.complete < 10) {
      obj.objectSprite.setAlpha(0.1);
      if (obj.objectSprite.top) obj.objectSprite.top.setAlpha(0.1);
      if (obj.objectSprite.top2) obj.objectSprite.top2.setAlpha(0.1);
      if (obj.objectSprite.weapon) obj.objectSprite.weapon.setAlpha(0.1);
      if (obj.objectSprite.equip) obj.objectSprite.equip.setAlpha(0.1);
    } else {
      obj.objectSprite.setAlpha(obj.complete / 100);
      if (obj.objectSprite.equip) obj.objectSprite.equip.setAlpha(obj.complete / 100);
      if (obj.objectSprite.top) obj.objectSprite.top.setAlpha(obj.complete / 100);
      if (obj.objectSprite.top2) obj.objectSprite.top2.setAlpha(obj.complete / 100);
      if (obj.objectSprite.weapon) obj.objectSprite.weapon.setAlpha(obj.complete / 100);
    }

  } else {

    if (obj.objectSprite.alpha !== 1) obj.objectSprite.setAlpha(1);
    if (obj.objectSprite.top && obj.objectSprite.top.alpha !== 1) obj.objectSprite.top.setAlpha(1);
    if (obj.objectSprite.top2 && obj.objectSprite.top2.alpha !== 1) obj.objectSprite.top2.setAlpha(1);
    if (obj.objectSprite.equip && obj.objectSprite.equip.alpha !== 1) obj.objectSprite.equip.setAlpha(1);
    if (obj.objectSprite.weapon && obj.objectSprite.weapon.alpha !== 1) obj.objectSprite.weapon.setAlpha(1);
    if (obj.objectSprite.light && obj.objectSprite.light.alpha !== 1) obj.objectSprite.light.setAlpha(1);

    if (obj.objectSprite.shadow && !obj.objectSprite.shadow.visible) obj.objectSprite.shadow.setVisible(true);
    if (obj.objectSprite.shadowTop && !obj.objectSprite.shadowTop.visible) obj.objectSprite.shadowTop.setVisible(true);
    if (obj.objectSprite.shadowTop2 && !obj.objectSprite.shadowTop2.visible) obj.objectSprite.shadowTop2.setVisible(true);
    if (obj.objectSprite.weaponShadow && !obj.objectSprite.weaponShadow.visible) obj.objectSprite.weaponShadow.setVisible(true);
    if (obj.objectSprite.equipShadow && !obj.objectSprite.equipShadow.visible) obj.objectSprite.equipShadow.setVisible(true);
  }
}

function DisableObj(obj) {

  let tint = 0x999999
  if (gameStore.time.h > 1 && gameStore.time.h < 6) {
    tint = 0x444444
  }

  obj.objectSprite.setTint(tint)
  obj.disable = true;

  if (obj.objectSprite.top && obj.objectSprite.top.tween) {
    obj.objectSprite.top.tween.pause();
    obj.objectSprite.top.setTint(tint)
  }

  if (obj.objectSprite.top2 && obj.objectSprite.top2.tween) {
    obj.objectSprite.top2.tween.pause();
    obj.objectSprite.top2.setTint(tint)
  }

  if (obj.objectSprite.shadowTop && obj.objectSprite.shadowTop.tween) {
    obj.objectSprite.shadowTop.tween.pause();
  }

  if (obj.objectSprite.shadowTop2 && obj.objectSprite.shadowTop2.tween) {
    obj.objectSprite.shadowTop2.tween.pause();
  }

  if (obj.objectSprite.emitter) {
    try {
      obj.objectSprite.emitter.stop()
    } catch (e) {
    }
  }

  if (obj.objectSprite.equip && obj.objectSprite.equip.tween) {
    obj.objectSprite.equip.tween.pause();
    obj.objectSprite.equip.setTint(tint)
  }

  if (obj.objectSprite.equipShadow && obj.objectSprite.equipShadow.tween) {
    obj.objectSprite.equipShadow.tween.pause();
  }

  if (obj.objectSprite.weapon) {
    obj.objectSprite.weapon.setTint(tint)
  }

  if (obj.objectSprite.light) {
    obj.objectSprite.light.visible = false;
  }

  if (gameStore.FogOfWar.back && gameStore.FogOfWar.objBrush && gameStore.FogOfWar.objBrush.object[obj.id]) {
    gameStore.FogOfWar.objBrush.object[obj.id].destroy();
    delete gameStore.FogOfWar.objBrush.object[obj.id];
  }
}

export {NoCompleteStructure, DisableObj}
