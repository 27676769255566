<template>

  <div id="wrapperInventoryAndStorage"
       ref="wrapperInventoryAndStorage"
       @mousedown="toUp">

    <div class="error_message"
         :class="{error_message_visible: officeNotActive || inventoryTransferNotAllow || removeLastBody || weightExceeded}">
      <div style="margin-top: 30%">
        {{ officeNotActive ? texts['text_12'][language] : '' }}
        {{ inventoryTransferNotAllow ? texts['text_13'][language] : '' }}
        <template v-if="removeLastBody">
          Нельзя выбросить/продать <span class="importantly">последний корпус</span>.
        </template>
        <template v-if="weightExceeded">
          Не поместилось.
        </template>
      </div>
    </div>

    <app-cell-sub-menu
      v-if="subMenuProps.slot && subMenuProps.src === inventory.warehouse"
      v-bind:subMenuProps="subMenuProps"/>

    <div class="mask" v-if="inventory.placeDialog">
      <app-place-dialog/>
    </div>

    <div v-if="throwItems.open">
      <app-throw-items-dialog/>
    </div>

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'wrapperInventoryAndStorage'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 145, width: 342}"/>

    <div id="warehousesTabs">
      <app-warehouse-tab v-if="hold"
                         @mouseover.native="warehouseMouseOver(hold.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:green_animate="selectHold || selectAmmo"
                         v-bind:title="warehouseTitle(hold.warehouse.title)"
                         v-bind:id="hold.id"/>

      <app-warehouse-tab v-for="ai in aInv"
                         :key="ai.id"
                         @mouseover.native="warehouseMouseOver(ai.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:green_animate="selectHold || (ai.warehouse.title === 'aInv:fuel' && selectFuel2)"
                         v-bind:a-inv="true"
                         v-bind:title="warehouseTitle(ai.warehouse.title)"
                         v-bind:id="ai.id"/>

      <app-warehouse-tab v-for="b in boxes"
                         :key="b.id"
                         @mouseover.native="warehouseMouseOver(b.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:title="warehouseTitle(b.warehouse.title)"
                         v-bind:id="b.id"/>

      <app-warehouse-tab v-if="scanner"
                         @mouseover.native="warehouseMouseOver(scanner.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:title="warehouseTitle(scanner.warehouse.title)"
                         v-bind:id="scanner.id"/>

      <app-warehouse-tab v-for="aInv in scannerAInv"
                         :key="aInv.id"
                         @mouseover.native="warehouseMouseOver(aInv.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:a-inv="true"
                         v-bind:s-a-inv="true"
                         v-bind:title="warehouseTitle(aInv.warehouse.title)"
                         v-bind:id="aInv.id"/>

      <app-warehouse-tab v-if="storage"
                         @mouseover.native="warehouseMouseOver(storage.id)"
                         @mouseout.native="warehouseMouseLeave"
                         v-bind:title="warehouseTitle(storage.warehouse.title)"
                         v-bind:id="storage.id"
                         v-bind:green_animate="selectStorage || selectAmmo || selectFuel"/>

      <div class="RemoteWarehousesTabs" v-if="hangars.length > 0" @click="hangar = !hangar">
        {{ hangar ? '▼' : '▶' }} Ангар
      </div>

      <template v-if="hangar" v-for="h in hangars">
        <app-warehouse-tab :key="h.id"
                           @mouseover.native="warehouseMouseOver(h.id)"
                           @mouseout.native="warehouseMouseLeave"
                           v-bind:title="warehouseTitle(h.warehouse.title)"
                           v-bind:id="h.id"/>

        <app-warehouse-tab v-for="aInv in getHangarAInv(h)"
                           :key="aInv.id"
                           @mouseover.native="warehouseMouseOver(aInv.id)"
                           @mouseout.native="warehouseMouseLeave"
                           v-bind:a-inv="true"
                           v-bind:title="warehouseTitle(aInv.warehouse.title)"
                           v-bind:id="aInv.id"/>
      </template>

      <div class="RemoteWarehousesTabs" v-if="officeStocks.length > 0" @click="office = !office">
        {{ office ? '▼' : '▶' }} {{ texts['text_14'][language] }}
      </div>

      <template v-if="office">
        <app-warehouse-tab v-for="s in officeStocks"
                           :key="s.id"
                           @mouseover.native="warehouseMouseOver(s.id)"
                           @mouseout.native="warehouseMouseLeave"
                           v-bind:office="true"
                           v-bind:rights="rights"
                           v-bind:lock="getStockBlock()"
                           v-bind:title="warehouseTitle(s.warehouse.title)"
                           v-bind:id="s.id"/>
      </template>

      <div class="RemoteWarehousesTabs" @click="remotes = !remotes">
        {{ remotes ? '▼' : '▶' }} {{ texts['text_15'][language] }}
      </div>

      <template v-if="remotes">
        <app-warehouse-tab v-for="(warehouse, id) in inventory.warehouses"
                           :key="id"
                           v-if="warehouse && warehouse.remote"
                           v-bind:remote="true"
                           v-bind:title="warehouseTitle(warehouse.title)"
                           v-bind:id="id"/>
      </template>
    </div>

    <div id="Inventory">

      <app-size v-if="!warehouseOver && inventory.warehouses[inventory.warehouse]"
                v-bind:capacity="inventory.warehouses[inventory.warehouse].capacity"
                v-bind:size="inventory.warehouses[inventory.warehouse].size"
                v-bind:itemSlot="mouserOveSlot"/>

      <app-size v-if="warehouseOver && inventory.warehouses[inventory.warehouse]"
                v-bind:capacity="inventory.warehouses[warehouseOver].capacity"
                v-bind:size="inventory.warehouses[warehouseOver].size"
                v-bind:itemSlot="mouserOveSlot"/>

      <app-size v-if="!inventory.warehouses[inventory.warehouse]"
                v-bind:capacity="0"
                v-bind:size="0"/>

      <div class="categoryTabs">
        <ol>
          <li v-for="tab in tabs"
              v-bind:class="{activeInventoryTabs: inventory.filters.category === tab.category}"
              class="cursor_hover"
              @mouseover="playSound('select_sound.mp3', 0.3)"
              @click="selectCategory(tab.category)">
            <div class="image"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/'+ tab.icon +'.png').default + ')'}"></div>
          </li>

          <li v-if="inventory.warehouse.includes('aInv')"
              class="categoryTabsButton"
              @click="fillInventory(inventory.warehouse)">
            <div class="image"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/unit_pointer.png').default + ')'}"></div>
          </li>

          <li v-if="inventory.warehouse === 'aInv:fuel'"
              class="categoryTabsButton"
              @click="fuelCombine(inventory.warehouse)">
            <div class="image"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/category_fuel_mini_plus.png').default + ')'}"></div>
          </li>
        </ol>
      </div>

      <div id="inventoryStorageInventory" class="inventoryStorage" ref="inventoryStorageInventory">
        <template v-if="inventory.warehouses[inventory.warehouse]">
          <app-item-cell v-for="slot in inventory.warehouses[inventory.warehouse].inventory"
                         v-if="visibleCell(slot, inventory.filters)"
                         :key="inventory.warehouse + slot.number"
                         v-bind:itemSlot="slot"
                         v-bind:size="inventory.cellSize"
                         v-bind:parent="inventory.warehouse"
                         v-bind:drag="true"
                         v-bind:showHP="true"
                         v-bind:cursor_pointer="true"
                         v-bind:noAllowClass="NotAllowCell(slot, inventory.findFilter)"
                         v-bind:label_type="slot.item.type_slot"
                         v-bind:back_storage="true"
                         @mouseover.native="itemMouseOver(slot)"
                         @mouseout.native="itemMouseLeave"
                         @click.native="openSubMenu($event, slot)"
                         v-bind:green_animate="
                         (selectBody && slot.type === 'body') ||
                         (selectHold && slot.type === 'resource' && slot.item_id === 4) ||
                         (selectWeaponAndAmmo && (slot.type === 'weapon' || slot.type === 'ammo')) ||
                         (selectAmmo && slot.type === 'ammo') ||
                         (selectGeoEquip && slot.type === 'equip' && (slot.item_id === 7 || slot.item_id ===  8)) ||
                         (selectMining && slot.type === 'equip') ||
                         ((selectFuel || selectFuel2) && slot.type === 'fuel')"/>
        </template>
      </div>

      <div class="remote_menu"
           v-if="inventory.warehouses[inventory.warehouse] && inventory.warehouses[inventory.warehouse].remote">
        <input type="button"
               class="cursor_hover"
               :value="texts['button_25'][language]"
               style="width: 70px; margin-right: 2px;"
               @click.stop="autoPilot(inventory.warehouse, 'auto_pilot')"
               @mouseover="playSound('select_sound.mp3', 0.3)">

        <input type="button"
               class="cursor_hover"
               :value="texts['button_26'][language]"
               @click.stop="autoPilot(inventory.warehouse, 'target')"
               @mouseover="playSound('select_sound.mp3', 0.3)">
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Size from './Size';
import ItemCell from './ItemCell';
import WarehouseTab from './WarehouseTab';
import CellSubMenu from './CellSubMenu';
import PlaceDialog from './PlaceDialog';
import ThrowItemsDialog from './ThrowItemsDialog';

export default {
  name: "Inventory",
  props: ["meta"],
  data() {
    return {
      remotes: false,
      office: false,
      hangar: false,
      mouserOveSlot: null,
      warehouseOver: null,
      subMenuProps: {
        src: '',
        slot: null,
        x: 0,
        y: 0,
        points: [],
      }
    }
  },
  destroyed() {
    this.$store.commit({
      type: 'setPlaceDialog',
      data: false,
    });
  },
  created() {
    this.selectCategory('all');
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));
  },
  mounted() {
    let app = this;

    let wait = setInterval(function () {
      if (app.$refs.inventoryStorageInventory) {

        clearInterval(wait);

        if (app.$props.meta && app.$props.meta.warehouse && app.inventory.warehouses[app.$props.meta.warehouse]) {
          app.$store.commit({
            type: 'setCurrentWarehouse',
            warehouse: app.$props.meta.warehouse,
          });
        } else {
          if (app.inventory.warehouses['squadInventory']) {
            app.$store.commit({
              type: 'setCurrentWarehouse',
              warehouse: 'squadInventory',
            });
          } else {
            app.$store.commit({
              type: 'setCurrentWarehouse',
              warehouse: 'storage',
            });
          }
        }

        $(app.$refs.inventoryStorageInventory).selectable({
          filter: '.InventoryCell',
          appendTo: 'body',
          start: function () {
            $('.ui-selected').removeClass('ui-selected');
          }
        });
        $(app.$refs.inventoryStorageInventory).droppable({
          tolerance: "pointer",
          drop: function (event, ui) {

            $('.ui-selected').removeClass('ui-selected');

            let draggable = ui.draggable;
            if (!draggable.data("selectedItems")) return;

            // если родитель конструктор то это снятие эквипа с тушки, там свои методы
            if (draggable.data("slotData").parent === "Constructor") {
              app.$store.dispatch("sendSocketData", JSON.stringify({
                service: "inventory",
                data: {
                  event: draggable.data("slotData").event,
                  equip_slot: Number(draggable.data("slotData").equipSlot),
                  equip_slot_type: Number(draggable.data("slotData").equipType),
                  unit_slot: Number(draggable.data("slotData").unitSlot),
                  destination: "squadInventory",
                }
              }));
            } else {

              let slots = {}
              for (let slotNumber of draggable.data("selectedItems").slotsNumbers) {
                slots[slotNumber] = 0
              }
              app.$store.dispatch("sendSocketData", JSON.stringify({
                service: "inventory",
                data: {
                  event: "itemsToDst",
                  source: draggable.data("slotData").parent,
                  destination: app.inventory.warehouse,
                  storage_slots: slots,
                  strategy: location.href.includes('global') ? 'global' : '',
                }
              }));
            }
          }
        });
      }
    }, 100)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    fillInventory(wh) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "FillInventory",
          destination: wh,
        }
      }));
    },
    fuelCombine(wh) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "FuelCombine",
          destination: wh,
        }
      }));
    },
    autoPilot(title, type) {
      this.playSound('button_press.mp3', 0.3)

      let n = title.split(':');
      if (n.length > 1 && n[0] === 'storage') {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "global",
          data: {
            event: "MoveToSector",
            base_id: Number(n[1]),
            type: type,
          }
        }));
      }
    },
    resize(event, ui, el) {
      let inventory = $('#Inventory');
      let inventoryStorageInventory = $('#inventoryStorageInventory');
      inventory.css("height", el.height());
      inventory.css("width", el.width() - 110);
      inventoryStorageInventory.css("height", inventory.height() - 41);
    },
    selectCategory(category) {
      this.playSound('button_press.mp3', 0.3)
      this.subMenuProps.slot = null;
      this.$store.commit({
        type: 'setInventoryFilters',
        filters: {category: category},
      });
    },
    itemMouseOver(slot) {
      this.mouserOveSlot = slot
    },
    itemMouseLeave() {
      this.mouserOveSlot = null
    },
    warehouseMouseOver(id) {
      this.warehouseOver = id
    },
    warehouseMouseLeave() {
      this.warehouseOver = null
    },
    openSubMenu(event, slot) {
      this.playSound('button_press.mp3', 0.3)
      let app = this;

      if (app.subMenuProps.slot) {
        app.subMenuProps.slot = null;
        return;
      }

      app.subMenuProps.slot = null;

      setTimeout(function () {
        app.getSubMenuPoints(slot);
        app.subMenuProps.src = app.inventory.warehouse;
        app.subMenuProps.x = ((event.pageX - $(app.$refs['wrapperInventoryAndStorage']).position().left) / app.interfaceZoom);
        app.subMenuProps.y = ((event.pageY - $(app.$refs['wrapperInventoryAndStorage']).position().top) / app.interfaceZoom);
        app.subMenuProps.slot = slot;
      }, 0)
    },
    getSubMenuPoints(slot) {

      this.subMenuProps.points = [];
      this.subMenuProps.points.push('detail');

      if (this.inventory.warehouse === "squadInventory" || this.inventory.warehouse.includes("aInv:") || this.inventory.warehouse === 'storage') {

        if (!slot.infinite && slot.quantity > 1) this.subMenuProps.points.push('split');

        if (!this.inventory.inBase) {
          this.subMenuProps.points.push('throw');

          if (slot.type === "boxes") {
            this.subMenuProps.points.push('install');
          }

          if (slot.type === "ammo") this.subMenuProps.points.push('install_ammo');
          if (slot.type === "fuel") this.subMenuProps.points.push('install_thorium');
        } else if (this.inventory.warehouse === 'storage') {
          this.subMenuProps.points.push('destroy');

          if (!slot.infinite && slot.item_id < 10000000) this.subMenuProps.points.push('cell');

          if (slot.max_hp > slot.hp) {
            this.subMenuProps.points.push('repair');
          }

          this.subMenuProps.points.push('to_hold');

          if (slot.type === "blueprints" && this.baseStatus && this.baseStatus.state.type === 'base') this.subMenuProps.points.push('to_work');
          if (slot.type === "weapon") this.subMenuProps.points.push('install_weapon');
          if (slot.type === "ammo") this.subMenuProps.points.push('install_ammo');
          if (slot.type === "body") this.subMenuProps.points.push('install_body');
          if (slot.type === "fuel") this.subMenuProps.points.push('install_thorium');
        } else if (this.inventory.warehouse === 'squadInventory' && this.inventory.warehouses.hasOwnProperty('squadInventory')) {
          this.subMenuProps.points.push('destroy');

          this.subMenuProps.points.push('to_storage');
          if (slot.type === "weapon") this.subMenuProps.points.push('install_weapon');
          if (slot.type === "ammo") this.subMenuProps.points.push('install_ammo');
          if (slot.type === "body") this.subMenuProps.points.push('install_body');
          if (slot.type === "fuel") this.subMenuProps.points.push('install_thorium');
        } else if (this.inventory.warehouse.includes("aInv:")) {
          this.subMenuProps.points.push('destroy');
          this.subMenuProps.points.push('to_hold');
          this.subMenuProps.points.push('to_storage');

          // if (slot.type === "weapon") this.subMenuProps.points.push('install_weapon');
          // if (slot.type === "ammo") this.subMenuProps.points.push('install_ammo');
          // if (slot.type === "body") this.subMenuProps.points.push('install_body');
          // if (slot.item.name === "enriched_thorium") this.subMenuProps.points.push('install_thorium');
        }
      }

      this.subMenuProps.points.push('cancel');
    },
    visibleCell(slot, filter) {

      if (slot.quantity === 0 || !slot.item) {
        return false
      }

      if (slot.type === filter.category || filter.category === 'all') {
        return true
      }

      return slot.type === 'recycle' && filter.category === 'resource';
    },
    /**
     * @return {boolean}
     */
    NotAllowCell(slot, filter) {
      return false
      if (!filter) return false;

      if (filter.type === 'equip' && slot.type === filter.type && (slot.item.type_slot === filter.type_slot || filter.type_slot === 0)) {
        if (filter.mining && slot.item.x_attach !== 0 && slot.item.y_attach !== 0) {
          return false
        }
        if (!filter.mining) {
          return false;
        }
      }

      if (filter.type === 'weapon' && slot.type === filter.type) {
        if (filter.standard_size.small && slot.item.standard_size === 1) {
          return false;
        }
        if (filter.standard_size.medium && slot.item.standard_size === 2) {
          return false;
        }
        if (filter.standard_size.big && slot.item.standard_size === 3) {
          return false;
        }
      }

      if (filter.type === 'ammo' && slot.type === filter.type) {
        if (filter.standard_size === slot.item.standard_size && filter.weaponType === slot.item.type) {
          return false;
        }
      }

      return !(filter.type === 'body' && slot.type === filter.type);
    },
    warehouseTitle(title) {

      if (title === 'aInv:resource') {
        return this.texts['text_16'][this.language];
      }

      if (title === 'aInv:organic') {
        return this.texts['text_20'][this.language];
      }

      if (title === 'aInv:oil') {
        return this.texts['text_21'][this.language];
      }

      if (title === 'aInv:dust') {
        return this.texts['text_22'][this.language];
      }

      if (title === 'aInv:fuel') {
        return this.texts['text_23'][this.language];
      }

      let n = title.split(':');
      if (n.length > 1 && n[0] === 'storage') {
        return this.getBaseName(n[1]);
      }

      if (n.length > 1 && n[0] === 'hangar_aInv_resource') {
        return this.texts['text_16'][this.language];
      }

      if (n.length > 1 && n[0] === 'hangar_aInv_organic') {
        return this.texts['text_20'][this.language];
      }

      if (n.length > 1 && n[0] === 'hangar_aInv_oil') {
        return this.texts['text_21'][this.language];
      }

      if (n.length > 1 && n[0] === 'hangar_aInv_fuel') {
        return this.texts['text_23'][this.language];
      }

      if (title === 'hangar_aInv_dust') {
        return this.texts['text_22'][this.language];
      }

      if (n.length > 1 && n[0] === 'office_stock') {
        return this.getStockName(n[1]);
      }

      if (n.length > 1 && n[0] === 'hangar') {
        if (!this.inventory.baseSquads.hasOwnProperty(n[1])) {
          this.$store.commit({
            type: 'removeWarehouse',
            warehouse: title,
          });
        }

        return this.inventory.baseSquads[n[1]]
      }

      if (title.includes('box')) {
        return this.texts['box'][this.language];
      }

      if (title === 'storage' || title === 'squadInventory' || title === 'scanner') {
        return this.texts[title][this.language];
      }

      return title
    },
    getBaseName(id) {
      for (let i in this.handbook.base[this.language]) {
        if (this.handbook.base[this.language][i].id === Number(id)) {
          return this.handbook.base[this.language][i].name
        }
      }

      if (this.corporationBaseNames.hasOwnProperty(id)) {
        return this.corporationBaseNames[id]
      }

      return id
    },
    getStockName(id) {
      if (this.corporation.offices) {
        for (let i in this.corporation.offices) {
          if (this.corporation.offices[i].base_id === this.baseStatus.state.id) {
            for (let j in this.corporation.offices[i].storages) {
              if (this.corporation.offices[i].storages[j].id === Number(id)) {
                return this.corporation.offices[i].storages[j].name;
              }
            }
          }
        }
      }

      return "Stock"
    },
    getStockBlock() {
      if (this.corporation.offices) {
        for (let i in this.corporation.offices) {
          if (this.corporation.offices[i].base_id === this.baseStatus.state.id) {
            return !this.corporation.offices[i].active
          }
        }
      }

      return true
    },
    getHangarAInv(h) {
      let hangars = []
      for (let i in this.inventory.warehouses) {
        let wh = this.inventory.warehouses[i]

        if (wh.source_type && wh.source_type.includes('hangar_aInv_') && h.warehouse.source_id === wh.source_id) {
          hangars.push({id: i, warehouse: wh})
        }
      }

      return hangars.sort(function (a, b) {
        return Number(a.warehouse.source_id) - Number(b.warehouse.source_id);
      });
    }
  },
  computed: {
    inventory() {
      let inventoryState = this.$store.getters.getInventory

      if (!inventoryState.warehouses[inventoryState.warehouse]) {
        for (let wh in inventoryState.warehouses) {
          this.$store.commit({
            type: 'setCurrentWarehouse',
            warehouse: wh,
          });
        }
      }

      return inventoryState
    },
    hold() {
      for (let i in this.inventory.warehouses) {
        if (i === 'squadInventory') {
          return {id: i, warehouse: this.inventory.warehouses[i]}
        }
      }
    },
    storage() {
      for (let i in this.inventory.warehouses) {
        if (i === 'storage') {
          return {id: i, warehouse: this.inventory.warehouses[i]}
        }
      }
    },
    officeStocks() {
      let stocks = []
      for (let i in this.inventory.warehouses) {
        if (this.inventory.warehouses[i].source_type === 'office_stock') {
          stocks.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      return stocks.sort(function (a, b) {
        return Number(a.warehouse.source_id) - Number(b.warehouse.source_id);
      });
    },
    hangars() {
      let hangars = []
      for (let i in this.inventory.warehouses) {
        if (this.inventory.warehouses[i].source_type === 'hangar') {
          hangars.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      return hangars.sort(function (a, b) {
        return Number(a.warehouse.source_id) - Number(b.warehouse.source_id);
      });
    },
    scanner() {
      for (let i in this.inventory.warehouses) {
        if (i === 'scanner') {
          return {id: i, warehouse: this.inventory.warehouses[i]}
        }
      }
    },
    aInv() {
      let inventorys = []
      for (let i in this.inventory.warehouses) {
        if (!i.includes('hangar') && !i.includes('scanner') && i.includes('aInv')) {
          inventorys.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      // inventorys.sort(function(a, b){
      //   if(b.id < a.id) { return -1; }
      //   if(b.id > a.id) { return 1; }
      //   return 0;
      // })
      //
      // console.log(inventorys)
      return inventorys
    },
    scannerAInv() {
      let inventorys = []
      for (let i in this.inventory.warehouses) {
        if (i.includes('scannerAInv')) {
          inventorys.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      return inventorys
    },
    boxes() {
      let inventorys = []
      for (let i in this.inventory.warehouses) {
        if (i.includes('box') && !this.inventory.warehouses[i].object_owner) {
          inventorys.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      return inventorys
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    myRole() {
      let corp = this.corporation;
      if (corp) {
        return corp.roles[corp.members[this.currentPlayer.id].role_id]
      }

      return null
    },
    rights() {
      if (!this.inventory.warehouses[this.inventory.warehouse]) {
        return {view: false, take: false, put: false}
      }

      if (this.myRole && this.myRole.name === "Leader" && this.inventory.warehouse.includes('office_stock')) {
        return {view: true, take: false, put: false}
      }

      return false
    },
    officeNotActive() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('office_not_active') && errors['office_not_active'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'office_not_active',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    inventoryTransferNotAllow() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('inventory_transfer_not_allow') && errors['inventory_transfer_not_allow'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'inventory_transfer_not_allow',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    removeLastBody() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('remove_last_body') && errors['remove_last_body'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'remove_last_body',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    weightExceeded() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('weight_exceeded') && errors['weight_exceeded'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'weight_exceeded',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectBody() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "move_body";
    },
    selectMining() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "equip_mining";
    },
    selectHold() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "process_ore";
    },
    selectFuel() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "fill_fuel_body";
    },
    selectFuel2() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "fill_fuel_inventory";
    },
    selectStorage() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "move_body" || this.missions.currentAction.type_func_monitor === "fill_fuel_body" ||
        this.missions.currentAction.type_func_monitor === "install_weapon_and_ammo" || this.missions.currentAction.type_func_monitor === "equip_mining" ||
        this.missions.currentAction.type_func_monitor === "set_geo_equip";
    },
    selectWeaponAndAmmo() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "install_weapon_and_ammo"
    },
    selectAmmo() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "put_ammo"
    },
    selectGeoEquip() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "set_geo_equip"
    },
    corporationBaseNames() {
      return this.$store.getters.getCorporationBaseNames
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    tabs() {
      if (this.inventory.warehouse === 'aInv:fuel') {
        this.selectCategory("all")
        return [
          {category: "all", icon: "category_all_mini"},
          {category: "fuel", icon: "category_fuel_mini"},
        ]
      }

      if (['aInv:resource', 'aInv:organic', 'aInv:oil', 'aInv:dust'].includes(this.inventory.warehouse)) {
        this.selectCategory("all")
        return [
          {category: "all", icon: "category_all_mini"},
          {category: "resource", icon: "category_resource_mini"},
        ]
      }

      return [
        {category: "all", icon: "category_all_mini"},
        {category: "body", icon: "category_bodies_mini"},
        {category: "weapon", icon: "category_weapons_mini"},
        {category: "ammo", icon: "category_ammo_mini"},
        {category: "equip", icon: "category_equips_mini"},
        {category: "detail", icon: "category_details_mini"},
        {category: "resource", icon: "category_resource_mini"},
        {category: "fuel", icon: "category_fuel_mini"},
        {category: "blueprints", icon: "category_bluprint_mini"},
        {category: "boxes", icon: "category_box_mini"},
        {category: "trash", icon: "category_other_mini"},
        {category: "product", icon: "category_product_mini"},
      ]
    },
    throwItems() {
      return this.$store.getters.getThrowItemsState
    }
  },
  components: {
    AppControl: Control,
    AppSize: Size,
    AppItemCell: ItemCell,
    AppWarehouseTab: WarehouseTab,
    AppCellSubMenu: CellSubMenu,
    AppPlaceDialog: PlaceDialog,
    AppThrowItemsDialog: ThrowItemsDialog,
  }
}
</script>

<style scoped>
#wrapperInventoryAndStorage {
  position: absolute;
  top: 35%;
  left: calc(50% - 170px);
  z-index: 11;
  width: 345px;
  height: 226px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#warehousesTabs {
  float: left;
  height: 100%;
  width: 106px;;
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

#Inventory {
  width: 245px;
  height: 100%;
  user-select: none;
  float: right;
  pointer-events: auto;
}

.categoryTabs {
  margin-top: 4px;
  height: 20px;
  padding-left: 3px;
}

.categoryTabs ol {
  padding: 0;
  margin: 0;
}

.categoryTabs ol li {
  float: left;
  list-style: none;
  height: 16px;
  width: 16px;
  background: #9e9e9e;
  margin: 1px 3px 0 0;
  border-radius: 3px;
  background-size: 100%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 40%);
}

.categoryTabs ol .activeInventoryTabs .image {
  /*border: 0 solid transparent;*/
  /*box-shadow: inset 0 0 2px 1px black;*/
  /*height: 18px;*/
  /*width: 18px;*/
  /*background: #ce6f3c;*/
  filter: contrast(60%) sepia(100%) hue-rotate(320deg) brightness(1) saturate(800%) drop-shadow(0px 0px 0px black);
}

.categoryTabs ol li:hover {
  background-color: #d9d9d9;
}

.inventoryStorage {
  margin: 0 auto 0;
  overflow-y: scroll;
  height: 180px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  overflow-x: hidden;
  padding: 0;
  box-shadow: inset 0 0 2px black;
  backdrop-filter: blur(12px);
}

.hide {
  display: none !important;
}

.mask {
  position: absolute;
  left: 1px;
  top: 20px;
  height: calc(100% - 21px);
  width: calc(100% - 2px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  z-index: 16;
}

.image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  filter: contrast(100%) sepia(100%) hue-rotate(320deg) brightness(0.15) saturate(800%);
  background-repeat: no-repeat;
}

.RemoteWarehousesTabs {
  text-align: left;
  display: block;
  width: calc(100% - 7px);
  padding-left: 5px;
  background: #3486dd;
  box-shadow: 0 0 2px rgb(0 0 0);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 3px 0 0 3px;
  margin: 5px 0 0 2px;
  font-size: 11px;
  line-height: 15px;
  height: 15px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
}

.RemoteWarehousesTabs:active {
  transform: scale(0.99);
}

.RemoteWarehousesTabs:hover {
  background: #3a95f6;
}

.remote_menu {
  height: 12px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  padding: 1px;
  border-radius: 5px;
  z-index: 10;
}

.remote_menu input {
  display: block;
  width: 115px;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 3px;
  color: #fff;
  line-height: 11px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: left;
  margin-left: 2px;
}

.remote_menu input:active {
  transform: scale(0.98);
}

.remote_menu input:hover {
  background: rgba(255, 129, 0, 1);
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.categoryTabs ol li.categoryTabsButton {
  background: rgb(136, 68, 34);
}

.categoryTabs ol li.categoryTabsButton:hover {
  background: rgb(221, 112, 52)
}

.categoryTabs ol li.categoryTabsButton:active {
  transform: scale(0.97);
}

.categoryTabs ol li.categoryTabsButton .image {
  transition: 0.1s;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  pointer-events: none;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

</style>
