import {gameStore} from "../../../game/store";
import router from "../../../router/router";
import {RemoveGroupBar, UpdateGroupPlayer} from "../../../game/interface/group_bar";
import {DrawMapDialog, HideMapDialog} from "../../../game/interface/map_dialog";
import {CutsceneRouter, OneCutscene} from "../../../game/cutscenes/one";
import {ViewGhostBullet} from "../../../game/cutscenes/view_ghost_bullet";

export default function systemRead(msg, store) {
  if (msg.event === "SetGameTypes") {

    gameStore.gameTypes = msg.data
    gameStore.unitSpriteSize = msg.data.unit_sprite_size
    gameStore.mapBinItems = msg.data.map_bin_items
    gameStore.mapBinItems = Object.assign({}, ...Object.entries(gameStore.mapBinItems).map(([a, b]) => ({[b]: a})))

    store.commit({
      type: 'SetProductsType',
      products: msg.data.product,
    });

    store.commit({
      type: 'SetInit',
      id: 'items',
    });

    store.commit({
      type: 'setInterfaceHandBook',
      interface_handbook: msg.data.interface_handbook,
    });

    gameStore.init.items = true
  }

  if (msg.event === "st") {
    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: msg.data,
    });
  }

  if (msg.event === "stcib") {
    router.push('/lobby')
    store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "CheckInBase",
      }
    }));

    if (msg.data === "get_ui") {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetMissionState",
        }
      }));

      store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetUI",
        }
      }));
    }
  }

  if (msg.event === "GetPlayers") {

    store.commit({
      type: 'setLanguage',
      language: msg.data.user.language,
    });

    store.commit({
      type: 'setGameUserSate',
      gameUser: msg.data.user,
      userPlayers: msg.data.players,
    });
  }

  if (msg.event === "NeedChoiceFraction") {
    router.push('/choice_fraction')
  }

  if (msg.event === "choiceFractionComplete") {
    store.commit({
      type: 'setPlayerFraction',
      fraction: msg.data,
    });

    router.push('/lobby')
  }

  if (msg.event === "SetWindowsState") {
    store.commit({
      type: 'setHandBook',
      description_items: msg.data.description_items,
    });

    store.commit({
      type: 'setInterfaceState',
      user_interface: JSON.parse(msg.data.ui),
      allow_window_save: msg.data.allow_windows,
    });

    store.commit({
      type: 'setGameSettings',
      settings: msg.data.settings,
    });

    store.commit({
      type: 'setPlayer',
      player: msg.data.player,
    });

    gameStore.gameSettings = msg.data.settings

    store.commit({
      type: 'SetInit',
      id: 'interface',
    });

    store.commit({
      type: 'setNotOpenMails',
      count: msg.data.not_open_mails,
    });

    gameStore.init.interface = true
  }

  if (msg.event === 'GetCountNotOpenMails') {
    store.commit({
      type: 'setNotOpenMails',
      count: msg.data,
    });
  }

  if (msg.event === 'SetBattleRank') {
    store.commit({
      type: 'setBattleRank',
      data: msg.data,
    });
  }

  if (msg.event === 'CheckInBase') {
    if (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('choice_fraction') || location.href.includes('transfer')) {
      if (msg.data) {
        if (!location.href.includes('global')) {
          console.log("Корректировка: global")
          router.push({path: '/global'});
        }
      } else {
        if (!location.href.includes('lobby')) {
          console.log("Корректировка: lobby")
          router.push({path: '/lobby'});
        }
      }
    }
  }

  if (msg.e === "dialog") {
    if (msg.dialog_action) {
      dialogAction(msg.dialog_action, store);
    }

    if (msg.mission && msg.reward_items) {
      msg.mission.rewardItems = [];
      for (let i in msg.reward_items) {
        msg.mission.rewardItems.push(JSON.parse(msg.reward_items[i]))
      }
    }

    store.commit({
      type: 'setOpenDialog',
      page: msg.page,
      mission: msg.mission,
      visited_pages: msg.visited_pages,
    });

    if (msg.page) {
      DrawMapDialog(msg.x, msg.y);
    } else {
      HideMapDialog();
    }
  }

  if (msg.e === "udp") {
    DrawMapDialog(msg.x, msg.y)
  }

  if (msg.e === 'GetFractionNews') {
    store.commit({
      type: 'setFractionNews',
      news: msg.news,
    });
  }

  if (msg.event === 'OpenUserStat') {
    store.commit({
      type: 'addUserState',
      player: msg.data,
    });
  }

  if (msg.event === "GetMySkills") {
    store.commit({
      type: 'setMySkills',
      skills: msg.data.skills,
      points: msg.data.points,
    });
  }

  if (msg.e === "getAllFullMission") {
    store.commit({
      type: 'setAllFullMissions',
      data: msg,
    });
  }

  if (msg.e === "OpenMapMenu") {
    store.commit({
      type: 'setWorldMapState',
      maps: msg.maps,
      id: msg.id,
      defenders: msg.defenders,
      regions: msg.regions
    });
  }

  if (msg.event === "GetSecureHandlers") {
    store.commit({
      type: 'setSecureHandlers',
      secureHandlers: msg.data,
    });
  }

  if (msg.e === "umm") {
    store.commit({
      type: 'updateWorldMapState',
      update_maps: msg.um,
      id: msg.id,
    });
  }

  if (msg.e === "pmt") {
    store.commit({
      type: 'updatePriorityWarMap',
      attack: msg.ai,
      defend: msg.di,
    });
  }

  if (msg.event === "GetMapInfo") {
    store.commit({
      type: 'setMapInfo',
      mapID: msg.data.id,
      bases: msg.data.bases,
    });
  }

  if (msg.event === "PreviewPath") {
    store.commit({
      type: 'setPreviewPath',
      previewPath: msg.data,
    });
  }

  if (msg.event === "GetContacts") {
    store.commit({
      type: 'setFriends',
      friends: msg.data,
    });
  }

  if (msg.e === 'GetGroupState') {
    store.commit({
      type: 'setGroup',
      members: msg.m,
      leader: msg.l,
      online: msg.o,
      uuid: msg.uuid,
    });

    gameStore.groupState.members = msg.m;
    gameStore.groupState.leader = msg.l;

    if (msg.uuid === 0) {
      gameStore.groupState.state = {};
      gameStore.groupState.members = {};
      gameStore.groupState.leader = 0;
      RemoveGroupBar()
    }

    UpdateGroupPlayer(true)
  }

  if (msg.event === "RejectInvitation") {
    console.log(msg)
  }

  if (msg.e === "GetCurrentMission") {
    let actionJson = {};
    if (msg.actions !== "") {
      actionJson = JSON.parse(msg.actions)
    }

    if (document.getElementById('user_state_mission_block')) {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetAllFullMission",
        }
      }));
    }

    store.commit({
      type: 'setMission',
      missions: msg.missions,
      mission_uuid: msg.mission_uuid,
      actions: actionJson,
      need_items_slots: msg.need_items_slots,
    });
  }

  if (msg.e === "nn") {
    notify(msg.n, store)
  }

  if (msg.e === "dn") {
    store.commit({
      type: 'removeNotify',
      uuid: msg.uuid,
    });
  }

  if (msg.event === "Search") {
    store.commit({
      type: 'setSearch',
      search: msg.data,
    });
  }

  if (msg.event === "GetExitTime") {
    store.commit({
      type: 'setExitTime',
      time: msg.data.et,
      vio_type: msg.data.vt,
      vio_time: msg.data.vtt,
      rank_update: msg.data.u,
    });
  }

  if (msg.event === "all_bots") {
    store.commit({
      type: 'setBotsState',
      bots: msg.data.all_bots,
    });
  }

  if (msg.event === "full_bot") {
    store.commit({
      type: 'setBotInfo',
      bot: msg.data.full_bot_info,
    });
  }

  if (msg.event === "grb") {

    store.commit({
      type: 'SetRelateBonuses',
      data: msg.data,
    });

    gameStore.relationBonuses = msg.data;
  }

  if (msg.event === "GetTimeAttack") {
    store.commit({
      type: 'setTimeAttack',
      t: msg.data.hour,
    });
  }

  if (msg.e === "scs") {
    CutsceneRouter(msg)
  }

  if (msg.e === "vgb") {
    ViewGhostBullet(msg.id)
  }
}

function dialogAction(action, store) {
  if (action === "close" || action === "start_training" || action === "miss_training") {
    store.commit({
      type: 'setOpenDialog',
      page: null,
      mission: null,
      visited_pages: null,
    });
  }
  if (action === "start_training") {
    store.commit({
      type: 'setTraining',
      training: 1,
    });
  }
}

function notify(data, store) {

  let deleteNotify = function (uuid) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "DeleteNotify",
        uuid: uuid,
      }
    }));
  }

  if (data.event === "update_ammo") {
    let infoBullet = gameStore.gameTypes.ammo[data.ammo_id];

    for (let i in gameStore.unitState.weapon_slots) {
      let weapon = gameStore.unitState.weapon_slots[i];
      if (weapon.number_slot === data.to) {
        gameStore.unitState.weapon_slots[i].ammo = infoBullet
      }
    }

    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "update_mails") {
    store.commit({
      type: 'setNotOpenMails',
      count: data.count,
    });

    store.commit({
      type: 'setUpdateMails',
      update: true,
    });

    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "update_action_count") {

    store.commit({
      type: 'updateActionCount',
      mission: data.data.mission,
      action: data.data.action,
      slot: data.data.slot,
      find_count: data.data.find_count,
    });

    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "battle_kill_broadcast") {
    store.commit({
      type: 'addKillsNotify',
      notify: data,
    });
    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "game_point") {
    store.commit({
      type: 'addPointsNotify',
      notify: data,
    });
    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "open_dialog") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "getCurrentDialog",
      }
    }));
    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "group_exit_queue") {
    addError('Группа неготова', store)
    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "update_credits") {
    store.commit({
      type: 'setCredits',
      my_credits: data.credits,
    });
    deleteNotify(data.uuid);
    return;
  }

  if (data.event === "update_corp_credits") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporationDeposits",
      }
    }));
    return;
  }

  if (data.event === "update_corp_base") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporationBases",
      }
    }));
    return;
  }

  if (data.event === "update_corp_office") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetAllCorporationOffices",
      }
    }));
    return;
  }

  if (data.event === "update_corp_contacts") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetContacts",
      }
    }));
    return;
  }

  if (data.event === "remove_global_map_cache") {
    let components = store.getters.getNeedOpenComponents

    if (components['GlobalViewMap'] && components['GlobalViewMap'].open) {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetSecureHandlers",
        }
      }));
    } else {
      store.commit({
        type: 'removeWorldMapState',
      });
    }

    return;
  }

  if (data.event === "change_relation") {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "grb",
      }
    }));
  }

  if (data.event === "change_sector") {
    console.log("Change sector")
    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Обновляем карту...',
        'EN': 'Updating the map...',
      }
    });

    if (location.pathname.includes("global")) {
      gameStore.gameDataInit.data = false;
      gameStore.gameDataInit.sendRequest = false;
    } else if (location.href.includes('lobby')) {
      router.push('/global');
    }

    deleteNotify(data.uuid);
  }

  if (data.event === "to_base") {
    console.log(data)
    if (!location.pathname.includes("lobby")) {
      gameStore.noLoader = true;
      store.commit({
        type: 'setVisibleLoader',
        visible: true,
        text: {
          'RU': 'Входим на базу...',
          'EN': 'Entering the base...',
        }
      });
      router.push('/lobby');
    }
    deleteNotify(data.uuid);
  }

  if (data.modal_text) {
    if (!data.text[store.getters.getSettings.Language].includes('server_hidden')) {
      store.commit({
        type: 'toggleWindow',
        id: data.uuid,
        component: 'modalBlock',
        meta: data,
        forceOpen: true,
      });
    }
    deleteNotify(data.uuid);
    return;
  }

  if (data.update_inventory) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
        name: data.data,
      }
    }));

    deleteNotify(data.uuid);
    return;
  }

  if (data.update_chat) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "chat",
      data: {
        event: "OpenChat",
      }
    }));

    deleteNotify(data.uuid);
    return;
  }

  if (data.update_mission) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetMissionState",
      }
    }));

    deleteNotify(data.uuid);
    return;
  }

  if (data.skill_up) {
    store.commit({
      type: 'addNotification',
      id: data.uuid,
      removeSec: 5,
      html: getSkillLvlUpMsg(store, data),
    });
    deleteNotify(data.uuid);
  }

  store.commit({
    type: 'addNotify',
    notify: data,
  });
}

function getSkillLvlUpMsg(store, notify) {
  if (store.getters.getSettings.Language === 'RU') {
    return `Уровень <span class="importantly"> ${notify.text[store.getters.getSettings.Language]} </span> повышен до
                <span class="importantly"> ${notify.count} </span> уровня.`
  } else {
    return `Lvl up <span class="importantly"> ${notify.text[store.getters.getSettings.Language]} </span> to
                <span class="importantly"> ${notify.count} </span> level.`
  }
}
