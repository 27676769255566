import {GrabCamera, LazyCamera} from './interface/grabCamera'
import {FocusUnit} from './interface/focus_unit'
import {UpdateFogBack, UpdateObjectFog, UpdateUnitFog} from './interface/fog_of_war'
import {CreateAllMaps} from './map/createMap'
import {gameStore} from './store'
import store from '../store/store'
import {getBarScale, PosUpdateUnitHP, UpdatePosBars} from "./interface/status_layer"
import {Scene} from "./create";
import {MoveTo} from "./unit/move";
import {ServerGunDesignator, updateAimBoxPos} from "./interface/server_gun_designator";
import {FlyBullet} from "./bullet/fly";
import {DroneTo} from "./drone/fly";
import {BoxMove} from "./box/move";
import {rotatePoint} from "./utils/utils";
import {ObjectTo} from "./map/move_object";
import {
  DrawMissionPath,
  initMiniMap,
  RenderGlobalPathInMiniMap,
  setPositionMapRectangle,
  setPositionObject,
  SetPositionRadarRing,
  setUserUnit,
  updateMinimapLabels
} from "./interface/mini_map";
import {initUI} from "./interface/status_bar";
import {initPanel} from "./interface/equip_bar";
import {ItemMove} from "./map_items/move";
import {initGroupBar} from "./interface/group_bar";
import {initJoystick} from "./interface/joystick";
import {deleteBullet, removedBullets} from "./bullet/remove";
import {placeStructure} from "./build/select_place_build";
import {SetDayCycleUnit} from "./day_cycle/day_cycle";
import {ParseMsg} from "./messages_queue";
import {colorName} from "./unit/unit";
import {UpdatePosPumpMiniGame} from "./reservoir/pump_mini_game";
import {MovePollen} from "./pollen/move";
import {sendInputsToBack} from "./send_input_data";
import {UpdateShieldPos} from "./shield/shield";

let connect = null;
let userUnit = false;
let tickTime = 64;
let serverMoveLag = 0; // благодаря этой задержки мы убиваем "рывки" в движениях

function gameInit(scene) {
  if (!(location.href.includes('global') || location.href.includes('map_editor'))) {
    gameStore.reload = true;
    gameStore.unitReady = false;
    return
  }

  if (!gameStore.gameDataInit.sendRequest) {
    gameStore.gameDataInit.sendRequest = true;
    gameStore.radarWork = false;

    if (!gameStore.mapEditor) {
      console.log("SEND INIT GAME")
      store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "InitGame",
        }
      }));
    }
  }

  if (!gameStore.mapEditor) {
    if (gameStore.reload || !gameStore.gameDataInit.data || !gameStore.init.items ||
      !gameStore.init.interface || !gameStore.init.inventory) return;
  }

  if (!gameStore.gameReady) {
    if (!gameStore.mapEditor) {
      connect = store.getters.getNodeWS;
    }

    if (!connect || !connect.ws || !connect.connect) return;

    // todo ксотыль без которого карта при переходе из сектра в сектор артефачит
    if (!gameStore.mapDrawing) {
      gameStore.mapDrawing = true;
      setTimeout(function () {
        CreateAllMaps(scene);
        gameStore.gameReady = true;
        gameStore.mapDrawing = false;

        let currentZoom = store.getters.getSettings.ZoomCamera;
        if (!gameStore.mapEditor) Scene.cameras.main.setZoom(currentZoom);
      })
    }

    if (!gameStore.mapEditor) {
      connect.ws.postMessage(JSON.stringify({
        event: "StartLoad",
      }));
    }
  }
}

function update() {
  ParseMsg()

  if (gameStore.exitTab) {
    return;
  }

  if (!gameStore.gameReady) {
    gameInit(this)
    return;
  }

  if (!gameStore.OtherFollowSprite) {
    if (!gameStore.gameSettings.follow_camera || gameStore.mapEditor) {
      GrabCamera(this);  // функцуия для перетаскивания карты мышкой /* Магия */
    }

    if (gameStore.unitReady && gameStore.gameSettings.follow_camera && gameStore.gameSettings.lazy_camera) { // todo вынести в настройки
      LazyCamera(this)
    }
  }

  if (!connect || !connect.ws || !connect.connect) return;

  if (!gameStore.unitReady && gameStore.radarWork) {
    let unit = gameStore.units[gameStore.user_squad_id];
    if (unit && unit.sprite) {
      setUnitReady(unit.id)
    }
  }

  if (placeStructure) {
    if (this.wasd.left.isDown) {
      placeStructure.objectSprite.angle++;
    } else if (this.wasd.right.isDown) {
      placeStructure.objectSprite.angle--;
    }
  }

  if (!gameStore.mapEditor) {
    sendInputsToBack(this, connect);
    UpdateFogBack(this);
    processUnit(this);
    processDrones(this);
    processBullets(this);
    processPollen(this);
    processObjects(this);
    processBoxes(this);
    processMarks(this);
    processItems(this)
    updateAimBoxPos(this);
    updateMinimapLabels();
    UpdatePosPumpMiniGame();
    initJoystick();
    UpdateShieldPos();

    setPositionMapRectangle();

    initUI();
    initMiniMap();
    initPanel();
    initGroupBar();

    // testGlitch();
  } else {
    if (gameStore.mapEditorState.placeObj) {
      if (this.wasd.left.isDown) {
        gameStore.mapEditorState.placeObj.angle++;
      } else if (this.wasd.right.isDown) {
        gameStore.mapEditorState.placeObj.angle--;
      }
    }
  }

  // if (!decalSprite) {
  //   decalSprite = Scene.make.image({
  //     key: 'd' + 8, x: 0, y: 0, add: true
  //   });
  //   decalSprite.setOrigin(0.5);
  //   decalSprite.setAngle(0);
  //   decalSprite.setAlpha(0.3)
  //   decalSprite.setDepth(999)
  // } else {
  //   decalSprite.setPosition(
  //     Scene.input.mousePointer.x / Scene.cameras.main.zoom + Scene.cameras.main.worldView.x,
  //     Scene.input.mousePointer.y / Scene.cameras.main.zoom + Scene.cameras.main.worldView.y)
  // }
}

// let decalSprite
// function testGlitch() {
//   let pos = GetGlobalPos(5299, 2696, gameStore.map.id);
//   for (let i in gameStore.units) {
//     if (gameStore.playerID === gameStore.units[i].owner_id) {
//       let unit = gameStore.units[gameStore.units[i].id];
//
//       let d = Phaser.Math.Distance.Between(pos.x, pos.y, unit.sprite.x, unit.sprite.y);
//       if (d < 1000) {
//         let percent = (100 - ((d / 1000) * 100)) / 20
//         store.commit({
//           type: 'setGlitch',
//           f: percent,
//         });
//         console.log(percent)
//       } else {
//         store.commit({
//           type: 'setGlitch',
//           f: 0,
//         });
//       }
//     }
//   }
// }

function setUnitReady(focusUnit) {
  gameStore.unitReady = true;
  gameStore.playerUnitID = focusUnit;
  Scene.game.input.activePointer.moveTime = 0;
  gameStore.focusUnitID = 0;

  if (focusUnit) FocusUnit(focusUnit, true, true);
  store.commit({
    type: 'setVisibleLoader',
    visible: false,
  });

  //setTimeout(function () {
  connect.ws.postMessage(JSON.stringify({
    event: "LoadComplete",
  }));
  //}, 1000)
}

function processMarks(scene) {
  for (let i in gameStore.radar_marks) {
    gameStore.radar_marks[i].sprite.setVisible(Scene.cameras.main.worldView.contains(gameStore.radar_marks[i].sprite.x, gameStore.radar_marks[i].sprite.y))
    setPositionObject(gameStore.radar_marks[i].type, gameStore.radar_marks[i].sprite, 150, 150, 0, 0)
  }
}

function processUnit(scene) {
  let findUserUnit = false

  for (let i in gameStore.units) {
    let unit = gameStore.units[i];
    let view = !unit.ghost && Scene.cameras.main.worldView.contains(unit.sprite.x, unit.sprite.y)

    if (unit.updaterPos && (!unit.sprite.moveTween || !unit.sprite.moveTween.isPlaying() || unit.bufferMoveTick.length > serverMoveLag)) {
      MoveTo(unit, tickTime, view);
    }

    processUnitWeapon(unit)
    processUnitEquip(unit)
    processUnitBottom(unit)
    processLights(unit)

    setPositionObject('ground', unit.sprite, unit.sprite.displayWidth * 4, unit.sprite.displayHeight * 4,
      unit.owner_id, unit.warrior, unit.fraction, unit.corporation_id, true)

    if (unit.sprite.damageText) {
      if (unit.sprite.damageText.sprite) {
        unit.sprite.damageText.sprite.setPosition(unit.sprite.x + unit.sprite.damageText.x_offset, unit.sprite.y + unit.sprite.damageText.y_offset)
      }

      if (unit.sprite.damageText.damageTypeSprite) {
        unit.sprite.damageText.damageTypeSprite.setPosition(unit.sprite.x + unit.sprite.damageText.x_offset, unit.sprite.y + unit.sprite.damageText.y_offset - 13)
      }
    }

    if (unit.sprite.userMessage) {
      if (unit.sprite.userMessage.text) unit.sprite.userMessage.text.setPosition(unit.sprite.x, unit.sprite.y - 45);
    }

    if (unit.sprite.userName) {
      if (!view) {
        if (unit.sprite.userName.login && unit.sprite.userName.login.visible) unit.sprite.userName.login.setVisible(false)
        if (unit.sprite.userName.pointer.visible) unit.sprite.userName.pointer.setVisible(false)
      } else {

        if (unit.sprite.userName.login) {
          if (!unit.sprite.userName.login.visible) unit.sprite.userName.login.setVisible(true)
          unit.sprite.userName.login.setPosition(unit.sprite.x, unit.sprite.y - (20 + (7 * getBarScale())));
          unit.sprite.userName.login.setScale(getBarScale());
        }

        if (unit.sprite.userName.pointer) {
          if (!unit.sprite.userName.pointer.visible) unit.sprite.userName.pointer.setVisible(true)
          unit.sprite.userName.pointer.setPosition(unit.sprite.x, unit.sprite.y - 17);
          unit.sprite.userName.pointer.setScale(0.2 * getBarScale());
        }

        if (unit.sprite.userName.attitude) {
          if (!unit.sprite.userName.attitude.visible) unit.sprite.userName.attitude.setVisible(true)
          if (unit.sprite.userName.login) {
            unit.sprite.userName.attitude.setPosition(unit.sprite.x - (unit.sprite.userName.login.width / 2) - (9 * getBarScale()), unit.sprite.y - (20 + (7 * getBarScale())));
          } else {
            unit.sprite.userName.attitude.setPosition(unit.sprite.x, unit.sprite.y - (20 + (7 * getBarScale())));
          }

          unit.sprite.userName.attitude.setDisplaySize(12 * getBarScale(), 12 * getBarScale());
        }
      }
    }

    if (unit.body.max_hp > 50) {
      PosUpdateUnitHP(unit)
      colorName(unit)
    }

    if (unit.id === gameStore.user_squad_id) {
      UpdateUnitFog(scene, unit);
      SetPositionRadarRing(unit);
      RenderGlobalPathInMiniMap(unit);
      DrawMissionPath(unit);
      findUserUnit = unit
      setUserUnit(unit)
    }

    if (unit.id !== gameStore.user_squad_id && checkView(unit.owner_id, unit.warrior, unit.fraction, unit.corporation_id)) {
      UpdateObjectFog(scene, unit, unit.sprite, "unit", unit.body_state.range_view);
    } else if (gameStore.FogOfWar.back && gameStore.FogOfWar.objBrush.unit[i]) {
      gameStore.FogOfWar.objBrush.unit[i].destroy();
      delete gameStore.FogOfWar.objBrush.unit[i];
    }

    if (unit.sprite.passUnitSelectSprite) {
      unit.sprite.passUnitSelectSprite.setPosition(unit.sprite.x, unit.sprite.y)
    }

    if (unit.sprite.noPassUnitSelectSprite) {
      unit.sprite.noPassUnitSelectSprite.setPosition(unit.sprite.x, unit.sprite.y)
    }

    unit.sprite.explode_mask.graphics.x = unit.sprite.x;
    unit.sprite.explode_mask.graphics.y = unit.sprite.y;
  }

  userUnit = findUserUnit
  if (!findUserUnit) {
    gameStore.unitReady = false
    ServerGunDesignator({hide: true})
  }
}

function processUnitWeapon(unit) {
  for (let i in unit.weapons) {

    let newPos = rotatePoint(unit.weapons[i].weapon.xAttach, unit.weapons[i].weapon.yAttach, unit.sprite.angle)

    unit.weapons[i].weapon.setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));
    if (unit.weapons[i].weapon.scale !== unit.sprite.scale) unit.weapons[i].weapon.setScale(unit.sprite.scale)
    if (unit.weapons[i].weapon.depth !== unit.sprite.depth + 2) unit.weapons[i].weapon.setDepth(unit.sprite.depth + 2)
  }
}

function processUnitEquip(unit) {
  for (let i in unit.equips) {
    let newPos = rotatePoint(unit.equips[i].xAttach, unit.equips[i].yAttach, unit.sprite.angle)

    unit.equips[i].sprite.setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));
    if (unit.equips[i].sprite.scale !== unit.sprite.scale) unit.equips[i].sprite.setScale(unit.sprite.scale)
    if (unit.equips[i].sprite.depth !== unit.sprite.depth + 1) unit.equips[i].sprite.setDepth(unit.sprite.depth + 1)
  }
}

function processUnitBottom(unit) {
  let shadowXOffset = Scene.shadowXOffset / 3;
  let shadowYOffset = Scene.shadowXOffset / 3;
  if (unit.body.fraction === "Fauna") {
    shadowXOffset = 1;
    shadowYOffset = 1;
  }

  if (unit.sprite.damage) {
    unit.sprite.damage.setPosition(unit.sprite.x, unit.sprite.y);
    unit.sprite.damage.setAngle(unit.sprite.angle);
  }

  if (unit.body.chassis_type === "wheels") {
    unit.sprite.bodyBottom.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottom.angle !== unit.sprite.angle) unit.sprite.bodyBottom.setAngle(unit.sprite.angle)
    if (unit.sprite.bodyBottom.scale !== unit.sprite.scale) unit.sprite.bodyBottom.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottom.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottom.setDepth(unit.sprite.depth - 1)

    let newRightPos = rotatePoint(unit.body.wheel_anchors["right_front_wheel_attach"].real_x_attach, unit.body.wheel_anchors["right_front_wheel_attach"].real_y_attach, unit.sprite.angle)

    unit.sprite.bodyBottomRight.setPosition(unit.sprite.x + (newRightPos.x * unit.sprite.scale), unit.sprite.y + (newRightPos.y * unit.sprite.scale))

    let newLeftPos = rotatePoint(unit.body.wheel_anchors["left_front_wheel_attach"].real_x_attach, unit.body.wheel_anchors["left_front_wheel_attach"].real_y_attach, unit.sprite.angle)

    unit.sprite.bodyBottomLeft.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    unit.sprite.bodyBottomRightShadow.setPosition(unit.sprite.bodyBottomRight.x + shadowXOffset, unit.sprite.bodyBottomRight.y + shadowYOffset);
    if (unit.sprite.bodyBottomRightShadow.angle !== unit.sprite.angle) unit.sprite.bodyBottomRightShadow.setAngle(unit.sprite.bodyBottomRight.angle)
    if (unit.sprite.bodyBottomRightShadow.scale !== unit.sprite.scale) unit.sprite.bodyBottomRightShadow.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomRightShadow.depth !== unit.sprite.depth - 2) unit.sprite.bodyBottomRightShadow.setDepth(unit.sprite.depth - 2)

    unit.sprite.bodyBottomLeftShadow.setPosition(unit.sprite.bodyBottomLeft.x + shadowXOffset, unit.sprite.bodyBottomLeft.y + shadowYOffset);
    if (unit.sprite.bodyBottomLeftShadow.angle !== unit.sprite.angle) unit.sprite.bodyBottomLeftShadow.setAngle(unit.sprite.bodyBottomLeft.angle)
    if (unit.sprite.bodyBottomLeftShadow.scale !== unit.sprite.scale) unit.sprite.bodyBottomLeftShadow.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomLeftShadow.depth !== unit.sprite.depth - 2) unit.sprite.bodyBottomLeftShadow.setDepth(unit.sprite.depth - 2)
  }

  if (unit.body.chassis_type === "caterpillars") {
    unit.sprite.bodyBottomLeft.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottomLeft.angle !== unit.sprite.angle) unit.sprite.bodyBottomLeft.setAngle(unit.sprite.angle)

    unit.sprite.bodyBottomRight.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.bodyBottomRight.angle !== unit.sprite.angle) unit.sprite.bodyBottomRight.setAngle(unit.sprite.angle)
  }

  if (unit.body.chassis_type === "caterpillars" || unit.body.chassis_type === "wheels") {
    if (unit.sprite.bodyBottomLeft.scale !== unit.sprite.scale) unit.sprite.bodyBottomLeft.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomLeft.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottomLeft.setDepth(unit.sprite.depth - 1)

    if (unit.sprite.bodyBottomRight.scale !== unit.sprite.scale) unit.sprite.bodyBottomRight.setScale(unit.sprite.scale)
    if (unit.sprite.bodyBottomRight.depth !== unit.sprite.depth - 1) unit.sprite.bodyBottomRight.setDepth(unit.sprite.depth - 1)
  }

  if (unit.body.chassis_type === "antigravity") {
    unit.sprite.antigravityPlace.setPosition(unit.sprite.x, unit.sprite.y);
    if (unit.sprite.antigravityPlace.angle !== unit.sprite.angle) unit.sprite.antigravityPlace.setAngle(unit.sprite.angle)
    if (unit.sprite.antigravityPlace.scale !== unit.sprite.scale) unit.sprite.antigravityPlace.setScale(unit.sprite.scale)
    if (unit.sprite.antigravityPlace.depth !== unit.sprite.depth - 1) unit.sprite.antigravityPlace.setDepth(unit.sprite.depth - 1)

    // BACK ENGAGE
    let newLeftPos = rotatePoint(unit.body.wheel_anchors["back_engine"].real_x_attach, unit.body.wheel_anchors["back_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.backEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.backEngine.angle !== unit.sprite.angle) unit.sprite.backEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.backEngine.scale !== unit.sprite.scale) unit.sprite.backEngine.setScale(unit.sprite.scale)
    if (unit.sprite.backEngine.depth !== unit.sprite.depth - 1) unit.sprite.backEngine.setDepth(unit.sprite.depth - 1)

    // Right ENGAGE
    newLeftPos = rotatePoint(unit.body.wheel_anchors["right_engine"].real_x_attach, unit.body.wheel_anchors["right_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.rightEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.rightEngine.angle !== unit.sprite.angle) unit.sprite.rightEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.rightEngine.scale !== unit.sprite.scale) unit.sprite.rightEngine.setScale(unit.sprite.scale)
    if (unit.sprite.rightEngine.depth !== unit.sprite.depth - 1) unit.sprite.rightEngine.setDepth(unit.sprite.depth - 1)

    // Left ENGAGE
    newLeftPos = rotatePoint(unit.body.wheel_anchors["left_engine"].real_x_attach, unit.body.wheel_anchors["left_engine"].real_y_attach, unit.sprite.angle)
    unit.sprite.leftEngine.setPosition(unit.sprite.x + (newLeftPos.x * unit.sprite.scale), unit.sprite.y + (newLeftPos.y * unit.sprite.scale))

    if (unit.sprite.leftEngine.angle !== unit.sprite.angle) unit.sprite.leftEngine.setAngle(unit.sprite.angle)
    if (unit.sprite.leftEngine.scale !== unit.sprite.scale) unit.sprite.leftEngine.setScale(unit.sprite.scale)
    if (unit.sprite.leftEngine.depth !== unit.sprite.depth - 1) unit.sprite.leftEngine.setDepth(unit.sprite.depth - 1)
  }

  unit.sprite.shadow.setPosition(unit.sprite.x + shadowXOffset, unit.sprite.y + shadowYOffset);
  if (unit.sprite.shadow.angle !== unit.sprite.angle) unit.sprite.shadow.setAngle(unit.sprite.angle)
  if (unit.sprite.shadow.scale !== unit.sprite.scale) unit.sprite.shadow.setScale(unit.sprite.scale)
  if (unit.sprite.shadow.depth !== unit.sprite.depth - 2) unit.sprite.shadow.setDepth(unit.sprite.depth - 2)
}

function processLights(unit) {
  if (unit.light) {
    // если нет создаем, если есть обновляем позиции
    if (!unit.lights) {
      unit.lights = {}

      for (let i in unit.body.wheel_anchors) {
        if (i.includes('light')) {
          let newPos = rotatePoint(unit.body.wheel_anchors[i].real_x_attach, unit.body.wheel_anchors[i].real_y_attach, unit.sprite.angle);

          if (i.includes('out')) {
            let r = 50
            if (i === 'light_out_2') r = 65;
            if (i === 'light_out_3') r = 75;
            if (i === 'light_out_4') r = 85;
            if (i === 'light_out_5') r = 90;

            unit.lights[i] = Scene.lights.addLight(
              unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale),
              r, 0xffffff, 0.5);
          } else {
            unit.lights[i] = Scene.add.pointlight(
              unit.sprite.x + (newPos.x * unit.sprite.scale),
              unit.sprite.y + (newPos.y * unit.sprite.scale),
              0, 3, 0.75);

            unit.lights[i].attenuation = 0.04
            unit.lights[i].setDepth(unit.sprite.depth + 4)
            unit.lights[i].color.setTo(255, 255, 255);
          }
        }
      }

      SetDayCycleUnit(unit)
    }

    for (let i in unit.lights) {
      let newPos = rotatePoint(unit.body.wheel_anchors[i].real_x_attach, unit.body.wheel_anchors[i].real_y_attach, unit.sprite.angle);
      unit.lights[i].setPosition(unit.sprite.x + (newPos.x * unit.sprite.scale), unit.sprite.y + (newPos.y * unit.sprite.scale));

      if (!unit.lights[i].visible) unit.lights[i].setVisible(true)
    }

  } else {
    for (let i in unit.lights) {
      if (unit.lights[i].visible) unit.lights[i].setVisible(false)
    }
  }
}

function processPollen() {
  for (let i in gameStore.pollens) {
    let p = gameStore.pollens[i];

    if ((!p.moveTween || !p.moveTween.isPlaying() || p.bufferMoveTick.length > serverMoveLag) && p.updaterPos) {
      MovePollen(p, tickTime)
    }

    setPositionObject('resource', p.sprite, 128, 128)
  }
}

function processBullets() {
  for (let i in gameStore.bullets) {
    let bullet = gameStore.bullets[i];

    if (removedBullets[i]) {
      console.log("update remove bullet: ", i, " : ", bullet);
      deleteBullet(i, true);
      continue;
    }

    if (bullet.fairy) {
      bullet.fairy.setPosition(bullet.sprite.x, bullet.sprite.y)
    }

    if ((!bullet.moveTween || !bullet.moveTween.isPlaying() || bullet.bufferMoveTick.length > serverMoveLag) && bullet.updaterPos) {
      FlyBullet(bullet, tickTime)
    }

    setPositionObject('bullet', bullet.sprite, 64, 64)
  }
}

function processBoxes(scene) {
  for (let i in gameStore.boxes) {
    let box = gameStore.boxes[i];
    if (!box.moveTween || !box.moveTween.isPlaying() || box.bufferMoveTick.length > serverMoveLag) {
      if (box.updaterPos) BoxMove(box, tickTime)
    }
    UpdatePosBars(box.sprite, box.max_hp, box.hp, 4, null, Scene, 'box', box.id, 'hp', box.max_hp / 5);
    setPositionObject('structure', box.sprite, 150, 150)
  }
}

function processItems(scene) {
  for (let i in gameStore.mapItems) {
    let item = gameStore.mapItems[i];

    let visible = Scene.cameras.main.worldView.contains(item.sprite.x, item.sprite.y)
    item.sprite.setVisible(visible);
    item.shadow.setVisible(visible);
    item.light.setVisible(visible);

    if (!item.moveTween || !item.moveTween.isPlaying() || item.bufferMoveTick.length > serverMoveLag) {
      if (item.updaterPos) ItemMove(item, tickTime)
      setPositionObject('map_item', item.sprite, 64, 64)
    }
  }
}


function processDrones(scene) {
  for (let i in gameStore.drones) {
    let d = gameStore.drones[i];
    if (!d.moveTween || !d.moveTween.isPlaying() || d.bufferMoveTick.length > serverMoveLag) {
      if (d.updaterPos) {
        DroneTo(d, tickTime, i)
      } else {
        d.speed = 0
      }
    }

    let fractionView = function (d) {
      if (d.fraction === 5 || d.owner_id !== 0) return false;
      if (d.fraction === 1 && gameStore.relationBonuses.Replics.fraction_drone_view) return true; // Replics
      if (d.fraction === 2 && gameStore.relationBonuses.Explores.fraction_drone_view) return true; // Explores
      if (d.fraction === 3 && gameStore.relationBonuses.Reverses.fraction_drone_view) return true; // Reverses
    }

    if (checkView(d.owner_id, d.warrior, d.fraction, d.corporation_id) || fractionView(d)) {
      UpdateObjectFog(scene, d, d.sprite, "drone", d.range_view);
    }

    if (d.max_hp !== 99990) {
      UpdatePosBars(d.sprite, d.max_hp, d.hp, 0, null, scene, 'drone', d.id, 'hp', 1);
      if (d.life_percent <= 100) {
        UpdatePosBars(d.sprite, 100, d.life_percent, 6, 0x00ffd6, scene, 'drone', d.id, 'energy', 20)
      }
    }

    setPositionObject('fly', d.sprite, 150, 150, d.owner_id, d.warrior, d.fraction, d.corporation_id, true)
    if (d.sprite.engages) processDroneEngages(d)
  }
}

function processDroneEngages(d) {
  for (let k in d.sprite.engages) {

    let e = d.sprite.engages[k];
    let newRightPos = rotatePoint(e.real_x_attach, e.real_y_attach, d.sprite.angle)
    e.emitter.setPosition(
      d.sprite.x + (newRightPos.x * d.sprite.scale),
      d.sprite.y + (newRightPos.y * d.sprite.scale),
    )

    if (d.sprite.engages[k].typeEngage === "2" || d.sprite.engages[k].typeEngage === "3") {
      let newAngle = {min: 180 + d.sprite.angle + e.offsetRotate, max: 180 + d.sprite.angle + e.offsetRotate}
      if (!(e.emitter.ops.angle.start === newAngle.min && e.emitter.ops.angle.end === newAngle.max)) {
        //e.emitter.setEmitterAngle(newAngle);
        // TODO костыль т.к. стандарные методы не работают
        e.emitter.ops.angle.start = newAngle.min;
        e.emitter.ops.angle.end = newAngle.max;
      }

      let speed = 5
      if (d.sprite.engages[k].typeEngage === "3") {
        speed = 3
      }

      let offsetSpeed = 1
      if (e.offsetRotate > 0 && d.angularVelocity < 0) offsetSpeed = 0.5;
      if (e.offsetRotate < 0 && d.angularVelocity > 0) offsetSpeed = 0.5;

      e.emitter.ops.speedX.end = (d.speed * speed) * offsetSpeed;
      e.emitter.ops.speedY.end = (d.speed * speed) * offsetSpeed;

      e.emitter.ops.scaleX.start = (((d.speed * 0.02) + 0.05) * e.offsetScale);
      e.emitter.ops.scaleX.end = 0;

      // e.emitter.setSpeed({min: 0, max: (d.speed * 25) * offsetSpeed});
      // e.emitter.setScale({start: (((d.speed * 0.02) + 0.05) * e.offsetScale) * offsetSpeed, end: 0})
    }

    // todo if (unit.weapons[i].weapon.scale !== unit.sprite.scale) unit.weapons[i].weapon.setScale(unit.sprite.scale)
    if (e.depth !== (d.sprite.depth - 2) + e.offsetHeight) e.emitter.setDepth(d.sprite.depth - 2 + e.offsetHeight);
  }
}

function processObjects(scene) {
  for (let i in gameStore.objects) {
    let obj = gameStore.objects[i];
    if (!obj) continue;

    if (!obj.moveTween || !obj.moveTween.isPlaying() || obj.bufferMoveTick.length > serverMoveLag) {
      if (obj.updaterPos) ObjectTo(obj, tickTime)
    }

    switchVisibleObj(obj)

    if (obj.work) {
      if (checkView(obj.owner_id, obj.warrior, obj.fraction, obj.corporation_id)) {
        UpdateObjectFog(scene, obj, obj.objectSprite, "object", obj.range_view)
      }
    }

    if (obj.build) {
      setPositionObject('structure', obj.objectSprite, 130, 130, obj.owner_id,
        obj.warrior, obj.fraction, obj.corporation_id, obj.work)
    }
  }
}

// TODO вероятны проблемы с производиткельностью
function scaleBar() {

}

function checkView(ownerId, warrior, fraction, corporationID) {
  if (ownerId === gameStore.playerID || gameStore.groupState.state[ownerId]) {
    return true
  }

  if (corporationID > 0 && gameStore.mapCorporationID === gameStore.playerCorporationID && gameStore.mapCorporationID === corporationID) {
    return true
  }

  return !!(gameStore.map.possible_battle && userUnit && warrior && userUnit.warrior && userUnit.fraction === fraction);
}

function switchVisibleObj(obj) {

  if (!obj.objectSprite || obj.objectSprite.frame.name === 'storage_of_souls' || obj.objectSprite.frame.name === 'corporation_base_1' ||
    obj.objectSprite.frame.name === 'corporation_base_1_destroy' || obj.type === 'base' || obj.type === 'office' ||
    obj.type === 'prefabricated' || obj.type === 'processing' || obj.type === 'market' || obj.objectSprite.frame.name === 'unknown_civilization_9') {
    return;
  }

  if (!Scene.cameras.main.worldView.contains(obj.objectSprite.x, obj.objectSprite.y)) {
    if (obj.objectSprite.visible) {
      obj.objectSprite.setVisible(false)
      if (obj.objectSprite.shadow) obj.objectSprite.shadow.setVisible(false)
      if (obj.objectSprite.weapon) obj.objectSprite.weapon.setVisible(false)
      if (obj.objectSprite.weaponShadow) obj.objectSprite.weaponShadow.setVisible(false)
      if (obj.objectSprite.top) obj.objectSprite.top.setVisible(false)
      if (obj.objectSprite.shadowTop) obj.objectSprite.shadowTop.setVisible(false)
    }
  } else {
    if (!obj.objectSprite.visible) {
      obj.objectSprite.setVisible(true)
      if (obj.objectSprite.shadow) obj.objectSprite.shadow.setVisible(true)
      if (obj.objectSprite.weapon) obj.objectSprite.weapon.setVisible(true)
      if (obj.objectSprite.weaponShadow) obj.objectSprite.weaponShadow.setVisible(true)
      if (obj.objectSprite.top) obj.objectSprite.top.setVisible(true)
      if (obj.objectSprite.shadowTop) obj.objectSprite.shadowTop.setVisible(true)
    }
  }
}

export {update, userUnit, processUnitWeapon, processUnitEquip, processUnitBottom, serverMoveLag, tickTime}
