<template>
  <section>
    <template v-if="lang==='RU'">
      <h3 class="headSection">Над игрой работали: </h3>
      <table class="table">
        <tr>
          <td>Concept Artist:</td>
          <td><a href="https://www.artstation.com/tripoltri">Константин Кеменов</a></td>
        </tr>
        <tr>
          <td>Сеттинг:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Геймдизайн:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Разработка:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Лор:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Текст диалогов/заданий:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Сюжет:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Музыка:</td>
          <td>Антон Вильгоцкий</td>
        </tr>
      </table>

      <h3 class="headSection">Используемые ресурсы: </h3>
      <table class="table">
        <tr>
          <td>Игровой движок фронт:</td>
          <td><a href="https://phaser.io/phaser3">Phaser 3</a></td>
        </tr>
        <tr>
          <td>Игровой движок бэк:</td>
          <td><a href="https://github.com/TrashPony/game-engine">тык</a></td>
        </tr>
      </table>
    </template>
    <template v-if="lang==='EN'">
      <h3 class="headSection">Worked on the game:</h3>
      <table class="table">
        <tr>
          <td>Concept Artist:</td>
          <td><a href="https://www.artstation.com/tripoltri">Константин Кеменов</a></td>
        </tr>
        <tr>
          <td>Setting:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Game design:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Development:</td>
          <td><a href="https://github.com/TrashPony">TrashPony</a></td>
        </tr>
        <tr>
          <td>Lore:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Text of dialogues/missions:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Plot:</td>
          <td>Андрей Бабиченко</td>
        </tr>
        <tr>
          <td>Music:</td>
          <td>Антон Вильгоцкий</td>
        </tr>
      </table>

      <h3 class="headSection">Resources used: </h3>
      <table class="table">
        <tr>
          <td>Game engine front:</td>
          <td><a href="https://phaser.io/phaser3">Phaser 3</a></td>
        </tr>
        <tr>
          <td>Game engine back:</td>
          <td><a href="https://github.com/TrashPony/game-engine">poke</a></td>
        </tr>
      </table>
    </template>
  </section>
</template>

<script>
export default {
  name: "Authors",
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.headSection {
  color: #ffc588;
}

a {
  color: #0cfdff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #ffc588;
}

@media (max-width: 650px) {
  .headSection {
    font-size: 13px;
  }

  .table {
    font-size: 10px;
  }
}
</style>
