<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Стрельба</h2>

      <p>Оружие корпуса - основное средство обороны и атаки. Игрок, используя мышь, наводит прицел (красный круг на
        экране), на необходимую точку/цель, после чего открывает огонь - выпуская туда снаряд. </p>

      <p>Стрельба в Veliri строится на умениях игрока, типе вооружения и дальности до цели, по причине чего, угол
        отклонения/рассеивания снарядов достаточно высок. Об этом же свидетельствует и сам прицел - красный круг - чем
        он
        шире, тем больше шанс отклонения выпущенных снарядов. </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('shooting_1', 'gif')+ ')',
            width: '375px',
            height: '359px',
            marginLeft: 'calc(50% - 187px)',
            }"/>

      <p>Чтобы выстрелить из оружия, вам нужно нажать на левую кнопку мышки. Вы увидите, как ваш снаряд летит по воздуху
        и
        взрывается по достижении цели или препятствия. Вы также увидите, как отлетают цифры, которые показывают, сколько
        урона вы нанесли и кому. Если ваш снаряд имеет урон по площади, то урон получат все, кто находится в зоне
        взрыва.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('shooting_2', 'gif')+ ')',
            width: '375px',
            height: '359px',
            marginLeft: 'calc(50% - 187px)',
            }"/>

      <p>Стоит помнить, что большинство объектов в игре имеют высоты, а сама карта мира - рельеф. Это означает, что при
        ведении огня игрок должен учитывать не только дистанцию, но и особенности окружения. </p>

      <p>Особую роль в данном случае сыграют баллистические снаряды, которые при выстреле поднимаются вверх и
        впоследствии
        падают вниз под действием гравитации. Таким образом, вы можете поразить цель, которая находится за препятствием
        или напротив - не нанести ей урона из-за особенностей местности.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('shooting_3', 'gif')+ ')',
            width: '471px',
            height: '155px',
            marginLeft: 'calc(50% - 235px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Shooting</h2>

      <p>The chassis weapon is the primary means of defense and attack. The player, using the mouse, aims the reticle
        (red circle on the screen) at the necessary point/target, and then opens fire by shooting a projectile
        there.</p>

      <p>Shooting in Veliri is based on the player's skills, the type of weapon, and the distance to the target, which
        is why the angle of deviation/dispersion of the projectiles is quite high. This is also evidenced by the reticle
        itself - the red circle - the wider it is, the greater the chance of deviation of the fired projectiles.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('shooting_1', 'gif')+ ')',
        width: '375px',
        height: '359px',
        marginLeft: 'calc(50% - 187px)',
        }"></div>

      <p>To fire the weapon, you need to press the left mouse button. You will see how your projectile flies through the
        air and explodes upon reaching the target or an obstacle. You will also see numbers flying off, showing how much
        damage you have dealt and to whom. If your projectile has area damage, then everyone in the blast zone will take
        damage.</p>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('shooting_2', 'gif')+ ')',
        width: '375px',
        height: '359px',
        marginLeft: 'calc(50% - 187px)',
        }"></div>

      <p>It is worth remembering that most objects in the game have heights, and the world map itself has relief. This
        means that when firing, the player must take into account not only the distance but also the features of the
        environment.</p>

      <p>Ballistic projectiles, which rise up when fired and subsequently fall down under the action of gravity, will
        play a special role in this case. Thus, you can hit a target that is behind an obstacle, or conversely, not
        inflict damage on it due to the terrain features.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('shooting_3', 'gif')+ ')',
        width: '471px',
        height: '155px',
        marginLeft: 'calc(50% - 235px)',
        }"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Ballistic",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
