var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Терминал")]),_vm._v(" "),_c('p',[_vm._v("Терминал позволяет установить связь с базой. Тут можно получить задания или завершить текущие.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_terminal_1', 'png')+ ')',
          width: '264px',
          height: '406px',
          marginLeft: 'calc(50% - 132px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Terminal")]),_vm._v(" "),_c('p',[_vm._v("The terminal allows you to establish communication with the base. Here you can receive tasks or complete\n      current ones.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_terminal_1', 'png')+ ')',
          width: '264px',
          height: '406px',
          marginLeft: 'calc(50% - 132px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }