<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">APD</h2>

      <p>Порой, из-за границ игровой карты могут явиться “странные” машины - древние механизмы, почему-то испытывающие
        беспричинную враждебность ко всем синтетам без исключения.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_2', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }">
          <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('APD', 'png')+ ')',
              width: '64px',
              height: '64px',
              float: 'left',
              marginLeft: 'calc(100% - 70px)'
            }"/>
        </div>
      </div>

      <p>Судя по всему, их действия не спонтанны. Крупные группы скоплений АОП/APD зачастую встречаются у территорий,
        которые насыщены реликтовыми сооружениями и мега-городами былой цивилизации на планете. </p>
      <p>Возможно, что-то защищая, или подчиняясь некой ещё неизведанной силе - эти машины ведут собственную игру за
        господство. </p>
      <p>Их вооружение слабо, а интеллект по сравнению с синтетами - слаб. При всём этом, единственная причина почему
        фракции синтетов до сих пор не истребили АОП/APD - является их неисчислимо прибывающие орды. </p>
    </template>

    <template v-if="lang==='EN'">
      <p>Sometimes, from beyond the borders of the game map, "strange" machines may appear - ancient mechanisms that,
        for some reason, exhibit unprovoked hostility towards all synthetics without exception.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_2', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }">
          <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('APD', 'png')+ ')',
              width: '64px',
              height: '64px',
              float: 'left',
              marginLeft: 'calc(100% - 70px)'
            }"/>
        </div>
      </div>

      <p>Apparently, their actions are not spontaneous. Large groups of APD clusters are often found in territories that
        are saturated with relic structures and mega-cities of a bygone civilization on the planet.</p>
      <p>Possibly defending something, or obeying some yet unexplored force - these machines play their own game for
        dominance.</p>
      <p>Their weaponry is weak, and their intellect is inferior compared to the synthetics. Despite all this, the only
        reason why the synthetic factions have not yet exterminated the APD is their innumerable arriving hordes.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Aop",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
