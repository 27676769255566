var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("APD")]),_vm._v(" "),_c('p',[_vm._v("Порой, из-за границ игровой карты могут явиться “странные” машины - древние механизмы, почему-то испытывающие\n      беспричинную враждебность ко всем синтетам без исключения.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_2', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('APD', 'png')+ ')',
            width: '64px',
            height: '64px',
            float: 'left',
            marginLeft: 'calc(100% - 70px)'
          })})])]),_vm._v(" "),_c('p',[_vm._v("Судя по всему, их действия не спонтанны. Крупные группы скоплений АОП/APD зачастую встречаются у территорий,\n      которые насыщены реликтовыми сооружениями и мега-городами былой цивилизации на планете. ")]),_vm._v(" "),_c('p',[_vm._v("Возможно, что-то защищая, или подчиняясь некой ещё неизведанной силе - эти машины ведут собственную игру за\n      господство. ")]),_vm._v(" "),_c('p',[_vm._v("Их вооружение слабо, а интеллект по сравнению с синтетами - слаб. При всём этом, единственная причина почему\n      фракции синтетов до сих пор не истребили АОП/APD - является их неисчислимо прибывающие орды. ")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('p',[_vm._v("Sometimes, from beyond the borders of the game map, \"strange\" machines may appear - ancient mechanisms that,\n      for some reason, exhibit unprovoked hostility towards all synthetics without exception.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_2', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('APD', 'png')+ ')',
            width: '64px',
            height: '64px',
            float: 'left',
            marginLeft: 'calc(100% - 70px)'
          })})])]),_vm._v(" "),_c('p',[_vm._v("Apparently, their actions are not spontaneous. Large groups of APD clusters are often found in territories that\n      are saturated with relic structures and mega-cities of a bygone civilization on the planet.")]),_vm._v(" "),_c('p',[_vm._v("Possibly defending something, or obeying some yet unexplored force - these machines play their own game for\n      dominance.")]),_vm._v(" "),_c('p',[_vm._v("Their weaponry is weak, and their intellect is inferior compared to the synthetics. Despite all this, the only\n      reason why the synthetic factions have not yet exterminated the APD is their innumerable arriving hordes.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }