var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Метеориты")]),_vm._v(" "),_c('p',[_vm._v("Время от времени на поверхность планеты падают метеориты, способные нанести ощутимый урон всему в области\n      поражения. ")]),_vm._v(" "),_c('p',[_vm._v("Иногда от метеоритов в месте падения образуются залежи минералов которые можно добывать.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('meteorite', 'jpg')+ ')',
          width: '480px',
          height: '122px',
          marginLeft: 'calc(50% - 240px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Meteorites")]),_vm._v(" "),_c('p',[_vm._v("From time to time, meteorites fall onto the planet's surface, capable of causing significant damage to\n      everything in the impact area. ")]),_vm._v(" "),_c('p',[_vm._v("Sometimes, mineral deposits that can be mined form at the site of a meteorite fall.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('meteorite', 'jpg')+ ')',
      width: '480px',
      height: '122px',
      marginLeft: 'calc(50% - 240px)',
      })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }