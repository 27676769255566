var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Репутация")]),_vm._v(" "),_c('p',[_vm._v("Репутация - система начисления очков, определяющая, как именно к вам относятся те или иные фракции в мире\n      игры.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('relations_1', 'png')+ ')',
          width: '420px',
          height: '338px',
          marginLeft: 'calc(50% - 210px)',
          })}),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Reputation")]),_vm._v(" "),_c('p',[_vm._v("Reputation is a point system that determines how various factions in the game world relate to you.")]),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('relations_1', 'png')+ ')',
      width: '420px',
      height: '338px',
      marginLeft: 'calc(50% - 210px)',
      })}),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Хорошая репутация")]),_vm._v(" - отображается в виде активных скидок на товары/услуги\n        фракции, доступа к специализированным\n        предметам и, даже поддержки в бою.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Плохая репутация")]),_vm._v(" - отображается в виде запрета на использование ряда услуг,\n        зачастую агрессивного поведения.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"headSection"},[_vm._v("игрок получает репутацию за:")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("убийство пиратов в безопасном секторе (улучшается у владельца сектора)")]),_vm._v(" "),_c('li',[_vm._v("выполнение миссий")]),_vm._v(" "),_c('li',[_vm._v("захват сектора в фракционной войне (надо состоять в ополчение)")]),_vm._v(" "),_c('li',[_vm._v("за уплаченный налог при переработке ресурсов")]),_vm._v(" "),_c('li',[_vm._v("за торговлю товарами (улучшается у владельца сектора) если в следке больше 100 товаров")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"headSection"},[_vm._v("ухудшается за:")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("убийство мирного в секторе фракции (ухудшается у владельца сектора)")]),_vm._v(" "),_c('li',[_vm._v("убийство воина в его секторе в войне фракций")]),_vm._v(" "),_c('li',[_vm._v("провал миссий, отказ от выполнения миссий")]),_vm._v(" "),_c('li',[_vm._v("за провоз контрабанды (ухудшается у владельца сектора)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Бонусы")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("Обзор защитников (если выше 0)")]),_vm._v(" "),_c('li',[_vm._v("налог на переработку сырья (-1% за 1 репутацию) (макс: -10%)")]),_vm._v(" "),_c('li',[_vm._v("налог на производство (-1% за 1 репутацию) (макс: -10%)")]),_vm._v(" "),_c('li',[_vm._v("налог на производство деталей (-3% за 1 репутацию) (макс: -30%)")]),_vm._v(" "),_c('li',[_vm._v("снижение налога на рынок. На базах фракции (1% за 3 репутации) (макс: -3%)")]),_vm._v(" "),_c('li',[_vm._v("награда за задания (+2% за 1 репутацию) (макс: +20%)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Штрафы:")]),_vm._v(" "),_c('h4',{staticClass:"headSection"},[_vm._v("Плохое отношение (-0.1 : -4.9)")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("заперт на использование НПС магазина товаров")]),_vm._v(" "),_c('li',[_vm._v("запрет на переработку/производство")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h4',{staticClass:"headSection"},[_vm._v("Вражда (-5 : -10)")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("полиция атакует даже в безопасном секторе и не получает PVP/PK")]),_vm._v(" "),_c('li',[_vm._v("игроки и боты не получают PVP/PK атакуя вас")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Good reputation")]),_vm._v(" is reflected in active discounts on goods/services of the\n        faction, access to specialized items, and even support in combat.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Bad reputation")]),_vm._v(" is reflected in the prohibition of using a number of\n        services, often aggressive behavior.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"headSection"},[_vm._v("a player earns reputation for:")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("killing pirates in a safe sector (improves with the sector owner)")]),_vm._v(" "),_c('li',[_vm._v("completing missions")]),_vm._v(" "),_c('li',[_vm._v("capturing a sector in the faction war (must be part of the militia)")]),_vm._v(" "),_c('li',[_vm._v("for the tax paid when processing resources")]),_vm._v(" "),_c('li',[_vm._v("for trading goods (improves with the sector owner) if the deal involves more than 100 goods")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"headSection"},[_vm._v("deteriorates for:")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("killing a civilian in the faction's sector (worsens with the sector owner)")]),_vm._v(" "),_c('li',[_vm._v("killing a warrior in his sector in the faction war")]),_vm._v(" "),_c('li',[_vm._v("mission failure, refusal to complete missions")]),_vm._v(" "),_c('li',[_vm._v("for smuggling (worsens with the sector owner)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Bonuses")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("Defender overview (if above 0)")]),_vm._v(" "),_c('li',[_vm._v("tax on raw material processing (-1% per 1 reputation) (max: -10%)")]),_vm._v(" "),_c('li',[_vm._v("tax on production (-1% per 1 reputation) (max: -10%)")]),_vm._v(" "),_c('li',[_vm._v("tax on parts production (-3% per 1 reputation) (max: -30%)")]),_vm._v(" "),_c('li',[_vm._v("reduction of market tax. At faction bases (1% per 3 reputations) (max: -3%)")]),_vm._v(" "),_c('li',[_vm._v("reward for tasks (+2% per 1 reputation) (max: +20%)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Penalties:")]),_vm._v(" "),_c('h4',{staticClass:"headSection"},[_vm._v("Poor relationship (-0.1 : -4.9)")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("locked from using NPC goods store")]),_vm._v(" "),_c('li',[_vm._v("ban on processing/production")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h4',{staticClass:"headSection"},[_vm._v("Hostility (-5 : -10)")]),_vm._v(" "),_c('ul',{staticStyle:{"float":"left"}},[_c('li',[_vm._v("police attack even in a safe sector and do not receive PVP/PK")]),_vm._v(" "),_c('li',[_vm._v("players and bots do not receive PVP/PK attacking you")])])])
}]

export { render, staticRenderFns }