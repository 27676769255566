var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: ориентация")]),_vm._v(" "),_c('p',[_vm._v("В правом верхнем углу мини карта показывает куда тебе надо двигаться зеленой линией.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_2', 'png')+ ')',
          width: '266px',
          height: '228px',
          marginLeft: 'calc(50% - 133px)',
          })}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_4', 'gif')+ ')',
          width: '422px',
          height: '293px',
          marginLeft: 'calc(50% - 211px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Orientation")]),_vm._v(" "),_c('p',[_vm._v("In the top right corner, the mini-map shows where you need to go with a green line.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_2', 'png')+ ')',
          width: '266px',
          height: '228px',
          marginLeft: 'calc(50% - 133px)',
          })}),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_exit_base_4', 'gif')+ ')',
          width: '422px',
          height: '293px',
          marginLeft: 'calc(50% - 211px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы "),_c('span',{staticClass:"importantly"},[_vm._v("зайти на базу")]),_vm._v(" надо просто въехать на нее.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To "),_c('span',{staticClass:"importantly"},[_vm._v("enter the base")]),_vm._v(", you simply need to drive onto it.")])
}]

export { render, staticRenderFns }