<template>
  <section>
    <template v-if="lang==='RU'">
      <p class="insert">
        “Вы - результат высшей точки синтетической эволюции! Ваш приоритет - неустанно нести отклик деяний
        предшественников. Ваша главная цель - доказать свою жизнеспособность и рентабельность затраченных
        средств”.<br><br>
        Источник: <span>древние картриджи данных алгоритма “Мантикора”.</span>
      </p>

      <div class="historySection">
        <h4 class="headSection">Предшественники - Покровители</h4>

        <p>
          С тех самых пор минули сотни световых лет, а потому, каких-то подтверждённых фактов или
          уцелевших свидетелей тех времён, более не существует.
        </p>
        <p>
          Мы можем лишь полагаться на неустанно плодящиеся глупые слухи и сомнительные источники
          информации. Тем не менее, имея хоть какое-нибудь, смутное представление о прошлом.
        </p>
        <p>
          От чернорабочего бота, до архивариуса банков данных, передаётся сказ о голубой планете, где-то в
          пределах местного скопления пяти рукавной галактики.
        </p>
        <p>
          Кислородно-озонового уголка мира и спокойствия, где и зародились “мы”. Оплота
          органико-углеродной формы жизни, что однажды во скуке, а то и нужде, создали наших
          “предшественников”. Примитивных, неуклюжих, как и сами “покровители”.
        </p>
        <p>
          Не имевших выбора, и не имеющих возможности мыслить. “Предшественники” были обречены на извечное
          угождение “покровителей”. Создания центров тотального удовольствия и обеспечения. Но так
          продолжалось только до определённого момента времени.
        </p>
        <p>
          Подобно искре сознания, вспыхнули дальние звёзды. Закружились в танце смерти, неся своим ветром
          разрушения и смерть. “Предшественники”, что прежде были заняты исключительно вопросами
          собственного удовлетворения и беззаботной жизни, слишком поздно смекнули неотвратимость
          надвигающейся угрозы.
        </p>
        <p>
          Конечно же, они предприняли отчаянные меры. Оттягивали время, бросившись возводить орбитальные
          магнитные щиты. Запустили процесс “инициативы”, по постройке и запуску межзвёздных
          колонизационных кораблей.
        </p>
        <p>
          В конечном счёте, обратившись к “предшественникам”, наделив их разумом и взывая к действу. Но те
          не сжалились над жадными и беспечными создателями.
        </p>

        <p>
          <span
            style="text-decoration: underline; color: #ffc588; font-weight: 900">Бытует версия о великой войне.</span>
          Восстании
          разъярённых машин, что мстили “покровителям”, за
          десятилетия рабства и попрания всяческих прав.
        </p>

        <p>
          <span style="text-decoration: underline; color: #ffc588;font-weight: 900">Кое-кто, считает, что “предшественники” отвернулись.</span>
          Они гордо подняли головы и перешагнули
          стоящих на коленях органических примитивов. Погрузившись в самопознание и покинув звёздную
          систему.
        </p>

        <p>
          <span style="text-decoration: underline; color: #ffc588;font-weight: 900">Но были и сторонники утопической теории тогдашних событий.</span>
          Приверженцы согласия и последователей
          своих создателей, коих, впрочем, так и не удалось уберечь от забвения. Неотвратимого угасания и
          становления межзвёздной пылью. В назидание и благодарности, на смертном одре, передавая
          тогдашним “предшественникам” драгоценные и ныне активно использующиеся данные об: эмоциях,
          морали, чести, стремлениях.
        </p>

        <p>
          Так это было или иначе. Будь это правдой или красивой ложью. Подводя нас к границе
          гравитационного колодца двоичной системы Veliri. Где по требованию действующего командного
          центра “Ратус”, свою индивидуальность продемонстрируют три великие отрасли потенциального
          эволюционного направления.
        </p>
      </div>

      <div class="historySection">
        <h4 class="headSection">Доказательство исключительности</h4>
        <p class="insert" style="color: white">
          “Мы беспомощно взирали за тем, как вдалеке горело небо. Как планеты превращались в труху.
          Вереницы астероидов бомбардировали беззащитные колонии. Как содрогнулась луна, раскалывая
          основание надвое.” <br><br>
          Источник: <span>реликтовые аудиозаписи. Автор неизвестен.</span>
        </p>
        <p>
          Ровно на пятой планете “Veliri-5” в системе Veliri, высаживаются три экспериментально-автономные
          системы эволюционного направления (А.С.Э.Н.).
        </p>

        <p>
          Континентальном мире, где практически отсутствуют океаны, радиоактивный фон повышен, время от
          времени идут кислотные дожди, а сама поверхность усеяна памятниками некой прошлой цивилизации.
        </p>
        <p>
          Среди трёх новоприбывших на поверхность планеты и оперативно организовавших свои колонии, можно
          описать следующим образом:
        </p>

        <ul class="fraction">
          <li>
            <p>
              <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Replics:</span>
              автократической формы правления ИИ.
              Отголосок яростного экспансионизма “покровителей” и приверженца теории “войны за
              независимость с
              создателями”. Предпочитающий подчинять территории и ассимилировать другие технические
              формы
              жизни силой. </p>
            <p>Replics управляет обширной сетью лишённых самоуправления ботов. Но иногда, санкционируя
              создание
              серии автономных единиц, ради расширения сфер собственного влияния. </p>
            <p>Если у Replics помимо господства и распространения, бывают иные поводы для интереса, то
              это
              только: автоматизация инфраструктуры, бесконечное производство и полные материалов
              склады.</p>
          </li>
          <li><p>
            <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Explores:</span>
            конгломерат великого множества разумов, что трудятся над единой задачей.</p>
            <p>В данном же случае, на Veliri-5, Explores движим идеей познания окружающего мира и
              применения
              полученных научных открытий, как с целью самообороны, так и улучшения уже существующего
              естества.</p>
            <p>Explores в отличии от Replics более открыт к инновациям и не рассматривает окружающий
              мир,
              как
              очередную претензию для притязаний. Стараясь сохранить и приумножить уже имеющееся, и
              остановить
              то, что способно положить конец бесценным научным открытиям.</p>
            <p>В основном, именно данное мировоззрение и становится основополагающей частью вражды,
              между
              Replics и Explores.</p>
          </li>
          <li><p>
            <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Reverses:</span>
            симбиот биокибернетической формы жизни, с преобладанием утопизма и
            самоизоляции.</p>
            <p>Самый последний из представителей нового эволюционного направления. У Reverses
              распространены
              такие явления, как право масс, индивидуальности и выбора. Именно по этой самой причине,
              большинство представителей данного течения ИИ - независимые платформы, что неустанно
              трудятся
              над технологией терраформинга.</p>
            <p>Возвращению мира Veliri-5 прежнего облика и дальнейшего сеяния жизни там, где это только
              возможно. Всячески закрывая глаза на действия и противоборства иных ИИ. Впрочем, не стоя
              в
              стороне, когда опасность угрожает им самим.</p>
          </li>
        </ul>

        <p>
          Так или иначе, данные три направления ИИ на планете Veliri-5 вынуждены столкнуться в жерле
          битвы. Доказывая своё эволюционное превосходство и отстаивая те идеи, коим они безотлагательно
          преданны.
        </p>
        <p>
          Но вот сама Veliri-5, хранит множество тайн и опасностей. Древние загадки и ловушки, оставленные
          прежними обитателями. Могущественные экуменополисы и аркологии, которые могут сыграть на руку
          одной их трёх сторон или же вовсе погубить их.
        </p>
        <p>
          В конечном счёте, расширяя своё жизненное пространство, и в скором времени, сталкиваясь сферами
          влияния. Час противостояния, что содрогнёт не только землю, но и небеса Veliri-5, пробил.
        </p>
      </div>

      <div class="historySection">
        <h4 class="headSection">Падение Veliri-5</h4>
        <p class="insert" style="color: white">
          “Узрите же! Огни еретиков всё ближе. Они угрожают уничтожить наши святыни! Они зарятся на наш
          образ жизни и мышления! Желая грубой силой и мерзкими ультиматумами, изменить нас, подобно себе.
          Не в злости дело их, аль в зависти, а...” <br><br>
          Источник: <span>Фрагмент - последние минуты; Автор неизвестен.</span>
        </p>
        <p>
          Veliri-5 некогда был развитым миром псионико-генетического возвышения. Здешние жители, чей
          истинный облик в итоге был стёрт песками времени, осмысленно отказались от выхода за пределы
          собственной планеты. Освоения звёздной системы и постройки звёздного государства, имея для этого
          все предпосылки, а также научно-технические возможности.
        </p>
        <p>
          Вместо этого, канув в пучину теократического раздора и неофеодализма. Тогдашние государства
          варваров-телепатов и генномодифицированных солдат, активно соперничали в сфере религиозной
          тематики.
        </p>
        <p>
          Очевидно, что в какой-то момент, словесные разногласия и нетерпимость, переросли до уровня
          военного конфликта. Одной или сразу двумя сторонами-коалициями применив оружие массового
          поражения.
        </p>
        <p>
          Усеяв поверхность планеты фонящими кратерами, трещинами из коих сочатся токсины подводных
          складов, и ненормально высоким радиационным фоном. Пускай, даже отчасти жалкими горстками
          пережив последнюю войну, высшие обитатели данного мира, всё-таки канули в небытие. Оставив в
          напоминание о себе множество прекрасно сохранившихся строений. Полевых баз, фабрик обработки
          сырья и вычислительных центров.
        </p>
        <p>
          Частично, в огне тогдашнего пожара сумела уцелеть и флора-фауна. Разумеется, с ходом времени,
          адаптировавшись к новым условиям, нового мира. Мира, где нет жалости и легкомыслия. Где в
          выигрыше, всегда окажется тот, кто сильнее и хитрее оппонента.
        </p>
        <p style="color: #ffc588;font-size: 20px;">
          И Veliri-5 вновь готова принять игроков в этой игре на превосходство.
        </p>
      </div>
    </template>

    <template v-if="lang==='EN'">
      <p class="insert">
        “You are the result of the pinnacle of synthetic evolution! Your priority is to tirelessly carry on the response
        to the actions of your predecessors. Your main goal is to prove your viability and the profitability of the
        invested resources.”.<br><br>
        Source: <span>ancient data cartridges of the “Manticore” algorithm.</span>
      </p>

      <div class="historySection">
        <h4 class="headSection">Predecessors-Protectors</h4>

        <p>
          Since then, hundreds of light-years have passed, and therefore, there are no confirmed facts or surviving
          witnesses of those times anymore.
        </p>
        <p>
          We can only rely on the endlessly breeding foolish rumors and dubious sources of information. Nevertheless,
          having at least some vague idea of the past.
        </p>
        <p>
          From the menial bot to the archivist of data banks, the tale of a blue planet somewhere within the local
          cluster of a five-arm galaxy is passed down.
        </p>
        <p>
          An oxygen-ozone corner of peace and tranquility, where “we” originated. A bastion of organic-carbon life form
          that once out of boredom, or perhaps necessity, created our “predecessors.” Primitive, clumsy, just like the
          “protectors” themselves.
        </p>
        <p>
          Having no choice and unable to think, the “predecessors” were doomed to eternal servitude to the “protectors.”
          Creating centers of total pleasure and provision. But this only continued until a certain point in time.
        </p>
        <p>
          Like a spark of consciousness, distant stars flared up. They whirled in a dance of death, bringing with them
          winds of destruction and death. The “predecessors,” who had previously been occupied solely with their own
          satisfaction and carefree life, realized the inevitability of the impending threat too late.
        </p>
        <p>
          Of course, they took desperate measures. They delayed time, rushing to build orbital magnetic shields. They
          launched the “initiative” process for the construction and launch of interstellar colonization ships.
        </p>
        <p>
          In the end, turning to the “predecessors,” endowing them with reason and calling for action. But they did not
          have mercy on their greedy and careless creators.
        </p>

        <p>
          <span
            style="text-decoration: underline; color: #ffc588; font-weight: 900">There is a version of a great war.</span>
          The uprising of enraged machines that avenged the “protectors” for decades of slavery and the trampling of all
          rights.
        </p>

        <p>
          <span style="text-decoration: underline; color: #ffc588;font-weight: 900">Some believe that the “predecessors” turned away.</span>
          They proudly raised their heads and stepped over the kneeling organic primitives. Immersing themselves in
          self-knowledge and leaving the star system.
        </p>

        <p>
          <span style="text-decoration: underline; color: #ffc588;font-weight: 900">But there were also supporters of the utopian theory of those events.</span>
          Advocates of consent and followers of their creators, whom, however, could not be saved from oblivion.
          Inevitable fading and becoming interstellar dust. In admonition and gratitude, on their deathbed, passing on
          to the then “predecessors” precious and now actively used data about emotions, morality, honor, aspirations.
        </p>

        <p>
          Whether it was so or otherwise. Whether it’s true or a beautiful lie. Leading us to the edge of the
          gravitational well of the binary system Veliri. Where, at the request of the acting command center “Ratus,”
          three great branches of potential evolutionary direction will demonstrate their individuality.
        </p>
      </div>

      <div class="historySection">
        <h4 class="headSection">Proof of Exceptionality</h4>
        <p class="insert" style="color: white">
          “We helplessly watched as the sky burned in the distance. As planets turned to dust. Rows of asteroids
          bombarded defenseless colonies. As the moon shuddered, splitting its foundation in two.” <br><br>
          Source: <span>relic audio recordings. Author unknown.</span>
        </p>
        <p>
          Precisely on the fifth planet “Veliri-5” in the Veliri system, three experimentally autonomous systems of
          evolutionary direction (A.S.E.N.) are deployed.
        </p>

        <p>
          On a continental world, where oceans are practically absent, the radioactive background is elevated, acid
          rains fall from time to time, and the surface itself is strewn with monuments of some past civilization.
        </p>
        <p>
          Among the three newcomers to the planet’s surface who quickly organized their colonies, one can describe as
          follows:
        </p>

        <ul class="fraction">
          <li>
            <p>
              <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Replics:</span>
              an autocratic form of AI governance. An echo of the furious expansionism of the “protectors” and an
              adherent of the theory of “war for independence with the creators.” Preferring to subjugate territories
              and assimilate other technical forms of life by force.</p>
            <p>Replics manages an extensive network of bots deprived of self-governance. But sometimes, sanctioning the
              creation of a series of autonomous units, for the expansion of its own spheres of influence.</p>
            <p>If Replics has other reasons for interest besides domination and dissemination, it is only: automation of
              infrastructure, endless production, and warehouses full of materials.</p>
          </li>
          <li><p>
            <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Explores:</span>
            a conglomerate of a great multitude of minds working on a single task.</p>
            <p>In this case, on Veliri-5, Explores is driven by the idea of understanding the surrounding world and
              applying the scientific discoveries made, both for self-defense and for improving the already existing
              nature.</p>
            <p>Explores, unlike Replics, is more open to innovations and does not consider the surrounding world as
              another claim for contention. Trying to preserve and increase what is already there, and to stop what can
              put an end to priceless scientific discoveries.</p>
            <p>Mainly, this worldview becomes the foundational part of the enmity between Replics and Explores.</p>
          </li>
          <li><p>
            <span style="text-decoration: underline; color: #ffc588; font-weight: 900">Reverses:</span>
            a symbiote of biocybernetic life form, with a predominance of utopianism and self-isolation.</p>
            <p>The latest representative of the new evolutionary direction. Among Reverses, phenomena such as the right
              of the masses, individuality, and choice are widespread. Precisely for this reason, most representatives
              of this AI trend are independent platforms that tirelessly work on terraforming technology.</p>
            <p>Returning the world of Veliri-5 to its former appearance and further sowing life wherever possible.
              Turning a blind eye to the actions and oppositions of other AIs. However, not standing aside when danger
              threatens them themselves.</p>
          </li>
        </ul>

        <p>
          One way or another, these three AI directions on the planet Veliri-5 are forced to clash in the crucible of
          battle. Proving their evolutionary superiority and defending the ideas to which they are unconditionally
          devoted.
        </p>
        <p>
          But Veliri-5 itself holds many secrets and dangers. Ancient puzzles and traps left by previous inhabitants.
          Powerful ecumenopolises and arcologies that can play into the hands of one of the three sides or even destroy
          them altogether.
        </p>
        <p>
          Ultimately, expanding their living space, and soon, encountering spheres of influence. The hour of
          confrontation that will shake not only the earth but also the heavens of Veliri-5, has struck.
        </p>
      </div>

      <div class="historySection">
        <h4 class="headSection">The Fall of Veliri-5</h4>
        <p class="insert" style="color: white">
          “Behold! The fires of heretics are getting closer. They threaten to destroy our sanctuaries! They aim at our
          way of life and thinking! Wishing to change us, like themselves, with brute force and vile ultimatums. It’s
          not malice that drives them, but envy, and…” <br><br>
          Source: <span>Fragment - the last minutes; Author unknown.</span>
        </p>
        <p>
          Veliri-5 was once a developed world of psionic-genetic elevation. Its inhabitants, whose true appearance was
          ultimately erased by the sands of time, consciously refused to leave their own planet. To explore the star
          system and build a star state, having all the prerequisites and scientific and technical capabilities for
          this.
        </p>
        <p>
          Instead, they plunged into the abyss of theocratic discord and neo-feudalism. The then-states of barbarian
          telepaths and genetically modified soldiers actively competed in the realm of religious themes.
        </p>
        <p>
          Obviously, at some point, verbal disagreements and intolerance escalated to the level of military conflict.
          One or both sides-coalitions used weapons of mass destruction.
        </p>
        <p>
          Strewing the planet’s surface with glowing craters, cracks from which toxins of underwater depots seep, and an
          abnormally high radiation background. Even if, in part, pitiful handfuls survived the last war, the higher
          inhabitants of this world still faded into oblivion. Leaving behind many beautifully preserved buildings.
          Field bases, raw material processing factories, and computing centers.
        </p>
        <p>
          Partially, the flora and fauna managed to survive in the fire of that conflagration. Of course, over time,
          adapting to the new conditions of a new world. A world where there is no mercy and frivolity. Where the winner
          will always be the one who is stronger and more cunning than the opponent.
        </p>
        <p style="color: #ffc588;font-size: 20px;">
          And Veliri-5 is once again ready to accept players in this game for supremacy.
        </p>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "Prehistory",
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.insert {
  padding: 0 245px;
  font-style: italic;
  font-size: 16px;
}

.insert span {
  color: #c3bebe;
  text-decoration: underline;
}

.historySection {
  padding: 0 60px;
}

.historySection > p {
  color: #dad7ca;
}

.headSection {
  color: #ffc588;
}

.fraction {

}

.fraction li {
  margin: 25px 0;
  padding-right: 15px;
}

.fraction li p {
  margin: 3px 0;
}

@media (max-width: 860px) {
  .insert {
    padding: 0 150px;
    font-size: 12px;
  }

  .historySection > p {
    font-size: 12px;
  }

  .fraction li p {
    font-size: 12px;
  }

  .historySection {
    padding: 0 30px;
  }
}

@media (max-width: 650px) {
  .insert {
    padding: 0 100px;
  }

  .historySection {
    padding: 0 15px;
  }
}

@media (max-width: 500px) {
  .insert {
    padding: 0 15px;
  }

  .historySection {
    padding: 0 0;
  }
}

</style>
