<template>
  <div>
    <div class="detailItemBpBlock" v-if="type === 'blueprints'">

      <template v-if="completeProductions">
        <h4>{{ texts['text_18'][language] }}</h4>
        <div class="detailItemBpBlockRow">
          <div class="detailItemBpBlockIcon">
            <app-background-item-cell v-bind:slotItem="completeProductions"/>
          </div>
          <div class="detailItemBpBlockName">
            {{ itemName(completeProductions) }}
            <span style="color: #fff16f">х{{ item.count }}</span>
          </div>
          <div class="detailItemBpBlockDetailButton cursor_hover"
               @click="openDetail(completeProductions.type, completeProductions.item_id)"/>
        </div>
      </template>

      <template v-if="needResource">
        <h4 v-html="texts['text_19'][language]"></h4>
        <div class="detailItemBpBlockRow" v-for="resource in needResource">
          <div class="detailItemBpBlockIcon">
            <app-background-item-cell v-bind:slotItem="resource"/>
          </div>
          <div class="detailItemBpBlockName">{{ itemName(resource) }}
            <span style="color: #fff16f">х{{ resource.quantity }}</span>
          </div>
          <div class="detailItemBpBlockDetailButton cursor_hover" @click="openDetail(resource.type, resource.item_id)"/>
        </div>
      </template>
    </div>

    <div class="detailItemBpBlock" v-if="type === 'recycle'">
      <h4>{{ texts['text_20'][language] }}</h4>
      <div class="detailItemBpBlockRow" v-for="resource in resourceItems">
        <div class="detailItemBpBlockIcon">
          <app-background-item-cell v-bind:slotItem="resource"/>
        </div>
        <div class="detailItemBpBlockName">{{ itemName(resource) }}</div>
        <div class="detailItemBpBlockDetailButton cursor_hover"
             @click="openDetail(resource.type, resource.item.type_id)"/>
      </div>
    </div>

    <div class="detailItemBpBlock"
         v-if="type !== 'blueprints' && type !== 'recycle'">

      <template v-if="blueprints && blueprints.length > 0">
        <h4>{{ texts['text_21'][language] }}</h4>
        <div class="detailItemBpBlockRow" v-for="slot in blueprints">

          <div class="detailItemBpBlockIcon">
            <app-background-item-cell v-bind:slotItem="slot.bp"/>
          </div>

          <div class="detailItemBpBlockName">{{ itemName(slot.bp) }}</div>
          <div class="detailItemBpBlockDetailButton cursor_hover" @click="openDetail(slot.bp.type, slot.bp.item_id)"/>
        </div>
      </template>

      <template v-if="recycleItems">

        <h4 v-if="item.recycling_algorithm === 'combine'"
            v-html="texts['text_24'][language].replace('%count%', item.count)"/>
        <h4 v-else>{{ texts['text_22'][language] }}</h4>

        <div class="detailItemBpBlockRow" v-for="resource in recycleItems">
          <div class="detailItemBpBlockIcon">
            <app-background-item-cell v-bind:slotItem="resource"/>
          </div>
          <div class="detailItemBpBlockName">{{ itemName(resource) }}
            <span style="color: #fff16f">х{{ resource.quantity }}</span>
          </div>
          <div class="detailItemBpBlockDetailButton cursor_hover" @click="openDetail(resource.type, resource.item_id)"/>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import {urls} from '../../const';

export default {
  name: "ProductionTab",
  props: ['type', 'item'],
  data() {
    return {
      blueprints: null,
      needResource: null,
      completeProductions: null,
      recycleItems: null,
      resourceItems: null
    }
  },
  created() {
    let app = this;
    let item = app.$props.item;
    let user = this.$store.getters.getPlayer;

    if (app.$props.type === 'blueprints') {

      app.$api.get(urls.itemURL + '?id=' + item.item_id + "&type=" + item.item_type + "&method=item").then(function (response) {

        if (response.data.type === "dynamic_objects") {
          response.data.type = "structure";
          response.data.item.name = response.data.item.name + "_" + user.fraction;
        }

        app.completeProductions = response.data;

      }).catch(function (err) {
        //console.log(err)
      });

      app.$api.get(urls.bpURL + '?id=' + item.id + "&method=id").then(function (response) {
        app.needResource = response.data[0].resources
      }).catch(function (err) {
        //console.log(err)
      });

    } else if (app.$props.type === 'recycle') {
      app.$api.get(urls.getResourceByRecycle + '?id=' + item.type_id).then(function (response) {
        app.resourceItems = response.data
      }).catch(function (err) {
        //console.log(err)
      });
    } else {
      let id = item.id;
      if (!id) id = item.type_id;

      app.$api.get(urls.bpURL + '?id=' + id + "&type=" + app.$props.type + "&method=item").then(function (response) {
        app.blueprints = response.data
      }).catch(function (err) {
        //console.log(err)
      });

      app.$api.get(urls.getRecycleItems + '?id=' + id + "&type=" + app.$props.type).then(function (response) {
        app.recycleItems = response.data
      }).catch(function (err) {
        //console.log(err)
      });
    }
  },
  methods: {
    openDetail(type, id) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
    itemName(slot) {
      if (!slot) {
        return ''
      }

      if (slot.type === "blueprints") {
        if (slot.item.in_map) {
          return this.handbook['structure'][this.language][slot.item.name.split('_bp')[0]].name
        } else {
          return this.handbook[slot.item.item_type][this.language][slot.item.item_name].name
        }
      }

      return this.handbook[slot.type][this.language][slot.item.name].name
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.detailItemBpBlock h4 {
  margin: 0;
  width: calc(100% - 14px);
  padding: 2px;
  background: #df6f2c;
  padding-left: 15px;
  box-shadow: 0 0 2px black;
  font-size: 12px;
  clear: both;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detailItemBpBlockIcon {
  position: relative;
  height: 30px;
  width: 30px;
  float: left;
}

.detailItemBpBlockName {
  float: left;
  line-height: 30px;
  color: white;
  margin-left: 10px;
}

.detailItemBpBlockRow {
  background: rgba(0, 0, 0, 0.2);
  float: left;
  width: calc(100% - 4px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 2px;
  transition: 200ms;
}

.detailItemBpBlockRow:hover {
  background: rgba(0, 0, 0, 0.1);
}

.detailItemBpBlockDetailButton {
  float: right;
  height: 20px;
  width: 20px;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
  margin-top: 4px;
  background-image: url('../../assets/icons/question-mark.png');
}

.detailItemBpBlockDetailButton:active {
  transform: scale(0.95);
}

.detailItemBpBlockDetailButton:hover {
  box-shadow: 0 0 4px white;
  color: white;
}
</style>
