var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Строительство баз")]),_vm._v(" "),_c('p',[_c('router-link',{attrs:{"to":"clusters"}},[_vm._v("Кластеры")]),_vm._v("\n      могут основать базы в пустошах. Все обзоры и радары членов кластера и строений складываются.\n    ")],1),_vm._v(" "),_c('p',[_vm._v("Что дает база и захваченный сектор.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Так же наличие базы является обязательным условием что бы кластер мог участвовать в войнах.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Строительство главной базы.")]),_vm._v(" "),_c('p',[_vm._v("Основать базу можно на картах помеченные черепом. Что начать освоение базы необходимо открыть меню\n      строительства\n      через меню кластера.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_1', 'png')+ ')',
          width: '400px',
          height: '658px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Выбираете базу и устанавливаете в доступное место. Помимо объекта базы на превью будут помечены выходы и входы\n      на\n      базу - они тоже не должны быть перекрыты.")]),_vm._v(" "),_c('p',[_vm._v("Фиолетовая линия показывает радиус на котором можно будет остальные объекты а так же покрытие зоны щитом.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_2', 'png')+ ')',
          width: '360px',
          height: '382px',
          marginLeft: 'calc(50% - 180px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Когда вы установите объект на его месте появится \"строительная площадка\". Нажмите на нее манипулятором и\n      откроется меню строительства этого объекта.")]),_vm._v(" "),_c('p',[_vm._v("Что бы объект построился необходимо что бы члены кластера завели в него все необходимые ресурсы.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_3', 'png')+ ')',
          width: '380px',
          height: '207px',
          marginLeft: 'calc(50% - 190px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Так же как вы установите объект сектор закрепляется за вашим кластером на 24 часа. Если вы не успеете построить\n      базу за это время то она станет уязвима и ее смогут уничтожить. Когда вы построите базу это время будет продлено\n      на 30 дней, а потом базу надо заправлять.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Расширение базы.")]),_vm._v(" "),_c('p',[_vm._v("Когда главная база установлена то вокруг нее вы можете строить модули которые расширяют ее функционал:\n      \"переработчик\", \"фабрика\", \"рынок\", \"офис и склады\", \"сканеры ресурсов\" и \"маяки призыва\"")]),_vm._v(" "),_c('p',[_vm._v("Помимо модулей вы можете ставить турели, радары, ремонтные станции и щиты для обороны базы.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_4', 'png')+ ')',
          width: '363px',
          height: '321px',
          marginLeft: 'calc(50% - 181px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Все постройки кроме главной базы потребляют энергию, главная база дает всего 5 энергии а для увеличения надо\n      строить генераторы. Каждый генератор увеличивает кол-во ресурсов необходимых для \"заправки\" базы на 50%.")]),_vm._v(" "),_c('p',[_vm._v("Генератор можно установить где угодно, а вокруг него можно так же строить как и вокруг основной базы.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_5', 'png')+ ')',
          width: '400px',
          height: '236px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Если случилось так что уничтожили генератор а строение больше не к чему не подключено то строение будет\n      \"отключено\", до тех пор пока генератор не восстановиться.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_6', 'png')+ ')',
          width: '400px',
          height: '236px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Если энергии не хватает на обеспечение работы базы (например уничтожили генераторы но строения стоят на\n      допустимой зоне) то отключаются поочереди щиты, радары, турели и тд пока не начнет хватать энергии.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Заправка базы.")]),_vm._v(" "),_c('p',[_vm._v("После установки базы она будет работать всего 30 дней, что бы она продолжала работать дальше то ее надо\n      \"заправить\" на еще 30 дней. Сразу можно заправить базу ток на 30 (текущее + 30 дней) дней вперед.")]),_vm._v(" "),_c('p',[_vm._v("Что бы заправить базу - надо находится на ней, зайти в меню \"кластера\", выбрать раздел базы - выбрать текущую и\n      нажать \"заправить\". А так же иметь необходимый торий на личном складе.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_7', 'png')+ ')',
          width: '467px',
          height: '192px',
          marginLeft: 'calc(50% - 233px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Base Construction")]),_vm._v(" "),_c('p',[_vm._v("\n      Clusters can establish bases in the wastelands. All views and radars of cluster members and structures are\n      cumulative.\n    ")]),_vm._v(" "),_c('p',[_vm._v("What a base and a captured sector provide:")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Also, the presence of a base is a mandatory condition for a cluster to participate in wars.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Construction of the Main Base")]),_vm._v(" "),_c('p',[_vm._v("To establish a base, you can choose locations marked with a skull on the map. To start developing the base, you\n      need to open the construction menu through the cluster menu.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_1', 'png')+ ')',
          width: '400px',
          height: '658px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("You select the base and place it in an available location. In addition to the base object on the preview, the\n      exits and entrances to the base will be marked - they also must not be blocked.")]),_vm._v(" "),_c('p',[_vm._v("The purple line shows the radius on which other objects can be placed as well as the coverage area of the\n      shield.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_2', 'png')+ ')',
          width: '360px',
          height: '382px',
          marginLeft: 'calc(50% - 180px)',
          })}),_vm._v(" "),_c('p',[_vm._v("When you place the object, a \"construction site\" will appear. Click on it with the manipulator, and the\n      construction menu for this object will open.")]),_vm._v(" "),_c('p',[_vm._v("To build the object, cluster members need to bring in all the necessary resources.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_3', 'png')+ ')',
          width: '380px',
          height: '207px',
          marginLeft: 'calc(50% - 190px)',
          })}),_vm._v(" "),_c('p',[_vm._v("As soon as you install the object, the sector is secured for your cluster for 24 hours. If you fail to build\n      the base within this time, it will become vulnerable and can be destroyed. When you build the base, this time\n      will be extended for 30 days, after which the base needs to be refueled.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Base Expansion")]),_vm._v(" "),_c('p',[_vm._v("When the main base is established, you can build modules around it that expand its functionality: \"processor,\"\n      \"factory,\" \"market,\" \"office and warehouses,\" \"resource scanners,\" and \"summoning beacons.\"")]),_vm._v(" "),_c('p',[_vm._v("In addition to modules, you can place turrets, radars, repair stations, and shields to defend the base.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_4', 'png')+ ')',
          width: '363px',
          height: '321px',
          marginLeft: 'calc(50% - 181px)',
          })}),_vm._v(" "),_c('p',[_vm._v("All structures except the main base consume energy; the main base provides only 5 energy, and to increase it,\n      you need to build generators. Each generator increases the number of resources needed for \"refueling\" the base\n      by 50%.")]),_vm._v(" "),_c('p',[_vm._v("The generator can be placed anywhere, and around it, you can build as around the main base.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_5', 'png')+ ')',
          width: '400px',
          height: '236px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("If a generator is destroyed and a structure is no longer connected to anything, the structure will be\n      \"disconnected\" until the generator is restored.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_6', 'png')+ ')',
          width: '400px',
          height: '236px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("If there is not enough energy to support the base's operation (for example, if generators are destroyed but\n      structures are within the permissible zone), shields, radars, turrets, etc., are turned off one by one until\n      there is enough energy.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Refueling the Base")]),_vm._v(" "),_c('p',[_vm._v("After installing the base, it will operate for only 30 days; to keep it running further, it needs to be\n      \"refueled\" for another 30 days. You can immediately refuel the base for up to 30 (current + 30 days) days in\n      advance.")]),_vm._v(" "),_c('p',[_vm._v("To refuel the base - you need to be on it, enter the \"cluster\" menu, select the base section - choose the\n      current one and press \"refuel.\" You also need to have the necessary thorium in your personal warehouse.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('corp_base_7', 'png')+ ')',
          width: '467px',
          height: '192px',
          marginLeft: 'calc(50% - 233px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("база может иметь весь функционал баз в безопасных секторах")]),_vm._v(" "),_c('li',[_vm._v("дополнительный функционал (призыв мобов, разведка ресурсов, поиск аномалий)")]),_vm._v(" "),_c('li',[_vm._v("все радиусы обзора и радара членов кластера складываются (все видят все)")]),_vm._v(" "),_c('li',[_vm._v("на базе можно устанавливать свой налог на торговлю, переработку и крафт, сам налог идет в кластер")]),_vm._v(" "),_c('li',[_vm._v("доступ к редким ресурсам в большем кол-ве")]),_vm._v(" "),_c('li',[_vm._v("относительную безопасность находят в пустошах")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("the base can have all the functionality of bases in safe sectors")]),_vm._v(" "),_c('li',[_vm._v("additional functionality (summoning mobs, resource reconnaissance, anomaly search)")]),_vm._v(" "),_c('li',[_vm._v("all vision and radar radii of cluster members are cumulative (everyone sees everything)")]),_vm._v(" "),_c('li',[_vm._v("you can set your own tax on trade, processing, and crafting at the base, the tax goes to the cluster")]),_vm._v(" "),_c('li',[_vm._v("access to rare resources in greater quantities")]),_vm._v(" "),_c('li',[_vm._v("relative safety found in the wastelands")])])
}]

export { render, staticRenderFns }