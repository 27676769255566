import {GetGlobalPos} from "../map/gep_global_pos";
import {Scene} from "../create";
import {gameStore} from "../store";
import {addIgnoreObject} from "../interface/mini_map";

let emitterHideRadarStore = []

function HideRadarWork(x, y, r) {
  if (!gameStore.gameReady) return;

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y)) {
    return;
  }

  let shape1 = new Phaser.Geom.Circle(0, 0, r);
  let emitter
  if (emitterHideRadarStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: 'green',
      speed: 0,
      lifespan: 200,
      quantity: 2,
      scale: {start: 0.1, end: 0.1},
      alpha: {start: 0.5, end: 0},
      blendMode: 'SCREEN',
      emitZone: {type: 'random', source: shape1}
    });
    emitter.setDepth(400);
    addIgnoreObject(emitter);
  } else {
    emitter = emitterHideRadarStore.shift()
    // emitter.setEmitZone(new Phaser.GameObjects.Particles.Zones.RandomZone(shape1));
    emitter.emitZones[0].source = shape1
  }

  emitter.explode(10, pos.x, pos.y);

  Scene.time.addEvent({
    delay: 200,                // ms
    callback: function () {
      emitter.stop()
      emitterHideRadarStore.push(emitter);
    },
    loop: false
  });
}

export {HideRadarWork}
