<template>
  <div class="menuBar" :style="{zoom: interfaceZoom}">

    <div class="texture_wrapper"/>

    <div class="audio_player_wrapper"
         :style="{right: audioPlayerVisible ? '40px' : '-200px' }">
      <app-audio-player/>
    </div>

    <div class="menuBarInner vertical" style="float: left">
      <div class="settings"/>
      <div class="menu">
        <div class="menuPoint vertical cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toggleAudioPlayer()">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/audio_player.png').default + ')'}"/>
        </div>
      </div>
    </div>

    <div class="frame10"/>
    <div class="menuBarInner vertical mini">
      <div class="menu vertical">

        <div class="menuPoint mini cursor_hover"
             :title="texts['text_4'][language]"
             :class="{disable: baseStatus.state || countTransports === 0}"
             @click.stop="evacuation"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/evacuation_icon.png').default + ')'}"/>
        </div>

        <div class="menuPoint mini cursor_hover"
             @click="changeSub('help')"
             :title="texts['text_2'][language]"
             @mouseover="playSound('select_sound.mp3', 0.2)">

          <div class="image" :style="{backgroundImage: 'url(' + require('../../assets/icons/help.png').default + ')'}"/>

          <div v-if="sub === 'help'" class="sub" @click.stop="">
            <div @click.stop="openService('help-control', null, null,true)"
                 @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/control_button.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_5'][language] }}</h4>
            </div>

            <div @click.stop="openService('helpWrapper', null, null,true)"
                 @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image" style="height: 90%;width: 90%;"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/help.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_6'][language] }}</h4>
            </div>

            <div
              @click.stop="openService('TechnologyMenu', {fraction: currentPlayer.fraction, category: 'body'}, null,true)"
              @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image" style="height: 90%;width: 90%;"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/technology_menu_icon.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_7'][language] }}</h4>
            </div>

            <div @click.stop="toVK" class="cursor_hover" @mouseover="playSound('select_sound.mp3', 0.2)">
              <div class="image_wrapper">
                <div class="image" style="filter: none;"
                     :style="{backgroundImage: 'url(https://img.icons8.com/color/96/000000/vk-com.png)'}"/>
              </div>
              <h4>{{ texts['sub_8'][language] }}</h4>
            </div>

            <div @click.stop="toDiscord" class="cursor_hover" @mouseover="playSound('select_sound.mp3', 0.2)">
              <div class="image_wrapper">
                <div class="image" style="filter: none;"
                     :style="{backgroundImage: 'url(https://img.icons8.com/color/96/discord-new-logo.png)'}"/>
              </div>
              <h4>{{ texts['sub_9'][language] }}</h4>
            </div>

            <div v-if="currentUser.user_role === 'admin' || currentUser.user_role === 'toster'"
                 @click.stop="openService('DamageLog')"
                 class="cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.2)">
              <div class="image_wrapper">

              </div>
              <h4>Лог урона</h4>
            </div>
          </div>
        </div>

        <div class="menuPoint mini cursor_hover" title="Fullscreen"
             @click.stop="toggleFullScreen"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/fullscreen.png').default + ')'}"/>
        </div>

        <div class="menuPoint mini cursor_hover" title="Exit game"
             @click.stop="exitGame"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/exit_game.png').default + ')'}"/>
        </div>

      </div>
    </div>

    <div class="menuBarInner vertical mini" style="bottom: 148px">
      <div class="menu vertical">
        <div class="menuPoint mini cursor_hover" title="Hangar"
             @click.stop="openService('inventoryBox')"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/category_bodies.png').default + ')'}"/>
        </div>

        <div class="menuPoint mini cursor_hover"
             @click="changeSub('social')"
             :title="texts['text_1'][language]"
             :class="{select: selectSkill}"
             @mouseover="playSound('select_sound.mp3', 0.2)">

          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/group.png').default + ')'}"/>

          <div v-if="sub === 'social'" class="sub" @click.stop="">

            <div @click.stop="openService('userStat' + currentPlayer.id, {id: currentPlayer.id}, 'userStat', true)"
                 :class="{select: selectSkill}"
                 class="cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.2)">
              <div class="image_wrapper">
                <div class="image"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/profile_info.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_1'][language] }}</h4>
            </div>

            <div @click.stop="openService('FriendList', null, null,true)"
                 @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/contacts.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_2'][language] }}</h4>
            </div>

            <div @click.stop="openService('GroupMenu', null, null,true)"
                 @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/group.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_3'][language] }}</h4>
            </div>

            <div @click.stop="openService('Corporation', null, null,true)"
                 @mouseover="playSound('select_sound.mp3', 0.2)" class="cursor_hover">
              <div class="image_wrapper">
                <div class="image"
                     :style="{backgroundImage: 'url(' + require('../../assets/icons/claster_icon.png').default + ')'}"/>
              </div>
              <h4>{{ texts['sub_4'][language] }}</h4>
            </div>
          </div>
        </div>

        <div class="menuPoint mini cursor_hover" title="Market"
             @click.stop="openService('marketBox')"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/market.png').default + ')'}"/>
        </div>

        <div class="menuPoint mini cursor_hover" title="Map"
             :class="{select: selectGlobalMap}"
             @click.stop="openService('GlobalViewMap')"
             @mouseover="playSound('select_sound.mp3', 0.2)">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/poligon.png').default + ')'}"/>
        </div>
      </div>
    </div>

    <div class="menuBarInner chat_button"
         style="bottom: 0; right: calc(24% - 50px); height: 22px;border-radius: 3px 3px 0 0"
         :title="texts['text_3'][language]">
      <div class="frame15"/>
      <div class="menu">
        <div class="menuPoint cursor_hover"
             :class="{on: inventory.unit && inventory.unit.autoPilot}"
             style="
                border-radius: 3px;
                margin-left: 4px;
                margin-top: -1px;
                height: 20px;
                width: 20px;"
             @mouseover="playSound('select_sound.mp3', 0.2)"
             @click.stop="autoPilotChange">
          <div class="image"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/ai_move_off.png').default + ')'}"/>
        </div>
      </div>
    </div>

    <div class="frame14"/>
    <div class="credits_wrapper cursor_hover" title="кредиты" @click="openService('SendCredits', null)">
      <div class="credits">
        <div class="credits_icon"/>
        <div class="credits_count">{{ credits }}</div>
      </div>
    </div>

    <div class="aInvButton"
         :style="{transform: addInvVisible ? 'scale(-1, -1)' : 'none'}"
         @click.stop="addInvVisible = !addInvVisible">
    </div>

    <div class="inventory_size_out_wrapper">
      <div class="inventory_size_out_wrapper2 cursor_hover" title="трюм"
           @click.stop="openService('wrapperInventoryAndStorage')">
        <div class="inventory_size" :class="{blink_red: removeThoriumNoFreeSpaceInventory}">
          <div class="inventory_size_icon"/>
          <div class="inventory_size_wrapper">
            <app-size v-if="inventory.warehouses['squadInventory']"
                      v-bind:capacity="inventory.warehouses['squadInventory'].capacity"
                      v-bind:size="inventory.warehouses['squadInventory'].size"/>
          </div>
        </div>
      </div>

      <div class="aInvWrapper" :style="{bottom: addInvVisible ? '27px' : '-200px' }">

        <template v-for="ai in aInv">
          <div class="aInvIcon"
               :style="{backgroundImage: 'url(' + getInvIcon(ai.id) + ')'}"
          />

          <div class="aInvWrapper2">
            <app-size v-bind:capacity="ai.warehouse.capacity"
                      v-bind:size="ai.warehouse.size"
                      v-bind:min="true"/>
          </div>
        </template>
      </div>
    </div>

    <!--    <div class="reactor_out_wrapper"-->
    <!--         :title="texts['text_5'][language]">-->
    <!--      <div class="reactor">-->
    <!--        <div class="reactor_icon"/>-->
    <!--        <app-reactor-status/>-->
    <!--      </div>-->
    <!--    </div>-->

    <app-notifys/>

    <div class="menuBarInner chat_button" style="float: left"
         v-if="!openComponents['chat'] || !openComponents['chat'].open">
      <div class="chat_button_texture"/>
      <div class="menu">
        <div class="menuPoint cursor_hover" style="
        margin-left: 4px;
        margin-top: 4px;
        height: 23px;
        width: 23px;"
             @mouseover="playSound('select_sound.mp3', 0.2)"
             @click.stop="openService('chat')">
          <div class="image" :style="{backgroundImage: 'url(' + require('../../assets/icons/chat.png').default + ')'}"/>
        </div>
      </div>
    </div>

    <div class="menuBarInner mail_button" style="float: left">
      <div class="count_mails" v-if="notOpenMails > 0">{{ notOpenMails }}</div>
      <div class="mail_button_texture"/>
      <div class="menu">
        <div class="menuPoint cursor_hover" style="
        margin-left: 4px;
        margin-top: 4px;
        height: 23px;
        width: 23px;"
             @mouseover="playSound('select_sound.mp3', 0.2)"
             @click.stop="openService('Mail')">
          <div class="image" :style="{backgroundImage: 'url(' + require('../../assets/icons/mail.png').default + ')'}"/>
        </div>
      </div>
    </div>

    <div class="bottom_line">
    </div>
  </div>
</template>

<script>
import Size from '../Inventory/Size'
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import AudioPlayer from "../AudioPlayer/AudioPlayer"
import ReactorStatus from '../StatusBar/ReactorStatus';
import Notifys from './Notifys'

export default {
  name: "MenuBar",
  data() {
    return {
      sub: '',
      audioPlayerVisible: false,
      addInvVisible: false,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toVK() {
      window.open('https://vk.com/veliri', '_blank');
    },
    toDiscord() {
      window.open('https://discord.gg/tFMj6Ffr', '_blank');
    },
    changeSub(sub) {
      this.playSound('button_press.mp3', 0.3)
      this.sub === sub ? this.sub = '' : this.sub = sub
    },
    toggleAudioPlayer() {
      this.playSound('button_press.mp3', 0.3)
      this.audioPlayerVisible = !this.audioPlayerVisible
    },
    autoPilotChange() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "AutoPilotChange"
        }
      }));
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    evacuation() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "evacuation"
        }
      }));
    },
    toggleFullScreen() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("toggleFullScreen");
    },
    exitGame() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "ExitGame"
        }
      }));

      if (!(this.battleState && this.battleState.type.includes('training'))) {
        this.$router.push({path: "/gate"});
      }
    },
    getInvIcon(key) {
      if (key === 'aInv:fuel') {
        return require('../../assets/icons/category_fuel_mini.png').default
      }

      if (['aInv:resource', 'aInv:organic', 'aInv:oil', 'aInv:dust'].includes(key)) {
        return require('../../assets/icons/category_resource_mini.png').default
      }

      return require('../../assets/icons/inventory_size_icon.png').default
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('MenuBar')
    },
    battleState() {
      return this.$store.getters.getBattleState;
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    currentUser() {
      return this.$store.getters.getGameUser
    },
    market() {
      return this.$store.getters.getMarket
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    chatState() {
      let chats = this.$store.getters.getChatState;
      this.currentChatID = chats.currentChatID;
      return chats
    },
    openComponents() {
      return this.$store.getters.getNeedOpenComponents
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    countTransports() {
      return this.$store.getters.getCountTransports
    },
    removeThoriumNoFreeSpaceInventory() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('remove_thorium_no_free_space_inventory') && errors['remove_thorium_no_free_space_inventory'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'remove_thorium_no_free_space_inventory',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectGlobalMap() {
      if (!(this.missions && this.missions.currentAction && this.missions.currentAction.description.RU && this.missions.currentAction.description.RU.includes("Отправится в сектор"))) {
        return false;
      }

      // todo только для обучения
      return this.missions.currentAction.type_func_monitor === "to_sector";
    },
    selectSkill() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "learn_skill";
    },
    notOpenMails() {
      return this.$store.getters.getCountNotOpenMails
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    credits() {
      let cr = (this.market.my_credits / 100).toFixed(2)
      if (cr <= 999999) {
        return cr
      } else if (cr <= 99999999) {
        return (this.market.my_credits / 100).toFixed(0)
      } else {
        return (this.market.my_credits / 100 / 1000).toFixed(0) + 'k'
      }
    },
    aInv() {
      let inventorys = []
      for (let i in this.inventory.warehouses) {
        if (!i.includes('hangar') && !i.includes('scanner') && i.includes('aInv')) {
          inventorys.push({id: i, warehouse: this.inventory.warehouses[i]})
        }
      }

      return inventorys
    },
  },
  components: {
    AppSize: Size,
    AppBackgroundItemCell: BackgroundItemCell,
    AppAudioPlayer: AudioPlayer,
    AppReactorStatus: ReactorStatus,
    AppNotifys: Notifys,
  }
}
</script>

<style scoped>
.menuBar {
  position: fixed;
  display: block;
  width: calc(100% - 2px);
  height: 32px;
  bottom: 0;
  z-index: 900;
  pointer-events: none;
}

.menuBarInner {
  height: calc(100% - 1px);
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0 0 2px black;
  position: absolute;
  z-index: 12;
  pointer-events: auto;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.menuBarInner.vertical {
  border-radius: 5px 0 0 5px;
  right: 0;
  bottom: 10px;
}

.menuBarInner.vertical.mini {
  width: 24px;
  bottom: 48px;
  height: unset;
  border-radius: 0;
}

.menu, .credits, .inventory_size, .reactor, .credits_up {
  float: left;
}

.reactor {
  padding: 2px 0 2px 0;
  width: 108px;
}

.credits, .inventory_size, .credits_up {
  width: 66px;
  height: 100%;
  pointer-events: none;
}

.inventory_size {
  width: 93px;
}

.blink_red {
  background: red;
  -webkit-animation: blink_red_animation 500ms infinite; /* Safari 4+ */
  -moz-animation: blink_red_animation 500ms infinite; /* Fx 5+ */
  -o-animation: blink_red_animation 500ms infinite; /* Opera 12+ */
  animation: blink_red_animation 500ms infinite; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes blink_red_animation {
  0%, 49% {
    background-color: #ff0000;
  }
  50%, 100% {
    background-color: rgba(8, 138, 210, 0.3);
  }
}

.credits_up {
  width: 20px;
  height: 16px;
  margin-right: 5px;
  background: none;
}

.credits_up:hover {
  background-color: #b4eaff;
}

.credits_up:active {
  transform: scale(.98);
}

.credits_icon, .inventory_size_icon, .reactor_icon, .credits_up_icon {
  height: 18px;
  width: 18px;
  margin-top: -2px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: 100%;
  background-position: -1px;
  background-image: url('../../assets/icons/credits_mini.png');
  position: absolute;
  left: 2px;
  top: 1px;
}

.credits_count, .inventory_size_wrapper {
  background: #066ea8;
  float: right;
  width: calc(100% - 19px);
  padding-right: 2px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 10px;
  background: rgba(0, 0, 0, .17);
}

.credits_count {
  height: 13px;
  margin-top: 2px;
  margin-right: 4px;
  width: calc(100% - 21px);
  border-radius: 2px;
}

.reactor_icon, .inventory_size_icon {
  background-image: url('../../assets/icons/thorium_mini.png');
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

.reactor_icon {
  position: relative;
}

.inventory_size_icon {
  background-image: url('../../assets/icons/inventory_size_icon.png');
  top: 2px;
  left: 1px;
  background-size: 75%;
  background-position: 1px;
  background-repeat: no-repeat;
}

.credits_icon {
  height: 16px;
  width: 16px;
  top: 2px;
  left: 1px;
}

.inventory_size_wrapper {
  width: calc(100% - 16px);
  padding-right: 0;
  overflow: hidden;
  height: 100%;
  background: none;
  box-shadow: none;
}

.menuPoint {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 4px 2px 2px 4px;
  float: left;
  transition: 0.1s;
  background-size: contain;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 2px 0 black;
  height: 23px;
  width: 23px;
}

.menuPoint.mini {
  margin: 2px 2px 1px 2px;
  height: 20px;
  width: 20px;
  border-radius: 3px;
}

.menuPoint:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.menuPoint:active {
  transform: scale(0.95);
}

.sub {
  position: absolute;
  min-height: 20px;
  min-width: 200px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  border-radius: 5px;
  bottom: calc(100% + 5px);
  right: 30px;
}

.sub > div {
  height: 35px;
  clear: both;
  background: #0cc2fb;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  margin: 2px;
  line-height: 35px;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.sub > div .image_wrapper {
  height: 30px;
  width: 30px;
  box-shadow: inset 0 0 2px black;
  border: 1px solid grey;
  background: #8cb3c7;
  border-radius: 5px;
  margin: 2px 5px 2px 5px;
  float: left;
  transition: 0.1s;
  background-size: contain;
}

.sub > div h4 {
  margin: 0 2px;
  float: left;
  opacity: 0.8;
  font-size: 18px;
}

.sub > div:hover {
  border: 1px solid rgba(37, 160, 225, 0.8);
  background: rgba(8, 138, 210, 0.8);
}

.sub > div:hover h4 {
  opacity: 1;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.bottom_line {
  border: 1px solid rgba(37, 160, 225, 0.3);
  background: rgba(8, 138, 210, 0.3);
  height: 5px;
  box-shadow: 0 0 2px black;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

.credits_wrapper, .inventory_size_out_wrapper, .reactor_out_wrapper, .autopilot {
  z-index: 999;
  pointer-events: auto;
  height: calc(100% - 11px);
  top: 12px;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  position: absolute;
}

.reactor_out_wrapper {
  right: 518px;
}

.inventory_size_out_wrapper2 {
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  height: 100%;
}

.inventory_size_out_wrapper {
  background: none;
  backdrop-filter: none;
  right: calc(24% + 72px);
}

.credits_wrapper {
  right: calc(24% - 1px);
}

.autopilot {
  right: 136px;
}

.inventory_size_out_wrapper2:hover, .credits_wrapper:hover {
  background: rgba(255, 96, 0, 1);
}

.credits_up {

}

.menuPoint_audio {
  height: 22px;
  width: 22px;
  filter: none;
  background-size: contain;
  border-radius: 5px;
  padding: 2px;
}

.menuPoint_audio:hover {
  background-color: #b4eaff;
}

.menuPoint_audio:active {
  transform: scale(0.95);
}

.audio_player_wrapper {
  position: absolute;
  bottom: 10px;
  right: calc(100% - 35px);
  transition: 100ms;
}

.on {
  background-image: url("../../assets/icons/ai_move_on.png") !important;
}

.image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
  pointer-events: none;
}

.chat_button, .mail_button {
  border-radius: 0 5px 5px 0;
  bottom: 58px;
}

.mail_button {
  bottom: 25px;
}

.texture_wrapper, .chat_button_texture, .mail_button_texture, .settings, .frame14, .frame10, .frame15 {
  height: 35px;
  width: 635px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 1001;
  top: -2px;
  right: 0;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.75));
}

.chat_button_texture, .mail_button_texture {
  background-image: url("../../assets/interface/frame_11.png");
  left: 0;
  top: -1px;
  height: 32px;
  width: 31px;
}

.settings {
  background-image: url("../../assets/interface/frame_11.png");
  left: 0;
  top: -1px;
  height: 32px;
  width: 31px;
  transform: rotate(180deg);
}

.frame14 {
  background-image: url("../../assets/interface/frame_14.png");
  top: -3px;
  height: 35px;
  width: 184px;
  right: calc(24% + -9px);
}

.frame15 {
  background-image: url("../../assets/interface/frame_15.png");
  left: -9px;
  top: -6px;
  height: 29px;
  width: 48px;
}

.frame10 {
  background-image: url("../../assets/interface/frame_10.png");
  height: 205px;
  width: 31px;
  top: -218px;
  right: -2px;
}

.count_mails {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 1px;
  left: 12px;
  color: white;
  z-index: 901;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
}

.disable {
  filter: grayscale(1);
  opacity: 0.5;
}

.select {
  box-shadow: 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
}

.aInvWrapper {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 90px;
  min-height: 2px;
  background: rgba(8, 138, 210, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 3px;
  text-shadow: 1px 1px 1px black;
  border: 1px solid #5372837d;
  transition: 100ms;
}

.aInvWrapper2 {
  width: 72px;
  float: right;
}

.aInvIcon {
  float: left;
  width: 14px;
  height: 14px;
  background-size: cover;
  margin: 1px;
  box-shadow: inset 0 0 1px black;
  background-repeat: no-repeat;
}

.aInvButton {
  position: absolute;
  bottom: 21px;
  height: 9px;
  width: 22px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
  background-image: url("../../assets/icons/ainv_button.png");
  background-size: contain;
  z-index: 1002;
  right: calc(24% + 73px);
  pointer-events: all;
  background-repeat: no-repeat;
}

.aInvButton:hover {
  background-image: url("../../assets/icons/ainv_button_on.png");
}

.aInvButton:active {
  transform: scale(0.97);
}
</style>

<style>
.menuBar .sizeInventoryInfo {
  box-shadow: inset 0 0 2px black !important;
  background: none !important;
  border: none !important;
  border-radius: 2px !important;
  margin-top: 2px !important;
  height: 13px !important;
  width: calc(100% - 4px) !important;
}

.menuBar .sizeInventoryInfo.min {
  height: 12px !important;
  margin: 2px !important;
}

.menuBar .sizeInventoryInfo span {
  background-image: none !important;
  font-size: 10px !important;
  line-height: 14px !important;
}

.menuBar .sizeInventoryInfo.min span {
  line-height: 13px !important;
  font-size: 9px !important;
}

.menuBar .sizeInventoryInfo .realSize {
  background: rgba(255, 96, 0, 1) !important;
  box-shadow: inset 0 0 2px black !important;
  z-index: -1;
  position: relative;
}

.menuBar .sizeInventoryInfo span {
  filter: none !important;
}
</style>
