<template>
  <section>
    <template v-if="lang==='RU'">
      <h3 class="headSection">Системные требования:</h3>
      <table class="system_requirements">
        <tr>
          <td colspan="2">1. Клавиатура, мышь</td>
        </tr>
        <tr>
          <td>2. Браузер:</td>
          <td>да</td>
        </tr>
        <tr>
          <td colspan="2" style="color: orange">3. Поддержка аппаратного ускорения в браузере</td>
        </tr>
        <tr>
          <td>4. Разрешение экрана:</td>
          <td>не менее 1280 x 720</td>
        </tr>
      </table>
    </template>

    <template v-if="lang==='EN'">
      <h3 class="headSection">System requirements:</h3>
      <table class="system_requirements">
        <tr>
          <td colspan="2">1. Keyboard, mouse</td>
        </tr>
        <tr>
          <td>2. Browser:</td>
          <td>yes</td>
        </tr>
        <tr>
          <td colspan="2" style="color: orange">3. Hardware acceleration support in the browser</td>
        </tr>
        <tr>
          <td>4. Screen resolution:</td>
          <td>at least 1280 x 720</td>
        </tr>
      </table>
    </template>
  </section>
</template>

<script>
export default {
  name: "NecessaryRequirements",
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.headSection {
  color: #ffc588;
}

@media (max-width: 650px) {
  .headSection {
    font-size: 13px;
  }

  .system_requirements {
    font-size: 10px;
  }
}
</style>
