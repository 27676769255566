<template>
  <div style="height: calc(100% - 2px);">
    <div v-if="dialog.type === 'new_war'">
      <div class="mask"/>

      <div class="error_message" :class="{error_message_visible: startWarMsg}">
        <div style="margin-top: 14%">{{ texts['text_63'][language] }}</div>
      </div>

      <div class="error_message" :class="{error_message_visible: baseBusy}">
        <div style="margin-top: 14%">{{ texts['text_64'][language] }}</div>
      </div>

      <div class="error_message" :class="{error_message_visible: wrongBase}">
        <div style="margin-top: 14%">{{ texts['text_65'][language] }}</div>
      </div>

      <div class="error_message" :class="{error_message_visible: warAlready}">
        <div style="margin-top: 14%">{{ texts['text_66'][language] }}</div>
      </div>

      <div class="error_message" :class="{error_message_visible: targetNoBase}">
        <div style="margin-top: 14%">{{ texts['text_67'][language] }}</div>
      </div>

      <div class="error_message" :class="{error_message_visible: targetSurrender}">
        <div style="margin-top: 14%">{{ texts['text_68'][language] }}</div>
      </div>

      <div class="wrapper">

        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper">
            <template v-if="!search_corp">
              <input type="text" v-model="corp_name"
                     class="cursor_hover"
                     :placeholder="texts['placeholder_5'][language]"/>
              <input type="button"
                     :value="texts['button_38'][language]"
                     class="cluster_button cursor_hover"
                     style="width: 45%; float: right; position: unset"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="search">

              <div class="not_found" v-if="notFound">
                {{ texts['text_69'][language] }}
              </div>
            </template>

            <template v-if="search_corp && corporations[search_corp.corporation.id]">
              <app-corporation-line :corp="search_corp.corporation"
                                    :logo="corporations[search_corp.corporation.id].logo"
                                    :parent="'CorporationStarWar'"
                                    :buttonExit="true"
                                    :exitFunc="removeSearchCorp"/>

              <div class="setting_row">
                <div class="setting_head" style="width: 110px">{{ texts['text_70'][language] }}</div>

                <select v-model="deposit_id" class="local_select settings cursor_hover" style="width: 190px">
                  <option value="-1">{{ texts['option_30'][language] }}</option>
                  <option :value="d.id" v-for="d in deposits">{{ d.name }} ({{ (d.credits / 100).toFixed(2) }})</option>
                </select>
              </div>

              <p class="price_text" v-html="texts['text_71'][language]"/>
              <p class="price_text" style="color: #ff4c4c">
                {{ creditsIsSelectDeposit < 1000000 ? texts['text_72'][language] : '&nbsp;' }}
              </p>

              <div class="setting_row" style="border-top: 1px solid rgb(0 0 0); padding-top: 4px;margin-top: 10px;">
                <div class="setting_head" style="width: 100px">{{ texts['text_73'][language] }}</div>

                <select v-model="base_id" class="local_select settings cursor_hover" style="width: 190px">
                  <option value="-1">{{ texts['option_31'][language] }}</option>
                  <option :value="b.id" v-for="b in bases">{{ b.name }} ({{ getSectorNameByID(b.map_id) }})</option>
                </select>
              </div>

              <p class="price_text">{{ texts['text_74'][language] }}</p>
            </template>
          </div>
        </div>

        <input type="button"
               :value="texts['button_39'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialog.type = ''; removeSearchCorp();">

        <input type="button"
               :value="texts['button_40'][language]"
               class="cluster_button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               style="float: right; position: unset; width: 45%"
               @click="startWar()"
               :class="{disable: !search_corp || creditsIsSelectDeposit < 1000000 || base_id === -1}">
      </div>
    </div>

    <div v-if="dialog.type === 'status_quo'">
      <div class="mask"/>

      <div class="error_message" :class="{error_message_visible: setSurrender}">
        <div style="margin-top: 14%">{{ texts['text_75'][language] }}</div>
      </div>

      <div class="wrapper">

        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper">

            <div v-if="getSurrenderOffer(dialog.payload).my"
                      v-html="texts['text_76'][language].replace('%corporation_name%', getHostileCorporationName(dialog.payload))"/>

            <div v-if="!getSurrenderOffer(dialog.payload).my"
                      v-html="texts['text_77'][language].replace('%corporation_name%', getHostileCorporationName(dialog.payload))"/>

          </div>
        </div>

        <input type="button"
               :value="texts['button_41'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialog.type = '';">

        <input type="button"
               :value="getSurrenderOffer(dialog.payload).my ? texts['button_42'][language] : texts['button_43'][language]"
               class="cluster_button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeSurrender(dialog.payload, !getSurrenderOffer(dialog.payload).my)"
               style="float: right; position: unset; width: 45%">
      </div>
    </div>

    <div v-if="dialog.type === 'set_mutual'">
      <div class="mask"/>

      <div class="error_message" :class="{error_message_visible: changeMutualMsg}">
        <div style="margin-top: 14%">{{ texts['text_78'][language] }}</div>
      </div>

      <div class="wrapper">

        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper">

            <div v-if="dialog.payload.mutual"
                      v-html="texts['text_79'][language].replace('%corporation_name%', getHostileCorporationName(dialog.payload))"/>

            <div v-if="!dialog.payload.mutual"
                      v-html="texts['text_80'][language].replace('%corporation_name%', getHostileCorporationName(dialog.payload))"/>

          </div>
        </div>

        <input type="button"
               :value="texts['button_44'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialog.type = '';">

        <input type="button"
               :value="texts['button_45'][language]"
               class="cluster_button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeMutual(dialog.payload, !dialog.payload.mutual)"
               style="float: right; position: unset; width: 45%">
      </div>
    </div>

    <div class="tableWrap">

      <div class="warRow" v-if="wars && Object.keys(wars).length > 0" v-for="w in wars">
        <app-corporation-line v-if="corporations[getHostileID(w)]"
                              :corp="corporations[getHostileID(w)].corporation"
                              :logo="corporations[getHostileID(w)].logo"
                              :parent="'CorporationWar'"
                              @click.native="selectWarID === w.id ? selectWarID = -1 : selectWarID = w.id"
        />

        <div class="war_type" v-html="getNameTypeWar(w)"/>

        <template v-if="w.id === selectWarID">

          <!--      - статус войны (подготовка/завершение/активна)-->
          <div class="setting_row">
            <div class="setting_head" style="width: 120px">{{ texts['text_81'][language] }}</div>
            <div class="local_select settings div">{{ getWarStatus(w) }}</div>
          </div>

          <!--      - показать в каком базе/секторе штаб -->
          <div class="setting_row">
            <div class="setting_head" style="width: 120px">{{ texts['text_85'][language] }}</div>
            <div class="local_select settings div">{{
                getHeadquartersName(w.headquarters_base_id, w.attack_corporation_id)
              }}
            </div>
          </div>

          <!--      - кнопки перемерия, показано предложил ли враг перемирие и кнопка что бы отозвать/предложить самому-->
          <div class="setting_row">
            <div class="setting_head" style="width: 120px">{{ texts['text_86'][language] }}</div>

            <div class="custom_check_box hostile" :class="{check: getSurrenderOffer(w).hostile}"></div>
            <div class="custom_check_box" :class="{check: getSurrenderOffer(w).my}"></div>

            <input type="button"
                   :value="getSurrenderOffer(w).my ? texts['button_46'][language] : texts['button_47'][language]"
                   class="cluster_button cursor_hover"
                   @click="openDialog('status_quo', w)"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   :class="{disable: warEnding(w)}"
                   style="float: right; position: unset; width: 86px">
          </div>

          <!--      - "взаимная" и кнопка ее таковой сделать, если это оборонительная война-->
          <div class="setting_row">
            <div class="setting_head" style="width: 120px">{{ texts['text_89'][language] }}</div>
            <div class="custom_check_box" :class="{hostile: getTypeWar(w) !== 'defend', check: w.mutual}"></div>

            <input type="button"
                   :value="texts['button_48'][language]"
                   class="cluster_button cursor_hover"
                   v-if="getTypeWar(w) === 'defend'"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="openDialog('set_mutual', w)"
                   :class="{disable: warEnding(w)}"
                   style="float: right; position: unset; width: 86px; margin-right: 27px;">
          </div>

          <!--      - показывать счет и деньги на нем с которого спонсируется война а так же когда следующий взнос-->
          <template v-if="getTypeWar(w) === 'attack'">
            <div class="setting_row" v-if="getDepositByID(w.deposit_id)">
              <div class="setting_head" style="width: 120px">{{ texts['text_90'][language] }}</div>
              <div class="local_select settings div">{{ getDepositByID(w.deposit_id).name }}</div>
            </div>

            <div class="setting_row" v-if="getDepositByID(w.deposit_id)">
              <div class="setting_head" style="width: 120px">{{ texts['text_91'][language] }}</div>
              <div class="local_select settings div">
                {{ getPaymentTime(w, w.last_payment_time) }}
              </div>
            </div>
          </template>
        </template>
      </div>

      <div class="no_wars" v-if="!wars || Object.keys(wars).length === 0">
        {{ texts['text_92'][language] }}
      </div>
    </div>

    <input type="button"
           :value="texts['button_49'][language]"
           class="cluster_button cursor_hover"
           style="position: unset; width: 150px; margin-left: calc(50% - 75px)"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="openDialog('new_war')">
  </div>
</template>

<script>
import {urls} from "../../const";
import CorporationLine from '../Corporation/CorporationLine';
import Vue from "vue";

export default {
  name: "Wars",
  data() {
    return {
      dialog: {
        type: '',
        payload: {},
      },
      search_corp: null,
      corp_name: null,
      deposit_id: -1,
      base_id: -1,
      notFound: false,
      corporations: {},
      selectWarID: -1,
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetWars",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openDialog(type, payload) {
      this.dialog.type = type
      this.dialog.payload = payload
    },
    getSurrenderOffer(w) {
      if (w.attack_corporation_id === this.corporation.info.id) {
        return {my: w.attack_offer_surender, hostile: w.defend_offer_surender}
      }

      if (w.defend_corporation_id === this.corporation.info.id) {
        return {my: w.defend_offer_surender, hostile: w.attack_offer_surender}
      }
    },
    getHostileCorporationName(w) {
      let id = 0
      if (w.attack_corporation_id === this.corporation.info.id) {
        id = w.defend_corporation_id
      }

      if (w.defend_corporation_id === this.corporation.info.id) {
        id = w.attack_corporation_id
      }

      if (this.corporations[id] && this.corporations[id].corporation) {
        return this.corporations[id].corporation.name
      }
    },
    getWarStatus(w) {
      let startTime = Date.parse(w.start_time)
      let endTime = Date.parse(w.end_time)

      if (endTime > 0) {
        return this.texts['text_82'][this.language]
      }

      let startHours = Math.abs(new Date() - new Date(startTime)) / 36e5;
      if (startHours < 24) {
        return this.texts['text_83'][this.language]
      }

      return this.texts['text_84'][this.language]
    },
    warEnding(w) {
      let endTime = Date.parse(w.end_time)
      if (endTime > 0) {
        return true
      }
    },
    getPaymentTime(w, t) {
      let endTime = Date.parse(w.end_time)

      if (endTime > 0) {
        return '-- --'
      }

      let lastPaymentTime = Date.parse(t)
      let utcTimestamp = new Date().getTime();

      let date = new Date(0);
      date.setMilliseconds(utcTimestamp - lastPaymentTime);

      let date2 = new Date(0);
      date2.setHours(24 * 7);

      let date3 = new Date(0);
      date3.setMilliseconds(date2.getTime() - date.getTime());

      return Math.floor(date3.getTime() / 1000 / 60 / 60 / 24) + "д " + date3.getHours() + ":" + date3.getMinutes();
    },
    getHostileID(w) {
      if (w.attack_corporation_id === this.corporation.info.id) {
        return w.defend_corporation_id
      }

      if (w.defend_corporation_id === this.corporation.info.id) {
        return w.attack_corporation_id
      }

      return 0
    },
    getNameTypeWar(w) {
      if (this.getTypeWar(w) === 'attack') {
        return `<span style='color: cornflowerblue'>${this.texts['text_87'][this.language]}<span>`
      }

      if (this.getTypeWar(w) === 'defend') {
        return `<span style='color: #ff4c4c'>${this.texts['text_88'][this.language]}<span>`
      }
    },
    getTypeWar(w) {
      if (w.attack_corporation_id === this.corporation.info.id) {
        return 'attack'
      }

      if (w.defend_corporation_id === this.corporation.info.id) {
        return 'defend'
      }
    },
    search() {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_name=' + app.corp_name).then(function (response) {
        app.$set(app, 'search_corp', response.data);
        if (!response.data) {
          app.notFound = true;
          setTimeout(function () {
            app.notFound = false;
          }, 1000)
        } else {
          app.$set(app.corporations, response.data.corporation.id, response.data);
          app.getCorpLogo(response.data.corporation.id);
        }
      });
    },
    getCorporationByID(id) {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        if (response.data) {
          app.$set(app.corporations, response.data.corporation.id, response.data);
          app.getCorpLogo(response.data.corporation.id);
        }
      })
    },
    getCorpLogo(id) {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.corporations[id], 'logo', response.data.avatar);
      });
    },
    removeSearchCorp() {
      Vue.set(this, 'search_corp', null);
    },
    getSectorNameByID(id) {

      for (let i in this.handbook['sector'][this.language]) {
        if (this.handbook['sector'][this.language][i].id === id) {
          return this.handbook['sector'][this.language][i].name;
        }
      }

      return ''
    },
    startWar() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "StartWar",
          value_2: this.deposit_id,
          id: this.search_corp.corporation.id,
          value: this.base_id,
        }
      }));
    },
    msgLogic(typeMsg, close, time) {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty(typeMsg) && errors[typeMsg].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: typeMsg,
            active: false,
          });

          if (close) {
            this.dialog.type = '';
            this.removeSearchCorp();
          }
        }.bind(this), time)

        return true
      }

      return false
    },
    getDepositByID(id) {
      for (let d of this.deposits) {
        if (d.id === id) {
          return d
        }
      }
    },
    changeMutual(war, mutual) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "SetMutualWar",
          id: war.id,
          data: String(mutual),
        }
      }));
    },
    changeSurrender(war, surrender) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "Surrender",
          id: war.id,
          data: String(surrender),
        }
      }));
    },
    getHeadquartersName(id, corpID) {
      if (!this.headquartersNames.hasOwnProperty(id)) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "corporation",
          data: {
            event: "GetHeadquartersName",
            id: id,
            value: corpID,
          }
        }));

        return '...'
      }

      return this.headquartersNames[id].name + ' (' + this.getSectorNameByID(this.headquartersNames[id].map_id) + ')'
    }
  },
  computed: {
    handbook() {
      return this.$store.getters.getHandBook
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    wars() {
      let wars = this.$store.getters.getWars
      for (let i in wars) {
        let id = this.getHostileID(wars[i]);
        if (!this.corporations.hasOwnProperty(id)) {
          this.getCorporationByID(id)
        }
      }

      return wars
    },
    headquartersNames() {
      return this.$store.getters.getHeadquartersNames
    },
    deposits() {
      if (this.corporation && this.corporation.deposits) {
        return this.corporation.deposits.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return [];
    },
    creditsIsSelectDeposit() {
      for (let d of this.deposits) {
        if (d.id === this.deposit_id) {
          return d.credits
        }
      }

      return 0
    },
    bases() {
      return this.corporation.bases.bases
    },
    startWarMsg() {
      return this.msgLogic('start_war', true, 750)
    },
    baseBusy() {
      return this.msgLogic('war_headquarter_busy', false, 1500)
    },
    wrongBase() {
      return this.msgLogic('wrong_headquarter', false, 1500)
    },
    warAlready() {
      return this.msgLogic('war_already', false, 1500)
    },
    targetNoBase() {
      return this.msgLogic('war_target_no_base', false, 1500)
    },
    targetSurrender() {
      return this.msgLogic('war_surrender', false, 1500)
    },
    changeMutualMsg() {
      return this.msgLogic('war_change_mutual', true, 750)
    },
    setSurrender() {
      return this.msgLogic('set_surrender', true, 750)
    },
  },
  components: {
    AppCorporationLine: CorporationLine,
  }
}
</script>

<style scoped>
.tableWrap {
  width: calc(100% - 4px);
  margin: 2px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 19px);
}

.no_wars {
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 1px 1px 1px black;
  text-align: center;
  width: 100%;
  padding-top: 20px;
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 12px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  width: 250px;
  position: relative;
  left: calc(50% - 125px);
  user-select: none;
  font-weight: bold;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}

.warRow {
  left: 0;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  min-height: 10px;
  box-shadow: 1px 1px 1px black;
  position: relative;
  float: left;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(0, 0, 0, 0.5);
  margin: 5px;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.wrapper {
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 164px);
  z-index: 11;
  width: 320px;
  height: 202px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
}

.text_wrapper {
  height: 170px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.wrapper input[type=text] {
  width: calc(100% - 10px);
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.not_found {
  height: 25px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(137, 150, 156, 0.7);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  text-shadow: 1px 1px 1px black;
}

.setting_head {
  float: left;
  width: 40%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
  text-indent: 5px;
}

.setting_row {
  border-bottom: 1px solid #7b7b7b;
  float: left;
  width: 100%;
  line-height: 24px;
  height: 24px;
}

.local_select {
  outline: none;
  width: calc(100% - 6px);
  border-radius: 5px;
  margin: 0 0 4px 0;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

.settings {
  width: 45%;
  float: right;
  margin-right: 4px;
}

.local_select option {
  color: black;
  font-weight: bold;
}

.price_text {
  font-size: 11px;
  margin: 4px 0 0 0;
  float: left;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 750ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.war_type {
  position: absolute;
  right: 0;
  top: 1px;
  height: 30px;
  color: #ff4c4c;
  font-size: 12px;
  font-weight: bold;
  border-left: 1px solid black;
  text-indent: 3px;
  line-height: 19px;
  width: 109px;
}

.local_select.settings.div {
  border-radius: 3px;
  background: none;
  width: calc(100% - 130px);
  text-align: right;
  margin: 0 5px 0 0;
  line-height: 24px;
}

.warRow .setting_row:last-child {
  border: none;
}

.custom_check_box {
  float: right;
  margin: 2px 2px;
  height: 16px;
  width: 16px;
  border: 2px solid rgba(100, 149, 237, 0.7);
  background: 1px rgba(127, 127, 127, 0.3);
}

.custom_check_box.hostile {
  border: 2px solid rgba(255, 76, 76, 0.7);
}

.custom_check_box.check {
  background-image: url("../../assets/icons/ready.png");
  background-size: contain;
  background-position: 15px center;
}
</style>
<style>
.warRow .chatUserIcon {
  height: 30px !important;
  width: 30px !important;
}

.warRow .chatUserName {
  font-size: 15px !important;
  height: 30px !important;
  line-height: 42px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}
</style>
