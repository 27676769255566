import {GetGlobalPos} from "../gep_global_pos";
import {gameStore} from "../../store";
import {ShortDirectionRotateTween} from "../../utils/utils";

function CreateRepairStation(state, base, scene) {

  base.setDepth(state.height - 1);
  base.shadow.setDepth(state.height - 2);

  let pos = GetGlobalPos(state.x, state.y, gameStore.map.id);

  let equip = scene.make.sprite({
    x: pos.x + state.equips[1].real_x_attach,
    y: pos.y + state.equips[1].real_y_attach,
    scale: state.scale / 100,
    key: 'structures',
    add: true,
    frame: state.texture + '_equip',
  });
  equip.setOrigin(state.equips[1].x_anchor, state.equips[1].y_anchor);
  equip.setDepth(state.height);

  let xShadow = scene.shadowXOffset * 0.3;
  let yShadow = scene.shadowYOffset * 0.3;

  let equipShadow = scene.make.sprite({
    x: pos.x + state.equips[1].real_x_attach + xShadow,
    y: pos.y + state.equips[1].real_y_attach + yShadow,
    scale: state.scale / 100,
    key: 'structures',
    add: true,
    frame: state.texture + '_equip',
  });
  equipShadow.setDepth(state.height - 1);
  equipShadow.setOrigin(state.equips[1].x_anchor, state.equips[1].y_anchor);
  equipShadow.setAlpha(0.4);
  equipShadow.setTint(0x000000);

  equip.angle = state.equips[1].rotate;
  equipShadow.angle = state.equips[1].rotate;

  base.equip = equip;
  base.equipShadow = equipShadow;

  return base
}

function RotateObjectEquip(data) {
  if (gameStore.objects[data.id]) {
    let obj = gameStore.objects[data.id].objectSprite;

    ShortDirectionRotateTween(obj.equip, data.r - obj.angle, data.ms * 3);
    ShortDirectionRotateTween(obj.equipShadow, data.r - obj.angle, data.ms * 3);
  }
}

export {CreateRepairStation, RotateObjectEquip}
