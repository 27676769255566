<template>
  <div id="chatBox" ref="chatBox" :style="styles" :class="{attention: animate}">
    <app-user-sub-menu v-if="userSubMenuProps.user" v-bind:userSubMenuProps="userSubMenuProps"/>

    <div class="chatMessage">
      <span class="chatSystem">{{ greetings }}</span>
    </div>

    <div class="new_msg_shadow" v-if="animate">
      <div class="new_msg_wrapper">
        <div class="new_msg">
          ATTENTION
        </div>
      </div>
    </div>

    <div class="chatMessage"
         v-for="(message) in wrapMsgs"
         :key="message.uuid"
         :ref="message.uuid"
         @click="openSubMenu($event, message.user_id)">

      <template v-if="message.system">
        <span class="chatSystem" :style="{fontSize: fontSize + 'px'}">{{ message.message }}</span>
      </template>

      <template v-else>
        <div class="chatUserIcon" v-if="UserMessageIcon || message.bot"
             v-bind:style="{backgroundImage: avatars[message.user_id] ? avatars[message.user_id].avatar : undefined}">
          {{ getAvatar(message.user_id) }}
        </div>
        <span class="ChatUserName" :style="{fontSize: fontSize + 'px'}">[{{ playerName(message.user_name) }}]</span>

        <span class="ChatText npc"
              v-if="message.type === 'npc_dialog'"
              :style="{fontSize: fontSize + 'px'}"
              v-html="'<br>' + message.message">
        </span>

        <span class="ChatText"
              :class="{[message.type]: color, private: message.private}"
              :style="{fontSize: fontSize + 'px'}">

          <template v-if="tag">
              <span v-if="message.type === 'local'">[{{ texts['chanel_local'][language] }}]:</span>

              <span v-if="message.type === 'group'">[{{ texts['chanel_group'][language] }}]:</span>

              <span v-if="message.type === 'battle'">[{{ texts['chanel_battle'][language] }}]:</span>

              <span v-if="message.type === 'corporation'">[{{ texts['chanel_corporation'][language] }}]:</span>

              <span v-if="message.type === 'private'">[{{ texts['chanel_private'][language] }}]:</span>
          </template>

          <template v-if="!message.bot">
              {{ tag ? ' ' + parseMessage(message) : parseMessage(message) }}
          </template>

          <span v-if="message.bot" v-html="tag ? ' ' + parseMessage(message) : parseMessage(message)"/>
        </span>

      </template>

    </div>
  </div>
</template>

<script>
import UserSubMenu from './UserSubMenu';

export default {
  name: "Messages",
  props: ["messages", "fontSize", "UserMessageIcon", "styles", "force", "animate", "greetings", 'color', 'tag', 'alwaysToBottom'],
  data() {
    return {
      userSubMenuProps: {
        user: null,
        x: 0,
        y: 0,
        avatar: null,
        additionalTabs: null,
        parent: null,
        dialogChangeRole: null,
      }
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getAvatar(userID) {
      this.$store.dispatch('getAvatar', {
        userID: userID,
        vm: this
      });
    },
    scrollToBottom(id) {
      let wait = setInterval(function () {
        let el = this.$refs[id];
        if (el && el.length > 0) {
          clearInterval(wait)
          el[0].scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
        }
      }.bind(this));
    },
    parseMessage(msg) {
      if (!msg.bot) return msg.message;

      let data = JSON.parse(msg.message);
      return data[this.language];
    },
    playerName(name) {
      if (name === "") return this.texts['chanel_unknown'][this.language]
      return name
    },
    openSubMenu(event, id) {
      this.playSound('button_press.mp3', 0.3)

      let parent = this.$parent.$el;
      let chatState = this.$store.getters.getChatState;
      let p;

      for (let i of chatState.users[chatState.currentChatID]) {
        if (Number(i.id) === Number(id)) p = i;
      }

      this.userSubMenuProps.x = (event.pageX / this.interfaceZoom) - $(parent).position().left;
      this.userSubMenuProps.y = (event.pageY / this.interfaceZoom) - $(parent).position().top;
      this.userSubMenuProps.user = p;
      this.userSubMenuProps.avatar = this.avatars[id].avatar;
    },
  },
  computed: {
    avatars() {
      return this.$store.getters.getAvatars;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
    wrapMsgs() {

      if (this.$props.messages && this.$props.messages.length > 0) {
        let chatBox = this.$refs['chatBox'];
        let chatIsDown = chatBox && (chatBox.scrollTop + 50 > chatBox.scrollHeight - chatBox.clientHeight);

        if (!chatBox || chatIsDown || this.$props.alwaysToBottom)
          this.scrollToBottom(this.$props.messages[this.$props.messages.length - 1].uuid)
      }

      return this.$props.messages
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    }
  },
  components: {
    AppUserSubMenu: UserSubMenu,
  }
}
</script>

<style scoped>
.chatUserIcon {
  height: 25px;
  width: 25px;
  background: rgba(0, 0, 0, 0.3);
  float: left;
  box-shadow: inset 0 0 5px black;
  background-size: cover;
  margin-bottom: -1px;
}

.chatMessage {
  transition: 200ms;
  clear: both;
  min-height: 25px;
  margin-top: 4px;
  text-align: left;
}

.chatMessage:last-child {
  margin-bottom: 15px;
}

.chatMessage:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.chatSystem {
  color: #ff8100;
  font-size: 10px;
  text-shadow: 1px 1px 1px black, 1px 0 0 black;
}

.chatMessage .chatUserIcon {
  margin-right: 5px;
}

.ChatUserName {
  color: #f8a377;
  text-shadow: 1px 1px 1px #000000, 0 0 0 black;
  font-weight: bold;
}

.ChatText {
  color: papayawhip;
  letter-spacing: 0;
  word-wrap: break-word;
  font-weight: bold;
  text-shadow: 1px 1px 1px black, 0 0 0 black;
  text-align: justify;
}

.attention .ChatText {
  text-shadow: none;
}

.battle {
  color: #ecb009
}

.group {
  color: #55ee5a
}

.corporation {
  color: rgb(169, 194, 255)
}

.npc {
  color: rgb(239, 235, 239)
}

.private {
  color: rgb(239, 169, 255)
}

#chatBox {
  word-wrap: break-word;
  overflow-y: scroll;
  border-radius: 5px 0 0 0;
  text-align: left;
  margin: auto auto 0 3px;
  width: 160px;
  height: 135px;
  text-shadow: #28a5e4 0 0 4px;
  font-size: 11px;
  float: left;
  box-shadow: inset 0 0 2px black;
  margin-bottom: 3px;
  padding: 4px 8px 0 4px;
}

.new_msg_shadow {
  top: -28px;
  left: 5px;
  position: absolute;
  filter: drop-shadow(1px 1px 1px black);
}

.new_msg_wrapper {
  width: 100px;
  height: 22px;
  -webkit-clip-path: polygon(0 50%, 10% 0, 100% 0%, 100% 50%, 90% 100%, 0 100%);
  clip-path: polygon(0 50%, 10% 0, 100% 0%, 100% 50%, 90% 100%, 0 100%);
  background: rgba(128, 128, 128, 1);
  padding: 2px 1px 1px 3px;
}

.new_msg {
  width: 97px;
  height: 20px;
  -webkit-clip-path: polygon(0 54%, 10% 0, 100% 0%, 100% 50%, 90% 100%, 0 100%);
  clip-path: polygon(0 54%, 10% 0, 100% 0%, 100% 50%, 90% 100%, 0 100%);
  background: yellow;
  text-align: center;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none;
}

.attention {
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>
