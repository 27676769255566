import {SmokeExplosion} from "../weapon/explosion";
import {GetGlobalPos} from "../map/gep_global_pos";
import {PlayPositionSound} from "../sound/play_sound";
import {gameStore} from "../store";

function PlaneMine(x, y, r) {
  let pos = GetGlobalPos(x, y, gameStore.map.id);

  PlayPositionSound(['capsule_flight'], null, pos.x, pos.y);
  SmokeExplosion(pos.x, pos.y, 300, 50, 10, 150, r, r, 3);
  SmokeExplosion(pos.x, pos.y, 600, 25, 10, 50, r, r, 3);
}

export {PlaneMine}
