import {FireExplosion} from "../weapon/explosion";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";

function Mining(data) {
  // TODO звук
  let pos = GetGlobalPos(data.to_x, data.to_y, gameStore.map.id);
  FireExplosion(pos.x, pos.y, 1000, 10, 7, 25, 0, 360, 500);
}

export {Mining}
