<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Метеориты</h2>

      <p>Время от времени на поверхность планеты падают метеориты, способные нанести ощутимый урон всему в области
        поражения. </p>
      <p>Иногда от метеоритов в месте падения образуются залежи минералов которые можно добывать.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('meteorite', 'jpg')+ ')',
            width: '480px',
            height: '122px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Meteorites</h2>

      <p>From time to time, meteorites fall onto the planet's surface, capable of causing significant damage to
        everything in the impact area. </p>
      <p>Sometimes, mineral deposits that can be mined form at the site of a meteorite fall.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('meteorite', 'jpg')+ ')',
        width: '480px',
        height: '122px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Meteorites",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
