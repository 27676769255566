import store from "../../store/store";
import {SelectSprite} from "../utils/select_sprite";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";

let targetsSprites = [];

function SectorAttackTargets(targets, slot, source) {
  removeAllTargets()

  if (!targets) return;

  let callBack = function (pointer, data) {

    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "SelectAttackTarget",
        value: data.slot,
        data: data.source,
        x: data.x,
        y: data.y,
      }
    }));

    setTimeout(function () {
      removeAllTargets();
    }, 500)
  }

  for (let i of targets) {
    let pos = GetGlobalPos(i.x, i.y, gameStore.map.id);
    let targetSprite = SelectSprite(pos.x, pos.y, callBack, 0xffffff, 0x00ff00, 150, {
      slot: slot,
      source: source,
      x: i.x,
      y: i.y
    });
    targetSprite.setDepth(999)
    targetsSprites.push(targetSprite)
  }
}

function removeAllTargets() {
  for (let i of targetsSprites) {
    i.destroy();
  }

  targetsSprites = [];
}

export {SectorAttackTargets}
