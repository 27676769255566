<template>
  <div class="passBlock" :id="windowID" :ref="windowID" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="windowID"
                 v-bind:noHeight="true"
                 texture="only_top"
                 v-bind:noWidth="true"/>

    <div>
      <div class="numberBlock value cursor_hover" v-bind:class="{active: activeCell==='one'}">{{ pass.one.number }}</div>
      <div class="numberBlock value cursor_hover" v-bind:class="{active: activeCell==='two'}">{{ pass.two.number }}</div>
      <div class="numberBlock value cursor_hover" v-bind:class="{active: activeCell==='three'}">{{ pass.three.number }}</div>
      <div class="numberBlock value cursor_hover" v-bind:class="{active: activeCell==='four'}">{{ pass.four.number }}</div>
    </div>

    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(1)">1</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(2)">2</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(3)">3</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(4)">4</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(5)">5</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(6)">6</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(7)">7</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(8)">8</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" @click="addNumber(9)">9</div>
    <div class="numberBlock cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)" style="margin-left: 45px;"
         @click="addNumber(0)">0
    </div>

    <input type="button" class="lobbyButton cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="closeWindow"
           :value="texts['cancel'][language]">
    <input type="button" class="lobbyButton cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="sendPass"
           :value="texts['ok'][language]">
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "BoxPass",
  props: ['boxID', 'action', 'windowID', 'boxSlot'],
  data() {
    return {
      pass: {
        one: {number: 0, use: false},
        two: {number: 0, use: false},
        three: {number: 0, use: false},
        four: {number: 0, use: false},
      }
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    addNumber(number) {
      this.playSound('button_press.mp3', 0.3)
      if (this.pass.four.use) {
        for (let i in this.pass) {
          this.pass[i].use = false;
        }
      }
      for (let i in this.pass) {
        if (!this.pass[i].use) {
          this.pass[i].number = number;
          this.pass[i].use = true;
          break
        }
      }
    },
    closeWindow() {
      this.playSound('window_close.mp3', 0.3)
      this.$store.commit({
        type: 'toggleWindow',
        id: this.$el.id,
        component: '',
      });
    },
    sendPass() {
      if (this.$props.action === 'open') {
        this.playSound('button_press.mp3', 0.3)

        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "OpenBox",
            box_password: Number(this.passValue),
            id: Number(this.$props.boxID),
          }
        }));
      }

      if (this.$props.action === 'install') {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "PlaceNewBox",
            src_slot: Number(this.$props.boxSlot),
            box_password: Number(this.passValue),
          }
        }));

        this.closeWindow()
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('BoxPass')
    },
    passValue() {
      let value = "";
      for (let i in this.pass) {
        value += this.pass[i].number;
      }
      return value;
    },
    activeCell() {
      for (let i in this.pass) {
        if (!this.pass[i].use) {
          return i
        }
      }

      return 'one'
    }
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
.passBlock {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  width: 120px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  box-shadow: 0 0 2px;
  height: 183px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  padding: 20px 3px 3px 3px;
  backdrop-filter: blur(4px);
}

.passBlock h2 {
  top: -1px;
  left: -1px;
  width: 100%;
  display: block;
  position: absolute;
  height: 17px;
  background: #8aaaaa;
  border: 1px solid #25a0e1;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
  text-align: left;
  text-indent: 5px;
  font-size: 11px;
  line-height: 18px;
  transition: 200ms;
  color: black;
  text-shadow: none;
  margin: 0;
}

.passBlock .numberBlock {
  width: 30px;
  height: 30px;
  background: #89969c;
  float: left;
  margin: 2px 5px;
  font-weight: bold;
  font-size: 25px;
  user-select: none;
  transition: 100ms;
  text-align: center;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  color: #fff;
  line-height: 34px;
  box-shadow: 0 0 2px #000;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.passBlock .numberBlock:active {
  box-shadow: inset 0 0 5px #a7a4a8;
  transform: scale(0.9);
}

.passBlock span {
  display: inline-block;
  width: 80px;
  text-align: left;
}

.passBlock input[type=number] {
  width: 45px;
  font-size: 20px;
  margin-bottom: 5px;
}

.passBlock input[type=button]:first-child {
  float: left;
  clear: both;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.passBlock .numberBlock.value {
  height: 20px;
  width: 20px;
  font-size: 12px;
  line-height: 23px;
  margin: 5px 5px;
  color: white;
  background: rgba(144, 187, 187, 0.3);
  box-shadow: inset 0 0 2px black;
}

.passBlock .numberBlock.value.active {
  background: #0d8511;
}

.lobbyButton {
  display: block;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
}

.lobbyButton:last-child {
  float: right;
}

.lobbyButton:hover, .passBlock .numberBlock:hover {
  background: rgba(255, 129, 0, 1);
}

.lobbyButton:active, .passBlock .numberBlock:active {
  transform: scale(0.98);
}
</style>
