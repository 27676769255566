<template>
  <div id="DepartmentOfEmploymentInBase" ref="DepartmentOfEmploymentInBase"
       :class="{
          global: $route.path === '/global',
          expand: expand,
       }"
       :style="globalStyle"
       @mousedown="toUp">

    <app-control v-bind:head="texts['window_name_1'][language]"
                 v-bind:move="$route.path !== '/global'"
                 v-bind:close="true"
                 v-bind:closeFunc="closeDialog"
                 v-bind:no-pos="$route.path === '/global'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"
                 v-bind:resizeFunc="$route.path === '/global' ? null : resize"
                 v-bind:texture="$route.path === '/global' ? 'only_top' : null"
                 v-bind:refWindow="'DepartmentOfEmploymentInBase'"
                 v-bind:minSize="{height: 100, width: 402}"/>

    <div id="missionText"
         :class="{
            missionTextGlobal: $route.path === '/global',
            expand: expand,
         }"
         :style="{
            maxHeight: $route.path === '/global' ? '150px' : 'unset',
            height: expand && openDialog.mission ? 'calc(60% - 26px) !important' : 'revert-layer'
         }"
    >
      <div v-html="pageText"/>
    </div>

    <div class="rewardBlock"
         :class="{
            expand: expand,
         }"
         :style="{marginBottom: expand ? '4px' : 'revert-layer'}"
         v-if="openDialog.mission">
      <div id="rewards">
        <h3 style="float: left"> {{ getNameTypeMission(openDialog.mission) }} </h3>

        <template v-if="openDialog.mission.destination">
          <div class="destination">
            <div>
              {{ texts['text_1'][language] }}

              {{ texts['text_2'][language] }} - <span class="importantly">{{
                handbook['sector'][language][openDialog.mission.destination.map_name].name
              }}</span>
              <template v-if="openDialog.mission.destination.base_name !== ''">
                , {{ texts['text_3'][language] }} - <span class="importantly">{{
                  handbook['base'][language][openDialog.mission.destination.base_name].name
                }}</span>
              </template>
              .

              <div class="map_button cursor_hover"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="openService('GlobalViewMap', openDialog.mission.destination)">
                {{ texts['button_1'][language] }}
              </div>
            </div>
          </div>
        </template>

        <h3 style="float: left">{{ texts['text_4'][language] }}</h3>
        <div id="rewardsCredits">
          <div class="credits_icon"/>
          <span id="countRewardCredits">
            {{ (openDialog.mission.reward_cr / 100).toFixed(2) }}
          </span>
        </div>
        <div id="rewardsItems" v-if="openDialog.mission.rewardItems">
          <app-item-cell v-for="slot in openDialog.mission.rewardItems" v-bind:itemSlot="slot" :key="slot.number"
                         v-bind:size="35"/>
        </div>
      </div>
    </div>

    <div id="missionAsc" :class="{expand: expand}">
      <div class="asks cursor_hover"
           :class="{missionAscGlobal: $route.path === '/global', visitedAsk : checkVisited(asc)}"
           v-for="asc in openDialog.page.asc" v-if="checkAccess(asc)"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="doAsc(asc)">

        <div class="wrapperAsk" v-html="asc.text[language]"/>
      </div>
    </div>

    <div class="expandButton cursor_hover"
         @click="changeExpand()"
         :style="{
           backgroundImage: 'url(' + (expand ? require('../../assets/icons/collapse.png').default : require('../../assets/icons/expand.png').default) + ')',
           top: $route.path === '/global' ? '4px' : '21px'
         }"/>

  </div>
</template>

<script>
import ItemCell from '../Inventory/ItemCell';
import Control from '../Window/Control';
import {urls} from '../../const';

export default {
  name: "InBase",
  data() {
    return {
      avatar: null,
      expand: false,
    }
  },
  created() {
    this.$store.commit({
      type: 'toggleWindow',
      id: 'DepartmentOfEmployment',
      component: 'DepartmentOfEmployment',
      forceOpen: true,
    });
  },
  updated() {
    if (!this.expand) this.resize(null, null, $(this.$el))
  },
  methods: {
    resize(event, ui, el) {
      if (!el) return;

      let rewardBlockHeight = el.find('.rewardBlock').height()
      let allHeight = el.find('#missionAsc').height()
      if (rewardBlockHeight) allHeight += rewardBlockHeight + 2;

      el.find('#missionText').css("height", el.height() - allHeight - 22);
    },
    changeExpand() {
      this.expand = !this.expand;
      if (this.$route.path === '/global') {
        this.$el.style.top = '63px';
        this.$el.style.left = '88px';
      }

      $(this.$el).resizable(this.expand ? 'disable' : 'enable');
      if (!this.expand) this.resize(null, null, $(this.$el))
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    getNameTypeMission(m) {
      if (m.type === 'escort') return 'Сопровождение';
      if (m.type === 'delivery') return 'Доставка';
      if (m.type === 'kill') return 'Устранение';

      return "...";
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    closeDialog() {
      this.$store.commit({
        type: 'setOpenDialog',
        page: null,
        mission: null,
        visited_pages: null,
      });

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "CloseDialog",
        }
      }));
    },
    checkAccess(asc) {
      if (asc.access_pages && asc.access_pages.length > 0) {
        for (let pageNumber of asc.access_pages) {
          if (!this.openDialog.visited_pages.hasOwnProperty(pageNumber)) {
            return false
          }
        }
      }
      return true
    },
    checkVisited(asc) {
      if (this.openDialog.visited_pages && this.openDialog.visited_pages.hasOwnProperty(asc.to_page)) {
        return true
      }
      return false
    },
    doAsc(asc) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "Ask",
          to_page: asc.to_page,
          id: asc.id,
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Department')
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    openDialog() {
      let app = this;

      let dialog = this.$store.getters.getOpenDialog;
      app.$api.get(urls.dialogPicURL + '?dialog_page_id=' + dialog.page.id + "&user_id=" + this.currentPlayer.id).then(function (response) {
        app.$set(app, 'avatar', "url('" + response.data.picture + "')");
      });

      return dialog
    },
    pageText() {
      let html = `<div id="missionFace" class="${this.expand ? 'expand' : ''}" style="background-image: ${this.avatar}"></div>` + this.openDialog.page.text[this.language]
      return html
    },
    globalStyle() {
      if (this.$route.path === '/global') {
        return {
          left: 'calc(50% - 200px)',
          top: '10%',
        }
      }
      return {}
    }
  },
  components: {
    AppControl: Control,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
#DepartmentOfEmploymentInBase {
  position: fixed;
  display: block;
  border-radius: 5px;
  z-index: 11;
  width: 402px;
  min-height: 100px;
  top: 50px;
  left: calc(50% - 200px);
  padding: 19px 1px 1px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#infoBlock, .infoBlock {
  width: 100%;
  float: left;
}

.rewardBlock {
  float: left;
  margin-bottom: 2px;
  width: 100%;
}

#missionText, .missionText {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 5px 5px 0 0;
  margin: auto auto 2px 0;
  width: calc(100% - 10px);
  height: 170px;
  box-shadow: inset 0 0 3px black;
  text-indent: 2em;
  overflow-y: scroll;
  color: papayawhip;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  float: left;
  letter-spacing: 0.7px;
  backdrop-filter: blur(4px);
}

#missionAsc, .missionAsc {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 0 0 5px 5px;
  text-align: center;
  margin: auto auto 0 0;
  width: calc(100% - 10px);
  min-height: 10px;
  font-family: sans-serif;
  float: left;
  box-shadow: inset 0 0 3px black;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  overflow-y: scroll;
  backdrop-filter: blur(4px);
}

.asks {
  width: calc(100% - 15px);
  border-radius: 5px;
  border: 1px solid #25a0e1;
  background-color: rgba(33, 176, 255, 0.6);
  float: left;
  margin-top: 4px;
  transition: 100ms;
  text-align: left;
}

#missionAsc .asks, .missionAsc .asks {
  border: 0 solid #25a0e1;
  background-image: linear-gradient(1deg, rgba(33, 176, 255, 0.3), rgba(37, 160, 225, 0.3) 6px);
  background-color: unset;
  padding: 3px 3px 3px 10px;
}

#missionAsc .asks:hover, .missionAsc .asks:hover {
  background-image: linear-gradient(1deg, rgba(33, 176, 255, 0.6), rgba(37, 160, 225, 0.6) 6px);
}

.visitedAsk {
  color: #a4abaf;
  background-image: linear-gradient(0deg, rgba(23, 173, 255, 0), rgba(30, 48, 57, 0.3) 0px);
}

.rewardBlock h3 {
  text-align: center;
  width: 70px;
  line-height: 18px;
  padding-right: 5px;
  box-shadow: inset 0 0 2px black;
  border: 0;
  font-size: 11px;
  outline: none;
  padding-left: 5px;
  border-radius: 5px;
  color: #ff7800;
  margin: 3px auto 3px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#rewards {
  overflow-y: scroll;
  width: 100%;
  box-shadow: inset 0 0 2px black;
  background: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 2px;
}

.rewardBlock h3 {
  margin: 4px 0 4px 4px;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  text-align: left;
  width: calc(100% - 21px);
}

#rewardsCredits {
  float: left;
  padding: 2px;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  background: #8aaaaa;
  width: 60px;
  height: 12px;
  margin: 3px 3px 6px 5px;
}

#rewardsCredits span, .rewardsCredits span {
  background: #066ea8;
  float: right;
  width: calc(100% - 21px);
  padding-right: 4px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 14px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
}

#missionAsc .wrapperAsk, .missionAsc .wrapperAsk {
  font-size: 12px;
  font-weight: 900;
  text-align: left;
  box-shadow: none;
  background: none;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.asks:active {
  transform: scale(0.98);
}

.credits_icon {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png');
}

.destination {
  float: left;
  padding: 2px;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  background: #8aaaaa;
  width: calc(100% - 12px);
  height: 13px;
  margin: 3px 3px 0 5px;
}

.destination > div {
  background: #066ea8;
  float: right;
  width: calc(100% - 4px);
  padding-right: 4px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: left;
  line-height: 14px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
  text-indent: 5px;
  position: relative;
}

.map_button {
  position: absolute;
  right: 1px;
  top: -1px;
  display: block;
  width: 43px;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 11px;
  border-radius: 2px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  padding-left: 0;
  text-indent: 0;
  line-height: 11px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.map_button:hover {
  background: rgba(255, 129, 0, 1);
}

.map_button:active {
  transform: scale(0.98);
}

.expandButton {
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: auto;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  top: 4px;
  left: 4px;
  user-select: none;
  background-size: contain;
}

.expandButton:hover {
  background-color: #ff8100;
}

.expandButton:active {
  transform: scale(0.98);
}
</style>
<style>
#missionText i {
  color: #b9d5cc;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#missionFace {
  box-shadow: inset 0 0 2px 2px black, 0 0 2px black;
  background-size: cover !important;
  border-radius: 5px;
  margin: 2px 2px 2px 8px;
  float: right;
  height: 90px;
  width: 90px;
  background: rgba(0, 0, 0, 0.3);
}

#missionText p, .missionText p {
  margin: 10px 0;
}

#missionText p:nth-child(2) {
  margin-top: 2px;
}

.global {
  padding: 2px !important;
  min-height: 150px;
}

.global .windowsHead {
  width: 15px;
  float: right;
  left: unset;
  border: none;
  color: transparent;
  text-shadow: none;
  box-shadow: none;
  background: none;
  z-index: 999;
  right: -4px;
  top: -2px;
  transform: scale(0.8);
}

.global .windowsHead:hover {
  background: none;
}

.global .windowsHeadTitle {
  display: none;
}

#DepartmentOfEmploymentInBase.global {
  box-shadow: 0 0 200px 100px black !important;
}

#DepartmentOfEmploymentInBase.expand {
  width: 602px !important;
  left: calc(50% - 301px) !important;
  top: 10% !important;
  height: 80% !important;
}

#missionText.expand {
  height: calc(85% - 22px) !important;
  max-height: unset !important;
}

#missionText.expand p {
  font-size: 16px;
}

#missionAsc.expand {
  height: 15% !important;
  max-height: unset !important;
}

#missionFace.expand {
  height: 190px !important;
  width: 190px !important;
}

.rewardBlock.expand {
  height: 25% !important;
}
</style>
