<template>
  <div class="sessionRankWrapper" v-if="this.currentPlayer">

    <div class="texture_wrapper"
         :style="{
          backgroundImage: 'url(' + require('../../assets/interface/frame_9.png').default + ')',
          top: '-64px',
          left:'-36px'}"/>

    <div class="sessionRankWrapper2">

      <div class="userAvatarWrapper cursor_hover"
           v-bind:style="{backgroundImage:  avatars[currentPlayer.id] ? avatars[currentPlayer.id].avatar : undefined}"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click.stop="openService('userStat' + currentPlayer.id, {id: currentPlayer.id}, 'userStat', false)"/>

      <div v-if="currentPlayer && currentPlayer.battle_rank">

        <h3 class="userName">
          <div class="tag">{{ tag ? tag : `` }}</div>
          {{ currentPlayer.login }}
        </h3>

        <div class="state">
          <div class="Rank">
            {{ currentPlayer.battle_rank.lvl }}
          </div>

          <div :title="(currentPlayer.battle_rank.points / 100).toFixed(0) + ' / ' +
                           (currentPlayer.battle_rank.need_points_to_up / 100).toFixed(0)">
            <h5>
              {{
                currentPlayer.battle_rank.fraction_warrior ? getRankName(currentPlayer.battle_rank.rank) : getRankName(0)
              }}
            </h5>
            <div class="RankBar">
              <div class="RankBarInner"
                   :style="{width: (100 / (currentPlayer.battle_rank.need_points_to_up / currentPlayer.battle_rank.points)) + '%'}"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="exitTime" v-if="chatState && chatState.exitTime > 0"
         :title="texts['text_10'][language]">
      <div class="time">
        {{ TimeFormat(chatState.exitTime).minutes }}:{{ TimeFormat(chatState.exitTime).sec }}
      </div>
    </div>

    <div class="lawTime" v-if="chatState && chatState.vioType !== ''"
         :title="texts['text_11'][language]">
      <div class="lawTime_icon" :class="{[chatState.vioType]: true}"></div>
      <div class="time">
        {{ TimeFormat(chatState.vioTime).minutes }}:{{ TimeFormat(chatState.vioTime).sec }}
      </div>
    </div>

    <div class="rankUpdate" v-if="chatState && chatState.rankUpdate"
         :title="texts['text_12'][language]">
    </div>
  </div>
</template>

<script>

export default {
  name: "SessionBattleRank",
  data() {
    return {
      logo: null,
      updater: null,
    }
  },
  destroyed() {
    clearInterval(this.updater);
  },
  mounted() {
    let app = this;
    this.updater = setInterval(function () {
      app.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetExitTime",
        }
      }));
    }, 1000)
  },
  methods: {
    getAvatar(userID) {
      this.$store.dispatch('getAvatar', {
        userID: userID,
        vm: this
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    TimeFormat(time) {

      if (time < 0 || time === 99999) {
        return {minutes: '--', sec: '--'}
      }

      let minutes = Math.floor(time / 60);
      let sec = time % 60;
      return {minutes: (minutes % 60 < 10 ? "0" : "") + minutes, sec: (sec % 60 < 10 ? "0" : "") + sec};
    },
    getRankName(r) {
      for (let i in this.handbook.rank[this.language]) {
        if (Number(this.handbook.rank[this.language][i].id) === Number(r)) {
          return this.handbook.rank[this.language][i].name
        }
      }

      return ""
    }
  },
  computed: {
    avatars() {
      return this.$store.getters.getChatState.avatars;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Lobby')
    },
    currentPlayer() {
      let currentPlayer = this.$store.getters.getPlayer
      this.getAvatar(currentPlayer.id)
      return currentPlayer
    },
    tag() {
      if (this.corporation.info) {
        return this.corporation.info.tag
      } else {
        return ""
      }
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    chatState() {
      return this.$store.getters.getChatState
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
  }
}
</script>

<style scoped>
.sessionRankWrapper {
  height: 50px;
  width: 100%;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  box-shadow: none;
  border-radius: 0 0 50px 5px;
}

.sessionRankWrapper2 {
  height: 44px;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 0 0 50px 5px;
  margin: 2px;
  line-height: 35px;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.userAvatarWrapper {
  background-color: rgb(128, 128, 128);
  height: 40px;
  width: 42px;
  float: left;
  margin: 2px 5px 5px 1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
  border-radius: 5px 0 5px 0;
  transition: 100ms;
}

.userAvatarWrapper:hover {
  box-shadow: inset 0 0 2px 0 white, 0 0 2px 0 white;
  border: 1px solid white;
}

.userAvatarWrapper:active {
  transform: scale(0.97);
}

.userName {
  margin: 1px 0 0 2px;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 50px 5px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  text-align: left;
  width: calc(100% - 16px);
}

.tag {
  display: inline-block;
  float: left;
  font-size: 10px;
  line-height: 20px;
  margin-right: 8px;
  width: 30px;
  /*margin-left: -4px;*/
  height: 17px;
}

.tag span {
  color: #e4ff00;
  font-size: 8px;
}

.Rank {
  height: 21px;
  width: 31px;
  float: left;
  background-color: rgb(128, 128, 128);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  margin: -1px 0 0 -1px;
  font-family: 'Audiowide', cursive;
  line-height: 22px;
  font-size: 17px;
  text-align: center;
  border-radius: 3px;
}

.state {
  margin-top: 3px;
}

.state h5 {
  margin: 0;
  width: calc(100% - 100px);
  float: left;
  height: 12px;
  font-size: 11px;
  line-height: 10px;
  text-align: left;
  text-indent: 5px;
}

.RankBar {
  position: relative;
  width: calc(100% - 125px);
  height: 7px;
  margin: 1px 2px;
  background: rgb(145, 145, 145);
  float: left;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  overflow: hidden;
}

.RankBarInner {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 50%;
  box-shadow: inset 0 0 2px 0 black;
  background: #ff8100;
}

.exitTime, .lawTime, .rankUpdate {
  position: absolute;
  height: 35px;
  width: 35px;
  background-size: cover;
  background-image: none;
  left: calc(100% + 12px);
  top: 1px;
  filter: drop-shadow(0px 0px 2px black);
}

.exitTime {
  background-image: url('../../assets/icons/not_exist_ready.png');
  left: calc(100% + 55px);
  top: 2px;
}

.rankUpdate {
  background-image: url('../../assets/icons/up_rank.png');
  left: calc(100% + 98px);
  top: 2px;
}

.lawTime_icon {
  background-size: cover;
  background-image: url('../../assets/icons/law_icon.png');
  position: absolute;
  height: 35px;
  width: 35px;
  left: 0;
  top: 0;
}

.pvp {
  filter: contrast(50%) sepia(100%) hue-rotate(240deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

.pk {
  filter: contrast(50%) sepia(100%) hue-rotate(319deg) brightness(0.5) saturate(800%) drop-shadow(0px 1px 0px black);
}

.exitTime .time, .lawTime .time {
  color: yellow;
  font-weight: 900;
  font-size: 10px;
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.vio {
  font-size: 8px;
  line-height: 45px;
  color: white;
  width: 100%;
  text-align: center;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.texture_wrapper {
  height: 178px;
  width: 178px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 89;
}
</style>
