import {Scene} from "../create";
import {intFromBytes} from "../../utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {UnitHeight} from "../unit/unit";
import {GetMapLvl} from "../map/height_offset";
import {addIgnoreObject} from "../interface/mini_map";

function ParsePoint(data) {

  let pos = GetGlobalPos(intFromBytes(data.slice(12, 16)), intFromBytes(data.slice(16, 20)), gameStore.map.id);

  return {
    id: intFromBytes(data.slice(0, 4)),
    rope_id: intFromBytes(data.slice(4, 8)),
    rope_position: intFromBytes(data.slice(8, 12)),
    x: pos.x,
    y: pos.y,
    sprite_id: intFromBytes(data.slice(20, 21)),
  }
}

let ropes = {};

function AddPoint(point) {
  if (!ropes[point.rope_id]) ropes[point.rope_id] = {
    sprite: null,
    shadow: null,
    segments: [],
    points: [],
  };

  ropes[point.rope_id].points.push(point);

  RopeRender(ropes[point.rope_id])
}

function RemovePoint(id) {
  let remove = false
  for (let ropeID in ropes) {
    for (let i in ropes[ropeID].points) {
      if (Number(ropes[ropeID].points[i].id) === id) {
        remove = true
        ropes[ropeID].points.splice(i, 1);
        break
      }
    }

    if (remove) {
      RopeRender(ropes[ropeID])
      return
    }
  }
}

function MovePoint(id, x, y) {
  for (let ropeID in ropes) {
    for (let i in ropes[ropeID].points) {
      if (ropes[ropeID].points[i].id === id) {
        let pos = GetGlobalPos(x, y, gameStore.map.id);
        ropes[ropeID].points[i].x = pos.x
        ropes[ropeID].points[i].y = pos.y
        break
      }
    }
  }
}

function AllRopeRender() {
  for (let ropeID in ropes) {
    RopeRender(ropes[ropeID])
  }
}

function RopeRender(rope) {

  rope.points.sort(function (a, b) {
    return a.rope_position - b.rope_position;
  });

  let segments = getSegments(rope)
  if (rope.segments.length > segments.length) {

    for (let i = segments.length; i < rope.segments.length; i++) {
      if (rope.segments[i].sprite) rope.segments[i].sprite.destroy();
      if (rope.segments[i].shadow) rope.segments[i].shadow.destroy();
    }

    rope.segments.splice(segments.length)
  } else {
    for (let i = rope.segments.length; i < segments.length; i++) {
      rope.segments.push({
        sprite: null,
        shadow: null,
        points: null,
      })
    }
  }

  for (let i = 0; i < segments.length; i++) {
    rope.segments[i].points = segments[i];
  }

  for (let i = 0; i < rope.segments.length; i++) {
    let phaserPoints = []
    let shadowPhaserPoints = []

    for (let j = 0; j < rope.segments[i].points.length; j++) {

      if (j === 0 || j === rope.segments[i].points.length - 1) {
        shadowPhaserPoints.push(new Phaser.Math.Vector2(rope.segments[i].points[j].x - Scene.shadowXOffset / 4, rope.segments[i].points[j].y - Scene.shadowXOffset / 4));
      } else {
        shadowPhaserPoints.push(new Phaser.Math.Vector2(rope.segments[i].points[j].x, rope.segments[i].points[j].y));
      }

      phaserPoints.push(new Phaser.Math.Vector2(rope.segments[i].points[j].x, rope.segments[i].points[j].y));
    }

    if (phaserPoints.length < 2) {
      continue
    }

    if (!rope.segments[i].sprite) {
      let lvl = GetMapLvl(rope.segments[i].points[0].x, rope.segments[i].points[0].y, gameStore.map.id)

      rope.segments[i].sprite = Scene.add.rope(0, 0, "rope_" + rope.segments[i].points[0].sprite_id, null, phaserPoints);
      rope.segments[i].sprite.setDepth(lvl + UnitHeight + 2);
      addIgnoreObject(rope.segments[i].sprite);

      rope.segments[i].shadow = Scene.add.rope(Scene.shadowXOffset / 4, Scene.shadowYOffset / 4, "rope_" + rope.segments[i].points[0].sprite_id, null, shadowPhaserPoints);
      rope.segments[i].shadow.setDepth(lvl + UnitHeight + 1);
      addIgnoreObject(rope.segments[i].shadow);
    } else {
      rope.segments[i].sprite.setPoints(phaserPoints);
      rope.segments[i].sprite.setDirty();

      rope.segments[i].shadow.setPoints(shadowPhaserPoints);
      rope.segments[i].shadow.setDirty();
    }

    rope.segments[i].shadow.setAlphas(0.2);
    rope.segments[i].shadow.setColors(0x000000);
  }
}

function getSegments(rope) {

  let segments = [];
  let currentSegment = [];

  for (let i in rope.points) {
    if (i === 0 || rope.points.length === Number(i) + 1 || (rope.points[Number(i)].rope_position + 1 === rope.points[Number(i) + 1].rope_position)) {
      currentSegment.push(rope.points[i]);

      if (rope.points.length === Number(i) + 1) {
        segments.push(currentSegment)
      }

    } else {
      segments.push(currentSegment)
      currentSegment = [];
    }
  }

  let realSegments = [];
  for (let i of segments) {
    if (i.length > 0) realSegments.push(i)
  }

  return realSegments
}

function RemoveRope(ropeID) {

  for (let i = 0; i < ropes[ropeID].segments.length; i++) {
    if (ropes[ropeID].segments[i].sprite) ropes[ropeID].segments[i].sprite.destroy();
    if (ropes[ropeID].segments[i].shadow) ropes[ropeID].segments[i].shadow.destroy();
  }

  delete ropes[ropeID];
}

function ClearAllRopes() {
  for (let ropeID in ropes) {
    RemoveRope(ropeID)
  }
}

export {RopeRender, ParsePoint, AddPoint, RemovePoint, MovePoint, ClearAllRopes, RemoveRope, AllRopeRender}
