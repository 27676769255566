<template>
  <div class="skills">
    <div id="HelpSections">

      <div class="button cursor_hover">
        {{ texts['only_studied'][language] }} <input v-model="onlyStudied" type="checkbox">
      </div>

      <div class="points_wrapper">
        {{ texts['experience'][language] }}
        <div class="points_count importantly">
          {{ skillStore.points }}
          <div class="detail_button" @click.stop="openService('helpWrapper', {component: 'Skills'})"/>
        </div>
      </div>

      <div class="filter_head">{{ texts['filter'][language] }}</div>

      <template v-for="(section, key) in skills" v-if="!section.notList && key !== 'title'">

        <div class="helpSection cursor_hover"
             v-bind:class="{select: selectCat.main && selectCat.main === key}"
             @click="selectCat.main && selectCat.main === key ? selectCat.main = null: selectCat = {main: key}">
          {{ section.title }}
        </div>

        <template v-if="key === 'weapon' || key === 'body' || key === 'ammo'" v-for="(children, subKey) in section">

          <div class="helpSubSection cursor_hover" v-if="selectCat.main && selectCat.main === key && subKey !== 'title'"
               v-bind:class="{select: selectCat.sub && selectCat.sub === subKey}"
               @click="selectCat = {main: key, sub: subKey, twoSub: null}">
            {{ children.title }}
          </div>

          <template v-if="!children.notDeep">
            <div class="helpSubSection two cursor_hover"
                 v-for="(children2, subKey2) in children"
                 v-bind:class="{select: selectCat.twoSub && selectCat.twoSub === subKey2}"
                 v-if="selectCat.main && selectCat.main === key && selectCat.sub && selectCat.sub === subKey && subKey2 !== 'title'"
                 @click="selectCat = {main: key, sub: subKey, twoSub: subKey2}">
              {{ children2.title }}
            </div>
          </template>
        </template>
      </template>
    </div>


    <div class="skillContent">

      <div class="not_is_a_base" v-if="!inBase">{{ texts['base_alert'][language] }}</div>

      <template v-for="(skill, key) in selectSkills" v-if="(onlyStudied && skill.level > 0) || !onlyStudied">

        <div class="skillWrap" v-if="key !== 'undefined'" :class="{selectTraining: selectSkill  === skill.type_id}">

          <div class="detailSkillButton">
            <div class="detailSkill" v-html="getDescription(skill)"/>
          </div>

          <div class="skillHead">
            {{ handbook["skill"][language][skill.name] ? handbook["skill"][language][skill.name].name : '' }}
            <div style="margin-top: 6px">
              <span>{{ texts['lvl'][language] }} <span class="level">{{ skill.level }}</span></span>
            </div>
            <div style="margin-top: 6px">
              {{ texts['price'][language] }} <span class="importantly">{{ skill.need_points_to_up }}</span>
            </div>

          </div>

          <div class="skillRow">
            <div class="skill_power" v-for="index in 25" :class="{up: index <= skill.level}"/>
          </div>

          <div class="up_button cursor_hover"
               :class="{up_button_disable: skill.need_points_to_up > skillStore.points || skill.level >= 25 || !inBase}"
               @click="upSkill(skill.name)"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Input from "../Chat/Input";

export default {
  name: "Skills",
  components: {Input},
  data() {
    return {
      selectCat: {
        main: null,
        sub: null,
        twoSub: null,
      },
      onlyStudied: false,
    }
  },
  created() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetMySkills",
      }
    }));
  },
  mounted() {
    this.$parent.resize(null, null, $(this.$parent.$el))
  },
  methods: {
    getFillPercent(skill) {
      let percent = (100 / (skill.need_points_to_up / skill.user_experience_point));
      if (!percent > 0) {
        percent = 0
      }
      return '0%'
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    getDescription(skill) {
      let description = ''
      if (this.handbook['skill'] && this.handbook['skill'][this.language] && this.handbook['skill'][this.language][skill.name]) {
        description = this.handbook['skill'][this.language][skill.name].description
      }

      let oneLevelFactor = (100 * skill.percent_factor).toFixed(2)
      let factor = (skill.level * skill.percent_factor * 100).toFixed(2)

      if (skill.type === 'processing' || skill.name === 'production_economy' || skill.name === 'production_time') {
        oneLevelFactor = skill.percent_factor
        factor = (skill.level * skill.percent_factor)
      }

      description += `
        <table style="width: 100%">
          <tr>
            <td>${this.texts['bonus_for_lvl'][this.language]}</td> <td style="text-align: right"><span class=\"importantly\">${oneLevelFactor}%</span></td>
          </tr>
          <tr>
            <td>${this.texts['bonus_current'][this.language]}</td> <td style="text-align: right"><span class=\"importantly\">${factor}%</span></td>
          </tr>
        </table>
      `

      return description
    },
    upSkill(name) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "UpSkill",
          name: name,
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('UserStat')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    skills() {

      //  оружие {лазерное, ракетное, балистическое}
      //  корпуса {фракции {размеры}}
      //  переработка
      //  производство

      let skills = {
        weapon: {
          rocket: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['missile'][this.language]
          },
          laser: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['laser'][this.language]
          },
          ballistic: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['ballistic'][this.language]
          },
          title: this.texts['weapon'][this.language]
        },
        ammo: {
          rocket: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['missile'][this.language]
          },
          laser: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['laser'][this.language]
          },
          ballistic: {
            small: {
              title: this.texts['small'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['big'][this.language]
            },
            title: this.texts['ballistic'][this.language]
          },
          title: this.texts['ammo'][this.language]
        },
        body: {
          explores: {
            small: {
              title: this.texts['light'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['heavy'][this.language]
            },
            other: {
              title: this.texts['other_1'][this.language]
            },
            title: "Explores"
          },
          replics: {
            small: {
              title: this.texts['light'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['heavy'][this.language]
            },
            other: {
              title: this.texts['other_1'][this.language]
            },
            title: "Replics"
          },
          reverses: {
            small: {
              title: this.texts['light'][this.language]
            },
            medium: {
              title: this.texts['medium'][this.language]
            },
            big: {
              title: this.texts['heavy'][this.language]
            },
            other: {
              title: this.texts['other_1'][this.language]
            },
            title: "Reverses"
          },
          other: {
            title: this.texts['other_2'][this.language],
            other: {},
            notDeep: true,
          },
          title: this.texts['bodies'][this.language]
        },
        ore: {
          title: this.texts['mining'][this.language]
        },
        production: {
          title: this.texts['production'][this.language]
        },
        processing: {
          title: this.texts['processing'][this.language]
        },
      };

      let mySkills = this.skillStore.skills
      for (let i in mySkills) {
        if (mySkills.hasOwnProperty(i)) {
          let skill = mySkills[i];
          if (skill.type === 'weapon' || skill.type === 'ammo') {
            if (skill.name.includes('rocket')) {
              if (skill.name.includes('small')) skills[skill.type].rocket.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].rocket.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].rocket.big[i] = skill;
            }
            if (skill.name.includes('laser')) {
              if (skill.name.includes('small')) skills[skill.type].laser.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].laser.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].laser.big[i] = skill;
            }
            if (skill.name.includes('ballistic')) {
              if (skill.name.includes('small')) skills[skill.type].ballistic.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].ballistic.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].ballistic.big[i] = skill;
            }
          } else if (skill.type === 'body') {
            if (skill.name.includes('explores')) {
              if (skill.name.includes('small')) skills[skill.type].explores.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].explores.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].explores.big[i] = skill;

              if (!skill.name.includes('small') && !skill.name.includes('medium') && !skill.name.includes('big')) {
                skills[skill.type].explores.other[i] = skill;
              }
            }
            if (skill.name.includes('replics')) {
              if (skill.name.includes('small')) skills[skill.type].replics.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].replics.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].replics.big[i] = skill;

              if (!skill.name.includes('small') && !skill.name.includes('medium') && !skill.name.includes('big')) {
                skills[skill.type].replics.other[i] = skill;
              }
            }
            if (skill.name.includes('reverses')) {
              if (skill.name.includes('small')) skills[skill.type].reverses.small[i] = skill;
              if (skill.name.includes('medium')) skills[skill.type].reverses.medium[i] = skill;
              if (skill.name.includes('big')) skills[skill.type].reverses.big[i] = skill;

              if (!skill.name.includes('small') && !skill.name.includes('medium') && !skill.name.includes('big')) {
                skills[skill.type].reverses.other[i] = skill;
              }
            }

            if (!skill.name.includes('explores') && !skill.name.includes('replics') && !skill.name.includes('reverses')) {
              skills[skill.type].other.other[i] = skill;
            }

          } else {
            skills[skill.type][i] = skill;
          }
        }
      }

      return skills;
    },
    selectSkills() {
      let skills = {};

      if (!this.selectCat.main) {
        return this.skillStore.skills
      }

      if (this.selectCat.main === 'weapon' || this.selectCat.main === 'ammo' || this.selectCat.main === 'body') {
        if (!this.selectCat.sub) {
          for (let i in this.skills[this.selectCat.main]) {
            for (let j in this.skills[this.selectCat.main][i]) {
              for (let k in this.skills[this.selectCat.main][i][j]) {
                skills[this.skills[this.selectCat.main][i][j][k].name] = this.skills[this.selectCat.main][i][j][k]
              }
            }
          }
        } else {
          if (!this.selectCat.twoSub) {
            for (let i in this.skills[this.selectCat.main][this.selectCat.sub]) {
              for (let j in this.skills[this.selectCat.main][this.selectCat.sub][i]) {
                skills[this.skills[this.selectCat.main][this.selectCat.sub][i][j].name] = this.skills[this.selectCat.main][this.selectCat.sub][i][j]
              }
            }
          } else {
            for (let j in this.skills[this.selectCat.main][this.selectCat.sub][this.selectCat.twoSub]) {
              skills[this.skills[this.selectCat.main][this.selectCat.sub][this.selectCat.twoSub][j].name] = this.skills[this.selectCat.main][this.selectCat.sub][this.selectCat.twoSub][j]
            }
          }
        }
      } else {
        for (let i in this.skills[this.selectCat.main]) {
          skills[this.skills[this.selectCat.main][i].name] = this.skills[this.selectCat.main][i]
        }
      }

      return skills
    },
    skillStore() {
      return this.$store.getters.getSkills
    },
    inBase() {
      return this.$route.path.includes('lobby')
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectSkill() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.x
    }
  }
}
</script>

<style scoped>

.skillContent {
  margin: 0 auto 0;
  overflow-y: scroll;
  height: 188px;
  border-radius: 0;
  box-shadow: inset 0 0 1px black;
  pointer-events: auto;
  overflow-x: hidden;
  width: 235px;
  text-align: left;
  font-size: 13px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(137, 150, 156, 0.3);
}

.skills {
  float: left;
  width: 100%;
  margin-top: 2px;
  height: 200px;
}

#HelpSections {
  float: left;
  height: calc(100%);
  width: 150px;
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin-right: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.helpSection, .helpSubSection, .points_wrapper, .filter_head {
  text-align: left;
  display: block;
  width: calc(100% - 4px);
  padding-left: 4px;
  background: rgba(255, 129, 0, 0.6);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin: 2px 0 0 2px;
  font-size: 11px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: left;
  box-shadow: 0 0 2px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter_head {
  background: rgb(222, 101, 40);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}

.helpSubSection {
  background: rgba(158, 69, 24, 0.6);
  margin-left: 10px;
  font-size: 10px;
}

.helpSubSection.two {
  margin-left: 20px;
}

.helpSection:hover, .helpSubSection:hover {
  box-shadow: 0 0 2px rgb(255, 255, 255);
}

.helpSection:active, .helpSubSection:active {
  transform: scale(0.98);
}

.skillRow {
  height: 12px;
  width: calc(100% - 50px);
  position: relative;
}

.skillRow > div:first-child {
  margin-left: 0;
  width: 4%;
}

.skillRow > div {
  height: 100%;
  width: 3%;
  box-shadow: 0 0 1px black;
  background: rgba(128, 128, 128, 0.2);
  float: left;
  margin-left: 1%;
}

.up {
  background: #3486dd !important;
}

.skillHead {
  color: #f1f1f1;
  font-size: 11px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2px;
  height: 52px;
}

.skillHead .points {
  color: yellow;
  float: right;
}

.level {
  color: #ffc800;
  font-size: 15px;
  line-height: 11px;
}

.skillWrap {
  padding: 4px 5px 4px 5px;
  background: rgba(0, 0, 0, 0.17);
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  position: relative;
}

.skillWrap:last-child {
  margin-bottom: 0;
}

.button {
  width: 150px;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  float: left;
  left: 0;
  box-shadow: 0 0 2px black;
  color: #f1f1f1;
  font-size: 11px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  line-height: 21px;
  padding-left: 5px;
  top: 0;
  position: sticky;
}

.button input {
  float: right;
  margin-right: 20px;
}

.helpSubSection.select {
  background: rgba(255, 129, 0, 1);
}

.helpSection.select {
  background: rgba(255, 129, 0, 1);
}

.detailSkillButton {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 3px;
  top: 0;
  transition: 200ms;
  z-index: 10;
  float: right;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
  margin-top: 4px;
  background-image: url('../../assets/icons/question-mark.png');
}

.detailSkillButton:hover {
  opacity: 1;
}

.detailSkill {
  position: absolute;
  top: 100%;
  right: 100%;
  min-height: 25px;
  width: 200px;
  padding: 3px;
  border-radius: 5px;
  z-index: 20;
  visibility: hidden;
  transition: 0ms;
  word-wrap: break-word;
  text-align: justify;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  float: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
}

.detailSkillButton:hover .detailSkill {
  visibility: visible;
}

.points_wrapper {
  background: #3486dd;
}

.points_count {
  float: right;
  margin-right: 3px;
  margin-top: 1px;
  background: #066ea8;
  width: calc(100% - 47px);
  padding-right: 4px;
  height: 14px;
  border-radius: 4px;
  text-align: right;
  line-height: 15px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  box-shadow: inset 0 0 2px black;
}

.up_button {
  position: absolute;
  top: 29px;
  right: 3px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: block;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: .1s;
  background-color: rgba(255, 129, 0, .6);
  color: #fff;
  line-height: 14px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: right;
  margin-left: 2px;
  background-image: url('../../assets/icons/up_skill.png');
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center center;
}

.up_button:hover {
  background-color: #ff8100;
}

.up_button:active {
  transform: scale(.98);
}

.up_button_disable {
  background-color: grey;
  opacity: 0.5;
}

.up_button_disable:hover {
  background-color: grey;
}

.not_is_a_base {
  padding: 4px 5px 4px 5px;
  background: rgb(255, 0, 0, 0.3);
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  color: white;
  font-weight: bold;
  font-size: 11px;
}

.detail_button {
  height: 13px;
  width: 13px;
  right: 1px;
  bottom: 1px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 100ms;
  float: right;
  margin-left: 3px;
  margin-right: -3px;
}

.detail_button:hover {
  box-shadow: 0 0 4px 2px orange;
}

.detail_button:active {
  transform: scale(0.8);
}

.selectTraining {
  box-shadow: 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
}
</style>
<style>
.detailSkill p {
  text-indent: 1.5em;
  margin: 0 auto 10px;
}
</style>
