import {CreateAnimate, CreateObject} from "../map/objects";
import {CreateNewUnit} from "../unit/unit";
import {removeUnit} from "../unit/remove";
import {gameStore} from "../store";
import {Scene} from "../create";
import {CreateShield, RemoveShield} from "../shield/shield";
import {RemoveSelectSpriteReservoir} from "../equip/mining_targets";
import {DestroyBox} from "../box/remove";
import {deleteBullet, removedBullets} from "../bullet/remove";
import store from "../../store/store";
import {ClearBars, createObjectBars} from "../interface/status_layer";
import {ParseObject} from "../map/createMap";
import {CreateMapItems, DestroyItem} from "../map_items/map_items";
import {base64ToArrayBuffer, intFromBytes} from "../../utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {CreateFireBullet} from "../bullet/meteorite";
import {GetCacheBulletSprite} from "../bullet/create";
import {CreateDrone} from "../drone/create";
import {CreateBox} from "../box/create";
import {CreateReservoir} from "../reservoir/reservoir";
import {AddPoint, ParsePoint, RemovePoint} from "../rope/render";
import {RemoveReservoirStatus} from "../interface/reservoir_count";
import {clearTrash} from "../map/remove_old_map";
import {removeDrone} from "../drone/remove";
import {SetDayCycleObject} from "../day_cycle/day_cycle";
import {RemoveSelectSpriteCombineTargets} from "../equip/combine_targets";
import {CreatePollen, DestroyPollen} from "../pollen/pollen";

function CreateRadarObject(type, object) {

  if (type === "box") {
    CreateBox(object)
  }

  if (type === "reservoir") {
    CreateReservoir(object);
  }

  if (type === "item") {
    CreateMapItems(object)
  }

  if (type === "unit") {
    CreateNewUnit(object)
  }

  if (type === "drone") {
    CreateDrone(object)
  }

  if (type === "object" || type === "dynamic_objects") {
    createDynamicObject(ParseObject(object));
  }

  if (type === "shield") {
    CreateShield(object);
  }

  if (type === "bullet") {
    object = {
      type_id: intFromBytes(object.slice(0, 1)),
      id: intFromBytes(object.slice(1, 5)),
      x: intFromBytes(object.slice(5, 9)),
      y: intFromBytes(object.slice(9, 13)),
      z: intFromBytes(object.slice(13, 17)),
      r: intFromBytes(object.slice(17, 21)),
    }

    if (removedBullets[object.id]) {
      console.log("create remove bullet")
      return;
    }

    let pos = GetGlobalPos(object.x, object.y, gameStore.map.id);
    object.x = pos.x;
    object.y = pos.y;

    let infoBullet = gameStore.gameTypes.ammo[object.type_id];
    if (!infoBullet || infoBullet.name === "plant_mine_1" || infoBullet.name === "plant_mine_2" ||
      infoBullet.name === "fauna_smoke_screen" || infoBullet.name === "drone_destroy" || infoBullet.name === "harvester_1") {
      return
    }

    if (infoBullet.type === "meteorite" || infoBullet.name === "big_lens_1_additional" || infoBullet.name === "ballistics_artillery_bullet_2") {
      CreateFireBullet(object, infoBullet)
    } else {
      GetCacheBulletSprite(object, infoBullet, gameStore.map.id);
    }
  }

  if (type === "rope_element") {
    AddPoint(ParsePoint(object))
  }

  if (type === "pollen_cloud") {
    CreatePollen(object)
  }
}

function RemoveRadarObject(mark) {
  if (mark.to === "box") {
    DestroyBox(Number(mark.id))
  }

  if (mark.to === "unit") {
    let unit = gameStore.units[mark.id];
    if (unit) removeUnit(unit);
  }

  if (mark.to === "drone") {
    let drone = gameStore.drones[mark.id];
    if (drone) {
      removeDrone(drone, mark.id)
    }
  }

  if (mark.to === "reservoir") {
    if (gameStore.reservoirs.hasOwnProperty(mark.id)) {
      removeReservoir(gameStore.reservoirs[mark.id], true);
      RemoveReservoirStatus('reservoir_' + mark.id)
      delete gameStore.reservoirs[mark.id];
    }
  }

  if (mark.to === "dynamic_objects") {
    removeDynamicObject(gameStore.objects[mark.id], true);
    gameStore.removeObjects[mark.id] = gameStore.objects[mark.id]
    gameStore.objects[mark.id] = null;
  }

  if (mark.to === "bullet") {
    deleteBullet(mark.id);
  }

  if (mark.to === "shield") {
    RemoveShield(mark.id);
  }

  if (mark.to === "item") {
    DestroyItem(Number(mark.id))
  }

  if (mark.to === "rope_element") {
    RemovePoint(mark.id)
  }

  if (mark.to === "pollen_cloud") {
    DestroyPollen(mark.id)
  }
}

function removeReservoir(reservoir, animate) {

  RemoveSelectSpriteReservoir(reservoir.sprite);
  if (reservoir.sprite.RadarMark) reservoir.sprite.RadarMark.destroy();
  if (reservoir.crackSprite) reservoir.crackSprite.destroy();

  let remove = function () {
    if (reservoir.sprite.shadow) {
      reservoir.sprite.shadow.destroy();
    }
    reservoir.sprite.destroy();
  }

  animate = animate && Scene.cameras.main.worldView.contains(reservoir.sprite.x, reservoir.sprite.y)

  if (animate) {
    Scene.tweens.add({
      targets: [reservoir.sprite, reservoir.sprite.shadow],
      alpha: 0,
      ease: 'Linear',
      duration: 250,
      onComplete: function () {
        remove()
      }
    });
  } else {
    remove()
  }

  RemoveReservoirStatus('reservoir_' + reservoir.id)

  ClearBars('reservoir', reservoir.id, 'progress');
  delete gameStore.reservoirs[reservoir.id];
}

function removeAllObj() {
  for (let id in gameStore.boxes) {
    DestroyBox(Number(id), false)
  }

  for (let id in gameStore.mapItems) {
    DestroyItem(Number(id), false)
  }

  for (let i in gameStore.units) {
    removeUnit(gameStore.units[i]);
  }

  for (let id in gameStore.reservoirs) {
    removeReservoir(gameStore.reservoirs[id], false);
  }

  for (let i in gameStore.bullets) {
    deleteBullet(i)
  }

  removeDynamicObjects();
  clearTrash();
}

function removeDynamicObjects() {
  for (let i in gameStore.objects) {
    if (gameStore.objects[i] && gameStore.objects[i].objectSprite) {
      removeDynamicObject(gameStore.objects[i], false)
      gameStore.removeObjects[i] = gameStore.objects[i]
      gameStore.objects[i] = null;
    }
  }
}

function createDynamicObject(object) {
  if (gameStore.objects[object.id]) return;

  if (object.animate) {
    CreateAnimate(object, object.x, object.y, Scene);
  } else {
    CreateObject(object, object.x, object.y, true, Scene);
  }

  gameStore.objects[object.id] = object;
  SetDayCycleObject(object)
  createObjectBars(object.id)
}

function removeDynamicObject(obj, animate) {
  if (!obj) return

  animate = animate && Scene.cameras.main.worldView.contains(obj.objectSprite.x, obj.objectSprite.y)

  store.commit({
    type: 'toggleWindow',
    id: 'reservoirTip' + obj.x + "" + obj.y,
    component: 'reservoirTip',
    forceClose: true,
  });

  store.commit({
    type: 'toggleWindow',
    id: 'ObjectDialog' + obj.id,
    component: 'ObjectDialog',
    forceClose: true,
  });

  RemoveSelectSpriteCombineTargets(obj.objectSprite);
  let remove = function () {
    if (obj.objectSprite.weapon) obj.objectSprite.weapon.destroy();
    if (obj.objectSprite.weaponShadow) obj.objectSprite.weaponShadow.destroy();
    if (obj.objectSprite.weaponBox) obj.objectSprite.weaponBox.destroy();
    if (obj.objectSprite.equip) obj.objectSprite.equip.destroy();
    if (obj.objectSprite.equipShadow) obj.objectSprite.equipShadow.destroy();
    if (obj.objectSprite.equipBox) obj.objectSprite.equipBox.destroy();
    if (obj.objectSprite.top) obj.objectSprite.top.destroy();
    if (obj.objectSprite.shadowTop) obj.objectSprite.shadowTop.destroy();
    if (obj.objectSprite.top2) obj.objectSprite.top2.destroy();
    if (obj.objectSprite.shadowTop2) obj.objectSprite.shadowTop2.destroy();
    if (obj.objectSprite.shadow) obj.objectSprite.shadow.destroy();
    if (obj.objectSprite.passBuildSelectSprite) obj.objectSprite.passBuildSelectSprite.destroy();
    if (obj.objectSprite.noPassbuildSelectSprite) obj.objectSprite.noPassbuildSelectSprite.destroy();
    if (obj.noPassMiningSelectSprite) obj.noPassMiningSelectSprite.destroy();
    if (obj.passMiningSelectSprite) obj.passMiningSelectSprite.destroy();
    if (obj.objectSprite.light) obj.objectSprite.light.destroy();
    if (obj.objectSprite.RadarMark) obj.objectSprite.RadarMark.destroy();
    if (obj.objectSprite.emitter) {
      obj.objectSprite.emitter.stop();
      obj.objectSprite.emitter.destroy();
    }

    if (obj.placeSprite) obj.placeSprite.destroy();
    if (obj.objectSprite.lights) {
      for (let i of obj.objectSprite.lights) {
        i.green.destroy();
        i.red.destroy();
      }
    }

    if (obj.objectSprite.emitter_tween) {
      obj.objectSprite.emitter_tween.destroy();
    }

    obj.objectSprite.destroy();
  }

  if (animate) {
    Scene.tweens.add({
      targets: [obj.objectSprite, obj.objectSprite.weapon, obj.objectSprite.top, obj.objectSprite.top2,
        obj.objectSprite.equip, obj.objectSprite.weaponShadow, obj.objectSprite.weaponBox, obj.objectSprite.equip,
        obj.objectSprite.equipShadow, obj.objectSprite.equipBox, obj.objectSprite.shadowTop, obj.objectSprite.shadow,
        obj.objectSprite.light, obj.objectSprite.emitter, obj.objectSprite.shadowTop2],
      alpha: 0,
      ease: 'Linear',
      duration: 150,
      onComplete: function () {
        remove(obj)
      }
    });
  } else {
    remove(obj)
  }

  ClearBars('object', obj.id, 'hp');
  ClearBars('object', obj.id, 'build');
  ClearBars('object', obj.id, 'energy');
  ClearBars('object', obj.id, 'shield');
  ClearBars('object', obj.id, 'progress');

  if (obj.type === "rope_trap") {
    for (let i in gameStore.ropes) {
      if (i.includes(id + '_')) {
        gameStore.ropes[i].sprite.destroy()
        gameStore.ropes[i].shadow.destroy()
        delete gameStore.ropes[i]
      }
    }
  }
}

function CreateDynamicObjects(dynamicObjects) {

  for (let i in gameStore.shields) {
    RemoveShield(gameStore.shields[i].id)
  }

  removeDynamicObjects();
  for (let id in dynamicObjects) {
    let bytes = base64ToArrayBuffer(dynamicObjects[id])
    createDynamicObject(ParseObject(bytes));
  }
}

export {
  CreateRadarObject,
  RemoveRadarObject,
  removeAllObj,
  CreateDynamicObjects,
  removeReservoir,
  removeDrone,
  removeDynamicObject
}
