<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Рынок</h2>

      <p>Рынок - функция доступная на каждой базе любой фракции, позволяющая торговать товарами с иными синтетами.</p>
      <p>Сам рынок привязан к базе, где осуществляется сделка купли/продажи. Игроку следует быть готовым отправиться в
        путешествие, чтобы забрать купленный товар. </p>

      <h3 class="head">Особенности рынка</h3>
      <ul>
        <li>Все игроки рынка анонимны, то есть вы не знаете, с кем вы торгуете.</li>
        <li>Все запросы и предложения находятся в единой базе, то есть вы видите все доступные лоты на рынке.</li>
        <li>Запросы/предложения схлопываются автоматически при совпадении цены, то есть если вы согласны на цену другого
          игрока, то сделка заключается мгновенно.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('market_2', 'png')+ ')',
            width: '480px',
            height: '286px',
            marginLeft: 'calc(50% - 240px)',
            }"/>

      <p>За каждый выставленный лот, игрок платит взнос на его содержание, чья цена зависит от типа товара, его
        количества
        и времени удержания лота на самом рынке.</p>
      <p>Игроки имеют полное право обмениваться предметами игнорируя рынок, но в таком случае, подобные сделки не
        являются
        безопасными.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Market</h2>

      <p>The market is a feature available at every base of any faction, allowing trade with other synthetics.</p>
      <p>The market itself is tied to the base where the purchase/sale transaction takes place. Players should be
        prepared to travel to
        pick up the purchased goods. </p>

      <h3 class="head">Market Features</h3>
      <ul>
        <li>All market players are anonymous, meaning you do not know who you are trading with.</li>
        <li>All requests and offers are located in a single database, meaning you see all available lots on the
          market.
        </li>
        <li>Requests/offers automatically collapse when the price matches, meaning if you agree to another player's
          price, the deal is concluded instantly.
        </li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('market_2', 'png')+ ')',
        width: '480px',
        height: '286px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

      <p>For each lot listed, the player pays a maintenance fee, the price of which depends on the type of goods, their
        quantity
        and the time the lot is held on the market itself.</p>
      <p>Players have the full right to exchange items ignoring the market, but in such cases, such deals are not
        considered
        safe.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Market",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
