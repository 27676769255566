<template>
  <div :class="{glitch: glitch && glitch.x > 0 && glitch.y > 0}">

    <template v-if="trailer && trailerProgress.open">

      <div class="image_transition" v-if="trailerProgress.close"/>
      <div class="image_transition_open" v-if="!trailerProgress.close"/>

      <video v-if="trailerProgress.video"
             ref="video"
             class="video"
             controls
             :src="getVideoUrl(require(trailer.url + '').default)"
             autoplay
             preload="auto"/>
    </template>

    <svg class="filter" v-if="glitch && glitch.x > 0 && glitch.y > 0">
      <filter id="rgb-split-1">
        <feOffset in="SourceGraphic" :dx="glitch.x" :dy="glitch.y" result="layer-one"/>
        <feComponentTransfer in="layer-one" result="red">
          <feFuncR type="identity"/>
          <feFuncG type="discrete" tableValues="0"/>
          <feFuncB type="discrete" tableValues="0"/>
        </feComponentTransfer>

        <feOffset in="SourceGraphic" :dx="glitch.x * -1" :dy="glitch.y * -1" result="layer-two"/>
        <feComponentTransfer in="layer-two" result="cyan">
          <feFuncR type="discrete" tableValues="0"/>
          <feFuncG type="identity"/>
          <feFuncB type="identity"/>
        </feComponentTransfer>

        <feBlend in="red" in2="cyan" mode="screen" result="color-split"/>
      </filter>

      <filter id="rgb-split-2">
        <feOffset in="SourceGraphic" dx="0" dy="0" result="layer-one"/>
        <feComponentTransfer in="layer-one" result="red">
          <feFuncR type="identity"/>
          <feFuncG type="discrete" tableValues="0"/>
          <feFuncB type="discrete" tableValues="0"/>
        </feComponentTransfer>

        <feOffset in="SourceGraphic" dx="0" dy="0" result="layer-two"/>
        <feComponentTransfer in="layer-two" result="cyan">
          <feFuncR type="discrete" tableValues="0"/>
          <feFuncG type="identity"/>
          <feFuncB type="identity"/>
        </feComponentTransfer>

        <feBlend in="red" in2="cyan" mode="screen" result="color-split"/>
      </filter>

      <filter id="rgb-split-3">
        <feOffset in="SourceGraphic" dx="-2" dy="-2" result="layer-one"/>
        <feComponentTransfer in="layer-one" result="red">
          <feFuncR type="identity"/>
          <feFuncG type="discrete" tableValues="0"/>
          <feFuncB type="discrete" tableValues="0"/>
        </feComponentTransfer>

        <feOffset in="SourceGraphic" dx="2" dy="2" result="layer-two"/>
        <feComponentTransfer in="layer-two" result="cyan">
          <feFuncR type="discrete" tableValues="0"/>
          <feFuncG type="identity"/>
          <feFuncB type="identity"/>
        </feComponentTransfer>

        <feBlend in="red" in2="cyan" mode="screen" result="color-split"/>
      </filter>
    </svg>

    <div id="MousePosition" :style="mousePosition" v-if="mousePosition.on">
      {{ mousePosition.x }} : {{ mousePosition.y }}
    </div>

    <div id="interface_wrapper"
         class="interface_wrapper"
         :style="{zoom: ($route.path === '/lobby' || $route.path === '/global' || $route.path === '/gate')  ? interfaceZoom : 1}">
      <template
        v-if="currentUser.hasOwnProperty('id') && gameInit.items && gameInit.interface && ($route.path === '/lobby' || $route.path === '/global')">

        <!--      Chat Components открыто всегда-->
        <app-chat v-if="openChat()"/>
        <app-view-all-groups v-if="openComponents['allGroupsWindow'] && openComponents['allGroupsWindow'].open"/>
        <app-create-new-group
          v-if="openComponents['NewChatCreateWrapper'] && openComponents['NewChatCreateWrapper'].open"/>

        <!--      Missions открыто всегда-->
        <app-missions/>

        <!--      Help -->
        <app-help v-if="openComponents['helpWrapper'] && openComponents['helpWrapper'].open"
                  v-bind:meta="openComponents['helpWrapper'].meta"/>
        <app-help-control v-if="this.openComponents['help-control'] && this.openComponents['help-control'].open"/>

        <app-settings v-if="this.openComponents['settings'] && this.openComponents['settings'].open"/>
        <app-technology-menu
          v-if="openComponents['TechnologyMenu'] && openComponents['TechnologyMenu'].open"
          v-bind:meta="openComponents['TechnologyMenu'].meta"/>

        <!--      Inventory-->
        <template>
          <app-inventory
            v-if="openComponents['wrapperInventoryAndStorage'] && openComponents['wrapperInventoryAndStorage'].open"
            v-bind:meta="openComponents['wrapperInventoryAndStorage'].meta"/>

          <app-hangar
            v-if="openComponents['inventoryBox'] && openComponents['inventoryBox'].open"/>

          <app-detail-item
            v-for="window in detailItems"
            v-bind:key="window.id"
            v-bind:windowID="window.id"
            v-bind:type="window.meta.itemType"
            v-bind:id="window.meta.itemID"/>

          <app-modal-notify v-for="window in modalBlocks"
                            v-if="window.meta"
                            :key="window.meta.uuid"
                            v-bind:windowID="window.id"
                            v-bind:notify="window.meta"/>

          <!--LOBBY-->
          <template v-if="$route.path === '/lobby'">
            <app-exit-menu v-if="openComponents['ExitMenu'] && openComponents['ExitMenu'].open"/>
            <app-news v-if="openComponents['News'] && openComponents['News'].open"/>

            <app-workbench
              v-if="openWorkbench && openComponents['Workbench'] && openComponents['Workbench'].open"/>

            <app-processor-root
              v-if="openProcessing && openComponents['processorRoot'] && openComponents['processorRoot'].open"/>

            <app-prefabricated-menu
              v-if="openPrefabricated && openComponents['prefabricatedMenuWrapper'] && openComponents['prefabricatedMenuWrapper'].open"/>

            <app-fraction-market
              v-if="openFractionMarket && openComponents['FractionMarket'] && openComponents['FractionMarket'].open"/>

            <app-n-p-c-trade
              v-if="openNPCTrade && openComponents['NPCTrade'] && openComponents['NPCTrade'].open"/>
          </template>

          <!--GLOBAL-->
          <template v-if="$route.path === '/global'">
            <app-open-build-object v-if="openComponents['OpenBuildObject'] && openComponents['OpenBuildObject'].open"/>
            <app-build-tip v-if="openComponents['BuildTip'] && openComponents['BuildTip'].open"/>
            <app-attack-dialog v-if="openComponents['AttackDialog'] && openComponents['AttackDialog'].open"/>
            <app-build-menu v-if="openComponents['buildDialogWrapper'] && openComponents['buildDialogWrapper'].open"/>
            <app-geo-scan
              v-if="openComponents['anomalyScannerWrapper'] && openComponents['anomalyScannerWrapper'].open"/>
            <app-select-target v-if="openComponents['SelectTarget'] && openComponents['SelectTarget'].open"/>

            <app-box-pass
              v-for="window in boxPasss"
              :key="window.meta.boxID"
              v-bind:windowID="window.id"
              v-bind:action="window.meta.action"
              v-bind:boxID="window.meta.boxID"
              v-bind:boxSlot="window.meta.slot"/>

            <app-object-dialog
              v-for="window in objectDialogs"
              :key="window.meta.id"
              v-bind:windowID="window.id"
              v-bind:meta="window.meta"/>
          </template>
        </template>

        <!--      WorldMap-->
        <app-global-map v-if="openComponents['GlobalViewMap'] && openComponents['GlobalViewMap'].open"
                        v-bind:meta="openComponents['GlobalViewMap'].meta"/>

        <!-- Market -->
        <app-market v-if="openComponents['marketBox'] && openComponents['marketBox'].open"/>

        <!-- Market -->
        <app-mini-game-wrapper v-if="openComponents['MiniGame'] && openComponents['MiniGame'].open"
                               v-bind:meta="openComponents['MiniGame'].meta"/>

        <!--      UserStat-->
        <app-user-stat
          :key="window.meta.id"
          v-for="window in userState"
          v-bind:windowID="window.id"
          v-bind:player_id="window.meta.id"
          v-bind:openTab="window.meta.tab"/>

        <!--      DepartmentOfEmployment-->
        <app-department v-if="openDialog && openDialog.page"/>

        <!--      сюда летают ошибки или оповещения об игре-->
        <app-alerts/>

        <!-- социальные окошки -->
        <app-friend-list v-if="openComponents['FriendList'] && openComponents['FriendList'].open"/>
        <app-group-menu v-if="openComponents['GroupMenu'] && openComponents['GroupMenu'].open"/>
        <app-corporation v-if="openComponents['Corporation'] && openComponents['Corporation'].open"
                         v-bind:meta="openComponents['Corporation'].meta"/>
        <app-send-credits v-if="openComponents['SendCredits'] && openComponents['SendCredits'].open"
                          v-bind:meta="openComponents['SendCredits'].meta"/>
        <app-mail v-if="openComponents['Mail'] && openComponents['Mail'].open"
                  v-bind:meta="openComponents['Mail'].meta"/>
        <app-damage-log v-if="openComponents['DamageLog'] && openComponents['DamageLog'].open"/>

      </template>

      <router-view/>
    </div>

    <!--      MenuBar открыто всегда, панель снизу экрана с элементами меню -->
    <template
      v-if="currentUser.hasOwnProperty('id') && gameInit.items && gameInit.interface && ($route.path === '/lobby' || $route.path === '/global')">
      <app-loader/>
      <app-menu-bar/>
      <app-messages v-if="!openChat()"/>
    </template>

    <app-mini-map v-if="$router.currentRoute.path === '/global'"/>
    <!--      игра открыта всегда даже в фоне, что бы не делать загрузки каждый раз когда зашел вышел из базы -->
    <app-game/>
  </div>
</template>

<script>
import Game from "./components/Game/Game"
import Chat from "./components/Chat/Chat"
import Messages from "./components/Chat/MessagesWrapper"
import ViewAllGroups from "./components/Chat/ViewAllGroups"
import CreateNewGroup from "./components/Chat/CreateNewGroup"
import Inventory from "./components/Inventory/Inventory"
import Hangar from "./components/Hangar/Hangar"
import DetailItem from "./components/DetailItem/DetailItem"
import Help from "./components/Help/Help"
import HelpControl from "./components/HelpControl/HelpControl"
import GlobalMap from "./components/GlobalMap/GlobalMap"
import UserStat from "./components/UserStat/UserStat"
import Department from "./components/Department/Department"
import Missions from "./components/Missions/Missions"
import Alerts from "./components/Alerts/Alerts"
import Loader from "./components/Loader/Loader"
import Market from "./components/Market/Market"
import ModalNotify from "./components/ModalNotify/ModalNotify"
import FriendList from "./components/FriendList/FriendList"
import GroupMenu from "./components/GroupMenu/GroupMenu"
import Corporation from "./components/Corporation/Corporation"
import TechnologyMenu from './components/TechnologyMenu/TechnologyMenu'
import MenuBar from './components/MenuBar/MenuBar'
import Settings from "./components/Settings/Settings";
import SendCredits from "./components/SendCredits/SendCredits";
import {gameStore} from "./game/store";
import {RemoveOldMap} from "./game/map/remove_old_map";
import MiniGameWrapper from "./components/MiniGames/MiniGameWrapper";
import {Scene} from "./game/create";
import {MapSize} from "./game/map/createMap";
import Mail from "./components/Mail/Mail"
import {SetDayCycle} from "./game/day_cycle/day_cycle";
import {ElectronApi, urls} from "./const";
import MiniMap from './components/MiniMap/MiniMap'
import DamageLog from './components/DamageLog/DamageLog';
import SelectTarget from "./components/SelectTarget/SelectTarget";
import GeoScan from "./components/GeoScan/GeoScan";
import OpenBuildObject from "./components/BuildMenu/OpenBuildObject";
import AttackDialog from "./components/AttackDialog/AttackDialog";
import BuildTip from "./components/BuildMenu/BuildTip";
import BuildMenu from "./components/BuildMenu/BuildMenu";
import BoxPass from "./components/BoxPass/BoxPass";
import ObjectDialog from "./components/ObjectDialog/ObjectDialog";
import ExitMenu from "./components/Lobby/ExitMenu";
import News from "./components/News/News";
import Workbench from "./components/Workbench/Workbench";
import ProcessorRoot from "./components/ProcessorRoot/ProcessorRoot";
import PrefabricatedMenu from "./components/PrefabricatedMenu/PrefabricatedMenu";
import FractionMarket from "./components/FractionMarket/FractionMarket";
import NPCTrade from "./components/NPCTrade/NPCTrade";

export default {
  name: 'app',
  data() {
    return {
      game: false,
      mousePosition: {
        left: 0,
        top: 0,
        x: 0,
        y: 0,
        on: false,
      },
      trailerProgress: {
        open: false,
        video: false,
        close: false,
        playerVolume: 0,
      }
    }
  },
  mounted() {
    ElectronApi()

    let app = this;
    let playerVolume = 0;

    document.addEventListener("keydown", function (e) {
      let keycode1 = (e.keyCode ? e.keyCode : e.which);
      if (keycode1 === 27) {

        for (let id in app.openComponents) {
          if (app.openComponents[id].open) {
            app.$store.commit({
              type: 'toggleWindow',
              id: id,
              component: '',
              forceClose: true,
            });
          }
        }

        e.preventDefault();
        e.stopPropagation();
      }

      if (keycode1 === 0 || keycode1 === 9) {

        app.$store.dispatch("sendSocketData", JSON.stringify({
          service: "global",
          data: {
            event: "SelectUnit",
          },
        }))

        e.preventDefault();
        e.stopPropagation();
      }

      if (keycode1 === 122) {
        app.$store.dispatch("toggleFullScreen");
        e.preventDefault();
        e.stopPropagation();
      }
    })

    document.addEventListener("visibilitychange", function () {

      if (gameStore.mapEditor) {
        return
      }

      if (document.hidden) {
        RemoveOldMap();
        gameStore.gameReady = false;
        gameStore.gameDataInit = {
          data: false,
          sendRequest: false,
        }
        gameStore.exitTab = true

        playerVolume = app.$store.getters.getSettings.volume;
        app.$store.commit({
          type: 'setVolume',
          volume: 0,
        });

      } else {
        gameStore.exitTab = false
        SetDayCycle(true)
        app.$store.commit({
          type: 'setVolume',
          volume: playerVolume,
        });
      }
    });

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetPlayers",
      },
    }))

    console.log(this.$route)
    if (this.$route.path === '/lobby' || this.$route.path === '/global') {
      this.$router.push('/gate');
    }

    if (this.mousePosition.on) {
      $('body').mousemove(function (e) {
        app.updatePos(e);
      });
    }
  },
  methods: {
    updatePos(e) {
      this.mousePosition.left = (e.pageX + 30) + "px";
      this.mousePosition.top = (e.pageY + 3) + "px";

      if (gameStore.gameReady) {
        this.mousePosition.x = Math.round(Scene.input.mousePointer.worldX) - MapSize;
        this.mousePosition.y = Math.round(Scene.input.mousePointer.worldY) - MapSize;
      }
    },
    getVideoUrl(turl) {
      let url = urls.audioPrefix + turl
      if (url.includes('local-audio:/./')) {
        url = url.replace("local-audio:/./", 'local-audio://')
      }

      return url
    },
    getWindows(type) {
      let windows = [];
      for (let i in this.openComponents) {
        if (this.openComponents.hasOwnProperty(i) && this.openComponents[i].open && this.openComponents[i].component === type) {
          windows.push(this.openComponents[i])
        }
      }

      return windows
    },
    openChat() {
      return this.openComponents['chat'] && this.openComponents['chat'].open
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getGameUser
    },
    gameInit() {
      return this.$store.getters.getInit
    },
    openComponents() {
      return this.$store.getters.getNeedOpenComponents
    },
    openDialog() {
      return this.$store.getters.getOpenDialog
    },
    detailItems() {
      return this.getWindows('detailItem')
    },
    modalBlocks() {
      return this.getWindows('modalBlock')
    },
    userState() {
      let windows = [];
      for (let i in this.openComponents) {
        if (this.openComponents.hasOwnProperty(i) && this.openComponents[i].open && this.openComponents[i].meta && this.openComponents[i].meta.id && this.openComponents[i].component === 'userStat') {
          windows.push(this.openComponents[i])
        }
      }

      return windows
    },
    glitch() {
      return this.$store.getters.getGlitch
    },
    trailer() {

      let trailerOptions = this.$store.getters.getTrailer;

      if (trailerOptions.open) {
        if (trailerOptions.open !== this.trailerProgress.open) {
          setTimeout(function () { // чтобы было не реактивно
            this.trailerProgress.playerVolume = this.Volume;
          }.bind(this))
        }

        this.trailerProgress.open = trailerOptions.open;
        this.trailerProgress.close = true;
        this.trailerProgress.video = false;

        if (trailerOptions.sound && trailerOptions.sound !== '') {
          this.$store.dispatch('playSound', {
            sound: trailerOptions.sound,
            k: 1,
          });
        }

        setTimeout(function () {
          this.trailerProgress.video = true;

          let waitVideo = setInterval(function () {
            let el = this.$refs.video;
            if (el) {
              clearInterval(waitVideo);

              if (trailerOptions.glitchOff) {
                this.$store.commit({
                  type: 'setGlitch',
                  f: 0,
                });
              }

              this.$store.commit({
                type: 'setVolume',
                volume: 0,
              });

              el.volume = this.SFXVolume;

              el.addEventListener("ended", (event) => {
                this.$store.commit({
                  type: 'setTrailer',
                  open: false,
                  url: '',
                  glitchOff: true,
                  mission_uuid: trailerOptions.mission_uuid,
                  action_number: trailerOptions.action_number,
                });
              }, false);
            }
          }.bind(this), 100)
        }.bind(this), 5700)
      } else {
        if (this.trailerProgress.open) {
          this.trailerProgress.close = false;
          this.trailerProgress.video = false;
          this.$store.commit({
            type: 'setVolume',
            volume: this.trailerProgress.playerVolume,
          });

          this.$store.dispatch("sendSocketData", JSON.stringify({
            service: "system",
            data: {
              event: "CompleteCutscene",
              data: trailerOptions.mission_uuid,
              id: trailerOptions.action_number,
            },
          }))

          setTimeout(function () {
            this.trailerProgress.open = false;
            gameStore.cutScene.active = false;
          }.bind(this), 2700)
        }
      }

      return trailerOptions
    },
    SFXVolume() {
      return this.$store.getters.getSFXVolume
    },
    Volume() {
      return this.$store.getters.getVolume
    },
    prefixVideo() {
      return urls.audioPrefix
    },
    interfaceZoom() {
      return (100 + this.$store.getters.getSettings.ZoomInterface) / 100
    },
    openPrefabricated() {
      return this.$store.getters.openPrefabricated
    },
    openWorkbench() {
      return this.$store.getters.openWorkbench
    },
    openProcessing() {
      return this.$store.getters.openProcessing
    },
    openFractionMarket() {
      return this.$store.getters.openFractionMarket
    },
    openNPCTrade() {
      return this.$store.getters.openNPCTrade
    },
    boxPasss() {
      return this.getWindows('boxPass');
    },
    objectDialogs() {
      return this.getWindows('ObjectDialog');
    },
  },
  components: {
    AppGame: Game,
    AppChat: Chat,
    AppViewAllGroups: ViewAllGroups,
    AppCreateNewGroup: CreateNewGroup,
    AppInventory: Inventory,
    AppHangar: Hangar,
    AppDetailItem: DetailItem,
    AppHelp: Help,
    AppGlobalMap: GlobalMap,
    AppUserStat: UserStat,
    AppDepartment: Department,
    AppMissions: Missions,
    AppAlerts: Alerts,
    AppLoader: Loader,
    AppMarket: Market,
    AppModalNotify: ModalNotify,
    AppFriendList: FriendList,
    AppGroupMenu: GroupMenu,
    AppCorporation: Corporation,
    AppTechnologyMenu: TechnologyMenu,
    AppMenuBar: MenuBar,
    AppSettings: Settings,
    AppHelpControl: HelpControl,
    AppMessages: Messages,
    AppSendCredits: SendCredits,
    AppMiniGameWrapper: MiniGameWrapper,
    AppMail: Mail,
    AppMiniMap: MiniMap,
    AppDamageLog: DamageLog,
    AppSelectTarget: SelectTarget,
    AppGeoScan: GeoScan,
    AppOpenBuildObject: OpenBuildObject,
    AppAttackDialog: AttackDialog,
    AppBuildTip: BuildTip,
    AppBuildMenu: BuildMenu,
    AppBoxPass: BoxPass,
    AppObjectDialog: ObjectDialog,
    AppExitMenu: ExitMenu,
    AppNews: News,
    AppWorkbench: Workbench,
    AppProcessorRoot: ProcessorRoot,
    AppPrefabricatedMenu: PrefabricatedMenu,
    AppFractionMarket: FractionMarket,
    AppNPCTrade: NPCTrade,
  }
}
</script>

<style>
/*@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,500,700|Noto+Sans+SC:300,500,700|Source+Code+Pro:300,500,700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');*/

.body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  cursor: url("assets/cursor/1.png"), auto;
}

body * {
  scrollbar-color: #adc6cd rgba(1, 77, 93, 0.4);
  scrollbar-width: thin;
}

.interface_wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
}

.body:active {
  cursor: url("assets/cursor/3.png"), auto !important;
}

.body *:active {
  cursor: url("assets/cursor/3.png"), auto !important;
}

.cursor_hover {
  cursor: url("assets/cursor/2.png"), auto;
}

input {
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 18px;
  box-shadow: inset 0 0 1px 1px rgb(118, 118, 118);
  border: 0;
  font-size: 11px;
  background: #79a0b4;
  border-radius: 5px;
  color: #ff7800;
  text-shadow: 1px 1px 1px black;
}

input[type=text], input[type=password], input[type=number] {
  background: #8d9b9e;
}

/*input:hover {*/
/*  background-color: rgba(0, 120, 211, 0.9);*/
/*}*/

input[type=submit]:active {
  transform: scale(0.95);
}

input::placeholder, textarea::placeholder {
  text-shadow: none;
}

input, textarea, select, option {
  outline: none;
}

.Failed {
  color: rgb(255, 95, 97);
}

/* CUSTOM SCROLLBAR */
body ::-webkit-scrollbar,
body ::-webkit-scrollbar:horizontal {
  width: 12px;
}

body ::-webkit-scrollbar {
  background: rgba(165, 213, 239, 0.3);
  box-shadow: inset 0 0 5px black;
}

/* DISABLED STATES */
body ::-webkit-scrollbar:disabled {
  background: rgba(165, 213, 239, 0.31);
  box-shadow: inset 0 0 5px black;
}

/* HANDLE */
body ::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb:window-inactive,
body ::-webkit-scrollbar-thumb:disabled {
  box-shadow: inset 0 0 5px black;
  background-size: 5px 2px;
  background: rgba(1, 77, 93, 0.5);
}

/*--------*/
body ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0 0;
  border: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: url('assets/cursor/2.png'), auto;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: rgba(165, 213, 239, 0.7);
  border-radius: 0px;
  border: 0.1px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 14px;
  width: 16px;
  border-radius: 0px;
  background: rgba(0, 73, 93, 0.7);
  cursor: url('assets/cursor/2.png'), auto;
  -webkit-appearance: none;
  margin-top: -0.1px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(NaN, NaN, NaN, 0.7);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: url('assets/cursor/2.png'), auto;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: rgba(165, 213, 239, 0.7);
  border-radius: 0px;
  border: 0.1px solid #000000;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 16px;
  width: 16px;
  border-radius: 0px;
  background: rgba(0, 73, 93, 0.7);
  cursor: url('assets/cursor/2.png'), auto;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 16px;
  cursor: url('assets/cursor/2.png'), auto;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: rgba(NaN, NaN, NaN, 0.7);
  border: 0.1px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
  background: rgba(165, 213, 239, 0.7);
  border: 0.1px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 16px;
  width: 16px;
  border-radius: 0px;
  background: rgba(0, 73, 93, 0.7);
  cursor: url('assets/cursor/2.png'), auto;
}

input[type=range]:focus::-ms-fill-lower {
  background: rgba(165, 213, 239, 0.7);
}

input[type=range]:focus::-ms-fill-upper {
  background: rgba(NaN, NaN, NaN, 0.7);
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0px 0;
}

input[type=range]:focus {
  outline: none;
}

.importantly {
  text-decoration: none;
  color: #faff00;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
}

@keyframes selectMenu {
  0% {
    box-shadow: 0 0 15px 0 rgb(252, 255, 0);
  }
  95% {
    box-shadow: 0 0 30px 15px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
}

@keyframes selectMenu2 {
  0% {
    box-shadow: 0 0 15px 0 rgb(0, 255, 46);
  }
  95% {
    box-shadow: 0 0 30px 15px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
}

@keyframes selectMenu3 {
  0% {
    box-shadow: 0 0 15px 0 rgb(255, 0, 8);
  }
  95% {
    box-shadow: 0 0 30px 15px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
}

@keyframes selectMenu4 {
  0% {
    box-shadow: 0 0 15px 0 rgb(0, 37, 255);
  }
  95% {
    box-shadow: 0 0 30px 15px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
}

::-webkit-scrollbar-corner {
  background: rgb(8, 138, 210);
  box-shadow: inset 0 0 4px black;
}

.InventoryCell.bodyEquip span {
  font-weight: 900;
  font-size: 15px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  display: block;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.InventoryCell.bodyThorium h6 {
  margin: 4px 0 0 3px;
  padding: 0;
  z-index: 1;
  font-weight: 900;
  font-size: 9px;
  line-height: 8px;
  width: 100%;
  display: block;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  text-align: left;
}

.InventoryCell.bodyThorium p {
  margin: 0 2px 0 0;
  font-size: 9px;
  padding: 0;
  text-align: right;
  color: #c5c3c1;
  background: none;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.InventoryCell.bodyThorium .sep {
  text-align: center;
}

.InventoryCell.bodyThorium .itemIconInventoryCell {
  z-index: -1;
}

#hp_bar_wrapper {
  height: 6px;
  border: 1px solid #4c4c4c;
  text-align: left;
  display: block;
  box-shadow: inset 0 0 2px black, 0 0 2px black;
  border-radius: 10px;
  background-size: 12%;
  overflow: hidden;
  background-color: #959595;
  margin: 0 auto 0;
  position: absolute;
  top: 79px;
  z-index: 2;
  width: 80px;
  left: calc(50% - 40px);
  opacity: .75;
}

#hp_bar_wrapper span {
  display: block;
  font-size: 5px;
  line-height: 7px;
  width: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  margin: auto;
  float: left;
  color: white;
}

#hp_bar {
  overflow: visible;
  background: rgba(72, 255, 40, 0.7);
  height: 100%;
}

#bodyFine {
  background-image: url("https://img.icons8.com/fluent/48/000000/high-priority.png");
  background-size: contain;
  height: 50px;
  width: 50px;
  filter: drop-shadow(0px 0px 3px black);
  position: absolute;
  left: -23px;
  top: -2px;
}

.tipBodyFine {
  position: absolute;
  z-index: 15;
  left: 92px;
  top: 117px;
  display: block;
  font-size: 12px;
  color: white;
  width: 138px;
  padding: 5px 5px 5px 20px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  box-shadow: 0 0 2px white;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.disable {
  background: grey !important;
  pointer-events: none !important;
}

.ui-resizable-handle {
  filter: contrast(00%) sepia(95%) hue-rotate(289deg) brightness(0.8) saturate(900%) drop-shadow(0px 1px 0px black);
  right: -3px;
  bottom: -4px;
}

.credits {
  color: #f1bd00;
  text-shadow: 1px 1px 0 black;
}

#MousePosition {
  position: absolute;
  min-height: 20px;
  line-height: 20px;
  min-width: 20px;
  background: rgba(71, 71, 71, 0.6);
  color: greenyellow;
  pointer-events: none;
  opacity: 0.4;
  z-index: 999;
}

.window_active {
  box-shadow: 0 0 2px black !important;
}

.window_no_active {
  box-shadow: none !important;
}

.window_no_active .windowsHead {
  background: rgba(0, 74, 74, 0.6);
  color: grey;
}

.filter {
  display: none;
}

.glitch div {
  animation: glitch 1500ms ease infinite;
}

@keyframes glitch {
  2%, 64% {
    filter: url(#rgb-split-1);
  }
  /*4%, 60% {*/
  /*  filter: url(#rgb-split-2);*/
  /*}*/
  /*62% {*/
  /*  filter: url(#rgb-split-3);*/
  /*}*/
}

@property --x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

.image_transition {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) var(--x));
  position: absolute;
  animation: imageTransition 6000ms linear 1 !important;
  z-index: 1000;
  pointer-events: none;
  opacity: 1;
}

@keyframes imageTransition {
  0% {
    --x: 200%;
    opacity: 1;
  }
  40% {
    --x: 10%;
    opacity: 1;
  }
  69% {
    --x: 0%;
    opacity: 1;
  }
  80% {
    --x: 0%;
    opacity: 1;
  }
  100% {
    --x: 0%;
    opacity: 1;
  }
}

@property --y {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 200%;
}

.image_transition_open {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) var(--y));
  position: absolute;
  animation: imageTransitionOpen 3000ms linear 1 !important;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
}

@keyframes imageTransitionOpen {
  100% {
    --y: 200%;
    opacity: 1;
  }
  80% {
    --y: 10%;
    opacity: 1;
  }
  69% {
    --y: 0%;
    opacity: 1;
  }
  40% {
    --y: 0%;
    opacity: 1;
  }
  0% {
    --y: 0%;
    opacity: 1;
  }
}

.video {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  background: black;
}

.cursor_box {
  cursor: url("assets/cursor/4.png"), auto !important;
}

.cursor_box:active {
  cursor: url("assets/cursor/4.png"), auto !important;
}

.cursor_box *:active {
  cursor: url("assets/cursor/4.png"), auto !important;
}

.cursor_hand {
  cursor: url("assets/cursor/9.png"), auto !important;
}

.cursor_hand:active {
  cursor: url("assets/cursor/9.png"), auto !important;
}

.cursor_hand *:active {
  cursor: url("assets/cursor/9.png"), auto !important;
}

.cursor_mining {
  cursor: url("assets/cursor/5.png"), auto !important;
}

.cursor_mining:active {
  cursor: url("assets/cursor/5.png"), auto !important;
}

.cursor_mining *:active {
  cursor: url("assets/cursor/5.png"), auto !important;
}

.cursor_scanner {
  cursor: url("assets/cursor/7.png"), auto !important;
}

.cursor_scanner:active {
  cursor: url("assets/cursor/7.png"), auto !important;
}

.cursor_scanner *:active {
  cursor: url("assets/cursor/7.png"), auto !important;
}

.cursor_digger {
  cursor: url("assets/cursor/8.png"), auto !important;
}

.cursor_digger:active {
  cursor: url("assets/cursor/8.png"), auto !important;
}

.cursor_digger *:active {
  cursor: url("assets/cursor/8.png"), auto !important;
}

.cursor_weapon {
  cursor: url("assets/cursor/6.png"), auto !important;
}

.cursor_weapon:active {
  cursor: url("assets/cursor/6.png"), auto !important;
}

.cursor_weapon *:active {
  cursor: url("assets/cursor/6.png"), auto !important;
}

@media screen and (min-width: 3000px) {
  body {
    cursor: url("assets/cursor/1_32.png"), auto;
  }

  body:active {
    cursor: url("assets/cursor/3_32.png"), auto !important;
  }

  body *:active {
    cursor: url("assets/cursor/3_32.png"), auto !important;
  }

  .cursor_hover {
    cursor: url("assets/cursor/2_32.png"), auto;
  }

  .cursor_box {
    cursor: url("assets/cursor/4_32.png"), auto !important;
  }

  .cursor_box:active {
    cursor: url("assets/cursor/4_32.png"), auto !important;
  }

  .cursor_box *:active {
    cursor: url("assets/cursor/4_32.png"), auto !important;
  }

  .cursor_hand {
    cursor: url("assets/cursor/9_32.png"), auto !important;
  }

  .cursor_hand:active {
    cursor: url("assets/cursor/9_32.png"), auto !important;
  }

  .cursor_hand *:active {
    cursor: url("assets/cursor/9_32.png"), auto !important;
  }

  .cursor_mining {
    cursor: url("assets/cursor/5_32.png"), auto !important;
  }

  .cursor_mining:active {
    cursor: url("assets/cursor/5_32.png"), auto !important;
  }

  .cursor_mining *:active {
    cursor: url("assets/cursor/5_32.png"), auto !important;
  }

  .cursor_scanner {
    cursor: url("assets/cursor/7_32.png"), auto !important;
  }

  .cursor_scanner:active {
    cursor: url("assets/cursor/7_32.png"), auto !important;
  }

  .cursor_scanner *:active {
    cursor: url("assets/cursor/7_32.png"), auto !important;
  }

  .cursor_digger {
    cursor: url("assets/cursor/8_32.png"), auto !important;
  }

  .cursor_digger:active {
    cursor: url("assets/cursor/8_32.png"), auto !important;
  }

  .cursor_digger *:active {
    cursor: url("assets/cursor/8_32.png"), auto !important;
  }

  .cursor_weapon {
    cursor: url("assets/cursor/6_32.png"), auto !important;
  }

  .cursor_weapon:active {
    cursor: url("assets/cursor/6_32.png"), auto !important;
  }

  .cursor_weapon *:active {
    cursor: url("assets/cursor/6_32.png"), auto !important;
  }
}
</style>
