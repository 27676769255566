<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: снаряды</h2>

      <p>Во время перезарядки оружия снаряды берутся из трюма вашего транспорта, что бы перенести снаряды со склада в
        трюм
        необходимо перенести снаряды на вкладку "трюм" в меню инвентаря.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_ammo_1', 'gif')+ ')',
            width: '352px',
            height: '184px',
            marginLeft: 'calc(50% - 176px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Ammunition</h2>

      <p>During weapon reloading, ammunition is taken from the hold of your transport. To transfer ammunition from the
        warehouse to the hold, it is necessary to move the ammunition to the "hold" tab in the inventory menu.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_ammo_1', 'gif')+ ')',
            width: '352px',
            height: '184px',
            marginLeft: 'calc(50% - 176px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationAmmo",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
