var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Добыча нефти")]),_vm._v(" "),_c('p',[_vm._v("Особый вид ресурса, добываемый посредством использования “вакуумного насоса” на месторождении. Месторождения\n      нефти, как правило располагаются на территориях свободных секторов. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('oil_1', 'gif')+ ')',
          width: '300px',
          height: '185px',
          marginLeft: 'calc(50% - 150px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Oil Extraction")]),_vm._v(" "),_c('p',[_vm._v("Oil is a special type of resource extracted using a “vacuum pump” at oil fields. Oil fields are typically\n      located in free sectors.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('oil_1', 'gif')+ ')',
          width: '300px',
          height: '185px',
          marginLeft: 'calc(50% - 150px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }