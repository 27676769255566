<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head" id="bodies">Корпуса</h2>
      <p>Корпус - основа транспортной системы любого синтета. Корпус определяет ходовые/защитные характеристики
        транспорта, а также количество слотов, выделенных под различное снаряжение и вооружение. </p>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('bodies_1', 'png')+ ')',
        width: '310px',
        height: '170px',
        marginLeft: 'calc(50% - 155px)',
        }"/>

      <p>Корпуса делятся на три стандарта шаблонных конструкций: лёгкие, средние, тяжёлые.</p>
      <p>Их характеристики и назначение разительно отличаются в зависимости от фракции, которая изобрела тот или иной
        транспорт. Это определяет какие навыки необходимы для владения тем или иным корпусом. Если игрок использует
        корпус
        который ему не подходит по навыкам то корпус будет работать менее эффективнее. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_1', 'png')+ ')',
        width: '386px',
        height: '208px',
        marginLeft: 'calc(50% - 193px)',
        }"/>

      <div class="content_section">
        <h3 class="headSection">Характеристики: </h3>
        <p>Корпус определяет такие характеристики: </p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_2', 'png')+ ')',
        width: '414px',
        height: '701px',
        marginLeft: 'calc(50% - 207px)',
        }"/>

        <ul>
          <li><span class="headSection">Вместимость трюма</span> - определяет сколько места в трюме.</li>
          <li><span class="headSection">Макс. скорость</span> - максимальная скорость которую может развить корпус.
          </li>
          <li><span class="headSection">Разгон</span> - указывает как быстро будет набирать скорость корпус.</li>
          <li><span class="headSection">Скорость поворота</span> - поворот корпуса градусы/сек.</li>
          <li><span class="headSection">Дальность обзора</span> - как далеко может видеть в тумане войны.</li>
          <li><span class="headSection">Дальность радара</span> - определяет на каком расстояние радар будет улавливать
            сигналы.
          </li>
          <li><span class="headSection">Вместимость накопителя энергии</span> - максимальное количество энергии в
            накопителе
            для использования снаряжения.
          </li>
          <li><span class="headSection">Скорость зарядки</span> - кол-во восполняемой энергии в сек.</li>
          <li><span class="headSection">Структура</span> - кол-во хп корпуса.</li>
          <li><span class="headSection">Защита</span> - блокирует входящий урон в % соотношение по типу урона.</li>
          <li><span class="headSection">Броня и уязвимые места</span> - множитель урона на определенных участках
            корпуса.
          </li>
        </ul>

        <p>Параметры в ангаре могут отличатся в ангаре из за навыков и установленного снаряжения</p>

      </div>

      <div class="content_section">
        <h3 class="head">Ячейки для снаряжения и оружия</h3>
        <p>Корпуса могут иметь только 1 ячейку для оружия, и несколько слотов для пассивного и активного снаряжения.</p>

        <p>Каждый корпус имеет различие конфигурации для снаряжения, всего существует 3 типа снаряжения
          определяющее его мощность, они устанавливаются в соответствующие ячейки (I, II, III).</p>

        <p>Информация по оснащению корпуса можно узнать на карточке корпуса</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_3', 'png')+ ')',
        width: '381px',
        height: '284px',
        float: 'left',
        marginLeft: 'calc(50% - 190px)',
        }"/>
      </div>

      <div class="content_section">
        <p>Некоторое снаряжение требует что бы ячейка для снаряжения была снаружи корпуса, например под модуль
          переработки, промышленный лазер или дрона, такие ячейки помечаются желтым цветом в ангаре. В них по прежнему
          можно установить внутреннее снаряжение.</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_4', 'png')+ ')',
        width: '174px',
        height: '160px',
        float: 'left',
         marginLeft: 'calc(50% - 87px)',
        }"/>
      </div>

      <div class="content_section">
        <h3 class="head">Бонусы</h3>
        <p>Некоторые корпуса могут иметь профильные особенности, но что бы их использовать надо обладать подходящим
          навыком владения этим корпусом.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_3', 'png')+ ')',
            width: '399px',
            height: '339px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head" id="bodies">Chassis</h2>
      <p>The chassis is the foundation of any synthetic's transport system. The chassis determines the
        mobility/defensive characteristics of the transport, as well as the number of slots allocated for various
        equipment and weapons.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('bodies_1', 'png')+ ')',
        width: '310px',
        height: '170px',
        marginLeft: 'calc(50% - 155px)',
        }"/>

      <p>Chassis are divided into three standards of template designs: light, medium, heavy.</p>
      <p>Their characteristics and purpose vary greatly depending on the faction that invented the particular transport.
        This determines which skills are necessary to operate each chassis. If a player uses a chassis that does not fit
        their skills, the chassis will operate less effectively.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_1', 'png')+ ')',
        width: '386px',
        height: '208px',
        marginLeft: 'calc(50% - 193px)',
        }"/>

      <div class="content_section">
        <h3 class="headSection">Characteristics:</h3>
        <p>The chassis determines such characteristics as:</p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_2', 'png')+ ')',
        width: '414px',
        height: '701px',
        marginLeft: 'calc(50% - 207px)',
        }"/>

        <ul>
          <li><span class="headSection">Cargo capacity</span> - determines how much space is in the cargo hold.</li>
          <li><span class="headSection">Max. speed</span> - the maximum speed that the chassis can develop.</li>
          <li><span class="headSection">Acceleration</span> - indicates how quickly the chassis will gain speed.</li>
          <li><span class="headSection">Turn speed</span> - the turn of the chassis in degrees/sec.</li>
          <li><span class="headSection">Visibility range</span> - how far it can see in the fog of war.</li>
          <li><span class="headSection">Radar range</span> - determines at what distance the radar will capture signals.
          </li>
          <li><span class="headSection">Energy storage capacity</span> - the maximum amount of energy in the storage for
            using equipment.
          </li>
          <li><span class="headSection">Recharge speed</span> - the amount of energy replenished per second.</li>
          <li><span class="headSection">Structure</span> - the amount of HP of the chassis.</li>
          <li><span class="headSection">Protection</span> - blocks incoming damage in % ratio by type of damage.</li>
          <li><span class="headSection">Armor and vulnerable spots</span> - damage multiplier on certain parts of the
            chassis.
          </li>
        </ul>
        <p>Parameters in the hangar may differ due to skills and installed equipment.</p>
      </div>

      <div class="content_section">
        <h3 class="head">Slots for equipment and weapons</h3>
        <p>Chassis can have only 1 slot for weapons, and several slots for passive and active equipment.</p>
        <p>Each chassis has different configurations for equipment, there are 3 types of equipment determining its
          power,
          they are installed in the corresponding slots (I, II, III).</p>
        <p>Information on equipping the chassis can be found on the chassis card.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_3', 'png')+ ')',
          width: '381px',
          height: '284px',
          float: 'left',
          marginLeft: 'calc(50% - 190px)',
          }"/>
      </div>

      <div class="content_section">
        <p>Some equipment requires that the equipment slot be outside the chassis, for example, under a processing
          module,
          industrial laser, or drone, such slots are marked in yellow in the hangar. Internal equipment can still be
          installed in them.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('wiki_body_4', 'png')+ ')',
          width: '174px',
          height: '160px',
          float: 'left',
          marginLeft: 'calc(50% - 87px)',
          }"/>
      </div>

      <div class="content_section">
        <h3 class="head">Bonuses</h3>
        <p>Some chassis may have profile features, but to use them you need to have the appropriate skill to operate
          this chassis.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_3', 'png')+ ')',
            width: '399px',
            height: '339px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Bodies",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
