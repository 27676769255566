var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Автопилот")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Автопилот - функция, позволяющая вам перемещаться по поверхности планеты без собственных усилий. Игроку\n        достаточно выбрать пункт достижения, включить сам автопилот, затем, наслаждаться поездкой.")]),_vm._v(" "),_c('p',[_vm._v("Будь-то база, иной сектор или просто точка посреди пустошей, автопилот вас туда доставит. Однако стоит\n        помнить,\n        что при работе автопилота, вы теряете возможность и для самообороны. Становитесь уязвимы для атак и прочих\n        опасностей.")]),_vm._v(" "),_c('p',[_vm._v("Благо выключение автопилота, происходит так же легко, как и его активация - нажатием соответствующей иконки\n        на\n        нижней панели.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot', 'png')+ ')',
      width: '66px',
      height: '75px',
      marginLeft: 'calc(50% - 33px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Интерфейс специально продемонстрирует игроку, если автопилот активирован - высвечивая соответствующую иконку\n        зелёным светом и выводя на экран надпись - “Autopilot”. В случае же отключения автопилота, надпись пропадает,\n        а\n        сама иконка тускнеет. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot_2', 'png')+ ')',
      width: '480px',
      height: '140px',
      marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Принцип работы автопилота")]),_vm._v(" "),_c('p',[_vm._v("Игрок открывает карту мира, выбирая необходимую точку достижения (сектор, база и т.д.), после чего, интерфейс\n        строит маршрут движения. ")]),_vm._v(" "),_c('p',[_vm._v("Подтвердив свой выбор, система автопилота автоматически включается и корпус игроку начинает своё движение по\n        построенному маршруту. Вы всегда можете прекратить движения корпуса посредством автопилота - отключив его.\n        Аналогичным же образом, работает и изменение маршрута для автопилота - попросту выбрав иную цель движения.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot_3', 'png')+ ')',
      width: '480px',
      height: '446px',
      marginLeft: 'calc(50% - 240px)',
      })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Autopilot")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Autopilot is a feature that allows you to move across the planet's surface without any effort. The player\n        just needs to select a destination point, activate the autopilot, and then enjoy the ride.")]),_vm._v(" "),_c('p',[_vm._v("Whether it's a base, another sector, or just a point in the middle of the wastelands, the autopilot will get\n        you there. However, it is worth remembering\n        that when the autopilot is operating, you lose the ability to defend yourself. You become vulnerable to\n        attacks and other\n        dangers.")]),_vm._v(" "),_c('p',[_vm._v("Fortunately, turning off the autopilot is just as easy as activating it - by pressing the corresponding icon\n        on\n        the bottom panel.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot', 'png')+ ')',
        width: '66px',
        height: '75px',
        marginLeft: 'calc(50% - 33px)',
      })}),_vm._v(" "),_c('p',[_vm._v("The interface will specifically show the player if the autopilot is activated - by lighting up the\n        corresponding icon\n        with green light and displaying the inscription - “Autopilot”. In the case of deactivating the autopilot, the\n        inscription disappears,\n        and\n        the icon dims.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot_2', 'png')+ ')',
        width: '480px',
        height: '140px',
        marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("How Autopilot Works")]),_vm._v(" "),_c('p',[_vm._v("The player opens the world map, selecting the necessary destination point (sector, base, etc.), after which,\n        the interface\n        constructs a route. ")]),_vm._v(" "),_c('p',[_vm._v("After confirming their choice, the autopilot system automatically activates and the player's vehicle begins\n        its movement along\n        the constructed route. You can always stop the vehicle's movement via autopilot - by deactivating it.\n        Similarly, changing the route for the autopilot works by simply selecting a different travel target.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('auto_pilot_3', 'png')+ ')',
        width: '480px',
        height: '446px',
        marginLeft: 'calc(50% - 240px)',
      })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }