import {gameStore} from "../store";
import {GetSpriteEquip} from "../unit/equip";
import {ClearBars} from "../interface/status_layer";
import {droneEngages} from "./create";

function removeDrone(drone, id) {

  if (!drone) drone = gameStore.drones[id];

  if (!drone) {
    return
  }

  if (drone.owner_object_type === "unit") {
    let equipSlot = GetSpriteEquip(drone.owner_object_id, drone.equip_type, drone.equip_slot)
    if (equipSlot && equipSlot.sprite) {
      equipSlot.sprite.setVisible(true);
      if (equipSlot.sprite.shadow) equipSlot.sprite.shadow.setVisible(true);
    }
  }

  if (drone.sprite.RadarMark) drone.sprite.RadarMark.destroy();
  drone.sprite.shadow.destroy();
  drone.sprite.destroy();

  ClearBars('drone', drone.id, 'hp');
  ClearBars('drone', drone.id, 'shield');
  ClearBars('drone', drone.id, 'energy');

  for (let k in drone.sprite.engages) {
    if (drone.sprite.engages[k].emitter) {
      drone.sprite.engages[k].emitter.stop();

      if (drone.sprite.engages[k].typeEngage === "1") {
        droneEngages.t1.push(drone.sprite.engages[k].emitter)
      }

      if (drone.sprite.engages[k].typeEngage === "2" || drone.sprite.engages[k].typeEngage === "3") {
        droneEngages.t2t3.push(drone.sprite.engages[k].emitter)
      }
    }
  }

  delete gameStore.drones[id];
}

export {removeDrone}
