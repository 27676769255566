import {gameStore} from "../store";
import {Scene} from "../create";
import {FocusMS} from "./focus_unit";
import {GetCircle} from "./server_gun_designator";

function GrabCamera(scene) {
  if (scene.game.input.activePointer.rightButtonDown()) {

    // gameStore.time.m++
    // if (gameStore.time.m > 20) {
    //   gameStore.time.m = 0
    //
    //   gameStore.time.h++
    //   if (gameStore.time.h >= 23) {
    //     gameStore.time.h = 0
    //   }
    //   console.log(gameStore.time.h)
    //   SetDayCycle(false)
    // }

    // ShakeCamera(5)

    scene.cameras.main.stopFollow();
    if (scene.game.origDragPoint) {
      // move the camera by the amount the mouse has moved since last update
      scene.cameras.main.scrollX +=
        scene.game.origDragPoint.x - scene.game.input.activePointer.position.x;
      scene.cameras.main.scrollY +=
        scene.game.origDragPoint.y - scene.game.input.activePointer.position.y;
    } // set new drag origin to current position
    scene.game.origDragPoint = scene.game.input.activePointer.position.clone();
  } else {
    scene.game.origDragPoint = null;
  }
}

function LazyCamera() {
  let u = gameStore.units[gameStore.playerUnitID]
  if (!u) {
    return
  }

  let c = GetCircle()
  if (!Scene.game.input.activePointer.camera || !c) {
    FocusMS(true);
    return;
  }

  if (Scene.game.input.activePointer.camera.scene.scene.key !== 'default') {
    return;
  }

  Scene.cameras.main.stopFollow();

  let p = Scene.game.input.activePointer
  if (p.moveTime === 0) {
    FocusMS(true);
    return;
  }

  // наводимся на точку между прицелом и камерой, причем так что бы в кадре был и прицел и камера.
  let dist = Phaser.Math.Distance.Between(p.worldX, p.worldY, u.sprite.x, u.sprite.y);
  // if (dist < 200) {
  //   Scene.cameras.main.startFollow(u.sprite);
  //   return;
  // } else {
  //   dist -= 200
  // }

  let angle = Phaser.Math.Angle.Between(u.sprite.x, u.sprite.y, p.worldX, p.worldY);
  let newPos = {x: u.sprite.x + (dist / 2.2) * Math.cos(angle), y: u.sprite.y + (dist / 2.2) * Math.sin(angle)}

  Scene.cameras.main.pan(newPos.x, newPos.y, 256, Phaser.Math.Easing.Linear, true)
}

export {GrabCamera, LazyCamera}
