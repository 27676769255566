import {GetGlobalPos} from "../gep_global_pos";
import {gameStore} from "../../store";
import {Scene} from "../../create";
import {rotatePoint} from "../../utils/utils";

function createCorporationBase(state, base, scene) {
  base.setDepth(1);
  let pos = GetGlobalPos(state.x, state.y, gameStore.map.id);

  let shadow = scene.make.image({
    x: pos.x + 5,
    y: pos.y + 5,
    key: 'structures',
    add: true,
    frame: state.texture + "_top",
  });
  shadow.setOrigin(0.5);
  shadow.setScale(state.scale / 100);
  shadow.setAlpha(state.shadow_intensity / 100);
  shadow.setTint(0x000000);
  shadow.setDepth(state.height - 1);
  shadow.setAngle(base.angle);

  let object = scene.make.image({
    x: pos.x,
    y: pos.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top",
  });
  object.setOrigin(0.5);
  object.setScale(state.scale / 100);
  object.setDepth(state.height - 1);
  object.setAngle(base.angle);

  base.top = object;
  base.shadowTop = shadow;

  let posTop1 = rotatePoint((-40 * state.scale / 100), 0, base.angle);

  base.light = Scene.add.pointlight(pos.x + posTop1.x, pos.y + posTop1.y, 0, 55, 0.15);
  base.light.color.setTo(0, 255, 255);
  base.light.setDepth(state.height + 1);

  let particles = Scene.add.particles(pos.x + posTop1.x, pos.y + posTop1.y, 'flares', {
    frame: 'smoke_puff',
    speed: {min: -5, max: 5},
    lifespan: {min: 3000, max: 6000},
    quantity: 7,
    frequency: 1000,
    scale: {start: 0, end: 2},
    angle: {min: -180, max: 180},
    alpha: {start: 0.4, end: 0},
    blendMode: 'SCREEN',
    moveToX: {min: 0, max: 0},
    moveToY: {min: 0, max: 0},
    emitZone: {type: 'random', source: new Phaser.Geom.Circle(0, 0, 5)}
  });
  particles.setDepth(state.height + 2);

  base.emitter = particles;

  let posTop2 = rotatePoint((33 * state.scale / 100), (5 * state.scale / 100), base.angle);
  let shadow2 = scene.make.image({
    x: pos.x + posTop2.x,
    y: pos.y + posTop2.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top_2",
  });
  shadow2.setOrigin(0.5);
  shadow2.setScale(state.scale / 100);
  shadow2.setAlpha(state.shadow_intensity / 100);
  shadow2.setTint(0x000000);
  shadow2.setDepth(state.height + 1);

  let posTop3 = rotatePoint((28 * state.scale / 100), 0, base.angle);
  let object2 = scene.make.image({
    x: pos.x + posTop3.x,
    y: pos.y + posTop3.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top_2",
  });
  object2.setOrigin(0.5);
  object2.setScale(state.scale / 100);
  object2.setDepth(state.height + 1);

  base.top2 = object2;
  base.shadowTop2 = shadow2;
}

function initTweenBase(sprite) {
  sprite.initTween = true;

  let speed = 20000
  let ease = 'Linear'
  ease = 'Back.easeInOut'

  setTimeout(function () {
    sprite.top2.tween = Scene.tweens.add({
      targets: sprite.top2,
      props: {
        angle: {value: '+=' + 180, duration: speed, ease: ease},
      },
      repeat: -1,
      yoyo: true
    });

    if (sprite.shadowTop2) {
      sprite.shadowTop2.tween = Scene.tweens.add({
        targets: sprite.shadowTop2,
        props: {
          angle: {value: '+=' + 180, duration: speed, ease: ease},
        },
        repeat: -1,
        yoyo: true
      });
    }
  }, 1000)
}

export {createCorporationBase, initTweenBase}
