<template>
  <div id="OpenBuildObject" ref="OpenBuildObject" @mousedown="toUp" v-if="object.object_id">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'OpenBuildObject'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 140, width: 350}"/>

    <div class="wrapper">
      <h3 class="head">{{ texts['text_8'][language] }}</h3>

      <input style="width: calc(100% - 8px);float: left;margin: 1px 1px 3px 5px;"
             @click="loadAll"
             type="button"
             :value="texts['button_1'][language]"
             class="button cursor_hover">

      <div class="resource_wrapper">
        <div class="resource_row" v-for="r in object.current_resources" v-if="r.need > 0">

          <div class="icon">
            <app-item-cell v-bind:item-slot="r.slot" v-bind:no-quantity="true"/>
          </div>

          <div class="progress_bar_wrapper">

            <div class="progress_bar_head">
              {{ r.slot.quantity }} / {{ r.need }}
            </div>

            <div class="progress_bar_outer">
              <div class="progress_bar_inner" :style="{width: (100 / (r.need / r.slot.quantity)) + '%'}"/>
            </div>

          </div>

          <div class="add_panel">

            <div class="detail_count">
              {{ getMaxCount(r.slot.item_id) }}
            </div>

            <div class="add_count_resource">
              <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                   @click="minusOne(r.slot.item_id)"
                   @mouseover="playSound('select_sound.mp3', 0.3)">
                -1
              </div>

              <input type="number"
                     class="input_number cursor_hover"
                     min="1"
                     :max="getMaxCount(r.slot.item_id)"
                     v-model="detail_count[r.slot.item_id]">

              <div class="range_button cursor_hover" style="float: right; margin-right: 5px;"
                   @click="setMax(r.slot.item_id)"
                   @mouseover="playSound('select_sound.mp3', 0.3)">
                m.
              </div>
            </div>

            <input type="button"
                   :value="texts['button_2'][language]"
                   @click="loadDetail(r.slot.item_id)"
                   class="button cursor_hover">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import ItemCell from "../Inventory/ItemCell";
import Vue from "vue";

export default {
  name: "OpenBuildObject",
  data() {
    return {
      detail_count: {}
    }
  },
  mounted() {
    // TODO обновление ресурсов когда туда кто то положил, лучше на бекенда просто кинуть сообщение всем кто в текущем секторе
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));
  },
  methods: {
    resize() {
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    setMax(detailID) {
      Vue.set(this.detail_count, detailID, this.getMaxCount(detailID));
    },
    minusOne(detailID) {

      let current = this.detail_count[detailID]
      current--
      if (current < 0) {
        current = 0
      }

      Vue.set(this.detail_count, detailID, current);
    },
    getMaxCount(detailID) {
      let inInventory = this.getCount(detailID)
      let current = this.object.current_resources[detailID]
      let need = current.need - current.slot.quantity

      let result = inInventory < need ? inInventory : need
      if (!this.detail_count.hasOwnProperty(detailID)) {
        Vue.set(this.detail_count, detailID, result);
      }

      return result
    },
    getCount(detailID) {
      if (!this.transportInventory) {
        return 0
      }

      let allCount = 0
      for (let i in this.transportInventory.inventory) {
        let slot = this.transportInventory.inventory[i];
        if (slot.type === 'detail' && slot.item_id === detailID) {
          allCount += slot.quantity
        }
      }

      return allCount
    },
    loadAll() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "LoadAllDetailsInBuild",
          structure_id: Number(this.object.object_id)
        }
      }));
    },
    loadDetail(detailID) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "LoadDetailInBuild",
          id: detailID,
          value: Number(this.detail_count[detailID]),
          structure_id: Number(this.object.object_id)
        }
      }));
    }
  },
  computed: {
    object() {
      return this.$store.getters.getOpenBuildObject
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    transportInventory() {
      let inv = this.inventory
      if (inv && inv.warehouses && inv.warehouses['squadInventory'] && inv.warehouses['squadInventory'].inventory) {
        return inv.warehouses['squadInventory']
      }

      return false
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('BuildMenu')
    },
  },
  components: {
    AppControl: Control,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
#OpenBuildObject {
  height: 140px;
  width: 350px;
  position: absolute;
  left: calc(50% - 100px);
  top: 40%;
  padding: 21px 3px 3px 3px;
  border-radius: 5px;
  z-index: 10;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.wrapper {
  background: rgba(137, 150, 156, 0.3);
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0 3px black;
  overflow-x: hidden;
  overflow-y: scroll;
}

.head {
  margin: 0 0 4px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.resource_wrapper {

}

.resource_row {
  float: left;
  width: calc(100% - 10px);
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  margin: 2px 2px 2px 5px;
  filter: drop-shadow(0 0 2px black);
  box-shadow: 0 0 1px black;
  border-radius: 3px;
}

.icon {
  float: left;
}

.progress_bar_wrapper {
  float: left;
  width: calc(100% - 220px);
  margin-top: 5px;
  position: relative;
}

.progress_bar_head {
  text-align: center;
  font-size: 12px;
  color: white;
}

.progress_bar_outer {
  height: 8px;
  border: 1px solid #a5a5b4;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 black;
  overflow: hidden;
  background: #404041;
  position: relative;
}

.progress_bar_inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: inset 0 0 2px 0 black;
  width: 50%;
  background-color: #0cc2fb;
}

.add_panel {
  float: right;
  width: 175px;
  position: relative;
}

.add_count_resource {
  text-align: left;
  width: 100px;
  float: left;
  margin-top: 15px;
}

.button {
  margin: 9px 3px 0 0;
  pointer-events: auto;
  font-size: 12px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 14px;
  box-shadow: 0 0 2px #000;
  width: 64px;
  user-select: none;
  float: right;
}

.button:active {
  transform: scale(.98);
}

.button:hover {
  background: #ff8100;
}

.input_number {
  float: left;
  margin-top: 0;
  color: #ff7800;
  width: 55px;
  height: 13px;
  text-indent: 1px;
  text-align: right;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  text-shadow: 1px 1px 1px black;
  border-radius: 0;
}

.range_button {
  width: 15px;
  height: 15px;
  float: left;
  font-size: 10px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number], input[type=number] {
  -moz-appearance: textfield;
}

.detail_count {
  color: white;
  opacity: 0.5;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  text-shadow: 1px 1px 1px black;
  width: 89px;
  text-align: right;
}
</style>
