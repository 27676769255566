import {Scene} from "../create";

let event = false

function ShakeCamera(intensity) {

  intensity = intensity / 10000

  if (event) {
    event.remove()
  }

  let timeTick = 128
  let count = 1
  let runCount = 20

  Scene.cameras.main.shake(timeTick, intensity, true);
  event = Scene.time.addEvent({
    delay: timeTick,
    repeat: runCount,
    callback: function () {
      Scene.cameras.main.shake(timeTick, intensity / count, true);
      count++
    },
    loop: false
  });
}

export {ShakeCamera}
