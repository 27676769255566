import {gameStore} from "../store";
import {Scene} from "../create";
import {ShortDirectionRotateTween} from "../utils/utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";

function AddBoxMoveBufferData(data) {
  let box = gameStore.boxes[data.id];
  if (box) {
    AddMoveBufferData(data, box)
  }
}

function BoxMove(box, ms) {

  let path = box.bufferMoveTick.shift();
  if (!path) {
    return;
  }

  let pos = GetGlobalPos(path.x, path.y, gameStore.map.id);
  path.x = pos.x;
  path.y = pos.y;

  MoveSprite(box.sprite, path.x, path.y, ms, null);
  if (box.sprite.shadow) MoveSprite(box.sprite.shadow, path.x + Scene.shadowXOffset / 5, path.y + Scene.shadowYOffset / 5, ms, null);

  ShortDirectionRotateTween(box.sprite, path.r, ms);
  if (box.sprite.shadow) ShortDirectionRotateTween(box.sprite.shadow, path.r, ms);
}

export {BoxMove, AddBoxMoveBufferData}
