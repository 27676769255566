<template>
  <div style="height: 100%">

    <div v-if="dialog.type !== ''">
      <div class="mask"/>

      <div class="wrapper">

        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper">
            <template v-if="!search_corp">
              <input type="text"
                     v-model="corp_name"
                     class="cursor_hover"
                     :placeholder="texts['placeholder_1'][language]"/>
              <input type="button"
                     :value="texts['button_25'][language]"
                     class="cluster_button cursor_hover"
                     style="width: 45%; float: right; position: unset"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="search">

              <div class="not_found" v-if="notFound">
                {{ texts['text_28'][language] }}
              </div>
            </template>

            <template v-if="search_corp && corporations[search_corp.corporation.id]">
              <app-corporation-line :corp="search_corp.corporation"
                                    :logo="corporations[search_corp.corporation.id].logo"
                                    :parent="'CorporationPolicy'"
                                    :buttonExit="true"
                                    :exitFunc="removeSearchCorp"/>
            </template>
          </div>
        </div>

        <input type="button"
               :value="texts['button_26'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialog.type = ''; removeSearchCorp();">

        <input type="button"
               :value="texts['button_27'][language]"
               class="cluster_button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               style="float: right; position: unset; width: 45%"
               :class="{disable: !search_corp}"
               @click="addContact(dialog.type)">
      </div>
    </div>

    <h3 class="head" @click="tab='global'" :class="{active: tab==='global'}">
      {{ texts['text_29'][language] }}
      <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'CorporationGlobalPolicy'})"></div>
    </h3>

    <h3 class="head" @click="tab='local'" :class="{active: tab==='local'}" style="margin-left: 3%">
      {{ texts['text_30'][language] }}
      <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'CorporationLocalPolicy'})"></div>
    </h3>

    <div class="wrapperPolicy" v-if="tab==='global'">

      <div class="setting_row">
        <div class="setting_head" style="width: 50%">Налог:</div>
        <div class="range">
          <div class="importantly">{{ corporation.info.tax }}%</div>
          <input @change="changeGlobalPolicy($event, 'tax')"
                 :value="corporation.info.tax" type="range" min="0" max="75" step="0">
        </div>
      </div>

      <div class="setting_row">
        <div class="setting_head" style="width: 50%">{{ texts['text_42'][language] }}</div>

        <select @change="changeGlobalPolicy($event, 'tax_deposit_id')"
                :value="corporation.info.tax_deposit_id" class="local_select settings cursor_hover">
          <option value="0" style="display: none">{{ mainDeposit.name }}</option>
          <option :value="d.id" v-for="d in deposits">{{ d.name }}</option>
        </select>
      </div>

      <div class="setting_row">
        <div class="setting_head">Огонь по своим:</div>
        <select @change="changeGlobalPolicy($event, 'friendly_fire')"
                :value="corporation.info.friendly_fire" class="local_select settings cursor_hover">
          <option value="true">да</option>
          <option value="false">нет</option>
        </select>
      </div>

      <h3 class="head2 cursor_hover" @click="union = !union">{{ union ? '▼' : '▶' }} {{
          texts['text_31'][language]
        }}</h3>
      <div class="policyTableWrap" v-if="union">

        <app-corporation-line v-for="c in contacts"
                              v-if="c.p === -200 && corporations.hasOwnProperty(c.id)"
                              :corp="corporations[c.id].corporation"
                              :logo="corporations[c.id].logo"
                              :parent="'CorporationPolicy'"
                              :buttonExit="true"
                              :arguments="['corporation', c.id]"
                              :exitFunc="removeContact"/>

        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="openDialog('union')"
             class="cluster_button cursor_hover"> {{ texts['button_28'][language] }}
        </div>
      </div>

      <h3 class="head2" @click="rival = !rival">{{ rival ? '▼' : '▶' }} {{ texts['text_32'][language] }}</h3>
      <div class="policyTableWrap" v-if="rival">

        <app-corporation-line v-for="c in contacts"
                              v-if="c.p === 200 && corporations.hasOwnProperty(c.id)"
                              :corp="corporations[c.id].corporation"
                              :logo="corporations[c.id].logo"
                              :parent="'CorporationPolicy'"
                              :buttonExit="true"
                              :arguments="['corporation', c.id]"
                              :exitFunc="removeContact"/>

        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="openDialog('rival')"
             class="cluster_button cursor_hover"> {{ texts['button_29'][language] }}
        </div>
      </div>
    </div>

    <div class="wrapperPolicy" v-if="tab==='local'">
      <select class="local_select" v-model="selectSectorID">
        <option value="0" disabled selected>{{ texts['text_33'][language] }}</option>
        <option v-for="s in sectors" :value="s.id">{{ s.name }}</option>
      </select>

      <div class="policyTableWrap local" v-if="selectSectorID > 0">

        <div class="setting_row">
          <div class="setting_head">{{ texts['text_34'][language] }}</div>
          <select @change="changeLocalPolicy($event, 'map_access')"
                  :value="localPolicy.map_access" class="local_select settings cursor_hover">
            <option value="all">{{ texts['option_1'][language] }}</option>
            <option value="except-hostiles">{{ texts['option_2'][language] }}</option>
            <option value="except-unions">{{ texts['option_3'][language] }}</option>
            <option value="except-rivals">{{ texts['option_4'][language] }}</option>
            <option value="only-unions">{{ texts['option_5'][language] }}</option>
            <option value="only-rivals">{{ texts['option_6'][language] }}</option>
            <option value="only-owner">{{ texts['option_7'][language] }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head">{{ texts['text_35'][language] }}</div>
          <select @change="changeLocalPolicy($event, 'base_access')"
                  :value="localPolicy.base_access" class="local_select settings cursor_hover">
            <option value="all">{{ texts['option_8'][language] }}</option>
            <option value="except-hostiles">{{ texts['option_9'][language] }}</option>
            <option value="except-unions">{{ texts['option_10'][language] }}</option>
            <option value="except-rivals">{{ texts['option_11'][language] }}</option>
            <option value="only-unions">{{ texts['option_12'][language] }}</option>
            <option value="only-rivals">{{ texts['option_13'][language] }}</option>
            <option value="only-owner">{{ texts['option_14'][language] }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_36'][language] }}</div>
          <select @change="changeLocalPolicy($event, 'turret_policy')"
                  :value="localPolicy.turret_policy" class="local_select settings cursor_hover">
            <option value="all">{{ texts['option_15'][language] }}</option>
            <option value="except-owner">{{ texts['option_16'][language] }}</option>
            <option value="except-unions">{{ texts['option_17'][language] }}</option>
            <option value="except-rivals">{{ texts['option_18'][language] }}</option>
            <option value="except-rivals-unions">{{ texts['option_19'][language] }}</option>
            <option value="only-hostile">{{ texts['option_20'][language] }}</option>
            <option value="disable">{{ texts['option_21'][language] }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_37'][language] }}</div>
          <select @change="changeLocalPolicy($event, 'repair_policy')"
                  :value="localPolicy.repair_policy" class="local_select settings cursor_hover">
            <option value="only-owner">{{ texts['option_22'][language] }}</option>
            <option value="unions">{{ texts['option_23'][language] }}</option>
            <option value="rivals">{{ texts['option_24'][language] }}</option>
            <option value="except-hostile">{{ texts['option_25'][language] }}</option>
            <option value="disable">{{ texts['option_26'][language] }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_38'][language] }}</div>
          <div class="range">
            <div class="importantly">{{ localPolicy.recycle_tax }}%</div>
            <input @change="changeLocalPolicy($event, 'recycle_tax')"
                   :value="localPolicy.recycle_tax" type="range" min="1" max="75" step="1">
          </div>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_39'][language] }}</div>
          <div class="range">
            <div class="importantly">{{ localPolicy.work_tax }}%</div>
            <input @change="changeLocalPolicy($event, 'work_tax')"
                   :value="localPolicy.work_tax" type="range" min="1" max="75" step="1">
          </div>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_40'][language] }}</div>
          <div class="range">
            <div class="importantly">{{ localPolicy.market_tax }}%</div>
            <input @change="changeLocalPolicy($event, 'market_tax')"
                   :value="localPolicy.market_tax" type="range" min="1" max="10" step="1">
          </div>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_41'][language] }}</div>

          <select @change="changeLocalPolicy($event, 'tax_stock_id')"
                  :value="localPolicy.tax_stock_id" :key="localPolicy.tax_stock_id"
                  class="local_select settings cursor_hover">
            <option value="-1">{{ texts['option_27'][language] }}</option>
            <option :value="s.id" v-for="s in stocks" :key="s.id">{{ s.name }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_42'][language] }}</div>

          <select @change="changeLocalPolicy($event, 'tax_deposit_id')"
                  :value="localPolicy.tax_deposit_id" class="local_select settings cursor_hover">
            <option value="-1">{{ texts['option_28'][language] }}</option>
            <option :value="d.id" v-for="d in deposits">{{ d.name }}</option>
          </select>
        </div>

        <div class="setting_row">
          <div class="setting_head" style="width: 50%">{{ texts['text_43'][language] }} ({{ timeZone }}):</div>

          <select :class="{disable: true}" :value="localPolicy.vulnerability_window + 1"
                  class="local_select settings clock">
            <option :value="i-1" v-for="i in 25" :key="i-1">{{ getHourByTimeZone(i - 1) }}:00</option>
          </select>
          <div class="splitter">
            -
          </div>
          <select @change="changeLocalPolicy($event, 'vulnerability_window')"
                  :value="localPolicy.vulnerability_window" class="local_select settings clock cursor_hover">
            <option :value="i-1" v-for="i in 24" :key="i-1">{{ getHourByTimeZone(i - 1) }}:00</option>
          </select>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {urls} from "../../const";
import CorporationLine from '../Corporation/CorporationLine';
import Vue from "vue";

export default {
  name: "Policy",
  data() {
    return {
      tab: 'global',
      union: false,
      rival: false,
      dialog: {
        type: '',
      },
      search_corp: null,
      corp_name: null,
      notFound: false,
      corporations: {},
      selectSectorID: 0,
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetLocalPolicy",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openDialog(type) {
      this.dialog.type = type
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    search() {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_name=' + app.corp_name).then(function (response) {
        app.$set(app, 'search_corp', response.data);
        if (!response.data) {
          app.notFound = true;
          setTimeout(function () {
            app.notFound = false;
          }, 1000)
        } else {
          app.$set(app.corporations, response.data.corporation.id, response.data);
          app.getCorpLogo(response.data.corporation.id);
        }
      });
    },
    getCorporationByID(id) {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        if (response.data) {
          app.$set(app.corporations, response.data.corporation.id, response.data);
          app.getCorpLogo(response.data.corporation.id);
        }
      })
    },
    getCorpLogo(id) {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.corporations[id], 'logo', response.data.avatar);
      });
    },
    removeSearchCorp() {
      Vue.set(this, 'search_corp', null);
    },
    removeContact(t, id) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "RemoveContact",
          id: id,
          data: t
        }
      }));
    },
    addContact(type) {
      let points = type === 'union' ? -200 : 200

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "AddContact",
          value: points,
          id: this.search_corp.corporation.id,
          data: "corporation"
        }
      }));

      this.dialog.type = ''
      this.$set(this, 'search_corp', null);
    },
    changeGlobalPolicy(e, parameter) {
      // сначала обновляем локально потом отправляем сообщение
      this.corporation.info[parameter] = e.target.value

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "ChangeGlobalPolicy",
          tag: parameter,
          data: e.target.value,
        }
      }));
    },
    changeLocalPolicy(e, parameter) {
      if (!this.selectSectorID || !this.localPolicy) return;
      // сначала обновляем локально потом отправляем сообщение
      this.localPolicy[parameter] = e.target.value

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "ChangeLocalPolicy",
          tag: parameter,
          data: e.target.value,
          id: this.localPolicy.map_id
        }
      }));
    },
    getSectorNameByID(id) {

      for (let i in this.handbook['sector'][this.language]) {
        if (this.handbook['sector'][this.language][i].id === id) {
          return this.handbook['sector'][this.language][i].name;
        }
      }

      return ''
    },
    getHourByTimeZone(utcHour) {
      let offset = new Date().getTimezoneOffset() / 60
      let timeZoneHour = utcHour + (offset * -1)
      if (timeZoneHour >= 24) timeZoneHour -= 24;
      return timeZoneHour
    }
  },
  computed: {
    handbook() {
      return this.$store.getters.getHandBook
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    stocks() {
      let offices = this.corporation.offices
      if (this.corporation && offices) {
        for (let i in offices) {
          if (offices[i].map_id === Number(this.selectSectorID)) {
            return offices[i].storages
          }
        }
      }

      return [];
    },
    deposits() {
      if (this.corporation && this.corporation.deposits) {
        return this.corporation.deposits.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return [];
    },
    mainDeposit() {
      for (let d of this.corporation.deposits) {
        if (d.main) return d
      }
    },
    localPolicy() {
      for (let i of this.corporation.localPolicy) {
        if (i.map_id === Number(this.selectSectorID)) {
          return i
        }
      }
    },
    contacts() {
      let contacts = this.corporation.contacts;

      for (let i in contacts) {
        if (contacts[i].t === 'corporation') {
          if (!this.corporation.hasOwnProperty(contacts[i].id)) {
            this.getCorporationByID(this.corporation.contacts[i].id)
          }
        }
      }

      return contacts
    },
    sectors() {
      let sectors = [];

      let bases = this.corporation.bases.bases;
      for (let i in bases) {
        if (bases[i].structure === 'base') {
          sectors.push({id: bases[i].map_id, name: this.getSectorNameByID(bases[i].map_id)})
        }
      }

      return sectors
    },
    timeZone() {
      let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
      return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) // + ":" + ("00" + (o % 60)).slice(-2);
    }
  },
  components: {
    AppCorporationLine: CorporationLine,
  }
}
</script>

<style scoped>
.wrapperPolicy {
  height: 100%;
}

.head {
  margin: 0 0 4px 0;
  background: rgba(182, 156, 127, 1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  width: 45%;
  float: left;
  text-align: center;
  position: relative;
}

.head.active {
  background: rgba(255, 129, 0, 0.8);
}

.head:hover {
  background: rgba(255, 129, 0, 1);
}

.head:active {
  transform: scale(0.98);
}

.policyTableWrap {
  width: calc(100% - 4px);
  margin: 2px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 130px;
  max-height: 300px;
}

.head2 {
  margin: 5px;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  float: left;
  width: calc(100% - 16px);
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  width: 250px;
  position: relative;
  left: calc(50% - 125px);
  user-select: none;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.wrapper {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 80px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
}

.text_wrapper {
  height: 49px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.wrapper input[type=text] {
  width: calc(100% - 10px);
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.not_found {
  height: 25px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(137, 150, 156, 0.7);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  text-shadow: 1px 1px 1px black;
}

.local_select {
  outline: none;
  width: calc(100% - 6px);
  border-radius: 5px;
  margin: 0 0 4px 0;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

.local_select option {
  color: black;
  font-weight: bold;
}

.settings {
  width: 45%;
  float: right;
  margin-right: 7px;
}

.setting_head {
  float: left;
  width: 40%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
  text-indent: 5px;
}

.setting_row {
  border-bottom: 1px solid #7b7b7b;
  padding: 4px 2px 0;
  float: left;
  width: 100%;
  line-height: 24px;
  height: 24px;
}

.policyTableWrap.local {
  min-height: unset;
  max-height: unset;
  height: calc(100% - 50px);
}

.range {
  height: 100%;
  width: 45%;
  line-height: 5px;
  float: right;
}

.range input[type=range] {
  -webkit-appearance: none;
  width: calc(100% - 50px);
  height: 8px;
  box-shadow: none;
  margin-top: 7px;
  float: right;
  margin-right: 9px;
}

.range input[type=range]:focus {
  outline: none;
}

.range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 6px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
  position: relative;
  top: -1px
}

.range input[type=range]:focus::-webkit-slider-runnable-track {
}

.range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.importantly {
  height: 100%;
  line-height: 22px;
  float: left;
  font-size: 16px;
  margin-left: -10px;
}

.clock {
  width: 18%;
}

.disable {
  background: rgba(127, 127, 127, 0.5) !important;
}

.splitter {
  float: right;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
  margin: 0 12px 0 4px;
  line-height: 21px;
}

.splitter .zone {
  height: 100%;
  line-height: 22px;
  font-size: 13px;
  margin-right: 6px;
}

.help_point_menu {
  height: 13px;
  width: 13px;
  /* background: black; */
  position: absolute;
  right: 3px;
  bottom: 2px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}
</style>
