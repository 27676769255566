import { render, staticRenderFns } from "./Variants.vue?vue&type=template&id=61ac3fc7&scoped=true"
import script from "./Variants.vue?vue&type=script&lang=js"
export * from "./Variants.vue?vue&type=script&lang=js"
import style0 from "./Variants.vue?vue&type=style&index=0&id=61ac3fc7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ac3fc7",
  null
  
)

export default component.exports