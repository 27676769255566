var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Защитники")]),_vm._v(" "),_c('p',[_vm._v("Защитники - они же - стражи; могучие дроны, что охраняют порядок в “безопасных секторах” фракций. Подобно\n      телепортам, эти роботы очередные артефакты предтеч, чьи технология воспроизводства утеряны, а сами они,\n      поистине,\n      практически неуязвимы.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('defenders_1', 'png')+ ')',
      width: '420px',
      height: '150px',
      marginLeft: 'calc(50% - 210px)',
      })}),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Основная огневая мощь защитников - крио-манипулятор, с помощью которых, робот замораживает нарушителей и\n        игроков, находящихся в статусе ПВП. Помимо очевидной функции по пресечению правонарушений, защитники также\n        выполняют роль спасателей - эвакуируя корпус синтета на ближайшую базу. ")]),_vm._v(" "),_c('p',[_vm._v("Защитники не способны действовать в секторах вне своей юрисдикции патрулирования, а потому, данных\n        роботов никогда нельзя наблюдать в небе над зоной боевых действий. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('guard_1', 'gif')+ ')',
          width: '320px',
          height: '185px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('defender2', 'gif')+ ')',
          width: '320px',
          height: '185px',
          marginLeft: 'calc(50% - 160px)',
          })})]),_vm._v(" "),_vm._m(0)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Defenders")]),_vm._v(" "),_c('p',[_vm._v("Defenders, also known as guardians; mighty drones that maintain order in the \"safe sectors\" of factions. Like\n      teleporters, these robots are another artifact of the precursors, whose reproduction technology has been lost,\n      and they themselves,\n      truly,\n      are virtually invulnerable.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('defenders_1', 'png')+ ')',
      width: '420px',
      height: '150px',
      marginLeft: 'calc(50% - 210px)',
      })}),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("The main firepower of the defenders is the cryo-manipulator, with which the robot freezes violators and\n        players in PVP status. In addition to the obvious function of suppressing violations, defenders also\n        act as rescuers - evacuating the synthetic body to the nearest base. ")]),_vm._v(" "),_c('p',[_vm._v("Defenders are incapable of operating in sectors outside their patrol jurisdiction, and therefore, these\n        robots can never be observed in the sky above the combat zone. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('guard_1', 'gif')+ ')',
          width: '320px',
          height: '185px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('defender2', 'gif')+ ')',
          width: '320px',
          height: '185px',
          marginLeft: 'calc(50% - 160px)',
          })})]),_vm._v(" "),_vm._m(1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Защитники - это уникальные и полезные союзники, которые помогают синтетам жить в мире и безопасности. Они\n        также\n        являются свидетелями древней истории планеты, которую они хранят в своих памяти.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Defenders are unique and useful allies that help synthetics live in peace and safety. They are also\n        witnesses to the ancient history of the planet, which they keep in their memory.")])])
}]

export { render, staticRenderFns }