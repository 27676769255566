var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Снаряжение")]),_vm._v(" "),_c('p',[_vm._v("В любой корпус можно установить самое различное снаряжение, которое будет делиться по своему функционалу,\n        требуемой мощности и соответствующему слоту. ")]),_vm._v(" "),_c('p',[_vm._v("Снаряжение делится на:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Некоторому снаряжения необходимо устанавливается снаружи корпуса, ячейки таких слотов помечены желтым\n        цветом. Преимущественно это снаряжения III типа.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('equip_1', 'png')+ ')',
          width: '480px',
          height: '110px',
          marginLeft: 'calc(50% - 240px)',
          })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_vm._v("\n      У разного снаряжения могут быть совершенно разные характеристики, поэтому что оно делает и сколько потребляет\n      ресурсов надо смотреть на карточке снаряжения.\n\n      "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('wiki_equip_1', 'png')+ ')',
          width: '400px',
          height: '364px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Equipment")]),_vm._v(" "),_c('p',[_vm._v("Various equipment can be installed in any hull, which will be divided by its functionality,\n        required power, and corresponding slot. ")]),_vm._v(" "),_c('p',[_vm._v("Equipment is divided into:")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Some equipment needs to be installed outside the hull, the cells of such slots are marked in yellow.\n        Mostly this is Type III equipment.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('equip_1', 'png')+ ')',
          width: '480px',
          height: '110px',
          marginLeft: 'calc(50% - 240px)',
          })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_vm._v("\n      Different equipment can have completely different characteristics, so what it does and how much it consumes\n      resources must be looked at on the equipment card.\n\n      "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('wiki_equip_1', 'png')+ ')',
          width: '400px',
          height: '364px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Пассивное")]),_vm._v(" - не потребляет энергию накопителя и работает самостоятельно.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Активируемое")]),_vm._v(" - не требует действий кроме активации,\n          потребляет энергию за цикл или за действие.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Активируемое с целью")]),_vm._v(" - например бросок\n          гранаты произойдет туда где находится указатель мышки.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Активное")]),_vm._v(" - выбирается и используется как оружие.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Passive")]),_vm._v(" - does not consume accumulator energy and operates independently.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Activatable")]),_vm._v(" - requires no action other than activation,\n          consumes energy per cycle or per action.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Targeted Activatable")]),_vm._v(" - for example, a grenade throw\n          will occur where the mouse pointer is located.\n        ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Active")]),_vm._v(" - selected and used as a weapon.\n        ")])])
}]

export { render, staticRenderFns }