<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Добыча нефти</h2>

      <p>Особый вид ресурса, добываемый посредством использования “вакуумного насоса” на месторождении. Месторождения
        нефти, как правило располагаются на территориях свободных секторов. </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('oil_1', 'gif')+ ')',
            width: '300px',
            height: '185px',
            marginLeft: 'calc(50% - 150px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Oil Extraction</h2>

      <p>Oil is a special type of resource extracted using a “vacuum pump” at oil fields. Oil fields are typically
        located in free sectors.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('oil_1', 'gif')+ ')',
            width: '300px',
            height: '185px',
            marginLeft: 'calc(50% - 150px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Oil",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
