import {minimap} from "./mini_map";
import {gameStore} from "../store";
import {statusBarWrapper} from "./status_bar";
import {Scene} from "../create";
import {SetDayCycle} from "../day_cycle/day_cycle";

let state = {
  oldString: "",
  sprite: null,
  back: null,
  offsetX: -78,
  offsetY: 20,
  textureWrapper: null,
}

function SetGameTime(h, m) {
  if (!minimap.backRenderTexture || !gameStore.gameReady || !statusBarWrapper.scene) return;

  if (gameStore.time.h !== h || !gameStore.time.init) {
    gameStore.time.h = h
    SetDayCycle(!gameStore.time.init)
    gameStore.time.init = true
  }

  gameStore.time.m = m

  if (h < 10) {
    h = "0" + h
  }

  if (m < 10) {
    m = "0" + m
  }

  let str = h + ":" + m
  if (str === state.oldString) return;
  state.oldString = str

  if (state.sprite) state.sprite.destroy();
  if (!state.back) {

    state.back = statusBarWrapper.scene.add.renderTexture(
      Scene.cameras.main.width + state.offsetX - 4,
      minimap.size + state.offsetY,
      40,
      15);
    state.back.setOrigin(0)
    let back = statusBarWrapper.scene.add.graphics({
      x: 0, y: 0, add: true,
    });

    back.fillStyle(0x000000, 0.5);
    back.fillRect(0, 0, 50, 20);

    state.back.draw(back);
    back.destroy();
  } else {
    state.back.setPosition(
      Scene.cameras.main.width + state.offsetX - 4,
      minimap.size + state.offsetY,
    )
  }

  if (!state.textureWrapper) {
    state.textureWrapper = statusBarWrapper.scene.make.image({
      x: Scene.cameras.main.width + state.offsetX - 10,
      y: minimap.size + state.offsetY - 1,
      key: "time_bracket",
      add: true
    });
    state.textureWrapper.setOrigin(0)
    state.textureWrapper.setScale(0.3);
  } else {
    state.textureWrapper.setPosition(
      Scene.cameras.main.width + state.offsetX - 10,
      minimap.size + state.offsetY - 1,
    )
  }

  state.sprite = statusBarWrapper.scene.add.bitmapText(
    Scene.cameras.main.width + state.offsetX + 3,
    minimap.size + state.offsetY + 3,
    'bit_text', str, 13);
  state.sprite.setLetterSpacing(1)
  state.sprite.setTint(0xcccccc)
  state.sprite.setOrigin(0)
}

export {SetGameTime}
