import Vue from "vue";
import VueRouter from 'vue-router'
import Login from '../components/Auth/Login'
import Registration from '../components/Auth/Registration'
import VkPopup from '../components/Auth/VkPopup'
import Lobby from '../components/Lobby/Lobby'
import Global from '../components/Global/Global'
import Transfer from '../components/Transfer/Transfer'
import Gate from '../components/Gate/Gate'
import MapEditor from '../components/Editors/MapEditor'
import DialogsEditor from '../components/Editors/DialogsEditor'
import MissionEditor from '../components/Editors/MissionEditor'

import ServerState from '../components/ServerState/ServerState'
import Timers from '../components/ServerState/Timers'
import BotMonitor from '../components/ServerState/BotMonitor'

import ChoiceFraction from '../components/ChoiceFraction/ChoiceFraction'

import Index from '../components/Index/Index'
import Main from '../components/Index/Main'

import Wiki from '../components/Index/Wiki/Wiki'
import ContentList from '../components/Index/Wiki/ContentList'
import Bodies from '../components/Index/Wiki/WikiPages/Bodies'
import Weapons from '../components/Index/Wiki/WikiPages/Weapons'
import Equips from '../components/Index/Wiki/WikiPages/Equips'
import Sector from '../components/Index/Wiki/WikiPages/Sector'
import Anomaly from '../components/Index/Wiki/WikiPages/Anomaly'
import Mining from '../components/Index/Wiki/WikiPages/Mining'
import PartsProduction from '../components/Index/Wiki/WikiPages/PartsProduction'
import Law from '../components/Index/Wiki/WikiPages/Law'
import Processing from '../components/Index/Wiki/WikiPages/Processing'
import Defenders from '../components/Index/Wiki/WikiPages/Defenders'
import Base from '../components/Index/Wiki/WikiPages/Base'
import FractionWar from '../components/Index/Wiki/WikiPages/FractionWar'
import AutoPilot from '../components/Index/Wiki/WikiPages/AutoPilot'
import Expedition from '../components/Index/Wiki/WikiPages/Expedition'
import MapItems from '../components/Index/Wiki/WikiPages/MapItems'
import Teleports from '../components/Index/Wiki/WikiPages/Teleports'
import Missions from '../components/Index/Wiki/WikiPages/Missions'
import Hangar from '../components/Index/Wiki/WikiPages/Hangar'
import Market from '../components/Index/Wiki/WikiPages/Market'
import Storage from '../components/Index/Wiki/WikiPages/Storage'
import SellBaseResource from '../components/Index/Wiki/WikiPages/SellBaseResource'
import Meteorites from '../components/Index/Wiki/WikiPages/Meteorites'
import Salvage from '../components/Index/Wiki/WikiPages/Salvage'
import FractionMarket from '../components/Index/Wiki/WikiPages/FractionMarket'
import Boxes from '../components/Index/Wiki/WikiPages/Boxes'
import Blueprints from '../components/Index/Wiki/WikiPages/Blueprints'
import CraftItems from '../components/Index/Wiki/WikiPages/CraftItems'
import StructureTypes from '../components/Index/Wiki/WikiPages/StructureTypes'
import SpecialCell from '../components/Index/Wiki/WikiPages/SpecialCell'
import Scouting from '../components/Index/Wiki/WikiPages/Scouting'
import Control from '../components/Index/Wiki/WikiPages/Control'
import Ballistic from '../components/Index/Wiki/WikiPages/Ballistic'
import Structures from '../components/Index/Wiki/WikiPages/Structures'
import NPC from '../components/Index/Wiki/WikiPages/Npc'
import AOP from '../components/Index/Wiki/WikiPages/Aop'
import Reverses from '../components/Index/Wiki/WikiPages/Reverses'
import Explores from '../components/Index/Wiki/WikiPages/Explores'
import Replics from '../components/Index/Wiki/WikiPages/Replics'
import Fine from '../components/Index/Wiki/WikiPages/Fine'
import Bonus from '../components/Index/Wiki/WikiPages/Bonus'
import Clusters from '../components/Index/Wiki/WikiPages/Clusters'
import Squad from '../components/Index/Wiki/WikiPages/Squad'
import Chat from '../components/Index/Wiki/WikiPages/Chat'
import Friends from '../components/Index/Wiki/WikiPages/Friends'
import Oil from '../components/Index/Wiki/WikiPages/Oil'
import Relations from '../components/Index/Wiki/WikiPages/Relations'
import EquipPanel from "../components/Index/Wiki/WikiPages/EquipPanel";
import Skills from "../components/Index/Wiki/WikiPages/Skills";
import CaptureSector from "../components/Index/Wiki/WikiPages/CaptureSector";
import Militia from "../components/Index/Wiki/WikiPages/Militia";
import Synthet from "../components/Index/Wiki/WikiPages/Synthet";
import Planet from "../components/Index/Wiki/WikiPages/Planet";
import ClusterDeposit from "../components/Index/Wiki/WikiPages/ClusterDeposit";
import ClusterOffice from "../components/Index/Wiki/WikiPages/ClusterOffice";
import CorporationBuild from "../components/Index/Wiki/WikiPages/CorporationBuild";
import CorporationSiege from "../components/Index/Wiki/WikiPages/CorporationSiege";
import CorporationLocalPolicy from "../components/Index/Wiki/WikiPages/CorporationLocalPolicy";
import CorporationGlobalPolicy from "../components/Index/Wiki/WikiPages/CorporationGlobalPolicy";
import CorporationWars from "../components/Index/Wiki/WikiPages/CorporationWars";
import Police from "../components/Index/Wiki/WikiPages/Police";
import PermanentDamage from "../components/Index/Wiki/WikiPages/PermanentDamage";
import TimesOfDay from "../components/Index/Wiki/WikiPages/TimesOfDay";
import GunRotationAngles from "../components/Index/Wiki/WikiPages/GunRotationAngles";
import Fuel from "../components/Index/Wiki/WikiPages/Fuel";
import Sync from "../components/Index/Wiki/WikiPages/Sync";
import Mods from "../components/Index/Wiki/WikiPages/Mods";
import Damage from "../components/Index/Wiki/WikiPages/Damage";
import Organic from "../components/Index/Wiki/WikiPages/Organic";
import Pellet from "../components/Index/Wiki/WikiPages/Pellet";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/', component: Index, meta: {title: ""},
      children: [
        {path: '', component: Main},
        {
          path: 'wiki', component: Wiki,
          children: [
            {path: '', component: ContentList},
            {path: 'bodies', component: Bodies},
            {path: 'weapons', component: Weapons},
            {path: 'equips', component: Equips},
            {path: 'sector', component: Sector},
            {path: 'anomaly', component: Anomaly},
            {path: 'mining', component: Mining},
            {path: 'parts_production', component: PartsProduction},
            {path: 'law', component: Law},
            {path: 'processing', component: Processing},
            {path: 'defenders', component: Defenders},
            {path: 'base', component: Base},
            {path: 'fraction_war', component: FractionWar},
            {path: 'auto_pilot', component: AutoPilot},
            {path: 'expedition', component: Expedition},
            {path: 'map_items', component: MapItems},
            {path: 'teleports', component: Teleports},
            {path: 'missions', component: Missions},
            {path: 'hangar', component: Hangar},
            {path: 'market', component: Market},
            {path: 'storage', component: Storage},
            {path: 'sell_base_resource', component: SellBaseResource},
            {path: 'meteorites', component: Meteorites},
            {path: 'salvage', component: Salvage},
            {path: 'fraction_market', component: FractionMarket},
            {path: 'boxes', component: Boxes},
            {path: 'blueprints', component: Blueprints},
            {path: 'craft_items', component: CraftItems},
            {path: 'structure_types', component: StructureTypes},
            {path: 'special_cell', component: SpecialCell},
            {path: 'scouting', component: Scouting},
            {path: 'control', component: Control},
            {path: 'ballistic', component: Ballistic},
            {path: 'structures', component: Structures},
            {path: 'npc', component: NPC},
            {path: 'aop', component: AOP},
            {path: 'reverses', component: Reverses},
            {path: 'explores', component: Explores},
            {path: 'replics', component: Replics},
            {path: 'bonus', component: Bonus},
            {path: 'fine', component: Fine},
            {path: 'clusters', component: Clusters},
            {path: 'squad', component: Squad},
            {path: 'chat', component: Chat},
            {path: 'friends', component: Friends},
            {path: 'oil', component: Oil},
            {path: 'relations', component: Relations},
            {path: 'equip_panel', component: EquipPanel},
            {path: 'skills', component: Skills},
            {path: 'capture_sector', component: CaptureSector},
            {path: 'militia', component: Militia},
            {path: 'synthet', component: Synthet},
            {path: 'planet', component: Planet},
            {path: 'cluster_deposit', component: ClusterDeposit},
            {path: 'cluster_office', component: ClusterOffice},
            {path: 'corporation_build', component: CorporationBuild},
            {path: 'corporation_siege', component: CorporationSiege},
            {path: 'corporation_local_policy', component: CorporationLocalPolicy},
            {path: 'corporation_global_policy', component: CorporationGlobalPolicy},
            {path: 'corporation_wars', component: CorporationWars},
            {path: 'police', component: Police},
            {path: 'permanent_damage', component: PermanentDamage},
            {path: 'time_of_day', component: TimesOfDay},
            {path: 'gun_rotation_angles', component: GunRotationAngles},
            {path: 'fuel', component: Fuel},
            {path: 'sync', component: Sync},
            {path: 'mods', component: Mods},
            {path: 'damage', component: Damage},
            {path: 'organic', component: Organic},
            {path: 'pellet', component: Pellet},
          ]
        },
      ]
    },
    {path: '/login', component: Login, meta: {title: ""}},
    {path: '/registration', component: Registration, meta: {title: ""}},
    {path: '/lobby', component: Lobby, meta: {title: ""}},
    {path: '/global', component: Global, meta: {title: ""}},
    {path: '/transfer', component: Transfer, meta: {title: ""}},
    {path: '/choice_fraction', component: ChoiceFraction, meta: {title: ""}},
    {path: '/gate', component: Gate, meta: {title: ""}},
    {path: '/map_editor', component: MapEditor, meta: {title: ""}},
    {path: '/dialog_editor', component: DialogsEditor, meta: {title: ""}},
    {path: '/mission_editor', component: MissionEditor, meta: {title: ""}},
    {path: '/server_state', component: ServerState, meta: {title: ""}},
    {path: '/timers', component: Timers, meta: {title: ""}},
    {path: '/ai_state', component: BotMonitor, meta: {title: ""}},
    {path: '/vk-oauth', component: VkPopup, meta: {title: ""}},
    {
      path: '*', component: Index, meta: {title: ""},
      children: [
        {path: '', component: Main},
      ]
    },
  ]
});

export default router
