<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h3 class="head" id="weapons">Углы оружия</h3>
      <p>Этот функционал пока не доступен игрокам, однако он есть и работает у ботов.</p>

      <p>У слотов под оружия на корпусе и у самого оружия есть параметр "допустимый угл", это значит что оружие может
        повернутся на этот угол относительно "центра" слота. </p>
      <p>Если угол указан и у слота и у оружия то берется меньший, то есть если оружие может поворачиваться на 45
        градусов а слот на 15, то в итоге можно будет повернуть только на 15.</p>
      <p>Центр слота - это куда смотрит слот относительно корпуса.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('gun_rotation_angles_1', 'gif')+ ')',
            width: '320px',
            height: '278px',
            marginLeft: 'calc(50% - 160px)',
            }"/>

      <p>TODO скриншоты из карточек итема, когда там начнется отображатся этот параметр</p>
    </template>

    <template v-if="lang==='EN'">
      <h3 class="head" id="weapons">Weapon Angles</h3>
      <p>This feature is not yet available to players, but it exists and works for bots.</p>

      <p>There is a "permissible angle" parameter for the weapon slots on the hull and for the weapon itself, which means that the weapon can
        rotate to this angle relative to the "center" of the slot.</p>
      <p>If the angle is specified for both the slot and the weapon, the smaller one is taken, meaning if the weapon can rotate 45
        degrees and the slot 15, then in the end, it can only be turned 15 degrees.</p>
      <p>The center of the slot is where the slot faces relative to the hull.</p>

      <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('gun_rotation_angles_1', 'gif')+ ')',
          width: '320px',
          height: '278px',
          marginLeft: 'calc(50% - 160px)',
          }"></div>

      <p>TODO screenshots from the item cards, when this parameter starts to be displayed there.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "GunRotationAngles",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
