import {Scene} from "../create";
import {gameStore} from "../store";
import {GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {deleteBullet} from "./remove";
import {SetDayCycleBullet} from "../day_cycle/day_cycle";
import {addIgnoreObject} from "../interface/mini_map";

function CreateBullet(data, infoBullet) {

  if (!gameStore.radarWork) return;

  if (['unknown_civilization_9', 'unknown_civilization_10', 'resource_9', 'resource_10'].includes(infoBullet.name)) {
    return
  }

  let bullet = {bufferMoveTick: []};
  let shadowBullet = Scene.make.image({
    x: GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id).x,
    y: GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id).y,
    key: 'equips',
    add: true,
    frame: infoBullet.name,
  });

  shadowBullet.setOrigin(0.5);
  shadowBullet.setScale(GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05).x);
  shadowBullet.setAngle(data.r);
  shadowBullet.setAlpha(0.3);
  shadowBullet.setTint(0x000000);
  addIgnoreObject(shadowBullet);

  bullet.sprite = Scene.make.image({
    x: data.x,
    y: data.y,
    key: 'equips',
    add: true,
    frame: infoBullet.name,
  });
  bullet.sprite.setOrigin(0.5);
  bullet.sprite.setScale(GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05).x);
  bullet.sprite.setAngle(data.r);
  addIgnoreObject(bullet.sprite);

  bullet.shadow = shadowBullet;
  gameStore.bullets[data.id] = bullet;

  let depth = 399;
  if (infoBullet.name === "nuke_missile") {
    depth = 401
  }

  if (infoBullet.type === 'mine') {
    depth = 10
  }

  // console.log(bullet.sprite)
  // console.log(data)

  bullet.sprite.setDepth(depth);
  shadowBullet.setDepth(depth - 1);

  createFairy(data, infoBullet, bullet)
  createPing(bullet, data.id)
  SetDayCycleBullet(bullet)

  addIgnoreObject(bullet.sprite)
  addIgnoreObject(bullet.shadow)

  return bullet
}

function createFairy(data, infoBullet, bullet) {
  if (infoBullet.type === "missile") {
    bullet.fairy = Fairy(data, 128, 'yellow', {min: 0, max: 200}, {start: 0.25, end: 0}, 1, {
      start: 1, end: 0
    })
  }

  if (infoBullet.type === "firearms") {

    let scale = {start: 0.15, end: 0};
    let quantity = 1;
    let alpha = {start: 1, end: 0};
    let speed = {min: 100, max: 200};

    let frame = 'white';
    if (infoBullet.name === 'fauna_drone_ammo_1') {
      frame = 'green'
    }

    if (infoBullet.name === "piu-piu_2" || infoBullet.name === "piu-piu_3" || infoBullet.name === "piu-piu_4") {
      scale = {start: 0.075, end: 0.02};
      quantity = 1;
      alpha = {start: 0.5, end: 0};
      speed = {min: 25, max: 75};
    }

    bullet.fairy = Fairy(data, 500, frame, speed, scale, quantity, alpha)
  }
}

let fairyStore = [];

function clearFairyStore() {
  fairyStore = []
}

function Fairy(data, lifespan, frame, speed, scale, quantity, alpha) {

  let rocketFairy
  if (fairyStore.length > 0 && fairyStore[0].getAliveParticleCount() === 0) {

    rocketFairy = fairyStore.shift()
    rocketFairy.x = data.x;
    rocketFairy.y = data.y;
    rocketFairy.setEmitterFrame(frame);
    // rocketFairy.setParticleLifespan(lifespan);
    rocketFairy.ops.lifespan.current = lifespan
    rocketFairy.ops.lifespan.propertyValue = lifespan
    //rocketFairy.emitter.setSpeed(speed)
    rocketFairy.ops.speedX.start = speed.min;
    rocketFairy.ops.speedX.end = speed.max;
    rocketFairy.ops.speedY.start = speed.min;
    rocketFairy.ops.speedY.end = speed.max;

    //rocketFairy.emitter.setAngle({min: 180 + data.r, max: 180 + data.r})
    rocketFairy.ops.angle.start = 180 + data.r;
    rocketFairy.ops.angle.end = 180 + data.r;

    //rocketFairy.emitter.setScale(scale)
    rocketFairy.ops.scaleX.start = scale.start;
    rocketFairy.ops.scaleX.end = scale.end;

    //rocketFairy.setParticleAlpha(alpha);
    rocketFairy.ops.alpha.start = alpha.start;
    rocketFairy.ops.alpha.end = alpha.end;

    rocketFairy.setVisible(true);
    rocketFairy.setQuantity(quantity);
    rocketFairy.start();
  } else {
    rocketFairy = Scene.add.particles(data.x, data.y, 'flares', {
      frame: frame,
      x: 0,
      y: 0,
      lifespan: lifespan,
      speed: speed,
      angle: {min: 180 + data.r, max: 180 + data.r},
      gravityY: 0,
      scale: scale,
      quantity: quantity,
      blendMode: 'SCREEN',
      alpha: alpha,
      name: 'bullet',
      frequency: 32,
    });

    rocketFairy.name = 'bullet';
    rocketFairy.setDepth(400);
    addIgnoreObject(rocketFairy);
  }

  return rocketFairy
}

function createPing(bullet, id) {
  let allTime = 512
  bullet.time_out = allTime;
  bullet.ping = Scene.time.addEvent({
    delay: allTime / 2,
    callback: function () {
      bullet.time_out -= allTime / 2
      if (bullet.time_out <= 0) {
        console.log("remove bullet timeout")
        bullet.ping.remove()
        deleteBullet(id)
      }
    },
    loop: true,
  });
}

function GetCacheBulletSprite(data, infoBullet) {

  if (gameStore.cacheSpriteBullets.hasOwnProperty(infoBullet.name) && gameStore.cacheSpriteBullets[infoBullet.name].length > 0) {

    let bullet = gameStore.cacheSpriteBullets[infoBullet.name].shift();

    let bulletSize = GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05).x

    bullet.sprite.x = data.x;
    bullet.sprite.y = data.y;
    bullet.sprite.setScale(bulletSize);
    bullet.sprite.setAngle(data.r);

    if (bullet.shadow) {
      let shadowPos = GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id)
      bullet.shadow.x = shadowPos.x;
      bullet.shadow.y = shadowPos.y;
      bullet.shadow.setScale(bulletSize);
      bullet.shadow.setAngle(data.r);
    }

    if (bullet.fairy) {
      createFairy(data, infoBullet, bullet)
    }

    createPing(bullet, data.id)
    bullet.bufferMoveTick = [];
    gameStore.bullets[data.id] = bullet;

    uncoverBullet(bullet);
    return bullet;
  } else {
    return CreateBullet(data, infoBullet)
  }
}

function uncoverBullet(bullet) {
  if (bullet.debugText) {
    bullet.debugText.setVisible(true);
  }

  if (bullet.shadow) {
    bullet.shadow.setVisible(true);
  }

  if (bullet.fairy) {
    bullet.fairy.setVisible(true);
  }

  if (bullet.sprite.RadarMark) {
    bullet.sprite.RadarMark.setVisible(true);
  }

  SetDayCycleBullet(bullet)
  bullet.sprite.setVisible(true);
}

export {CreateBullet, GetCacheBulletSprite, clearFairyStore, fairyStore}
