<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Бонусы корпусов</h2>

      <p>Некоторые корпуса могут иметь дополнительные бонусы если заработаны необходимые навыки.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_3', 'png')+ ')',
            width: '350px',
            height: '572px',
            marginLeft: 'calc(50% - 175px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Chassis Bonuses</h2>

      <p>Some chassis may have additional bonuses if the necessary skills are earned.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_3', 'png')+ ')',
            width: '350px',
            height: '572px',
            marginLeft: 'calc(50% - 175px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Bonus",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
