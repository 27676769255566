var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Ящики")]),_vm._v(" "),_c('p',[_vm._v("На карте вы можете устанавливать ящики, которые служат для хранения предметов. Ящики имеют разную прочность,\n      вместимость и тип. Вы можете выбрать ящик в зависимости от ваших целей и предпочтений. Вот три основных типа\n      ящиков:")]),_vm._v(" "),_c('ul',[_c('li',[_c('p',[_vm._v("Обычный контейнер - это самый простой и дешевый ящик, который не имеет никаких особых свойств. Он может\n          быть\n          легко поврежден или украден другими синтетами. Но зато его можно толкать и перемещать по карте, если вам\n          нужно\n          изменить место хранения.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_1', 'gif')+ ')',
          width: '250px',
          height: '200px',
          marginLeft: 'calc(50% - 125px)',
          })})]),_vm._v(" "),_c('li',[_c('p',[_vm._v("Подземный ящик - более дорогая вариация “стандартного контейнера”, чья единственная особенность -\n          возможность\n          спрятать ящик под землю. Тем самым, скрыв его от визуального обнаружения. Обнаружить его можно с помощью\n          \"сканера аномалий\" или владелец/группа. (не релиз.)")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_2', 'gif')+ ')',
          width: '400px',
          height: '200px',
          marginLeft: 'calc(50% - 200px)',
          })})]),_vm._v(" "),_c('li',[_c('p',[_vm._v("Ящик защищенный паролем - это самый продвинутый и дорогой ящик, который имеет электронный замок. Он может\n          быть открыт только игроками, которые знают пароль, который вы задаете при установке ящика. Он также имеет\n          большую вместимость и прочность, чем другие ящики. Так же защищен от визуального обнаружения.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_3', 'gif')+ ')',
          width: '320px',
          height: '200px',
          marginLeft: 'calc(50% - 160px)',
          })})])]),_vm._v(" "),_c('p',[_vm._v("Если ящик был уничтожен то все его содержимое теряется.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Boxes")]),_vm._v(" "),_c('p',[_vm._v("On the map, you can place boxes that serve to store items. Boxes have different durability, capacity, and type.\n      You can choose a box depending on your goals and preferences. Here are the three main types of boxes:")]),_vm._v(" "),_c('ul',[_c('li',[_c('p',[_vm._v("A standard container is the simplest and cheapest box, which has no special properties. It can be easily\n          damaged or stolen by other synthetics. But it can be pushed and moved around the map if you need to change\n          the storage location.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_1', 'gif')+ ')',
          width: '250px',
          height: '200px',
          marginLeft: 'calc(50% - 125px)',
          })})]),_vm._v(" "),_c('li',[_c('p',[_vm._v("An underground box is a more expensive variation of the \"standard container,\" whose only feature is the\n          ability to hide the box underground. Thus, hiding it from visual detection. It can be detected using an\n          \"anomaly scanner\" or by the owner/group. (not released.)")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_2', 'gif')+ ')',
          width: '400px',
          height: '200px',
          marginLeft: 'calc(50% - 200px)',
          })})]),_vm._v(" "),_c('li',[_c('p',[_vm._v("A password-protected box is the most advanced and expensive box, which has an electronic lock. It can only\n          be opened by players who know the password, which you set when installing the box. It also has greater\n          capacity and durability than other boxes. Also protected from visual detection.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('box_3', 'gif')+ ')',
          width: '320px',
          height: '200px',
          marginLeft: 'calc(50% - 160px)',
          })})])]),_vm._v(" "),_c('p',[_vm._v("If the box was destroyed, all its contents are lost.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }