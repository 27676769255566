<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Офис кластера</h2>
      <div class="content_section">
        <p>Офис - особое место представительства вашего кластера на выбранной базе. Офис позволяет иметь на базе до
          четырёх (4) складов, чья вместимость - 10000.00 кубометров. </p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_2', 'jpg')+ ')',
            width: '400px',
            height: '208px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <p>Прежде, чем открыть сам офис, игроку придётся арендовать под него помещение. Арендовать офис может только
          лидер
          кластера. Цена за аренду зависит от общего количества уже имеющихся офисов у кластера и начинается с 25000.0
          cr
          сроком на 30 дней, если данный офис - первый. </p>

        <p>Сами склады и Офисы можно подвергать переименованию, совершая данное действие во вкладке - “О кластере”. </p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_1', 'jpg')+ ')',
            width: '370px',
            height: '179px',
            marginLeft: 'calc(50% - 185px)',
            }"/>

        <p>Склады по-умолчанию являются личной собственностью главы кластера, тем не менее, доступ к ним можно
          расширить,
          попросту раздав на это право прочим и необходимым участникам кластера.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_3', 'jpg')+ ')',
            width: '353px',
            height: '192px',
            marginLeft: 'calc(50% - 176px)',
            }"/>
      </div>
      <h2 class="head">Блокировка</h2>
      <div class="content_section">

        <p>В случае, если игрок просрочил платёж за аренду офиса, база арестовывает склад кластера, ограничивая туда
          доступа до тех пор, пока сам долг не будет выплачен. </p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_4', 'jpg')+ ')',
            width: '120px',
            height: '178px',
            marginLeft: 'calc(50% - 60px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Cluster Office</h2>
      <div class="content_section">
        <p>The office is a special place representing your cluster at the chosen base. The office allows having up to
          four (4) warehouses on the base, each with a capacity of 10,000.00 cubic meters.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_2', 'jpg')+ ')',
            width: '400px',
            height: '208px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <p>Before opening the office itself, the player will have to rent a space for it. Only the cluster leader can
          rent an office. The rental price depends on the total number of offices already owned by the cluster and
          starts at 25,000.0 cr for 30 days if this office is the first.</p>

        <p>The warehouses and Offices themselves can be renamed, performing this action in the "About Cluster" tab.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_1', 'jpg')+ ')',
            width: '370px',
            height: '179px',
            marginLeft: 'calc(50% - 185px)',
            }"/>

        <p>By default, the warehouses are the personal property of the cluster head, however, access to them can be
          expanded by simply granting this right to other necessary cluster members.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_3', 'jpg')+ ')',
            width: '353px',
            height: '192px',
            marginLeft: 'calc(50% - 176px)',
            }"/>
      </div>
      <h2 class="head">Lockdown</h2>
      <div class="content_section">

        <p>In case a player has overdue payment for the office rent, the base seizes the cluster's warehouse,
          restricting access until the debt is paid off.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('office_4', 'jpg')+ ')',
            width: '120px',
            height: '178px',
            marginLeft: 'calc(50% - 60px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ClusterOffice",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
