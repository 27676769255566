import { render, staticRenderFns } from "./EducationAmmo.vue?vue&type=template&id=409b42b6&scoped=true"
import script from "./EducationAmmo.vue?vue&type=script&lang=js"
export * from "./EducationAmmo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409b42b6",
  null
  
)

export default component.exports