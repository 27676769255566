var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Временные структуры")]),_vm._v(" "),_c('p',[_vm._v("В секторе те или иные сооружения, которые синтеты возвели ради собственных целей. Как правило, подобные\n      постройки\n      могут подвергаться разрушению, если они препятствуют деятельности игрока, либо же представляют для того реальную\n      угрозу.")]),_vm._v(" "),_c('p',[_vm._v("Они выполняют разные\n      "),_c('router-link',{attrs:{"to":"structure_types"}},[_vm._v("функции")]),_vm._v("\n      , например:\n    ")],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('structures_1', 'png')+ ')',
      width: '300px',
      height: '100px',
      marginLeft: 'calc(50% - 150px)',
      })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Temporary Structures")]),_vm._v(" "),_c('p',[_vm._v("In the sector, there are various structures that synthetics have erected for their own purposes. Typically,\n      such buildings can be destroyed if they hinder the player's activity or pose a real threat to them.")]),_vm._v(" "),_c('p',[_vm._v("They perform different\n      "),_c('router-link',{attrs:{"to":"structure_types"}},[_vm._v("functions")]),_vm._v("\n      , for example:\n    ")],1),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('structures_1', 'png')+ ')',
      width: '300px',
      height: '100px',
      marginLeft: 'calc(50% - 150px)',
    })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('p',[_vm._v("Турели - автоматизированные орудия обороны периметра, которые атакуют любую обнаруженную цель в\n        зависимости\n        от своей программы идентификации.")]),_vm._v(" "),_c('p',[_vm._v("Турели имеют множества вариаций, разнясь как от мощности, так и дальности стрельбы.")])]),_vm._v(" "),_c('li',[_c('p',[_vm._v("\n        Щиты - мобильные защитные поля, которые после своей установки создают энергетический купол, поглощающий\n        большую\n        часть направленных атак.\n      ")])]),_vm._v(" "),_c('li',[_c('p',[_vm._v("Добывающие установки - автоматизированные механизмы горнодобывающей сферы. Основная деятельность -\n        выработка ресурсов из\n        близлежащих пластов земли. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('p',[_vm._v("Turrets - automated perimeter defense guns that attack any detected target depending on their\n        identification program.")]),_vm._v(" "),_c('p',[_vm._v("Turrets have many variations, differing in both power and firing range.")])]),_vm._v(" "),_c('li',[_c('p',[_vm._v("Shields - mobile protective fields that, once installed, create an energy dome that absorbs most of the\n        directed attacks.")])]),_vm._v(" "),_c('li',[_c('p',[_vm._v("Mining installations - automated mechanisms of the mining industry. The main activity is the extraction\n        of resources from nearby strata of the earth.")])])])
}]

export { render, staticRenderFns }