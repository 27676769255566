<template>
  <div id="ConstructorBackGround" ref="ConstructorBackGround">

    <app-cell-sub-menu v-if="subMenuProps.slot" v-bind:subMenuProps="subMenuProps"/>

    <app-repair-modal v-if="repairDialog.open"
                      v-bind:slotItem="{type: 'body', item: inventory.unit.body}"
                      v-bind:source="'body'"
                      v-bind:addStyle="{top: '-1px', left: '-1px', width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'}"/>

    <app-synchronization v-if="inventory.unit && inventory.unit.body && synchronization"
                         v-bind:body_id="inventory.unit.body.id"/>

    <div class="error_message" :class="{error_message_visible: hangarLackPower}">
      <div style="margin-top: 30%">{{ texts['text_1'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: removeThoriumNoFreeSpaceInventory}">
      <div style="margin-top: 30%">{{ texts['text_2'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: notEquipSameRigModule}">
      <div style="margin-top: 30%">{{ texts['text_21'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: failAddSlot}">
      <div style="margin-top: 30%">{{ texts['text_22'][language] }}</div>
    </div>

    <div class="skinButtonWrapper" v-if="inventory.unit && inventory.unit.body">
      <div class="skinButtonHead">{{ texts['text_3'][language] }}</div>
      <div class="skinButton cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="openSkins(true,'body')">{{ texts['text_4'][language] }}
      </div>
      <div class="skinButton cursor_hover" v-if="weapon"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="openSkins(true, 'weapon')">{{ texts['text_5'][language] }}
      </div>
    </div>

    <div class="fine_button cursor_hover" v-if="fines.length > 0">
      <div class="fine_list">
        <h3 class="fine_head">{{ texts['text_6'][language] }}</h3>
        <ul style="margin-left: -15px;">
          <li style="margin-bottom: 5px;" v-for="fine in fines" v-html="getFineTitle(fine)"></li>
        </ul>
      </div>
    </div>

    <div class="switch_button">
      <div v-if="tab==='equip'"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="changeTab('defence')"
           class="switch_button_img defence cursor_hover"></div>
      <div v-if="tab==='defence'"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="changeTab('equip')"
           class="switch_button_img equip cursor_hover"></div>
    </div>

    <div class="SelectSkinMenu" v-if="skinsMenu.open">
      <div class="skinHead" id="queueProduction">
        {{ texts['text_11'][language] }}

        <div class="skinButton cursor_hover" style="width: 40px; margin-top: 3px; margin-right: 5px; float: right"
             v-if="weapon"
             @mouseover="playSound('select_sound.mp3', 0.2)"
             @click="openSkins(false)">{{ texts['button_1'][language] }}
        </div>
      </div>

      <div class="skins" v-for="s in skins">
        <div class="skin_wrapper" @mouseover="playSound('select_sound.mp3', 0.2)" @click="selectSkin(s)">
          <div class="skin"
               :style="{ backgroundImage: 'url(' + require('../../assets/' + getSkinPath(s.item_type, getItemName(s.item_type, s.item_id), s.name)).default + ')'}">

            <div class="need_credits" v-if="!s.access">
              <div class="credits_icon"/>
              <div class="credits_count">{{ s.price }}</div>
            </div>

            <div class="buy_button cursor_hover" v-if="!s.access"
                 @mouseover="playSound('select_sound.mp3', 0.2)"
                 @click="buySkin(s)">
              {{ texts['button_2'][language] }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="synchronizationState cursor_hover"
         v-if="inventory.unit && inventory.unit.body"
         @click="synchronization = !synchronization">
      {{ percentage }}%
    </div>

    <div id="powerIcon"/>
    <div id="powerPanel" v-if="inventory.stateMS">
      <div class="user_energy_bar"
           :class="{over_energy: percentPower > 100}"
           :style="{width: percentPower + '%'}"/>
      <span>{{ inventory.stateMS.use_energy }} / {{ inventory.stateMS.max_energy }}</span>
    </div>

    <div class="repairButton cursor_hover" @click="repair"></div>

    <div id="ConstructorMS">
      <div class="msIconWrapper">
        <app-view-m-s
          @click.native="openSubMenu($event, inventory.unit && inventory.unit.body ? inventory.unit.body : null, 'body')"
          v-bind:body="inventory.unit && inventory.unit.body ? inventory.unit.body : null"
          v-bind:weapons="inventory.unit && inventory.unit.body ? inventory.unit.weapon_slots : null"
          v-bind:equips="inventory.unit && inventory.unit.body ? inventory.unit.equip_slots : null"
          v-bind:background-size="'128px'"
          v-bind:hangar="true"/>
      </div>

      <div class="figure" :class="{opacity: tab==='defence'}"></div>
      <div class="figure" :class="{opacity: tab==='defence'}"
           style="transform: rotate(-225deg);left: 140px; top: 63px;"></div>
      <div class="figure" :class="{opacity: tab==='defence'}"
           style="left: 21px;top: -9px;transform: rotate(16deg);"></div>
    </div>

    <template v-if="inventory.unit && inventory.unit.body">

      <div class="tipBodyFine" v-if="false">Не хватает навыка!
        <div id="bodyFine"/>
      </div>

      <div id="hp_bar_wrapper" v-if="inventory.stateMS.max_hp !== inventory.unit.hp">
        <span> HP </span>
        <div id="hp_bar"
             :style="{width: (100 / (inventory.stateMS.max_hp / inventory.unit.hp)) > 0 ? Math.round(100 / (inventory.stateMS.max_hp / inventory.unit.hp)) + '%' : 0}"></div>
      </div>

      <template>
        <div v-if="inventory.unit.thorium_slots"
             class="bodyThoriumWrapper"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             ref="bodyThoriumWrapper">
          <app-item-cell v-for="slot in thoriumSlots"
                         :key="slot.number"
                         class="bodyThorium"
                         @click.native="removeThorium(slot)"
                         v-bind:cursor_pointer="true"
                         v-bind:equipProps="{type: 'thorium', data: slot}"
                         v-bind:showHP="true"
                         v-bind:downHP="true"
                         v-bind:itemSlot="{type: 'fuel', max_hp: slot.fuel.energy_cap, hp: slot.worked_out, item: slot.fuel}"
                         v-bind:size="33"/>
        </div>
      </template>

      <template v-if="tab==='defence'">
        <canvas class="body_damage_zone" height="200" width="200" ref="body_damage_zone"></canvas>
        <div class="damage_zone_label" v-for="z in zones"
             :style="{left: (z.x + 10) + 'px', top: (z.y) + 'px',
                 backgroundImage: z.damage_k > 1 ? 'url(' + require('../../assets/icons/target.png').default + ')':'url(' + require('../../assets/icons/shield_2.png').default + ')'}">
          <div class="damage_zone_label_text">{{ z.damage_k }}</div>
        </div>
      </template>

      <template v-if="tab==='equip'">
        <!--  1 -->
        <app-item-cell v-for="slot in inventory.unit.equip_slots"
                       :key="slot.number"
                       v-if="slot.type_slot === 1"
                       v-bind:style="{ left: getPos(160 - (slot.number_slot-1) * 20).x + 'px', top: getPos(160 - (slot.number_slot-1) * 20).y + 'px' }"
                       class="bodyEquip"
                       v-bind:fine="false"
                       v-bind:textFine="''"
                       v-bind:opacity="false"
                       v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                       v-bind:class="{mining: slot.mining, additional: slot.additional}"
                       v-bind:equipProps="{type: 'equip', data: slot}"
                       v-bind:type="slot.equip ? null : 1"
                       v-bind:cursor_pointer="true"
                       @mouseover.native="setFindEquipFilter(slot)"
                       @mouseout.native="removeFindFilter()"
                       @click.native="openSubMenu($event, slot, 'equip')"
                       v-bind:size="34"/>

        <div class="add_slot_button"
             v-if="getCountSlotsByType(1) < 5 && additionalSlots.length === 0"
             @click="addSlot(1)"
             v-bind:style="{
               left: getPos(159 - (getCountSlotsByType(1)) * 20).x + 'px',
               top: getPos(159 - (getCountSlotsByType(1)) * 20).y + 'px',
             }"
        />

        <!--  2 -->
        <app-item-cell v-for="slot in inventory.unit.equip_slots"
                       :key="slot.number"
                       v-if="slot.type_slot === 2"
                       v-bind:style="{ left: getPos(40 - (slot.number_slot-1) * 20).x + 'px', top: getPos(40 - (slot.number_slot-1) * 20).y + 'px' }"
                       class="bodyEquip"
                       v-bind:fine="false"
                       v-bind:textFine="''"
                       v-bind:opacity="false"
                       v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                       v-bind:class="{mining: slot.mining, green_animate: selectEquipII, additional: slot.additional}"
                       v-bind:equipProps="{type: 'equip', data: slot}"
                       v-bind:type="slot.equip ? null : 2"
                       v-bind:cursor_pointer="true"
                       @mouseover.native="setFindEquipFilter(slot)"
                       @mouseout.native="removeFindFilter()"
                       @click.native="openSubMenu($event, slot, 'equip')"
                       v-bind:size="34"/>

        <div class="add_slot_button"
             v-if="getCountSlotsByType(2) < 5 && additionalSlots.length === 0"
             @click="addSlot(2)"
             v-bind:style="{
               left: getPos(39 - (getCountSlotsByType(2)) * 20).x + 'px',
               top: getPos(39 - (getCountSlotsByType(2)) * 20).y + 'px',
             }"
        />

        <!--  3 -->
        <app-item-cell v-for="slot in inventory.unit.equip_slots"
                       :key="slot.number"
                       v-if="slot.type_slot === 3"
                       v-bind:style="{ left: getPos(280 - (slot.number_slot-1) * 20).x + 'px', top: getPos(280 - (slot.number_slot-1) * 20).y + 'px' }"
                       class="bodyEquip"
                       v-bind:fine="false"
                       v-bind:textFine="''"
                       v-bind:opacity="false"
                       v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                       v-bind:class="{mining: slot.mining, green_animate: selectMining}"
                       v-bind:equipProps="{type: 'equip', data: slot}"
                       v-bind:type="slot.equip ? null : 3"
                       v-bind:cursor_pointer="true"
                       @mouseover.native="setFindEquipFilter(slot)"
                       @mouseout.native="removeFindFilter()"
                       @click.native="openSubMenu($event, slot, 'equip')"
                       v-bind:size="34"
                       v-bind:lock="checkLock(slot)"/>
        <!-- 4 -->
        <app-item-cell v-for="slot in inventory.unit.equip_slots"
                       :key="slot.number"
                       v-if="slot.type_slot === 4"
                       v-bind:style="{ left: getPos(248 - (slot.number_slot-1) * 27, 65).x + 'px', top: getPos(248 - (slot.number_slot-1) * 27, 65).y + 'px' }"
                       class="bodyEquip Rig"
                       v-bind:fine="false"
                       v-bind:textFine="''"
                       v-bind:opacity="false"
                       v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                       v-bind:class="{mining: slot.mining}"
                       v-bind:equipProps="{type: 'equip', data: slot}"
                       v-bind:type="slot.equip ? null : 4"
                       v-bind:cursor_pointer="true"
                       @mouseover.native="setFindEquipFilter(slot)"
                       @mouseout.native="removeFindFilter()"
                       @click.native="openSubMenu($event, slot, 'equip')"
                       v-bind:size="24"
                       v-bind:text="slot.number_slot"
                       v-bind:lock="checkLock(slot)"/>

        <div class="lockEquip"
             v-if="inventory.unit && inventory.unit.body && ['explores_start_body', 'replic_start_body', 'reverses_start_body'].includes(inventory.unit.body.name)">
          <!--          супер костыль т.к. лень делать нормально и нужно ток для этих корпусов-->
        </div>

        <!--  оружие всегда на 3тем слоте -->
        <template v-for="slot in inventory.unit.weapon_slots">

          <app-item-cell
            v-bind:style="{ left: getPos(280 - (slot.number_slot - 1) * 20).x + 'px', top: getPos(280 - (slot.number_slot - 1) * 20).y + 'px' }"
            class="bodyWeapon"
            v-bind:class="{green_animate: selectWeapon}"
            v-bind:fine="false"
            v-bind:textFine="''"
            v-bind:opacity="false"
            v-bind:itemSlot="{type: 'weapon', item: slot.weapon}"
            v-bind:equipProps="{type: 'weapon', data: slot}"
            v-bind:cursor_pointer="true"
            @mouseover.native="setFindWeaponFilter(slot)"
            @mouseout.native="removeFindFilter()"
            @click.native="openSubMenu($event, slot, 'weapon')"
            v-bind:size="34"
            v-bind:lock="checkLock(slot)"
            v-bind:type="slot.weapon ? null : 6"
          />

          <app-item-cell
            v-bind:style="{ left: getPos(280 - (slot.number_slot - 1) * 20, 90).x + 'px', top: getPos(280 - (slot.number_slot - 1) * 20, 90).y + 'px' }"
            class="bodyAmmo"
            v-bind:class="{green_animate: selectWeapon}"
            v-bind:fine="false"
            v-bind:textFine="''"
            v-bind:opacity="false"
            v-bind:itemSlot="{type: 'ammo', quantity: slot.ammo_quantity, item: slot.ammo}"
            v-bind:equipProps="{type: 'ammo', data: slot}"
            v-bind:cursor_pointer="true"
            @mouseover.native="setFindAmmoFilter(slot)"
            @mouseout.native="removeFindFilter()"
            @click.native="openSubMenu($event, slot, 'ammo')"
            v-bind:size="20"/>

        </template>
      </template>
    </template>
  </div>
</template>

<script>

import CellSubMenu from '../Inventory/CellSubMenu';
import ItemCell from '../Inventory/ItemCell';
import ViewMS from './ViewMS';
import {gameStore} from "../../game/store";
import RepairModal from "../Inventory/RepairModal";
import Synchronization from './Synchronization';

export default {
  name: "Constructor",
  data() {
    return {
      tab: "equip",
      subMenuProps: {
        src: '',
        slot: null,
        x: 0,
        y: 0,
        points: [],
      },
      skinsMenu: {
        open: false,
        type: '',
        id: 0,
      },
      repairDialog: {
        open: false,
      },
      synchronization: false,
    }
  },
  mounted() {
    if (this.inventory && this.inventory.unit && this.inventory.unit.body && this.inventory.unit.body.id) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "GetSynchronization",
          id: this.inventory.unit.body.id,
        }
      }));
    }
  },
  updated() {
    this.drawDamageZone()
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    addSlot(type) {
      this.playSound('button_press.mp3', 0.3)
      this.$parent.dialogSlot = !this.$parent.dialogSlot
      this.$store.commit({
        type: 'setAddSlotDialogData',
        data: null,
      });

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "GetAddSlotDialogData",
          equip_slot_type: type,
        }
      }));
    },
    checkLock(slot) {
      if (slot.lock_slot === 0) return false;

      for (let i in this.inventory.unit.equip_slots) {
        let s = this.inventory.unit.equip_slots[i];
        if (slot.type_slot === s.type_slot && slot.lock_slot === s.number_slot && s.equip) {
          return true
        }
      }

      for (let i in this.inventory.unit.weapon_slots) {
        let s = this.inventory.unit.weapon_slots[i];
        if (slot.type_slot === s.type_slot && slot.lock_slot === s.number_slot && s.weapon) {
          return true
        }
      }

      return false
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)
      this.tab = tab;
    },
    getItemName(type, id) {
      let item = {};
      if (type === "body") item = gameStore.gameTypes.bodies[id]
      if (type === "weapon") item = gameStore.gameTypes.weapons[id]
      return item.name
    },
    getSkinPath(type, name, skin) {
      return "units/" + type + "/" + name + "/skins/" + name + "_skin_" + skin + ".png";
    },
    getCountSlotsByType(type) {
      let count = 0
      for (let s of this.inventory.unit.equip_slots) {
        if (s.type_slot === type) count++
      }

      return count
    },
    openSkins(open, type) {
      this.playSound('button_press.mp3', 0.3)
      let id = this.inventory.unit.body.id
      if (type === "weapon" && this.weapon) {
        id = this.weapon.id
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetSkins",
          type: type,
          id: Number(id),
        }
      }));

      this.skinsMenu = {open: open, type: type, id: id}
    },
    selectSkin(s) {
      this.playSound('button_press.mp3', 0.3)
      if (s.access) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: "SetSkin",
            id: Number(s.id),
          }
        }));
        this.openSkins(false)
      }
    },
    buySkin(s) {
      this.playSound('button_press.mp3', 0.3)
      if (!s.access) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: "BuySkin",
            id: Number(s.id),
          }
        }));
      }
    },
    repair() {
      this.repairDialog.open = true
    },
    getPos(angle, dist = 110) {
      let ConstructorBackGroundSize = 283;

      return {
        x: (dist * Math.cos(angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2),
        y: (dist * Math.sin(angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2),
      };
    },
    thoriumSlotsOffset(unit) {
      return (35 - 35 / 2) * unit.thorium_slots.length
    },
    removeFindFilter() {
      this.$store.commit({
        type: 'setFindFilter',
        filters: null
      });
    },
    setFindEquipFilter(slot) {
      this.playSound('select_sound.mp3', 0.3)

      this.$store.commit({
        type: 'setFindFilter',
        filters: {
          type: 'equip',
          type_slot: slot.type_slot,
          mining: slot.mining,
        }
      });
    },
    setFindWeaponFilter() {
      this.playSound('select_sound.mp3', 0.3)

      this.$store.commit({
        type: 'setFindFilter',
        filters: {
          type: 'weapon',
          standard_size: {
            small: this.inventory.unit.body.standard_size_small,
            medium: this.inventory.unit.body.standard_size_medium,
            big: this.inventory.unit.body.standard_size_big,
          },
        }
      });
    },
    setFindAmmoFilter(slot) {
      this.playSound('select_sound.mp3', 0.3)

      if (slot.weapon) {
        this.$store.commit({
          type: 'setFindFilter',
          filters: {
            type: 'ammo',
            standard_size: slot.weapon.standard_size,
            weaponType: slot.weapon.type,
          }
        });
      }
    },
    removeThorium(slot) {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch({
        type: 'removeThorium',
        slot: slot.number_slot
      })
    },
    openSubMenu(event, slot, type) {
      this.playSound('button_press.mp3', 0.3)

      let app = this;

      if (app.subMenuProps.slot) {
        app.subMenuProps.slot = null;
        return;
      }

      app.subMenuProps.slot = null;

      if (!slot) return;
      if (!slot[type] && type !== 'body') return;

      let invSlot;
      if (type === 'body') {
        invSlot = {type: type, item_id: slot.id, item: slot};
      } else {
        invSlot = {type: type, item_id: slot[type].id, item: slot[type]};
      }

      setTimeout(function () {
        app.getSubMenuPoints(slot, type);
        app.subMenuProps.src = 'Constructor';
        app.subMenuProps.x = ((event.pageX - $(app.$parent.$refs['inventoryBox']).position().left - 300) / app.interfaceZoom);
        app.subMenuProps.y = ((event.pageY - $(app.$parent.$refs['inventoryBox']).position().top - 50) / app.interfaceZoom);
        app.subMenuProps.type = type;
        app.subMenuProps.data = slot;
        app.subMenuProps.slot = invSlot;
      }, 0)
    },
    getSubMenuPoints(slot, type) {
      this.subMenuProps.points = [];
      this.subMenuProps.points.push('detail');

      if ((this.inventory.inBase || type === 'ammo') && type !== 'body') {
        this.subMenuProps.points.push('remove');
      }

      this.subMenuProps.points.push('cancel');
    },
    drawDamageZone() {

      let draw = function (canvas, zones) {
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.lineWidth = 2;
        for (let z of zones) {

          if (z.damage_k < 1) {
            ctx.fillStyle = "rgba(128,128,128,0.6)";
            ctx.strokeStyle = 'rgba(128, 128, 128, 0.9)';
          } else {
            ctx.fillStyle = "rgba(255,0,0,0.3)";
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.6)';
          }

          ctx.beginPath();
          ctx.moveTo(100, 100);
          ctx.arc(100, 100, 99, z.start_angle * (Math.PI / 180), z.end_angle * (Math.PI / 180));
          ctx.lineTo(100, 100);
          ctx.fill();
          ctx.stroke();
        }
      }

      let wait = setInterval(function () {
        let canvas = this.$refs['body_damage_zone'];
        if (canvas) {
          clearInterval(wait)
          draw(canvas, this.zones)
        }
      }.bind(this), 100)
    },
    getFineTitle(fineName) {
      if (fineName === 'fine_body') return this.texts['text_7'][this.language];
      if (fineName === 'fine_weapon') return this.texts['text_8'][this.language];
      if (fineName === 'fine_ammo') return this.texts['text_9'][this.language];
      if (fineName === 'fuel_') return this.texts['text_10'][this.language];

      return fineName
    }
  },
  computed: {
    skins() {
      return this.$store.getters.getSkins
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    thoriumSlots() {
      let slots = [];

      for (let i in this.inventory.unit.thorium_slots) {
        slots.push(this.inventory.unit.thorium_slots[i])
      }

      return slots.sort(function (a, b) {
        if (a.number_slot > b.number_slot) return 1;
        if (b.number_slot > a.number_slot) return -1;
        return 0;
      });
    },
    weapon() {
      if (this.inventory.unit && this.inventory.unit.weapon_slots) {
        for (let i in this.inventory.unit.weapon_slots) {
          let wSlot = this.inventory.unit.weapon_slots[i]
          if (wSlot.weapon) {
            return wSlot.weapon
          }
        }
      }

      return false
    },
    zones() {
      let zones = [];
      let body = this.inventory.unit && this.inventory.unit.body ? this.inventory.unit.body : null;
      if (body && body.damage_zones && body.damage_zones.length > 0) {
        let boundaryZone360 = null;
        let boundaryZone0 = null

        for (let z of body.damage_zones) {
          // todo костыль
          if (z.end_angle === 360 || z.start_angle === 0) {
            if (z.end_angle === 360) boundaryZone360 = z;
            if (z.start_angle === 0) boundaryZone0 = z;
          } else {
            zones.push(z)
          }
        }

        if (boundaryZone360 && boundaryZone0 && boundaryZone360.damage_k === boundaryZone0.damage_k) {
          zones.push({
            start_angle: boundaryZone360.start_angle,
            end_angle: boundaryZone0.end_angle,
            damage_k: boundaryZone360.damage_k
          })
        } else {
          if (boundaryZone360) zones.push(boundaryZone360);
          if (boundaryZone0) zones.push(boundaryZone0);
        }
      }

      let ConstructorBackGroundSize = 283;
      let dist = 110;

      for (let z of zones) {
        if (z.start_angle > z.end_angle) z.end_angle += 360;
        z.angle = z.start_angle + ((z.end_angle - z.start_angle) / 2);
        z.x = (dist * Math.cos(z.angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2);
        z.y = (dist * Math.sin(z.angle * Math.PI / 180)) + (ConstructorBackGroundSize / 2 - 40 / 2);
      }

      return zones
    },
    hangarLackPower() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('hangar_lack_power') && errors['hangar_lack_power'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'hangar_lack_power',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    removeThoriumNoFreeSpaceInventory() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('remove_thorium_no_free_space_inventory') && errors['remove_thorium_no_free_space_inventory'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'remove_thorium_no_free_space_inventory',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    notEquipSameRigModule() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('not_equip_same_rig_module') && errors['not_equip_same_rig_module'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'not_equip_same_rig_module',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    failAddSlot() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('fail_add_body_slot') && errors['fail_add_body_slot'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'fail_add_body_slot',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    effects() {
      return this.inventory.effects
    },
    fines() {
      let effects = this.effects;
      let fines = [];
      for (let i in effects) {
        let e = effects[i];
        if (e.u.includes('fine_body') && !fines.includes('fine_body')) {
          fines.push('fine_body')
        }

        if (e.u.includes('fine_weapon') && !fines.includes('fine_weapon')) {
          fines.push('fine_weapon')
        }

        if (e.u.includes('fine_ammo') && !fines.includes('fine_ammo')) {
          fines.push('fine_ammo')
        }

        if (e.u.includes('fuel_') && !fines.includes('fuel_')) {
          fines.push('fuel_')
        }
      }

      return fines
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectMining() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "equip_mining" ||
        this.missions.currentAction.type_func_monitor === "equip_mining_to_quick_panel" ||
        this.missions.currentAction.type_func_monitor === "set_geo_equip";
    },
    selectWeapon() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "install_weapon_and_ammo" ||
        this.missions.currentAction.type_func_monitor === "weapon_in_panel";
    },
    selectEquipII() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "set_geo_equip"
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    percentPower() {
      return 100 - (100 / (this.inventory.stateMS.max_energy / (this.inventory.stateMS.max_energy - this.inventory.stateMS.use_energy)))
    },
    percentage() {

      if (this.inventory && this.inventory.unit && this.inventory.unit.body && this.inventory.unit.body.id) {
        let state = this.$store.getters.getSynchronizationState[this.inventory.unit.body.id]
        if (!state) {
          return 0
        }

        return state.lvl
      }

      return 0
    },
    additionalSlots() {
      let slots = [];
      if (this.inventory && this.inventory.unit && this.inventory.unit.body && this.inventory.unit.body.id) {
        for (let slot of this.inventory.unit.equip_slots) {
          if (slot.additional) {
            slots.push(slot)
          }
        }
      }

      return slots
    }
  },

  components: {
    AppItemCell: ItemCell,
    AppViewMS: ViewMS,
    AppCellSubMenu: CellSubMenu,
    AppRepairModal: RepairModal,
    AppSynchronization: Synchronization,
  }
}
</script>

<style scoped>
#ConstructorBackGround {
  border-radius: 5px;
  width: 286px;
  height: 286px;
  position: relative;
  margin-left: -3px;
  float: left;
  box-shadow: inset 0 0 5px 1px black;
  background: rgba(76, 76, 76, 0.75);
}

#powerPanel {
  bottom: 0;
  right: 0;
  position: absolute;
  float: right;
  padding-right: 4px;
  border-radius: 4px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.17);
  width: 84px;
  height: 14px;
  text-align: center;
  z-index: 0;
  font-weight: bold;
  line-height: 16px;
  overflow: hidden;
}

#MSWeaponPanel {
  left: -3px;
  top: -3px;
  padding-top: 2px;
}

#ConstructorMS {
  border-radius: 50%;
  padding: 5px;
  vertical-align: middle;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  margin: auto;
  left: 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 1);
  background-size: 100% 2px;
  background-image: linear-gradient(0deg, rgba(7, 41, 60, 0.6) 50%, rgba(0, 62, 95, 0.5) 30%);
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.repairButton {
  width: 40px;
  height: 40px;
  background: url("../../assets/icons/repair_icon.png");
  background-size: contain;
  position: absolute;
  top: 5px;
  right: 5px;
  filter: drop-shadow(1px 1px 1px black);
}

.repairButton:active, .repairButtonActive:active {
  top: 8px;
  right: 8px;
  width: 34px;
  height: 34px;
}

.repairButton:hover {
  filter: drop-shadow(0px 0px 1px white);
}

.bodyThoriumWrapper {
  z-index: 2;
  position: absolute;
  left: -221px;
  top: 286px;
  border-radius: 0 0 5px 5px;
  float: right;
  margin-top: 2px;
  border: 1px solid #009aeb7d;
  border-top: 0;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 1px 0 0 0;
}

#ConstructorBackGround .bodyThorium {
  margin: 1px;
  background-color: unset;
  backdrop-filter: blur(4px);
}

#ConstructorBackGround .bodyEquip, #ConstructorBackGround .bodyWeapon, #ConstructorBackGround .bodyAmmo {
  position: absolute;
  border-radius: 18px;
  z-index: 2;
}

#ConstructorBackGround .mining {
  background: rgba(255, 236, 14, 0.4);
}

#ConstructorBackGround .bodyWeapon {
  background: rgba(255, 58, 53, 0.71);
}

#ConstructorBackGround .bodyAmmo {
  border-radius: 10px;
}

.figure {
  position: absolute;
  height: 155px;
  width: 155px;
  background: rgba(86, 88, 88, 0.3);
  left: 19px;
  top: 129px;
  z-index: 1;
  border-radius: 200% 50% 200% 50%;
  transform: rotate(-103deg);
  box-shadow: inset 0 0 2px 0 rgb(245, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

#powerIcon {
  background-image: url("../../assets/icons/reactor_icon.png");
  background-size: cover;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 9px;
  right: 26px;
  filter: drop-shadow(1px 1px 1px black);
  z-index: 1;
}

.synchronizationState {
  height: 15px;
  width: 40px;
  position: absolute;
  bottom: 58px;
  left: 120px;
  filter: drop-shadow(1px 1px 1px black);
  border-radius: 8px;
  border: 1px solid rgba(1, 155, 236, 0.49);
  z-index: 3;
  font-size: 12px;
  color: wheat;
  background: rgba(20, 110, 157, 0.49);
  text-align: center;
  line-height: 17px;
  text-shadow: 1px 1px 1px black;
  user-select: none;
}

.synchronizationState:hover {
  background: rgba(20, 110, 157, 0.65);
  box-shadow: 0 0 5px 1px rgba(16, 157, 231, 0.75);
  color: white;
}

.synchronizationState:active {
  transform: scale(0.97);
}

.skinButtonWrapper {
  min-height: 13px;
  min-width: 110px;
  position: absolute;
  top: -20px;
  left: 55px;
  border-radius: 5px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}

.skinButtonHead {
  color: hsla(0, 0%, 100%, .8);
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  position: relative;
  clear: both;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #3486dd;
  font-size: 10px;
  line-height: 14px;
  height: 13px;
  border-radius: 4px 0 0 4px;
  text-align: center;
  width: 60px;
  float: left;
}

.skinButton {
  display: block;
  width: 50px;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 13px;
  border-radius: 5px;
  color: #fff;
  line-height: 14px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: right;
  margin-left: 2px;
}

.skinButton:active {
  transform: scale(.98);
}

.skinButton:hover {
  background: #ff8100;
}

.switch_button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 40px;
  height: 40px;
  filter: drop-shadow(1px 1px 1px black);
}

.switch_button:active {
  top: 8px;
  right: 8px;
  width: 34px;
  height: 34px;
}

.switch_button:hover {
  filter: drop-shadow(0px 0px 1px white);
}

.switch_button_img {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.defence {
  background-image: url('../../assets/icons/shield.png');
}

.equip {
  background-image: url('../../assets/icons/weapon.png');
}

.skin_wrapper {
  padding: 3px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  float: left;
  margin: 2px 2px 2px 7px;
}

.skin_wrapper:hover {
  background: rgba(128, 128, 128, 0.3);
}

.skin {
  height: 120px;
  width: 120px;
  background-size: contain;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.no_access, .need_credits {
  height: 20px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px;
  border-radius: 2px;
  box-shadow: 0 0 2px black;
  position: absolute;
  top: 4px;
  left: 4px;
}

.need_credits {
  width: 60px;
  height: 12px;
  left: calc(50% - 30px);
}

.no_access img {
  height: 100%;
  width: 100%;
}

.credits_icon {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png')
}

.credits_count {
  background: #066ea8;
  float: right;
  width: calc(100% - 21px);
  padding-right: 4px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
}

.buy_button {
  display: block;
  width: 60px;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  z-index: 3;
  bottom: 4px;
  left: calc(50% - 30px);
}

.buy_button:active {
  transform: scale(.98);
}

.buy_button:hover {
  background: #ff8100;
}

.SelectSkinMenu {
  height: 100%;
  width: 100%;
  background: #89969c;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  z-index: 999;
  box-shadow: inset 0 0 3px black;
  overflow-x: hidden;
  overflow-y: scroll;
}

.skinHead {
  line-height: 17px;
  width: calc(100% - 9px);
  text-align: left;
  height: 17px;
  background: #df6f2c;
  padding-left: 7px;
  box-shadow: 0 0 2px black;
  font-size: 11px;
  clear: both;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  border-radius: 3px 0 0 0;
  margin: 1px 1px 2px 1px;
}

.opacity {
  opacity: 0.1;
}

.msIconWrapper {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 1px solid rgba(149, 149, 149, 0.25);
  background-color: rgba(64, 49, 31, 0.75);
  overflow: hidden;
  box-shadow: inset 0 0 7px 2px black;
  z-index: 2;
  position: absolute;
  left: calc(50% - 82px);
  top: calc(50% - 82px);
}

.body_damage_zone {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.damage_zone_label, .damage_zone_label_text {
  position: absolute;
  height: 20px;
  width: 20px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  border-radius: 5px;
  background-size: contain;
}

.damage_zone_label_text {
  color: white;
  left: calc(-50% + 2px);
  top: calc(100% - 2px);
  height: 14px;
  font-size: 12px;
  line-height: 16px;
  width: 34px;
  text-align: center;
}

.user_energy_bar {
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background: deepskyblue;
  position: absolute;
  z-index: -1;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 1);
  text-shadow: 1px 1px 1px black;
}

.over_energy {
  animation: blink 2.0s linear infinite;
  background: #dd7034;
}

@keyframes blink {
  0% {
    box-shadow: inset 0 0 2px 1px red;
  }
  50% {
    box-shadow: inset 0 0 4px 3px red;
  }
  100% {
    box-shadow: inset 0 0 2px 1px red;
  }
}

.lockEquip {
  height: 16px;
  width: 16px;
  position: absolute;
  left: 135px;
  top: 9px;
  background-image: url("../../assets/icons/lock.png");
  background-size: contain;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 2px 1px black;
  z-index: 4;
  background-position: center;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.fine_button {
  background: url("../../assets/icons/equip_fine.png");
  width: 32px;
  height: 32px;
  background-size: contain;
  position: absolute;
  left: 8px;
  bottom: 8px;
  filter: drop-shadow(1px 1px 1px black);
  z-index: 4;
}

.fine_list {
  position: absolute;
  left: 20px;
  bottom: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  box-shadow: 1px 1px 1px black;
  min-height: 15px;
  width: 200px;
  opacity: 0;
  transition: 100ms;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
  pointer-events: none;
}

.fine_button:hover .fine_list {
  opacity: 1;
}

.fine_head {
  margin: 0;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 5px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  text-indent: 5px;
  position: relative;
  clear: both;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.green_animate {
  box-shadow: inset 0 0 4px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 3px 2px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 12px 6px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 3px 2px greenyellow;
  }
}

.bodyEquip.Rig {
  background-color: rgba(0, 0, 0, 0.5);
}

.add_slot_button {
  position: absolute;
  border-radius: 18px;
  z-index: 2;
  height: 34px;
  width: 34px;
  display: inline-block;
  background-color: rgba(76, 76, 76, 0.4);
  vertical-align: top;
  margin: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  float: left;
  overflow: hidden;
  border: 1px dashed rgba(127, 127, 127, 1);
  user-select: none;
  background-image: url(../../assets/icons/add_slot.png);
  background-position: center;
  background-size: 50%;
  filter: contrast(75%) sepia(50%) hue-rotate(164deg) brightness(0.5) saturate(600%);
  opacity: 0.5;
}

.add_slot_button:hover {
  border: 1px dashed rgb(253, 133, 1);
  opacity: 1;
}

.add_slot_button:active {
  transform: scale(0.97);
}
</style>
