<template>
  <div class="synchronization">

    <h3 class="head">
      {{ texts['text_23'][language] }}
      <div class="back" @click="$parent.synchronization = false">{{ texts['text_24'][language] }}</div>
    </h3>

    <div class="loader" v-if="!state">
      <app-preloader/>
    </div>

    <template v-if="state">
      <div class="sync_percent">
        <div class="percent_value">
          {{ percentage }}%
        </div>

        <div class="percent_row">
          <div class="percent_box"
               v-for="index in 20"
               :class="{
               up: (index*5) <= percentage,
               perk: ((index*5) <= percentage) && (index % 5 === 0),
             }">
            <div class="pick" :class="{big: index % 5 === 0}" v-if="index <= usePoints"/>
          </div>
        </div>

        <div class="not_is_a_base" v-if="!inBase">
          <div>{{ texts['text_25'][language] }}</div>
        </div>
      </div>

      <div class="system_points">
        <h4 class="sub_head">{{ texts['text_26'][language] }}</h4>

        <div class="system" v-for="system in systems">
          <div class="system_head_wrap">
            <div class="range_button l cursor_hover"
                 @click="setPointToSystem(system.key, 'take')"
                 :class="{disableRange: system.points === 0 || !inBase}"
                 style="left: 0; border-radius: 5px 0 0 5px;"/>
            <div class="sub_head">{{ system.name }}</div>
            <div class="range_button cursor_hover"
                 @click="setPointToSystem(system.key, 'add')"
                 :class="{disableRange: freePoints <= usePoints || !inBase || system.points >= 10}"
                 style="right: 0;"/>
          </div>

          <div class="system_row">
            <div class="percent_box"
                 v-for="index in 10"
                 :class="{up: index <= system.points}"
            />
          </div>
        </div>

      </div>

      <div class="perks">
        <h4 class="sub_head">{{ texts['text_27'][language] }}</h4>

        <div v-for="bonus in bonuses">
          <div style="color: white; text-align: left;"
               v-if="getBonusDescription(bonus) !== ''">
            <div class="passive_effect"
                 :class="{reduced: bonus.n.includes('reduced')}"
                 v-html="getBonusDescription(bonus)"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Preloader from "../Preloader/Preloader";

export default {
  name: "Synchronization",
  props: ['body_id'],
  data() {
    return {}
  },
  methods: {
    getBonusDescription(e) {
      return this.handbook.effects[this.language][e.n].description
    },
    setPointToSystem(name, operation) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "SetPointToSystem",
          name: name,
          data: operation,
        }
      }));
    }
  },
  computed: {
    state() {
      let state = this.$store.getters.getSynchronizationState[this.$props.body_id]
      if (!state) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "GetSynchronization",
            id: this.$props.body_id,
          }
        }));
      }

      return state
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
    percentage() {
      return this.state.lvl
    },
    systems() {
      return [
        {name: this.texts['ch_1'][this.language], key: "attack", points: this.state.systems["attack"].points},
        {name: this.texts['ch_11'][this.language], key: "defend", points: this.state.systems["defend"].points},
        {name: this.texts['ch_14'][this.language], key: "nav", points: this.state.systems["nav"].points},
        {name: this.texts['ch_17'][this.language], key: "power", points: this.state.systems["power"].points},
        {name: this.texts['ch_21'][this.language], key: "move", points: this.state.systems["move"].points},
      ]
    },
    usePoints() {
      let points = 0

      for (let s of this.systems) {
        points += s.points
      }

      return points
    },
    bonuses() {
      return this.state.bonuses.sort(function (a, b) {
        if (a.u < b.u) return -1;
        if (a.u > b.u) return 1;
        return 0;
      })
    },
    freePoints() {
      return Math.floor(this.percentage / 5)
    },
    inBase() {
      return this.$route.path.includes('lobby')
    },
  },
  components: {
    AppPreloader: Preloader,
  }
}
</script>

<style scoped>
.synchronization {
  position: absolute;
  z-index: 13;
  border-radius: 5px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  box-shadow: 0 0 2px black;
  left: 0;
  top: 0;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(8px);
  overflow: hidden;
}

.head {
  margin: 0 0 0 0;
  background: #3486dd;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px 4px 0 0;
  font-size: 12px;
  line-height: 14px;
  height: 13px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  text-indent: 10px;
  box-shadow: 1px 1px 1px 0 black;
}

.back {
  display: block;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 13px;
  border-radius: 5px;
  color: #fff;
  line-height: 11px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: right;
  margin-left: 2px;
  width: 48px;
  margin-top: 0;
  margin-right: -1px;
  text-indent: 0;
}

.back:hover {
  background: #ff8100;
}

.back:active {
  transform: scale(.98);
}

.sync_percent {
  padding: 4px 5px 4px 5px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 4px 0 black;
  position: relative;
  height: 45px;
  margin: 3px;
}

.percent_row, .system_row {
  height: 22px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 5px;
}

.percent_box {
  height: 50%;
  width: 12px;
  box-shadow: 0 0 1px black;
  background: rgba(128, 128, 128, 0.2);
  float: left;
  margin-left: 3px;
  align-self: flex-end;
  outline: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.percent_box:first-child {
  margin-left: 0;
}

.up {
  background: #3486dd !important;
}

.perk {
  height: 100%;
  background: #ff9900 !important;
}

.percent_value {
  text-align: center;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-weight: 900;
  color: powderblue;
  text-shadow: 1px 1px 1px black;
}

.system_points, .perks {
  float: left;
  width: calc(48% - 5px);
  background: rgba(0, 0, 0, 0.3);
  height: calc(100% - 75px);
  margin: 3px;
  box-shadow: inset 0 0 4px 0 black;
  border-radius: 5px;
  margin-top: 0;
  margin-right: 0;
}

.perks {
  width: calc(52% - 5px);
  float: right;
  margin-right: 3px;
  margin-left: 0;
  overflow-y: scroll;
}

.sub_head {
  margin: 3px;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px black;
  font-size: 11px;
}

.system {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  position: relative;
  margin: 10px 5px 5px 5px;
  height: 27px;
}

.system_head_wrap {
  float: left;
  width: 100%;
}

.system_row {
  display: block;
  height: 5px;
  float: left;
  margin: 0 0 0 22px;
  width: calc(100% - 44px);
}

.system_row .percent_box {
  height: 100%;
  width: 5px;
}

.range_button {
  width: 15px;
  height: 27px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  background-color: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0;
  user-select: none;
  position: absolute;
  top: 0;
  background-image: url("../../assets/icons/sync_up_right.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.range_button.l {
  background-image: url("../../assets/icons/sync_up_left.png");
}

.range_button:active {
  transform: scale(0.97);
}

.range_button:hover {
  background-color: rgba(255, 129, 0, 1);
}

.disableRange {
  background-color: dimgrey !important;
  pointer-events: none;
}

.passive_effect {
  width: calc(100% - 15px);
  float: left;
  font-size: 11px;
  padding: 3px;
  clear: both;
  border: 1px solid transparent;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 3px;
  margin: 3px 3px;
  color: white;
  box-shadow: 0 0 2px 0 black;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.passive_effect.reduced {
  background: rgba(255, 0, 0, 0.1)
}

.pick {
  height: 5px;
  width: 5px;
  background: #d0e8ff;
  box-shadow: inset 0 0 1px 1px black;
  margin: 3px 0 0 3px;
  border-radius: 5px;
}

.pick.big {
  height: 17px;
}

.loader {
  width: 43px;
  height: 43px;
  float: left;
  margin-left: calc(50% - 21px);
  margin-top: 20px;
}

.not_is_a_base {
  padding: 4px 5px 4px 5px;
  background: rgb(127, 0, 0, 0.5);
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  color: white;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 10px);
  height: calc(100% - 8px);
  backdrop-filter: blur(12px);
  text-shadow: 1px 1px 1px black;
}
</style>
