<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Боты</h2>
      <p>NPC (массовка) ведущие собственную деятельность в секторах игрового мира. Они - такие же синтеты как и игроки,
        из-за чего, зачастую ботов можно встретить за привычными для игрока делами: добыча ресурсов, охрана территории,
        участие в боевых действиях, перевозка грузов и прочее. </p>

      <p>Боты - это живая часть мира, которая остро реагирует на действия игрока и прочих ботов. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_3', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>В отличии от обычных ботов фракции, также существуют и APD - не поддающиеся логике из-за своей враждебности
        древние машины. Они - противники для всех синтетов из любых фракций. Доподлинно неизвестно откуда они прибывают
        и какую именно преследуют цель. Компенсируя своё устаревшее вооружение и слабые по сравнению с синтетами
        дедуктивные функции, численным превосходством. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_2', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <h2 class="head">Взаимодействие с ботами</h2>
      <p>У ботов есть система «отношений» с другими ботами или игроками. Отношения могут варьироваться от «вражды» до
        «отличных». От этого зависит поведение бота по отношению к другим.</p>

      <p>Чтобы понять, как бот относится к игроку, нужно выделить его с помощью клавиши <span
        class="importantly">TAB</span>. Появится рамка, цвет которой говорит об отношении бота. Если рамка синяя или
        голубая, это значит, что бот «боится», независимо от его отношения к вам.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_1', 'png')+ ')',
        width: '400px',
        height: '93px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>Выделение цели также полезно для того, чтобы следить за ней в «тумане войны», так как цель остаётся помеченной
        до тех пор, пока она находится в зоне работы радара.</p>

      <p>Боты не только взаимодействуют с игроками, но и постоянно общаются друг с другом с помощью системы запросов.
        Например, бот может попросить помощи или «кредитов за проезд» у другого бота или игрока.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_4', 'png')+ ')',
        width: '265px',
        height: '194px',
        marginLeft: 'calc(50% - 132px)',
        }"/>

      <p>Игроки также могут взаимодействовать с ботами (и даже с другими игроками, хотя и без диалогов) с помощью этого
        способа через выделение цели.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_5', 'gif')+ ')',
        width: '400px',
        height: '175px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>На текущий момент доступны такие запросы:</p>
      <ul>
        <li>"<span class="importantly">Ограбить</span>" - при успешном срабатывании вы получаете кредиты.</li>
        <li>"<span class="importantly">Вытряхивай трюм</span>" - при успешном срабатывании выбрасывает случайное
          количество предметов в мир.
        </li>
        <li>"<span class="importantly">Совместное нападение</span>" - при успешном срабатывании бот нападает на
          указанную цель.
        </li>
        <li>"<span class="importantly">Оставить в покое</span>" - при успешном срабатывании бот прекращает атаковать
          текущую цель.
        </li>
        <li>"<span class="importantly">Мир</span>" - при успешном срабатывании отношение с ботом становится нейтральным,
          и он перестаёт вас атаковать.
        </li>
      </ul>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Bots</h2>
      <p>NPCs (extras) conduct their own activities in the sectors of the game world. They are the same synthetics as
        players, which is why bots can often be found doing familiar tasks for the player: resource extraction,
        territory protection, participation in combat actions, cargo transportation, and more.</p>

      <p>Bots are a living part of the world that sharply reacts to the actions of the player and other bots.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_3', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>Unlike regular faction bots, there are also AOP/APD - ancient machines that are illogical due to their
        hostility. They are enemies for all synthetics from any faction. It is not known for certain where they come
        from and what exact goal they pursue. Compensating for their outdated weaponry and deductive functions, which
        are weak compared to synthetics, with numerical superiority.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_2', 'png')+ ')',
        width: '400px',
        height: '283px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <h2 class="head">Interaction with bots</h2>
      <p>The bots have a system of «relationships» with other bots or players that ranges from «hostility» to
        «excellent» and determines the bot's behavior towards others.</p>

      <p>To understand how a bot feels about a player, you need to select it using the <span
        class="importantly">TAB</span> key. A frame will appear, the color of which indicates the bot's attitude. If the
        frame is blue or light blue, it means that the bot is «afraid», regardless of its relationship to you.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_1', 'png')+ ')',
        width: '400px',
        height: '93px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>Selecting a target is also useful for keeping track of it in the «fog of war», as the target remains marked as
        long as it is within the radar’s range.</p>

      <p>Bots not only interact with players but also constantly communicate with each other through a query system. For
        example, bot can ask for help or «travel credits» from another bot or player.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_4', 'png')+ ')',
        width: '265px',
        height: '194px',
        marginLeft: 'calc(50% - 132px)',
        }"/>

      <p>Players can also interact with bots (and even with other players, although without dialogues) using this method
        by selecting a target.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('npc_5', 'gif')+ ')',
        width: '400px',
        height: '175px',
        marginLeft: 'calc(50% - 200px)',
        }"/>

      <p>At the moment, the following requests are available:</p>
      <ul>
        <li>"<span class="importantly">Rob</span>" - if successful, you get credits.</li>
        <li>"<span class="importantly">Empty the hold</span>" - if successful, a random number of items are thrown into
          the world.
        </li>
        <li>"<span class="importantly">Joint attack</span>" - if successful, the bot attacks the specified target.
        </li>
        <li>"<span class="importantly">Leave alone</span>" - if successful, the bot stops attacking the current target.
        </li>
        <li>"<span class="importantly">Peace</span>" - if successful, the relationship with the bot becomes neutral and
          it stops attacking you.
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "Npc",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
