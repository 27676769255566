var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Контакты")]),_vm._v(" "),_c('p',[_vm._v("Меню контактов позволяет добавлять контакты и друзей что бы в будущем их не терять. Так же можно заблокировать\n      игрока что бы он не могу вам писать.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('friends', 'png')+ ')',
          width: '183px',
          height: '213px',
          marginLeft: 'calc(50% - 91px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Друзей от контактов отличает то что друзей видно когда они онлайн.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Contacts")]),_vm._v(" "),_c('p',[_vm._v("The contacts menu allows you to add contacts and friends so that you don't lose them in the future. You can\n      also block\n      a player so they can't write to you.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('friends', 'png')+ ')',
          width: '183px',
          height: '213px',
          marginLeft: 'calc(50% - 91px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The difference between friends and contacts is that you can see when friends are online.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }