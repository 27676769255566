import {Scene} from "../create";
import {FlyLaser} from "../bullet/laser";

function sunCityQuest(data, pos) {
  let sunCityMap = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: "sun_city",
    add: true
  });
  sunCityMap.setOrigin(0.5)
  sunCityMap.setDisplaySize(170, 170)
  sunCityMap.setDepth(900)

  let sunCityMapShadow = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: "sun_city",
    add: true
  });
  // sunCityMapShadow.setAlpha(0.5)
  sunCityMapShadow.setOrigin(0.5)
  sunCityMapShadow.setDisplaySize(170, 170)
  sunCityMapShadow.setDepth(900)
  sunCityMapShadow.setTint(0xffffff)

  let timeTick = 64
  Scene.time.addEvent({
    delay: timeTick,
    repeat: 10000 / timeTick,
    callback: function () {
      for (let c of [{x: 1669, y: 1969}, {x: 1901, y: 1799}, {x: 2229, y: 1871}, {x: 2371, y: 2107},
        {x: 2211, y: 2369}]) {

        let data = {
          to_x: pos.x + getRandomArbitrary(-50, 50), // random
          to_y: pos.y + getRandomArbitrary(-50, 50),
          x: c.x,
          y: c.y,
        }

        FlyLaser(data, 'super_small_blue')
      }
    },
    loop: false
  });

  Scene.time.addEvent({
    delay: 10000,
    callback: function () {
      sunCityMap.destroy();
      sunCityMapShadow.destroy();
    }
  })
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export {sunCityQuest}
