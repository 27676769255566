import {gameStore} from "../store";
import {Scene} from "../create";
import store from "../../store/store";
import {addIgnoreObject} from "../interface/mini_map";

let regexRemoveHtmlTags = /(<([^>]+)>)/ig

function showMessage(msg) {

  if (!gameStore.unitReady) {
    return
  }

  let unit;
  for (let i in gameStore.units) {
    if (gameStore.units[i].owner_id === Number(msg.user_id)) {
      unit = gameStore.units[i];
    }
  }

  if (!unit) {
    return;
  }

  let text = msg.message;
  if (msg.bot) {
    let data = JSON.parse(msg.message);
    text = data[store.getters.getSettings.Language];
  }

  text = text.replace(regexRemoveHtmlTags, "");
  if (text.length > 20) {
    text = text.substring(0, 20) + "...";
  }

  if (!unit.sprite.userMessage) unit.sprite.userMessage = {}

  if (unit.sprite.userMessage.text) {
    unit.sprite.userMessage.text.destroy();
  }

  unit.sprite.userMessage.text = Scene.add.bitmapText(unit.sprite.x, unit.sprite.y - 45, 'bit_text', text, 10);
  unit.sprite.userMessage.text.setOrigin(0.5, 0);
  unit.sprite.userMessage.text.setLetterSpacing(1)
  unit.sprite.userMessage.text.setDepth(1001);
  addIgnoreObject(unit.sprite.userMessage.text);

  let textSprite = unit.sprite.userMessage.text;
  Scene.time.addEvent({
    delay: 3500,
    callback: function () {
      textSprite.destroy();
    },
  });
}

export {showMessage}
