import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {gameStore} from "../store";
import {GetItemByTypeAndID} from "../utils/get_item_by_type_and_id";
import {getIconPath} from "./equip_bar";

let cacheNumber = {};

function HitItem(count, type, id, x, y) {

  let bp = type === 'blueprints'
  let item = GetItemByTypeAndID(type, id)
  if (bp) {
    type = item.item_type
    item = GetItemByTypeAndID(item.item_type, item.item_id)
  }

  function randomInt(min, max) {
    return min + Math.floor((max - min) * Math.random());
  }

  let allTime = 1250
  let pos = GetGlobalPos(x, y, gameStore.map.id);
  x = pos.x;
  y = pos.y;

  x += randomInt(-15, 15);
  y += randomInt(-15, 15);

  if (count > 0) {
    count = "+" + count
  }

  // Texture key already in use: number_+8
  if (!cacheNumber[count]) {

    let text = Scene.add.text(0, 0, count, {
      font: "48px Arial Black",
      fill: "#fff"
    });
    text.setStroke('#000', 6);

    let rt = Scene.add.renderTexture(0, 0, 256, 64);
    rt.draw(text, 0, 0);
    rt.saveTexture("number_" + count);

    rt.destroy();
    text.destroy();

    cacheNumber[count] = true;
  }

  let key = type + '_' + item.name
  Scene.load.image(key, getIconPath({type: type, item: {name: item.name}}))
  Scene.load.once(Phaser.Loader.Events.COMPLETE, () => {

    let text = Scene.make.sprite({
      x: x,
      y: y,
      key: "number_" + count,
      add: true
    });

    text.setDepth(1001);
    text.setDisplaySize(40, 10);
    text.setOrigin(0, 0.5);

    MoveSprite(text, text.x, text.y - 100, allTime, null);
    Scene.tweens.add({
      targets: text,
      alpha: 0,
      ease: 'Linear',
      duration: allTime / 2,
      delay: allTime / 2,
    });

    let back
    if (bp) {
      back = Scene.make.sprite({
        x: x,
        y: y,
        key: "items",
        frame: "blueprint",
        add: true
      });
      back.setDepth(1001);
      back.setOrigin(1, 0.5);
      back.setDisplaySize(10, 10);

      MoveSprite(back, back.x, back.y - 100, allTime, null);
      Scene.tweens.add({
        targets: back,
        alpha: 0,
        ease: 'Linear',
        duration: allTime / 2,
        delay: allTime / 2,
      });
    }

    let itemIcon = Scene.make.sprite({
      x: x,
      y: y,
      key: key,
      add: true
    });
    itemIcon.setDepth(1002);
    itemIcon.setOrigin(1, 0.5);
    itemIcon.setDisplaySize(10, 10);

    MoveSprite(itemIcon, itemIcon.x, itemIcon.y - 100, allTime, null);
    Scene.tweens.add({
      targets: itemIcon,
      alpha: 0,
      ease: 'Linear',
      duration: allTime / 2,
      delay: allTime / 2,
    });

    Scene.time.addEvent({
      delay: allTime,
      callback: function () {
        text.destroy();
        itemIcon.destroy();
        if (back) back.destroy();
      },
      loop: false
    });
  })
  Scene.load.start();
}

export {HitItem}
