import {GetGlobalPos} from "../map/gep_global_pos";
import {GreenExplosion} from "../weapon/explosion";
import {PlayPositionSound} from "../sound/play_sound";
import {gameStore} from "../store";

function Jump(x, y, angle) {
  let pos = GetGlobalPos(x, y, gameStore.map.id);

  PlayPositionSound(['teleport_jump'], null, pos.x, pos.y);
  GreenExplosion(pos.x, pos.y, 400, 40, 40, 500, angle - 5, angle + 5, 50);
}

export {Jump}
