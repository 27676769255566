import { render, staticRenderFns } from "./CorporationSiege.vue?vue&type=template&id=65d105a6&scoped=true"
import script from "./CorporationSiege.vue?vue&type=script&lang=js"
export * from "./CorporationSiege.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d105a6",
  null
  
)

export default component.exports