<template>
  <div id="NewChatCreateWrapper" ref="NewChatCreateWrapper" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name_2'][language]"
                 v-bind:move="true" v-bind:close="true" v-bind:no-height="true"
                 v-bind:refWindow="'NewChatCreateWrapper'"/>

    <div id="NewChatCreate">

      <input id="nameNewChatGroup" v-model="name" type="text" :placeholder="texts['text_1'][language]">
      <input id="passNewChatGroup" v-model="password" type="text" :placeholder="texts['text_2'][language]">

      <div id="avatarNewChatGroupWrapper">
        <div id="avatarNewChatGroup" v-bind:style="{backgroundImage: `url('` + avatar + `')`} "></div>
        <input style="position: absolute; left: -9999px;" type="file" @change="previewAvatarGroup($event)"
               name="uploadFile" id="file"/>
        <label for="file" id="labelFile">{{ texts['text_3'][language] }}</label>
      </div>

      <div id="greetingsNewChatGroupWrapper">
        <h4>{{ texts['text_4'][language] }}</h4>
        <textarea v-model="greetings" id="greetingsNewChatGroup"/>
      </div>

      <input type="button"
             :value="texts['button_1'][language]" style="float: right; margin-right: 25px"
             @click="create">

      <input type="button"
             :value="texts['button_2'][language]" style="float: left; margin-left: 25px"
             @click="closeWindow">
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "CreateNewGroup",
  data() {
    return {
      name: '',
      password: '',
      avatar: '',
      greetings: '',
    }
  },
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    create() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "chat",
        data: {
          event: "CreateNewChatGroup",
          password: this.password,
          file: this.avatar,
          name: this.name,
          greetings: this.greetings,
        }
      }));
      this.closeWindow()
    },
    closeWindow() {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'NewChatCreateWrapper',
        component: '',
      });
      this.$store.commit({
        type: 'toggleWindow',
        id: 'allGroupsWindow',
        component: '',
      });
    },
    previewAvatarGroup(e) {
      let app = this;

      let file_reader = new FileReader(e.target.files[0]);
      file_reader.readAsDataURL(e.target.files[0]);
      file_reader.onload = function (evt) {
        app.avatar = evt.target.result;
      };
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#NewChatCreateWrapper {
  height: 197px;
  width: 300px;
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 150px);
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  pointer-events: auto;
}

#nameNewChatGroup, #passNewChatGroup {
  float: left;
  margin: 0 10px 2px;
  width: 263px;
}

#avatarNewChatGroupWrapper {
  float: left;
  clear: both;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  box-shadow: inset 0 0 3px;
  border-radius: 5px;
  position: relative;
}

#avatarNewChatGroupWrapper label {
  position: absolute;
  bottom: 5px;
  left: calc(50% - 46px);
  width: 71px;
  font-size: 12px;
  background: #79a0b4;
  border-radius: 5px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  padding: 0 10px;
  box-shadow: 0 0 2px black;
  opacity: 0.3;
  transition: 0.2s;
}

#avatarNewChatGroupWrapper label:hover {
  opacity: 1;
}

#avatarNewChatGroupWrapper label:active {
  transform: scale(0.97);
}

#avatarNewChatGroup {
  height: 100%;
  width: 100%;
  background-size: cover;
}

#greetingsNewChatGroupWrapper {
  float: left;
  height: 100px;
  width: 100px;
  margin-left: 10px;
}

#greetingsNewChatGroupWrapper h4 {
  text-align: left;
  height: 14px;
  font-size: 9px;
  margin: 0 0 2px -4px;
  border-radius: 0 20px 0 0;
  padding-left: 11px;
  border: 1px solid #793f1f;
  background-size: 10px 2px;
  background-image: linear-gradient(1deg, rgba(251, 93, 0, 0.55), rgba(225, 37, 46, 0.6) 6px);
  color: #ff7800;
  line-height: 15px;
  width: 162px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#greetingsNewChatGroupWrapper textarea {
  box-shadow: inset 0 0 5px black;
  background: rgba(148, 166, 179, 0.3);
  background-size: 10px 3px;
  height: 61px;
  width: 150px;
  margin: 0 0 0 -4px;
  border-radius: 5px;
  padding: 10px;
  text-align: justify;
  resize: none;
  color: #ffef0f;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#NewChatCreate {
  border-radius: 5px;
  width: calc(100% - 8px);
  height: 165px;
  margin: 24px auto 0;
  padding-top: 5px;
  box-shadow: inset 0 0 2px black;
  background: rgba(0, 0, 0, 0.2);
}

#NewChatCreateWrapper input[type="button"] {
  display: block;
  width: 34%;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin: 3px 5%;
}

#NewChatCreateWrapper input[type="button"]:hover {
  background: rgba(255, 129, 0, 1);
}

#NewChatCreateWrapper input[type="button"]:active {
  transform: scale(0.98);
}

#NewChatCreateWrapper h3 {
  display: block;
  height: 17px;
  background: linear-gradient(0deg, red, yellow);
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  font-size: 15px;
  margin: 3px 3px 0 3px;
  font-weight: 100;
  padding: 0 5px;
}
</style>
