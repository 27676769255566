import {gameStore} from "../store";
import {SelectSprite} from "../utils/select_sprite";
import store from '../../store/store'

function DestroyTargets() {
  RemoveAllSelectSprite();

  let callBack = function (pointer, data) {
    console.log(data)
    store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "SelectDestroyTarget",
        x: data.x,
        y: data.y,
      }
    }));

    setTimeout(function () {
      RemoveAllSelectSprite();
    }, 500)
  }

  for (let id in gameStore.objects) {
    let obj = gameStore.objects[id];
    if (!obj || obj.type === 'base' || obj.complete < 100) continue;

    if (obj.corporation_id > 0 && gameStore.playerCorporationID === obj.corporation_id && gameStore.mapCorporationID === gameStore.playerCorporationID) {
      obj.objectSprite.passBuildSelectSprite = SelectSprite(obj.objectSprite.x, obj.objectSprite.y, callBack, 0xffffff, 0x00ff00, obj.objectSprite.displayHeight + 5, obj);
    }
  }
}

function RemoveAllSelectSprite() {
  for (let i in gameStore.objects) {
    if (!gameStore.objects.hasOwnProperty(i) || !gameStore.objects[i]) continue;
    RemoveSelectSprite(gameStore.objects[i].objectSprite);
  }
}

function RemoveSelectSprite(obj) {
  if (obj.passBuildSelectSprite) {
    obj.passBuildSelectSprite.setVisible(false);
  }

  if (obj.noPassbuildSelectSprite) {
    obj.noPassbuildSelectSprite.setVisible(false);
  }
}

export {DestroyTargets, RemoveAllSelectSprite}
