import { render, staticRenderFns } from "./DamageLog.vue?vue&type=template&id=beab13a6&scoped=true"
import script from "./DamageLog.vue?vue&type=script&lang=js"
export * from "./DamageLog.vue?vue&type=script&lang=js"
import style0 from "./DamageLog.vue?vue&type=style&index=0&id=beab13a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beab13a6",
  null
  
)

export default component.exports