<template>
  <div class="usersStatusWrapper" v-if="user">

    <div class="userAvatarWrapper">
      <div class="userAvatar" v-bind:style="{backgroundImage: avatar}"></div>
    </div>

    <h3 class="userName">
      <template v-if="tag !== ''">[<span>{{ tag }}</span>]</template>
      {{ user.login }}
    </h3>

    <div style="float: left; width: calc(100% - 106px);">

      <div style="float: left; width: calc(100% - 125px);">
        <div class="section">
          <div class="fractionLogo"
               :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(user.fraction)).default+')'}"/>
          <span style="line-height: 18px;">{{ texts['fraction_2'][language] }}</span> <br>
          <span class="importantly">{{ user.fraction }}</span>
        </div>

        <div v-if="corporation.info" class="section cursor_hover"
             @click="$parent.openService('Corporation', {corp_name: corporation.info.name}, null,true)">
          <div class="fractionLogo" v-bind:style="{backgroundImage:  'url(\'' + corporation.logo + '\')'}"/>
          <span style="line-height: 18px;">{{ texts['cluster_2'][language] }}</span> <br>
          <span class="importantly">{{ corporation.info.name }}</span>
        </div>

        <div v-if="!corporation.info" class="section"
             style="line-height: 35px; font-size: 12px; color: white; text-align: center">
          {{ texts['no_cluster_2'][language] }}
        </div>
      </div>

      <app-user-sub-menu
        v-bind:no-head="true"
        v-bind:no-detail="true"
        v-bind:no-close="true"
        v-bind:userSubMenuProps="{x: 0, y: 0, user: user}"/>
    </div>


    <!--    <div class="actionPanel">-->
    <!--      <input type="button" value="Отправить сообщение"-->
    <!--             @mouseover="playSound('select_sound.mp3', 0.3)"-->
    <!--             @click="sendMessage">-->
    <!--      <input type="button" value="Предложить дружбу"-->
    <!--             @mouseover="playSound('select_sound.mp3', 0.3)"-->
    <!--             @click="sendInviteFriend">-->
    <!--      <input type="button" value="Пригласить в отряд"-->
    <!--             @mouseover="playSound('select_sound.mp3', 0.3)"-->
    <!--             @click="sendInviteGroup">-->
    <!--      &lt;!&ndash;      <input type="button" value="Пригласить в кластер"&ndash;&gt;-->
    <!--      &lt;!&ndash;             @mouseover="playSound('select_sound.mp3', 0.3)"&ndash;&gt;-->
    <!--      &lt;!&ndash;             @click="sendInviteCorp">&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>

<script>
import {urls} from '../../const';
import UserSubMenu from '../Chat/UserSubMenu';

export default {
  name: "OtherUser",
  props: ['user'],
  data() {
    return {
      avatar: null,
      corporation: {info: null, logo: null},
    }
  },
  created() {
    this.getAvatar();
    this.getCorp();
  },
  mounted() {
    this.$parent.resize(null, null, $(this.$parent.$el))
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getAvatar() {
      let app = this;
      app.$api.get(urls.avatarURL + '?user_id=' + app.$props.user.id).then(function (response) {
        app.$set(app, 'avatar', "url('" + response.data.avatar + "')");
      });
    },
    getCorp() {
      let app = this;
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + app.$props.user.corporation_id).then(function (response) {
        app.$set(app.corporation, 'info', response.data.corporation);
        app.getCorpLogo();
      });
    },
    getCorpLogo() {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + app.corporation.info.id).then(function (response) {
        app.$set(app.corporation, 'logo', response.data.avatar);
      });
    },
    getLogo(fraction) {
      return `logo/${fraction}.png`
    },
    sendMessage() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "chat",
        data: {
          event: "CreateNewPrivateGroup",
          id: Number(this.$props.user.id),
        }
      }));
    },
    sendInviteFriend() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "AddFriend",
          data: this.$props.user.login,
        }
      }));
    },
    sendInviteGroup() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "InviteToGroup",
          name: this.$props.user.login,
        }
      }));
    },
    sendInviteCorp() {
      return
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "InviteToCorp",
          name: this.$props.user.login,
        }
      }));
    }
  },
  computed: {
    tag() {
      if (this.corporation.info) {
        return this.corporation.info.tag
      } else {
        return ""
      }
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('UserStat')
    },
  },
  components: {
    AppUserSubMenu: UserSubMenu
  }
}
</script>

<style scoped>
.userAvatar, .userAvatarWrapper, .fractionLogo {
  background-color: rgb(128, 128, 128);
  height: 100px;
  width: 100px;
  float: left;
  margin: -1px 5px 5px -1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
}

.userName {
  margin: 0 0 7px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.userName span {
  color: #e4ff00;
  font-size: 10px;
}

.fractionLogo {
  height: 35px;
  width: 35px;
}

.section {
  line-height: 16px;
  clear: both;
  background: rgba(77, 77, 84, 0.3);
  width: 100%;
  height: 35px;
  border-radius: 0 5px 5px 0;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  box-shadow: 0 0 2px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.section:first-child {
  margin-bottom: 5px;
}

.biography {
  box-shadow: inset 0 0 2px black;
  background: #adc6cd;
  padding: 5px 1px 5px 5px;
  word-wrap: break-word;
  overflow-y: scroll;
  border-radius: 5px;
  text-align: left;
  width: calc(100% - 5px);
  text-shadow: #28a5e4 0 0 4px;
  font-size: 9pt;
  float: left;
  resize: none;
  overflow-x: hidden;
  margin: 0;
}

.actionPanel {
  float: left;
  width: 103px;
  margin-left: 4px;
  margin-top: -2px;
}

.actionPanel input {
  font-size: 9px;
  width: 105px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  margin: 2px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.actionPanel input:hover {
  background: rgba(255, 129, 0, 1);
}

.actionPanel input:active {
  transform: scale(0.98);
}

.section.pointer {
}
</style>
<style>
.usersStatusWrapper #chatSubMenu {
  background: none !important;
  border: none !important;
  position: unset !important;
  float: left !important;
  width: 121px !important;
  margin-left: 4px !important;
  padding: 0;
}

.usersStatusWrapper #chatSubMenu .wrapper {
  padding: 1px 1px 3px 3px;
  height: 72px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: none;
  box-shadow: inset 0 0 2px black;
}
</style>
