<template>
  <section class="section">

    <div
      style="padding-left: calc(50% - 345px); width: 100%; float: left; clear: both; filter: drop-shadow(2px 4px 6px black);">
      <iframe src="https://store.steampowered.com/widget/2828170/?utm_source=veliri_main_page"
              frameborder="0"
              width="646"
              height="190"></iframe>
    </div>

    <template v-if="lang==='RU'">
      <h4 class="headSection">О игре</h4>

      <p>Veliri. Некогда безжизненная планета, повидавшая на своём веку многое, ныне снова раздирается войной за влияние
        между тремя фракциями когда-то единой синтетической формы жизни - военизированными Replics, любознательными
        Explores и целеустремлёнными Reverses. Подражая своим создателям, три разумных центра ИИ сформировали своё
        общество. Исследование, торговля, закон, преступность, война. И вы.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/2.jpg').default"/>

      <p>Окунитесь в живой и развивающийся открытый мир экономической онлайн RPG жанра "privateer" с элементами
        выживания
        и ответьте на вопрос - кто вы в этом мире?</p>

      <h3>СВОБОДА ВЫБОРА</h3>

      <p>Будете ли вы сами по себе, встанете на чью-либо сторону, или же вовсе захотите бросить вызов вообще всем?
        Будете
        ли вы Исследователем, Торговцем, Производителем, Строителем, Воином, или же Бандитом? А может вы захотите узнать
        тайны истории этого места, столкнувшись с теми, кто был здесь до вас?</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/4.png').default"/>

      <h3>ЖИВОЙ И РАЗВИВАЮЩИЙСЯ МИР</h3>

      <p>Вам решать. Мир игры живой и постоянно меняется как другими игроками, так и без них с подачи NPC. Глобальная
        война за территории и репутацию не прекращается ни на минуту. Сектора постоянно переходят от одной фракции к
        другой. В каждом из них появляются новые строения - как военного, так и иного назначения, влияющие как на силу
        фракции, так и на экономику.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/3.png').default"/>

      <h3>ЖИВАЯ ЭКОНОМИКА</h3>

      <p>Кстати об экономике. Пока одни воюют за территории, другие делают на этом деньги. А ведь экономика - это тоже
        поле боя. Глобальные цены на всё в игре меняются от любого влияния на них как предприимчивыми
        торговцами-одиночками, так и целыми корпорациями, что рождаются и исчезают здесь в реальном времени. Налоги,
        пошлины, спекуляция, демпинг - всё это не чуждо как игрокам, так и NPC.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/5.png').default"/>

      <h3>ШИРОКИЙ ВЫБОР ТРАНСПОРТА</h3>

      <p>Для участия во всём этом вам понадобятся колёса. А может гусеницы? Или же вы больше по антигравам? Игра
        позволит
        вам создать своё уникальное транспортное средство, выбрав из множества деталей, корпусов и даже оружия. Без
        оружия
        здесь никуда. Захотите ли вы купить всё необходимое, или же создать самим? А может и вовсе найти или
        отобрать?</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/1.png').default"/>

      <h3>ХАРДКОРНЫЙ ГЕЙМПЛЕЙ</h3>

      <p>Помните лишь то, что всё, что вы найдёте или создадите - вы также легко и потеряете, чтобы потом начать заново.
        Всё, что вы потеряли - найдут другие. Всё, что потеряли другие - найдёте вы.</p>

      <p>Дерзнёте ли вы выжить на этой планете? Veliri ждёт вас!</p>

      <div class="video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/VOnRUjXt8Sc?si=sAvjNyiAXr8yaUxc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
      </div>


    </template>
    <template v-if="lang==='EN'">
      <h4 class="headSection">About This Game</h4>

      <p>Veliri. The once lifeless planet, having seen a thing in its lifetime, is now once again torn apart by a war
        for influence between factions, once a single synthetic life form - militarized Replicas, inquisitive Research
        and purposeful Reverse Actions. Imitating their creators, the three intelligent AI centers formed their own
        society. Research, trade, law, crime, war. And you.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/2.jpg').default"/>

      <p>Plunge into a living and developing open world, economic online RPG of the privateer genre with elements of
        survival and answer the question - who are you in this world?</p>

      <h3>FREEDOM OF CHOICE</h3>

      <p>Will you be on your own, will you take sides, or will you even want to challenge everyone? Will you be an
        Explorer, Trader, Manufacturer, Builder, Warrior or Bandit? Can you learn the secrets of the history of this
        place by encountering those who were here with you?</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/4.png').default"/>

      <h3>LIVING AND DEVELOPING WORLD</h3>

      <p>You decide. The game world is alive and constantly changing, just like other players, and without them being
        provided by NPCs. The global war for territory and reputation does not stop for a minute. Sectors are constantly
        moving from one participant to another. In each of them, new structures are being built - both military and for
        other purposes, influencing both the forces of resistance and the consequences.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/3en.png').default"/>

      <h3>LIVING ECONOMY</h3>

      <p>Speaking of economics. While some are fighting for territory, others are making money from it. But the economy
        is also a battlefield. Global prices for everything in the game change from everyone to both enterprising solo
        traders and targeted suppliers who are born and disappeared here at first. Taxes, duties, speculation, dumping -
        all this is not alien to both players and NPCs.</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/5en.png').default"/>

      <h3>WIDE CHOICE OF TRANSPORTATION</h3>

      <p>To participate in all this you will need wheels. Or maybe caterpillars? Or are you more into anti-gravity? The
        game will allow you to create your own unique vehicle by choosing a large number of parts, bodies and even
        weapons. There is no place here without weapons. Do you want to buy everything you need, or create it yourself?
        Or maybe even find it or take it away?</p>

      <img class="main_img" :src="require('../../assets/indexImg/main/1en.png').default"/>

      <h3>HARDCORE GAMEPLAY</h3>

      <p>Just remember that everything you find or create, you can also easily lose, so that you can then start all over
        again. All you have lost is the attention of others. Everything that others have lost, you will find.</p>

      <p>Dare you, can you survive on this planet? Veliri is waiting for you!</p>

      <div class="video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/VOnRUjXt8Sc?si=sAvjNyiAXr8yaUxc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "AboutGame",
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.headSection {
  color: #ffc588;
}

.video {
  margin: auto;
  margin-bottom: 10px;
  width: 560px;
  height: 315px;
  filter: drop-shadow(2px 4px 6px black);
  position: relative;
  z-index: 0;
}

.video iframe {
  width: 100%;
  height: 100%;
  margin-left: -24px;
}

p {
  color: #dad7ca;
  font-weight: bold;
}

h3 {
  color: #ffc588;
}

.main_img {
  width: 616px;
  margin-left: calc(50% - 308px);
  filter: drop-shadow(2px 4px 6px black);
}

@media (max-width: 602px) {
  .video {
    width: 320px;
    height: 250px;
  }
}

@media (max-width: 370px) {
  .video {
    width: 240px;
    height: 200px;
  }
}

@media (max-width: 650px) {
  .headSection {
    font-size: 13px;
  }

  .section {
    font-size: 10px;
  }
}
</style>
