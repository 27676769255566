<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Снаряжение</h2>

      <p>Для управления своим снаряжением, игроку необходимо разместить свой выбор на специализированной панели.</p>

      <p>Располагаете вы оружие или какое-либо устройство, сделать это можно посредством перетаскивания данного предмета
        из ангара/трюма в нужную ячейку панели снаряжения. </p>

      <p>Если же вам требуется убрать из панели снаряжения вооружение/устройство - просто наведите курсор на необходимую
        ячейку и вытащите/выбросите требуемое за её пределы.</p>

      <p>Аналогичным образом работает и перекладывание вооружения/устройств в иную ячейку - перетаскивать требуемое с
        помощью курсора.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_1', 'gif')+ ')',
            width: '443px',
            height: '320px',
            marginLeft: 'calc(50% - 221px)',
            }"/>
      <p>Фон указывает на тип объекта расположеного в ячейке.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_3', 'png')+ ')',
            width: '400px',
            height: '271px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <p>"Панель снаряжения" во внешнем мире выглядит вот так. Через эту панель нельзя назначить или менять местами
        снаряжение.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_2', 'png')+ ')',
            width: '288px',
            height: '131px',
            marginLeft: 'calc(50% - 187px)',
            }"/>
      <p><span class="importantly">Манипулятор</span> в 1 ячейке внешнего мира позволяет взаимодействовать с объектами
        (открывать ящики и меню сооружений).</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Equipment</h2>

      <p>To manage your equipment, the player needs to place their choice on a specialized panel.</p>

      <p>Whether you are placing a weapon or any device, this can be done by dragging the item
        from the hangar/hold to the desired slot on the equipment panel. </p>

      <p>If you need to remove a weapon/device from the equipment panel - simply hover the cursor over the required
        slot and drag/drop the item out of it.</p>

      <p>Transferring weapons/devices to another slot works in a similar way - drag the required item
        with the cursor.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_1', 'gif')+ ')',
            width: '443px',
            height: '320px',
            marginLeft: 'calc(50% - 221px)',
            }"/>
      <p>The background indicates the type of object located in the slot.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_3', 'png')+ ')',
            width: '400px',
            height: '271px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <p>The "Equipment Panel" in the outside world looks like this. Through this panel, you cannot assign or swap
        equipment.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('control_2', 'png')+ ')',
            width: '288px',
            height: '131px',
            marginLeft: 'calc(50% - 187px)',
            }"/>
      <p>The <span class="importantly">Manipulator</span> in slot 1 of the outside world allows you to interact with
        objects (open boxes and building menus).</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "EquipPanel",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
