<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Explores</h2>

      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('Explores', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
            }"/>
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('explores_logo_min', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
              marginLeft: '50px'
            }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">“Воля - наша единственная движущая сила.
          Окно реальности, за которым лежит истинный внешний мир. Посредством наших умственных способностей, мы сумеем
          познать данный мир и погрузиться в него…”
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Explores</i>
        </p>
      </div>

      <br>

      <p>Explores - фракция, чья иерархическая основа - конгломерат великого множества разумов, без устали трудящихся
        над
        неисчислимым множеством вопросов как настоящего, так и прошлого.</p>

      <p>Познание, научные открытия, искусственная эволюция естества - вот, что отличает данную фракцию от всех
        прочих. </p>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Explorers</h2>

      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('Explores', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
            }"/>
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('explores_logo_min', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
              marginLeft: '50px'
            }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">"Will is our only driving force.
          A window to reality, behind which lies the true external world. Through our mental abilities, we will be able
          to comprehend this world and immerse ourselves in it…"
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Explorers</i>
        </p>
      </div>

      <br>

      <p>The Explorers - a faction whose hierarchical foundation is a conglomerate of a great multitude of minds,
        tirelessly working
        on an innumerable number of questions of both the present and the past.</p>

      <p>Knowledge, scientific discoveries, artificial evolution of nature - that's what distinguishes this faction from
        all
        others. </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Explores",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
