var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Replics")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('Replics', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('replics_logo_min', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
            marginLeft: '50px'
          })}),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("Replics - автократической формы правления фракция синтетиков. Приверженец теории “войны за независимость с\n      предтечами” и яркий отголосок неумолимого экспансионизма. Подчинение, ассимиляция, распространение - вот\n      основные\n      движущие принципы данной фракции. ")]),_vm._v(" "),_c('p',[_vm._v("Любой мир - великий производственный цех. Любая продукция - мощности для покорения всего материального.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Replics")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('Replics', 'png')+ ')',
      width: '150px',
      height: '150px',
      float: 'left',
    })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('replics_logo_min', 'png')+ ')',
      width: '150px',
      height: '150px',
      float: 'left',
      marginLeft: '50px'
    })}),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("Replics - an autocratic form of governance faction of synthetics. An adherent of the theory of \"war for\n      independence with the precursors\" and a vivid echo of relentless expansionism. Subjugation, assimilation,\n      dissemination - these are the main driving principles of this faction.")]),_vm._v(" "),_c('p',[_vm._v("Any world is a great production workshop. Any product is power for conquering all that is material.")])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("“Истинный синтет не имеет страха перед\n        забвением и ничтожным существованием будущего. Он боится\n        лишь бесславия и отсутствия своего пути.”\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Replic")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("\"A true synthetic has no fear of\n        oblivion and the insignificant existence of the future. He only fears disgrace and the absence of his path.\"\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Replic")])])
}]

export { render, staticRenderFns }