import {gameStore} from "../store";
import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {PlayPositionSound} from "../sound/play_sound";
import {sunCityQuest} from "../unit/sun_city_quest";
import {addIgnoreObject} from "../interface/mini_map";

function ExplosionBullet(data) {
  if (!gameStore.gameReady) return;

  let infoBullet = gameStore.gameTypes.ammo[data.type_id];
  if (!infoBullet) return;

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y) && !infoBullet.force_animate && infoBullet.name !== "storage_of_souls_destroy") {
    return;
  }

  if (infoBullet.name === "blue_wave") {
    ExplosionRing(pos.x, pos.y, 3000, infoBullet.area_covers, data.z);
  }

  if (infoBullet.name === "cargo" || infoBullet.name === "blueprint" || infoBullet.name === "organic" || infoBullet.name === "oil" || infoBullet.name.includes('ore')) {
    return;
  }

  if (infoBullet.name === "nuke_missile") {
    PlayPositionSound(['explosion_5'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 500, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 1000, 10, 275, 55, 0, 360, 500);
    SmokeExplosion(pos.x, pos.y, 750, 10, 275, 20, 0, 360, 500);
    return;
  }

  if (infoBullet.name === "aim_ghost_missile_bullet") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 1000, 6, 120, 35, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 750, 6, 120, 35, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "unknown_civilization_9") {
    PlayPositionSound(['explosion_5'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 500, infoBullet.area_covers, 900);
    FireExplosion(pos.x, pos.y, 1000, 10, 275, 55, 0, 360, 900);
    SmokeExplosion(pos.x, pos.y, 750, 10, 275, 20, 0, 360, 900);
    return;
  }

  if (infoBullet.name === "unknown_civilization_10") {
    PlayPositionSound(['explosion_5'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 500, infoBullet.area_covers, 900);
    FireExplosion(pos.x, pos.y, 1000, 10, 150, 55, 0, 360, 900);
    SmokeExplosion(pos.x, pos.y, 750, 10, 150, 20, 0, 360, 900);
    return;
  }

  if (infoBullet.name === "big_missile_bullet" || infoBullet.name === "aim_big_missile_bullet") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 500, 6, 60, 35, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 500, 6, 60, 35, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "medium_missile_bullet" || infoBullet.name === "aim_medium_missile_bullet") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 500, 4, 40, 35, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 500, 4, 40, 35, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "piu-piu_6") {
    PlayPositionSound(['explosion_3x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    FireExplosion(pos.x, pos.y, 250, 5, 20, 10, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 250, 5, 20, 10, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "piu-piu" || infoBullet.name === "ballistics_artillery_bullet_4") {
    PlayPositionSound(['explosion_3x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 200, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 500, 4, 30, 10, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 4, 30, 10, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "piu-piu_4" || infoBullet.name === "ballistics_artillery_bullet_3") {
    PlayPositionSound(['explosion_4x3'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 250, 3, 10, 10, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 250, 3, 10, 10, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "small_missile_bullet") {
    // TODO sound
    PlayPositionSound(['explosion_3x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 250, 5, 15, 10, 0, 360, data.z);
    return;
  }


  if (infoBullet.name === "piu-piu_5" || infoBullet.name === "piu-piu_2" || infoBullet.name === "piu-piu_3") {
    PlayPositionSound(['explosion_4x3'], null, pos.x, pos.y, false, undefined, 'explosions');
    FireExplosion(pos.x, pos.y, 75, 2, 10, 1, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 75, 1, 10, 1, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "ballistics_artillery_bullet") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 150, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 750, 7, 75, 55, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1200, 7, 75, 35, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "mine_bomb") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 150, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 500, 10, 60, 55, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 10, 60, 35, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "mine_detonation") {
    // todo звук активации мины
    RedExplosion(pos.x, pos.y, 1000, 1, 30, 0, 0, 0, data.z);
    return
  }

  if (infoBullet.name === "drone_destroy") {
    PlayPositionSound(['drone_explosion'], null, pos.x, pos.y, false, 0.5, 'explosions');
    FireExplosion(pos.x, pos.y, 500, 5, 15, 15, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 5, 15, 15, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "grenade_1") {
    PlayPositionSound(['large_explosion'], null, pos.x, pos.y, false, 0.5, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    BlueExplosion(pos.x, pos.y, 500, 5, 60, 50, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 5, 60, 50, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "suicide_1") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, 0.5, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    RedExplosion(pos.x, pos.y, 500, 5, 60, 50, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 5, 60, 50, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "ballistics_artillery_bullet_2" || infoBullet.name === "digger_1") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, 0.5, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    BlueExplosion(pos.x, pos.y, 500, 45, 50, 150, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 700, 15, 15, 150, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "big_lens_1_additional") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, 0.5, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    BlueExplosion(pos.x, pos.y, 400, 25, 35, 50, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 500, 15, 15, 50, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "meteorite1") {
    PlayPositionSound(['explosion_1x2', 'explosion_5x2'], null, pos.x, pos.y, false, 0.5, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 1500, 25, 100, 50, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 2000, 15, 35, 75, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "plant_mine_1") {
    // todo sound
    ExplosionRing(pos.x, pos.y, 150, infoBullet.area_covers, data.z);
    GreenExplosion(pos.x, pos.y, 500, 5, 50, 55, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "plant_mine_1_cluster") {
    // todo sound
    FireExplosion(pos.x, pos.y, 500, 5, 15, 15, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 1000, 5, 15, 15, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "plant_mine_2") {
    // todo звуки кислоты
    GreenExplosionSCREEN(pos.x, pos.y, 256, 1, infoBullet.area_covers, 55, 0, 360, data.z, null, {start: 0.2, end: 0});
    return;
  }

  if (infoBullet.name === "fauna_drone_ammo_1") {
    // todo звуки кислоты
    GreenExplosionSCREEN(pos.x, pos.y, 500, 5, 15, 15, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "fauna_smoke_screen") {
    return;
  }

  if (infoBullet.name === "radio_tower_destroy") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 550, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 1500, 15, 100, 75, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 3000, 15, 100, 75, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "random_buildings_3_2_1") {
    // TODO звук
    BlueExplosion(pos.x, pos.y, 8000, 2, 30, 0, 0, 0, 100, null, {start: 0.2, end: 1});
  }

  if (infoBullet.name === "random_buildings_3_2_2") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 250, infoBullet.area_covers, data.z);
    FireExplosion(pos.x, pos.y, 1000, 15, 50, 75, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 2000, 15, 50, 75, 0, 360, data.z);
    return
  }

  if (infoBullet.name === "random_buildings_3_2_3") {
    sunCityQuest(data, pos)
  }

  if (infoBullet.name === "harvester_1") {
    FireExplosion(pos.x, pos.y, 128, 10, 2, 75, 0, 360, data.z);
    return;
  }

  if (infoBullet.name === "resource_10") {
    // TODO звук
    console.log(pos.x, pos.y,data.z)
    GreenExplosionSCREEN(pos.x, pos.y, 3000, 10, 50, 0, 0, 360, data.z, true, {start: 0.5, end: 0});
  }

  if (infoBullet.name === "resource_9") {
    // TODO звук
    console.log(pos.x, pos.y,data.z)
    WhiteExplosion(pos.x, pos.y, 3000, 10, 50, 0, 0, 360, data.z, true, {start: 0.5, end: 0});
  }

  if (infoBullet.name === "storage_of_souls_destroy") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 1500, 2000, 900);
    FireExplosion(pos.x, pos.y, 2500, 35, 500, 100, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 4000, 35, 500, 100, 0, 360, data.z);

    Scene.time.addEvent({
      delay: 32,
      repeat: 2,
      callback: function () {
        ExplosionRing(pos.x, pos.y, 1500, 2000, 900);
      },
      loop: false
    });

    return
  }

  if (infoBullet.name === "base_destroy") {
    PlayPositionSound(['explosion_4x2'], null, pos.x, pos.y, false, undefined, 'explosions');
    ExplosionRing(pos.x, pos.y, 1500, 2000, 900);
    FireExplosion(pos.x, pos.y, 2500, 35, 450, 100, 0, 360, data.z);
    SmokeExplosion(pos.x, pos.y, 4000, 35, 450, 100, 0, 360, data.z);

    Scene.time.addEvent({
      delay: 128,
      repeat: 0,
      callback: function () {
        ExplosionRing(pos.x, pos.y, 1500, 2000, 900);
      },
      loop: false
    });
  }

  SmokeExplosion(pos.x, pos.y, 1000, 10, 15, 15, 0, 360, data.z);
}

function FireExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["yellow"], speed, minAngle, maxAngle, z, 'ADD', reverseScale, alpha, mask)
}

function RedExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["red"], speed, minAngle, maxAngle, z, 'ADD', reverseScale, alpha, mask)
}

function BlueExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["blue"], speed, minAngle, maxAngle, z, 'ADD', reverseScale, alpha, mask)
}

function BlueExplosionSCREEN(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["blue"], speed, minAngle, maxAngle, z, 'SCREEN', reverseScale, alpha, mask)
}

function SmokeExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["smoke_puff"], speed, minAngle, maxAngle, z, 'SCREEN', reverseScale, alpha, mask)
}

function GreenExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["green"], speed, minAngle, maxAngle, z, 'ADD', reverseScale, alpha, mask)
}

function GreenExplosionSCREEN(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["green"], speed, minAngle, maxAngle, z, 'SCREEN', reverseScale, alpha, mask)
}

function WhiteExplosion(x, y, time, count, scale, speed, minAngle, maxAngle, z, reverseScale, alpha, mask) {
  Explosion(x, y, time, count, scale, ["white"], speed, minAngle, maxAngle, z, 'SCREEN', reverseScale, alpha, mask)
}


let rings = [];

function ExplosionRing(x, y, time, radius, z) {

  let border;
  if (rings.length === 0) {
    border = Scene.make.image({
      x: x,
      y: y,
      key: 'flares',
      frame: 'explosion_border',
      add: true
    });
    border.setOrigin(0.5);
    border.setDepth(400);
  } else {
    border = rings.shift()
    border.setVisible(true)
    border.setPosition(x, y)
    border.setAlpha(1)
  }
  border.setDisplaySize(0, 0);

  addIgnoreObject(border)
  Scene.tweens.add({
    targets: border,
    props: {
      displayHeight: {value: radius * 3, duration: time},
      displayWidth: {value: radius * 3, duration: time},
      alpha: {value: 0, duration: time, delayTime: time},
    },
    repeat: 0,
    onComplete: function () {
      border.setVisible(false)
      rings.push(border)
    }
  });
}

let explosions = [];

function Explosion(x, y, time, count, scale, type, speed, minAngle, maxAngle, z,
                   blendMode, reverseScale, alpha, mask = undefined) {

  if (reverseScale) {
    scale = {start: scale / 200, end: scale / 25}
  } else {
    scale = {start: scale / 50, end: scale / 100}
  }

  if (!alpha) {
    alpha = {start: 1, end: 0}
  }

  let angle = getRandomArbitrary(minAngle, maxAngle)

  let emitter
  if (explosions.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: type,
      gravityY: 0,
      speed: {min: 0, max: speed},
      scale: scale,
      angle: {min: angle, max: angle},
      // rotate: {start: angle, end: angle},
      alpha: alpha,
      lifespan: {min: time / 2, max: time},
    });
  } else {
    emitter = explosions.shift();

    //   emitter.setFrame(type);
    emitter.setEmitterFrame(type);

    //   emitter.setSpeed({min: 0, max: speed})
    emitter.ops.speedX.end = speed;
    emitter.ops.speedY.end = speed;

    //   emitter.setScale(scale);
    emitter.ops.scaleX.start = scale.start;
    emitter.ops.scaleX.end = scale.end;

    //   emitter.setAngle(angle);
    emitter.ops.angle.start = angle;
    emitter.ops.angle.end = angle;

    //   emitter.setAlpha(alpha);
    emitter.ops.alpha.start = alpha.start;
    emitter.ops.alpha.end = alpha.end;

    //   emitter.setLifespan({min: time / 2, max: time});
    emitter.ops.lifespan.start = time / 2;
    emitter.ops.lifespan.end = time;
  }
  emitter.setDepth(z < 400 ? 400 : z);

  addIgnoreObject(emitter)
  if (blendMode) {
    emitter.setBlendMode(blendMode);
  } else {
    emitter.setBlendMode(undefined);
  }

  if (mask) {
    emitter.setMask(mask)
  }

  emitter.explode(count, x, y);
  Scene.time.addEvent({
    delay: time,                // ms
    callback: function () {
      emitter.clearMask(false)
      emitter.setMask(undefined)
      emitter.stop();
      explosions.push(emitter);
    },
    loop: false
  });
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export {
  ExplosionBullet,
  FireExplosion,
  RedExplosion,
  BlueExplosion,
  GreenExplosion,
  SmokeExplosion,
  ExplosionRing,
  Explosion,
  BlueExplosionSCREEN,
  GreenExplosionSCREEN,
  WhiteExplosion,
}
