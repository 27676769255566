import {GreenExplosion} from "../weapon/explosion";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {GetMapLvl} from "../map/height_offset";

function DropExit(x, y, angle) {

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  GreenExplosion(pos.x, pos.y, 400, 40, 40, 500,
    angle - 5, angle + 5, 105 + GetMapLvl(x, y, gameStore.map.id));
}

export {DropExit}
