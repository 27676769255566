<template>
  <div id="inventoryBox"
       ref="inventoryBox"
       @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:no-height="true"
                 v-bind:no-width="true"
                 v-bind:refWindow="'inventoryBox'"
                 v-bind:texture="'none'"/>

    <app-hangar-head/>
    <app-m-s-params/>
    <app-constructor/>
    <app-body-config v-if="openComponents['BodyConfig'] && openComponents['BodyConfig'].open"
                     v-bind:meta="openComponents['BodyConfig'].meta"/>

    <div class="equipBarOutWrapper">
      <div class="equipBarWrapper">
        <app-equip-bar/>
      </div>
    </div>

    <div class="mask" v-if="dialogRemove || dialogRename || dialogSlot"/>

    <div class="dialog" v-if="dialogRemove" ref="removeSquadDialog">

      <app-control v-bind:head="texts['text_12'][language]"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:no-height="true"
                   v-bind:no-width="true"
                   v-bind:texture="'none'"
                   v-bind:refWindow="'removeSquadDialog'"/>

      <div class="msg" v-html="texts['text_13'][language]"/>
      <div style="margin-top: 1px;">
        <input type="button"
               class="cursor_hover"
               :value="texts['button_3'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogRemoveSquad">
        <input type="button"
               class="cursor_hover"
               :value="texts['button_4'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="removeSquad">
      </div>
    </div>

    <div class="dialog" v-if="dialogRename" id="renameSquadDialog" ref="renameSquadDialog">

      <app-control v-bind:head="texts['text_14'][language]"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:no-height="true"
                   v-bind:no-width="true"
                   v-bind:texture="'none'"
                   v-bind:refWindow="'renameSquadDialog'"/>

      <div class="msg" style="height: calc(100% - 56px)">{{ texts['text_15'][language] }}</div>
      <input style="width: calc(100% - 20px)" type="text"
             :placeholder="texts['placeholder_1'][language]"
             v-model="newName">

      <input type="button"
             class="cursor_hover"
             :value="texts['button_5'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="dialogRenameSquad">
      <input type="button"
             class="cursor_hover"
             :value="texts['button_6'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="renameSquad">

    </div>

    <div class="dialog add_slot" v-if="dialogSlot" ref="removeSquadDialog">

      <app-control v-bind:head="texts['text_19'][language]"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:no-height="true"
                   v-bind:no-width="true"
                   v-bind:texture="'none'"
                   v-bind:refWindow="'removeSquadDialog'"/>

      <template v-if="inventory.addSlotDialogData">
        <div class="msg" v-html="texts['text_20'][language].
        replace('%credits_count%', (inventory.addSlotDialogData.price / 100).toFixed(2)).
        replace('%type%', inventory.addSlotDialogData.type).
        replace('%debuf%', inventory.addSlotDialogData.debuf)"/>
      </template>

      <template v-if="!inventory.addSlotDialogData">
        <div class="msg">
          <div class="loader">
            <app-preloader/>
          </div>
        </div>
      </template>

      <div style="margin-top: 1px;">
        <input type="button"
               class="cursor_hover"
               :value="texts['button_3'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogAddSlot">
        <input type="button"
               class="cursor_hover"
               :class="{disable: !inventory.addSlotDialogData || market.my_credits < inventory.addSlotDialogData.price}"
               :value="texts['button_7'][language]"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="addSlot(inventory.addSlotDialogData.price, inventory.addSlotDialogData.type)">
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import MSParams from './MSParams';
import HangarHead from './HangarHead';
import Constructor from './Constructor';
import EquipBar from '../EquipBar/EquipBar';
import BodyConfig from './BodyConfig';
import Preloader from "../Preloader/Preloader";

export default {
  name: "Hangar",
  data() {
    return {
      dialogRemove: false,
      dialogRename: false,
      dialogSlot: false,
      newName: '',
    }
  },
  created() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));
  },
  destroyed() {
    this.$store.commit({
      type: 'toggleWindow',
      id: 'BodyConfig',
      component: '',
      forceClose: true,
    });
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    dialogRemoveSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogRemove = !this.dialogRemove
    },
    dialogRenameSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogRename = !this.dialogRename
    },
    dialogAddSlot() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogSlot = !this.dialogSlot
      this.$store.commit({
        type: 'setAddSlotDialogData',
        data: null,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    addSlot(price, type) {
      this.dialogAddSlot()
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "AddEquipSlot",
          equip_slot_type: type,
          count: price,
        }
      }));
    },
    removeSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "RemoveMotherShipBody",
          destination: "storage",
        }
      }));

      this.dialogRemove = false
    },
    renameSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "RenameSquad",
          name: this.newName,
        }
      }));

      this.dialogRename = false
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    openComponents() {
      return this.$store.getters.getNeedOpenComponents
    },
    market() {
      return this.$store.getters.getMarket
    },
  },
  components: {
    AppControl: Control,
    AppMSParams: MSParams,
    AppHangarHead: HangarHead,
    AppConstructor: Constructor,
    AppEquipBar: EquipBar,
    AppBodyConfig: BodyConfig,
    AppPreloader: Preloader,
  },
}
</script>

<style scoped>
#inventoryBox {
  position: absolute;
  top: 9%;
  width: 508px;
  left: calc(50% - 260px);
  border-radius: 5px;
  z-index: 11;
  padding: 20px 0 3px 3px;
  height: 306px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.equipBarOutWrapper {
  border-radius: 0 0 5px 5px;
  float: right;
  padding: 2px;
  margin-top: 2px;
  border: 1px solid #009aeb7d;
  border-top: 0;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  position: relative;
}

.equipBarWrapper {
  padding: 2px;
  float: right;
  border-radius: 5px;
  background: #89969c;
  box-shadow: inset 0 0 3px black;
}

.dialog {
  position: absolute;
  z-index: 13;
  border-radius: 5px;
  width: 240px;
  height: 122px;
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px black;
  left: calc(50% - 120px);
  top: 30%;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.dialog input[type=button] {
  display: block;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 13px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
}

.dialog input[type=button]:last-child {
  float: right;
}

.dialog input[type=button]:hover {
  background: rgba(255, 129, 0, 1);
}

.dialog input[type=button]:active {
  transform: scale(0.98);
}

.dialog input[type=text] {
  float: left;
  margin: 4px;
}

.dialog .msg {
  height: calc(100% - 28px);
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 14px;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.dialog.add_slot {
  height: 160px;
  width: 280px;
  left: calc(50% - 140px);
  top: 20%;
}

.loader {
  width: 43px;
  height: 43px;
  float: left;
  margin-left: calc(50% - 21px);
  margin-top: 20px;
}
</style>

<style>
#inventoryBox .SquadBarEquipCell {
  height: 27px;
  width: 27px;
}

.dialog .msg p {
  margin-top: 0;
}
</style>
