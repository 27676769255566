<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Полиция</h2>
      <p>Помимо стражей в безопасных секторах еще действует полиция. Их легко узнать по значку рядом с именем над
        транспортом.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('police_1', 'png')+ ')',
            width: '378px',
            height: '147px',
            marginLeft: 'calc(50% - 189px)',
            }"/>

      <p>Цель полиции защищать мирные транспорты от агрессии со стороны пиратов/преступников/врагов и тд.</p>
      <p>Если страж видит что кто то атакует "белого"(не пвп и не пк) игрока/бота то он за него заступится и если это
        возможно позовет подкрепление.</p>
      <p>Если полиция видит конфликт 2х "фиолетовых" то он не вмешается, поэтому если хотите помощи полиции старайтесь
        оставаться "белыми". Однако если один из этих двух станет белым то полиция ему поможет.</p>
      <p>В безопасных секторах так же есть турели, они тоже выполняют функции полиции и если на вас напал пират
        отступайте
        к такой и вам будет оказана помощь.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('police_2', 'png')+ ')',
            width: '386px',
            height: '352px',
            marginLeft: 'calc(50% - 193px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Police</h2>
      <p>In addition to the guards in the safe sectors, there is also the police. They can be easily identified by the
        badge next to the name above the transport.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('police_1', 'png')+ ')',
        width: '378px',
        height: '147px',
        marginLeft: 'calc(50% - 189px)',
        }"/>

      <p>The goal of the police is to protect peaceful transports from aggression by pirates/criminals/enemies, etc.</p>
      <p>If a guard sees someone attacking a "white" (non-PvP and non-PK) player/bot, they will intervene on their
        behalf and, if possible, call for reinforcements.</p>
      <p>If the police see a conflict between two "purples," they will not interfere, so if you want the help of the
        police, try to stay "white." However, if one of the two becomes white, the police will help them.</p>
      <p>In the safe sectors, there are also turrets that perform the functions of the police, and if a pirate attacks
        you, retreat to such a turret, and you will be assisted.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('police_2', 'png')+ ')',
        width: '386px',
        height: '352px',
        marginLeft: 'calc(50% - 193px)',
        }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Police",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
