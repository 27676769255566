<template>
  <div style="height: 100%">

    <div class="error_event" v-if="error !== ''" :class="{error_event_visible: error !== ''}">
      <div style="margin-top: 30%">{{ texts[error][language] }}</div>
    </div>

    <h3 class="head">{{ texts['text_2'][language] }}</h3>
    <div class="form">

      <div class="userAvatarWrapper">

        <div class="help_point_menu">
          <div class="helper_image" v-html="texts['helper_image'][language]"/>
        </div>

        <div class="userAvatar" v-bind:style="{backgroundImage:  'url(\'' + logo + '\')'}"></div>
        <input style="position: absolute; left: -9999px;" id='file' type="file" class="file" @change="setLogo($event)"/>
        <label for="file" class="labelFile cursor_hover">{{ texts['button_3'][language] }}</label>
      </div>

      <div class="main">
        <label>
          <span class="importantly">{{ texts['text_3'][language] }}</span><br>
          <span style="font-size: 10px; color: lightgrey; text-shadow: 1px 1px 1px black">
            {{ texts['text_4'][language] }}</span>
          <br>
          <input v-model="name" type="text"><br>
        </label>
        <label>
          <span class="importantly">{{ texts['text_5'][language] }}</span><br>
          <span style="font-size: 10px; color: lightgrey; text-shadow: 1px 1px 1px black">
            {{ texts['text_6'][language] }}</span>
          <br>
          <input v-model="tag" type="text" @input="tag = tag.toUpperCase()">
        </label>
      </div>

      <div class="description">
        <label>
          <span class="importantly">{{ texts['text_7'][language] }}</span> <br>
          <span style="font-size: 10px; color: lightgrey; text-shadow: 1px 1px 1px black">({{ description.length }} / 500)</span><br>
          <textarea v-model="description"/>
        </label>
      </div>
    </div>

    <input class="cluster_button cursor_hover" style="float: right"
           type="button"
           :value="texts['button_4'][language]"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="createCorporation">
  </div>
</template>

<script>
import Input from "../Chat/Input";

export default {
  name: "Create",
  components: {Input},
  data() {
    return {
      name: '',
      description: '',
      logo: '',
      tag: '',
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    setLogo(e) {
      let app = this;

      let file_reader = new FileReader(e.target.files[0]);
      file_reader.readAsDataURL(e.target.files[0]);
      file_reader.onload = function (evt) {
        app.logo = evt.target.result;
      };
    },
    createCorporation() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "CreateCorporation",
          name: this.name,
          data: this.logo,
          biography: this.description,
          tag: this.tag,
        }
      }));
    }
  },
  computed: {
    error() {
      let errors = this.$store.getters.getErrors

      for (let er in this.errors)

        if (errors.hasOwnProperty(er) && errors[er].active) {

          setTimeout(function () {
            this.$store.commit({
              type: 'AddError',
              error: er,
              active: false,
            });
          }.bind(this), 1000)

          return er
        }

      return ""
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
  }
}
</script>

<style scoped>
.userAvatarWrapper label {
  display: block;
  width: 80px;
  margin: 2px 0 3px 10px;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  bottom: 2px;
  position: absolute;
  z-index: 3;
}

.userAvatarWrapper label:hover {
  background-color: rgba(0, 120, 211, 0.9);
  opacity: 1;
}

.userAvatarWrapper label:active {
  transform: scale(0.95);
}

.userAvatar {
  height: 100px;
  width: 100px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px black;
  background-size: cover;
  padding: 0;
  margin: 0;
}

.userAvatarWrapper {
  float: left;
  width: 110px;
  position: relative;
}

.userAvatarWrapper input {
  margin-top: 5px;
  margin-left: 19px;
}

.userAvatarWrapper input:active {
  transform: scale(0.95);
}

.head {
  margin: 0 0 10px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.description {
  margin-top: 10px;
  width: 100%;
  float: left;
}

.main input, .description textarea {
  border-radius: 5px;
  margin-bottom: 3px;
  width: 150px;
  padding: 0 3px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.description textarea {
  height: 75px;
  margin: 0;
  width: calc(100% - 10px);
  resize: none;
  border: 0;
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  width: 75px;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}

.error_event {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: -27px 0 0 -10px;
  font-size: 18px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  border-radius: 5px;
  pointer-events: none;
}

.error_event_visible {
  opacity: 1;
}

.help_point_menu {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 13px;
  top: 4px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
}

.helper_image {
  top: 100%;
  right: calc(100% - 19px);
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-shadow: 1px 1px 1px black;
  width: 98px;
  position: absolute;
  font-size: 11px;
  display: none;
}

.help_point_menu:hover .helper_image {
  display: block;
}
</style>
