<template>
  <div class="detailItemInfo" :id="windowID" :ref="windowID" v-if="slot" @mousedown="toUp">

    <app-control v-bind:head="itemName" v-bind:move="true" v-bind:close="true"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"
                 v-bind:resizeFunc="resize"
                 v-bind:refWindow="windowID"
                 v-bind:minSize="{height: 170, width: 270}"/>

    <div class="detailItemInfoHead">
      <div class="detailItemInfoIcon" :class="{broken_outline: slot.item.id > 10000000}">
        <app-background-item-cell v-if="slot.item" v-bind:slotItem="slot" v-bind:noShowName="true"/>
      </div>

      <div class="detailItemInfoHeads">
        <h4>{{ itemName }}</h4>
        <h6>{{ handbook.categories[language][type].name }}</h6>
        <h6 style="color: white;"
            v-html="texts['text_1'][language].replace('%credits_count%', (defaultPrice / 100).toFixed(2))"/>
      </div>

      <div class="detailItemInfoTabs">

        <div v-bind:class="{active: tab === 'description'}"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('description')">
          {{ texts['text_2'][language] }}
        </div>

        <div v-if="slot.item.hasOwnProperty('bonuses') && Object.keys(slot.item.bonuses).length !== 0"
             class="cursor_hover"
             v-bind:class="{active: tab === 'bonus'}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('bonus')">
          {{ texts['text_3'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='characteristic'}"
             class="cursor_hover"
             v-if="characteristicTypes.includes(type)"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('characteristic')">
          {{ texts['text_4'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='characteristic_weapon'}"
             class="cursor_hover"
             v-if="(type === 'equip' && (slot.item.applicable === 'mini_turret' || slot.item.applicable === 'drone_defender'))"
             @click="changeTab('characteristic_weapon')">
          {{ texts['text_5'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='characteristic_ammo'}"
             class="cursor_hover"
             v-if="(type === 'equip' &&
             (slot.item.applicable === 'mini_turret' || slot.item.applicable === 'drone_defender' || slot.item.applicable === 'grenade'))"
             @click="changeTab('characteristic_ammo')">
          {{ texts['text_6'][language] }}
        </div>

        <div v-bind:class="{active: tab === 'variants'}"
             class="cursor_hover"
             v-if="slot.item.variants && slot.item.variants.length > 0"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('variants')">
          {{ texts['text_7'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='requirements'}"
             class="cursor_hover"
             v-if="requirements"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('requirements')">
          {{ texts['text_8'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='equipment'}"
             class="cursor_hover"
             v-if="equipmentTypes.includes(type)"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('equipment')">
          {{ texts['text_9'][language] }}
        </div>

        <div v-bind:class="{active: tab ==='production'}"
             class="cursor_hover"
             v-if="productionTypes.includes(type) && slot.item_id < 10000000"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeTab('production')">
          {{ texts['text_10'][language] }}
        </div>
      </div>
    </div>

    <div class="detailItemInfoBody">
      <div class="description" v-if="tab === 'description'">

        <div class="broken" v-if="slot.item.id > 10000000"
             v-html="texts['broken_tip'][language]"/>

        <div class="resource_detail" style="line-height: 15px" v-if="type === 'recycle'">
          {{ texts['resource_tip_1_1'][language] }}
          <span class="a_help cursor_hover"
                @click.stop="openService('helpWrapper', {component: 'Processing'})">
            {{ texts['resource_tip_1_2'][language] }}</span>:
          <br>

          <div v-for="ore in getOresByRecycle(slot.item)">
            <div class="a_help_icon cursor_hover" @click="openDetail('resource', ore.id)">
              <app-background-item-cell v-bind:slotItem="{item: {name: ore.name}, type: 'resource'}"
                                        v-bind:noShowName="true"/>
            </div>

            <span class="a_help cursor_hover" @click="openDetail('resource', ore.id)">{{
                handbook.resource[language][ore.name].name
              }}</span>.
          </div>

        </div>

        <div class="resource_detail" v-if="type === 'resource'">
          {{ texts['resource_tip_2_1'][language] }}
          <span class="a_help cursor_hover"
                @click.stop="openService('helpWrapper', {component: slot.item.type_id === 6 ? 'Oil' : 'Mining'})">
            {{ texts['resource_tip_2_2'][language] }}</span>.
        </div>

        <div class="resource_detail" v-if="type === 'blueprints'">
          {{ texts['blueprints_tip_1_1'][language] }}
          <span class="a_help cursor_hover"
                @click.stop="openService('helpWrapper', {component: 'Anomaly'})">
            {{ texts['blueprints_tip_1_2'][language] }}</span>.
        </div>

        <div class="resource_detail" v-if="type === 'detail'">
          {{ texts['detail_tip_1'][language] }}
          <ul>
            <li>
              <span class="a_help cursor_hover"
                    @click.stop="openService('helpWrapper', {component: 'PartsProduction'})">{{
                  texts['detail_tip_1_1'][language]
                }}</span>
            </li>
            <li>
              <span class="a_help cursor_hover" @click.stop="openService('helpWrapper', {component: 'Blueprints'})">{{
                  texts['detail_tip_1_2'][language]
                }}</span>
            </li>
            <li>
              <span class="a_help cursor_hover"
                    @click.stop="openService('helpWrapper', {component: 'Salvage'})">{{
                  texts['detail_tip_1_3'][language]
                }}</span>
            </li>
          </ul>
        </div>

        <div v-html="itemDescription"></div>
      </div>

      <app-characteristic-tab v-if="tab === 'characteristic'"
                              v-bind:type="type" v-bind:item="slot.item"
                              v-bind:ammo="ammo" v-bind:weapon="weapon" v-bind:object="dynObject"/>

      <app-characteristic-tab v-if="tab === 'characteristic_ammo'" v-bind:type="'ammo'" v-bind:item="ammo"
                              v-bind:object="dynObject"/>
      <app-characteristic-tab v-if="tab === 'characteristic_weapon'" v-bind:type="'weapon'" v-bind:item="weapon"
                              v-bind:object="dynObject"/>

      <app-equipment-tab v-if="tab === 'equipment'" v-bind:type="type" v-bind:item="slot.item"/>
      <app-production-tab v-if="tab === 'production'" v-bind:type="type" v-bind:item="slot.item"/>
      <app-requirements v-if="tab === 'requirements'" v-bind:requirements="requirements"/>
      <app-bonus-tab v-if="tab === 'bonus'" v-bind:item="slot.item" v-bind:type="type" v-bind:bonuses="slot.item.bonuses"/>
      <app-variants v-if="tab === 'variants'" v-bind:type="type" v-bind:ids="slot.item.variants"/>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import CharacteristicTab from './CharacteristicTab';
import EquipmentTab from './EquipmentTab';
import ProductionTab from './ProductionTab';
import Requirements from './Requirements';
import BonusTab from './BonusTab';
import Variants from './Variants';

import {urls} from '../../const';
import {gameStore} from "../../game/store";

export default {
  name: "DetailItem",
  props: ['type', 'id', 'windowID'],
  data() {
    return {
      slot: null,
      ammo: null,
      weapon: null,
      dynObject: null,
      tab: 'description',
      productionTypes: ["detail", "blueprints", "boxes", "body", "ammo", "equip", "weapon", "recycle", "resource", "fuel"],
      characteristicTypes: ["trash", "resource", "recycle", "detail", "boxes", "body", "ammo", "equip", "weapon", "product", "fuel"],
      equipmentTypes: ["body", "weapon"],
      requirements: null,
      defaultPrice: 0,
    }
  },
  created() {
    let app = this;

    app.$api.get(urls.itemURL + '?id=' + app.$props.id + "&type=" + app.$props.type + "&method=item").then(function (response) {
      app.slot = response.data;

      console.log(app.slot.item.requirements)
      if (app.slot && app.slot.item && app.slot.item.hasOwnProperty('requirements')) {
        let idsString = "";

        for (let id in app.slot.item.requirements) {
          if (idsString !== "") idsString += "&";
          idsString += "id=" + id
        }

        app.$api.get(urls.skillInfoURL + '?' + idsString).then(function (response) {

          for (let id in app.slot.item.requirements) {
            if (response.data[id]) {
              response.data[id].level = app.slot.item.requirements[id]
            }
          }

          app.requirements = response.data;
        });
      }

      app.open()
    }).catch(function (err) {
      //console.log(err)
    });

    app.$api.get(urls.getItemPrice + '?id=' + app.$props.id + "&type=" + app.$props.type).then(function (response) {
      app.defaultPrice = response.data.price;
    }).catch(function (err) {
      //console.log(err)
    });
  },
  methods: {

    open() {
      let app = this;
      let item = this.slot.item;

      if (this.slot.type === "equip") {
        if (item.attributes) {
          if (item.attributes.ammo_id) {
            this.ammo = gameStore.gameTypes.ammo[item.attributes.ammo_id]
          }

          if (item.attributes.obj_weapon_id) {
            let wa = this.getWeapon(item.attributes.obj_weapon_id)
            this.weapon = wa.w
            this.ammo = wa.a
          }
        }

        if (item.weapon_id > 0) {
          let wa = this.getWeapon(item.weapon_id, this.ammo)
          this.weapon = wa.w
          this.ammo = wa.a
        }

        if (item.object_id > 0) {
          app.$api.get(urls.getDynObject + '?id=' + item.object_id).then(function (response) {
            app.dynObject = response.data
          });
        }
      }
    },
    getWeapon(id, ammo) {
      let weapon = JSON.parse(JSON.stringify(gameStore.gameTypes.weapons[id]))
      ammo.min_damage = ammo.min_damage * weapon.damage_modifier
      ammo.max_damage = ammo.max_damage * weapon.damage_modifier

      return {w: weapon, a: ammo}
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)
      this.tab = tab;
    },
    resize(event, ui, el) {
      el.find('.detailItemInfoBody').css("height", el.height() - 84);
    },
    openDetail(type, id) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    getOresByRecycle(recycle) {
      // TODO хардкод
      if (recycle.type_id === 1) return [{id: 4, name: 'thorium_ore'}];
      if (recycle.type_id === 2) return [{id: 1, name: 'copper_ore'}];
      if (recycle.type_id === 3) return [{id: 2, name: 'iron_ore'}];
      if (recycle.type_id === 4) return [{id: 6, name: 'oil'}];
      if (recycle.type_id === 5) return [{id: 3, name: 'silicon_ore'}];
      if (recycle.type_id === 6) return [{id: 5, name: 'titanium_ore'}];

      // thorium_ore 4 // enriched_thorium 1
      // copper_ore 1 // copper 2
      // iron_ore 2 // iron 3
      // oil  6 // plastic 4
      // silicon_ore 3 // silicon 5
      // titanium_ore 5 // titanium 6

      return [{id: 3, name: 'iron_ore'}]
    }
  },
  computed: {
    itemName() {
      if (!this.slot) {
        return ''
      }

      if (this.slot.type === "blueprints") {
        if (this.slot.item.in_map) {
          return this.handbook['structure'][this.language][this.slot.item.name.split('_bp')[0]].name
        } else {
          return this.handbook[this.slot.item.item_type][this.language][this.slot.item.item_name].name
        }
      }

      return this.handbook[this.slot.type][this.language][this.slot.item.name].name
    },
    itemDescription() {
      if (!this.slot) {
        return ''
      }

      if (this.slot.type === "blueprints") {
        if (this.slot.item.in_map) {
          return "<p>Чертеж для строительства: " + this.handbook['structure'][this.language][this.slot.item.name.split('_bp')[0]].name + "</p>"
        } else {
          return "<p>Чертеж для создания: " + this.handbook[this.slot.item.item_type][this.language][this.slot.item.item_name].name + "</p>"
        }
      }

      return this.handbook[this.slot.type][this.language][this.slot.item.name].description
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
  },
  components: {
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
    AppCharacteristicTab: CharacteristicTab,
    AppEquipmentTab: EquipmentTab,
    AppProductionTab: ProductionTab,
    AppRequirements: Requirements,
    AppBonusTab: BonusTab,
    AppVariants: Variants,
  }
}
</script>

<style scoped>
.detailItemInfo {
  height: 300px;
  width: 360px;
  position: absolute;
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  top: 30%;
  left: calc(50% - 154px);
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.detailItemInfoHead {
  width: 100%;
  white-space: nowrap;
}

.detailItemInfoIcon {
  position: relative;
  height: 50px;
  width: 50px;
  float: left;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  box-shadow: inset 0 0 2px;
}

.detailItemInfoHeads {
  text-align: left;
}

.detailItemInfoTabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.detailItemInfoTabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detailItemInfoTabs div:last-child {
  /*border: 0;*/
}

.detailItemInfoTabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.detailItemInfoBody {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 5px 5px 0 0;
  text-align: justify;
  margin: auto auto 2px 0;
  width: calc(100% - 10px);
  height: 170px;
  box-shadow: inset 0 0 3px black;
  overflow-y: scroll;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  float: left;
  backdrop-filter: blur(4px);
}


.detailItemInfoHead h4, .detailItemInfoHead h6 {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detailItemInfoHead h4 {
  font-size: 11px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  margin-top: 4px;
  height: 13px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.detailItemInfoHead h6 {
  font-size: 12px;
  display: block;
  text-align: left;
  text-indent: 6px;
  margin-top: 4px;
  color: #fff200;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.detailItemInfoTabs div:hover {
  color: #fff200 !important;
}

.broken, .resource_detail {
  padding: 4px 5px 4px 5px;
  background: rgb(255, 0, 0, 0.3);
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  color: white;
  font-weight: bold;
  margin-top: 5px;
  font-size: 9px;
}

.resource_detail {
  background: rgba(0, 166, 255, 0.3);
  font-size: 12px;
  line-height: 15px;
}

.broken_outline {
  outline: lightgrey dashed 2px;
}

.a_help {
  color: #32f2ff;
  font-weight: 900;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  text-indent: 15px;
  text-align: justify;
  margin: 4px 0;
  text-decoration: none;
}

.a_help:hover {
  text-decoration: underline;
  color: #ffc588;
}

.a_help_icon {
  position: relative;
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  bottom: -2px;
}
</style>

<style>
.detailItemInfo .description p {
  text-indent: 1.5em;
  font-size: 12px;
}
</style>
