import router from "../../../router/router";
import {gameStore} from "../../../game/store";

export default function lobbyRead(msg, store) {
  if (msg.event === "BaseStatus") {
    store.commit({
      type: 'setBaseFullStatus',
      state: msg.data.base,
      resources: msg.data.inventory_slots,
      fraction: msg.data.fraction,
      stories: msg.data.stories,
      tax: msg.data.tax,
      attr: msg.data.attr,
      base_attr: msg.data.base_attr,
      siege: msg.data.siege,
    });

    setTimeout(function () {
      store.commit({
        type: 'setVisibleLoader',
        visible: false,
      });
    }, 1000)
  }

  if (msg.event === "gps") {
    store.commit({
      type: 'setBaseProducts',
      products: msg.data,
    });
  }

  if (msg.event === "WorkbenchStorage") {

    store.commit({
      type: 'setBlueWork',
      works: msg.data.blue_works,
    });

    let state = store.getters.getWorkbenchState.select_bp;
    if (state.type_select === 'work' && state.blue_work) {

      if (msg.data.blue_works.hasOwnProperty(state.blue_work.id)) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "lobby",
          data: {
            event: "SelectWork",
            id: state.blue_work.id,
            count: Number(state.count),
            start_time: state.start_time,
            to_time: state.to_time,
          }
        }));
      } else {
        store.commit({
          type: 'setSelectBP',
          type_select: null,
          blue_print: null,
          blue_work: null,
        });
      }
    }
  }

  if (msg.event === "SelectBP") {
    store.commit({
      type: 'setSelectBP',
      type_select: 'bp',
      blue_print: msg.data.blue_print,
      bp_item: msg.data.bp_item,
      preview_recycle_slots: msg.data.preview_recycle_slots,
      count: msg.data.count,
      mineral_tax: 100 + (msg.data.efficiency - msg.data.user_work_skill_detail_percent),
      time_tax: msg.data.user_work_skill_time_percent,
      time: msg.data.time,
      storage_slot: msg.data.storage_slot,
      max_count: msg.data.max_count,
      user_work_skill_detail_percent: msg.data.user_work_skill_detail_percent,
    });
  }

  if (msg.event === "SelectWork") {
    store.commit({
      type: 'setSelectBP',
      type_select: 'work',
      blue_print: msg.data.blue_print,
      blue_work: msg.data.blue_work,
      bp_item: msg.data.bp_item,
      count: msg.data.count,
      preview_recycle_slots: msg.data.preview_recycle_slots,
      max_count: msg.data.max_count,
      to_time: msg.data.to_time,
      start_time: msg.data.start_time,
    });
  }

  if (msg.event === "GetAssortmentFractionStore") {
    store.commit({
      type: 'setFractionStoreAssortment',
      assortment: msg.data.assortment,
    });

    store.commit({
      type: 'setFractionStoreUserPoints',
      user_fraction_points: msg.data.user_fraction_points,
    });
  }

  if (msg.event === 'UpdateFractionPoints') {
    store.commit({
      type: 'setFractionStoreUserPoints',
      user_fraction_points: msg.data,
    });
  }

  if (msg.event === "StartOutBase") {
    store.commit({
      type: 'setExitBase',
      exit: true,
    });

    store.commit({
      type: 'toggleWindow',
      id: 'ExitMenu',
      forceClose: true,
    });
  }

  if (msg.event === "OutBase") {
    console.log("Выходим с базы")

    store.commit({
      type: 'setExitBase',
      exit: false,
    });

    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Подключаемся к игре...',
        'EN': 'Connecting to the game...',
      }
    });

    if (!location.href.includes('global')) {
      router.push('/global');
    }
  }

  if (msg.event === "IntoToBase") {
    console.log("Начинаем входить на базу")
    if (msg.data === 0) {
      store.commit({
        type: 'setVisibleLoader',
        visible: true,
        text: {
          'RU': 'Входим на базу...',
          'EN': 'Entering the base...',
        }
      });
    }

    setTimeout(function () {

      store.commit({
        type: 'removeNotification',
        id: 'softTransition',
      });

      store.commit({
        type: 'removeNotification',
        id: 'setFreeCoordinate',
      });

      if (!location.pathname.includes("lobby")) {
        console.log("Входим на базу")
        gameStore.noLoader = true;

        if (!location.href.includes('lobby')) {
          router.push('/lobby');
        }
      }
    }, msg.data * 1000)
  }

  if (msg.event === "UpdateRecycler") {
    store.commit({
      type: 'setRecycler',
      recycle_slots: msg.data.recycle_slots,
      preview_recycle_slots: msg.data.preview_recycle_slots,
      user_recycle_skill: msg.data.user_recycle_skill,
      lost_recycle_slots: msg.data.lost_recycle_slots,
      tax_recycle_slots: msg.data.tax_recycle_slots,
    });
  }

  if (msg.event === "UpdatePrefabricatedMenu") {
    store.commit({
      type: 'setPrefabricatedMenu',
      detail_need_items: msg.data.need_items,
      detail_max_counts: msg.data.max_counts,
    });
  }

  if (msg.event === "GetDetailBps") {
    console.log(msg.data.blue_prints)
    store.commit({
      type: 'setPrefabricatedBpsMenu',
      detail_bps: msg.data.blue_prints,
    });
  }

  if (msg.event === "GetPrefabricatedNeedItems") {
    store.commit({
      type: 'setNeedItemsForSelectDetail',
      needItems: msg.data.need_item,
      tax: msg.data.tax,
    });
  }

  if (msg.event === "Transfer") {

    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Перемещаемся...',
        'EN': 'We are moving around...',
      }
    });

    if (!location.href.includes('transfer')) {
      router.push('/transfer');
    }
  }
}
