export default function dialogEditorRead(msg, store) {
  if (msg.event === "OpenEditor") {
    let dialogs = {}
    for (let i in msg.data.dialogs) {
      dialogs[i] = JSON.parse(msg.data.dialogs[i])
      dialogs[i].pages = JSON.parse(msg.data.pages[i])
    }

    store.commit({
      type: 'setDialogsInDialogEditor',
      dialogs: dialogs,
    });
  }

  if (msg.event === "GetAllMissions") {
    store.commit({
      type: 'setMissionInMissionEditor',
      missions: msg.data.missions,
      items: msg.data.action_items,
      actions: msg.data.mission_actions,
      rewardItems: msg.data.reward_items,
    });
  }
}
