<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: объекты заданий</h2>

      <p>В некоторых заданиях необходимо вставить в объект ключ или карту что бы он что то сделал.</p>
      <p>Для этого надо открыть меню объекта, меню инвентаря и перетащить ключ/карту в специальную синюю ячейку в меню
        объекта.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_open_special_cell_1', 'gif')+ ')',
            width: '391px',
            height: '268px',
            marginLeft: 'calc(50% - 195px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Task Objects</h2>

      <p>In some tasks, it is necessary to insert a key or card into an object to make it do something.</p>
      <p>To do this, you need to open the object's menu, the inventory menu, and drag the key/card into a special blue
        cell in the object's menu.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_open_special_cell_1', 'gif')+ ')',
            width: '391px',
            height: '268px',
            marginLeft: 'calc(50% - 195px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationOpenSpecialCell",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
