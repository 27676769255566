var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Счет кластера")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Счета кластера - это банальное хранилище денежных сумм кластера. Финансовая система игры настроена таким\n        образом, что у каждого кластера имеется пять (5) счетов, позволяющих более тонко настроить затраты и права на\n        них.")]),_vm._v(" "),_c('p',[_vm._v("Помимо прочего, сами счета можно переименовывать, посредством меню - “О кластере”, что только добавит\n        контроля\n        за денежными суммами.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_1', 'jpg')+ ')',
          width: '400px',
          height: '126px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Зачислить деньги на счёт кластера может любой игрок, даже не состоящий в самом кластере. Осуществлено это\n        может\n        быть через меню - “перевода кредитов”. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_2', 'jpg')+ ')',
          width: '319px',
          height: '288px',
          marginLeft: 'calc(50% - 159px)',
          })}),_vm._v(" "),_c('p',[_vm._v("По-умолчанию, доступ к снятию средств со счетов кластера имеет только лидер, впрочем, подобными права можно\n        наделить и остальных членов кластера. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_3', 'jpg')+ ')',
          width: '369px',
          height: '361px',
          marginLeft: 'calc(50% - 184px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Cluster Account")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Cluster accounts are a simple storage for the cluster's funds. The game's financial system is set up in such\n        a way that each cluster has five (5) accounts, allowing for finer tuning of expenses and rights to them.")]),_vm._v(" "),_c('p',[_vm._v("In addition, the accounts themselves can be renamed through the \"About Cluster\" menu, which only adds control\n        over the funds.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_1', 'jpg')+ ')',
          width: '400px',
          height: '126px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Any player, even those not in the cluster, can credit money to the cluster's account. This can be done\n        through the \"credit transfer\" menu.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_2', 'jpg')+ ')',
          width: '319px',
          height: '288px',
          marginLeft: 'calc(50% - 159px)',
          })}),_vm._v(" "),_c('p',[_vm._v("By default, only the leader has access to withdraw funds from the cluster accounts, however, such rights can\n        also be granted to other cluster members.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('deposit_3', 'jpg')+ ')',
          width: '369px',
          height: '361px',
          marginLeft: 'calc(50% - 184px)',
          })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }