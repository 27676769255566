var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Ангар")]),_vm._v(" "),_c('p',[_vm._v("Ангар - это меню, которое позволяет вам настраивать ваш транспорт и просматривать все его характеристики. Ангар\n      доступен везде для просмотра характеристик, но настраивать транспорт можно только на базе, где есть необходимое\n      оборудование.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Если вы хотите поменять имя вашего транспорта или переместить его на склад, то используйте две кнопки в\n      заголовке. На складе вы можете хранить свои транспорты без ограничений и без риска потери их при смерти.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('hangar_1', 'png')+ ')',
          width: '480px',
          height: '348px',
          marginLeft: 'calc(50% - 240px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Подробнее о\n      "),_c('router-link',{attrs:{"to":"bodies"}},[_vm._v("корпусах")]),_vm._v("\n      ,\n      "),_c('router-link',{attrs:{"to":"equips"}},[_vm._v("снаряжение")]),_vm._v("\n      и\n      "),_c('router-link',{attrs:{"to":"weapons"}},[_vm._v("оружие")]),_vm._v("\n      .\n    ")],1)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Hangar")]),_vm._v(" "),_c('p',[_vm._v("The hangar is a menu that allows you to customize your transport and view all its characteristics. The hangar\n      is accessible everywhere for viewing characteristics, but you can only configure your transport at a base where\n      the necessary equipment is available.")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('p',[_vm._v("If you want to change your transport's name or move it to storage, use the two buttons in the header. In\n      storage, you can keep your transports without restrictions and without the risk of losing them upon death.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('hangar_1', 'png')+ ')',
          width: '480px',
          height: '348px',
          marginLeft: 'calc(50% - 240px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Learn more about\n      "),_c('router-link',{attrs:{"to":"bodies"}},[_vm._v("hulls")]),_vm._v("\n      ,\n      "),_c('router-link',{attrs:{"to":"equips"}},[_vm._v("equipment")]),_vm._v("\n      ,\n      and\n      "),_c('router-link',{attrs:{"to":"weapons"}},[_vm._v("weapons")]),_vm._v("\n      .\n    ")],1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("В меню "),_c('span',{staticClass:"importantly"},[_vm._v("“Транспорты на базе”")]),_vm._v(" вы можете видеть активные транспорты на текущей\n      базе\n      и быстро переключаться между\n      ними. Это удобно, если вы хотите сравнить разные транспорты или использовать разные транспорты для разных\n      задач.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Если вы устанавливаете новый корпус на ваш транспорт, то старый корпус со всем содержимым не пропадает, а\n      появляется в меню "),_c('span',{staticClass:"importantly"},[_vm._v("“Транспорты на базе”")]),_vm._v(". Вы можете вернуться к старому корпусу\n      выбрав его в этом меню.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("In the "),_c('span',{staticClass:"importantly"},[_vm._v("\"Transports at Base\"")]),_vm._v(" menu, you can see the active transports at the\n      current base and quickly switch between them. This is convenient if you want to compare different transports or\n      use different ones for various tasks.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If you install a new hull on your transport, the old hull with all its contents doesn't disappear; it appears\n      in the "),_c('span',{staticClass:"importantly"},[_vm._v("\"Transports at Base\"")]),_vm._v(" menu. You can revert to the old hull by selecting\n      it in this menu.")])
}]

export { render, staticRenderFns }