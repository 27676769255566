import {Scene} from "../create";
import {statusBarWrapper} from "./status_bar";

let points = {
  points: {},
  pos: {
    x: 0,
    y: 75,
    offset: 15,
  }
}

function addPointNotify(notify) {
  let key = notify.t;

  if (points.points[key]) {
    points.points[key].count += notify.c;
    if (points.points[key].timer) points.points[key].timer.remove();
    if (points.points[key].sprite) points.points[key].sprite.destroy();
  } else {
    points.points[key] = {
      count: notify.c,
      y: points.pos.y + (Object.keys(points.points).length * points.pos.offset)
    }
  }


  let text = getName(notify.t) + '  x' + (points.points[key].count / 100).toFixed(1);
  points.points[key].sprite = statusBarWrapper.scene.add.bitmapText(0, points.points[key].y, 'bit_text_12', text, 12);
  points.points[key].sprite.setLetterSpacing(1)
  points.points[key].sprite.x = (statusBarWrapper.scene.cameras.main.displayWidth / 2) - (points.points[key].sprite.width / 2)
  points.points[key].sprite.setAlpha(0.75)

  points.points[key].timer = Scene.time.addEvent({
    delay: 3000,
    callback: function () {
      points.points[key].sprite.destroy();
      delete points.points[key]
    },
  });
}

function getName(pointType) {
  if (pointType === 1) return "Уничтожение"; // "destroy"
  if (pointType === 2) return "Повреждение"; // "damage_hostile"
  if (pointType === 3) return "Помощь в уничтожение"; // "assist_destroy"
  if (pointType === 4) return "Захват базы"; // "capture"
  if (pointType === 5) return "Уничтожение структур"; // "destroy_structure"
  if (pointType === 6) return "Захват сектора"; // "capture_sector"
}

export {addPointNotify}
