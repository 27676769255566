var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Топливо ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('transport_2', 'png')+ ')',
        width: '113px',
        height: '151px',
        float: 'left',
        marginRight: '10px',
      })}),_vm._v(" "),_c('p',[_vm._v("Топливо - это кровь вашего транспорта. Оно влияет на то, как хорошо работает ваш корпус. Каждый корпус имеет\n        от\n        одной до трех ячеек для топлива, которые нужно заполнять торием - специальным видом ресурса. Если вы не\n        заполните все ячейки, то ваш корпус будет работать хуже. Если у вас кончится топливо, то ваш корпус будет\n        работать очень плохо.")]),_vm._v(" "),_c('p',[_vm._v("Чем больше ячеек в транспорте тем быстрее расходуется топливо. (1 ячейка потребляет х1, 3 потребляет х3)")])]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Топливо бывает разных видов, главное их отличие это его ресурс, однако некоторые виды топлива могут давать\n        бонусы за использование.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('fuel_1', 'png')+ ')',
        width: '410px',
        height: '154px',
        marginLeft: 'calc(50% - 205px)',
      })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_vm._v("\n      Базовый вариант топлива продается на любой базе, остальные варианты надо производить на\n      "),_c('router-link',{attrs:{"to":"base"}},[_vm._v("заводе полуфабрикатов")]),_vm._v("\n      .\n    ")],1),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-top":"15px"}},[_c('h3',{staticClass:"head"},[_vm._v("Использование")]),_vm._v(" "),_c('p',[_vm._v("Что бы установить топливо перетащите его на корпус или ячейку.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Остатки топлива надо погрузить в инвентарь с названием \"топливо\", когда кончится энергия у корпуса то он\n        автоматически начнет использовать топливо из этого инвентаря.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_2', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h3',{staticClass:"head"},[_vm._v("Fuel")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('transport_2', 'png')+ ')',
        width: '113px',
        height: '151px',
        float: 'left',
        marginRight: '10px',
      })}),_vm._v(" "),_c('p',[_vm._v("Fuel is the blood of your transport. It affects how well your chassis works. Each chassis has from one to\n        three\n        fuel cells that need to be filled with thorium - a special type of resource. If you do not fill all the cells,\n        your chassis will perform worse. If you run out of fuel, your chassis will perform very poorly.")]),_vm._v(" "),_c('p',[_vm._v("The more cells in the transport, the faster the fuel is consumed. (1 cell consumes x1, 3 consumes x3)")])]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Fuel comes in different types, the main thing is that they differ from this resource, however, some types of\n        fuel can give bonuses for use.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('fuel_1_en', 'png')+ ')',
        width: '410px',
        height: '154px',
        marginLeft: 'calc(50% - 205px)',
      })}),_vm._v(" "),_c('div',{staticClass:"content_section"},[_vm._v("\n        The basic version of the fuel is sold at any base, the other variants must be produced at the\n        "),_c('router-link',{attrs:{"to":"parts_production"}},[_vm._v("semi-finished products plant")]),_vm._v("\n        .\n      ")],1),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-top":"15px"}},[_c('h3',{staticClass:"head"},[_vm._v("Usage")]),_vm._v(" "),_c('p',[_vm._v("What is needed to install the fuel is to drag it onto the hull or cell.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The remaining fuel should be loaded into the inventory called «fuel», when the hull runs out of energy, it\n          will automatically start using fuel from this inventory.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_2', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }