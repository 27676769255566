<template>
  <div id="anomalyScannerWrapper" ref="anomalyScannerWrapper" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="false"
                 v-bind:no-width="true"
                 v-bind:no-height="true"
                 v-bind:refWindow="'anomalyScannerWrapper'"
                 v-bind:texture="'none'"/>

    <div class="texture_wrapper"
         :style="{
          backgroundImage: 'url(' + require('../../assets/interface/frame_6_2.png').default + ')',
          top: '-14px',
          left:'-24px'}"/>

    <div class="texture_wrapper_web"
         :style="{
          backgroundImage: 'url(' + require('../../assets/interface/frame_6_3.png').default + ')',
          top: '-14px',
          left:'-24px'}"/>

    <div id="anomalyScanner">
      <div id="anomalyDisplay">
        <div id="anomalyBody" ref="anomalyBody"
             :style="{backgroundImage: 'url(' + require('../../assets/interface/frame_6_1.png').default + ')'}"></div>

        <div class="anomalyPing"
             v-for="anomaly in anomalies"
             :style="{
               left: anomaly.left,
               top: anomaly.top,
               transform: anomaly.transform,
               background: anomaly.background,
               zIndex: anomaly.priority,
               }">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "GeoScan",
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('GeoScan')
    },
    unit() {
      return this.$store.getters.getUnit
    },
    anomalies() {
      let anomalies = this.$store.getters.getAnomalies;
      let anomaliesDisplay = [];

      if (!this.$refs['anomalyBody']) return anomaliesDisplay;

      for (let i = 0; i < anomalies.length; i++) {

        let display = {};

        anomalies[i].rotate -= 180;
        if (anomalies[i].rotate < 0) {
          anomalies[i].rotate += 360
        }

        if (anomalies[i].rotate > 360) {
          anomalies[i].rotate -= 360
        }

        let radRotate = (anomalies[i].rotate) * Math.PI / 180;

        let yAttach = this.$refs['anomalyBody'].offsetHeight / 2 * Math.cos(radRotate);
        let xAttach = this.$refs['anomalyBody'].offsetHeight / 2 * Math.sin(radRotate);

        xAttach += this.$refs['anomalyBody'].offsetHeight / 2 - 4; // 8 это радиус дива пинга
        yAttach += this.$refs['anomalyBody'].offsetHeight / 2 - 4;

        display.left = xAttach + "px";
        display.top = yAttach + "px";

        // display.opacity = (75 + anomalies[i].signal) / 100
        // if (display.opacity < 0.75) display.opacity = 0.75;

        // мин 0.3 макс 3
        let max = 3.5
        let min = 0.3

        display.scale = Number(max - (min * anomalies[i].signal / 10))
        display.priority = Math.round((max * 10) - (display.scale * 10))

        display.transform = "scale(" + display.scale + ") rotate( " + anomalies[i].rotate * -1 + "deg)";

        if (anomalies[i].type === 999) { // неопознаный тип
          display.background = "#15fff5";
        }

        if (anomalies[i].type === 0 || anomalies[i].type === 1 || anomalies[i].type === 7 || anomalies[i].type === 8) { // ящик, установленные ящики
          display.background = "#fff604";
        }

        if (anomalies[i].type === 2) { // руда, руды на карте
          display.background = "#13ff12";
        }

        if (anomalies[i].type === 3 || anomalies[i].type === 4) { // текс, артефакт
          display.background = "#0b0fff";
        }

        if (anomalies[i].type === 9) { // юниты
          display.background = "#f38b27";
        }

        if (anomalies[i].type === 10) { // мап итемы
          display.background = "#9c08f7";
        }

        anomaliesDisplay.sort(function (a, b) {
          return b.scale - a.scale;
        });

        anomaliesDisplay.push(display);
      }

      return anomaliesDisplay
    },
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#anomalyScannerWrapper {
  position: absolute;
  border-radius: 5px;
  background: rgb(8, 138, 210);
  border: 1px solid #25a0e1;
  padding: 20px 1px 0;
  left: 290px;
  top: 15px;
  width: 130px;
  pointer-events: auto;
}

#anomalyScanner {
  width: 130px;
  height: 130px;
  border-radius: 5px;
  box-shadow: inset 0 0 2px 0 black;
  float: left;
  background: #6c8998;
  margin: 2px 2px 2px 1px;
}

#anomalyDisplay {
  width: 130px;
  height: 130px;
  background-size: 10px 2px;
  background: linear-gradient(1deg, rgba(4, 13, 19, 0.6), rgba(2, 3, 6, 0.6) 6px);
  border-radius: 50%;
  box-shadow: inset 0 0 20px black;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

#anomalyBody {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-size: cover;
  top: -7px;
  position: relative;
}

.anomalyPing {
  width: 4px;
  height: 25px;
  position: absolute;
  top: 5px; /* */
  right: 5px; /* */
  background: #15fff5;
  border-radius: 50%;
  filter: blur(1px);
  transform: scale(1); /* изменяется скриптом */
}

.texture_wrapper, .texture_wrapper_web {
  height: 180px;
  width: 180px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 89;
}

.texture_wrapper_web {
  z-index: 88;
}
</style>
<style>
#anomalyScannerWrapper .windowsHead {
  height: 25px;
  line-height: 28px;
  font-size: 15px;
  z-index: 2;
  background: #569dba;
}
</style>
