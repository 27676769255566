var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Снаряжение")]),_vm._v(" "),_c('p',[_vm._v("Для управления своим снаряжением, игроку необходимо разместить свой выбор на специализированной панели.")]),_vm._v(" "),_c('p',[_vm._v("Располагаете вы оружие или какое-либо устройство, сделать это можно посредством перетаскивания данного предмета\n      из ангара/трюма в нужную ячейку панели снаряжения. ")]),_vm._v(" "),_c('p',[_vm._v("Если же вам требуется убрать из панели снаряжения вооружение/устройство - просто наведите курсор на необходимую\n      ячейку и вытащите/выбросите требуемое за её пределы.")]),_vm._v(" "),_c('p',[_vm._v("Аналогичным образом работает и перекладывание вооружения/устройств в иную ячейку - перетаскивать требуемое с\n      помощью курсора.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_1', 'gif')+ ')',
          width: '443px',
          height: '320px',
          marginLeft: 'calc(50% - 221px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Фон указывает на тип объекта расположеного в ячейке.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_3', 'png')+ ')',
          width: '400px',
          height: '271px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("\"Панель снаряжения\" во внешнем мире выглядит вот так. Через эту панель нельзя назначить или менять местами\n      снаряжение.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_2', 'png')+ ')',
          width: '288px',
          height: '131px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_vm._m(0)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Equipment")]),_vm._v(" "),_c('p',[_vm._v("To manage your equipment, the player needs to place their choice on a specialized panel.")]),_vm._v(" "),_c('p',[_vm._v("Whether you are placing a weapon or any device, this can be done by dragging the item\n      from the hangar/hold to the desired slot on the equipment panel. ")]),_vm._v(" "),_c('p',[_vm._v("If you need to remove a weapon/device from the equipment panel - simply hover the cursor over the required\n      slot and drag/drop the item out of it.")]),_vm._v(" "),_c('p',[_vm._v("Transferring weapons/devices to another slot works in a similar way - drag the required item\n      with the cursor.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_1', 'gif')+ ')',
          width: '443px',
          height: '320px',
          marginLeft: 'calc(50% - 221px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The background indicates the type of object located in the slot.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_3', 'png')+ ')',
          width: '400px',
          height: '271px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The \"Equipment Panel\" in the outside world looks like this. Through this panel, you cannot assign or swap\n      equipment.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('control_2', 'png')+ ')',
          width: '288px',
          height: '131px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_vm._m(1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Манипулятор")]),_vm._v(" в 1 ячейке внешнего мира позволяет взаимодействовать с объектами\n      (открывать ящики и меню сооружений).")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("The "),_c('span',{staticClass:"importantly"},[_vm._v("Manipulator")]),_vm._v(" in slot 1 of the outside world allows you to interact with\n      objects (open boxes and building menus).")])
}]

export { render, staticRenderFns }