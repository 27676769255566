<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Осада</h2>

      <p>
        Когда сектор принадлежит какому то кластеру то в специальное время которое указывает владелец в
        <router-link to="corporation_local_policy">локальных политиках</router-link>
        сектор ее можно атаковать.
      </p>

      <p>Что бы атаковать сектор потребуется самому находится в кластере и предмет которых нужен для саботажа систем
        базы
        и телепортов.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_1', 'png')+ ')',
            width: '361px',
            height: '247px',
            marginLeft: 'calc(50% - 180px)',
            }"/>

      <p>Что бы использовать предмет взлома, необходимо:</p>
      <ul>
        <li>перетащить предмет для саботажа из инвентаря на панель снаряжения.</li>
        <li>придти во время уязвимости базы к телепорту который ведет в нужный сектор.</li>
        <li>"использовать" предмет для взлома на вход в телепорт (вход должен быть в зоне обзора), если все правильно то
          появится диалоговое окно для подтверждения атаки.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_2', 'gif')+ ')',
            width: '364px',
            height: '281px',
            marginLeft: 'calc(50% - 182px)',
            }"/>

      <p>Так же можно взламывать уже взломанные порталы, например обороняющийся кластер или их союзники могут взломать
        уже
        взломанный портал что бы отсечь возможное подкрепление.</p>

      <p><span class="importantly">
      Одновременно на сектор может напасть столько кластеров сколько есть входов в портал.</span> То есть сектор где
        только 1 вход - очень защищенный сектор. Однако нападающие могут поочередно взламывать портал друг за другом.
      </p>

      <p>Атакующими считаются только те кто на текущей момент владеет телепортом или за кем он был закреплен после
        таймера. Это важно для условия завершения осады.</p>

      <h2 class="head">Как получить предмет для взлома порталов:</h2>
      <p>Он продается в
        <router-link to="fraction_market">наградном магазине</router-link>
        любой фракции за кредиты и очки фракций.
      </p>

      <h2 class="head">Как узнать когда можно напасть на сектор:</h2>
      <p>В меню карты мира при наведение на сектор будет показано это время.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_3', 'png')+ ')',
            width: '316px',
            height: '230px',
            marginLeft: 'calc(50% - 158px)',
            }"/>

      <h2 class="head">После взлома портала:</h2>
      <ul>
        <li>активируется режим осады в секторе на 1 час, если он уже активирован то время не увеличивается.</li>
        <li>телепорт который вы взломали начнет пропускать всех членов вашего кластера в сектор, даже если это не
          позволяла политика раньше.
        </li>
        <li>на выходах телепорта будет поле глушащие радары</li>
        <li>все строения при уничтожение больше не восстановятся</li>
        <li>главная база в секторе станет уязвима для атак</li>
        <li>сверху экрана начнется показыватся статистки кто нападет их число и состояние базы</li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_4', 'png')+ ')',
            width: '393px',
            height: '72px',
            marginLeft: 'calc(50% - 196px)',
            }"/>

      <h2 class="head">Завершение осады:</h2>
      <p>
        Через час взломанные порталы возвращаются в нормальный режим работы и если локальная политика настроена верно
        подкрепление для атакующих больше не будет поступать. (однако если в политике прописано что впускать всех то они
        смогут это сделать).
      </p>

      <p>
       <span class="importantly">
         После таймера осада будет продолжатся до тех пор пока все атакующие в секторе не будут уничтожены.</span>
      </p>

      <p>Уничтожение базы так же заканчивает осаду. На месте базы появятся обломки базы в которых будет сохранена часть
        предметов.</p>
      <p>Если базу уничтожат:</p>
      <ul>
        <li>все имущество находящиеся на складах игроков и кластеров будет потеряно или останется в обломках</li>
        <li>все ордера на рынке которые находятся на этой базу будут уничтожены</li>
        <li>игроки находящиеся на базе в момент уничтожения потеряют свои транспорты и отправятся в столицу</li>
      </ul>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Siege</h2>

      <p>
        When a sector belongs to a certain cluster, it can be attacked during special times indicated by the owner in
        the
        <router-link to="corporation_local_policy">local policies</router-link>
        of the sector.
      </p>

      <p>To attack a sector, you need to be in a cluster and have an item needed for sabotaging the base systems and
        teleports.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_1', 'png')+ ')',
            width: '361px',
            height: '247px',
            marginLeft: 'calc(50% - 180px)',
            }"/>

      <p>To use the hacking item, you need to:</p>
      <ul>
        <li>drag the sabotage item from the inventory to the equipment panel.</li>
        <li>arrive at the teleport leading to the desired sector during the base's vulnerability time.</li>
        <li>"use" the hacking item at the teleport entrance (the entrance must be within the line of sight), if
          everything is correct, a dialogue window will appear to confirm the attack.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_2', 'gif')+ ')',
            width: '364px',
            height: '281px',
            marginLeft: 'calc(50% - 182px)',
            }"/>

      <p>You can also hack already hacked portals, for example, the defending cluster or their allies can hack an
        already hacked portal to cut off possible reinforcements.</p>

      <p><span class="importantly">
  Multiple clusters can simultaneously attack a sector as many as there are entrances to the portal.</span> That is, a
        sector with only one entrance is a very protected sector. However, attackers can sequentially hack the portal
        one after another.</p>

      <p>Those who currently own the teleport or for whom it was secured after the timer are considered attackers. This
        is important for the condition of ending the siege.</p>

      <h2 class="head">How to get an item to hack portals:</h2>
      <p>It is sold in the
        <router-link to="fraction_market">reward shop</router-link>
        of any faction for credits and faction points.
      </p>

      <h2 class="head">How to know when you can attack a sector:</h2>
      <p>In the world map menu, when hovering over a sector, this time will be shown.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_3', 'png')+ ')',
            width: '316px',
            height: '230px',
            marginLeft: 'calc(50% - 158px)',
            }"/>

      <h2 class="head">After hacking the portal:</h2>
      <ul>
        <li>the siege mode is activated in the sector for 1 hour, if it is already activated then the time does not
          increase.
        </li>
        <li>the teleport you hacked will start letting all members of your cluster into the sector, even if the policy
          did not allow it before.
        </li>
        <li>there will be a radar jamming field at the exits of the teleport.</li>
        <li>all structures upon destruction will no longer be restored.</li>
        <li>the main base in the sector will become vulnerable to attacks.</li>
        <li>statistics of who attacks, their number, and the state of the base will start to be displayed at the top of
          the screen.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('siege_4', 'png')+ ')',
            width: '393px',
            height: '72px',
            marginLeft: 'calc(50% - 196px)',
            }"/>

      <h2 class="head">Ending the siege:</h2>
      <p>
        After an hour, the hacked portals return to normal operation and if the local policy is set correctly,
        reinforcements for the attackers will no longer arrive. (however, if the policy is written to let everyone in,
        they will be able to do so).
      </p>

      <p>
   <span class="importantly">
     After the timer, the siege will continue until all attackers in the sector are destroyed.</span>
      </p>

      <p>The destruction of the base also ends the siege. Debris of the base will appear in its place, in which some of
        the items will be preserved.</p>
      <p>If the base is destroyed:</p>
      <ul>
        <li>all property located in the warehouses of players and clusters will be lost or remain in the debris.</li>
        <li>all orders on the market that are on this base will be destroyed.</li>
        <li>players who are on the base at the time of destruction will lose their transports and be sent to the
          capital.
        </li>
      </ul>

    </template>
  </div>
</template>

<script>
export default {
  name: "CorporationSiege",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
