import {gameStore} from "../store";
import {CreateSelectSprite, destroyAllSelectedSprite} from "./create_select_sprite";
import store from "../../store/store";
import {Scene} from "../create";
import {MapSize} from "../map/createMap";

function MoveHandler() {

  let callBack = function (x, y) {
    destroyAllSelectedSprite();

    let selectSprite = Scene.make.image({
      x: x,
      y: y,
      key: "select_sprite",
      add: true
    });

    selectSprite.setOrigin(0.5);
    selectSprite.setDisplaySize(120, 120);
    selectSprite.setDepth(1000);
    selectSprite.setInteractive();

    console.log(selectSprite)
    let updatePos = setInterval(function () {
      selectSprite.x = Math.round(Scene.input.mousePointer.worldX);
      selectSprite.y = Math.round(Scene.input.mousePointer.worldY);
    }, 10);

    selectSprite.on('pointerdown', function (pointer) {
      if (pointer.leftButtonDown()) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "map_editor",
          data: {
            event: "moveHandler",
            id: gameStore.mapEditorState.mpID,
            x: x,
            y: y,
            to_x: Number(selectSprite.x - MapSize),
            to_y: Number(selectSprite.y - MapSize)
          }
        }));
      }

      selectSprite.destroy()
      clearInterval(updatePos)
    });
  };

  SelectedHandlers(callBack)
}

function SelectedHandlers(callBack, type) {
  if (gameStore.gameReady) {
    let map = gameStore.map;

    for (let x in map.OneLayerMap) {
      for (let y in map.OneLayerMap[x]) {
        if (map.OneLayerMap[x][y].handler !== "") {
          if (!map.OneLayerMap[x][y].handler) continue
          if ((type && map.OneLayerMap[x][y].handler === type) || !type) {
            CreateSelectSprite(x, y, callBack, true, x, y);
          }
        }
      }
    }
  }
}

export {MoveHandler}
