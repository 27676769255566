<template>
  <div id="AttackDialog" ref="AttackDialog"
       :style="{left: 'calc(50% - 125px)', top: '20%', opacity: '1'}" v-if="true">

    <div class="error_message" :class="{error_message_visible: attackBaseFail}">
      <div style="margin-top: 14%">{{ texts['failed'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: attackBaseSuccess}">
      <div style="margin-top: 14%">{{ texts['success'][language] }}</div>
    </div>


    <div class="wrapper">
      <div v-if="!data || !attackCorporation || !attackCorporation.logo"
           style="width: 43px; height: 100%; margin-left: calc(50% - 21px)">
        <app-preloader/>
      </div>

      <template v-if="data && attackCorporation && attackCorporation.logo">
        <div style="width: 100%; height: 100%;">
          <p class="text">{{ texts['text_1'][language] }}</p>
          <app-corporation-line :corp="attackCorporation.corporation"
                                :logo="attackCorporation.logo"
                                :parent="'AttackDialog'"/>

          <p class="text">{{ texts['text_2'][language] }}</p>
          <select class="local_select settings" v-model="access">
            <option value="cluster">{{ texts['option_1'][language] }}</option>
          </select>

          <p class="text importantly">{{ texts['text_3'][language] }}</p>
        </div>

        <div style="margin-top: 6px;">
          <input type="button" :value="texts['back_button'][language]" class="button"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="close">
          <input type="button" :value="texts['attack_button'][language]" class="button"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="attack"
                 style="float: right">
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import Preloader from "../Preloader/Preloader";
import CorporationLine from '../Corporation/CorporationLine';
import {urls} from "../../const";

export default {
  name: "AttackDialog",
  data() {
    return {
      attackCorporation: null,
      access: 'cluster'
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    close() {
      this.$store.commit({
        type: 'setAttackDialog',
        data: false,
      });

      this.$store.commit({
        type: 'toggleWindow',
        id: 'AttackDialog',
        forceClose: true,
      });
    },
    getCorporationByID(id) {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        if (response.data) {
          app.$set(app, 'attackCorporation', response.data);
          app.getCorpLogo(response.data.corporation.id);
        }
      })
    },
    getCorpLogo(id) {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.attackCorporation, 'logo', response.data.avatar);
      });
    },
    attack() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "AttackBase",
          value: this.data.slot,
          x: this.data.x,
          y: this.data.y,
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('AttackDialog')
    },
    data() {
      let data = this.$store.getters.getAttackDialog
      if (data) this.getCorporationByID(data.corporation_id)

      return data
    },
    attackBaseFail() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('attack_base_fail') && errors['attack_base_fail'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'attack_base_fail',
            active: false,
          });
        }.bind(this), 750)

        return true
      }

      return false
    },
    attackBaseSuccess() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('attack_base_success') && errors['attack_base_success'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'attack_base_success',
            active: false,
          });
          this.close();
        }.bind(this), 750)

        return true
      }

      return false
    },
  },
  components: {
    AppPreloader: Preloader,
    AppCorporationLine: CorporationLine,
  }
}
</script>

<style scoped>
#AttackDialog {
  line-height: 20px;
  min-width: 20px;
  opacity: 0.6;
  position: absolute;
  min-height: 45px;
  width: 250px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  border-radius: 5px;
  z-index: 120;
  padding: 3px;
  filter: drop-shadow(0 0 2px black);
  background: rgba(8, 70, 140, 0.4);
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
}

.wrapper {
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: inset 0 0 2px black;
  padding: 5px;
  height: 110px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}

.text {
  margin: 0;
}

.local_select {
  outline: none;
  width: calc(100% - 6px);
  border-radius: 5px;
  margin: 0 0 4px 0;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

.local_select option {
  color: black;
  font-weight: bold;
}

.settings {
  float: right;
  margin-right: 7px;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 750ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}
</style>
