var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Отряды")]),_vm._v(" "),_c('p',[_vm._v("При желании, игроки могут объединить свои усилия ради достижения любых целей посредством - отрядов.")]),_vm._v(" "),_c('p',[_vm._v("Данное объединение игроков считается единой союзной силой, к чьим услугам будет доступна статистика\n      каждого члена отряда, а такие функции как “Радар” и “Обзор” объединяются.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('squad_1', 'gif')+ ')',
          width: '480px',
          height: '224px',
           marginLeft: 'calc(50% - 240px)',
    })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('squad_2', 'gif')+ ')',
          width: '480px',
          height: '224px',
          marginLeft: 'calc(50% - 240px)',
          marginTop: '10px',
    })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Squads")]),_vm._v(" "),_c('p',[_vm._v("If desired, players can unite their efforts to achieve any goals through squads.")]),_vm._v(" "),_c('p',[_vm._v("This union of players is considered a single allied force, which will have access to the statistics of each\n      squad member, and functions such as \"Radar\" and \"Overview\" are combined.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('squad_1', 'gif')+ ')',
          width: '480px',
          height: '224px',
           marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('squad_2', 'gif')+ ')',
            width: '480px',
            height: '224px',
            marginLeft: 'calc(50% - 240px)',
            marginTop: '10px',
      })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }