<template>
  <div id="helpWrapper" ref="helpWrapper" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'helpWrapper'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"
                 v-bind:resizeFunc="resize"
                 v-bind:maxSize="{height: null, width: null}"
                 v-bind:minSize="{height: 325, width: 700}"/>

    <div id="HelpSections">
      <app-content-list v-bind:noRoute="true" @click="select = $event"/>
    </div>

    <div id="HelpContentWrapper">

      <div id="HelpContent">
        <component v-if="select" :is="select.component"/>
        <div v-if="!select">
          <h3 class="head">{{ texts['text_1'][language] }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import Control from '../Window/Control';
import ContentList from '../Index/Wiki/ContentList';
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  '../Index/Wiki/WikiPages/',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
)

/** ¯\_(ツ)_/¯ **/

export default {
  name: "Help",
  props: ['meta'],
  mounted() {
    let app = this;

    this.$router.beforeEach((to, from, next) => {
      if (to.path === "/global" || to.path === "/lobby" || to.path === "/login" || to.path === "/game" ||
        to.path === "/gate" || to.path === "/transfer") {
        next()
        return;
      }

      let components = app.$router.getMatchedComponents(`/wiki${to.path}`);
      if (components && components.length > 0) {

        app.$store.commit({
          type: 'setHelpSelect',
          data: {component: components[components.length - 1].name},
        });

        next(false)
      } else {
        next()
      }
    })

    requireComponent.keys().forEach(fileName => {
      // Get component config
      const componentConfig = requireComponent(fileName)

      // Get PascalCase name of component
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    })

    if (this.$props.meta && this.$props.meta.component) {
      app.$store.commit({
        type: 'setHelpSelect',
        data: {component: this.$props.meta.component},
      });
    }
  },
  methods: {
    resize(event, ui, el) {
      el.find('#HelpSections').css("width", 190);
      el.find('#HelpContentWrapper').css("width", 'calc(100% - 193px)');
      el.find('#HelpContent').css("width", 'calc(100% - 12px)');
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    getUrlImg(imgName, format) {
      return require('../../assets/indexImg/index_detail/' + imgName + '.' + format).default
    },
    getImagesCss(el, defaultCss) {
      return defaultCss
    },
  },
  components: {
    AppControl: Control,
    VRuntimeTemplate,
    AppContentList: ContentList,
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Help')
    },
    select() {
      return this.$store.getters.getHelpSelect
    },
  },
}
</script>

<style>
#helpWrapper {
  top: 50px;
  left: calc(50% - 200px);
  height: 325px;
  width: 700px;
  position: absolute;
  border-radius: 5px;
  padding: 20px 3px 3px 3px;
  z-index: 11;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  box-shadow: 0 0 2px black;
  pointer-events: auto;
  backdrop-filter: blur(4px);
}

#HelpSections {
  float: left;
  height: 100%;
  width: 190px;
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin-right: 3px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#HelpContentWrapper {
  height: calc(100% - 11px);
  pointer-events: auto;
  width: 507px;
  float: left;
}

#HelpHead {
  float: left;
  height: 23px;
  width: 497px;
  text-align: left;
  margin: 0;
  padding-left: 10px;
  line-height: 25px;
  font-weight: 900;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#HelpContent {
  margin: 0;
  overflow-y: scroll;
  height: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0 5px black;
  background: #89969c;
  pointer-events: auto;
  overflow-x: hidden;
  width: calc(100% - 12px);
  text-align: left;
  font-size: 13px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.helpImg {
  float: left;
  margin: 5px;
  border-radius: 3px;
  background-size: cover;
  box-shadow: 0 0 2px 1px black;
}

#HelpContent > div {
  font-size: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  text-indent: 15px;
  text-align: justify;
  padding: 5px;
  margin: 4px 0;
}

#HelpContent ol {
  margin: 0;
  padding: 0;
}

#HelpContent ol li {
  margin: 0;
  padding: 0;
}

#HelpContent > div a {
  color: #32f2ff;
  font-weight: 900;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  text-indent: 15px;
  text-align: justify;
  padding: 5px;
  margin: 4px 0;
  text-decoration: none;
}

.bracket {
  font-size: 10px;
  color: white;
  opacity: 0.5;
}

#HelpSections .content_head, #HelpSections .content_button, #HelpSections .content_button.sub {
  text-align: left;
  display: block;
  padding-left: 10px;
  background: rgb(222, 101, 40);
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin: 2px 0 0 2px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  font-weight: bold;
  list-style-type: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 12px);
}

#HelpSections .content_head {
  background: rgb(117 146 171);
}

#HelpSections .content_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


#HelpSections .content_list .content_list {
  list-style-type: none;
  padding: 0;
  margin-top: 0 !important;
}

#HelpSections .content_button.sub {
  background: rgba(199, 127, 90, 0.75);
  margin-left: 10px;
  font-size: 11px;
  width: calc(100% - 15px);
}

#HelpSections .content_button:hover, #HelpSections .content_button.sub:hover {
  box-shadow: 0 0 2px rgb(255, 255, 255);
}

#HelpSections .content_button:active, #HelpSections .content_button.sub:active {
  transform: scale(0.98);
}

#HelpContent {
  text-align: justify;
  text-shadow: 0 0 2px #343434, 2px 1px 0 hsl(0, 0%, 27%), 2px 1px 1px hsl(0, 0%, 23%), 1px 1px 2px hsl(80, 10%, 15%);
  color: white;
  font-size: 12px;
  background: rgba(111, 111, 111, 0.70);
}

#HelpContent .headSection {
  color: #ffc588;
  font-size: 14px;
}

#HelpContent .features p {
  margin: 0;
}

#HelpContent .head {
  margin: 0 0 7px;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
}

#HelpContent .content_button {
  text-indent: 5px;
  pointer-events: auto;
  text-align: left;
  transition: .1s;
  box-shadow: 0 0 2px #000;
  height: 15px;
  width: 200px;
  background: rgba(255, 129, 0, .75);
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  line-height: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#HelpContent .content_button.sub {
  background: rgba(243, 129, 11, 0.5);
}

#HelpContent .content_button:active {
  transform: scale(.98);
}

#HelpContent .content_button:hover {
  background: #ff8100;
}

#HelpContent .content_list li {
  margin-bottom: 5px;
}

#HelpContent .detailImg {
  border-radius: 3px;
  background-size: cover;
  filter: drop-shadow(0 0 3px black);
}

#HelpContent .content_section {
  position: relative;
  clear: both;
  padding: 0 5px;
}

#HelpContent .content_section:after {
  content: ' ';
  clear: both;
  display: block;
}

#HelpContent .imageLi:after {
  content: ' ';
  clear: both;
  display: block;
}

#HelpContent .imageLi {
  clear: both;
  margin-bottom: 5px;
}

#HelpContent a {
  color: #0cfdff;
  text-decoration: none;
}

#HelpContent a:hover {
  text-decoration: underline;
  color: #ffc588;
}

#HelpContent .bracket {
  font-size: 10px;
  color: white;
  opacity: 0.5;
}

#HelpContent .content_section li {
  margin-top: 10px;
}
</style>
