<template>
  <div class="content_section">
    <h2 class="head">Продажа сырья</h2>
  </div>
</template>

<script>
export default {
  name: "SellBaseResource",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
