<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Войны</h2>
      <p><span class="importantly">War... War never changes.</span></p>
      <p>Когда у корпорации появляется
        <router-link to="corporation_build">база в пустошах</router-link>
        она может участвовать в войнах. То есть для того что бы участвовать в войнах нужна база.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('war_1', 'png')+ ')',
            width: '375px',
            height: '142px',
            marginLeft: 'calc(50% - 187px)',
            }"/>

      <p>В войне участвует 2 корпорации, одна является нападающей стороной другая обороняющийся. На враждующие стороны
        не
        действуют правила правосудия даже в безопасных секторах.</p>

      <p>В игре транспорты врагов будут помечены особым значком.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('war_2', 'png')+ ')',
            width: '253px',
            height: '172px',
            marginLeft: 'calc(50% - 126px)',
            }"/>

      <h2 class="head">Объявление войны</h2>
      <p>Что бы объявить войну другому кластеру надо соблюсти несколько условий:</p>
      <ul>
        <li>У вашего кластера должны быть база в пустошах, которую вы можете назначить "штабом" (1 база - 1 война)</li>
        <li>У кластера который вы хотите атаковать тоже должны быть база в пустошах</li>
        <li>У вас есть на одном из депозитов 100000.0 кредитов</li>
      </ul>

      <p>Нападающая сторона должны будет платить взнос(100000.0cr) каждую неделю на подержание войны, иначе она
        завершится. Однако если война "взаимная" то платить не придется.</p>

      <h2 class="head">Завершение войны</h2>
      <p>Война завершается если:</p>
      <ul>
        <li>Кластер нападающей или обороняющейся стороны распался.</li>
        <li>Война не является "взаимной" и нападающая сторона не смогла оплатить взнос.</li>
        <li>Кластер нападающей или обороняющейся стороны больше не имеет базы в пустошах.</li>
        <li>"Штаб" нападающей стороны уничтожен.</li>
        <li>Обе стороны согласились на статус-кво.</li>
      </ul>

      <h2 class="head">Взаимная война</h2>
      <p>Если обороняющиеся стороны решит сделать войну "взаимной" то нападающей стороне не надо больше платить взнос,
        однако и завершить конфликт по этой причине тоже не получится. Придется договариваться или воевать до самого
        конца
        }8)</p>

      <h2 class="head">Статус-кво</h2>
      <p>Если обе стороны согласились на статус-кво то конфликт завершается и 2 стороны не смогут начать новый в течение
        14 дней.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Wars</h2>
      <p><span class="importantly">War... War never changes.</span></p>
      <p>When a corporation has a <a href="corporation_build">base in the wastelands</a>, it can participate in wars.
        That is, to participate in wars, a base is needed.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('war_1', 'png')+ ')',
            width: '375px',
            height: '142px',
            marginLeft: 'calc(50% - 187px)',
            }"/>

      <p>In a war, two corporations participate, one as the attacking side and the other as the defending side. The
        rules of justice do not apply to the warring sides even in safe sectors.</p>

      <p>In the game, enemy transports will be marked with a special icon.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('war_2', 'png')+ ')',
            width: '253px',
            height: '172px',
            marginLeft: 'calc(50% - 126px)',
            }"/>

      <h2 class="head">Declaring War</h2>
      <p>To declare war on another cluster, several conditions must be met:</p>
      <ul>
        <li>Your cluster must have a base in the wastelands, which you can designate as "headquarters" (1 base - 1
          war).
        </li>
        <li>The cluster you want to attack must also have a base in the wastelands.</li>
        <li>You have 100,000.0 credits on one of the deposits.</li>
      </ul>

      <p>The attacking side will have to pay a contribution (100,000.0cr) every week to maintain the war, otherwise, it
        will end. However, if the war is "mutual," no payment is required.</p>

      <h2 class="head">Ending the War</h2>
      <p>The war ends if:</p>
      <ul>
        <li>The cluster of the attacking or defending side has disbanded.</li>
        <li>The war is not "mutual" and the attacking side could not pay the contribution.</li>
        <li>The cluster of the attacking or defending side no longer has a base in the wastelands.</li>
        <li>The "headquarters" of the attacking side is destroyed.</li>
        <li>Both sides agreed to a status quo.</li>
      </ul>

      <h2 class="head">Mutual War</h2>
      <p>If the defending side decides to make the war "mutual," the attacking side no longer needs to pay the
        contribution, but it also won't be able to end the conflict for this reason. It will have to negotiate or fight
        to the very end. }8)</p>

      <h2 class="head">Status Quo</h2>
      <p>If both sides agree to a status quo, the conflict ends and the two sides will not be able to start a new one
        for 14 days.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "CorporationWars",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
