<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Сбор обломков</h2>
      <p>После уничтожения синтета на поверхности планеты часто остаются обломки его корпуса. Всё, что находилось в
        трюме, разбрасывается вокруг обломков.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('dismantling_unit_2', 'gif')+ ')',
            width: '400px',
            height: '300px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <p>Обломки корпуса имеют половину от максимального здоровья уничтоженного транспорта. Это позволяет использовать
        их как укрытие в случае необходимости.</p>

      <p>Всё снаряжение, установленное в корпусе, включая оружие, может сохраниться внутри обломков. Также в обломках
        остаются боеприпасы, которые находились в трюме. Всё остальное, что было в трюме (кроме боеприпасов),
        разлетается вокруг.</p>

      <p>Шанс сохранения оружия и снаряжения установленное в корпусе равен 1 к 15.</p>

      <p>Количество предметов из трюма, которые сохранятся после уничтожения, варьируется от 10 % до 100 %. Однако
        гарантированно сохранится хотя бы один предмет из пачки, даже если в ней всего один предмет.</p>

      <p>Сами обломки можно переработать на детали посредством инженерного модуля.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('dismantling_unit_1', 'gif')+ ')',
            width: '300px',
            height: '185px',
            marginLeft: 'calc(50% - 150px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Debris Collection</h2>

      <p>After the destruction of the synthet, the fragments of its hull often remain on the planet's surface.
        Everything that was in the hold is scattered around the fragments.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('dismantling_unit_2', 'gif')+ ')',
            width: '400px',
            height: '300px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <p>The fragments of the hull have half of the maximum health of the destroyed transport. This allows them to be
        used as a shelter if necessary.</p>

      <p>All equipment installed in the hull, including weapons, may be preserved inside the fragments. Also, the
        ammunition that was in the hold remains in the fragments. Everything else that was in the hold (except for
        ammunition) scatters around.</p>

      <p>The chance of preserving weapons and equipment installed in the hull is 1 to 15.</p>

      <p>The number of items from the hold that will be preserved after destruction varies from 10% to 100%. However, at
        least one item from the batch will be guaranteed to be preserved, even if there is only one item in it.</p>

      <p>The debris itself can be processed into parts using an engineering module.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('dismantling_unit_1', 'gif')+ ')',
        width: '300px',
        height: '185px',
        marginLeft: 'calc(50% - 150px)',
        }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Salvage",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
