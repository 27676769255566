<template>
  <div>
    <template v-if="lang==='RU'">

      <h2 class="head" id="bodies">Переработка ресурсов</h2>
      <p>Каждый найденный игроком ресурс может и должен быть преобразован в сырьё, что пойдёт на дальнейшие нужды
        игрока.
        Ради подобной задачи, следует использовать “переработчик ресурсов” - устройство, что присутствует базе
        переработки
        фракций синтетов и способное не только перерабатывать добытое сырьё в предметы, но и даже разбирать уже готовые
        детали. </p>

      <p>Игровая система переработки построена таким образом, что у каждого найденного ресурса-ископаемого, имеется
        базовое количество сырья, которое можно получить при переработке. На данный параметр, влияют соответствующие
        навыки игрока, что позволяет уменьшить потери во время переработки сырья и снизить налог фракции на данную
        услугу. </p>

      <h3 class="head">Алгоритм переработки</h3>
      <ul>
        <li>Игрок устанавливает в переработчик ресурсов соответствующие предметы - данное действие запускает весь
          процесс
          переработки;
        </li>
        <li>Система рассчитывает максимальное количество ресурсов, затем отнимается процент потерь при переработке,
          предоставляя игроку n-количество предметов, получаемых от данного действия;
        </li>
        <li>Из того, что осталось, база забирает налог на поддержание своих систем.
        </li>
        <li>Оставшееся количество ресурсов упадет на склад игрока.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('processing_1', 'jpg')+ ')',
            width: '480px',
            height: '222px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head" id="bodies">Resource Processing</h2>
      <p>Every resource found by a player can and should be transformed into raw materials for further use by the
        player. For such a task, it is necessary to use a "resource processor" - a device present at the processing base
        of the synthetic factions, capable of not only processing the extracted raw materials into items but also
        disassembling already finished parts.</p>

      <p>The game's processing system is built in such a way that each found resource-fossil has a base amount of raw
        materials that can be obtained during processing. This parameter is influenced by the corresponding skills of
        the player, which allows reducing losses during the processing of raw materials and reducing the faction's tax
        for this service.</p>

      <h3 class="head">Processing Algorithm</h3>
      <ul>
        <li>The player places the corresponding items in the resource processor - this action starts the entire
          processing process;
        </li>
        <li>The system calculates the maximum amount of resources, then subtracts the percentage of losses during
          processing, providing the player with an n-amount of items obtained from this action;
        </li>
        <li>From what remains, the base takes a tax to maintain its systems.</li>
        <li>The remaining amount of resources falls into the player's warehouse.</li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('processing_1', 'jpg')+ ')',
        width: '480px',
        height: '222px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Processing",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
