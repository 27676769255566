<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Создание предметов на базе</h2>

      <p>На главной базе сектора можно создавать различные предметы при наличие чертежа и ресурсов для него. База может
        взымать налог на создание из расчета своей эффективности.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('craft_items_1', 'png')+ ')',
            width: '487px',
            height: '266px',
            marginLeft: 'calc(50% - 243px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Item Creation at the Base</h2>

      <p>At the main base of the sector, you can create various items if you have the blueprint and resources for it.
        The base may charge a tax on creation based on its efficiency.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('craft_items_1', 'png')+ ')',
            width: '487px',
            height: '266px',
            marginLeft: 'calc(50% - 243px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "CraftItems",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
