import {percentEnergy, percentHP, statusBarWrapper} from "./status_bar";
import store from "../../store/store";
import {VueEx} from "../../main";
import {md5} from "../utils/md5";
import {Scene} from "../create";
import {gameStore} from "../store";

let groupBar = {
  groupState: [], // {player_id, name/max, hp/max, energy/max, inventory/max}
  bars: {},       // player_id: 0, type: '', outer: null, inner: null,
  initAvatars: {},
  avatars: {},
  names: {},
  loadAvatars: {},
  barConfig: {
    outerBorderColor: 0x000000,
    outerBackColor: 0x919191,
    width: 70,
    hp: {
      x_offset: -10,
      y_offset: -5,
      height: 13,
    },
    energy: {
      x_offset: -10,
      y_offset: 7,
      height: 8,
      color: 0x87ceeb,
    },
  },
  avatarConfig: {
    pos: {x: 24, y: 102},
    offset: 38,
    size: 30,
  },
  waitData: null,
  waitMsgs: [],
  cacheTexture: {},
}

function initGroupBar() {
  if (!statusBarWrapper.scene) return;

  for (let i in groupBar.groupState) {
    if (!groupBar.loadAvatars[groupBar.groupState[i].id]) {
      createAvatar(groupBar.groupState[i].id, i);
      createBars(groupBar.groupState[i].id, i);
    }
  }
}

function createAvatar(playerID, i) {
  if (!groupBar.initAvatars[playerID]) {
    groupBar.initAvatars[playerID] = true

    store.dispatch('getAvatar', {
      userID: playerID,
      vm: VueEx
    });

    let avatar = store.getters.getAvatars[playerID]
    if (!avatar || avatar.fake) {
      groupBar.initAvatars[playerID] = false
      return
    }

    let avatarBase64 = avatar.avatar;
    if (!avatarBase64) {
      groupBar.initAvatars[playerID] = false
      return
    }

    avatarBase64 = avatarBase64.substring(5)
    avatarBase64 = avatarBase64.substring(0, avatarBase64.length - 2)

    let key = md5(avatarBase64)
    if (!groupBar.avatars[playerID]) groupBar.avatars[playerID] = {};
    groupBar.avatars[playerID].textureKey = 'avatar' + key;

    try {
      if (!groupBar.cacheTexture.hasOwnProperty(key)) {
        groupBar.cacheTexture[key] = true;
        Scene.textures.addBase64(groupBar.avatars[playerID].textureKey, avatarBase64);
      }
    } catch (e) {
      console.log(e)
    }

    groupBar.avatars[playerID].border = statusBarWrapper.scene.add.rectangle(
      groupBar.avatarConfig.pos.x,
      groupBar.avatarConfig.pos.y + (groupBar.avatarConfig.offset * i),
      groupBar.avatarConfig.size,
      groupBar.avatarConfig.size,
      0x25a0e1);

    groupBar.avatars[playerID].border.setStrokeStyle(4, 0x25a0e1);
    groupBar.avatars[playerID].border.setDepth(1001)
  }

  if (groupBar.avatars[playerID].sprite) {
    groupBar.avatars[playerID].sprite.destroy();
    groupBar.avatars[playerID].sprite = null;
  }

  groupBar.avatars[playerID].sprite = statusBarWrapper.scene.make.image({
    x: groupBar.avatarConfig.pos.x,
    y: groupBar.avatarConfig.pos.y + (groupBar.avatarConfig.offset * i),
    key: groupBar.avatars[playerID].textureKey,
    add: true
  });
  groupBar.avatars[playerID].sprite.setDepth(1100)
  groupBar.avatars[playerID].sprite.setScale(0.2)
  groupBar.avatars[playerID].sprite.setDisplaySize(groupBar.avatarConfig.size, groupBar.avatarConfig.size);

  if (!groupBar.names[playerID]) groupBar.names[playerID] = {};
  if (groupBar.names[playerID].sprite) {
    groupBar.names[playerID].sprite.destroy();
    groupBar.names[playerID].sprite = null;
  }

  groupBar.names[playerID].sprite = statusBarWrapper.scene.add.bitmapText(
    groupBar.avatarConfig.pos.x + groupBar.avatarConfig.size - 8,
    groupBar.avatarConfig.pos.y + (groupBar.avatarConfig.offset * i) - 17,
    'bit_text', getLogin(playerID), 12);

  groupBar.names[playerID].sprite.setOrigin(0);
  groupBar.names[playerID].sprite.setLetterSpacing(1)
  groupBar.names[playerID].sprite.setTint(0xFFFFFF)

  if (groupBar.avatars[playerID].sprite.texture.key !== '__MISSING') {
    groupBar.loadAvatars[playerID] = true
  }
}

function createBars(playerID, i) {

  if (!groupBar.bars[playerID]) groupBar.bars[playerID] = {
    hp: {},
    energy: {},
  };

  if (groupBar.bars[playerID].hp.outer) {
    groupBar.bars[playerID].hp.outer.destroy();
  }

  groupBar.bars[playerID].hp.outer = statusBarWrapper.scene.add.graphics({
    x: groupBar.avatarConfig.pos.x + groupBar.avatarConfig.size + groupBar.barConfig.hp.x_offset,
    y: (groupBar.avatarConfig.pos.y + groupBar.barConfig.hp.y_offset) + (groupBar.avatarConfig.offset * i),
    add: true,
  });

  groupBar.bars[playerID].hp.outer.fillStyle(groupBar.barConfig.outerBorderColor);
  groupBar.bars[playerID].hp.outer.fillRect(0, 0, groupBar.barConfig.width, groupBar.barConfig.hp.height);
  groupBar.bars[playerID].hp.outer.fillStyle(groupBar.barConfig.outerBackColor);
  groupBar.bars[playerID].hp.outer.fillRect(2, 2, groupBar.barConfig.width - 4, groupBar.barConfig.hp.height - 4);

  if (groupBar.bars[playerID].energy.outer) {
    groupBar.bars[playerID].energy.outer.destroy();
  }

  groupBar.bars[playerID].energy.outer = statusBarWrapper.scene.add.graphics({
    x: groupBar.avatarConfig.pos.x + groupBar.avatarConfig.size + groupBar.barConfig.energy.x_offset,
    y: (groupBar.avatarConfig.pos.y + groupBar.barConfig.energy.y_offset) + (groupBar.avatarConfig.offset * i),
    add: true,
  });

  groupBar.bars[playerID].energy.outer.fillStyle(groupBar.barConfig.outerBorderColor);
  groupBar.bars[playerID].energy.outer.fillRect(0, 0, groupBar.barConfig.width, groupBar.barConfig.energy.height);
  groupBar.bars[playerID].energy.outer.fillStyle(groupBar.barConfig.outerBackColor);
  groupBar.bars[playerID].energy.outer.fillRect(2, 2, groupBar.barConfig.width - 4, groupBar.barConfig.energy.height - 4);
}

function addGroupPlayer(playerID) {
  let find = false;

  for (let i in groupBar.groupState) {
    if (groupBar.groupState[i].id === playerID) find = true;
  }

  if (!find) {
    groupBar.groupState.push({id: playerID});
    groupBar.initAvatars[playerID] = false;
    groupBar.loadAvatars[playerID] = false;
    groupBar.avatars[playerID] = {};
    groupBar.names[playerID] = {};
    groupBar.bars[playerID] = {
      hp: {},
      energy: {},
    };
  }
}

function RemoveGroupBar() {
  for (let i in groupBar.groupState) {
    let id = groupBar.groupState[i].id
    groupBar.initAvatars[id] = false;
    groupBar.loadAvatars[id] = false;

    if (groupBar.avatars[id] && groupBar.avatars[id].sprite) {
      groupBar.avatars[id].sprite.destroy();
      groupBar.avatars[id].border.destroy();
    }

    if (groupBar.names[id] && groupBar.names[id].sprite) {
      groupBar.names[id].sprite.destroy();
    }

    if (groupBar.bars[id]) {
      if (groupBar.bars[id].hp.outer) groupBar.bars[id].hp.outer.destroy();
      if (groupBar.bars[id].hp.inner) groupBar.bars[id].hp.inner.destroy();

      if (groupBar.bars[id].energy.outer) groupBar.bars[id].energy.outer.destroy();
      if (groupBar.bars[id].energy.inner) groupBar.bars[id].energy.inner.destroy();
    }
  }

  groupBar.groupState = [];
  groupBar.initAvatars = {};
  groupBar.loadAvatars = {};
  groupBar.avatars = {};
  groupBar.bars = {};
  groupBar.names = {};
}

function waitInit() {
  if (!groupBar.waitData) {
    groupBar.waitData = setInterval(function () {
      if (!statusBarWrapper.scene) return;

      while (groupBar.waitMsgs.length > 0) {
        let msg = groupBar.waitMsgs.shift();
        UpdateGroupPlayer(msg);
      }

      clearInterval(groupBar.waitData)
      groupBar.waitData = null
    }, 100)
  }
}

function UpdateGroupPlayer(force) {
  if (!statusBarWrapper.scene) {
    groupBar.waitMsgs.push(gameStore.groupState.state);
    waitInit()
    return;
  }

  let data = parseData(gameStore.groupState.state)
  if (data.join('') !== groupBar.groupState.join('') || force) {
    fullUpdateGroupPlayer(data)
  }

  for (let i in data) {
    let playerID = data[i].id
    fillBars(playerID, i)
    if (groupBar.avatars[playerID] && groupBar.names[playerID] && groupBar.bars[playerID]) {
      if (gameStore.groupState.state[playerID].on) {
        if (groupBar.avatars[playerID].border) groupBar.avatars[playerID].border.setAlpha(1)
        if (groupBar.avatars[playerID].sprite) groupBar.avatars[playerID].sprite.setAlpha(1)
        if (groupBar.names[playerID].sprite) groupBar.names[playerID].sprite.setAlpha(1)
        if (groupBar.bars[playerID].hp.outer) groupBar.bars[playerID].hp.outer.setAlpha(1)
        if (groupBar.bars[playerID].energy.outer) groupBar.bars[playerID].energy.outer.setAlpha(1)
        if (groupBar.bars[playerID].hp.inner) groupBar.bars[playerID].hp.inner.setAlpha(1)
        if (groupBar.bars[playerID].energy.inner) groupBar.bars[playerID].energy.inner.setAlpha(1)
      } else {
        if (groupBar.avatars[playerID].border) groupBar.avatars[playerID].border.setAlpha(0.4)
        if (groupBar.avatars[playerID].sprite) groupBar.avatars[playerID].sprite.setAlpha(0.4)
        if (groupBar.names[playerID].sprite) groupBar.names[playerID].sprite.setAlpha(0.4)
        if (groupBar.bars[playerID].hp.outer) groupBar.bars[playerID].hp.outer.setAlpha(0.4)
        if (groupBar.bars[playerID].energy.outer) groupBar.bars[playerID].energy.outer.setAlpha(0.4)
        if (groupBar.bars[playerID].hp.inner) groupBar.bars[playerID].hp.inner.setAlpha(0.4)
        if (groupBar.bars[playerID].energy.inner) groupBar.bars[playerID].energy.inner.setAlpha(0.4)
      }
    }
  }

  groupBar.groupState = data;
}

function fillBars(id, i) {
  let percentEnergy = (100 / (gameStore.groupState.state[id].mpw / gameStore.groupState.state[id].pw))
  fillBar(id, 'energy', percentEnergy, i)

  let percentHP = (100 / (gameStore.groupState.state[id].mhp / gameStore.groupState.state[id].hp))
  fillBar(id, 'hp', percentHP, i)
}

function fillBar(id, type, percent, i) {
  if (!groupBar.bars[id] || !groupBar.bars[id][type]) {
    return
  }

  if (groupBar.bars[id][type].inner) {
    groupBar.bars[id][type].inner.clear();
  } else {
    groupBar.bars[id][type].inner = statusBarWrapper.scene.add.graphics({
      x: groupBar.avatarConfig.pos.x + groupBar.avatarConfig.size + groupBar.barConfig[type].x_offset,
      y: (groupBar.avatarConfig.pos.y + groupBar.barConfig[type].y_offset) + (groupBar.avatarConfig.offset * i),
      add: true,
    });
  }

  groupBar.bars[id][type].inner.setDepth(1000)

  let width = groupBar.barConfig.width * (percent / 100)
  let color = 0x87ceeb;
  if (type === 'hp') color = percentHP(percent);
  if (type === 'energy') color = percentEnergy(percent);

  groupBar.bars[id][type].inner.fillStyle(color);
  groupBar.bars[id][type].inner.fillRect(2, 2, width - 4, groupBar.barConfig[type].height - 4);
}

function fullUpdateGroupPlayer(groupData) {
  RemoveGroupBar();

  for (let p of groupData) {
    addGroupPlayer(p.id)
  }

  UpdateGroupPlayer()
}

function parseData(groupData) {
  let d = [];
  for (let id in groupData) {

    if (Number(id) === Number(gameStore.playerID)) continue;

    d.push({
      id: id,
    })
  }

  d.sort(function (a, b) {
    return a - b;
  })

  return d
}

function getLogin(id) {
  for (let p of gameStore.groupState.members) {
    if (Number(p.id) === Number(id)) return p.login;
  }
}

export {initGroupBar, RemoveGroupBar, UpdateGroupPlayer}
