<template>
  <div class="MiniGame" id="MiniGame" ref="MiniGame" @mousedown="toUp">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'MiniGame'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"/>

    <template v-if="win">
      <div class="mask"></div>

      <div class="dialog_wrapper">
        <div class="text_wrapper">
          {{ texts['text_1'][language] }}
        </div>

        <input type="button"
               :value="texts['button_1'][language]"
               class="button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="endAction">
      </div>
    </template>

    <div class="wrapper">
      <app-pipes v-bind:win="win"/>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Pipes from './Pipes';

export default {
  name: "MiniGameWrapper",
  props: ['meta'],
  data() {
    return {
      win: false,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    endAction() {
      // отправляем данные о завершение экшона т.к. мини игры это чисто фронтовая херня то завершение воспринимаем на веру)
      // this.$props.meta { "act_number": 7, "mission_uuid": "4de01e49-934c-4e3f-bd08-e23a830f84c7" }

      if (this.$props.meta) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "global",
          data: {
            event: "EndMiniGame",
            uuid: this.$props.meta.mission_uuid,
            slot: this.$props.meta.act_number,
          }
        }));
      }

      this.$store.commit({
        type: 'toggleWindow',
        id: 'MiniGame',
        component: '',
        forceClose: true,
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('MiniGames')
    },
  },
  components: {
    AppControl: Control,
    AppPipes: Pipes,
  }
}
</script>

<style scoped>
.MiniGame {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  min-height: 20px;
  width: 222px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  height: 210px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.wrapper {
  width: 100%;
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: 100%;
  background: rgba(137, 150, 156, 0.3);
}

.mask {
  position: absolute;
  left: 1px;
  top: 20px;
  height: calc(100% - 21px);
  width: calc(100% - 2px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  z-index: 10;
}

.dialog_wrapper {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 48px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
}

.text_wrapper {
  height: 20px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
}

.button {
  display: block;
  float: right;
  width: 45%;
  margin: 3px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}
</style>
