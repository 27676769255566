// одновременно может быть только 1 диалог поэтому это можно сказать синглтон
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {Scene} from "../create";
import {MoveSprite} from "../utils/move_sprite";

let icon = null;

function DrawMapDialog(x, y) {
  if (!gameStore.gameReady) {
    return;
  }

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  x = pos.x;
  y = pos.y - 50;

  if (!icon) {
    icon = Scene.make.sprite({
      x: x,
      y: y,
      key: "map_dialog",
      add: true
    });
    icon.setOrigin(0.5);
    icon.setDepth(900);
    icon.setScale(0.35);
  }

  if (x === 0 && y === 0) {
    HideMapDialog()
    return
  }

  icon.setVisible(true)
  MoveSprite(icon, x, y, 1024, null, false, false);
}

function HideMapDialog() {
  if (icon) icon.setVisible(false)
}

export {DrawMapDialog, HideMapDialog}
