<template>
  <div id="subMenu" ref="subMenu" v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">

    <div class="wrapper_all">

      <div class="marketDialogItemIcon">
        <app-background-item-cell v-bind:slotItem="slotItem"/>
      </div>

      <div class="input_number_row">
        <div class="title_row">{{ texts['text_5'][language] }}</div>

        <div style="float: right; width: 50%">
          <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(-1, 1, 9999, 'count')">-1
          </div>
          <input type="number"
                 style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                 class="cursor_hover"
                 v-model="count" min="1">
          <div class="range_button cursor_hover" style="float: right; margin-right: 5px;"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(1, 1, 9999, 'count')">+1
          </div>
        </div>
      </div>

      <div class="input_number_row">
        <div class="title_row">{{ texts['text_6'][language] }}</div>

        <div style="float: right; width: 50%">
          <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(-0.1, 1, 9999, 'credits')">-.1
          </div>
          <input type="number"
                 style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                 class="cursor_hover"
                 v-model="credits" min="1" step="0.1">
          <div class="range_button cursor_hover" style="float: right; margin-right: 5px;"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(0.1, 1, 9999, 'credits')">+.1
          </div>
        </div>
      </div>

      <div style="width: 100%; float: left; margin-bottom: 3px; margin-top:5px; font-size: 1px;">
        <span style="margin-left: 14px; float: left; line-height: 20px;">{{ texts['text_7'][language] }}</span>
        <span class="total">{{ (count * credits).toFixed(2) }} cr.</span>
      </div>

      <input type="button" class="lobbyButton inventoryTip cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="back"
             :value="texts['button_4'][language]">
      <input type="button" class="lobbyButton inventoryTip cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="buy" style="float: right; margin-right: 10px"
             :value="texts['button_5'][language]">
    </div>
  </div>
</template>

<script>
import Control from "../Window/Control"
import BackgroundItemCell from '../Inventory/BackgroundItemCell'

export default {
  name: "NewBuy",
  props: ['slotItem', 'subMenuProps'],
  data() {
    return {
      count: 1,
      credits: 1,
      min: 1,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeQuantity(change, min, max, props) {
      this.playSound('button_press.mp3', 0.3)

      this[props] = Number(this[props])
      if (isNaN(this[props])) {
        this[props] = 0
      }

      this[props] += change

      if (this[props] > max) {
        this[props] = max
      }

      if (this[props] < min) {
        this[props] = min
      }

      if (change < 1) this[props] = Number(this[props].toFixed(2));
    },
    back() {
      this.playSound('button_press.mp3', 0.3)

      this.$parent.dialog.order = null;
    },
    buy() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: 'PlaceNewBuyOrder',
          id: Number(this.$props.slotItem.item.id),
          type: this.$props.slotItem.type,
          price: Number(this.credits) * 100,
          count: Number(this.count),
          expires: Number(14),
          min_buy_out: Number(this.min),
        }
      }));
      this.back()
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Market')
    },
  },
  components: {
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
#subMenu {
  position: absolute;
  left: calc(50% - 100px);
  top: 27px;
  width: 200px;
  height: 97px;
  display: block;
  background-size: 100% 2px;
  border-radius: 5px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
  padding: 3px 3px 5px;
}

.wrapper_all {
  height: 100%;
  width: calc(100% - 2px);
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
}

.wrapper_all input[type=range] {
  float: left;
  margin-top: 10px;
  width: 70px;
  margin-bottom: 5px;
  padding: 0;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#subMenu input[type=number]::-webkit-inner-spin-button, #sellDialog input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#subMenu input[type=number], #sellDialog input[type=number] {
  -moz-appearance: textfield;
}

.title_row {
  float: left;
  font-size: 10px;
  color: bisque;
  margin-right: 5px;
  line-height: 15px;
}

.input_number_row {
  float: left;
  width: calc(100% - 48px);
  margin-top: 7px;
}

.total {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  margin: 0;
  float: right;
  text-align: right;
  background-size: 5px 2px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  line-height: 15px;
  box-shadow: inset 0 0 1px 1px rgb(118 118 118);
  border: 0;
  font-size: 11px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}
</style>
