<template>
  <ul class="content_list">
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Мир' : 'World' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'planet')">
            {{ lang === 'RU' ? 'Планета' : 'Planet' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'time_of_day')">
            {{ lang === 'RU' ? 'Время с уток' : 'Time of day' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'synthet')">
            {{ lang === 'RU' ? 'Синтеты' : 'Synthet' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'base')">
            {{ lang === 'RU' ? 'База' : 'Base' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'sector')">
            {{ lang === 'RU' ? 'Сектор' : 'Sector' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'teleports')">
            {{ lang === 'RU' ? 'Телепорты' : 'Teleports' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'defenders')">
            {{ lang === 'RU' ? 'Защитники' : 'Defenders' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'npc')">
            {{ lang === 'RU' ? 'Боты' : 'Bots' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'structures')">
            {{ lang === 'RU' ? 'Временные структуры' : 'Structures' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'expedition')">
            {{ lang === 'RU' ? 'Экспедиции' : 'Expedition' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'law')">
            {{ lang === 'RU' ? 'Система правосудия' : 'Law' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'police')">
            {{ lang === 'RU' ? 'Полиция' : 'Police' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'relations')">
            {{ lang === 'RU' ? 'Система репутации' : 'Relations' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'skills')">
            {{ lang === 'RU' ? 'Навыки' : 'Skills' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'fraction_war')">
            {{ lang === 'RU' ? 'Фракционная война' : 'Fraction war' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Транспорт и снаряжение' : 'Transport and equipment' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'bodies')">
            {{ lang === 'RU' ? 'Корпуса' : 'Bodies' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'fuel')">
            {{ lang === 'RU' ? 'Топливо' : 'Fuel' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'sync')">
            {{ lang === 'RU' ? 'Синхронизация' : 'Synchronization' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'mods')">
            {{ lang === 'RU' ? 'Модификаторы корпуса' : 'Hull modifiers' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'damage')">
            {{ lang === 'RU' ? 'Урон и защита' : 'Damage and Defense' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'weapons')">
            {{ lang === 'RU' ? 'Оружие' : 'Weapons' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'gun_rotation_angles')">
            {{ lang === 'RU' ? 'Углы оружия' : 'Weapon angles' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'equips')">
            {{ lang === 'RU' ? 'Снаряжение' : 'Equips' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'permanent_damage')">
            {{ lang === 'RU' ? 'Невосполнимый урон' : 'Permanent damage' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Бой и управление' : 'Combat and controls' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'control')">
            {{ lang === 'RU' ? 'Управление' : 'Control' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'ballistic')">
            {{ lang === 'RU' ? 'Стрельба' : 'Ballistic' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'equip_panel')">
            {{ lang === 'RU' ? 'Панель снаряжения' : 'Equip panel' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'scouting')">
            {{ lang === 'RU' ? 'Разведка' : 'Scouting' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'auto_pilot')">
            {{ lang === 'RU' ? 'Автопилот' : 'Autopilot' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Добыча ресурсов' : 'Mining' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'mining')">
            {{ lang === 'RU' ? 'Добыча руды' : 'Ore mining' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'oil')">
            {{ lang === 'RU' ? 'Добыча нефти' : 'Oil production' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'organic')">
            {{ lang === 'RU' ? 'Добыча органики' : 'Organic mining' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'pellet')">
            {{ lang === 'RU' ? 'Добыча гранул' : 'Pellet mining' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'meteorites')">
            {{ lang === 'RU' ? 'Метеориты' : 'Meteorites' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'salvage')">
            {{ lang === 'RU' ? 'Сбор обломков' : 'Salvaging' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'anomaly')">
            {{ lang === 'RU' ? 'Поиск аномалий' : 'Search for anomalies' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Производство' : 'Production' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'processing')">
            {{ lang === 'RU' ? 'Центр переработки' : 'Recycling Center' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'parts_production')">
            {{ lang === 'RU' ? 'Детали' : 'Details' }}
          </div>
        </li>
        <!--        <li>-->
        <!--          <div class="content_button sub disable" @click="toPath('sell_base_resource')">Продажа сырья</div>-->
        <!--        </li>-->
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'blueprints')">
            {{ lang === 'RU' ? 'Чертежи' : 'Blueprints' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'craft_items')">
            {{ lang === 'RU' ? 'Создание предметов на базе' : 'Creating items based on' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Управление имуществом' : 'Property management' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'storage')">
            {{ lang === 'RU' ? 'Склад / Трюм' : 'Warehouse / Hold' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'market')">
            {{ lang === 'RU' ? 'Рынок' : 'Market' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'hangar')">
            {{ lang === 'RU' ? 'Ангар' : 'Hangar' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'boxes')">
            {{ lang === 'RU' ? 'Ящики' : 'Boxes' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'map_items')">
            {{ lang === 'RU' ? 'Предметы на земле' : 'Items on the ground' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'fraction_market')">
            {{ lang === 'RU' ? 'Фракционный магазин' : 'Faction store' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Фракции' : 'Fractions' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'replics')">
            {{ lang === 'RU' ? 'Replics' : 'Replics' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'explores')">
            {{ lang === 'RU' ? 'Explores' : 'Explores' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'reverses')">
            {{ lang === 'RU' ? 'Reverses' : 'Reverses' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'aop')">
            {{ lang === 'RU' ? 'APD' : 'APD' }}
          </div>
        </li>
      </ul>
    </li>
    <!--    <li>-->
    <!--      <div class="content_head">Навыки персонажа</div>-->
    <!--      <ul class="content_list" style="margin-top: 5px">-->
    <!--        <li>-->
    <!--          <div class="content_button sub" @click="toPath($event, 'fine')">Штрафы</div>-->
    <!--        </li>-->
    <!--        <li>-->
    <!--          <div class="content_button sub" @click="toPath($event, 'bonus')">Бонусы корпусов</div>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </li>-->
    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Социальность' : 'Sociality' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'squad')">
            {{ lang === 'RU' ? 'Отряды' : 'squad' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'chat')">
            {{ lang === 'RU' ? 'Чат' : 'Chat' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'friends')">
            {{ lang === 'RU' ? 'Контакты' : 'Friends' }}
          </div>
        </li>
      </ul>
    </li>

    <li>
      <div class="content_button cursor_hover" @click="toPath($event, 'clusters')">
        {{ lang === 'RU' ? 'Кластер (Клан/Корпорация)' : 'Cluster (Clan/Corporation)' }}
      </div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'cluster_deposit')">
            {{ lang === 'RU' ? 'Счета' : 'Deposits' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'cluster_office')">
            {{ lang === 'RU' ? 'Офис' : 'Office' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'corporation_build')">
            {{ lang === 'RU' ? 'Строительство' : 'Build' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'corporation_siege')">
            {{ lang === 'RU' ? 'Осада' : 'Siege' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'corporation_local_policy')">
            {{ lang === 'RU' ? 'Локальная политика' : 'Local policy' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'corporation_global_policy')">
            {{ lang === 'RU' ? 'Глобальная политика' : 'Global policy' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'corporation_wars')">
            {{ lang === 'RU' ? 'Война' : 'Wars' }}
          </div>
        </li>
      </ul>
    </li>

    <li>
      <div class="content_head">{{ lang === 'RU' ? 'Фракционная война' : 'Fractional War' }}</div>
      <ul class="content_list" style="margin-top: 5px">
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'militia')">
            {{ lang === 'RU' ? 'Ополчение' : 'Militia' }}
          </div>
        </li>
        <li>
          <div class="content_button sub cursor_hover" @click="toPath($event, 'capture_sector')">
            {{ lang === 'RU' ? 'Захват сектора' : 'Capture sector' }}
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="content_button cursor_hover" @click="toPath($event, 'missions')">
        {{ lang === 'RU' ? 'Задания' : 'Missions' }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ContentList",
  props: ['noRoute'],
  methods: {
    toPath(e, path) {
      this.$parent.tab = path;
      if (!this.$props.noRoute) {
        this.$router.push({path: `/wiki/${path}`});
      } else {
        let components = this.$router.getMatchedComponents(`/wiki/${path}`)
        this.$store.commit({
          type: 'setHelpSelect',
          data: {name: e.target.innerHTML, component: components[components.length - 1].name},
        });
      }
    },
    getUrlImg(imgName, format) {
      return require('../../../assets/indexImg/index_detail/' + imgName + '.' + format).default
    },
    getImagesCss(el, defaultCss) {
      return defaultCss
    },
  },
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.head {
  margin: 0 0 7px;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  margin-top: 10px;
}

.content_button, .content_head {
  text-indent: 5px;
  pointer-events: auto;
  text-align: left;
  transition: .1s;
  box-shadow: 0 0 2px #000;
  height: 15px;
  width: 200px;
  background: rgba(255, 129, 0, .75);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content_button.sub {
  background: rgba(243, 129, 11, 0.5);
}

.content_button:active {
  transform: scale(.98);
}

.content_button:hover {
  background: #ff8100;
}

.disable {
  pointer-events: none !important;
  background: grey !important;
}

.content_head {
  background: rgba(233, 178, 122, 0.75);
}
</style>
