var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Войны")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Когда у корпорации появляется\n      "),_c('router-link',{attrs:{"to":"corporation_build"}},[_vm._v("база в пустошах")]),_vm._v("\n      она может участвовать в войнах. То есть для того что бы участвовать в войнах нужна база.\n    ")],1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('war_1', 'png')+ ')',
          width: '375px',
          height: '142px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_c('p',[_vm._v("В войне участвует 2 корпорации, одна является нападающей стороной другая обороняющийся. На враждующие стороны\n      не\n      действуют правила правосудия даже в безопасных секторах.")]),_vm._v(" "),_c('p',[_vm._v("В игре транспорты врагов будут помечены особым значком.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('war_2', 'png')+ ')',
          width: '253px',
          height: '172px',
          marginLeft: 'calc(50% - 126px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Объявление войны")]),_vm._v(" "),_c('p',[_vm._v("Что бы объявить войну другому кластеру надо соблюсти несколько условий:")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Нападающая сторона должны будет платить взнос(100000.0cr) каждую неделю на подержание войны, иначе она\n      завершится. Однако если война \"взаимная\" то платить не придется.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Завершение войны")]),_vm._v(" "),_c('p',[_vm._v("Война завершается если:")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Взаимная война")]),_vm._v(" "),_c('p',[_vm._v("Если обороняющиеся стороны решит сделать войну \"взаимной\" то нападающей стороне не надо больше платить взнос,\n      однако и завершить конфликт по этой причине тоже не получится. Придется договариваться или воевать до самого\n      конца\n      }8)")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Статус-кво")]),_vm._v(" "),_c('p',[_vm._v("Если обе стороны согласились на статус-кво то конфликт завершается и 2 стороны не смогут начать новый в течение\n      14 дней.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Wars")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('war_1', 'png')+ ')',
          width: '375px',
          height: '142px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_c('p',[_vm._v("In a war, two corporations participate, one as the attacking side and the other as the defending side. The\n      rules of justice do not apply to the warring sides even in safe sectors.")]),_vm._v(" "),_c('p',[_vm._v("In the game, enemy transports will be marked with a special icon.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('war_2', 'png')+ ')',
          width: '253px',
          height: '172px',
          marginLeft: 'calc(50% - 126px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Declaring War")]),_vm._v(" "),_c('p',[_vm._v("To declare war on another cluster, several conditions must be met:")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('p',[_vm._v("The attacking side will have to pay a contribution (100,000.0cr) every week to maintain the war, otherwise, it\n      will end. However, if the war is \"mutual,\" no payment is required.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Ending the War")]),_vm._v(" "),_c('p',[_vm._v("The war ends if:")]),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Mutual War")]),_vm._v(" "),_c('p',[_vm._v("If the defending side decides to make the war \"mutual,\" the attacking side no longer needs to pay the\n      contribution, but it also won't be able to end the conflict for this reason. It will have to negotiate or fight\n      to the very end. }8)")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Status Quo")]),_vm._v(" "),_c('p',[_vm._v("If both sides agree to a status quo, the conflict ends and the two sides will not be able to start a new one\n      for 14 days.")])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("War... War never changes.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("У вашего кластера должны быть база в пустошах, которую вы можете назначить \"штабом\" (1 база - 1 война)")]),_vm._v(" "),_c('li',[_vm._v("У кластера который вы хотите атаковать тоже должны быть база в пустошах")]),_vm._v(" "),_c('li',[_vm._v("У вас есть на одном из депозитов 100000.0 кредитов")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Кластер нападающей или обороняющейся стороны распался.")]),_vm._v(" "),_c('li',[_vm._v("Война не является \"взаимной\" и нападающая сторона не смогла оплатить взнос.")]),_vm._v(" "),_c('li',[_vm._v("Кластер нападающей или обороняющейся стороны больше не имеет базы в пустошах.")]),_vm._v(" "),_c('li',[_vm._v("\"Штаб\" нападающей стороны уничтожен.")]),_vm._v(" "),_c('li',[_vm._v("Обе стороны согласились на статус-кво.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("War... War never changes.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("When a corporation has a "),_c('a',{attrs:{"href":"corporation_build"}},[_vm._v("base in the wastelands")]),_vm._v(", it can participate in wars.\n      That is, to participate in wars, a base is needed.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Your cluster must have a base in the wastelands, which you can designate as \"headquarters\" (1 base - 1\n        war).\n      ")]),_vm._v(" "),_c('li',[_vm._v("The cluster you want to attack must also have a base in the wastelands.")]),_vm._v(" "),_c('li',[_vm._v("You have 100,000.0 credits on one of the deposits.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("The cluster of the attacking or defending side has disbanded.")]),_vm._v(" "),_c('li',[_vm._v("The war is not \"mutual\" and the attacking side could not pay the contribution.")]),_vm._v(" "),_c('li',[_vm._v("The cluster of the attacking or defending side no longer has a base in the wastelands.")]),_vm._v(" "),_c('li',[_vm._v("The \"headquarters\" of the attacking side is destroyed.")]),_vm._v(" "),_c('li',[_vm._v("Both sides agreed to a status quo.")])])
}]

export { render, staticRenderFns }