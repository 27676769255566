import {Scene} from "../create";

const frameTime = 64

function ScaleInterpolation(sprite, ms, scale) {

  // x_current = x_source + (x_destination - x_source) * current_frame_no / total_frames_count
  /*
    x_current - текущее (искомое) значение координаты;
    x_source - начальное значение координаты; (откуда движемся);
    x_destination - конечное значение координаты; (куда движемся);
    current_frame_no - номер текущего кадра;
    total_frames_count - сколько всего кадров в анимации
  */

  if (sprite.interpolation) {
    sprite.interpolation.updater.remove();
    sprite.interpolation = null
    return
  }

  let setState = function (src, dst, currentFrame, totalFrames) {
    return src + (dst - src) * (currentFrame / totalFrames);
  };

  sprite.interpolation = {
    currentFrame: 1,
    totalFrames: Math.round(ms / frameTime),
    startScale: sprite.scale,
    dstScale: scale,
    updater: Scene.time.addEvent({
      delay: frameTime, loop: true, callback: function () {

        if (!sprite.interpolation) return;

        sprite.interpolation.currentFrame++;
        sprite.setScale(setState(sprite.interpolation.startScale, sprite.interpolation.dstScale, sprite.interpolation.currentFrame, sprite.interpolation.totalFrames));

        if (sprite.interpolation.totalFrames === sprite.interpolation.currentFrame) {
          sprite.interpolation.updater.remove();
          sprite.interpolation = null
        }
      },
    })
  }
}

export {ScaleInterpolation}
