<template>
  <div>
    <template v-if="lang === 'RU'">
      <h2 class="head" id="bodies">Невосполнимый урон</h2>

      <p>Четверть урона, который получает транспорт, уходит в категорию «невосполнимый урон». Это означает, что этот
        урон уже не может быть восстановлен. Чем больше транспорт получает урон, тем меньше становится его максимальное
        здоровье. Дебаф растёт вплоть до 66% от всего здоровья.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('permanent_damage_1', 'gif')+ ')',
              width: '193px',
              height: '73px',
              marginLeft: 'calc(50% - 96px)'
            }"/>
    </template>

    <template v-if="lang === 'EN'">
      <h2 class="head" id="bodies">Permanent Damage</h2>

      <p>A quarter of the damage received by the transport goes into the «permanent damage» category. This means that
        this damage cannot be restored. The more damage the transport receives, the less its maximum health becomes. The
        debuff increases up to 66% of the total health.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('permanent_damage_1', 'gif')+ ')',
              width: '193px',
              height: '73px',
              marginLeft: 'calc(50% - 96px)'
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "PermanentDamage",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
