<template>
  <div id="ExitMenu" ref="ExitMenu" @mousedown="toUp">
    <app-control
      v-bind:head="texts['window_name_1'][language]"
      v-bind:move="true"
      v-bind:close="true"
      v-bind:refWindow="'ExitMenu'"
      v-bind:noHeight="true"
      v-bind:noWidth="true"
      v-bind:noPos="true"/>

    <div class="error_message" :class="{error_message_visible: outBaseInventoryIsFull}">
      <div style="margin-top: 20%">{{ texts['error_1'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: outBaseNoBody}">
      <div style="margin-top: 20%">{{ texts['error_2'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: outBaseOverEnergy}">
      <div style="margin-top: 20%">Нехватает энергии</div>
    </div>

    <div class="error_message" :class="{error_message_visible: removeThoriumNoFreeSpaceInventory}">
      <div style="margin-top: 30%">{{ texts['text_13'][language] }}</div>
    </div>

    <app-repair-modal v-if="repairDialog.open"
                      v-bind:slotItem="{type: 'body', item: bodyState.body}"
                      v-bind:source="'body'"
                      v-bind:addStyle="{top: '-1px', left: '-1px', width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'}"/>

    <app-arm-modal v-if="armDialog.open"
                   v-bind:addStyle="{top: '-1px', left: '-1px', width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'}"/>

    <app-fill-up-modal v-if="fillDialog.open"
                       v-bind:addStyle="{top: '-1px', left: '-1px', width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'}"/>


    <div class="wrapper">
      <div class="section body_state">
        <div class="name_option">{{ texts['text_1'][language] }}</div>
        <div class="value" :style="{color: bodyState.color}">
          <span>{{ bodyState.title }}</span>
          <div class="inner_hp"
               :style="{width: bodyState.percentHP ? bodyState.percentHP + '%' : '0', background: bodyState.color}"/>
        </div>
        <div class="action" :class="{
                disableAll: !bodyState.body,
                disable: baseStatus.state.type === 'quest_base' || bodyState.percentHP === 100,
            }"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="openRepairDialog()">{{ texts['button_1'][language] }}
        </div>
      </div>

      <div class="section items">
        <div class="name_option">{{ texts['text_2'][language] }}</div>
        <div class="value">
          <template v-if="inventory && inventory.unit">
            <template v-for="slot in inventory.unit.weapon_slots">

              <app-item-cell
                class="valueItem"
                v-bind:fine="!slot.weapon"
                v-bind:textFine="''"
                v-bind:opacity="false"
                v-bind:itemSlot="{type: 'weapon', item: slot.weapon}"
                v-bind:equipProps="{type: 'weapon', data: slot}"
                v-bind:size="27"/>

              <app-item-cell
                class="valueItem"
                v-bind:fine="!(slot.ammo && slot.ammo_quantity > 0)"
                v-bind:itemSlot="{type: 'ammo', quantity: slot.ammo_quantity, item: slot.ammo}"
                v-bind:equipProps="{type: 'ammo', data: slot}"
                v-bind:size="27"/>

              +

              <div style="float: right">
                <app-item-cell
                  class="valueItem"
                  v-bind:fine="countAmmo === 0"
                  v-bind:itemSlot="{type: 'ammo', quantity: countAmmo, item: countAmmo === 0 ? null : slot.ammo}"
                  v-bind:equipProps="{type: 'ammo', data: slot}"
                  v-bind:size="27"/>
              </div>

            </template>
          </template>
        </div>
        <div class="action"
             :class="{disable: baseStatus.state.type === 'quest_base'}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="arm()">{{ texts['button_2'][language] }}
        </div>
      </div>

      <div class="section items thorium" v-if="baseStatus.state.type !== 'lobby'">
        <div class="name_option">{{ texts['text_3'][language] }}</div>
        <div class="value thorium">
          <template v-if="inventory && inventory.unit && inventory.unit.body">
            <app-item-cell v-for="slot in thoriumSlots"
                           :key="slot.number"
                           class="bodyThorium valueItem"
                           v-bind:equipProps="{type: 'thorium', data: slot}"
                           v-bind:showHP="true"
                           v-bind:downHP="true"
                           v-bind:itemSlot="{type: 'fuel', max_hp: slot.fuel.energy_cap, hp: slot.worked_out, item: slot.fuel}"
                           v-bind:size="27"/>
          </template>
        </div>
        <div class="action"
             :class="{disable: baseStatus.state.type === 'quest_base' || fullFuel}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="refuel()">
          {{ texts['button_3'][language] }}
        </div>
      </div>

      <!--      <div class="squad_ready" v-if="groupState && Number(groupState.uuid) !== 0 && baseStatus.state.type === 'lobby'">-->
      <!--        <div class="squad_ready_head">-->
      <!--          Отряд:-->
      <!--        </div>-->
      <!--        <div class="squad_ready_status">-->
      <!--          <template v-for="member in groupState.members">-->
      <!--            <div class="squad_ready_status_member">-->
      <!--              <img v-if="groupState.leaderID === Number(member.user_id) || groupState.state[member.user_id].rd"-->
      <!--                   src="https://img.icons8.com/color/48/000000/ok&#45;&#45;v1.png"/>-->
      <!--              <img v-else src="https://img.icons8.com/office/16/000000/hourglass.png"/>-->
      <!--            </div>-->
      <!--          </template>-->
      <!--        </div>-->

      <!--        <div class="action"-->
      <!--             @mouseover="playSound('select_sound.mp3', 0.3)"-->
      <!--             @click="openService('GroupMenu')">Отряд-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <template v-if="!waitGame.MyWait && baseStatus.state.type !== 'lobby'">
      <div class="exit_button_wrapper">
        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="closeWindow"
             class="outBase">{{ texts['button_4'][language] }}
        </div>

        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             style="float: right; margin-right: 5px"
             :class="{select: selectExitBase}"
             @click="outBase"
             class="outBase">{{ texts['button_5'][language] }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import ItemCell from '../Inventory/ItemCell';
import RepairModal from "../Inventory/RepairModal";
import ArmModal from "../Inventory/ArmModal";
import FillUpModal from "../Inventory/FillUpModal";

export default {
  name: "ExitMenu",
  data() {
    return {
      repairDialog: {
        open: false,
      },
      fillDialog: {
        open: false,
      },
      armDialog: {
        open: false,
      }
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    closeWindow() {
      this.playSound('window_close.mp3', 0.3)
      this.$store.commit({
        type: 'toggleWindow',
        id: 'ExitMenu',
        component: '',
        forceClose: true,
      });
    },
    outBase() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "OutBase",
        }
      }));
    },
    openRepairDialog() {
      this.repairDialog.open = true
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    refuel() {
      this.fillDialog.open = true
    },
    arm() {
      this.armDialog.open = true
    }
  },
  computed: {
    inventory() {
      return this.$store.getters.getInventory
    },
    countAmmo() {
      if (!(this.inventory && this.inventory.unit)) return 0;
      if (!this.hold || !this.hold.warehouse || !this.hold.warehouse.inventory) return 0;

      let count = 0
      for (let i in this.inventory.unit.weapon_slots) {
        if (this.inventory.unit.weapon_slots[i].ammo) {
          for (let j in this.hold.warehouse.inventory) {
            let slot = this.hold.warehouse.inventory[j];
            if (slot.type === 'ammo' && slot.item_id === this.inventory.unit.weapon_slots[i].ammo.id) {
              count += slot.quantity
            }
          }
        }
      }

      return count
    },
    hold() {
      for (let i in this.inventory.warehouses) {
        if (i === 'squadInventory') {
          return {id: i, warehouse: this.inventory.warehouses[i]}
        }
      }
    },
    bodyState: function () {
      let inventory = this.inventory;
      if (inventory.unit && inventory.unit.body) {

        let percentHP = Math.round(100 / (inventory.stateMS.max_hp / inventory.unit.hp));

        if (percentHP === 100) return {
          title: this.texts['body_state_1'][this.language],
          color: this.$store.getters.GetColorDamage(percentHP),
          percentHP: percentHP,
          body: inventory.unit.body,
        };
        if (percentHP < 100 && percentHP > 50) return {
          title: this.texts['body_state_2'][this.language],
          color: this.$store.getters.GetColorDamage(percentHP),
          percentHP: percentHP,
          body: inventory.unit.body,
        };
        return {
          title: this.texts['body_state_3'][this.language],
          color: this.$store.getters.GetColorDamage(percentHP),
          percentHP: percentHP,
          body: inventory.unit.body,
        };

      } else {
        return {
          title: this.texts['body_state_4'][this.language],
          color: "#ff0000",
        }
      }
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    waitGame() {
      return this.$store.getters.getWaitGame
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    groupState() {
      return this.$store.getters.getGroupSessionState;
    },
    thoriumSlots() {
      let slots = [];

      for (let i in this.inventory.unit.thorium_slots) {
        slots.push(this.inventory.unit.thorium_slots[i])
      }

      return slots.sort(function (a, b) {
        if (a.number_slot > b.number_slot) return 1;
        if (b.number_slot > a.number_slot) return -1;
        return 0;
      });
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Lobby')
    },
    outBaseInventoryIsFull() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('out_base_inventory_is_full') && errors['out_base_inventory_is_full'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'out_base_inventory_is_full',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    outBaseNoBody() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('out_base_no_body') && errors['out_base_no_body'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'out_base_no_body',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    outBaseOverEnergy() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('out_base_over_energy') && errors['out_base_over_energy'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'out_base_over_energy',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    removeThoriumNoFreeSpaceInventory() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('remove_thorium_no_free_space_inventory') && errors['remove_thorium_no_free_space_inventory'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'remove_thorium_no_free_space_inventory',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    fullFuel() {
      return false
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectExitBase() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "exit_base";
    },
  },
  components: {
    AppRepairModal: RepairModal,
    AppControl: Control,
    AppItemCell: ItemCell,
    AppArmModal: ArmModal,
    AppFillUpModal: FillUpModal,
  }
}
</script>

<style scoped>
#ExitMenu {
  position: absolute;
  border-radius: 5px;
  padding: 18px 3px 3px;
  width: 320px;
  left: calc(50% - 160px);
  top: 50px;
  min-height: 100px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.section {
  clear: both;
}

.outBase {
  display: block;
  width: 115px;
  margin: 4px 0 1px 2px;
  font-size: 13px;
  line-height: 22px;
  height: 20px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  text-align: center;
  float: left;
  pointer-events: auto;
  transition: 100ms;
  background-color: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.outBase.training {
  font-size: 9px;
  height: 12px;
  line-height: 12px;
  margin-top: 0;
}

.outBase:hover {
  background-color: rgba(255, 129, 0, 1);
}

.outBase:active {
  transform: scale(0.98);
}

.body_state {

}

.wrapper {
  width: calc(100% - 14px);
  background: rgba(137, 150, 156, 0.3);
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: calc(100% - 34px);
  padding: 7px;
  font-size: 12px;
  pointer-events: auto;
}

.name_option {
  text-align: left;
  display: block;
  width: 80px;
  padding-left: 10px;
  background: rgb(222, 101, 40);
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px 0 0 4px;
  margin: 4px 0 0 0;
  font-size: 14px;
  line-height: 22px;
  height: 20px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: left;
}

.value {
  background: rgba(108, 108, 108, 0.3);
  height: 20px;
  width: 119px;
  float: left;
  margin: 4px 0 0 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  text-shadow: 0 2px 2px #000000, 0 1px 2px #000000;
  font-weight: bold;
  line-height: 21px;
  position: relative;
  color: white;
  text-align: center;
}

.action {
  text-align: center;
  display: block;
  width: 79px;
  margin: 6px 0 0 6px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: left;
  font-size: 11px;
  pointer-events: auto;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
}

.action:hover {
  background: rgba(255, 129, 0, 1);
}

.action:active {
  transform: scale(0.98);
}

.valueItem {
  margin: 1px 3px;
}

.section.items > div {
  height: 35px;
  line-height: 35px;
}

.section.items .action {
  height: 32px;
}

.section.items .value.thorium {
  line-height: 10px;
  position: relative;
  z-index: 0;
}

.QuickTypeBattles {
  float: left;
  width: calc(100% - 8px);
  padding: 4px 4px 0 4px;
  position: relative;
}

.QuickTypeBattleWrapper {
  float: left;
  width: 151px;
  margin-right: 9px;
  position: relative;
}

.QuickTypeBattle {
  float: left;
  height: 98px;
  width: 152px;
  background: grey;
  border-radius: 10px;
  box-shadow: 0 0 2px black;
  background-size: cover;
  color: white;
  font-size: 11px;
  position: relative;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  opacity: 0.75;
  font-weight: bold;
}

.QuickTypeBattle span {
  position: absolute;
  width: 100%;
  left: 0;
  top: 25px;
}

.QuickTypeBattle:hover {
  opacity: 1;
}

.QuickTypeBattle.click:active {
  transform: scale(0.97);
}

.QuickTypeBattleWrapper.last {
  margin-right: 0;
}

.waitGame {
  float: left;
  background: #6c6c6c;
  box-shadow: 0 0 2px black;
  border-radius: 10px;
  color: white;
  line-height: 15px;
  font-size: 10px;
  position: absolute;
  top: 22px;
  right: 6px;
  height: 98px;
  width: 152px;
}

.action.waitBattle {
  margin: 3px calc(50% - 40px) 0;
  position: absolute;
  bottom: 5px;
}

.QuickActive {
  opacity: 1;
}

.SubTypeBattle {
  text-align: center;
  display: block;
  width: 152px;
  background: rgb(136, 68, 34);
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin: 4px 0 0 0;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: left;
  font-size: 10px;
}

.SubTypeBattle:hover {
  background: rgb(221, 112, 52);
}

.SubTypeBattle:active {
  transform: scale(0.98);
}

.sectionBattles {
  clear: both;
  float: left;
  margin-bottom: 5px;
}

.sectionBattles:last-child {
  margin-bottom: 0;
}

.sectionBattlesHead {
  height: 15px;
  font-size: 10px;
  font-weight: 900;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgba(255, 255, 255, 0.85);
  background: rgb(187, 93, 42);
  margin-bottom: 4px;
  box-shadow: 0 0 2px black;
}

.readySection {
  clear: both;
  width: 100%;
  float: left;
}

.SubTypeBattle.ready {
  width: 100%;
  height: 25px;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  margin-bottom: 4px;
}

.SubTypeBattle.ready.wait {
  background: rgb(221, 112, 52);
}

.reward_block {
  height: 35px;
  width: fit-content;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50%;
  bottom: 5px;
  border-radius: 5px;
  transform: translate(-50%, 0%);
  padding-bottom: 3px;
  box-shadow: inset 0 0 2px black;
}

.disableAll {
  display: none;
}

.squad_ready {
  float: left;
  clear: both;
}

.squad_ready_head {
  text-align: left;
  display: block;
  width: 80px;
  padding-left: 10px;
  background: rgb(222, 101, 40);
  box-shadow: -1px 1px 2px rgb(0 0 0);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  height: 20px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  float: left;
  margin: 4px 0 0 0;
}

.squad_ready_status {
  background: #6c6c6c;
  height: 20px;
  width: 119px;
  float: left;
  box-shadow: 1px 1px 2px rgb(0 0 0);
  text-shadow: 0 2px 2px #000000, 0 1px 2px #000000;
  font-weight: bold;
  line-height: 21px;
  margin: 4px 0 0 0;
}

.squad_ready_status_member {
  height: 14px;
  width: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  margin: 2px 0 0 2px;
  transition: 200ms;
  z-index: 1;
  float: left;
}

.squad_ready_status_member img {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.inner_hp {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  /*opacity: 0.3;*/
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  left: 0;
  top: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.select {
  box-shadow: 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
}

.exit_button_wrapper {
  height: 25px;
  float: left;
  width: 100%;
}
</style>

<style>
#ExitMenu .section.items .value.thorium .sep {
  font-size: 6px;
  line-height: 5px;
}

.reward_block .InventoryCell:active {
  transform: scale(0.97);
}

.value.thorium .InventoryCell {
  width: 33px !important;
  margin: 2px !important;
  margin-top: 3px !important;
  background-color: unset !important;
  backdrop-filter: blur(4px);
}

.value.thorium .InventoryCell:first-child {
  margin-left: 3px !important;
}

.value.thorium .InventoryCell:last-child {
  margin-right: 0 !important;
}
</style>
