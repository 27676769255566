<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Модификаторы</h2>
        <p>У каждого корпуса теперь есть три слота под установку мода, которые отличаются от экипировки:</p>
        <ul>
          <li>они не потребляют энергию;</li>
          <li>помимо бафа модуль даёт существенный дебаф (например: +25% к здоровью и -25% трюма);</li>
          <li>если снять установленный модуль, он сломается, а если упаковать машинку — сломаются все модули.</li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mods_1', 'png')+ ')',
            width: '400px',
            height: '270px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Расширение слотов</h2>
        <p>На базе можно арендовать фрезерный станок, что бы в корпусе проделать дырку под новое снаряжение 🙂</p>
        <ul>
          <li>можно установить только 1 слот;</li>
          <li>слот 1го уровня дает дебаф на 15% по хп, слот 2го на 25%;</li>
          <li>убрать слот можно только через упаковку транспорта (то есть сломаются все моды если такие есть);</li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mods_2', 'gif')+ ')',
            width: '400px',
            height: '285px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Modifiers</h2>
        <p>Each body now has three slots for installing mods, which differ from equipment:</p>
        <ul>
          <li>they do not consume energy;</li>
          <li>in addition to a buff, the module gives a significant debuff (for example: +25% to health and -25% to cargo hold);</li>
          <li>if you remove an installed module, it will break, and if you pack the machine, all modules will break.</li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mods_1', 'png')+ ')',
            width: '400px',
            height: '270px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Slot Expansion</h2>
        <p>At the base, you can rent a milling machine to make a hole in the body for new equipment 🙂</p>
        <ul>
          <li>only 1 slot can be installed;</li>
          <li>a level 1 slot gives a 15% debuff to HP, a level 2 slot gives a 25% debuff;</li>
          <li>the slot can only be removed by packing the vehicle (which means all mods will break if there are any).</li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mods_2', 'gif')+ ')',
            width: '400px',
            height: '285px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Mods",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
