import {gameStore} from "../store";
import {Scene} from "../create";
import {MapSize} from "../map/createMap";
import store from "../../store/store";

let geoPoint;
let radius = 24;
let response = [];
let geoDataDrawing = null;

//todo тут нечего не работает т.к. под движок фазер2
function AddGeoData() {
  if (gameStore.gameReady) {

    //создание окна с настройками
    if (document.getElementById("rotateBlock")) document.getElementById("rotateBlock").remove();
    let rotate = document.createElement("div");
    rotate.style.height = "50px";
    rotate.id = "rotateBlock";

    let geoRadius = createRange("geoRadius", 1, 300, 1, radius);
    geoRadius.oninput = function () {
      document.getElementById("geoInput").innerHTML = geoRadius.value;
      geoPoint.destroy();
      geoPoint = Scene.add.graphics(0, 0);
      geoPoint.fillStyle(0xFF0000, 0.3);
      geoPoint.fillCircleShape({
        x: Number(geoRadius.value),
        y: Number(geoRadius.value),
        radius: Number(geoRadius.value) * 2
      });
      radius = geoRadius.value;
    };
    let div = document.createElement("div");
    div.innerHTML = "<span> Радиус: </span> <span id='geoInput'> 12 </span>";

    let apply = document.createElement("input");
    apply.value = "Применить";
    apply.type = "submit";
    apply.onclick = function () {
      for (let i = 0; i < response.length; i++) {
        store.dispatch("sendSocketData", response[i]);
      }
    };

    rotate.appendChild(geoRadius);
    rotate.appendChild(div);
    rotate.appendChild(apply);
    document.getElementById("coordinates").appendChild(rotate);

    Scene.input.removeAllListeners()
    Scene.input.on('pointerdown', function (pointer) {

      if (!pointer.leftButtonDown()) {
        return
      }

      let x = Math.round(Scene.game.input.mousePointer.worldX)
      let y = Math.round(Scene.game.input.mousePointer.worldY)
      addGeoData(x, y)
    });

    geoPoint = Scene.add.graphics(0, 0);
    geoPoint.fillStyle(0xFF0000, 0.3);
    geoPoint.fillCircleShape({x: radius, y: radius, radius: radius * 2});

    Scene.input.on('pointermove', function (pointer) {
      let x = Math.round(Scene.game.input.mousePointer.worldX)
      let y = Math.round(Scene.game.input.mousePointer.worldY)

      geoPoint.x = x - radius;
      geoPoint.y = y - radius;
    });

    if (geoDataDrawing == null) {

      let geoDataCanvas = Scene.add.graphics(0, 0);
      geoDataCanvas.fillStyle(0xFF0000, 0.3);

      geoDataDrawing = setInterval(function () {
        geoDataCanvas.clear();
        console.log(gameStore.map)
        for (let i in gameStore.map.geo_data) {
          let point = gameStore.map.geo_data[i]
          geoDataCanvas.fillCircleShape({x: point.x + MapSize, y: point.y + MapSize, radius: point.radius});
        }

        for (let i in gameStore.mapsState[gameStore.map.id].staticObjects) {
          let obj = gameStore.mapsState[gameStore.map.id].staticObjects[i];
          if (obj && obj.geo_data && obj.geo_data.length > 0) {
            for (let j in obj.geo_data) {
              let point = obj.geo_data[j];
              geoDataCanvas.fillCircleShape({x: point.x + MapSize, y: point.y + MapSize, radius: point.radius});
            }
          }
        }
      }, 1000)
    }
  }
}

// function CancelGeoData() {
//   if (game) {
//     game.input.onDown.remove(addGeoData, game);
//     game.input.moveCallbacks = [];
//     if (document.getElementById("rotateBlock")) document.getElementById("rotateBlock").remove();
//     if (geoPoint) geoPoint.destroy();
//     response = [];
//     game.icon.forEach(function (c) {
//       c.kill();
//     });
//   }
// }

function addGeoData(x, y) {
  response.push(JSON.stringify({
    event: "addGeoData",
    id: Number(gameStore.mapEditorState.mpID),
    x: x - MapSize,
    y: y - MapSize,
    radius: Number(document.getElementById("geoRadius").value) * 2,
  }));

  gameStore.map.geo_data.push({
    x: x - MapSize,
    y: y - MapSize,
    radius: Number(document.getElementById("geoRadius").value) * 2
  })
}

function createRange(id, min, max, step, startValue) {

  let range = document.createElement("input");
  range.id = id;
  range.type = "range";
  range.min = min;
  range.max = max;
  range.step = step;
  range.value = startValue;

  return range
}

export {AddGeoData}
