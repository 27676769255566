import {statusBarWrapper} from "./status_bar";
import {gameStore} from "../store";
import {CreateSpawn} from "../map_editor/spawn";

let battleState = {
  background: null,
  width: 600,
  height: 50,
  alpha: 0.4,
  bases: {
    height: 16,
    width: 50,
    current: {},
    init: false,
    1: {
      top: 42,
      centerOffset: 0,
    },
    2: {
      top: 42,
      centerOffset: 95,
    },
    3: {
      top: 42,
      centerOffset: -95,
    },
  },
  fractionPointsBar: {
    height: 25,
    width: 250,
    back: null,
    inner: null,
    logos: {},
  },
  ReplicsColor: 0xFF5153,
  ReversesColor: 0x4699FF,
  ExploresColor: 0x57d651,
  barAlpha: 0.8,
  warState: {x: 0, y: 0, logos: {}},
  ownerLogo: {x: 0, y: 0, logos: {}},
}

function battleStateReset(clearData) {
  if (battleState.background) {
    battleState.background.destroy();
    battleState.background = null;
  }

  for (let i in battleState.bases.current) {
    let renderBase = battleState.bases.current[i];
    renderBase.inner.destroy();
    renderBase.header.destroy()
  }
  battleState.bases.current = {}
  battleState.bases.init = false;

  if (battleState.fractionPointsBar.back) {
    battleState.fractionPointsBar.back.destroy();
  }

  if (battleState.fractionPointsBar.inner) {
    battleState.fractionPointsBar.inner.destroy();
    battleState.fractionPointsBar.inner = null;
  }

  for (let i in battleState.fractionPointsBar.logos) {
    battleState.fractionPointsBar.logos[i].setVisible(false)
  }

  for (let i in battleState.ownerLogo.logos) {
    battleState.ownerLogo.logos[i].setVisible(false)
  }

  for (let i in battleState.warState.logos) {
    battleState.warState.logos[i].setVisible(false)
  }

  if (clearData) {
    gameStore.sectorWarState = {
      maxPoints: 0,
      fractionPoints: {},
      bases: [],
    };
  }
}

function updateBattleData() {
  if (!statusBarWrapper.scene || !gameStore.gameReady || !gameStore.unitReady) return;

  if (!battleState.background) {
    createBackground();
  }

  if (gameStore.radarWork && gameStore.sectorWarState.bases && gameStore.sectorWarState.bases.length > 0 && !battleState.bases.init) {
    battleState.bases.init = true
    for (let i in gameStore.sectorWarState.bases) {
      CreateSpawn(gameStore.sectorWarState.bases[i], "battle");
    }
  }

  fillOwnerAndPhase()
  fillBaseCapture();
  fillFractionPoints();
}

function fillOwnerAndPhase() {
  let fraction = gameStore.sectorWarState.owner

  if (!battleState.ownerLogo.logos[fraction]) {
    battleState.ownerLogo.logos[fraction] = statusBarWrapper.scene.make.image({
      x: battleState.background.x + battleState.ownerLogo.x + 1,
      y: battleState.ownerLogo.y + 1,
      key: fraction + '_mini',
      add: true,
    });
    battleState.ownerLogo.logos[fraction].setOrigin(0);
    battleState.ownerLogo.logos[fraction].setDisplaySize(battleState.fractionPointsBar.height - 2, battleState.fractionPointsBar.height - 2);
    battleState.ownerLogo.logos[fraction].setDepth(2000);
  }

  for (let i in battleState.ownerLogo.logos) {
    if (i === fraction) {
      battleState.ownerLogo.logos[i].setVisible(true)
    } else {
      battleState.ownerLogo.logos[i].setVisible(false)
    }
  }

  if (!battleState.warState.logos["battle"]) {
    for (let t of ["battle", "defend"]) {
      battleState.warState.logos[t] = statusBarWrapper.scene.make.image({
        x: battleState.background.x + battleState.warState.x + 1,
        y: battleState.warState.y + 1,
        key: t + '_icon',
        add: true,
      });
      battleState.warState.logos[t].setOrigin(0);
      battleState.warState.logos[t].setDisplaySize(battleState.fractionPointsBar.height - 2, battleState.fractionPointsBar.height - 2);
      battleState.warState.logos[t].setDepth(2000);
    }
  }

  if (gameStore.sectorWarState.phase) {
    battleState.warState.logos["battle"].setVisible(true)
    battleState.warState.logos["defend"].setVisible(false)
  } else {
    battleState.warState.logos["battle"].setVisible(false)
    battleState.warState.logos["defend"].setVisible(true)
  }
}

function fillFractionPoints() {

  if (battleState.fractionPointsBar.inner) {
    battleState.fractionPointsBar.inner.clear();
  } else {
    battleState.fractionPointsBar.inner = statusBarWrapper.scene.add.graphics({
      x: battleState.background.x + battleState.fractionPointsBar.x - 1,
      y: battleState.fractionPointsBar.y - 1,
      add: true,
    });
    battleState.fractionPointsBar.inner.setDepth(1999)
  }

  for (let i in battleState.fractionPointsBar.logos) {
    battleState.fractionPointsBar.logos[i].setVisible(false)
  }

  let startWidth = 0
  for (let i in gameStore.sectorWarState.fractionPoints) {
    let fraction = gameStore.sectorWarState.fractionPoints[i].fraction;
    let points = gameStore.sectorWarState.fractionPoints[i].points;
    let color = battleState[fraction + "Color"];

    let width = (battleState.fractionPointsBar.width - 2) * (points / gameStore.sectorWarState.maxPoints);

    if (!battleState.fractionPointsBar.logos[fraction]) {
      battleState.fractionPointsBar.logos[fraction] = statusBarWrapper.scene.make.image({
        x: battleState.background.x + battleState.fractionPointsBar.x - 1 + width + startWidth,
        y: battleState.fractionPointsBar.y - 1 + (battleState.fractionPointsBar.height / 2),
        key: fraction + '_mini',
        add: true,
      });
      battleState.fractionPointsBar.logos[fraction].setOrigin(0.5);
      battleState.fractionPointsBar.logos[fraction].setScale(0.6);
      battleState.fractionPointsBar.logos[fraction].setDepth(2000);
    } else {
      battleState.fractionPointsBar.logos[fraction].setPosition(
        battleState.background.x + battleState.fractionPointsBar.x - 1 + (width / 2) + startWidth,
        battleState.fractionPointsBar.y - 1 + (battleState.fractionPointsBar.height / 2),
      )
      battleState.fractionPointsBar.logos[fraction].setVisible(true)
    }

    battleState.fractionPointsBar.inner.fillStyle(color, battleState.barAlpha);
    battleState.fractionPointsBar.inner.fillRect(2 + startWidth, 2, width, battleState.fractionPointsBar.height - 2);
    startWidth += width
  }
}

function fillBaseCapture() {
  if (gameStore.sectorWarState.bases && gameStore.sectorWarState.bases.length > 0) {
    for (let i in gameStore.sectorWarState.bases) {
      for (let j in battleState.bases.current) {
        let base = gameStore.sectorWarState.bases[i];
        let renderBase = battleState.bases.current[j];

        if (renderBase.base.id === base.id) {

          let width = (battleState.bases.width - 2) * (base.capture / 100)
          if (renderBase.width === width) {
            continue
          }
          renderBase.width = width

          let color = battleState[base.capture_team + "Color"]
          if (renderBase.inner) {
            renderBase.inner.clear();
          } else {
            renderBase.inner = statusBarWrapper.scene.add.graphics({
              x: battleState.background.x + renderBase.x - 1, y: battleState.background.y + renderBase.y - 1, add: true,
            });
            renderBase.inner.setDepth(1999)
          }

          if (base.capture_fact) {
            renderBase.inner.lineStyle(1, color); // 0xff6600
            renderBase.inner.strokeRect(1, 0, battleState.bases.width + 1, battleState.bases.height + 1);
          }

          renderBase.inner.fillStyle(color, battleState.barAlpha);
          renderBase.inner.fillRect(2, 2, width, battleState.bases.height - 2);
        }
      }
    }
  }
}

function createBackground() {

  battleState.height = 65
  battleState.background = statusBarWrapper.scene.add.renderTexture((statusBarWrapper.scene.cameras.main.displayWidth / 2) - (battleState.width / 2), 0, battleState.width, battleState.height);
  battleState.background.setOrigin(0);

  // общий бек
  let backGraphics = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, 0, battleState.alpha, 0, battleState.alpha);
  backGraphics.fillRect(0, 0, battleState.width / 3, battleState.height);

  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, battleState.alpha, battleState.alpha, battleState.alpha, battleState.alpha);
  backGraphics.fillRect(battleState.width / 3, 0, battleState.width / 3, battleState.height);

  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, battleState.alpha, 0, battleState.alpha, 0);
  backGraphics.fillRect((battleState.width / 3) * 2, 0, battleState.width / 3, battleState.height);

  battleState.background.draw(backGraphics);
  backGraphics.destroy();

  battleState.fractionPointsBar.x = (battleState.width / 2) - (battleState.fractionPointsBar.width / 2);
  battleState.fractionPointsBar.y = 8;

  let leftBar = createBar(battleState.fractionPointsBar.x, battleState.fractionPointsBar.y, battleState.fractionPointsBar.width, battleState.fractionPointsBar.height)
  battleState.background.draw(leftBar);
  leftBar.destroy();

  if (gameStore.sectorWarState.bases && gameStore.sectorWarState.bases.length > 0) {
    let basesBacks = [];
    if (gameStore.sectorWarState.bases.length === 1) basesBacks.push(1)
    if (gameStore.sectorWarState.bases.length === 2) basesBacks.push(2, 3)
    if (gameStore.sectorWarState.bases.length === 3) basesBacks.push(1, 2, 3)

    for (let i in basesBacks) {
      let base = gameStore.sectorWarState.bases[i];
      let config = battleState.bases[basesBacks[i]];

      let barX = (battleState.width / 2 + config.centerOffset) - battleState.bases.width / 2;
      let bar = createBar(barX, config.top, battleState.bases.width, battleState.bases.height)

      battleState.background.draw(bar);
      bar.destroy();

      let header = statusBarWrapper.scene.add.bitmapText(0, battleState.background.y + config.top + 2, 'bit_text_12', base.name, 12);
      header.setOrigin(0.5, 0)
      header.x = battleState.background.x + ((battleState.width / 2) + config.centerOffset)
      header.setDepth(2000)

      battleState.bases.current[i] = {
        base: base, x: barX, y: config.top, header: header,
      };
    }
  }

  battleState.warState.x = (battleState.width / 2) - (battleState.fractionPointsBar.width / 2) - 5 - battleState.fractionPointsBar.height;
  battleState.warState.y = 8
  let warStateBack = createBar(battleState.warState.x, battleState.warState.y, battleState.fractionPointsBar.height, battleState.fractionPointsBar.height);
  battleState.background.draw(warStateBack);
  warStateBack.destroy();

  battleState.ownerLogo.x = (battleState.width / 2) + (battleState.fractionPointsBar.width / 2) + 5;
  battleState.ownerLogo.y = 8
  let ownerBack = createBar(battleState.ownerLogo.x, battleState.ownerLogo.y, battleState.fractionPointsBar.height, battleState.fractionPointsBar.height);
  battleState.background.draw(ownerBack);
  ownerBack.destroy();
}

function createBar(x, y, width, height) {
  let newBar = statusBarWrapper.scene.add.graphics({
    x: x, y: y, add: true,
  });

  newBar.fillStyle(statusBarWrapper.bars.outerBorderColor, 0.5);
  newBar.fillRect(0, 0, width, height);
  newBar.fillStyle(0xdddddd, 0.3);
  newBar.fillRect(1, 1, width - 2, height - 2);

  return newBar
}

export {updateBattleData, battleStateReset, createBar}
