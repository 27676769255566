import {gameStore} from "../store";
import {createJammer} from "./structures/jammer";
import {createShield} from "./structures/shield";
import {createRadar} from "./structures/radar";
import {CreateSpriteTurret} from "./structures/turret"
import {createExtractor} from "./structures/extractor";
import {NoCompleteStructure} from "./structures/no_complete_structure"
import store from "../../store/store";
import {GetGlobalPos} from "./gep_global_pos";
import {CreateRepairStation} from "./structures/repair_station";
import {createGeyser} from "./structures/geyser";
import {GetMapLvl} from "./height_offset";
import {UnitHeight} from "../unit/unit";
import {Scene} from "../create";
import {ReversesSciFi3Broken, SectorStructure_3_28} from "./structures/animate_structures";
import {createCorporationBase} from "./structures/corporation_base";
import {createCorporationOffice} from "./structures/corporation_office";
import {createCorporationProcessing} from "./structures/corporation_processing";

function CreateObject(coordinate, x, y, noBmd, scene) {

  if (coordinate.type === "mission" || coordinate.type === 'sector_structure_3' ||
    coordinate.texture === "parks_and_recreation_1" || coordinate.texture === "parks_and_recreation_2"
    || coordinate.texture === "teleport_in" || coordinate.texture === "tunel_out") {
    noBmd = true
  }

  // if (coordinate.texture === "ravine_2_8" || coordinate.texture === "ravine_1_8") {
  //   return
  // }

  let structures = ['unknown_civilization_jammer', 'repair_station', 'turret', 'beacon', 'zone_repair_station',
    'storage', 'shield', 'generator', 'missile_defense', 'turret', 'turret', 'jammer', 'radar', '' +
    'meteorite_defense', 'extractor', 'explores_antenna', 'expensive_tower', 'rope_trap', 'gravity_generator', 'wall',
    'base', 'office', 'prefabricated', 'processing', 'market'];
  let plants = ['plant_21']

  let atlasName = coordinate.type;
  if (structures.includes(coordinate.type)) {
    atlasName = 'structures';
  }

  if (plants.includes(coordinate.texture)) {
    atlasName = 'plants';
  }

  coordinate.objectSprite = gameObjectCreate(x, y, coordinate.texture, coordinate.scale,
    coordinate.shadow && coordinate.shadow_intensity > 0, coordinate.rotate,
    coordinate.x_shadow_offset, coordinate.y_shadow_offset, coordinate.shadow_intensity,
    noBmd || coordinate.build || coordinate.interactive, atlasName, scene, gameStore.map.id, coordinate);

  if (coordinate.objectSprite) {
    coordinate.height = coordinate.height + GetMapLvl(x, y, gameStore.map.id)
    coordinate.objectSprite.setDepth(coordinate.height);
    if (coordinate.objectSprite.shadow) {
      coordinate.objectSprite.shadow.setDepth(coordinate.height - 1);
    }
  }

  // todo костыль
  if ((coordinate.texture === "bones_3" || coordinate.texture === "bones_4" ||
      coordinate.texture === "bones_5" || coordinate.texture === "unknown_civilization_8" ||
      coordinate.texture === "scrap_6" || coordinate.texture === "scrap_7" || coordinate.texture === "scrap_8" ||
      coordinate.texture === "parks_and_recreation_1" || coordinate.texture === "parks_and_recreation_2" ||
      coordinate.type === "unit_wreckage" || coordinate.type === "structure_wreckage" ||
      coordinate.texture === "corporation_base_1_destroy") &&
    coordinate.objectSprite) {

    coordinate.objectSprite.setDepth(4);
    if (coordinate.objectSprite.shadow) coordinate.objectSprite.shadow.setDepth(3);
  }

  if (!coordinate.texture.includes('build') && coordinate.type !== 'structure_wreckage' && !coordinate.texture.includes('destroy')) {
    if (coordinate.texture.includes('corporation_base')) {
      createCorporationBase(coordinate, coordinate.objectSprite, scene)
    }

    if (coordinate.texture.includes('corporation_office')) {
      createCorporationOffice(coordinate, coordinate.objectSprite, scene)
    }

    if (coordinate.texture.includes('corporation_processing')) {
      createCorporationProcessing(coordinate, coordinate.objectSprite, scene)
    }
  }

  if (coordinate.texture.includes('geyser')) {
    coordinate.objectSprite.setDepth(2);
    createGeyser(x, y, coordinate.height, scene, gameStore.map.id, coordinate.objectSprite)
  }

  if (coordinate.texture === "sector_structure_3_28") {
    SectorStructure_3_28(x, y, coordinate.height, scene, gameStore.map.id, coordinate.objectSprite)
  }

  if (coordinate.texture === "reverses_sci_fi_3_broken") {
    ReversesSciFi3Broken(x, y, coordinate.height, scene, gameStore.map.id, coordinate.objectSprite)
  }

  ObjectEvents(coordinate, coordinate.objectSprite, x, y);

  if (coordinate.type === "turret") {
    CreateSpriteTurret(coordinate, coordinate.objectSprite, scene)
  }

  if (coordinate.type === "repair_station") {
    CreateRepairStation(coordinate, coordinate.objectSprite, scene)
  }

  if (coordinate.type === "shield") {
    createShield(x, y, coordinate.texture, coordinate.scale, coordinate.shadow, coordinate.rotate,
      coordinate.x_shadow_offset, coordinate.y_shadow_offset, coordinate.shadow_intensity, coordinate.objectSprite, coordinate.height, scene, gameStore.map.id)
  }

  if (coordinate.type === "jammer") {
    createJammer(x, y, coordinate.texture, coordinate.scale, coordinate.shadow, coordinate.rotate,
      coordinate.x_shadow_offset, coordinate.y_shadow_offset, coordinate.shadow_intensity, coordinate.objectSprite, coordinate.height, scene, gameStore.map.id)
  }

  if (coordinate.type === "radar" || coordinate.type === "expensive_tower") {
    createRadar(x, y, coordinate.texture, coordinate.scale, coordinate.shadow, coordinate.rotate,
      coordinate.x_shadow_offset, coordinate.y_shadow_offset, coordinate.shadow_intensity, coordinate.objectSprite, coordinate.height, scene, gameStore.map.id)
  }

  if (coordinate.type === "extractor") {
    createExtractor(x, y, coordinate.texture, coordinate.scale, coordinate.shadow, coordinate.rotate,
      coordinate.x_shadow_offset, coordinate.y_shadow_offset, coordinate.shadow_intensity, coordinate.objectSprite, coordinate.height, scene, gameStore.map.id)
  }

  Scene.time.addEvent({
    delay: 150,
    callback: function () {
      NoCompleteStructure(coordinate);
    },
    loop: false,
  });

  return coordinate
}

function gameObjectCreate(x, y, texture, scale, needShadow, rotate, xShadowOffset, yShadowOffset, shadowIntensity,
                          noBmd, atlasName, scene, mapID, coordinate) {
  let shadow;

  // noBmd = true;
  // todo костыль, надо передалть спрайт раздели его на верхнюю и нижнюю часть
  if (texture === "scrap_6" || texture === "scrap_7" || texture === "scrap_8") {
    noBmd = true;
  }

  let pos = GetGlobalPos(x, y, mapID);
  if (needShadow) {
    shadow = scene.make.image({
      x: pos.x + xShadowOffset, y: pos.y + yShadowOffset, frame: texture, add: true, key: atlasName,
    });

    shadow.setOrigin(0.5);
    shadow.setScale(scale / 100);
    shadow.setAngle(rotate);
    shadow.setAlpha(shadowIntensity / 100);
    shadow.setTint(0x000000);
  }

  let object = scene.make.image({
    x: pos.x, y: pos.y, frame: texture, add: true, key: atlasName,
  });
  object.setOrigin(0.5);
  object.setScale(scale / 100);
  object.setAngle(rotate);

  object.shadow = shadow;

  if (gameStore.mapEditorState.mpID === 0) {
    if ((!noBmd && !texture.includes('geyser') && !texture.includes('plant_4') && coordinate.static && atlasName !== 'structures')) {

      let bmd = gameStore.mapsState[mapID].bmdTerrain.bmd
      if (coordinate.height > UnitHeight && !texture.includes('bones')) {
        bmd = gameStore.mapsState[mapID].bmdTerrain.bmdObject
      }

      if (object.shadow) {
        object.shadow.setPosition(x + xShadowOffset, y + yShadowOffset);
        bmd.draw(object.shadow);
        object.shadow.destroy();
      }

      object.setPosition(x, y);
      bmd.draw(object);
      object.destroy();
      object = null;

      return
    } else {
      if (texture.includes('plant_4') || coordinate.build) {
        // addIgnoreObject(object)
        // if (object.shadow) addIgnoreObject(object.shadow);
      }
    }
  }

  return object
}

function CreateAnimate(coordinate, x, y, scene) {

  coordinate.objectSprite = gameAnimateObjectCreate(x, y, coordinate.texture, coordinate.scale, coordinate.shadow,
    coordinate.rotate, coordinate.animation_speed, coordinate.animate_loop, scene, gameStore.map.id);

  ObjectEvents(coordinate, coordinate.objectSprite, x, y);

  if (coordinate.objectSprite) {
    coordinate.objectSprite.setDepth(coordinate.height);
    if (coordinate.objectSprite.shadow) coordinate.objectSprite.shadow.setDepth(coordinate.height - 1);
  }
}

function gameAnimateObjectCreate(x, y, texture, scale, needShadow, rotate, speed, needAnimate, scene, mapID) {
  let shadow;

  let pos = GetGlobalPos(x, y, mapID);

  scene.anims.create({
    key: texture,
    frames: scene.anims.generateFrameNumbers(texture),
    frameRate: speed,
    repeat: -1
  });

  if (needShadow) {

    shadow = scene.make.sprite({
      x: pos.x + scene.shadowXOffset,
      y: pos.y + scene.shadowYOffset,
      key: texture,
      add: true
    });

    shadow.setOrigin(0.5);
    shadow.setScale(scale / 100);
    shadow.setAngle(rotate);
    //shadow.setAlpha(shadowIntensity / 100);
    shadow.setTint(0x000000);

    if (needAnimate) {
      shadow.anims.play(texture);
    }
  }

  let object = scene.make.sprite({
    x: pos.x,
    y: pos.y,
    key: texture,
    add: true
  });
  object.setOrigin(0.5);
  object.setScale(scale / 100);
  object.setAngle(rotate);

  if (needAnimate) {
    object.anims.play(texture);
  }

  object.shadow = shadow;

  return object
}

function ObjectEvents(coordinate, object, x, y) {

  if (!object) return;
  let postFxPlugin = Scene.plugins.get('rexoutlinepipelineplugin');

  if (coordinate.interactive) {

    let config = {pixelPerfect: false}
    if (coordinate.texture === 'bunker_entrance_2' || coordinate.texture === 'radio_tower' ||
      coordinate.texture === 'supercomputer' || coordinate.texture === 'random_buildings_3_2') {

      config.pixelPerfect = true
    }

    object.setInteractive(config);

    object.on('pointerover', function () {
      if (gameStore.equipPanel.selectApplicable === 'empty')
        document.body.classList.add("cursor_box");

      postFxPlugin.add(object, {
        thickness: 1,
        outlineColor: 0xffffff
      });
    });

    object.on('pointerout', function () {
      if (gameStore.equipPanel.selectApplicable === 'empty')
        document.body.classList.remove("cursor_box");

      postFxPlugin.remove(object);
    });

    object.on('pointerdown', function (pointer) {
      if (!gameStore.mapEditor && pointer.leftButtonDown() && gameStore.unitReady && (!gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface)) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "global",
          data: {
            event: "OpenObject",
            id: coordinate.id,
          }
        }));
      }
    });
  }
}

export {CreateObject, CreateAnimate, gameObjectCreate, gameAnimateObjectCreate}
