<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Движение</h2>

      <p>Управление в Veliri стандартизированное по меркам современных игр и осуществляется посредством клавиш - <span
        class="importantly">WASD</span> (движение в различные направления), <span class="importantly">Space</span>
        (остановка/тормоз) и <span class="importantly">Shift</span> (ускорение/форсаж). </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_4', 'gif')+ ')',
            width: '279px',
            height: '155px',
            marginLeft: 'calc(50% - 140px)',
            }"/>


      <h3 class="head">Особенности шасси:</h3>
      <p><span class="importantly">Гусеницы</span>: это шасси, которое дает вам большую маневренность и устойчивость.
        Оно
        позволяет вам крутиться на месте
        и легко поворачивать в любом направлении.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_1', 'gif')+ ')',
            width: '321px',
            height: '170px',
            marginLeft: 'calc(50% - 160px)',
            }"/>

      <p><span class="importantly">Антиграв</span>: это шасси, которое дает вам большую свободу и гибкость. Оно
        позволяет
        вам управлять направлением корпуса мышкой и двигаться боком и назад без поворота корпуса.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_2', 'gif')+ ')',
            width: '321px',
            height: '240px',
            marginLeft: 'calc(50% - 160px)',
            }"/>

      <p><span class="importantly">Колеса</span>: это шасси, которое не имеет особых особенностей. Оно является самым
        простым и надежным типом шасси. Оно
        подходит для тех, кто предпочитает классический стиль управления.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_3', 'gif')+ ')',
            width: '321px',
            height: '174px',
            marginLeft: 'calc(50% - 160px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">

      <h2 class="head">Movement</h2>

      <p>Control in Veliri is standardized by the standards of modern games and is carried out using the keys - <span
        class="importantly">WASD</span> (movement in different directions), <span class="importantly">Space</span>
        (stop/brake) and <span class="importantly">Shift</span> (acceleration/afterburner).</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_4', 'gif')+ ')',
            width: '279px',
            height: '155px',
            marginLeft: 'calc(50% - 140px)',
            }"/>

      <h3 class="head">Chassis Features:</h3>
      <p><span class="importantly">Tracks</span>: This chassis gives you greater maneuverability and stability. It
        allows you to rotate in place and easily turn in any direction.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_1', 'gif')+ ')',
            width: '321px',
            height: '170px',
            marginLeft: 'calc(50% - 160px)',
            }"/>

      <p><span class="importantly">Antigrav</span>: This chassis gives you greater freedom and flexibility. It allows
        you to control the direction of the chassis with the mouse and move sideways and backward without turning the
        chassis.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_2', 'gif')+ ')',
            width: '321px',
            height: '240px',
            marginLeft: 'calc(50% - 160px)',
            }"/>

      <p><span class="importantly">Wheels</span>: This chassis does not have any special features. It is the simplest
        and most reliable type of chassis. It is suitable for those who prefer a classic style of control.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('motion_3', 'gif')+ ')',
            width: '321px',
            height: '174px',
            marginLeft: 'calc(50% - 160px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Control",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
