<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: ориентация</h2>

      <p>В правом верхнем углу мини карта показывает куда тебе надо двигаться зеленой линией.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_2', 'png')+ ')',
            width: '266px',
            height: '228px',
            marginLeft: 'calc(50% - 133px)',
            }"/>

      <p>Что бы <span class="importantly">зайти на базу</span> надо просто въехать на нее.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_4', 'gif')+ ')',
            width: '422px',
            height: '293px',
            marginLeft: 'calc(50% - 211px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Orientation</h2>

      <p>In the top right corner, the mini-map shows where you need to go with a green line.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_2', 'png')+ ')',
            width: '266px',
            height: '228px',
            marginLeft: 'calc(50% - 133px)',
            }"/>

      <p>To <span class="importantly">enter the base</span>, you simply need to drive onto it.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_4', 'gif')+ ')',
            width: '422px',
            height: '293px',
            marginLeft: 'calc(50% - 211px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationMiniMap",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
