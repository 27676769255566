<template>
  <div id="miniMap" ref="miniMap">

    <div class="texture_wrapper"
         :style="{
            backgroundImage: 'url(' + require('../../assets/interface/frame_4.png').default + ')',
            top: '-57px',
            right:'-68px'
          }"/>

    <div class="zoomButton cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @mousedown="mouseZoomPress(+0.15)" @mouseup="mouseZoomUp"
         :title="texts['zoom'][language]"
         :style="{backgroundImage: 'url(' + require('../../assets/icons/zoom_plus.png').default + ')'}"
         style="top: calc(5%);">
    </div>

    <div class="zoomButton cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @mousedown="mouseZoomPress(-0.15)" @mouseup="mouseZoomUp"
         :title="texts['zoom'][language]"
         :style="{backgroundImage: 'url(' + require('../../assets/icons/zoom_minus.png').default + ')'}"
         style="top: calc(5% + 25px);">

    </div>

    <div class="zoomButton cursor_hover" style="top: calc(5% + 50px);"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @mouseup="focusMS()"
         :title="texts['fix_cam'][language]">

      <div class="image" v-if="settings.FollowCamera"
           :style="{backgroundImage: 'url(' + require('../../assets/icons/on.png').default + ')'}"/>
      <div class="image" v-if="!settings.FollowCamera"
           :style="{backgroundImage: 'url(' + require('../../assets/icons/off.png').default + ')'}"/>
    </div>

    <div class="zoomButton cursor_hover"
         v-if="settings.FollowCamera"
         style="top: calc(5% + 75px);"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @mouseup="lazyCamera()"
         :title="texts['battle_cam'][language]">

      <div class="image" v-if="settings.LazyCamera"
           :style="{backgroundImage: 'url(' + require('../../assets/icons/battle_view.png').default + ')'}"/>
      <div class="image" v-if="!settings.LazyCamera"
           :style="{backgroundImage: 'url(' + require('../../assets/icons/no_battle_view.png').default + ')'}"/>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import SectorStatus from './SectorStatus';
import {FocusMS} from "../../game/interface/focus_unit";

import {Scene} from '../../game/create';
import {gameStore} from "../../game/store";
import {userUnit} from "../../game/update";
import {GetGlobalPos} from "../../game/map/gep_global_pos";
import {minimap} from "../../game/interface/mini_map";
import {selector} from "../../game/interface/target_info";
import {getBar, getBarScale} from "../../game/interface/status_layer";
import {reservoirStatus} from "../../game/interface/reservoir_count";
import {pumpMiniGameState} from "../../game/reservoir/pump_mini_game";

export default {
  name: "MiniMap",
  data() {
    return {
      zoomChange: false
    }
  },
  created() {
    window.addEventListener('wheel', this.wheelZoom);
  },
  destroyed() {
    window.removeEventListener('wheel', this.wheelZoom);
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    resize(event, ui, el) {

      let sizeCanvas = function (id) {
        el.find(id).css("height", el.height());
        el.find(id).css("width", el.width());
        el.find(id).prop('width', el.height())
        el.find(id).prop('height', el.width())
      }

      sizeCanvas('#canvasFog')
      sizeCanvas('#canvasMap')
      sizeCanvas('#canvasMapStatic')
    },
    mouseZoomPress(size) {
      this.playSound('button_press.mp3', 0.3)

      let app = this;

      app.zoomChange = true;
      let zoomer = setInterval(function () {
        if (app.zoomChange) {
          app.zoom(null, size)
        } else {
          clearInterval(zoomer);
        }
      });
    },
    wheelZoom(event, size) {
      if ((!gameStore.HoldAttackMouse && gameStore.mapEditorState.mpID === 0) || (gameStore.mapEditor && !gameStore.HoldAttackMouse && !gameStore.mapEditorState.placeObj)) {
        if (!Scene.wasd || !Scene.wasd.ctrl.isDown) {
          this.zoom(event)
        }
      }
    },
    zoom(event, size) {

      if (event) {
        size = (event.deltaY * 0.005) * -1
      }

      let zoom = Scene.cameras.main.zoom + size

      if (gameStore.mapEditor) {
        Scene.cameras.main.setZoom(zoom);
        return;
      }

      let minSize = 0.5
      if (gameStore.mapEditorState.mpID !== 0) { // this.currentPlayer.user_role === 'admin' ||
        minSize = 0.2
      }

      if (zoom < minSize) {
        zoom = minSize
      } else if (zoom > 2) {
        zoom = 2
      }

      let app = this;
      Scene.tweens.add({
        targets: Scene.cameras.main,
        zoom: zoom,
        duration: 256,
        onComplete: function () {
          app.scaleBars()
        }
      });

      this.$store.dispatch('changeSettings', {
        name: "ZoomCamera",
        count: zoom * 100
      });

      this.$store.commit({
        type: 'setZoomCamera',
        zoom: zoom,
      });
    },
    mouseZoomUp() {
      this.zoomChange = false;
    },
    focusMS() {
      this.playSound('button_press.mp3', 0.3)

      gameStore.gameSettings.follow_camera = !gameStore.gameSettings.follow_camera
      this.$store.dispatch('changeSettings', {
        name: "FollowCamera",
        count: gameStore.gameSettings.follow_camera
      });

      this.$store.commit({
        type: 'setFollowCamera',
        follow: gameStore.gameSettings.follow_camera,
      });

      if (gameStore.gameSettings.follow_camera) {
        FocusMS();
      } else {
        Scene.cameras.main.stopFollow();
      }
    },
    lazyCamera() {
      this.playSound('button_press.mp3', 0.3)

      gameStore.gameSettings.lazy_camera = !gameStore.gameSettings.lazy_camera
      this.$store.dispatch('changeSettings', {
        name: "LazyCamera",
        count: gameStore.gameSettings.lazy_camera
      });

      this.$store.commit({
        type: 'setLazyCamera',
        lazy_camera: gameStore.gameSettings.lazy_camera,
      });

      FocusMS();
    },
    scaleBars() {
      let bars = ['hp', 'build', 'energy', 'shield', 'progress']
      for (let i in gameStore.objects) {
        if (!gameStore.objects[i]) continue;

        for (let bar of bars) {
          let b = getBar('object', gameStore.objects[i].id, bar);
          if (b && b.bar) {
            b.bar.setScale(0.5 * getBarScale())
          }
        }
      }

      for (let i in gameStore.reservoirs) {
        let b = getBar('reservoir', gameStore.reservoirs[i].id, 'progress');
        if (b && b.bar) {
          b.bar.setScale(0.5 * getBarScale())
        }
      }

      if (reservoirStatus.texture) reservoirStatus.texture.setScale(0.4 * getBarScale())
      if (selector) selector.setDisplaySize(32 + (48 * getBarScale() / 2), 32 + (48 * getBarScale() / 2));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('MiniMap')
    },
    currentPlayer() {
      return this.$store.getters.getGameUser
    },
    state() {
      return this.$store.getters.getSectorState
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  components: {
    AppControl: Control,
    AppSectorStatus: SectorStatus,
  }
}
</script>

<style scoped>
#miniMap, #miniMap .zoomButton {
  /*background: rgb(8, 138, 210);*/
  box-shadow: 0 0 2px rgba(0, 0, 0, 1), inset 0 0 2px rgba(0, 0, 0, 1);
  border: 3px solid #009aeb7d;
}

#miniMap {
  position: absolute;
  height: 178px;
  width: 195px;
  border-radius: 5px;
  top: 15px;
  right: 35px;
  user-select: none;
  padding: 19px 2px 2px;
  display: none;
  z-index: 1 !important;
}

#miniMap .zoomButton {
  height: 20px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  line-height: 20px;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: calc(100% + 1px);
  top: 22px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

#miniMap .zoomButton:hover {
  background-color: rgb(33, 176, 255);
}

#miniMap .zoomButton:nth-child(5) {
  line-height: 15px;
}

#miniMap .zoomButton:nth-child(6) {
  font-weight: 100;
}

#miniMap .topButton:nth-child(1) {
  font-size: 22px;
  line-height: 0;
}

#miniMap .zoomButton .label {
  font-size: 11px;
  color: #ffed93;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  margin-top: 2px;
}

.image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  /*filter: contrast(45%) sepia(100%) hue-rotate(11deg) brightness(0.8) saturate(800%) drop-shadow(1px 1px 0px black);*/
}

.texture_wrapper {
  height: 260px;
  width: 260px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 89;
}
</style>
