<template>
  <div class="usersStatusWrapper">
    <template v-if="!skinsMenu.open && !changeNameMenu.open">
      <div class="userAvatarWrapper">
        <div class="userAvatar"
             v-bind:style="{backgroundImage:  avatars[user.id] ? avatars[user.id].avatar : undefined}"></div>
        <label class="noSelect"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="openSkins(true, 'avatar')">
          {{ texts['avatar_change'][language] }}</label>
      </div>

      <h3 class="userName">
        <template v-if="tag !== ''">[<span>{{ tag }}</span>]</template>
        {{ user.login }}
      </h3>

      <div style="float: left; width: calc(100% - 116px);">
        <div class="section">
          <div class="fractionLogo"
               :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(currentPlayer.fraction)).default+')'}"/>
          <span style="line-height: 18px;">{{ texts['fraction_1'][language] }}</span>
          <span class="importantly">{{ currentPlayer.fraction }}</span> <br>
          <span style="line-height: 18px;">{{ texts['rank_1'][language] }}</span>
          <span
            class="importantly">{{
              currentPlayer.battle_rank.fraction_warrior ? getRankName(currentPlayer.battle_rank.rank) : getRankName(0)
            }}</span>
        </div>

        <div v-if="corporation.info" class="section select cursor_hover"
             @click="$parent.openService('Corporation', {corp_name: corporation.info.name}, null,true)">
          <div class="fractionLogo" v-bind:style="{backgroundImage:  'url(\'' + logo + '\')'}"/>
          <span style="line-height: 18px;">{{ texts['cluster_1'][language] }}</span> <br>
          <span class="importantly">{{ corporation.info.name }}</span>
        </div>

        <div v-if="!corporation.info" class="section"
             style="line-height: 35px; font-size: 12px; color: white; text-align: center">
          {{ texts['no_cluster_1'][language] }}
        </div>
      </div>
    </template>

    <template v-if="skinsMenu.open">
      <div class="skinHead" id="queueProduction">
        {{ language === 'RU' ? 'Портреты:' : 'Portraits:' }}

        <div class="skinButton cursor_hover" style="width: 40px; margin-top: 3px; margin-right: 5px; float: right"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="openSkins(false, 'avatar')">
          {{ texts['back_1'][language] }}
        </div>

        <div class="skins" v-for="s in skins">
          <div class="skin_wrapper"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="selectSkin(s)">
            <div class="skin"
                 :style="{ backgroundImage: 'url(' + require('../../assets/' + s.path).default + ')'}">

              <div class="need_credits" v-if="!s.access">
                <div class="credits_icon"/>
                <div class="credits_count">{{ s.price }}</div>
              </div>

              <div class="buy_button cursor_hover" v-if="!s.access"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="buySkin(s)">
                {{ texts['buy'][language] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {urls} from '../../const';

export default {
  name: "MyUser",
  props: ['user'],
  data() {
    return {
      biography: '',
      logo: null,
      skinsMenu: {open: false, type: ""},
      changeNameMenu: {open: false, newName: ""},
    }
  },
  created() {
    this.biography = this.$props.user.biography;
    this.getCorpLogo();
  },
  mounted() {
    this.$parent.resize(null, null, $(this.$parent.$el))
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getCorpLogo() {
      let app = this;

      if (app.corporation.info) {
        app.$api.get(urls.corpLogoURL + '?corporation_id=' + app.corporation.info.id).then(function (response) {
          app.$set(app, 'logo', response.data.avatar);
        });
      }
    },
    getLogo(fraction) {
      return `logo/${fraction}.png`
    },
    openSkins(open, type) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetSkins",
          type: type,
        }
      }));

      this.skinsMenu = {open: open, type: type}
      setTimeout(function () {
        this.$parent.resize(null, null, $(this.$parent.$el))
      }.bind(this))
    },
    selectSkin(s) {
      this.playSound('button_press.mp3', 0.3)
      if (s.access) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: "SetSkin",
            id: Number(s.id),
          }
        }));
        this.openSkins(false)
      }
    },
    buySkin(s) {
      this.playSound('button_press.mp3', 0.3)
      if (!s.access) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: "BuySkin",
            id: Number(s.id),
          }
        }));
      }
    },
    getRankName(r) {
      for (let i in this.handbook.rank[this.language]) {
        if (Number(this.handbook.rank[this.language][i].id) === Number(r)) {
          return this.handbook.rank[this.language][i].name
        }
      }

      return ""
    },
  },
  computed: {
    avatars() {
      return this.$store.getters.getChatState.avatars;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('UserStat')
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    tag() {
      let corp = this.$store.getters.getChatState.corporation;
      if (corp.info) {
        return corp.info.tag
      } else {
        return ""
      }
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    skins() {
      return this.$store.getters.getSkins
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
  }
}
</script>

<style scoped>
input[type=button]:active {
  transform: scale(0.95);
}

.userName {
  margin: 0 0 7px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.userName span {
  color: #e4ff00;
  font-size: 10px;
}

.userAvatar, .userAvatarWrapper, .fractionLogo {
  background-color: rgb(128, 128, 128);
  height: 100px;
  width: 100px;
  float: left;
  margin: -1px 5px 0 -1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
  position: relative;
}

.fractionLogo {
  height: 35px;
  width: 35px;
}

textarea {
  box-shadow: inset 0 0 2px black;
  background: #adc6cd;
  padding: 5px 1px 5px 5px;
  word-wrap: break-word;
  overflow-y: scroll;
  border-radius: 5px;
  text-align: left;
  width: calc(100% - 5px);
  text-shadow: #28a5e4 0 0 4px;
  font-size: 9pt;
  float: left;
  resize: none;
  overflow-x: hidden;
  margin: 0;
}

.userAvatarWrapper label {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  bottom: 2px;
  position: absolute;
  z-index: 3;
}

.userAvatarWrapper label:hover {
  background: #ff8100;
}

.userAvatarWrapper label:active {
  transform: scale(0.98);
}

.biography {
  position: relative;
  clear: both;
}

.section {
  line-height: 16px;
  clear: both;
  background: rgba(77, 77, 84, 0.3);
  width: 100%;
  height: 35px;
  border-radius: 0 5px 5px 0;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  box-shadow: 0 0 2px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.section:first-child {
  margin-bottom: 5px;
}

.count_letters {
  position: absolute;
  bottom: -15px;
  left: 0;
  font-size: 8px;
  font-weight: bold;
  background: #c18435;
  border-radius: 4px;
  padding: 1px 4px 1px 4px;
  box-shadow: 0 0 2px black;
  color: white;
  text-shadow: 0 1px 1px black;
}

.skinHead {
  line-height: 17px;
  width: calc(100% + 6px);
  text-align: left;
  height: 17px;
  background: #df6f2c;
  padding-left: 7px;
  box-shadow: 0 0 2px black;
  font-size: 11px;
  clear: both;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  border-radius: 3px 0 0 0;
  margin: -6px 1px 2px -6px;
}

.skinButton {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
}

.skinButton:active {
  transform: scale(.98);
}

.skinButton:hover {
  background: #ff8100;
}

.skin_wrapper {
  padding: 3px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 0 2px black;
  float: left;
  margin: 2px 2px 2px 7px;
}

.skin_wrapper:hover {
  background: rgba(128, 128, 128, 0.3);
}

.skin {
  height: 119px;
  width: 119px;
  background-size: contain;
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.no_access, .need_credits {
  height: 20px;
  width: 20px;
  background-color: rgba(125, 125, 125, 1);
  padding: 2px;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px black;
  position: absolute;
  top: 4px;
  left: 4px;
}

.need_credits {
  width: 60px;
  height: 12px;
  left: calc(50% - 30px);
}

.no_access img {
  height: 100%;
  width: 100%;
}

.credits_icon {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png')
}

.credits_count {
  background: #066ea8;
  float: right;
  width: calc(100% - 21px);
  padding-right: 4px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
}

.buy_button {
  display: block;
  width: 60px;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 12px;
  border-radius: 5px;
  color: #fff;
  line-height: 12px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  z-index: 3;
  bottom: 4px;
  left: calc(50% - 30px);
}

.buy_button:active {
  transform: scale(.98);
}

.buy_button:hover {
  background: #ff8100;
}

.createNewUser {
  height: 20px;
  width: calc(100% - 4px);
  background: rgba(108, 108, 108, 0.51);
  margin: 2px;
  border-radius: 5px;
  color: white;
  line-height: 20px;
  box-shadow: 0 0 2px black;
  text-align: center;
}

.createNewUser.input {
  width: calc(100% - 14px);
  text-align: left;
}

.createNewUser.input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.createNewUser.button {
  transition: 100ms;
  background: rgba(255, 129, 0, 0.75);
}

.createNewUser.button:hover {
  background: rgba(255, 129, 0, 1);
}

.createNewUser.button:active {
  transform: scale(0.98);
}

.select:hover {
  background: rgba(255, 129, 0, 0.75);
}
</style>
