<template>
  <div id="SquadBarCells">
    <div v-for="(slot, key) in slots"
         class="SquadBarEquipCell cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         v-bind:class="[{'mouse_select': slot.active, 'active': slot.type === 'equip' && slot.on, green_animate: selectCells}, slot.class]"
         :ref="'SquadBarEquipCell' + key">

      <div>
        <app-background-item-cell v-if="slot.slot" v-bind:slotItem="slot.slot" v-bind:noShowName="true"/>
      </div>

      <div class="cell_button_label">{{ getButtonByKey(key) }}</div>

      <span class="QuantityItems" v-if="slot.type ==='equip' && slot.maxAmmoCount > 0">
        {{ slot.ammoCount }} / {{ slot.maxAmmoCount }}
      </span>

      <div class="ammoEquip" v-if="slot.ammo">
        <div>
          <app-background-item-cell v-bind:slotItem="slot.ammo.background"/>
        </div>
        <span class="QuantityItems">{{ slot.slot.ammo_quantity }}</span>
      </div>

    </div>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import {gameStore} from "../../game/store";
import {getButtonByKey, noPower} from "../../game/interface/equip_bar";
import {ItemBinTypes} from "../../const";
import {GetItemByTypeAndID} from "../../game/utils/get_item_by_type_and_id";

export default {
  name: "EquipBar",
  data() {
    return {
      subAmmoOpen: false,
      visible: false,
      visibleCells: 8,
      updater: null,
      slots: {},
    }
  },
  destroyed() {
    clearInterval(this.updater)
  },
  updated() {
    for (let i = 2; i <= this.visibleCells + 1; i++) {
      this.initCell('SquadBarEquipCell' + i, i);
    }
  },
  mounted() {
    let app = this;
    app.updateSlots(gameStore.equipPanel.slots)

    for (let i = 2; i <= this.visibleCells + 1; i++) {
      this.initCell('SquadBarEquipCell' + i, i);
    }

    this.updater = setInterval(function () {
      app.updateSlots(gameStore.equipPanel.slots)
    }.bind(this), 512)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    updateSlots(equipPanel) {
      let slots = {};
      this.visibleCells = 8 // getMaxNumberCell()-1

      for (let i = 2; i <= this.visibleCells + 1; i++) {

        if (i === 1) {
          slots[i] = {type: 'empty', class: 'weapon'}
        } else {
          slots[i] = {type: 'empty'};
        }

        if (equipPanel[i]) {


          let equipSlot = equipPanel[i];
          let slotItem = this.getItemInEquipPanel(equipSlot);
          if (!slotItem) {
            slots[i] = {type: 'empty'};
            continue;
          }

          if (slotItem.hasOwnProperty('equip') && slotItem.equip) {
            let cssClass = 'equip';
            if (slotItem.equip.active) {

              if (slotItem.equip.x_attach > 0 && slotItem.equip.y_attach > 0) {
                cssClass = 'distEquip';
              } else {
                cssClass = 'activeEquip';
              }
            } else {
              cssClass = 'passiveEquip';
            }

            slotItem.item = slotItem.equip;
            slotItem.type = 'equip';

            slots[i] = {
              type: 'equip',
              class: cssClass,
              slot: slotItem,
              maxAmmoCount: slotItem.equip.max_ammo_count,
              ammoCount: equipSlot.aq,
              on: equipSlot.on,
              active: false,
              noPower: noPower(equipSlot),
              type_slot: equipSlot.type_slot,
              number_slot: equipSlot.slot,
            }
          }

          if (slotItem.hasOwnProperty('weapon') && slotItem.weapon) {

            slotItem.item = slotItem.weapon;
            slotItem.type = 'weapon';

            if (equipSlot.cr < 0) equipSlot.cr = 0
            slots[i] = {
              type: 'weapon',
              class: 'weapon',
              slot: slotItem,
              active: true,
            };

            if (slotItem.ammo) {
              slotItem.ammo_quantity = equipSlot.aq;
              slots[i].ammo = {
                ammo: slotItem.ammo,
                background: {
                  type: "ammo",
                  item: {name: slotItem.ammo.name}
                }
              }
            }
          }

          if (!slotItem.hasOwnProperty('equip') && !slotItem.hasOwnProperty('weapon')) {
            slots[i] = {type: 'item', class: 'item', slot: slotItem}
          }
        }
      }

      this.slots = slots
    },
    initCell(ref, number) {
      let app = this;
      let waitCell = setInterval(function () {
        if (app.$refs[ref]) {

          $(app.$refs[ref][0]).data("slotData", {parent: "equipPanel", number: number});

          $(app.$refs[ref][0]).draggable({
            disabled: false,
            drag: function (e, ui) {
              let factor = 1 / app.$store.getters.getZoom - 1
              ui.position.top += Math.round((ui.position.top - ui.originalPosition.top) * factor)
              ui.position.left += Math.round((ui.position.left - ui.originalPosition.left) * factor)
            },
            stop: function (event, ui) {
              let squadBar = document.getElementById("SquadBarCells");
              let squadBarRect = squadBar.getBoundingClientRect();

              if (squadBarRect.top < event.pageY && squadBarRect.bottom > event.pageY &&
                squadBarRect.left < event.pageX && squadBarRect.right > event.pageX) {
              } else {
                app.$store.dispatch("sendSocketData", JSON.stringify({
                  service: "inventory",
                  data: {
                    event: "DropItemEquipPanel",
                    panel_slot: number - 1,
                  }
                }));
              }
            },
            helper: 'clone',
            delay: 200,
          });

          $(app.$refs[ref][0]).droppable({
            tolerance: "pointer",
            drop: function (event, ui) {

              $('.ui-selected').removeClass('ui-selected');

              let draggable = ui.draggable;

              //console.log(draggable.data("slotData"))
              // если родитель конструктор то это снятие эквипа с тушки, там свои методы
              if (draggable.data("slotData").parent === "Constructor") {
                app.$store.dispatch("sendSocketData", JSON.stringify({
                  service: "inventory",
                  data: {
                    event: "ItemToEquipPanel",
                    source: draggable.data("slotData").parent,
                    equip_slot: Number(draggable.data("slotData").equipSlot),
                    equip_slot_type: Number(draggable.data("slotData").equipType),
                    panel_slot: number - 1,
                  }
                }));
              } else if (draggable.data("slotData").parent === "squadInventory" || draggable.data("slotData").parent.includes('aInv:')) {
                app.$store.dispatch("sendSocketData", JSON.stringify({
                  service: "inventory",
                  data: {
                    event: "ItemToEquipPanel",
                    source: draggable.data("slotData").parent,
                    panel_slot: number - 1,
                    src_slot: draggable.data("selectedItems").slotsNumbers[0]
                  }
                }));
              } else if (draggable.data("slotData").parent === "equipPanel") {
                app.$store.dispatch("sendSocketData", JSON.stringify({
                  service: "inventory",
                  data: {
                    event: "SwitchItemToEquipPanel",
                    src_slot: draggable.data("slotData").number - 1,
                    dst_slot: number - 1,
                  }
                }));
              }
            }
          });

          clearInterval(waitCell)
        }
      }, 100)
    },
    getButtonByKey(key) {
      return getButtonByKey(Number(key))
    },
    getItemInEquipPanel(cell) {
      if (!cell) return;

      if (cell.source === "squadInventory" || cell.source.includes('aInv:')) {
        let quantity = 0;


        let t = ItemBinTypes[cell.type_slot]
        let item = GetItemByTypeAndID(t, cell.slot)
        if (this.inventory.warehouses[cell.source] && this.inventory.warehouses[cell.source].inventory) {
          for (let i in this.inventory.warehouses[cell.source].inventory) {
            let slot = this.inventory.warehouses[cell.source].inventory[i];
            if (slot && slot.item && slot.type === t && slot.item_id === cell.slot) {
              quantity += slot.quantity;
            }
          }
        }

        if (!item) {
          return null;
        } else {
          return {type: t, quantity: quantity, item: item}
        }
      }

      if (cell.source === "Constructor") {
        if (this.inventory.unit && this.inventory.unit.body) {
          let {slot} = this.findEquip(cell.type_slot, cell.slot);
          return slot
        }
      }

      return null
    },
    findEquip(findType, findSlot) {
      let item = {slot: null};

      if (findType === 4) {
        for (let i in this.inventory.unit.weapon_slots) {
          let weapon = this.inventory.unit.weapon_slots[i];
          if (weapon.number_slot === findSlot) {
            item = {slot: weapon}
          }
        }
      } else {
        for (let i in this.inventory.unit.equip_slots) {
          let equip = this.inventory.unit.equip_slots[i];
          if (equip.number_slot === findSlot && equip.type_slot === findType) {
            item = {slot: equip}
          }
        }
      }

      return item
    },
  },
  computed: {
    ammo() {
      return this.$store.getters.getAvailableAmmo
    },
    inventory() {
      let inventory = this.$store.getters.getInventory

      if (!this.visible && inventory.equipPanel) {
        this.visible = true;
        for (let i = 1; i <= this.visibleCells; i++) {
          this.initCell('SquadBarEquipCell' + i, i);
        }
      }

      if (!inventory.equipPanel) {
        this.visible = false;
      }

      return inventory
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectCells() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "equip_mining_to_quick_panel" || this.missions.currentAction.type_func_monitor === "weapon_in_panel";
    }
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.SquadBarEquipCell {
  float: left;
  height: 40px;
  width: 40px;
  background-color: #585858;
  border-radius: 5px;
  margin: 0 1px;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

@media (max-width: 1200px) {
  .SquadBarEquipCell {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 1000px) {
  .SquadBarEquipCell {
    height: 30px;
    width: 30px;
  }
}

.SquadBarEquipCell.weapon {
  background: #852700;
}

.SquadBarEquipCell.distEquip {
  background: #0d8511;
}

.SquadBarEquipCell.activeEquip {
  background: #006485;
}

.SquadBarEquipCell.passiveEquip {
  background: #687585;
}

.SquadBarEquipCell.item {
  background: #bcbbbd;
}

.SquadBarEquipCell.active {
  box-shadow: inset 0 0 2px yellow, 0 0 2px yellow;
  animation: 2s linear 0s infinite alternate active;
}

.mode.active {
  box-shadow: inset 0 0 2px yellow, 0 0 2px yellow;
  border: 1px solid yellow;
}

.SquadBarEquipCell:hover {
  border: 2px solid rgb(255, 183, 6);
}

.reloadEquip {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  border-top: 1px solid grey;
  bottom: 0;
  transition: 32ms;
  z-index: 2;
}

.ammoEquip {
  height: 50%;
  width: 50%;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  border: 1px solid grey;
}

@keyframes active {
  0% {
    border-left: 2px solid rgb(174, 255, 3);
    border-right: 2px solid rgb(253, 255, 0);
    border-top: 2px solid rgb(255, 212, 5);
    border-bottom: 2px solid rgb(255, 91, 0);
  }
  25% {
    border-left: 2px solid rgb(255, 91, 0);
    border-right: 2px solid rgb(174, 255, 3);
    border-top: 2px solid rgb(253, 255, 0);
    border-bottom: 2px solid rgb(255, 212, 5);
  }

  50% {
    border-left: 2px solid rgb(255, 212, 5);
    border-right: 2px solid rgb(255, 91, 0);
    border-top: 2px solid rgb(174, 255, 3);
    border-bottom: 2px solid rgb(253, 255, 0);
  }
  75% {
    border-left: 2px solid rgb(253, 255, 0);
    border-right: 2px solid rgb(255, 212, 5);
    border-top: 2px solid rgb(255, 91, 0);
    border-bottom: 2px solid rgb(174, 255, 3);
  }
}

.user_avatar {
  float: left;
  height: 50px;
  width: 50px;
  margin: 5px;
  border-radius: 2px;
  background: grey;
  box-shadow: 0 0 2px black;
  z-index: 1;
  position: relative;
  background-size: cover;
}

.user_name {
  color: white;
  float: left;
  padding: 0;
  margin: 6px 2px 2px -10px;
  box-shadow: 0 0 2px black;
  width: 146px;
  background: rgb(119, 119, 119);
}

.bars {
  float: left;
  width: 100%;
  margin-top: 3px;
}

#hp_bar {
  overflow: visible;
  background: rgba(72, 255, 40, 0.7);
  height: 100%;
}

.hp_bar_wrapper {
  width: calc(100% - 25px);
  height: 10px;
  border: 1px solid #4c4c4c;
  text-align: left;
  display: block;
  box-shadow: inset 0 0 2px black, 0 0 2px black;
  border-radius: 10px;
  background-size: 12%;
  overflow: hidden;
  background-color: #959595;
  margin: 0 auto 0;
}

.hp_bar_wrapper span {
  display: block;
  font-size: 10px;
  line-height: 11px;
  width: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  margin: auto;
  float: left;
  color: white;
}

#SquadBar .mouse_select {
  box-shadow: inset 0 0 7px 2px white, 0 0 2px white;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.QuantityItems {
  z-index: 1;
  position: absolute;
  bottom: 2px;
  left: 2px;
  font-size: 9px;
  color: #fff16f;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  pointer-events: none;
}

.modes {
  position: absolute;
  top: -53px;
  left: 2px;
}

.mode {
  border: 1px solid #2e2e2e;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(127, 127, 127, 0.8);
  background-size: cover;
  margin-bottom: 2px;
}

.mode .title {
  position: absolute;
  bottom: calc(100% + 2px);
  display: none;
  color: white;
  font-size: 12px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.mode:hover .title {
  display: block;
}

#SquadBarCells:after {
  content: ' ';
  display: block;
  clear: both;
}

.cell_button_label {
  position: absolute;
  font-size: 10px;
  color: #ffed93;
  top: 0;
  left: 0;
  font-weight: bold;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.green_animate {
  box-shadow: inset 0 0 4px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 3px 2px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 9px 3px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 3px 2px greenyellow;
  }
}
</style>
