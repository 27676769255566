// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/bases/base.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.transfer[data-v-5ec690aa] {\n  height: 100vh;\n  width: 100%;\n  text-align: center;\n  background-color: #7f7f7f;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-attachment: fixed;\n  background-position: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Transfer/Transfer.vue"],"names":[],"mappings":";AA+BA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,yDAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;AACA","sourcesContent":["<template>\n  <div class=\"transfer\" id=\"transfer\" ref=\"transfer\">\n  </div>\n</template>\n\n<script>\nexport default {\n  name: \"Transfer\",\n  mounted() {\n    this.$store.commit({\n      type: 'setVisibleLoader',\n      visible: true,\n      text: {\n        'RU': 'Перемещаемся...',\n        'EN': 'We are moving around...',\n      }\n    });\n\n    setTimeout(function () {\n      this.$store.dispatch(\"sendSocketData\", JSON.stringify({\n        service: \"system\",\n        data: {\n          event: \"CheckInBase\",\n        }\n      }));\n    }.bind(this), 1000)\n  },\n}\n</script>\n\n<style scoped>\n.transfer {\n  height: 100vh;\n  width: 100%;\n  text-align: center;\n  background-color: #7f7f7f;\n  background-image: url('../../assets/bases/base.jpg');\n  background-size: cover;\n  background-attachment: fixed;\n  background-position: center;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
