import {byteArrayToLong, intFromBytes} from "../../../utils";
import {AddUnitMoveBufferData} from "../../../game/unit/move";
import {AddBoxMoveBufferData} from "../../../game/box/move";
import {AddDroneMoveBufferData} from "../../../game/drone/fly";
import {CreateMark, MoveMark, RemoveMark} from "../../../game/radar/marks";
import {AddBulletMoveBufferData} from "../../../game/bullet/fly";
import {ExplosionBullet} from "../../../game/weapon/explosion";
import {RotateEquip, RotateUnitGun} from "../../../game/unit/rotate";
import {FireWeapon} from "../../../game/weapon/fire";
import {RotateTurretGun} from "../../../game/map/structures/turret";
import {CreateCloud, removeCloud} from "../../../game/cloud/cloud";
import {ServerGunDesignator} from "../../../game/interface/server_gun_designator";
import {fractionByte, ItemBinTypes, SourceItemBin} from "../../../const";
import {damageText} from "../../../game/text/damage";
import {ObjectDead} from "../../../game/map/object_dead";
import {UpdateObject} from "../../../game/radar/update_objects";
import {CreateRadarObject, RemoveRadarObject} from "../../../game/radar/object";
import {gameStore} from "../../../game/store";
import {FlyLaser} from "../../../game/bullet/laser";
import {AddObjectMoveBufferData} from "../../../game/map/move_object";
import {AllRopeRender, MovePoint, RopeRender} from "../../../game/rope/render";
import {PlaneMine} from "../../../game/equip/plane_mine";
import {GravitySquareWork} from "../../../game/equip/gravity_square";
import {Jump} from "../../../game/equip/jump";
import {ActiveInvisibility, DeactivateInvisibility, Invisibility} from "../../../game/equip/invisibility";
import {Afterburner} from "../../../game/equip/afterburner";
import {SmokeScreen} from "../../../game/equip/smoke_screen";
import {HideRadarWork} from "../../../game/equip/hide_radar";
import {MissileDefense} from "../../../game/equip/missile_defense";
import {Suicide, SuicideProgress} from "../../../game/equip/suicide";
import {ZoneHealAnimate} from "../../../game/equip/zone_heal";
import {TransportBlock} from "../../../game/evacuation/transport_block";
import {
  setUnrepairableDamage,
  thoriumBars,
  updateEnergy,
  updateExistReady,
  updateHP,
  updateRank,
  updateVioStatus
} from "../../../game/interface/status_bar";
import {getNewStateSlots, resetEquipBarState} from "../../../game/interface/equip_bar";
import {MiningTargets} from "../../../game/equip/mining_targets";
import {Mining} from "../../../game/equip/mining";
import {CreateMapBar} from "../../../game/interface/status_layer";
import {Scene} from "../../../game/create";
import {HitItem} from "../../../game/interface/hit_item";
import {DroneTarget} from "../../../game/drone/target";
import {BuildAnimate} from "../../../game/build/build_animate";
import {AddItemMoveBufferData} from "../../../game/map_items/move";
import {GravityGunDrop, GravityGunRun, GravityGunTarget} from "../../../game/equip/gravity_gun_target";
import {UnitsTargets} from "../../../game/equip/units_targets";
import {RepairLine} from "../../../game/equip/repair";
import {BoxTargets} from "../../../game/box/target";
import {UpdateGroupPlayer} from "../../../game/interface/group_bar";
import {PortalInto, PortalOut} from "../../../game/portal/portal_into";
import {DropExit} from "../../../game/portal/drop_exit";
import {updateSignals} from "../../../game/interface/mini_map";
import {PlayPositionSound} from "../../../game/sound/play_sound";
import {GetGlobalPos} from "../../../game/map/gep_global_pos";
import {PumpAnimate} from "../../../game/equip/pump_animate";
import {DrawText} from "../../../game/interface/center_text";
import {RotateObjectEquip} from "../../../game/map/structures/repair_station";
import {updateBattleData} from "../../../game/interface/battle_state";
import {addPointNotify} from "../../../game/interface/points_board";
import {addKill} from "../../../game/interface/kill_board";
import {calcPing} from "../../../game/utils/server_time";
import {DrawAnomaly} from "../../../game/equip/anomaly";
import {NoCompleteStructure} from "../../../game/map/structures/no_complete_structure";
import {updateSiegeData} from "../../../game/interface/siege_state";
import {RunGlitch} from "../../../game/equip/danger_anomaly";
import {TargetInfo} from "../../../game/interface/target_info";
import {SetGameTime} from "../../../game/interface/game_time";
import {AddDecal} from "../../../game/unit/decal";
import {shieldAnimate} from "../../../game/shield/shield";
import {shieldOn} from "../../../game/equip/body_shield";
import {PumpMiniGame} from "../../../game/reservoir/pump_mini_game";
import {CombineTargets} from "../../../game/equip/combine_targets";
import {AddPollenMoveBufferData} from "../../../game/pollen/move";

const MarksTypes = {
  1: "fly", 2: "ground", 3: "structure", 4: "resource", 5: "bullet",
}

function parseMegaPackData(data, store) {
  // [1[eventID],
  //      4[data_size], data_size[data],
  //      ...
  //      4[data_size], data_size[data],

  let unitMoveSize = intFromBytes(data.slice(1, 5))
  let stopByte = 5 + unitMoveSize;
  BinaryReader(data.subarray(5, 5 + unitMoveSize), store)

  for (; stopByte < data.length;) {
    let subData = intFromBytes(data.slice(stopByte, stopByte + 4))
    stopByte = 4 + stopByte;

    if (subData === 0) continue;
    if (stopByte < 0 || subData < 0) {
      console.log("wrong date from server: ", data, ", stopByte: ", stopByte, ", subData", subData)
      return
    }

    BinaryReader(data.subarray(stopByte, stopByte + subData), store)
    stopByte = subData + stopByte;
  }
}

function BinaryReader(msgBytes, store) {
  if (msgBytes[0] === 100) {
    parseMegaPackData(msgBytes, store)
    return
  }

  if (msgBytes[0] === 1) {
    // [eventID| 4[unitID], 4[speed], 4[x], 4[y], 4[z], 4[ms], 4[rotate], 4[angularVelocity], 4[mpID] 1[animate], 1[direction]]
    //      [1 | 0, 0, 2, 96, 0, 0, 0, 0, 0, 0, 7, 251, 0, 0, 4, 136, 0, 0, 0, 100, 0, 0, 0, 64, 0, 0, 0, 87, 0, 0, 0, 0, 0, 0, 0, 2, 1, 0, 0]
    for (let i = 0; i < msgBytes.length; i += 37) {
      let event = msgBytes.slice(i, i + 37);

      AddUnitMoveBufferData({
        id: intFromBytes(event.slice(1, 5)),
        s: intFromBytes(event.slice(5, 9)),
        x: intFromBytes(event.slice(9, 13)),
        y: intFromBytes(event.slice(13, 17)),
        z: intFromBytes(event.slice(17, 21)),
        ms: intFromBytes(event.slice(21, 22)),
        r: intFromBytes(event.slice(22, 26)),
        av: intFromBytes(event.slice(26, 30)) * 0.001,
        a: intFromBytes(event.slice(30, 31)),
        d: intFromBytes(event.slice(31, 32)),
        sky: intFromBytes(event.slice(32, 33)),
        ka: intFromBytes(event.slice(33, 34)),
        kd: intFromBytes(event.slice(34, 35)),
        kw: intFromBytes(event.slice(35, 36)),
        ksp: intFromBytes(event.slice(36, 37)),
      })
    }
  }

  if (msgBytes[0] === 3) {
    for (let i = 0; i < msgBytes.length; i += 22) {
      let event = msgBytes.slice(i, i + 22);
      let msg = {
        id: intFromBytes(event.slice(2, 6)),
        x: intFromBytes(event.slice(6, 10)),
        y: intFromBytes(event.slice(10, 14)),
        ms: intFromBytes(event.slice(14, 18)),
        r: intFromBytes(event.slice(18, 22)),
      }

      if (msgBytes[1] === 1) AddBoxMoveBufferData(msg);
      if (msgBytes[1] === 2) AddObjectMoveBufferData(msg)
      if (msgBytes[1] === 3) AddItemMoveBufferData(msg);
    }
  }

  if (msgBytes[0] === 4) {
    for (let i = 0; i < msgBytes.length; i += 22) {
      let event = msgBytes.slice(i, i + 22);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        z: intFromBytes(event.slice(13, 17)),
        ms: intFromBytes(event.slice(17, 18)),
        r: intFromBytes(event.slice(18, 22)),
      }

      AddDroneMoveBufferData(msg);
    }
  }

  if (msgBytes[0] === 5) {
    for (let i = 0; i < msgBytes.length; i += 17) {
      let event = msgBytes.slice(i, i + 17);
      let msg = {
        mu: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        ms: intFromBytes(event.slice(13, 17)),
      }

      MoveMark(msg)
    }
  }

  if (msgBytes[0] === 6) {
    for (let i = 0; i < msgBytes.length; i += 23) {
      let event = msgBytes.slice(i, i + 23);

      let msg = {
        type_id: intFromBytes(event.slice(1, 2)),
        id: intFromBytes(event.slice(2, 6)),
        x: intFromBytes(event.slice(6, 10)),
        y: intFromBytes(event.slice(10, 14)),
        z: intFromBytes(event.slice(14, 18)),
        ms: intFromBytes(event.slice(18, 19)),
        r: intFromBytes(event.slice(19, 23)),
      }

      AddBulletMoveBufferData(msg)
    }
  }

  if (msgBytes[0] === 7) {
    for (let i = 0; i < msgBytes.length; i += 14) {
      let event = msgBytes.slice(i, i + 14);

      let msg = {
        type_id: intFromBytes(event.slice(1, 2)),
        x: intFromBytes(event.slice(2, 6)),
        y: intFromBytes(event.slice(6, 10)),
        z: intFromBytes(event.slice(10, 14)),
      }

      ExplosionBullet(msg);
    }
  }

  if (msgBytes[0] === 8) {
    for (let i = 0; i < msgBytes.length; i += 11) {
      let event = msgBytes.slice(i, i + 11);
      RotateUnitGun(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 6)), intFromBytes(event.slice(6, 10)), intFromBytes(event.slice(10, 11)))
    }
  }

  if (msgBytes[0] === 10) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        ms: intFromBytes(event.slice(5, 9)),
        r: intFromBytes(event.slice(9, 13)),
      }

      RotateTurretGun(msg)
    }
  }

  if (msgBytes[0] === 9) {
    for (let i = 0; i < msgBytes.length; i += 23) {
      let event = msgBytes.slice(i, i + 23);
      let msg = {
        type_id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        z: intFromBytes(event.slice(13, 17)),
        r: intFromBytes(event.slice(17, 21)),
        ap: intFromBytes(event.slice(21, 22)),
        f: intFromBytes(event.slice(22, 23)) === 1,
      }

      FireWeapon(msg)
    }
  }

  if (msgBytes[0] === 11) {
    for (let i = 0; i < msgBytes.length; i += 29) {
      let event = msgBytes.slice(i, i + 29);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        type_id: intFromBytes(event.slice(5, 9)),
        x: intFromBytes(event.slice(9, 13)),
        y: intFromBytes(event.slice(13, 17)),
        m: intFromBytes(event.slice(17, 21)),
        a: intFromBytes(event.slice(21, 25)) * 0.01,
        r: intFromBytes(event.slice(25, 29))
      }
      CreateCloud(msg)
    }
  }

  if (msgBytes[0] === 12) {
    for (let i = 0; i < msgBytes.length; i += 5) {
      let event = msgBytes.slice(i, i + 5);
      removeCloud(intFromBytes(event.slice(1, 5)))
    }
  }

  if (msgBytes[0] === 13) {
    for (let i = 0; i < msgBytes.length; i += 33) {
      let event = msgBytes.slice(i, i + 33);

      let msg = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        rd: intFromBytes(event.slice(9, 13)),
        ac: intFromBytes(event.slice(13, 14)),
        aa: intFromBytes(event.slice(14, 15)),
        ap: intFromBytes(event.slice(15, 16)),
        r: intFromBytes(event.slice(16, 17)),
        chase: intFromBytes(event.slice(17, 18)),
        t: gameStore.mapBinItems[intFromBytes(event.slice(18, 19))],
        id: intFromBytes(event.slice(19, 23)),
        fireX: intFromBytes(event.slice(23, 27)),
        fireY: intFromBytes(event.slice(27, 31)),
        weaponSlot: intFromBytes(event.slice(31, 32)),
        hide: intFromBytes(event.slice(32, 33)) === 1,
      }

      if (msg.select) {
        gameStore.fireState.target.x = msg.x;
        gameStore.fireState.target.y = msg.y;
        gameStore.fireState.firePosition.x = msg.fireX;
        gameStore.fireState.firePosition.y = msg.fireY;
      }

      ServerGunDesignator(msg);
    }
  }

  if (msgBytes[0] === 14) {

    updateHP(intFromBytes(msgBytes.slice(1, 5)), intFromBytes(msgBytes.slice(5, 9)))
    updateEnergy(intFromBytes(msgBytes.slice(9, 13)))

    store.commit({
      type: 'setAutoPilot',
      auto: intFromBytes(msgBytes.slice(13, 14)),
    });

    let countThoriumSlots = intFromBytes(msgBytes.slice(14, 18))
    let lowPower = false
    if (countThoriumSlots > 0) {
      let slots = []

      for (let i = 0; i < 18 * countThoriumSlots; i += 18) {
        let slotData = msgBytes.slice(i + 18, i + 18 + 18);

        let slot = {
          number_slot: intFromBytes(slotData.slice(0, 4)),
          count: intFromBytes(slotData.slice(4, 8)),
          max_count: intFromBytes(slotData.slice(8, 12)),
          worked_out: intFromBytes(slotData.slice(12, 13)),
          processing_thorium: intFromBytes(slotData.slice(13, 17)),
          inversion: intFromBytes(slotData.slice(17, 18)),
        }

        if (slot.count === 0) {
          lowPower = true
        }

        slots.push(slot)
      }

      store.commit({
        type: 'setLowPower',
        lowPower: lowPower,
      });

      thoriumBars(slots)
    }
  }

  if (msgBytes[0] === 15) {

    gameStore.equipPanel.select = intFromBytes(msgBytes.slice(1, 2)) + 1
    if (gameStore.equipPanel.select === 256) {
      gameStore.equipPanel.select = 1
    }

    let rerender = intFromBytes(msgBytes.slice(2, 3)) === 1
    if (rerender) {
      resetEquipBarState();
    }

    let countSlots = intFromBytes(msgBytes.slice(3, 4))
    if (countSlots > 0) {
      let slots = {}

      let equipPanelData = msgBytes.subarray(4)

      for (; equipPanelData.length > 0;) {

        let number = intFromBytes(equipPanelData.slice(29, 30)) + 1;
        slots[number] = {
          number: number,
          source: SourceItemBin[intFromBytes(equipPanelData.slice(0, 1))],
          type_slot: intFromBytes(equipPanelData.slice(1, 2)),
          slot: intFromBytes(equipPanelData.slice(2, 6)),
          sr: byteArrayToLong(equipPanelData.slice(6, 14)),
          er: byteArrayToLong(equipPanelData.slice(14, 22)),
          aq: intFromBytes(equipPanelData.slice(22, 26)),
          on: intFromBytes(equipPanelData.slice(26, 27)) === 1,
          mode: intFromBytes(equipPanelData.slice(27, 28)),
          am: intFromBytes(equipPanelData.slice(28, 29)) === 1,
        }

        equipPanelData = equipPanelData.subarray(30)
      }

      gameStore.equipPanel.slots = slots;
      getNewStateSlots()
    }
  }

  if (msgBytes[0] === 16) {
    for (let i = 0; i < msgBytes.length; i += 21) {
      let event = msgBytes.slice(i, i + 21);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        r: intFromBytes(event.slice(5, 9)),
        ms: intFromBytes(event.slice(9, 13)),
        ts: intFromBytes(event.slice(13, 17)),
        s: intFromBytes(event.slice(17, 21)),
      }

      RotateEquip(msg)
    }
  }

  if (msgBytes[0] === 17) {
    for (let i = 0; i < msgBytes.length; i += 27) {
      let event = msgBytes.slice(i, i + 27);
      let msg = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        damage: intFromBytes(event.slice(9, 13)),
        ownerType: gameStore.mapBinItems[intFromBytes(event.slice(13, 14))],
        dealerType: gameStore.mapBinItems[intFromBytes(event.slice(14, 15))],
        dealerID: intFromBytes(event.slice(15, 19)),
        ownerID: intFromBytes(event.slice(19, 23)),
        damageK: intFromBytes(event.slice(23, 24)),
        unitShield: intFromBytes(event.slice(24, 25)) === 1,
        destroyShield: intFromBytes(event.slice(25, 26)) === 1,
        area: intFromBytes(event.slice(26, 27))
      }

      damageText(msg)
    }
  }

  if (msgBytes[0] === 18) {
    for (let i = 0; i < msgBytes.length; i += 14) {
      let event = msgBytes.slice(i, i + 14);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        t: gameStore.mapBinItems[intFromBytes(event.slice(13, 14))]
      }

      ObjectDead(msg)
    }
  }

  if (msgBytes[0] === 19) {

    let subArray = msgBytes
    for (; subArray.length > 0;) {
      let ammoNameSize = intFromBytes(subArray.slice(21, 22))
      let msg = {
        x: intFromBytes(subArray.slice(1, 5)),
        y: intFromBytes(subArray.slice(5, 9)),
        msg: {
          name: String.fromCharCode(...subArray.slice(22, 22 + ammoNameSize)),
          map_id: intFromBytes(subArray.slice(9, 13)),
          target: {
            x: intFromBytes(subArray.slice(13, 17)),
            y: intFromBytes(subArray.slice(17, 21)),
          }
        }
      }

      FlyLaser(msg)
      subArray = subArray.subarray(22 + ammoNameSize)
    }
  }

  if (msgBytes[0] === 20) {
    for (let i = 0; i < msgBytes.length; i += 25) {
      let event = msgBytes.slice(i, i + 25);

      let msg = {
        type_id: intFromBytes(event.slice(1, 2)),
        x: intFromBytes(event.slice(2, 6)),
        y: intFromBytes(event.slice(6, 10)),
        to_x: intFromBytes(event.slice(10, 14)),
        to_y: intFromBytes(event.slice(14, 18)),
        unit_id: intFromBytes(event.slice(18, 22)),
        accum_power: intFromBytes(event.slice(22, 23)),
        et: intFromBytes(event.slice(23, 24)),
        en: intFromBytes(event.slice(24, 25)),
      }

      FlyLaser(msg);
    }
  }

  if (msgBytes[0] === 22) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      GravitySquareWork(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)),)
    }
  }

  if (msgBytes[0] === 21) {
    for (let i = 0; i < msgBytes.length; i += 14) {
      let event = msgBytes.slice(i, i + 14);
      if (intFromBytes(event.slice(13, 14)) === 0) {
        HideRadarWork(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)))
      }
      if (intFromBytes(event.slice(13, 14)) === 1) {
        SmokeScreen(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)))
      }
    }
  }

  if (msgBytes[0] === 23) {
    for (let i = 0; i < msgBytes.length; i += 17) {
      let event = msgBytes.slice(i, i + 17);
      let data = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        to_x: intFromBytes(event.slice(9, 13)),
        to_y: intFromBytes(event.slice(13, 17)),
      }
      MissileDefense(data)
    }
  }

  if (msgBytes[0] === 28) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      PlaneMine(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)),)
    }
  }

  if (msgBytes[0] === 29) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      let pos = GetGlobalPos(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), gameStore.map.id);
      PlayPositionSound(['harpoon'], null, pos.x, pos.y, false, 0.5);
    }
  }

  if (msgBytes[0] === 31) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      Jump(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)),)
    }
  }

  if (msgBytes[0] === 32) {
    for (let i = 0; i < msgBytes.length; i += 6) {
      let event = msgBytes.slice(i, i + 6);
      Afterburner(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 6)))
    }
  }

  if (msgBytes[0] === 33) {
    for (let i = 0; i < msgBytes.length; i += 5) {
      let event = msgBytes.slice(i, i + 5);
      Invisibility(intFromBytes(event.slice(1, 5)))
    }
  }

  if (msgBytes[0] === 34) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      ActiveInvisibility(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)))
    }
  }

  if (msgBytes[0] === 35) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      DeactivateInvisibility(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)),)
    }
  }

  if (msgBytes[0] === 36) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      SuicideProgress(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)))
    }
  }

  if (msgBytes[0] === 37) {
    for (let i = 0; i < msgBytes.length; i += 5) {
      let event = msgBytes.slice(i, i + 5);
      Suicide(intFromBytes(event.slice(1, 5)))
    }
  }

  if (msgBytes[0] === 39) {
    for (let i = 0; i < msgBytes.length; i += 14) {
      let event = msgBytes.slice(i, i + 14);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        t: MarksTypes[intFromBytes(event.slice(13, 14))],
      }

      CreateMark(msg.id, msg.t, msg.x, msg.y)
    }
  }

  if (msgBytes[0] === 40) {
    for (let i = 0; i < msgBytes.length; i += 5) {
      let event = msgBytes.slice(i, i + 5);
      RemoveMark(intFromBytes(event.slice(1, 5)))
    }
  }

  if (msgBytes[0] === 41) {
    for (let i = 0; i < msgBytes.length; i += 6) {
      let event = msgBytes.slice(i, i + 6);

      let mark = {id: intFromBytes(event.slice(1, 5)), to: gameStore.mapBinItems[intFromBytes(event.slice(5, 6))]}
      RemoveRadarObject(mark)
    }
  }

  if (msgBytes[0] === 42) {
    let stopByte = 1
    for (; stopByte < msgBytes.length;) {

      let typeObj = gameStore.mapBinItems[intFromBytes(msgBytes.slice(stopByte, stopByte + 1))]
      stopByte = 1 + stopByte;

      let subData = intFromBytes(msgBytes.slice(stopByte, stopByte + 4))
      stopByte = 4 + stopByte;

      CreateRadarObject(typeObj, msgBytes.subarray(stopByte, stopByte + subData))

      stopByte = subData + stopByte + 1;
    }
  }

  if (msgBytes[0] === 43) {
    let stopByte = 1
    for (; stopByte < msgBytes.length;) {

      let typeObj = gameStore.mapBinItems[intFromBytes(msgBytes.slice(stopByte, stopByte + 1))]
      stopByte = 1 + stopByte;

      let idObj = intFromBytes(msgBytes.slice(stopByte, stopByte + 4))
      stopByte = 4 + stopByte;

      let subData = intFromBytes(msgBytes.slice(stopByte, stopByte + 4))
      stopByte = 4 + stopByte;

      let data = msgBytes.subarray(stopByte, stopByte + subData)

      if (data.length > 0) {
        UpdateObject(typeObj, idObj, data)
      }

      stopByte = subData + stopByte + 1;
    }
  }

  if (msgBytes[0] === 44) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      let data = {
        x: intFromBytes(event.slice(1, 5)), y: intFromBytes(event.slice(5, 9)), m: intFromBytes(event.slice(9, 13))
      }
      let pos = GetGlobalPos(data.x, data.y, data.m);
      PlayPositionSound(['capsule_flight'], null, pos.x, pos.y);
    }
  }

  if (msgBytes[0] === 45) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      let data = {
        x: intFromBytes(event.slice(1, 5)), y: intFromBytes(event.slice(5, 9)), m: intFromBytes(event.slice(9, 13))
      }
      let pos = GetGlobalPos(data.x, data.y, data.m);
      PlayPositionSound(['drone_activation'], null, pos.x, pos.y, false, 0.3);
    }
  }

  if (msgBytes[0] === 46) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      let data = {
        x: intFromBytes(event.slice(1, 5)), y: intFromBytes(event.slice(5, 9)), m: intFromBytes(event.slice(9, 13))
      }
      let pos = GetGlobalPos(data.x, data.y, data.m);
      PlayPositionSound(['vehicle_crash'], null, pos.x, pos.y, false, 0.05);
    }
  }

  if (msgBytes[0] === 52) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      ZoneHealAnimate(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)))
    }
  }

  if (msgBytes[0] === 53) {
    let stopByte = 1
    for (; stopByte < msgBytes.length;) {

      let points = []
      let lengthPoints = intFromBytes(msgBytes.slice(stopByte, stopByte + 1))
      stopByte = 1 + stopByte;

      for (let i = 0; i < lengthPoints; i++) {
        points.push({
          x: intFromBytes(msgBytes.slice(stopByte, stopByte + 4)),
          y: intFromBytes(msgBytes.slice(stopByte + 4, stopByte + 8)),
        })
        stopByte = stopByte + 8;
      }

      RopeRender(points)
    }
  }

  if (msgBytes[0] === 54) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      MovePoint(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)))
    }

    AllRopeRender()
  }

  if (msgBytes[0] === 55) {
    let anomalies = [];
    let anomaliesBin = msgBytes.subarray(1)

    for (; anomaliesBin.length > 0;) {
      anomalies.push({
        rotate: intFromBytes(anomaliesBin.slice(0, 4)),
        type: intFromBytes(anomaliesBin.slice(4, 5)),
        signal: intFromBytes(anomaliesBin.slice(5, 6)),
      })
      anomaliesBin = anomaliesBin.subarray(6)
    }

    if (!document.getElementById('anomalyScannerWrapper')) {
      store.commit({
        type: 'toggleWindow',
        id: 'anomalyScannerWrapper',
        component: 'anomalyScannerWrapper',
        forceOpen: true,
      });
    }

    store.commit({
      type: 'setAnomalies',
      anomalies: anomalies,
    });
  }

  if (msgBytes[0] === 56) {
    for (let i = 0; i < msgBytes.length; i += 29) {
      let event = msgBytes.slice(i, i + 29);
      TransportBlock(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)),
        intFromBytes(event.slice(13, 17)), intFromBytes(event.slice(17, 21)), intFromBytes(event.slice(21, 25)),
        intFromBytes(event.slice(25, 29)))
    }
  }

  if (msgBytes[0] === 57) {
    // [1[eventID], membersData[]]
    let state = {}; //[user_id] member_state

    let memberState = msgBytes.subarray(1)

    for (; memberState.length > 0;) {
      state[intFromBytes(memberState.slice(0, 4))] = {
        u_id: intFromBytes(memberState.slice(4, 8)),
        on: intFromBytes(memberState.slice(8, 9)),
        hp: intFromBytes(memberState.slice(9, 13)),
        mhp: intFromBytes(memberState.slice(13, 17)),
        pw: intFromBytes(memberState.slice(17, 21)),
        mpw: intFromBytes(memberState.slice(21, 25)),
        rv: intFromBytes(memberState.slice(25, 29)),
        rr: intFromBytes(memberState.slice(29, 33)),
        map_id: intFromBytes(memberState.slice(33, 37)),
        base_id: intFromBytes(memberState.slice(37, 41)),
      }

      memberState = memberState.subarray(41)
    }

    gameStore.groupState.state = state
    if (gameStore.groupState.members.length !== Object.keys(state).length) {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetGroupState",
        }
      }));
    }

    // обновляем жетончики на мини карте если они изменились
    for (let i in gameStore.groupState.state) {

      if (gameStore.playerID === Number(i)) {
        continue;
      }

      let unit = gameStore.units[gameStore.groupState.state[i].u_id];
      if (unit && unit.sprite && unit.sprite.RadarMark && unit.sprite.RadarMark.markColor !== 0x00c6ff) {
        unit.sprite.RadarMark.destroy()
        unit.sprite.RadarMark = null;
      }
    }

    UpdateGroupPlayer()
  }

  if (msgBytes[0] === 58) {
    // [1[eventID], 1[source_type], 4[source_id] slots_data]
    let sourceType = SourceItemBin[msgBytes.slice(1, 2)];
    let sourceID = intFromBytes(msgBytes.slice(2, 6));
    let size = intFromBytes(msgBytes.slice(6, 10));
    let cap = intFromBytes(msgBytes.slice(10, 14));
    let forceOpen = intFromBytes(msgBytes.slice(14, 15)) === 1;
    let objectOwner = intFromBytes(msgBytes.slice(15, 16)) === 1;
    let remote = intFromBytes(msgBytes.slice(16, 17)) === 1;

    let source = sourceType

    if (sourceID !== 0) {
      source = sourceType + ":" + sourceID
    }

    let slotsData = msgBytes.subarray(17)
    let slots = {}

    for (; slotsData.length > 0;) {
      // slots_data [4[quantity], 1[type], 4[item_id], 4[hp], 4[max_hp], 4[size], 4[number], 4[access_user_id], 1[infinite], 4[place_user_id], 4[tax], 1[find], 4[find_count],
      // 1[in_map], 1[item_type], 1[name_size], ???[name], 1[item_item_name_size], ???[item_item_name], 1[icon_size], ???[icon]]

      let itemNameSize = intFromBytes(slotsData.slice(46, 47))
      let itemName2Size = intFromBytes(slotsData.slice(47 + itemNameSize, 48 + itemNameSize))
      let iconSize = intFromBytes(slotsData.slice(48 + itemNameSize + itemName2Size, 49 + itemNameSize + itemName2Size))

      slots[intFromBytes(slotsData.slice(21, 25))] = {
        quantity: intFromBytes(slotsData.slice(0, 4)),
        type: ItemBinTypes[intFromBytes(slotsData.slice(4, 5))],
        item_id: intFromBytes(slotsData.slice(5, 9)),
        hp: intFromBytes(slotsData.slice(9, 13)),
        max_hp: intFromBytes(slotsData.slice(13, 17)),
        size: intFromBytes(slotsData.slice(17, 21)),
        number: intFromBytes(slotsData.slice(21, 25)),
        access_user_id: intFromBytes(slotsData.slice(25, 29)),
        infinite: Boolean(intFromBytes(slotsData.slice(29, 30))),
        place_user_id: intFromBytes(slotsData.slice(30, 34)),
        tax: intFromBytes(slotsData.slice(34, 38)),
        find: Boolean(intFromBytes(slotsData.slice(38, 39))),
        find_count: intFromBytes(slotsData.slice(39, 43)),
        item: {
          in_map: Boolean(intFromBytes(slotsData.slice(43, 44))),
          item_type: ItemBinTypes[intFromBytes(slotsData.slice(44, 45))],
          type_slot: intFromBytes(slotsData.slice(45, 46)),
          name: String.fromCharCode(...slotsData.slice(47, 47 + itemNameSize)),
          item_name: String.fromCodePoint(...slotsData.slice(48 + itemNameSize, 48 + itemNameSize + itemName2Size)),
          icon: String.fromCodePoint(...slotsData.slice(49 + itemNameSize + itemName2Size, 49 + itemNameSize + itemName2Size + iconSize)),
        }
      }

      slotsData = slotsData.subarray(49 + itemNameSize + itemName2Size + iconSize)
    }

    if (sourceType === "box") {
      store.commit({
        type: 'setInventoryToOpenObjects',
        id: sourceID,
        inventory: slots,
        size: size,
      });
    }

    store.commit({
      type: 'updateWarehouse',
      warehouse: source,
      capacity: cap,
      size: size,
      title: source,
      inventory: slots,
      object_owner: objectOwner,
      remote: remote,
      source_type: sourceType,
      source_id: sourceID,
    });

    if (forceOpen) {
      store.commit({
        type: 'setCurrentWarehouse',
        warehouse: source,
      });

      store.commit({
        type: 'toggleWindow',
        id: 'wrapperInventoryAndStorage',
        component: '',
        forceOpen: true,
        meta: {warehouse: source},
      });
    }

    getNewStateSlots()
  }

  if (msgBytes[0] === 59 || msgBytes[0] === 64) {
    let targets = {};
    let targetsBin = msgBytes.subarray(1)

    for (; targetsBin.length > 0;) {
      targets[intFromBytes(targetsBin.slice(0, 4))] = intFromBytes(targetsBin.slice(4, 5)) === 1
      targetsBin = targetsBin.subarray(5)
    }

    if (msgBytes[0] === 59) MiningTargets(targets)
  }

  if (msgBytes[0] === 60) {
    for (let i = 0; i < msgBytes.length; i += 22) {
      let event = msgBytes.slice(i, i + 22);
      let data = {
        unit_id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        to_x: intFromBytes(event.slice(13, 17)),
        to_y: intFromBytes(event.slice(17, 21)),
        crack: intFromBytes(event.slice(21, 22)) === 1,
      }

      Mining(data);
    }
  }

  if (msgBytes[0] === 65 || msgBytes[0] === 70) {
    for (let i = 0; i < msgBytes.length; i += 21) {
      let event = msgBytes.slice(i, i + 21);
      let data = {
        unit_id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        to_x: intFromBytes(event.slice(13, 17)),
        to_y: intFromBytes(event.slice(17, 21)),
      }

      if (msgBytes[0] === 65) BuildAnimate(data);
      if (msgBytes[0] === 70) RepairLine(data);
    }
  }

  if (msgBytes[0] === 61) {
    for (let i = 0; i < msgBytes.length; i += 6) {
      let event = msgBytes.slice(i, i + 6);
      let data = {
        id: intFromBytes(event.slice(1, 5)),
        progress: intFromBytes(event.slice(5, 6)),
      }

      let reservoir = gameStore.reservoirs[data.id];
      if (reservoir) {
        reservoir.progress = data.progress;
        CreateMapBar(reservoir.sprite, reservoir.complexity, reservoir.progress, 7, 0xffa009, Scene, 'reservoir', reservoir.id, 'progress', 10);
      }
    }
  }

  if (msgBytes[0] === 62) {
    for (let i = 0; i < msgBytes.length; i += 18) {
      let event = msgBytes.slice(i, i + 18);
      HitItem(intFromBytes(event.slice(1, 5)), ItemBinTypes[intFromBytes(event.slice(5, 6))],
        intFromBytes(event.slice(6, 10)), intFromBytes(event.slice(10, 14)), intFromBytes(event.slice(14, 18)))
    }
  }

  if (msgBytes[0] === 63) {
    for (let i = 0; i < msgBytes.length; i += 18) {
      let event = msgBytes.slice(i, i + 18);
      let data = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        r: intFromBytes(event.slice(9, 13))
      }

      DroneTarget(data)
    }
  }

  if (msgBytes[0] === 66) {
    for (let i = 0; i < msgBytes.length; i += 33) {
      let event = msgBytes.slice(i, i + 33);
      let data = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        r: intFromBytes(event.slice(9, 13)),
        ma: intFromBytes(event.slice(13, 17)),
        rd: intFromBytes(event.slice(17, 21)),
        cx: intFromBytes(event.slice(21, 25)),
        cy: intFromBytes(event.slice(25, 29)),
        crd: intFromBytes(event.slice(29, 33)),
      }
      GravityGunTarget(data)
    }
  }

  if (msgBytes[0] === 67) {
    for (let i = 0; i < msgBytes.length; i += 17) {
      let event = msgBytes.slice(i, i + 17);

      let data = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        r: intFromBytes(event.slice(9, 13)),
        rd: intFromBytes(event.slice(13, 17)),
      }

      GravityGunRun(data)
    }
  }

  if (msgBytes[0] === 68) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);

      let data = {
        x: intFromBytes(event.slice(1, 5)),
        y: intFromBytes(event.slice(5, 9)),
        r: intFromBytes(event.slice(9, 13)),
      }

      GravityGunDrop(data)
    }
  }

  if (msgBytes[0] === 69 || msgBytes[0] === 78) {
    let unitsTargets = {}
    let ObjectTargets = {}
    let boxTargets = {}

    let targetsBin = msgBytes.subarray(1)
    for (; targetsBin.length > 0;) {
      let t = gameStore.mapBinItems[intFromBytes(targetsBin.slice(0, 1))]
      if (t === 'unit') unitsTargets[intFromBytes(targetsBin.slice(1, 5))] = intFromBytes(targetsBin.slice(5, 6)) === 1
      if (t === 'box') boxTargets[intFromBytes(targetsBin.slice(1, 5))] = intFromBytes(targetsBin.slice(5, 6)) === 1
      if (t === 'object') ObjectTargets[intFromBytes(targetsBin.slice(1, 5))] = intFromBytes(targetsBin.slice(5, 6)) === 1
      targetsBin = targetsBin.subarray(6)
    }

    UnitsTargets(unitsTargets)
    // TODO BuilderTargets()
    BoxTargets(boxTargets)
  }

  if (msgBytes[0] === 71) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      PortalInto(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)));
    }
  }

  if (msgBytes[0] === 72) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      PortalOut(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)));
    }
  }

  if (msgBytes[0] === 73) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      DropExit(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)));
    }
  }

  if (msgBytes[0] === 74) {
    updateExistReady(intFromBytes(msgBytes.slice(1, 5)));
  }

  if (msgBytes[0] === 75) { // vio
    let vioBin = msgBytes.subarray(1)
    let data = {};
    for (; vioBin.length > 0;) {
      data[intFromBytes(vioBin.slice(0, 4))] = intFromBytes(vioBin.slice(4, 5))
      vioBin = vioBin.subarray(5)
    }

    gameStore.violators = data;
  }

  if (msgBytes[0] === 76) { // signal
    let signalData = msgBytes.subarray(1);
    let data = {};

    for (; signalData.length > 0;) {
      data[intFromBytes(signalData.slice(0, 4)) + ":" + intFromBytes(signalData.slice(4, 8))] = {
        x: intFromBytes(signalData.slice(0, 4)),
        y: intFromBytes(signalData.slice(4, 8)),
      }
      signalData = signalData.subarray(8)
    }

    gameStore.distressSignals = data;
    updateSignals()
  }

  if (msgBytes[0] === 77) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      PumpAnimate(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)));
    }
  }

  if (msgBytes[0] === 79) {
    DrawText("Цель не удалось просканировать!", 0xFFFFFF, true)
  }

  if (msgBytes[0] === 80) {
    DrawText("Обнаружено сканирование!", 0xFF8100)
  }

  if (msgBytes[0] === 81) {
    for (let i = 0; i < msgBytes.length; i += 17) {
      let event = msgBytes.slice(i, i + 17);
      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        r: intFromBytes(event.slice(5, 9)),
        ms: intFromBytes(event.slice(9, 13)),
        s: intFromBytes(event.slice(13, 17)),
      }

      RotateObjectEquip(msg)
    }
  }

  if (msgBytes[0] === 82) {
    gameStore.sectorWarState.maxPoints = intFromBytes(msgBytes.slice(1, 5))
    gameStore.sectorWarState.owner = fractionByte[intFromBytes(msgBytes.slice(5, 6))]
    gameStore.sectorWarState.phase = intFromBytes(msgBytes.slice(6, 7)) === 1

    let lenFractionPoints = intFromBytes(msgBytes.slice(7, 8))
    gameStore.sectorWarState.fractionPoints = [];
    let bases = [];

    let stopByte = 8;
    for (let i = 0; i < lenFractionPoints; i++) {
      gameStore.sectorWarState.fractionPoints.push({
        fraction: fractionByte[intFromBytes(msgBytes.slice(stopByte, stopByte + 1))],
        points: intFromBytes(msgBytes.slice(stopByte + 1, stopByte + 1 + 4))
      })
      stopByte = stopByte + 1 + 4
    }

    let lenBases = intFromBytes(msgBytes.slice(stopByte, stopByte + 1))
    stopByte += 1

    for (let i = 0; i < lenBases; i++) {
      let id = intFromBytes(msgBytes.slice(stopByte, stopByte + 4))
      bases.push({
        id: id,
        capture_team: fractionByte[intFromBytes(msgBytes.slice(stopByte + 4, stopByte + 5))],
        capture: intFromBytes(msgBytes.slice(stopByte + 5, stopByte + 6)),
        capture_fact: intFromBytes(msgBytes.slice(stopByte + 6, stopByte + 7)) === 1,
      })
      stopByte = stopByte + 7
    }

    for (let updateBase of bases) {
      for (let i in gameStore.sectorWarState.bases) {
        if (updateBase.id === gameStore.sectorWarState.bases[i].id) {
          gameStore.sectorWarState.bases[i].capture_team = updateBase.capture_team
          gameStore.sectorWarState.bases[i].capture = updateBase.capture
          gameStore.sectorWarState.bases[i].capture_fact = updateBase.capture_fact
        }
      }
    }

    gameStore.sectorWarState.fractionPoints.sort(function (a, b) {
      return b.points - a.points;
    });

    updateBattleData()
  }

  if (msgBytes[0] === 83) {
    for (let i = 0; i < msgBytes.length; i += 6) {
      let event = msgBytes.slice(i, i + 6);
      let msg = {
        t: intFromBytes(event.slice(1, 2)),
        c: intFromBytes(event.slice(2, 6)),
      }

      addPointNotify(msg)
    }
  }

  if (msgBytes[0] === 84) {
    let subArray = msgBytes
    for (; subArray.length > 0;) {
      let deadNameSize = intFromBytes(subArray.slice(7, 8))
      let killNameSize = intFromBytes(subArray.slice(8 + deadNameSize, 9 + deadNameSize))

      let msg = {
        ammo_id: intFromBytes(subArray.slice(1, 5)),
        dead_fraction: intFromBytes(subArray.slice(5, 6)),
        killer_fraction: intFromBytes(subArray.slice(6, 7)),
        dead_name: new TextDecoder().decode(new Uint8Array(subArray.slice(8, 8 + deadNameSize))),
        killer_name: new TextDecoder().decode(new Uint8Array(subArray.slice(9 + deadNameSize, 9 + deadNameSize + killNameSize))),
      }

      addKill(msg)
      subArray = subArray.subarray(22 + deadNameSize + killNameSize)
    }
  }

  if (msgBytes[0] === 85) {
    updateVioStatus(intFromBytes(msgBytes.slice(1, 5)), intFromBytes(msgBytes.slice(5, 6)), intFromBytes(msgBytes.slice(6, 7)));
  }

  if (msgBytes[0] === 86) {
    updateRank(intFromBytes(msgBytes.slice(1, 2)) === 1);
  }

  if (msgBytes[0] === 87) {
    calcPing(byteArrayToLong(msgBytes.slice(1, 9)))
  }

  if (msgBytes[0] === 88) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      DrawAnomaly(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 9)), intFromBytes(event.slice(9, 13)))
    }
  }

  if (msgBytes[0] === 89) {
    for (let i = 0; i < msgBytes.length; i += 6) {
      let event = msgBytes.slice(i, i + 6);
      let obj = gameStore.objects[intFromBytes(event.slice(1, 5))]
      if (obj) {
        obj.work = intFromBytes(event.slice(5, 6)) !== 1;
        NoCompleteStructure(obj);
      }
    }
  }

  if (msgBytes[0] === 90) {
    gameStore.sectorSiegeState.active = intFromBytes(msgBytes.slice(1, 2)) === 1;
    gameStore.sectorSiegeState.endTime = byteArrayToLong(msgBytes.slice(2, 10));
    gameStore.sectorSiegeState.hp = intFromBytes(msgBytes.slice(10, 14));
    gameStore.sectorSiegeState.shield = intFromBytes(msgBytes.slice(14, 18));

    for (let i = 18; i < msgBytes.length; i += 8) {
      let event = msgBytes.slice(i, i + 8);
      gameStore.sectorSiegeState.hostiles[intFromBytes(event.slice(0, 4))] = intFromBytes(event.slice(4, 8))
    }

    updateSiegeData()
  }

  if (msgBytes[0] === 91) {
    RunGlitch(intFromBytes(msgBytes.slice(1, 5)))
  }

  if (msgBytes[0] === 92) {
    TargetInfo(msgBytes)
  }

  if (msgBytes[0] === 93) {
    SetGameTime(intFromBytes(msgBytes.slice(1, 2)), intFromBytes(msgBytes.slice(2, 3)))
  }

  if (msgBytes[0] === 94) {
    for (let i = 0; i < msgBytes.length; i += 9) {
      let event = msgBytes.slice(i, i + 9);
      let unitID = intFromBytes(event.slice(1, 5))
      let decal = {
        x: intFromBytes(event.slice(5, 6)),
        y: intFromBytes(event.slice(6, 7)),
        id: intFromBytes(event.slice(7, 8)),
        angle: intFromBytes(event.slice(8, 9)),
      }

      AddDecal(gameStore.units[unitID], decal, true)
    }
  }

  if (msgBytes[0] === 95) {
    for (let i = 0; i < msgBytes.length; i += 2) {
      let event = msgBytes.slice(i, i + 2);
      setUnrepairableDamage(intFromBytes(event.slice(1, 2)))
    }
  }

  if (msgBytes[0] === 96) {
    for (let i = 0; i < msgBytes.length; i += 14) {
      let event = msgBytes.slice(i, i + 14);
      shieldAnimate(intFromBytes(event.slice(1, 5)), intFromBytes(event.slice(5, 6)), intFromBytes(event.slice(6, 10)), intFromBytes(event.slice(10, 14)))
    }
  }

  if (msgBytes[0] === 97) {
    for (let i = 0; i < msgBytes.length; i += 5) {
      let event = msgBytes.slice(i, i + 5);
      shieldOn(intFromBytes(event.slice(1, 5)))
    }
  }

  if (msgBytes[0] === 98) {
    for (let i = 0; i < msgBytes.length; i += 13) {
      let event = msgBytes.slice(i, i + 13);
      PumpMiniGame(
        intFromBytes(event.slice(1, 2)),
        intFromBytes(event.slice(2, 6)),
        intFromBytes(event.slice(6, 7)),
        intFromBytes(event.slice(7, 11)),
        intFromBytes(event.slice(11, 12)) === 1,
        intFromBytes(event.slice(12, 13)) === 1)
    }
  }

  if (msgBytes[0] === 99 && intFromBytes(msgBytes.slice(1, 2)) === 1) {
    store.commit({
      type: 'setPlayerRole',
      role: 'Spectrum',
    });
  }

  if (msgBytes[0] === 101) {
    let targets = {};
    let targetsBin = msgBytes.subarray(1)

    for (; targetsBin.length > 0;) {
      targets[intFromBytes(targetsBin.slice(0, 4))] = intFromBytes(targetsBin.slice(4, 5)) === 1
      targetsBin = targetsBin.subarray(5)
    }

    CombineTargets(targets)
  }

  if (msgBytes[0] === 102) {
    for (let i = 0; i < msgBytes.length; i += 21) {
      let event = msgBytes.slice(i, i + 21);

      let msg = {
        id: intFromBytes(event.slice(1, 5)),
        x: intFromBytes(event.slice(5, 9)),
        y: intFromBytes(event.slice(9, 13)),
        z: intFromBytes(event.slice(13, 17)),
        r: intFromBytes(event.slice(17, 21)),
      }

      AddPollenMoveBufferData(msg)
    }
  }
}

export {BinaryReader}
