import {gameStore} from "../store";
import {FocusMS} from "../interface/focus_unit";
import {Scene} from "../create";
import {FlyLaser} from "../bullet/laser";
import {GetGlobalPos} from "../map/gep_global_pos";
import store from "../../store/store";
import {TwoCutscene} from "./two";

const timeTick = 64

function CutsceneRouter(data) {
  if (!gameStore.gameReady || gameStore.cutScene.active) {
    return
  }

  gameStore.cutScene.active = true

  if (data.id === 1) OneCutscene(data);
  if (data.id === 2) TwoCutscene(data);
}

function OneCutscene(data) {

  // zoom
  Scene.cameras.main.setZoom(1.25);
  store.dispatch('changeSettings', {
    name: "ZoomCamera",
    count: 1.25 * 100
  });

  store.commit({
    type: 'setZoomCamera',
    zoom: 1.25,
  });

  // сначала мы переводим камеру на игрока где бы она не была
  gameStore.gameSettings.follow_camera = true
  store.dispatch('changeSettings', {
    name: "FollowCamera",
    count: true
  });

  store.commit({
    type: 'setFollowCamera',
    follow: true
  });

  FocusMS()

  let unit
  for (let i in gameStore.units) {
    if (gameStore.playerID === gameStore.units[i].owner_id) {
      unit = gameStore.units[i]
      break
    }
  }

  if (!unit || !unit.sprite) {
    return
  }

  // зажигаем постеменно 3 шара по данным позиции
  let lights = []
  for (let c of data.config.positions) {
    let pos = GetGlobalPos(c.x, c.y, gameStore.map.id);

    let light = Scene.add.pointlight(pos.x, pos.y, 0, c.radius, 0.75);
    light.attenuation = 0.001
    light.setDepth(900)

    light.color.setTo(179, 220, 253);
    lights.push(light)
  }

  Scene.tweens.add({
    targets: lights,
    duration: 3000,
    attenuation: 0.1,
    onComplete: function () {
      // todo звук активации какойнить сайфай хуйни
      // врубаем лазерные лучи от позиции в юнита
      Scene.time.addEvent({
        delay: timeTick,
        repeat: 10000 / timeTick,
        callback: function () {
          for (let c of data.config.positions) {

            let pos = GetGlobalPos(c.x, c.y, gameStore.map.id);

            let data = {
              to_x: pos.x, // random
              to_y: pos.y,
              x: unit.sprite.x,
              y: unit.sprite.y,
            }

            FlyLaser(data, 'intro_1')
          }
        },
        loop: false,
      });
    }
  });

  // врубаем трейлер
  Scene.time.addEvent({
    delay: 6000,
    callback: function () {
      store.commit({
        type: 'setTrailer',
        open: true,
        sound: "intro_1.mp3",
        url: './assets/movies/intro_1.mp4',
        glitchOff: true,
        mission_uuid: data.mission_uuid, //   завершаем экшон после просмотра
        action_number: data.action_number,
      });
    }
  })

  Scene.time.addEvent({
    delay: 10000,
    callback: function () {
      for (let l of lights) {
        l.destroy();
      }
    }
  })
}

export {CutsceneRouter}
