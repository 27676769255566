var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: топливо")]),_vm._v(" "),_c('p',[_vm._v("Транспорту необходимо топливо что бы использовать все свои возможности. Как работает топливо можно почитать на\n      этой\n      "),_c('router-link',{attrs:{"to":"fuel"}},[_vm._v("странице")]),_vm._v("\n      .\n    ")],1),_vm._v(" "),_c('p',[_vm._v("Что бы установить топливо перетащите его на корпус или ячейку.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Остатки топлива надо погрузить в инвентарь с названием \"топливо\", когда кончится энергия у корпуса то он\n      автоматически начнет использовать топливо из этого инвентаря.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_2', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Fuel")]),_vm._v(" "),_c('p',[_vm._v("The vehicle needs fuel to use all its capabilities. You can read how the fuel works on\n      "),_c('router-link',{attrs:{"to":"fuel"}},[_vm._v("this page")]),_vm._v("\n      .\n    ")],1),_vm._v(" "),_c('p',[_vm._v("What is needed to install the fuel is to drag it onto the hull or cell.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The remaining fuel should be loaded into the inventory called «fuel», when the hull runs out of energy, it\n      will automatically start using fuel from this inventory.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_fuel_2', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }