<template>
  <div class="out" :style="addStyle">
    <div class="mask"/>

    <div class="wrapper">
      <div>

        <div class="title_row">{{ texts['text_24'][language] }}</div>

        <div class="items">
          <app-item-cell v-for="slot in throwItems.items"
                         v-bind:size="41"
                         v-bind:itemSlot="slot"/>
        </div>

        <input type="button"
               :value="texts['button_23'][language]"
               class="button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="close">
        <input type="button"
               :value="texts['button_29'][language]"
               class="button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="destroyItems"
               style="right: 5px; left: unset;">
      </div>
    </div>
  </div>
</template>

<script>
import ItemCell from '../Inventory/ItemCell';

export default {
  name: "ThrowItemsDialog",
  props: ["addStyle"],
  data() {
    return {}
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    close() {
      this.$store.commit({
        type: 'setThrowItemsState',
        open: false,
        slots: {},
        items: {},
      });
    },
    destroyItems() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "ThrowItems",
          storage_slots: this.throwItems.slots,
          source: this.throwItems.source,
        }
      }));

      this.close();
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    throwItems() {
      return this.$store.getters.getThrowItemsState
    }
  },
  components: {
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.out {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  pointer-events: auto;
  left: 0;
  top: 0;
}

.wrapper {
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 138px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  padding: 0 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  backdrop-filter: blur(4px);
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.title_row {
  float: left;
  font-size: 11px;
  color: bisque;
  line-height: 15px;
  text-shadow: 1px 1px 1px black;
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 3px);
  text-align: center;
  padding: 1px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}

.items {
  margin: 0 auto 0;
  overflow-y: scroll;
  height: 98px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  overflow-x: hidden;
  box-shadow: inset 0 0 2px black;
  backdrop-filter: blur(12px);
  float: left;
  width: 100%;
}
</style>
