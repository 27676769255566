<template>
  <div id="lobbyWrapper" v-if="baseStatus.state" :style="{backgroundImage:  'url(' + baseBackground + ')'}">
    <!--  TODO рефакторинг требует наши сердца-->
    <div class="RankWrapper">
      <app-session-battle-rank/>
    </div>

    <div id="displayNotify">
      <div id="sector_under_attack" v-if="baseStatus.siege">
        <div class="displayNotifyText">{{ texts['text_4'][language] }}</div>
      </div>
    </div>

    <template>

      <app-training/>

      <div id="ServiceTable">

        <div class="texture_wrapper"
             :style="{
          backgroundImage: 'url(' + require('../../assets/interface/frame_3.png').default + ')',
          top: '-28px',
          left:'-67px'}"/>

        <div class="head">
          <div class="base_icon" :style="{backgroundImage: 'url(' + baseIcon + ')'}">
            <div class="base_type_icon" :style="{backgroundImage: 'url(' + baseTypeIcon + ')'}"/>
          </div>
          <h3 class="base_name">
            <span style="float: left">{{ getBaseName(baseStatus.state.name) }}</span>
          </h3>

          <div class="section">
            <div class="fractionLogo" v-if="baseStatus.fraction !== ''"
                 :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(baseStatus.fraction)).default+')'}"/>

            <template v-if="baseStatus.state.type !== 'corporation'">
              <span style="line-height: 18px;">{{ texts['text_5'][language] }}:</span> <br>
              <span class="importantly">{{ baseFraction }}</span>
            </template>

            <template v-if="baseStatus.state.type === 'corporation'">
              <span style="line-height: 18px;">{{ texts['text_6'][language] }}:</span> <br>
              <span class="importantly">{{ corporation.info ? corporation.info.name : "" }}</span>
            </template>
          </div>

        </div>

        <div class="sub" @click.stop="">
          <div @mouseover="playSound('select_sound.mp3', 0.3)" id="OutBaseButton"
               :class="{select: selectExitBase}"
               class="cursor_hover"
               @click.stop="openService('ExitMenu')">
            <div class="image">
              <div :style="{backgroundImage: 'url(' + require('../../assets/icons/exit.png').default + ')'}"></div>
            </div>
            <h4>{{ texts['menu_1'][language] }} </h4>
          </div>

          <div @mouseover="playSound('select_sound.mp3', 0.3)" v-if="false"
               @click.stop="">
            <div class="image">
              <div :style="{backgroundImage: 'url(' + require('../../assets/icons/exit.png').default + ')'}"></div>
            </div>
            <h4>{{ texts['menu_2'][language] }}</h4>
          </div>

          <div @mouseover="playSound('select_sound.mp3', 0.3)" v-if="false"
               class="cursor_hover"
               @click.stop="openService('MiniGame')">
            <div class="image">
              <div :style="{backgroundImage: 'url(' + require('../../assets/icons/exit.png').default + ')'}"></div>
            </div>
            <h4>{{ texts['menu_3'][language] }}</h4>
          </div>

        </div>

        <template v-if="baseStatus.state.type !== 'quest_base'">
          <h2 class="head_category">{{ texts['menu_4'][language] }}: </h2>
          <div class="sub" @click.stop="">

            <div @mouseover="playSound('select_sound.mp3', 0.3)" id="hangarButton"
                 :class="{select: selectHangar}"
                 class="cursor_hover"
                 @click.stop="openService('inventoryBox')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/category_bodies.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_5'][language] }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Hangar'})"></div>
            </div>

            <div @mouseover="playSound('select_sound.mp3', 0.3)" id="inventoryButton"
                 :class="{select: selectInventory}"
                 class="cursor_hover"
                 @click.stop="openService('wrapperInventoryAndStorage')">
              <div class="image">
                <div :style="{backgroundImage: 'url(' + require('../../assets/icons/store.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_6'][language] }} </h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Storage'})"></div>
            </div>

            <div @mouseover="playSound('select_sound.mp3', 0.3)"
                 v-if="openMarket"
                 class="cursor_hover"
                 @click.stop="openService('marketBox')">
              <div class="image">
                <div :style="{backgroundImage: 'url(' + require('../../assets/icons/market.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_7'][language] }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Market'})"></div>
            </div>

            <div @mouseover="playSound('select_sound.mp3', 0.3)"
                 v-if="baseStatus.state.type !== 'corporation'"
                 class="cursor_hover"
                 @click.stop="openService('NPCTrade')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/npc_trade_icon.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_8'][language] }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'NPCTrade'})"></div>
            </div>

            <div
              v-if="baseStatus.fraction === currentPlayer.fraction && (baseStatus.state.type === 'replic_bunker' || baseStatus.state.type === 'reverses_terraforming_generator' || baseStatus.state.type === 'explores_science_center')"
              @mouseover="playSound('select_sound.mp3', 0.3)"
              class="cursor_hover"
              @click.stop="openService('FractionMarket')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/uniq_base_icon.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_9'][language] }}</h4>
              <div class="help_point_menu"
                   @click.stop="openService('helpWrapper', {component: 'FractionMarket'})"></div>
            </div>
          </div>
        </template>

        <template
          v-if="(baseStatus.state.type !=='not_work' && baseStatus.state.type !== 'hostile') &&
          (baseStatus.state.type === 'lobby' || baseStatus.state.type === 'processing_plant' ||
          baseStatus.state.type === 'base' || baseStatus.state.type === 'prefabricated_plant' ||
          (baseStatus.state.type === 'corporation' && (openProcessing || openWorkbench || openPrefabricated)))">

          <h2 class="head_category">{{ texts['menu_10'][language] }}: </h2>

          <div class="sub" @click.stop="">

            <div v-if="openProcessing"
                 :class="{select: selectProcessRoot}"
                 class="cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click.stop="openService('processorRoot')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/processing_plant_icon.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_11'][language] }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Processing'})"></div>
            </div>

            <div v-if="openWorkbench"
                 class="cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click.stop="openService('Workbench')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/productions.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_12'][language] }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'CraftItems'})"></div>
            </div>

            <div v-if="openPrefabricated"
                 class="cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click.stop="openService('prefabricatedMenuWrapper')">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/category_details.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_13'][language] }}</h4>
              <div class="help_point_menu"
                   @click.stop="openService('helpWrapper', {component: 'PartsProduction'})"></div>
            </div>

          </div>
        </template>

        <template
          v-if="baseStatus.state.type !== 'lobby' && (baseStatus.state.type !=='not_work' && baseStatus.state.type !== 'hostile')">
          <h2 class="head_category" v-if="baseStatus.state.type !== 'quest_base'">
            {{ texts['menu_14'][language] }}: </h2>
          <div class="sub" @click.stop="">

            <div id="DepartmentOfEmploymentButton"
                 :class="{select: selectTerminal}"
                 v-if="baseStatus.state.type !== 'corporation'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="cursor_hover"
                 @click.stop="openBaseDepartment">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/terminal_icon.png').default + ')'}"></div>
              </div>
              <h4>{{ terminalName }}</h4>
              <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Missions'})"></div>
            </div>

            <div id="News"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="cursor_hover"
                 @click.stop="openService('News')"
                 v-if="baseStatus.state.type !== 'quest_base'">
              <div class="image">
                <div
                  :style="{backgroundImage: 'url(' + require('../../assets/icons/news_icon.png').default + ')'}"></div>
              </div>
              <h4>{{ texts['menu_16'][language] }}</h4>
            </div>

            <!--            <div v-for="story in baseStatus.stories"-->
            <!--                 @mouseover="playSound('select_sound.mp3', 0.3)"-->
            <!--                 @click.stop="openStory(story.id)">-->
            <!--              <div class="image">-->
            <!--                <div :style="{backgroundImage: 'url(' + require('../../assets/icons/cancelIcon.png') + ')'}"></div>-->
            <!--              </div>-->
            <!--              <h4>{{ story.name }}</h4>-->
            <!--            </div>-->
          </div>
        </template>
      </div>

      <div id="OutDialog" v-if="baseStatus.exit">
        <h3>{{ texts['text_7'][language] }}!</h3>
        <div>
          <div class="arrow">
            <span/>
            <span/>
            <span/>
          </div>
        </div>
      </div>

      <template v-if="(baseStatus.state.type !=='not_work' && baseStatus.state.type !== 'hostile')">
        <app-base-status v-bind:logo="baseStatus.state.type === 'corporation' ? this.corporation.logo : false"/>
      </template>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import BaseStatus from './BaseStatus'
import ExitMenu from './ExitMenu'
import SessionBattleRank from './SessionBattleRank'
import {gameStore} from "../../game/store";
import Training from '../Training/Training'
import Loader from '../Loader/Loader'
import {RemoveOldMap} from "../../game/map/remove_old_map";
import {urls} from "../../const";

export default {
  name: "Lobby",
  data() {
    return {
      sub: '',
      corporation: {},
    }
  },
  mounted() {

    let app = this;
    gameStore.reload = true;
    gameStore.unitReady = false;
    gameStore.gameReady = false;
    gameStore.gameDataInit.sendRequest = false;

    RemoveOldMap();

    app.$store.commit({
      type: 'setPlaceDialog',
      data: false,
    });

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "lobby",
      data: {
        event: "GetBaseStatus",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "chat",
      data: {
        event: "OpenChat",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "CheckInBase",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetBattleRank",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "market",
      data: {
        event: "GetCredits",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporation",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetAllCorporationOffices",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetContacts",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "grb",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetContacts",
      }
    }));

    app.$store.commit({
      type: 'closeNodeWS'
    });

    // app.$store.commit({
    //   type: 'setVisibleLoader',
    //   visible: true,
    //   text: {
    //     'RU': '<span>Получаем информацию...</span>',
    //     'EN': '<span>We are gathering information...</span>',
    //   }
    // });
  },
  methods: {
    getLogo(fraction) {
      return `logo/${fraction}.png`
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    outBase() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "OutBase"
        }
      }));

      this.$store.commit({
        type: 'toggleWindow',
        id: 'ExitMenu',
        forceClose: true,
      });
    },
    openBaseDepartment() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "openDepartmentOfEmployment",
        }
      }));
    },
    openStory(storyID) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "openStory",
          id: Number(storyID),
        }
      }));
    },
    getBaseName(name) {
      if (this.handbook['base'][this.language][name]) {
        return this.handbook['base'][this.language][name].name
      }

      if (this.baseStatus.attr && this.baseStatus.attr.name && this.baseStatus.attr.name[this.language] !== "") {
        return this.baseStatus.attr.name[this.language]
      }

      if (this.baseStatus.state.type === "corporation") {
        return this.texts['text_8'][this.language]
      }

      return this.texts['text_9'][this.language]
    },
    getCorp(id) {
      let app = this;
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.corporation, 'info', response.data.corporation);
        app.getCorpLogo(id);
      });
    },
    getCorpLogo(id) {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.corporation, 'logo', response.data.avatar);
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Lobby')
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    baseStatus() {

      let baseStatus = this.$store.getters.getBaseStatus
      if (baseStatus && baseStatus.state && baseStatus.state.corporation_id > 0) {
        this.getCorp(baseStatus.state.corporation_id)
      }

      return baseStatus
    },
    openComponents() {
      return this.$store.getters.getNeedOpenComponents
    },
    openMarket() {
      if (!this.baseStatus.state) return;

      if (this.baseStatus.state.type === 'corporation') {
        return this.baseStatus.base_attr.hasOwnProperty('market') && this.baseStatus.base_attr.market
      }

      return true
    },
    openPrefabricated() {
      return this.$store.getters.openPrefabricated
    },
    openWorkbench() {
      return this.$store.getters.openWorkbench
    },
    openProcessing() {
      return this.$store.getters.openProcessing
    },
    terminalName() {
      if (this.baseStatus.attr && this.baseStatus.attr.terminal_name && this.baseStatus.attr.terminal_name[this.language] !== "") {
        return this.baseStatus.attr.terminal_name[this.language]
      }

      return this.texts['menu_15'][this.language]
    },
    baseFraction() {
      if (this.baseStatus.attr && this.baseStatus.attr.fraction && this.baseStatus.attr.fraction !== "") {
        return this.baseStatus.attr.fraction
      }

      if (this.baseStatus.fraction !== "") {
        return this.baseStatus.fraction
      }

      return `empty`
    },
    baseIcon() {
      try {
        if (this.baseStatus.attr && this.baseStatus.attr.icon && this.baseStatus.attr.icon !== "") {
          return require('../../assets/map/structures/icon/' + this.baseStatus.attr.icon + '.png').default
        }

        if (this.baseStatus.state.type === "replic_bunker") {
          return require('../../assets/map/structures/icon/replic_gauss_gun.png').default
        }

        if (this.baseStatus.state.type === "corporation") {
          return require('../../assets/map/structures/icon/' + this.baseStatus.state.sprite_texture + '.png').default
        }

        if (this.baseStatus.state.sprite_texture !== "") {
          return require('../../assets/map/objects/sector_structure/' + this.baseStatus.state.sprite_texture + '.png').default
        }

        return require('../../assets/icons/cancelIcon.png').default

      } catch (e) {
        return require('../../assets/icons/cancelIcon.png').default
      }
    },
    baseTypeIcon() {
      if (this.baseStatus.state.type === "replic_bunker" || this.baseStatus.state.type === "reverses_terraforming_generator" || this.baseStatus.state.type === "explores_science_center") {
        return require('../../assets/icons/uniq_base_icon.png').default
      }

      if (this.baseStatus.state.type === "base" || this.baseStatus.state.type === "corporation") {
        return require('../../assets/icons/base_icon.png').default
      }

      if (this.baseStatus.state.type === "mining_installation") {
        return require('../../assets/icons/mining_installation_icon.png').default
      }

      if (this.baseStatus.state.type === "processing_plant") {
        return require('../../assets/icons/processing_plant_icon.png').default
      }

      if (this.baseStatus.state.type === "prefabricated_plant") {
        return require('../../assets/icons/category_details.png').default
      }

      return require('../../assets/icons/cancelIcon.png').default
    },
    baseBackground() {
      let back = require("../../assets/bases/" + "base" + ".jpg").default

      if (this.baseStatus.state.type === 'processing_plant') {
        return require("../../assets/bases/processing_plant.jpg").default
      }

      if (this.baseStatus.state.type === 'mining_installation') {
        return require("../../assets/bases/mining_installation.jpg").default
      }

      if (this.baseStatus.state.type === 'prefabricated_plant') {
        return require("../../assets/bases/prefabricated_plant.jpg").default
      }

      if (this.baseStatus.attr && this.baseStatus.attr.background && this.baseStatus.attr.background !== "") {
        return require("../../assets/bases/" + this.baseStatus.attr.background.toLowerCase()).default
      }

      if (this.baseStatus.fraction !== "") {
        return require("../../assets/bases/" + this.baseStatus.fraction.toLowerCase() + ".jpg").default
      }

      return back
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectHangar() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "open_hangar_storage" || this.missions.currentAction.type_func_monitor === "install_weapon_and_ammo" || this.missions.currentAction.type_func_monitor === "set_geo_equip";
    },
    selectInventory() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      if (this.missions.currentAction.type_func_monitor === "extract_item" && this.baseStatus && this.baseStatus.state && this.baseStatus.state.id === this.missions.currentAction.base_id) {
        return true
      }

      return this.missions.currentAction.type_func_monitor === "open_hangar_storage" || this.missions.currentAction.type_func_monitor === "process_ore" || this.missions.currentAction.type_func_monitor === "install_weapon_and_ammo" || this.missions.currentAction.type_func_monitor === "set_geo_equip";
    },
    selectTerminal() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      if (this.baseStatus && this.baseStatus.state && this.baseStatus.state.id === this.missions.currentAction.base_id) {
        return this.missions.currentAction.type_func_monitor === "talk_with_base" || this.missions.currentAction.type_func_monitor === "get_base_mission";
      }

      return false;
    },
    selectExitBase() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "exit_base";
    },
    selectProcessRoot() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "process_ore";
    },
  },
  components: {
    AppTraining: Training,
    AppBaseStatus: BaseStatus,
    AppControl: Control,
    AppLoader: Loader,
    AppSessionBattleRank: SessionBattleRank,
  }
}
</script>

<style scoped>
#lobbyWrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  pointer-events: auto;
  position: fixed;
}

#ServiceTable {
  top: 80px;
  left: 40px;
  text-align: center;
  float: left;
  border-radius: 5px;
  position: relative;
  padding: 2px;
  width: 220px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

#dialogBase {
  pointer-events: auto;
  height: 80px;
  width: 267px;
  border: 1px solid rgb(37, 160, 225);
  background-size: 10px 2px;
  background-image: linear-gradient(1deg, rgba(33, 176, 255, 0.6), rgba(37, 160, 225, 0.6) 6px);
  position: absolute;
  border-radius: 5px;
  padding: 20px 2px 2px;
  left: calc(50% - 133px) !important;
  top: 40% !important;
}

#dialogBase #textWrapper {
  box-shadow: inset 0 0 5px black;
  background: #8cb3c7;
  border-radius: 5px;
  height: calc(100% - 42px);
  position: relative;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  padding: 10px;
  font-size: 15px;
}

#dialogBase input {
  margin: 2px auto;
  width: 100%;
  background: rgb(221, 112, 52);
  box-shadow: inset 0 0 4px 0 white;
  color: rgba(255, 255, 255, 0.8);
  display: block;
}

#dialogBase input:hover {
  box-shadow: inset 0 0 4px 0 #20fffd;
}

#dialogBase input:active {
  transform: scale(0.98);
}

#OutDialog {
  position: absolute;
  top: 50px;
  left: calc(50% - 150px);
  display: block;
  width: 300px;
  height: 100px;
  z-index: 999;
}

#OutDialog h3 {
  color: #ff8100;
  font-size: 11px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  margin: 8px 4px;
  padding: 5px 0;
  box-shadow: inset 0 0 2px 0 black;
  border-radius: 9px;
  border: 1px solid #25a0e1;
  background: rgb(8, 138, 210);
}

#OutDialog > div {
  height: 52px;
  width: 175px;
  margin: 0 auto;
  background: rgba(2, 2, 2, 0.2);
  padding-left: 0px;
  border-radius: 0 0 40% 40%;
  margin-top: -9px;
}

.arrow {
  width: 60px;
  height: 32px;
  margin-left: 14px;
  padding-top: 6px;
  transform: rotate(270deg) scale(0.9);
}

.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid rgb(222, 156, 0);
  border-right: 5px solid rgb(255, 204, 0);
  transform: rotate(45deg);
  margin: 5px;
  animation: animate 2s infinite;
  filter: drop-shadow(0 0 5px rgba(255, 250, 0, 1));
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.sub {
  min-height: 20px;
  min-width: 200px;
  border-radius: 5px;
  top: calc(100% + 8px);
  filter: drop-shadow(0px 0px 1px black);
  /*box-shadow: 0 0 2px black;*/
}

.sub > div {
  height: 28px;
  clear: both;
  background: #0cc2fb;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  margin: 1px;
  line-height: 35px;
  color: white;
  text-shadow: 1px 1px 1px black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  overflow: hidden;
  position: relative;
}

.sub > div .image {
  height: 28px;
  width: 31px;
  float: left;
  transition: 0.1s;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  pointer-events: none;
}

.sub > div .image > div {
  height: 28px;
  width: 28px;
  background-size: contain;
  background-position: center;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

.sub > div h4 {
  margin: 0 5px;
  float: left;
  opacity: 0.8;
  font-size: 14px;
  line-height: 28px;
  pointer-events: none;
}

.sub > div:hover {
  border: 1px solid rgba(37, 160, 225, 0.8);
  background: rgba(8, 138, 210, 0.8);
}

.sub > div:active {
  transform: scale(0.97);
}

.sub > div:hover h4 {
  opacity: 1;
}

.RankWrapper {
  position: absolute;
  top: 7px;
  left: 35px;
  width: 270px;
  background: none;
  box-shadow: none;
  z-index: 0;
}

.base_icon {
  height: 50px;
  width: 50px;
  float: left;
  margin: 1px 5px 5px 2px;
  background-size: contain;
  background-color: rgba(6, 110, 168, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(6, 110, 168, 0.6);
  box-shadow: inset 0 0 2px black;
}

.base_type_icon {
  height: 25px;
  width: 25px;
  background-size: contain;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
}

.base_name {
  margin: 0 0 7px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  float: right;
  width: calc(100% - 74px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.base_type {
  float: left;
  color: #e4ff00;
  font-size: 10px;
  margin-right: 4px;
}

.section {
  line-height: 8px;
  background: rgba(77, 77, 84, 0.3);
  width: 140px;
  height: 28px;
  border-radius: 0 5px 5px 0;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
  font-size: 11px;
  text-align: left;
}

.fractionLogo {
  height: 28px;
  width: 28px;
  float: left;
  margin: -1px 5px 5px -1px;
  background-size: cover;
  background-color: rgba(6, 110, 168, 0.2);
  border-radius: 5px;
  border: 1px solid rgba(6, 110, 168, 0.3);
  box-shadow: inset 0 0 2px black;
}

.importantly {
  color: #f1bd00;
  font-weight: 100;
}

.head_category {
  margin: 3px 0 3px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  text-align: left;
  clear: both;
}

.help_point_menu {
  height: 16px;
  width: 16px;
  /* background: black; */
  position: absolute;
  right: 3px;
  bottom: 2px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}

@media (max-width: 1000px) {
  .sub > div h4 {
    font-size: 14px;
    line-height: 24px;
  }

  .sub > div {
    height: 24px;
  }

  .sub > div .image {
    height: 24px;
    width: 27px;
    float: left;
    transition: 0.1s;
    border-right: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .sub > div .image > div {
    height: 24px;
    width: 24px;
    background-size: contain;
    background-position: center;
    filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
  }

  #ServiceTable {
    width: 202px;
  }

  .sub {
    min-height: 20px;
    filter: drop-shadow(0px 0px 2px black);
    box-shadow: 0 0 2px black;
  }

  .help_point_menu {
    height: 13px;
    width: 13px;
  }
}

.texture_wrapper {
  height: 260px;
  width: 260px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 89;
}

.select {
  box-shadow: 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: 0 0 2px 1px greenyellow;
  }
}

#displayNotify {
  position: absolute;
  width: 100%;
  pointer-events: none;
  top: 15%;
  text-align: center;
  height: 50px;
  color: rgba(243, 6, 6, 0.9);
  font-size: 50px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.displayNotifyText {
  animation: LowGravity 2s infinite;
}

@keyframes LowGravity {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}

</style>
