import {gameStore} from "../store";
import {Scene} from "../create";
import {statusBarWrapper} from "./status_bar";
import {getIconPath} from "./equip_bar";

let killBoard = {
  pos: {x: 200, y: 50},
  kills: {},
  offset: 40,
}

function addKill(notify) {

  if (!gameStore.gameReady || !statusBarWrapper.scene) return;

  let key = notify.killer_name + notify.dead_name;
  let infoBullet = gameStore.gameTypes.ammo[notify.ammo_id];

  let kill = {
    killer: {
      name: notify.killer_name,
      fraction: notify.killer_fraction,
    },
    dead: {
      name: notify.dead_name,
      fraction: notify.dead_fraction,
    },
    ammo: infoBullet
  };

  if (kill.killer.name === "" || kill.dead.name === "") {
    return;
  }

  killBoard.kills[key] = kill;

  let killerName = createText(kill.killer)
  let deadName = createText(kill.dead)
  let width = killerName.width + deadName.width + killBoard.offset

  let sprite = statusBarWrapper.scene.add.renderTexture(killBoard.pos.x, killBoard.pos.y + (Object.keys(killBoard.kills).length * killBoard.offset), width + 4, killBoard.offset + 4);
  kill.sprite = sprite

  sprite.draw(killerName)
  killerName.destroy()

  deadName.x = ((width / 2) + killBoard.offset / 2) + 2
  sprite.draw(deadName)
  deadName.destroy()

  let ammoKey = "ammo_" + infoBullet.name;

  Scene.load.image(ammoKey, getIconPath({
    type: "ammo", item: {name: infoBullet.name}
  }));

  Scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
    let ammoIcon = statusBarWrapper.scene.make.image({
      x: (width / 2) + 2, y: killBoard.offset / 2, key: ammoKey, add: true
    });
    ammoIcon.setDisplaySize(30, 30)
    sprite.draw(ammoIcon);
    ammoIcon.destroy();

    Scene.time.addEvent({
      delay: 6000,
      callback: function () {
        sprite.destroy();
        delete killBoard.kills[key]

        for (let i in killBoard.kills) {
          if (killBoard.kills[i].sprite.y > sprite.y) {
            killBoard.kills[i].sprite.y -= killBoard.offset
          }
        }
      },
      loop: false
    });
  })

  Scene.load.start()
}

function createText(data) {
  let text = statusBarWrapper.scene.add.bitmapText(2, 10, 'bit_text_14', data.name, 14);
  text.setLetterSpacing(1)

  if (data.fraction === 1) {
    text.setTint(0xFF5153)
  }

  if (data.fraction === 2) {
    text.setTint(0x57FF51)
  }

  if (data.fraction === 3) {
    text.setTint(0x4673FF)
  }

  if (data.fraction === 4) {
    text.setTint(0xF1ED08)
  }

  return text
}

export {addKill}
