import {gameStore} from "../store";
import {Scene} from "../create";

const additionalFog = 50;
let usersVisibleZone = [];
const fogK = (512 / 420);

function UpdateUnitFog(scene, unit) {
  if (!gameStore.FogOfWar.back) {
    return
  }

  if (gameStore.unitReady) {
    if (unit && unit.sprite) {
      gameStore.FogOfWar.brush.setVisible(true);
      UpdateFogOfWar(scene, unit.sprite, unit.body.range_view * 2, gameStore.FogOfWar.brush);
    }
  }
}

function UpdateObjectFog(scene, obj, sprite, type, range_view) {
  if (!gameStore.FogOfWar.back) {
    return
  }

  if (gameStore.unitReady) {

    let brush = gameStore.FogOfWar.objBrush[type][obj.id];
    if (!brush) {
      brush = createFogBrush(scene);
      brush.setVisible(true);
      gameStore.FogOfWar.objBrush[type][obj.id] = brush;
      gameStore.FogOfWar.brushContainer.add([brush]);
    }

    UpdateFogOfWar(scene, sprite, range_view * 2, brush);
  }
}

function UpdateFogOfWar(scene, sprite, range_view, brush) {
  if (!gameStore.FogOfWar.back) {
    return
  }

  brush.setPosition(sprite.x, sprite.y);
  brush.displayHeight = range_view;
  brush.displayWidth = range_view;
  usersVisibleZone.push({x: sprite.x, y: sprite.y, r: range_view * 0.5})
}

function UpdateFogBack(scene) {
  if (!gameStore.FogOfWar.back) {
    return
  }

  usersVisibleZone = [];

  for (let i in gameStore.FogOfWar.objBrush.object) {
    if (!gameStore.objects[i]) {
      gameStore.FogOfWar.objBrush.object[i].destroy();
      delete gameStore.FogOfWar.objBrush.object[i];
    }
  }

  for (let i in gameStore.FogOfWar.objBrush.unit) {
    if (!gameStore.units[i]) {
      gameStore.FogOfWar.objBrush.unit[i].destroy();
      delete gameStore.FogOfWar.objBrush.unit[i];
    }
  }

  for (let i in gameStore.FogOfWar.objBrush.drone) {
    if (!gameStore.drones[i]) {
      gameStore.FogOfWar.objBrush.drone[i].destroy();
      delete gameStore.FogOfWar.objBrush.drone[i];
    }
  }
}

function createFogBrush(scene) {
  let c = scene.add.circle(0, 0, 4, 0x000000);
  // let c = scene.add.image(0, 0, 'brush_fog').setVisible(false);
  return c;
}

let initBack = false

function initFog(scene) {

  if (gameStore.FogOfWar && gameStore.FogOfWar.back) {
    return
  }

  let back = scene.add.rectangle(0, 0, 1024, 1024, 0x000000);
  back.setAlpha(0.3);
  back.setDepth(999);

  let brush = createFogBrush(scene);
  let maskContainer = scene.make.container({
    x: 0,
    y: 0,
    width: gameStore.map.XSize,
    height: gameStore.map.YSize,
  }, false);
  maskContainer.add([brush]);

  // let mask = new Phaser.Display.Masks.BitmapMask(scene, maskContainer); // TODO из за BitmapMask ломается мини карта
  let mask = new Phaser.Display.Masks.GeometryMask(scene, maskContainer);
  mask.invertAlpha = true;
  back.setMask(mask)

  return {
    back: back,
    brush: brush,
    brushContainer: maskContainer,
    objBrush: {
      object: {},
      unit: {},
      drone: {},
    },
  }
}

function removeFog() {
  if (gameStore.FogOfWar.back) {
    gameStore.FogOfWar.back.clearMask(true);
    gameStore.FogOfWar.back.destroy();
    gameStore.FogOfWar.brush.destroy();
    gameStore.FogOfWar.brushContainer.remove(gameStore.FogOfWar.brush)
    gameStore.FogOfWar.brushContainer.destroy();

    if (gameStore.FogOfWar.objBrush) {

      if (gameStore.FogOfWar.objBrush.object) {
        for (let i in gameStore.FogOfWar.objBrush.object) {
          gameStore.FogOfWar.objBrush.object[i].destroy();
        }
      }

      if (gameStore.FogOfWar.objBrush.unit) {
        for (let i in gameStore.FogOfWar.objBrush.unit) {
          gameStore.FogOfWar.objBrush.unit[i].destroy();
        }
      }

      if (gameStore.FogOfWar.objBrush.drone) {
        for (let i in gameStore.FogOfWar.objBrush.drone) {
          gameStore.FogOfWar.objBrush.drone[i].destroy();
        }
      }
    }

    gameStore.FogOfWar = {
      back: null,
      brush: null,
    };
  }
}

function fullUpdateFog() {
  removeFog();
  setTimeout(function () {
    let newFog = initFog(Scene);

    if (newFog) {
      gameStore.FogOfWar = newFog
    }

    updateFogSize();
  }, 1000)
}

function updateFogSize() {
  if (!gameStore.FogOfWar.back) {
    return
  }

  gameStore.FogOfWar.back.x = (gameStore.map.XSize / 2) //Scene.cameras.main.worldView.centerX;
  gameStore.FogOfWar.back.y = (gameStore.map.XSize / 2) //Scene.cameras.main.worldView.centerY;
  gameStore.FogOfWar.back.displayHeight = gameStore.map.YSize   //Scene.cameras.main.displayHeight + additionalFog * 2;
  gameStore.FogOfWar.back.displayWidth = gameStore.map.XSize //Scene.cameras.main.displayWidth + additionalFog * 2;
}

export {
  usersVisibleZone,
  UpdateUnitFog,
  initFog,
  UpdateFogBack,
  UpdateObjectFog,
  updateFogSize,
  removeFog,
  fullUpdateFog,
}
