<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Разведка</h2>
      <p>Большую часть обзора в игровом мире закрывает туман войны, но через него можно смотреть с помощью радара. Радар
        показывает метки по типу объекта, но это единственная информация которая доступна через радар. Некоторые обьекты
        окружения и статичные структуры запоминаются и вы видите их состояние на момент когда перестали их видеть.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('scouting_1', 'png')+ ')',
            width: '450px',
            height: '325px',
            marginLeft: 'calc(50% - 225px)',
            }"/>

      <p>Вот такие метки позволяет видеть радар: </p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('scouting_2', 'png')+ ')',
            width: '350px',
            height: '185px',
            marginLeft: 'calc(50% - 175px)',
            }"/>

      <p>Эти метки показывает и миникарта, желтыми кругами показаны летящие снарды. </p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('minimap', 'gif')+ ')',
            width: '370px',
            height: '230px',
            marginLeft: 'calc(50% - 185px)',
            backgroundPosition: 'bottom',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Reconnaissance</h2>
      <p>Most of the overview in the game world is covered by the fog of war, but it can be seen through using radar.
        The radar shows marks according to the type of object, but this is the only information available through the
        radar. Some environmental objects and static structures are remembered, and you see their state at the moment
        when you stopped seeing them.</p>

      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('scouting_1', 'png')+ ')',
      width: '450px',
      height: '325px',
      marginLeft: 'calc(50% - 225px)',
      }"/>

      <p>These are the kinds of marks the radar allows you to see: </p>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('scouting_2', 'png')+ ')',
      width: '350px',
      height: '185px',
      marginLeft: 'calc(50% - 175px)',
      }"/>

      <p>The mini-map also shows these marks, with flying projectiles indicated by yellow circles. </p>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('minimap', 'gif')+ ')',
      width: '370px',
      height: '230px',
      marginLeft: 'calc(50% - 185px)',
      backgroundPosition: 'bottom',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Scouting",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
