<template>
  <div>
    <app-map-pin v-if="subMenuProps" v-bind:subMenuProps="subMenuProps"/>
    <div id="GlobalViewMap" ref="GlobalViewMap" v-if="path || globalPath || maps">

      <app-control v-bind:head="texts['window_name'][language]"
                   v-bind:move="true"
                   v-bind:close="true"
                   v-bind:resizeFunc="resize"
                   v-bind:minSize="{height: 300, width: 400}"
                   v-bind:maxSize="{height: 837, width: 962}"
                   v-bind:refWindow="'GlobalViewMap'"/>


      <div id="GlobalMapWrapper" ref="GlobalMapWrapper" v-dragscroll @wheel.stop="wheelZoom">

        <div class="buttons">
          <div @mousedown="scaling(1)" class="cursor_hover" @mouseup="zoomChange = false"
               style="background-image: url('https://img.icons8.com/material-sharp/24/000000/plus-math.png')"></div>
          <div @mousedown="scaling(-1)" class="cursor_hover" @mouseup="zoomChange = false"
               style="background-image: url('https://img.icons8.com/material-sharp/24/000000/minus-math.png')"></div>
        </div>

        <canvas id="GlobalMapCanvas" ref="GlobalMapCanvas"/>
        <canvas id="GlobalMapPathCanvas2" ref="GlobalMapPathCanvas2"/>
        <canvas id="GlobalMapArrow" ref="GlobalMapArrow"/>

        <canvas id="GlobalMapPathCanvas"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @mousemove="mapPointMouseOut()"
                ref="GlobalMapPathCanvas"/>


        <div class="GridBox" :style="{height: (sizeMap.y * gridSize) + 'px', width: (sizeMap.x * gridSize) + 'px'}">

          <div class="MapPoint cursor_hover" v-for="map in maps" v-if="map.global"
               :style="{
                  left: ((map.x_global * gridSize) + gridSize / 2) + 'px',
                  top: ((map.y_global * gridSize) + gridSize / 2) + 'px',
               }"
               @mouseout="mapPointMouseOut()"
               @mouseover="previewPath($event, map)"
               @mousedown="openSubMenu($event, map, true)"
               v-bind:class="{
                 User: map.id === userID,
                 Dst: destination && destination.map_id === map.id,
               }"
               :ref="'map' + map.id">

            <div class="count_defenders" v-if="!map.possible_battle && !map.free_land">
              {{ defenders[map.id] ? defenders[map.id] : "" }}
            </div>

            <div class="corporation_logo"
                 v-if="map.corporation_id !== 0 && getCorpLogo(map.corporation_id)"
                 v-bind:style="{backgroundImage:  'url(\'' + corpLogo[map.corporation_id] + '\')'}">
            </div>

            <div class="transit" v-if="map.free_land && map.corporation_id === 0 && !map.transit"/>

            <div class="animateAura"
                 v-bind:class="{animate: map.id === userID, battleMap: map.possible_battle}"
                 @mouseout="clearPath('GlobalMapPathCanvas')"></div>

            <div class="fractionIcon" v-if="!map.free_land"
                 :style="{backgroundImage: 'url(' + require('../../assets/' + fractionLogo(map.fraction)).default + ')'}"/>

            <div class="fractionIcon" v-if="map.free_land && map.corporation_id === 0"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/apd_mini.png').default}"/>

            <div class="priority"
                 v-if="getPriorityWarMap.attack === map.id || getPriorityWarMap.defend === map.id"></div>

            <div class="sectorName">{{ handbook['sector'][language][map.name].name }}</div>

            <div class="battle" v-if="map.battle"
                 :style="{backgroundSize: ((map.battle / getMaxPoints()) * 100) + '%'}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import MapPin from './MapPin';
import {dragscroll} from 'vue-dragscroll'
import {gameStore} from "../../game/store";
import {urls} from "../../const";

export default {
  name: "GlobalMap",
  props: ["meta"],
  directives: {
    'dragscroll': dragscroll
  },
  data() {
    return {
      gridSize: 35,
      defaultGridSize: 35,
      img: null,
      initUser: false,
      zoomChange: false,
      whellTimeOut: false,
      subMenuProps: null,
      updater: null,
      actialPath: null,
      destination: null,
      sizeMap: {
        x: 0,
        y: 0,
      },
      corpLogo: {},
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater);
    this.$store.commit({
      type: 'updateWorldMapState',
      id: 0,
    });
  },
  created() {
    this.destination = this.$props.meta

    if (!this.maps || Object.keys(this.maps).length === 0) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "OpenMapMenu",
        }
      }));
    }

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetSecureHandlers",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "UpdateMapInfo",
      }
    }));

    this.DrawMaps('GlobalMapCanvas')
    this.updater = setInterval(function () {

      $('#GlobalMapWrapper').on("wheel mousewheel", function (e) {
        if (e.originalEvent.deltaY > 0) {
          e.preventDefault();
          return;
        } else if (e.originalEvent.wheelDeltaY < 0) {
          e.preventDefault();
          return;
        }
      });

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "UpdateMapInfo",
        }
      }));
    }.bind(this), 1000);
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getMaxPoints() {
      let max = 0
      for (let i in this.maps) {
        if (this.maps[i].battle > max) {
          max = this.maps[i].battle
        }
      }
      return max
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    resize(event, ui, el) {
      let GlobalMapWrapper = $('#GlobalMapWrapper');
      GlobalMapWrapper.css("height", el.height());
      GlobalMapWrapper.css("width", el.width());
    },
    getCorpLogo(id) {
      let app = this;

      if (app.corpLogo.hasOwnProperty(id)) {
        return app.corpLogo[id]
      }

      app.$api.get(urls.corpLogoURL + '?corporation_id=' + id).then(function (response) {
        app.$set(app.corpLogo, id, response.data.avatar);
      });

      return false
    },
    mapPointMouseOut() {

      this.$store.commit({
        type: 'setPreviewPath',
        previewPath: null,
      });

      this.actialPath = null;

      if (this.subMenuProps && this.subMenuProps.active) {
        return
      }

      this.subMenuProps = null;
    },
    openSubMenu(event, map, active) {
      this.playSound('button_press.mp3', 0.3)

      if (this.subMenuProps && this.subMenuProps.active) {
        return
      }

      this.subMenuProps = {
        x: ((event.pageX + 20) / this.interfaceZoom),
        y: ((event.pageY + 20) / this.interfaceZoom),
        map: map,
        active: active,
      };

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetMapInfo",
          id: Number(map.id)
        }
      }));
    },
    previewPath(e, map) {
      this.playSound('select_sound.mp3', 0.3)
      this.openSubMenu(e, map, false)
      this.clearPath('GlobalMapPathCanvas')
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "PreviewPath",
          id: Number(map.id)
        }
      }));
    },
    wheelZoom(e, cancel) {
      e.preventDefault();
      this.scaleAndScroll((e.deltaY * 0.01) * -1);
    },
    scaling(change) {
      let app = this;
      app.zoomChange = true;
      let zoomer = setInterval(function () {
        if (app.zoomChange) {
          app.scaleAndScroll(change);
        } else {
          clearInterval(zoomer);
        }
      }, 32);
    },
    scaleAndScroll(change) {
      let app = this;

      let globalMapWrapper = $('#GlobalMapWrapper')
      if (globalMapWrapper.width() > this.sizeMap.x * (app.gridSize + change) ||
        globalMapWrapper.height() > this.sizeMap.y * (app.gridSize + change)) {
        return
      }

      if (app.gridSize + change < 15) {
        return
      } else if (app.gridSize + change > 150) {
        return
      } else {
        app.gridSize += change;
      }

      app.actialPath = null;
      app.DrawMaps('GlobalMapCanvas', true);
      app.PreviewGlobalPath()

      // let scrollWidth = app.$refs['GlobalMapWrapper'].scrollWidth;
      // let scrollHeight = app.$refs['GlobalMapWrapper'].scrollHeight;
      // let oldScrollX = app.$refs['GlobalMapWrapper'].scrollLeft;
      // let oldScrollY = app.$refs['GlobalMapWrapper'].scrollTop;

      //setTimeout(function () {
      //  app.setMapPosition(oldScrollX, oldScrollY, scrollWidth, scrollHeight)
      //})
    },
    setMapPosition(oldScrollX, oldScrollY, oldScrollWidth, oldScrollHeight) {
      // функция сохраняет положение x, y на текущем положение, todo но она нихуя не роботаед
      let scrollWidth = this.$refs['GlobalMapWrapper'].scrollWidth;
      let scrollHeight = this.$refs['GlobalMapWrapper'].scrollHeight;

      let differentWidth = scrollWidth - oldScrollWidth;
      let percentXScroll = oldScrollX / oldScrollWidth * 100;
      let xScrollOffset = percentXScroll * differentWidth / 100;

      let differentHeight = scrollHeight - oldScrollHeight;
      let percentYScroll = oldScrollY / oldScrollHeight * 100;
      let yScrollOffset = percentYScroll * differentHeight / 100;

      if (differentWidth > 0) {
        this.$refs['GlobalMapWrapper'].scrollLeft = oldScrollX + xScrollOffset + 4;
        this.$refs['GlobalMapWrapper'].scrollTop = oldScrollY + yScrollOffset + 4;
      } else {
        this.$refs['GlobalMapWrapper'].scrollLeft = oldScrollX + xScrollOffset - 4;
        this.$refs['GlobalMapWrapper'].scrollTop = oldScrollY + yScrollOffset - 4;
      }
    },
    fractionLogo(fraction) {
      if (fraction === '') {
        fraction = 'empty'
      }
      return 'logo/' + fraction + '.png'
    },
    setCanvasSize(ref, pathSize) {
      this.$refs[ref].width = this.sizeMap.x * this.gridSize;
      this.$refs[ref].height = this.sizeMap.y * this.gridSize;

      if (pathSize) {
        this.$refs['GlobalMapPathCanvas'].width = this.sizeMap.x * this.gridSize;
        this.$refs['GlobalMapPathCanvas'].height = this.sizeMap.y * this.gridSize;
      }
    },
    DrawMaps(ref, pathSize) {
      let wait = setInterval(function () {
        if (this.$refs && this.$refs.hasOwnProperty(ref) && Object.keys(this.maps).length > 0 && this.$store.getters.getWorldMapState.userMapID !== 0) {
          clearInterval(wait);

          if (this.sizeMap.x === 0 && this.sizeMap.y === 0) {
            this.getSizeMap()
          }

          this.setCanvasSize(ref, pathSize || !this.initUser);

          if (this.$refs['map' + this.$store.getters.getWorldMapState.userMapID] && this.$refs['map' + this.$store.getters.getWorldMapState.userMapID][0]) {
            if (!this.initUser) {
              this.initUser = true;

              this.$refs['map' + this.$store.getters.getWorldMapState.userMapID][0].scrollIntoView({
                block: "center",
                inline: 'center',
                behavior: "smooth"
              });
              if (this.destination) {
                setTimeout(function () {
                  this.$refs['map' + this.destination.map_id][0].scrollIntoView({
                    block: "center",
                    inline: 'center',
                    behavior: "smooth"
                  });
                }.bind(this), 1000)
              }
            }
          }

          if (!this.$store.getters.getWorldMapState.background) {
            let background = new Image();
            background.src = require("../../assets/logo/mapBackground.jpg").default;
            background.addEventListener("load", function () {

              this.$refs[ref].getContext('2d').drawImage(background, 0, 0, this.sizeMap.x * this.gridSize, this.sizeMap.y * this.gridSize)

              this.CanvasGrid(ref);
              this.CanvasGlobalLine(ref, this.maps);

              let data = this.$refs[ref].toDataURL()
              this.$store.commit({
                type: 'loadMapBackGround',
                background: data,
              });
            }.bind(this))
          } else {
            this.$refs[ref].getContext('2d').drawImage(this.$store.getters.getWorldMapState.background, 0, 0, this.sizeMap.x * this.gridSize, this.sizeMap.y * this.gridSize)
          }

          this.CanvasGlobalArrow(this.maps);
        }
      }.bind(this), 32);
    },
    CanvasGrid(ref) {
      this.DrawRegions(this.$refs[ref].getContext('2d'))
    },
    DrawRegions(ctx) {
      for (let r in this.$store.getters.getWorldMapState.regions) {
        let region = this.$store.getters.getWorldMapState.regions[r];

        ctx.font = "32px serif";
        ctx.strokeStyle = "rgba(0,0,0, 0.6)";
        ctx.fillStyle = "rgba(255, 255, 255, 0.75)";
        ctx.lineWidth = 2;

        ctx.strokeText(this.handbook['region'][this.language][region.name].name, region.coordinate_name.x * this.gridSize, region.coordinate_name.y * this.gridSize)
        ctx.fillText(this.handbook['region'][this.language][region.name].name, region.coordinate_name.x * this.gridSize, region.coordinate_name.y * this.gridSize)

        ctx.strokeStyle = "rgb(212,212,212)";
        ctx.lineWidth = 4;
        ctx.beginPath();

        for (let i in region.coordinates) {
          let coordinate = region.coordinates[i]
          ctx.moveTo(coordinate.x * this.gridSize, coordinate.y * this.gridSize);
          ctx.lineTo(coordinate.to_x * this.gridSize, coordinate.to_y * this.gridSize);
        }

        ctx.stroke();
      }
    },
    CanvasGlobalLine(ref, maps) {
      for (let i in maps) {
        for (let j in maps[i].handlers_coordinates) {
          if (maps[i].handlers_coordinates[j].handler === 'sector') {

            let mp = this.getMapByID(maps, maps[i].handlers_coordinates[j].to_map_id);
            if (!mp) continue;

            let xCell = (maps[i].x_global * this.gridSize) + this.gridSize / 2;
            let yCell = (maps[i].y_global * this.gridSize) + this.gridSize / 2;

            let toX = (mp.x_global * this.gridSize) + this.gridSize / 2;
            let toY = (mp.y_global * this.gridSize) + this.gridSize / 2;

            this.CanvasGlobalLineXY_To_XY(ref, xCell, yCell, toX, toY);
          }
        }
      }
    },
    CanvasGlobalArrow(maps) {
      let canvas = this.$refs['GlobalMapArrow'];
      canvas.width = this.sizeMap.x * this.gridSize;
      canvas.height = this.sizeMap.y * this.gridSize;

      let secure = this.secure

      let a = this.gridSize / 2
      for (let i in maps) {

        let xCell = (maps[i].x_global * this.gridSize) + a;
        let yCell = (maps[i].y_global * this.gridSize) + a;

        for (let j in maps[i].handlers_coordinates) {
          if (maps[i].handlers_coordinates[j].handler === 'sector') {

            let tomp = this.getMapByID(maps, maps[i].handlers_coordinates[j].to_map_id);
            if (!tomp) continue;

            let toX = (tomp.x_global * this.gridSize) + a;
            let toY = (tomp.y_global * this.gridSize) + a;

            if (secure.hasOwnProperty(i + ":" + maps[i].handlers_coordinates[j].x + ":" + maps[i].handlers_coordinates[j].y)) {
              this.drawX(canvas, xCell, yCell, toX, toY)
            } else {
              this.CanvasArrowPath(canvas, xCell, yCell, toX, toY);
            }
          }
        }
      }
    },
    CanvasArrowPath(canvas, startX, startY, endX, endY, headLength = 7) {
      const ctx = canvas.getContext('2d');

      let points = [];

      let time = Math.max(Math.abs(startX - endX), Math.abs(startY - endY));//для повышения точности выбираем что сильнее изменилось, для ускорения выбор наоборот min
      for (let i = 0; i <= time; i++) {
        let delta = i / time;
        let a = delta * (endX - startX) + startX;
        let b = delta * (endY - startY) + startY;
        points.push({x: a, y: b});
      }

      let pointNumber = Math.round((points.length / 100) * 40);
      let centerPathX = points[pointNumber].x;
      let centerPathY = points[pointNumber].y;

      ctx.strokeStyle = "rgba(3, 250, 15, 0.75)";
      ctx.lineWidth = 3;
      ctx.shadowColor = 'black';
      ctx.shadowBlur = 2;

      // constants (could be declared as globals outside this function)
      let degreesInRadians225 = 225 * Math.PI / 180;
      let degreesInRadians135 = 135 * Math.PI / 180;

      // calc the angle of the line
      let dx = endX - startX;
      let dy = endY - startY;
      let angle = Math.atan2(dy, dx);

      // calc arrowhead points
      let x225 = centerPathX + headLength * Math.cos(angle + degreesInRadians225);
      let y225 = centerPathY + headLength * Math.sin(angle + degreesInRadians225);
      let x135 = centerPathX + headLength * Math.cos(angle + degreesInRadians135);
      let y135 = centerPathY + headLength * Math.sin(angle + degreesInRadians135);

      ctx.beginPath();

      ctx.moveTo(points[pointNumber + 1].x, points[pointNumber + 1].y);
      ctx.lineTo(centerPathX, centerPathY);

      ctx.moveTo(centerPathX, centerPathY);
      ctx.lineTo(x225, y225);
      // draw partial arrowhead at 135 degrees
      ctx.moveTo(centerPathX, centerPathY);
      ctx.lineTo(x135, y135);
      // stroke the line and arrowhead
      ctx.stroke();
    },
    drawX(canvas, startX, startY, endX, endY, headLength = 5) {
      const ctx = canvas.getContext('2d');

      let points = [];

      let time = Math.max(Math.abs(startX - endX), Math.abs(startY - endY));//для повышения точности выбираем что сильнее изменилось, для ускорения выбор наоборот min
      for (let i = 0; i <= time; i++) {
        let delta = i / time;
        let a = delta * (endX - startX) + startX;
        let b = delta * (endY - startY) + startY;
        points.push({x: a, y: b});
      }

      let pointNumber = Math.round((points.length / 100) * 40);
      let x = points[pointNumber].x;
      let y = points[pointNumber].y;

      ctx.beginPath();

      ctx.strokeStyle = "rgba(255, 0, 15, 1)";
      ctx.lineWidth = 3;
      ctx.shadowColor = 'black';
      ctx.shadowBlur = 2;

      ctx.moveTo(x - headLength, y - headLength);
      ctx.lineTo(x + headLength, y + headLength);

      ctx.moveTo(x + headLength, y - headLength);
      ctx.lineTo(x - headLength, y + headLength);
      ctx.stroke();
    },
    CanvasGlobalLineXY_To_XY(ref, startX, startY, endX, endY) {
      let canvas = this.$refs[ref];
      const ctx = canvas.getContext('2d');

      ctx.strokeStyle = "rgba(127, 127, 127, 0.1)";
      ctx.lineWidth = 3;
      ctx.shadowBlur = 1;
      ctx.shadowColor = 'white';

      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
    },
    getMapByID(maps, id) {
      for (let i in maps) {
        if (maps[i].id === id) {
          return maps[i]
        }
      }
    },
    PreviewMapPath(path) {
      let app = this;
      let wait = setInterval(function () {
        if (app.$refs && app.$refs.hasOwnProperty('GlobalMapPathCanvas')) {

          clearInterval(wait);
          for (let i in path) {
            let xCell = (path[i].x * app.gridSize) + app.gridSize / 2;
            let yCell = (path[i].y * app.gridSize) + app.gridSize / 2;

            if (path.hasOwnProperty(Number(i) + 1)) {
              let toX = (path[Number(i) + 1].x * app.gridSize) + app.gridSize / 2;
              let toY = (path[Number(i) + 1].y * app.gridSize) + app.gridSize / 2;
              app.CanvasGlobalPathXY_To_XY(xCell, yCell, toX, toY, "rgba(0,255,226,1)", 2, 'GlobalMapPathCanvas')
            }
          }
        }
      }, 100);
    },
    PreviewGlobalPath() {
      let app = this;

      let wait = setInterval(function () {
        if (app.$refs && app.initUser && app.maps && app.$refs.hasOwnProperty('GlobalMapPathCanvas2')) {
          clearInterval(wait);

          let canvas = app.$refs['GlobalMapPathCanvas2'];
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          app.$refs['GlobalMapPathCanvas2'].width = app.sizeMap.x * app.gridSize;
          app.$refs['GlobalMapPathCanvas2'].height = app.sizeMap.y * app.gridSize;

          try {
            app.PreviewTargetPath(gameStore.globalPath.points)
            app.PreviewMissionPath(gameStore.missionPath.actions)
          } catch (e) {
            console.log(e)
          }
        }
      }, 10);
    },
    PreviewMissionPath(action) {
      for (let i in action) {
        for (let j in action[i]) {

          let map = this.getMapByID(this.maps, action[i][j].map_id);

          let xCell = (map.x_global * this.gridSize) + this.gridSize / 2;
          let yCell = (map.y_global * this.gridSize) + this.gridSize / 2;

          if (action[i].hasOwnProperty(Number(j) + 1)) {
            let toMap = this.getMapByID(this.maps, action[i][Number(j) + 1].map_id);

            let toX = (toMap.x_global * this.gridSize) + this.gridSize / 2;
            let toY = (toMap.y_global * this.gridSize) + this.gridSize / 2;

            this.CanvasGlobalPathXY_To_XY(xCell, yCell, toX, toY, "rgba(0,255,0,1)", 3, 'GlobalMapPathCanvas2')
          }
        }
      }
    },
    PreviewTargetPath(path) {
      let app = this;
      for (let i in path) {

        let map = app.getMapByID(app.maps, path[i].map_id);
        let toMap = app.getMapByID(app.maps, path[i].to_map_id);

        let xCell = (map.x_global * app.gridSize) + app.gridSize / 2;
        let yCell = (map.y_global * app.gridSize) + app.gridSize / 2;

        if (toMap) {
          let toX = (toMap.x_global * app.gridSize) + app.gridSize / 2;
          let toY = (toMap.y_global * app.gridSize) + app.gridSize / 2;
          app.CanvasGlobalPathXY_To_XY(xCell, yCell, toX, toY, "rgb(227,152,22)", 5, 'GlobalMapPathCanvas2')
        }
      }
    },
    CanvasGlobalPathXY_To_XY(startX, startY, endX, endY, color, width, canvasID) {
      let canvas = this.$refs[canvasID];
      const ctx = canvas.getContext('2d');

      ctx.strokeStyle = color;
      ctx.lineWidth = width;
      ctx.shadowBlur = 1;
      ctx.shadowColor = 'black';

      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
    },
    clearPath(ref) {
      let app = this;
      let wait = setInterval(function () {
        if (app.$refs && app.$refs.hasOwnProperty(ref)) {
          clearInterval(wait);

          let canvas = app.$refs[ref];
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }, 100)
    },
    equalsPath(newPath) {
      if (this.actialPath === null || this.actialPath.length !== newPath.length) {
        return false
      }

      for (let i in this.actialPath) {
        if (this.actialPath.hasOwnProperty(i)) {
          if (!newPath.hasOwnProperty(i) || newPath[i].ID !== this.actialPath[i].ID) {
            return false
          }
        }
      }

      return true
    },
    getSizeMap() {
      this.sizeMap.x = 0;
      this.sizeMap.y = 0;

      let maps = this.$store.getters.getWorldMapState.maps;

      for (let i in maps) {
        if (this.sizeMap.x < maps[i].x_global) this.sizeMap.x = maps[i].x_global;
        if (this.sizeMap.y < maps[i].y_global) this.sizeMap.y = maps[i].y_global;
      }

      this.sizeMap.x += 4;
      this.sizeMap.y += 4;
    }
  },
  computed: {
    maps() {
      return this.$store.getters.getWorldMapState.maps
    },
    globalPath() {
      let target = this.$store.getters.getGlobalPath
      let ump = this.$store.getters.getWorldMapState.userMapID

      if (target) {
        this.PreviewGlobalPath()
        return target.mapID && ump && false
      }

      return false
    },
    getPriorityWarMap() {
      return this.$store.getters.getPriorityWarMap
    },
    defenders() {
      return this.$store.getters.getWorldMapState.defenders
    },
    userID() {
      return this.$store.getters.getWorldMapState.userMapID
    },
    path() {
      let path = this.$store.getters.getWorldMapState.previewPath
      if (path) {
        if (!this.equalsPath(path)) {
          this.actialPath = path;
          this.PreviewMapPath(path)
        }
      } else {
        this.clearPath('GlobalMapPathCanvas')
      }
    },
    secure() {
      return this.$store.getters.getSecureHandlers
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('GlobalMap')
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    }
  },
  components: {
    AppControl: Control,
    AppMapPin: MapPin,
  },
}
</script>

<style scoped>
#GlobalViewMap {
  top: 50px;
  left: calc(50% - 200px);
  height: 370px;
  width: 482px;
  position: absolute;
  border-radius: 5px;
  padding: 22px 5px 5px;
  z-index: 11;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#GlobalMapWrapper {
  box-shadow: inset 0 0 5px black;
  background: #8cb3c7;
  border-radius: 5px;
  height: 100%;
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: scroll;
}

.MapPoint {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: all;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.animateAura {
  position: absolute;
  left: -1px;
  top: -1px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px dashed rgb(255, 255, 255);
}

.animateAura.animate {
  animation: rotate 8s infinite ease-in-out;
}

.animateAura:hover {
  animation-play-state: paused;
  box-shadow: inset 0 0 10px 3px white, 0 0 8px 1px #84ffdc;
}

.MapPoint.User .animateAura {
  animation: userSector 2000ms infinite ease-in-out, rotate 8s infinite ease-in-out;
  border: 1px dashed rgb(255, 249, 30);
}

.MapPoint.User .animateAura:hover {
  animation: none;
}

@keyframes userSector {
  0% {
    box-shadow: inset 0 0 5px 0 rgb(255, 249, 30), 0 0 5px 0 rgb(255, 249, 30);
  }
  50% {
    box-shadow: inset 0 0 10px 10px rgba(204, 169, 44, 0), 0 0 10px 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: inset 0 0 0 10px rgba(204, 169, 44, 0), 0 0 0 10px rgba(204, 169, 44, 0);
  }
}

.MapPoint.Dst .animateAura {
  animation: dstSector 2000ms infinite ease-in-out, rotate 8s infinite ease-in-out;
  border: 1px dashed rgb(102, 171, 243);
}

.MapPoint.Dst .animateAura:hover {
  animation: none;
}

@keyframes dstSector {
  0% {
    box-shadow: inset 0 0 5px 0 rgb(102, 171, 243), 0 0 5px 0 rgb(102, 171, 243);
  }
  50% {
    box-shadow: inset 0 0 10px 10px rgba(75, 144, 217, 0), 0 0 10px 10px rgba(75, 144, 217, 0);
  }
  100% {
    box-shadow: inset 0 0 0 10px rgba(11, 121, 236, 0), 0 0 0 10px rgba(11, 121, 236, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.9);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

#GlobalMapCanvas, #GlobalMapPathCanvas, #GlobalMapPathCanvas2, #GlobalMapArrow {
  position: absolute;
  left: 0;
  top: 0;
}

.sectorName {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  top: calc(100% + 5px);
  left: 50%;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, 0%);
  width: fit-content;
}

.MapPoint:hover .sectorName {
  color: rgba(255, 255, 255, 1) !important;
}

.fractionIcon {
  position: absolute;
  left: calc(50% - 13px);
  top: calc(50% - 14px);
  height: 26px;
  width: 26px;
  background-size: cover;
  pointer-events: none;
}

.GridBox {
  pointer-events: none;
  top: 0;
  left: 0;
  position: absolute;
}

.buttons {
  position: sticky;
  left: calc(100% - 56px);
  top: 10px;
  height: 24px;
  width: 46px;
  pointer-events: all;
  z-index: 2;
  border: 1px solid #25a0e1;
  background: rgb(8, 138, 210);
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  transition: 100ms;
}

.buttons div {
  margin-top: 2px;
  float: left;
  border-radius: 2px;
  pointer-events: auto;
  margin-left: 2px;
  box-shadow: 0 0 2px black;
  background: #8ea6b2;
  width: 20px;
  height: 20px;
  background-size: contain;
  transition: 100ms;
}

.buttons div:hover {
  box-shadow: 0 0 2px 1px white;
}

.buttons div:active {
  transform: scale(0.97);
}

.battle {
  height: 24px;
  width: 24px;
  background-image: url('../../assets/icons/battle_icon.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  pointer-events: none;
}

.fractionIcon.shield {
  background-image: url('https://img.icons8.com/fluent/48/000000/shield.png');
  height: 40px;
  width: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}

.battleMap {
  border: 2px dashed rgb(251, 72, 72);
  left: -2px;
  top: -2px;
}

.count_defenders, .priority, .transit, .corporation_logo {
  background-image: url('../../assets/icons/shield_2.png');
  height: 24px;
  width: 24px;
  right: -6px;
  top: -6px;
  background-size: contain;
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  font-size: 9px;
  text-align: center;
  line-height: 22px;
  font-family: 'Audiowide', cursive;
  color: #fff16f;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.priority {
  background-image: url('../../assets/icons/priority.png');
  filter: drop-shadow(1px 1px 1px black);
  left: -9px;
  top: -9px;
  z-index: 6;
}

.transit, .corporation_logo {
  background-image: url('../../assets/icons/law_icon.png');
  left: -9px;
  top: -9px;
  z-index: 6;
  filter: contrast(5%) sepia(100%) hue-rotate(344deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

.corporation_logo {
  height: 32px;
  width: 32px;
  border-radius: 10px;
  left: 4px;
  top: 4px;
  pointer-events: none;
  filter: drop-shadow(1px 1px 1px black);
}
</style>
