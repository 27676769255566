<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Экспедиции</h2>

      <p>Экспедиция - это организованная какой-либо фракцией группа ботов, чья задача отправиться в свободные земли,
        застолбив тамошнюю территорию. Экспедиция закрепляется на территории “свободного сектора”, после, организовывая
        базу, начиная процессы по добыче ресурсов. </p>

      <p>В отличии от обычных ботов, члены экспедиции воспринимают игроков из иных фракций синтетов как недругов,
        немедленно нападая на них. Что же касается игроков из той же самой фракции, к коей принадлежат боты экспедиции,
        то
        они воспринимают игрока, как - “гостя нейтрала” - не пытаясь атаковать первыми и даже позволяя взаимодействовать
        с
        теми.</p>

      <h3 class="headSection">Экспедиция дает членам своей фракции разные преимущества:</h3>
      <ul>
        <li>Защита от машин АОП/APD; защита от налётчиков; защита от ботов и игроков из других фракций в свободных
          землях;
        </li>
        <li>Безопасная территория, где можно оборудовать собственный склад хранения;</li>
        <li>Возможность для отыгрыша роли налётчика, поскольку из сектора регулярно отходят транспорты гружёные
          ресурсами.
        </li>
        <li>Периодически из сектора уходят транспорты с ресурсами и охраной. Экспедиция может помочь вам вернуться в
          безопасную зону, если вы перевозите ценный груз или хотите избежать опасностей.
        </li>
      </ul>

      <p>Игроки из иных фракций, имея достаточно сильный отряд, могут попытаться уничтожить аванпост экспедиции и
        разграбить тамошние склады.</p>

      <p>Сама же экспедиция считается уничтоженной, когда ликвидированы все боты-участники в секторе аванпоста. После
        данного события, на помощь к аванпосту экспедиции перестанут прибывать подкрепления. Однако те силы, что уже
        явились в сектор ради помощи экспедиции продолжат свою деятельность. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('expedirion', 'png')+ ')',
        width: '480px',
        height: '253px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
      <p>Если бы вы могли состоять в отряде экспедиции то она бы выглядела как то так, но вы не можете С:</p>

      <div class="content_section">
        <!--      <h3 class="headSection">Жизненный цикл экспедиции: </h3>-->
        <!--      <ol>-->
        <!--        <li>У столицы появляется стартовый отряд из бойцов, строителей и транспорта забитый торием.</li>-->
        <!--        <li>Стартовый отряд следует в свободный сектор. Уничтожают всех врагов которых встретят по пути.</li>-->
        <!--        <li>В свободном сектора - зачищают зону установки лагеря. Строили начинают строить строения.</li>-->
        <!--        <li>Когда установлен склад из столицы идет подкрепление состоящие из бойцов и майнеров.</li>-->
        <!--        <li>При заполнение склада 50%++ из столицы выходит 3тий отряд который отвечает за перевозку ресурсов из склада-->
        <!--          до столицы. Состоит из бойцов и транспорта.-->
        <!--        </li>-->
        <!--      </ol>-->

        <!--      <p>Особености:</p>-->
        <!--      <ul>-->
        <!--        <li>Каждые 5 минут при условии что на аванпост не надпают из столицы приходит подкрепление состоящие из-->
        <!--          потеряных ботов во время боя.-->
        <!--        </li>-->
        <!--        <li>На аванпосте есть ремонтная станция которая чинит защитников а так же союзных игроков.</li>-->
        <!--        <li>Если вы установите свое строение его заправит транспорт аванпоста, при условии что он жив.</li>-->
        <!--        <li>Если склад аванпоста переполнен то майнеры могут класть накопаные ресурсы в строения игроков.</li>-->
        <!--        <li>Защитники преследуют врага до тех пор пока он не умрет или полностью не пропадет с радаров.</li>-->
        <!--        <li>После уничтожения аванпоста, склад становится доступен всем игрокам при условии что он остался жив.</li>-->
        <!--        <li>Отряд аванпоста забывает своих врагов через 3 минуты, если вы случайно спровоцировали их на себя, убегите и-->
        <!--          подождите 3 минуты.-->
        <!--        </li>-->
        <!--      </ul>-->
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Expeditions</h2>

      <p>An expedition is a group of bots organized by a faction, whose task is to venture into the free lands,
        claiming the territory there. The expedition establishes itself in the territory of the "free sector", then
        organizes
        a base, starting the processes of resource extraction. </p>

      <p>Unlike regular bots, expedition members perceive players from other synthetic factions as foes,
        immediately attacking them. As for players from the same faction as the expedition bots, they
        perceive the player as a "neutral guest" - not trying to attack first and even allowing interaction with
        them.</p>

      <h3 class="headSection">The expedition offers various advantages to its faction members:</h3>
      <ul>
        <li>Protection from AOP/APD machines; protection from raiders; protection from bots and players from other
          factions in the free
          lands;
        </li>
        <li>A safe territory where you can set up your own storage warehouse;</li>
        <li>The opportunity to play the role of a raider, as transports loaded with resources regularly depart from the
          sector.
        </li>
        <li>Periodically, transports with resources and security leave the sector. The expedition can help you return to
          a safe zone if you are transporting valuable cargo or want to avoid dangers.
        </li>
      </ul>

      <p>Players from other factions, having a strong enough squad, can try to destroy the expedition's outpost and
        loot the warehouses there.</p>

      <p>The expedition itself is considered destroyed when all the participating bots in the outpost sector are
        eliminated. After
        this event, reinforcements will stop arriving to help the expedition's outpost. However, the forces that have
        already arrived in the sector to assist the expedition will continue their activities. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('expedirion', 'png')+ ')',
        width: '480px',
        height: '253px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
      <p>If you could be part of the expedition squad, it would look something like this, but you cannot C:</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Expedition",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
