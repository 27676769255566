<template>
  <div>
    <table v-if="type === 'body'">
      <tr>
        <td colspan="2" style="text-align: left;">{{ texts['text_11'][language] }}</td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="UIThoriumSlot" v-for="slot in thorium_slots">

            <app-background-item-cell v-bind:slotItem="{type: 'recycle', item: {name: 'enriched_thorium'}}"/>

            <div class="UIThoriumSlotCount" title="Емкость ячейки">
              <span>{{ slot.max_count }}</span>
            </div>

            <!--            <div class="UIThoriumSlotCount" style="clear: both" title="Количество отработки">-->
            <!--              <div style="background-image: url('https://img.icons8.com/offices/80/000000/recycling.png')"></div>-->
            <!--              <span> {{ slot.processing_thorium }}</span>-->
            <!--            </div>-->

          </div>
        </td>
      </tr>
      <tr>
        <td>{{ texts['text_12'][language] }}</td>
        <td>{{ item.max_energy }}</td>
      </tr>
      <tr>
        <td>{{ texts['text_13'][language] }}</td>
        <td>{{ weapon_slots.length }}</td>
      </tr>
      <tr>
        <td>{{ texts['text_14'][language] }}</td>
        <td>{{ countEquipSlots.b }}</td>
      </tr>
      <tr>
        <td>{{ texts['text_15'][language] }}</td>
        <td>{{ countEquipSlots.m }}</td>
      </tr>
      <tr>
        <td>{{ texts['text_16'][language] }}</td>
        <td>{{ countEquipSlots.s }}</td>
      </tr>
    </table>

    <table v-if="type === 'weapon'">
      <h4>{{ texts['text_17'][language] }}</h4>
      <template>
        <div class="detailItemBpBlockRow" v-for="ammo in weaponAmmo">
          <div class="detailItemBpBlockIcon">
            <app-background-item-cell v-bind:slotItem="ammo"/>
          </div>
          <div class="detailItemBpBlockName">{{ ammo.item.name }}</div>
          <div class="detailItemBpBlockDetailButton" @click="openDetail(ammo)">i</div>
        </div>
      </template>
    </table>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import {urls} from '../../const';
import {gameStore} from "../../game/store";

export default {
  name: "EquipmentTab",
  props: ['type', 'item'],
  data() {
    return {
      equip_slots: [],
      weapon_slots: [],
      thorium_slots: [],
    }
  },
  created() {
    let app = this;
    app.$api.get(urls.bodySlotsInfo + '?id=' + app.$props.item.id).then(function (response) {

      for (let i in response.data.equips) {
        response.data.equips[i] = JSON.parse(response.data.equips[i])
      }

      for (let i in response.data.weapons) {
        response.data.weapons[i] = JSON.parse(response.data.weapons[i])
      }

      for (let i in response.data.thorium) {
        response.data.thorium[i] = JSON.parse(response.data.thorium[i])
      }

      app.equip_slots = response.data.equips;
      app.weapon_slots = response.data.weapons;
      app.thorium_slots = response.data.thorium;
    }).catch(function (err) {
      //console.log(err)
    });
  },
  methods: {
    openDetail(slot) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + slot.type + slot.item.id,
        component: 'detailItem',
        meta: {itemType: slot.type, itemID: slot.item.id},
        forceOpen: true,
      });
    },
  },
  computed: {
    countEquipSlots() {
      let s = 0;
      let m = 0;
      let b = 0;
      let outer = 0;

      for (let equipSlot of this.equip_slots) {
        if (equipSlot.type_slot === 1) s++;
        if (equipSlot.type_slot === 2) m++;
        if (equipSlot.type_slot === 3) {
          b++;
          if (equipSlot.mining) outer++
        }
      }

      return {s: s, m: m, b: b, outer: outer}
    },
    weaponAmmo() {
      let ammo = [];
      for (let id of this.$props.item.allow_ammo) {
        ammo.push({type: 'ammo', item: gameStore.gameTypes.ammo[id]})
      }
      return ammo
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.detailItemInfoBody table {
  font-size: 11px;
  width: 100%;
  border-collapse: collapse;
}

.detailItemInfoBody tr {
  transition: 200ms;
}

.detailItemInfoBody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detailItemInfoBody tr td:last-child {
  color: #ffef0f;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  text-align: right;
}

.vul {
  width: calc(33% - 3px);
  height: 20px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  margin-left: 3px;
  box-shadow: inset 0 0 2px black;
  position: relative;
}

.vul div {
  height: 100%;
  box-shadow: inset 0 0 2px 1px black;
}

.vul span {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  color: white;
}

.detailItemInfoBody tr:hover {
  background: rgba(179, 179, 179, 0.5);
}

.weapon_standard_size {
  width: calc(33% - 3px);
  height: 20px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  margin-left: 3px;
  box-shadow: inset 0 0 2px black;
  position: relative;
  color: white;
  text-align: center;
  line-height: 21px;
}

.UIThoriumSlot {
  width: 50px;
  height: 50px;
  float: left;
  background: rgba(75, 64, 76, 0.4);
  margin: 3px;
  box-shadow: inset 0 0 2px black;
  position: relative;
  text-align: center;
}

.UIThoriumSlot .itemIconInventoryCell {
  opacity: 0.3;
  pointer-events: none;
  z-index: 0;
}

.UIThoriumSlotCount {
  margin-left: 2px;
}

.UIThoriumSlotCount div {
  height: 25px;
  width: 25px;
  background-position: center;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.1);
  float: left;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0));
}

.UIThoriumSlot span {
  position: relative;
  z-index: 1;
  line-height: 25px;
  top: 30px;
  left: 10px;
}

h4 {
  margin: 0;
  width: calc(100% - 14px);
  padding: 2px;
  background: #df6f2c;
  padding-left: 15px;
  box-shadow: 0 0 2px black;
  font-size: 12px;
  clear: both;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
}

.detailItemBpBlockRow {
  background: rgba(0, 0, 0, 0.2);
  float: left;
  width: calc(100% - 4px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 2px;
  transition: 200ms;
}

.detailItemBpBlockRow:hover {
  background: rgba(0, 0, 0, 0.1);
}

.detailItemBpBlockIcon {
  position: relative;
  height: 30px;
  width: 30px;
  float: left;
}

.detailItemBpBlockName {
  float: left;
  line-height: 30px;
  color: white;
  margin-left: 10px;
}

.detailItemBpBlockDetailButton {
  float: right;
  line-height: 23px;
  height: 20px;
  width: 20px;
  text-align: center;
  border: 2px solid rgb(173, 173, 173);
  border-radius: 50%;
  color: yellow;
  font-weight: 900;
  background: #138edc;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  margin-top: 3px;
  box-shadow: 0 0 4px black;
}

.detailItemBpBlockDetailButton:active {
  transform: scale(0.95);
}

.detailItemBpBlockDetailButton:hover {
  box-shadow: 0 0 4px white;
  color: white;
}
</style>
