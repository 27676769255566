import { render, staticRenderFns } from "./Npc.vue?vue&type=template&id=6b4957bd&scoped=true"
import script from "./Npc.vue?vue&type=script&lang=js"
export * from "./Npc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4957bd",
  null
  
)

export default component.exports