import {gameStore} from "../store";
import {ClearBars} from "../interface/status_layer";
import {unitRemoveInfo} from "./mouse_body_over";
import {updateEnergy, updateHP} from "../interface/status_bar";
import {RemoveSuicideSound} from "../equip/suicide";
import {Scene} from "../create";

// удалять эммиторы делает микрофризы, поэтому мы их переиспользуем
let antigravityEngages = {
  antigravityPlace: [],
  backEngine: [],
  rightEngine: [],
  leftEngine: [],
}

let cacheBody = {};
let cacheWeapon = {};
let cacheEquip = {};
let cacheLights = [];

function removeUnit(unit) {
  if (unit.oldPoint) {
    while (0 < unit.oldPoint.length) {
      let label = unit.oldPoint.shift();
      if (label) label.destroy();
    }
  }

  let key = unit.body_state.texture

  if (!cacheBody[key]) {
    cacheBody[key] = {
      shadow: [],
      bodyBottomLeftShadow: [],
      bodyBottomRightShadow: [],
      bodyBottom: [],
      bodyBottomRight: [],
      bodyBottomLeft: [],
      sprite: [],
      damage: [],
    };
  }

  if (unit.sprite.shadow) {
    unit.sprite.shadow.setVisible(false);
    cacheBody[key].shadow.push(unit.sprite.shadow);
  }

  if (unit.sprite.bodyBottomLeftShadow) {
    unit.sprite.bodyBottomLeftShadow.setVisible(false);
    cacheBody[key].bodyBottomLeftShadow.push(unit.sprite.bodyBottomLeftShadow);
  }

  if (unit.sprite.bodyBottomRightShadow) {
    unit.sprite.bodyBottomRightShadow.setVisible(false);
    cacheBody[key].bodyBottomRightShadow.push(unit.sprite.bodyBottomRightShadow);
  }

  if (unit.sprite.bodyBottom) {
    unit.sprite.bodyBottom.anims.pause();
    unit.sprite.bodyBottom.setVisible(false);
    cacheBody[key].bodyBottom.push(unit.sprite.bodyBottom);
  }

  if (unit.sprite.bodyBottomRight) {
    unit.sprite.bodyBottomRight.anims.pause();
    unit.sprite.bodyBottomRight.setVisible(false);
    cacheBody[key].bodyBottomRight.push(unit.sprite.bodyBottomRight);
  }

  if (unit.sprite.bodyBottomLeft) {
    unit.sprite.bodyBottomLeft.anims.pause();
    unit.sprite.bodyBottomLeft.setVisible(false);
    cacheBody[key].bodyBottomLeft.push(unit.sprite.bodyBottomLeft);
  }

  if (unit.sprite.damage) {
    unit.sprite.damage.clear();
    unit.sprite.damage.setVisible(false);
    cacheBody[key].damage.push(unit.sprite.damage);
  }

  if (unit.sprite) {
    unit.sprite.setVisible(false);
    cacheBody[key].sprite.push(unit.sprite);
  }

  for (let e of unit.equips) {
    if (e.sprite) {
      if (!cacheEquip[e.name]) cacheEquip[e.name] = [];
      e.sprite.setVisible(false);
      cacheEquip[e.name].push(e.sprite);
    }
  }

  for (let i in unit.lights) {
    if (i.includes('out')) {
      Scene.lights.removeLight(unit.lights[i]);
    } else {
      unit.lights[i].destroy();
    }
  }

  for (let i in unit.weapons) {
    if (!cacheWeapon[unit.weapons[i].name]) cacheWeapon[unit.weapons[i].name] = [];
    unit.weapons[i].weapon.setVisible(false);
    cacheWeapon[unit.weapons[i].name].push(unit.weapons[i].weapon);
  }

  if (unit.sprite.antigravityPlace) {
    unit.sprite.antigravityPlace.stop();
    antigravityEngages.antigravityPlace.push(unit.sprite.antigravityPlace);
  }
  if (unit.sprite.backEngine) {
    unit.sprite.backEngine.stop();
    antigravityEngages.backEngine.push(unit.sprite.backEngine);
  }
  if (unit.sprite.rightEngine) {
    unit.sprite.rightEngine.stop();
    antigravityEngages.rightEngine.push(unit.sprite.rightEngine);
  }
  if (unit.sprite.leftEngine) {
    unit.sprite.leftEngine.stop();
    antigravityEngages.leftEngine.push(unit.sprite.leftEngine);
  }

  if (gameStore.playerID === unit.owner_id) {
    updateHP(0)
    updateEnergy(0)
  }

  if (unit.sprite.RadarMark) {
    unit.sprite.RadarMark.setVisible(false);
  }

  if (unit.engigeSound) {
    unit.engigeSound.stop();
    unit.engigeSound.destroy();
  }

  if (unit.antigravityBack) {
    unit.antigravityBack.stop();
    unit.antigravityBack.destroy();
  }

  if (unit.afterburnerSound) {
    unit.afterburnerSound.stop();
    unit.afterburnerSound.destroy();
  }

  if (gameStore.playerID === unit.owner_id) {
    RemoveSuicideSound()
  }

  if (unit.colision) {
    unit.colision.destroy();
  }

  if (unit.userLabel) {
    unit.userLabel.remove();
  }

  if (unit.sprite.passUnitSelectSprite) {
    unit.sprite.passUnitSelectSprite.destroy()
  }

  if (unit.sprite.noPassUnitSelectSprite) {
    unit.sprite.noPassUnitSelectSprite.destroy()
  }

  ClearBars('unit', unit.id, 'hp');
  ClearBars('unit', unit.id, 'shield');
  ClearBars('unit', unit.id, 'shield', 3);

  let gameUnit = gameStore.units[unit.id];
  if (gameUnit) {
    clearInterval(gameUnit.updaterPos);
    if (gameUnit.sprite.userName) {
      if (gameUnit.sprite.userName.login) unit.sprite.userName.login.destroy();
      if (gameUnit.sprite.userName.pointer) unit.sprite.userName.pointer.destroy();
      if (gameUnit.sprite.userName.attitude) unit.sprite.userName.attitude.destroy();
      if (gameUnit.sprite.userName.detail) unit.sprite.userName.detail.destroy();
      if (gameUnit.sprite.userName.pk) unit.sprite.userName.pk.destroy();
    }
  }

  unitRemoveInfo(unit, unit.sprite, true);
  delete gameStore.units[unit.id]
}

export {removeUnit, antigravityEngages, cacheBody, cacheWeapon, cacheEquip, cacheLights}
