<template>
  <form autocomplete="off">
    <textarea placeholder="message..." id="chatInput"
              :class="{select: selectChat}"
              @focus="focus"
              @blur="blur"
              @input="validate"
              v-bind:disabled="disabled"
              v-model="newMessage"
              @keydown.13="sendMessage"/>
  </form>
</template>

<script>
import {gameStore} from "../../game/store";

export default {
  name: "Input",
  props: ['currentChatID', 'disabled'],
  data() {
    return {
      newMessage: null,
      selectChat: false,
    }
  },
  methods: {
    validate() {
      this.newMessage = this.newMessage.trimStart();
    },
    focus() {
      this.selectChat = true
      gameStore.HoldKeys = true
    },
    blur() {
      this.selectChat = false
      gameStore.HoldKeys = false
    },
    sendMessage() {
      if (this.newMessage !== "") {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "chat",
          data: {
            event: "NewChatMessage",
            message_text: this.newMessage.trimStart(),
            id: this.$props.currentChatID,
          }
        }));

        this.newMessage = null;
      }
    },
  }
}
</script>

<style scoped>
textarea {
  width: 284px;
  height: 31px;
  border-radius: 0 0 5px 5px;
  border: 0;
  margin-left: 3px;
  box-shadow: inset 0 0 3px black;
  background-size: 100% 3px;
  text-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
  resize: none;
  font-weight: 900;
  background: rgba(148, 166, 179, 0.3);
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}

textarea:disabled {
  color: rgba(0, 0, 0, 0.4);
}

#chatInput.select {
  background: rgba(148, 166, 179, 0.5);
}
</style>
