var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"notifyBlock"}},[_c('div',{staticClass:"texture_wrapper"}),_vm._v(" "),(_vm.notifys.length > 0)?_c('div',{staticClass:"wrapNotifyBlock"},[_vm._l((_vm.notifys),function(notify){return [_c('div',{staticClass:"pic_wrapper cursor_hover"},[(_vm.isToUser(notify) || (notify.answers && notify.answers.length > 0))?[_c('div',{staticClass:"notify_pic",class:{
                 icon: _vm.getNotifyImage(notify),
                 toUser: _vm.isToUser(notify),
                 danger: notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna'),
                 action_end: notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0),
                 animate: _vm.isToUser(notify) || notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna') ||
                 notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0)
               },style:({
                 backgroundColor: _vm.getNotifyColor(notify),
                 backgroundImage: _vm.getNotifyImage(notify),
               }),on:{"click":function($event){return _vm.deleteNotify(notify, true)},"mouseover":function($event){return _vm.setShow(notify.uuid)},"mouseout":function($event){return _vm.slowHide()}}}),_vm._v(" "),(_vm.show(notify))?_c('app-notify',{attrs:{"notify":notify},nativeOn:{"mouseover":function($event){return _vm.setForce(notify.uuid)},"mouseout":function($event){$event.stopPropagation();return _vm.forceHide($event)}}}):_vm._e()]:[_c('div',{staticClass:"notify_pic",class:{
                 icon: _vm.getNotifyImage(notify),
                 toUser: _vm.isToUser(notify),
                 danger: notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna'),
                 action_end: notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0),
                 animate: _vm.isToUser(notify) || notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna') ||
                   notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0)
               },style:({
           backgroundColor: _vm.getNotifyColor(notify),
           backgroundImage: _vm.getNotifyImage(notify),
         }),on:{"click":function($event){return _vm.deleteNotify(notify, true)},"mouseover":function($event){return _vm.show2(notify.uuid)},"blur":function($event){_vm.showNotify = notify.uuid},"focus":function($event){_vm.showNotify = notify.uuid},"mouseenter":function($event){_vm.showNotify = notify.uuid},"mousemove":function($event){_vm.showNotify = notify.uuid},"mouseout":function($event){_vm.showNotify = ''}}}),_vm._v(" "),(_vm.show(notify))?_c('app-notify',{attrs:{"notify":notify},nativeOn:{"mouseover":function($event){_vm.forceShowNotify = notify.uuid},"blur":function($event){_vm.forceShowNotify = notify.uuid},"focus":function($event){_vm.forceShowNotify = notify.uuid},"mouseenter":function($event){_vm.forceShowNotify = notify.uuid},"mousemove":function($event){_vm.forceShowNotify = notify.uuid},"mouseout":function($event){_vm.forceShowNotify = ''}}}):_vm._e()]],2)]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }