<template>
  <div class="wrapper">
    <table class="audit_table">
      <tr>
        <th style="width: 21%">Время</th>
        <th style="width: 25%">Операция</th>
        <th style="width: 22%">Исполнитель</th>
        <th>Payload</th>
      </tr>

      <tr v-for="log in logs">
        <td style="text-align: center">{{ parseDate(log.time) }}</td>
        <td>{{ getOperationName(log.event) }}</td>
        <td class="executor"><span class="importantly">{{ getExecutorName(log.data) }}</span></td>
        <td v-html="formatData(log.event, log.data)"></td>
      </tr>
    </table>

    <div style="float: left; width: 100%; height: 23px;">
      <input type="button"
             value="Назад"
             class="cluster_button cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="back">

      <input type="button"
             value="В начало"
             class="cluster_button cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="fullBack">

      <input type="button"
             style="float: right"
             value="Далее"
             class="cluster_button cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="next">
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  data() {
    return {
      cursor: 0,
      limit: 20,
      operation: '',
    }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    getLogs() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "GetCorporationLogs",
          cursor: this.cursor,
          limit: this.limit,
          tag: this.operation,
        }
      }));
    },
    parseDate(str) {
      let d = new Date(str);
      let minute = d.getMinutes()
      let hours = d.getHours()
      let day = d.getDate()
      let month = d.getMonth() + 1

      if (minute < 10) minute = '0' + minute;
      if (hours < 10) hours = '0' + hours;
      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      if (2500 < d.getFullYear()) {
        return "Вечно"
      }

      return day + "-" + month + "-" + d.getFullYear() + " " + hours + ":" + minute;
    },
    getOperationName(operation) {
      if (operation === "build_structure_complete") return 'Завершено строительство'
      if (operation === "change_player_role") return 'Установка роли игроку'
      if (operation === "change_role") return 'Изменение роли'
      if (operation === "place_corporation_structure") return 'Установка структуры'
      if (operation === "change_global_policy") return 'Изменение глобальной политики'
      if (operation === "create_invite_request") return 'Создана заявка на вступление'
      if (operation === "corp_reject_invite_request") return 'Отклонена заявка на вступление'
      if (operation === "accept_invite_request") return 'Одобрена заявка на вступление'
      if (operation === "add_contact_200") return 'Добавление соперника'
      if (operation === "add_contact_-200") return 'Добавление союзника'
      if (operation === "remove_contact_-200") return 'Удаление союзника'
      if (operation === "remove_contact_200") return 'Удаление соперника'
      if (operation === "new_member") return 'Новый член кластера'
      if (operation === "kick_true") return 'Игрок покинул кластер'
      if (operation === "kick_false") return 'Игрока выгнали'


      return operation
    },
    getExecutorName(data) {
      let o = JSON.parse(data)
      if (o.hasOwnProperty('executor_name')) {
        return o.executor_name
      }

      return 'Система'
    },
    formatData(operation, data) {
      let o = JSON.parse(data)

      if (operation === "change_player_role") {
        return `Игроку <span class="importantly">${o.member_name}</span> была назначена роль <span class="importantly">${o.new_role.name}</span>`
      }

      if (["add_contact_-200", "add_contact_200", "remove_contact_-200", "remove_contact_200"].includes(operation)) {
        return `${this.texts[o.type][this.language]}: <span class="importantly">${o.contact_name}</span>`
      }

      if (["create_invite_request"].includes(operation)) {
        return `от игрока: <span class="importantly">${o.request.player_name}</span>`
      }

      if (["corp_reject_invite_request", "accept_invite_request"].includes(operation)) {
        return `для игрока: <span class="importantly">${o.request.player_name}</span>`
      }

      if (["new_member"].includes(operation)) {
        return `игрок: <span class="importantly">${o.new_member_name}</span>`
      }

      if (["kick_true", "kick_false"].includes(operation)) {
        return `игрок: <span class="importantly">${o.kick_name}</span>`
      }

      if (["change_global_policy"].includes(operation)) {
        return `параметр: <span class="importantly">${this.texts[o.parameter][this.language]}</span>, новое значение: <span class="importantly">${o.new_value}</span>`
      }

      return o
    },
    next() {
      if (this.logs.length > 0) {
        this.cursor = this.logs[this.logs.length - 1].id
        this.operation = ''
        this.getLogs()
      }
    },
    back() {
      this.operation = '>'
      this.getLogs()
    },
    fullBack() {
      this.cursor = 0;
      this.operation = '';
      this.getLogs()
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    logs() {
      return this.$store.getters.getCorporationLogs
    }
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
}

.audit_table {
  width: calc(100% + 13px);
  border-spacing: 0;
  word-wrap: break-word;
  table-layout: fixed;
  background: rgba(0, 0, 10, 0.4);
  margin: -6px 0 0 -6px;
}

.audit_table tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  user-select: none;
  margin: 2px auto;
  padding-top: 2px;
}

.audit_table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  padding-top: 4px;
  font-size: 13px;
  position: relative;
}

.audit_table td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.executor {
  text-align: center;
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  user-select: none;
  width: 75px;
  float: left;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}
</style>
