var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Модификаторы")]),_vm._v(" "),_c('p',[_vm._v("У каждого корпуса теперь есть три слота под установку мода, которые отличаются от экипировки:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mods_1', 'png')+ ')',
          width: '400px',
          height: '270px',
          marginLeft: 'calc(50% - 200px)',
          })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Расширение слотов")]),_vm._v(" "),_c('p',[_vm._v("На базе можно арендовать фрезерный станок, что бы в корпусе проделать дырку под новое снаряжение 🙂")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mods_2', 'gif')+ ')',
          width: '400px',
          height: '285px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Modifiers")]),_vm._v(" "),_c('p',[_vm._v("Each body now has three slots for installing mods, which differ from equipment:")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mods_1', 'png')+ ')',
          width: '400px',
          height: '270px',
          marginLeft: 'calc(50% - 200px)',
          })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Slot Expansion")]),_vm._v(" "),_c('p',[_vm._v("At the base, you can rent a milling machine to make a hole in the body for new equipment 🙂")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mods_2', 'gif')+ ')',
          width: '400px',
          height: '285px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("они не потребляют энергию;")]),_vm._v(" "),_c('li',[_vm._v("помимо бафа модуль даёт существенный дебаф (например: +25% к здоровью и -25% трюма);")]),_vm._v(" "),_c('li',[_vm._v("если снять установленный модуль, он сломается, а если упаковать машинку — сломаются все модули.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("можно установить только 1 слот;")]),_vm._v(" "),_c('li',[_vm._v("слот 1го уровня дает дебаф на 15% по хп, слот 2го на 25%;")]),_vm._v(" "),_c('li',[_vm._v("убрать слот можно только через упаковку транспорта (то есть сломаются все моды если такие есть);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("they do not consume energy;")]),_vm._v(" "),_c('li',[_vm._v("in addition to a buff, the module gives a significant debuff (for example: +25% to health and -25% to cargo hold);")]),_vm._v(" "),_c('li',[_vm._v("if you remove an installed module, it will break, and if you pack the machine, all modules will break.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("only 1 slot can be installed;")]),_vm._v(" "),_c('li',[_vm._v("a level 1 slot gives a 15% debuff to HP, a level 2 slot gives a 25% debuff;")]),_vm._v(" "),_c('li',[_vm._v("the slot can only be removed by packing the vehicle (which means all mods will break if there are any).")])])
}]

export { render, staticRenderFns }