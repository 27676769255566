import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";
import {GreenExplosion} from "../weapon/explosion";

function unitShieldAnimate(x, y, r, ownerSprite, destroyShield) {
  if (!ownerSprite.explode_mask) return;

  let scale = r * 15
  if (scale < 25) {
    scale = 25
  }

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  GreenExplosion(pos.x, pos.y, 250, 1, scale, 25, 0, 360, 500, null, {start: 1, end: 1}, ownerSprite.explode_mask.mask);

  if (destroyShield) {
    // TODO звук
    GreenExplosion(ownerSprite.x, ownerSprite.y, 1000, 5, 75, 55, 0, 360, 500, null, {start: 0.5, end: 0}, null);
  }
}

function shieldOn(id) {
  let unit = gameStore.units[id]
  if (!unit || !unit.sprite || !unit.sprite.explode_mask) return;

  // TODO звук
  GreenExplosion(unit.sprite.x, unit.sprite.y, 750, 1, 500, 0, 0, 360, 500, null, {
    start: 0.5,
    end: 0
  }, unit.sprite.explode_mask.mask);
}

export {unitShieldAnimate, shieldOn}
