<template>
  <div class="index_background">
    <div class="scroll_div">

      <header>
        <div class="WrapLeftTopBlock">
          <div class="leftTopBlock">
            Server online
          </div>
        </div>

        <div class="pre_alpha_wrapper">
          <div class="pre_alpha">
            Pre-Alpha
          </div>
        </div>

        <div class="play_button_wrapper cursor_hover">
          <div class="play_button" @click="to('/gate')">
            PLAY
          </div>
        </div>

        <div class="lang_button_wrapper">
          <div :class="{select: lang === 'RU'}" class="cursor_hover" @click="changeLang('RU')"></div>
          <div class="EN cursor_hover" :class="{select: lang === 'EN'}" @click="changeLang('EN')"></div>
        </div>

        <div class="WrapRightTopBlock">
          <div class="rightTopBlock" style="color: white">
            ... ...
          </div>
        </div>
      </header>

      <div class="logo"/>

      <router-view/>

      <footer>
        <div id="about">
          <div class="mail_a cursor_hover">
            <a class="no_a" href="mailto:trashpony13@gmail.com">TrashPony13@gmail.com</a>
          </div>
          <div style="
             float: left;
             margin-left: 10px;">
            <a href="https://discord.gg/GVZbgvXt5E" class="cursor_hover" target="_blank">
              <div class="social discord"></div>
            </a>
            <a href="https://t.me/+xkM9J19oR9E2MDEy" class="cursor_hover" target="_blank">
              <div class="social telegram"></div>
            </a>
            <a href="https://vk.com/veliri" class="cursor_hover" target="_blank">
              <div class="social vk"></div>
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "Index",
  methods: {
    to(url) {
      this.$router.push(url)
    },
    changeLang(lang) {
      this.$store.commit({
        type: 'setLang',
        lang: lang,
      });
    }
  },
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
body {
  background: #000;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  text-align: justify;
  text-shadow: 0 0 2px #343434, 2px 1px 0 hsl(0, 0%, 27%), 2px 1px 1px hsl(0, 0%, 23%), 1px 1px 2px hsl(80, 10%, 15%);
  color: white;
}

.index_background {
  background: url('../../assets/bases/base.jpg') center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  pointer-events: auto;
}

.logo {
  /*background: url('../../assets/logo/Veliri_logo_Colour_12.png') left no-repeat;*/
  background-size: contain;
  background-position: 5% center;
  height: 100px;
  width: 1100px;
  margin: auto;
  filter: drop-shadow(2px 2px 0px black);
}

.scroll_div {
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

section {
  margin: auto;
}

a {
  color: #0cfdff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #ffc588;
}

main {
  padding-bottom: 25px;
  /*flex: 1 0 auto;*/
}

footer {
  box-shadow: 0 0 2px #000;
  /*flex: 0 0 auto;*/
  position: fixed;
  bottom: 0;
  width: 100%;
  border: 1px solid rgba(37, 160, 225, 0.75);
  background: rgba(8, 138, 210, 0.75);
}

header {
  border-bottom: 1px solid rgba(37, 160, 225, 0.75);
  background: rgba(8, 138, 210, 0.75);
  box-shadow: 0 0 2px #000;
  height: 35px;
  width: 100%;
  z-index: 100;
  position: relative;
}

#features #features-container .feature {
  box-shadow: inset 0 0 8px rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.2);
  width: 20%;
  border-radius: 15px;
  color: white;
}

#features #features-container .feature span {
  padding: 10px;
}

#features #features-container .feature div {
  margin: 15px auto 10px;
  height: 100px;
  width: 100px;
  background: grey;
  border-radius: 5px;
  border: 1px solid grey;
  box-shadow: 0 0 8px rgb(0, 0, 0);
}

footer #about {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.play_button {
  display: block;
  pointer-events: auto;
  text-align: center;
  transition: .1s;
  box-shadow: 0 0 2px #000;
  /* text-indent: 0; */
  height: 26px;
  width: 100px;
  background: rgba(255, 129, 0, .75);
  margin: 2px;
  border-radius: 5px;
  color: #fff;
  line-height: 28px;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 0 -1px 1px #000, 0 -1px 1px #000, 0 1px 1px #000, 0 1px 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000;
}

.play_button:active {
  transform: scale(.98);
}

.play_button:hover {
  background: #ff8100;
}

.play_button_wrapper {
  position: absolute;
  top: 3px;
  left: calc(50% - 50px);
}

.WrapLeftTopBlock, .WrapRightTopBlock {
  height: 30px;
  width: 15%;
  border: 1px solid rgba(37, 160, 225, .2);
  background: rgba(17, 73, 105, 0.60);
  box-shadow: inset 0 0 2px #00d7ff;
  border-radius: 0 0 50px 50px;
  float: left;
  margin-left: 20%;
  filter: drop-shadow(0 0 2px black);
}

.WrapRightTopBlock {
  float: right;
  margin-right: 20%;
}

.leftTopBlock, .rightTopBlock {
  font-weight: bold;
  height: 27px;
  clear: both;
  background: rgba(8, 138, 210, .5);
  border-radius: 0 0 50px 50px;
  margin: 2px;
  line-height: 27px;
  color: #7ebc3d;
  box-shadow: inset 0 0 2px black;
  text-align: center;
  text-shadow: 0 -1px 1px #000, 0 -1px 1px #000, 0 1px 1px #000, 0 1px 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000;
}

.lang_button_wrapper, .pre_alpha_wrapper {
  float: left;
  height: 27px;
  background: rgba(8, 138, 210, .5);
  border-radius: 5px;
  margin: 2px;
  line-height: 27px;
  box-shadow: inset 0 0 2px black;
  text-align: center;
  position: absolute;
  width: 60px;
  top: 3px;
  left: calc(50% - -60px);
}

.pre_alpha_wrapper {
  left: calc(50% - 122px);;
}

.lang_button_wrapper div {
  background-image: url('../../assets/icons/RU_language.png');
  float: left;
  width: 21px;
  height: 21px;
  margin: 3px 2px 2px 5px;
  background-position: center;
  background-size: contain;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 black;
  opacity: 0.5;
}

.lang_button_wrapper div.EN {
  background-image: url('../../assets/icons/EN_language.png');
}

.lang_button_wrapper div:hover {
  opacity: 0.8;
}

.lang_button_wrapper div.select {
  opacity: 1 !important;
}

@media (max-width: 1122px) {
  .WrapLeftTopBlock, .WrapRightTopBlock {
    width: 25%;
    margin-left: 10%;
  }

  .WrapRightTopBlock {
    margin-right: 10%;
  }
}

@media (max-width: 600px) {
  .WrapLeftTopBlock, .WrapRightTopBlock {
    width: 32%;
    margin-left: 3%;
  }

  .WrapRightTopBlock {
    margin-right: 3%;
  }
}

@media (max-width: 470px) {
  .WrapLeftTopBlock, .WrapRightTopBlock {
    display: none;
  }
}

.pre_alpha {
  color: #ff4c4c;
  text-align: center;
  font-size: 10px;
  text-shadow: 0 -1px 1px #000, 0 -1px 1px #000, 0 1px 1px #000, 0 1px 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px 0 1px #000, 1px 0 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000, 1px 1px 1px #000;
  font-weight: bold;
}

.social {
  float: left;
  width: 24px;
  height: 24px;
  background-size: contain;
  margin-left: 5px;
  filter: drop-shadow(1px 1px 1px black);
}

.social:hover {
  filter: drop-shadow(1px 1px 1px white);
}

.discord {
  background-image: url('../../assets/indexImg/logo_discord.png');
}

.telegram {
  background-image: url('../../assets/indexImg/logo_telegram.png');
}

.vk {
  background-image: url('../../assets/indexImg/logo_vk.png');
}

.no_a {
  color: whitesmoke;
  text-decoration: none;
}

.no_a:hover {
  text-decoration: none;
}

.mail_a {
  text-shadow: 1px 1px 1px black;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #a0a0a0;
  float: left;
  margin-left: calc(50% - 80px);
}
</style>
