const getDefaultState = () => {
  return {
    init: {
      items: false,
    },
    Lang: null,
    Steam: {steam: false, status: 0},
    token: "",
    CheckViewPort: 1,
    ws: {
      connect: false,
      error: false,
      ws: null,
    },
    node_ws: {
      connect: false,
      error: false,
      ws: null,
    },
    GameUser: {},
    UserPlayers: [],
    Player: {},
    training: {
      point: 0,
      needIntoHangar: false,
    },
    Settings: {
      playMusic: true,
      FollowCamera: true,
      ZoomCamera: 1,
      volume: 0.1,
      SFXVolume: 0.1,
      Language: "RU",
      LazyCamera: true,
      ShowNpcDialog: true,
      ZoomInterface: 0,
    },
    EndLoad: {
      visible: false,
      text: '',
    },
    /** Notifications[id] = {html: template} **/
    Notifications: {},
    WaitGame: {
      usersWait: 0,
      MyWait: null,
    },
    SessionGame: {
      players: {},
      playersStatus: null,
      reward: null,
      loses: null,
    },
    // тут лежит все что связано с игровыми ИНТЕРФЕЙСАМИ игровые обьекты лежат в сторедже самой игры game/storage
    Game: {
      thorium_slots: null,
      lowPower: false,
      anomalies: null,
      role: "",
      sector: {
        base: null,
        capture_time: null,
        name: "",
        id: 0,
        detail: null,
        fraction: "",
        free_land: false,
        battle: false,
        transports: 0,
      },
      open_objects: {},
      availableAmmo: {},
      current_speed: 0,
      secure_zone: false,
      global_target: null,
    },
    OpenBuildObject: {},
    TargetData: {
      history: [],
      overload_ascs: [], // варианты ответа в диалоге которые пришли из "запроса"
      overload_ascs_uuid: "",
    },
    AttackDialog: null,
    Battle: null,
    KillsNotify: [],
    PointsNotify: [],
    Glitch: null,
    // [body_id]:state
    SynchronizationState: {},
    Trailer: {open: false, sound: "intro_1.mp3", url: './assets/movies/intro_1.mp4', glitchOff: true},
    Chat: {
      currentChatID: 0,
      groups: {},
      users: {},
      notifys: {},
      allGroups: {},
      allGroupsCountPlayers: {},
      friends: [],
      avatars: {},
      npc_animate: false,
      group: {
        /** тут хранится вся мета отряда игрока (пати/группа) **/
        members: {},
        leaderID: 0,
        state: null,
        uuid: "",
        online: {},
      },
      sessionGroup: {
        /** тут хранится вся мета отряда игрока (пати/группа) для сессионных игр **/
        members: {},
        leaderID: 0,
        state: null,
        uuid: "",
      },
      corporation: {
        offices: {},
        bases: {},
        contacts: [],
        localPolicy: [],
      }, /** тут хранится вся мета корпорации игрока **/
      corporations: {
        current: [],
      }, /** все корпорации в игре **/
      corporationBaseNames: {},
      corporationsLogo: {},
      violator: {},
      exitTime: null,
      vioType: null,
      vioTime: null,
      rankUpdate: null,
      distressSignals: {},
      fractionNews: {},
    },
    /** скилы игрока **/
    mySkills: {
      skills: {},
      points: 0,
    },
    Skins: {},
    Market: {
      preloader: false,
      history: [],
      base_market_tax: {},
      filterItems: [],
      assortment: {
        ammo: null,
        blueprints: null,
        body: null,
        boxes: null,
        detail: null,
        equip: null,
        recycle: null,
        resource: null,
        trash: null,
        weapon: null,
        product: null,
      },
      orders: null,
      my_orders: [],
      my_credits: 0,
      init_credits: false,
      count_items: {}, // что бы игрок знал сколько у него итемов на складе под каждый ордер
      filters: {
        // указывает на фильтр по которому будет ариентироватся таблицы заказов
        selectType: '', // ['ammo', 'body', 'weapon', 'equip', 'resource', 'blueprints', 'boxes', 'trash']
        item: null,
        ammo: {
          type: '', // ['laser', 'missile', 'firearms']
          size: 0,  // [1, 2, 3]
          id: 0,
        },
        weapon: {
          type: '', // ['laser', 'missile', 'firearms']
          size: 0,  // [1, 2, 3]
          id: 0,
        },
        body: {
          type: '',
          size: 0,  // [1, 2, 3]
          id: 0,
        },
        equip: {
          type: '',
          size: 0,  // [1, 2, 3]
          id: 0,
        },
        resource: {
          type: '',  // [resource, recycle, detail]
          size: 0,
          id: 0,
        },
        blueprints: {
          type: '', //  ['ammo', 'body', 'weapon', 'equip', 'detail', 'boxes', 'map']
          size: 0,
          id: 0,
        },
        boxes: {
          type: '',
          size: 0,
          id: 0,
        },
        trash: {
          type: '',
          size: 0,
          id: 0,
        },
        product: {
          type: '',
          size: 0,
          id: 0,
        },
        fuel: {
          type: '',
          size: 0,
          id: 0,
        },
      }
    },
    Inventory: {
      warehouse: "", // squadInventory, storage, box:ID
      warehouses: {},
      equipPanel: null,
      // selectEquip: null,
      cellSize: 64,
      filters: {category: "all"},
      addSlotDialogData: null,
      /** используется что бы подсвечивать допустимые ячейки в инвентаре, примеры использования по типам **/
      // type: 'equip', type_slot: [1,2,3], mining: [false, true]
      // type: 'weapon', standard_size: {small:[false, true], medium:[false, true], big:[false, true]}
      // type: 'ammo', type: ['laser', 'firearms', 'missile'], standard_size: [1,2,3]
      // type: 'body'
      findFilter: null,

      inBase: 0,
      unit: null,
      squadName: null,
      stateMS: null,
      baseSquads: null,
      placeDialog: null,
      effects: [],
      // --
      squadConfigs: [],
      checkItems: [],
    },
    Damage: false,
    CriticalDamage: false,
    /** если у игрока открыт диалог то автоматически откроется окно с активной странице **/
    OpenDialog: {
      page: null,
      mission: null,
      visited_pages: null,
    },
    /** остлеживает текущее состояние миссии и обновляется всегда когда происходят изменения **/
    Missions: {
      missions: null,
      currentMissionUUID: null,
      currentAction: null,
      helper: null, /** обьект который позволяет видеть на мини карте или даже в игре подсказки по мисии **/
      points: null,
    },
    /** для отображения подробной информации по миссиям в меню игрока **/
    AllFullMissions: {},
    WorldMap: {
      userMapID: null,
      maps: null,
      defenders: {},
      previewPath: null,
      mapInfo: {}, /** подробная информация о каждой карте [id] {bases: , etc...}**/
      background: null,
      regions: null,
      secureHandlers: {},
      global_path: {
        mapID: 0,
        baseID: 0,
        mission: '',
      },
      timeAttack: null,
    },
    PriorityWarMap: {
      attack: 0,
      defend: 0,
    },
    UsersStat: {
      users: {},
    },
    Interface: {
      resolution: $(window).width() + ':' + $(window).height(),
      state: {},
      allowIDs: {},
      openQueue: [],
    },
    /** когда игрок находится на базе то вот работает этот сервис, так сложилось что у него такое название **/
    Lobby: {
      base: {
        state: null,
        resources: null,
        fraction: null, // т.к. база может быть не центральная(заводы, установки) то фракции там может не быть
        exit: false,
        stories: {},
        products: {},
        tax: "плоти нолог",
        attr: {},
        base_attr: {},
        siege: false,
      },
      recycler: {
        recycle_slots: null,
        preview_recycle_slots: null,
        user_recycle_skill: null,
        lost_recycle_slots: null,
        tax_recycle_slots: null,
      },
      prefabricated_menu: {
        details: null,
        detail_need_items: null,
        detail_max_counts: null,
        detail_bps: null,
        select_detail: {detail: null, needItems: null, maxCount: null, count: null},
        tax: 0,
      },
      workbench: {
        blue_works: null,
        select_bp: {
          // bp - выбран блюпринт из инвентаря для того что бы создать, work - работа что бы посмотреть/отменить
          type_select: null,
          blue_print: null,
          bp_item: null,
          preview_recycle_slots: null,
          count: null,
          mineral_tax: null,
          time_tax: null,
          time: null,
          storage_slot: null,
          max_count: null,
          user_work_skill_detail_percent: null,
          blue_work: null,
          start_time: null,
          to_time: null,
        },
      },
    },
    BuildMenu: {
      points: null,
      overBP: null,
      selectBPID: 0,
      expandTip: false,
      maxEnergy: 0,
      currentEnergy: 0,
      selectDestroyTarget: null,
      currentLimit: {},
    },
    Wars: {},
    HeadquartersNames: {},
    CorporationLogs: {},
    NeedOpenComponents: {
      /** в общем т.к. тут один поток данных, а в моем интерфейсы мелкие компоненты должны уметь открыть
       более высокий уровень (модальники), то вот эта штука заставляет открывать компоненты
       тут хранятся обьекты с полями id: component:, component определяет тип окна **/
    },
    MapEditor: {
      maps: null,
      typeCoordinates: null,
    },
    DialogEditor: {
      dialogs: null,
    },
    MissionEditor: {
      missions: null,
      items: null,
      actions: null,
      rewardItems: null,
    },
    ServerState: {
      count_bots: 0,
      count_players: 0,
      count_calc_move: 0,
      count_bullets: 0,
      count_objects: 0,
      count_transports: 0,
      count_reservoirs: 0,
      count_boxes: 0,
      count_signals: 0,
      ai_move: 0,
      meteorite_wait_end: 0,
      maps_ticks: null,
      players: null,
      maps: null,
      maps_dynamic_objects: null,
      maps_reservoirs: null,
      maps_geo_zones: null,
      maps_boxes: null,
      maps_bullets: null,
      short_info_map: null,
      maps_units: null,
      maps_evacuations: null,
      maps_signals: null,
    },
    BotsState: {
      bots: null,
      bot: null,
    },
    RelateBonuses: null,
    HandBook: {}, // описания и названия предмедов на разных языках
    InterfaceHandBook: {},
    FractionStore: {
      assortment: {},
      user_fraction_points: 0,
    },
    Search: {},
    Products: null,
    Errors: {},
    HelpSelect: null,
    FillUp: {
      need: null,
      price: null,
    },
    ThrowItems: {
      open: false,
      slots: {},
      items: {},
      source: "",
    },
    Arm: {
      ammo_id: null,
      need: null,
      price: null,
      step: null,
    },
    Mails: {
      update: false,
      notOpenMails: 0,
    },
    DamageLog: [],
  }
};

export {getDefaultState}
