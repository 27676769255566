var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: снаряды")]),_vm._v(" "),_c('p',[_vm._v("Во время перезарядки оружия снаряды берутся из трюма вашего транспорта, что бы перенести снаряды со склада в\n      трюм\n      необходимо перенести снаряды на вкладку \"трюм\" в меню инвентаря.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_ammo_1', 'gif')+ ')',
          width: '352px',
          height: '184px',
          marginLeft: 'calc(50% - 176px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Ammunition")]),_vm._v(" "),_c('p',[_vm._v("During weapon reloading, ammunition is taken from the hold of your transport. To transfer ammunition from the\n      warehouse to the hold, it is necessary to move the ammunition to the \"hold\" tab in the inventory menu.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_ammo_1', 'gif')+ ')',
          width: '352px',
          height: '184px',
          marginLeft: 'calc(50% - 176px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }