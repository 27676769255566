<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h3 class="head">Задания</h3>

      <p>Задания - одна из возможностей по развитию игрока, приобретению им денег, репутации и опыта. </p>

      <p>Получить задание игрок может на любой базе, посредством меню базы в разделе - "Связь"->"Терминал".</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mission_3', 'png')+ ')',
            width: '226px',
            height: '85px',
            marginLeft: 'calc(50% - 113px)',
            }"/>

      <p>Сами задания представляют из себя набор диалогов и требований, которые игроку необходимо выполнить, прежде, чем
        получить свою награду. При желании, более подробно о заданиях (активной части), игрок может узнать в меню -
        "Личное дело" и вкладке "Задания".</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('mission_4', 'png')+ ')',
        width: '480px',
        height: '507px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

      <p>Чтобы отслеживать цели задания, игроку следует использовать меню миссий и пользоваться подсказками на
        мини-карте,
        если таковые имеются.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mission_2', 'png')+ ')',
            width: '459px',
            height: '225px',
            marginLeft: 'calc(50% - 230px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h3 class="head">Missions</h3>

      <p>Missions are one of the opportunities for player development, acquiring money, reputation, and experience.</p>

      <p>A player can receive a mission at any base, through the base menu in the section -
        "Communication"->"Terminal".</p>

      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('mission_3', 'png')+ ')',
      width: '226px',
      height: '85px',
      marginLeft: 'calc(50% - 113px)',
      }"/>

      <p>The missions themselves consist of a set of dialogues and requirements that the player must fulfill before
        receiving their reward. If desired, more details about the missions (active part) can be found in the menu -
        "Personal File" and the "Missions" tab.</p>

      <div class="detailImg" :style="{
    backgroundImage: 'url(' + $parent.getUrlImg('mission_4', 'png')+ ')',
    width: '480px',
    height: '507px',
    marginLeft: 'calc(50% - 240px)',
    }"/>

      <p>To track mission objectives, the player should use the mission menu and use hints on the mini-map, if
        available.</p>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('mission_2', 'png')+ ')',
      width: '459px',
      height: '225px',
      marginLeft: 'calc(50% - 230px)',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Missions",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
