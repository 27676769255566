<template>
  <div class="userLineWrapper">
    <div class="chatUserLine cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)">

      <div class="dialogExitShow" v-if="!empty && dialogExitShow" @click.stop="">
        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="closeDialog">
          <img :src="require('../../assets/icons/dont.png').default"/>
        </div>
        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="exitFunction">
          <img :src="require('../../assets/icons/ready.png').default"/>
        </div>
      </div>

      <div class="chatUserIcon" v-if="!empty" v-bind:style="{backgroundImage: 'url(\'' + logo + '\')'}"/>
      <div class="chatUserIcon" v-if="empty"
           v-bind:style="{backgroundImage: 'url(\'' + require('../../assets/icons/question-mark.png').default + '\')'}"/>

      <div class="chatUserName">
        {{ empty ? texts['no_cluster'][language] : corp.name }}
      </div>

      <div class="exitChatButton" v-if="!empty && buttonExit && !dialogExitShow" @click.stop="dialogExit">
        <img :src="require('../../assets/icons/group_exit.png').default"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CorporationLine",
  props: ['corp', 'logo', 'parent', 'exitFunc', 'buttonExit', 'arguments', 'empty'],
  data() {
    return {
      dialogExitShow: false,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    closeDialog() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogExitShow = false;
    },
    dialogExit() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogExitShow = true;
    },
    exitFunction() {
      this.playSound('button_press.mp3', 0.3)
      this.closeDialog();

      if (this.$props.exitFunc) {
        if (this.$props.arguments) this.$props.exitFunc.apply(null, this.$props.arguments);
        this.$props.exitFunc();
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
  },
}
</script>

<style scoped>

</style>

<style scoped>
.chatUserLine {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  min-height: 25px;
  color: #e6e1d8;
  font-size: 9pt;
  transition: 100ms;
  text-shadow: 1px 1px 1px black;
}

.chatUserLine:hover {
  background: rgba(0, 0, 0, 0.2);
}

.chatUserLine:active {
  background: rgba(214, 214, 214, 0.2);
}

.chatUserLine div {
  display: inline-block;
}

.chatUserIcon, .crown {
  height: 25px;
  width: 25px;
  background: rgba(0, 0, 0, 0.3);
  float: left;
  box-shadow: inset 0 0 5px black;
  background-size: cover;
  margin-right: 2px;
  border-radius: 3px;
}

.crown {
  background: rgba(0, 0, 0, 0.1);
  height: 21px;
  width: 21px;
  margin: 2px;
  box-shadow: none;
  border-radius: 3px;
  background-image: url("../../assets/icons/group_leader.png");
  background-size: contain;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

.chatUserName {
  line-height: 35px;
  height: 25px;
  margin-left: 2px;
}

.noActive {
  opacity: 0.25;
}

.noActive:hover {
  opacity: 0.75;
}

.dialogExitShow {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  width: 100%;
  z-index: 10;
}

.exitChatButton {
  position: relative;
}

.dialogExitShow div, .exitChatButton, .ready {
  height: 20px;
  width: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  float: right;
  margin: 2px;
  opacity: 0.4;
  transition: 200ms;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.dialogExitShow div {
  position: static;
}

.ready {
  right: 22px;
  opacity: 1;
}

.dialogExitShow div img, .exitChatButton img, .ready img {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.dialogExitShow div:hover, .exitChatButton:hover {
  border: 1px solid rgba(255, 242, 15, 0.7);
  background: rgba(255, 232, 0, 0.6);
  opacity: 0.8;
}

.dialogExitShow div:active {
  transform: scale(0.97);
}

.chatUserLine .additionalInfo {
  display: block;
  min-height: 25px;
  width: 100%;
  background: rgba(0, 255, 251, 0.2);
}

.additionalInfo table {
  font-size: 11px;
  color: #c1c1c1;
  width: calc(100% - 8px);
  margin: 2px auto;
}

.additionalInfo table tr td:first-child {
  text-align: right;
  width: 30px;
}

.bar_wrapper {
  width: 100%;
  height: 5px;
  border: 1px solid #4c4c4c;
  text-align: left;
  display: block;
  box-shadow: 0 0 2px black;
  border-radius: 10px;
  background-size: 12%;
  overflow: hidden;
  background-color: #959595;
  margin: 0 auto 0;
  position: relative;
}

#hp_bar, #power_bar {
  overflow: visible;
  background: rgba(72, 255, 40, 0.7);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: inset 0 0 2px black;
}

#power_bar {
  background: rgba(3, 245, 255, 0.7);
}

.userTitle, .userTitleChange {
  color: #ffef0f;
  margin-left: 5px;
}

.userTitleChange {
  float: right;
  margin: 4px 3px 0 3px;
}

.userTitleChange select {
  width: 100px;
  box-shadow: inset 0 0 1px 1px rgb(118, 118, 118);
  outline: none;
  border-radius: 5px;
  border: 0;
  background: #79a0b4;
  height: 17px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.userOnline {
  float: right;
  position: relative;
  margin-top: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  opacity: 0.7;
}

.userOnline div {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 50%;
  box-shadow: inset 0 0 2px #737373;
  border: 1px solid #737373;
}

.roleSelect {
  box-shadow: inset 0 0 1px 1px rgb(118, 118, 118);
  outline: none;
  width: 190px;
  border-radius: 5px;
  border: 0;
  background: #79a0b4;
  height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  margin: 3px;
  font-size: 11px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

select * {
  color: black;
  font-weight: bold;
}
</style>
<style>
.chatUserLine .additionalInfo .sizeInventoryInfo {
  display: block;
  width: 100%;
  box-shadow: 0 0 2px black;
  height: 5px;
}

.chatUserLine .additionalInfo .sizeInventoryInfo span {
  display: none;
}
</style>
