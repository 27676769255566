<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Контакты</h2>

      <p>Меню контактов позволяет добавлять контакты и друзей что бы в будущем их не терять. Так же можно заблокировать
        игрока что бы он не могу вам писать.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('friends', 'png')+ ')',
            width: '183px',
            height: '213px',
            marginLeft: 'calc(50% - 91px)',
            }"/>

      <p>Друзей от контактов отличает то что друзей видно когда они онлайн.</p>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Contacts</h2>

      <p>The contacts menu allows you to add contacts and friends so that you don't lose them in the future. You can
        also block
        a player so they can't write to you.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('friends', 'png')+ ')',
            width: '183px',
            height: '213px',
            marginLeft: 'calc(50% - 91px)',
            }"/>

      <p>The difference between friends and contacts is that you can see when friends are online.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Friends",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
