<template>
  <div class="roles">
    <div class="rolesTableWrap">
      <label> {{ texts['text_46'][language] }}
        <select v-model="role" class="cursor_hover">
          <option value="none">{{ texts['option_29'][language] }}</option>
          <option v-for="(role, key) in corporation.roles" :value="key">{{ role.name }}</option>
        </select>

        <input class="button cursor_hover" type="button" value="Удалить"
               :class="{disable: !myRole.edit_roles || (corporation.roles[role] && (corporation.roles[role].name === 'Member' || corporation.roles[role].name === 'Leader'))}"
               style="width: 60px; float: right; margin-bottom: 3px; position: relative; top: -2px"
               @click="removeRole">
      </label>

      <div class="rolesTableWrap2">
        <template v-if="corporation.roles && corporation.roles[role]">

          <h3 class="head"
              @click="common = !common">
            {{ common ? '▼' : '▶' }}
            {{ texts['text_47'][language] }}
          </h3>

          <table v-if="common"
                 class="rolesTable"
                 v-bind:class="{readOnly: corporation.roles[role].read_only || !myRole.edit_roles}">

            <tr>
              <td>{{ texts['text_48'][language] }}</td>
              <td>
                <input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].invite_users"
                       @change="changeRole(corporation.roles[role])">
              </td>
            </tr>

            <tr>
              <td>{{ texts['text_49'][language] }}</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].kick_users"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>{{ texts['text_50'][language] }}</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].edit_roles"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>{{ texts['text_51'][language] }}</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].change_role_user"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Аудит</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].audit"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Изменений глобальной политики</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].change_global_policy"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Аренда офисов</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].rental_office"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Управление войнами</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].wars"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Осады</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].attack_sectors"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

            <tr>
              <td>Захват секторов</td>
              <td><input type="checkbox" class="cursor_hover" v-model="corporation.roles[role].rental_sectors"
                         @change="changeRole(corporation.roles[role])"></td>
            </tr>

          </table>
          <h3 class="head" @click="dep = !dep">
            {{ dep ? '▼' : '▶' }}
            {{ texts['text_52'][language] }}</h3>

          <table v-if="dep"
                 class="rolesTable"
                 v-bind:class="{readOnly: corporation.roles[role].read_only || !myRole.edit_roles}">
            <tr>
              <td></td>
              <td style="text-align: center;">{{ texts['text_53'][language] }}</td>
              <td style="text-align: center;">{{ texts['text_54'][language] }}</td>
              <td style="text-align: center;">Аудит</td>
            </tr>

            <tr v-for="d in deposits">
              <td>{{ d.name }}</td>
              <td style="text-align: center;" v-for="i in ['view', 'withdraw', 'audit']">
                <input type="checkbox" class="cursor_hover"
                       :checked="findLocalRight(corporation.roles[role], 'deposit', d.id, i)"
                       @click="addLocalRight($event, 'deposit', d.id, i, corporation.roles[role])"/>
              </td>
            </tr>
          </table>

          <h3 class="head" @click="local = !local">
            {{ local ? '▼' : '▶' }}
            {{ texts['text_55'][language] }}</h3>

          <template v-if="local">

            <select style="margin-top: 2px; margin-left: 2px; width: calc(100% - 4px); margin-bottom: 0"
                    v-model="localType">
              <option value="office">Офис</option>
              <option value="base">База</option>
            </select>

            <template v-if="localType === 'office'">
              <select style="margin-top: 2px; margin-left: 2px; width: calc(100% - 4px)" v-model="office">
                <option value="-1" selected>{{ texts['text_56'][language] }}</option>
                <option v-for="o in offices" :value="o.id">{{ o.name }}</option>
              </select>

              <table class="rolesTable" v-if="storages.length > 0"
                     v-bind:class="{readOnly: corporation.roles[role].read_only || !myRole.edit_roles}">

                <tr>
                  <td></td>
                  <td style="text-align: center;">{{ texts['text_57'][language] }}</td>
                  <td style="text-align: center;">{{ texts['text_58'][language] }}</td>
                  <td style="text-align: center;">{{ texts['text_59'][language] }}</td>
                </tr>

                <tr v-for="s in storages">
                  <td>{{ s.name }}</td>
                  <td style="text-align: center;" v-for="i in ['view_storage', 'put_in_storage', 'take_from_storage']">
                    <input type="checkbox" class="cursor_hover"
                           :checked="findLocalRight(corporation.roles[role], 'office_stock', s.id, i)"
                           @click="addLocalRight($event,'office_stock', s.id, i, corporation.roles[role])"/>
                  </td>
                </tr>

              </table>
            </template>

            <template v-if="localType === 'base'">
              <select style="margin-top: 2px; margin-left: 2px; width: calc(100% - 4px)" v-model="base">
                <option value="-1" selected>Выберите базу</option>
                <option v-for="b in bases" v-if="b.structure === 'base'" :value="b.id">
                  {{ b.name }} ({{ texts['text_15'][language] }} {{ getSectorNameByID(b.map_id) }})
                </option>
              </select>

              <table class="rolesTable" v-if="base > 0"
                     v-bind:class="{readOnly: corporation.roles[role].read_only || !myRole.edit_roles}">

                <tr v-for="i in ['build', 'dismantling', 'change_local_policy']">
                  <td>{{ texts[i][language] }}</td>
                  <td style="text-align: center;">
                    <input type="checkbox" class="cursor_hover"
                           :checked="findLocalRight(corporation.roles[role], 'base', base, i)"
                           @click="addLocalRight($event,'base', base, i, corporation.roles[role])"/>
                  </td>
                </tr>
              </table>
            </template>
          </template>
        </template>
      </div>

      <label style="margin-top: 8px">
        {{ texts['text_60'][language] }}
        <input v-model="newRoleName" :class="{disable: !myRole.edit_roles}"
               type="text"
               class="cursor_hover"
               :placeholder="texts['placeholder_2'][language]"
               style="width: 125px">
        <input type="button"
               class="button cursor_hover"
               :class="{disable: !myRole.edit_roles}"
               :value="texts['button_32'][language]"
               style="width: 70px; float: right; margin-right: 10px;"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="addNewRole">
      </label>
    </div>
  </div>
</template>

<script>
import Input from "../Chat/Input";

export default {
  name: "Roles",
  components: {Input},
  data() {
    return {
      role: 'none',
      newRoleName: null,
      office: -1,
      base: -1,
      localType: 'office',
      common: false,
      dep: false,
      local: false
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetLocalRight",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    addNewRole() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "addNewCorporationRole",
          name: this.newRoleName,
        }
      }));
      this.newRoleName = null;
    },
    removeRole() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "removeCorporationRole",
          id: Number(this.role),
        }
      }));
    },
    changeRole(role) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "editRole",
          role: role,
        }
      }));
    },
    addLocalRight(e, type, id, right, role) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "AddLocalRight",
          role_id: role.id,
          name: type,
          id: id,
          data: right,
          value: e.target.checked ? 1 : 0,
        }
      }));
    },
    findLocalRight(role, type, id, right) {
      if (!this.corporation || !this.corporation.local_right || !this.corporation.local_right[role.id]) {
        return false
      }

      let localRights = this.corporation.local_right[role.id]
      for (let lr of localRights) {
        if (lr.local_type === type && lr.local_id === id) {
          return lr[right]
        }
      }

      return false
    },
    getSectorNameByID(id) {

      for (let i in this.handbook['sector'][this.language]) {
        if (this.handbook['sector'][this.language][i].id === id) {
          return this.handbook['sector'][this.language][i].name;
        }
      }

      return ''
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    myRole() {
      let corp = this.corporation;
      if (corp) {
        return corp.roles[corp.members[this.currentPlayer.id].role_id]
      }

      return {}
    },
    deposits() {
      if (this.corporation && this.corporation.deposits) {
        return this.corporation.deposits.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return [];
    },
    offices() {
      let offices = []
      if (this.corporation && this.corporation.offices) {
        for (let i in this.corporation.offices) {
          offices.push(this.corporation.offices[i])
        }

        return offices.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return offices
    },
    storages() {
      let office;

      if (this.corporation && this.corporation.offices) {
        for (let i in this.corporation.offices) {
          if (this.office === this.corporation.offices[i].id) {
            office = this.corporation.offices[i]
          }
        }
      }

      if (!office) return [];

      return office.storages.sort(function (a, b) {
        return a.id - b.id;
      });
    },
    bases() {
      return this.corporation.bases.bases
    },
  }
}
</script>

<style scoped>
.roles {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
}

.rolesTableWrap {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.rolesTableWrap2 {
  width: calc(100% - 4px);
  margin: 2px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 53px);
}

.rolesTable {
  width: 100%;
  border-spacing: 0;
}

.rolesTable tr td {
  border-bottom: 1px solid #7b7b7b;
  padding: 1px 2px 0;
}

.rolesTable tr td:last-child {
  width: 50px;
  text-align: center;
}

label {
  display: block;
}

select {
  outline: none;
  width: 170px;
  border-radius: 5px;
  margin: 0 0 4px 2px;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

select * {
  color: black;
  font-weight: bold;
}

.readOnly {
  pointer-events: none;
  opacity: 0.4;
}

input[type="button"]:active {
  transform: scale(0.98);
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

input[type=text] {
  text-indent: 1px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  text-shadow: 1px 1px 1px black;
}

.head {
  margin: 0 0 1px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  background: rgba(255, 129, 0, 0.6);
}

.head:active {
  background: rgb(248, 171, 92);
}

.head:hover {
  background: rgba(255, 129, 0, 1);
}
</style>
