import {gameStore} from "../../../game/store";
import {RemoveOldMap} from "../../../game/map/remove_old_map";
import {CreateDynamicObjects} from "../../../game/radar/object";
import {removeMissionPath} from "../../../game/interface/mini_map";
import {showMessage} from "../../../game/text/message";
import {DrawText} from "../../../game/interface/center_text";
import {SectorAttackTargets} from "../../../game/interface/sector_attack_targets";
import {SetGameTime} from "../../../game/interface/game_time";
import {v4 as uuidv4} from 'uuid';

export default function globalRead(msg, store) {
  if (msg.event === "InitGame") {
    console.log("InitGame")

    store.commit({
      type: 'removeNotification',
      id: 'softTransition',
    });

    gameStore.gameReady = false;
    gameStore.unitReady = false;

    RemoveOldMap();

    // console.log(msg.data.map)
    gameStore.playerID = msg.data.player_id
    gameStore.playerCorporationID = msg.data.player_corporation_id
    gameStore.mapCorporationID = msg.data.map_corporation_id
    gameStore.maps = {[msg.data.map.id]: {x: 0, y: 0, map: msg.data.map}};
    gameStore.bases = msg.data.bases
    gameStore.user_squad_id = msg.data.unit_id;

    store.commit({
      type: 'setSector',
      fraction: msg.data.map.fraction,
      free_land: msg.data.map.free_land,
      battle: msg.data.map.possible_battle,
      transports: msg.data.transports
    });

    store.commit({
      type: 'setBaseStatus',
      state: false,
    });

    store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));

    if (msg.data.war_bases && msg.data.war_bases.length > 0) {
      gameStore.sectorWarState.bases = msg.data.war_bases
      gameStore.sectorWarState.bases.sort(function (a, b) {
        return a.name - b.name;
      });
    }

    store.commit({
      type: 'closeNodeWS'
    });

    store.commit({
      type: 'reconnectNodeWS',
      node: msg.data.node,
    });

    gameStore.gameDataInit.data = true;
    SetGameTime(msg.data.h, msg.data.m)
  }

  if (msg.event === "RefreshDynamicObj") {
    CreateDynamicObjects(msg.data);
    gameStore.radarWork = true;
  }

  if (msg.event === "softTransition") {
    store.commit({
      type: 'addNotification',
      id: 'softTransition',
      removeSec: msg.data,
      html: `<p class="importantly">Внимание!</p>
             Перемещение через: <span class="importantly">(time_place)</span>`,
    });
  }

  if (msg.event === "ChangeSector") {
    console.log("Change sector")

    gameStore.gameReady = false;
    gameStore.gameDataInit = {
      data: false,
      sendRequest: false,
    }
    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Обновляем карту...',
        'EN': 'Updating the map...',
      }
    });
  }

  if (msg.event === "OpenObject") {
    if (msg.error && msg.error !== '') {
      store.commit({
        type: 'toggleWindow',
        id: 'ObjectDialog' + msg.data.object.id,
        forceClose: true,
      });
    }


    if (!document.getElementById('ObjectDialog' + msg.data.object.id)) {
      store.commit({
        type: 'toggleWindow',
        id: 'ObjectDialog' + msg.data.object.id,
        component: 'ObjectDialog',
        meta: {
          id: msg.data.object.id
        },
        forceOpen: true,
      });
    }

    store.commit({
      type: 'addOpenObjects',
      id: msg.data.object.id,
      dynamic_object: msg.data.object,
      other_user: msg.data.owner,
      box: msg.data.box,
      inventory: msg.data.inventory,
      size: msg.data.size,
    });
  }

  if (msg.event === "OpenBuildObject") {

    if (msg.data.open) {
      store.commit({
        type: 'toggleWindow',
        id: 'OpenBuildObject',
        forceOpen: true,
      });
    }

    store.commit({
      type: 'setOpenBuildObject',
      object_id: msg.data.object_id,
      base_id: msg.data.base_id,
      current_resources: msg.data.current_resources,
    });
  }

  if (msg.event === 'GeoScanChange') {
    if (msg.data) {
      store.commit({
        type: 'toggleWindow',
        id: 'anomalyScannerWrapper',
        component: 'anomalyScannerWrapper',
        forceOpen: true,
      });
    } else {
      store.commit({
        type: 'toggleWindow',
        id: 'anomalyScannerWrapper',
        component: 'anomalyScannerWrapper',
        forceClose: true,
      });
    }
  }

  if (msg.e === "SetGlobalTarget") {
    gameStore.globalPath = {
      points: msg.p,
      mapID: msg.m,
      baseID: msg.b,
    }

    store.commit({
      type: 'setGlobalPath',
      mapID: msg.m,
      baseID: msg.b,
    });
  }
  if (msg.event === "SetMissionPoints") {
    removeMissionPath()
    gameStore.missionPath.actions = msg.data

    store.commit({
      type: 'setGlobalMission',
      mission: JSON.stringify(msg.data),
    });
  }

  if (msg.event === "AiMessage") {
    showMessage(msg.data)
  }

  if (msg.event === "npcDialog") {
    let data = store.getters.getTargetData;
    let current = store.getters.getPlayer;
    let language = store.getters.getSettings.Language;

    if (
      data && current &&
      (data.owner_id === msg.data.to_user || data.owner_id === msg.data.from_user) &&
      (current.id === msg.data.to_user || current.id === msg.data.from_user)
    ) {

      for (let i in msg.data.texts) {

        let my = false
        if (Number(i) === 0) my = Number(current.id) === Number(msg.data.from_user);
        if (Number(i) === 1) my = Number(current.id) === Number(msg.data.to_user);

        setTimeout(function () {

          let success = msg.data.success
          if (my) {
            success = 0
          }

          store.commit({
            type: 'addMsgInTargetData',
            data: {new: false, my: my, text: msg.data.texts[i], success: success},
          });
        }, 1000 * i)
      }

      if (msg.data.need_answer && msg.data.to_user === current.id) {
        store.commit({
          type: 'addAscsInTargetData',
          ascs: msg.data.answers,
          uuid: msg.data.uuid,
        });
      }
    } else if ((['npc_dialog'].includes(msg.data.event)) && !IsToUser(msg.data, current)) {

      for (let i in msg.data.texts) {

        let id = msg.data.from_user
        let name = msg.data.from_user_name

        if (Number(i) === 1) {
          id = msg.data.to_user
          name = msg.data.to_user_name
        }

        let chatMsg = {
          bot: true,
          message: JSON.stringify(msg.data.texts[i]),
          system: false,
          time: new Date(Date.now() + i),
          user_id: id,
          user_name: name,
          uuid: uuidv4(),
        }

        store.commit({
          type: 'newChatMessage',
          id: 0,
          msg: chatMsg,
          npc_animate: false,
          notify: false,
        });
      }

    } else {
      store.commit({
        type: 'addNotify',
        notify: msg.data,
      });
    }
  }

  if (msg.event === "StartMiniGame") {
    store.commit({
      type: 'toggleWindow',
      id: 'MiniGame',
      component: '',
      meta: msg.data,
      forceOpen: true,
    });
  }

  if (msg.event === "et") {
    DrawText(msg.data[store.getters.getSettings.Language], 0xFFFFFF, true)
  }

  if (msg.event === "tnp") {
    store.commit({
      type: 'addNotification',
      id: 'softTransition',
      removeSec: 3,
      html: `<p class="importantly">В доступе отказано!</p>`,
    });
  }

  if (msg.event === "GetAttackSectorTargets") {
    SectorAttackTargets(msg.data.targets, msg.data.slot, msg.data.source)
  }

  if (msg.event === "DialogAttackSector") {
    store.commit({
      type: 'setAttackDialog',
      data: msg.data,
    });

    store.commit({
      type: 'toggleWindow',
      id: 'AttackDialog',
      forceOpen: true,
    });
  }
}

function IsToUser(notify, currentPlayer) {
  return notify.to_user === currentPlayer.id ||
    notify.from_user === currentPlayer.id ||
    notify.target_user === currentPlayer.id ||
    notify.name === 'bot_request' ||
    notify.name === 'bot_mission_request'
}

export {IsToUser}
