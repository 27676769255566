import {GetGlobalPos} from "../gep_global_pos";
import {Scene} from "../../create";

function createGeyser(x, y, height, scene, mapID, sprite) {

  let pos = GetGlobalPos(x, y, mapID);
  let shape1 = new Phaser.Geom.Circle(0, 0, sprite.displayHeight / 4);

  let particles = Scene.add.particles(pos.x, pos.y, 'flares', {
    frame: 'smoke_puff',
    speed: {min: -5, max: 5},
    lifespan: {min: 5000, max: 6000},
    quantity: 7,
    frequency: 1000,
    scale: {start: 0, end: sprite.scale * 1.5},
    angle: {min: -180, max: 180},
    alpha: {start: 0.4, end: 0},
    blendMode: 'SCREEN',
    moveToX: {min: 0, max: 0},
    moveToY: {min: 0, max: 0},
    emitZone: {type: 'random', source: shape1}
  });
  particles.setDepth(height + 1);

  sprite.emitter = particles;
}

export {createGeyser}
