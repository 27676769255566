var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Осада")]),_vm._v(" "),_c('p',[_vm._v("\n      Когда сектор принадлежит какому то кластеру то в специальное время которое указывает владелец в\n      "),_c('router-link',{attrs:{"to":"corporation_local_policy"}},[_vm._v("локальных политиках")]),_vm._v("\n      сектор ее можно атаковать.\n    ")],1),_vm._v(" "),_c('p',[_vm._v("Что бы атаковать сектор потребуется самому находится в кластере и предмет которых нужен для саботажа систем\n      базы\n      и телепортов.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_1', 'png')+ ')',
          width: '361px',
          height: '247px',
          marginLeft: 'calc(50% - 180px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Что бы использовать предмет взлома, необходимо:")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_2', 'gif')+ ')',
          width: '364px',
          height: '281px',
          marginLeft: 'calc(50% - 182px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Так же можно взламывать уже взломанные порталы, например обороняющийся кластер или их союзники могут взломать\n      уже\n      взломанный портал что бы отсечь возможное подкрепление.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Атакующими считаются только те кто на текущей момент владеет телепортом или за кем он был закреплен после\n      таймера. Это важно для условия завершения осады.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Как получить предмет для взлома порталов:")]),_vm._v(" "),_c('p',[_vm._v("Он продается в\n      "),_c('router-link',{attrs:{"to":"fraction_market"}},[_vm._v("наградном магазине")]),_vm._v("\n      любой фракции за кредиты и очки фракций.\n    ")],1),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Как узнать когда можно напасть на сектор:")]),_vm._v(" "),_c('p',[_vm._v("В меню карты мира при наведение на сектор будет показано это время.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_3', 'png')+ ')',
          width: '316px',
          height: '230px',
          marginLeft: 'calc(50% - 158px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("После взлома портала:")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_4', 'png')+ ')',
          width: '393px',
          height: '72px',
          marginLeft: 'calc(50% - 196px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Завершение осады:")]),_vm._v(" "),_c('p',[_vm._v("\n      Через час взломанные порталы возвращаются в нормальный режим работы и если локальная политика настроена верно\n      подкрепление для атакующих больше не будет поступать. (однако если в политике прописано что впускать всех то они\n      смогут это сделать).\n    ")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('p',[_vm._v("Уничтожение базы так же заканчивает осаду. На месте базы появятся обломки базы в которых будет сохранена часть\n      предметов.")]),_vm._v(" "),_c('p',[_vm._v("Если базу уничтожат:")]),_vm._v(" "),_vm._m(4)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Siege")]),_vm._v(" "),_c('p',[_vm._v("\n      When a sector belongs to a certain cluster, it can be attacked during special times indicated by the owner in\n      the\n      "),_c('router-link',{attrs:{"to":"corporation_local_policy"}},[_vm._v("local policies")]),_vm._v("\n      of the sector.\n    ")],1),_vm._v(" "),_c('p',[_vm._v("To attack a sector, you need to be in a cluster and have an item needed for sabotaging the base systems and\n      teleports.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_1', 'png')+ ')',
          width: '361px',
          height: '247px',
          marginLeft: 'calc(50% - 180px)',
          })}),_vm._v(" "),_c('p',[_vm._v("To use the hacking item, you need to:")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_2', 'gif')+ ')',
          width: '364px',
          height: '281px',
          marginLeft: 'calc(50% - 182px)',
          })}),_vm._v(" "),_c('p',[_vm._v("You can also hack already hacked portals, for example, the defending cluster or their allies can hack an\n      already hacked portal to cut off possible reinforcements.")]),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('p',[_vm._v("Those who currently own the teleport or for whom it was secured after the timer are considered attackers. This\n      is important for the condition of ending the siege.")]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("How to get an item to hack portals:")]),_vm._v(" "),_c('p',[_vm._v("It is sold in the\n      "),_c('router-link',{attrs:{"to":"fraction_market"}},[_vm._v("reward shop")]),_vm._v("\n      of any faction for credits and faction points.\n    ")],1),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("How to know when you can attack a sector:")]),_vm._v(" "),_c('p',[_vm._v("In the world map menu, when hovering over a sector, this time will be shown.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_3', 'png')+ ')',
          width: '316px',
          height: '230px',
          marginLeft: 'calc(50% - 158px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("After hacking the portal:")]),_vm._v(" "),_vm._m(7),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('siege_4', 'png')+ ')',
          width: '393px',
          height: '72px',
          marginLeft: 'calc(50% - 196px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Ending the siege:")]),_vm._v(" "),_c('p',[_vm._v("\n      After an hour, the hacked portals return to normal operation and if the local policy is set correctly,\n      reinforcements for the attackers will no longer arrive. (however, if the policy is written to let everyone in,\n      they will be able to do so).\n    ")]),_vm._v(" "),_vm._m(8),_vm._v(" "),_c('p',[_vm._v("The destruction of the base also ends the siege. Debris of the base will appear in its place, in which some of\n      the items will be preserved.")]),_vm._v(" "),_c('p',[_vm._v("If the base is destroyed:")]),_vm._v(" "),_vm._m(9)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("перетащить предмет для саботажа из инвентаря на панель снаряжения.")]),_vm._v(" "),_c('li',[_vm._v("придти во время уязвимости базы к телепорту который ведет в нужный сектор.")]),_vm._v(" "),_c('li',[_vm._v("\"использовать\" предмет для взлома на вход в телепорт (вход должен быть в зоне обзора), если все правильно то\n        появится диалоговое окно для подтверждения атаки.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("\n    Одновременно на сектор может напасть столько кластеров сколько есть входов в портал.")]),_vm._v(" То есть сектор где\n      только 1 вход - очень защищенный сектор. Однако нападающие могут поочередно взламывать портал друг за другом.\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("активируется режим осады в секторе на 1 час, если он уже активирован то время не увеличивается.")]),_vm._v(" "),_c('li',[_vm._v("телепорт который вы взломали начнет пропускать всех членов вашего кластера в сектор, даже если это не\n        позволяла политика раньше.\n      ")]),_vm._v(" "),_c('li',[_vm._v("на выходах телепорта будет поле глушащие радары")]),_vm._v(" "),_c('li',[_vm._v("все строения при уничтожение больше не восстановятся")]),_vm._v(" "),_c('li',[_vm._v("главная база в секторе станет уязвима для атак")]),_vm._v(" "),_c('li',[_vm._v("сверху экрана начнется показыватся статистки кто нападет их число и состояние базы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("\n       После таймера осада будет продолжатся до тех пор пока все атакующие в секторе не будут уничтожены.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("все имущество находящиеся на складах игроков и кластеров будет потеряно или останется в обломках")]),_vm._v(" "),_c('li',[_vm._v("все ордера на рынке которые находятся на этой базу будут уничтожены")]),_vm._v(" "),_c('li',[_vm._v("игроки находящиеся на базе в момент уничтожения потеряют свои транспорты и отправятся в столицу")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("drag the sabotage item from the inventory to the equipment panel.")]),_vm._v(" "),_c('li',[_vm._v("arrive at the teleport leading to the desired sector during the base's vulnerability time.")]),_vm._v(" "),_c('li',[_vm._v("\"use\" the hacking item at the teleport entrance (the entrance must be within the line of sight), if\n        everything is correct, a dialogue window will appear to confirm the attack.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("\nMultiple clusters can simultaneously attack a sector as many as there are entrances to the portal.")]),_vm._v(" That is, a\n      sector with only one entrance is a very protected sector. However, attackers can sequentially hack the portal\n      one after another.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("the siege mode is activated in the sector for 1 hour, if it is already activated then the time does not\n        increase.\n      ")]),_vm._v(" "),_c('li',[_vm._v("the teleport you hacked will start letting all members of your cluster into the sector, even if the policy\n        did not allow it before.\n      ")]),_vm._v(" "),_c('li',[_vm._v("there will be a radar jamming field at the exits of the teleport.")]),_vm._v(" "),_c('li',[_vm._v("all structures upon destruction will no longer be restored.")]),_vm._v(" "),_c('li',[_vm._v("the main base in the sector will become vulnerable to attacks.")]),_vm._v(" "),_c('li',[_vm._v("statistics of who attacks, their number, and the state of the base will start to be displayed at the top of\n        the screen.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("\n   After the timer, the siege will continue until all attackers in the sector are destroyed.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("all property located in the warehouses of players and clusters will be lost or remain in the debris.")]),_vm._v(" "),_c('li',[_vm._v("all orders on the market that are on this base will be destroyed.")]),_vm._v(" "),_c('li',[_vm._v("players who are on the base at the time of destruction will lose their transports and be sent to the\n        capital.\n      ")])])
}]

export { render, staticRenderFns }