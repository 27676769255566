import {RemoveOldMap} from "../../../game/map/remove_old_map";
import {gameStore} from "../../../game/store";
import {CreateGame, Scene} from "../../../game/create";
import {CreateDynamicObjects} from "../../../game/radar/object";
import {CreateLabelBase, CreateMapLabels} from "../../../game/map_editor/labels";
import {CreateSpawnZone} from "../../../game/map_editor/spawn";
import {MapSize} from "../../../game/map/createMap";

let init = false;

export default function mapEditorRead(msg, store) {
  gameStore.mapEditor = true
  if (msg.event === "MapList") {
    store.commit({
      type: 'setMapsInMapEditor',
      maps: msg.data,
    });
  }

  if (msg.event === "GetAllTypeCoordinate") {
    store.commit({
      type: 'setTypeCoordinates',
      typeCoordinates: msg.data,
    });
  }

  if (msg.event === "MapSelect") {
    RemoveOldMap();

    gameStore.maps = msg.data.full_maps;
    gameStore.bases = msg.data.bases;
    gameStore.gameDataInit.data = true;

    CreateGame();

    let wait = setInterval(function () {
      if (gameStore.gameReady) {
        setTimeout(function () {
          CreateDynamicObjects(msg.data.dynamic_objects)
          CreateMapLabels(gameStore.map, false);
          if (gameStore.bases) CreateLabelBase(gameStore.bases);
          clearInterval(wait);
          if (gameStore.map.spawns) CreateSpawnZone(gameStore.map.spawns);
          CreateSpawnZone(msg.data.war_base);

          if (!init) {
            init = true;
            Scene.cameras.main.centerOn(MapSize, MapSize);
          }
        }, 1000)
      }
    }, 1000);
  }

  if (msg.event === "UpdateLvl") {
    gameStore.map.level_map = msg.data;
  }

  if (msg.event === "UpdateTypeCoordinate") {
    if (gameStore.mapEditorState.typeCoordinateEdit) {
      gameStore.mapEditorState.typeCoordinateEdit = msg.data.dynamic_object;
      gameStore.mapEditorState.typeCoordinateEdit.type_geo_data = msg.data.type_geo_data;
    }
  }
}
