import {GetGlobalPos} from "../gep_global_pos";
import {gameStore} from "../../store";

function createCorporationOffice(state, base, scene) {
  base.setDepth(1);
  let pos = GetGlobalPos(state.x, state.y, gameStore.map.id);

  let shadow = scene.make.image({
    x: pos.x + 5,
    y: pos.y + 5,
    key: 'structures',
    add: true,
    frame: state.texture + "_top",
  });
  shadow.setOrigin(0.5);
  shadow.setScale(state.scale / 100);
  shadow.setAlpha(state.shadow_intensity / 100);
  shadow.setTint(0x000000);
  shadow.setDepth(state.height - 1);
  shadow.setAngle(base.angle);

  let object = scene.make.image({
    x: pos.x,
    y: pos.y,
    key: 'structures',
    add: true,
    frame: state.texture + "_top",
  });
  object.setOrigin(0.5);
  object.setScale(state.scale / 100);
  object.setDepth(state.height - 1);
  object.setAngle(base.angle);

  base.top = object;
  base.shadowTop = shadow;
}

export {createCorporationOffice}
