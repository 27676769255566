<template>
  <div id="FractionMarket" ref="FractionMarket" @mousedown="toUp">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'FractionMarket'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 150, width: 300}"/>

    <div class="error_message" :class="{error_message_visible: fractionStoreComplete}">
      <div style="margin-top: 14%" v-html="texts['to_storage'][language]"/>
    </div>

    <div class="error_message" :class="{error_message_visible: fractionStoreFail}">
      <div style="margin-top: 14%" v-html="texts['error_1'][language]"/>
    </div>

    <div class="head">

      <h3 style="line-height: 18px;" class="head_title">
        {{ texts['text_1'][language] }}
        {{ baseStatus.fraction !== '' ? baseStatus.fraction : '-' }}</h3>

      <div class="fractionLogo" v-if="baseStatus.fraction !== ''"
           :style="{backgroundImage: 'url(' + require('../../assets/' + getLogo(baseStatus.fraction)).default+')'}"/>

      <span class="user_fraction_point">
        {{ texts['text_2'][language] }}
        <span class="importantly">{{ (fractionStoreState.user_fraction_points / 100).toFixed(0) }}
          {{ texts['text_3'][language] }}
        </span>
      </span>

      <span class="user_fraction_point" style="font-size: 9px; margin-top: 7px;">
        {{ texts['text_4'][language] }}
      </span>

      <div class="help_button cursor_hover" @click.stop="openService('helpWrapper', {component: 'Militia'})"/>
    </div>

    <div class="assortment">
      <table class="assortment_table">
        <tr>
          <th style="width: 60px">{{ texts['text_5'][language] }}</th>
          <th>{{ texts['text_6'][language] }}</th>
          <th>{{ texts['text_7'][language] }}</th>
          <th>
            {{ texts['text_8'][language] }}
            ({{ texts['text_3'][language] }})
          </th>
          <th>
            {{ texts['text_8'][language] }}
            ({{ 'cr' }})
          </th>
          <th></th>
        </tr>

        <tr v-for="point in assortment" v-if="getItems(point) && items[point.id]"
            :style="{Opacity: currentPlayer.battle_rank.lvl < point.min_rank || !currentPlayer.battle_rank.fraction_warrior ? 0.7 : 1}">
          <td>
            <app-item-cell @click.native="openDetail(items[point.id].type, items[point.id].item_id)"
                           v-bind:itemSlot="items[point.id]"
                           v-bind:size="45"/>
          </td>
          <td><span class="importantly" style="color: white">{{ point.count }}</span></td>
          <td><span class="importantly" style="color: white">{{ point.min_rank }}</span></td>
          <td><span class="importantly">{{ (point.points / 100).toFixed(0) }}</span> <span
            style="color: white; text-shadow: 1px 1px 1px black">{{ texts['text_3'][language] }}</span></td>
          <td><span class="importantly">{{ (point.credits / 100).toFixed(2) }}</span> <span
            style="color: white; text-shadow: 1px 1px 1px black">cr.</span></td>
          <td><input class="buyButton cursor_hover"
                     type="button"
                     :value="texts['button_1'][language]"
                     :class="{
                              disable: currentPlayer.battle_rank.lvl < point.min_rank ||
                              !currentPlayer.battle_rank.fraction_warrior ||
                              fractionStoreState.user_fraction_points < point.points ||
                              market.my_credits < point.credits
                      }"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="setPoint(point)"/></td>
        </tr>
      </table>
    </div>

    <div class="mask" v-if="dialogItem"/>
    <div class="dialog" v-if="dialogItem">

      <div class="dialogText">
        <app-item-cell v-bind:itemSlot="items[dialogItem.id]" v-bind:size="50"/>
        <span class="importantly" style="color: white; font-size: 12px; margin-top: 5px">
          {{ texts['text_9'][language] }} {{ items[dialogItem.id].name }} {{ texts['text_10'][language] }} <br>

          <span class="importantly" v-if="dialogItem.points> 0">
            {{ (dialogItem.points / 100).toFixed(0) }} {{ texts['text_3'][language] }}
          </span>

          <span v-if="dialogItem.points> 0 && dialogItem.credits > 0">
            {{ texts['text_11'][language] }}
          </span>

          <span class="importantly" v-if="dialogItem.credits > 0">
            {{ (dialogItem.credits / 100).toFixed(2) }} {{ 'cr' }}
          </span>
          ?
        </span>
      </div>

      <input style="width: 45%; float: left; margin-left: 3px"
             class="buyButton cursor_hover"
             type="button"
             :value="texts['button_2'][language]"
             @click="dialogItem = null"/>
      <input style="width: 45%; float: right; margin-right: 3px"
             class="buyButton cursor_hover"
             type="button"
             :value="texts['button_3'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="buy(dialogItem.id)"/>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import ItemCell from "../Inventory/ItemCell"
import {urls} from "../../const";

export default {
  name: "FractionMarket",
  data() {
    return {
      items: {},
      requestItems: {},
      dialogItem: null,
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "lobby",
      data: {
        event: "GetAssortmentFractionStore",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    setPoint(point) {
      this.playSound('button_press.mp3', 0.3)
      this.dialogItem = point
    },
    resize(event, ui, el) {

    },
    getLogo(fraction) {
      return `logo/${fraction}.png`
    },
    getItems(option) {
      let app = this;

      if (app.requestItems[option.id]) return true;
      app.requestItems[option.id] = true

      app.$api.get(urls.itemURL + '?id=' + option.item_id + "&type=" + option.item_type + "&method=item").then(function (response) {
        response.data.quantity = option.count;
        app.$set(app.items, option.id, response.data)
      }).catch(function (err) {
        //console.log(err)
      });

      return true;
    },
    buy(id) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "FractionStoreBuy",
          id: id,
        }
      }));
      this.dialogItem = null;
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    openDetail(type, id) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
  },
  computed: {
    inventory() {
      return this.$store.getters.getInventory
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('FractionMarket')
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    fractionStoreState() {
      let fractionStoreState = this.$store.getters.getFractionStoreState
      return fractionStoreState
    },
    assortment() {
      let assortment = this.fractionStoreState.assortment
      assortment.sort(function (a, b) {
        return a.min_rank - b.min_rank || a.points - b.points || a.credits - b.credits;
      });

      return assortment
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    market() {
      return this.$store.getters.getMarket
    },
    fractionStoreComplete() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('fraction_store_complete') && errors['fraction_store_complete'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'fraction_store_complete',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    fractionStoreFail() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('fraction_store_fail') && errors['fraction_store_fail'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'fraction_store_fail',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
  },
  components: {
    AppItemCell: ItemCell,
    AppControl: Control,
  }
}
</script>

<style scoped>
#FractionMarket {
  position: absolute;
  top: 35%;
  left: calc(50% - 170px);
  z-index: 11;
  width: 345px;
  height: 226px;
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.head {
  line-height: 8px;
  background: rgba(77, 77, 84, 0.3);
  width: 100%;
  height: 52px;
  border-radius: 5px;
  color: white;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
  font-size: 11px;
  text-align: left;

}

.fractionLogo {
  height: 28px;
  width: 28px;
  float: left;
  margin: -1px 5px 5px 2px;
  background-size: cover;
  background-color: rgba(6, 110, 168, 0.2);
  border-radius: 5px;
}

.head_title {
  margin: 0 0 7px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
}

.user_fraction_point {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  text-shadow: 1px 1px 1px black;
}

.user_fraction_point .importantly {
  text-shadow: 1px 1px 1px black;
}

.assortment {
  float: left;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0 0 2px black;
  width: 100%;
  height: calc(100% - 57px);
  background: rgba(137, 150, 156, 0.3);
}

.assortment_table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
}

.assortment_table tr:first-child {
  box-shadow: 0 0 5px rgb(0 0 0);
}

.assortment_table tr {
  text-align: center;
}

.assortment_table tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 8px;
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 2;
}

.assortment_table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.buyButton, .dialogButton {
  display: block;
  width: 90%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.buyButton:hover, .dialogButton:hover {
  background: rgba(255, 129, 0, 1);
}

.buyButton:active, .dialogButton:active {
  transform: scale(0.98);
}

.dialog {
  position: absolute;
  left: calc(50% - 100px);
  top: 30%;
  width: 200px;
  padding: 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  z-index: 10;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  height: 81px;
}

.dialogText {
  border-radius: 5px;
  padding: 3px;
  width: calc(100% - 6px);
  height: 58px;
  box-shadow: inset 0 0 2px;
  background: rgba(137, 150, 156, 0.5);
}

.mask {
  position: absolute;
  z-index: 6 !important;
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
}

.help_button {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 11px;
  top: 44px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_button:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}

.help_button:active {
  transform: scale(0.98);
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}
</style>
