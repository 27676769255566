var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Типы строений")]),_vm._v(" "),_c('p',[_vm._v("Сооружения служат разным целям от складов до турелей.")]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('turrents', 'png')+ ')',
          width: '200px',
          height: '75px',
          float: 'left',
          marginRight: '10px',
          })}),_vm._v(" "),_c('p',[_vm._v("Турели - атакуют врагов находящиеся в радиусе атаки, очень не любят кусты поэтому отстреливают их при\n          возможности ¯\\_(ツ)_/¯.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('shield', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Щиты - не пропускают снаряды внутрь, но зато выпускает их из под себя.")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('radar', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Радар - расширяет радар транспорта, показывает все что попадает в радиус радара.")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('jammer', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Генератор помех - делает в области помех бесполезным радар, но не только вражеский а вообще.")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('missile_defense', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("ПРО - сбивает вражеские ракеты на подлете.")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('storage', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Склад - как ящик, только склад ¯\\_(ツ)_/¯.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('energy_generator', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Генератор - имеет расширеную топливную ячейку и способен самостоятельно питать все строения в радиусе\n          действия.")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание, которое он будет раздавать.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('extractor', 'png')+ ')',
          width: '75px',
          height: '75px',
          float: 'left',
          })}),_vm._v(" "),_c('p',[_vm._v("Добывающее строение - строится на минерале и добывает ресурсы в автоматическом режиме, что бы строение\n          работало ему ноеобходимо иметь ящик, транспорт или строение с инвентарем в точке сброса (красный/зеленый\n          квадратик у здания).")]),_vm._v(" "),_c('p',[_vm._v("Для работы необходимо питание, которое он будет потреблять в каждом цикле добычи.")])])])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Types of Structures")]),_vm._v(" "),_c('p',[_vm._v("Structures serve various purposes from warehouses to turrets.")]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('turrents', 'png')+ ')',
    width: '200px',
    height: '75px',
    float: 'left',
    marginRight: '10px',
    })}),_vm._v(" "),_c('p',[_vm._v("Turrets - attack enemies within the attack radius, they really don't like bushes so they shoot them\n          whenever possible ¯\\_(ツ)_/¯.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('shield', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Shields - do not let projectiles inside, but they do let them out from underneath.")]),_vm._v(" "),_c('p',[_vm._v("Power supply is required for operation.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('radar', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Radar - expands the vehicle's radar, shows everything that falls within the radar radius.")]),_vm._v(" "),_c('p',[_vm._v("Power supply is required for operation.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('jammer', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Jammer - makes the radar useless in the jamming area, not just the enemy's but any radar.")]),_vm._v(" "),_c('p',[_vm._v("Power supply is required for operation.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('missile_defense', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Missile Defense - intercepts enemy missiles on approach.")]),_vm._v(" "),_c('p',[_vm._v("Power supply is required for operation.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('storage', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Warehouse - like a box, only a warehouse ¯\\_(ツ)_/¯.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('energy_generator', 'png')+ ')',
    width: '75px',
    height: '75px',
    float: 'left',
    })}),_vm._v(" "),_c('p',[_vm._v("Generator - has an extended fuel cell and is capable of independently powering all structures within its\n          range of action.")]),_vm._v(" "),_c('p',[_vm._v("Power supply is necessary for operation, which it will distribute.")])]),_vm._v(" "),_c('li',{staticClass:"imageLi"},[_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('extractor', 'png')+ ')',
        width: '75px',
        height: '75px',
        float: 'left',
        })}),_vm._v(" "),_c('p',[_vm._v("Resource Extractor - built on a mineral and extracts resources in automatic mode, to operate it must have a\n          box, transport, or a structure with inventory at the drop point (red/green square at the building).")]),_vm._v(" "),_c('p',[_vm._v("Power supply is required for operation, which it will consume in each extraction cycle.")])])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }