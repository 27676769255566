var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Кластеры")]),_vm._v(" "),_c('p',[_vm._v("Кластер (Клан или Корпорация): кластер, это активное сообщество игроков, решивших объединить свои усилия под\n      эгидой некоего общего интереса. Желаете вы создать пиратскую банду, или сформировать гильдию богатейших\n      торговцев,\n      кластер позволит вам установить подобное сотрудничество, а также соперничество с прочими игроками. ")]),_vm._v(" "),_c('p',[_vm._v("Прежде чем вступить в кластер, вам необходимо либо подать заявку в уже существующий, либо создать собственный,\n      став его лидером. ")]),_vm._v(" "),_c('p',[_vm._v("Игрок, может отыскать все доступные для вступления кластеры в графе - “поиск кластеров”. Там же вы увидите\n      наименование кластера, количество участников и статус набора. Выбрав интересующий вас кластер, всё что остаётся\n      -\n      нажать на кнопку “Отправить заявку”, после чего, дожидаться одобрения от лидера кластера. ")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_1', 'jpg')+ ')',
          width: '500px',
          height: '238px',
          marginLeft: 'calc(50% - 250px)',
          })})]),_vm._v(" "),_c('p',[_vm._v("Когда вы состоите в кластере у вас доступна панель кластера:")]),_vm._v(" "),_c('ul',[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_6', 'jpg')+ ')',
          width: '400px',
          height: '329px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('li',[_vm._v("Во вкладке \""),_c('span',{staticClass:"importantly"},[_vm._v("Участники")]),_vm._v("\" вы можете посмотреть кто из игроков сейчас онлайн,\n        если у вас есть соответствующие права или вы лидер вы можете поменять роли игрокам из этого меню или выгнать\n        их.\n\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_3', 'jpg')+ ')',
          width: '400px ',
          height: '313px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('li',[_vm._v("Во вкладке \""),_c('span',{staticClass:"importantly"},[_vm._v("Роли")]),_vm._v("\" вы можете посмотреть существующие роли в кластере и если\n        вы\n        имеет права то можете создавать и редактировать их.\n\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_4', 'jpg')+ ')',
          width: '400px',
          height: '454px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('li',[_vm._v("Во вкладке \""),_c('span',{staticClass:"importantly"},[_vm._v("Заявки")]),_vm._v("\" будут показаны все заявки которые послали игроки что бы\n        вступить в ваш кластер. Если принять заявку то игрок сразу попадает в кластер.\n\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_5', 'jpg')+ ')',
          width: '400px',
          height: '323px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})])])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Clusters")]),_vm._v(" "),_c('p',[_vm._v("A cluster (Clan or Corporation) is an active community of players who have decided to unite their efforts under\n      the aegis of some common interest. Whether you want to create a pirate gang or form a guild of the wealthiest\n      traders, a cluster will allow you to establish such cooperation, as well as compete with other players.")]),_vm._v(" "),_c('p',[_vm._v("Before joining a cluster, you need to either apply to an existing one or create your own, becoming its\n      leader.")]),_vm._v(" "),_c('p',[_vm._v("A player can find all available clusters to join in the \"search clusters\" section. There you will see the name\n      of the cluster, the number of members, and the recruitment status. After selecting the cluster you are\n      interested in, all that remains is to click the \"Send Request\" button, and then wait for approval from the\n      cluster leader.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_1', 'jpg')+ ')',
          width: '500px',
          height: '238px',
          marginLeft: 'calc(50% - 250px)',
          })})]),_vm._v(" "),_c('p',[_vm._v("When you are part of a cluster, you have access to the cluster panel:")]),_vm._v(" "),_c('ul',[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_6', 'jpg')+ ')',
          width: '400px',
          height: '329px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('li',[_vm._v("In the \""),_c('span',{staticClass:"importantly"},[_vm._v("Members")]),_vm._v("\" tab, you can see which players are currently online, and\n        if you have the appropriate rights or are the leader, you can change roles for players from this menu or expel\n        them.\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_3', 'jpg')+ ')',
          width: '400px ',
          height: '313px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('li',[_vm._v("In the \""),_c('span',{staticClass:"importantly"},[_vm._v("Roles")]),_vm._v("\" tab, you can view the existing roles in the cluster and if\n        you have the rights, you can create and edit them.\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_4', 'jpg')+ ')',
          width: '400px',
          height: '454px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})]),_vm._v(" "),_c('li',[_vm._v("In the \""),_c('span',{staticClass:"importantly"},[_vm._v("Applications")]),_vm._v("\" tab, all applications sent by players to join your\n        cluster will be shown. If you accept an application, the player immediately joins the cluster.\n        "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('cluster_5', 'jpg')+ ')',
          width: '400px',
          height: '323px',
          marginLeft: 'calc(50% - 200px)',
          marginTop: '10px',
          })})])])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Во вкладке \""),_c('span',{staticClass:"importantly"},[_vm._v("О кластере")]),_vm._v("\" будет отображены описание, лого, депозиты и офисы\n        кластера.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("In the \""),_c('span',{staticClass:"importantly"},[_vm._v("About Cluster")]),_vm._v("\" tab, you will see the description, logo, deposits,\n        and offices of the cluster.\n      ")])
}]

export { render, staticRenderFns }