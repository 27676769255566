var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Гранулы")]),_vm._v(" "),_c('p',[_vm._v("Гранулы — это вид ресурсов, которые используются в химической промышленности для создания топлива и\n        бустеров.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('pellet_1', 'png')+ ')',
        width: '400px',
        height: '107px',
        marginLeft: 'calc(50% - 200px)',
        })}),_vm._v(" "),_c('p',[_vm._v("Гранулы можно добыть из некоторых растений и штормов с помощью специального устройства.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('pellet_2', 'gif')+ ')',
        width: '400px',
        height: '211px',
        marginLeft: 'calc(50% - 218px)',
        })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Pellets")]),_vm._v(" "),_c('p',[_vm._v("Pellets are a type of resource used in the chemical industry for creating fuel and boosters.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('pellet_1', 'png')+ ')',
        width: '400px',
        height: '211px',
        marginLeft: 'calc(50% - 200px)',
        })}),_vm._v(" "),_c('p',[_vm._v("Pellets can be extracted from certain plants and storms using a special device.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('pellet_2', 'gif')+ ')',
        width: '400px',
        height: '107px',
        marginLeft: 'calc(50% - 218px)',
        })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }