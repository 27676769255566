import {MoveSprite} from "../utils/move_sprite";
import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {tickTime} from "../update";

function AddPollenMoveBufferData(data) {
  if (!gameStore.gameReady) {
    return;
  }

  let p = gameStore.pollens[data.id];
  if (!p) {
    return;
  }

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  data.x = pos.x;
  data.y = pos.y;

  AddMoveBufferData(data, p)
}


function MovePollen(p) {
  let data = p.bufferMoveTick.shift();
  if (!data || !p || !p.sprite || !p.shape) {
    return;
  }

  if (!p.sprite.ops || !p.sprite.ops.angle) {
    return;
  }

  MoveSprite(p.shape, data.x, data.y, tickTime, null);
  MoveSprite(p.circle, data.x, data.y, tickTime, null);
  MoveSprite(p.sprite, data.x, data.y, tickTime, null);
  MoveSprite(p.wortex, data.x, data.y, tickTime, null);

  p.sprite.ops.angle.start = data.r;
  p.sprite.ops.angle.end = data.r;
}

export {MovePollen, AddPollenMoveBufferData}
