import {GetGlobalPos} from "../gep_global_pos";
import {Scene} from "../../create";

function SectorStructure_3_28(x, y, height, scene, mapID, sprite) {
  let pos = GetGlobalPos(x, y, mapID);
  let shape1 = new Phaser.Geom.Circle(0, 0, sprite.displayHeight / 2);

  let particles = Scene.add.particles(pos.x, pos.y, 'flares', {
    frame: 'blue',
    lifespan: {min: 500, max: 2000},
    quantity: 20,
    frequency: 1000,
    scale: {start: 0, end: sprite.scale * 1.5},
    angle: {min: -180, max: 180},
    alpha: {start: 1, end: 0},
    blendMode: 'SCREEN',
    moveToX: {min: 0, max: 0},
    moveToY: {min: 0, max: 0},
    emitZone: {type: 'random', source: shape1}
  });
  particles.setDepth(height + 1);

  sprite.emitter_tween = Scene.tweens.add({
    targets: particles.emitter,
    speed: -200,
    duration: 2000,
    yoyo: true,
    repeat: -1
  });

  sprite.emitter = particles;
}

function ReversesSciFi3Broken(x, y, height, scene, mapID, sprite) {
  let pos = GetGlobalPos(x, y, mapID);
  let shape1 = new Phaser.Geom.Circle(0, 0, sprite.displayHeight * 12);

  let particles = Scene.add.particles(pos.x, pos.y, 'flares', {
    frame: 'smoke_puff',
    speed: {min: -50, max: 50},
    lifespan: {min: 5000, max: 20000},
    quantity: 40,
    frequency: 1000,
    scale: {start: 0, end: 10},
    angle: {min: -180, max: 180},
    alpha: {start: 1, end: 0},
    blendMode: 'SCREEN',
    emitZone: {type: 'random', source: shape1},
  });
  particles.setDepth(height + 1);

  sprite.emitter = particles;
}

export {SectorStructure_3_28, ReversesSciFi3Broken}
