<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Добыча руды</h2>

      <p>В мире игры, руда это один из видов ценных источников ресурсов, использующихся впоследствии для создания
        различных предметов.</p>

      <p>Сама руда представляет собой минералы, которые игрок находит в земле и в дальнейшем подвергает разработке. </p>

      <p>Всего в игре насчитывается пять видов минеральных руд и соответствующих сырьевых компонентов, которые из них
        можно получить:</p>

      <p>Стандартный вид руд (можно обнаружить во всех секторах):</p>
      <ul>
        <li>Медная руда</li>
        <li>Железная руда</li>
        <li>Ториевая руда</li>
      </ul>

      <p>Экзотический вид руд (можно обнаружить только в боевом или свободном типе секторов): </p>
      <ul>
        <li>Титановая руда</li>
        <li>Кремниевая руда</li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mining_1', 'png')+ ')',
            width: '400px',
            height: '140px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <p>Сами залежи руд образуются в игровом мире случайным образом, из-за чего возможность их отыскать и разработать,
        доступна любому игроку у кого есть необходимое снаряжение.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mining_2', 'png')+ ')',
            width: '400px',
            height: '167px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

      <h3 class="head">Принцип разработки руды</h3>
      <ul>
        <li><span class="importantly">Отыскать месторождение руды</span>.</li>
        <li><span class="importantly">Выбрать снаряжение для добычи руды</span> - это слот в вашем корпусе, куда вы
          устанавливаете
          промышленный/горнодобывающий лазер или иное подобное устройство.
        </li>
        <li><span class="importantly">Кликнуть ЛКМ по месторождению руды</span> - данное действий запустит процесс
          добычи посредством устройства.
        </li>
        <li><span class="importantly">Ожидать окончания разработки</span> - индикатор заполнения шкалы, демонстрирующий
          количество руды в месторождении и
          количество собранных вами ресурсов.
        </li>
      </ul>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mining_3', 'gif')+ ')',
            width: '300px',
            height: '185px',
            marginLeft: 'calc(50% - 150px)',
            }"/>

      <!--    <h3 class="headSection">Добыча руды c помощью строения:</h3>-->
      <!--    <ul>-->
      <!--      <li>Постройте экстрактор ресурсов над залежами реды.</li>-->
      <!--      <li>Заправте экстрактор топливом и он начнет автоматически добывать ресурсы</li>-->
      <!--      <li>Когда экстратор выполнить 1 цикл добычи он сбросить ресурсы в ящик/транспорт/склад которые расположены над-->
      <!--        зеленым квадратом-->
      <!--      </li>-->
      <!--      <li>Если ресурсы сбросить некуда добыча пекращается пока кто то не заберет ресурсы</li>-->
      <!--    </ul>-->

      <!--    <div class="detailImg" :style="{-->
      <!--            backgroundImage: 'url(' + $parent.getUrlImg('extractor_mining', 'gif')+ ')',-->
      <!--            width: '300px',-->
      <!--            height: '250px',-->
      <!--            marginLeft: 'calc(50% - 150px)',-->
      <!--            }"/>-->
      <h2 class="head">Разрушение руды</h2>
      <p>Если стрелять в руду на карте, то она будет разрушатся и разбрасывать вокруг себя руду, но такой вид копания не
        эффективен и в итоге большая часть руды будет уничтожена.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mining_4', 'gif')+ ')',
            width: '400px',
            height: '330px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Ore Mining</h2>

      <p>In the game world, ore is one of the types of valuable resource sources, subsequently used to create various
        items.</p>

      <p>The ore itself consists of minerals that the player finds in the ground and later develops.</p>

      <p>There are a total of five types of mineral ores and the corresponding raw components that can be obtained from
        them:</p>

      <p>Standard type of ores (can be found in all sectors):</p>
      <ul>
        <li>Copper ore</li>
        <li>Iron ore</li>
        <li>Thorium ore</li>
      </ul>

      <p>Exotic type of ores (can be found only in combat or free type sectors): </p>
      <ul>
        <li>Titanium ore</li>
        <li>Silicon ore</li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('mining_1', 'png')+ ')',
        width: '400px',
        height: '140px',
        marginLeft: 'calc(50% - 200px)',
      }"/>

      <p>Ore deposits themselves are formed randomly in the game world, which is why the opportunity to find and develop
        them is available to any player who has the necessary equipment.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('mining_2', 'png')+ ')',
        width: '400px',
        height: '167px',
        marginLeft: 'calc(50% - 200px)',
      }"/>

      <h3 class="head">The Principle of Ore Development</h3>
      <ul>
        <li><span class="importantly">Find an ore deposit</span>.</li>
        <li><span class="importantly">Choose equipment for ore mining</span> - this is a slot in your hull where you
          install an industrial/mining laser or a similar device.
        </li>
        <li><span class="importantly">Click LMB on the ore deposit</span> - this action will start the mining process
          using the device.
        </li>
        <li><span class="importantly">Wait for the development to finish</span> - an indicator filling the scale,
          showing the amount of ore in the deposit and the number of resources you have collected.
        </li>
      </ul>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('mining_3', 'gif')+ ')',
        width: '300px',
        height: '185px',
        marginLeft: 'calc(50% - 150px)',
      }"/>

      <h2 class="head">Ore destruction</h2>
      <p>If you shoot at the ore on the map, it will be destroyed and scatter ore around itself, but this type of
        digging is not effective and as a result most of the ore will be destroyed.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('mining_4', 'gif')+ ')',
            width: '400px',
            height: '330px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

    </template>
  </div>
</template>

<script>
export default {
  name: "Mining",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
