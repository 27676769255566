var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Предметы на земле")]),_vm._v(" "),_c('p',[_vm._v(" Когда вы выбрасываете предметы из трюма, они падают на землю. Эти предметы не взаимодействуют с окружающей\n      средой, но окружающая среда может влиять на них. При столкновении с транспортами, объектами или другими\n      предметами\n      они отскакивают.")]),_vm._v(" "),_c('p',[_vm._v("Их можно подобрать если в трюме достаточно места.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('map_items', 'png')+ ')',
          width: '250px',
          height: '200px',
          marginLeft: 'calc(50% - 125px)',
    })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Items on the Ground")]),_vm._v(" "),_c('p',[_vm._v(" When you throw items out of the hold, they fall to the ground. These items do not interact with the\n      environment, but the environment can affect them. When colliding with transports, objects, or other items, they\n      bounce off.")]),_vm._v(" "),_c('p',[_vm._v("You can pick them up if there is enough space in the hold.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('map_items', 'png')+ ')',
          width: '250px',
          height: '200px',
          marginLeft: 'calc(50% - 125px)',
    })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }