<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h3 class="head">Телепорты</h3>
      <p>Телепорты - масштабные устройства мгновенного перемещения, чья технология производства уходит глубоко в историю
        предтеч, а потому, фракции синтетов используют уцелевшие остатки сего наследия в сотни тысяч веков, не в силах
        создать нечто подобное самостоятельно.</p>

      <p>Телепорты, это самый быстрый и эффективный способ переместиться от одного сектора в другой, таким образом
        миновав
        опасные участки планеты, где царят непреодолимые радиационные бури. </p>

      <p>По внешнему виду, телепорт, это громадная платформа, куда заезжают синтеты, после чего, они подвергаются
        процессу
        распыления и повторного сбора молекулярной копии на следующей платформе телепорта. В случае, если все точки
        выхода
        из телепорта будут заняты, устройство расчистит площадку, позволяя через короткое время осуществить новую
        попытку
        перехода.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('teleport_1', 'png')+ ')',
        width: '480px',
        height: '238px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

      <p>Выходы из телепорта выглядят вот так:</p>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('teleport_2', 'png')+ ')',
        width: '480px',
        height: '170px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
      <br>
      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('teleport_3', 'gif')+ ')',
        width: '340px',
        height: '230px',
        marginLeft: 'calc(50% - 170px)',
        }"/>

      <p>Стоит помнить, что телепорты, это односторонние устройства перемещения. А потому, вернуться в прошлый сектор
        тем
        же самым путём - тем же самым телепортом, уже никак не выйдет. Опытному синтету, прежде, всегда стоит проверять
        свою карту, чтобы удостовериться в связях телепортов между собой. </p>
    </template>
    <template v-if="lang==='EN'">
      <h3 class="head">Teleports</h3>
      <p>Teleports are large-scale instant transportation devices, whose production technology dates back deep into the
        history of the precursors, and therefore, the synthetic factions use the surviving remnants of this legacy for
        hundreds of thousands of centuries, unable to create something similar on their own.</p>

      <p>Teleports are the fastest and most efficient way to move from one sector to another, thus bypassing dangerous
        parts of the planet where insurmountable radiation storms reign.</p>

      <p>By appearance, a teleport is a huge platform where synthetics drive in, after which they undergo a process of
        disintegration and reassembly of a molecular copy at the next teleport platform. In case all exit points from
        the teleport are occupied, the device will clear the area, allowing a new attempt to transition after a short
        time.</p>

      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('teleport_1', 'png')+ ')',
      width: '480px',
      height: '238px',
      marginLeft: 'calc(50% - 240px)',
      }"/>

      <p>The exits from the teleport look like this:</p>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('teleport_2', 'png')+ ')',
      width: '480px',
      height: '170px',
      marginLeft: 'calc(50% - 240px)',
      }"/>
      <br>
      <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('teleport_3', 'gif')+ ')',
      width: '340px',
      height: '230px',
      marginLeft: 'calc(50% - 170px)',
      }"/>

      <p>It is worth remembering that teleports are one-way transportation devices. Therefore, it is not possible to
        return to the previous sector by the same path - by the same teleport. An experienced synthetic should always
        check their map beforehand to ensure the connections between teleports.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Teleports",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
