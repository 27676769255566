<template>
  <div style="margin: 3px;">
    <div class="row" v-for="(row, y) in grid">
      <div class="cell cursor_hover" v-for="(cell, x) in row" :class="{select: cell.select}" @click="rotate(y,x)">

        <!--        <div class="xy">{{ x }}, {{ y }}</div>-->
        <div v-if="cell.hide" :class="{hide: true}"/>

        <div class="inner"
             v-if="!cell.hide"
             :class="{[cell.type]: true, full: cell.full}"
             :style="{transform: 'rotate(' + cell.angle + 'deg)'}"/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pipes",
  props: ['win'],
  data() {
    return {
      gridSize: 6,
      grid: [
        [ // row 1
          {type: 'src', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
        ],
        [ // row 2
          {type: 'pipe4', angle: 0},
          {type: 'pipe2', angle: 0},
          {type: 'pipe3', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
        ],
        [ // row 3
          {type: 'pipe1', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe3', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
        ],
        [ // row 4
          {type: 'pipe1', angle: 0},
          {type: 'pipe2', angle: 0},
          {type: 'pipe3', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
        ],
        [ // row 5
          {type: 'pipe1', angle: 0},
          {type: 'pipe2', angle: 0},
          {type: 'pipe3', angle: 0},
          {type: 'pipe4', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
        ],
        [ // row 6
          {type: 'pipe1', angle: 0},
          {type: 'pipe2', angle: 0},
          {type: 'pipe3', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'pipe1', angle: 0},
          {type: 'dst', angle: 180},
        ],
      ],
      typePipes: {
        src: {
          inputs: [0],
        },
        dst: {
          inputs: [0],
        },
        pipe1: {
          inputs: [0, 180],
        },
        pipe2: {
          inputs: [270, 0],
        },
        pipe3: {
          inputs: [270, 0, 90],
        },
        pipe4: {
          inputs: [0, 90, 180, 270],
        },
      },
    }
  },
  mounted() {
    this.generateGrid()
  },
  methods: {
    rotate(y, x) {

      if (this.$props.win) {
        return;
      }

      if (this.grid[y][x].type === 'src' || this.grid[y][x].type === 'dst') {
        return;
      }

      if (this.grid[y][x].hide) {
        this.$set(this.grid[y][x], 'hide', false)
        this.checkFullPipesAndWin()
        return
      }

      let angle = this.grid[y][x].angle + 90;
      if (angle >= 360) {
        angle = 0;
      }

      this.$set(this.grid[y][x], 'angle', angle);
      this.checkFullPipesAndWin()
    },
    checkFullPipesAndWin() {
      // поиск пути от источника во все возможные участки пути и поставить им флаг full = true, если удалось пройти до dst то победа
      let openPoints = {}
      let closePoints = {}
      let current = null;
      let end = null;
      let wave = 0;

      for (let y in this.grid) {
        for (let x in this.grid[y]) {
          this.$set(this.grid[y][x], 'full', false);

          if (this.grid[y][x].type === 'src') {
            current = {x: Number(x), y: Number(y), angle: this.grid[y][x].angle, inputs: [0], wave: wave}
          }
          if (this.grid[y][x].type === 'dst') {
            end = {x: Number(x), y: Number(y), angle: this.grid[y][x].angle, inputs: [0], wave: wave}
          }
        }
      }

      if (!current) return;
      openPoints[current.x + ':' + current.y] = current;

      while (Object.keys(openPoints).length > 0) {
        wave++

        for (let k in openPoints) {

          // взятие соседей
          for (let input of openPoints[k].inputs) {
            let neighbour
            if (this.prepareAngle(openPoints[k].angle + input) === 0) {
              neighbour = {x: openPoints[k].x + 1, y: openPoints[k].y}
            }
            if (this.prepareAngle(openPoints[k].angle + input) === 90) {
              neighbour = {x: openPoints[k].x, y: openPoints[k].y + 1}
            }
            if (this.prepareAngle(openPoints[k].angle + input) === 180) {
              neighbour = {x: openPoints[k].x - 1, y: openPoints[k].y}
            }
            if (this.prepareAngle(openPoints[k].angle + input) === 270) {
              neighbour = {x: openPoints[k].x, y: openPoints[k].y - 1}
            }

            let newKey = neighbour.x + ':' + neighbour.y;
            if (closePoints.hasOwnProperty(newKey) || openPoints.hasOwnProperty(newKey)) {
              continue
            }

            if (!this.checkCell(neighbour.x, neighbour.y, this.gridSize, this.grid, false)) {
              continue
            }

            let cell = this.grid[neighbour.y][neighbour.x]
            if (cell.hide) {
              continue
            }

            let typePipe = this.typePipes[cell.type];
            if (!typePipe) {
              continue;
            }

            neighbour.inputs = typePipe.inputs;
            neighbour.wave = wave;
            neighbour.angle = cell.angle;

            for (let input2 of typePipe.inputs) {
              // проверяем что у соседа труда повернута 1м инпутом в этот инпут
              if (this.prepareAngle(cell.angle + input2) === this.prepareAngle(openPoints[k].angle + input + 180)) {

                if (cell.type === 'dst') {
                  this.$set(this.grid[neighbour.y][neighbour.x], 'type', 'src');
                  this.$parent.win = true
                }

                openPoints[newKey] = neighbour
                break
              } else {
                // console.log('xy: ', neighbour.y, neighbour.x, 'angle: ', this.prepareAngle(cell.angle + input2), this.prepareAngle(openPoints[k].angle + input + 180))
              }
            }
          }

          closePoints[k] = openPoints[k]
          delete openPoints[k];
        }
      }

      for (let k in closePoints) {
        this.$set(this.grid[closePoints[k].y][closePoints[k].x], 'full', true);
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    generateGrid() {
      let newGrid = [];
      for (let i = 0; i < this.gridSize; i++) {

        let row = [];

        for (let j = 0; j < this.gridSize; j++) {
          if (i === 0 && j === 0) {
            row.push({type: 'src', angle: 0, hide: false})
            continue
          }

          if (i === this.gridSize - 1 && j === this.gridSize - 1) {
            row.push({type: 'dst', angle: 180, hide: false})
            continue
          }

          row.push({type: '', angle: 0, hide: true})
        }

        newGrid.push(row)
      }

      this.grid = newGrid
      this.getRandomSolutions(this.grid)
    },
    getRandomSolutions() {
      // надо убедится что у текущего грида есть хотя бы 1 комбинация для победы

      let startCell, endCell, currentCell, direction, endDirection

      // ищем старт и конец, в моем случае они фиксированы, но их можно будет расширять
      for (let y in this.grid) {
        for (let x in this.grid[y]) {
          if (this.grid[y][x].type === 'src') {
            if (this.grid[y][x].angle === 0) startCell = {x: Number(x + 1), y: Number(y)}
            if (this.grid[y][x].angle === 90) startCell = {x: Number(x), y: Number(y + 1)}
            // if (grid[i][j].angle === 180) startCell = {x: i + 1, y: j}
            // if (grid[i][j].angle === 270) startCell = {x: i + 1, y: j}

            direction = this.grid[y][x].angle
          }

          if (this.grid[y][x].type === 'dst') {
            // if (grid[i][j].angle === 0) startCell = {x: i + 1, y: j}
            // if (grid[i][j].angle === 90) startCell = {x: i, y: j + 1}
            if (this.grid[y][x].angle === 180) endCell = {x: Number(x - 1), y: Number(y)}
            if (this.grid[y][x].angle === 270) endCell = {x: Number(x), y: Number(y - 1)}

            endDirection = this.grid[y][x].angle
          }
        }
      }

      currentCell = startCell
      let getNeedDirection = function (f) {
        let need = direction + 180
        return f(need)
      }

      let timeOut = 0
      while (!((currentCell.x === endCell.x && currentCell.y === endCell.y) || timeOut > 100)) {
        timeOut++

        if (timeOut > 100) {
          console.log("no path")
          this.generateGrid()
          return;
        }

        let pipeKey //= 'pipe' + Number(this.getRandomInt(4) + 1)
        if (this.getRandomInt(4) === 0) {
          pipeKey = ['pipe3', 'pipe4'][this.getRandomInt(2)]
        } else {
          pipeKey = ['pipe1', 'pipe2'][this.getRandomInt(2)]
        }


        let typePipe = this.typePipes[pipeKey];

        let pipeAngle = 0;
        let find = false;

        for (let input of this.shuffle(typePipe.inputs)) {

          if (find) break;

          for (let angle of this.shuffle([0, 90, 180, 270])) {
            if (this.prepareAngle(angle + input) === getNeedDirection(this.prepareAngle)) {

              let pass = -1
              let nextCell = {}

              for (let input2 of typePipe.inputs) {
                if (input === input2) continue;

                // должен быть хотя бы 1 инпут который не упирается в стену
                if (this.prepareAngle(angle + input2) === 0) {
                  nextCell = {x: currentCell.x + 1, y: currentCell.y}
                }
                if (this.prepareAngle(angle + input2) === 90) {
                  nextCell = {x: currentCell.x, y: currentCell.y + 1}
                }
                if (this.prepareAngle(angle + input2) === 180) {
                  nextCell = {x: currentCell.x - 1, y: currentCell.y}
                }
                if (this.prepareAngle(angle + input2) === 270) {
                  nextCell = {x: currentCell.x, y: currentCell.y - 1}
                }

                if (!this.checkCell(nextCell.x, nextCell.y, this.gridSize, this.grid, true)) {
                  continue
                }

                pass = input2
                break
              }

              if (pass < 0) {
                continue
              }

              direction = this.prepareAngle(angle + pass)
              find = true;
              pipeAngle = this.prepareAngle(angle);

              this.$set(this.grid[currentCell.y][currentCell.x], 'type', pipeKey);
              this.$set(this.grid[currentCell.y][currentCell.x], 'angle', pipeAngle);
              currentCell = nextCell;
              break
            }
          }
        }
      }

      let find = false
      if (timeOut < 100) {
        // ищем тайтл который подойдет к предудйщему и дст
        let keys = Object.keys(this.typePipes);
        for (let pipeKey of this.shuffle(keys)) {
          for (let input of this.typePipes[pipeKey].inputs) {
            for (let angle of [0, 90, 180, 270]) {
              if (this.prepareAngle(angle + input) === getNeedDirection(this.prepareAngle)) {

                for (let input2 of this.typePipes[pipeKey].inputs) {
                  if (input === input2) continue;

                  if (this.prepareAngle(angle + input2) === this.prepareAngle(endDirection + 180)) {
                    this.$set(this.grid[currentCell.y][currentCell.x], 'type', pipeKey);
                    this.$set(this.grid[currentCell.y][currentCell.x], 'angle', angle);
                    find = true
                  }
                }
              }
            }
          }
        }
      }

      if (!find) {
        console.log("no path")
        this.generateGrid()
        return;
      }

      for (let y in this.grid) {
        for (let x in this.grid[y]) {

          if (this.grid[y][x].type === 'src' || this.grid[y][x].type === 'dst') continue;

          let angle = [0, 90, 180, 270][this.getRandomInt(4)]

          if (this.grid[y][x].type === '') {
            if (this.getRandomInt(4) === 0) {
              this.$set(this.grid[y][x], 'type', ['pipe3', 'pipe4'][this.getRandomInt(2)]);
            } else {
              this.$set(this.grid[y][x], 'type', ['pipe1', 'pipe2'][this.getRandomInt(2)]);
            }
          }

          this.$set(this.grid[y][x], 'angle', angle);
        }
      }
    },
    prepareAngle(angle) {
      if (angle >= 360) {
        angle -= 360
      }

      if (angle >= 360) {
        angle -= 360
      }

      if (angle < 0) {
        angle += 360
      }

      if (angle < 0) {
        angle += 360
      }

      return angle
    },
    checkCell(x, y, gridSize, grid, checkEmpty) {
      if (x < 0 || y < 0 || x > gridSize - 1 || y > gridSize - 1) {
        return false
      }

      if (checkEmpty && grid[y][x].type !== '') {
        return false
      }

      return true
    },
    shuffle(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }
  },
}
</script>

<style scoped>
.row {
  clear: both;
}

.cell {
  height: 32px;
  width: 32px;
  float: left;
  background: rgba(0, 0, 0, 0.5);
  margin: 1px;
  border-radius: 2px;
  position: relative;
}

.src, .dst, .pipe1, .pipe2, .pipe3, .pipe4, .hide {
  width: 100%;
  height: 100%;
  background-size: contain;
  transition: 100ms;
}

.src {
  background-image: url('../../assets/icons/mini_game_src.png');
}

.dst {
  background-image: url('../../assets/icons/mini_game_dst.png');
}

.pipe1 {
  background-image: url('../../assets/icons/mini_game_pipe1.png');
}

.pipe1.full {
  background-image: url('../../assets/icons/mini_game_pipe1_full.png');
}

.pipe2 {
  background-image: url('../../assets/icons/mini_game_pipe2.png');
}

.pipe2.full {
  background-image: url('../../assets/icons/mini_game_pipe2_full.png');
}

.pipe3 {
  background-image: url('../../assets/icons/mini_game_pipe3.png');
}

.pipe3.full {
  background-image: url('../../assets/icons/mini_game_pipe3_full.png');
}

.pipe4 {
  background-image: url('../../assets/icons/mini_game_pipe4.png');
}

.pipe4.full {
  background-image: url('../../assets/icons/mini_game_pipe4_full.png');
}


.hide {
  background-image: url('../../assets/icons/question-mark.png');
}

.select {
  outline: 1px red solid;
}

.xy {
  color: yellowgreen;
  text-shadow: 1px 1px 1px black;
  position: absolute;
  top: 2px;
  left: 2px;
}
</style>
