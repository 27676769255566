var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Explores")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('Explores', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('explores_logo_min', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
            marginLeft: '50px'
          })}),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("Explores - фракция, чья иерархическая основа - конгломерат великого множества разумов, без устали трудящихся\n      над\n      неисчислимым множеством вопросов как настоящего, так и прошлого.")]),_vm._v(" "),_c('p',[_vm._v("Познание, научные открытия, искусственная эволюция естества - вот, что отличает данную фракцию от всех\n      прочих. ")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Explorers")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('Explores', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('explores_logo_min', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
            marginLeft: '50px'
          })}),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("The Explorers - a faction whose hierarchical foundation is a conglomerate of a great multitude of minds,\n      tirelessly working\n      on an innumerable number of questions of both the present and the past.")]),_vm._v(" "),_c('p',[_vm._v("Knowledge, scientific discoveries, artificial evolution of nature - that's what distinguishes this faction from\n      all\n      others. ")])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("“Воля - наша единственная движущая сила.\n        Окно реальности, за которым лежит истинный внешний мир. Посредством наших умственных способностей, мы сумеем\n        познать данный мир и погрузиться в него…”\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Explores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("\"Will is our only driving force.\n        A window to reality, behind which lies the true external world. Through our mental abilities, we will be able\n        to comprehend this world and immerse ourselves in it…\"\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Explorers")])])
}]

export { render, staticRenderFns }