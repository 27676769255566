import BooleanBuffer from "phaser3-rex-plugins/plugins/utils/arraybuffers/BooleanBuffer";

function intFromBytes(x) {
  let val = 0;
  for (let i = 0; i < x.length; ++i) {
    val += x[i];
    if (i < x.length - 1) {
      val = val << 8;
    }
  }
  return val;
}

function byteArrayToLong(byteArray) {
  return byteArray.reduce((a, c, i) => a + c * 2 ** (56 - i * 8), 0)
}

function getInt64Bytes(x) {
  let bytes = [];
  let i = 4;
  do {
    bytes[--i] = x & (255);
    x = x >> 8;
  } while (i)
  return bytes;
}

function base64ToArrayBuffer(base64) {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

function GetArrayBytesFromInt32(n) {
  return new Uint8Array(new Int32Array([n]).buffer)
}

export {intFromBytes, getInt64Bytes, byteArrayToLong, base64ToArrayBuffer, GetArrayBytesFromInt32}
