<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Чертежи</h2>

      <p>Чертежи - универсальный способ по быстрому созданию предметов. В этих нейро-графических изображениях содержится
        вся необходимая информация, касающаяся процесса создания той или иной вещи по чертежу.
      </p>

      <p>Вы можете посмотреть подробности о любом чертеже, открыв его
        карточку в своем инвентаре и перейдя на вкладку “Производство”. Там вы увидите список всех компонентов,
        результат,
        а также время производства.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('blueprint', 'png')+ ')',
            width: '320px',
            height: '459px',
            marginLeft: 'calc(50% - 160px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Blueprints</h2>

      <p>Blueprints are a universal way to quickly create items. These neuro-graphic images contain all the necessary
        information regarding the process of creating a particular item from the blueprint.</p>

      <p>You can view the details of any blueprint by opening its card in your inventory and going to the "Production"
        tab. There you will see a list of all components, the result, and the production time.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('blueprint', 'png')+ ')',
        width: '320px',
        height: '459px',
        marginLeft: 'calc(50% - 160px)',
        }"></div>

    </template>
  </div>
</template>

<script>
export default {
  name: "Blueprints",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
