<template>
  <app-in-base/>
</template>

<script>

import InBase from './InBase'

export default {
  name: "Department",
  components: {
    AppInBase: InBase,
  }
}
</script>

<style scoped>

</style>
