import {Scene} from "../create";
import {gameStore} from "../store";
import {GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {GetGlobalPos} from "../map/gep_global_pos";
import {CreateMapBar} from "../interface/status_layer";
import {intFromBytes} from "../../utils";
import {GetSpriteEquip} from "../unit/equip";
import {SetDayCycleDrone} from "../day_cycle/day_cycle";
import {addIgnoreObject} from "../interface/mini_map";

function CreateDrone(droneState) {

  if (!gameStore.gameReady) return;

  let textureLength = intFromBytes(droneState.slice(51, 52))
  let sprite = String.fromCharCode.apply(String, droneState.subarray(52, 52 + textureLength))
  droneState = {
    id: intFromBytes(droneState.slice(0, 4)),
    x: intFromBytes(droneState.slice(4, 8)),
    y: intFromBytes(droneState.slice(8, 12)),
    z: intFromBytes(droneState.slice(12, 16)),
    r: intFromBytes(droneState.slice(16, 20)),
    max_hp: intFromBytes(droneState.slice(20, 24)),
    hp: intFromBytes(droneState.slice(24, 28)),
    range_view: intFromBytes(droneState.slice(28, 32)),
    scale: intFromBytes(droneState.slice(32, 33)),
    owner_id: intFromBytes(droneState.slice(33, 37)),
    life_percent: intFromBytes(droneState.slice(37, 38)),
    equip_type: intFromBytes(droneState.slice(38, 39)),
    equip_slot: intFromBytes(droneState.slice(39, 40)),
    owner_object_id: intFromBytes(droneState.slice(40, 44)),
    owner_object_type: gameStore.mapBinItems[intFromBytes(droneState.slice(44, 45))],
    warrior: intFromBytes(droneState.slice(45, 46)) === 1,
    fraction: intFromBytes(droneState.slice(46, 47)),
    corporation_id: intFromBytes(droneState.slice(47, 51)),
    sprite: sprite,
  }

  if (gameStore.drones[droneState.id]) {
    return;
  }

  if (droneState.owner_object_type === "unit") {
    let equipSlot = GetSpriteEquip(droneState.owner_object_id, droneState.equip_type, droneState.equip_slot)
    if (equipSlot && equipSlot.sprite) {
      equipSlot.sprite.setVisible(false);
      if (equipSlot.sprite.shadow) equipSlot.sprite.shadow.setVisible(false);
    }
  }

  let pos = GetGlobalPos(droneState.x, droneState.y, gameStore.map.id);
  droneState.x = pos.x;
  droneState.y = pos.y;

  let shadow, drone
  if (droneState.sprite === "fly_fauna_1" || droneState.sprite === "fly_fauna_2" || droneState.sprite === "fly_fauna_3" || droneState.sprite === "fly_fauna_4") {
    droneAnimate(droneState.sprite)

    shadow = Scene.make.sprite({
      x: GetOffsetShadowByMapLvl(droneState.z, droneState.x, droneState.y, 0.5, 2.5, gameStore.map.id).x,
      y: GetOffsetShadowByMapLvl(droneState.z, droneState.x, droneState.y, 0.5, 2.5, gameStore.map.id).y,
      key: droneState.sprite,
      add: true
    });
    // shadow.anims.load(droneState.sprite)
    shadow.play(droneState.sprite);
    addIgnoreObject(shadow);

    drone = Scene.make.sprite({
      x: droneState.x,
      y: droneState.y,
      key: droneState.sprite,
      add: true
    });
    // drone.anims.load(droneState.sprite)
    drone.play(droneState.sprite);
    addIgnoreObject(drone);

  } else {

    shadow = Scene.make.image({
      x: GetOffsetShadowByMapLvl(droneState.z, droneState.x, droneState.y, 0.5, 2.5, gameStore.map.id).x,
      y: GetOffsetShadowByMapLvl(droneState.z, droneState.x, droneState.y, 0.5, 2.5, gameStore.map.id).y,
      key: 'equips',
      frame: droneState.sprite,
      add: true
    });
    addIgnoreObject(shadow);

    drone = Scene.make.image({
      x: droneState.x,
      y: droneState.y,
      key: 'equips',
      frame: droneState.sprite,
      add: true
    });
    addIgnoreObject(drone);
  }

  shadow.setOrigin(0.5);
  shadow.setScale(GetSpriteSizeByMapLvl(droneState.z, droneState.scale / 100, 0.02).x);
  shadow.setAlpha(0.2);
  shadow.setTint(0x000000);
  shadow.setAngle(droneState.r)

  drone.setOrigin(0.5);
  drone.setScale(GetSpriteSizeByMapLvl(droneState.z, droneState.scale / 100, 0.02).x);
  drone.setAlpha(1);
  drone.setAngle(droneState.r)
  drone.shadow = shadow;

  drone.setDepth(902);
  drone.shadow.setDepth(901);

  let infoDrone = gameStore.gameTypes.drones[droneState.sprite];
  if (infoDrone && infoDrone.wheel_anchors) {
    createEngage(drone, infoDrone, droneState.z, droneState.scale, droneState.r)
  }

  droneState.sprite = drone;
  gameStore.drones[droneState.id] = droneState
  SetDayCycleDrone(gameStore.drones[droneState.id])

  if (droneState.max_hp !== 99990) {
    CreateMapBar(droneState.sprite, droneState.max_hp, droneState.hp, 10, null, Scene, 'drone', droneState.id, 'hp', 1);
  }
}

function createEngage(drone, info, z, scale, rotate) {
  drone.engages = {}

  for (let k in info.wheel_anchors) {
    let s = info.wheel_anchors[k]
    drone.engages[k] = getEngage(drone, s.type, z, scale, rotate, s.real_x_attach, s.real_y_attach, s.rotate, s.height, s.scale)
  }
}

let droneEngages = {
  t1: [],
  t2t3: [],
}

function getEngage(drone, type, z, scale, rotate, real_x_attach, real_y_attach, offsetRotate, offsetHeight, offsetScale) {
  let engage = {};

  engage.typeEngage = type;
  engage.offsetRotate = offsetRotate;
  engage.offsetHeight = offsetHeight;
  engage.offsetScale = offsetScale;
  engage.real_x_attach = real_x_attach;
  engage.real_y_attach = real_y_attach;


  if (type === "1") {
    if (droneEngages.t1.length === 0) {
      engage.emitter = Scene.add.particles(0, 0, 'flares', {
        frame: 'yellow',
        speed: {min: 0, max: (scale * 1.5) * offsetScale},
        lifespan: (10 * scale) + 500,
        quantity: 1,
        scale: {start: (0.5) * offsetScale, end: (1) * offsetScale},
        alpha: {start: 0.4, end: 0},
        blendMode: 'ADD',
        frequency: 64,
      });
      engage.emitter.setDepth(z - 2);
      addIgnoreObject(engage);
    } else {
      engage.emitter = droneEngages.t1.shift();
      engage.emitter.start()

      engage.emitter.ops.speedX.end = (scale * 1.5) * offsetScale;
      engage.emitter.ops.speedY.end = (scale * 1.5) * offsetScale;

      engage.emitter.ops.scaleX.start = (0.5) * offsetScale;
      engage.emitter.ops.scaleX.end = (1) * offsetScale;

      engage.emitter.ops.lifespan.current = (10 * scale) + 500;
      engage.emitter.ops.lifespan.propertyValue = (10 * scale) + 500;
    }
  }

  if (type === "2" || type === "3") {

    let frame = 'yellow'
    if (type === "3") frame = 'blue'

    if (droneEngages.t2t3.length === 0) {
      engage.emitter = Scene.add.particles(0, 0, 'flares', {
        frame: frame,
        lifespan: 256,
        speed: {min: 0, max: 50},
        angle: {min: 180 + rotate + offsetRotate, max: 180 + rotate + offsetRotate},
        gravityY: 0,
        scale: {start: (0.35) * offsetScale, end: (0) * offsetScale},
        quantity: 1,
        blendMode: 'SCREEN',
        alpha: {start: 1, end: 0},
        name: 'bullet',
        frequency: 48,
      });
      engage.emitter.setDepth(z - 2);
      addIgnoreObject(engage);
    } else {
      engage.emitter = droneEngages.t2t3.shift();
      engage.emitter.start()

      engage.emitter.setEmitterFrame(frame);

      engage.emitter.ops.angle.start = 180 + rotate + offsetRotate;
      engage.emitter.ops.angle.end = 180 + rotate + offsetRotate;

      engage.emitter.ops.scaleX.start = (0.25) * offsetScale;
      engage.emitter.ops.scaleX.end = (0) * offsetScale;
    }
  }

  // let x = drone.x + (real_x_attach * drone.scale)
  // let y = drone.y + (real_y_attach * drone.scale)

  return engage
}

let initAnimate = {};

function droneAnimate(droneSprite) {
  if (!gameStore.cacheAnimate.hasOwnProperty(droneSprite)) {
    gameStore.cacheAnimate[droneSprite] = true;

    if (!initAnimate[droneSprite]) {
      Scene.textures.addSpriteSheetFromAtlas(droneSprite, {
        atlas: 'equips',
        frame: droneSprite,
        frameWidth: gameStore.unitSpriteSize,
        frameHeight: gameStore.unitSpriteSize,
      });

      initAnimate[droneSprite] = true
    }

    Scene.anims.create({
      key: droneSprite,
      frames: Scene.anims.generateFrameNumbers(droneSprite, {}),
      frameRate: 8,
      repeat: -1
    });
  }
}

export {CreateDrone, droneEngages}
