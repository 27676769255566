var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Детали")]),_vm._v(" "),_c('p',[_vm._v("Детали это составные компоненты которые используются в производстве устройств, вооружения и корпусов. Они могут\n      состоять как из сырья так и из других деталей.")]),_vm._v(" "),_c('p',[_vm._v("На заводе полуфабрикатов можно изготовить детали из сырья, однако база будет брать % ресурсов за создание\n      нежели эти же детали делать с помощью\n      "),_c('router-link',{attrs:{"to":"blueprints"}},[_vm._v("чертежа")]),_vm._v("\n      на главной базе сектора.\n    ")],1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' +  _vm.$parent.getUrlImg('parts_production_1', 'png')+ ')',
          width: '400px',
          height: '458px',
          marginLeft: 'calc(50% - 200px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Parts")]),_vm._v(" "),_c('p',[_vm._v("Parts are the components used in the production of devices, weapons, and hulls. They can consist of both raw\n      materials and other parts.")]),_vm._v(" "),_c('p',[_vm._v("At the semi-finished products factory, it is possible to manufacture parts from raw materials, however, the\n      base will take % of the resources for creation compared to making these same parts using a\n      "),_c('router-link',{attrs:{"to":"blueprints"}},[_vm._v("blueprint")]),_vm._v("\n      at the main base of the sector.\n    ")],1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' +  _vm.$parent.getUrlImg('parts_production_1', 'png')+ ')',
          width: '400px',
          height: '458px',
          marginLeft: 'calc(50% - 200px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }