<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: панель снаряжения</h2>

      <p>Панель снаряжения позволяет использовать снаряжение, что бы разместить там снаряжение нужно перетащить его в
        ячейку панели.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_quick_panel', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Equipment Panel</h2>

      <p>The equipment panel allows you to use equipment. To place equipment there, you need to drag it into a panel
        slot.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_quick_panel', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationQuickPanel",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
