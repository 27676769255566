import {gameStore} from "./store";
import {Scene} from "./create";
import {MapSize} from "./map/createMap";
import {joystickState, updateTargetJoystick} from "./interface/joystick";
import {GetArrayBytesFromInt32} from "../utils";

function sendInputsToBack(scene, connect) {
  if (gameStore.inputType === "wasd" && !gameStore.HoldKeys && gameStore.mapEditorState.mpID === 0) {

    let leftKeyDown = false;
    let rightKeyDown = false;
    let upKeyDown = false;
    let downKeyDown = false;
    let fire = false
    let pos = {};

    let getXY = function (pointer, yOffset = 0) {

      yOffset = yOffset * (1 / Scene.cameras.main.zoom)

      let x = Math.round((Scene.cameras.main.worldView.left + (pointer.x / Scene.cameras.main.zoom)) - MapSize);
      let y = Math.round((Scene.cameras.main.worldView.top + ((pointer.y + yOffset) / Scene.cameras.main.zoom)) - MapSize);

      return {x: x, y: y, worldX: pointer.x, worldY: pointer.y + yOffset}
    }

    let getFire = function (pointer) {
      return (pointer.leftButtonDown() && (!gameStore.HoldAttackMouse && !gameStore.MouseMoveInterface))
    }

    if (joystickState.joystick) {

      let targetPointer

      if (joystickState.joystick.pointer) {
        if (joystickState.joystick.pointer.id !== Scene.game.input.activePointer.id) {
          pos = getXY(Scene.game.input.activePointer, -1 * joystickState.attackRadius);
          fire = getFire(Scene.game.input.activePointer)
          targetPointer = Scene.game.input.activePointer
        } else if (Scene.input.pointer1.active && joystickState.joystick.pointer.id !== Scene.input.pointer1.id) {
          pos = getXY(Scene.input.pointer1, -1 * joystickState.attackRadius);
          fire = getFire(Scene.input.pointer1)
          targetPointer = Scene.input.pointer1
        } else {
          pos = gameStore.oldTargetPos;
        }

        leftKeyDown = joystickState.joystick.left;
        rightKeyDown = joystickState.joystick.right;
        upKeyDown = joystickState.joystick.up;
        downKeyDown = joystickState.joystick.down;
      } else {
        pos = getXY(Scene.game.input.activePointer, -1 * joystickState.attackRadius);
        fire = getFire(Scene.game.input.activePointer)
        targetPointer = Scene.game.input.activePointer
      }

      let lock = updateTargetJoystick(pos, targetPointer)
      fire = fire && !lock.lockAttack

      if (lock.deadZone) pos = gameStore.oldTargetPos
    } else {
      pos = getXY(Scene.game.input.activePointer);
      fire = getFire(Scene.game.input.activePointer);
    }

    gameStore.oldTargetPos = pos
    connect.ws.postMessage(getBinDataMsg({
      event: "i",
      select_units: gameStore.selectUnits,
      w: scene.wasd.up.isDown || scene.wasd.up2.isDown || upKeyDown,
      a: scene.wasd.left.isDown || scene.wasd.left2.isDown || leftKeyDown,
      s: scene.wasd.down.isDown || scene.wasd.down2.isDown || downKeyDown,
      d: scene.wasd.right.isDown || scene.wasd.right2.isDown || rightKeyDown,
      z: scene.wasd.z.isDown,
      sp: scene.wasd.sp.isDown,
      st: scene.wasd.st.isDown,
      x: pos.x,
      y: pos.y,
      fire: fire,
    }));
  }
}

function getBinDataMsg(data) {
  let byteData = new Uint8Array(16);

  byteData[0] = data.w ? 1 : 0
  byteData[1] = data.a ? 1 : 0
  byteData[2] = data.s ? 1 : 0
  byteData[3] = data.d ? 1 : 0
  byteData[4] = data.z ? 1 : 0
  byteData[5] = data.sp ? 1 : 0
  byteData[6] = data.st ? 1 : 0
  byteData[7] = data.fire ? 1 : 0

  let stopbyte = 7
  let xArray = GetArrayBytesFromInt32(data.x)
  for (let i of xArray) {
    stopbyte++
    byteData[stopbyte] = i
  }

  let yArray = GetArrayBytesFromInt32(data.y)
  for (let i of yArray) {
    stopbyte++
    byteData[stopbyte] = i
  }

  return byteData
}

export {sendInputsToBack}
