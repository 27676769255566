import {intFromBytes} from "../../utils";
import {gameStore} from "../store";
import {fractionByte} from "../../const";
import {Scene} from "../create";
import {addIgnoreObject} from "./mini_map";
import {MoveSprite} from "../utils/move_sprite";
import {GetGlobalPos} from "../map/gep_global_pos";
import store from "../../store/store";
import {getBarScale} from "./status_layer";

let selector = null;
let selectMiniMap = null;

let oldData = null;

function TargetInfo(data) {

  let openComponents = store.getters.getNeedOpenComponents

  if (data.length > 1) {
    data = parseMsg(data);

    let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);

    if (!selector) {
      selector = Scene.make.image({
        x: pos.x, y: pos.y, key: "select_unit", add: true
      });

      selector.setOrigin(0.5);
      selector.setDepth(900);
      selector.setDisplaySize(32 + (48 * getBarScale() / 2), 32 + (48 * getBarScale() / 2));

      addIgnoreObject(selector)

      // minimap
      selectMiniMap = Scene.make.image({
        x: pos.x, y: pos.y, key: "select_unit_minimap", add: true
      });

      selectMiniMap.setOrigin(0.5);
      selectMiniMap.setDepth(900);
      selectMiniMap.setDisplaySize(256, 256);
      Scene.cameras.main.ignore(selectMiniMap)
    }

    if (!selector.visible) {
      selector.setVisible(true);
      selector.setPosition(pos.x, pos.y)

      selectMiniMap.setVisible(true);
      selectMiniMap.setPosition(pos.x, pos.y)
    }

    if (data.fear === 1) {
      selector.setTint(0xb3dcfd);
      selectMiniMap.setTint(0xb3dcfd);
    } else if (data.fear === 2) {
      selector.setTint(0x0047ab);
      selectMiniMap.setTint(0x0047ab);
    } else if (data.relation < -100) {
      selector.setTint(0x00ff00);
      selectMiniMap.setTint(0x00ff00);
    } else if (data.relation >= -100 && data.relation < -30) {
      selector.setTint(0xaeda74);
      selectMiniMap.setTint(0xaeda74);
    } else if (data.relation > 30 && data.relation < 150) {
      selector.setTint(0xe5989b);
      selectMiniMap.setTint(0xe5989b);
    } else if (data.relation >= 150) {
      selector.setTint(0xff0000);
      selectMiniMap.setTint(0xff0000);
    } else {
      selector.setTint(0xffffff);
      selectMiniMap.setTint(0xffffff);
    }

    MoveSprite(selector, pos.x, pos.y, 48, null, false, false);
    MoveSprite(selectMiniMap, pos.x, pos.y, 48, null, false, false);

    // todo возмодная утечка проивзодительности из за вью внутри игры
    if (openComponents && (!openComponents['SelectTarget'] || !openComponents['SelectTarget'].open)) {
      store.commit({
        type: 'toggleWindow',
        id: "SelectTarget",
        component: "SelectTarget",
        forceOpen: true,
      });
    }

    if (data.typeTarget === "") data.typeTarget = "map"
    if (!oldData || oldData.unit_id !== data.unit_id || oldData.type !== data.type || oldData.owner_id !== data.owner_id ||
      oldData.corporationID !== data.corporationID || oldData.typeTarget !== data.typeTarget ||
      oldData.idTarget !== data.idTarget || oldData.fraction !== data.fraction || oldData.relation !== data.relation) {

      store.commit({
        type: 'setTargetData',
        data: data,
      });
    }

    oldData = data;
  } else {
    if (selector) {
      selector.setVisible(false);
      selectMiniMap.setVisible(false);
    }

    oldData = null;

    // todo возмодная утечка проивзодительности из за вью внутри игры
    if (openComponents && openComponents['SelectTarget'] && openComponents['SelectTarget'].open) {
      store.commit({
        type: 'setTargetData',
        data: {},
      });

      store.commit({
        type: 'toggleWindow',
        id: "SelectTarget",
        component: "SelectTarget",
        forceClose: true,
      });
    }
  }
}

function parseMsg(data) {
  return {
    unit_id: intFromBytes(data.slice(1, 5)),
    owner_id: intFromBytes(data.slice(5, 9)),
    x: intFromBytes(data.slice(9, 13)),
    y: intFromBytes(data.slice(13, 17)),
    corporationID: intFromBytes(data.slice(17, 21)),
    typeTarget: gameStore.mapBinItems[intFromBytes(data.slice(21, 22))],
    idTarget: intFromBytes(data.slice(22, 26)),
    fraction: fractionByte[intFromBytes(data.slice(26, 27))],
    relation: intFromBytes(data.slice(27, 31)),
    fear: intFromBytes(data.slice(31, 32)),
    type: fractionByte[intFromBytes(data.slice(32, 33))],
  };
}

export {TargetInfo, selector}
