import store from "../../store/store";
import {Scene} from "../create";

let glitchState = {
  time_out: 0,
  checker: null,
  current: 0,
}

function RunGlitch(power) {

  power = Math.round(power / 300)

  if (power !== glitchState.current) {
    glitchState.current = power
    store.commit({
      type: 'setGlitch',
      f: power / 3,
    });
  }

  glitchState.time_out = 1000
  if (!glitchState.checker) {
    glitchState.checker = Scene.time.addEvent({
      delay: 128,                // ms
      callback: function () {
        if (glitchState.time_out < 0) {

          glitchState.checker.remove();
          glitchState.checker = null;

          store.commit({
            type: 'setGlitch',
            f: 0,
          });
        }

        glitchState.time_out -= 128;
      },
      loop: true
    })
  }
}

export {RunGlitch}
