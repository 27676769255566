//  NODE_ENV определяется при сборке в файле package.json, раздел scripts {}
import store from "./store/store";
import router from "./router/router";
import {AxiosClient} from "./main";

let host = window.location.host;
let ws = 'ws';
let http = 'http';
let siteUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;

if (NODE_ENV !== 'production') {
  host = '192.168.88.254:8080';
} else {
  host = 'veliri-game.ru';
  ws = 'wss';
  http = 'https';
}

host = 'veliri-game.ru';
ws = 'wss';
http = 'https';

export const ItemBinTypes = {
  0: "",
  11: "weapon",
  12: "ammo",
  13: "equip",
  14: "body",
  15: "resource",
  16: "recycle",
  17: "detail",
  18: "boxes",
  19: "blueprints",
  20: "trash",
  21: "product",
  22: "fuel",
}

export const SourceItemBin = {
  1: "squadInventory",
  2: "Constructor",
  3: "empty",
  4: "box",
  5: "storage",
  6: "scanner",
  7: "aInv:resource",
  8: "scannerAInv:resource",
  9: "office_stock",
  10: "hangar",
  11: "hangar_aInv_resource",
  12: "aInv:organic",
  13: "hangar_aInv_organic",
  14: "aInv:oil",
  15: "hangar_aInv_oil",
  16: "aInv:dust",
  17: "hangar_aInv_dust",
  18: "aInv:fuel",
  19: "hangar_aInv_fuel",
  20: "scannerAInv:organic",
  21: "scannerAInv:oil",
  22: "scannerAInv:dust",
  23: "scannerAInv:fuel",
}

export const SelectEquipType = {
  0: "",
  1: "weapon",
  2: "equip",
}

export const fractionByte = {
  7: "Empty",
  1: "Replics",
  2: "Explores",
  3: "Reverses",
  4: "APD",
  5: "Fauna",
  6: "FarGod",
}

export const urls = {
  audioPrefix: siteUrl.includes('app://rse') ? 'local-audio:/' : '',
  authTokenKey: 'veliri-auth-token',
  socketURL: ws + "://" + host + "/socket/ws",
  nodeSocketURL: ws + "://" + host + "/socket/node",
  regURL: http + "://" + host + "/api/registration",
  loginURL: http + "://" + host + "/api/login",
  avatarURL: http + "://" + host + "/api/avatar",
  itemURL: http + "://" + host + "/api/get_item",
  bpURL: http + "://" + host + "/api/get_bp",
  groupAvatarURL: http + "://" + host + "/api/chat_group_avatar",
  dialogPicURL: http + "://" + host + "/api/get_picture_dialog",
  corpLogoURL: http + "://" + host + "/api/get_corporation_logo",
  corpInfoURL: http + "://" + host + "/api/get_corporation_main_info",
  userInfoURL: http + "://" + host + "/api/get_user_info",
  userBattleInfoURL: http + "://" + host + "/api/get_user_battle_info",
  userRelationsURL: http + "://" + host + "/api/get_user_relations",
  skillInfoURL: http + "://" + host + "/api/get_skill_info",
  bodySlotsInfo: http + "://" + host + "/api/get_body_slots",
  getRecycleItems: http + "://" + host + "/api/get_recycle_items",
  getResourceByRecycle: http + "://" + host + "/api/get_resource_by_recycle",
  vkOAuth: http + "://" + host + "/api/vk-oauth",
  vkOAuthUrl: http + "://" + host + "/api/vk-get-oauth-url",
  vkAppLogin: http + "://" + host + "/api/vk-app-login",
  getRepairPrice: http + "://" + host + "/api/get_repair_price",
  getItemPrice: http + "://" + host + "/api/get_item_price",
  getDynObject: http + "://" + host + "/api/get_dyn_object",
  getHistoryCredits: http + "://" + host + "/api/get_history_credits",
  getNPCGreetings: http + "://" + host + "/api/get_npc_greetings",
  npcProcessAsk: http + "://" + host + "/api/npc_process_ask",
  getMails: http + "://" + host + "/api/mail/get",
  getMailContent: http + "://" + host + "/api/mail/get_content",
  deleteMail: http + "://" + host + "/api/mail/delete",
  getMailTrash: http + "://" + host + "/api/mail/trash",
  getTokenByStore: http + "://" + host + "/api/store/get_token",
  siteUrl: siteUrl,
};

if (NODE_ENV === 'production') {
  console.log('There is Production mode');
} else {
  console.log('There is Development mode');
}

console.log('parent url: ', urls.siteUrl)
if (siteUrl.includes('app://rse')) {

  // store.dispatch("toggleFullScreen");

  store.commit({
    type: 'setSteam',
    steam: true,
    status: 0,
  });

  store.commit({
    type: 'setVisibleLoader',
    visible: true,
    text: {
      'RU': 'Пытаемся понять что происходит...',
      'EN': 'We are trying to figure out what is going on...',
    }
  });

  router.push('/login');
}

export function ElectronApi() {
  if (window.electronAPI) {
    window.electronAPI.setSteam((value) => {
      // отправляем на страницу загрузке где ждем токен
    })
    window.electronAPI.steamTicket((value) => {
      // получили токен, подсовываем его в апиху, реконектим сокет, отправляем игрока в gate
      let lang = 'EN'
      if (value.language === "russian" || value.language === "ru-RU") lang = 'RU'

      store.commit({
        type: 'setLang',
        lang: lang,
      });

      AxiosClient.post(urls.getTokenByStore, value, {
        withCredentials: true,
      }).then(function (response) {
        if (response.data.success) {
          // подсовываем его в апиху, реконектим сокет, отправляем игрока в gate
          window.localStorage.setItem(urls.authTokenKey, response.data.token)
          store.commit({
            type: 'setToken',
            data: response.data.token
          });

          setTimeout(function () {
            store.commit({
              type: 'reconnectWS',
            });

            store.commit({
              type: 'setVisibleLoader',
              visible: false,
            });

            router.push('/gate');
          }, 1000)
        }
      }).catch(function (error) {

        router.push('/login');
        store.commit({
          type: 'setSteam',
          steam: value,
          status: 1,
        });

        store.commit({
          type: 'setVisibleLoader',
          visible: false,
        });
        console.log(error);
      });
    })
  }
}

export default {urls, ItemBinTypes, SourceItemBin, SelectEquipType, fractionByte}

