import { render, staticRenderFns } from "./FractionWar.vue?vue&type=template&id=a3ac7b54&scoped=true"
import script from "./FractionWar.vue?vue&type=script&lang=js"
export * from "./FractionWar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3ac7b54",
  null
  
)

export default component.exports