<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h3 class="head">Синтеты</h3>
      <p>Синтеты - цифровой разум, запечатанный внутри беспрепятственно сменяемых синтетических тел и форм, максимально
        олицетворяющих само качество индивидуальности.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('synthet', 'png')+ ')',
              width: '400px',
              height: '131px',
              marginLeft: 'calc(50% - 200px)'
            }"/>

      <p>Их цивилизация неспособна к самовоспроизводству и в случае утери тела/полного уничтожения иной физической
        формы, синтет всего-навсего осуществляет очередное уже заурядное путешествие к "хранилищу сознаний", где и
        загружает себя в уже новое тело/форму.</p>

      <p>Им неведомы страх и сомнения. Каждый синтет, так или иначе, принадлежит к одной из множества фракций, что
        исповедуют собственные цели пребывания на планете, а в особенности - идеологическое учение самого смысла их
        существования. </p>

      <p>Всё это выливается в разрушительные конфликты, однако они – эти войны, не спонтанность, а очередная яркая черта
        их природы. Злая шутка судьбы, преобразованная в наследие предтеч, от коего им едва ли когда-то удастся
        избавиться.</p>

      <p>На этой грозной планете, куда на астролётах они добирались без меньшего десятки световых лет, фракции синтетов
        развернули базы - единственные оплоты относительной безопасности и спокойствия. Здесь всегда кипит своеобразная
        жизнь: ведётся торговля, объявляются союзы и совершаются коварные предательства.</p>

      <p>Это уникальная форма жизни, которая постоянно меняется и адаптируется к новым условиям. Они не знают
        своего происхождения и судьбы, но пытаются найти свой путь в этой игре на выживание. </p>

      <h3 class="head">Хранилище сознания</h3>
      <p>Фактически, любой синтет – это, бессмертное существо, что не обременено временем или собственной оболочкой.</p>

      <p>Благодаря стараниям предтеч, окончание физического существования любого синтета, будь оно вследствие износа
        оборудования или же имея некую насильственную природу возникновения – всего-навсего путешествия прямиком к
        фракционным хранилищам. </p>

      <p>Хранилища сознаний – они, последний из подарков предтеч, которые синтеты столь бережно хранят. Эти хранилища,
        расположенные под каждой базой любой фракции – хранят в себе оригинальные слепки сознаний всех
        когда-либо из существовавших синтетов.
      </p>

      <p>Пользуясь подобной технологией, погибший посреди пустошей планеты Veliri синтет, попросту перемещается в данное
        хранилище, будучи готовым вновь отправиться в новое путешествие. </p>
    </template>
    <template v-if="lang==='EN'">
      <h3 class="head">Synthetics</h3>
      <p>Synthetics - a digital mind, sealed inside effortlessly interchangeable synthetic bodies and forms, embodying
        the very quality of individuality to the fullest.</p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('synthet', 'png')+ ')',
        width: '400px',
        height: '131px',
        marginLeft: 'calc(50% - 200px)'
      }"/>

      <p>Their civilization is incapable of self-reproduction and in case of loss of body/complete destruction of
        another physical form, a synthetic simply undertakes another already ordinary journey to the "consciousness
        storage", where it loads itself into a new body/form.</p>

      <p>They are strangers to fear and doubt. Each synthetic, in one way or another, belongs to one of the many
        factions that profess their own goals of staying on the planet, and especially - the ideological teaching of the
        very meaning of their existence.</p>

      <p>All this results in devastating conflicts, however, these wars are not spontaneity, but another vivid feature
        of their nature. A cruel joke of fate, transformed into the legacy of the precursors, from which they will
        hardly ever be able to get rid of.</p>

      <p>On this formidable planet, where they reached by starships without less than dozens of light years, the
        synthetic factions have deployed bases - the only bastions of relative safety and tranquility. Here, a peculiar
        life always boils: trade is conducted, alliances are announced, and treacherous betrayals are committed.</p>

      <p>This is a unique form of life that is constantly changing and adapting to new conditions. They do not know
        their origin and fate, but try to find their way in this survival game.</p>

      <h3 class="head">Consciousness Storage</h3>
      <p>In fact, any synthetic is an immortal being, unburdened by time or its own shell.</p>

      <p>Thanks to the efforts of the precursors, the end of the physical existence of any synthetic, whether it is due
        to wear and tear of equipment or having some violent nature of occurrence - is just a journey straight to the
        factional storages.</p>

      <p>Consciousness storages - they are the last of the gifts of the precursors, which synthetics so carefully
        preserve. These storages, located under each base of any faction - contain the original imprints of the
        consciousnesses of all synthetics that have ever existed.</p>

      <p>Using such technology, a synthetic that perished in the wastelands of the planet Veliri simply moves to this
        storage, ready to embark on a new journey again.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Synthet",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
