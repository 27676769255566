<template>
  <div id="subMenu" ref="subMenu" v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">
    <div class="wrapper_all">

      <div class="marketDialogItemIcon">
        <app-background-item-cell v-bind:slotItem="{type: order.TypeItem, item: order.Item}"/>
      </div>

      <div class="input_number_row">
        <div class="title_row">{{ texts['text_1'][language] }}</div>

        <div style="float: right; width: 50%">
          <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(-1, 1, order.Count)">-1
          </div>
          <input type="number"
                 style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                 class="cursor_hover"
                 v-model="quantity" min="1">
          <div class="range_button cursor_hover" style="float: right; margin-right: 5px;"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeQuantity(1, 1, order.Count)">
            +1
          </div>
        </div>
      </div>

      <div style="width: calc(100% - 48px); float: left; margin-top: 7px">
        <span style="float: left; line-height: 17px;">{{ texts['text_2'][language] }}</span>
        <span class="total">{{ (order.Price / 100).toFixed(2) }} cr.</span>
      </div>

      <div style="width: 100%; float: left; margin-bottom: 3px; margin-top:5px; font-size: 1px;">
        <span style="margin-left: 14px; float: left; line-height: 20px;">{{ texts['text_3'][language] }}</span>
        <span class="total" :class="{few_credits: market.my_credits < order.Price * quantity}">{{
            ((order.Price * quantity) / 100).toFixed(2)
          }} cr.</span>
      </div>

      <input type="button" @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="back" class="lobbyButton inventoryTip cursor_hover"
             :value="texts['button_1'][language]">
      <input type="button" @mouseover="playSound('select_sound.mp3', 0.3)"
             :class="{disable: market.my_credits < order.Price * quantity}"
             @click="buy" class="lobbyButton inventoryTip cursor_hover" style="float: right; margin-right: 10px"
             :value="texts['button_2'][language]">
    </div>
  </div>
</template>

<script>
import Control from "../Window/Control"
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import Input from "../Chat/Input";

export default {
  name: "Buy",
  props: ['order', 'subMenuProps'],
  data() {
    return {
      quantity: 1
    }
  },
  mounted() {
    this.$parent.resize(null, null, $(this.$parent.$el));
    this.quantity = this.$props.order.Count
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeQuantity(change, min, max) {
      this.playSound('button_press.mp3', 0.3)

      this.quantity = Number(this.quantity)
      if (isNaN(this.quantity)) {
        this.quantity = 0
      }

      this.quantity += change

      if (this.quantity > max) {
        this.quantity = max
      }

      if (this.quantity < min) {
        this.quantity = min
      }
    },
    back() {
      this.playSound('button_press.mp3', 0.3)
      this.$parent.dialog.order = null;
    },
    buy() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: 'Buy',
          id: Number(this.$props.order.Id),
          count: Number(this.quantity)
        }
      }));

      this.back();
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Market')
    },
    market() {
      return this.$store.getters.getMarket
    },
  },
  components: {
    Input,
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
#subMenu {
  position: absolute;
  left: calc(50% - 100px);
  top: 27px;
  width: 200px;
  height: 99px;
  display: block;
  background-size: 100% 2px;
  border-radius: 5px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
  padding: 3px 3px 5px;
}

.wrapper_all {
  height: 100%;
  width: calc(100% - 2px);
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
}

.wrapper_all input[type=range] {
  float: left;
  margin-top: 10px;
  width: 70px;
  margin-bottom: 5px;
  padding: 0;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#subMenu input[type=number]::-webkit-inner-spin-button, #sellDialog input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#subMenu input[type=number], #sellDialog input[type=number] {
  -moz-appearance: textfield;
}

#subMenu input[type=number]:disabled {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  background: rgba(127, 127, 127, 0.5);
}

.title_row {
  float: left;
  font-size: 10px;
  color: bisque;
  margin-right: 5px;
  line-height: 15px;
}

.input_number_row {
  float: left;
  width: calc(100% - 48px);
  margin-top: 7px;
}

.total {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  margin: 0;
  float: right;
  text-align: right;
  background-size: 5px 2px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  line-height: 15px;
  box-shadow: inset 0 0 1px 1px rgb(118 118 118);
  border: 0;
  font-size: 11px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.few_credits {
  outline: 1px solid red;
}
</style>
