<template>
  <div id="registrationWrapper">
    <div id="mask"></div>
    <iframe name="iframe1" style="position: absolute; left: -9999px;"></iframe>
    <div id="registrationBlock" ref="registrationBlock">

      <div class="language">
        <div class="cursor_hover" :class="{disable_language: lang !=='RU'}"
             @click="changeLanguage('RU')"/>
        <div class="EN cursor_hover" :class="{disable_language: lang !=='EN'}"
             @click="changeLanguage('EN')"/>
      </div>

      <app-control v-bind:head="lang ==='RU' ? 'Регистрация' : 'Registration'"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:refWindow="'registrationBlock'"/>

      <form method="POST" id="formNewUser" autocomplete="off" onsubmit="return false;">
        <table>
          <tr>
            <td>{{ lang === 'RU' ? 'Логин' : 'Login' }}:</td>
            <td><input type="text" class="cursor_hover" autocomplete="new-password" v-model="username"
                       placeholder="Login"></td>
          </tr>
          <!--          <tr>-->
          <!--            <td> Почта:</td>-->
          <!--            <td><input type="text" autocomplete="new-password" v-model="email" placeholder="E-mail"></td>-->
          <!--          </tr>-->
          <tr>
            <td>{{ lang === 'RU' ? 'Пароль' : 'Password' }}:</td>
            <td><input type="password" class="cursor_hover" autocomplete="new-password" v-model="password"
                       placeholder="password"></td>
          </tr>
          <tr>
            <td>{{ lang === 'RU' ? 'Еще раз' : 'Again' }}:</td>
            <td><input type="password" class="cursor_hover" autocomplete="new-password" v-model="confirm"
                       placeholder="password"></td>
          </tr>
          <tr id="buttonCell">
            <td colspan="2" style="text-align: center; padding-top: 6px;">
              <input v-if="!success"
                     class="button cursor_hover"
                     id="regButton"
                     type="submit"
                     :value="lang ==='RU' ? 'Регистрация' : 'Registration'"
                     @click="registration">
              <input v-if="success"
                     class="button cursor_hover"
                     id="regButton"
                     type="submit"
                     :value="lang ==='RU' ? 'Войти' : 'Login'"
                     style="box-shadow: 0 0 10px 2px #b4ffb4, inset 0 0 10px 2px #b4ffb4"
                     @click="login">
            </td>
          </tr>
        </table>
        <div class="Failed" id="error">{{ err }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import {urls} from '../../const'
import store from "../../store/store";

export default {
  name: "Registration",
  data() {
    return {
      username: null,
      password: null,
      confirm: null,
      email: null,
      err: '',
      success: false,
    }
  },
  methods: {
    changeLanguage(language) {
      this.$store.commit({
        type: 'setLang',
        lang: language,
      });
    },
    registration() {
      let app = this;
      app.err = '';

      store.commit({
        type: 'closeWS',
      });

      window.localStorage.removeItem(urls.authTokenKey);
      app.$store.commit({
        type: 'setToken',
        data: ""
      });
      
      this.$api.post(urls.regURL, {
        username: app.username,
        email: '123',
        password: app.password,
        confirm: app.confirm,
        lang: app.lang,
      }, {
        withCredentials: true,
      }).then(function (response) {
        if (response.data.success) {

          try {
            window.localStorage.setItem(urls.authTokenKey, response.data.token)
          } catch (e) {
            console.log(e)
            console.log("in-memory token mod")
            app.$store.commit({
              type: 'setToken',
              data: response.data.token
            });
          }

          app.success = true;

        } else {
          if (response.data.error === "form is empty") {
            app.err = "Не все формы заполнены"
          }
          if (response.data.error === "login busy") {
            app.err = "Этот логин уже занят"
          }
          if (response.data.error === "small_name") {
            app.err = "У логина не может быть меньше 3х символов."
          }
          if (response.data.error === "large_name") {
            app.err = "У логина не может быть больше 16ти символов."
          }
          if (response.data.error === "email busy") {
            app.err = "Эта почта уже используется"
          }
          if (response.data.error === "password error") {
            app.err = "Пароли не совпадают"
          }
          //console.log(response)
        }
      }).catch(function (error) {
        //console.log(error)
      });
    },
    login: function () {

      let app = this;
      app.err = '';

      this.$api.post(urls.loginURL, {
        username: this.username,
        password: this.password,
      }, {
        withCredentials: true,
      }).then(function (response) {
        if (response.data.success) {
          app.load();
        } else {
          if (response.data.error === "not allow") {
            app.err = "Не верный логин либо пароль"
          }
          //console.log(response)
        }
      }).catch(function (error) {
        //console.log(error);
      });
    },
    load() {
      this.$router.push({path: '/gate'});
    }
  },
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    }
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#registrationWrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  pointer-events: auto;
}

#registrationBlock {
  position: absolute;
  left: calc(50% - 130px);
  top: 20%;
  display: block;
  border-radius: 5px;
  width: 260px;
  height: 160px;
  z-index: 11;
  padding: 20px 0 0 0;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

#formNewUser input[type="text"], #formNewUser input[type="password"] {
  text-shadow: none;
  color: black;
}

#formNewUser input[type="submit"] {
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  width: 78px;
}

#formNewUser input[type="submit"]:active {
  transform: scale(.98);
}

#formNewUser input[type="submit"]:hover {
  background: #ff8100;
}

#buttonCell {
  text-align: center
}

td {
  width: 150px;
  height: 20px;
  padding: 10px;
  text-shadow: 1px 1px 1px black;
  text-align: right;
  font-size: 12px;
  color: white;
}

th {
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

#mask {
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.language {
  position: absolute;
  left: calc(100% + 2px);
  box-shadow: 0 0 2px 0 black;
  border-radius: 0 2px 2px 0;
  top: 17px;
  border: 1px solid #009aeb7d;
  border-left: 0;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding-left: 3px;
  border-left: none;
}

.language div {
  height: 16px;
  width: 16px;
  background-size: cover;
  filter: drop-shadow(0 0 1px black);
  background-image: url('../../assets/icons/RU_language.png');
  border-radius: 3px;
  margin: 2px 2px 2px 0;
}

.language div.EN {
  background-image: url('../../assets/icons/EN_language.png');
}

.language .disable_language {
  filter: grayscale(75%);
  opacity: 0.75;
}

.language .disable_language:hover {
  filter: drop-shadow(0 0 1px white) grayscale(0%);
  opacity: 1;
}
</style>
