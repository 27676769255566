import {gameStore} from "../store";
import {Scene} from "../create";
import {GetVolume, PlayPositionSound} from "../sound/play_sound";
import {statusBarWrapper} from "../interface/status_bar";

let suicideSound = null

function RemoveSuicideSound() {
  if (suicideSound) {
    suicideSound.pause();
    suicideSound.destroy();
    suicideSound = null;
  }
}

let progressBar = {
  bar: {
    outer: null, inner: null, x: 0, y: -150, height: 20, width: 100,
  },
  outerBorderColor: 0x000000,
  outerBackColor: 0x919191,
}

function drawBar(current, deadTime) {
  if (!progressBar.bar.outer) {
    progressBar.bar.outer = statusBarWrapper.scene.add.graphics({
      x: (statusBarWrapper.scene.cameras.main.displayWidth / 2) + progressBar.bar.x - (progressBar.bar.width / 2),
      y: (statusBarWrapper.scene.cameras.main.displayHeight / 2) + progressBar.bar.y - (progressBar.bar.height / 2),
      add: true,
    });

    progressBar.bar.outer.fillStyle(progressBar.outerBorderColor);
    progressBar.bar.outer.fillRect(0, 0, progressBar.bar.width, progressBar.bar.height);
    progressBar.bar.outer.fillStyle(progressBar.outerBackColor);
    progressBar.bar.outer.fillRect(2, 2, progressBar.bar.width - 4, progressBar.bar.height - 4);

    progressBar.bar.inner = statusBarWrapper.scene.add.graphics({
      x: (statusBarWrapper.scene.cameras.main.displayWidth / 2) + progressBar.bar.x - (progressBar.bar.width / 2) + 2,
      y: (statusBarWrapper.scene.cameras.main.displayHeight / 2) + progressBar.bar.y - (progressBar.bar.height / 2) + 2,
      add: true,
    });
  }

  if (current < 0) {
    progressBar.bar.outer.setVisible(false)
    progressBar.bar.inner.setVisible(false)
    return
  }

  progressBar.bar.outer.setVisible(true)
  progressBar.bar.inner.setVisible(true)

  progressBar.bar.inner.clear();

  let percent = 100 - (100 / (deadTime / current))
  let width = (progressBar.bar.width - 4) * (percent / 100)
  let color = 0xFF8100;

  progressBar.bar.inner.fillStyle(color);
  progressBar.bar.inner.fillRect(0, 0, width, progressBar.bar.height - 4);
}

function SuicideProgress(current, deadTime) {
  drawBar(current, deadTime)
  // TODO
  // store.commit({
  //   type: 'setSuicide', current: current, deadTime: deadTime,
  // });

  if (suicideSound) {
    if (current === -1) {
      if (suicideSound.isPlaying) suicideSound.stop()
      return;
    } else {
      if (!suicideSound.isPlaying) suicideSound.play()
    }
  }

  let unit = null
  for (let i in gameStore.units) {
    if (gameStore.playerID === gameStore.units[i].owner_id) {
      unit = gameStore.units[i]
    }
  }

  if (!unit || !unit.sprite) {
    return
  }

  if (!suicideSound) {
    suicideSound = PlayPositionSound(['self_destruction_countdown'], {
      loop: true, volume: 0
    }, unit.sprite.x, unit.sprite.y);
  } else {
    let volume = GetVolume(unit.sprite.x, unit.sprite.y);
    suicideSound.setVolume(volume * 0.75);
    suicideSound.resume()
  }
}

let suicideStore = []

function Suicide(id) {
  let unit = gameStore.units[id]
  if (!unit) return;

  let lifespan = 300
  let emitter
  if (suicideStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      x: unit.sprite.x,
      y: unit.sprite.y,
      frame: "red",
      gravityY: 0,
      quantity: 3,
      speed: {min: 50, max: 75},
      scale: {min: 0.1, max: 0.2},
      angle: {min: 0, max: 360},
      alpha: {start: 0.05, end: 0, ease: 'Quad.easeIn'},
      lifespan: {min: lifespan / 2, max: lifespan},
      blendMode: 'ADD',
    });
    emitter.setDepth(400);
  } else {
    emitter = suicideStore.shift();
    emitter.start();

    emitter.ops.x.current = unit.sprite.x;
    emitter.ops.y.current = unit.sprite.y;
  }

  Scene.time.addEvent({
    delay: lifespan, callback: function () {
      emitter.stop()
      suicideStore.push(emitter);
    },
  });
}

export {SuicideProgress, Suicide, RemoveSuicideSound}
