var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Разведка")]),_vm._v(" "),_c('p',[_vm._v("Большую часть обзора в игровом мире закрывает туман войны, но через него можно смотреть с помощью радара. Радар\n      показывает метки по типу объекта, но это единственная информация которая доступна через радар. Некоторые обьекты\n      окружения и статичные структуры запоминаются и вы видите их состояние на момент когда перестали их видеть.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('scouting_1', 'png')+ ')',
          width: '450px',
          height: '325px',
          marginLeft: 'calc(50% - 225px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Вот такие метки позволяет видеть радар: ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('scouting_2', 'png')+ ')',
          width: '350px',
          height: '185px',
          marginLeft: 'calc(50% - 175px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Эти метки показывает и миникарта, желтыми кругами показаны летящие снарды. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('minimap', 'gif')+ ')',
          width: '370px',
          height: '230px',
          marginLeft: 'calc(50% - 185px)',
          backgroundPosition: 'bottom',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Reconnaissance")]),_vm._v(" "),_c('p',[_vm._v("Most of the overview in the game world is covered by the fog of war, but it can be seen through using radar.\n      The radar shows marks according to the type of object, but this is the only information available through the\n      radar. Some environmental objects and static structures are remembered, and you see their state at the moment\n      when you stopped seeing them.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('scouting_1', 'png')+ ')',
    width: '450px',
    height: '325px',
    marginLeft: 'calc(50% - 225px)',
    })}),_vm._v(" "),_c('p',[_vm._v("These are the kinds of marks the radar allows you to see: ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('scouting_2', 'png')+ ')',
    width: '350px',
    height: '185px',
    marginLeft: 'calc(50% - 175px)',
    })}),_vm._v(" "),_c('p',[_vm._v("The mini-map also shows these marks, with flying projectiles indicated by yellow circles. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('minimap', 'gif')+ ')',
    width: '370px',
    height: '230px',
    marginLeft: 'calc(50% - 185px)',
    backgroundPosition: 'bottom',
    })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }