<template>
  <div class="MSIconRoot"
       :class="{select: selectBody}"
       @mouseover="setFindBodyFilter()"
       @mouseout="removeFindFilter()">

    <template v-if="body">
      <div class="MSIcon"
           :style="{ backgroundImage: 'url(' + bodyBackground.bottom + ')', backgroundSize: backgroundSize}">
      </div>

      <div class="MSIcon"
           :style="{ backgroundImage: 'url(' + bodyBackground.body + ')', backgroundSize: backgroundSize}">

        <div class="weaponIcon"
             v-for="slot in weaponBackground"
             v-if="slot"
             :style="{
                backgroundImage: 'url(' + slot.back + ')',
                left: slot.left,
                top: slot.top,
                backgroundSize: backgroundSize,
              }"/>

        <div class="equipSprites"
             v-for="slot in equips"
             v-if="equipBackground(slot)"
             :style="{
                     backgroundImage: 'url(' + require('../../assets/' + equipBackground(slot).back).default + ')',
                     left: equipBackground(slot).left,
                     top: equipBackground(slot).top,
                     backgroundSize: backgroundSize,
             }"/>

      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ViewMS",
  props: ['body', 'backgroundSize', 'weapons', 'equips', 'hangar'],
  mounted() {
    let app = this;
    $(app.$el).droppable({
      drop: function (event, ui) {

        $('.ui-selected').removeClass('ui-selected');
        let draggable = ui.draggable;
        let slotData = draggable.data("slotData");

        if (slotData && slotData.data && slotData.data.type === "body") {
          app.$store.dispatch('setBody', {
            slot: slotData.data,
            src: slotData.parent,
          });
        }

        if (slotData && slotData.data && slotData.data.type === "weapon") {
          app.$store.dispatch('setWeapon', {
            slot: slotData.data,
            src: slotData.parent,
            equip_slot: -1,
          });
        }

        if (slotData && slotData.data && slotData.data.type === "ammo") {
          app.$store.dispatch('setAmmo', {
            slot: slotData.data,
            src: slotData.parent,
            equip_slot: -1,
          });
        }

        if (slotData && slotData.data && slotData.data.type === "fuel") {
          app.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: "FillBody",
              inventory_slot: Number(slotData.data.number),
              source: slotData.parent,
            }
          }));
        }
      }
    });
  },
  methods: {
    setFindBodyFilter() {
      this.$store.commit({
        type: 'setFindFilter',
        filters: {
          type: 'body',
        }
      });
    },
    removeFindFilter() {
      this.$store.commit({
        type: 'setFindFilter',
        filters: null
      });
    },
    equipBackground(slot) {
      if (slot && slot.equip && (slot.equip.x_attach > 0 || slot.equip.y_attach > 0)) {

        let sizeOffset = 1
        if (this.$props.backgroundSize.includes("%")) {
          sizeOffset = (Number(this.size) / 128)
        }

        return {
          back: "units/equip/" + slot.equip.name + ".png",
          top: (slot.y_attach - slot.equip.y_attach) * sizeOffset + "px",
          left: (slot.x_attach - slot.equip.x_attach) * sizeOffset + "px",
        }
      }
    },
  },
  computed: {
    bodyBackground() {

      let bottom = '';
      try {
        bottom = require("../../assets/units/body/" + this.$props.body.name + "/" + this.$props.body.name + "_bottom.png").default
      } catch (e) {
      }

      return {
        body: require("../../assets/units/body/" + this.$props.body.name + "/skins/" + this.$props.body.texture + ".png").default,
        bottom: bottom,
      }
    },
    weaponBackground() {
      let weapons = [];

      for (let i in this.$props.weapons) {
        let wSlot = this.$props.weapons[i]
        if (wSlot.weapon) {

          let sizeOffset = 1
          if (this.$props.backgroundSize.includes("%")) {
            sizeOffset = (Number(this.size) / 128)
          }

          let back = require("../../assets/units/weapon/" + wSlot.weapon.name + "/skins/" + wSlot.weapon_texture + ".png").default

          weapons.push({
            top: (wSlot.y_attach - wSlot.weapon.y_attach) * sizeOffset + "px",
            left: (wSlot.x_attach - wSlot.weapon.x_attach) * sizeOffset + "px",
            back: back
          })
        }
      }

      return weapons
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectBody() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "move_body" || this.missions.currentAction.type_func_monitor === "fill_fuel_body";
    }
  }
}
</script>

<style scoped>

.MSIconRoot {
  height: 100%;
  width: 100%;
  position: relative;
}

.equipSprites {
  height: 100%;
  width: 100%;
  background-size: 128px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

.MSIcon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(149, 149, 149, 0.25);
  background-repeat: no-repeat;
  background-size: 128px;
  background-position: center;
  position: absolute;
  box-shadow: inset 0 0 7px 2px black;
  z-index: 2;
}

.weaponIcon {
  height: 100%;
  width: 100%;
  background-size: 128px;
  position: absolute;
  background-repeat: no-repeat;
  z-index: 900;
  background-position: center;
  pointer-events: none;
}

.select {
  box-shadow: inset 0 0 4px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 10px 4px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 14px 8px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 10px 4px greenyellow;
  }
}
</style>
