<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Пропавшие предметы для заданий</h2>

      <p>Для завершения некоторых заданий требуется доставить или получить определённый предмет.</p>
      <p>Такой предмет можно потерять если ваш транспорт уничтожили, в таком случае его можно попробовать достать из
        обломков (но не факт что предмет в них будет). В противном случае можно попробовать купить нужный предмет на
        рынке
        или отменить задание и взять его заново.</p>

      <h3 class="head">Отмена задания</h3>
      <p>Что бы отменить задание надо зайти в меню личного дела во вкладку "задания", проще всего это сделать через
        кнопку
        "задания" в меню прогресса задания.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('lost_item_1', 'png')+ ')',
            width: '272px',
            height: '216px',
            marginLeft: 'calc(50% - 136px)',
            }"/>

      <p>Далее нажмите кнопку "отменить" и подтвердите действие.</p>
      <p>Сюжетные и обучающие миссии будут предложены повторно когда вы посетите <span class="importantly">новую</span>
        (или перезайдите в игру находясь на базе) базу.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('lost_item_2', 'png')+ ')',
            width: '319px',
            height: '167px',
            marginLeft: 'calc(50% - 159px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Missing Items for Tasks</h2>

      <p>To complete some tasks, you need to deliver or receive a certain item.</p>
      <p>If your transport is destroyed, you may lose such an item; in that case, you can try to retrieve it from the
        wreckage (although it's not guaranteed that the item will be there). Otherwise, you can try to buy the required
        item on the market or cancel the task and take it again.</p>

      <h3 class="head">Cancelling a Task</h3>
      <p>To cancel a task, you need to go to the personal file menu in the "tasks" tab, which is most easily done
        through the "tasks" button in the task progress menu.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('lost_item_1', 'png')+ ')',
            width: '272px',
            height: '216px',
            marginLeft: 'calc(50% - 136px)',
            }"/>

      <p>Then press the "cancel" button and confirm the action.</p>
      <p>Story and tutorial missions will be offered again when you visit a <span class="importantly">new</span> base
        (or re-enter the game while at a base).</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('lost_item_2', 'png')+ ')',
            width: '319px',
            height: '167px',
            marginLeft: 'calc(50% - 159px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "LostItem",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
