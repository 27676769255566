var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"anomalyScannerWrapper",attrs:{"id":"anomalyScannerWrapper"},on:{"mousedown":_vm.toUp}},[_c('app-control',{attrs:{"head":_vm.texts['window_name'][_vm.language],"move":true,"close":false,"no-width":true,"no-height":true,"refWindow":'anomalyScannerWrapper',"texture":'none'}}),_vm._v(" "),_c('div',{staticClass:"texture_wrapper",style:({
        backgroundImage: 'url(' + require('../../assets/interface/frame_6_2.png').default + ')',
        top: '-14px',
        left:'-24px'})}),_vm._v(" "),_c('div',{staticClass:"texture_wrapper_web",style:({
        backgroundImage: 'url(' + require('../../assets/interface/frame_6_3.png').default + ')',
        top: '-14px',
        left:'-24px'})}),_vm._v(" "),_c('div',{attrs:{"id":"anomalyScanner"}},[_c('div',{attrs:{"id":"anomalyDisplay"}},[_c('div',{ref:"anomalyBody",style:({backgroundImage: 'url(' + require('../../assets/interface/frame_6_1.png').default + ')'}),attrs:{"id":"anomalyBody"}}),_vm._v(" "),_vm._l((_vm.anomalies),function(anomaly){return _c('div',{staticClass:"anomalyPing",style:({
             left: anomaly.left,
             top: anomaly.top,
             transform: anomaly.transform,
             background: anomaly.background,
             zIndex: anomaly.priority,
             })})})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }