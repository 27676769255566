<template>
  <div class="main">

    <template v-if="selectBotID === 0">

      <input type="text" placeholder="id" v-model.number="filters.id"/>
      <input type="text" placeholder="role" v-model="filters.role"/>
      <label> In Base
        <input type="checkBox" @change="filters.inBase = !filters.inBase"/>
      </label>

      <table>

        <tr>
          <th>ID</th>
          <th>Role</th>
          <th>Fraction</th>
          <th>MapID</th>
          <th>BaseID</th>
          <th>TTL</th>
        </tr>

        <tr v-for="bot in bots" @click="selectBotID = bot.id"
            v-if="(bot.id === filters.id || filters.id === 0) && (bot.role.includes(filters.role) || filters.role === '') && (bot.in_base_id === 0 && !filters.inBase || bot.in_base_id > 0 && filters.inBase )">
          <th>{{ bot.id }}</th>
          <th>{{ bot.role }}</th>
          <th>{{ bot.fraction }}</th>
          <th>{{ bot.map_id }}</th>
          <th>{{ bot.in_base_id }}</th>
          <th>{{ bot.ttl }}</th>
        </tr>

      </table>
    </template>

    <template v-if="selectBotID !== 0">

      <h3>Bot ID: {{ bot.bot.id }}, Name: {{ bot.bot.login }}, Role: {{ bots[bot.bot.id].role }}</h3>
      <input type="button" @click="selectBotID = 0" value="Назад"/>

      <table>
        <tr>
          <th>Action</th>
        </tr>

        <tr v-for="log in bot.logs">
          <td>{{ log.action }}</td>
        </tr>
      </table>

    </template>
  </div>
</template>

<script>
import Input from "../Chat/Input";

export default {
  name: "BotMonitor",
  components: {Input},
  data() {
    return {
      updater: null,
      filters: {
        id: 0,
        role: "",
        inBase: false,
        logs: "all",
      },
      selectBotID: 0,
    }
  },
  created() {
    let app = this;

    app.updater = setInterval(function () {
      if (app.selectBotID === 0) {
        app.$store.dispatch("sendSocketData", JSON.stringify({
          service: "system",
          data: {
            event: "get_ai_all_bots",
          }
        }));
      } else {
        app.$store.dispatch("sendSocketData", JSON.stringify({
          service: "system",
          data: {
            event: "get_ai_bot",
            id: app.selectBotID
          }
        }));
      }
    }, 1000);
  },
  computed: {
    bots() {
      return this.$store.getters.getBotsState
    },
    bot() {
      return this.$store.getters.getBotState
    }
  }
}
</script>

<style scoped>
body {
  padding: 0;
  margin: 0;
}

.main {
  width: 1000px;
  margin: auto;
  margin-top: 15px;
  height: calc(100vh - 25px);
  box-shadow: 0 0 2px black;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: auto;
}
</style>
