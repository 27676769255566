var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h3',{staticClass:"head"},[_vm._v("Телепорты")]),_vm._v(" "),_c('p',[_vm._v("Телепорты - масштабные устройства мгновенного перемещения, чья технология производства уходит глубоко в историю\n      предтеч, а потому, фракции синтетов используют уцелевшие остатки сего наследия в сотни тысяч веков, не в силах\n      создать нечто подобное самостоятельно.")]),_vm._v(" "),_c('p',[_vm._v("Телепорты, это самый быстрый и эффективный способ переместиться от одного сектора в другой, таким образом\n      миновав\n      опасные участки планеты, где царят непреодолимые радиационные бури. ")]),_vm._v(" "),_c('p',[_vm._v("По внешнему виду, телепорт, это громадная платформа, куда заезжают синтеты, после чего, они подвергаются\n      процессу\n      распыления и повторного сбора молекулярной копии на следующей платформе телепорта. В случае, если все точки\n      выхода\n      из телепорта будут заняты, устройство расчистит площадку, позволяя через короткое время осуществить новую\n      попытку\n      перехода.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_1', 'png')+ ')',
      width: '480px',
      height: '238px',
      marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Выходы из телепорта выглядят вот так:")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_2', 'png')+ ')',
      width: '480px',
      height: '170px',
      marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_3', 'gif')+ ')',
      width: '340px',
      height: '230px',
      marginLeft: 'calc(50% - 170px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Стоит помнить, что телепорты, это односторонние устройства перемещения. А потому, вернуться в прошлый сектор\n      тем\n      же самым путём - тем же самым телепортом, уже никак не выйдет. Опытному синтету, прежде, всегда стоит проверять\n      свою карту, чтобы удостовериться в связях телепортов между собой. ")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h3',{staticClass:"head"},[_vm._v("Teleports")]),_vm._v(" "),_c('p',[_vm._v("Teleports are large-scale instant transportation devices, whose production technology dates back deep into the\n      history of the precursors, and therefore, the synthetic factions use the surviving remnants of this legacy for\n      hundreds of thousands of centuries, unable to create something similar on their own.")]),_vm._v(" "),_c('p',[_vm._v("Teleports are the fastest and most efficient way to move from one sector to another, thus bypassing dangerous\n      parts of the planet where insurmountable radiation storms reign.")]),_vm._v(" "),_c('p',[_vm._v("By appearance, a teleport is a huge platform where synthetics drive in, after which they undergo a process of\n      disintegration and reassembly of a molecular copy at the next teleport platform. In case all exit points from\n      the teleport are occupied, the device will clear the area, allowing a new attempt to transition after a short\n      time.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_1', 'png')+ ')',
    width: '480px',
    height: '238px',
    marginLeft: 'calc(50% - 240px)',
    })}),_vm._v(" "),_c('p',[_vm._v("The exits from the teleport look like this:")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_2', 'png')+ ')',
    width: '480px',
    height: '170px',
    marginLeft: 'calc(50% - 240px)',
    })}),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_3', 'gif')+ ')',
    width: '340px',
    height: '230px',
    marginLeft: 'calc(50% - 170px)',
    })}),_vm._v(" "),_c('p',[_vm._v("It is worth remembering that teleports are one-way transportation devices. Therefore, it is not possible to\n      return to the previous sector by the same path - by the same teleport. An experienced synthetic should always\n      check their map beforehand to ensure the connections between teleports.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }