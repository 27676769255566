import {gameStore} from "../store";
import {SelectSprite} from "../utils/select_sprite";

let initBuilderRemoveTarget = false;

function BoxTargets(data) {

  for (let i in gameStore.boxes) {
    if (!gameStore.boxes.hasOwnProperty(i)) continue;
    if (gameStore.boxes[i] && !data.hasOwnProperty(String(i))) {
      RemoveSelectSprite(gameStore.boxes[i].sprite);
    }
  }

  if (!initBuilderRemoveTarget) {
    let closeTarget = setInterval(function () {
      if (initBuilderRemoveTarget < 0) {
        clearInterval(closeTarget);
        RemoveAllSelectSprite();
        initBuilderRemoveTarget = false;
      } else {
        initBuilderRemoveTarget -= 10
      }
    }, 10)
  }

  initBuilderRemoveTarget = 100;

  for (let id in data) {
    if (!data.hasOwnProperty(id)) continue;

    let obj = gameStore.boxes[id];
    if (!obj) continue;

    if (data[id]) {

      if (obj.sprite.noPassbuildSelectSprite) {
        obj.sprite.noPassbuildSelectSprite.setVisible(false);
      }

      if (obj.sprite.passBuildSelectSprite) {
        obj.sprite.passBuildSelectSprite.setVisible(true);
        continue
      }

      obj.sprite.passBuildSelectSprite = SelectSprite(obj.sprite.x, obj.sprite.y, null, 0xffffff, 0x00ff00, obj.sprite.displayHeight + 5);
    } else {

      if (obj.sprite.passBuildSelectSprite) {
        obj.sprite.passBuildSelectSprite.setVisible(false);
      }

      if (obj.sprite.noPassbuildSelectSprite) {
        obj.sprite.noPassbuildSelectSprite.setVisible(true);
        continue
      }

      obj.sprite.noPassbuildSelectSprite = SelectSprite(obj.sprite.x, obj.sprite.y, null, 0xff0000, 0xff0000, obj.sprite.displayHeight + 5);
    }
  }
}

function RemoveAllSelectSprite() {
  for (let i in gameStore.boxes) {
    if (!gameStore.boxes.hasOwnProperty(i) || !gameStore.boxes[i]) continue;
    RemoveSelectSprite(gameStore.boxes[i].sprite);
  }
}

function RemoveSelectSprite(obj) {
  if (obj.passBuildSelectSprite) {
    obj.passBuildSelectSprite.setVisible(false);
  }

  if (obj.noPassbuildSelectSprite) {
    obj.noPassbuildSelectSprite.setVisible(false);
  }
}

export {BoxTargets}
