import {gameStore} from "../store";

function GetSpriteEquip(unitID, typeSlot, numberSlot) {
  let unit = gameStore.units[unitID];
  if (unit) {
    for (let i = 0; i < unit.equips.length; i++) {
      let slot = unit.equips[i];
      if (slot.slot.type_slot === typeSlot && slot.slot.number_slot === numberSlot) {
        return slot
      }
    }
  }
}

export {GetSpriteEquip}
