var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Reverses")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('Reverses', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('reverses_logo_min', 'png')+ ')',
            width: '150px',
            height: '150px',
            float: 'left',
            marginLeft: '50px'
          })}),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("Reverses - фракция синтетов - биокиборгов, погрязших в идее утопизма и самоизоляции от всех прочих. Члены\n      данной\n      фракции считают себя избранниками “предтеч”, чья великая цель - эволюционная революция, что засеет жизнью\n      стерильные миры всей вселенной.")]),_vm._v(" "),_c('p',[_vm._v("Право масс, индивидуальность, невыполнимое предназначение - именно с этим столкнуться те синтеты, что пожелают\n      вступить в Reverses. ")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Reverses")]),_vm._v(" "),_c('div',{staticClass:"content_section",staticStyle:{"margin-left":"calc(50% - 300px)"}},[_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('Reverses', 'png')+ ')',
      width: '150px',
      height: '150px',
      float: 'left',
    })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('reverses_logo_min', 'png')+ ')',
      width: '150px',
      height: '150px',
      float: 'left',
      marginLeft: '50px'
    })}),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_vm._v("Reverses - a faction of synthetics - biocyborgs, mired in the idea of utopianism and self-isolation from all\n      others. Members of this faction consider themselves the chosen ones of the \"precursors,\" whose great goal is an\n      evolutionary revolution that will seed life in the sterile worlds of the entire universe.")]),_vm._v(" "),_c('p',[_vm._v("The right of the masses, individuality, an unfulfillable destiny - this is what those synthetics who wish to\n      join the Reverses will face.")])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("“Через перемены, посредством истребления\n        отвратительного былого и радикального порождения, мы несём жизнь и спасение…”\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Reverses")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"bracket",staticStyle:{"float":"left","width":"200px","margin-left":"15px"}},[_vm._v("\"Through changes, by exterminating the\n        abominable past and radical genesis, we bring life and salvation...\"\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('i',{staticStyle:{"text-align":"right","width":"100%","display":"block"}},[_vm._v("Reverses")])])
}]

export { render, staticRenderFns }