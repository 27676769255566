var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Движение")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_4', 'gif')+ ')',
          width: '279px',
          height: '155px',
          marginLeft: 'calc(50% - 140px)',
          })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Особенности шасси:")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_1', 'gif')+ ')',
          width: '321px',
          height: '170px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_2', 'gif')+ ')',
          width: '321px',
          height: '240px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_3', 'gif')+ ')',
          width: '321px',
          height: '174px',
          marginLeft: 'calc(50% - 160px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Movement")]),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_4', 'gif')+ ')',
          width: '279px',
          height: '155px',
          marginLeft: 'calc(50% - 140px)',
          })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Chassis Features:")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_1', 'gif')+ ')',
          width: '321px',
          height: '170px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_2', 'gif')+ ')',
          width: '321px',
          height: '240px',
          marginLeft: 'calc(50% - 160px)',
          })}),_vm._v(" "),_vm._m(7),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('motion_3', 'gif')+ ')',
          width: '321px',
          height: '174px',
          marginLeft: 'calc(50% - 160px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Управление в Veliri стандартизированное по меркам современных игр и осуществляется посредством клавиш - "),_c('span',{staticClass:"importantly"},[_vm._v("WASD")]),_vm._v(" (движение в различные направления), "),_c('span',{staticClass:"importantly"},[_vm._v("Space")]),_vm._v("\n      (остановка/тормоз) и "),_c('span',{staticClass:"importantly"},[_vm._v("Shift")]),_vm._v(" (ускорение/форсаж). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Гусеницы")]),_vm._v(": это шасси, которое дает вам большую маневренность и устойчивость.\n      Оно\n      позволяет вам крутиться на месте\n      и легко поворачивать в любом направлении.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Антиграв")]),_vm._v(": это шасси, которое дает вам большую свободу и гибкость. Оно\n      позволяет\n      вам управлять направлением корпуса мышкой и двигаться боком и назад без поворота корпуса.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Колеса")]),_vm._v(": это шасси, которое не имеет особых особенностей. Оно является самым\n      простым и надежным типом шасси. Оно\n      подходит для тех, кто предпочитает классический стиль управления.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Control in Veliri is standardized by the standards of modern games and is carried out using the keys - "),_c('span',{staticClass:"importantly"},[_vm._v("WASD")]),_vm._v(" (movement in different directions), "),_c('span',{staticClass:"importantly"},[_vm._v("Space")]),_vm._v("\n      (stop/brake) and "),_c('span',{staticClass:"importantly"},[_vm._v("Shift")]),_vm._v(" (acceleration/afterburner).")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Tracks")]),_vm._v(": This chassis gives you greater maneuverability and stability. It\n      allows you to rotate in place and easily turn in any direction.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Antigrav")]),_vm._v(": This chassis gives you greater freedom and flexibility. It allows\n      you to control the direction of the chassis with the mouse and move sideways and backward without turning the\n      chassis.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"importantly"},[_vm._v("Wheels")]),_vm._v(": This chassis does not have any special features. It is the simplest\n      and most reliable type of chassis. It is suitable for those who prefer a classic style of control.")])
}]

export { render, staticRenderFns }