import {gameStore} from "../../../game/store";
import {OpenBox} from "../../../game/box/open";

export default function inventoryRead(msg, store) {
  if (msg.event === "UpdateSquad") {

    let activeTab = '';

    let unit = fillUnit(msg.data);

    if (msg.data.unit) {
      activeTab = 'squadInventory';
    } else {
      store.commit({
        type: 'removeWarehouse',
        warehouse: 'squadInventory',
      });
    }

    if (msg.data.in_base) {
      activeTab = 'storage';
    } else {
      store.commit({
        type: 'removeWarehouse',
        warehouse: 'storage',
      });
    }

    store.commit({
      type: 'setInventoryState',
      warehouse: activeTab, // squadInventory, storage, box:ID
      inBase: msg.data.in_base,
      unit: unit,
      squadName: msg.data.name,
      stateMS: msg.data.state_ms,
      baseSquads: msg.data.base_squads,
      effects: msg.data.effects
    });

    gameStore.init.inventory = true
  }

  if (msg.event === "UpdateThorium") {
    let thorium_slots = [];
    for (let thoriumSlot of msg.data) {
      thorium_slots.push(JSON.parse(thoriumSlot))
    }

    store.commit({
      type: 'setThoriumState',
      thorium_slots: thorium_slots,
    });
  }

  if (msg.e === "DialogPlace") {
    store.commit({
      type: 'setPlaceDialog',
      data: msg,
    });
  }

  if (msg.event === "OpenBox") {
    OpenBox(msg.data, msg.error, true)
  }

  if (msg.event === "DeleteBox") {
    store.commit({
      type: 'removeWarehouse',
      warehouse: 'box:' + msg.data,
    });
  }

  if (msg.event === "StoragesTabs") {
    for (let id of msg.data.base_ids) {
      store.commit({
        type: 'updateWarehouse',
        warehouse: 'storage:' + id,
        capacity: 9999,
        size: 0,
        title: "storage:" + id,
        remote: true,
      });
    }

    store.commit({
      type: 'setCorporationBaseNames',
      names: msg.data.corporation_base_names,
    });
  }

  if (msg.event === "RemoveStorageTab") {
    store.commit({
      type: 'removeWarehouse',
      warehouse: 'storage:' + msg.data,
    });
  }

  if (msg.event === "RemoveTab") {
    store.commit({
      type: 'removeWarehouse',
      warehouse: msg.data,
    });
  }

  if (msg.event === "GetFillUpPriceAndCount") {
    store.commit({
      type: 'setFillUpState',
      need: msg.data.need,
      price: msg.data.price,
    });
  }

  if (msg.event === "GetArmPriceAndCount") {
    store.commit({
      type: 'setArmState',
      need: msg.data.need,
      price: msg.data.price,
      step: msg.data.step,
      ammo_id: msg.data.ammo_id,
    });
  }

  if (msg.event === "GetSquadConfigs") {
    store.commit({
      type: 'setSquadConfigs',
      configs: msg.data,
    });
  }

  if (msg.event === "CheckItems") {
    store.commit({
      type: 'setCheckItems',
      items: msg.data,
    });
  }

  if (msg.event === "CloseBodyConfig") {
    store.commit({
      type: 'toggleWindow',
      id: 'BodyConfig',
      component: '',
      forceClose: true,
    });
  }

  if (msg.event === "GetSynchronization") {
    store.commit({
      type: 'setSynchronization',
      state: msg.data.state,
      bonuses: msg.data.bonuses,
    });
  }

  if (msg.event === "GetAddSlotDialogData") {
    store.commit({
      type: 'setAddSlotDialogData',
      data: msg.data,
    });
  }
}

function fillUnit(data) {
  let unit = null;

  if (data.unit) {
    unit = JSON.parse(data.unit);
    unit.body = JSON.parse(data.body);
    unit.equip_slots = [];
    unit.weapon_slots = [];
    unit.thorium_slots = [];

    for (let equipSlot of data.equips) {
      unit.equip_slots.push(JSON.parse(equipSlot))
    }

    for (let weaponSlot of data.weapons) {
      unit.weapon_slots.push(JSON.parse(weaponSlot))
    }

    for (let thoriumSlot of data.thorium) {
      unit.thorium_slots.push(JSON.parse(thoriumSlot))
    }
  }

  return unit
}
