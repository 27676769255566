<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Сектор</h2>
      <p>Сектор - изведанные, занятые синтетами или попросту представляющие интерес для будущей экспансии участки
        планеты.
        Это зоны, в которых активно бурлит жизнь, ведутся политические интриги и происходят грандиозные сражения. </p>

      <p>Рельеф планеты причудлив, а потому, за пределами секторов, расположились “красные зоны” - участки печально
        известные своими радиационными бурями, кислотными дождями и непроходимым ландшафтом. Синтеты стараются обходить
        подобные зоны стороной, из-за чего, единственными путники в тамошних местах, становятся суицидальные
        исследовательские экспедиции.</p>

      <p>В секторах синтеты передвигаются на транспортах, которые служат им для войны, добычи ресурсов и исследований.
        Транспорты бывают разных типов и классов в зависимости от их функций и характеристик.</p>

      <p>Сектора делятся на три основных типа:</p>
      <ul>
        <li><p><span class="importantly">Безопасные сектора</span> - зона торговли, сделок и отдыха. Как правило это
          наиболее защищенные районы любой
          фракции,
          где свою работу активно демонстрирует система правосудия - Страж. Неуязвимые роботы с примитивным ограниченным
          ИИ, остановят любого обнаруженного правонарушителя.</p>
        </li>
        <li><p><span class="importantly">Боевые сектора</span> - спорная территория, что зачастую удостаивается стать
          местом битвы фракций за её обладание.
          Контроль над данным сектором, несёт исключительно стратегическое значение для той или иной фракции.</p>

          <p>Для обычных же синтетов, данный сектор - хороший способ заработка, участвуя в битвах за свою фракцию как
            против ботов, так и против живых игроков в свободном ПВП. </p>

          <p>Подобные сектора не славятся наличием крупных месторождений ресурсов, в отличии от активности древних машин
            -
            АОП/APD.</p>
        </li>
        <li><p><span class="importantly">Свободная территория</span> - так именуются пустоши. Здесь царит анархия и ни
          одна фракция не в силах обеспечить
          защиту своим преданным синтетам здесь.</p>
          <p>Истинный клондайк для горнодобытчиков, ведь именно в данном типе секторов, наиболее часто обнаруживают
            крупные залежи ресурсов. Платой за подобные богатства станет прямая угроза со стороны налётчиков и древних
            машин АОП/APD, коими словно кишит вся дальняя часть пустошей, а также руины мегаструктур былой
            цивилизации. </p>
        </li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('world_1', 'png')+ ')',
        width: '480px',
        height: '514px',
        marginLeft: 'calc(50% - 240px)',
    }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Sector</h2>
      <p>A sector is an explored, occupied by synthetics, or simply an area of interest for future expansion of the
        planet. These are zones where life is actively bustling, political intrigues are conducted, and grand battles
        take place.</p>

      <p>The planet's relief is whimsical, and therefore, beyond the sectors, there are "red zones" - areas infamously
        known for their radiation storms, acid rains, and impassable terrain. Synthetics tend to avoid such zones,
        making the only travelers in those places suicidal research expeditions.</p>

      <p>In the sectors, synthetics move on transports that serve them for war, resource extraction, and research.
        Transports come in different types and classes depending on their functions and characteristics.</p>

      <p>Sectors are divided into three main types:</p>
      <ul>
        <li><p><span class="importantly">Safe sectors</span> - a zone of trade, deals, and rest. Typically, these are
          the most protected areas of any faction, where the justice system - the Guard - actively demonstrates its
          work. Invulnerable robots with primitive limited AI will stop any detected offender.</p></li>
        <li><p><span class="importantly">Combat sectors</span> - a disputed territory that often becomes the site of
          battles between factions for its possession. Control over this sector has exclusively strategic importance for
          one faction or another.</p>

          <p>For ordinary synthetics, this sector is a good way to earn money by participating in battles for their
            faction against both bots and live players in free PvP.</p>

          <p>Such sectors are not famous for the presence of large resource deposits, unlike the activity of ancient
            machines - AOP/APD.</p></li>
        <li><p><span class="importantly">Free territory</span> - these are called wastelands. Here anarchy reigns, and
          no faction is able to provide protection to its loyal synthetics here.</p>
          <p>A true gold mine for miners, as it is in this type of sectors that large deposits of resources are most
            often found. The price for such wealth will be a direct threat from raiders and ancient machines AOP/APD,
            which infest the entire far part of the wastelands, as well as the ruins of megastructures of a bygone
            civilization.</p></li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('world_1', 'png')+ ')',
        width: '480px',
        height: '514px',
        marginLeft: 'calc(50% - 240px)',
      }"/>

    </template>
  </div>
</template>

<script>
export default {
  name: "Sector",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
