<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: Установка оружия</h2>

      <p>Оружие как и снаряжение устанавливается в свой слот. Что бы оружие стреляло ему нужны еще боеприпасы они
        вставляются в небольшой слот под слотом оружия.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_weapon_1', 'gif')+ ')',
            width: '433px',
            height: '190px',
            marginLeft: 'calc(50% - 216px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Weapon Installation</h2>

      <p>Weapons, like equipment, are installed in their own slot. For the weapon to fire, it also needs ammunition
        which is inserted into a small slot below the weapon slot.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_weapon_1', 'gif')+ ')',
            width: '433px',
            height: '190px',
            marginLeft: 'calc(50% - 216px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationWeapon",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
