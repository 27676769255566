<template>
  <div class="userLineWrapper">
    <app-user-sub-menu v-if="userSubMenuProps.user" v-bind:userSubMenuProps="userSubMenuProps"/>

    <div class="chatUserLine cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @click="openSubMenu($event, user)"
         v-bind:class="{noActive: noActive}">

      <div class="dialogExitShow" v-if="dialogExitShow" @click.stop="">
        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="closeDialog">
          <img :src="require('../../assets/icons/dont.png').default"/>
        </div>
        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="exitFunction">
          <img :src="require('../../assets/icons/ready.png').default"/>
        </div>
      </div>

      <div class="dialogExitShow" v-if="userSubMenuProps.dialogChangeRole" @click.stop="">

        <select class="roleSelect" v-model="role">
          <option value="none">{{ texts['text_5'][language] }}</option>
          <option v-for="(role, key) in corporation.roles" :value="key"
                  v-if="role.name !=='Leader'">{{ role.name }}
          </option>
        </select>

        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="closeDialog">
          <img :src="require('../../assets/icons/dont.png').default"/>
        </div>
        <div @mouseover="playSound('select_sound.mp3', 0.3)" @click.stop="changeCorporationRole">
          <img :src="require('../../assets/icons/ready.png').default"/>
        </div>
      </div>

      <div class="chatUserIcon"
           v-bind:style="{backgroundImage: avatars[user.id] ? avatars[user.id].avatar : undefined}">
        {{ getAvatar(user.id) }}
      </div>

      <div class="crown" v-if="crown"></div>

      <div class="chatUserName">
        {{ hideName ? '---' : user.login }}
      </div>

      <div class="userTitle" v-if="title && !changeRole">
        {{ title }}
      </div>

      <div class="userOnline" v-if="online === true || online === false">
        <div v-bind:style="{backgroundColor: online ? '#00ff00':'#ff0000'}"></div>
      </div>

      <div class="ready" v-if="ready === true || ready === false">
        <img :src="require('../../assets/icons/ready.png').default"/>
        <img v-if="ready === false" src="https://img.icons8.com/office/16/000000/hourglass.png"/>
      </div>

      <div class="exitChatButton" v-if="buttonExit && !dialogExitShow" @click.stop="dialogExit">
        <img :src="require('../../assets/icons/group_exit.png').default"/>
      </div>

      <div v-if="additionalInfo" class="additionalInfo">
        <table>
          <tr>
            <td>{{ texts['text_6'][language] }}</td>
            <td v-if="additionalInfo.bn !== ''">
              <span class="importantly">{{ additionalInfo.bn }}</span> ({{ additionalInfo.mn }})
            </td>
            <td v-if="additionalInfo.bn === ''">
              <span class="importantly">{{ additionalInfo.mn }}</span>
            </td>
          </tr>

          <tr v-if="additionalInfo.mhp > 0">
            <td>HP:</td>
            <td>
              <div class="bar_wrapper">
                <div id="hp_bar"
                     :style="{width: (100 / (additionalInfo.mhp / additionalInfo.hp)) + '%', backgroundColor: colorDamage((100 / (additionalInfo.mhp / additionalInfo.hp)))}"></div>
              </div>
            </td>
          </tr>

          <tr v-if="additionalInfo.mpw > 0">
            <td>Power:</td>
            <div class="bar_wrapper">
              <div id="power_bar"
                   :style="{width: (100 / (additionalInfo.mpw / additionalInfo.pw)) + '%'}"></div>
            </div>
          </tr>

          <tr v-if="additionalInfo.inm > 0">
            <td>Трюм:</td>
            <td>
              <app-size v-bind:capacity="additionalInfo.inm" v-bind:size="additionalInfo.in"/>
            </td>
          </tr>

        </table>
      </div>

      <div class="userTitleChange" v-if="title && changeRole">
        <select class="userTitleChangeSelect"
                :class="{disable: changeRoleDisable}"
                :data-player-id="user.id"
                :data-disable="changeRoleDisable"
        >

          <option v-for="(role, key) in corporation.roles"
                  :disabled="role.name === 'Leader'"
                  :value="key"
                  :selected="role.id === currentRole">
            {{ role.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import UserSubMenu from './UserSubMenu';
import Size from '../Inventory/Size'

export default {
  name: "UserLine",
  props: ['user', 'buttonExit', 'exitFunc', 'parent', 'meta', 'noActive', 'crown', 'additionalInfo', 'additionalTabs',
    'title', 'online', 'parentEl', 'noMenu', 'ready', 'changeRole', 'currentRole', 'changeRoleDisable', 'hideName'],
  data() {
    return {
      dialogExitShow: false,
      role: 'none',
      userSubMenuProps: {
        user: null,
        x: 0,
        y: 0,
        avatar: null,
        additionalTabs: null,
        parent: null,
        dialogChangeRole: null,
      }
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    colorDamage(percent) {
      return this.$store.getters.GetColorDamage(percent)
    },
    getAvatar(userID) {
      this.$store.dispatch('getAvatar', {
        userID: userID,
        vm: this
      });
    },
    openSubMenu(event, user) {
      this.playSound('button_press.mp3', 0.3)
      if (this.$props.noMenu) {
        return
      }

      let parent = this.$parent.$el;
      if (this.$props.parentEl) {
        parent = this.$props.parentEl
      }

      this.userSubMenuProps.x = ((event.pageX - $(parent).position().left) / this.interfaceZoom);
      this.userSubMenuProps.y = ((event.pageY - $(parent).position().top) / this.interfaceZoom);
      this.userSubMenuProps.user = user;
      this.userSubMenuProps.parent = this.$props.parent;
      this.userSubMenuProps.additionalTabs = this.$props.additionalTabs;
      this.userSubMenuProps.avatar = this.avatars[user.id].avatar;
    },
    closeDialog() {
      this.playSound('button_press.mp3', 0.3)
      this.dialogExitShow = false;
      this.userSubMenuProps.dialogChangeRole = false;
    },
    dialogExit() {
      this.playSound('button_press.mp3', 0.3)
      this.userSubMenuProps.user = null;
      this.dialogExitShow = true;
    },
    exitFunction() {
      this.playSound('button_press.mp3', 0.3)
      this.closeDialog();

      if (this.$props.parent === 'chat') {
        this.$props.exitFunc(this.$props.meta.currentChatID);
      }
      if (this.$props.parent === 'friendList') {
        this.$props.exitFunc(this.$props.meta.id);
      }
      if (this.$props.parent === 'GroupMenu') {
        this.$props.exitFunc(this.$props.meta.id);
      }
      if (this.$props.parent === 'SendCredits') {
        this.$props.exitFunc();
      }
      if (this.$props.parent === 'Mail') {
        this.$props.exitFunc();
      }
    },
    changeCorporationRole() {
      this.playSound('button_press.mp3', 0.3)
      this.closeDialog();
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "changeRoleUserInCorporation",
          player_id: Number(this.$props.user.user_id),
          id: Number(this.role),
        }
      }));
    }
  },
  computed: {
    avatars() {
      return this.$store.getters.getChatState.avatars;
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    }
  },
  components: {
    AppUserSubMenu: UserSubMenu,
    AppSize: Size,
  }
}
</script>

<style scoped>
.chatUserLine {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  min-height: 25px;
  color: #e6e1d8;
  font-size: 9pt;
  transition: 100ms;
  text-shadow: 1px 1px 1px black;
}

.chatUserLine:hover {
  background: rgba(0, 0, 0, 0.2);
}

.chatUserLine:active {
  background: rgba(214, 214, 214, 0.2);
}

.chatUserLine div {
  display: inline-block;
}

.chatUserIcon, .crown {
  height: 25px;
  width: 25px;
  background: rgba(0, 0, 0, 0.3);
  float: left;
  box-shadow: inset 0 0 5px black;
  background-size: cover;
  margin-right: 2px;
  border-radius: 3px;
  pointer-events: none;
}

.crown {
  background: rgba(0, 0, 0, 0.1);
  height: 21px;
  width: 21px;
  margin: 2px;
  box-shadow: none;
  background-image: url("../../assets/icons/group_leader.png");
  background-size: contain;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
}

.chatUserName {
  line-height: 35px;
  height: 25px;
  margin-left: 2px;
  pointer-events: none;
}

.noActive {
  opacity: 0.25;
}

.noActive:hover {
  opacity: 0.75;
}

.dialogExitShow {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  width: 100%;
  z-index: 10;
}

.exitChatButton {
  position: relative;
}

.dialogExitShow div, .exitChatButton, .ready {
  height: 20px;
  width: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  float: right;
  margin: 2px;
  opacity: 0.4;
  transition: 200ms;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.dialogExitShow div {
  position: static;
}

.ready {
  right: 22px;
  opacity: 1;
}

.dialogExitShow div img, .exitChatButton img, .ready img {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.dialogExitShow div:hover, .exitChatButton:hover {
  border: 1px solid rgba(255, 242, 15, 0.7);
  background: rgba(255, 232, 0, 0.6);
  opacity: 0.8;
}

.dialogExitShow div:active {
  transform: scale(0.97);
}

.chatUserLine .additionalInfo {
  display: block;
  min-height: 25px;
  width: 100%;
  background: rgba(0, 255, 251, 0.2);
}

.additionalInfo table {
  font-size: 11px;
  color: #c1c1c1;
  width: calc(100% - 8px);
  margin: 2px auto;
}

.additionalInfo table tr td:first-child {
  text-align: right;
  width: 30px;
}

.bar_wrapper {
  width: 100%;
  height: 5px;
  border: 1px solid #4c4c4c;
  text-align: left;
  display: block;
  box-shadow: 0 0 2px black;
  border-radius: 10px;
  background-size: 12%;
  overflow: hidden;
  background-color: #959595;
  margin: 0 auto 0;
  position: relative;
}

#hp_bar, #power_bar {
  overflow: visible;
  background: rgba(72, 255, 40, 0.7);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: inset 0 0 2px black;
}

#power_bar {
  background: rgba(3, 245, 255, 0.7);
}

.userTitle, .userTitleChange {
  color: #ffef0f;
  margin-left: 5px;
}

.userTitleChange {
  float: right;
  margin: 4px 3px 0 3px;
}

.userTitleChange select {
  outline: none;
  border-radius: 5px;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 17px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
  user-select: none;
}

.userOnline {
  float: right;
  position: relative;
  margin-top: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  opacity: 0.7;
}

.userOnline div {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 50%;
  box-shadow: inset 0 0 2px #737373;
  border: 1px solid #737373;
}

.roleSelect {
  box-shadow: inset 0 0 1px 1px rgb(118, 118, 118);
  outline: none;
  width: 190px;
  border-radius: 5px;
  border: 0;
  background: #79a0b4;
  height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  margin: 3px;
  font-size: 11px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

select * {
  color: black;
  font-weight: bold;
}
</style>
<style>
.chatUserLine .additionalInfo .sizeInventoryInfo {
  display: block;
  width: 100%;
  box-shadow: 0 0 2px black;
  height: 5px;
}

.chatUserLine .additionalInfo .sizeInventoryInfo span {
  display: none;
}
</style>
