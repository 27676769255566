<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Типы урона и защита</h2>
        <p>В игре есть три типа урона: “кинетический”, “взрывной” и “термический”. Для каждого типа урона у транспорта
          есть своя защита.</p>
        <p>У каждого снаряда может быть разное процентное соотношение типов урона. Например, снаряд может иметь 50%
          кинетического урона, 30% взрывного урона и 20% термического урона.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('damage_1', 'png')+ ')',
            width: '305px',
            height: '191px',
            marginLeft: 'calc(50% - 152px)',
            }"/>

        <p>Например: урон 100, по 33% на каждый тип. Броня корпуса допустим 50% на кинетику и по 90% на все
          остальное</p>
        <ul>
          <li>урон кинетикой 33 * 50% = 16</li>
          <li>урон термосом 33 * 90% = 3</li>
          <li>урон взрывом 33 * 90% = 3</li>
        </ul>
      </div>
      <div class="content_section">
        <h2 class="head">Толщина брони</h2>
        <p>У каждого корпуса есть уязвимые и бронированные места. Урон, нанесённый по уязвимому месту, умножается на
          дополнительный коэффициент. Это заставит игроков быть более внимательными и выбирать оптимальную тактику боя,
          пряча уязвимые места и танковать броней.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('damage_2', 'png')+ ')',
            width: '311px',
            height: '291px',
            marginLeft: 'calc(50% - 155px)',
            }"/>
        <p>Карта брони корпуса.</p>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('damage_3', 'gif')+ ')',
            width: '368px',
            height: '292px',
            marginLeft: 'calc(50% - 184px)',
            }"/>
        <p>Красная иконка говорит об "критическом уроне" а щит о "блоке"</p>
      </div>

      <div class="content_section">
        <h2 class="head">Щит</h2>
        <p>Почти у каждого транспорта есть персональный щит, он берет на себя первый удар и позволяет синтету успет
          среагировать на ситуацию, однако он довольно слабый и рассчитывать только на него не стоит.</p>
        <ul>
          <li>У щита очень мало ХП, но урон не будет перетекать в транспорт, пока есть щит (условно, щит 50 ХП, урон на
            300.
            50 ХП щита заберут все 300 урона, и щит сломается).
          </li>
          <li>У щита высокая резистентность, обратная дефолтным резистам корпуса.</li>
          <li>Щит восстанавливается через 5 секунд после урона по корпусу или щиту (то есть в активном бою он не будет
            восстанавливаться вообще).
          </li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('damage_4', 'gif')+ ')',
            width: '400px',
            height: '285px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Damage Types and Protection</h2>
        <p>There are three types of damage in the game: "kinetic", "explosive", and "thermal". Each type of damage has
          its own protection for the vehicle.</p>
        <p>Each projectile can have a different percentage of damage types. For example, a projectile can have 50%
          kinetic damage, 30% explosive damage, and 20% thermal damage.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('damage_1', 'png')+ ')',
            width: '305px',
            height: '191px',
            marginLeft: 'calc(50% - 152px)',
            }"/>

        <p>For example: damage is 100, with 33% for each type. The body armor is, say, 50% for kinetic and 90% for
          everything else.</p>
        <ul>
          <li>kinetic damage: 33 * 50% = 16</li>
          <li>thermal damage: 33 * 90% = 3</li>
          <li>explosive damage: 33 * 90% = 3</li>
        </ul>
      </div>
      <div class="content_section">
        <h2 class="head">Armor Thickness</h2>
        <p>Each body has vulnerable and armored spots. Damage dealt to a vulnerable spot is multiplied by an additional
          coefficient. This forces players to be more attentive and choose the optimal battle tactics, hiding vulnerable
          spots and tanking with armor.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('damage_2', 'png')+ ')',
          width: '311px',
          height: '291px',
          marginLeft: 'calc(50% - 155px)',
          }"/>
        <p>Body armor map.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('damage_3', 'gif')+ ')',
          width: '368px',
          height: '292px',
          marginLeft: 'calc(50% - 184px)',
          }"/>
        <p>The red icon indicates "critical damage" and the shield indicates "block".</p>
      </div>

      <div class="content_section">
        <h2 class="head">Shield</h2>
        <p>Almost every vehicle has a personal shield that takes the first hit and allows the synthetic to react to the
          situation. However, it is quite weak and should not be relied upon solely.</p>
        <ul>
          <li>The shield has very little HP, but damage will not transfer to the vehicle as long as the shield is active
            (for example, if the shield has 50 HP and the damage is 300, the 50 HP shield will absorb all 300 damage and
            break).
          </li>
          <li>The shield has high resistance, opposite to the default resistances of the body.</li>
          <li>The shield regenerates 5 seconds after taking damage to the body or shield (meaning it will not regenerate
            at all during active combat).
          </li>
        </ul>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('damage_4', 'gif')+ ')',
          width: '400px',
          height: '285px',
          marginLeft: 'calc(50% - 200px)',
          }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Damage",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
