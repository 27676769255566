import {gameStore} from "../store";
import {Scene} from "../create";

function FocusUnit(id, force, noAnimate) {

  if (gameStore.gameSettings.follow_camera && !force) return;

  let unit = gameStore.units[id];
  if (unit && unit.sprite) {

    if (!Scene.cameras.main._follow) {
      gameStore.focusUnitID = 0;
    }

    if (gameStore.focusUnitID === id) {
      return;
    }

    if (noAnimate) {
      Scene.cameras.main.startFollow(unit.sprite);
      return;
    }

    Scene.cameras.main.pan(unit.sprite.x, unit.sprite.y, 256, Phaser.Math.Easing.Linear, true, function (camera, progress, x, y) {
      if (progress === 1) Scene.cameras.main.startFollow(unit.sprite);
    })

    gameStore.focusUnitID = id
    return true
  }

  return false
}

function FocusMS(noAnimate) {
  if (!gameStore.gameReady) return;

  for (let i in gameStore.units) {
    if (gameStore.playerID === gameStore.units[i].owner_id) {
      return FocusUnit(gameStore.units[i].id, true, noAnimate)
    }
  }

  return false
}

export {FocusUnit, FocusMS}
