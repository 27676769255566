<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Автопилот</h2>
      <div class="content_section">
        <p>Автопилот - функция, позволяющая вам перемещаться по поверхности планеты без собственных усилий. Игроку
          достаточно выбрать пункт достижения, включить сам автопилот, затем, наслаждаться поездкой.</p>

        <p>Будь-то база, иной сектор или просто точка посреди пустошей, автопилот вас туда доставит. Однако стоит
          помнить,
          что при работе автопилота, вы теряете возможность и для самообороны. Становитесь уязвимы для атак и прочих
          опасностей.</p>

        <p>Благо выключение автопилота, происходит так же легко, как и его активация - нажатием соответствующей иконки
          на
          нижней панели.</p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot', 'png')+ ')',
        width: '66px',
        height: '75px',
        marginLeft: 'calc(50% - 33px)',
        }"/>

        <p>Интерфейс специально продемонстрирует игроку, если автопилот активирован - высвечивая соответствующую иконку
          зелёным светом и выводя на экран надпись - “Autopilot”. В случае же отключения автопилота, надпись пропадает,
          а
          сама иконка тускнеет. </p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot_2', 'png')+ ')',
        width: '480px',
        height: '140px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

        <h3 class="head">Принцип работы автопилота</h3>

        <p>Игрок открывает карту мира, выбирая необходимую точку достижения (сектор, база и т.д.), после чего, интерфейс
          строит маршрут движения. </p>

        <p>Подтвердив свой выбор, система автопилота автоматически включается и корпус игроку начинает своё движение по
          построенному маршруту. Вы всегда можете прекратить движения корпуса посредством автопилота - отключив его.
          Аналогичным же образом, работает и изменение маршрута для автопилота - попросту выбрав иную цель движения.</p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot_3', 'png')+ ')',
        width: '480px',
        height: '446px',
        marginLeft: 'calc(50% - 240px)',
        }"/>
      </div>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Autopilot</h2>
      <div class="content_section">
        <p>Autopilot is a feature that allows you to move across the planet's surface without any effort. The player
          just needs to select a destination point, activate the autopilot, and then enjoy the ride.</p>

        <p>Whether it's a base, another sector, or just a point in the middle of the wastelands, the autopilot will get
          you there. However, it is worth remembering
          that when the autopilot is operating, you lose the ability to defend yourself. You become vulnerable to
          attacks and other
          dangers.</p>

        <p>Fortunately, turning off the autopilot is just as easy as activating it - by pressing the corresponding icon
          on
          the bottom panel.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot', 'png')+ ')',
          width: '66px',
          height: '75px',
          marginLeft: 'calc(50% - 33px)',
        }"></div>

        <p>The interface will specifically show the player if the autopilot is activated - by lighting up the
          corresponding icon
          with green light and displaying the inscription - “Autopilot”. In the case of deactivating the autopilot, the
          inscription disappears,
          and
          the icon dims.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot_2', 'png')+ ')',
          width: '480px',
          height: '140px',
          marginLeft: 'calc(50% - 240px)',
        }"></div>

        <h3 class="head">How Autopilot Works</h3>

        <p>The player opens the world map, selecting the necessary destination point (sector, base, etc.), after which,
          the interface
          constructs a route. </p>

        <p>After confirming their choice, the autopilot system automatically activates and the player's vehicle begins
          its movement along
          the constructed route. You can always stop the vehicle's movement via autopilot - by deactivating it.
          Similarly, changing the route for the autopilot works by simply selecting a different travel target.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('auto_pilot_3', 'png')+ ')',
          width: '480px',
          height: '446px',
          marginLeft: 'calc(50% - 240px)',
        }"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AutoPilot",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
