import { render, staticRenderFns } from "./ProductionTab.vue?vue&type=template&id=dc9f1164&scoped=true"
import script from "./ProductionTab.vue?vue&type=script&lang=js"
export * from "./ProductionTab.vue?vue&type=script&lang=js"
import style0 from "./ProductionTab.vue?vue&type=style&index=0&id=dc9f1164&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9f1164",
  null
  
)

export default component.exports