<template>
  <div>

    <template v-if="type === 'fuel'">
      <div class="bonus open"
           v-for="bonus in bonuses"
           v-if="bonus.n !== ''"
           v-html="getBonusDescriptionByName(bonus.n)">
      </div>
    </template>

    <template v-if="profileFeatures.length > 0">
      <h3 class="head">{{ texts['text_25'][language] }}</h3>
      <div class="bonus" v-if="getBonusDescription(bonus) !== ''" v-for="bonus in profileFeatures"
           v-bind:class="{open: checkBonus(bonus)}">
        <div class="bonusHead" v-html="getBonusDescription(bonus)"/>

        <div class="needSkills">
          <div class="skillRow" v-for="(lvl, requirement) in bonus.requirements">
            <span>{{ handbook["skill"][language][getSkillByID(requirement).name].name }}</span>

            <div :class="'imgPassRequirement' + String(getSkillByID(requirement).level >= lvl)"/>

            <span class="level">{{ lvl }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-if="syncBonuses.length > 0">
      <h3 class="head" style="margin-top: 3px" v-html="texts['text_26'][language]"/>
      <div class="bonus sync" v-for="bonus in syncBonuses"
           v-if="getBonusDescription(bonus) !== ''">
        <div class="bonusHead" v-html="' - ' + getBonusDescription(bonus)"/>
      </div>

      <div class="button cursor_hover"
           @click="openService('userStat' + currentPlayer.id, {id: currentPlayer.id, tab: 'skill'}, 'userStat', false)">
        {{ texts['button_1'][language] }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "BonusTab",
  props: ['type', 'item', 'bonuses'],
  created() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetMySkills",
      }
    }));
  },
  methods: {
    getBonusDescription(b) {
      if (b.od !== "") {
        return this.handbook.effects[this.language][b.od].description
      }
      return this.handbook.effects[this.language][b.bonus.n].description
    },
    getBonusDescriptionByName(n) {
      return this.handbook.effects[this.language][n].description
    },
    checkBonus(bonus) {
      for (let requirement in bonus.requirements) {
        if (this.getSkillByID(requirement) && this.getSkillByID(requirement).level < bonus.requirements[requirement]) {
          return false
        }
      }

      return true
    },
    getSkillByID(id) {
      for (let s in this.skillStore.skills) {
        if (this.skillStore.skills[s] && Number(this.skillStore.skills[s].type_id) === Number(id)) {
          return this.skillStore.skills[s];
        }
      }

      return {level: -1}
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('DetailItem')
    },
    skillStore() {
      return this.$store.getters.getSkills
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    profileFeatures() {
      let bonuses = []

      for (let i in this.$props.bonuses) {
        if (this.$props.bonuses[i].sync_lvl === 0) {
          bonuses.push(this.$props.bonuses[i])
        }
      }

      return bonuses
    },
    syncBonuses() {
      let bonuses = []

      for (let i in this.$props.bonuses) {
        if (this.$props.bonuses[i].sync_lvl === 25) {
          bonuses.push(this.$props.bonuses[i])
        }
      }

      return bonuses
    }
  },
}
</script>

<style scoped>
.bonus {
  width: calc(100% - 13px);
  float: left;
  font-size: 11px;
  padding: 5px;
  clear: both;
  border: 1px solid transparent;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 3px;
  margin: 3px 1px;
  color: white;
  box-shadow: 0 0 2px 0 black;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  backdrop-filter: blur(4px);
}

.bonus.open {
  background: rgba(0, 255, 0, 0.1);
}

.bonus.sync {
  background: rgba(0, 0, 0, 0.2);
}

.bonus:first-letter {
  text-transform: capitalize;
}

.bonusHead {
  margin-bottom: 2px;
}

.needSkills {
  width: calc(100% - 10px);
  float: left;
  padding: 5px;
  box-shadow: inset 0 0 2px black;
  background: rgba(255, 255, 255, 0.2);
}

.skillRow {
  position: relative;
  float: left;
  width: calc(100% - 10px);
  height: 17px;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px 5px;
  margin-bottom: 6px;
  font-size: 11px;
  line-height: 20px;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.skillRow:last-child {
  margin-bottom: 0;
}

.level {
  float: right;
  color: yellow;
}

.imgPassRequirementtrue, .imgPassRequirementfalse {
  height: 16px;
  width: 16px;
  float: right;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url('../../assets/icons/ready.png');
  background-size: contain;
  filter: drop-shadow(1px 1px 0px black);
  margin-left: 10px;
}

.imgPassRequirementfalse {
  background-image: url('../../assets/icons/dont.png');
}

.button {
  text-align: center;
  display: block;
  width: 79px;
  margin: 6px 0 0 6px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  float: right;
  font-size: 11px;
  pointer-events: auto;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.head {
  margin: 0 0 0 0;
  background: #3486dd;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  height: 13px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  text-indent: 10px;
  box-shadow: 1px 1px 1px 0 black;
  float: left;
}
</style>
