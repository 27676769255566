var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Инвентарь и специальные ячейки строений")]),_vm._v(" "),_c('p',[_vm._v("Некоторые структуры имеют внутри себя инвентарь и специальные ячейки для топлива и\n    заданий.")]),_vm._v(" "),_c('p',[_vm._v("Некоторые структуры не могут работать без топлива поэтому им необходимо после установки устанавливать в\n    ячейку топливо или что бы был рядом генератор с топливом или союзный транспорт с дистанционной передачей\n    энергии.")]),_vm._v(" "),_c('p',[_vm._v("Для взаимодействия со структурой нужно быть ее владельцем и находясь рядом нажать на нее манипулятором.")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('open_object_1', 'gif')+ ')',
            width: '286px',
            height: '200px',
            marginLeft: 'calc(50% - 143px)',
          })}),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('open_object_2', 'gif')+ ')',
            width: '286px',
            height: '200px',
            marginLeft: 'calc(50% - 143px)',
            marginTop: '10px',
          })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }