var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Фракционный магазин")]),_vm._v(" "),_c('p',[_vm._v(" Фракционный магазин - это специальный магазин, который находится на особой базе, принадлежащей фракции игрока.\n      (Генератор “Гифебрации”/Орудие “Гончкинс”/Станция отслеживания “Велири”), В фракционном магазине вы можете\n      обменять очки фракции, которые вы заработали в\n      "),_c('router-link',{attrs:{"to":"fraction_war"}},[_vm._v("фракционной войне")]),_vm._v("\n      , на разные товары, которые могут быть полезны для\n      вашего транспорта или вашей фракции. Вы можете купить чертежи, оружие, патроны и другие предметы, которые\n      доступны\n      только в фракционном магазине.\n    ")],1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('fraction_market', 'png')+ ')',
          width: '353px',
          height: '203px',
          marginLeft: 'calc(50% - 171px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Faction Store")]),_vm._v(" "),_c('p',[_vm._v(" The faction store is a special shop located on a special base belonging to the player's faction.\n      (Generator \"Gifebration\"/Weapon \"Gonchkins\"/Tracking Station \"Veliri\"), In the faction store, you can\n      exchange faction points, which you earned in\n      "),_c('router-link',{attrs:{"to":"fraction_war"}},[_vm._v("faction war")]),_vm._v("\n      ,\n      for various goods that can be useful for\n      your transport or your faction. You can buy blueprints, weapons, ammunition, and other items that are\n      available\n      only in the faction store.\n    ")],1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('fraction_market', 'png')+ ')',
          width: '353px',
          height: '203px',
          marginLeft: 'calc(50% - 171px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }