<template>
  <div class="transfer" id="transfer" ref="transfer">
  </div>
</template>

<script>
export default {
  name: "Transfer",
  mounted() {
    this.$store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Перемещаемся...',
        'EN': 'We are moving around...',
      }
    });

    setTimeout(function () {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "CheckInBase",
        }
      }));
    }.bind(this), 1000)
  },
}
</script>

<style scoped>
.transfer {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
</style>
