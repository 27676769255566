var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Добыча руды")]),_vm._v(" "),_c('p',[_vm._v("В мире игры, руда это один из видов ценных источников ресурсов, использующихся впоследствии для создания\n      различных предметов.")]),_vm._v(" "),_c('p',[_vm._v("Сама руда представляет собой минералы, которые игрок находит в земле и в дальнейшем подвергает разработке. ")]),_vm._v(" "),_c('p',[_vm._v("Всего в игре насчитывается пять видов минеральных руд и соответствующих сырьевых компонентов, которые из них\n      можно получить:")]),_vm._v(" "),_c('p',[_vm._v("Стандартный вид руд (можно обнаружить во всех секторах):")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Экзотический вид руд (можно обнаружить только в боевом или свободном типе секторов): ")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_1', 'png')+ ')',
          width: '400px',
          height: '140px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Сами залежи руд образуются в игровом мире случайным образом, из-за чего возможность их отыскать и разработать,\n      доступна любому игроку у кого есть необходимое снаряжение.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_2', 'png')+ ')',
          width: '400px',
          height: '167px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Принцип разработки руды")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_3', 'gif')+ ')',
          width: '300px',
          height: '185px',
          marginLeft: 'calc(50% - 150px)',
          })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Разрушение руды")]),_vm._v(" "),_c('p',[_vm._v("Если стрелять в руду на карте, то она будет разрушатся и разбрасывать вокруг себя руду, но такой вид копания не\n      эффективен и в итоге большая часть руды будет уничтожена.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_4', 'gif')+ ')',
          width: '400px',
          height: '330px',
          marginLeft: 'calc(50% - 200px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Ore Mining")]),_vm._v(" "),_c('p',[_vm._v("In the game world, ore is one of the types of valuable resource sources, subsequently used to create various\n      items.")]),_vm._v(" "),_c('p',[_vm._v("The ore itself consists of minerals that the player finds in the ground and later develops.")]),_vm._v(" "),_c('p',[_vm._v("There are a total of five types of mineral ores and the corresponding raw components that can be obtained from\n      them:")]),_vm._v(" "),_c('p',[_vm._v("Standard type of ores (can be found in all sectors):")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('p',[_vm._v("Exotic type of ores (can be found only in combat or free type sectors): ")]),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_1', 'png')+ ')',
      width: '400px',
      height: '140px',
      marginLeft: 'calc(50% - 200px)',
    })}),_vm._v(" "),_c('p',[_vm._v("Ore deposits themselves are formed randomly in the game world, which is why the opportunity to find and develop\n      them is available to any player who has the necessary equipment.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_2', 'png')+ ')',
      width: '400px',
      height: '167px',
      marginLeft: 'calc(50% - 200px)',
    })}),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("The Principle of Ore Development")]),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_3', 'gif')+ ')',
      width: '300px',
      height: '185px',
      marginLeft: 'calc(50% - 150px)',
    })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Ore destruction")]),_vm._v(" "),_c('p',[_vm._v("If you shoot at the ore on the map, it will be destroyed and scatter ore around itself, but this type of\n      digging is not effective and as a result most of the ore will be destroyed.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('mining_4', 'gif')+ ')',
          width: '400px',
          height: '330px',
          marginLeft: 'calc(50% - 200px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Медная руда")]),_vm._v(" "),_c('li',[_vm._v("Железная руда")]),_vm._v(" "),_c('li',[_vm._v("Ториевая руда")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Титановая руда")]),_vm._v(" "),_c('li',[_vm._v("Кремниевая руда")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Отыскать месторождение руды")]),_vm._v(".")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Выбрать снаряжение для добычи руды")]),_vm._v(" - это слот в вашем корпусе, куда вы\n        устанавливаете\n        промышленный/горнодобывающий лазер или иное подобное устройство.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Кликнуть ЛКМ по месторождению руды")]),_vm._v(" - данное действий запустит процесс\n        добычи посредством устройства.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Ожидать окончания разработки")]),_vm._v(" - индикатор заполнения шкалы, демонстрирующий\n        количество руды в месторождении и\n        количество собранных вами ресурсов.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Copper ore")]),_vm._v(" "),_c('li',[_vm._v("Iron ore")]),_vm._v(" "),_c('li',[_vm._v("Thorium ore")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Titanium ore")]),_vm._v(" "),_c('li',[_vm._v("Silicon ore")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Find an ore deposit")]),_vm._v(".")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Choose equipment for ore mining")]),_vm._v(" - this is a slot in your hull where you\n        install an industrial/mining laser or a similar device.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Click LMB on the ore deposit")]),_vm._v(" - this action will start the mining process\n        using the device.\n      ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"importantly"},[_vm._v("Wait for the development to finish")]),_vm._v(" - an indicator filling the scale,\n        showing the amount of ore in the deposit and the number of resources you have collected.\n      ")])])
}]

export { render, staticRenderFns }