var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Синхронизация")]),_vm._v(" "),_c('p',[_vm._v("Чем больше синтет использует корпус, тем сильнее его связь с ним, что позволяет повышать показатели различных\n        систем. Этот процесс называется \"синхронизация\".")]),_vm._v(" "),_c('p',[_vm._v("По сути, это навык для конкретного корпуса. Чем больше опыта зарабатывает игрок, тем сильнее прокачивается\n        его синхронизация.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('sync_2', 'png')+ ')',
          width: '394px',
          height: '285px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Очки подсистем усиливают все свойства корпуса конкретной категории на 1%. На одну подсистему можно потратить\n        только 10 очков.")]),_vm._v(" "),_c('p',[_vm._v("Перки усиливают корпус по определённой специализации. Например, если это лёгкий боевой корпус, то каждые 25%\n        будут усиливать малые орудия на 5%, а грузовик — расширять свой трюм на 10%. Со временем планируется расширить\n        перки до более узких специализаций: дроны, щиты, добывающее снаряжение и т.д.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('sync_1', 'png')+ ')',
          width: '400px',
          height: '290px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Synchronization")]),_vm._v(" "),_c('p',[_vm._v("The more a synthetic uses a body, the stronger its connection with it becomes, allowing for the enhancement\n        of various system parameters. This process is called \"synchronization\".")]),_vm._v(" "),_c('p',[_vm._v("Essentially, it is a skill for a specific body. The more experience a player gains, the stronger their\n        synchronization becomes.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("Subsystem points enhance all properties of the body in a specific category by 1%. Only 10 points can be spent\n        on one subsystem.")]),_vm._v(" "),_c('p',[_vm._v("Perks enhance the body according to a specific specialization. For example, if it is a light combat body,\n        every 25% will enhance small weapons by 5%, while a truck will expand its cargo hold by 10%. Over time, it is\n        planned to expand perks to more specific specializations: drones, shields, mining equipment, etc.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('sync_1', 'png')+ ')',
          width: '400px',
          height: '290px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Каждые 5% синхронизации дают 1 очко для усиления подсистемы (атака, защита, навигация, энергия, ходовая\n          часть).\n        ")]),_vm._v(" "),_c('li',[_vm._v("Каждые 25% открывают или усиливают перк корпуса.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Every 5% synchronization gives 1 point to enhance a subsystem (attack, defense, navigation, energy,\n          propulsion).\n        ")]),_vm._v(" "),_c('li',[_vm._v("Every 25% unlocks or enhances a body perk.")])])
}]

export { render, staticRenderFns }