<template>
  <div>
    <div class="detailItemBpBlockRow" v-for="id in ids">
      <div class="detailItemBpBlockIcon" :class="{broken_outline: id > 10000000}">
        <app-background-item-cell v-bind:slotItem="{type: type, item: getItemByTypeAndID(type, id)}"/>
      </div>
      <div class="detailItemBpBlockName">{{ itemName({type: type, item: getItemByTypeAndID(type, id)}) }}</div>
      <div class="detailItemBpBlockDetailButton cursor_hover" @click="openDetail(type, id)"/>
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import {GetItemByTypeAndID} from "../../game/utils/get_item_by_type_and_id";

export default {
  name: "Variants",
  props: ['ids', 'type'],
  methods: {
    getItemByTypeAndID(type, id) {
      return GetItemByTypeAndID(type, id)
    },
    openDetail(type, id) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
    itemName(slot) {
      if (!slot) {
        return ''
      }

      if (slot.type === "blueprints") {
        if (slot.item.in_map) {
          return this.handbook['structure'][this.language][slot.item.name.split('_bp')[0]].name
        } else {
          return this.handbook[slot.item.item_type][this.language][slot.item.item_name].name
        }
      }

      return this.handbook[slot.type][this.language][slot.item.name].name
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    }
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
.detailItemBpBlockIcon {
  position: relative;
  height: 30px;
  width: 30px;
  float: left;
}

.detailItemBpBlockName {
  float: left;
  line-height: 30px;
  color: white;
  margin-left: 10px;
}

.detailItemBpBlockRow {
  background: rgba(0, 0, 0, 0.2);
  float: left;
  width: calc(100% - 4px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 2px;
  transition: 200ms;
}

.detailItemBpBlockRow:hover {
  background: rgba(0, 0, 0, 0.1);
}

.detailItemBpBlockDetailButton {
  float: right;
  height: 20px;
  width: 20px;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
  margin-top: 4px;
  background-image: url('../../assets/icons/question-mark.png');
}

.detailItemBpBlockDetailButton:active {
  transform: scale(0.95);
}

.detailItemBpBlockDetailButton:hover {
  box-shadow: 0 0 4px white;
  color: white;
}

.broken_outline {
  outline: lightgrey dashed 2px;
}
</style>
