<template>
  <div>
    <template v-if="lang==='RU'">
      <h3 class="head">Время суток</h3>

      <p>На планете Veliri-5 время суток ровняется 24 часам, ночью становится ощутимо темнее и обзор транспорта
        изменяется
        в худшую сторону.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('times_of_day_1', 'gif')+ ')',
              width: '316px',
              height: '177px',
              marginLeft: 'calc(50% - 158px)'
            }"/>

      <p>Самое темное время на планете с 3х до 5ти ночи, тогда видимость транспорта уменьшается на 40%. Однако если
        включить фары (<span class="importantly">L</span>) то негативный эффект будет в 2 раза меньше.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('times_of_day_2', 'gif')+ ')',
              width: '390px',
              height: '365px',
              marginLeft: 'calc(50% - 195px)'
            }"/>

      <p>Время можно отслеживать на табло ниже мини карты.</p>
      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('times_of_day_3', 'png')+ ')',
              width: '261px',
              height: '241px',
              marginLeft: 'calc(50% - 130px)'
            }"/>

    </template>

    <template v-if="lang==='EN'">
    </template>
  </div>
</template>

<script>
export default {
  name: "TimesOfDay",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
