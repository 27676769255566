import store from "../../store/store";

function OpenBox(box_id, error, open) {

  store.dispatch("sendSocketData", JSON.stringify({
    service: "inventory",
    data: {
      event: "OpenInventory",
    }
  }));

  if (open) {
    if (error === "need password") {
      store.commit({
        type: 'toggleWindow',
        id: 'boxPass' + box_id,
        component: 'boxPass',
        meta: {
          boxID: box_id,
          action: 'open',
        },
        forceOpen: true,
      });

      return;
    }

    store.commit({
      type: 'toggleWindow',
      id: 'boxPass' + box_id,
      forceClose: true,
    });

    let timeOut = 15
    let wait = setInterval(function () {

      timeOut--
      if (timeOut <= 0) {
        clearInterval(wait)
        return
      }

      let find = false
      let warehousesKey = 'box:' + box_id
      for (let i in store.getters.getInventory.warehouses) {
        if (i === warehousesKey) {
          find = true
          break
        }
      }

      if (!find) return;

      clearInterval(wait)
      store.commit({
        type: 'setCurrentWarehouse',
        warehouse: warehousesKey,
      });

      store.commit({
        type: 'toggleWindow',
        id: 'wrapperInventoryAndStorage',
        forceOpen: true,
        meta: {warehouse: warehousesKey}
      });
    }, 100)
  }
}

export {OpenBox}
