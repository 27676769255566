<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Предметы на земле</h2>
      <p> Когда вы выбрасываете предметы из трюма, они падают на землю. Эти предметы не взаимодействуют с окружающей
        средой, но окружающая среда может влиять на них. При столкновении с транспортами, объектами или другими
        предметами
        они отскакивают.</p>

      <p>Их можно подобрать если в трюме достаточно места.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('map_items', 'png')+ ')',
            width: '250px',
            height: '200px',
            marginLeft: 'calc(50% - 125px)',
      }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Items on the Ground</h2>
      <p> When you throw items out of the hold, they fall to the ground. These items do not interact with the
        environment, but the environment can affect them. When colliding with transports, objects, or other items, they
        bounce off.</p>

      <p>You can pick them up if there is enough space in the hold.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('map_items', 'png')+ ')',
            width: '250px',
            height: '200px',
            marginLeft: 'calc(50% - 125px)',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "MapItems",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
