<template>
  <div class="Mail" id="Mail" ref="Mail" @mousedown="toUp" v-if="notOpenMails">

    <div class="error_message" :class="{error_message_visible: sendMailFail}">
      <div style="margin-top: 14%">{{ texts['error_1'][language] }}</div>
    </div>

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'Mail'"
                 v-bind:resizeFunc="resize"
                 v-bind:maxSize="{height: null, width: null}"
                 v-bind:minSize="{height: 180, width: 300}"/>

    <div class="tabs">
      <div v-bind:class="{active: tab === 'mail'}"
           class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('mail')">
        {{ texts['tab_1'][language] }}
      </div>

      <div v-bind:class="{active: tab === 'sent'}"
           class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('sent')">
        {{ texts['tab_2'][language] }}
      </div>

      <div style="background: rgb(221, 112, 52); color: white"
           v-bind:class="{active: tab === 'send'}"
           class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('send')">
        {{ texts['tab_3'][language] }}
      </div>

      <div v-bind:class="{active: tab === 'trash'}"
           class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('trash')">
        {{ texts['tab_4'][language] }}
      </div>
    </div>

    <div class="wrapper" :class="{mail_select: !(tab === 'open_mail' || tab === 'send' || tab === 'contacts')}">
      <div class="preloader_wrapper" v-if="loader">
        <app-preloader/>
      </div>

      <template v-if="!loader">

        <div v-for="m in mails"
             v-if="(tab === 'mail' && m.rpi === currentPlayer.id) || (tab === 'sent' && m.spi === currentPlayer.id)"
             class="mini_message" :class="{open: m.o && m.rpi === currentPlayer.id}">

          <div class="head cursor_hover" @click="setOpenMsgContent(m.id)">

            <div class="check_box cursor_hover" @click.stop="checkMail(m.id)">
              <input type="checkbox" class="cursor_hover" v-model="selectMails[m.id]">
            </div>

            <div class="notify_time">
              {{ texts['from'][language] }}: <span style="color: white" v-html="m.sn"/>
            </div>

            <div class="notify_time" style="float: right">
              {{ texts['date'][language] }}: <span style="color: white">{{ getDateTime(m.t) }}</span>
            </div>

            <div class="head_title">
              {{ m.s }}
            </div>
          </div>
        </div>

        <div v-for="m in trash" v-if="tab === 'trash'" class="mini_message">
          <div class="head cursor_hover" @click="setOpenMsgContent(m.id)">

            <div class="check_box cursor_hover" @click.stop="checkMail(m.id)">
              <input type="checkbox" :key="m.id" class="cursor_hover" v-model="selectMails[m.id]">
            </div>

            <div class="notify_time">
              {{ texts['from'][language] }}: <span style="color: white" v-html="m.sn"/>
            </div>

            <div class="notify_time" style="float: right">
              {{ texts['date'][language] }}: <span style="color: white">{{ getDateTime(m.t) }}</span>
            </div>

            <div class="head_title">
              {{ m.s }}
            </div>
          </div>
        </div>

        <template v-if="tab === 'open_mail' && mailContents[openContent]">
          <div class="message">
            <div class="head cursor_hover">
              <div class="notify_time">
                {{ texts['from'][language] }}: <span style="color: white" v-html="mailContents[openContent].sn"/>
              </div>
              <div class="notify_time" style="float: right">
                {{ texts['date'][language] }}: <span style="color: white">{{
                  getDateTime(mailContents[openContent].t)
                }}</span>
              </div>
              <div class="head_title">
                {{ mailContents[openContent].s }}
              </div>
            </div>

            <div class="content_message" v-html="mailContents[openContent].c"/>

            <template v-if="mailContents[openContent].ad">
              <div class="items" v-if="['craft', 'sell', 'buy'].includes(mailContents[openContent].ad.e)">
                <app-item-cell
                  v-bind:itemSlot="mailContents[openContent].ad.i"
                  v-bind:size="35"
                  @click.native="detailItem(mailContents[openContent].ad.i)"
                />

                <div class="map_button cursor_hover"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="openService('GlobalViewMap', {map_id: mailContents[openContent].ad.m_id})">
                  карта
                </div>
              </div>
            </template>
          </div>

          <input type="button"
                 :value="texts['button_1'][language]"
                 @click="changeTab('mail')" class="button cursor_hover">
          <input type="button"
                 :value="texts['button_2'][language]"
                 @click="deleteMsg(openContent)"
                 style="background: rgba(255, 76, 76, 0.6)" class="button cursor_hover">
          <input type="button"
                 :value="texts['button_3'][language]"
                 :class="{disable: mailContents[openContent].spi === 0}"
                 style="float: right; margin-right: 5px;" class="button cursor_hover">

        </template>

        <template v-if="tab === 'send'">

          <div class="search" v-if="!player">
            <input v-model="playerName" type="text" :placeholder="texts['placeholder_1'][language]"/>

            <input type="button"
                   class="cursor_hover"
                   :value="texts['button_4'][language]"
                   @mouseover="playSound('select_sound.mp3', 0.2)"
                   @click="changeTab('contacts')">

            <input type="button" style="margin-right: 8px;"
                   class="cursor_hover"
                   :value="texts['button_5'][language]"
                   @mouseover="playSound('select_sound.mp3', 0.2)"
                   @click="searchByName">

            <div class="not_found" v-if="notFound">
              {{ texts['text_1'][language] }}
            </div>
          </div>

          <div v-if="player" style="float: left; width: calc(100% - 6px); margin-left: 5px; height: 100%;">
            <app-user-line :user="player"
                           :parent="'Mail'"
                           :buttonExit="true"
                           :meta="{id: player.id}"
                           :exitFunc="removeUser"
            />

            <input class="theme_input cursor_hover" v-model="subject" type="text"
                   :placeholder="texts['placeholder_2'][language]"
                   @input="checkLimit('subject', 'subject')"/>

            <div class="limit">{{ limit.subject.current }} / {{ limit.subject.max }}</div>

            <textarea class="message_input cursor_hover" v-model="message"
                      :placeholder="texts['placeholder_3'][language]"
                      @input="checkLimit('message', 'message')"
            />

            <div class="limit_message">{{ limit.message.current }} / {{ limit.message.max }}</div>

            <input class="button cursor_hover" type="button"
                   :class="{disable: subject.length === 0 || message.length === 0}"
                   style="position: absolute;bottom: 11px; right: 18px; width: 65px;"
                   :value="texts['button_6'][language]"
                   @mouseover="playSound('select_sound.mp3', 0.2)"
                   @click="send"
            >
          </div>

        </template>

        <template v-if="tab === 'contacts'">
          <app-user-line v-for="c in contacts"
                         :key="c.player_id"
                         v-bind:user="{id: c.player_id, login: c.player_name}"
                         v-bind:parent="'Mail'"
                         v-bind:no-menu="true"
                         v-bind:buttonExit="false"
                         @click.native="selectContact(c.player_id)"
          />
        </template>

        <template v-if="tab === 'send_mail'">
          <div class="send_mail_msg">{{ texts['text_2'][language] }}</div>
        </template>
      </template>
    </div>

    <div class="mail_select_buttons" v-if="!(tab === 'open_mail' || tab === 'send' || tab === 'contacts')">
      <input type="button"
             :value="allTrue ? 'Снять все' : 'Выбрать все'"
             class="button cursor_hover"
             @click="checkAllMail"
             style="margin-top:3px;">
      <input type="button"
             :value="tab === 'trash' ? 'Удалить' : 'В корзину'"
             @click="deleteAllSelect"
             :class="{disable: selectMailsArray.length === 0}"
             style="margin-top:3px; background: rgba(255, 76, 76, 0.6);"
             class="button cursor_hover">
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import UserLine from "../Chat/UserLine";
import Preloader from "../Preloader/Preloader";
import {urls} from "../../const";
import Vue from "vue";
import ItemCell from '../Inventory/ItemCell';
import Input from "../Chat/Input";

export default {
  name: "Mail",
  props: ['meta'],
  data() {
    return {
      tab: "mail",
      loader: true,
      mails: [],
      trash: [],
      mailContents: {}, // [msg_id]content
      openContent: 0,
      playerName: '',
      player: null,
      notFound: false,
      subject: '',
      message: '',
      selectMails: {},
      limit: {
        subject: {
          current: 0,
          max: 50,
        },
        message: {
          current: 0,
          max: 300,
        }
      }
    }
  },
  mounted() {
    this.getMails();
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetContacts",
      }
    }));

    if (this.$props.meta && this.$props.meta.player_id) {
      this.tab = 'send';
      this.searchByID(this.$props.meta.player_id)
    }
  },
  methods: {
    resize(event, ui, el) {

    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    checkMail(id) {
      Vue.set(this.selectMails, id, !Boolean(this.selectMails[id]))
    },
    checkAllMail() {
      let mails = this.mails;
      if (this.tab === 'trash') mails = this.trash;

      let allTrue = this.allTrue

      for (let i in mails) {
        Vue.set(this.selectMails, mails[i].id, !allTrue)
      }
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)

      this.tab = tab;
      this.selectMails = {};

      if (tab === 'trash') {
        this.getTrashMails();
      }
    },
    send() {
      this.tab = 'send_mail'
      setTimeout(function () {
        this.subject = '';
        this.message = '';
        this.player = null;
        this.tab = 'sent'
      }.bind(this), 500)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "SendMail",
          name: this.subject,
          data: this.message,
          id: this.player.id,
        }
      }));
    },
    checkLimit(t, t2) {
      if (this[t].length > this.limit[t2].max) {
        this[t] = this[t].substring(0, this.limit[t2].max);
      }
      this.limit[t2].current = this[t].length
    },
    setOpenMsgContent(id) {
      let app = this
      if (!app.mailContents[id]) {
        app.loader = true
        app.$api.get(urls.getMailContent + '?pid=' + this.currentPlayer.id + '&mid=' + id).then(function (response) {
          if (response.data) {
            app.mailContents[id] = response.data
            app.getMails()
          } else {
            // TODO error
          }

          app.loader = false
        })
      }

      app.tab = 'open_mail'
      app.openContent = id
    },
    deleteMsg(id) {
      let app = this;
      app.loader = true;
      app.changeTab('mail');

      app.$api.get(urls.deleteMail + '?pid=' + this.currentPlayer.id + '&mid=' + id).then(function (response) {
        app.getMails()
      })
    },
    deleteAllSelect() {
      let app = this;
      app.loader = true;

      let promises = [];

      for (let id of this.selectMailsArray) {
        promises.push(app.$api.get(urls.deleteMail + '?pid=' + this.currentPlayer.id + '&mid=' + id))
      }

      Promise.all(promises).then(function (response) {
        if (app.tab === 'trash') {
          app.getTrashMails();
        } else {
          app.getMails()
        }
      })
    },
    getTrashMails() {
      let app = this;
      app.$api.get(urls.getMailTrash + '?pid=' + this.currentPlayer.id).then(function (response) {
        if (response.data) {
          app.trash = response.data
        } else {
          // TODO error
        }

        app.loader = false
      })
    },
    getMails() {
      let app = this;
      app.$api.get(urls.getMails + '?pid=' + this.currentPlayer.id).then(function (response) {
        if (response.data) {
          app.mails = response.data
        } else {
          // TODO error
        }

        app.loader = false
      })

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetCountNotOpenMails"
        }
      }));
    },
    selectContact(id) {
      this.changeTab('send');
      this.searchByID(id);
    },
    searchByName() {
      this.search('?user_name=' + this.playerName)
    },
    searchByID(id) {
      this.search('?user_id=' + id)
    },
    search(params) {
      let app = this;

      app.$api.get(urls.userInfoURL + params).then(function (response) {
        if (!response.data) {
          app.notFound = true;
          setTimeout(function () {
            app.notFound = false;
          }, 1000)
        }
        Vue.set(app, 'player', response.data);
      });
    },
    removeUser() {
      Vue.set(this, 'player', null);
    },
    getDateTime(time) {
      let now = new Date(time);

      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hour = now.getHours();
      let minute = now.getMinutes();
      let second = now.getSeconds();

      if (month.toString().length === 1) {
        month = '0' + month;
      }
      if (day.toString().length === 1) {
        day = '0' + day;
      }
      if (hour.toString().length === 1) {
        hour = '0' + hour;
      }
      if (minute.toString().length === 1) {
        minute = '0' + minute;
      }
      if (second.toString().length === 1) {
        second = '0' + second;
      }

      return year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
    },
    detailItem(i) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + i.type + i.item_id,
        component: 'detailItem',
        meta: {itemType: i.type, itemID: i.item_id},
        forceOpen: true,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)

      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Mail')
    },
    contacts() {
      return this.$store.getters.getChatState.friends;
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    notOpenMails() {
      let update = this.$store.getters.getUpdateMails
      if (update) {
        this.getMails();
        this.$store.commit({
          type: 'setUpdateMails',
          update: false,
        });
      }

      return true
    },
    sendMailFail() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('send_mail_fail') && errors['send_mail_fail'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'send_mail_fail',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    selectMailsArray() {
      let mail = [];

      for (let i in this.selectMails) {
        if (this.selectMails[i]) {
          mail.push(Number(i))
        }
      }

      return mail
    },
    allTrue() {
      let mails = this.mails;
      if (this.tab === 'trash') mails = this.trash;

      let allTrue = true
      let count = 0
      for (let i in mails) {
        if (!this.selectMails[mails[i].id]) {
          allTrue = false
          count++
          break
        }
      }

      return allTrue && count > 0
    },
  },
  components: {
    Input,
    AppControl: Control,
    AppUserLine: UserLine,
    AppPreloader: Preloader,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.Mail {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  width: 300px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  height: 180px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.tabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tabs div:last-child {
  /*border: 0;*/
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.tabs div:hover {
  color: #fff200 !important;
}

.wrapper {
  width: 100%;
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  background: rgba(137, 150, 156, 0.3);
  height: calc(100% - 15px);
}

.mail_select {
  height: calc(100% - 35px);
}

.preloader_wrapper {
  float: left;
  margin-left: calc(50% - 21px);
  margin-top: 40px;
}

.mini_message, .message {
  left: 0;
  border-radius: 5px 0 0 5px;
  z-index: 1;
  color: white;
  font-size: 12px;
  padding: 5px;
  overflow: hidden;
  height: 20px;
  box-shadow: 0 0 2px black;
  position: relative;
  float: left;
  bottom: unset;
  width: calc(100% - 18px);
  margin: 4px 0 0 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.message {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 40px);
}

.mini_message:hover {
  background-color: rgba(127, 127, 127, 0.5);
}

.mini_message:active {
  background-color: rgba(255, 255, 255, 0.5);
}

.head {
  clear: both;
  margin-bottom: 0;
  margin-top: -3px;
  margin-left: -2px;
  float: left;
  width: 100%;
}

.notify_time {
  font-size: 11px;
  color: #c9c5c5;
  float: left;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  width: calc(50% - 20px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.head_title {
  float: left;
  color: #f1bd00;
  clear: both;
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  width: calc(100% - 20px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content_message {
  margin-top: 2px;
  float: left;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  padding-top: 12px;
}

.button {
  display: block;
  float: left;
  width: 75px;
  margin: 5px 0 0 6px;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1) !important;
}

.open {
  opacity: 0.65;
}

.search {
  float: left;
  width: calc(100% - 10px);
  position: relative;
  margin: 6px 0 0 6px;
}

.search input {
  width: 100%;
  margin-bottom: 4px;
}

.search input[type="button"], .send_button {
  display: block;
  width: 70px;
  pointer-events: auto;
  font-size: 13px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: right;
  margin: 2px 2px 0 0;
}

.search input[type="button"]:hover, .send_button:hover {
  background: rgba(255, 129, 0, 1);
}

.search input[type="button"]:active, .send_button:active {
  transform: scale(0.98);
}

.search input[type=button]:active {
  transform: scale(0.97);
}

.search input[type=text], .theme_input, .message_input {
  width: calc(100% - 170px);
  margin-left: 2px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  color: whitesmoke;
}

.theme_input, .message_input {
  width: calc(100% - 11px);
  margin: 4px 2px 2px 0;
}

.message_input {
  outline: none;
  border: none;
  border-radius: 5px;
  resize: none;
  width: calc(100% - 5px);
  height: calc(100% - 66px);
}

.not_found {
  height: 21px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(137, 150, 156, 0.7);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-shadow: 1px 1px 1px black;
}

.limit, .limit_message {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 1px black;
  top: 65px;
  right: 18px;
  font-size: 10px;
}

.limit_message {
  top: 91px;
}

.send_mail_msg {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  color: white;
  line-height: 50px;
  text-align: center;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.8);
  margin: -23px 0 0 -3px;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  top: 23px;
  left: 3px;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.items {
  /*border-top: 1px solid rgba(255, 255, 255, 0.5);*/
  height: 42px;
  width: 100%;
  float: left;
  position: relative;
  margin-top: 10px;
}

.map_button {
  width: 38px;
  margin: 2px auto 0;
  font-size: 12px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 2px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  padding-left: 0;
  text-indent: 0;
  line-height: 16px;
  float: right;
}

.map_button:hover {
  background: rgba(255, 129, 0, 1);
}

.map_button:active {
  transform: scale(0.98);
}

.check_box {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: auto;
  height: 30px;
  width: 30px;
}

.check_box input {
  margin: 9px;
}
</style>
