<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">База</h2>
      <div class="content_section">

        <h3 class="headSection" id="base">База</h3>
        <p>База - это центр сектора, где тысячи синтетов находят свое убежище и занятие. Это центр торговли, пункт
          обмена знаний и форпост военных операций. Синтеты всегда могут найти здесь не только укрытие, но занятие под
          свой вкус и выгодное предложение.</p>

        <p>Комплектация базы:</p>
        <ul>
          <li>Подземный комплекс - рабочие тоннели, производственные цеха и лаборатории. Всё самое ценное, что можно
            скрыть от любопытных глаз и ракетных атак.
          </li>
          <li>Вход и ангар - ремонтные мастерские, загрузочно-заправочные доки и центры контроля, не допускающие вглубь
            базы неблагонадёжных синтетов.
          </li>
          <li>Торговая площадка - связующая целый сектор биржа, где одни товары приобретаются, другие продаются, а
            третьи
            обмениваются за ценную информацию.
          </li>
          <li>Рынок - склады хранения и пункты “живой” торговли, где синтеты без лишнего надзора со стороны фракции
            осуществляют сделки по купле-продаже чего только угодно.
          </li>
        </ul>

        <p>База, это место отдыха от долгих путешествий по пустошам планеты. Впрочем, не смотря на наличие надзорных
          ботов, что пресекают любую преступную деятельность, никто не гарантирует вам здесь полную безопасность. </p>

        <p>На центральных базах сектора производится различное оборудование для корпуса, навесное оружие, собственно,
          как
          и сами корпуса, с помощью которых синтеты и исследуют Veliri 5. Для всех данных манипуляций, синтету прежде
          всего придётся запастись всеми необходимыми деталями, которые можно будет добыть на специализированных
          заводах. </p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('base_1', 'png')+ ')',
        width: '480px',
        height: '165px',
        marginLeft: 'calc(50% - 240px)',
        }"/>

        <p>Перерабатывающий завод - сооружение, чья деятельность перерабатывать полезные ископаемые в сырье для
          производства. Зачастую же именно здесь, синтеты, используя мощности завода, разбирают различные предметы на
          составляющие компоненты. Впрочем, все эти манипуляции не бесплатны. Фракция, заботливо берёт за услуги завода
          налог, чья ставка зачастую варьируется от репутационной ситуации того или иного синтета. </p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('base_2', 'png')+ ')',
        width: '200px',
        height: '200px',
        marginLeft: 'calc(50% - 100px)',
        }"/>

        <p>Завод полуфабрикатов - сооружение, позволяющее создавать любые необходимые синтету детали из загруженного
          сырья. Учитывая массовую востребованность и загруженность заводов полуфабрикатов, многие фракции прибегли к
          практике взимания двойного налога за услуги данного сооружения.</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('base_3', 'png')+ ')',
        width: '200px',
        height: '200px',
        marginLeft: 'calc(50% - 100px)',
        }"/>

        <p>Базы и заводы - это ключевые элементы инфраструктуры сектора, которые обеспечивают синтетов всем необходимым
          для выживания и развития.</p>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Base</h2>
      <div class="content_section">

        <h3 class="headSection" id="base">Base</h3>
        <p>The base is the sector's center, where thousands of synthetics find their refuge and occupation. It is a
          center of trade, a point of knowledge exchange, and an outpost of military operations. Synthetics can always
          find not only shelter here but also an occupation to their liking and a profitable offer.</p>

        <p>Base equipment:</p>
        <ul>
          <li>Underground complex - working tunnels, production workshops, and laboratories. Everything most valuable
            that can be hidden from prying eyes and missile attacks.
          </li>
          <li>Entrance and hangar - repair shops, loading and refueling docks, and control centers that do not allow
            unreliable synthetics into the depths of the base.
          </li>
          <li>Trading floor - a sector-wide exchange where some goods are bought, others are sold, and others are
            exchanged for valuable information.
          </li>
          <li>Market - storage warehouses and points of "live" trade, where synthetics conduct purchase and sale
            transactions of anything they please without excessive oversight from the faction.
          </li>
        </ul>

        <p>The base is a place to rest from long journeys through the planet's wastelands. However, despite the presence
          of supervisory bots that suppress any criminal activity, no one guarantees you complete safety here.</p>

        <p>At the central bases of the sector, various equipment for the chassis, mounted weapons, and the chassis
          themselves are produced, with which synthetics explore Veliri 5. For all these manipulations, the synthetic
          must first stock up on all the necessary parts, which can be obtained at specialized factories.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('base_1', 'png')+ ')',
          width: '480px',
          height: '165px',
          marginLeft: 'calc(50% - 240px)',
        }"></div>

        <p>The processing plant is a facility whose activity is to process minerals into raw materials for production.
          Often it is here that synthetics, using the power of the plant, disassemble various items into their component
          parts. However, all these manipulations are not free. The faction carefully takes a tax for the services of
          the plant, the rate of which often varies depending on the reputational situation of a particular
          synthetic.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('base_2', 'png')+ ')',
          width: '200px',
          height: '200px',
          marginLeft: 'calc(50% - 100px)',
        }"></div>

        <p>The semi-finished products factory is a facility that allows creating any necessary parts for the synthetic
          from the loaded raw materials. Considering the massive demand and workload of semi-finished product factories,
          many factions have resorted to the practice of charging double tax for the services of this facility.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('base_3', 'png')+ ')',
          width: '200px',
          height: '200px',
          marginLeft: 'calc(50% - 100px)',
        }"></div>

        <p>Bases and factories are key elements of the sector's infrastructure that provide synthetics with everything
          necessary for survival and development.</p>
      </div>

    </template>
  </div>
</template>

<script>
export default {
  name: "Base",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
