import {Scene} from "../create";
import {statusBarWrapper} from "./status_bar";
import {gameStore} from "../store";

const timeout = 1500;

function DrawText(text, color, toPointer) {

  if (!statusBarWrapper.scene || !gameStore.gameReady) return;

  let x = statusBarWrapper.scene.cameras.main.displayWidth / 2;
  let y = (statusBarWrapper.scene.cameras.main.displayHeight / 2) - (statusBarWrapper.scene.cameras.main.displayHeight / 4);

  if (toPointer) {
    x = Scene.game.input.activePointer.x
    y = Scene.game.input.activePointer.y - 50
  }

  let textSprite = statusBarWrapper.scene.add.bitmapText(x, y, 'bit_text', text, 14);
  textSprite.setOrigin(0.5);
  textSprite.setLetterSpacing(1)
  textSprite.setDepth(1001);
  if (color) textSprite.setTint(color)

  Scene.time.addEvent({
    delay: timeout,
    callback: function () {
      textSprite.destroy()
    },
  });
}

export {DrawText}
