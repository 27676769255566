var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Офис кластера")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Офис - особое место представительства вашего кластера на выбранной базе. Офис позволяет иметь на базе до\n        четырёх (4) складов, чья вместимость - 10000.00 кубометров. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_2', 'jpg')+ ')',
          width: '400px',
          height: '208px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Прежде, чем открыть сам офис, игроку придётся арендовать под него помещение. Арендовать офис может только\n        лидер\n        кластера. Цена за аренду зависит от общего количества уже имеющихся офисов у кластера и начинается с 25000.0\n        cr\n        сроком на 30 дней, если данный офис - первый. ")]),_vm._v(" "),_c('p',[_vm._v("Сами склады и Офисы можно подвергать переименованию, совершая данное действие во вкладке - “О кластере”. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_1', 'jpg')+ ')',
          width: '370px',
          height: '179px',
          marginLeft: 'calc(50% - 185px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Склады по-умолчанию являются личной собственностью главы кластера, тем не менее, доступ к ним можно\n        расширить,\n        попросту раздав на это право прочим и необходимым участникам кластера.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_3', 'jpg')+ ')',
          width: '353px',
          height: '192px',
          marginLeft: 'calc(50% - 176px)',
          })})]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Блокировка")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("В случае, если игрок просрочил платёж за аренду офиса, база арестовывает склад кластера, ограничивая туда\n        доступа до тех пор, пока сам долг не будет выплачен. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_4', 'jpg')+ ')',
          width: '120px',
          height: '178px',
          marginLeft: 'calc(50% - 60px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Cluster Office")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("The office is a special place representing your cluster at the chosen base. The office allows having up to\n        four (4) warehouses on the base, each with a capacity of 10,000.00 cubic meters.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_2', 'jpg')+ ')',
          width: '400px',
          height: '208px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Before opening the office itself, the player will have to rent a space for it. Only the cluster leader can\n        rent an office. The rental price depends on the total number of offices already owned by the cluster and\n        starts at 25,000.0 cr for 30 days if this office is the first.")]),_vm._v(" "),_c('p',[_vm._v("The warehouses and Offices themselves can be renamed, performing this action in the \"About Cluster\" tab.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_1', 'jpg')+ ')',
          width: '370px',
          height: '179px',
          marginLeft: 'calc(50% - 185px)',
          })}),_vm._v(" "),_c('p',[_vm._v("By default, the warehouses are the personal property of the cluster head, however, access to them can be\n        expanded by simply granting this right to other necessary cluster members.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_3', 'jpg')+ ')',
          width: '353px',
          height: '192px',
          marginLeft: 'calc(50% - 176px)',
          })})]),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Lockdown")]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("In case a player has overdue payment for the office rent, the base seizes the cluster's warehouse,\n        restricting access until the debt is paid off.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('office_4', 'jpg')+ ')',
          width: '120px',
          height: '178px',
          marginLeft: 'calc(50% - 60px)',
          })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }