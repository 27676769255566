var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Ангар и склад")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_hangar_1', 'png')+ ')',
          width: '266px',
          height: '375px',
          marginLeft: 'calc(50% - 133px)',
          })}),_vm._v(" "),_c('p',[_vm._v("После чего откроется 2 меню и пункт задания будет выполнен.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_hangar_2', 'png')+ ')',
          width: '447px',
          height: '198px',
          marginLeft: 'calc(50% - 223px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Hangar and Warehouse")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_hangar_1', 'png')+ ')',
          width: '266px',
          height: '375px',
          marginLeft: 'calc(50% - 133px)',
          })}),_vm._v(" "),_c('p',[_vm._v("After which two menus will open and the task item will be completed.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_hangar_2', 'png')+ ')',
          width: '447px',
          height: '198px',
          marginLeft: 'calc(50% - 223px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы открыть ангар и склад необходимо нажать "),_c('span',{staticClass:"importantly"},[_vm._v("\"Ангар\"")]),_vm._v(" / "),_c('span',{staticClass:"importantly"},[_vm._v("\"Склад / Трюм\"")]),_vm._v(" на меню базы слева.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To open the hangar and warehouse, you need to press "),_c('span',{staticClass:"importantly"},[_vm._v("\"Hangar\"")]),_vm._v(" / "),_c('span',{staticClass:"importantly"},[_vm._v("\"Warehouse / Hold\"")]),_vm._v(" on the base menu to the left.")])
}]

export { render, staticRenderFns }