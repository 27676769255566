import store from "../../store/store";

function TwoCutscene(data) {

  let current = store.getters.getPlayer

  store.commit({
    type: 'setTrailer',
    open: true,
    sound: false,
    url: './assets/movies/intro_2_' + current.fraction.toLowerCase() + '.mp4',
    glitchOff: true,
    mission_uuid: data.mission_uuid, //   завершаем экшон после просмотра
    action_number: data.action_number,
  });
}

export {TwoCutscene}
