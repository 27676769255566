var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Рынок")]),_vm._v(" "),_c('p',[_vm._v("Рынок - функция доступная на каждой базе любой фракции, позволяющая торговать товарами с иными синтетами.")]),_vm._v(" "),_c('p',[_vm._v("Сам рынок привязан к базе, где осуществляется сделка купли/продажи. Игроку следует быть готовым отправиться в\n      путешествие, чтобы забрать купленный товар. ")]),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Особенности рынка")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('market_2', 'png')+ ')',
          width: '480px',
          height: '286px',
          marginLeft: 'calc(50% - 240px)',
          })}),_vm._v(" "),_c('p',[_vm._v("За каждый выставленный лот, игрок платит взнос на его содержание, чья цена зависит от типа товара, его\n      количества\n      и времени удержания лота на самом рынке.")]),_vm._v(" "),_c('p',[_vm._v("Игроки имеют полное право обмениваться предметами игнорируя рынок, но в таком случае, подобные сделки не\n      являются\n      безопасными.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Market")]),_vm._v(" "),_c('p',[_vm._v("The market is a feature available at every base of any faction, allowing trade with other synthetics.")]),_vm._v(" "),_c('p',[_vm._v("The market itself is tied to the base where the purchase/sale transaction takes place. Players should be\n      prepared to travel to\n      pick up the purchased goods. ")]),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Market Features")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('market_2', 'png')+ ')',
      width: '480px',
      height: '286px',
      marginLeft: 'calc(50% - 240px)',
      })}),_vm._v(" "),_c('p',[_vm._v("For each lot listed, the player pays a maintenance fee, the price of which depends on the type of goods, their\n      quantity\n      and the time the lot is held on the market itself.")]),_vm._v(" "),_c('p',[_vm._v("Players have the full right to exchange items ignoring the market, but in such cases, such deals are not\n      considered\n      safe.")])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Все игроки рынка анонимны, то есть вы не знаете, с кем вы торгуете.")]),_vm._v(" "),_c('li',[_vm._v("Все запросы и предложения находятся в единой базе, то есть вы видите все доступные лоты на рынке.")]),_vm._v(" "),_c('li',[_vm._v("Запросы/предложения схлопываются автоматически при совпадении цены, то есть если вы согласны на цену другого\n        игрока, то сделка заключается мгновенно.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("All market players are anonymous, meaning you do not know who you are trading with.")]),_vm._v(" "),_c('li',[_vm._v("All requests and offers are located in a single database, meaning you see all available lots on the\n        market.\n      ")]),_vm._v(" "),_c('li',[_vm._v("Requests/offers automatically collapse when the price matches, meaning if you agree to another player's\n        price, the deal is concluded instantly.\n      ")])])
}]

export { render, staticRenderFns }