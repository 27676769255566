<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Отряды</h2>

      <p>При желании, игроки могут объединить свои усилия ради достижения любых целей посредством - отрядов.</p>
      <p>Данное объединение игроков считается единой союзной силой, к чьим услугам будет доступна статистика
        каждого члена отряда, а такие функции как “Радар” и “Обзор” объединяются.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('squad_1', 'gif')+ ')',
            width: '480px',
            height: '224px',
             marginLeft: 'calc(50% - 240px)',
      }"/>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('squad_2', 'gif')+ ')',
            width: '480px',
            height: '224px',
            marginLeft: 'calc(50% - 240px)',
            marginTop: '10px',
      }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Squads</h2>

      <p>If desired, players can unite their efforts to achieve any goals through squads.</p>
      <p>This union of players is considered a single allied force, which will have access to the statistics of each
        squad member, and functions such as "Radar" and "Overview" are combined.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('squad_1', 'gif')+ ')',
            width: '480px',
            height: '224px',
             marginLeft: 'calc(50% - 240px)',
        }"/>

        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('squad_2', 'gif')+ ')',
              width: '480px',
              height: '224px',
              marginLeft: 'calc(50% - 240px)',
              marginTop: '10px',
        }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Squad",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
