<template>
  <div id="buildDialogWrapper" ref="buildDialogWrapper" @mousedown="toUp">


    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'buildDialogWrapper'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 72, width: 230}"/>

    <div class="tabs">
      <div class="cursor_hover" :class="{active: menu === 'build'}" @click="menu = 'build'">
        {{ texts['tab_1'][language] }}
      </div>
      <div class="cursor_hover" :class="{active: menu === 'destroy'}" @click="menu = 'destroy'">
        {{ texts['tab_2'][language] }}
      </div>
    </div>

    <div id="buildDialog" v-if="menu === 'build'">

      <div class="energy">
        <div class="bar_energy_icon"
             :style="{backgroundImage: 'url(' + require('../../assets/icons/low_power_icon.png').default + ')'}"></div>
        <div style="width: 100%;position: relative; z-index: 999">

          <template v-if="!tip.visible || tip.energy === 0">
            {{ buildMenu.maxEnergy - buildMenu.currentEnergy + '  /  ' + buildMenu.maxEnergy }}
          </template>

          <template v-if="tip.visible && tip.energy > 0">
            {{ buildMenu.maxEnergy - buildMenu.currentEnergy + '  /  ' + (buildMenu.maxEnergy + tip.energy) }}
          </template>

          <template v-if="tip && tip.visible && tip.energy < 0">
            {{ ((buildMenu.maxEnergy - buildMenu.currentEnergy) + (-1 * tip.energy)) + '  /  ' + buildMenu.maxEnergy }}
          </template>
        </div>

        <div class="current_energy" v-if="!tip.visible || tip.energy === 0 || tip.energy < 0"
             :style="{
          width: buildMenu.currentEnergy > 0 && buildMenu.maxEnergy > 0 ?
             (100 / (buildMenu.maxEnergy / (buildMenu.maxEnergy - buildMenu.currentEnergy))) + '%': '0%'}"/>

        <div class="current_energy" v-if="tip && tip.visible && tip.energy > 0"
             :style="{
          width: buildMenu.currentEnergy > 0 && buildMenu.maxEnergy > 0 ?
             (100 / ((buildMenu.maxEnergy + tip.energy) / (buildMenu.maxEnergy - buildMenu.currentEnergy))) + '%': '0%',
             }"/>

        <div class="tip_energy" v-if="tip && tip.visible && tip.energy < 0"
             :style="{
          width: buildMenu.currentEnergy > 0 && buildMenu.maxEnergy > 0 ?
             (100 / (buildMenu.maxEnergy / (buildMenu.maxEnergy - buildMenu.currentEnergy + (-1 * tip.energy)))) + '%': '0%',
             }"/>
      </div>

      <template>

        <app-item-cell v-for="p in points"
                       v-if="p && p.blueprint"
                       @mouseover.native="cellOver({item: p.blueprint, type: 'blueprints'}, p.credits, p.energy)"
                       @mousemove.native="cellMove($event)"
                       @mouseout.native="cellOut"
                       @click.native="selectBP(p.id, p.energy * -1 > buildMenu.currentEnergy)"
                       v-bind:cursor_pointer="true"
                       v-bind:disable="(p.energy * -1 > buildMenu.currentEnergy) || (p.limit > 0 && buildMenu.currentLimit.hasOwnProperty(p.id) && p.limit <= buildMenu.currentLimit[p.id])"
                       v-bind:size="64"
                       v-bind:noShowName="true"
                       v-bind:quantityColor="(p.limit > 0 && buildMenu.currentLimit.hasOwnProperty(p.id) && p.limit <= buildMenu.currentLimit[p.id]) ? '#ff5858' : null"
                       v-bind:itemSlot="{
                         item: p.blueprint,
                         type: 'blueprints',
                         quantity: p.limit > 0 && buildMenu.currentLimit.hasOwnProperty(p.id) ? buildMenu.currentLimit[p.id] + '/' + p.limit : false,
                       }"/>

        <div class="buildStatusBP"
             v-if="tip.visible"
             :style="{left: tip.left, top: tip.top}">

          <div class="structure_head">
            <div class="structure_icon">
              <app-background-item-cell v-bind:slotItem="{type: 'structure', item: {name: tip.structureIcon}}"/>
            </div>
            <div class="structure_description">
              <h3 class="object_name">{{ handbook['structure'][language][tip.structureName].name }}</h3>
              <div class="description" v-html="handbook['structure'][language][tip.structureName].description"></div>
            </div>
          </div>

          <div>
            <h5>{{ texts['text_1'][language] }}</h5>
            <div class="need_credits">
              <div class="credits_icon"></div>
              <div class="credits_count">{{ (tip.credits / 100).toFixed(2) }}</div>
            </div>

            <div class="need_credits">
              <div class="energy_icon"></div>
              <div class="credits_count">{{ tip.energy > 0 ? '+' + tip.energy : tip.energy }}</div>
            </div>
          </div>

          <div class="need_items">
            <h5>{{ texts['text_2'][language] }}</h5>
            <div class="structure_need_items">
              <app-item-cell
                v-for="detailName in ['wire', 'electronics', 'wires', 'gear', 'titanium_plate', 'batteries', 'armor_items', 'steel']"
                v-if="tip.bp.item[detailName] > 0"
                v-bind:itemSlot="{type: 'detail', item: {name: detailName}, quantity: tip.bp.item[detailName]}"
                v-bind:size="52"/>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div id="buildDialog" v-if="menu === 'destroy'">

      <template v-if="!buildMenu.selectDestroyTarget">
        <div class="button cursor_hover" @click="selectDestroyStructure()">
          {{ texts['text_3'][language] }}
        </div>
      </template>

      <template v-if="buildMenu.selectDestroyTarget">
        <div class="button cursor_hover" style="margin-left: 5%; width: 40%; float: left; left: unset"
             @click="removeSelectDestroyStructure()">
          {{ texts['text_4'][language] }}
        </div>

        <div class="button cursor_hover" style="margin-right: 5%; width: 40%; float: right; left: unset"
             @click="destroy()">
          {{ texts['text_5'][language] }}
        </div>

        <h5 class="remove_head">{{ texts['text_6'][language] }}</h5>
        <div>
          <app-item-cell v-if="buildMenu.selectDestroyTarget.point && buildMenu.selectDestroyTarget.point.blueprint"
                         v-bind:size="64"
                         v-bind:noShowName="false"
                         v-bind:itemSlot="{item: buildMenu.selectDestroyTarget.point.blueprint, type: 'blueprints'}"/>
        </div>

        <h5 class="remove_head">{{ texts['text_7'][language] }}</h5>
        <div>
          <app-item-cell
            v-for="slot in buildMenu.selectDestroyTarget.resources"
            v-if="slot.quantity > 0"
            v-bind:itemSlot="slot"
            v-bind:size="45"/>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import ItemCell from '../Inventory/ItemCell';
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import {DestroyTargets, RemoveAllSelectSprite} from "../../game/build/builder_targets";

export default {
  name: "BuildMenu",
  data() {
    return {
      tip: {
        left: 0,
        top: 0,
        visible: false,
        structureName: '',
        structureIcon: '',
        credits: 0,
        bp: {},
      },
      menu: "build",
      updater: null,
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater)
  },
  mounted() {
    let app = this;

    app.updater = setInterval(function () {
      app.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "OpenBuildMenu",
        }
      }));
    }, 1000)

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporationDeposits",
      }
    }));
  },
  methods: {
    resize() {
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    selectBP(id, disable) {
      if (disable) return;

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "SelectBuildBP",
          id: id,
        }
      }));
    },
    cellOver(bp, credits, energy) {
      // todo costil
      let user = this.$store.getters.getPlayer;
      let fraction = "_" + user.fraction

      if (bp.item.name.includes('corporation_base') || bp.item.name.includes('corporation_office') ||
        bp.item.name.includes('corporation_prefabricated') || bp.item.name.includes('corporation_processing') ||
        bp.item.name.includes('corporation_market')) {

        fraction = ''
      }

      let name = bp.item.name.split('_bp')[0] + fraction;
      name = name.replace('mp_', '');

      this.tip.structureIcon = name.replace('map_', '');
      this.tip.structureName = bp.item.name.split('_bp')[0].replace('mp_', '').replace('map_', '');

      this.tip.credits = credits;
      this.tip.bp = bp;
      this.tip.energy = energy
    },
    cellMove(e, id) {
      this.tip.visible = true;
      this.tip.left = e.pageX - $(this.$el).position().left + 10 + 'px';
      this.tip.top = e.pageY - $(this.$el).position().top + 10 + 'px';
    },
    cellOut() {
      this.tip.visible = false;
    },
    selectDestroyStructure() {
      DestroyTargets()
    },
    removeSelectDestroyStructure() {
      RemoveAllSelectSprite()
      this.$store.commit({
        type: 'setSelectDestroyTarget',
        data: null,
      });
    },
    destroy() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "DestroyStructure",
          id: this.buildMenu.selectDestroyTarget.bid,
        }
      }));
    }
  },
  computed: {
    buildMenu() {
      return this.$store.getters.getBuildMenu
    },
    points() {
      let p = this.buildMenu.points;
      if (!p || p.length === 0) return [];

      p.sort(function (a, b) {
        return b.id - a.id;
      });

      return p
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('BuildMenu')
    },
  },
  components: {
    AppControl: Control,
    AppItemCell: ItemCell,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
#buildDialogWrapper {
  height: 72px;
  width: 230px;
  position: absolute;
  left: calc(50% - 100px);
  top: 40%;
  padding: 21px 3px 3px 3px;
  border-radius: 5px;
  z-index: 10;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#buildDialog {
  background: rgba(0, 0, 0, 0.5);
  height: calc(100% - 15px);
  width: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0 3px black;
  overflow-x: hidden;
  overflow-y: scroll;
}

#buildDialog .InventoryCell {
  height: 40px;
  width: 40px;
}

.buildStatusBP {
  /*pointer-events: none;*/
  position: absolute;
  min-height: 150px;
  width: 240px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  border-radius: 5px;
  z-index: 120;
  color: #ff8100;
  font-size: 10px;
  padding: 3px;
  filter: drop-shadow(0 0 2px black);
  background: rgba(8, 70, 140, 0.3);
}

.buildStatusBP .structure_head {
  float: left;
  width: calc(100% - 0px);
  margin-bottom: 4px;
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  height: 100px;
}

.buildStatusBP .structure_icon {
  float: left;
  border-radius: 5px;
  background: rgba(159, 159, 159, 0.5);
  border: 1px solid rgba(159, 159, 159, 0.5);
  width: 50px;
  height: 50px;
  margin: 5px 5px 5px 4px;
  position: relative;
}

.buildStatusBP .structure_description {
  min-height: 39px;
  margin: 5px;
  padding: 5px;
  width: calc(100% - 20px);
}

.buildStatusBP h5, .remove_head {
  margin: 1px;
  text-align: left;
  text-indent: 5px;
  color: aliceblue;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
  float: left;
  clear: both;
  width: 100%;
}

.structure_need_items {
  min-height: 30px;
  width: calc(100% - 0px);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: inset 0 0 2px black;
  float: left;
}

.structure_need_items .InventoryCell {
  height: 30px;
  width: 30px;
}

.object_name {
  margin: -4px 0 4px 0;
  background: #dd7034;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  width: calc(100% - 55px);
  text-indent: 5px;
  float: left;
}

.description {
  color: rgba(255, 255, 255, 1);
  text-shadow: 1px 1px 1px black;
  font-size: 11px;
  font-weight: bold;
}

.need_credits {
  background-color: rgba(125, 125, 125, 1);
  padding: 2px;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px black;
  width: calc(47% - 7px);
  height: 12px;
  left: calc(50% - 30px);
  float: left;
  margin: 4px 8px 3px 1px;
}

.credits_icon, .energy_icon {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png')
}

.energy_icon {
  background-image: url('../../assets/icons/low_power_icon_no_back.png')
}

.credits_count {
  background: #066ea8;
  float: right;
  width: calc(100% - 21px);
  padding-right: 4px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
}

.energy {
  height: 14px;
  border: 1px solid #000000;
  width: calc(100% - 7px);
  border-radius: 2px;
  box-shadow: 0 0 2px 0 black;
  overflow: hidden;
  background: #404041;
  position: relative;
  margin: 3px 3px 3px 3px;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px black, 0 1px 1px black, 1px 0 1px black, -1px -1px 1px black, 0 -1px 1px black, -1px 0 1px black;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
}

.current_energy, .tip_energy {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: inset 0 0 2px 0 black;
  width: 50%;
  background-color: #ffe37b;
  transition: 200ms;
}

.tip_energy {
  background-color: rgba(5, 199, 234, 0.9);
  opacity: 0.4;
}

.bar_energy_icon {
  float: left;
  width: 15px;
  height: 15px;
  background-size: contain;
  z-index: 999;
  position: relative;
}

.disable {
  pointer-events: auto !important;
}

.tabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabs div:active {
  transform: scale(0.98);
}

.tabs div:hover {
  background: rgb(235, 98, 44);
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42);
}

.button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  width: 70%;
  position: relative;
  left: calc(50% - 35%);
  user-select: none;
}

.button:hover {
  background: #ff8100;
}

.button:active {
  transform: scale(.98);
}
</style>
