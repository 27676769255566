import {gameStore} from "../store";

function GetItemByTypeAndID(type, id) {
  let item = {};

  if (type === "body") item = gameStore.gameTypes.bodies[id]
  if (type === "weapon") item = gameStore.gameTypes.weapons[id]
  if (type === "equip") item = gameStore.gameTypes.equips[id]
  if (type === "ammo") item = gameStore.gameTypes.ammo[id]
  if (type === "recycle") item = gameStore.gameTypes.resource[id]
  if (type === "resource") item = gameStore.gameTypes.ore[id]
  if (type === "detail") item = gameStore.gameTypes.details[id]
  if (type === "boxes") item = gameStore.gameTypes.boxes[id]
  if (type === "blueprints") item = gameStore.gameTypes.blueprints[id]
  if (type === "trash") item = gameStore.gameTypes.trash[id]
  if (type === "product") item = gameStore.gameTypes.product[id]
  if (type === "fuel") item = gameStore.gameTypes.fuel[id]

  return item
}

function GetItemByTypeAndName(type, name) {
  let fineName = function (store, name) {
    for (let id in store) {
      if (store[id].name === name) {
        return store[id]
      }
    }
  }

  if (type === "body") return fineName(gameStore.gameTypes.bodies, name);
  if (type === "weapon") return fineName(gameStore.gameTypes.weapons, name);
  if (type === "equip") return fineName(gameStore.gameTypes.equips, name);
  if (type === "ammo") return fineName(gameStore.gameTypes.ammo, name);
  if (type === "recycle") return fineName(gameStore.gameTypes.resource, name);
  if (type === "resource") return fineName(gameStore.gameTypes.ore, name);
  if (type === "detail") return fineName(gameStore.gameTypes.details, name);
  if (type === "boxes") return fineName(gameStore.gameTypes.boxes, name);
  if (type === "blueprints") return fineName(gameStore.gameTypes.blueprints, name);
  if (type === "trash") return fineName(gameStore.gameTypes.trash, name);
  if (type === "product") return fineName(gameStore.gameTypes.product, name);
  if (type === "fuel") return fineName(gameStore.gameTypes.fuel, name);
}

export {GetItemByTypeAndID, GetItemByTypeAndName}
