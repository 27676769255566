import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {RotateWheel, SetBodyAngle} from "./rotate";
import {Scene} from "../create";
import {DrawTrackTrace} from "./track_trace";
import {GetVolume, PlayPositionSound} from "../sound/play_sound";
import {GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {scaleAntigravityEngages} from "./unit";

function AddUnitMoveBufferData(data) {
  let unit = gameStore.units[data.id];
  if (unit) {
    AddMoveBufferData(data, unit)
  }
}

function MoveTo(unit, ms, view) {
  if (!gameStore.units.hasOwnProperty(unit.id)) return;

  let path = unit.bufferMoveTick.shift();
  if (!path) {
    return;
  }

  unit.x = path.x;
  unit.y = path.y;

  let pos = GetGlobalPos(path.x, path.y, gameStore.map.id);
  let size = GetSpriteSizeByMapLvl(path.z, unit.body.scale / 100, 0.02).x;
  MoveSprite(unit.sprite, pos.x, pos.y, ms, size, false, false);
  let needZ = 0;

  let shadowDist = Scene.shadowXOffset * 1.5
  if (path.sky) {
    let shadowPos = GetOffsetShadowByMapLvl(path.z, pos.x, pos.y, 0, 2.5, gameStore.map.id);
    shadowDist = Phaser.Math.Distance.Between(pos.x, pos.y, shadowPos.x, shadowPos.y) * (1 / unit.sprite.scale);
    SetBodyAngle(unit, path.r, ms, true, shadowDist, true);
    needZ = 299
  } else {
    if (unit.body) needZ = (unit.body.height * unit.sprite.scale) + path.z + 1
    SetBodyAngle(unit, path.r, ms, true, shadowDist);
  }

  if (needZ !== unit.sprite.depth) {
    unit.sprite.setDepth(needZ);
    if (unit.sprite.damage) unit.sprite.damage.setDepth(needZ + 1);
  }

  unit.speedDirection = path.d;
  unit.speed = path.s;
  unit.angularVelocity = path.av;
  unit.animateSpeed = path.a;
  unit.rotate = path.r;

  if (unit.body.fraction === "Fauna") {
    unit.speed = unit.speed * 0.5;
  }

  // unit.sprite.setScale(1)
  // unit.sprite.setAngle(0)
  // unit.sprite.damage.setScale(1)
  // unit.sprite.damage.setAngle(0)

  AnimationMove(unit, path.kw, path.ka, path.kd, path.ksp, !view, shadowDist);
}

let engagesSound = {
  MasherShip_1: "engine_7",
  MasherShip_2: "engine_7",
  MasherShip_3: "engine_7",
  // replic
  replic_start_body: "engine_7",
  replic_miner: "engine_7",
  replic_builder_body: "engine_7",
  replic_scout: "engine_7",
  replic_pirat: "engine_7",
  replic_warrior: "engine_7",
  replic_transport: "engine_13",
  replic_transport_2: "engine_13",
  // explores
  explores_start_body: "engine_13",
  explores_miner: "engine_13",
  explores_builder_body: "engine_13",
  explores_scout: "engine_13",
  explores_pirat: "engine_13",
  explores_warrior: "engine_13",
  explores_transport: "engine_13",
  explores_transport_2: "engine_13",
  // reverse
  reverses_start_body: "engine_14",
  reverses_miner: "engine_14",
  reverses_builder_body: "engine_14",
  reverses_scout: "engine_14",
  reverses_pirat: "engine_14",
  reverses_warrior: "engine_14",
  reverses_transport: "engine_14",
  reverses_transport_2: "engine_14",
  // apd
  apd_light: "engine_7",
  apd_medium: "engine_7",
  apd_heavy: "engine_7",
  apd_super_heavy_1: "engine_7",
  apd_super_heavy_2: "engine_7",
};

function AnimationMove(unit, w, a, d, space, stop, shadowDist) {

  if (stop) {
    if (unit.sprite.visible) {

      unit.sprite.setVisible(false)
      if (unit.sprite.bodyBottom) unit.sprite.bodyBottom.setVisible(false)
      if (unit.sprite.bodyBottomRight) unit.sprite.bodyBottomRight.setVisible(false)
      if (unit.sprite.bodyBottomLeft) unit.sprite.bodyBottomLeft.setVisible(false)
      if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.setVisible(false)
      if (unit.sprite.shadow) unit.sprite.shadow.setVisible(false)
      if (unit.sprite.bodyBottomLeftShadow) unit.sprite.bodyBottomLeftShadow.setVisible(false)
      if (unit.sprite.bodyBottomRightShadow) unit.sprite.bodyBottomRightShadow.setVisible(false)
      //if (unit.sprite.mask) unit.sprite.mask.setVisible(false)
      for (let i in unit.weapons) {
        unit.weapons[i].weapon.setVisible(false)
      }

      if (unit.sprite.backEngine) unit.sprite.backEngine.stop()
      if (unit.sprite.rightEngine) unit.sprite.rightEngine.stop()
      if (unit.sprite.leftEngine) unit.sprite.leftEngine.stop()
      if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.stop()
    }
    return;
  } else {
    if (!unit.sprite.visible) {

      unit.sprite.setVisible(true)
      if (unit.sprite.bodyBottom) unit.sprite.bodyBottom.setVisible(true)
      if (unit.sprite.bodyBottomRight) unit.sprite.bodyBottomRight.setVisible(true)
      if (unit.sprite.bodyBottomLeft) unit.sprite.bodyBottomLeft.setVisible(true)
      if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.setVisible(true)
      if (unit.sprite.shadow) unit.sprite.shadow.setVisible(true)
      if (unit.sprite.bodyBottomLeftShadow) unit.sprite.bodyBottomLeftShadow.setVisible(true)
      if (unit.sprite.bodyBottomRightShadow) unit.sprite.bodyBottomRightShadow.setVisible(true)
      //if (unit.sprite.mask) unit.sprite.mask.setVisible(true)
      for (let i in unit.weapons) {
        unit.weapons[i].weapon.setVisible(true)
      }

      if (unit.sprite.backEngine) unit.sprite.backEngine.start()
      if (unit.sprite.rightEngine) unit.sprite.rightEngine.start()
      if (unit.sprite.leftEngine) unit.sprite.leftEngine.start()
      if (unit.sprite.antigravityPlace) unit.sprite.antigravityPlace.start()
    }
  }

  if (unit.body.chassis_type === "wheels") {
    wheelsAnimate(unit, a, d, space, stop, shadowDist)
  }

  if (unit.body.chassis_type === "caterpillars") {
    caterpillarsAnimate(unit, space, stop)
  }

  if (unit.body.chassis_type === "antigravity") {
    antigravityAnimate(unit, w, a, d)
  }
}

function antigravityAnimate(unit, w, a, d) {

  if (!unit.antigravityBack) {
    unit.antigravityBack = PlayPositionSound(['antigravity_engine'], {
      loop: true,
      volume: 0
    }, unit.sprite.x, unit.sprite.y, undefined, 0.1);
  } else {

    let newVolume = GetVolume(unit.sprite.x, unit.sprite.y, 0.1);
    if (unit.antigravityBack.volume.toFixed(2) !== newVolume.toFixed(2)) {
      unit.antigravityBack.setVolume(newVolume);
    }

    if (!unit.antigravityBack.isPlaying) {
      unit.antigravityBack.resume()
    }
  }

  if (w && !unit.sprite.w) {
    // unit.sprite.backEngine.setSpeed({min: 140, max: 200})
    unit.sprite.backEngine.ops.speedX.start = 140;
    unit.sprite.backEngine.ops.speedX.end = 200;
    unit.sprite.backEngine.ops.speedY.start = 140;
    unit.sprite.backEngine.ops.speedY.end = 200;

    // unit.sprite.backEngine.setScale({start: scaleAntigravityEngages.start * 2, end: scaleAntigravityEngages.end * 2})
    unit.sprite.backEngine.ops.scaleX.start = scaleAntigravityEngages.start * 2;
    unit.sprite.backEngine.ops.scaleX.end = scaleAntigravityEngages.end * 2;
  }

  if (!w && unit.sprite.w) {
    // unit.sprite.backEngine.setSpeed({min: 40, max: 60})
    unit.sprite.backEngine.ops.speedX.start = 40;
    unit.sprite.backEngine.ops.speedX.end = 60;
    unit.sprite.backEngine.ops.speedY.start = 40;
    unit.sprite.backEngine.ops.speedY.end = 60;

    // unit.sprite.backEngine.setScale(scaleAntigravityEngages)
    unit.sprite.backEngine.ops.scaleX.start = scaleAntigravityEngages.start;
    unit.sprite.backEngine.ops.scaleX.end = scaleAntigravityEngages.end;
  }

  if (a && !unit.sprite.a) {
    // unit.sprite.rightEngine.setSpeed({min: 140, max: 200})
    unit.sprite.rightEngine.ops.speedX.start = 140;
    unit.sprite.rightEngine.ops.speedX.end = 200;
    unit.sprite.rightEngine.ops.speedY.start = 140;
    unit.sprite.rightEngine.ops.speedY.end = 200;

    // unit.sprite.rightEngine.setScale({start: scaleAntigravityEngages.start * 2, end: scaleAntigravityEngages.end * 2})
    unit.sprite.rightEngine.ops.scaleX.start = scaleAntigravityEngages.start * 2;
    unit.sprite.rightEngine.ops.scaleX.end = scaleAntigravityEngages.end * 2;
  }

  if (!a && unit.sprite.a) {
    // unit.sprite.rightEngine.setSpeed({min: 40, max: 60})
    unit.sprite.rightEngine.ops.speedX.start = 40;
    unit.sprite.rightEngine.ops.speedX.end = 60;
    unit.sprite.rightEngine.ops.speedY.start = 40;
    unit.sprite.rightEngine.ops.speedY.end = 60;

    // unit.sprite.rightEngine.setScale(scaleAntigravityEngages)
    unit.sprite.rightEngine.ops.scaleX.start = scaleAntigravityEngages.start;
    unit.sprite.rightEngine.ops.scaleX.end = scaleAntigravityEngages.end;
  }

  if (d && !unit.sprite.d) {
    // unit.sprite.leftEngine.setSpeed({min: 140, max: 200})
    unit.sprite.leftEngine.ops.speedX.start = 140;
    unit.sprite.leftEngine.ops.speedX.end = 200;
    unit.sprite.leftEngine.ops.speedY.start = 140;
    unit.sprite.leftEngine.ops.speedY.end = 200;

    // unit.sprite.leftEngine.setScale({start: scaleAntigravityEngages.start * 2, end: scaleAntigravityEngages.end * 2})
    unit.sprite.leftEngine.ops.scaleX.start = scaleAntigravityEngages.start * 2;
    unit.sprite.leftEngine.ops.scaleX.end = scaleAntigravityEngages.end * 2;
  }

  if (!d && unit.sprite.d) {
    // unit.sprite.leftEngine.setSpeed({min: 40, max: 60})
    unit.sprite.leftEngine.ops.speedX.start = 40;
    unit.sprite.leftEngine.ops.speedX.end = 60;
    unit.sprite.leftEngine.ops.speedY.start = 40;
    unit.sprite.leftEngine.ops.speedY.end = 60;

    // unit.sprite.leftEngine.setScale(scaleAntigravityEngages)
    unit.sprite.leftEngine.ops.scaleX.start = scaleAntigravityEngages.start;
    unit.sprite.leftEngine.ops.scaleX.end = scaleAntigravityEngages.end;
  }

  unit.sprite.w = w
  unit.sprite.a = a
  unit.sprite.d = d

  soundEngine(unit)
}

function stopWheels(unit) {
  if (unit.engigeSound && unit.engigeSound.isPlaying) {
    unit.engigeSound.pause();
  }

  if (unit.sprite.bodyBottomLeft.anims.isPlaying) {
    unit.sprite.bodyBottomLeft.anims.pause();
    unit.sprite.bodyBottomRight.anims.pause();
    unit.sprite.bodyBottom.anims.pause();
  }
}

function wheelsAnimate(unit, a, d, space, stop) {

  if (stop) {
    stopWheels(unit)
    return
  }

  let wheelAngle = 20
  if (unit.position_data.turn_speed < 0.0225) {
    wheelAngle = 10
  }

  if (space) {
    DrawTrackTrace(unit)
  }

  if (a) {
    RotateWheel(unit.sprite.angle - wheelAngle, unit.sprite.bodyBottomLeft)
    RotateWheel(unit.sprite.angle - wheelAngle, unit.sprite.bodyBottomRight)
  } else if (d) {
    RotateWheel(unit.sprite.angle + wheelAngle, unit.sprite.bodyBottomLeft)
    RotateWheel(unit.sprite.angle + wheelAngle, unit.sprite.bodyBottomRight)
  } else {
    RotateWheel(unit.sprite.angle, unit.sprite.bodyBottomLeft)
    RotateWheel(unit.sprite.angle, unit.sprite.bodyBottomRight)
  }

  if (unit.speed && unit.speed > 0 && unit.animateSpeed) {

    if (!unit.sprite.bodyBottomLeft.anims.isPlaying) {
      unit.sprite.bodyBottomLeft.anims.resume();
      unit.sprite.bodyBottomRight.anims.resume();
      unit.sprite.bodyBottom.anims.resume();
    }

    if (unit.speedDirection && !unit.sprite.bodyBottomLeft.anims.forward) unit.sprite.bodyBottomLeft.anims.reverse()
    if (!unit.speedDirection && unit.sprite.bodyBottomLeft.anims.forward) unit.sprite.bodyBottomLeft.anims.reverse()
    if (unit.speedDirection && !unit.sprite.bodyBottomRight.anims.forward) unit.sprite.bodyBottomRight.anims.reverse()
    if (!unit.speedDirection && unit.sprite.bodyBottomRight.anims.forward) unit.sprite.bodyBottomRight.anims.reverse()
    if (unit.speedDirection && !unit.sprite.bodyBottom.anims.forward) unit.sprite.bodyBottom.anims.reverse()
    if (!unit.speedDirection && unit.sprite.bodyBottom.anims.forward) unit.sprite.bodyBottom.anims.reverse()

    speedBody(unit.speed, unit.sprite.bodyBottom.anims);
    speedBody(unit.speed, unit.sprite.bodyBottomLeft.anims);
    speedBody(unit.speed, unit.sprite.bodyBottomRight.anims);

    soundEngine(unit)

    DrawTrackTrace(unit)
  } else {
    stopWheels(unit)
  }
}

function stopCaterpillars(unit) {
  if (unit.engigeSound && unit.engigeSound.isPlaying) {
    unit.engigeSound.pause();
  }

  if (unit.sprite.bodyBottomLeft.anims.isPlaying) {
    unit.sprite.bodyBottomLeft.anims.pause();
    unit.sprite.bodyBottomRight.anims.pause();
  }
}

function caterpillarsAnimate(unit, space, stop) {

  if (stop) {
    stopCaterpillars(unit);
    return
  }

  if (space) {
    DrawTrackTrace(unit);
  }

  if (unit.speed && unit.speed > 0 && unit.animateSpeed) {

    if (!unit.sprite.bodyBottomLeft.anims.isPlaying) {
      unit.sprite.bodyBottomLeft.anims.resume();
      unit.sprite.bodyBottomRight.anims.resume();
    }

    // направление гусей
    if (unit.speedDirection && !unit.sprite.bodyBottomLeft.anims.forward) {
      unit.sprite.bodyBottomLeft.anims.reverse()
    }
    if (!unit.speedDirection && unit.sprite.bodyBottomLeft.anims.forward) {
      unit.sprite.bodyBottomLeft.anims.reverse()
    }

    if (unit.speedDirection && !unit.sprite.bodyBottomRight.anims.forward) {
      unit.sprite.bodyBottomRight.anims.reverse()
    }
    if (!unit.speedDirection && unit.sprite.bodyBottomRight.anims.forward) {
      unit.sprite.bodyBottomRight.anims.reverse()
    }

    speedBody(unit.speed, unit.sprite.bodyBottomLeft.anims);
    speedBody(unit.speed, unit.sprite.bodyBottomRight.anims);
    DrawTrackTrace(unit)
    soundEngine(unit)

  } else {
    if (unit.angularVelocity !== 0) {

      if (!unit.sprite.bodyBottomLeft.anims.isPlaying) {
        unit.sprite.bodyBottomLeft.anims.resume();
        unit.sprite.bodyBottomRight.anims.resume();
      }

      if (unit.angularVelocity > 0) {
        if (!unit.sprite.bodyBottomLeft.anims.forward) {
          unit.sprite.bodyBottomLeft.anims.reverse()
        }
        if (unit.sprite.bodyBottomRight.anims.forward) {
          unit.sprite.bodyBottomRight.anims.reverse()
        }
      } else {
        if (unit.sprite.bodyBottomLeft.anims.forward) {
          unit.sprite.bodyBottomLeft.anims.reverse()
        }
        if (!unit.sprite.bodyBottomRight.anims.forward) {
          unit.sprite.bodyBottomRight.anims.reverse()
        }
      }

      speedBody(unit.angularVelocity, unit.sprite.bodyBottomLeft.anims);
      speedBody(unit.angularVelocity, unit.sprite.bodyBottomRight.anims);
      DrawTrackTrace(unit)
      soundEngine(unit, (unit.body.attributes.max_speed / 60) / 2) // поворот

    } else {
      stopCaterpillars(unit)
    }
  }
}

function speedBody(speed, anim) {

  if (speed < 0) {
    speed = speed * -1;
  }

  let ms = 110;

  if (speed < 10) {
    ms = 110
  } else if (speed >= 10 && speed < 15) {
    ms = 105
  } else if (speed >= 15 && speed < 20) {
    ms = 100
  } else if (speed >= 20 && speed < 25) {
    ms = 95
  } else if (speed >= 25 && speed < 30) {
    ms = 90
  } else if (speed >= 30 && speed < 40) {
    ms = 85
  } else if (speed >= 40 && speed < 50) {
    ms = 80
  } else if (speed >= 50 && speed < 60) {
    ms = 75
  } else if (speed >= 60 && speed < 70) {
    ms = 70
  } else if (speed >= 70 && speed < 80) {
    ms = 65
  } else if (speed >= 80 && speed < 90) {
    ms = 40
  } else if (speed >= 90 && speed < 100) {
    ms = 35
  } else if (speed >= 100 && speed < 110) {
    ms = 30
  } else if (speed >= 110 && speed < 120) {
    ms = 25
  } else if (speed >= 120 && speed < 130) {
    ms = 20
  } else if (speed >= 130 && speed < 140) {
    ms = 15
  } else if (speed >= 140) {
    ms = 10
  }

  if (anim) anim.msPerFrame = ms
  return ms
}

function soundEngine(unit, forceSpeed) {
  if (!unit.engigeSound) {

    let s = engagesSound[unit.body.name]
    if (s) {
      unit.engigeSound = PlayPositionSound([engagesSound[unit.body.name]], {
        loop: true,
        volume: 0
      }, unit.sprite.x, unit.sprite.y);
    }

  } else {
    let maxSpeed = unit.body.attributes.max_speed / 60 // не совсем верные данные, но приемлемые
    let speed = unit.speed
    if (forceSpeed) speed = forceSpeed

    let volume = GetVolume(unit.sprite.x, unit.sprite.y);
    let percent = (speed / maxSpeed) * 100;

    let newVolume = (percent / 100) * volume
    if (unit.engigeSound.volume.toFixed(2) !== newVolume.toFixed(2)) {
      unit.engigeSound.setVolume(newVolume);
    }

    if (!unit.engigeSound.isPlaying) {
      unit.engigeSound.resume()
    }
  }
}

export {MoveTo, AnimationMove, AddUnitMoveBufferData}
