import router from "../../router/router";
import systemRead from "./readers/system";
import chatRead from "./readers/chat";
import inventoryRead from "./readers/inventory";
import lobbyRead from "./readers/lobby";
import marketRead from "./readers/market";
import globalRead from "./readers/global";
import mapEditorRead from "./readers/map_editor";
import dialogEditorRead from "./readers/dialog_editor";
import {debugMessage} from "../../game/debug/debug";
import corporationRead from "./readers/corporation";
import {gameStore} from "../../game/store";
import {AddMessagesQueue} from "../../game/messages_queue";
import {logArrayMsgEvent} from "./log";
import {BinaryReader} from "./readers/binary_read";
import store from "../store";

export default function createSocketPlugin(worker, type, closeToLogin, uuid) {
  worker.addEventListener("message", (event) => { // TODO этот метод блокируется по какой то причине если цпу клиента работает на 100% и зажата любая клавиша (срабатывает key events)
    let msg = event.data;
    if (msg.msgType === 'system') {
      msg.data.type = type
      msg.data.uuid = uuid
      msg.data.ws = worker
      store.commit(msg.data)

      if (msg.data.error) {
        if (closeToLogin && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
          router.push('/login');
        }
      }
    }

    if (msg.msgType === 'bin_game') {
      if (gameStore.exitTab) {
        return;
      }

      if (gameStore.gameReady) {
        AddMessagesQueue(msg.data);
        logArrayMsgEvent(msg.data[0], msg.data)
      } else {
        BinaryReader(msg.data, store)
        logArrayMsgEvent(msg.data[0], msg.data)
      }
    }

    if (msg.msgType === 'json_game') {
      Reader(msg.data, store)
    }
  }, {passive: true});
}

function Reader(msg, store) {

  // if (msg.event) logMsg(msg.event, msg);
  // if (msg.e) logMsg(msg.e, msg);

  if (msg.service === 'global') globalRead(msg, store);
  if (msg.service === 'system') systemRead(msg, store);
  if (msg.service === 'market') marketRead(msg, store);
  if (msg.service === 'lobby') lobbyRead(msg, store);
  if (msg.service === 'inventory') inventoryRead(msg, store);
  if (msg.service === 'chat') chatRead(msg, store);
  if (msg.service === 'corporation') corporationRead(msg, store);

  if (msg.service === 'map_editor') mapEditorRead(msg, store);
  if (msg.service === 'dialog_editor') dialogEditorRead(msg, store);
  if (msg.service === 'debug') debugMessage(msg.data, store);
  if (msg.service === 'error') {
    store.commit({
      type: 'AddError',
      error: msg.error,
      active: true,
      data: msg.data,
    });
  }

  if (!msg.service) {
    console.log("unknown service: ", msg)
  }
}
