import {VueEx} from "../../../main";

export default function marketRead(msg, store) {
  if (msg.event === 'GetCredits') {
    store.commit({
      type: 'setCredits',
      my_credits: msg.data,
    });
  }

  if (msg.event === "GetAssortment") {
    store.commit({
      type: 'setMarketAssortment',
      assortment: msg.data,
    });
  }

  if (msg.event === "GetOrders") {
    store.commit({
      type: 'setMarketState',
      orders: msg.data.orders,
      my_orders: msg.data.my_orders,
      orders_distance: msg.data.orders_distance,
      preloader: false,
    });
  }

  if (msg.event === "GetItemsByFilterName") {
    store.commit({
      type: 'setMarketItemsByFilterName',
      filterItems: msg.data,
    });
  }

  if (msg.event === "NewMyOrder") {
    store.commit({
      type: 'addMyOrder', orderID: msg.data,
    });
  }

  if (msg.event === "NewOrder") {
    store.commit({
      type: 'addOrder',
      order: msg.data.order,
      distance: msg.data.distance,
    });
  }

  if (msg.event === "CancelOrder") {
    store.commit({
      type: 'removeOrder', id: msg.data,
    });
  }

  if (msg.event === "DealOrders") {
    store.commit({
      type: 'updateOrders',
      orders: msg.data.orders,
      orders_distance: msg.data.orders_distance,
    });
  }

  if (msg.event === "GetItemsInStorage") {
    console.log(msg)
    store.commit({
      type: 'setCountItems',
      id: msg.data.id,
      count: msg.data.count,
      fraction: msg.data.fraction,
    });
  }

  if (msg.event === "GetSkins") {
    store.commit({
      type: 'setSkins',
      data: msg.data,
    });
  }

  if (msg.event === "ra") {
    store.commit('removeAvatar', {
      id: Number(msg.data),
    });

    store.dispatch('getAvatar', {
      userID: Number(msg.data), vm: VueEx
    });
  }

  if (msg.event === "GetHistory") {
    store.commit({
      type: 'setHistory',
      history: msg.data,
    });
  }

  if (msg.event === "GetBaseTax") {
    store.commit({
      type: 'SetBaseMarketTax',
      base_id: msg.data.base_id,
      tax: msg.data.tax,
    });
  }
}
