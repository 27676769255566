<template>
  <div id="SelectTarget" ref="SelectTarget" v-if="targetData">

    <div v-if="targetData.type !== 'object'"
         class="collapse_button cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @mousedown="changeShow()"
         :title="texts['title_1'][language]"
         :style="{backgroundImage: 'url(' + require('../../assets/icons/chat.png').default + ')'}">
    </div>

    <div class="owner_line">
      <div class="head_row">
        <div v-if="owner">
          <app-user-line :user="owner"
                         :key="owner.id"
                         :parent="'SelectTarget'"
                         :fraction="targetData.fraction"
                         :corporation="targetData.corporationID"
                         :hideName="targetData.fraction === 'Fauna'"
          />
        </div>
        <div v-if="!owner">
          <app-user-line :user="{login: texts['chanel_unknown'][language], id: 0}"
                         :key="0"
                         :parent="'SelectTarget'"
                         :fraction="targetData.fraction"
                         :corporation="targetData.corporationID"
                         :hideName="true"
          />
        </div>
      </div>

      <div class="head_row" style="float: right">
        <app-corporation-line v-if="corporation && corporation.corporation && corporation.logo"
                              :corp="corporation.corporation"
                              :logo="corporation.logo"
                              :parent="'SelectTarget'"/>
        <app-corporation-line v-else
                              :empty="true"
                              :parent="'SelectTarget'"/>
      </div>
    </div>

    <template
      v-if="targetData.type !== 'object' && settings.ShowNpcDialog && targetData.owner_id !== currentPlayer.id && history && history.length > 0 && history[0].text[language] !== ''">

      <div class="dialog" v-if="targetData.owner_id < 0 && history && history.length > 0">
        <div class="dialog_wrap">
          <div v-for="msg in history" class="dialog_row"
               :class="{my: msg.my, success: msg.success && msg.success === 1, nosuccess: msg.success && msg.success === -1}"
               v-html="msg.text[language]"/>
        </div>
      </div>

      <div class="asks">
        <div class="asc cursor_hover"
             v-for="asc in ascs"
             :class="{disable: disable, request: asc.hasOwnProperty('uuid')}"
             v-html="asc.text[language]"
             @click="processAction(asc)"
        />

        <div class="preloader_asks" v-if="disable || ascs.length === 0">
          <app-preloader size="20px"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import UserLine from '../Chat/UserLine';
import {urls} from "../../const";
import Vue from "vue";
import Preloader from "../Preloader/Preloader";
import CorporationLine from '../Corporation/CorporationLine';
import {gameStore} from "../../game/store";

export default {
  name: "SelectTarget",
  data() {
    return {
      owner: null,
      corporation: null,
      disable: false,
      updater: null,
      subAscs: {
        type: "",
        prefix: "",
        action: ""
      },
      ascs: [],
      dialogID: 0,
      missionAscs: [],
      oldAscs: "",
      init: false,
      oldData: false,
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater);
  },
  mounted() {
    this.ascUpdater();
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    close() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "SelectUnit",
          close: true,
        },
      }))
    },
    changeShow() {
      let show = this.settings.ShowNpcDialog
      this.$store.dispatch('changeSettings', {
        name: "ShowNpcDialog",
        count: !show
      });

      this.$store.commit({
        type: 'setShowNpcDialog',
        ShowNpcDialog: !show,
      });

      if (!show) {
        this.ascUpdater();
      }
    },
    getOwnerData(id) {
      let app = this
      app.$api.get(urls.userInfoURL + '?user_id=' + id).then(function (response) {
        Vue.set(app, 'owner', response.data);
      });
    },
    getCorporation(id) {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        if (response.data) {
          app.$set(app, 'corporation', response.data);
          app.getCorpLogo();
        }
      });
    },
    getCorpLogo() {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + app.corporation.corporation.id).then(function (response) {
        app.$set(app.corporation, 'logo', response.data.avatar);
      });
    },
    getNPCGreetings(npcID, newDialog = true) {
      let app = this;
      app.$api.get(urls.getNPCGreetings + '?npc_id=' + npcID + '&to_id=' + app.currentPlayer.id).then(function (response) {
        app.$store.commit({
          type: 'addMsgInTargetData',
          data: {new: newDialog, my: false, text: response.data.greeting},
        });
        app.$set(app, 'missionAscs', response.data.additionalAsks);
        app.$set(app, 'dialogID', response.data.dialogID);
        app.$set(app, 'init', true);
      }, function () {
        app.$set(app, 'init', true);
      });
    },
    processAction(asc) {

      if (asc.hasOwnProperty('to_page')) {
        let app = this;

        app.$store.commit({
          type: 'addMsgInTargetData',
          data: {new: false, my: true, text: asc.text},
        });

        app.$api.get(urls.npcProcessAsk + '?npc_id=' + app.targetData.owner_id + '&to_id=' + app.currentPlayer.id + '&ask_id=' + asc.id + '&dialog_id=' + app.dialogID).then(function (response) {

          app.disable = false;
          if (!response.data.text || !response.data.ascs) {
            app.close()
          }

          app.$store.commit({
            type: 'addMsgInTargetData',
            data: {new: false, my: false, text: response.data.text},
          });
          app.$set(app, 'missionAscs', response.data.ascs);
        });

        app.disable = true;
        return;
      }

      if (asc.subAscs) {
        this.subAscs.type = asc.subAscs
        this.subAscs.action = asc.action
        this.subAscs.prefix = asc.subPrefix
        this.getAscs()
        return
      }

      if (asc.back) {
        this.subAscs.type = "";
        this.getAscs();
      }

      this.disable = true;
      setTimeout(function () {
        this.disable = false;
      }.bind(this), 1200)

      if (asc.hasOwnProperty("uuid")) {
        this.npcDialogAnswer(asc.uuid, asc.id)
        this.$store.commit({
          type: 'addAscsInTargetData',
          ascs: [],
          uuid: "",
        });
      } else {
        this.sendRequest(this.targetData.owner_id, asc.target, asc.action, asc.credits)
      }
    },
    sendRequest(ownerID, targetID, type, count) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "npc_request",
          id: ownerID, // кому отправляем запрос
          owner_id: targetID, // если запрос напасть на цель или отстать от цели, то это ид цели
          type: type, // тип запроса
          count: count, // например кол-во кредитов
        }
      }));
    },
    npcDialogAnswer(uuid, answerID) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "npc_request_answer",
          uuid: uuid,
          id: Number(answerID),
        }
      }));

      this.deleteNotify(uuid)
    },
    deleteNotify(uuid) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "DeleteNotify",
          data: uuid,
        }
      }));
    },
    getVisiblePlayersAcks() {
      let visiblePlayersAcks = [];
      for (let i in gameStore.units) {
        let u = gameStore.units[i];

        if (u && u.sprite && i !== gameStore.playerUnitID && u.fraction !== 5 &&
          u.owner_id !== this.targetData.owner_id && u.owner_id !== this.currentPlayer.id) {

          visiblePlayersAcks.push({
            action: this.subAscs.action,
            target: u.owner_id,
            back: true,
            text:
              {
                RU: this.subAscs.prefix + `<span style="font-weight: normal" class="importantly">${u.owner}</span>`,
                EN: this.subAscs.prefix + `<span style="font-weight: normal" class="importantly">${u.owner}</span>`,
              },
          })
        }
      }

      visiblePlayersAcks.push({
        action: "",
        back: true,
        text:
          {RU: "Назад", EN: "Back"},
      })

      visiblePlayersAcks.sort(function (a, b) {
        return a.target - b.target
      })

      return visiblePlayersAcks
    },
    getCreditsAcks() {
      let creditsAcks = [];
      for (let i of [100, 300, 500, 1000, 3000, 5000]) {
        creditsAcks.push({
          action: this.subAscs.action,
          credits: i,
          back: true,
          text:
            {
              RU: this.subAscs.prefix + `<span style="font-weight: normal" class="importantly">${(i / 100).toFixed(2)}</span> cr.`,
              EN: this.subAscs.prefix + `<span style="font-weight: normal" class="importantly">${(i / 100).toFixed(2)}</span> cr.`,
            },
        })
      }

      creditsAcks.push({
        action: "back",
        text:
          {RU: "Назад", EN: "Back"},
      })

      creditsAcks.sort(function (a, b) {
        return a.credits - b.credits
      })

      return creditsAcks
    },
    getDefaultAscs() {
      let ascs = [];

      if (this.targetData.relation > 30) {
        ascs.push({
          action: "make_friends",
          text:
            {RU: "Давай помиримся", EN: "Let's make it up"},
        })
      }

      if (this.targetData.typeTarget === "unit" && this.targetData.idTarget !== gameStore.playerUnitID) {
        let u = gameStore.units[this.targetData.idTarget];
        if (u) {
          ascs.push({
            action: "leave_alone",
            text: {
              RU: `Отстань от <span style="font-weight: normal" class="importantly">${u.owner}</span>`,
              EN: `Leave <span style="font-weight: normal" class="importantly">${u.owner}</span> alone`
            },
            target: u.owner_id
          })
        }
      }

      if (this.getVisiblePlayersAcks().length > 1) {
        ascs.push({
          action: "attack_target",
          subAscs: "players",
          subPrefix: `... `,
          text:
            {RU: "Нападем на ...", EN: "Let's attack ..."},
        })
      }

      ascs.push({
        action: "drop_cargo",
        text:
          {RU: "Вытряхивай трюм", EN: "Shake out the hold"},
      })

      ascs.push({
        action: "robbery",
        subAscs: "credits",
        subPrefix: `... `,
        text:
          {RU: "Ограбить ...", EN: "Rob ..."},
      })

      return ascs
    },
    ascUpdater() {
      this.getAscs()
      if (this.updater) clearInterval(this.updater);
      this.updater = setInterval(function () {

        if (!this.settings.ShowNpcDialog) clearInterval(this.updater);

        this.getAscs()
      }.bind(this), 1000)
    },
    convertNotifyToAscs(data, uuid) {
      let ascs = []
      for (let i of data) {

        let asc = {
          text: {},
          uuid: uuid,
        }

        for (let lang in i) {
          asc.text[lang] = i[lang].text;
          asc.id = i[lang].id;
        }

        ascs.push(asc)
      }

      return ascs
    },
    getAscs() {

      if (!this.init) return;

      let check = function () {
        let jsonAscs = JSON.stringify(this.ascs)
        if (this.oldAscs !== jsonAscs) {
          this.oldAscs = jsonAscs
          this.disable = true;
          setTimeout(function () {
            this.disable = false;
          }.bind(this), 300)
        }
      }.bind(this)

      // если есть диалог из миссии
      if (this.missionAscs && this.missionAscs.length > 0) {
        this.ascs = this.missionAscs
        check()
        return
      }

      // если есть ответы из нотифая то они в первом приоритете
      if (this.targetData.overload_ascs_uuid !== "" && this.targetData.overload_ascs && this.targetData.overload_ascs.length > 0) {
        this.ascs = this.convertNotifyToAscs(this.targetData.overload_ascs, this.targetData.overload_ascs_uuid)
        check()
        return
      }

      // если мы провалились во вложеность то они во втором приоритете
      if (this.subAscs.type === "players") {
        this.ascs = this.getVisiblePlayersAcks()
        check()
        return
      }

      if (this.subAscs.type === "credits") {
        this.ascs = this.getCreditsAcks()
        check()
        return
      }

      // по умолчанию если нет ничего другово
      this.ascs = this.getDefaultAscs();
      check()
    }
  },
  computed: {
    targetData() {

      let data = this.$store.getters.getTargetData
      // data = {
      //   owner_id: 12,
      //   corporationID: 19,
      //   fraction: "Replics",
      //   // typeTarget:
      //   // idTarget:
      //   // relation:
      // }

      if (!this.owner || this.owner.id !== data.owner_id) {
        // если мы сюда попали то надо удалять старые варианты ответов если они есть и отдавать дефолтные
        this.subAscs.type = "";
        this.getOwnerData(data.owner_id);
      }

      if (!this.corporation && data.corporationID !== 0) this.getCorporation(data.corporationID);
      if (!data.history || data.history.length === 0) {
        this.getNPCGreetings(data.owner_id)
      }

      if (data.overload_ascs_uuid !== "" && data.overload_ascs.length > 0) {
        this.ascs = this.convertNotifyToAscs(data.overload_ascs, data.overload_ascs_uuid)
      }

      if (this.oldData && this.oldData.fear === 0 && data.fear > 0) {
        // если цель не боялась, а теперь начала боятся - то запрашиваем еще раз приветсвие
        this.getNPCGreetings(data.owner_id, false)
      }

      if (this.oldData && data.fear === 0 && this.oldData.typeTarget === "unit" && this.oldData.idTarget === gameStore.playerUnitID &&
        (this.oldData.typeTarget !== data.typeTarget || this.oldData.idTarget !== data.idTarget)) {
        // если цель не имела цели или целью был не игрок, а теперь игрок - то запрашиваем еще раз приветсвие
        this.getNPCGreetings(data.owner_id, false)
      }

      this.oldData = data
      return data
    },
    history() {
      return this.targetData.history
    },
    settings() {
      return this.$store.getters.getSettings
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('SelectTarget')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
  },
  components: {
    AppUserLine: UserLine,
    AppPreloader: Preloader,
    AppCorporationLine: CorporationLine,
  }
}
</script>

<style scoped>
#SelectTarget {
  position: fixed;
  display: block;
  border-radius: 5px;
  z-index: 11;
  width: 250px;
  padding: 1px 1px 1px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  top: 15px;
  min-height: 0;
  right: 270px;
  pointer-events: auto;
}

.owner_line {
  height: 25px;
}

.asks {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 0 0 5px 5px;
  text-align: justify;
  margin: auto auto 0 0;
  width: calc(100% - 10px);
  height: 47px;
  box-shadow: inset 0 0 3px black;
  text-indent: 1.5em;
  overflow-y: scroll;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.preloader {
  transform: scale(0.5);
  margin-top: -6px;
  float: left;
  margin-left: calc(50% - 21px);
}

.preloader_asks {
  position: absolute;
  top: calc(50% - 21px);
  left: calc(50% - 21px);
}

.head_row {
  float: left;
  width: 49%;
}

.asc {
  border-radius: 3px;
  margin-bottom: 2px;
  background-color: rgba(0, 124, 255, 0.4);
  font-size: 11px;
  font-weight: 900;
  text-align: left;
  box-shadow: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-indent: 10px;
}

.asc:hover {
  background-color: rgba(18, 194, 246, 0.5);
}

.asc:active {
  transform: scale(0.98);
}

.dialog {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 5px 5px 0 0;
  /*text-align: justify;*/
  margin: auto auto 2px 0;
  width: calc(100% - 10px);
  height: 105px;
  box-shadow: inset 0 0 3px black;
  text-indent: 1.5em;
  overflow-y: scroll;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  margin-top: 2px;
  display: flex;
  flex-direction: column-reverse;
}

.dialog_row {
  width: 70%;
  float: left;
  margin: 2px;
  background: rgba(81, 159, 203, 0.3);
  border-radius: 4px;
  padding: 1px 4px;
  text-indent: 5px;
  opacity: 0;
  animation: fadeInFromNone 0.5s ease-out;
  animation-fill-mode: forwards;
  font-size: 12px;
}

@keyframes fadeInFromNone {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog_row.my {
  float: right;
  background: rgba(127, 127, 127, 0.5);
}

.asc:last-child, .dialog_row:last-child {
  margin-bottom: 0;
}

.collapse_button {
  height: 20px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  line-height: 20px;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: calc(100% + 1px);
  top: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.collapse_button:hover {
  background-color: rgb(33, 176, 255);
}

.disable {
  opacity: 0.5;
}

.request {
  background: rgba(255, 129, 0, 0.75);
}

.request:hover {
  background: rgba(255, 129, 0, 1);
}

.dialog_row.nosuccess {
  background: rgba(236, 122, 122, 0.5);
}

.dialog_row.success {
  background: rgba(100, 234, 86, 0.5);
}

</style>
<style>
.dialog_row p {
  margin: 5px 0;
}
</style>
