<template>
  <section>
    <div id="features-container">
      <div class="feature">
        <div class="features-icon"></div>
        <span></span>
      </div>
      <div class="feature">
        <div class="features-icon"></div>
        <span></span>
      </div>
      <div class="feature">
        <div class="features-icon"></div>
        <span></span>
      </div>
      <div class="feature">
        <div class="features-icon"></div>
        <span></span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "Features"
}
</script>

<style scoped>
.headSection {
  color: #ffc588;
}
</style>
