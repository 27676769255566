import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";

let keys = {}

function DrawAnomaly(x, y, t) {
  let key = x + ":" + y + ":" + t

  if (keys.hasOwnProperty(key)) {
    keys[key].timeOut = 1000
    return;
  }

  let pos = GetGlobalPos(x, y, gameStore.map.id);

  // todo указывать t
  // 999 неопознаный тип
  // 0, 1, 7, 8 ящик, установленные ящики
  // 2 руда, руды на карте
  // 3, 4 текст, артефакт
  let anomaly = {}

  anomaly.sprite = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: "select_sprite",
    add: true
  });
  anomaly.sprite.setTint(0x15fff5)
  anomaly.sprite.setDisplaySize(24, 24)

  anomaly.tween = Scene.tweens.add({
    targets: anomaly.sprite,
    props: {
      displayHeight: {value: 16, duration: 1000, ease: 'Cubic.easeIn'},
      displayWidth: {value: 16, duration: 1000, ease: 'Cubic.easeIn'},
    },
    repeat: -1,
    yoyo: true,
  });

  keys[key] = anomaly

  anomaly.timeOut = 1000;
  anomaly.callBack = Scene.time.addEvent({
    delay: 500,
    callback: function () {
      anomaly.timeOut -= 500
      if (anomaly.timeOut <= 0) {
        anomaly.sprite.destroy();
        anomaly.callBack.remove();
        anomaly.tween.remove();
        delete keys[key];
      }
    },
    loop: true
  });
}


export {DrawAnomaly}
