<template>
  <div id="choiceBlockWrapper" v-if="fraction && fraction !== '' && getContent"
       :style="{backgroundImage: 'url(' + require('../../assets/bases/' + getContent.back).default + ')'}">
    <div id="fullMask"></div>
    <div id="choiceBlock">
      <div id="introduction">
        <h3 class="head">
          {{ texts['text_1'][language] }}
        </h3>
      </div>

      <div id="selectTabs">
        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             class="cursor_hover"
             @click="selectFraction('Replics')" :class="{select: fraction==='Replics'}">
        </div>
        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             class="cursor_hover"
             @click="selectFraction('Explores')" :class="{select: fraction==='Explores'}"
             :style="{ backgroundImage: 'url(' + require('../../assets/logo/menuKN_E.png').default + ')' }">
        </div>
        <div @mouseover="playSound('select_sound.mp3', 0.3)"
             class="cursor_hover"
             @click="selectFraction('Reverses')" :class="{select: fraction==='Reverses'}"
             :style="{ backgroundImage: 'url(' + require('../../assets/logo/menuKN_Rv.png').default + ')' }">
        </div>
      </div>

      <div style="width: 100%;">
        <div class="fraction">

          <div class="text_description">
            <div id="fraction_icon" class="icon"
                 :style="{ backgroundImage: 'url(' + require('../../assets/' + getContent.logo).default + ')' }"/>
            <div id="fraction_description" class="text" v-html="getContent.text"/>
          </div>

          <div class="map_description">
            <div class="bonus" v-html="getContent.bonus"/>

            <p style="margin-bottom: 5px;" v-html="getContent.weaponText"></p>

            <div class="detailImg"
                 :style="{ backgroundImage: 'url(' + require('./'+getContent.weapon+'.gif').default + ')' }"
                 style="width: 370px; height: 130px; margin-left: calc(50% - 187px);"/>

            <p style="margin: 5px 0" v-html="getContent.bodyText"></p>

            <div class="detailImg"
                 :style="{ backgroundImage: 'url(' + require('./' +getContent.body+ '.gif').default + ')' }"
                 style="width: 370px; height: 145px; margin-left: calc(50% - 187px); margin-top: 10px; background-position: bottom;"/>
          </div>

          <div class="detail_description">
            <div class="detailImg"
                 :style="{ backgroundImage: 'url(' + require('./' +getContent.map+ '.png').default + ')' }"
                 style="width: 376px; height: 148px; margin-left: calc(50% - 182px)"/>
          </div>

          <input id="fraction_button"
                 type="button"
                 class="cursor_hover"
                 :value="texts['button_1'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="select">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChoiceFraction",
  data() {
    return {
      fraction: 'Replics',
      fractions: {
        Replics: {
          iconUrl: 'dialogPictures/replics_logo.png',
          name: 'Replics',
          back: 'replics.jpg',
          map: 'base_Rp',
          weapon: 'rc2',
          weaponText: `Replics_weaponText`,
          body: 'rc1',
          bodyText: `Replics_bodyText`,
          bonus: `Replics_bonus`,
        },
        Explores: {
          iconUrl: 'dialogPictures/explores_logo.png',
          name: 'Explores',
          back: 'explores.jpg',
          map: 'base_Ex',
          weapon: 'ec2',
          weaponText: `Explores_weaponText`,
          body: 'ec1',
          bodyText: `Explores_bodyText`,
          bonus: `Explores_bonus`,
        },
        Reverses: {
          iconUrl: 'dialogPictures/reverses_logo.png',
          name: 'Reverses',
          back: 'reverses.jpg',
          map: 'base_Rv',
          weapon: 'rvc2',
          weaponText: `Reverses_weaponText`,
          body: 'rvc1',
          bodyText: `Reverses_bodyText`,
          bonus: `Reverses_bonus`,
        }
      }
    }
  },
  mounted() {
    this.$store.commit({
      type: 'setVisibleLoader',
      visible: false,
    });
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    select() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "choiceFraction",
          data: this.fraction,
        }
      }));
    },
    selectFraction(fraction) {
      this.playSound('button_press.mp3', 0.3)
      this.fraction = fraction;
    }
  },
  computed: {
    getContent() {
      return {
        text: this.texts[this.fraction][this.language],
        logo: this.fractions[this.fraction].iconUrl,
        back: this.fractions[this.fraction].back,
        map: this.fractions[this.fraction].map,
        weapon: this.fractions[this.fraction].weapon,
        weaponText: this.texts[this.fractions[this.fraction].weaponText][this.language],
        body: this.fractions[this.fraction].body,
        bodyText: this.texts[this.fractions[this.fraction].bodyText][this.language],
        bonus: this.texts[this.fractions[this.fraction].bonus][this.language],
      }
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('ChoiceFraction')
    },
  }
}
</script>

<style scoped>

#choiceBlockWrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  pointer-events: auto;
}

#choiceBlock {
  position: absolute;
  width: 900px;
  height: 500px;
  border-radius: 5px;
  left: calc(50% - 450px);
  top: 130px;
  z-index: 999;
  padding: 5px;
  box-shadow: 0 0 2px #000;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, .7);
  backdrop-filter: blur(4px);
}

#introduction {
  width: 100%;
  height: 40px;
  box-shadow: inset 0 0 3px black;
  background: #8cb3c7;
  margin-bottom: 5px;
  color: #fff;
  position: relative;
  background: rgba(0, 0, 0, .3);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  float: left;
  border-radius: 5px 5px 0 0;
  font-size: 13px;
}

#choiceBlock h3 {
  margin: 7px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#introduction p {
  font-size: 11px;
  margin: 0;
  text-align: left;
  font-weight: 900;
}

#selectTabs {
  position: absolute;
  top: -81px;
  left: 41%;
}

#selectTabs div {
  float: left;
  height: 64px;
  width: 53px;
  margin-left: 5px;
  font-weight: 900;
  box-shadow: 0 0 3px black;
  border: 1px solid black;
  font-size: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 113px;
  opacity: 0.5;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  background-image: url(../../assets/logo/menuKN_Rp.png);
  animation: selectAnimate 3000ms infinite !important;
}

.fraction {

}

#choiceBlock input {
  display: block;
  margin: 0 auto 0;
  width: 90px;
  pointer-events: auto;
  font-size: 16px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 27px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 2px;
  float: right;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
}

#choiceBlock input:hover {
  background: #ff8100;
}

#choiceBlock input:active {
  transform: scale(.98);
}

.text_description, .detail_description, .map_description {
  padding: 5px;
  word-wrap: break-word;
  width: 50%;
  box-shadow: inset 0 0 3px #000;
  position: relative;
  background: rgba(0, 0, 0, .3);
  float: left;
  text-align: left;
  margin: auto auto 2px 0;
  height: 277px;
  overflow-y: scroll;
  color: papayawhip;
  text-indent: 2em;
  font-size: 13px;
  text-shadow: none;
  letter-spacing: 0.7px;
}

.detail_description {
  height: 152px;
  margin-top: 4px;
  border-radius: 0 0 0 5px;
}

.map_description {
  float: right;
  width: calc(50% - 26px);
  height: 445px;
}

.fraction .icon {
  height: 130px;
  width: 130px;
  margin: 4px 9px 4px 9px;
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 0 5px black;
  float: right;
}

#fullMask {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
}

#selectTabs .select {
  box-shadow: 0 0 3px wheat;
  border: 1px solid wheat;
  opacity: 1;
}

#choiceBlock .head, #choiceBlock .sub_head {
  font-size: 24px;
  padding: 7px;
  margin: 0;
  text-align: left;
  color: #ff9d07;
  text-shadow: none;
}

#choiceBlock .sub_head {
  font-size: 16px;
}

.detailImg {
  border-radius: 3px;
  background-size: cover;
  filter: drop-shadow(0 0 3px black);
}

.bonus {
  width: calc(100% - 13px);
  float: left;
  font-size: 11px;
  padding: 5px;
  padding-left: 0;
  clear: both;
  border: 1px solid transparent;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 3px;
  margin: 3px 1px;
  color: white;
  box-shadow: 0 0 2px 0 black;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  margin-bottom: 10px;
}

@keyframes selectAnimate {
  0% {
    box-shadow: 0 0 2px 1px #ffe32f;
  }
  50% {
    box-shadow: 0 0 6px 2px #ffe32f;
  }
  100% {
    box-shadow: 0 0 2px 1px #ffe32f;
  }
}
</style>
<style>
#choiceBlockWrapper .insert {
  padding: 0 39px;
  font-style: italic;
  font-size: 12px;
  color: white;
  float: left;
  width: 210px;
  margin-top: -8px;
}
</style>
