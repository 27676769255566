<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Система правосудия</h2>

      <p>В мире Veliri синтетов существуют определённые правила, которые регулируют отношения между синтетами. В
        большинстве случаев, эти правила зависят и разнятся от типа сектора, в котором сейчас пребывают те или иные
        синтеты.</p>

      <p>Если же синтет, каким-либо образом данные правила нарушает, то он автоматически изменяет свой статус
        нейтрала/союзника, на режим ПВП или ПК. </p>

      <p><span class="importantly">Система правосудия работает на уровне фракций</span>, это значит если вы являетесь
        преступников в секторах красных, то в секторах зеленых вы им не являетесь. Если отношение фракции в игроку -
        <span class="importantly">"вражда"</span>, то вы являетесь преступником в секторе фракции, независимо от
        обстоятельств.</p>

      <br>
      <ul>
        <li class="imageLi">
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('pvp', 'png')+ ')',
            width: '200px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>

          <p><span class="importantly">Режим “ПВП”</span> - режим, в котором бот/игрок может быть атакован и уничтожен
            любым другим синтетом/игроком,
            который также получает статус “ПВП”.
          </p>

          <br>
          Активность данного режима, составляет - <span class="importantly">30 секунд</span>.
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('pk', 'png')+ ')',
            width: '200px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>
          <p><span class="importantly">Режим “ПК”</span> - режим, в котором бот/игрок выбирает себе роль преступника,
            который может быть свободно атакован и убит любым синтетом без каких-либо штрафов. </p>
          <p>Тот кто нанесет последний удар по преступнику получит 10% от его средней стоимости корпуса и всего
            снаряжения.</p>
          <br>
          Активность данного режима, составляет - <span class="importantly">15 минут</span>.
        </li>
      </ul>

      <h3 class="head">Правила и последствия нарушения статуса опасности различаются в разных типах секторов:</h3>

      <h3 class="headSection">Безопасные сектора:</h3>
      <p>Безопасные сектора - в данном типе зоны запрещены любые боевые действия, даже если они происходят между членами
        противоборствующих фракций.</p>

      <p>Здесь, при нанесении любого урона по боту/игроку, вы автоматически переходите в режим “ПВП”. В случае, если
        цель
        была уничтожена не имея статуса, ваш статус меняется на “ПК”.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('guard_1', 'gif')+ ')',
            width: '320px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>При условии, если в секторе присутствуют защитники/стражи - они стремятся заморозить любых ботов/игроков, у
          которых появился статус “ПВП”. При данном эффекте “заморозки” игрок или бот не могут двигаться и производить
          прочие действия определённое время. Однако, никто не способен нанести урон и “замороженным”, ведь они,
          автоматически получают непробиваемый щит.</p>

        <p>При проявлении агрессии к нпс - неигровым персонажам они откроют ответный огонь и могут позвать подмогу.</p>

        <p>Стражи, полиция и турели атакуют игроков в режиме “ПК”.</p>

        <p>полиция и турели могут напасть на агрессора если заметят что он атаковать бота без статуса.</p>

        <p>Несмотря на все меры безопасности в секторе, гарантировать ее вам никто не может.</p>
      </div>

      <div class="content_section">
        <h3 class="headSection">Боевые сектора:</h3>
        <p>Боевые сектора - это спорные территории, на которых идет война между фракциями. Здесь вы можете свободно
          атаковать и убивать игроков и структуры других фракций без штрафов.</p>
        <p>Здесь нет стражей, и вы должны полагаться на свою фракцию и свой транспорт для защиты.</p>
        <p>За убийство игрока своей фракции вы получаете режим “ПВП” и члены вашей фракции могут вас атаковать.</p>
      </div>

      <div class="content_section">
        <h3 class="headSection">Свободные сектора:</h3>
        <p>Анархия, не имеющая правил и последствий. Чтобы не происходило в данном типе сектора, навсегда там и
          остаётся.</p>
        <p>Будьте готовы к любым последствиям, ведь помимо фракционных врагов, существует немало тех, кому вы попросту
          не
          нравитесь. </p>
      </div>

      <div class="content_section">
        <h3 class="head">Нарушения</h3>
        <p>Совершая мелкие преступления (контрабанда, атака мирных, уничтожение сопсвености баз)) вы накапливаете
          счетчик
          преступлений. Если этот счетчик становится равен 10 то вы уходите в режим "pk" а счетчик сбрасывается.</p>
        <p>Нарушения показываются рядом с панелью состояния транспорта на фоне иконки полиции.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('law_strikes', 'png')+ ')',
            width: '212px',
            height: '72px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>Каждые 3 минуты счетчик уменьшается на 1.</p>
      </div>

      <h3 class="head">Маяки бедствия</h3>
      <p>В случае уничтожения какого-либо синтета, срабатывает его аварийный маяк. Это сигнал, который отображается на
        мини-карте и виден как ботам, так и игрокам. </p>
      <p>Подобный сигнал, может сулить как щедрые выгоды - разжиться оружием, деталями, ресурсами, уничтоженного
        синтета,
        так и нести скрытую опасность. </p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('battle_3', 'gif')+ ')',
            width: '198px',
            height: '200px',
            marginLeft: 'calc(50% - 99px)',
            }"/>

      <div class="content_section">
        <h3 class="head">Таймер выхода из игры</h3>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('exit_timer', 'png')+ ')',
            width: '250px',
            height: '85px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>Таймер выхода из игры - мера пресекающая резкое исчезновение игрока из мира. Получив урон или нанеся его,
          игрок
          вынужденно остаётся в игровом мире на следующий срок: </p>

        <ul style="float: left">
          <li> Получение урона от бота активирует таймер на 60сек</li>
          <li> Получение урона от игрока активирует таймер на 120сек</li>
          <li> Получение статуса опасности "PVP" на 30 секунд</li>
          <li> Получение статуса опасности "PK" на 5 минут</li>
        </ul>
      </div>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Justice System</h2>

      <p>In the world of Veliri synthetics, there are certain rules that regulate the relationships between synthetics.
        In
        most cases, these rules depend on and vary from the type of sector in which the synthetics currently reside.</p>

      <p>If a synthetic somehow violates these rules, they automatically change their status from neutral/ally to PVP or
        PK mode. </p>

      <p><span class="importantly">The justice system operates at the faction level</span>, meaning if you are a
        criminal in the red sectors, you are not in the green sectors. If the faction's attitude towards a player is
        <span class="importantly">"hostile"</span>, then you are a criminal in the faction's sector, regardless of
        circumstances.</p>

      <br>
      <ul>
        <li class="imageLi">
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('pvp', 'png')+ ')',
            width: '200px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>

          <p><span class="importantly">"PVP" mode</span> - a mode in which a bot/player can be attacked and destroyed by
            any other synthetic/player,
            who also receives "PVP" status.
          </p>

          <br>
          The duration of this mode is - <span class="importantly">30 seconds</span>.
        </li>
        <li class="imageLi">
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('pk', 'png')+ ')',
            width: '200px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>
          <p><span class="importantly">"PK" mode</span> - a mode in which a bot/player takes on the role of a criminal,
            who can be freely attacked and killed by any synthetic without any penalties. </p>
          <p>The one who deals the final blow to the criminal receives 10% of the average cost of their hull and all
            equipment.</p>
          <br>
          The duration of this mode is - <span class="importantly">15 minutes</span>.
        </li>
      </ul>

      <h3 class="head">Rules and consequences of violating the danger status vary in different types of sectors:</h3>

      <h3 class="headSection">Safe Sectors:</h3>
      <p>Safe sectors - in this type of zone, any combat actions are prohibited, even if they occur between members of
        opposing factions.</p>

      <p>Here, by inflicting any damage on a bot/player, you automatically switch to "PVP" mode. If the
        target was destroyed without having status, your status changes to "PK".</p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('guard_1', 'gif')+ ')',
            width: '320px',
            height: '185px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>If there are defenders/guards present in the sector, they strive to freeze any bots/players who have acquired
          "PVP" status. During this "freezing" effect, the player or bot cannot move or perform other actions for a
          certain time. However, no one can inflict damage on the "frozen" ones, as they automatically receive an
          impenetrable shield.</p>

        <p>If aggression is shown to NPCs - non-player characters, they will open fire in response and may call for
          backup.</p>

        <p>Guards, police, and turrets attack players in "PK" mode.</p>

        <p>The police and turrets may attack the aggressor if they notice that he is attacking a bot without status.</p>

        <p>Despite all the security measures in the sector, no one can guarantee it to you.</p>
      </div>

      <div class="content_section">
        <h3 class="headSection">Combat Sectors:</h3>
        <p>Combat sectors are disputed territories where war is ongoing between factions. Here you can freely attack and
          kill players and structures of other factions without penalties.</p>
        <p>There are no guards here, and you must rely on your faction and your transport for protection.</p>
        <p>For killing a player of your own faction, you receive "PVP" mode and members of your faction can attack
          you.</p>
      </div>

      <div class="content_section">
        <h3 class="headSection">Free Sectors:</h3>
        <p>Anarchy, having no rules and consequences. Whatever happens in this type of sector stays there forever.</p>
        <p>Be prepared for any consequences, because in addition to factional enemies, there are many who simply do not
          like you. </p>
      </div>

      <div class="content_section">
        <h3 class="head">Violations</h3>
        <p>By committing minor crimes (smuggling, attacking civilians, destroying base property), you accumulate a crime
          counter. If this counter reaches 10, you go into "PK" mode and the counter resets.</p>
        <p>Violations are displayed next to the transport status panel against the background of the police icon.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('law_strikes', 'png')+ ')',
            width: '212px',
            height: '72px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>Every 3 minutes the counter decreases by 1.</p>
      </div>

      <h3 class="head">Distress Beacons</h3>
      <p>In the event of the destruction of any synthetic, its emergency beacon is activated. This signal is displayed
        on the mini-map and is visible to both bots and players. </p>
      <p>Such a signal may promise generous benefits - to get weapons, parts, resources of the destroyed synthetic, as
        well as carry hidden danger. </p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('battle_3', 'gif')+ ')',
            width: '198px',
            height: '200px',
            marginLeft: 'calc(50% - 99px)',
            }"/>

      <div class="content_section">
        <h3 class="head">Game Exit Timer</h3>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('exit_timer', 'png')+ ')',
            width: '250px',
            height: '85px',
            float: 'left',
            marginRight: '10px',
            }"/>

        <p>The game exit timer is a measure that prevents the abrupt disappearance of a player from the world. Having
          received or inflicted damage, the player is forcibly retained in the game world for the following term: </p>

        <ul style="float: left">
          <li> Receiving damage from a bot activates the timer for 60 seconds</li>
          <li> Receiving damage from a player activates the timer for 120 seconds</li>
          <li> Receiving "PVP" danger status for 30 seconds</li>
          <li> Receiving "PK" danger status for 5 minutes</li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Law",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
