<template>
  <div>
    <h3 class="head head_filters"
        @click="filters = !filters; resetCursor(); getCorporations()">
      {{ filters ? '▼' : '▶' }}
      {{ texts['text_61'][language] }}</h3>

    <div class="filters" v-if="filters">
      <div class="filters_label"> Название:</div>
      <input class="search_input" v-model="clusterName" type="text"
             :placeholder="texts['placeholder_3'][language]"/>

      <div class="filters_label" style="clear: both"> Мин. число участников: {{ members }}</div>
      <div class="range">
        <input class="cursor_hover" v-model="members" type="range" min="0" max="100" step="1">
      </div>

      <div class="filters_label" style="clear: both"> Ведут набор:</div>
      <div class="setting_row">
        <select v-model="open" class="local_select settings cursor_hover">
          <option value="unset">{{ texts['option_32'][language] }}</option>
          <option value="true">{{ texts['option_33'][language] }}</option>
          <option value="false">{{ texts['option_34'][language] }}</option>
        </select>
      </div>

      <input class="button search_button cursor_hover"
             @click="resetCursor(); getCorporations();"
             :value="texts['button_50'][language]">
    </div>

    <div class="pagination_wrapper">
      <input class="button pagination_button cursor_hover"
             @click="back()"
             value="Назад">
      <input class="button pagination_button cursor_hover"
             @click="fullBack()"
             value="В начало">

      <input class="button pagination_button cursor_hover"
             style="float: right;"
             @click="next()"
             value="Вперед">
    </div>

    <div v-if="corporations && !preloader" style="width: 100%; min-height: 20px; margin-top: 5px; float: left">
      <div v-for="c in corporations" class="corpSection">

        <div class="userAvatar" v-bind:style="{backgroundImage:  'url(\'' + logs[c.corporation.id] + '\')'}">
          {{ getCorpLogo(c.corporation.id) }}
        </div>

        <h3 class="head"
            style="float: left; width: calc(100% - 78px); margin-bottom: 11px;">

          [<span>{{ c.corporation.tag }}</span>]
          {{ c.corporation.name }}


          <span style="float: right; margin-right: 2px; width: 16px; text-align: right;">{{ c.members }}</span>
          <div class="members"/>

          <input type="checkbox" class="disable" :checked="c.corporation.open" style="float: right">
          <span style="float: right; border-left: 1px solid black; text-indent: 2px;">набор:</span>
        </h3>
        <p class="description">{{ c.corporation.description }}</p>

        <h3 class="head menu cursor_hover"
            style="margin-bottom: 5px; float: left; width: 100%;"
            @click="openDetail(c.corporation.id)">
          {{ openDetails[c.corporation.id] ? '▼' : '▶' }}
          Подробно </h3>

        <div v-if="openDetails[c.corporation.id]">

          <div class="filters_label" style="margin-left: 20px; margin-bottom: 5px;"> Налог:</div>
          <input class="search_input" disabled style="width: 30px" type="text" :value="c.corporation.tax + '%'"/>

          <div class="filters_label" style="clear: both; margin-left: 20px; margin-bottom: 5px;"> Огонь по своим:</div>
          <div class="setting_row disable">
            <select v-model="c.corporation.friendly_fire" style="width: 100px"
                    class="local_select settings cursor_hover">
              <option value="true">Разрешен</option>
              <option value="false">Запрещен</option>
            </select>
          </div>

          <div style="float: right; width: calc(100% - 20px)">
            <h3 class="head menu cursor_hover"
                style="margin-bottom: 5px"
                @click="openDeposit(c.corporation.id)">
              {{ openDeposits[c.corporation.id] ? '▼' : '▶' }}
              {{ texts['text_9'][language] }}
              <div class="help_point_menu"
                   @click.stop="openService('helpWrapper', {component: 'CorporationBuild'})"></div>
            </h3>

            <template v-if="openDeposits[c.corporation.id]">
              <div class="deposit cursor_hover"
                   @click="openService('SendCredits', {corp_name: c.corporation.name, deposit: d.id})"
                   v-for="d in c.deposits">

                <div class="credits_icon"/>

                <input @click.stop="" maxlength="16" type="text" class="credits_name input cursor_hover disable"
                       :value="d.name">
              </div>
            </template>
          </div>

          <div style="float: right; width: calc(100% - 20px)">
            <h3 class="head menu cursor_hover"
                style="margin-bottom: 5px"
                @click="openOffice(c.corporation.id)">
              {{ openOffices[c.corporation.id] ? '▼' : '▶' }}
              {{ texts['text_10'][language] }}
              <div class="help_point_menu"
                   @click.stop="openService('helpWrapper', {component: 'CorporationBuild'})"></div>
            </h3>

            <template v-if="openOffices[c.corporation.id]">
              <div class="office_row cursor_hover"
                   v-for="office in c.offices"
                   :class="{open_office_row: selectOffice === office.id, path: globalPath.baseID === office.base_id}"
                   @click="selectOffice === office.id ? selectOffice = 0 : selectOffice = office.id"
              >

                <input @click.stop="" type="text" maxlength="16" class="office_name input cursor_hover disable"
                       :value="office.name">

                <div class="office_base">{{ texts['text_11'][language] }} <span
                  class='importantly'>{{ getBaseNameByID(office.base_id) }}</span>
                </div>

                <div v-if="selectOffice === office.id"
                     style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; margin-top: 2px;">
                  <div @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click.stop="autoPilot(office.base_id, 'target')"
                       class="cluster_button office_button cursor_hover"> {{ texts['button_15'][language] }}
                  </div>

                  <div @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click.stop="autoPilot(office.base_id, 'auto_pilot')"
                       class="cluster_button office_button cursor_hover"> {{ texts['button_16'][language] }}
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div style="float: right; width: calc(100% - 20px); padding-bottom: 10px;">
            <h3 class="head menu cursor_hover" style="margin-bottom: 5px"
                @click="openBase(c.corporation.id)">
              {{ openBases[c.corporation.id] ? '▼' : '▶' }}
              {{ texts['text_14'][language] }}
              <div class="help_point_menu"
                   @click.stop="openService('helpWrapper', {component: 'CorporationBuild'})"></div>
            </h3>

            <template v-if="openBases[c.corporation.id]">
              <div v-for="base in c.bases"
                   class="office_row"
                   :class="{open_base_row: selectBase === base.id, path: globalPath.mapID === base.map_id}"
                   @click="selectBase === base.id ? selectBase = 0 : selectBase = base.id"
              >

                <input @click.stop="" type="text" maxlength="16" class="office_name input cursor_hover disable"
                       :value="base.name">

                <div class="office_base">{{ texts['text_15'][language] }}<span
                  class='importantly'>{{ getSectorNameByID(base.map_id) }}</span></div>

                <div v-if="selectBase === base.id"
                     style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; margin-top: 2px; padding-top: 2px;">

                  <div v-if="base.base_id !== 0"
                       style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%;">
                    <div @mouseover="playSound('select_sound.mp3', 0.3)"
                         @click.stop="autoPilot(base.base_id, 'target')"
                         class="cluster_button office_button cursor_hover"> {{ texts['button_19'][language] }}
                    </div>

                    <div @mouseover="playSound('select_sound.mp3', 0.3)"
                         @click.stop="autoPilot(base.base_id, 'auto_pilot')"
                         class="cluster_button office_button cursor_hover"> {{ texts['button_20'][language] }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <template v-if="!newRequestCorpID">
          <div style="clear:both;">
            <template v-if="myRequests.hasOwnProperty(c.corporation.id)">
              <h4>
                {{ texts['text_62'][language] }}
                <input type="button"
                       style="float: right; width: 58px; "
                       class="button cursor_hover"
                       :value="texts['button_33'][language]"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click="rejectRequest(c.corporation.id)">
                <input type="button"
                       style="float: right; width: 58px; margin-right: 3px;"
                       class="button cursor_hover"
                       :value="texts['button_34'][language]"
                       @click="newRequestCorpID = c.corporation.id">
              </h4>
              <div class="myRequestBlock">{{ myRequests[c.corporation.id].text }}</div>
            </template>

            <template v-if="!myRequests.hasOwnProperty(c.corporation.id)">
              <input class="button cursor_hover"
                     :value="texts['button_35'][language]"
                     style="float: right"
                     :class="{disable: corporation.info}"
                     @click="newRequestCorpID = c.corporation.id">
            </template>
          </div>
        </template>

        <div class="newRequest" v-if="newRequestCorpID === c.corporation.id">

          <textarea v-model="newRequestText"
                    :placeholder="texts['placeholder_4'][language]"/>

          <div>
            <input class="button cursor_hover"
                   :value="texts['button_36'][language]"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="sendRequest">
            <input class="button cursor_hover"
                   :value="texts['button_37'][language]"
                   style="margin-right: 6px;"
                   @click="newRequestCorpID = null">
          </div>
        </div>
      </div>
    </div>

    <div class="preloader_wrapper" v-if="preloader">
      <app-preloader/>
    </div>
  </div>
</template>

<script>
import {urls} from "../../const";
import Vue from "vue";
import Preloader from "../Preloader/Preloader";

export default {
  name: "Search",
  props: ['corp_name'],
  data() {
    return {
      logs: {},
      preloader: true,
      newRequestCorpID: 0,
      newRequestText: '',
      cursor: 0,
      limit: 3,
      operation: '',
      openDetails: {},
      openDeposits: {},
      openOffices: {},
      openBases: {},
      selectOffice: 0,
      selectBase: 0,
      filters: false,
      clusterName: '',
      open: 'unset',
      members: 0,
    }
  },
  mounted() {
    this.clusterName = this.$props.corp_name;
    if (this.clusterName !== '') {
      this.filters = true
    }

    this.getCorporations()
  },
  methods: {
    getCorporations() {
      this.preloader = true
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "GetCorporations",
          cursor: this.cursor,
          limit: this.limit,
          name: this.filters ? this.clusterName : '',
          value: this.filters ? Number(this.members) : 0,
          data: this.filters ? this.open : 'unset',
          tag: this.operation
        }
      }));
    },
    resetCursor() {
      this.cursor = 0;
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    openDeposit(id) {
      Vue.set(this.openDeposits, id, !Boolean(this.openDeposits[id]));
    },
    openOffice(id) {
      Vue.set(this.openOffices, id, !Boolean(this.openOffices[id]));
    },
    openBase(id) {
      Vue.set(this.openBases, id, !Boolean(this.openBases[id]));
    },
    openDetail(id) {
      Vue.set(this.openDetails, id, !Boolean(this.openDetails[id]));
    },
    getBaseNameByID(id) {
      for (let i in this.handbook['base'][this.language]) {
        if (this.handbook['base'][this.language][i].id === id) {
          return this.handbook['base'][this.language][i].name;
        }
      }

      if (this.corporationBaseNames.hasOwnProperty(id)) {
        return this.corporationBaseNames[id]
      }

      return id
    },
    autoPilot(baseID, type) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "MoveToSector",
          base_id: Number(baseID),
          type: type,
        }
      }));
    },
    getSectorNameByID(id) {

      for (let i in this.handbook['sector'][this.language]) {
        if (this.handbook['sector'][this.language][i].id === id) {
          return this.handbook['sector'][this.language][i].name;
        }
      }

      return ''
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getCorpLogo(corpID) {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + corpID).then(function (response) {
        app.$set(app.logs, corpID, response.data.avatar);
      });
    },
    sendRequest() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "CreateInviteRequest",
          id: Number(this.newRequestCorpID),
          data: this.newRequestText,
        }
      }));

      this.newRequestCorpID = null
    },
    rejectRequest(corpID) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "RejectInviteRequest",
          id: Number(corpID),
        }
      }));
      this.newRequestCorpID = null
    },
    next() {
      if (this.corporations.length > 0) {
        this.cursor = this.corporations[this.corporations.length - 1].corporation.id
        this.operation = ''
        this.getCorporations()
      }
    },
    back() {
      this.operation = '>'
      this.getCorporations()
    },
    fullBack() {
      this.cursor = 0;
      this.operation = '';
      this.getCorporations()
    }
  },
  computed: {
    corporations() {
      this.preloader = false
      let corps = this.$store.getters.getChatState.corporations.current
      if (corps.length > 0) {
        this.cursor = corps[0].corporation.id + 1
      }
      return corps
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    myRequests() {
      return this.$store.getters.getChatState.corporation.inviteRequest;
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    corporationBaseNames() {
      return this.$store.getters.getCorporationBaseNames
    },
    globalPath() {
      return this.$store.getters.getGlobalPath
    },
  },
  components: {
    AppPreloader: Preloader,
  }
}
</script>

<style scoped>
.head {
  margin: 0 0 2px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.corpSection {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 6px;
  box-shadow: 0 0 2px black;
  margin-bottom: 7px;
  float: left;
  width: calc(100% - 10px);
}

.corpSection .head {
  background: rgb(168, 85, 39);
  padding: 2px;
}

.corpSection:last-child {
  margin-bottom: 0;
}

.userAvatar {
  background-color: rgba(128, 128, 128, 0.3);
  height: 68px;
  width: 68px;
  float: left;
  margin: -1px 5px 5px -1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
}

.head span {
  color: #e4ff00;
  font-size: 10px;
}

textarea {
  box-shadow: inset 0 0 2px black;
  background: #adc6cd;
  padding: 5px 1px 5px 5px;
  word-wrap: break-word;
  overflow-y: scroll;
  border-radius: 5px;
  text-align: left;
  width: calc(100% - 5px);
  min-height: 67px;
  text-shadow: #28a5e4 0 0 4px;
  font-size: 9pt;
  float: left;
  resize: none;
  overflow-x: hidden;
  margin: 0;
}

.newRequest {
  clear: both;
}

.newRequest input {
  float: right;
  width: 65px
}

.newRequest div {
  margin-top: 5px;
  float: right
}

h4 {
  margin: 0 0 5px 0;
  background: hsla(21, 71%, 54%, 0.4);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 11px;
  line-height: 20px;
  height: 20px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 5px;
  padding-right: 2px;
  float: left;
  width: 200px;
}

.myRequestBlock {
  float: left;
  width: calc(100% - 6px);
  min-height: 25px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-shadow: inset 0 0 2px black;
  color: white;
  font-size: 11px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.disable {
  color: grey;
  background: rgba(127, 127, 127, 0.3) !important;
}

.description {
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.head {
  margin: 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  position: relative;
}

.credits_icon {
  height: 16px;
  width: 16px;
  margin-top: 1px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png')
}

.head.menu {
  background: rgba(255, 129, 0, 0.6);
}

.head.menu:hover {
  background: rgba(255, 129, 0, 1);
}

.head.menu:active {
  background: rgb(248, 171, 92);
}

.deposit, .office_row {
  width: calc(100% - 10px);
  height: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px;
  border-radius: 2px;
  box-shadow: 0 0 2px black;
  margin: 2px 2px 5px 2px;
}

.deposit:hover, .office_row:hover {
  box-shadow: 0 0 1px 1px white;
}

.credits_name, .office_name, .office_base {
  background: #066ea8;
  float: right;
  width: calc(65% - 0px);
  padding-right: 4px;
  border-radius: 4px;
  color: white;
  line-height: 18px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 16px;
  text-align: left;
  text-indent: 5px;
}

.office_name.input, .credits_name.input {
  width: calc(33% - 10px);
  height: 13px;
  padding-top: 2px;
  color: #ff7800;
  font-weight: bold;
  background: rgb(0, 0, 0, 0.3);
  float: left;
  text-align: left;
  background: rgba(0, 0, 0, 0.3) !important;
}

.credits_name.input {
  width: calc(100% - 26px);
  text-align: left;
  background: rgba(0, 0, 0, 0.3) !important;
}

.open_office_row {
  height: 39px;
}

.path {
  background: rgba(227, 152, 22, 0.6);
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  width: 250px;
  position: relative;
  left: calc(50% - 125px);
  user-select: none;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}

.office_button {
  width: calc(25% - 4px);
  float: left;
  margin: 2px;
  left: unset;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 3px;
}

.members {
  background-image: url('../../assets/icons/group.png');
  height: 20px;
  float: right;
  width: 20px;
  background-position: center;
  background-size: contain;
  margin: -2px 2px 0 0;
  filter: drop-shadow(1px 1px 1px black);
}

.head_filters {
  border-radius: 4px 4px 0 0;
  background: rgba(255, 129, 0, 0.6);
}

.head_filters:active {
  background: rgb(248, 171, 92);
}

.head_filters:hover {
  background: rgba(255, 129, 0, 1);
}

.filters {
  width: 100%;
  float: left;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 2px black;
  padding-top: 5px;
  margin-top: 1px;
}

.filters_label {
  width: 180px;
  float: left;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-shadow: 1px 1px 1px black, 0 0 0 black;
  letter-spacing: 0;
  color: bisque;
  text-indent: 5px;
}

.search_input {
  width: calc(100% - 200px);
  margin: 0 3px 0 0;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  float: right;
  line-height: 20px;
  height: 18px;
}

.search_button, .pagination_button {
  float: right;
  width: 60px;
  clear: both;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-top: 10px;
}

.pagination_button {
  float: left;
  clear: unset;
  margin: 3px;
}

.preloader_wrapper {
  float: left;
  margin-left: calc(50% - 21px);
  margin-top: 40px;
}

.pagination_wrapper {
  width: 100%;
  float: left;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
  margin-top: 6px;
}

.range {
  height: 9px;
  width: calc(100% - 191px);
  line-height: 5px;
  float: right;
  margin: 6px 3px 0 0;
}

.range input[type=range] {
  -webkit-appearance: none;
  width: calc(100% - 10px);
  height: 8px;
  box-shadow: none;
}

.range input[type=range]:focus {
  outline: none;
}

.range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 6px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
  position: relative;
  top: -1px
}

.range input[type=range]:focus::-webkit-slider-runnable-track {
}

.range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.local_select {
  outline: none;
  width: calc(100% - 190px);
  border-radius: 5px;
  margin: 0 3px -2px 0;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
  float: right;
}

.local_select option {
  color: black;
  font-weight: bold;
}

.help_point_menu {
  height: 13px;
  width: 13px;
  /* background: black; */
  position: absolute;
  right: 3px;
  bottom: 2px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}
</style>
