<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: сектора</h2>

      <p>Во время выполнения этого задания <span class="importantly">рекомендуется не ввязываться в драки</span> и
        отступать на базу и телепорты, <span class="importantly">но</span> оружие активируется как добывающее снаряжение
        и
        целится надо мышкой.</p>

      <p>Карта мира состоит из секторов. Что бы увидеть всю карту мира надо нажать кнопку карты на нижней панели.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_map_1', 'png')+ ')',
            width: '341px',
            height: '73px',
            marginLeft: 'calc(50% - 170px)',
            }"/>

      <p>На карте мира можно посмотреть связи между секторами, проложить маршрут до базы/сектора или активировать
        автопилот.</p>

      <p>Если вы проложите маршрут то на мини карте появятся линии показывающие куда следовать.</p>
      <p>Если вы активируете автопилот то транспорт начнет самостоятельное движение до цели. Использовать автопилот не
        рекомендуется т.к. этот режим сильно ограничен а алгоритмы управления не оптимальны.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_map_2', 'png')+ ')',
            width: '480px',
            height: '346px',
            marginLeft: 'calc(50% - 240px)',
            }"/>

      <p>Переход между сектора осуществляется через телепорт, для перехода надо заехать в него как на базу или встать в
        центре.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('teleport_1', 'png')+ ')',
            width: '366px',
            height: '180px',
            marginLeft: 'calc(50% - 183px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Sectors</h2>

      <p>During the execution of this task, it is <span class="importantly">recommended not to engage in fights</span>
        and
        retreat to the base and teleports, <span class="importantly">but</span> the weapon is activated as mining
        equipment
        and
        must be aimed with the mouse.</p>

      <p>The world map consists of sectors. To see the entire world map, you need to press the map button on the bottom
        panel.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_map_1', 'png')+ ')',
            width: '341px',
            height: '73px',
            marginLeft: 'calc(50% - 170px)',
            }"/>

      <p>On the world map, you can view the connections between sectors, plot a route to a base/sector, or activate
        autopilot.</p>

      <p>If you plot a route, lines will appear on the mini-map showing where to follow.</p>
      <p>If you activate the autopilot, the transport will start moving independently to the destination. It is not
        recommended to use autopilot because this mode is very limited and the control algorithms are not optimal.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_map_2', 'png')+ ')',
            width: '480px',
            height: '346px',
            marginLeft: 'calc(50% - 240px)',
            }"/>

      <p>Transition between sectors is carried out through a teleport, to transition you need to drive into it as to a
        base or stand in the center.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('teleport_1', 'png')+ ')',
            width: '366px',
            height: '180px',
            marginLeft: 'calc(50% - 183px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationMap",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
