var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: взаимодействия с объектами")]),_vm._v(" "),_c('p',[_vm._v("С некоторыми объектами в открытом мире можно взаимодействовать например забрать квестовый предмет или лутать\n      обломки транспорта.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Если в инвентаре что то есть на это можно тыкнуть мышкой и оно упадет в трюм.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_open_object_1', 'gif')+ ')',
          width: '480px',
          height: '349px',
          marginLeft: 'calc(50% - 240px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Interacting with Objects")]),_vm._v(" "),_c('p',[_vm._v("Some objects in the open world can be interacted with, for example, to pick up a quest item or loot\n      wreckage of transport.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("If there is something in the inventory, you can click on it with the mouse and it will fall into the hold.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_open_object_1', 'gif')+ ')',
          width: '480px',
          height: '349px',
          marginLeft: 'calc(50% - 240px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы начать взаимодействие с объектом надо выбрать на панели снаряжения "),_c('span',{staticClass:"importantly"},[_vm._v("\"манипулятор\"")]),_vm._v(", подъехать к объекту и нажать на него мышкой. Результатом будет\n      открывшееся окно объекта с инвентарем.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To start interacting with an object, you need to select the "),_c('span',{staticClass:"importantly"},[_vm._v("\"manipulator\"")]),_vm._v(" on the equipment panel, approach the object and click on it with the\n      mouse. The result will be\n      an opened object window with inventory.")])
}]

export { render, staticRenderFns }