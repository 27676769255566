import {gameStore} from "../../store";
import {ShortDirectionRotateTween} from "../../utils/utils";
import {GetGlobalPos} from "../gep_global_pos";

function CreateSpriteTurret(turretState, turretBase, scene) {

  turretBase.setDepth(1);

  let pos = GetGlobalPos(turretState.x, turretState.y, gameStore.map.id);

  for (let weaponState of turretState.weapons) {

    let weapon = scene.make.image({
      x: pos.x + weaponState.real_x_attach,
      y: pos.y + weaponState.real_y_attach,
      scale: turretState.scale / 100,
      key: 'structures',
      add: true,
      frame: weaponState.weapon_texture,
    });
    weapon.setOrigin(weaponState.x_anchor, weaponState.y_anchor);
    weapon.setDepth(turretState.height);

    let xShadow = scene.shadowXOffset * 0.75;
    let yShadow = scene.shadowYOffset * 0.75;

    if (turretState.texture.includes('mini')) {
      xShadow = xShadow * 0.5
      yShadow = yShadow * 0.5
    }

    let weaponShadow = scene.make.image({
      x: pos.x + weaponState.real_x_attach + xShadow,
      y: pos.y + weaponState.real_y_attach + yShadow,
      scale: turretState.scale / 100,
      key: 'structures',
      add: true,
      frame: weaponState.weapon_texture,
    });
    weaponShadow.setDepth(turretState.height - 1);
    weaponShadow.setOrigin(weaponState.x_anchor, weaponState.y_anchor);
    weaponShadow.setAlpha(0.4);
    weaponShadow.setTint(0x000000);

    weapon.angle = weaponState.gun_rotate;
    weaponShadow.angle = weaponState.gun_rotate;

    turretBase.weapon = weapon;
    turretBase.weaponShadow = weaponShadow;
  }

  return turretBase
}

function RotateTurretGun(path) {
  if (gameStore.objects[path.id]) {
    let turret = gameStore.objects[path.id].objectSprite;

    ShortDirectionRotateTween(turret.weapon, path.r, path.ms);
    ShortDirectionRotateTween(turret.weaponShadow, path.r, path.ms);
  }
}

export {CreateSpriteTurret, RotateTurretGun}
