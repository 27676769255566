import {CreateObject} from "../map/objects";
import {Scene} from "../create";
import {gameStore} from "../store";
import store from "../../store/store"
import {MapSize, ParseObject} from "../map/createMap";
import {removeDynamicObject} from "../radar/object";
import {rotatePoint} from "../utils/utils";
import {GetGlobalPos} from "../map/gep_global_pos";

let placeStructure;

function SelectPlaceBuild(bpID, bytes, baseData, circles) {

  if (placeStructure) {
    RemoveBuildObject()
  }

  gameStore.HoldAttackMouse = true;

  let structureState = ParseObject(new Uint8Array(bytes))

  $('.nameItemInCell').remove();

  store.commit({
    type: 'toggleWindow',
    id: 'buildDialogWrapper',
    forceClose: true,
  });

  store.commit({
    type: 'toggleWindow',
    id: 'BuildTip',
    forceOpen: true,
  });

  store.commit({
    type: 'setBuildSelectBPID',
    id: bpID,
  });

  placeStructure = CreateObject(structureState, 0, 0, true, Scene);
  placeStructure.objectSprite.removeInteractive();
  placeStructure.baseData = baseData
  placeStructure.bpID = bpID
  placeStructure.zones = {}
  placeStructure.objectCircles = [];
  createObjectCircles(circles)
  createBlockZone()

  placeStructure.objectSprite.alpha = 0.6;
  if (placeStructure.objectSprite.top) placeStructure.objectSprite.top.alpha = 0.6;
  if (placeStructure.objectSprite.weapon) placeStructure.objectSprite.weapon.alpha = 0.6;

  if (placeStructure.objectSprite.top && placeStructure.objectSprite.top.tween) {
    placeStructure.objectSprite.top.tween.stop();
  }

  if (placeStructure.objectSprite.weaponShadow) placeStructure.objectSprite.weaponShadow.destroy();
  if (placeStructure.objectSprite.shadowTop) placeStructure.objectSprite.shadowTop.destroy();

  placeStructure.placeUpdate = setInterval(function () {
    updatePosition(placeStructure)
    CheckPlace();
  }, 32);

  for (let i in gameStore.mapsState) {
    gameStore.mapsState[i].bmdTerrain.bmd.addListener('pointerdown', function (pointer, x, y) {
      if (pointer.leftButtonDown()) {
        // раскрывать подсказку на весь экран где будет подтвеждение или отмена (защита от дурка)
        store.commit({
          type: 'setBuildExpandTip',
          expand: true,
        });
      }

      if (pointer.rightButtonDown()) {
        RemoveBuildObject()
      }
    });
  }
}

function createBlockZone() {
  placeStructure.block_zones = [];
  let radius = 1000

  for (let i of gameStore.map.entry_points) {
    let pos = GetGlobalPos(i.x, i.y, gameStore.map.id);
    let ring = Scene.add.circle(pos.x, pos.y, radius, 0xff0000);
    ring.setAlpha(0.1)
    placeStructure.block_zones.push(ring)
  }

  for (let i of gameStore.map.handlers_coordinates) {
    let pos = GetGlobalPos(i.x, i.y, gameStore.map.id);
    let ring = Scene.add.circle(pos.x, pos.y, radius, 0xff0000);
    ring.setAlpha(0.1)
    placeStructure.block_zones.push(ring)
  }
}

function createObjectCircles(circles) {
  if (!circles || circles.length === 0) return;

  for (let c of circles) {
    if (c.radius === 0 || !c.radius) continue;

    console.log(c.radius)
    let color = 0x000000
    if (c.handler === "base") color = 0x9A32CD
    if (c.handler === "min-weapon") color = 0x9b4d4d
    if (c.handler === "max-weapon") color = 0xff0000
    if (c.handler === "equip") color = 0x0000ff

    let circleLocal = Scene.add.circle(0, 0, c.radius);
    circleLocal.setStrokeStyle(4, color);

    let circleGlobal = Scene.add.circle(0, 0, c.radius);
    circleGlobal.setStrokeStyle(40, color);

    placeStructure.objectCircles.push(circleLocal)
    placeStructure.objectCircles.push(circleGlobal)
    Scene.cameras.main.ignore(circleGlobal)
  }
}

function RemoveBuildObject() {
  if (!placeStructure) return;

  clearInterval(placeStructure.placeUpdate);

  for (let i in gameStore.mapsState) {
    gameStore.mapsState[i].bmdTerrain.bmd.removeListener('pointerdown');
  }

  for (let i of placeStructure.block_zones) {
    i.destroy();
  }

  for (let i in placeStructure.zones) {
    placeStructure.zones[i].destroy()
  }

  for (let i of placeStructure.objectCircles) {
    i.destroy();
  }

  removeDynamicObject(placeStructure, true)
  for (let i in placeStructure.baseData) {
    if (placeStructure.baseData[i].sprite) {
      placeStructure.baseData[i].sprite.destroy();
    }

    if (placeStructure.baseData[i].ring) {
      placeStructure.baseData[i].ring.destroy();
    }
  }

  if (placeStructure.ring) {
    placeStructure.ring.destroy()
  }

  setTimeout(function () {
    gameStore.HoldAttackMouse = false;
  }, 200)

  store.commit({
    type: 'toggleWindow',
    id: 'buildDialogWrapper',
    forceOpen: true,
  });

  store.commit({
    type: 'toggleWindow',
    id: 'BuildTip',
    forceClose: true,
  });

  placeStructure = null;
}

function updatePosition() {

  if (store.getters.getBuildMenu.expandTip) {
    return
  }

  let x = Scene.input.mousePointer.x / Scene.cameras.main.zoom + Scene.cameras.main.worldView.x;
  let y = Scene.input.mousePointer.y / Scene.cameras.main.zoom + Scene.cameras.main.worldView.y;

  placeStructure.objectSprite.x = x;
  placeStructure.objectSprite.y = y;

  if (placeStructure.objectSprite.top) {
    placeStructure.objectSprite.top.x = x;
    placeStructure.objectSprite.top.y = y;
  }

  if (placeStructure.objectSprite.weapon) {
    placeStructure.objectSprite.weapon.x = x;
    placeStructure.objectSprite.weapon.y = y;
  }

  if (!placeStructure.ring) {
    placeStructure.ring = Scene.add.circle(0, 0, 500);
    placeStructure.ring.setStrokeStyle(4, 0x9ACD32);
  }

  let u = gameStore.units[gameStore.playerUnitID]
  if (u) {
    placeStructure.ring.x = u.sprite.x
    placeStructure.ring.y = u.sprite.y
  }

  for (let i of placeStructure.objectCircles) {
    i.setPosition(x, y)
  }

  for (let i in placeStructure.baseData) {
    if (!placeStructure.baseData[i].sprite) {
      placeStructure.baseData[i].sprite = Scene.make.sprite({
        x: placeStructure.objectSprite.x,
        y: placeStructure.objectSprite.y,
        key: placeStructure.baseData[i].handler === 'exit' ? "exit_base_place" : "entry_base_place",
        add: true
      });
      placeStructure.baseData[i].sprite.setOrigin(0.5);
      placeStructure.baseData[i].sprite.setDisplaySize(64, 64)
      placeStructure.baseData[i].sprite.setDepth(999);
      placeStructure.baseData[i].sprite.setAlpha(0.6)

      placeStructure.baseData[i].ring = Scene.add.circle(0, 0, 120, 0xffffff);
      placeStructure.baseData[i].ring.setAlpha(0.2)
    }

    let newPos = rotatePoint(placeStructure.baseData[i].x, placeStructure.baseData[i].y, placeStructure.objectSprite.angle)

    placeStructure.baseData[i].sprite.setPosition(
      placeStructure.objectSprite.x + (newPos.x * placeStructure.objectSprite.scale),
      placeStructure.objectSprite.y + (newPos.y * placeStructure.objectSprite.scale)
    );

    placeStructure.baseData[i].sprite.setAngle(placeStructure.objectSprite.angle)

    if (placeStructure.baseData[i].ring) {
      placeStructure.baseData[i].ring.setPosition(placeStructure.baseData[i].sprite.x, placeStructure.baseData[i].sprite.y)
    }
  }
}

function CheckPlace() {
  store.dispatch("sendSocketData", JSON.stringify({
    service: "corporation",
    data: {
      event: "CheckPlace",
      rotate: Math.round(placeStructure.objectSprite.angle),
      x: Math.round(placeStructure.objectSprite.x - MapSize),
      y: Math.round(placeStructure.objectSprite.y - MapSize),
      id: placeStructure.bpID,
    }
  }));
}

function ResponsePlace(objectCollision, baseDataPlace, zones) {
  if (!placeStructure) return;

  if (objectCollision) {
    placeStructure.objectSprite.setTint(0xff0000);
    if (placeStructure.objectSprite.top) placeStructure.objectSprite.setTint(0xff0000);
    if (placeStructure.objectSprite.weapon) placeStructure.objectSprite.setTint(0xff0000);
  } else {
    placeStructure.objectSprite.setTint(0x00ff00);
    if (placeStructure.objectSprite.top) placeStructure.objectSprite.setTint(0x00ff00);
    if (placeStructure.objectSprite.weapon) placeStructure.objectSprite.setTint(0x00ff00);
  }

  if (baseDataPlace) {
    for (let i in placeStructure.baseData) {
      if (!placeStructure.baseData[i].sprite) {
        continue
      }

      for (let j in baseDataPlace) {
        if (placeStructure.baseData[i].x === baseDataPlace[j].x && placeStructure.baseData[i].y === baseDataPlace[j].y) {
          if (baseDataPlace[j].collision) {
            placeStructure.baseData[i].sprite.setTint(0xff0000);
          } else {
            placeStructure.baseData[i].sprite.setTint(0x00ff00);
          }
        }
      }
    }
  }

  for (let i in placeStructure.zones) {
    placeStructure.zones[i].backUpdate = false
  }

  for (let z of zones) {
    let key = z.x + ":" + z.y;

    let pos = GetGlobalPos(z.x, z.y, gameStore.map.id);
    if (placeStructure.zones.hasOwnProperty(key)) {
      placeStructure.zones[key].backUpdate = true
    } else {
      placeStructure.zones[key] = Scene.add.circle(pos.x, pos.y, z.radius);
      placeStructure.zones[key].setStrokeStyle(4, 0x9A32CD);
      placeStructure.zones[key].backUpdate = true
    }
  }

  for (let i in placeStructure.zones) {
    if (!placeStructure.zones[i].backUpdate) {
      placeStructure.zones[i].destroy()
    }
  }
}

export {SelectPlaceBuild, ResponsePlace, placeStructure, RemoveBuildObject}
