<template>
  <div style="height: 100%">
    <div class="head">
      <label
        :class="{disable: myRole.name !== 'Leader'}">
        <span>{{ texts['text_44'][language] }}</span>
        <input v-model="corporation.info.open"
               class="cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="ChangeOpenCorp"
               type="checkbox">
      </label>
    </div>

    <div class="requests">

      <div class="not_invite_users" v-if="!myRole.invite_users">
        {{ texts['text_45'][language] }}
      </div>

      <div v-for="request in requests">
        <app-user-line v-bind:user="request.short_info"
                       :key="request.short_info.id"
                       v-bind:parent="'corpInviteRequests'"
                       v-bind:parentEl="$parent.$el"/>
        <div class="control">
          <input type="button"
                 :value="texts['button_30'][language]"
                 class="button cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="acceptRequest(request.short_info.id)">

          <input type="button"
                 :value="texts['button_31'][language]"
                 class="button cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="rejectRequest(request.short_info.id)">
        </div>
        <div class="myRequestBlockWrap">
          <div class="myRequestBlock">{{ request.text }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import UserLine from '../Chat/UserLine'

export default {
  name: "requests",
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    ChangeOpenCorp() {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "ChangeOpenCorp",
        }
      }));
    },
    rejectRequest(userID) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "RejectInviteRequestCorp",
          id: Number(userID),
        }
      }));
    },
    acceptRequest(userID) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "AcceptRequest",
          id: Number(userID),
        }
      }));
    }
  },
  computed: {
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    requests() {
      return this.$store.getters.getChatState.corporation.inviteRequest;
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    myRole() {
      let corp = this.corporation;
      if (corp) {
        return corp.roles[corp.members[this.currentPlayer.id].role_id]
      }

      return {}
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
  },
  components: {
    AppUserLine: UserLine,
  }
}
</script>

<style scoped>
.head h3, .head label {
  margin: 0 0 5px 0;
  background: hsla(21, 71%, 54%, 0.4);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 10px;
  line-height: 18px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 5px;
  padding-right: 5px;
  float: left;
}

.head input {
  padding: 0;
  margin: 2px 0 2px 7px;
  float: right;
}

.requests {
  clear: both;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: inset 0 0 2px black;
  height: calc(100% - 22px);
}

.myRequestBlock {
  float: left;
  width: 100%;
  min-height: 25px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  box-shadow: inset 0 0 2px black;
  color: white;
  font-size: 11px;
  word-wrap: break-word;
}

.myRequestBlockWrap {
  float: left;
  padding: 0;
  width: calc(100% - 6px);
}

.control {
  float: right;
  width: 122px;
}

.control input {
  margin: 5px 3px
}

.control input:active {
  transform: scale(0.98);
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.not_invite_users {
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 12px;
  margin: 5px;
}
</style>
<style>
.requests .chatUserLine {
  outline: 1px solid #656565;
  border: 0;
  width: 140px;
  float: left;
}
</style>
