<template>
  <div id="listItem">

    <div class="categoryItem" id="ammoCategoryItem">

      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('ammo', '', 0, 0)">
        {{ filters.selectType === 'ammo' ? '▼' : '▶' }}
        {{ texts['cat_1'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" v-if="filters.selectType === 'ammo'">

        <span v-for="ammo in ammoTypes"
              @mouseover="playSound('select_sound.mp3', 0.3)"
              @click.stop="selectFilter('ammo', ammo.type, 0, 0)" class="category cursor_hover">
          {{ filters.ammo.type === ammo.type ? '▼' : '▶' }} {{ ammo.title }}

          <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.ammo.type === ammo.type">

            <template v-for="type in sizeTypes">
              <span
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click.stop="selectFilter('ammo', ammo.type, type.size, 0)" class="category cursor_hover">
                      {{ filters.ammo.size === type.size ? '▼' : '▶' }} {{ type.title }}

                <div class="scrollFilter items" @click.stop="" v-if="filters.ammo.size === type.size">
                  <div v-for="item in filterItems"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click.stop="selectFilter('ammo', ammo.type, type.size, item.id, item)">
                          {{ handbook['ammo'][language][item.name].name }}
                  </div>
                </div>
              </span>
            </template>
          </div>
        </span>
      </div>
    </div>

    <div class="categoryItem" id="weaponCategoryItem">

      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('weapon', '', 0, 0)">
        {{ filters.selectType === 'weapon' ? '▼' : '▶' }}
        {{ texts['cat_2'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" v-if="filters.selectType === 'weapon'">
        <span v-for="weapon in weaponsTypes"
              @mouseover="playSound('select_sound.mp3', 0.3)"
              @click.stop="selectFilter('weapon', weapon.type, 0, 0)" class="category cursor_hover">
              {{ filters.weapon.type === weapon.type ? '▼' : '▶' }} {{ weapon.title }}

          <div class="scrollFilter" @click.stop="" v-if="filters.weapon.type === weapon.type">
                <template v-for="type in sizeTypes">
                  <span
                    @mouseover="playSound('select_sound.mp3', 0.3)"
                    @click.stop="selectFilter('weapon', weapon.type, type.size, 0)" class="category cursor_hover">
                    {{ filters.weapon.size === type.size ? '▼' : '▶' }} {{ type.title }}
                    <div class="scrollFilter items" @click.stop="" v-if="filters.weapon.size === type.size">
                      <div v-for="item in filterItems"
                           @mouseover="playSound('select_sound.mp3', 0.3)"
                           @click.stop="selectFilter('weapon', weapon.type, type.size, item.id, item)">
                       {{ handbook['weapon'][language][item.name].name }}
                      </div>
                    </div>
                  </span>
                </template>
          </div>
        </span>
      </div>
    </div>

    <div class="categoryItem" id="cabsCategoryItem">

      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('body', '', 0, 0)">
        {{ filters.selectType === 'body' ? '▼' : '▶' }}
        {{ texts['cat_3'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.selectType === 'body'">
          <span v-for="body in bodyTypes"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click.stop="selectFilter('body', '', body.size, 0)" class="category cursor_hover">
              {{ filters.body.size === body.size ? '▼' : '▶' }} {{ body.title }}
              <div class="scrollFilter items" @click.stop="" v-if="filters.body.size === body.size">
                <div v-for="item in filterItems"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.stop="selectFilter('body', '', body.size, item.id, item)">
                  {{ handbook['body'][language][item.name].name }}
                </div>
              </div>
            </span>
      </div>
    </div>

    <div class="categoryItem" id="equipCategoryItem">
          <span class="cursor_hover"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click="selectFilter('equip', '', 0, 0)">
            {{ filters.selectType === 'equip' ? '▼' : '▶' }}
            {{ texts['cat_4'][language] }}
          </span>

      <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.selectType === 'equip'">
          <span v-for="equip in equipTypes"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click.stop="selectFilter('equip', '', equip.size, 0)" class="category cursor_hover">
              {{ filters.equip.size === equip.size ? '▼' : '▶' }} {{ equip.title }}
              <div class="scrollFilter items" @click.stop="" v-if="filters.equip.size === equip.size">
                <div v-for="item in filterItems"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.stop="selectFilter('equip', '', equip.size, item.id, item)">
                  {{ handbook['equip'][language][item.name].name }}
                </div>
              </div>
            </span>
      </div>
    </div>

    <div class="categoryItem" id="resCategoryItem">

      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('resource', '', 0, 0)">
        {{ filters.selectType === 'resource' ? '▼' : '▶' }}
        {{ texts['cat_5'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.selectType === 'resource'">
          <span v-for="resource in resourceTypes"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click.stop="selectFilter('resource', resource.type, 0, 0)"
                class="category">
              {{ filters.resource.type === resource.type ? '▼' : '▶' }} {{ resource.title }}
              <div class="scrollFilter items" @click.stop="" v-if="filters.resource.type === resource.type">
                <div v-for="item in filterItems"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.stop="selectFilter('resource', resource.type, 0, item.id, item)">
                  {{ handbook[resource.type][language][item.name].name }}
                </div>
              </div>
            </span>
      </div>
    </div>

    <div class="categoryItem" id="fuelCategoryItem">
      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('fuel', '', 0, 0)">
        {{ filters.selectType === 'fuel' ? '▼' : '▶' }}
        {{ texts['cat_10'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.selectType === 'fuel'">
        <div v-for="item in filterItems"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="selectFilter('fuel', '', 0, item.id, item)">
          {{ handbook['fuel'][language][item.name].name }}
        </div>
      </div>
    </div>

    <div class="categoryItem" id="bpCategoryItem">

      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('blueprints', '', 0, 0)">
        {{ filters.selectType === 'blueprints' ? '▼' : '▶' }}
        {{ texts['cat_6'][language] }}
      </span>

      <div class="scrollFilter cursor_hover" @click.stop="" v-if="filters.selectType === 'blueprints'">
        <span v-for="blueprint in bpTypes"
              @mouseover="playSound('select_sound.mp3', 0.3)"
              @click.stop="selectFilter('blueprints', blueprint.type, 0, 0)"
              class="category cursor_hover">
                {{ filters.blueprints.type === blueprint.type ? '▼' : '▶' }} {{ blueprint.title }}
                <div class="scrollFilter items" @click.stop="" v-if="filters.blueprints.type === blueprint.type">
                  <div v-for="item in filterItems"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click.stop="selectFilter('blueprints', blueprint.type, 0, item.id, item)">
                    {{ handbook[item.item_type][language][item.item_name].name }}
                  </div>
                </div>
        </span>
      </div>
    </div>

    <div class="categoryItem" id="boxCategoryItem">
      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('boxes', '', 0, 0)">
        {{ filters.selectType === 'boxes' ? '▼' : '▶' }}
        {{ texts['cat_7'][language] }}
      </span>

      <div class="scrollFilter items cursor_hover" @click.stop="" v-if="filters.selectType === 'boxes'">
        <div v-for="item in filterItems"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="selectFilter('boxes', '', 0, item.id, item)">
          {{ handbook['boxes'][language][item.name].name }}
        </div>
      </div>
    </div>

    <div class="categoryItem" id="trashCategoryItem">
      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('trash', '', 0, 0)">
        {{ filters.selectType === 'trash' ? '▼' : '▶' }}
        {{ texts['cat_8'][language] }}
      </span>

      <div class="scrollFilter items cursor_hover" @click.stop="" v-if="filters.selectType === 'trash'">
        <div v-for="item in filterItems"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="selectFilter('trash', '', 0, item.id, item)">
          {{ handbook['trash'][language][item.name].name }}
        </div>
      </div>
    </div>

    <div class="categoryItem" id="productCategoryItem">
      <span class="cursor_hover"
            @mouseover="playSound('select_sound.mp3', 0.3)"
            @click="selectFilter('product', '', 0, 0)">
        {{ filters.selectType === 'product' ? '▼' : '▶' }}
        {{ texts['cat_9'][language] }}
      </span>

      <div class="scrollFilter items cursor_hover" @click.stop="" v-if="filters.selectType === 'product'">
        <div v-for="item in filterItems"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click.stop="selectFilter('product', '', 0, item.id, item)">
          {{ handbook['product'][language][item.name].name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Filter",
  data() {
    return {
      openTables: {}
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    selectFilter(main, type, size, id, item) {
      this.playSound('button_press.mp3', 0.3)

      let newSelectType = '';
      let newType = '';
      let newSize = 0;

      if (main === this.filters.selectType && type === '' && size === 0 && id === 0) {
      } else {
        newSelectType = main;
      }

      if (main === this.filters.selectType && type === this.filters[main].type && size === 0 && id === 0) {
      } else {
        newType = type;
      }

      if (main === this.filters.selectType && type === this.filters[main].type && size === this.filters[main].size && id === 0) {
      } else {
        newSize = size;
      }

      this.$store.commit({
        type: 'setMarketFilter',
        main: newSelectType,
        item: item,
        filterType: newType,
        size: newSize,
        id: id,
      });

      if (this.$parent.tab === 'my_orders') {
        // this.$parent.getPlayersOrders()
      } else {
        this.$parent.getOrders()
      }
    },
  },
  computed: {
    filters() {
      return this.$store.getters.getMarketFilter
    },
    market() {
      return this.$store.getters.getMarket
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Market')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    sizeTypes() {
      return [
        {title: this.texts['size_t1'][this.language], size: 1},
        {title: this.texts['size_t2'][this.language], size: 2},
        {title: this.texts['size_t3'][this.language], size: 3},
      ]
    },
    bodyTypes() {
      return [
        {title: this.texts['body_t1'][this.language], size: 1},
        {title: this.texts['body_t2'][this.language], size: 2},
        {title: this.texts['body_t3'][this.language], size: 3},
      ]
    },
    equipTypes() {
      return [
        {title: 'I', size: 1},
        {title: 'II', size: 2},
        {title: 'III', size: 3},
        {title: this.texts['equip_t3'][this.language], size: 4},
      ]
    },
    bpTypes() {
      // ['ammo', 'body', 'weapon', 'equip', 'detail', 'boxes', 'map']
      return [
        {title: this.texts['bp_t1'][this.language], type: 'ammo'},
        {title: this.texts['bp_t2'][this.language], type: 'body'},
        {title: this.texts['bp_t3'][this.language], type: 'weapon'},
        {title: this.texts['bp_t4'][this.language], type: 'equip'},
        {title: this.texts['bp_t5'][this.language], type: 'detail'},
        {title: this.texts['bp_t6'][this.language], type: 'boxes'},
        {title: this.texts['bp_t7'][this.language], type: 'map'},
      ]
    },
    resourceTypes() {
      return [
        {title: this.texts['r_t1'][this.language], type: 'resource'},
        {title: this.texts['r_t2'][this.language], type: 'recycle'},
        {title: this.texts['r_t3'][this.language], type: 'detail'},
      ]
    },
    ammoTypes() {
      return [
        {title: this.texts['a_t1'][this.language], type: 'laser'},
        {title: this.texts['a_t2'][this.language], type: 'missile'},
        {title: this.texts['a_t3'][this.language], type: 'firearms'},
      ]
    },
    weaponsTypes() {
      return [
        {title: this.texts['w_t1'][this.language], type: 'laser'},
        {title: this.texts['w_t2'][this.language], type: 'missile'},
        {title: this.texts['w_t3'][this.language], type: 'firearms'},
      ]
    },
    filterItems() {
      let items = [];
      console.log(this.market.assortment)

      if (this.filters.selectType === '' || !this.market.assortment) return [];

      let filterType = this.filters[this.filters.selectType];

      if (this.filters.selectType === 'resource') {
        for (let i in this.market.assortment[this.filters[this.filters.selectType].type]) {
          let item = this.market.assortment[this.filters[this.filters.selectType].type][i];
          items.push(item)
        }
      } else {

        if (!this.market.assortment[this.filters.selectType]) return [];

        for (let i in this.market.assortment[this.filters.selectType]) {
          let item = this.market.assortment[this.filters.selectType][i];

          if (this.filters.selectType === 'ammo' || this.filters.selectType === 'weapon') {
            if (filterType.type === item.type && filterType.size === item.standard_size) {
              items.push(item)
            }
          }

          if (this.filters.selectType === 'body') {
            if (filterType.size === item.standard_size) {
              items.push(item)
            }
          }

          if (this.filters.selectType === 'equip') {
            if (filterType.size === item.type_slot) {
              items.push(item)
            }
          }

          if (this.filters.selectType === 'blueprints') {
            if (filterType.type === 'map' && item.in_map) {
              items.push(item)
            } else if (filterType.type === item.item_type) {
              items.push(item)
            }
          }

          if (this.filters.selectType === 'boxes' || this.filters.selectType === 'fuel' || this.filters.selectType === 'trash' || this.filters.selectType === 'product') {
            items.push(item)
          }
        }
      }

      return items
    },
  }
}
</script>

<style scoped>
#listItem {
  border-radius: 4px;
  height: 0;
  width: 180px;
  margin: 0 5px 5px 2px;
  padding-top: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0 0 5px black;
  background: rgba(93, 93, 93, 0.3);
}

.categoryItem {
  text-align: left;
  display: block;
  width: calc(100% - 11px);
  padding-left: 2px;
  background: linear-gradient(22deg, rgb(221, 112, 52), #6c6c6c) center top / 190px 15px no-repeat;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  min-height: 15px;
  user-select: none;
  margin: 2px auto;
  font-weight: bold;
}

.categoryItem:hover {
  background: linear-gradient(22deg, rgb(251, 142, 82), #6c6c6c) center top / 190px 15px no-repeat;
}

.category {
  display: block;
  min-height: 15px;
  user-select: none;
  background: linear-gradient(22deg, rgba(183, 195, 201, 0.29), rgba(183, 195, 201, 0.29)) center top / 190px 15px no-repeat;
  margin: 2px auto;
  padding-left: 4px;
  border-radius: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 1px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.category:hover {
  background: linear-gradient(22deg, rgba(183, 195, 201, 0.59), rgba(183, 195, 201, 0.59)) center top / 190px 15px no-repeat;
}

.categoryItem div > div {
  background-color: rgba(255, 255, 255, 0.2);
  text-indent: 3px;
  padding: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 2px;
  margin: 1px 0;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.categoryItem div > div:hover {
  background-color: rgba(255, 255, 255, 0.4);
}


.categoryItem span {
  height: 100%;
  display: block;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.scrollFilter.items {
  font-size: 12px;
  color: #ffde09;
  margin-top: 3px;
}

</style>
