<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Органика</h2>
        <p>Органика перерабатывается в углерод, который необходим для создания некоторых компонентов. Также органика
          может использоваться в чистом виде для создания топлива и бустеров.</p>
        <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('organic_2', 'png')+ ')',
      width: '353px',
      height: '126px',
      marginLeft: 'calc(50% - 176px)',
      }"/>

        <p>Добыча органики осуществляется посредством переработки растений специальным устройством.</p>

        <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('organic_1', 'gif')+ ')',
      width: '400px',
      height: '290px',
      marginLeft: 'calc(50% - 200px)',
      }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Другие ресурсы</h2>
        <p>Помимо органики, существуют еще два вида ресурсов, которые можно собрать только манипулятором. Они
          используются для создания топлива и бустеров.</p>
        <p>Эти ресурсы выпадают из растений, если их уничтожить.</p>

        <p>Смоляная кислота</p>
        <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('organic_3', 'png')+ ')',
      width: '400px',
      height: '178px',
      marginLeft: 'calc(50% - 200px)',
      }"/>

        <p>Цветок ядрового куста</p>
        <div class="detailImg" :style="{
      backgroundImage: 'url(' + $parent.getUrlImg('organic_4', 'png')+ ')',
      width: '400px',
      height: '150px',
      marginLeft: 'calc(50% - 200px)',
      }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Organics</h2>
        <p>Organics are processed into carbon, which is necessary for creating certain components. Additionally,
          organics can be used in their pure form to create fuel and boosters.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('organic_2', 'png')+ ')',
          width: '353px',
          height: '126px',
          marginLeft: 'calc(50% - 176px)',
          }"/>

        <p>Organics are harvested by processing plants with a special device.</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('organic_1', 'gif')+ ')',
          width: '400px',
          height: '290px',
          marginLeft: 'calc(50% - 200px)',
          }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Other Resources</h2>
        <p>Besides organics, there are two other types of resources that can only be collected with a manipulator. They
          are used to create fuel and boosters.</p>
        <p>These resources drop from plants when they are destroyed.</p>

        <p>Resin Acid</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('organic_3', 'png')+ ')',
          width: '400px',
          height: '178px',
          marginLeft: 'calc(50% - 200px)',
          }"/>

        <p>Core Bush Flower</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('organic_4', 'png')+ ')',
          width: '400px',
          height: '150px',
          marginLeft: 'calc(50% - 200px)',
          }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Organic",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
