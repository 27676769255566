<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Reverses</h2>

      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('Reverses', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
            }"/>
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('reverses_logo_min', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
              marginLeft: '50px'
            }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">“Через перемены, посредством истребления
          отвратительного былого и радикального порождения, мы несём жизнь и спасение…”
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Reverses</i>
        </p>
      </div>

      <br>

      <p>Reverses - фракция синтетов - биокиборгов, погрязших в идее утопизма и самоизоляции от всех прочих. Члены
        данной
        фракции считают себя избранниками “предтеч”, чья великая цель - эволюционная революция, что засеет жизнью
        стерильные миры всей вселенной.</p>

      <p>Право масс, индивидуальность, невыполнимое предназначение - именно с этим столкнуться те синтеты, что пожелают
        вступить в Reverses. </p>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Reverses</h2>
      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('Reverses', 'png')+ ')',
        width: '150px',
        height: '150px',
        float: 'left',
      }"/>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('reverses_logo_min', 'png')+ ')',
        width: '150px',
        height: '150px',
        float: 'left',
        marginLeft: '50px'
      }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">"Through changes, by exterminating the
          abominable past and radical genesis, we bring life and salvation..."
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Reverses</i>
        </p>
      </div>

      <br>

      <p>Reverses - a faction of synthetics - biocyborgs, mired in the idea of utopianism and self-isolation from all
        others. Members of this faction consider themselves the chosen ones of the "precursors," whose great goal is an
        evolutionary revolution that will seed life in the sterile worlds of the entire universe.</p>

      <p>The right of the masses, individuality, an unfulfillable destiny - this is what those synthetics who wish to
        join the Reverses will face.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Reverses",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
