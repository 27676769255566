<template>
  <div id="processorRoot" ref="processorRoot" @mousedown="toUp">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'processorRoot'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 128, width: 445}"/>

    <div class="error_message" :class="{error_message_visible: recyclerResultToStorage}">
      <div style="margin-top: 14%" v-html="texts['to_storage'][language]"/>
    </div>

    <div class="error_message" :class="{error_message_visible: noUseRecycleService}">
      <div style="margin-top: 14%" v-html="texts['text_2'][language]"/>
    </div>

    <div class="not_allow"
         v-if="relationBonuses && relationBonuses[baseStatus.fraction] && relationBonuses[baseStatus.fraction].locked_up_recycle">
      <div class="not_allow_text">
        {{ texts['not_allow'][language] }}
      </div>
    </div>

    <app-items-drop-zone/>

    <div class="itemsPools">
      <div class="pollHead" style="background: #a59a9a;">
        <div>
          <div id="RecyclePercent">
            <div id="fillBackPercent" :style="{width: recycler.user_recycle_skill + '%'}"></div>
            <span id="UserRecyclePercent">
              <span id="losesText">{{ texts['lost_items'][language] }}</span> {{
                recycler.user_recycle_skill
              }} %
          </span>
          </div>
          <div class="util"></div>
        </div>
      </div>

      <div class="RecycleSection" v-for="(items, section) in lostItems" :key="section">

        <div class="nameSection">{{ handbook['categories'][language][section].name }}
          <div class="hideArrowSection"></div>
        </div>

        <app-item-cell v-for="(item,index) in items"
                       :key="item.item.name + item.type + index"
                       v-if="item.quantity > 0"
                       v-bind:itemSlot="item"
                       v-bind:size="cellSize"/>

      </div>
    </div>

    <div class="itemsPools">
      <div class="pollHead" style="background: #a59a9a;">
        <h3>{{ texts['tax'][language] }} <span class="importantly">{{ tax }}</span>%</h3>
      </div>

      <div class="RecycleSection" v-for="(items, section) in taxItems" :key="section">

        <div class="nameSection">{{ handbook['categories'][language][section].name }}
          <div class="hideArrowSection"></div>
        </div>

        <app-item-cell v-for="(item,index) in items"
                       :key="item.item.name + item.type + index"
                       v-bind:itemSlot="item"
                       v-bind:size="cellSize"/>

      </div>
    </div>

    <div class="itemsPools" id="previewPool">
      <div class="pollHead">
        <h3>{{ texts['result'][language] }}</h3>
      </div>

      <div class="RecycleSection" v-for="(items, section) in outputItems" :key="section">

        <div class="nameSection">{{ handbook['categories'][language][section].name }}
          <div class="hideArrowSection"></div>
        </div>

        <app-item-cell v-for="(item,index) in items"
                       :key="item.item.name + item.type + index"
                       v-bind:itemSlot="item"
                       v-bind:size="cellSize"/>

      </div>
    </div>

    <input type="button" class="lobbyButton inventoryTip cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="close"
           :value="texts['button_1'][language]"
           style="pointer-events: auto;">
    <input type="button" class="lobbyButton inventoryTip cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="recycle"
           :value="texts['button_2'][language]"
           style="pointer-events: auto;">

  </div>
</template>

<script>
import Control from '../Window/Control';
import ItemsDropZone from './ItemsDropZone';
import ItemCell from '../Inventory/ItemCell';

export default {
  name: "ProcessorRoot",
  data() {
    return {
      cellSize: 38,
    }
  },
  created() {
    this.clearProcessor();
  },
  methods: {
    close() {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'processorRoot',
        component: '',
        forceClose: true,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    clearProcessor() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "ClearProcessor",
        }
      }));
    },
    recycle() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "recycle",
        }
      }));
    },
    resize(event, ui, el) {
      el.find('.itemsPools').css("width", el.width() / 4 - 4);
      el.find('.itemsPools').css("height", el.height() - 38);

      if (el.width() < 460) {
        el.find('#losesText').css('display', 'none')
      } else {
        el.find('#losesText').css('display', 'unset')
      }

      el.find('#RecyclePercent').css("width", el.width() / 4 - 45);
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('ProcessorRoot')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    recycler() {
      return this.$store.getters.getRecyclerState
    },
    outputItems() {
      // todo повторящийся код
      let sections = {};

      let need_items_slots = this.$store.getters.getRecyclerState.preview_recycle_slots;
      for (let i in need_items_slots) {

        let slot = need_items_slots[i];
        if (!sections.hasOwnProperty(slot.type)) {
          sections[slot.type] = [];
        }
        sections[slot.type].push(slot);
      }

      return sections;
    },
    lostItems() {
      // todo повторящийся код
      let sections = {};

      let need_items_slots = this.$store.getters.getRecyclerState.lost_recycle_slots;
      for (let i in need_items_slots) {

        let slot = need_items_slots[i];
        if (!sections.hasOwnProperty(slot.type)) {
          sections[slot.type] = [];
        }
        sections[slot.type].push(slot);
      }

      return sections;
    },
    taxItems() {
      // todo повторящийся код
      let sections = {};

      let need_items_slots = this.$store.getters.getRecyclerState.tax_recycle_slots;
      for (let i in need_items_slots) {

        let slot = need_items_slots[i];
        if (!sections.hasOwnProperty(slot.type)) {
          sections[slot.type] = [];
        }
        sections[slot.type].push(slot);
      }

      return sections;
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    },
    recyclerResultToStorage() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('recycler_result_to_storage') && errors['recycler_result_to_storage'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'recycler_result_to_storage',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    noUseRecycleService() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('no_use_recycle_service') && errors['no_use_recycle_service'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'no_use_recycle_service',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    tax() {
      let need_items_slots = this.$store.getters.getRecyclerState.recycle_slots;
      for (let s in need_items_slots) {
        for (let i in need_items_slots[s]) {
          return need_items_slots[s][i].tax_percent
        }
      }

      return 0
    }
  },
  components: {
    AppControl: Control,
    AppItemsDropZone: ItemsDropZone,
    AppItemCell: ItemCell,
  }
}
</script>

<style>
#processorRoot {
  padding-top: 3px;
  width: 560px;
  height: 300px;
  position: absolute;
  display: block;
  border-radius: 5px;
  top: 50px;
  left: calc(50% - 400px);
  z-index: 11;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#processorRoot .itemsPools {
  border-radius: 2px;
  width: 266px;
  height: 240px;
  float: left;
  box-shadow: inset 0 0 2px black;
  background: rgba(137, 150, 156, 0.3);
  margin: 17px 2px 0 2px;
  text-align: left;
  overflow-x: hidden;
  overflow-y: scroll;
}

#processorRoot .itemsPools .pollHead {
  margin: 4px;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  width: calc(100% - 8px);
}

#processorRoot .itemsPools .pollHead h3 {
  margin: 0;
  padding: 0 4px;
  display: block;
  width: calc(100% - 4px);
  height: 14px;
  float: left;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#RecyclePercent {
  height: 15px;
  width: 90px;
  float: right;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 11px;
  line-height: 17px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  box-shadow: inset 0 0 2px black;
  overflow: hidden;
  margin: 1px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#fillBackPercent {
  background-size: 10px 2px;
  background-image: linear-gradient(1deg, rgb(255 118 0), rgb(0 0 0) 6px);
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
  box-shadow: inset 0 0 2px black;
}

#processorRoot .itemsPools .pollHead .util {
  height: 15px;
  width: 15px;
  background: url(https://img.icons8.com/flat_round/64/000000/recycle-sign--v1.png) center center / cover no-repeat;
  float: right;
  margin-right: 1px;
  margin-top: 1px;
}

#processorRoot .lobbyButton {
  display: block;
  width: 40%;
  pointer-events: auto;
  font-size: 12px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin: 3px 5%;
}

.nameSection {
  clear: both;
  width: calc(100% - 14px);
  height: 14px;
  font-size: 12px;
  margin: 2px 0 1px 1px;
  padding-left: 11px;
  border: 1px solid #793f1f;
  background-size: 10px 2px;
  background-image: linear-gradient(1deg, rgba(251, 93, 0, 0.55), rgba(225, 37, 46, 0.6) 6px);
  color: #ff7800;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
}

.RecycleSection {
  float: left;
  width: 100%;
}

#processorRoot .lobbyButton:active {
  transform: scale(0.98);
}

#processorRoot .lobbyButton:hover {
  background: rgba(255, 129, 0, 1);
}

.not_allow {
  height: calc(100% - 16px);
  width: 100%;
  position: absolute;
  left: 0;
  top: 17px;
  background: rgba(125, 125, 125, 0.3);
  z-index: 20;
  backdrop-filter: blur(10px);
}

.not_allow_text {
  margin-top: 15%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  opacity: 0.8;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}
</style>
