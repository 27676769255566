var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: сектора")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Карта мира состоит из секторов. Что бы увидеть всю карту мира надо нажать кнопку карты на нижней панели.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_map_1', 'png')+ ')',
          width: '341px',
          height: '73px',
          marginLeft: 'calc(50% - 170px)',
          })}),_vm._v(" "),_c('p',[_vm._v("На карте мира можно посмотреть связи между секторами, проложить маршрут до базы/сектора или активировать\n      автопилот.")]),_vm._v(" "),_c('p',[_vm._v("Если вы проложите маршрут то на мини карте появятся линии показывающие куда следовать.")]),_vm._v(" "),_c('p',[_vm._v("Если вы активируете автопилот то транспорт начнет самостоятельное движение до цели. Использовать автопилот не\n      рекомендуется т.к. этот режим сильно ограничен а алгоритмы управления не оптимальны.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_map_2', 'png')+ ')',
          width: '480px',
          height: '346px',
          marginLeft: 'calc(50% - 240px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Переход между сектора осуществляется через телепорт, для перехода надо заехать в него как на базу или встать в\n      центре.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_1', 'png')+ ')',
          width: '366px',
          height: '180px',
          marginLeft: 'calc(50% - 183px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Sectors")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("The world map consists of sectors. To see the entire world map, you need to press the map button on the bottom\n      panel.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_map_1', 'png')+ ')',
          width: '341px',
          height: '73px',
          marginLeft: 'calc(50% - 170px)',
          })}),_vm._v(" "),_c('p',[_vm._v("On the world map, you can view the connections between sectors, plot a route to a base/sector, or activate\n      autopilot.")]),_vm._v(" "),_c('p',[_vm._v("If you plot a route, lines will appear on the mini-map showing where to follow.")]),_vm._v(" "),_c('p',[_vm._v("If you activate the autopilot, the transport will start moving independently to the destination. It is not\n      recommended to use autopilot because this mode is very limited and the control algorithms are not optimal.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_map_2', 'png')+ ')',
          width: '480px',
          height: '346px',
          marginLeft: 'calc(50% - 240px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Transition between sectors is carried out through a teleport, to transition you need to drive into it as to a\n      base or stand in the center.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('teleport_1', 'png')+ ')',
          width: '366px',
          height: '180px',
          marginLeft: 'calc(50% - 183px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Во время выполнения этого задания "),_c('span',{staticClass:"importantly"},[_vm._v("рекомендуется не ввязываться в драки")]),_vm._v(" и\n      отступать на базу и телепорты, "),_c('span',{staticClass:"importantly"},[_vm._v("но")]),_vm._v(" оружие активируется как добывающее снаряжение\n      и\n      целится надо мышкой.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("During the execution of this task, it is "),_c('span',{staticClass:"importantly"},[_vm._v("recommended not to engage in fights")]),_vm._v("\n      and\n      retreat to the base and teleports, "),_c('span',{staticClass:"importantly"},[_vm._v("but")]),_vm._v(" the weapon is activated as mining\n      equipment\n      and\n      must be aimed with the mouse.")])
}]

export { render, staticRenderFns }