<template>
  <div class="SendCredits" id="SendCredits" ref="SendCredits" @mousedown="toUp">
    <!--  TODO рефакторинг требует наши сердца-->

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'SendCredits'"
                 v-bind:noHeight="true"
                 v-bind:no-pos="true"
                 v-bind:noWidth="true"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 255, width: 300}"/>

    <div class="tabs">
      <div v-bind:class="{active: tab === 'transfer'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('transfer')">
        {{ texts['transfer'][language] }}
      </div>

      <div v-bind:class="{active: tab === 'history'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('history')">
        {{ texts['history'][language] }}
      </div>
    </div>

    <template v-if="tab === 'transfer'">
      <div class="inner_block">

        <div style="height: 45px; float: left; width: 100%">
          <h3>{{ texts['text_1'][language] }}</h3>

          <select v-model="fromDeposit" key="1">
            <option value="-1" selected>{{ texts['option_1'][language] }}</option>
            <template v-if="corporationAll">
              <option v-for="d in corporationAll.available_withdraw_deposits"
                      :value="d.id">
                {{ texts['cluster'][language] }} "{{ corporationAll.info.name }}": {{ d.name }}
              </option>
            </template>
          </select>
        </div>

        <div style="height: 71px; float: left; width: 100%">
          <h3>{{ texts['text_2'][language] }}
            <span class="importantly">
              {{ user ? texts['player'][language] : '' }}
              {{ corporation ? texts['cluster'][language] : '' }}
            </span>
          </h3>
          <div class="search" v-if="!user && !corporation">

            <select v-model="object" key="1">
              <option value="player" selected>{{ texts['player'][language] }}</option>
              <option value="corporation">{{ texts['cluster'][language] }}</option>
            </select>

            <input v-model="userName" class="cursor_hover" type="text" :placeholder="texts['placeholder_1'][language]"/>
            <input type="button"
                   class="cursor_hover"
                   :value="texts['button_1'][language]"
                   @mouseover="playSound('select_sound.mp3', 0.2)"
                   @click="search">

            <div class="not_found" v-if="notFound">
              {{ texts['nothing'][language] }}
            </div>
          </div>

          <div v-if="object === 'player' && user" style="float: left; width: calc(100% - 6px); margin-left: 5px;">
            <app-user-line :user="user"
                           :parent="'SendCredits'"
                           :buttonExit="true"
                           :meta="{id: user.id}"
                           :exitFunc="removeUser"
            />
          </div>

          <div v-if="object === 'corporation' && corporation"
               style="float: left; width: calc(100% - 6px); margin-left: 5px;">

            <app-corporation-line :corp="corporation.corporation"
                                  :logo="corporation.logo"
                                  :parent="'SendCredits'"
                                  :buttonExit="true"
                                  :exitFunc="removeCorp"/>

            <select v-model="deposit" key="2" style="margin: 2px 2px 2px -3px;width: calc(100% - -3px);">
              <option v-for="d in corporation.deposits" :value="d.id" :selected="d.main"> {{ d.name }}</option>
            </select>
          </div>
        </div>

        <h3>{{ texts['text_3'][language] }}</h3>
        <div class="QuantityRange">
          <div>
            <div class="iconItem">
              <app-background-item-cell v-bind:slotItem="{type: 'credits', item: {name: 'credits'}}"/>
            </div>

            <div style="margin-top: 10px; float: left; width: calc(100% - 46px);">
              <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="splitChangeCount(-1)">
                -1
              </div>
              <input class="range_r cursor_hover" type="range" v-model="count" min="1" :max="maxCredits/100">
              <div class="range_button cursor_hover"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="splitChangeCount(1)">+1
              </div>
            </div>

            <input style="width: calc(100% - 58px)" class="range_n cursor_hover" type="number" v-model="count" min="1"
                   :max="maxCredits/100"/>

            <input class="cause_text cursor_hover" style="margin: 0 2px 2px 2px; width: calc(100% - 14px)"
                   maxlength="255"
                   v-model="Cause"
                   type="text"
                   :placeholder="texts['placeholder_2'][language]">
          </div>
        </div>
      </div>

      <input class="send_button cursor_hover"
             :class="{disable: (!user && !corporation) || count < 1 || count > maxCredits}"
             :value="texts['button_2'][language]"
             @mouseover="playSound('select_sound.mp3', 0.2)"
             @click="Send">
    </template>
    <template v-if="tab === 'history'">
      <div class="inner_block" style="height: calc(100% - 19px)">
        <table class="history_credits">
          <tr>
            <th>{{ texts['text_4'][language] }}</th>
            <th></th>
            <th>{{ texts['text_5'][language] }}</th>
          </tr>
          <tr v-for="log in logs">
            <td v-html="getOperationName(log.cause)"></td>
            <td v-html="prepareHistoryCredits(log)"></td>
            <td v-html="log.player_cause"></td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import UserLine from '../Chat/UserLine';
import CorporationLine from '../Corporation/CorporationLine';
import Vue from "vue";
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import {urls} from "../../const";

export default {
  name: "SendCredits",
  props: ['meta'],
  data() {
    return {
      object: 'player',
      userName: '',
      user: null,
      corporation: null,
      deposit: 0,
      count: 1,
      notFound: false,
      tab: "transfer",
      logs: [],
      Cause: '',
      fromDeposit: '-1',
    }
  },
  mounted() {
    if (this.$props.meta && this.$props.meta.user) {
      Vue.set(this, 'user', this.$props.meta.user);
    }

    if (this.$props.meta && this.$props.meta.corp_name) {
      Vue.set(this, 'object', 'corporation');
      Vue.set(this, 'userName', this.$props.meta.corp_name);

      if (this.$props.meta.deposit) {
        Vue.set(this, 'deposit', this.$props.meta.deposit);
      }

      this.search()
    }

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "market",
      data: {
        event: "GetCredits",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetAvailableWithdrawDeposits",
      }
    }));
  },
  methods: {
    resize(event, ui, el) {

    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    prepareHistoryCredits(log) {
      let credits = log.count
      let color = 'yellowgreen'
      let prefix = '+'
      if (log.operation === 'take') {
        prefix = '-'
        color = '#ff6f6f'
      }

      return `<span style="color: ${color};">${prefix}${(credits / 100).toFixed(2)} cr.</span>`
    },
    search() {
      let app = this;

      if (this.object === 'player') {
        app.$api.get(urls.userInfoURL + '?user_name=' + app.userName).then(function (response) {
          if (!response.data) {
            app.notFound = true;
            setTimeout(function () {
              app.notFound = false;
            }, 1000)
          }
          Vue.set(app, 'user', response.data);
        });
      }

      if (this.object === 'corporation') {
        app.$api.get(urls.corpInfoURL + '?corporation_name=' + app.userName).then(function (response) {
          app.$set(app, 'corporation', response.data);
          if (!response.data) {
            app.notFound = true;
            setTimeout(function () {
              app.notFound = false;
            }, 1000)
          } else {

            for (let d of response.data.deposits) {
              if (d.main && app.deposit === 0) {
                app.deposit = d.id
              }
            }

            app.getCorpLogo();
          }
        });
      }
    },
    getCorpLogo() {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + app.corporation.corporation.id).then(function (response) {
        app.$set(app.corporation, 'logo', response.data.avatar);
      });
    },
    removeUser() {
      Vue.set(this, 'user', null);
    },
    removeCorp() {
      Vue.set(this, 'corporation', null);
    },
    splitChangeCount(change) {
      this.playSound('button_press.mp3', 0.3)

      this.count = Number(this.count)
      if (isNaN(this.count)) {
        this.count = 0
      }

      this.count += change
      if (this.count < 1) {
        this.count = 1
      }

      if (this.count > this.maxCredits / 100) {
        this.count = Number(this.maxCredits / 100)
      }
    },
    Send() {
      this.playSound('button_press.mp3', 0.3)

      let id
      if (this.object === 'player') id = this.user.id
      if (this.object === 'corporation') id = this.corporation.corporation.id

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "SendCredits",
          id: id,
          credits: Number(this.count) * 10,
          type: this.object,
          dst: Number(this.deposit),
          src: Number(this.fromDeposit),
          data: this.Cause
        }
      }));
    },
    changeTab(tab) {
      if (tab === 'history') {
        this.getHistory()
      }

      this.tab = tab
    },
    getHistory() {
      let app = this;
      app.$api.get(urls.getHistoryCredits + '?id=' + app.currentPlayer.id).then(function (response) {
        app.logs = response.data
      });
    },
    getOperationName(operation) {
      if (operation.includes('transfer_from_corp_')) {
        let name = operation.split('_')[3];
        return this.texts['on_1'][this.language].replace('%name%', name)
      }

      if (operation.includes('transfer_to_corp_')) {
        let name = operation.split('_')[3];
        return this.texts['on_2'][this.language].replace('%name%', name)
      }

      if (operation.includes('transfer_from_')) {
        let name = operation.split('_')[2];
        return this.texts['on_3'][this.language].replace('%name%', name)
      }

      if (operation.includes('transfer_to_')) {
        let name = operation.split('_')[2];
        return this.texts['on_4'][this.language].replace('%name%', name)
      }

      if (operation.includes('corporation_tax')) {
        return this.texts['on_5'][this.language]
      }

      if (operation === "choice_fraction") return this.texts['on_6'][this.language]
      if (operation === "npc_sell") return this.texts['on_7'][this.language]
      if (operation === "npc_buy") return this.texts['on_8'][this.language]
      if (operation === "market_sell") return this.texts['on_9'][this.language]
      if (operation === "market_buy") return this.texts['on_10'][this.language]
      if (operation === "market_place") return this.texts['on_11'][this.language]
      if (operation === "market_cancel") return this.texts['on_12'][this.language]
      if (operation === "mission") return this.texts['on_13'][this.language]
      if (operation === "skin_buy") return this.texts['on_14'][this.language]
      if (operation === "transfer_robbery") return this.texts['on_15'][this.language]
      if (operation === "repair") return this.texts['on_16'][this.language]
      if (operation === "item_repair") return this.texts['on_17'][this.language]
      if (operation === "fraction_store_buy") return this.texts['on_18'][this.language]
      if (operation === "search") return this.texts['on_19'][this.language]
      if (operation === "kill_10.0") return this.texts['on_20'][this.language]
      if (operation === "kill_3.0") return this.texts['on_21'][this.language]
      if (operation === "kill_1.5") return this.texts['on_22'][this.language]
      if (operation === "arm transport") return this.texts['on_23'][this.language]
      if (operation === "fill up transport") return this.texts['on_24'][this.language]

      return operation
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('SendCredits')
    },
    market() {
      return this.$store.getters.getMarket
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    corporationAll() {
      return this.$store.getters.getChatState.corporation;
    },
    maxCredits() {
      if (Number(this.fromDeposit) === -1) {
        return this.market.my_credits
      }

      if (this.corporationAll) {
        for (let i in this.corporationAll.available_withdraw_deposits) {
          if (this.corporationAll.available_withdraw_deposits[i].id === Number(this.fromDeposit)) {
            return this.corporationAll.available_withdraw_deposits[i].credits
          }
        }
      }

      return 0
    }
  },
  components: {
    AppControl: Control,
    AppUserLine: UserLine,
    AppBackgroundItemCell: BackgroundItemCell,
    AppCorporationLine: CorporationLine,
  }
}
</script>

<style scoped>
.SendCredits {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  height: 255px;
  width: 300px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.search {
  float: left;
  width: 100%;
  position: relative;
}

.search input {
  width: 100%;
  margin-bottom: 4px;
}

.search input[type="button"], .send_button {
  display: block;
  width: 47px;
  pointer-events: auto;
  font-size: 13px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: right;
  margin: 2px 2px 0 0;
}

.send_button {
  width: 109px;
  float: left;
  margin-top: 3px;
  margin-left: 2px;
  height: 14px;
}

.search input[type="button"]:hover, .send_button:hover {
  background: rgba(255, 129, 0, 1);
}

.search input[type="button"]:active, .send_button:active {
  transform: scale(0.98);
}

.search input[type=button]:active {
  transform: scale(0.97);
}

.search input[type=text] {
  width: calc(100% - 65px);
  margin-left: 2px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.inner_block {
  width: 100%;
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  background: rgba(137, 150, 156, 0.3);
  height: calc(100% - 37px);
}

h3 {
  margin: 3px 0 3px 3px;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  float: left;
  width: calc(100% - 15px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.QuantityRange {
  width: calc(100% - 10px);
  height: 65px;
  display: block;
  background-size: 100% 2px;
  border-radius: 5px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
  float: left;
  margin: 1px 1px 4px 4px;
}

.iconItem {
  float: left;
  height: 40px;
  width: 40px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  margin: 3px;
  border-radius: 5px;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: -5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.range_r {
  float: left;
  margin-top: -6px;
  width: calc(100% - 32px);
  margin-bottom: 5px;
  padding: 0;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.range_n, .cause_text {
  float: left;
  margin-top: 0;
  color: #ff7800;
  width: 217px;
  height: 14px;
  text-indent: 1px;
  text-align: right;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  text-shadow: 1px 1px 1px black;
}

.range_n::-webkit-outer-spin-button,
.range_n::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.range_n {
  -moz-appearance: textfield;
}

.not_found {
  height: 21px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(137, 150, 156, 0.7);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-shadow: 1px 1px 1px black;
}

.tabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 4px 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tabs div:last-child {
  /*border: 0;*/
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.tabs div:hover {
  color: #fff200 !important;
}

.history_credits {
  width: 100%;
  border-spacing: 0;
  word-wrap: break-word;
  table-layout: fixed;
}

.history_credits tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 13;
}

.history_credits td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  padding-top: 4px;
  font-size: 13px;
  position: relative;
}

input[type=range]::-webkit-slider-thumb {
  height: 15px;
}

select {
  outline: none;
  width: calc(100% - 3px);
  border-radius: 5px;
  margin: 0 0 4px 2px;
  border: 0;
  background: rgb(0, 0, 0, 0.3);
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  color: #ff7800;
  font-size: 11px;
  text-shadow: 1px 1px 1px black;
}

select * {
  color: black;
  font-weight: bold;
}
</style>
