import {CreateSelectSprite, destroyAllSelectedSprite} from "./create_select_sprite";
import {Scene} from "../create";
import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MapSize} from "../map/createMap";
import store from "../../store/store";

function MoveEntryPoint() {

  let callBack = function (x, y, r) {
    destroyAllSelectedSprite();

    let selectSprite = Scene.make.image({
      x: x,
      y: y,
      key: "baseResp",
      add: true
    });

    selectSprite.setOrigin(0.5);
    selectSprite.setDepth(1000);
    selectSprite.setScale(0.05);
    selectSprite.setInteractive();
    selectSprite.setAngle(r);
    gameStore.mapEditorState.placeObj = selectSprite;

    let updatePos = setInterval(function () {
      selectSprite.x = Math.round(Scene.input.mousePointer.worldX);
      selectSprite.y = Math.round(Scene.input.mousePointer.worldY);
    }, 10);

    selectSprite.on('pointerdown', function (pointer) {
      if (pointer.leftButtonDown()) {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "map_editor",
          data: {
            event: "moveEntryPoint",
            id: gameStore.mapEditorState.mpID,
            x: x,
            y: y,
            to_x: Number(selectSprite.x - MapSize),
            to_y: Number(selectSprite.y - MapSize),
            rotate: Number(selectSprite.angle),
          }
        }));
      }

      selectSprite.destroy()
      clearInterval(updatePos)
      gameStore.mapEditorState.placeObj = null;
    });
  };

  SelectedEntry(callBack)
}

function SelectedEntry(callBack, type) {
  if (gameStore.gameReady) {
    let map = gameStore.map;

    for (let point of map.entry_points) {
      let pos = GetGlobalPos(point.x, point.y, gameStore.map.id);
      if (!point.resp_rotate) point.resp_rotate = 0;
      CreateSelectSprite(pos.x, pos.y, callBack, true, point.resp_rotate);
    }
  }
}

export {MoveEntryPoint}
