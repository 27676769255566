<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Replics</h2>
      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('Replics', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
            }"/>
        <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('replics_logo_min', 'png')+ ')',
              width: '150px',
              height: '150px',
              float: 'left',
              marginLeft: '50px'
            }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">“Истинный синтет не имеет страха перед
          забвением и ничтожным существованием будущего. Он боится
          лишь бесславия и отсутствия своего пути.”
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Replic</i>
        </p>
      </div>

      <br>

      <p>Replics - автократической формы правления фракция синтетиков. Приверженец теории “войны за независимость с
        предтечами” и яркий отголосок неумолимого экспансионизма. Подчинение, ассимиляция, распространение - вот
        основные
        движущие принципы данной фракции. </p>

      <p>Любой мир - великий производственный цех. Любая продукция - мощности для покорения всего материального.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Replics</h2>
      <div class="content_section" style="margin-left: calc(50% - 300px);">
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('Replics', 'png')+ ')',
        width: '150px',
        height: '150px',
        float: 'left',
      }"/>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('replics_logo_min', 'png')+ ')',
        width: '150px',
        height: '150px',
        float: 'left',
        marginLeft: '50px'
      }"/>

        <p class="bracket" style="float: left; width: 200px; margin-left: 15px">"A true synthetic has no fear of
          oblivion and the insignificant existence of the future. He only fears disgrace and the absence of his path."
          <br>
          <br>
          <i style="text-align: right; width: 100%; display: block;">Replic</i>
        </p>
      </div>

      <br>

      <p>Replics - an autocratic form of governance faction of synthetics. An adherent of the theory of "war for
        independence with the precursors" and a vivid echo of relentless expansionism. Subjugation, assimilation,
        dissemination - these are the main driving principles of this faction.</p>

      <p>Any world is a great production workshop. Any product is power for conquering all that is material.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Replics",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
