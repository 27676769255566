var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Навык")]),_vm._v(" "),_c('p',[_vm._v("Навыки позволяют немного улучшить характеристики используемого оборудования.")]),_vm._v(" "),_c('p',[_vm._v("Что бы открыть меню навыков надо открыть \"Личное дело\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_skill_1', 'png')+ ')',
          width: '343px',
          height: '213px',
          marginLeft: 'calc(50% - 171px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Откроется меню вашего персонажа, надо перейти во вкладку \"Навыки\". Найдите в меню необходимый вам навык и нажми\n      кнопку со стрелкой вверх.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_skill_2', 'png')+ ')',
          width: '491px',
          height: '292px',
          marginLeft: 'calc(50% - 245px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Вы великолепны.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Skill")]),_vm._v(" "),_c('p',[_vm._v("Skills allow you to slightly improve the characteristics of the equipment used.")]),_vm._v(" "),_c('p',[_vm._v("To open the skills menu, you need to open \"Personal File\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_skill_1', 'png')+ ')',
          width: '343px',
          height: '213px',
          marginLeft: 'calc(50% - 171px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Your character's menu will open, you need to go to the \"Skills\" tab. Find the skill you need in the menu and\n      press\n      the up arrow button.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_skill_2', 'png')+ ')',
          width: '491px',
          height: '292px',
          marginLeft: 'calc(50% - 245px)',
          })}),_vm._v(" "),_c('p',[_vm._v("You are magnificent.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }