<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Синхронизация</h2>
        <p>Чем больше синтет использует корпус, тем сильнее его связь с ним, что позволяет повышать показатели различных
          систем. Этот процесс называется "синхронизация".</p>
        <p>По сути, это навык для конкретного корпуса. Чем больше опыта зарабатывает игрок, тем сильнее прокачивается
          его синхронизация.</p>

        <ul>
          <li>Каждые 5% синхронизации дают 1 очко для усиления подсистемы (атака, защита, навигация, энергия, ходовая
            часть).
          </li>
          <li>Каждые 25% открывают или усиливают перк корпуса.</li>
        </ul>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('sync_2', 'png')+ ')',
            width: '394px',
            height: '285px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <p>Очки подсистем усиливают все свойства корпуса конкретной категории на 1%. На одну подсистему можно потратить
          только 10 очков.</p>
        <p>Перки усиливают корпус по определённой специализации. Например, если это лёгкий боевой корпус, то каждые 25%
          будут усиливать малые орудия на 5%, а грузовик — расширять свой трюм на 10%. Со временем планируется расширить
          перки до более узких специализаций: дроны, щиты, добывающее снаряжение и т.д.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('sync_1', 'png')+ ')',
            width: '400px',
            height: '290px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">

        <h2 class="head">Synchronization</h2>
        <p>The more a synthetic uses a body, the stronger its connection with it becomes, allowing for the enhancement
          of various system parameters. This process is called "synchronization".</p>
        <p>Essentially, it is a skill for a specific body. The more experience a player gains, the stronger their
          synchronization becomes.</p>

        <ul>
          <li>Every 5% synchronization gives 1 point to enhance a subsystem (attack, defense, navigation, energy,
            propulsion).
          </li>
          <li>Every 25% unlocks or enhances a body perk.</li>
        </ul>

        <p>Subsystem points enhance all properties of the body in a specific category by 1%. Only 10 points can be spent
          on one subsystem.</p>
        <p>Perks enhance the body according to a specific specialization. For example, if it is a light combat body,
          every 25% will enhance small weapons by 5%, while a truck will expand its cargo hold by 10%. Over time, it is
          planned to expand perks to more specific specializations: drones, shields, mining equipment, etc.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('sync_1', 'png')+ ')',
            width: '400px',
            height: '290px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Sync",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
