import {statusBarWrapper} from "./status_bar";
import {gameStore} from "../store";
import {createBar} from "./battle_state";
import {GetColorDamage} from "./status_layer";
import store from "../../store/store";
import {VueEx} from "../../main";
import {md5} from "../utils/md5";
import {Scene} from "../create";

let state = {
  background: null,
  width: 600,
  height: 50,
  alpha: 0.4,
  barAlpha: 0.6,
  baseBars: {
    width: 132, top: 10, centerOffset: 65, height: 16, shieldHeight: 6,
    currentBars: {},
  },
  commonData: {
    time: {
      text: '', sprite: null,
    }, mainMission: {
      text: '', sprite: null,
    },
  },
  corporations: {
    width: 32,
    height: 32,
    top: 10,
    centerOffset: 65,
  },
  corporationsLogo: {},
  corporationsData: [],
  active: false,
}

function updateSiegeData() {
  if (!statusBarWrapper.scene) return;
  if (!gameStore.sectorSiegeState.active && !state.active) return;

  if (!state.background) {
    createBackground();
  }

  updateCommonData();

  if (gameStore.sectorSiegeState.active) {
    fillBaseStatus();
    fillCorporations();
  }

  if (!gameStore.sectorSiegeState.active) {
    setTimeout(function () {
      siegeStateReset()
    }, 3000)
  }

  state.active = gameStore.sectorSiegeState.active
}

function siegeStateReset() {
  if (state.background) {
    state.background.destroy();
    state.background = null;
  }

  removePayload()
}

function removePayload() {
  if (state.commonData.time.sprite) state.commonData.time.sprite.destroy();
  if (state.commonData.mainMission.sprite) state.commonData.mainMission.sprite.destroy();
  state.commonData.time.text = '';
  state.commonData.mainMission.text = '';

  if (state.baseBars.currentBars.hp && state.baseBars.currentBars.hp.inner) state.baseBars.currentBars.hp.inner.destroy();
  if (state.baseBars.currentBars.shield && state.baseBars.currentBars.shield.inner) state.baseBars.currentBars.shield.inner.destroy();

  for (let i in state.corporationsData) {
    clearCorpLogo(state.corporationsData[i].id)
  }
}

let clearCorpLogo = function (id) {
  if (!state.corporationsLogo[id]) return;

  if (state.corporationsLogo[id].back) {
    state.corporationsLogo[id].back.destroy();
    state.corporationsLogo[id].back = null;
  }
  if (state.corporationsLogo[id].logo) {
    state.corporationsLogo[id].logo.destroy();
    state.corporationsLogo[id].logo = null;
  }
  if (state.corporationsLogo[id].count) {
    state.corporationsLogo[id].count.destroy();
    state.corporationsLogo[id].count = null;
  }

  delete state.corporationsLogo[id]
}

function fillCorporations() {
  let corps = []
  for (let id in gameStore.sectorSiegeState.hostiles) {
    if (!state.corporationsLogo.hasOwnProperty(id)) {
      state.corporationsLogo[id] = {};
    }

    if (!state.corporationsLogo[id].hasOwnProperty('textureKey')) {
      getCorpLogo(id);
      continue
    }

    if (gameStore.sectorSiegeState.hostiles[id] === 0) {
      continue
    }

    corps.push({id: id, count: gameStore.sectorSiegeState.hostiles[id]})
  }

  corps.sort(function (a, b) {
    return a.count - b.count;
  });

  if (state.corporationsData.length > corps.length) {
    for (let i in state.corporationsData) {
      if (i + 1 > corps.length) {
        clearCorpLogo(state.corporationsData[i].id)
      }
    }
  }

  for (let i in corps) {

    let id = corps[i].id

    if (!state.corporationsLogo[id]) return;

    if (typeof state.corporationsData[i] === 'undefined' || state.corporationsData[i].id !== id) {
      if (typeof state.corporationsData[i] !== 'undefined') clearCorpLogo(state.corporationsData[i].id)
    }

    if (!state.corporationsLogo[id].back) {
      let back = createCorpLogoBack(i)
      state.corporationsLogo[id].back = back.back;
      state.corporationsLogo[id].x = back.x;
      state.corporationsLogo[id].y = back.y;
    }

    if (!state.corporationsLogo[id].logo || state.corporationsLogo[id].logo.texture.key === '__MISSING') {
      if (state.corporationsLogo[id].logo && (state.corporationsLogo[id].logo.texture.key === '__MISSING')) {
        state.corporationsLogo[id].logo.destroy();
      }

      state.corporationsLogo[id].logo = statusBarWrapper.scene.make.image({
        x: state.corporationsLogo[id].x + 1,
        y: state.corporationsLogo[id].y + 1,
        key: state.corporationsLogo[id].textureKey,
        add: true
      });
      state.corporationsLogo[id].logo.setOrigin(0);
      state.corporationsLogo[id].logo.setDisplaySize(state.corporations.width - 2, state.corporations.height - 2);
      state.corporationsLogo[id].logo.setDepth(2000)
    }

    if (!state.corporationsLogo[id].count || state.corporationsData[i].count !== corps[i].count) {
      if (state.corporationsLogo[id].count) state.corporationsLogo[id].count.destroy();

      state.corporationsLogo[id].count = statusBarWrapper.scene.add.bitmapText(state.corporationsLogo[id].x + 3, state.corporationsLogo[id].y + 2, 'bit_text', corps[i].count, 14);
      state.corporationsLogo[id].count.setLetterSpacing(2)
      state.corporationsLogo[id].count.setDepth(2000)
    }
  }

  state.corporationsData = corps
}

function fillBaseStatus() {
  let fill = function (bar, percent, color, height) {

    if (bar.percent === percent) {
      return
    }
    bar.percent = percent

    let width = (state.baseBars.width - 2) * (percent / 100);

    if (bar.inner) {
      bar.inner.clear();
    } else {
      bar.inner = statusBarWrapper.scene.add.graphics({
        x: state.background.x + bar.x, y: state.background.y + bar.y, add: true,
      });
      bar.inner.setDepth(1999)
    }

    bar.inner.fillStyle(color, state.barAlpha);
    bar.inner.fillRect(1, 1, width, height - 3);
  }

  fill(state.baseBars.currentBars.hp, gameStore.sectorSiegeState.hp,
    Phaser.Display.Color.HexStringToColor(GetColorDamage(gameStore.sectorSiegeState.hp).color).color, state.baseBars.height)

  fill(state.baseBars.currentBars.shield, gameStore.sectorSiegeState.shield, 0x0070ff, state.baseBars.shieldHeight)
}

function updateCommonData() {

  let timeOut = timeOutMinutes()
  let mainMission = getMainMissionText()

  if (state.commonData.time.text !== timeOut.minutes + " : " + timeOut.sec) {
    // TODO кеширование цифр от 0 до 60ти и подставлять 2 спрайта, удаление и создание это дорого
    if (state.commonData.time.sprite) state.commonData.time.sprite.destroy()
    state.commonData.time.text = timeOut.minutes + " : " + timeOut.sec

    state.commonData.time.sprite = statusBarWrapper.scene.add.bitmapText(state.background.x + (state.width / 2), state.background.y + 5, 'bit_text', timeOut.minutes + " : " + timeOut.sec, 14);
    state.commonData.time.sprite.setLetterSpacing(2)
    state.commonData.time.sprite.x -= state.commonData.time.sprite.width / 2
    state.commonData.time.sprite.setDepth(2000)
  }

  if (state.commonData.mainMission.text !== mainMission) {

    if (state.commonData.mainMission.sprite) state.commonData.mainMission.sprite.destroy()
    state.commonData.mainMission.text = mainMission

    state.commonData.mainMission.sprite = statusBarWrapper.scene.add.bitmapText(state.background.x + (state.width / 2), state.background.y + 21, 'bit_text', mainMission, 14);
    state.commonData.mainMission.sprite.setLetterSpacing(1)
    state.commonData.mainMission.sprite.setCenterAlign()
    state.commonData.mainMission.sprite.x -= state.commonData.mainMission.sprite.width / 2
    state.commonData.mainMission.sprite.setDepth(2000)
  }
}

function createBackground() {

  state.height = 65
  state.background = statusBarWrapper.scene.add.renderTexture((statusBarWrapper.scene.cameras.main.displayWidth / 2) - (state.width / 2), 0, state.width, state.height);
  state.background.setOrigin(0);

  // общий бек
  let backGraphics = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, 0, state.alpha, 0, state.alpha);
  backGraphics.fillRect(0, 0, state.width / 3, state.height);

  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, state.alpha, state.alpha, state.alpha, state.alpha);
  backGraphics.fillRect(state.width / 3, 0, state.width / 3, state.height);

  backGraphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, state.alpha, 0, state.alpha, 0);
  backGraphics.fillRect((state.width / 3) * 2, 0, state.width / 3, state.height);

  state.background.draw(backGraphics);
  backGraphics.destroy();

  // заголовок базу
  let Header = statusBarWrapper.scene.add.bitmapText(0, state.baseBars.top - 4, 'bit_text', "Состояние базы", 16);
  Header.setLetterSpacing(1)
  Header.setOrigin(0, 0)

  Header.x = (state.width / 2) - Header.width - state.baseBars.centerOffset
  Header.setTint(0x4699FF)
  state.background.draw(Header);
  Header.destroy()

  // заголовок атакующих
  Header = statusBarWrapper.scene.add.bitmapText(0, state.baseBars.top - 4, 'bit_text', "Атакующие", 16);
  Header.setLetterSpacing(1)
  Header.setOrigin(0, 0)

  Header.x = (state.width / 2 + state.baseBars.centerOffset)
  Header.setTint(0xFF5153)
  state.background.draw(Header);
  Header.destroy()

  // хп бар базы
  let leftX = (state.width / 2 - state.baseBars.centerOffset) - state.baseBars.width;
  let leftY = state.baseBars.top + 13;

  let hpBar = createBar(leftX, leftY, state.baseBars.width, state.baseBars.height)
  state.background.draw(hpBar);
  hpBar.destroy();

  let energyBar = createBar(leftX, leftY + state.baseBars.height - 1, state.baseBars.width, state.baseBars.shieldHeight)
  state.background.draw(energyBar);
  energyBar.destroy();

  state.baseBars.currentBars = {
    hp: {
      x: leftX, y: leftY,
    },
    shield: {
      x: leftX, y: leftY + state.baseBars.height - 1,
    }
  }
}

function timeOutMinutes() {

  let time = gameStore.sectorSiegeState.endTime - Math.floor((new Date()).getTime() / 1000)

  time = Math.round(time)
  if (time <= 0) {
    return {minutes: '--', sec: '--'};
  }

  let minutes = Math.floor(time / 60);
  let sec = time % 60;
  return {minutes: (minutes % 60 < 10 ? "0" : "") + minutes, sec: (sec % 60 < 10 ? "0" : "") + sec};
}

function getMainMissionText() {
  if (!gameStore.sectorSiegeState.active) {
    return "Осада завершилась!"
  }

  let time = gameStore.sectorSiegeState.endTime - Math.floor((new Date()).getTime() / 1000)
  time = Math.round(time)
  if (time <= 0) {
    return "Уничтожьте врага"
  }

  return "Защищайте базу"
}

function getCorpLogo(id) {
  store.dispatch('getCorporationLogo', {
    id: id,
    vm: VueEx
  });

  let logo = store.getters.getCorpLogo[id];
  if (!logo || logo.fake) {
    return
  }

  let pic = logo.logo.substring(5)
  pic = pic.substring(0, pic.length - 2)
  state.corporationsLogo[id].textureKey = 'corp_logo' + md5(pic) + id;
  if (gameStore.corporationLogoKeys.hasOwnProperty(state.corporationsLogo[id].textureKey)) {
    return;
  }

  try {
    Scene.textures.addBase64(state.corporationsLogo[id].textureKey, pic);
    gameStore.corporationLogoKeys[state.corporationsLogo[id].textureKey] = true
  } catch (e) {
    console.log(e)
  }
}

function createCorpLogoBack(i) {
  let x = (statusBarWrapper.scene.cameras.main.displayWidth / 2) + state.corporations.centerOffset + (i * (state.corporations.width + 2))
  let y = state.corporations.top + 13

  return {
    back: createBar(x, y, state.corporations.width, state.corporations.height), x: x, y: y
  }
}

export {updateSiegeData, siegeStateReset}
