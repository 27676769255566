<template>
  <div id="DamageLog" ref="DamageLog">
    <app-control v-bind:head="'лог урона'"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:resizeFunc="resize"
                 v-bind:refWindow="'DamageLog'"
                 v-bind:minSize="{height: 150, width: 350}"
    />

    <div class="button" @click="full = !full">full: {{ full }}</div>

    <div class="wrapper">
      <table class="log_table"
             v-if="full" v-for="l in log"
             :key="l.uuid"
             :ref="l.uuid"
      >
        <tr>
          <td>начальный урон:</td>
          <td><span style="color: darkorange">{{ l.st }}</span></td>
          <td>(k: {{ l.k }}%, t:{{ l.t }}%, e:{{ l.ex }}%)</td>
        </tr>
        <tr>
          <td>итоговый урон:</td>
          <td><span style="color: orangered">{{ l.fd }}</span></td>
          <td>(k: {{ l.kd }}, t:{{ l.td }}, e:{{ l.ed }})</td>
        </tr>
        <tr>
          <td>оружие: {{ l.wid }}</td>
          <td>снаряд: {{ l.aid }}</td>
        </tr>
        <tr>
          <td>
            <span style="color: deepskyblue">{{ l.ss }}</span> -> <span style="color: deepskyblue">{{ l.fs }}</span>
          </td>
          <td colspan="2">
            <span style="color: greenyellow">{{ l.sh }}</span> -> <span style="color: greenyellow">{{ l.fh }}</span>
          </td>
        </tr>
      </table>

      <table class="log_table"
             v-if="!full" v-for="l in log"
             style="margin: 0"
             :key="l.uuid"
             :ref="l.uuid">

        <tr>
          <td style="width: 33%">
            <span style="color: orangered">{{ l.fd }}</span>
          </td>
          <td style="width: 33%">
            <span style="color: deepskyblue">{{ l.ss }}</span> -> <span style="color: deepskyblue">{{ l.fs }}</span>
          </td>
          <td style="width: 33%">
            <span style="color: greenyellow">{{ l.sh }}</span> -> <span style="color: greenyellow">{{ l.fh }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "DamageLog",
  data() {
    return {
      full: false
    }
  },
  methods: {
    resize(event, ui, el) {

    },
    scrollToBottom(id) {
      let wait = setInterval(function () {
        let el = this.$refs[id];
        if (el && el.length > 0) {
          clearInterval(wait)
          el[0].scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
        }
      }.bind(this));
    },
  },
  computed: {
    log() {
      let messages = this.$store.getters.getDamageLog
      if (messages.length > 0) {
        this.scrollToBottom(messages[messages.length - 1].uuid)
      }

      return messages;
    }
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#DamageLog {
  position: absolute;
  background-size: 100% 2px;
  border-radius: 5px;
  height: 150px;
  width: 350px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.log_table {
  width: 100%;
  border-spacing: 0;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  text-align: center;
}

.log_table td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: papayawhip;
  font-size: 12px;
}

.wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.button {
  display: block;
  width: 50px;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 13px;
  border-radius: 5px;
  color: #fff;
  line-height: 14px;
  box-shadow: 0 0 2px #000;
  z-index: 3;
  float: right;
  margin-left: 2px;
  position: absolute;
  top: 2px;
  right: 27px;
}

.button:hover {
  background: #ff8100;
}

.button:active {
  transform: scale(0.98);
}
</style>
