<template>
  <div id="allGroupsWindow" ref="allGroupsWindow" @mousedown="toUp">

    <app-control v-bind:head="'Чат Каналы'"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'allGroupsWindow'"/>

    <div class="mask" v-if="needPass.group"></div>
    <div id="chatPassBlock" v-if="needPass.group">
      <input id="enterChatPassword"
             type="text"
             v-model="needPass.pass"
             :placeholder="texts['placeholder_1'][language]">
      <input type="button"
             :value="texts['button_3'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="subscribe()">
      <input type="button"
             :value="texts['button_4'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="subscribe(needPass.group, needPass.pass)">
    </div>

    <div id="ViewChatGroups">
      <div>
        <h4>{{ texts['text_7'][language] }}</h4>
        <div class="chatGroup" v-for="group in groups.public"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="subscribe(group)">
          <div class="chatLogo" v-bind:style="{backgroundImage: avatars[group.id]}">{{ getAvatarGroup(group.id) }}</div>
          <div class="chatName">{{ group.name }}</div>
          <div class="chatPlayerCount">{{ getCountUsers(group) }}</div>
          <div class="public" v-if="group.secure"></div>
        </div>
      </div>

      <div id="userGroups">
        <h4>{{ texts['text_8'][language] }}</h4>
        <input v-model="filter" id="filterChatGroup" type="text" placeholder="поиск группы...">
        <div class="chatGroup" v-for="group in groups.users" v-if="group.name.indexOf(filter) + 1"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="subscribe(group)">
          <div class="chatLogo" v-bind:style="{backgroundImage: avatars[group.id]}">{{ getAvatarGroup(group.id) }}</div>
          <div class="chatName">{{ group.name }}</div>
          <div class="chatPlayerCount">{{ getCountUsers(group) }}</div>
          <div class="public" v-if="group.secure"></div>
        </div>
      </div>
    </div>
    <div>
      <input type="button"
             :value="texts['button_5'][language]"
             :class="{disable: true}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="openCreateDialog">
      <input type="button"
             :value="texts['button_6'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="closeWindow">
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import {urls} from '../../const';

export default {
  name: "ViewAllGroups",
  data() {
    return {
      avatars: {},
      filter: '',
      needPass: {
        group: null,
        pass: '',
      },
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "chat",
      data: {
        event: "GetAllGroups",
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    openCreateDialog() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.commit({
        type: 'toggleWindow',
        id: 'NewChatCreateWrapper',
        component: '',
      });
    },
    closeWindow() {
      this.playSound('window_close.mp3', 0.3)

      this.$store.commit({
        type: 'toggleWindow',
        id: 'allGroupsWindow',
        component: '',
      });
    },
    subscribe(group, pass) {
      this.playSound('button_press.mp3', 0.3)

      if (!group) {
        this.needPass.group = null;
        this.needPass.pass = '';
        return
      }

      if (group.secure && !pass) {
        this.needPass.group = group;
        this.needPass.pass = '';
      } else {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "chat",
          data: {
            event: "SubscribeGroup",
            id: Number(group.id),
            password: pass,
          }
        }));

        this.needPass.group = null;
        this.needPass.pass = '';
      }
    },
    getCountUsers(group) {
      return `${this.countPlayers[group.id].online} / ${this.countPlayers[group.id].all}`
    },
    getAvatarGroup(id) {
      let app = this;
      if (app.avatars.hasOwnProperty(id)) return;
      app.$api.get(urls.groupAvatarURL + '?chat_group_id=' + id).then(function (response) {
        app.$set(app.avatars, id, "url('" + response.data.avatar + "')");
      });
    }
  },
  computed: {
    groups() {
      let allGroups = this.$store.getters.getChatState.allGroups;

      let groups = {
        users: [],
        public: [],
      };

      for (let i in allGroups) {
        if (allGroups.hasOwnProperty(i)) {
          if (allGroups[i].user_create) {
            groups.users.push(allGroups[i])
          } else {
            groups.public.push(allGroups[i])
          }
        }
      }

      return groups;
    },
    countPlayers() {
      return this.$store.getters.getChatState.allGroupsCountPlayers
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#ViewChatGroups {
  border-radius: 5px;
  width: 100%;
  height: 277px;
  float: left;
  margin-top: 3px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0 0 2px black;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}

#allGroupsWindow {
  top: 20%;
  left: calc(50% - 100px);
  height: 300px;
  width: 230px;
  position: absolute;
  background-size: 100% 2px;
  border-radius: 5px;
  padding: 20px 3px 3px 3px;
  background: rgb(8, 138, 210);
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

#allGroupsWindow .topButton {
  margin-top: 5px;
}

#allGroupsWindow h3 {
  display: block;
  height: 17px;
  background: linear-gradient(0deg, red, yellow);
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  font-size: 15px;
  margin: 3px 3px 0 3px;
  font-weight: 100;
  padding: 0 5px;
}

#allGroupsWindow h4 {
  margin: 4px;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  width: calc(100% - 7px);
  text-indent: 5px;
}

#allGroupsWindow input {
  margin-bottom: 3px;
  float: left;
}

#allGroupsWindow input[type=button] {
  display: block;
  width: 40%;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin: 3px 5%;
}

#allGroupsWindow input[type=button]:hover {
  background: rgba(255, 129, 0, 1);
}

#allGroupsWindow input[type=button]:active {
  transform: scale(0.98);
}

#allGroupsWindow input[type="button"]:active {
  transform: scale(0.95);
}

#userGroups input {
  width: calc(100% - 20px);
  margin-left: 5px;
}

.chatGroup {
  clear: both;
  height: 25px;
  border-radius: 5px;
  width: 208px;
  padding: 2px 5px 2px;
  color: #ff7800;
  font-size: 15px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.chatGroup:hover {
  background: linear-gradient(22deg, rgba(183, 195, 201, 0.29), rgba(183, 195, 201, 0.29));
}

.chatGroup:active {
  transform: scale(0.95)
}

.chatLogo {
  height: 25px;
  width: 25px;
  background: rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.chatName {
  width: 91px;
  line-height: 25px;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
  color: #ffe510;
  text-shadow: 1px 1px 1px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

.chatPlayerCount {
  width: 60px;
  line-height: 25px;
  font-size: 12px;
  text-align: left;
  text-shadow: 1px 1px 1px black;
  color: #f1f1f1;
}

.chatGroup > div {
  float: left;
}

.chatGroup .chatPlayerCount {
  text-align: center;
}

.chatGroup .public {
  height: 24px;
  width: 20px;
  background-size: cover;
  background-image: url("https://img.icons8.com/plasticine/100/000000/lock-2.png");
}

#chatPassBlock {
  position: absolute;
  left: calc(50% - 100px);
  top: calc(20%);
  width: 200px;
  height: 55px;
  display: block;
  border-radius: 5px;
  z-index: 12;
  border: 1px solid #25a0e1;
  background: rgb(8, 138, 210);
}

#chatPassBlock input[type="text"] {
  width: 177px;
  margin: 6px;
}

#chatPassBlock input[type="button"] {
  margin-top: 0;
  margin-left: 14px;
  z-index: 1;
  width: 73px;
}

.mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  border-radius: 5px;
}

.userGroups .chatName {
  text-shadow: 1px 1px 1px black;
}
</style>
