<template>
  <div style="height: calc(100% - 5px);">
    <h3> {{ texts['text_27'][language] }} {{ online }}</h3>

    <div class="corporation_members">
      <app-user-line v-for="member in corporation.members"
                     :key="member.id"
                     v-bind:user="member.short_info"
                     v-bind:online="member.online"
                     v-bind:title="corporation.roles[member.role_id].name"
                     v-bind:parent="'corporation'"
                     v-bind:parentEl="$parent.$el"
                     v-bind:currentRole="member.role_id"
                     v-bind:changeRole="changeRole"
                     v-bind:noMenu="changeRole"
                     v-bind:changeRoleDisable="corporation.roles[member.role_id].name === 'Leader' || member.id === currentPlayer.id"
      />
    </div>

    <div class="management">
      <input type="button" :class="{disable: !myRole.change_role_user}" class="button cursor_hover"
             :value="changeRole ? texts['button_22'][language] : texts['button_23'][language]"
             @click="openChangeRoleMenu">

      <input v-if="changeRole" type="button" class="button cursor_hover" style="float: right"
             :value="texts['button_24'][language]"
             @click="saveRoles">
    </div>
  </div>
</template>

<script>
import UserLine from '../Chat/UserLine';

export default {
  name: "Members",
  data() {
    return {
      changeRole: false,
    }
  },
  methods: {
    openChangeRoleMenu() {
      this.changeRole = !this.changeRole;
    },
    saveRoles() {
      for (let i of $('.corporation_members').find('.userTitleChangeSelect')) {
        if ($(i).data('disable')) continue;

        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "corporation",
          data: {
            event: "changeRoleUserInCorporation",
            id: Number($(i).val()),
            player_id: Number($(i).data('player-id')),
          }
        }));
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "GetCorporation",
        }
      }));

      this.changeRole = false
    }
  },
  computed: {
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    online() {
      let online = 0;
      let count = 0;

      for (let i in this.corporation.members) {
        let member = this.corporation.members[i];
        count++;
        if (member.online) {
          online++;
        }
      }

      return `${online}/${count}`
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    myRole() {
      let corp = this.corporation;
      if (corp) {
        return corp.roles[corp.members[this.currentPlayer.id].role_id]
      }

      return {}
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
  },
  components: {
    AppUserLine: UserLine,
  }
}
</script>

<style scoped>
h3 {
  margin: 0 0 5px 0;
  background: hsla(21, 71%, 54%, 0.4);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 10px;
  line-height: 13px;
  height: 12px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 5px;
  padding-right: 5px;
  float: left;
}

.corporation_members {
  clear: both;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 10px;
  box-shadow: inset 0 0 2px black;
  height: calc(100% - 40px);
}

.management {

}

.management input {
  width: 112px;
  transition: 100ms;
}

.management input:active {
  transform: scale(0.98);
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}
</style>
