var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.lang==='RU')?[_c('h3',{staticClass:"head"},[_vm._v("Время суток")]),_vm._v(" "),_c('p',[_vm._v("На планете Veliri-5 время суток ровняется 24 часам, ночью становится ощутимо темнее и обзор транспорта\n      изменяется\n      в худшую сторону.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('times_of_day_1', 'gif')+ ')',
            width: '316px',
            height: '177px',
            marginLeft: 'calc(50% - 158px)'
          })}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('times_of_day_2', 'gif')+ ')',
            width: '390px',
            height: '365px',
            marginLeft: 'calc(50% - 195px)'
          })}),_vm._v(" "),_c('p',[_vm._v("Время можно отслеживать на табло ниже мини карты.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('times_of_day_3', 'png')+ ')',
            width: '261px',
            height: '241px',
            marginLeft: 'calc(50% - 130px)'
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?void 0:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Самое темное время на планете с 3х до 5ти ночи, тогда видимость транспорта уменьшается на 40%. Однако если\n      включить фары ("),_c('span',{staticClass:"importantly"},[_vm._v("L")]),_vm._v(") то негативный эффект будет в 2 раза меньше.")])
}]

export { render, staticRenderFns }