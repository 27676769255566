import {BinaryReader} from "../store/ws/readers/binary_read";
import store from "../store/store";

let messagesQueue = {
  queue: [],
  serverMsgCount: 0,
};

function AddMessagesQueue(msgBytes) {
  if (msgBytes[0] === 100) {
    messagesQueue.serverMsgCount++
  }
  messagesQueue.queue.push(msgBytes);
}

let serverLag = 1; // искуственное оставание от сервера что бы было получше на херовом интернете
// TODO отдавать на обработку буферезированый кадр если новый не пришел за N времени

function ParseMsg() {
  let timeOut = 0
  while (messagesQueue.serverMsgCount > serverLag) {
    timeOut++

    if (timeOut > 256) {
      console.log("need reload") // TODO
      break
    }
    // if (messagesQueue.length > 3) {
    //   console.log(messagesQueue.length)
    // }

    let msgBytes = messagesQueue.queue.shift()
    if (msgBytes[0] === 100) {
      messagesQueue.serverMsgCount--
    }

    BinaryReader(msgBytes, store)
  }

  // console.log(messagesQueue.length)
}

export {ParseMsg, AddMessagesQueue}
