<template>
  <div id="loginWrapper">

    <div id="mask"></div>
    <div id="loginBlock" ref="loginBlock">

      <div class="language">
        <div class="cursor_hover" :class="{disable_language: lang !=='RU'}"
             @click="changeLanguage('RU')"/>
        <div class="EN cursor_hover" :class="{disable_language: lang !=='EN'}"
             @click="changeLanguage('EN')"/>
      </div>

      <app-control v-bind:head="lang ==='RU' ? 'Логин' : 'Login'"
                   v-bind:move="false"
                   v-bind:close="false"
                   v-bind:refWindow="'loginBlock'"/>

      <template v-if="!steam.steam">
        <form method="POST" id="login" onsubmit="return false;">
          <table>
            <tr>
              <td><input class="cursor_hover" type="text"
                         :placeholder="lang ==='RU' ? 'пользователь' : 'user'"
                         name="username"
                         v-model="user"></td>
            </tr>
            <tr>
              <td><input class="cursor_hover" type="password"
                         :placeholder="lang ==='RU' ? 'пароль' : 'password'"
                         name="password"
                         v-model="password"></td>
            </tr>
            <tr>
              <td class="social disableSocial">
                <div class="vk_oauth" @click="windowVKOpen"/>
                <div class="steam_oauth" @click="windowSteamOpen"/>
              </td>
            </tr>
            <tr id="buttonCell" style="width: 110px; padding: 2px 5px">
              <td style="width: 110px; padding: 2px 5px">
                <input type="submit" class="button cursor_hover"
                       :value="lang ==='RU' ? 'Войти' : 'Login'"
                       @click="login">
                <input style="float: right" type="submit" class="button cursor_hover"
                       :value="lang ==='RU' ? 'Регистрация' : 'Registration'"
                       @click="to('/registration')">
              </td>
            </tr>
          </table>
          <div class="Failed" id="error" style="margin-top: 15px">{{ err }}</div>
        </form>
      </template>
      <template v-if="steam.steam">
        <template v-if="steam.status === 1">
          <div class="text">
            {{
              lang === 'RU' ?
                'Что то прошло не так, попробуйте перезапустить игру.' :
                'Something went wrong, try restarting the game.'
            }}
          </div>

          <input style="float: left; margin-left: calc(50% - 42px);" type="submit" class="button cursor_hover"
                 :value="lang ==='RU' ? 'Закрыть' : 'Close'"
                 @click="close()">
        </template>
        <template v-if="steam.status === 0">
          <div class="loader_wrapper">
            <app-preloader/>
          </div>
          <input style="float: left; margin-left: calc(50% - 42px);" type="submit" class="button cursor_hover"
                 :value="lang ==='RU' ? 'Закрыть' : 'Close'"
                 @click="close()">
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import {urls} from '../../const'
import store from "../../store/store";
import Preloader from "../Preloader/Preloader";

export default {
  name: "Login",
  data() {
    return {
      user: null,
      password: null,
      err: '',
      vkAuth: false,
    }
  },
  mounted() {
    this.$store.commit({
      type: 'setVisibleLoader',
      visible: false,
    });
  },
  methods: {
    to(url) {
      this.$router.push({path: url});
    },
    close() {
      window.close()
    },
    changeLanguage(language) {
      this.$store.commit({
        type: 'setLang',
        lang: language,
      });
    },
    windowVKOpen() {
      let app = this;

      app.code = this.$route.query.code
      app.$api.get(urls.vkOAuthUrl).then(function (response) {

        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=300,height=200,left=100,top=100`;
        window.open(response.data.o_auth_url, "vk_auth", params)

        let checkCookie = setInterval(function () {
          let cookies = document.cookie.split(";")
          for (let cookie of cookies) {

            let cookieName = cookie.split("=")[0]

            if (cookieName.trim() === "Veliri-key" && cookie.split("=")[1] !== '') {
              clearInterval(checkCookie);
              app.load();
            }
          }
        }, 100)
      });
    },
    windowSteamOpen() {

    },
    login: function () {

      let app = this;
      app.err = '';

      this.$store.commit({
        type: 'setToken',
        data: '',
      });

      store.commit({
        type: 'closeWS',
      });

      window.localStorage.removeItem(urls.authTokenKey);
      app.$store.commit({
        type: 'setToken',
        data: ""
      });

      this.$api.post(urls.loginURL, {
        username: this.user,
        password: this.password,
      }, {
        withCredentials: true,
      }).then(function (response) {
        if (response.data.success) {

          try {
            window.localStorage.setItem(urls.authTokenKey, response.data.token)
          } catch (e) {
            console.log(e)
            console.log("in-memory token mod")
            app.$store.commit({
              type: 'setToken',
              data: response.data.token
            });
          }

          app.load();
        } else {
          app.err = "Не верный логин либо пароль"
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    load() {
      let app = this;

      this.$store.commit({
        type: 'reconnectWS',
      });

      app.$store.commit({
        type: 'setVisibleLoader',
        visible: true,
        text: {
          'RU': 'Пытаемся понять что происходит...',
          'EN': 'We are trying to figure out what is going on...',
        }
      });

      setTimeout(function () {
        app.$router.push({path: '/gate'});
      }, 1000);
    }
  },
  computed: {
    lang() {
      return this.$store.getters.getIndexLanguage
    },
    steam() {
      return this.$store.getters.getSteam
    }
  },
  components: {
    AppControl: Control,
    AppPreloader: Preloader,
  }
}
</script>

<style scoped>

#loginWrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #7f7f7f;
  background-image: url('../../assets/bases/base.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  pointer-events: auto;
}

#buttonCell {
  text-align: left;
}

#buttonCell td {
  padding-top: 1px;
}

td {
  width: 100px;
  padding: 2px 10px;
}

th {
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

#loginBlock {
  position: absolute;
  left: calc(50% - 90px);
  top: 20%;
  display: block;
  border-radius: 5px;
  width: 180px;
  height: 110px;
  z-index: 11 !important;
  padding: 20px 0 0 0;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

#login input[type="text"], #login input[type="password"] {
  text-shadow: none;
  color: black;
}

input[type="submit"] {
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  width: 48%;
}

input[type="submit"]:hover {
  background: #ff8100;
}

input[type="submit"]:active {
  transform: scale(.98);
}

#mask {
  position: absolute;
  z-index: 1 !important;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.social {
  padding: 0;
}

.social div {
  height: 27px;
  width: 27px;
  background-size: contain;
  filter: drop-shadow(0px 0px 1px black);
  margin-left: 10px;
  float: left;
}

.social div:hover {
  filter: drop-shadow(0px 0px 1px yellow);
}

.social div:active {
  transform: scale(0.95);
}

.Failed {
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
}

.disableSocial {
  pointer-events: none;
  opacity: 0.3;
}

.language {
  position: absolute;
  left: calc(100% + 2px);
  box-shadow: 0 0 2px 0 black;
  border-radius: 0 2px 2px 0;
  top: 17px;
  border: 1px solid #009aeb7d;
  border-left: 0;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding-left: 3px;
  border-left: none;
}

.language div {
  height: 16px;
  width: 16px;
  background-size: cover;
  filter: drop-shadow(0 0 1px black);
  background-image: url('../../assets/icons/RU_language.png');
  border-radius: 3px;
  margin: 2px 2px 2px 0;
}

.language div.EN {
  background-image: url('../../assets/icons/EN_language.png');
}

.language .disable_language {
  filter: grayscale(75%);
  opacity: 0.75;
}

.language .disable_language:hover {
  filter: drop-shadow(0 0 1px white) grayscale(0%);
  opacity: 1;
}

.text {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-shadow: 1px 1px 1px black;
  width: calc(100% - 16px);
  margin-left: 6px;
  height: calc(100% - 27px);
  box-shadow: inset 0 0 3px black;
  padding: 2px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1px;
}

.vk_oauth {
  background-image: url('../../assets/indexImg/logo_vk.png');
}

.steam_oauth {
  background-image: url('../../assets/indexImg/logo_steam.png');
}

.loader_wrapper {
  float: left;
  margin-left: calc(50% - 21px);
  margin-top: 14%;
  margin-bottom: 12px;
}
</style>
