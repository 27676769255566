var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: объекты заданий")]),_vm._v(" "),_c('p',[_vm._v("В некоторых заданиях необходимо вставить в объект ключ или карту что бы он что то сделал.")]),_vm._v(" "),_c('p',[_vm._v("Для этого надо открыть меню объекта, меню инвентаря и перетащить ключ/карту в специальную синюю ячейку в меню\n      объекта.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_open_special_cell_1', 'gif')+ ')',
          width: '391px',
          height: '268px',
          marginLeft: 'calc(50% - 195px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Task Objects")]),_vm._v(" "),_c('p',[_vm._v("In some tasks, it is necessary to insert a key or card into an object to make it do something.")]),_vm._v(" "),_c('p',[_vm._v("To do this, you need to open the object's menu, the inventory menu, and drag the key/card into a special blue\n      cell in the object's menu.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_open_special_cell_1', 'gif')+ ')',
          width: '391px',
          height: '268px',
          marginLeft: 'calc(50% - 195px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }