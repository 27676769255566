import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {gameStore} from "../store";

let texture = false;

function CreateSpawnTexture() {
  texture = true;
  let graphics = Scene.add.graphics();
  graphics.setDefaultStyles({
    fillStyle: {
      color: 0xFFFFFF,
      alpha: 0.1
    },
    lineStyle: {
      width: 4,
      color: 0xFFFFFF,
      alpha: 0.3
    },
  });

  let circle = {x: 304, y: 304, radius: 300};
  graphics.fillCircleShape(circle);
  graphics.strokeCircleShape(circle);
  graphics.generateTexture("circle_spawn", 300 * 2 + 8, 300 * 2 + 8);
  graphics.destroy();

  let graphics2 = Scene.add.graphics();
  graphics2.setDefaultStyles({
    fillStyle: {
      color: 0x00FF00,
      alpha: 0.5
    },
    lineStyle: {
      width: 4,
      color: 0x00FF00,
      alpha: 0.75
    },
  });

  graphics2.fillCircleShape(circle);
  graphics2.strokeCircleShape(circle);
  graphics2.generateTexture("circle_spawn_mini_map", 300 * 2 + 8, 300 * 2 + 8);
  graphics2.destroy();
}

function CreateSpawnZone(spawns) {
  for (let spawn of spawns) {
    CreateSpawn(spawn);
  }
}

function CreateSpawn(spawn, addKey = '') {

  if (!texture) {
    CreateSpawnTexture()
  }

  let pos = GetGlobalPos(spawn.x, spawn.y, gameStore.map.id);
  let key = spawn.x + ':' + spawn.y + ':' + gameStore.map.id + addKey;
  if (gameStore.spawns[key]) gameStore.spawns[key].destroy();
  if (gameStore.spawns[key + "mini"]) gameStore.spawns[key + "mini"].destroy();

  let captureCircle = Scene.make.sprite({
    x: pos.x,
    y: pos.y,
    key: "circle_spawn",
    add: true
  });
  captureCircle.setOrigin(0.5);
  captureCircle.setDisplaySize(spawn.radius * 2, spawn.radius * 2);
  captureCircle.setDepth(10);

  if (gameStore.spawns[key] && addKey !== 'mission') gameStore.spawns[key].destroy();
  gameStore.spawns[key] = captureCircle;

  let captureCircleMiniMap = Scene.make.image({
    x: pos.x,
    y: pos.y,
    key: "circle_spawn_mini_map",
    add: true
  });
  captureCircleMiniMap.setOrigin(0.5);
  captureCircleMiniMap.setDisplaySize(spawn.radius * 2, spawn.radius * 2);
  captureCircleMiniMap.setDepth(1001);
  Scene.cameras.main.ignore(captureCircleMiniMap);

  if (gameStore.spawns[key + "mini"] && addKey !== 'mission') gameStore.spawns[key].destroy();
  gameStore.spawns[key + "mini"] = captureCircleMiniMap;

  if (spawn.name) {
    let captureCircleName = Scene.add.bitmapText(pos.x, pos.y, 'bit_text', spawn.name, spawn.radius * 2);
    captureCircleName.setOrigin(0.5);
    captureCircleName.setDepth(1002);

    Scene.cameras.main.ignore(captureCircleName);

    captureCircleName.RealX = spawn.x
    captureCircleName.RealY = spawn.y

    if (gameStore.spawns[key + "mini_label"] && addKey !== 'mission') gameStore.spawns[key].destroy();
    gameStore.spawns[key + "mini_label"] = captureCircleName;
  }

  return {captureCircle: captureCircle, captureCircleMiniMap: captureCircleMiniMap}
}

export {CreateSpawnZone, CreateSpawn}
