<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Репутация</h2>

      <p>Репутация - система начисления очков, определяющая, как именно к вам относятся те или иные фракции в мире
        игры.</p>

      <ul>
        <li><span class="importantly">Хорошая репутация</span> - отображается в виде активных скидок на товары/услуги
          фракции, доступа к специализированным
          предметам и, даже поддержки в бою.
        </li>
        <li><span class="importantly">Плохая репутация</span> - отображается в виде запрета на использование ряда услуг,
          зачастую агрессивного поведения.
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('relations_1', 'png')+ ')',
            width: '420px',
            height: '338px',
            marginLeft: 'calc(50% - 210px)',
            }"/>

      <div class="content_section">
        <h3 class="headSection">игрок получает репутацию за:</h3>
        <ul style="float: left">
          <li>убийство пиратов в безопасном секторе (улучшается у владельца сектора)</li>
          <li>выполнение миссий</li>
          <li>захват сектора в фракционной войне (надо состоять в ополчение)</li>
          <li>за уплаченный налог при переработке ресурсов</li>
          <li>за торговлю товарами (улучшается у владельца сектора) если в следке больше 100 товаров</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="headSection">ухудшается за:</h3>
        <ul style="float: left">
          <li>убийство мирного в секторе фракции (ухудшается у владельца сектора)</li>
          <li>убийство воина в его секторе в войне фракций</li>
          <li>провал миссий, отказ от выполнения миссий</li>
          <li>за провоз контрабанды (ухудшается у владельца сектора)</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="head">Бонусы</h3>
        <ul style="float: left">
          <li>Обзор защитников (если выше 0)</li>
          <li>налог на переработку сырья (-1% за 1 репутацию) (макс: -10%)</li>
          <li>налог на производство (-1% за 1 репутацию) (макс: -10%)</li>
          <li>налог на производство деталей (-3% за 1 репутацию) (макс: -30%)</li>
          <li>снижение налога на рынок. На базах фракции (1% за 3 репутации) (макс: -3%)</li>
          <li>награда за задания (+2% за 1 репутацию) (макс: +20%)</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="head">Штрафы:</h3>
        <h4 class="headSection">Плохое отношение (-0.1 : -4.9)</h4>
        <ul style="float: left">
          <li>заперт на использование НПС магазина товаров</li>
          <li>запрет на переработку/производство</li>
        </ul>
      </div>

      <div class="content_section">
        <h4 class="headSection">Вражда (-5 : -10)</h4>
        <ul style="float: left">
          <li>полиция атакует даже в безопасном секторе и не получает PVP/PK</li>
          <li>игроки и боты не получают PVP/PK атакуя вас</li>
        </ul>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Reputation</h2>

      <p>Reputation is a point system that determines how various factions in the game world relate to you.</p>

      <ul>
        <li><span class="importantly">Good reputation</span> is reflected in active discounts on goods/services of the
          faction, access to specialized items, and even support in combat.
        </li>
        <li><span class="importantly">Bad reputation</span> is reflected in the prohibition of using a number of
          services, often aggressive behavior.
        </li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('relations_1', 'png')+ ')',
        width: '420px',
        height: '338px',
        marginLeft: 'calc(50% - 210px)',
        }"/>

      <div class="content_section">
        <h3 class="headSection">a player earns reputation for:</h3>
        <ul style="float: left">
          <li>killing pirates in a safe sector (improves with the sector owner)</li>
          <li>completing missions</li>
          <li>capturing a sector in the faction war (must be part of the militia)</li>
          <li>for the tax paid when processing resources</li>
          <li>for trading goods (improves with the sector owner) if the deal involves more than 100 goods</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="headSection">deteriorates for:</h3>
        <ul style="float: left">
          <li>killing a civilian in the faction's sector (worsens with the sector owner)</li>
          <li>killing a warrior in his sector in the faction war</li>
          <li>mission failure, refusal to complete missions</li>
          <li>for smuggling (worsens with the sector owner)</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="head">Bonuses</h3>
        <ul style="float: left">
          <li>Defender overview (if above 0)</li>
          <li>tax on raw material processing (-1% per 1 reputation) (max: -10%)</li>
          <li>tax on production (-1% per 1 reputation) (max: -10%)</li>
          <li>tax on parts production (-3% per 1 reputation) (max: -30%)</li>
          <li>reduction of market tax. At faction bases (1% per 3 reputations) (max: -3%)</li>
          <li>reward for tasks (+2% per 1 reputation) (max: +20%)</li>
        </ul>
      </div>

      <div class="content_section">
        <h3 class="head">Penalties:</h3>
        <h4 class="headSection">Poor relationship (-0.1 : -4.9)</h4>
        <ul style="float: left">
          <li>locked from using NPC goods store</li>
          <li>ban on processing/production</li>
        </ul>
      </div>

      <div class="content_section">
        <h4 class="headSection">Hostility (-5 : -10)</h4>
        <ul style="float: left">
          <li>police attack even in a safe sector and do not receive PVP/PK</li>
          <li>players and bots do not receive PVP/PK attacking you</li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Relations",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
