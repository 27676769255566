import {gameStore} from "../store";
import {Scene} from "../create";
import {GreenExplosion} from "../weapon/explosion";
import {percentHP} from "../interface/status_bar";
import {getBarScale} from "../interface/status_layer";

let pumpMiniGameState = {
  background: null,
  height: 56,
  width: 150,
  borderSprite: null,
  rockSprite: null,
  text: null,
  timeOut: 0,
  data: {
    performance: 0,
    current: 0,
    border: 0,
    need: 0,
    reload: false,
    destroy: false
  }
}

function PumpMiniGame(performance, current, border, need, reload, destroy) {
  if (!gameStore.gameReady) return;

  pumpMiniGameState.timeOut = 30
  if (!pumpMiniGameState.background) {
    pumpMiniGameState.background = Scene.add.renderTexture(0, 0, pumpMiniGameState.width, pumpMiniGameState.height + 2);
    pumpMiniGameState.background.setDepth(900)
    pumpMiniGameState.background.setOrigin(0)
    pumpMiniGameState.background.setScale(0.5)
    pumpMiniGameState.background.setVisible(false)

    let back = Scene.add.graphics({x: 0, y: 0, add: true});
    // shadow
    back.fillStyle(0x000000, 0.5);
    back.fillRoundedRect(0, 0, pumpMiniGameState.width, pumpMiniGameState.height, 3);

    // back
    back.fillStyle(0x25a0e1, 0.4);
    back.fillRoundedRect(1, 1, pumpMiniGameState.width - 2, pumpMiniGameState.height - 2, 3);
    pumpMiniGameState.background.draw(back);
    back.destroy();

    // back 2
    back.fillStyle(0x000000, 0.4);
    back.fillRoundedRect(1, pumpMiniGameState.height / 3, pumpMiniGameState.width - 2, pumpMiniGameState.height - pumpMiniGameState.height / 3 - 2, 3);
    pumpMiniGameState.background.draw(back);
    back.destroy();

    let text = Scene.add.bitmapText(5, 2, 'bit_text', 'Загрязнение: ', 16);
    text.setOrigin(0);
    text.setLetterSpacing(1)
    text.setTint(0xFFFFFF)
    pumpMiniGameState.background.draw(text)
    text.destroy()
  }

  if (!pumpMiniGameState.borderSprite) {
    pumpMiniGameState.borderSprite = Scene.add.rectangle(0, 0, 50, 50, 0xffffff);
    pumpMiniGameState.borderSprite.setDepth(901)
    pumpMiniGameState.borderSprite.setOrigin(0)
    pumpMiniGameState.borderSprite.setVisible(false)
    pumpMiniGameState.borderSprite.setDisplaySize(0, 0);
  }

  if (!pumpMiniGameState.rockSprite) {
    pumpMiniGameState.rockSprite = Scene.add.circle(0, 0, 50, 0xff0000, 1);
    pumpMiniGameState.rockSprite.setDepth(902)
    pumpMiniGameState.rockSprite.setOrigin(0)
    pumpMiniGameState.rockSprite.setVisible(false)
  }

  if (!pumpMiniGameState.text || pumpMiniGameState.data.performance !== performance) {
    if (pumpMiniGameState.text) pumpMiniGameState.text.destroy();
    pumpMiniGameState.text = Scene.add.bitmapText(0, 0, 'bit_text', 100 - performance + '%', 16);
    pumpMiniGameState.text.setOrigin(1);
    pumpMiniGameState.text.setLetterSpacing(1)
    pumpMiniGameState.text.setTint(percentHP(performance))
    pumpMiniGameState.text.setScale(0.5)
    pumpMiniGameState.text.setVisible(false)
    pumpMiniGameState.text.setDepth(901)
  }

  if (current === 0) {
    pumpMiniGameState.rockSprite.setVisible(false)
    if (!destroy) return;
  }

  if (destroy) {
    // TODO звук
    GreenExplosion(pumpMiniGameState.rockSprite.x, pumpMiniGameState.rockSprite.y, 1000, 10, 7, 35, 355, 365, 999);

    pumpMiniGameState.rockSprite.setVisible(false)
    pumpMiniGameState.borderSprite.setFillStyle(0x00ff00, 0.7)
  } else {
    pumpMiniGameState.borderSprite.setFillStyle(0xffffff, 0.5)
  }

  if (!destroy && reload) {
    pumpMiniGameState.borderSprite.setFillStyle(0xff0000, 0.7)
  }

  pumpMiniGameState.rockSprite.setDisplaySize(4, 4);

  if (current !== 0) {
    if (border < 100) {
      pumpMiniGameState.borderSprite.setDisplaySize((border / 4) * getBarScale(), (((pumpMiniGameState.background.displayHeight / 3) * 2) - (2 * getBarScale())));
    }
    pumpMiniGameState.data.performance = performance
    pumpMiniGameState.data.border = border
    pumpMiniGameState.data.need = need
    pumpMiniGameState.data.reload = reload
    pumpMiniGameState.data.destroy = destroy
  }

  pumpMiniGameState.data.current = current
}

function UpdatePosPumpMiniGame() {
  pumpMiniGameState.timeOut--
  if (!pumpMiniGameState.background) {
    return
  }

  if (pumpMiniGameState.timeOut <= 0) {
    pumpMiniGameState.background.setVisible(false);
    if (pumpMiniGameState.borderSprite) {
      pumpMiniGameState.borderSprite.setVisible(false);
      pumpMiniGameState.borderSprite.setDisplaySize(0, 0);
    }
    if (pumpMiniGameState.rockSprite) {
      pumpMiniGameState.rockSprite.setVisible(false);
      pumpMiniGameState.rockSprite.setDisplaySize(0, 0);
    }
    if (pumpMiniGameState.text) pumpMiniGameState.text.setVisible(false);
    return;
  }

  let pointer = Scene.game.input.activePointer
  let x = Math.round((Scene.cameras.main.worldView.left + (pointer.x / Scene.cameras.main.zoom))) + 10;
  let y = Math.round((Scene.cameras.main.worldView.top + (pointer.y / Scene.cameras.main.zoom)));
  pumpMiniGameState.background.setPosition(x, y)
  pumpMiniGameState.background.setVisible(true)
  pumpMiniGameState.background.setScale(0.5 * getBarScale())

  if (pumpMiniGameState.borderSprite) {
    let boxX = pumpMiniGameState.background.x + (((pumpMiniGameState.data.need - (pumpMiniGameState.data.border / 2)) / 4) * getBarScale())
    let boxY = pumpMiniGameState.background.y + pumpMiniGameState.background.displayHeight / 3
    pumpMiniGameState.borderSprite.setPosition(boxX, boxY)
    pumpMiniGameState.borderSprite.setVisible(true)
  }

  if (pumpMiniGameState.rockSprite && pumpMiniGameState.data.current > 0) {
    let rockX = pumpMiniGameState.background.x + ((pumpMiniGameState.data.current / 4) * getBarScale())
    let rockY = pumpMiniGameState.background.y + ((pumpMiniGameState.background.displayHeight / 3) * 2) - 2
    pumpMiniGameState.rockSprite.setPosition(rockX, rockY)
    pumpMiniGameState.rockSprite.setVisible(true)
    pumpMiniGameState.rockSprite.setDisplaySize(4 * getBarScale(), 4 * getBarScale());
  }

  if (pumpMiniGameState.text) {
    let textX = pumpMiniGameState.background.x + pumpMiniGameState.background.displayWidth - (2 * getBarScale())
    let textY = pumpMiniGameState.background.y + (9 * getBarScale())
    pumpMiniGameState.text.setPosition(textX, textY)
    pumpMiniGameState.text.setVisible(true)
    pumpMiniGameState.text.setScale(0.5 * getBarScale())
  }
}

export {PumpMiniGame, UpdatePosPumpMiniGame, pumpMiniGameState}
