var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Типы урона и защита")]),_vm._v(" "),_c('p',[_vm._v("В игре есть три типа урона: “кинетический”, “взрывной” и “термический”. Для каждого типа урона у транспорта\n        есть своя защита.")]),_vm._v(" "),_c('p',[_vm._v("У каждого снаряда может быть разное процентное соотношение типов урона. Например, снаряд может иметь 50%\n        кинетического урона, 30% взрывного урона и 20% термического урона.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_1', 'png')+ ')',
          width: '305px',
          height: '191px',
          marginLeft: 'calc(50% - 152px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Например: урон 100, по 33% на каждый тип. Броня корпуса допустим 50% на кинетику и по 90% на все\n        остальное")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Толщина брони")]),_vm._v(" "),_c('p',[_vm._v("У каждого корпуса есть уязвимые и бронированные места. Урон, нанесённый по уязвимому месту, умножается на\n        дополнительный коэффициент. Это заставит игроков быть более внимательными и выбирать оптимальную тактику боя,\n        пряча уязвимые места и танковать броней.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_2', 'png')+ ')',
          width: '311px',
          height: '291px',
          marginLeft: 'calc(50% - 155px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Карта брони корпуса.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_3', 'gif')+ ')',
          width: '368px',
          height: '292px',
          marginLeft: 'calc(50% - 184px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Красная иконка говорит об \"критическом уроне\" а щит о \"блоке\"")])]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Щит")]),_vm._v(" "),_c('p',[_vm._v("Почти у каждого транспорта есть персональный щит, он берет на себя первый удар и позволяет синтету успет\n        среагировать на ситуацию, однако он довольно слабый и рассчитывать только на него не стоит.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_4', 'gif')+ ')',
          width: '400px',
          height: '285px',
          marginLeft: 'calc(50% - 200px)',
          })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Damage Types and Protection")]),_vm._v(" "),_c('p',[_vm._v("There are three types of damage in the game: \"kinetic\", \"explosive\", and \"thermal\". Each type of damage has\n        its own protection for the vehicle.")]),_vm._v(" "),_c('p',[_vm._v("Each projectile can have a different percentage of damage types. For example, a projectile can have 50%\n        kinetic damage, 30% explosive damage, and 20% thermal damage.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_1', 'png')+ ')',
          width: '305px',
          height: '191px',
          marginLeft: 'calc(50% - 152px)',
          })}),_vm._v(" "),_c('p',[_vm._v("For example: damage is 100, with 33% for each type. The body armor is, say, 50% for kinetic and 90% for\n        everything else.")]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Armor Thickness")]),_vm._v(" "),_c('p',[_vm._v("Each body has vulnerable and armored spots. Damage dealt to a vulnerable spot is multiplied by an additional\n        coefficient. This forces players to be more attentive and choose the optimal battle tactics, hiding vulnerable\n        spots and tanking with armor.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_2', 'png')+ ')',
        width: '311px',
        height: '291px',
        marginLeft: 'calc(50% - 155px)',
        })}),_vm._v(" "),_c('p',[_vm._v("Body armor map.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_3', 'gif')+ ')',
        width: '368px',
        height: '292px',
        marginLeft: 'calc(50% - 184px)',
        })}),_vm._v(" "),_c('p',[_vm._v("The red icon indicates \"critical damage\" and the shield indicates \"block\".")])]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Shield")]),_vm._v(" "),_c('p',[_vm._v("Almost every vehicle has a personal shield that takes the first hit and allows the synthetic to react to the\n        situation. However, it is quite weak and should not be relied upon solely.")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('damage_4', 'gif')+ ')',
        width: '400px',
        height: '285px',
        marginLeft: 'calc(50% - 200px)',
        })})])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("урон кинетикой 33 * 50% = 16")]),_vm._v(" "),_c('li',[_vm._v("урон термосом 33 * 90% = 3")]),_vm._v(" "),_c('li',[_vm._v("урон взрывом 33 * 90% = 3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("У щита очень мало ХП, но урон не будет перетекать в транспорт, пока есть щит (условно, щит 50 ХП, урон на\n          300.\n          50 ХП щита заберут все 300 урона, и щит сломается).\n        ")]),_vm._v(" "),_c('li',[_vm._v("У щита высокая резистентность, обратная дефолтным резистам корпуса.")]),_vm._v(" "),_c('li',[_vm._v("Щит восстанавливается через 5 секунд после урона по корпусу или щиту (то есть в активном бою он не будет\n          восстанавливаться вообще).\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("kinetic damage: 33 * 50% = 16")]),_vm._v(" "),_c('li',[_vm._v("thermal damage: 33 * 90% = 3")]),_vm._v(" "),_c('li',[_vm._v("explosive damage: 33 * 90% = 3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("The shield has very little HP, but damage will not transfer to the vehicle as long as the shield is active\n          (for example, if the shield has 50 HP and the damage is 300, the 50 HP shield will absorb all 300 damage and\n          break).\n        ")]),_vm._v(" "),_c('li',[_vm._v("The shield has high resistance, opposite to the default resistances of the body.")]),_vm._v(" "),_c('li',[_vm._v("The shield regenerates 5 seconds after taking damage to the body or shield (meaning it will not regenerate\n          at all during active combat).\n        ")])])
}]

export { render, staticRenderFns }