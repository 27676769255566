<template>
  <div class="wrapper">
    // TODO подгрузить все оружие фаркции, на канвасе разделить категории
  </div>
</template>

<script>
export default {
  name: "Weapon",
  props: ['fraction'],
}
</script>

<style scoped>

</style>
