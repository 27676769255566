<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Чат</h2>

      <p>Чат позволяет обмениваться сообщениями между игроками, в чате всегда есть локальный канал соответствующей
        текущему
        сектору. Блок слева показывает всех игроков подписанных на этот канал, если нажать на игрока то
        будут доступны различные действия, например создать приватный канал, посмотреть подробную информацию или
        пригласить группу.</p>
      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_1', 'png')+ ')',
            width: '301px',
            height: '237px',
             marginLeft: 'calc(50% - 150px)',
            }"/>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_2', 'png')+ ')',
            width: '331px',
            height: '237px',
           marginLeft: 'calc(50% - 165px)',
            marginTop: '10px',
            }"/>
      </div>

      <p>Некоторые каналы создаются автоматически например канал группы когда создается группа, канал кластера когда
        игрок
        состоит в кластере. Помимо основных каналов можно создавать свои каналы и ставить на них пароли.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_3', 'png')+ ')',
            width: '242px',
            height: '327px',
            marginLeft: 'calc(50% - 121px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Chat</h2>

      <p>The chat allows players to exchange messages with each other, and there is always a local channel corresponding
        to the current sector in the chat. The block on the left shows all players subscribed to this channel; clicking
        on a player will provide various actions, such as creating a private channel, viewing detailed information, or
        inviting to a group.</p>
      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_1', 'png')+ ')',
            width: '301px',
            height: '237px',
             marginLeft: 'calc(50% - 150px)',
            }"/>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_2', 'png')+ ')',
            width: '331px',
            height: '237px',
           marginLeft: 'calc(50% - 165px)',
            marginTop: '10px',
            }"/>
      </div>

      <p>Some channels are created automatically, for example, a group channel when a group is created, or a cluster
        channel when a player is part of a cluster. In addition to the main channels, you can create your own channels
        and set passwords on them.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('chat_3', 'png')+ ')',
            width: '242px',
            height: '327px',
            marginLeft: 'calc(50% - 121px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Chat",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
