<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Склад / Трюм</h2>

      <p>Склад - это ваше личное хранилище на базе, где вы можете хранить все свои предметы без ограничений. Склад
        доступен только вам, и вы можете перемещать предметы между складом и трюмом вашего транспорта. Склад также
        связан
        с сервисами базы, такими как производство, переработка или торговля. Когда вы создаете, перерабатываете или
        покупаете предмет, он автоматически попадает на склад. Когда вы используете ресурсы или детали для создания
        предмета, они автоматически берутся со склада.</p>

      <p>Трюм - это хранилище на вашем транспорте, где вы можете хранить предметы, которые вам нужны в пути. Трюм имеет
        ограниченный объем, который зависит от характеристик корпуса вашего транспорта. В отличии от склада, трюм
        подвержен постоянному риску утери своего содержимого в случае смерти игрока.
        При подобном исходе большая часть вашего груза будет утеряна, а оставшееся перенесено в
        <router-link to="salvage">обломки</router-link>
        корпуса, чем сумеют поживиться прочие синтеты или даже вы сами.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('property_management_1', 'png')+ ')',
            width: '468px',
            height: '186px',
            marginLeft: 'calc(50% - 234px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Warehouse / Hold</h2>

      <p>The warehouse is your personal storage at the base, where you can store all your items without restrictions.
        The warehouse is available only to you, and you can move items between the warehouse and the hold of your
        transport. The warehouse is also connected to the base services, such as production, processing, or trading.
        When you create, process, or purchase an item, it automatically goes to the warehouse. When you use resources or
        parts to create an item, they are automatically taken from the warehouse.</p>

      <p>The hold is the storage on your transport, where you can keep the items you need on the go. The hold has a
        limited volume, which depends on the characteristics of your transport's hull. Unlike the warehouse, the hold is
        constantly at risk of losing its contents in the event of the player's death. In such an outcome, most of your
        cargo will be lost, and the remainder will be transferred to the
        <router-link to="salvage">debris</router-link>
        of the hull, which other synthetics or even you yourself can scavenge.
      </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('property_management_1', 'png')+ ')',
        width: '468px',
        height: '186px',
        marginLeft: 'calc(50% - 234px)',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Storage",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
