<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Детали</h2>
      <p>Детали это составные компоненты которые используются в производстве устройств, вооружения и корпусов. Они могут
        состоять как из сырья так и из других деталей.</p>

      <p>На заводе полуфабрикатов можно изготовить детали из сырья, однако база будет брать % ресурсов за создание
        нежели эти же детали делать с помощью
        <router-link to="blueprints">чертежа</router-link>
        на главной базе сектора.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('parts_production_1', 'png')+ ')',
            width: '400px',
            height: '458px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Parts</h2>
      <p>Parts are the components used in the production of devices, weapons, and hulls. They can consist of both raw
        materials and other parts.</p>

      <p>At the semi-finished products factory, it is possible to manufacture parts from raw materials, however, the
        base will take % of the resources for creation compared to making these same parts using a
        <router-link to="blueprints">blueprint</router-link>
        at the main base of the sector.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' +  $parent.getUrlImg('parts_production_1', 'png')+ ')',
            width: '400px',
            height: '458px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "PartsProduction",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
