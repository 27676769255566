<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Кластеры</h2>

      <p>Кластер (Клан или Корпорация): кластер, это активное сообщество игроков, решивших объединить свои усилия под
        эгидой некоего общего интереса. Желаете вы создать пиратскую банду, или сформировать гильдию богатейших
        торговцев,
        кластер позволит вам установить подобное сотрудничество, а также соперничество с прочими игроками. </p>

      <p>Прежде чем вступить в кластер, вам необходимо либо подать заявку в уже существующий, либо создать собственный,
        став его лидером. </p>

      <p>Игрок, может отыскать все доступные для вступления кластеры в графе - “поиск кластеров”. Там же вы увидите
        наименование кластера, количество участников и статус набора. Выбрав интересующий вас кластер, всё что остаётся
        -
        нажать на кнопку “Отправить заявку”, после чего, дожидаться одобрения от лидера кластера. </p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_1', 'jpg')+ ')',
            width: '500px',
            height: '238px',
            marginLeft: 'calc(50% - 250px)',
            }"/>
      </div>

      <p>Когда вы состоите в кластере у вас доступна панель кластера:</p>
      <ul>
        <li>Во вкладке "<span class="importantly">О кластере</span>" будет отображены описание, лого, депозиты и офисы
          кластера.
        </li>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_6', 'jpg')+ ')',
            width: '400px',
            height: '329px',
            marginLeft: 'calc(50% - 200px)',
            }"/>

        <li>Во вкладке "<span class="importantly">Участники</span>" вы можете посмотреть кто из игроков сейчас онлайн,
          если у вас есть соответствующие права или вы лидер вы можете поменять роли игрокам из этого меню или выгнать
          их.

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_3', 'jpg')+ ')',
            width: '400px ',
            height: '313px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
        <li>Во вкладке "<span class="importantly">Роли</span>" вы можете посмотреть существующие роли в кластере и если
          вы
          имеет права то можете создавать и редактировать их.

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_4', 'jpg')+ ')',
            width: '400px',
            height: '454px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
        <li>Во вкладке "<span class="importantly">Заявки</span>" будут показаны все заявки которые послали игроки что бы
          вступить в ваш кластер. Если принять заявку то игрок сразу попадает в кластер.

          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_5', 'jpg')+ ')',
            width: '400px',
            height: '323px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
      </ul>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Clusters</h2>

      <p>A cluster (Clan or Corporation) is an active community of players who have decided to unite their efforts under
        the aegis of some common interest. Whether you want to create a pirate gang or form a guild of the wealthiest
        traders, a cluster will allow you to establish such cooperation, as well as compete with other players.</p>

      <p>Before joining a cluster, you need to either apply to an existing one or create your own, becoming its
        leader.</p>

      <p>A player can find all available clusters to join in the "search clusters" section. There you will see the name
        of the cluster, the number of members, and the recruitment status. After selecting the cluster you are
        interested in, all that remains is to click the "Send Request" button, and then wait for approval from the
        cluster leader.</p>

      <div class="content_section">
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_1', 'jpg')+ ')',
            width: '500px',
            height: '238px',
            marginLeft: 'calc(50% - 250px)',
            }"/>
      </div>

      <p>When you are part of a cluster, you have access to the cluster panel:</p>
      <ul>
        <li>In the "<span class="importantly">About Cluster</span>" tab, you will see the description, logo, deposits,
          and offices of the cluster.
        </li>
        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_6', 'jpg')+ ')',
            width: '400px',
            height: '329px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
        <li>In the "<span class="importantly">Members</span>" tab, you can see which players are currently online, and
          if you have the appropriate rights or are the leader, you can change roles for players from this menu or expel
          them.
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_3', 'jpg')+ ')',
            width: '400px ',
            height: '313px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
        <li>In the "<span class="importantly">Roles</span>" tab, you can view the existing roles in the cluster and if
          you have the rights, you can create and edit them.
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_4', 'jpg')+ ')',
            width: '400px',
            height: '454px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
        <li>In the "<span class="importantly">Applications</span>" tab, all applications sent by players to join your
          cluster will be shown. If you accept an application, the player immediately joins the cluster.
          <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('cluster_5', 'jpg')+ ')',
            width: '400px',
            height: '323px',
            marginLeft: 'calc(50% - 200px)',
            marginTop: '10px',
            }"/>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "Clusters",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
