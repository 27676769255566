<template>
  <div id="MissionInfoBlock" ref="MissionInfoBlock" @mousedown="toUp" style="height: unset"
       :class="{hidden: hidden}">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:refWindow="'MissionInfoBlock'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 60, width: 150}"
                 v-bind:texture="'none'"/>

    <div class="wrapper">

      <div class="mission_control_button cursor_hover"
           @click="openService('userStat' + currentPlayer.id, {id: currentPlayer.id, tab: 'missions'}, 'userStat', false)">
        {{ texts['button_1'][language] }}
      </div>

      <select v-model="missions.currentMissionUUID" id="SelectMission" class="cursor_hover" @change="updateCurrentMission">
        <option v-for="(mission, uuid) in missions.missions" :value="uuid">{{
            mission[language].name
          }}
        </option>
      </select>

      <app-points v-if="missions.currentMissionUUID"
                  v-bind:actions="currentActions"
                  v-bind:set-current-action="true"
                  v-bind:last="3"/>

      <div id="MissionStory">
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Points from './Points';

export default {
  name: "Missions",
  data() {
    return {
      // updater: null
    }
  },
  destroyed() {
    // if (this.updater) clearInterval(this.updater);
    // this.missions.currentAction = null;
    // this.$store.commit({
    //   type: 'setHelper',
    //   helper: null,
    // });
  },
  //created() {
  // this.updater = setInterval(function () {
  //   this.resize(null, null, this.$el)
  // }.bind(this), 1000);
  //},
  updated() {
    this.resize(null, null, this.$el)
  },
  methods: {
    resize(event, ui, el) {
      if (this.missions.currentMissionUUID === "") {
        $(el).css("height", 21)
      } else {
        if ($(el).height() < 60) $(el).css("height", 60);
      }
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    updateCurrentMission(e) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "SelectMission",
          data: e.target.value,
        }
      }));

      this.resize(null, null, this.$el)
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
  },
  computed: {
    missions() {
      return this.$store.getters.getMissions;
    },
    currentActions() {
      return this.$store.getters.getCurrentActions;
    },
    battleState() {
      return this.$store.getters.getBattleState;
    },
    hidden() {
      return !(this.missions && this.missions.missions && Object.keys(this.missions.missions).length !== 0);
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Missions')
    },
  },
  components: {
    AppControl: Control,
    AppPoints: Points,
  }
}
</script>

<style scoped>
.wrapper {
  float: left;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
}

#MissionInfoBlock {
  min-height: 21px;
  width: 220px;
  position: absolute;
  right: 15px;
  top: 280px;
  border-radius: 5px;
  color: papayawhip;
  display: block;
  z-index: 11;
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
  opacity: 0.75;
}

#MissionInfoBlock:hover {
  opacity: 1;
}

#MissionInfoBlock input {
  color: white;
  text-shadow: none;
  background: rgba(101, 165, 195, 0.7);
  border: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px 0 0 0;
}

#SelectMission {
  width: 100%;
  border-radius: 5px 5px 0 0;
  border: 0;
  display: block;
  background: rgba(101, 165, 195, 0.3);
  height: 21px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 200ms;
  font-family: 'Comfortaa', cursive;
  font-size: 12px;
}

#SelectMission option {
  background: rgba(0, 0, 0, 0.7);
}

#moveMissionButton {
  background: url(https://img.icons8.com/dotty/80/000000/expand.png) center center / contain no-repeat;
  height: 22px;
  width: 22px;
  position: absolute;
  right: 19px;
  top: 0;
}


#MissionStory {
  position: absolute;
  top: 21px;
  right: -159px;
  background: rgba(0, 0, 0, 0.5);
  height: 179px;
  width: 160px;
  overflow-y: scroll;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6);
  display: none;
}

@media (max-width: 1000px) {
  #SelectMission {
    font-size: 10px;
  }
}

.hidden {
  visibility: hidden;
  pointer-events: none;
  display: none;
}

.mission_control_button {
  display: block;
  width: 52px;
  pointer-events: auto;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 13px;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  bottom: 0;
  z-index: 3;
  float: right;
  margin-left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 12px;
  line-height: 13px;
}

.mission_control_button:hover {
  background: #ff8100;
}

.mission_control_button:active {
  transform: scale(.98);
}
</style>
