<template>
  <!--  TODO рефакторинг требует наши сердца-->
  <div>
    <div class="error_message" :class="{error_message_visible: noFillUpBase}">
      <div style="margin-top: 14%">{{ texts['error_1'][language] }}</div>
    </div>

    <div v-if="dialogShow === 'rental' || dialogShow === 'rental_add'">
      <div class="mask"/>

      <div class="wrapper">
        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper">

            <template
              v-if="corporation.rental_price && corporation.rental_price > 0 && dialogShow === 'rental'"
              v-html="texts['rental_dialog_1'][language].replace('%credits_count%', (corporation.rental_price / 100).toFixed(2))"
            />

            <template
              v-if="corporation.rental_price && corporation.rental_price > 0 && dialogShow === 'rental_add'"
              v-html="texts['rental_dialog_2'][language].replace('%credits_count%', (corporation.rental_price / 100).toFixed(2))"
            />

            <div v-if="!corporation.rental_price || corporation.rental_price < 0"
                 style="float: left; margin-left: calc(50% - 21px)">
              <app-preloader/>
            </div>
          </div>
        </div>

        <input type="button"
               :value="texts['button_5'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogShow = ''">

        <input v-if="dialogShow === 'rental'"
               type="button"
               :value="texts['button_6'][language]"
               class="cluster_button cursor_hover"
               :class="{disable: !mayRental()}"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="rental" style="float: right; position: unset; width: 45%">

        <input v-if="dialogShow === 'rental_add'"
               type="button"
               :value="texts['button_7'][language]"
               class="cluster_button cursor_hover"
               :class="{disable: !mayRental()}"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="rentalAdd" style="float: right; position: unset; width: 45%">
      </div>
    </div>

    <div v-if="dialogShow === 'fill_up'">
      <div class="mask"/>

      <div class="wrapper transfer">
        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper transfer">

            <template v-if="corporation.rental_price && corporation.rental_price.hasOwnProperty('credits')">
              <p style="margin-top: 0"> {{ texts['text_8'][language] }} </p>
              <p v-html="texts['fill_up_dialog_1'][language].
                replace('%credits_count%',(corporation.rental_price.credits / 100).toFixed(2)).
                replace('%thorium_count%',corporation.rental_price.thorium)"/>
            </template>


            <div v-if="!corporation.rental_price || !corporation.rental_price.hasOwnProperty('credits')"
                 style="float: left; margin-left: calc(50% - 21px)">
              <app-preloader/>
            </div>
          </div>
        </div>

        <input type="button"
               :value="texts['button_8'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogShow = ''">

        <input type="button"
               :value="texts['button_9'][language]"
               class="cluster_button cursor_hover"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="fillUpBase"
               style="float: right; position: unset; width: 45%">
      </div>
    </div>

    <div v-if="dialogShow === 'transfer'">
      <div class="mask"/>

      <div class="wrapper transfer">
        <div style="height: calc(100% - 20px)">
          <div class="text_wrapper transfer">
            <p style="margin-top: 0"
               v-html="texts['transfer_dialog_1'][language].replace('%base_name%', getBaseNameByID(dialogData.baseID))"
            ></p>
            <p v-html="texts['transfer_dialog_2'][language]"></p>
            <p v-html="texts['transfer_dialog_3'][language]"></p>
          </div>
        </div>

        <input type="button"
               :value="texts['button_10'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 45%"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogShow = ''">

        <input type="button"
               :value="texts['button_11'][language]"
               class="cluster_button cursor_hover"
               :class="{disable: market.my_credits < 5000}"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               style="float: right; position: unset; width: 45%"
               @click="transfer(dialogData.baseID)">
      </div>
    </div>

    <div v-if="dialogShow === 'deposit_history'">
      <div class="mask" style="top: 18px; height: calc(100% - 18px)"/>

      <div class="deposit_history_wrapper">
        <div
          style="height: calc(100% - 20px); width: calc(100% - 0px); background: rgba(0,0,0,0.3); overflow-y: scroll; overflow-x: hidden;">
          <table class="history_credits" v-if="corporation.deposit_history">
            <tr>
              <th width="110">{{ texts['deposit_history_h1'][language] }}</th>
              <th>{{ texts['deposit_history_h2'][language] }}</th>
              <th></th>
              <th>{{ texts['deposit_history_h3'][language] }}</th>
            </tr>
            <tr v-for="log in corporation.deposit_history">
              <td>{{ parseDate(log.time) }}</td>
              <td v-html="getOperationName(log.event)"></td>
              <td v-html="prepareHistoryCredits(log)"></td>
              <td v-html="getOperationName(log.cause)"></td>
            </tr>
          </table>
        </div>

        <div v-if="!corporation.deposit_history"
             style="float: left; margin-left: calc(50% - 21px)">
          <app-preloader/>
        </div>

        <input type="button"
               :value="texts['button_12'][language]"
               class="cluster_button cursor_hover"
               style="position: unset; width: 75px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="dialogShow = ''">

        <input type="button"
               value="Назад"
               class="cluster_button cursor_hover"
               style="position: unset; width: 75px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="back">

        <input type="button"
               value="В начало"
               class="cluster_button cursor_hover"
               style="position: unset; width: 75px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="fullBack">

        <input type="button"
               value="Далее"
               class="cluster_button cursor_hover"
               style="position: unset; width: 75px; float: right"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="next">
      </div>
    </div>

    <div style="float: left; width: 100%">
      <div class="userAvatar" v-bind:style="{backgroundImage:  'url(\'' + logo + '\')'}"></div>
      <h3 class="head" style="float: left;width: calc(100% - 111px);">
        <div style="display: inline-block;position: relative;top: -1px;left: -2px;">
          [<span class="tag">{{ corporation.info.tag }}</span>]
        </div>
        {{ corporation.info.name }}
      </h3>
      <p>{{ corporation.info.description }}</p>
    </div>

    <div style="float: left; width: 100%">
      <h3 class="head menu cursor_hover" style="margin-bottom: 5px" @click="menu.deposits = !menu.deposits">
        {{ menu.deposits ? '▼' : '▶' }} {{ texts['text_9'][language] }}
        <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'ClusterDeposit'})"></div>
      </h3>

      <div class="deposit cursor_hover" v-if="menu.deposits"
           v-for="d in deposits"
           :class="{open_deposit_row: selectDeposit === d.id}"
           @click="selectDeposit === d.id ? selectDeposit = 0 : selectDeposit = d.id">

        <div class="credits_icon"/>

        <div class="credits_count">
          {{ d.credits === -1 ? '-' : (d.credits / 100).toFixed(2) }}
        </div>

        <input @click.stop="" maxlength="16" type="text" class="credits_name input cursor_hover"
               :class="{disable: !myRole || myRole.name !== 'Leader'}"
               @change="rename($event, 'deposit', d.id)"
               :value="d.name">

        <div v-if="selectDeposit === d.id"
             style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; margin-top: 2px; padding-top: 1px;">

          <div style="float: right; width: 75px"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="openService('SendCredits', {corp_name: corporation.info.name, deposit: d.id})"
               class="cluster_button office_button cursor_hover"> {{ texts['button_13'][language] }}
          </div>

          <div style="float: right; width: 75px"
               :class="{disable: !myRole || myRole.name !== 'Leader'}"
               @click="rentalDialog('deposit_history', 0, d.id)"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               class="cluster_button office_button cursor_hover"> {{ texts['button_14'][language] }}
          </div>
        </div>
      </div>
    </div>

    <div style="float: left; width: 100%">
      <h3 class="head menu cursor_hover" style="margin-bottom: 5px" @click="menu.office = !menu.office">{{
          menu.office ? '▼' : '▶'
        }} {{ texts['text_10'][language] }}
        <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'ClusterOffice'})"></div>
      </h3>

      <template v-if="menu.office">
        <template v-if="offices.length > 0">
          <div class="office_row cursor_hover"
               :class="{open_office_row: selectOffice === office.id, path: globalPath.baseID === office.base_id}"
               v-for="office in offices"
               @click="selectOffice === office.id ? selectOffice = 0 : selectOffice = office.id"
          >

            <input @click.stop="" type="text" maxlength="16" class="office_name input cursor_hover"
                   :class="{disable: !myRole || myRole.name !== 'Leader'}"
                   @change="rename($event, 'office', office.id)"
                   :value="office.name">
            <div class="office_base">{{ texts['text_11'][language] }}<span
              class='importantly'>{{ getBaseNameByID(office.base_id) }}</span>
            </div>
            <div class="office_date">{{ texts['text_12'][language] }}<span
              class='importantly'>{{ parseDate(office.expiration_time) }}</span>
            </div>

            <div v-if="selectOffice === office.id">

              <div
                style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; margin-top: 2px; padding-top: 2px;">
                <div class="storage_row" v-for="s in sortStorages(office.storages)">
                  <div class="inventory_icon"/>
                  <input @click.stop="" type="text" maxlength="16" class="storage_name input cursor_hover"
                         :class="{disable: !myRole || myRole.name !== 'Leader'}"
                         @change.stop="rename($event, 'stock', s.id)"
                         :value="s.name">
                </div>
              </div>

              <div style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%;">
                <div @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.stop="autoPilot(office.base_id, 'target')"
                     class="cluster_button office_button cursor_hover"> {{ texts['button_15'][language] }}
                </div>

                <div @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.stop="autoPilot(office.base_id, 'auto_pilot')"
                     class="cluster_button office_button cursor_hover"> {{ texts['button_16'][language] }}
                </div>

                <div @mouseover="playSound('select_sound.mp3', 0.3)"
                     :class="{disable: baseStatus.state && office.base_id === baseStatus.state.id}"
                     @click.stop="transferDialog(office.base_id)"
                     class="cluster_button office_button cursor_hover"> {{ texts['button_17'][language] }}
                </div>

                <div @mouseover="playSound('select_sound.mp3', 0.3)"
                     :class="{disable: !viewRentalButton || mayRentalAdd(office.expiration_time)}"
                     @click.stop="rentalDialog('rental_add', office.base_id)"
                     class="cluster_button office_button cursor_hover"> {{ texts['button_18'][language] }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-if="baseStatus && viewButton"
             style="margin-bottom: 5px;"
             @click="rentalDialog('rental', 0)"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             class="cluster_button cursor_hover"> {{ texts['text_13'][language] }}
          <span class='importantly'> {{ getBaseName(baseStatus.state.name) }}</span>
        </div>
      </template>
    </div>

    <div style="float: left; width: 100%; padding-bottom: 10px;">
      <h3 class="head menu cursor_hover" style="margin-bottom: 5px" @click="menu.bases = !menu.bases">
        {{ menu.bases ? '▼' : '▶' }}
        {{ texts['text_14'][language] }}
        <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'CorporationBuild'})"></div>
      </h3>

      <template v-if="menu.bases">
        <div v-for="base in bases"
             v-if="base.structure === 'base'"
             class="office_row"
             :class="{open_base_row: selectBase === base.id, path: globalPath.mapID === base.map_id}"
             @click="selectBase === base.id ? selectBase = 0 : selectBase = base.id"
        >

          <input @click.stop="" type="text" maxlength="16" class="office_name input cursor_hover"
                 :class="{disable: !myRole || myRole.name !== 'Leader'}"
                 @change="rename($event, 'base', base.id)"
                 :value="base.name">

          <div class="office_base">{{ texts['text_15'][language] }}<span
            class='importantly'>{{ getSectorNameByID(base.map_id) }}</span></div>
          <div class="office_date">
            <template v-if="base.base_id === 0">
              {{ texts['text_16'][language] }}<span class='importantly'>{{ parseDate(base.end_build_time) }}</span>
            </template>
            <template v-if="base.base_id !== 0 && base.immortal && !base.siege">
              {{ texts['text_17'][language] }}
            </template>
            <template v-if="base.base_id !== 0 && !base.immortal && !base.siege">
              {{ texts['text_18'][language] }}
            </template>
            <template v-if="base.siege">
              <span style="color: #ff4c4c">{{ texts['text_19'][language] }}</span>
            </template>
          </div>

          <div v-if="selectBase === base.id"
               style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; margin-top: 2px; padding-top: 2px;">

            <div class="resource_wrapper" v-if="base.base_id !== 0">
              <div>
                <div style="color: white; text-shadow: 1px 1px 1px black; float: left; width: 45%;">
                  {{ texts['text_20'][language] }}
                </div>
                <div style="float: right; width: 45%; text-align: right;"><span
                  class='importantly'>{{ parseDate(base.end_build_time) }}</span>
                </div>
              </div>

              <div style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%; padding-top: 3px;">
                <div>
                  <div class="office_row no_hover">
                    <div class="office_base base" style="text-align: center"
                         :style="{background: getColorModule(base.map_id, 'office')}">
                      {{ texts['text_21'][language] }}
                    </div>
                    <div class="office_base base" style="text-align: center"
                         :style="{background: getColorModule(base.map_id, 'prefabricated')}">
                      {{ texts['text_22'][language] }}
                    </div>
                    <div class="office_base base" style="text-align: center"
                         :style="{background: getColorModule(base.map_id, 'processing')}">
                      {{ texts['text_23'][language] }}
                    </div>
                    <div class="office_base base" style="text-align: center"
                         :style="{background: getColorModule(base.map_id, 'market')}">
                      {{ texts['text_24'][language] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="resource_wrapper" v-if="base.structure === 'base' && base.base_id === 0">
              <div class="resource_row" v-for="r in base.resources" v-if="r.need > 0">

                <div class="icon">
                  <app-item-cell v-bind:item-slot="r.slot" v-bind:no-quantity="true"/>
                </div>

                <div class="progress_bar_wrapper">

                  <div class="progress_bar_head">
                    {{ r.slot.quantity }} / {{ r.need }}
                  </div>

                  <div class="progress_bar_outer">
                    <div class="progress_bar_inner" :style="{width: (100 / (r.need / r.slot.quantity)) + '%'}"/>
                  </div>

                </div>
              </div>
            </div>

            <div v-if="base.base_id !== 0" style="border-top: 1px solid rgba(0,0,0,0.5); float: left; width: 100%;">
              <div @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click.stop="autoPilot(base.base_id, 'target')"
                   class="cluster_button office_button cursor_hover"> {{ texts['button_19'][language] }}
              </div>

              <div @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click.stop="autoPilot(base.base_id, 'auto_pilot')"
                   class="cluster_button office_button cursor_hover"> {{ texts['button_20'][language] }}
              </div>

              <div style="float: right"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   :class="{disable: !viewRentalButton || mayRentalAdd(base.end_build_time) || !(baseStatus.state && base.base_id === baseStatus.state.id)}"
                   @click.stop="fillUpDialog()"
                   class="cluster_button office_button cursor_hover"> {{ texts['button_21'][language] }}
              </div>
            </div>
          </div>
        </div>

        <div :class="{disable: !buildBase}"
             @click="openService('buildDialogWrapper')"
             @mouseover="playSound('select_sound.mp3', 0.3)" class="cluster_button cursor_hover">
          {{ buildBase ? texts['text_25'][language] : texts['text_26'][language] }}
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import {urls} from '../../const';
import Preloader from "../Preloader/Preloader";
import ItemCell from "../Inventory/ItemCell";

export default {
  name: "Main",
  data() {
    return {
      logo: null,
      dialogShow: '',
      dialogData: {},
      selectOffice: 0,
      selectDeposit: 0,
      selectBase: 0,
      menu: {
        deposits: false,
        office: false,
        bases: false,
      },
      cursor: 0,
      limit: 15,
      operation: '',
    }
  },
  created() {
    this.getLogo();
  },
  methods: {
    getLogo() {
      let app = this;
      app.$api.get(urls.corpLogoURL + '?corporation_id=' + app.corporation.info.id).then(function (response) {
        app.$set(app, 'logo', response.data.avatar);
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    getBaseName(name) {
      if (this.handbook['base'][this.language][name]) {
        return this.handbook['base'][this.language][name].name
      }

      if (this.baseStatus.attr && this.baseStatus.attr.name && this.baseStatus.attr.name[this.language] !== "") {
        return this.baseStatus.attr.name[this.language]
      }

      return name
    },
    getBaseNameByID(id) {
      for (let i in this.handbook['base'][this.language]) {
        if (this.handbook['base'][this.language][i].id === id) {
          return this.handbook['base'][this.language][i].name;
        }
      }

      if (this.corporationBaseNames.hasOwnProperty(id)) {
        return this.corporationBaseNames[id]
      }

      return id
    },
    getSectorNameByID(id) {

      for (let i in this.handbook['sector'][this.language]) {
        if (this.handbook['sector'][this.language][i].id === id) {
          return this.handbook['sector'][this.language][i].name;
        }
      }

      return ''
    },
    transferDialog(baseID) {
      this.dialogShow = 'transfer'
      this.dialogData = {baseID: baseID}
    },
    fillUpDialog() {
      this.$store.commit({
        type: 'setRentalPrice',
        rental_price: null,
      });

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "GetBaseFillUpPrice",
        }
      }));

      this.dialogShow = 'fill_up'
    },
    rentalDialog(dialogType, baseID, depositID) {

      if (dialogType === 'rental' || dialogType === 'rental_add') {
        this.$store.commit({
          type: 'setRentalPrice',
          rental_price: null,
        });

        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "corporation",
          data: {
            event: "GetRentalPrice",
            data: dialogType,
            id: baseID,
          }
        }));
      }

      this.dialogShow = dialogType
      this.dialogData = {baseID: baseID, depositID: depositID}

      if (dialogType === 'deposit_history') {
        this.$store.commit({
          type: 'setDepositHistory',
          deposit_history: null,
        });

        this.getDepositLog()
      }
    },
    getDepositLog() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "GetDepositHistory",
          id: this.dialogData.depositID,
          cursor: this.cursor,
          limit: this.limit,
          tag: this.operation,
        }
      }));
    },
    next() {
      if (this.corporation.deposit_history.length > 0) {
        this.cursor = this.corporation.deposit_history[this.corporation.deposit_history.length - 1].id
        this.operation = ''
        this.getDepositLog()
      }
    },
    back() {
      this.operation = '>'
      this.getDepositLog()
    },
    fullBack() {
      this.cursor = 0;
      this.operation = '';
      this.getDepositLog()
    },
    rental() {
      this.dialogShow = ''

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "OfficeRental",
        }
      }));
    },
    rentalAdd() {
      this.dialogShow = ''

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "OfficeRentalAdd",
          id: this.dialogData.baseID,
        }
      }));
    },
    fillUpBase() {
      this.dialogShow = ''

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "BaseFillUp",
        }
      }));
    },
    mayRental() {
      if (!this.corporation.rental_price || this.corporation.rental_price < 0) {
        return false
      }

      if (this.corporation && this.corporation.deposits) {
        for (let d of this.corporation.deposits) {
          console.log(d.main && d.credits >= this.corporation.rental_price)
          if (d.main && d.credits >= this.corporation.rental_price) {
            return true
          }
        }
      }

      return false
    },
    parseDate(str) {
      let d = new Date(str);
      let minute = d.getMinutes()
      let hours = d.getHours()
      let day = d.getDate()
      let month = d.getMonth() + 1

      if (minute < 10) minute = '0' + minute;
      if (hours < 10) hours = '0' + hours;
      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      if (2500 < d.getFullYear()) {
        return "Вечно"
      }

      return day + "-" + month + "-" + d.getFullYear() + " " + hours + ":" + minute;
    },
    autoPilot(baseID, type) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "MoveToSector",
          base_id: Number(baseID),
          type: type,
        }
      }));
    },
    transfer(baseID) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "PlayerTransfer",
          id: Number(baseID),
        }
      }));
    },
    mayRentalAdd(time) {
      let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      let d1 = new Date(time);
      let d2 = new Date();

      let diffDays = Math.abs((d2 - d1) / oneDay)
      return diffDays >= 30
    },
    sortStorages(s) {
      return s.sort(function (a, b) {
        return a.id - b.id;
      });
    },
    rename(e, type, id) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "Rename",
          data: type,
          id: id,
          name: e.target.value,
        }
      }));
    },
    getOperationName(operation) {
      if (operation.includes('transfer_from_corp_')) {
        let name = operation.split('_')[3];
        return `Перевод от кластера <span class="importantly">${name}</span>`
      }

      if (operation.includes('transfer_to_corp_')) {
        let name = operation.split('_')[3];
        return `Перевод кластеру <span class="importantly">${name}</span>`
      }

      if (operation.includes('transfer_from_')) {
        let name = operation.split('_')[2];
        return `Перевод от <span class="importantly">${name}</span>`
      }

      if (operation.includes('transfer_to_')) {
        let name = operation.split('_')[2];
        return `Перевод <span class="importantly">${name}</span>`
      }

      if (operation.includes('corporation_global_tax_')) {
        let name = operation.split('_')[3];
        return `Налог (<span class="importantly">${name}</span>)`
      }


      if (operation.includes('corporation_tax')) {
        return `Налог с продажи`
      }

      if (operation === "choice_fraction") return 'Стартовый капитал'
      if (operation === "npc_sell") return 'Продажа товара'
      if (operation === "npc_buy") return 'Покупка товара'
      if (operation === "market_sell") return 'Продажа на рынке'
      if (operation === "market_buy") return 'Покупка на рынке'
      if (operation === "market_place") return 'Размещение заказа'
      if (operation === "market_cancel") return 'Отмена заказа'
      if (operation === "mission") return 'Выполнение миссии'
      if (operation === "skin_buy") return 'Покупка покраски'
      if (operation === "transfer_robbery") return 'Грабеж'
      if (operation === "repair") return 'Починка транспорта'
      if (operation === "item_repair") return 'Починка предмета'
      if (operation === "fraction_store_buy") return 'Покупка в фр. магазине'
      if (operation === "search") return 'Поиск'
      if (operation === "kill_10.0") return 'Устранение преступника'
      if (operation === "kill_3.0") return 'Устранение бойца в войне'
      if (operation === "kill_1.5") return 'Устранение сил APD'
      if (operation === "arm transport") return 'Покупка боеприпасов'
      if (operation === "fill up transport") return 'Заправка транспорта'
      if (operation === "build_base") return 'Строительство структуры'

      return operation
    },
    prepareHistoryCredits(log) {
      let credits = log.credits
      let color = 'yellowgreen'
      let prefix = '+'
      if (log.operation === 'take') {
        prefix = '-'
        color = '#ff6f6f'
      }

      return `<span style="color: ${color};">${prefix}${(credits / 100).toFixed(2)} cr.</span>`
    },
    findModule(mapID, type) {
      for (let m of this.corporation.bases.modules) {
        if (m.map_id === mapID && m.structure === type) {
          return m
        }
      }

      return false
    },
    getColorModule(mapID, type) {
      let m = this.findModule(mapID, type)
      if (!m || m.disable) {
        return '#c04f02'
      }

      return '#3e8d5e'
    }
  },
  computed: {
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    deposits() {
      if (this.corporation && this.corporation.deposits) {
        return this.corporation.deposits.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return [];
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
    offices() {
      let offices = []
      if (this.corporation && this.corporation.offices) {
        for (let i in this.corporation.offices) {
          offices.push(this.corporation.offices[i])
        }

        return offices.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      return offices
    },
    bases() {
      let bases = this.corporation.bases.bases

      for (let i in bases) {
        bases[i].resources = this.corporation.bases.resources[bases[i].id]
      }

      for (let i in bases) {
        bases[i].siege = this.corporation.bases.siege[bases[i].id]
      }

      return bases
    },
    viewButton() {

      if (!this.baseStatus || !this.baseStatus.state) return false;

      if (this.baseStatus.state.type !== 'base') return false;
      if (!this.corporation || !this.myRole) return false;
      if (this.myRole.name !== "Leader") return false;
      if (this.corporation.offices) {
        for (let i in this.corporation.offices) {
          if (this.corporation.offices[i].base_id === this.baseStatus.state.id) {
            return false
          }
        }
      }

      return true
    },
    viewRentalButton() {
      if (!this.baseStatus || !this.baseStatus.state) return false;
      if (this.baseStatus.state.type !== 'corporation') return false;
      if (!this.corporation || !this.myRole) return false;
      if (this.myRole.name !== "Leader") return false;

      return true
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    myRole() {
      let corp = this.corporation;
      if (corp) {
        return corp.roles[corp.members[this.currentPlayer.id].role_id]
      }

      return null
    },
    globalPath() {
      return this.$store.getters.getGlobalPath
    },
    buildBase() {
      let sector = this.$store.getters.getSectorState
      if (!sector || !sector.free_land) return false; // TODO сектор пренадлежит уже другим игрокам
      if (this.baseStatus && this.baseStatus.state) return false;

      return true
    },
    market() {
      return this.$store.getters.getMarket
    },
    noFillUpBase() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('no_fill_up_base') && errors['no_fill_up_base'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'no_fill_up_base',
            active: false,
          });
        }.bind(this), 750)

        return true
      }

      return false
    },
    corporationBaseNames() {
      return this.$store.getters.getCorporationBaseNames
    }
  },
  components: {
    AppPreloader: Preloader,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.userAvatar {
  background-color: rgba(0, 0, 0, 0.5);
  height: 95px;
  width: 95px;
  float: left;
  margin: -1px 5px 0px -1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 black;
  background-size: cover;
}

.head {
  margin: 0 0 10px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  padding-left: 10px;
  position: relative;
}

.tag {
  color: #e4ff00;
  font-size: 10px;
}

p {
  font-size: 12px;
  text-indent: 4px;
  word-wrap: break-word;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.deposit, .office_row, .storage_row {
  width: calc(100% - 10px);
  height: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px;
  border-radius: 2px;
  box-shadow: 0 0 2px black;
  margin: 2px 2px 5px 2px;
}

.deposit:hover, .office_row:hover {
  box-shadow: 0 0 1px 1px white;
}

.credits_icon, .inventory_icon {
  height: 16px;
  width: 16px;
  margin-top: 1px;
  float: left;
  filter: drop-shadow(0px 0px 1px black);
  background-size: contain;
  background-image: url('../../assets/icons/credits_mini.png')
}

.inventory_icon {
  background-image: url('../../assets/icons/inventory_size_icon.png');
  height: 14px;
  width: 14px;
}

.credits_count, .credits_name, .office_name, .office_base, .office_date, .storage_name {
  background: #066ea8;
  float: right;
  width: calc(65% - 14px);
  padding-right: 4px;
  border-radius: 4px;
  color: white;
  text-align: right;
  line-height: 18px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 2px black;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 16px;
}

.office_name, .office_base, .office_date {
  margin-right: 2px;
  width: calc(33% - 5px);
  float: left;
  text-align: left;
  text-indent: 5px;
}

.office_base.base {
  width: calc(25% - 6px);
}

.storage_name {

}

.credits_name {
  margin-right: 2px;
}

.cluster_button {
  margin: 3px 3px 0 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  width: 250px;
  position: relative;
  left: calc(50% - 125px);
  user-select: none;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.wrapper, .deposit_history_wrapper {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 80px);
  z-index: 11;
  width: 160px;
  height: 80px;
  border: 1px solid rgba(8, 138, 210, 0.3);
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  padding: 3px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
}

.wrapper.transfer {
  top: 15%;
  left: calc(50% - 150px);
  width: 300px;
  height: 120px;
}

.text_wrapper.transfer {
  height: 90px;
}

.deposit_history_wrapper {
  top: 18px;
  left: 0;
  width: calc(100% - 8px);
  height: calc(100% - 26px);
}

.text_wrapper {
  height: 49px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 black;
  padding: 5px;
  position: relative;
  bottom: unset;
  width: calc(100% - 10px);
  background-color: rgba(7, 41, 60, 0.4);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.office_button {
  width: calc(25% - 4px);
  float: left;
  margin: 2px;
  left: unset;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 3px;
}

.open_office_row {
  height: 145px;
}

.open_base_row {
  min-height: 45px;
  overflow: auto;
  height: unset;
}

.open_deposit_row {
  height: 42px;
}

.path {
  background: rgba(227, 152, 22, 0.6);
}

.office_name.input, .credits_name.input, .storage_name.input {
  width: calc(33% - 10px);
  height: 13px;
  padding-top: 2px;
  color: #ff7800;
  font-weight: bold;
  background: rgb(0, 0, 0, 0.3);
}

.credits_name.input {
  width: calc(35% - 18px);
}

.storage_name.input {
  width: calc(100% - 25px);
  text-align: left;
}

.history_credits {
  width: 100%;
  border-spacing: 0;
  word-wrap: break-word;
  table-layout: fixed;
}

.history_credits tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 13;
}

.history_credits td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  padding-top: 4px;
  font-size: 13px;
  position: relative;
}

.head.menu {
  background: rgba(255, 129, 0, 0.6);
}

.head.menu:hover {
  background: rgba(255, 129, 0, 1);
}

.head.menu:active {
  background: rgb(248, 171, 92);
}

.resource_wrapper {

}

.resource_row {
  float: left;
  width: calc(100% - 10px);
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  margin: 2px 2px 2px 5px;
  filter: drop-shadow(0 0 2px black);
  box-shadow: 0 0 1px black;
  border-radius: 3px;
}

.icon {
  float: left;
}

.progress_bar_wrapper {
  float: left;
  width: calc(100% - 40px);
  margin-top: 5px;
  position: relative;
}

.progress_bar_head {
  text-align: center;
  font-size: 12px;
  color: white;
}

.progress_bar_outer {
  height: 8px;
  border: 1px solid #a5a5b4;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 black;
  overflow: hidden;
  background: #404041;
  position: relative;
}

.progress_bar_inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: inset 0 0 2px 0 black;
  width: 50%;
  background-color: #0cc2fb;
}

.no_hover:hover {
  box-shadow: 0 0 2px black !important;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 750ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.help_point_menu {
  height: 13px;
  width: 13px;
  /* background: black; */
  position: absolute;
  right: 3px;
  bottom: 2px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}
</style>
