<template>
  <div id="marketBox" ref="marketBox" @mousedown="toUp">
    <!--  TODO рефакторинг требует наши сердца-->
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'marketBox'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 280, width: 490}"/>

    <div class="error_message" :class="{error_message_visible: noUseMarketService}">
      <div style="margin-top: 14%">{{ texts['error_1'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: removeLastBody}">
      <div style="margin-top: 14%">
        Нельзя выбросить/продать <span class="importantly">последний корпус</span>.
      </div>
    </div>

    <div class="error_message" v-if="marketSendItems" :class="{error_message_visible: marketSendItems}">
      <div style="margin-top: 14%">
        Предметы отправлены на <span class="importantly">склад</span>
        базы <span class="importantly">{{ getBaseName(marketSendItems) }}</span>.
      </div>
    </div>

    <div class="order_sub_menu"
         v-if="subMenu.order"
         v-bind:style="{ left: subMenu.x + 'px', top: subMenu.y + 'px' }">
      <div class="wrapper_sub_menu">
        <div class="wrapper_sub_menu_action">

          <input type="button" class="cursor_hover"
                 :value="texts['sub_button_1'][language]"
                 @click.stop="detail(subMenu.order.TypeItem, {id: subMenu.order.IdItem})"
                 @mouseover="playSound('select_sound.mp3', 0.3)">

          <input type="button" class="cursor_hover" v-if="subMenu.dialogType !== ''"
                 :value="getSubMenuName(subMenu.dialogType)"
                 @click.stop="openDialogFromSubMenu()"
                 @mouseover="playSound('select_sound.mp3', 0.3)">

          <input type="button" class="cursor_hover"
                 :value="texts['sub_button_4'][language]"
                 @click.stop="autoPilot(subMenu.order.PlaceID, 'auto_pilot')"
                 @mouseover="playSound('select_sound.mp3', 0.3)">

          <input type="button" class="cursor_hover"
                 :value="texts['sub_button_5'][language]"
                 @click.stop="autoPilot(subMenu.order.PlaceID, 'target')"
                 @mouseover="playSound('select_sound.mp3', 0.3)">

          <input type="button" class="cursor_hover"
                 :value="texts['sub_button_6'][language]"
                 @click.stop="openSubMenu($event)"
                 @mouseover="playSound('select_sound.mp3', 0.3)">
        </div>
      </div>
    </div>

    <div id="leftBar">

      <div id="headMarket">
        <div class="filterTab cursor_hover"
             @click="changeFilterTab('filter')"
             :class="{filterTabDisable: filterTab === 'search'}"/>
        <div class="filterTab two cursor_hover"
             @click="changeFilterTab('search')"
             :class="{filterTabDisable: filterTab === 'filter', disable: tab==='my_orders' || tab==='history'}"/>
        <div class="decor" style="margin-left: 28px"/>
        <div class="decor"/>
        <div class="decor"/>
        <div class="decor"/>
      </div>

      <app-filter v-if="filterTab === 'filter'"/>
      <div class="searchByNameBlock" v-if="filterTab === 'search'">
        <input type="text" v-model="findName" @input="findByName" placeholder="Название" class="cursor_hover">

        <div v-for="s in itemFilters"
             class="item_row cursor_hover"
             @click="selectFilterItem(s.type, s.item.id, s.item)"
             :class="{item_row_select: filterItemSelect && filterItemSelect.type === s.type && filterItemSelect.id === s.item.id}">
          <div class="item_row_icon">
            <app-background-item-cell v-bind:slotItem="s" v-bind:noShowName="true"/>
          </div>

          <div class="item_row_heads">
            <h4 v-if="s.type === 'blueprints'">{{ getNameItem(s.item.item_type, s.item.item_name) }}</h4>
            <h4 v-if="s.type !== 'blueprints'">{{ getNameItem(s.type, s.item.name) }}</h4>
            <h6>{{ handbook.categories[language][s.type].name }}</h6>
          </div>

          <h6 class="item_row_price"
              v-html="texts['text_14'][language].replace('%credits_count%', (s.price / 100).toFixed(2))"/>
        </div>
      </div>
    </div>

    <div id="ordersBlock">

      <div class="marketTopMenu">
        <div v-bind:class="{active: tab==='orders'}"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="switchTab('orders')">
          {{ texts['tab_1'][language] }}
        </div>
        <div v-bind:class="{active: tab==='my_orders'}"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="switchTab('my_orders')">
          {{ texts['tab_2'][language] }}
        </div>
        <div v-bind:class="{active: tab==='history'}"
             class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="switchTab('history')">
          {{ texts['tab_3'][language] }}
        </div>
      </div>

      <template v-if="tab==='orders'">

        <div id="selectItemIcon">
          <template v-if="filters.item">
            <app-background-item-cell v-bind:slotItem="getSlotIcon"/>
            <div class="detail_button cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="detail(getSlotIcon.type, getSlotIcon.item)"/>
          </template>
        </div>

        <div id="selectItemName">
          <template v-if="filters.item">
            <span>{{ itemName }}</span>
            <span class="category">{{ handbook.categories[language][filters.selectType].name }}</span>
            <div class="marketButton cursor_hover"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="openDialog($event, getSlotIcon, 'new-buy')" style="margin: 0 auto;">
              Купить
            </div>
          </template>
        </div>

        <div class="ordersHead cursor_hover"
             style="clear: both; display: block;"
             :class="{disable: !(tableView === 'both' || tableView === 'sell')}"
             @click="tableViewChange('sell')">
          <div class="table_icon"></div>
          {{ texts['head_table_1'][language] }}
        </div>

        <div class="ordersHead ordersHeadBuy cursor_hover"
             :class="{disable: !(tableView === 'both' || tableView === 'buy')}"
             @click="tableViewChange('buy')">
          <div class="table_icon buy"></div>
          {{ texts['head_table_2'][language] }}
        </div>

        <div id="sellOrdersBlock" v-if="tableView === 'both' || tableView === 'sell'">
          <table class="ordersTable" id="marketSellTable">
            <tr>
              <th style="width: 25px">
                <div class="table_icon"></div>
              </th>

              <th @click="setSortTable('sell', 'path_jump')" class="th_hover cursor_hover">
                {{ texts['head_col_table_1'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['sell'].field === 'path_jump' && sortData['sell'].sort === 'asc',
                  desc: sortData['sell'].field === 'path_jump' && sortData['sell'].sort === 'desc',
                }"/>
              </th>

              <th @click="setSortTable('sell', 'Count')" class="th_hover cursor_hover">
                {{ texts['head_col_table_2'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['sell'].field === 'Count' && sortData['sell'].sort === 'asc',
                  desc: sortData['sell'].field === 'Count' && sortData['sell'].sort === 'desc',
                }"/>
              </th>

              <th @click="setSortTable('sell', 'Price')" class="th_hover cursor_hover">
                {{ texts['head_col_table_3'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['sell'].field === 'Price' && sortData['sell'].sort === 'asc',
                  desc: sortData['sell'].field === 'Price' && sortData['sell'].sort === 'desc',
                }"/>
              </th>

              <th>{{ texts['head_col_table_4'][language] }}</th>
              <th>{{ texts['head_col_table_5'][language] }}</th>
              <th>{{ texts['head_col_table_6'][language] }}</th>
            </tr>

            <template v-if="filters.selectType === '' && filterTab === 'filter'">
              <tr style="height: 20px"></tr>
              <tr>
                <td colspan="7"><span class="importantly" style="font-size: 14px">Выберите категорию</span></td>
              </tr>
            </template>

            <template v-if="filters.selectType !== '' && market.preloader">
              <tr style="height: 20px"></tr>
              <tr>
                <td colspan="7">
                  <div class="preloader">
                    <app-preloader size="20px"/>
                  </div>
                </td>
              </tr>
            </template>

            <tr v-for="order in sortOrders('sell')"
                v-bind:class="{my : market.my_orders.includes(order.Id), path: globalPath.baseID === order.PlaceID}"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click="openSubMenu($event, order, 'buy')"
                class="marketRow cursor_hover">

              <td style="width: 35px">
                <div class="order_icon">
                  <app-background-item-cell v-bind:slotItem="{type: order.TypeItem, item: order.Item}"/>
                </div>
              </td>

              <td v-if="order.path_jump === -999"><span class='basePath'>{{ texts['dist_1'][language] }}</span>
              </td>
              <td v-if="order.path_jump === -1"><span class='basePath'>{{ texts['dist_2'][language] }}</span>
              </td>
              <td v-if="order.path_jump === 0"><span class='basePath'>{{ texts['dist_3'][language] }}</span>
              </td>
              <td v-if="order.path_jump > 0">{{ order.path_jump }}</td>

              <td>{{ order.Count }}</td>
              <td class="creditsTD">{{ (order.Price / 100).toFixed(2) }}</td>
              <td>{{ handbook.categories[language][order.TypeItem].name }}</td>
              <td>{{ getNameItemByOrder(order) }}</td>
              <td>{{ getBaseName(order.PlaceID) }}</td>
            </tr>
          </table>
        </div>

        <div id="BuyOrdersBlock" v-if="tableView === 'both' || tableView === 'buy'">
          <table class="ordersTable" id="marketBuyTable">
            <tr>
              <th style="width: 35px">
                <div class="table_icon buy"></div>
              </th>

              <th @click="setSortTable('buy', 'path_jump')" class="th_hover cursor_hover">
                {{ texts['head_col_table_7'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['buy'].field === 'path_jump' && sortData['buy'].sort === 'asc',
                  desc: sortData['buy'].field === 'path_jump' && sortData['buy'].sort === 'desc',
                }"/>
              </th>

              <th @click="setSortTable('buy', 'Count')" class="th_hover cursor_hover">
                {{ texts['head_col_table_8'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['buy'].field === 'Count' && sortData['buy'].sort === 'asc',
                  desc: sortData['buy'].field === 'Count' && sortData['buy'].sort === 'desc',
                }"/>
              </th>

              <th @click="setSortTable('buy', 'Price')" class="th_hover cursor_hover">
                {{ texts['head_col_table_9'][language] }}

                <div class="sortArrowIcon"
                     :class="{
                  asc: sortData['buy'].field === 'Price' && sortData['buy'].sort === 'asc',
                  desc: sortData['buy'].field === 'Price' && sortData['buy'].sort === 'desc',
                }"/>
              </th>

              <th>{{ texts['head_col_table_10'][language] }}</th>
              <th>{{ texts['head_col_table_11'][language] }}</th>
              <th>{{ texts['head_col_table_12'][language] }}</th>
              <!--              <th>{{ language === 'RU' ? 'Мин. выкуп' : 'Min. redemption' }} </th>-->
            </tr>

            <template v-if="filters.selectType === '' && filterTab === 'filter'">
              <tr style="height: 20px"></tr>
              <tr>
                <td colspan="7"><span class="importantly" style="font-size: 14px">Выберите категорию</span></td>
              </tr>
            </template>

            <template v-if="filters.selectType !== '' && market.preloader">
              <tr style="height: 20px"></tr>
              <tr>
                <td colspan="7">
                  <div class="preloader">
                    <app-preloader size="20px"/>
                  </div>
                </td>
              </tr>
            </template>

            <tr v-for="order in sortOrders('buy')"
                v-bind:class="{my : market.my_orders.includes(order.Id), path: globalPath.baseID === order.PlaceID}"
                @mouseover="playSound('select_sound.mp3', 0.3)"
                @click="openSubMenu($event, order, 'sell')"
                class="marketRow cursor_hover">

              <td style="width: 35px">
                <div class="order_icon">
                  <app-background-item-cell v-bind:slotItem="{type: order.TypeItem, item: order.Item}"/>
                </div>
              </td>

              <td v-if="order.path_jump === -999"><span class='basePath'>{{ texts['dist_1'][language] }}</span>
              </td>
              <td v-if="order.path_jump === -1"><span class='basePath'>{{ texts['dist_2'][language] }}</span>
              </td>
              <td v-if="order.path_jump === 0"><span class='basePath'>{{ texts['dist_3'][language] }}</span>
              </td>
              <td v-if="order.path_jump > 0">{{ order.path_jump }}</td>

              <td>{{ order.Count }}</td>
              <td class="creditsTD">{{ (order.Price / 100).toFixed(2) }}</td>
              <td>{{ handbook.categories[language][order.TypeItem].name }}</td>
              <td>{{ getNameItemByOrder(order) }}</td>
              <td>{{ getBaseName(order.PlaceID) }}</td>
              <!--              <td>{{ order.MinBuyOut }}</td>-->
            </tr>
          </table>
        </div>
      </template>

      <div id="MyOrdersBlock" v-if="tab==='my_orders'">
        <table class="ordersTable" id="marketMyTable">
          <tr>
            <th style="width: 25px"></th>
            <th @click="setSortTable('my', 'path_jump')" class="th_hover cursor_hover">
              {{ texts['head_col_table_13'][language] }}

              <div class="sortArrowIcon"
                   :class="{
                  asc: sortData['my'].field === 'path_jump' && sortData['my'].sort === 'asc',
                  desc: sortData['my'].field === 'path_jump' && sortData['my'].sort === 'desc',
                }"/>
            </th>
            <th @click="setSortTable('my', 'Count')" class="th_hover cursor_hover">
              {{ texts['head_col_table_14'][language] }}

              <div class="sortArrowIcon"
                   :class="{
                  asc: sortData['my'].field === 'Count' && sortData['my'].sort === 'asc',
                  desc: sortData['my'].field === 'Count' && sortData['my'].sort === 'desc',
                }"/>
            </th>
            <th @click="setSortTable('my', 'Price')" class="th_hover cursor_hover">
              {{ texts['head_col_table_15'][language] }}

              <div class="sortArrowIcon"
                   :class="{
                  asc: sortData['my'].field === 'Price' && sortData['my'].sort === 'asc',
                  desc: sortData['my'].field === 'Price' && sortData['my'].sort === 'desc',
                }"/>
            </th>
            <th>{{ texts['head_col_table_16'][language] }}</th>
            <th>{{ texts['head_col_table_17'][language] }}</th>
            <th>{{ texts['head_col_table_18'][language] }}</th>
            <!--            <th>{{ language === 'RU' ? 'Мин. выкуп' : 'Min. redemption' }}</th>-->
            <th>{{ texts['head_col_table_19'][language] }}</th>
            <th>{{ texts['head_col_table_20'][language] }}</th>
            <th></th>
          </tr>

          <template v-if="filters.selectType !== '' && market.preloader">
            <tr style="height: 20px"></tr>
            <tr>
              <td colspan="7">
                <div class="preloader">
                  <app-preloader size="20px"/>
                </div>
              </td>
            </tr>
          </template>

          <tr v-for="order in sortOrders('my')" class="marketRow myOrders">

            <td style="width: 35px">
              <div class="order_icon">
                <app-background-item-cell
                  v-bind:slotItem="{type: order.TypeItem, item: order.Item}"/>
              </div>
            </td>

            <td v-if="order.path_jump === -999"><span class='basePath'>{{
                texts['dist_1'][language]
              }}</span>
            </td>
            <td v-if="order.path_jump === -1"><span class='basePath'>{{ texts['dist_2'][language] }}</span>
            </td>
            <td v-if="order.path_jump === 0"><span class='basePath'>{{ texts['dist_3'][language] }}</span>
            </td>
            <td v-if="order.path_jump > 0">{{ order.path_jump }}</td>
            <td v-if="order.path_jump === 'undefined'">Недостижимо</td>

            <td>{{ order.Count }}</td>
            <td class="creditsTD">{{ (order.Price / 100).toFixed(2) }}</td>
            <td>{{ handbook.categories[language][order.TypeItem].name }}</td>
            <td>{{ getNameItemByOrder(order) }}</td>
            <td>{{ getBaseName(order.PlaceID) }}</td>
            <!--            <td>{{ order.MinBuyOut }}</td>-->

            <td>
              <span v-if="order.Type === 'sell'" style='color: #ffb300'>{{ order.Type }}</span>
              <span v-if="order.Type === 'buy'" style='color: #25ff00'>{{ order.Type }}</span>
            </td>

            <td>{{ ((order.Price * order.Count) / 100).toFixed(2) }}</td>
            <td><input class="button cancel order cursor_hover" type="button" value="Отменить"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click="cancelOrder(order.Id)"></td>
          </tr>
        </table>
      </div>

      <div class="history_block" v-if="tab==='history'">
        <table class="ordersTable" id="marketMyTable">
          <tr>
            <th style="width: 25px"></th>
            <th>{{ texts['head_col_table_21'][language] }}</th>
            <th>{{ texts['head_col_table_22'][language] }}</th>
            <th>{{ texts['head_col_table_23'][language] }}</th>
            <th>{{ texts['head_col_table_24'][language] }}</th>
            <th>{{ texts['head_col_table_25'][language] }}</th>
          </tr>

          <tr v-for="deal in market.history" v-if="market.history.length > 0"
              @click="openSubMenu($event, {
                TypeItem: deal.item_type,
                IdItem: deal.item_id,
                PlaceID: deal.base_id,
              }, '')"
              class="marketRow myOrders">

            <td style="width: 35px">
              <div class="order_icon">
                <app-background-item-cell
                  v-bind:slotItem="{type: deal.item_type, item: getItemByTypeAndID(deal.item_type, deal.item_id)}"/>
              </div>
            </td>

            <td>
              {{ getNameItem(deal.item_type, getItemByTypeAndID(deal.item_type, deal.item_id).name) }}
            </td>
            <td>{{ deal.count }}</td>
            <td>
              <span v-if="deal.sell_player === currentPlayer.id" style='color: #ffb300'>
                {{ texts['deal_t1'][language] }}</span>
              <span v-if="deal.buy_player === currentPlayer.id" style='color: #25ff00'>
                {{ texts['deal_t2'][language] }}</span>
            </td>
            <td class="creditsTD">
              <span v-if="deal.sell_player === currentPlayer.id">{{ (deal.price_with_tax / 100).toFixed(2) }}</span>
              <span v-if="deal.buy_player === currentPlayer.id">{{ (deal.price / 100).toFixed(2) }}</span>
            </td>
            <td>{{ getBaseName(deal.base_id) }}</td>
          </tr>
        </table>
      </div>
    </div>

    <template v-if="dialog.order">
      <app-sell v-if="dialog.dialogType === 'sell'" v-bind:sub-menu-props="dialog" v-bind:order="dialog.order"/>
      <app-buy v-if="dialog.dialogType === 'buy'" v-bind:sub-menu-props="dialog" v-bind:order="dialog.order"/>
      <app-new-buy v-if="dialog.dialogType === 'new-buy'" v-bind:sub-menu-props="dialog"
                   v-bind:slotItem="dialog.order"/>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import BackgroundItemCell from '../Inventory/BackgroundItemCell'
import Buy from "./Buy"
import NewBuy from "./NewBuy"
import Sell from "./Sell";
import Filter from "./Filter"
import {GetItemByTypeAndID} from "../../game/utils/get_item_by_type_and_id";
import Vue from "vue";
import Preloader from "../Preloader/Preloader";

export default {
  name: "Market",
  data() {
    return {
      tab: "orders",
      filterTab: "filter",
      findName: "",
      filterItemSelect: false,
      dialog: {
        dialogType: '', // sell, buy, newBuy
        order: null,
        x: 0,
        y: 0,
      },
      subMenu: {
        dialogType: '', // sell, buy, newBuy
        order: null,
        x: 0,
        y: 0,
      },
      updater: null,
      closeSubMenuHandler: null,
      ignoreFirstClick: true,
      tableView: 'both', // buy, sell,
      sortData: {
        sell: {
          field: 'path_jump', // path_jump, Count, Price
          sort: 'asc', // asc, desc
        },
        buy: {
          field: 'path_jump', // path_jump, Count, Price
          sort: 'asc', // asc, desc
        },
        my: {
          field: 'path_jump', // path_jump, Count, Price
          sort: 'asc', // asc, desc
        },
      },
    }
  },
  destroyed() {
    clearInterval(this.updater)
    window.removeEventListener("click", this.closeSubMenuHandler)
  },
  mounted() {
    if (!this.market.assortment.ammo) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetAssortment",
        }
      }));
    }

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "market",
      data: {
        event: "GetHistory",
      }
    }));

    this.getOrders()

    this.updater = setInterval(function () {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetBaseTax",
        }
      }));
    }.bind(this), 1000)
  },
  methods: {
    getOrders() {

      this.$store.commit({
        type: 'setMarketState',
        orders: {},
        my_orders: {},
        orders_distance: [],
        preloader: true,
      });

      let filters = this.filters[this.filters.selectType]
      if (this.filterItemSelect) {
        filters = this.filterItemSelect
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetOrders",
          type: this.filters.selectType,
          name: this.findName,
          filter: filters,
        }
      }));
    },
    getPlayersOrders() {
      this.$store.commit({
        type: 'setMarketState',
        orders: {},
        my_orders: {},
        orders_distance: [],
        preloader: true,
      });

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "GetPlayerOrders",
        }
      }));
    },
    findByName() {
      this.filterItemSelect = false
      if (this.findName.length >= 3) {
        this.getOrders();
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: "GetItemsByFilterName",
            name: this.findName,
          }
        }));
      } else {
        this.$store.commit({
          type: 'setMarketItemsByFilterName',
          filterItems: [],
        });

        this.$store.commit({
          type: 'setMarketState',
          orders: {},
          my_orders: {},
          orders_distance: [],
          preloader: true,
        });
      }
    },
    selectFilterItem(type, id, item) {
      if (this.filterItemSelect.type === type && this.filterItemSelect.id === id) {
        this.filterItemSelect = false
        this.getOrders();
        this.$store.commit({
          type: 'setMarketFilter',
          item: null,
          main: '',
          id: 0,
        });
        return;
      }

      this.$store.commit({
        type: 'setMarketFilter',
        item: item,
        main: type,
        id: id,
      });

      this.filterItemSelect = {type: type, id: id}
      this.getOrders();
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeFilterTab(tab) {
      this.filterTab = tab;

      this.$store.commit({
        type: 'setMarketFilter',
        main: "",
        item: null,
        filterType: "",
        size: 0,
        id: 0,
      });

      this.$store.commit({
        type: 'setMarketItemsByFilterName',
        filterItems: [],
      });

      this.findName = ''
      this.filterItemSelect = false

      this.$store.commit({
        type: 'setMarketState',
        orders: {},
        my_orders: {},
        orders_distance: [],
        preloader: true,
      });

      setTimeout(function () {
        this.resize(null, null, $(this.$el));
      }.bind(this), 0);
    },
    tableViewChange(table) {
      let el = $(this.$el)
      if (this.tableView === 'both' || this.tableView !== table) {
        this.tableView = table
      } else {
        this.tableView = 'both'
        el.find('#sellOrdersBlock').css("height", el.height() / 2 - 53);
        el.find('#BuyOrdersBlock').css("height", el.height() / 2 - 53);
      }

      setTimeout(function () {
        this.resize(null, null, el);
      }.bind(this), 0);
    },
    getBaseName(id) {
      for (let i in this.handbook.base[this.language]) {
        if (this.handbook.base[this.language][i].id === Number(id)) {
          return this.handbook.base[this.language][i].name
        }
      }

      if (this.corporationBaseNames.hasOwnProperty(id)) {
        return this.corporationBaseNames[id]
      }

      return id
    },
    getItemByTypeAndID(type, id) {
      return GetItemByTypeAndID(type, id)
    },
    autoPilot(baseID, type) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "MoveToSector",
          base_id: Number(baseID),
          type: type,
        }
      }));
      this.subMenu.order = null;
    },
    openSubMenu(event, order, dialogType) {
      let app = this;
      app.subMenu.order = order;
      app.subMenu.dialogType = dialogType;

      if (!event) return;

      app.subMenu.x = ((event.pageX - $(app.$refs['marketBox']).position().left) / app.interfaceZoom);
      app.subMenu.y = ((event.pageY - $(app.$refs['marketBox']).position().top) / app.interfaceZoom);

      window.removeEventListener("click", app.closeSubMenuHandler)

      if (order) {
        app.ignoreFirstClick = true
        app.closeSubMenuHandler = app.closeSubMenu(app)
        window.addEventListener("click", app.closeSubMenuHandler);
      }
    },
    closeSubMenu(vue) {
      return function wrapperCloseSellSubMenu(event) {
        if (vue.ignoreFirstClick) {
          vue.ignoreFirstClick = false
          return;
        }

        let a = event.target
        let els = [];

        while (a) {
          els.unshift(a);
          a = a.parentNode;

          if (a && a.className === 'order_sub_menu') return;
        }

        vue.openSubMenu(event)
      }
    },
    openDialogFromSubMenu() {
      this.playSound('button_press.mp3', 0.3)
      this.dialog.order = this.subMenu.order;
      this.dialog.dialogType = this.subMenu.dialogType;
      this.dialog.x = this.subMenu.x;
      this.dialog.y = this.subMenu.y;

      this.subMenu.order = null;
    },
    openDialog(event, order, dialogType) {
      this.playSound('button_press.mp3', 0.3)

      let app = this;
      app.dialog.order = order;
      this.dialog.dialogType = dialogType;

      setTimeout(function () {
        app.dialog.x = event.pageX - $(app.$refs['marketBox']).position().left;
        app.dialog.y = event.pageY - $(app.$refs['marketBox']).position().top;
      }, 0)
    },
    getNameItemByOrder(order) {
      if (order.TypeItem === "blueprints") {
        if (order.Item.in_map) {
          return this.handbook['structure'][this.language][order.Item.name.split('_bp')[0]].name
        } else {
          return this.handbook[order.Item.item_type][this.language][order.Item.item_name].name
        }
      }

      return this.handbook[order.TypeItem][this.language][order.Item.name].name
    },
    getNameItem(type, name) {
      if (type === "blueprints") {
        return "Чертеж"
      }

      return this.handbook[type][this.language][name].name
    },
    cancelOrder(id) {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: "CancelOrder",
          id: Number(id)
        }
      }));
    },
    switchTab(tab) {
      this.playSound('button_press.mp3', 0.3)

      let app = this;
      app.tab = tab;

      this.$store.commit({
        type: 'setMarketFilter',
        main: "",
        item: null,
        filterType: "",
        size: 0,
        id: 0,
      });

      if (tab === 'orders') {
        this.getOrders();
      }

      if (tab === 'my_orders') {
        this.getPlayersOrders()
      }

      setTimeout(function () {
        app.resize(null, null, $(app.$el));
      }, 0);
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    resize(event, ui, el) {

      el.find('#listItem').css("height", el.height() - 40);
      el.find('.searchByNameBlock').css("height", el.height() - 40);

      el.find('#ordersBlock').css("height", el.height() - 7);

      if (this.tableView === 'both') {
        el.find('#sellOrdersBlock').css("height", el.height() / 2 - 53);
        el.find('#BuyOrdersBlock').css("height", el.height() / 2 - 53);
      } else if (this.tableView === 'sell') {
        el.find('#sellOrdersBlock').css("height", el.height() - 101);
      } else {
        el.find('#BuyOrdersBlock').css("height", el.height() - 101);
      }

      el.find('#MyOrdersBlock').css("height", el.height() - 26);
      el.find('.history_block').css("height", el.height() - 26);

      el.find('#ordersBlock').css("width", el.width() - 187);
      el.find('#sellOrdersBlock').css("width", el.width() - 191);
      el.find('#BuyOrdersBlock').css("width", el.width() - 191);
      el.find('#MyOrdersBlock').css("width", el.width() - 191);
      el.find('.history_block').css("width", el.width() - 191);

      el.find('#selectItemIcon').css("width", 45);
      el.find('#selectItemIcon').css("height", 45);
    },
    detail(type, item) {
      this.playSound('button_press.mp3', 0.3)

      if (item.hasOwnProperty('id')) {
        this.$store.commit({
          type: 'toggleWindow',
          id: 'detailItem' + type + item.id,
          component: 'detailItem',
          meta: {itemType: type, itemID: item.id},
          forceOpen: true,
        });
      } else {
        this.$store.commit({
          type: 'toggleWindow',
          id: 'detailItem' + type + item.type_id,
          component: 'detailItem',
          meta: {itemType: type, itemID: item.type_id},
          forceOpen: true,
        });
      }

      this.openSubMenu()
    },
    orderVisible(order, emptyType) {
      return true

      let filterType = this.filters[this.filters.selectType];
      if (!filterType && emptyType) return true

      if (this.filters.selectType === 'body' && order.TypeItem === 'body') {
        if ((filterType.size === order.Item.standard_size || filterType.size === 0) &&
          (filterType.id === order.IdItem || filterType.id === 0)) {
          return true
        }
      }

      if ((this.filters.selectType === 'weapon' && order.TypeItem === 'weapon') || (this.filters.selectType === 'ammo' && order.TypeItem === 'ammo')) {

        let item = this.getItemByTypeAndID(order.TypeItem, order.IdItem)

        if ((filterType.size === order.Item.standard_size || filterType.size === 0) &&
          (filterType.id === order.IdItem || filterType.id === 0) &&
          (filterType.type === item.type || filterType.type === '')) {
          return true
        }
      }

      if (this.filters.selectType === 'equip' && order.TypeItem === 'equip') {
        if ((filterType.size === order.Item.type_slot || filterType.size === 0) &&
          (filterType.id === order.IdItem || filterType.id === 0)) {
          return true
        }
      }

      if (this.filters.selectType === 'boxes' && order.TypeItem === 'boxes' ||
        this.filters.selectType === 'trash' && order.TypeItem === 'trash' ||
        this.filters.selectType === 'product' && order.TypeItem === 'product') {
        if (filterType.id === order.IdItem || filterType.id === 0) return true
      }

      if ((this.filters.selectType === 'blueprints' && order.TypeItem === 'blueprints')) {
        if ((filterType.id === order.IdItem || filterType.id === 0) &&
          (filterType.type === order.Item.item_type || filterType.type === '' || filterType.type === 'map' && order.Item.in_map)) {
          return true
        }
      }

      if (this.filters.selectType === 'resource' && (order.TypeItem === 'resource' || order.TypeItem === 'recycle' || order.TypeItem === 'detail')) {
        if (filterType.type === '') {
          return true
        }
        if (filterType.type === 'resource' && order.TypeItem === 'resource') {
          return order.IdItem === filterType.id || filterType.id === 0
        }
        if (filterType.type === 'recycle' && order.TypeItem === 'recycle') {
          return order.IdItem === filterType.id || filterType.id === 0
        }
        if (filterType.type === 'detail' && order.TypeItem === 'detail') {
          return order.IdItem === filterType.id || filterType.id === 0
        }
      }

      return false
    },
    getSubMenuName(type) {
      if (this.language === 'EN') return type;

      if (type === 'sell') {
        return this.texts['sub_button_2'][this.language]
      }

      if (type === 'buy') {
        return this.texts['sub_button_3'][this.language]
      }
    },
    sortOrders(table) {
      let orders = [];

      for (let i in this.orders) {
        let order = this.orders[i]
        if ((table === 'my' && this.orderVisible(order, true)) || (order.Type === table && !order.d && this.orderVisible(order))) {
          orders.push(order)
        }
      }

      let sortData = this.sortData[table]
      orders.sort(function (a, b) {
        if (sortData.sort === 'asc') {
          return a[sortData.field] - b[sortData.field]
        } else {
          return b[sortData.field] - a[sortData.field]
        }
      });

      this.preloader = false
      return orders
    },
    setSortTable(table, field) {
      let sort = 'asc'

      if (field === field && this.sortData[table].sort === 'asc') {
        sort = 'desc'
      }

      Vue.set(this.sortData, table, {
        field: field,
        sort: sort,
      });
    },
  },
  computed: {
    filters() {
      return this.$store.getters.getMarketFilter
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Market')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    globalPath() {
      return this.$store.getters.getGlobalPath
    },
    itemName() {
      if (this.filters.selectType === "blueprints") {
        return this.handbook[this.filters.item.item_type][this.language][this.filters.item.item_name].name
      }

      let filterType = this.filters[this.filters.selectType];
      try {
        return this.handbook[filterType.type][this.language][this.filters.item.name].name
      } catch (e) {
        return this.handbook[this.filters.selectType][this.language][this.filters.item.name].name
      }
    },
    market() {
      return this.$store.getters.getMarket
    },
    orders() {
      return this.$store.getters.getOrders
    },
    getSlotIcon() {
      let type = this.filters.selectType;

      if (this.filters.selectType === 'resource') {
        if (this.filters[this.filters.selectType].type === 'resource') type = 'resource';
        if (this.filters[this.filters.selectType].type === 'recycle') type = 'recycle';
        if (this.filters[this.filters.selectType].type === 'detail') type = 'detail';
      }

      return {type: type, item: this.filters.item}
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    noUseMarketService() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('no_use_market_service') && errors['no_use_market_service'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'no_use_market_service',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    marketSendItems() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('market_send_items') && errors['market_send_items'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'market_send_items',
            active: false,
          });
        }.bind(this), 2500)

        return errors['market_send_items'].data
      }

      return false
    },
    corporationBaseNames() {
      return this.$store.getters.getCorporationBaseNames
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    removeLastBody() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('remove_last_body') && errors['remove_last_body'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'remove_last_body',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    },
    itemFilters() {
      let items = [];

      for (let i of this.market.filterItems) {
        items.push({type: i.type, item: this.getItemByTypeAndID(i.type, i.id), price: i.price})
      }

      return items
    },
  },
  components: {
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
    AppSell: Sell,
    AppBuy: Buy,
    AppNewBuy: NewBuy,
    AppFilter: Filter,
    AppPreloader: Preloader,
  }
}
</script>

<style scoped>
#marketBox {
  position: absolute;
  top: 15px;
  left: 50%;
  border-radius: 5px;
  z-index: 11;
  padding-top: 15px;
  height: 400px;
  width: 860px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

#ordersBlock {
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  background: rgba(183, 195, 201, 0.29);
  border-radius: 2px;
  margin: 5px 2px 0 0;
  float: right;
  height: 390px;
  width: 640px;
}

#selectItemIcon {
  width: 45px;
  height: 45px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  background: rgba(63, 70, 74, 0.5);
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 2px;
  float: left;
  position: relative;
}

#selectItemName {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 4px black;
  min-width: 180px;
  margin: 3px 0 0 10px;
  float: left;
  text-align: left;
}

#selectItemName span {
  font-size: 10px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 13px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

#selectItemName .category {
  font-size: 8px;
  display: block;
  text-align: left;
  text-indent: 6px;
  margin-top: 4px;
  color: #fff200;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

#sellOrdersBlock, #BuyOrdersBlock, #MyOrdersBlock, .history_block {
  margin: 0 0 5px 2px;
  overflow-y: scroll;
  border-radius: 2px;
  box-shadow: inset 0 0 2px black;
  height: 112px;
  width: 630px;
  background: rgba(137, 150, 156, 0.3);
}

#MyOrdersBlock, .history_block {
  margin-top: 2px;
  height: 315px;
}

.ordersHead {
  width: 110px !important;
  border-radius: 5px 5px 0 0;
  margin-left: 10px;
  text-align: left;
  text-indent: 6px;
  background: rgba(255, 129, 0, 0.6);
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 18px;
  height: 17px !important;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  float: left;
  margin-top: 10px;
  pointer-events: auto !important;
}

.ordersHead:hover {
  background: rgb(221, 112, 52) !important;
}

.marketRow {
  border-radius: 0;
}

.marketRow:hover {
  background: rgba(40, 227, 255, 0.37);
}

.marketRow td:first-child, .marketRow td:last-child {
  border-radius: 0;
}

.marketRow td {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: white;
  /*font-weight: 900;*/
  text-shadow: 1px 1px 1px black;
}

#BuyDialogButtonWrapper input[type=button] {
  position: static;
  margin: 6px 4px 0;
  width: 100px;
}

#leftBar {
  width: 185px;
  display: inline-block;
}

.headMarketHeading, #balance {
  text-align: center;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.85);
  margin-left: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  min-width: 120px;
  max-width: 180px;
  border-radius: 4px;
  padding-left: 10px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.searchInput {
  border-radius: 3px;
  width: 180px;
  position: absolute;
  left: 5px;
  bottom: 3px;
}

#headMarket {
  border-radius: 4px;
  height: 20px;
  width: 180px;
  margin: 5px 5px 5px 2px;
  padding-top: 4px;
  position: relative;
}

#headMarket form {
  color: rgba(255, 255, 255, 0.65);
  margin-top: 5px;
  font-size: 12px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
  background: rgba(183, 195, 201, 0.29);
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  min-width: 120px;
  max-width: 190px;
  border-radius: 4px;
  margin-left: 5px;
}

#headMarket form input {
  box-shadow: none;
}

#headMarket form h4 {
  margin: 0;
  padding: 0;
}

#balance {
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 9px;
  box-shadow: inset 0 0 2px black;
  height: 22px;
  line-height: 24px;
  background: #89969c;
}

#balance span {
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
  color: rgba(128, 255, 130, 0.85);
}

.marketButton {
  width: 60px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.75);
  height: 14px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  font-size: 10px;
}

.marketButton:hover {
  background: rgba(255, 129, 0, 1);
}

.marketButton:active {
  transform: scale(0.98);
}

.marketTopMenu {
  height: 15px;
  width: calc(100%);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.marketTopMenu div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.marketTopMenu div:last-child {
  /*border: 0;*/
}

.marketTopMenu .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.marketTopMenu div:hover {
  color: #fff200 !important;
}

.activePin {
  border-bottom: 0 !important;
  box-shadow: none !important;
}

.ordersTable {
  width: 100%;
  margin: auto;
  border-spacing: 0;
}

.ordersTable tr:first-child {
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
}

.ordersTable tr:first-child th {
  background: #216a8e;
  color: rgba(255, 255, 255, 0.8);
  user-select: none;
  margin: 2px auto;
  position: sticky;
  top: 0;
  padding-top: 2px;
  z-index: 10;
  font-size: 12px;
  text-shadow: 1px 1px 1px black;
  line-height: 18px;
}

#marketSellTable th {
  background: rgba(255, 129, 0, 0.6);
}

.ordersTable .th_hover:hover {
  background: #1c8cc4 !important;
}

.ordersTable tr {
  text-align: center;
}

.ordersTable td {
  font-size: 11px;
}

#marketBuyTable td {
  width: 16%;
}

#marketSellTable td {
  width: 16%;
}

#MyOrdersBlock td {
  width: 11%;
}

.button.cancel.order {
  width: 60px;
  display: block;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.creditsTD:after {
  content: " cr.";
  color: rgba(128, 255, 130, 0.85);
}

.basePath {
  margin-left: -10px;
}

#selectItemIcon input {
  height: 15px;
  width: 15px;
  text-align: center;
  border: 2px solid rgb(173, 173, 173);
  border-radius: 50%;
  font-weight: 900;
  background: #138edc;
  box-shadow: 0 0 2px black;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.75;
  font-size: 10px;
  line-height: 11px;
  padding: 0;
}

#selectItemIcon input:hover {
  opacity: 1;
}

#selectItemIcon input:active {
  transform: scale(0.96);
}

.marketRow.my {
  background: rgb(28, 109, 179);
  pointer-events: none;
}

.order_icon {
  position: relative;
  height: 25px;
  width: 25px;
  padding: 0;
  margin: 0;
}

.detail_button {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 100ms;
}

.detail_button:hover {
  box-shadow: 0 0 4px 2px orange;
}

.detail_button:active {
  transform: scale(0.8);
}
</style>

<style>
#subMenu input[type=number] {
  float: right;
  margin-top: 10px;
  margin-right: 5px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  width: 27px;
  height: 14px;
  text-indent: 1px;
  text-align: right;
}

#subMenu input[type=button] {
  width: 40%;
  margin-top: 3px;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin-left: 10px;
}

#subMenu input[type=button]:hover {
  background: rgba(255, 129, 0, 1);
}

#subMenu input[type=button]:active {
  transform: scale(0.98);
}

#subMenu .marketDialogItemIcon {
  position: relative;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  border: 1px solid #959595;
  background-color: #4c4c4c;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: top;
  margin: 7px 5px 0;
  box-shadow: 0 0 5px rgb(0 0 0);
  float: left;
}

#subMenu span {
  font-size: 10px;
  color: bisque;
  text-shadow: 1px 1px 1px black;
}

.order_sub_menu {
  width: fit-content;
  position: absolute;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  border-radius: 5px;
  min-height: 20px;
  min-width: 80px;
  padding: 3px;
  text-shadow: 1px 1px 2px black, 0 0 1em #696969;
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
}

.wrapper_sub_menu {
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  padding: 3px;
}

.wrapper_sub_menu_action input {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.wrapper_sub_menu_action input:active {
  transform: scale(0.98);
}

.wrapper_sub_menu_action input:hover {
  background: rgba(255, 129, 0, 1);
}

.path {
  background: rgba(227, 152, 22, 0.3);
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.preloader {
  margin-top: -6px;
  float: left;
  margin-left: calc(50% - 21px);
}

.filterTab, .decor {
  float: left;
  height: 20px;
  width: 20px;
  margin-left: 6px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px black;
  background-color: rgba(255, 129, 0, 0.6);
  background-image: url("../../assets/icons/filter_1.png");
  background-size: contain;
}

.filterTab.two {
  background-image: url("../../assets/icons/filter_2.png");
}

.decor {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px black;
}

.filterTabDisable {
  background-color: grey
}

.filterTab:active {
  transform: scale(0.98);
}

.filterTab:hover {
  background-color: rgba(255, 129, 0, 1)
}

.searchByNameBlock {
  border-radius: 4px;
  height: 0;
  width: 180px;
  margin: 0 5px 5px 2px;
  padding-top: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0 0 5px black;
  background: rgba(93, 93, 93, 0.3);
}

.searchByNameBlock input[type=text] {
  width: calc(100% - 15px);
  margin-left: 2px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
}

.item_row {
  width: 100%;
  height: 53px;
  margin-top: 2px;
  background: rgba(0, 0, 0, 0.2);
  padding-top: 5px;
  border-bottom: 1px solid;
  opacity: 0.8;

}

.item_row:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}

.item_row_icon {
  position: relative;
  height: 35px;
  width: 35px;
  float: left;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  box-shadow: inset 0 0 2px;
}

.item_row_heads {
  text-align: left;
  width: 118px;
  float: left;
}

.item_row h4 {
  font-size: 11px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  height: 13px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item_row h6 {
  font-size: 12px;
  display: block;
  text-align: left;
  text-indent: 6px;
  margin-top: 4px;
  margin-bottom: 0;
  color: #fff200;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item_row .item_row_price {
  color: white;
  float: left;
  width: 100%;
  margin-top: -2px;
  opacity: 0.7;
  font-size: 11px;
}

.item_row_select {
  background: rgba(255, 129, 0, 0.6) !important;
}

.ordersHead.ordersHeadBuy {
  background: #216a8e;
}

.table_icon, .sortArrowIcon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin: 1px;
  float: left;
  background-image: url("../../assets/icons/seller.png");
  background-size: contain;
}

.table_icon.buy {
  background-image: url("../../assets/icons/buyer.png");
}

.sortArrowIcon {
  background-image: url("../../assets/icons/sort_1.png");
  float: right;
}

.sortArrowIcon.asc {
  background-image: url("../../assets/icons/sort_2.png");
}

.sortArrowIcon.desc {
  background-image: url("../../assets/icons/sort_2.png");
  transform: rotate(180deg);
}
</style>
