<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Локальная политика</h2>
      <p>Локальная политика - это правила которые работают в секторе и базе принадлежащей вашему кластеру.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('local_policy_1', 'png')+ ')',
            width: '433px',
            height: '409px',
            marginLeft: 'calc(50% - 216px)',
            }"/>

      <h2 class="head">Управление доступом.</h2>
      <p>Вы можете определить кто может посещать ваш сектор и вашу базу, для доступа используются списки
        "союзников"/"соперников" которые указываются в
        <router-link to="corporation_global_policy">глобальных политиках</router-link>
        кластера
      </p>

      <p>Доступные правила:</p>
      <ul>
        <li>все</li>
        <li>кластер + союзники</li>
        <li>кластер + соперники</li>
        <li>только кластер</li>
        <li>все кроме союзников</li>
        <li>все кроме соперников</li>
      </ul>

      <h2 class="head">Управление турелями.</h2>
      <p>Правила определяет кого будут атаковать турели в секторе.</p>

      <p>Доступные правила:</p>
      <ul>
        <li>атакуют всех (включая кластер)</li>
        <li>атакуют всех кроме кластера</li>
        <li>атакуют всех кроме кластера и союзников</li>
        <li>атакуют всех кроме кластера и соперников</li>
        <li>атакуют всех кроме кластера, соперников и союзников (то есть нейтралов и врагов)</li>
        <li>атакуют только врагов (пк, апд, враги на войне) и агрессивных (те кто атакуют корпоративную собственность и
          членов)
        </li>
        <li>не работают</li>
      </ul>

      <h2 class="head">Управление ремонтниками.</h2>
      <p>Правила определяет чинить ремонтные станции.</p>

      <p>Доступные правила:</p>
      <ul>
        <li>кластер</li>
        <li>кластер и союзников</li>
        <li>кластер и соперников</li>
        <li>всех кроме врагов (пк, апд, враги на войне)</li>
        <li>атакуют всех кроме кластера, соперников и союзников (то есть нейтралов и врагов)</li>
        <li>Не работают</li>
      </ul>

      <h2 class="head">Налог на переработку и производство</h2>
      <p>Изымает налог с
        <router-link to="processing">переработки</router-link>
        ресурсов и производства
        <router-link to="craft_items">предметов</router-link>
        /
        <router-link to="parts_production">деталей</router-link>
        со всех игроков которые будет перерабатывать их в
        этом секторе.
      </p>
      <p>Налог будет отправляться на склад указанный в "Склад для сбора налога" или на склад лидера кластера на этой
        базе если в указанном складе кончилось место.</p>

      <h2 class="head">Налог на рынок</h2>
      <p>Процентная ставка с продаж на
        <router-link to="market">рынке</router-link>
        этой базе.
      </p>
      <p>Налог будет отправляться на счет указанный в "Счет для сбора налога".</p>

      <h2 class="head">Окно уязвимости</h2>
      <p>Указывает время в которое возможно
        <router-link to="corporation_siege">атаковать базу</router-link>
        .
      </p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Local Policy</h2>
      <p>Local policy - these are the rules that work in the sector and base belonging to your cluster.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('local_policy_1', 'png')+ ')',
            width: '433px',
            height: '409px',
            marginLeft: 'calc(50% - 216px)',
            }"/>

      <h2 class="head">Access Management</h2>
      <p>You can determine who can visit your sector and your base, access uses the lists of "allies"/"rivals" which are
        specified in the <a href="corporation_global_policy">global policies</a> of the cluster.</p>

      <p>Available rules:</p>
      <ul>
        <li>all</li>
        <li>cluster + allies</li>
        <li>cluster + rivals</li>
        <li>cluster only</li>
        <li>all except allies</li>
        <li>all except rivals</li>
      </ul>

      <h2 class="head">Turret Management</h2>
      <p>The rules determine who will be attacked by turrets in the sector.</p>

      <p>Available rules:</p>
      <ul>
        <li>attack everyone (including the cluster)</li>
        <li>attack everyone except the cluster</li>
        <li>attack everyone except the cluster and allies</li>
        <li>attack everyone except the cluster and rivals</li>
        <li>attack everyone except the cluster, rivals, and allies (i.e., neutrals and enemies)</li>
        <li>attack only enemies (PK, APD, enemies at war) and aggressors (those who attack corporate property and
          members)
        </li>
        <li>do not work</li>
      </ul>

      <h2 class="head">Repair Station Management</h2>
      <p>The rules determine who will be repaired by repair stations.</p>

      <p>Available rules:</p>
      <ul>
        <li>cluster</li>
        <li>cluster and allies</li>
        <li>cluster and rivals</li>
        <li>everyone except enemies (PK, APD, enemies at war)</li>
        <li>attack everyone except the cluster, rivals, and allies (i.e., neutrals and enemies)</li>
        <li>Do not work</li>
      </ul>

      <h2 class="head">Tax on Processing and Production</h2>
      <p>Levies a tax on the <a href="processing">processing</a> of resources and the production of <a
        href="craft_items">items</a> / <a href="parts_production">parts</a> from all players who will process them in
        this sector.</p>
      <p>The tax will be sent to the warehouse specified in "Warehouse for tax collection" or to the cluster leader's
        warehouse on this base if there is no space left in the specified warehouse.</p>

      <h2 class="head">Market Tax</h2>
      <p>The percentage rate of sales at the <a href="market">market</a> of this base.</p>
      <p>The tax will be sent to the account specified in "Account for tax collection".</p>

      <h2 class="head">Vulnerability Window</h2>
      <p>Indicates the time when it is possible to <a href="corporation_siege">attack the base</a>.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "CorporationLocalPolicy",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
