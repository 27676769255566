var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: корпус")]),_vm._v(" "),_c('p',[_vm._v("Что бы установить корпус, зажмите левую кнопку мыши на иконке корпуса в инвентаре и перенесите ее в центр\n      ангара.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_body_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Hull")]),_vm._v(" "),_c('p',[_vm._v("To install the hull, press and hold the left mouse button on the hull icon in the inventory and drag it to the\n      center of the hangar.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_body_1', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }