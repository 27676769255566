import {initFog, updateFogSize} from "../interface/fog_of_war";
import {gameStore} from "../store";
import {CreateAnimate, CreateObject} from "./objects";
import {GetGlobalPos} from "./gep_global_pos";
import store from "../../store/store";
import {CreateTextBaseLabels, CreateTextLabels} from "./text_labels";
import {base64ToArrayBuffer, intFromBytes} from "../../utils";
import {initMinimapLabels, updateMiniMap} from "../interface/mini_map";
import {RemoveMap} from "./remove_old_map";
import {SetDayCycle} from "../day_cycle/day_cycle";
import {setCursor} from "../interface/equip_bar";
import {Scene} from "../create";
import {GetLvl} from "./height_offset";

let MapSize = 0;
let border = 0; // 600
let chunks = 4; // разделяем карту на чанки что бы текстуры были меньше 4096

function CreateAllMaps(scene) {
  console.log("Create Map")
  RemoveMap.ok = false

  for (let i in gameStore.maps) {
    if (gameStore.maps[i].x === 0 && gameStore.maps[i].y === 0) {
      gameStore.map = gameStore.maps[i].map
    }
  }

  // наращивание размера карты не влияет на производительность
  if (!gameStore.mapEditor) {
    scene.cameras.main.setBounds(border * -1, border * -1, gameStore.map.XSize + (border * 2), gameStore.map.XSize + (border * 2));

    if (!gameStore.FogOfWar.back) {
      gameStore.FogOfWar = initFog(scene);
      scene.cameras.main.setZoom(1.25);
    }

    updateFogSize();
    updateMiniMap();
  } else {
    scene.cameras.main.setBounds(-4000, -4000, gameStore.map.XSize + 8000, gameStore.map.XSize + 8000);
  }

  if (!gameStore.mouseInGameChecker.updater) {
    gameStore.mouseInGameChecker.updater = true;
    scene.input.on('pointermove', function (pointer) {
      gameStore.HoldAttackMouse = false;
    });

    scene.input.on('gameout', function (pointer) {
      gameStore.HoldAttackMouse = true;
      document.body.classList.remove("cursor_weapon");
      document.body.classList.remove("cursor_scanner");
      document.body.classList.remove("cursor_digger");
      document.body.classList.remove("cursor_box");
      document.body.classList.remove("cursor_hand");
      document.body.classList.remove("cursor_mining");
    });

    scene.input.on('gameover', function (pointer) {
      gameStore.HoldAttackMouse = false;
      setCursor()
    });
  }

  for (let i in gameStore.maps) {
    if (gameStore.maps.hasOwnProperty(i) && gameStore.maps[i].map) {

      gameStore.mapsState[i] = {
        bmdTerrain: {},
        staticObjects: [],
      };

      if (gameStore.maps[i].x === 0 && gameStore.maps[i].y === 0) {
        gameStore.map = gameStore.maps[i].map

        store.commit({
          type: 'setSectorName',
          name: gameStore.map.Name,
          id: gameStore.map.id,
        });
      }

      CreateMap(scene, gameStore.maps[i].map)
      CreateTextLabels(gameStore.maps[i].map);
    }
  }

  initMinimapLabels()
  CreateTextBaseLabels()
  SetDayCycle(true)
}

function CreateMap(scene, map) {
  CreateFlore(map, scene);
  CreateObjects(map, scene);

  store.commit({
    type: 'setVisibleLoader',
    visible: true,
    text: {
      'RU': 'Создаем искусственные проблемы...',
      'EN': 'We are creating artificial problems...',
    }
  });
}

function CreateFlore(map, scene) {
  let pos = GetGlobalPos(0, 0, map.id);
  //bitmapData для отрисовки статичного нижнего слоя
  let bmdTerrain = scene.add.renderTexture(pos.x, pos.y, gameStore.map.XSize, gameStore.map.YSize);
  bmdTerrain.setDepth(-3);
  bmdTerrain.setOrigin(0);
  bmdTerrain.setInteractive();

  let bmdObject = scene.add.renderTexture(pos.x, pos.y, gameStore.map.XSize, gameStore.map.YSize);
  bmdObject.setDepth(300);
  bmdObject.setOrigin(0);

  let water = scene.add.renderTexture(pos.x, pos.y, gameStore.map.XSize, gameStore.map.YSize);
  water.setDepth(300);
  water.setOrigin(0);

  gameStore.mapsState[map.id].bmdTerrain = {
    bmd: bmdTerrain,
    bmdObject: bmdObject,
    bmdWater: water,
    water: [],
  };

  // сортировка по приоритету отрисовки текстур
  let flores = [];

  for (let x in map.flore) {
    for (let y in map.flore[x]) {
      flores.push(map.flore[x][y]);
    }
  }

  flores.sort(function (a, b) {
    return a.texture_priority - b.texture_priority;
  });

  let brush = scene.textures.get('brush').getSourceImage();
  let brush128 = scene.textures.get('brush_128').getSourceImage();

  for (let i in flores) {

    if (!flores.hasOwnProperty(i)) continue;

    let flore = flores[i];

    if (flore.texture_over_flore !== '') {

      let textureKey = "terrain_" + flore.texture_over_flore + "_brush";

      if (!gameStore.cashTextures.hasOwnProperty(textureKey)) {
        gameStore.cashTextures[textureKey] = true;
        let texture = scene.textures.get(flore.texture_over_flore).getSourceImage();

        if (flore.texture_over_flore === "water_1") {
          let bmd = scene.textures.createCanvas(textureKey, 128, 128);
          bmd.draw(0, 0, brush128);
          bmd.context.globalCompositeOperation = 'source-in';
          bmd.draw(0, 0, texture);
        } else {
          let bmd = scene.textures.createCanvas(textureKey, 1024, 1024);
          bmd.draw(0, 0, brush);
          bmd.context.globalCompositeOperation = 'source-in';
          bmd.draw(0, 0, texture);
        }
      }

      store.commit({
        type: 'setVisibleLoader',
        visible: true,
        text: {
          'RU': 'Рисуем траву: ' + i + "/" + flores.length,
          'EN': 'Drawing grass: ' + i + "/" + flores.length,
        }
      });

      if (flore.texture_over_flore === "water_1") {
        // TODO обьеденить в 1 текстуру, сделать из нее спрайт и сделать, накинуть на нее шейдер
        // let sptire = Scene.make.image({
        //   x: flore.x,
        //   y: flore.y,
        //   key: textureKey,
        //   add: true
        // }).setDepth(-1);

        // const fx = sptire.preFX.addDisplacement('displacement_1', -0.05, -0.05);
        // Scene.tweens.add({
        //   targets: fx,
        //   x: 0.05,
        //   y: 0.05,
        //   yoyo: true,
        //   loop: -1,
        //   duration: 2000,
        //   ease: 'sine.inout'
        // });

        gameStore.mapsState[map.id].bmdTerrain.bmdWater.drawFrame(textureKey, undefined, flore.x - 64, flore.y - 64);
      } else if (flore.texture_over_flore.includes('decal_')) {
        // let pos = GetGlobalPos(flore.x, flore.y, gameStore.map.id);
        let sprite = scene.make.image({
          x: flore.x,
          y: flore.y,
          key: flore.texture_over_flore,
          add: true
        });
        sprite.setOrigin(0.5)
        sprite.setDepth(999)
        sprite.setAngle(flore.r)
        gameStore.mapsState[map.id].bmdTerrain.bmd.draw(sprite);
        sprite.destroy();
      } else {

        let floreSprite = Scene.make.image({
          x: flore.x,
          y: flore.y,
          key: textureKey,
          add: true
        }).setDepth(-1);

        if (!textureKey.includes('arctic')) {
          let zoneLvl = GetLvl(flore.x, flore.y, map.id);
          if (zoneLvl === 100) {
            floreSprite.setTint(0xcccccc)
          }

          if (zoneLvl === 0) {
            floreSprite.setTint(0xaaaaaa)
          }
        }

        gameStore.mapsState[map.id].bmdTerrain.bmd.draw(floreSprite);
        floreSprite.destroy();
        // gameStore.mapsState[map.id].bmdTerrain.bmd.drawFrame(textureKey, undefined, flore.x - 512, flore.y - 512);
      }
    }
  }
}

function CreateObjects(map, scene) {
  for (let i in map.static_objects_json) {
    let bytes = base64ToArrayBuffer(map.static_objects_json[i])
    gameStore.mapsState[map.id].staticObjects.push(ParseObject(bytes));
  }

  // сортировка по приоритету отрисовки обьектов
  gameStore.mapsState[map.id].staticObjects.sort(function (a, b) {
    return a.priority - b.priority;
  });

  for (let i in gameStore.mapsState[map.id].staticObjects) {

    if (!gameStore.mapsState[map.id].staticObjects.hasOwnProperty(i)) {
      continue
    }

    let obj = gameStore.mapsState[map.id].staticObjects[i];

    store.commit({
      type: 'setVisibleLoader',
      visible: true,
      text: {
        'RU': 'Рисуем деревья: ' + i + "/" + gameStore.mapsState[map.id].staticObjects.length,
        'EN': 'Drawing trees: ' + i + "/" + gameStore.mapsState[map.id].staticObjects.length,
      }
    });

    if (obj.animate) {
      CreateAnimate(obj, obj.x, obj.y, scene);
    } else {
      CreateObject(obj, obj.x, obj.y, false, scene);
    }
  }
}

function ParseObject(data) {

  let typeLength = intFromBytes(data.slice(76, 77))
  let type = String.fromCharCode.apply(String, data.subarray(77, 77 + typeLength))
  let stopByte = 77 + typeLength;

  let textureLength = intFromBytes(data.slice(stopByte, stopByte + 1))
  let texture = String.fromCharCode.apply(String, data.subarray(stopByte + 1, stopByte + 1 + textureLength))
  stopByte = stopByte + 1 + textureLength;

  let weaponCounts = intFromBytes(data.slice(stopByte, stopByte + 1))
  stopByte = stopByte + 1;

  let weapons = [];

  for (let i = 0; i < weaponCounts; i++) {
    let slotData = {
      gun_rotate: intFromBytes(data.slice(stopByte, stopByte + 4)),
      real_x_attach: intFromBytes(data.slice(stopByte + 4, stopByte + 8)),
      real_y_attach: intFromBytes(data.slice(stopByte + 8, stopByte + 12)),
      number: intFromBytes(data.slice(stopByte + 12, stopByte + 13)),
      x_anchor: intFromBytes(data.slice(stopByte + 13, stopByte + 14)) / 100,
      y_anchor: intFromBytes(data.slice(stopByte + 14, stopByte + 15)) / 100,
      weapon_texture: "",
    }

    let textureLength = intFromBytes(data.slice(stopByte + 15, stopByte + 16));
    if (textureLength > 0) {
      slotData.weapon_texture = String.fromCharCode.apply(String, data.subarray(stopByte + 16, stopByte + 16 + textureLength))
    }

    weapons.push(slotData);
    stopByte = stopByte + 16 + textureLength;
  }

  let geoData = [];
  let geoDataSize = intFromBytes(data.slice(stopByte, stopByte + 4))
  stopByte = stopByte + 4;

  let startByte = stopByte
  for (; stopByte < startByte + geoDataSize;) {
    geoData.push({
      x: intFromBytes(data.slice(stopByte, stopByte + 4)),
      y: intFromBytes(data.slice(stopByte + 4, stopByte + 8)),
      radius: intFromBytes(data.slice(stopByte + 8, stopByte + 12)),
    })
    stopByte += 12
    gameStore.updateGeoData = true;
  }

  let equipDataSize = intFromBytes(data.slice(stopByte, stopByte + 4))
  stopByte = stopByte + 4;

  startByte = stopByte
  let equips = {}
  for (; stopByte < startByte + equipDataSize;) {
    let slotData = {
      number_slot: intFromBytes(data.slice(stopByte + 0, stopByte + 1)),
      rotate: intFromBytes(data.slice(stopByte + 1, stopByte + 5)),
      real_x_attach: intFromBytes(data.slice(stopByte + 5, stopByte + 9)),
      real_y_attach: intFromBytes(data.slice(stopByte + 9, stopByte + 13)),
      x_anchor: intFromBytes(data.slice(stopByte + 13, stopByte + 14)) / 100,
      y_anchor: intFromBytes(data.slice(stopByte + 14, stopByte + 15)) / 100,
      equip_texture: "",
    }

    let textureLength = intFromBytes(data.slice(stopByte + 15, stopByte + 16));
    if (textureLength > 0) {
      slotData.equip_texture = String.fromCharCode.apply(String, data.subarray(stopByte + 16, stopByte + 16 + textureLength))
    }

    stopByte = stopByte + 16 + textureLength
    equips[slotData.number_slot] = slotData
  }

  let template = {
    id: intFromBytes(data.slice(0, 4)),
    x: intFromBytes(data.slice(4, 8)),
    y: intFromBytes(data.slice(8, 12)),
    rotate: intFromBytes(data.slice(12, 16)),
    height: intFromBytes(data.slice(16, 20)),

    hp: intFromBytes(data.slice(20, 24)),
    max_hp: intFromBytes(data.slice(24, 28)),
    current_energy: intFromBytes(data.slice(28, 32)),
    max_energy: intFromBytes(data.slice(32, 36)),
    range_view: intFromBytes(data.slice(36, 40)),

    x_shadow_offset: intFromBytes(data.slice(40, 44)),
    y_shadow_offset: intFromBytes(data.slice(44, 48)),
    shadow_intensity: intFromBytes(data.slice(48, 52)),
    owner_id: intFromBytes(data.slice(52, 56)),
    priority: intFromBytes(data.slice(56, 60)),

    //team_id: intFromBytes(data.slice(60, 61)),
    work: intFromBytes(data.slice(60, 61)) === 1,
    build: intFromBytes(data.slice(61, 62)) === 1,
    scale: intFromBytes(data.slice(62, 63)),
    shadow: intFromBytes(data.slice(63, 64)) === 1,
    animate: intFromBytes(data.slice(64, 65)) === 1,
    animation_speed: intFromBytes(data.slice(65, 66)),
    animate_loop: intFromBytes(data.slice(66, 67)) === 1,
    complete: intFromBytes(data.slice(67, 68)),
    static: intFromBytes(data.slice(68, 69)) === 1,
    warrior: intFromBytes(data.slice(69, 70)) === 1,
    fraction: intFromBytes(data.slice(70, 71)),
    interactive: intFromBytes(data.slice(71, 72)),
    corporation_id: intFromBytes(data.slice(72, 76)),

    type: type,
    texture: texture,
    weapons: weapons,
    geo_data: geoData,
    equips: equips,
  }

  return template
}

export {CreateMap, CreateAllMaps, MapSize, ParseObject}
