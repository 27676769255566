import {showMessage} from "../../../game/text/message";

export default function chatRead(msg, store) {
  if (msg.event === 'OpenChat') {
    let currentChatID = store.getters.getChatState.currentChatID;

    if (msg.group && msg.force_select_group) {
      if (msg.group.local) {
        currentChatID = msg.group.id
      } else {
        store.dispatch("sendSocketData", JSON.stringify({
          service: "chat",
          data: {
            event: "ChangeGroup",
            group_id: Number(msg.group.id),
          }
        }));
      }
    }

    if (!msg.group) {
      store.dispatch("sendSocketData", JSON.stringify({
        service: "chat",
        data: {
          event: "ChangeGroup",
          group_id: Number(0),
        }
      }));
    }

    store.commit({
      type: 'setChatState',
      groups: msg.groups,
      currentChatID: currentChatID,
    });

    if (msg.group_id_notify !== 0) {
      store.commit({
        type: 'setNewMessageChatStateGroup',
        id: msg.group_id_notify,
        notify: true,
      });
    }

    parseGroupHistory(msg.group, msg.group_history, store)
  }

  if (msg.event === "UpdateUsers" || msg.event === 'ChangeGroup') {

    store.commit({
      type: 'updateChatUsers',
      group: msg.group,
      users: msg.users,
    });

    if (msg.event === 'ChangeGroup') {
      store.commit({
        type: 'setCurrentChatIDGroup',
        id: msg.group.id,
      });
      parseGroupHistory(msg.group, msg.group_history, store)
    }
  }

  if (msg.event === 'NewChatMessage') {

    if (msg.group_id === 0) showMessage(msg.message);

    store.commit({
      type: 'newChatMessage',
      id: msg.group_id,
      msg: msg.message,
      npc_animate: msg.message.bot && !msg.message.off,
      notify: true,
    });
  }

  if (msg.event === 'GetAllGroups') {
    store.commit({
      type: 'setAllGroupsInChat',
      groups: msg.groups,
      countPlayers: msg.groups_online,
    });
  }
}

function parseGroupHistory(group, history, store) {
  if (group) {
    store.commit({
      type: 'clearChatGroupHistory',
      id: group.id,
    });

    if (history !== "") {
      for (let msg of JSON.parse(history)) {
        if (msg) {
          store.commit({
            type: 'newChatMessage',
            id: group.id,
            msg: msg,
            notify: false,
          });
        }
      }
    }
  }
}
