var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Стрельба")]),_vm._v(" "),_c('p',[_vm._v("Оружие корпуса - основное средство обороны и атаки. Игрок, используя мышь, наводит прицел (красный круг на\n      экране), на необходимую точку/цель, после чего открывает огонь - выпуская туда снаряд. ")]),_vm._v(" "),_c('p',[_vm._v("Стрельба в Veliri строится на умениях игрока, типе вооружения и дальности до цели, по причине чего, угол\n      отклонения/рассеивания снарядов достаточно высок. Об этом же свидетельствует и сам прицел - красный круг - чем\n      он\n      шире, тем больше шанс отклонения выпущенных снарядов. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_1', 'gif')+ ')',
          width: '375px',
          height: '359px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Чтобы выстрелить из оружия, вам нужно нажать на левую кнопку мышки. Вы увидите, как ваш снаряд летит по воздуху\n      и\n      взрывается по достижении цели или препятствия. Вы также увидите, как отлетают цифры, которые показывают, сколько\n      урона вы нанесли и кому. Если ваш снаряд имеет урон по площади, то урон получат все, кто находится в зоне\n      взрыва.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_2', 'gif')+ ')',
          width: '375px',
          height: '359px',
          marginLeft: 'calc(50% - 187px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Стоит помнить, что большинство объектов в игре имеют высоты, а сама карта мира - рельеф. Это означает, что при\n      ведении огня игрок должен учитывать не только дистанцию, но и особенности окружения. ")]),_vm._v(" "),_c('p',[_vm._v("Особую роль в данном случае сыграют баллистические снаряды, которые при выстреле поднимаются вверх и\n      впоследствии\n      падают вниз под действием гравитации. Таким образом, вы можете поразить цель, которая находится за препятствием\n      или напротив - не нанести ей урона из-за особенностей местности.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_3', 'gif')+ ')',
          width: '471px',
          height: '155px',
          marginLeft: 'calc(50% - 235px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Shooting")]),_vm._v(" "),_c('p',[_vm._v("The chassis weapon is the primary means of defense and attack. The player, using the mouse, aims the reticle\n      (red circle on the screen) at the necessary point/target, and then opens fire by shooting a projectile\n      there.")]),_vm._v(" "),_c('p',[_vm._v("Shooting in Veliri is based on the player's skills, the type of weapon, and the distance to the target, which\n      is why the angle of deviation/dispersion of the projectiles is quite high. This is also evidenced by the reticle\n      itself - the red circle - the wider it is, the greater the chance of deviation of the fired projectiles.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_1', 'gif')+ ')',
      width: '375px',
      height: '359px',
      marginLeft: 'calc(50% - 187px)',
      })}),_vm._v(" "),_c('p',[_vm._v("To fire the weapon, you need to press the left mouse button. You will see how your projectile flies through the\n      air and explodes upon reaching the target or an obstacle. You will also see numbers flying off, showing how much\n      damage you have dealt and to whom. If your projectile has area damage, then everyone in the blast zone will take\n      damage.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_2', 'gif')+ ')',
      width: '375px',
      height: '359px',
      marginLeft: 'calc(50% - 187px)',
      })}),_vm._v(" "),_c('p',[_vm._v("It is worth remembering that most objects in the game have heights, and the world map itself has relief. This\n      means that when firing, the player must take into account not only the distance but also the features of the\n      environment.")]),_vm._v(" "),_c('p',[_vm._v("Ballistic projectiles, which rise up when fired and subsequently fall down under the action of gravity, will\n      play a special role in this case. Thus, you can hit a target that is behind an obstacle, or conversely, not\n      inflict damage on it due to the terrain features.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('shooting_3', 'gif')+ ')',
      width: '471px',
      height: '155px',
      marginLeft: 'calc(50% - 235px)',
      })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }