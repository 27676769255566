<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Штрафы</h2>

      <p>Если вы используете снаряжение к которому нет допуска по скилам то вы получаете штрафы на его
        использование:</p>

      <ul>
        <li>Корпуса - корпус едет медленнее, скорость не постоянная, повороты корпуса происходят рывками.</li>
        <li>Оружие - станонвится очень не точным, поворот оружия медленнее и происходит рывками.</li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_2', 'png')+ ')',
            width: '335px',
            height: '335px',
            marginLeft: 'calc(50% - 167px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Penalties</h2>

      <p>If you use equipment for which you do not have the necessary skills, you will receive penalties for its
        use:</p>

      <ul>
        <li>Hulls - the hull moves slower, the speed is not constant, and the hull's turns occur in jerks.</li>
        <li>Weapons - become very inaccurate, the weapon's rotation is slower and occurs in jerks.</li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('skills_2', 'png')+ ')',
            width: '335px',
            height: '335px',
            marginLeft: 'calc(50% - 167px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Fine",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
