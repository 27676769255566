<template>
  <div class="help_wrapper">

    <div class="closeWindowButton" @click="closeWindow"/>

    <div style="float: left; width: 310px; height: 50%; margin-right: 10px">
      <h3>{{ texts['text_1'][language] }}</h3>
      <div class="key_board"></div>
    </div>

    <div style="float: right; width: 280px; height: 50%;">
      <h3>{{ texts['text_2'][language] }}</h3>
      <div class="mouse"></div>
    </div>

    <div style="float: left; width: 100%; height: 50%;">
      <h3 style="margin-top: 40px;">{{ texts['text_3'][language] }}</h3>
      <div class="menu"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpControl",
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    closeWindow() {
      this.playSound('window_close.mp3', 0.3)
      this.$store.commit({
        type: 'toggleWindow',
        id: 'help-control',
        component: '',
        forceClose: true,
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('HelpControl')
    },
  }
}
</script>

<style scoped>
.help_wrapper {
  position: absolute;
  top: 20%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
  height: 375px;
  width: 600px;
  left: calc(50% - 300px);
  z-index: 110;
  border: 1px solid rgba(0, 0, 0, 0.75);
  pointer-events: auto;
}

.key_board, .mouse {
  background-image: url('../../assets/icons/control.png');
  background-size: contain;
  background-position: center;
  height: 170px;
  width: 369px;
  margin-left: 10px;
  margin-top: 20px;
}

.mouse {
  background-image: url('../../assets/icons/control_2.png');
  height: 170px;
  width: 145px;
  margin-left: 72px;
}

.menu {
  background-image: url('../../assets/icons/control_5.png');
  height: 120px;
  width: 574px;
  background-size: contain;
  margin-top: 0;
  margin-left: calc(50% - 286px);
}

h3 {
  color: rgb(237 157 12);
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 1px 1px black;
}

.closeWindowButton {
  position: absolute;
  right: 4px;
  top: 2px;
  border: 1px solid #7a858c;
  width: 12px;
  height: 11px;
  border-radius: 2px;
  background: #fcbb00;
  opacity: 0.7;
  float: right;
  transition: 200ms;
}

.closeWindowButton:hover {
  opacity: 1;
}

.closeWindowButton:active {
  transform: scale(0.80);
}

.closeWindowButton:before, .closeWindowButton:after {
  position: absolute;
  left: 5px;
  content: ' ';
  height: 11px;
  width: 2px;
  background-color: #333;
}

.closeWindowButton:before {
  transform: rotate(45deg);
}

.closeWindowButton:after {
  transform: rotate(-45deg);
}
</style>
