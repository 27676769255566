import {gameObjectCreate} from "../map/objects";
import {Scene} from "../create";
import {gameStore} from "../store";
import {intFromBytes} from "../../utils";
import {addIgnoreObject, setPositionObject} from "../interface/mini_map";
import {CreateReservoirStatus, RemoveReservoirStatus} from "../interface/reservoir_count";
import {SetDayCycleReservoirs} from "../day_cycle/day_cycle";
import {getBar} from "../interface/status_layer";

function parseReservoir(data) {

  let reservoir = {
    id: intFromBytes(data.slice(0, 4)),
    x: intFromBytes(data.slice(4, 8)),
    y: intFromBytes(data.slice(8, 12)),
    rotate: intFromBytes(data.slice(12, 16)),
    scale: intFromBytes(data.slice(16, 20)),
    shadow: intFromBytes(data.slice(20, 21)) === 1,
    map_id: intFromBytes(data.slice(21, 25)),
    height: intFromBytes(data.slice(25, 29)),
    count: intFromBytes(data.slice(29, 33)),
    complexity: intFromBytes(data.slice(33, 37)),
    crack: intFromBytes(data.slice(37, 38)) === 1,
    crackX: intFromBytes(data.slice(38, 39)) - 128,
    crackY: intFromBytes(data.slice(39, 40)) - 128,
  }

  let textureLength = intFromBytes(data.slice(40, 41))
  reservoir.texture = String.fromCharCode.apply(String, data.subarray(41, 41 + textureLength))

  let nameLength = intFromBytes(data.slice(41 + textureLength, 42 + textureLength))
  reservoir.name = String.fromCharCode.apply(String, data.subarray(42 + textureLength, 42 + textureLength + nameLength))

  return reservoir
}

function CreateReservoir(data) {

  let reservoir = parseReservoir(data);

  reservoir.sprite = gameObjectCreate(reservoir.x, reservoir.y, reservoir.texture, reservoir.scale * 4,
    reservoir.shadow, reservoir.rotate, Scene.shadowXOffset / 4, Scene.shadowYOffset / 4,
    40, true, 'resource', Scene, reservoir.map_id,
    {static: true, height: reservoir.height, build: false});


  addIgnoreObject(reservoir.sprite);
  reservoir.height = reservoir.height + 1
  reservoir.sprite.setDepth(reservoir.height);
  if (reservoir.sprite.shadow) {
    reservoir.sprite.setDepth(reservoir.height - 1);
    addIgnoreObject(reservoir.sprite.shadow);
  }

  gameStore.reservoirs[reservoir.id] = reservoir;
  SetDayCycleReservoirs(gameStore.reservoirs[reservoir.id])
  setPositionObject("resource", reservoir.sprite, 150, 150, 0, 0)

  reservoir.sprite.setInteractive();

  let postFxPlugin = Scene.plugins.get('rexoutlinepipelineplugin');
  reservoir.sprite.on('pointerover', function () {
    if (gameStore.equipPanel.selectApplicable === 'ore' && !reservoir.texture.includes('oil'))
      document.body.classList.add("cursor_mining");

    postFxPlugin.add(reservoir.sprite, {
      thickness: 1,
      outlineColor: 0xffffff
    });

    CreateReservoirStatus('reservoir_' + reservoir.id, 'resource', reservoir.name, reservoir.sprite,
      reservoir.count, 60)
  });

  reservoir.sprite.on('pointerout', function () {
    if (gameStore.equipPanel.selectApplicable === 'ore' && !reservoir.texture.includes('oil'))
      document.body.classList.remove("cursor_mining");

    postFxPlugin.remove(reservoir.sprite);
    RemoveReservoirStatus('reservoir_' + reservoir.id)
  });

  Crack(reservoir)
}

function Crack(r) {
  if (r.crackSprite) {
    r.crackSprite.destroy();
  }

  if (!r.crack) {
    return
  }

  let bar = getBar('reservoir', r.id, 'progress');
  if (!bar) {
    return;
  }

  r.crackSprite = Scene.make.sprite({
    x: r.sprite.x + r.crackX,
    y: r.sprite.y + r.crackY,
    key: "crack",
    add: true
  });
  r.crackSprite.setOrigin(0.5);
  r.crackSprite.setDisplaySize(12, 12);
  r.crackSprite.setDepth(999);
}

export {CreateReservoir, Crack}
