var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Боты")]),_vm._v(" "),_c('p',[_vm._v("NPC (массовка) ведущие собственную деятельность в секторах игрового мира. Они - такие же синтеты как и игроки,\n      из-за чего, зачастую ботов можно встретить за привычными для игрока делами: добыча ресурсов, охрана территории,\n      участие в боевых действиях, перевозка грузов и прочее. ")]),_vm._v(" "),_c('p',[_vm._v("Боты - это живая часть мира, которая остро реагирует на действия игрока и прочих ботов. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_3', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("В отличии от обычных ботов фракции, также существуют и APD - не поддающиеся логике из-за своей враждебности\n      древние машины. Они - противники для всех синтетов из любых фракций. Доподлинно неизвестно откуда они прибывают\n      и какую именно преследуют цель. Компенсируя своё устаревшее вооружение и слабые по сравнению с синтетами\n      дедуктивные функции, численным превосходством. ")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_2', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Взаимодействие с ботами")]),_vm._v(" "),_c('p',[_vm._v("У ботов есть система «отношений» с другими ботами или игроками. Отношения могут варьироваться от «вражды» до\n      «отличных». От этого зависит поведение бота по отношению к другим.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_1', 'png')+ ')',
      width: '400px',
      height: '93px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Выделение цели также полезно для того, чтобы следить за ней в «тумане войны», так как цель остаётся помеченной\n      до тех пор, пока она находится в зоне работы радара.")]),_vm._v(" "),_c('p',[_vm._v("Боты не только взаимодействуют с игроками, но и постоянно общаются друг с другом с помощью системы запросов.\n      Например, бот может попросить помощи или «кредитов за проезд» у другого бота или игрока.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_4', 'png')+ ')',
      width: '265px',
      height: '194px',
      marginLeft: 'calc(50% - 132px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Игроки также могут взаимодействовать с ботами (и даже с другими игроками, хотя и без диалогов) с помощью этого\n      способа через выделение цели.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_5', 'gif')+ ')',
      width: '400px',
      height: '175px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("На текущий момент доступны такие запросы:")]),_vm._v(" "),_vm._m(1)]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Bots")]),_vm._v(" "),_c('p',[_vm._v("NPCs (extras) conduct their own activities in the sectors of the game world. They are the same synthetics as\n      players, which is why bots can often be found doing familiar tasks for the player: resource extraction,\n      territory protection, participation in combat actions, cargo transportation, and more.")]),_vm._v(" "),_c('p',[_vm._v("Bots are a living part of the world that sharply reacts to the actions of the player and other bots.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_3', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Unlike regular faction bots, there are also AOP/APD - ancient machines that are illogical due to their\n      hostility. They are enemies for all synthetics from any faction. It is not known for certain where they come\n      from and what exact goal they pursue. Compensating for their outdated weaponry and deductive functions, which\n      are weak compared to synthetics, with numerical superiority.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_2', 'png')+ ')',
      width: '400px',
      height: '283px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('h2',{staticClass:"head"},[_vm._v("Interaction with bots")]),_vm._v(" "),_c('p',[_vm._v("The bots have a system of «relationships» with other bots or players that ranges from «hostility» to\n      «excellent» and determines the bot's behavior towards others.")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_1', 'png')+ ')',
      width: '400px',
      height: '93px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Selecting a target is also useful for keeping track of it in the «fog of war», as the target remains marked as\n      long as it is within the radar’s range.")]),_vm._v(" "),_c('p',[_vm._v("Bots not only interact with players but also constantly communicate with each other through a query system. For\n      example, bot can ask for help or «travel credits» from another bot or player.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_4', 'png')+ ')',
      width: '265px',
      height: '194px',
      marginLeft: 'calc(50% - 132px)',
      })}),_vm._v(" "),_c('p',[_vm._v("Players can also interact with bots (and even with other players, although without dialogues) using this method\n      by selecting a target.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('npc_5', 'gif')+ ')',
      width: '400px',
      height: '175px',
      marginLeft: 'calc(50% - 200px)',
      })}),_vm._v(" "),_c('p',[_vm._v("At the moment, the following requests are available:")]),_vm._v(" "),_vm._m(3)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Чтобы понять, как бот относится к игроку, нужно выделить его с помощью клавиши "),_c('span',{staticClass:"importantly"},[_vm._v("TAB")]),_vm._v(". Появится рамка, цвет которой говорит об отношении бота. Если рамка синяя или\n      голубая, это значит, что бот «боится», независимо от его отношения к вам.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Ограбить")]),_vm._v("\" - при успешном срабатывании вы получаете кредиты.")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Вытряхивай трюм")]),_vm._v("\" - при успешном срабатывании выбрасывает случайное\n        количество предметов в мир.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Совместное нападение")]),_vm._v("\" - при успешном срабатывании бот нападает на\n        указанную цель.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Оставить в покое")]),_vm._v("\" - при успешном срабатывании бот прекращает атаковать\n        текущую цель.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Мир")]),_vm._v("\" - при успешном срабатывании отношение с ботом становится нейтральным,\n        и он перестаёт вас атаковать.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To understand how a bot feels about a player, you need to select it using the "),_c('span',{staticClass:"importantly"},[_vm._v("TAB")]),_vm._v(" key. A frame will appear, the color of which indicates the bot's attitude. If the\n      frame is blue or light blue, it means that the bot is «afraid», regardless of its relationship to you.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Rob")]),_vm._v("\" - if successful, you get credits.")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Empty the hold")]),_vm._v("\" - if successful, a random number of items are thrown into\n        the world.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Joint attack")]),_vm._v("\" - if successful, the bot attacks the specified target.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Leave alone")]),_vm._v("\" - if successful, the bot stops attacking the current target.\n      ")]),_vm._v(" "),_c('li',[_vm._v("\""),_c('span',{staticClass:"importantly"},[_vm._v("Peace")]),_vm._v("\" - if successful, the relationship with the bot becomes neutral and\n        it stops attacking you.\n      ")])])
}]

export { render, staticRenderFns }