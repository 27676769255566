<template>
  <div class="modalBlock" :id="windowID" :ref="windowID" @mousedown="toUp" v-if="notify">
    <app-control v-bind:head="texts['window_name'][language]" v-bind:move="true" v-bind:close="true"
                 v-bind:refWindow="windowID"/>
    <div>
      <div class="notifyModalText">
        <v-runtime-template :template="notify.text[language]"/>
      </div>
      <div>
        <input value="Закрыть" class="cursor_hover" type="button"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="closeWindow">
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "ModalNotify",
  props: ['notify', 'windowID'],
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    closeWindow() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "DeleteNotify",
          uuid: this.$props.notify.uuid,
        }
      }));

      this.$store.commit({
        type: 'toggleWindow',
        id: this.$el.id,
        component: '',
      });
    },
    openHelpPageByName(name) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'helpWrapper',
        meta: {name: name},
        forceOpen: true,
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('ModalNotify')
    },
  },
  components: {
    AppControl: Control,
    VRuntimeTemplate,
  }
}
</script>

<style scoped>
.modalBlock {
  position: absolute;
  display: block;
  width: 200px;
  height: 100px;
  top: 15%;
  left: calc(50% - 100px);
  z-index: 11;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  padding: 20px 3px 3px 3px;
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.notifyModalText {
  padding: 5px;
  word-wrap: break-word;
  border-radius: 5px 5px 0 0;
  text-align: justify;
  margin: auto auto 2px 0;
  width: calc(100% - 10px);
  height: 70px;
  overflow-y: scroll;
  color: white;
  position: relative;
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  float: left;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5px black;
}

.modalBlock input {
  display: block;
  width: 100%;
  margin: 1px auto 0;
  pointer-events: auto;
  font-size: 12px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 16px;
  box-shadow: 0 0 2px #000;
  font-weight: bold;
  float: left;
}

.modalBlock input:hover {
  background: rgba(255, 129, 0, 1);
}

.modalBlock input:active {
  transform: scale(0.98);
}

</style>
<style>
.notifyModalText a {
  color: #32f2ff;
  font-weight: 900;
  text-indent: 15px;
  text-align: justify;
  margin: 4px 0;
  text-decoration: none;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}
</style>
