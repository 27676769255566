<template>
  <div>

    <template v-if="!createMenu">
      <app-user-line v-for="player in players"
                     :key="player.id"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click.native="selectPlayer(player.id)"
                     v-if="Object.keys(player).length !== 0 && player.game_mod === 'open_world'"
                     v-bind:user="player"
                     v-bind:noMenu="true"/>

      <div class="createNewUser button cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="openCreateMenu"
           :class="{disable: Object.keys(players).length >= 5}"
           style="margin-bottom: 4px; margin-top: 6px;">{{ texts['create'][lang] }} ({{
          Object.keys(players).length
        }} / 5)
      </div>
    </template>

    <template v-if="createMenu">
      <div class="createNewUser" style="margin-bottom: 4px">
        {{ texts['enter_name'][lang] }}
      </div>
      <input v-model="newName"
             class="createNewUser input"
             :placeholder="texts['player_name'][lang]"/>
      <div class="createNewUser button cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="create">{{ texts['create'][lang] }}
      </div>
    </template>

    <div class="createNewUser button cursor_hover" @mouseover="playSound('select_sound.mp3', 0.3)"
         @click="cancel">{{ texts['back'][lang] }}
    </div>
  </div>
</template>

<script>
import UserLine from '../Chat/UserLine';

export default {
  name: "OpenWorld",
  data() {
    return {
      createMenu: false,
      newName: null,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openCreateMenu() {
      this.playSound('button_press.mp3', 0.3)

      this.createMenu = true;
    },
    cancel() {
      this.playSound('button_press.mp3', 0.3)

      if (this.createMenu) {
        this.createMenu = false;
      } else {
        this.$parent.selectGameMode = '';
      }
    },
    create() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "CreateNewPlayer",
          data: this.newName,
        }
      }));

      this.createMenu = false;
    },
    selectPlayer(id) {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "SelectPlayer",
          id: id,
        }
      }));

      this.$store.commit({
        type: 'setVisibleLoader',
        visible: true,
        text: {
          'RU': 'Пытаемся понять что происходит...',
          'EN': 'We are trying to figure out what is going on...',
        }
      });

      this.$router.push({path: '/lobby'});
    },
  },
  computed: {
    user() {
      return this.$store.getters.getGameUser;
    },
    players() {
      let players = this.$store.getters.getUserPlayers
      let openPlayers = {}
      for (let i in players) {
        if (players[i].game_mod === 'open_world') openPlayers[i] = players[i]
      }
      return openPlayers;
    },
    settings() {
      return this.$store.getters.getSettings
    },
    lang() {
      return this.settings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Gate')
    }
  },
  components: {
    AppUserLine: UserLine,
  }
}
</script>

<style scoped>
.createNewUser {
  height: 20px;
  width: calc(100% - 4px);
  background: rgba(108, 108, 108, 0.51);
  margin: 2px;
  border-radius: 5px;
  color: white;
  line-height: 20px;
  box-shadow: 0 0 2px black;
}

.createNewUser.input {
  width: calc(100% - 14px);
}

.createNewUser.input::placeholder {
  color: white;
}

.createNewUser.button {
  transition: 100ms;
  background: rgba(255, 129, 0, 0.75);
}

.createNewUser.button:hover {
  background: rgba(255, 129, 0, 1);
}

.createNewUser.button:active {
  transform: scale(0.98);
}
</style>
