<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <div class="content_section">
        <h2 class="head">Снаряжение</h2>

        <p>В любой корпус можно установить самое различное снаряжение, которое будет делиться по своему функционалу,
          требуемой мощности и соответствующему слоту. </p>

        <p>Снаряжение делится на:</p>
        <ul>
          <li><span class="importantly">Пассивное</span> - не потребляет энергию накопителя и работает самостоятельно.
          </li>
          <li><span class="importantly">Активируемое</span> - не требует действий кроме активации,
            потребляет энергию за цикл или за действие.
          </li>
          <li><span class="importantly">Активируемое с целью</span> - например бросок
            гранаты произойдет туда где находится указатель мышки.
          </li>
          <li><span class="importantly">Активное</span> - выбирается и используется как оружие.
          </li>
        </ul>

        <p>Некоторому снаряжения необходимо устанавливается снаружи корпуса, ячейки таких слотов помечены желтым
          цветом. Преимущественно это снаряжения III типа.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('equip_1', 'png')+ ')',
            width: '480px',
            height: '110px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
      </div>
      <div class="content_section">
        У разного снаряжения могут быть совершенно разные характеристики, поэтому что оно делает и сколько потребляет
        ресурсов надо смотреть на карточке снаряжения.

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('wiki_equip_1', 'png')+ ')',
            width: '400px',
            height: '364px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>

    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Equipment</h2>

        <p>Various equipment can be installed in any hull, which will be divided by its functionality,
          required power, and corresponding slot. </p>

        <p>Equipment is divided into:</p>
        <ul>
          <li><span class="importantly">Passive</span> - does not consume accumulator energy and operates independently.
          </li>
          <li><span class="importantly">Activatable</span> - requires no action other than activation,
            consumes energy per cycle or per action.
          </li>
          <li><span class="importantly">Targeted Activatable</span> - for example, a grenade throw
            will occur where the mouse pointer is located.
          </li>
          <li><span class="importantly">Active</span> - selected and used as a weapon.
          </li>
        </ul>

        <p>Some equipment needs to be installed outside the hull, the cells of such slots are marked in yellow.
          Mostly this is Type III equipment.</p>

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('equip_1', 'png')+ ')',
            width: '480px',
            height: '110px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
      </div>
      <div class="content_section">
        Different equipment can have completely different characteristics, so what it does and how much it consumes
        resources must be looked at on the equipment card.

        <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('wiki_equip_1', 'png')+ ')',
            width: '400px',
            height: '364px',
            marginLeft: 'calc(50% - 200px)',
            }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Equips",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>
.headSection.equip {

}
</style>
