<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: Переработчик</h2>

      <p>Что бы открыть переработчик надо нажать кнопку <span class="importantly">"Переработчик"</span> в меню базы.
        <span class="importantly">Переработчик</span> позволяет перерабатывать руду и разбирать предметы.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_process_1', 'png')+ ')',
            width: '254px',
            height: '398px',
            marginLeft: 'calc(50% - 127px)',
            }"/>

      <p>Что бы переработать руду надо ее перетащить из инвентаря или склада в левую часть меню. Новые предметы упадут
        на
        <span class="importantly">склад</span> базы.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_process_2', 'gif')+ ')',
            width: '481px',
            height: '421px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Processor</h2>

      <p>To open the processor, you need to press the <span class="importantly">"Processor"</span> button in the base
        menu.
        The <span class="importantly">Processor</span> allows for the processing of ore and disassembling items.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_process_1', 'png')+ ')',
            width: '254px',
            height: '398px',
            marginLeft: 'calc(50% - 127px)',
            }"/>

      <p>To process ore, you need to drag it from the inventory or warehouse to the left side of the menu. New items
        will fall
        onto the <span class="importantly">warehouse</span> of the base.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_process_2', 'gif')+ ')',
            width: '481px',
            height: '421px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationProcess",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
