<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: Добыча руды</h2>

      <p>В этой части задания надо прокатиться по карте в поиска источника руды. На мини карте и радаре источники руды
        помечаются иконкой в виде ромбика. В данном задание нужен торий и такие залежи дополнительно будут помечаться на
        мини карте зеленым крестом.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_mining_1', 'png')+ ')',
            width: '472px',
            height: '118px',
            marginLeft: 'calc(50% - 236px)',
            }"/>

      <p>Что начать добывать руду надо: </p>
      <ul>
        <li>выбрать добывающий лазер на панели снаряжения</li>
        <li>навести курсов на руду и нажать лкм</li>
        <li>дождаться заполнения шкалы после чего руда упадет в трюм или отсек для руды если такой имеется</li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_mining_2', 'gif')+ ')',
            width: '405px',
            height: '393px',
            marginLeft: 'calc(50% - 202px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Ore Mining</h2>

      <p>In this part of the task, you need to travel around the map in search of an ore source. On the mini-map and
        radar, ore sources
        are marked with a diamond icon. In this task, thorium is required and such deposits will additionally be marked
        on the
        mini-map with a green cross.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_mining_1', 'png')+ ')',
            width: '472px',
            height: '118px',
            marginLeft: 'calc(50% - 236px)',
            }"/>

      <p>To start mining ore you need to: </p>
      <ul>
        <li>select the mining laser on the equipment panel</li>
        <li>aim the cursor at the ore and click LMB</li>
        <li>wait for the scale to fill up after which the ore will fall into the hold or the ore compartment if there is
          one
        </li>
      </ul>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_mining_2', 'gif')+ ')',
            width: '405px',
            height: '393px',
            marginLeft: 'calc(50% - 202px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationMining",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
