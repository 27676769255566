<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: корпус</h2>

      <p>Что бы установить корпус, зажмите левую кнопку мыши на иконке корпуса в инвентаре и перенесите ее в центр
        ангара.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_body_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Training: Hull</h2>

      <p>To install the hull, press and hold the left mouse button on the hull icon in the inventory and drag it to the
        center of the hangar.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_body_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationBody",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
