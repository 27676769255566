var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Создание предметов на базе")]),_vm._v(" "),_c('p',[_vm._v("На главной базе сектора можно создавать различные предметы при наличие чертежа и ресурсов для него. База может\n      взымать налог на создание из расчета своей эффективности.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('craft_items_1', 'png')+ ')',
          width: '487px',
          height: '266px',
          marginLeft: 'calc(50% - 243px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Item Creation at the Base")]),_vm._v(" "),_c('p',[_vm._v("At the main base of the sector, you can create various items if you have the blueprint and resources for it.\n      The base may charge a tax on creation based on its efficiency.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('craft_items_1', 'png')+ ')',
          width: '487px',
          height: '266px',
          marginLeft: 'calc(50% - 243px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }