<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <div class="content_section">
        <h2 class="head">Ополчение</h2>
        <p>Ополчение - основные участники в войне фракций за спорные сектора.</p>
        <p>Едва игрок вступает в ополчение своей фракции, как становится приоритетной целью для игроков иных фракций в
          свободных и боевых секторах. Между тем, помощь вам будут оказывать союзные силы - игроки из вашей фракции и
          NPC. </p>

        <p>Вступить в ополчение игрок может через особый диалог на базе своей фракции (Генератор “Гифебрации”/Орудие
          “Гончкинс”/Станция отслеживания “Велири”).</p>

        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('militia_1', 'png')+ ')',
        width: '400px',
        height: '271px',
        marginLeft: 'calc(50% - 200px)',
    }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Очки фракций (оф)</h2>
        <p>За участие в войне фракций, вы получаете особые очки, которые, затем сумеете обменять на ценные награды в
          фракционном магазине. </p>
        <ul style="float: left">
          <li>Уничтожение вражеского транспорта в войне - (макс кол-во жизней транспорта / 2) оф</li>
          <li>Помощь в уничтожение вражеского транспорта в войне - (макс кол-во жизней транспорта / 4) оф</li>
          <li>Урон по вражескому транспорту в войне - (урон / 3) оф</li>
          <li>Уничтожение вражеских структур - 200 оф</li>
          <li>Захват базы - 250 оф</li>
          <li>Захват сектора - 1000 оф (получают все кто заработал больше 250 оф за последние 5 минут в этом секторе)
          </li>
        </ul>
      </div>

      <div class="content_section">
        <h2 class="head">Фракционные армии</h2>
        <p>Помимо ополчения в войне участвует по 2 армии (1 армия = 12 ботов) ботов от каждой фракции. В пассивной фазе
          войны армии патрулируют захваченные сектора. В активной фазе 1 армия атакует приоритетную цель (ее можно
          узнать
          из новостей и меню карты), а 2я армия обороняет сектора. Однако в зависимости от ситуации их поведение может
          меняться.</p>
        <p>Нередко, в самый разгар конфликта, на спорные территории могут вторгаться силы APD - представляющие угрозу
          для
          всех без исключения.</p>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Militia</h2>
        <p>Militia are the main participants in the faction war for disputed sectors.</p>
        <p>As soon as a player joins their faction's militia, they become a priority target for players from other
          factions in
          free and combat sectors. Meanwhile, allied forces - players from your faction and NPCs - will assist you. </p>

        <p>A player can join the militia through a special dialogue at their faction's base (Generator
          "Gifebration"/Cannon "Gonchkins"/Tracking Station "Veliri").</p>

        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('militia_1', 'png')+ ')',
          width: '400px',
          height: '271px',
          marginLeft: 'calc(50% - 200px)',
        }"/>
      </div>

      <div class="content_section">
        <h2 class="head">Faction Points (FP)</h2>
        <p>For participating in the faction war, you receive special points that can then be exchanged for valuable
          rewards in
          the faction store. </p>
        <ul style="float: left">
          <li>Destruction of enemy transport in the war - (max transport health / 2) FP</li>
          <li>Assistance in destroying enemy transport in the war - (max transport health / 4) FP</li>
          <li>Damage to enemy transport in the war - (damage / 3) FP</li>
          <li>Destruction of enemy structures - 200 FP</li>
          <li>Capture of a base - 250 FP</li>
          <li>Capture of a sector - 1000 FP (awarded to all who earned more than 250 FP in the last 5 minutes in that
            sector)
          </li>
        </ul>
      </div>

      <div class="content_section">
        <h2 class="head">Faction Armies</h2>
        <p>In addition to the militia, 2 armies (1 army = 12 bots) of bots from each faction participate in the war. In
          the passive phase
          of the war, the armies patrol the captured sectors. In the active phase, 1 army attacks the priority target
          (which can be
          learned
          from the news and the map menu), and the 2nd army defends the sectors. However, depending on the situation,
          their behavior can
          change.</p>
        <p>Often, in the midst of conflict, APD forces may invade disputed territories - posing a threat
          to
          everyone without exception.</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Militia",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
