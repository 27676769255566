var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Пропавшие предметы для заданий")]),_vm._v(" "),_c('p',[_vm._v("Для завершения некоторых заданий требуется доставить или получить определённый предмет.")]),_vm._v(" "),_c('p',[_vm._v("Такой предмет можно потерять если ваш транспорт уничтожили, в таком случае его можно попробовать достать из\n      обломков (но не факт что предмет в них будет). В противном случае можно попробовать купить нужный предмет на\n      рынке\n      или отменить задание и взять его заново.")]),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Отмена задания")]),_vm._v(" "),_c('p',[_vm._v("Что бы отменить задание надо зайти в меню личного дела во вкладку \"задания\", проще всего это сделать через\n      кнопку\n      \"задания\" в меню прогресса задания.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('lost_item_1', 'png')+ ')',
          width: '272px',
          height: '216px',
          marginLeft: 'calc(50% - 136px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Далее нажмите кнопку \"отменить\" и подтвердите действие.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('lost_item_2', 'png')+ ')',
          width: '319px',
          height: '167px',
          marginLeft: 'calc(50% - 159px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Missing Items for Tasks")]),_vm._v(" "),_c('p',[_vm._v("To complete some tasks, you need to deliver or receive a certain item.")]),_vm._v(" "),_c('p',[_vm._v("If your transport is destroyed, you may lose such an item; in that case, you can try to retrieve it from the\n      wreckage (although it's not guaranteed that the item will be there). Otherwise, you can try to buy the required\n      item on the market or cancel the task and take it again.")]),_vm._v(" "),_c('h3',{staticClass:"head"},[_vm._v("Cancelling a Task")]),_vm._v(" "),_c('p',[_vm._v("To cancel a task, you need to go to the personal file menu in the \"tasks\" tab, which is most easily done\n      through the \"tasks\" button in the task progress menu.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('lost_item_1', 'png')+ ')',
          width: '272px',
          height: '216px',
          marginLeft: 'calc(50% - 136px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Then press the \"cancel\" button and confirm the action.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('lost_item_2', 'png')+ ')',
          width: '319px',
          height: '167px',
          marginLeft: 'calc(50% - 159px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Сюжетные и обучающие миссии будут предложены повторно когда вы посетите "),_c('span',{staticClass:"importantly"},[_vm._v("новую")]),_vm._v("\n      (или перезайдите в игру находясь на базе) базу.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Story and tutorial missions will be offered again when you visit a "),_c('span',{staticClass:"importantly"},[_vm._v("new")]),_vm._v(" base\n      (or re-enter the game while at a base).")])
}]

export { render, staticRenderFns }