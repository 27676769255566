<template>
  <div class="mapPin" :class="{active: subMenuProps.active}"
       v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">
    <div class="section">
      <div style="float: left">

        <div class="icon" v-if="!subMenuProps.map.free_land"
             :style="{backgroundImage: 'url(' + require('../../assets/' + fractionLogo(subMenuProps.map.fraction)).default + ')'}"></div>

        <div class="icon" v-if="subMenuProps.map.free_land"
             :style="{backgroundImage: 'url(' + require('../../assets/icons/apd_mini.png').default}"/>

        <div class="mapName">{{ handbook['sector'][language][subMenuProps.map.name].name }}</div>
      </div>

      <div style="float: right" v-if="subMenuProps.active">
        <div class="auto_pilot cursor_hover"
             :title="texts['button_1'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, 0, 'auto_pilot')"></div>
        <div class="target cursor_hover"
             :title="texts['button_2'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, 0, 'target')"></div>
      </div>
    </div>

    <div class="section" v-if="subMenuProps.map.battle">
      <div class="battle"></div>
      <div class="battle_text">{{ texts['text_1'][language] }}</div>
    </div>

    <div class="section" v-if="mainBase">
      <div style="float: left">
        <div class="icon"
             :style="{backgroundImage: 'url(' + baseIcon(mainBase) + ')'}"></div>

        <div class="baseName">
          <p>{{ getBaseTypeName(mainBase.type) }}</p>
          <p>{{ handbook['base'][language][mainBase.name].name }}</p>
        </div>
      </div>

      <div style="float: right" v-if="subMenuProps.active">
        <div class="auto_pilot cursor_hover"
             :title="texts['button_1'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, mainBase.id, 'auto_pilot')"></div>
        <div class="target cursor_hover"
             :title="texts['button_2'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, mainBase.id, 'target')"></div>
      </div>
    </div>

    <div class="section" v-if="otherStructures" v-for="base in otherStructures">
      <div style="float: left">
        <div class="icon"
             :style="{backgroundImage: 'url(' + baseIcon(base) + ')'}"></div>

        <div class="baseName">
          <p>
            <span v-html="getBaseTypeName(base.type, base.corporation_id)"/>
          </p>
          <p>{{ base.name }}</p>
        </div>
      </div>

      <div style="float: right" v-if="subMenuProps.active">
        <div class="auto_pilot cursor_hover"
             :title="texts['button_1'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, base.id, 'auto_pilot')"></div>
        <div class="target cursor_hover"
             :title="texts['button_2'][language]"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="toSectorAutoPilot(subMenuProps.map.id, base.id, 'target')"></div>
      </div>

      <div class="section_inner"
           v-if="base.type === 'corporation' && worldMapState && worldMapState.timeAttack !== null">
        <div class="battle_text" style="color: white">
          {{ texts['text_2'][language] }}
          {{ getHourByTimeZone(worldMapState.timeAttack) }}:00 -
          {{ getHourByTimeZone(worldMapState.timeAttack + 1) }}:00 ({{ timeZone }})
        </div>
      </div>
    </div>

    <div class="exit_block" v-if="subMenuProps.active">
      <input type="button" class="cursor_hover"
             :value="texts['button_3'][language]"
             @click="$parent.subMenuProps = null">
    </div>
  </div>
</template>

<script>

import {urls} from "../../const";

export default {
  name: "MapPin",
  props: ['subMenuProps'],
  data() {
    return {
      corporations: {},
    }
  },
  mounted() {
    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetTimeAttack",
        id: this.$props.subMenuProps.map.id,
      }
    }));
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    fractionLogo(fraction) {
      if (fraction === '') {
        fraction = 'empty'
      }
      return 'logo/' + fraction + '.png'
    },
    getCorporationName(id) {
      if (id === 0 || !this.corporations[id] || !this.corporations[id].corporation) {
        return ''
      }

      return ': <span class="importantly">' + this.corporations[id].corporation.name + '</span>'
    },
    getBaseTypeName(baseType, corpID) {
      let type = this.$store.getters.getBaseTypeName(baseType, this.language)

      if (corpID && corpID !== 0) {
        type += this.getCorporationName(corpID)
      }

      return type
    },
    toSectorAutoPilot(mapID, baseID, type) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "MoveToSector",
          map_id: Number(mapID),
          base_id: Number(baseID),
          type: type,
        }
      }));
      this.$parent.subMenuProps = null
      // this.$store.commit({
      //   type: 'toggleWindow',
      //   id: 'GlobalViewMap',
      //   forceClose: true,
      // });
    },
    baseIcon(base) {
      try {

        if (base.type === "corporation") {
          return require('../../assets/map/structures/icon/' + base.sprite_texture + '.png').default
        }

        if (base.sprite_texture !== "") {
          return require('../../assets/map/objects/sector_structure/' + base.sprite_texture + '.png').default
        }

        return require('../../assets/icons/cancelIcon.png').default
      } catch (e) {
        return require('../../assets/icons/cancelIcon.png').default
      }
    },
    getHourByTimeZone(utcHour) {
      let offset = new Date().getTimezoneOffset() / 60
      let timeZoneHour = utcHour + (offset * -1)
      if (timeZoneHour >= 24) timeZoneHour -= 24
      return timeZoneHour
    },
    getCorporationByID(id) {
      let app = this
      app.$api.get(urls.corpInfoURL + '?corporation_id=' + id).then(function (response) {
        if (response.data) {
          app.$set(app.corporations, response.data.corporation.id, response.data);
        }
      })
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('GlobalMap')
    },
    worldMapState() {
      return this.$store.getters.getWorldMapState
    },
    info() {
      return this.worldMapState.mapInfo[this.$props.subMenuProps.map.id]
    },
    mainBase() {
      if (this.info && this.info.bases) {
        for (let i in this.info.bases) {
          let base = this.info.bases[i];
          if (base.type === 'base') {
            return base
          }
        }
      }
    },
    otherStructures() {
      let structures = [];

      if (this.info && this.info.bases) {
        for (let i in this.info.bases) {
          let base = this.info.bases[i];
          if (base.type !== 'base') {
            structures.push(base);

            if (base.corporation_id !== 0 && !this.corporations.hasOwnProperty(base.corporation_id)) {
              this.getCorporationByID(base.corporation_id)
            }
          }
        }
      }

      return structures
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    timeZone() {
      let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
      return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) // + ":" + ("00" + (o % 60)).slice(-2);
    }
  },
}
</script>

<style scoped>
.mapPin {
  position: absolute;
  background-size: 100% 2px;
  background-image: linear-gradient(0deg, rgba(7, 41, 60, 0.8), rgba(0, 62, 95, 0.8));
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  min-height: 20px;
  min-width: 80px;
  padding: 5px;
  z-index: 999;
  text-shadow: 1px 1px 2px black, 0 0 1em #696969;
}

.active {
  border: 1px solid #25a0e1;
  background: rgb(8, 138, 210);
  box-shadow: 0 0 2px black;
}

.icon {
  float: left;
  height: 25px;
  width: 25px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 2px black;
}

.mapName {
  color: yellow;
  float: left;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-size: 15px;
  margin-left: 5px;
}

.section {
  clear: both;
  margin-bottom: 2px;
  float: left;
  width: calc(100% - 4px);
  padding: 3px 2px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 2px black;
}

.section_inner {
  clear: both;
  float: left;
  width: calc(100% - 4px);
  padding: 3px 2px;
  margin-top: 3px;
}

.battle {
  height: 30px;
  width: 30px;
  background-image: url('../../assets/icons/battle_icon.png');
  background-size: contain;
  z-index: 2;
  margin: 0 auto;
}

.battle_text {
  color: red;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

.baseName {
  color: yellow;
  float: left;
  font-size: 11px;
  margin-left: 5px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.baseName p {
  margin: 0;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.baseName p:first-child {
  font-size: 11px;
  color: white;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.exit_block input, .auto_pilot, .target {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  font-weight: 700;
}

.exit_block input:hover, .auto_pilot:hover, .target:hover {
  background: rgba(255, 129, 0, 1);
}

.exit_block input:active, .auto_pilot:active, .target:active {
  transform: scale(0.98);
}

.exit_block {
  clear: both;
  float: left;
  width: 100%;
}

.auto_pilot, .target {
  float: left;
  height: 25px;
  width: 25px;
  margin-left: 5px;
  margin-top: 0;
  background-size: cover;
  background-image: url('../../assets/icons/ai_move_on.png');
}

.target {
  background-image: url('../../assets/icons/move_target.png');
}

.auto_pilot:hover {
  background: url('../../assets/icons/ai_move_on.png'), rgba(255, 129, 0, 1);
  background-size: cover;
}

.target:hover {
  background: url('../../assets/icons/move_target.png'), rgba(255, 129, 0, 1);
  background-size: cover;
}

</style>
