var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"WarehousesTabs cursor_hover",class:{active: _vm.id === _vm.inventory.warehouse, remote: _vm.remote, path: _vm.path, scanner: _vm.id.includes('scanner'),
      aInv: _vm.aInv, sAInv: _vm.sAInv, office: _vm.office, disable: _vm.rights&& !_vm.rights.view, green_animate: _vm.green_animate},on:{"mouseover":function($event){return _vm.playSound('select_sound.mp3', 0.3)},"click":function($event){return _vm.switchWarehouse(_vm.id)}}},[_c('div',{staticStyle:{"position":"relative","z-index":"2"}},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"WarehousesTabBackground",class:{
          active: _vm.id === _vm.inventory.warehouse,
          remote: _vm.remote,
          path: _vm.path,
          scanner: _vm.id.includes('scanner'),
          green_animate: _vm.green_animate
       },style:({
          width: (_vm.id === 'squadInventory' || _vm.id.includes('aInv') || _vm.id.includes('office_stock') || _vm.id.includes('hangar')) ? _vm.fill + '%' : '100%',
       })}),_vm._v(" "),(_vm.rights)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0","z-index":"2"}},[(!_vm.rights.view)?_c('div',{staticClass:"no_view"}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lock)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0","z-index":"2"}},[_c('div',{staticClass:"lock"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }