<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Глобальная политика</h2>

      <p>Кластеры могут выставлять политики в другим кластерам, но текущий момент это меню позволяет указать список
        "Союзников"/"Соперников".</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('global_policy_1', 'png')+ ')',
            width: '368px',
            height: '401px',
            marginLeft: 'calc(50% - 184px)',
            }"/>

      <p>Этот список необходим для более гибкой установки правил локальной политики, а так же у соперников и союзников
        появится иконка рядом с ником в мире.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('global_policy_2', 'png')+ ')',
            width: '353px',
            height: '137px',
            marginLeft: 'calc(50% - 176px)',
            }"/>

      <p>Эти списки не вносят никакие директивы для систем правосудия или еще каких, а только информарует игрока что
        перед ним союзник или соперник.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Global Policy</h2>

      <p>Clusters can set policies towards other clusters, but at the moment this menu allows specifying a list of
        "Allies"/"Rivals".</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('global_policy_1', 'png')+ ')',
            width: '368px',
            height: '401px',
            marginLeft: 'calc(50% - 184px)',
            }"/>

      <p>This list is necessary for more flexible setting of local policy rules, and also an icon will appear next to
        the nickname in the world for rivals and allies.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('global_policy_2', 'png')+ ')',
            width: '353px',
            height: '137px',
            marginLeft: 'calc(50% - 176px)',
            }"/>

      <p>These lists do not introduce any directives for the justice system or others, but only inform the player that
        they are facing an ally or a rival.</p>

    </template>
  </div>
</template>

<script>
export default {
  name: "CorporationGlobalPolicy",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
