var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Сбор обломков")]),_vm._v(" "),_c('p',[_vm._v("После уничтожения синтета на поверхности планеты часто остаются обломки его корпуса. Всё, что находилось в\n      трюме, разбрасывается вокруг обломков.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('dismantling_unit_2', 'gif')+ ')',
          width: '400px',
          height: '300px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Обломки корпуса имеют половину от максимального здоровья уничтоженного транспорта. Это позволяет использовать\n      их как укрытие в случае необходимости.")]),_vm._v(" "),_c('p',[_vm._v("Всё снаряжение, установленное в корпусе, включая оружие, может сохраниться внутри обломков. Также в обломках\n      остаются боеприпасы, которые находились в трюме. Всё остальное, что было в трюме (кроме боеприпасов),\n      разлетается вокруг.")]),_vm._v(" "),_c('p',[_vm._v("Шанс сохранения оружия и снаряжения установленное в корпусе равен 1 к 15.")]),_vm._v(" "),_c('p',[_vm._v("Количество предметов из трюма, которые сохранятся после уничтожения, варьируется от 10 % до 100 %. Однако\n      гарантированно сохранится хотя бы один предмет из пачки, даже если в ней всего один предмет.")]),_vm._v(" "),_c('p',[_vm._v("Сами обломки можно переработать на детали посредством инженерного модуля.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('dismantling_unit_1', 'gif')+ ')',
          width: '300px',
          height: '185px',
          marginLeft: 'calc(50% - 150px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Debris Collection")]),_vm._v(" "),_c('p',[_vm._v("After the destruction of the synthet, the fragments of its hull often remain on the planet's surface.\n      Everything that was in the hold is scattered around the fragments.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('dismantling_unit_2', 'gif')+ ')',
          width: '400px',
          height: '300px',
          marginLeft: 'calc(50% - 200px)',
          })}),_vm._v(" "),_c('p',[_vm._v("The fragments of the hull have half of the maximum health of the destroyed transport. This allows them to be\n      used as a shelter if necessary.")]),_vm._v(" "),_c('p',[_vm._v("All equipment installed in the hull, including weapons, may be preserved inside the fragments. Also, the\n      ammunition that was in the hold remains in the fragments. Everything else that was in the hold (except for\n      ammunition) scatters around.")]),_vm._v(" "),_c('p',[_vm._v("The chance of preserving weapons and equipment installed in the hull is 1 to 15.")]),_vm._v(" "),_c('p',[_vm._v("The number of items from the hold that will be preserved after destruction varies from 10% to 100%. However, at\n      least one item from the batch will be guaranteed to be preserved, even if there is only one item in it.")]),_vm._v(" "),_c('p',[_vm._v("The debris itself can be processed into parts using an engineering module.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('dismantling_unit_1', 'gif')+ ')',
      width: '300px',
      height: '185px',
      marginLeft: 'calc(50% - 150px)',
      })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }