var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Чертежи")]),_vm._v(" "),_c('p',[_vm._v("Чертежи - универсальный способ по быстрому созданию предметов. В этих нейро-графических изображениях содержится\n      вся необходимая информация, касающаяся процесса создания той или иной вещи по чертежу.\n    ")]),_vm._v(" "),_c('p',[_vm._v("Вы можете посмотреть подробности о любом чертеже, открыв его\n      карточку в своем инвентаре и перейдя на вкладку “Производство”. Там вы увидите список всех компонентов,\n      результат,\n      а также время производства.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('blueprint', 'png')+ ')',
          width: '320px',
          height: '459px',
          marginLeft: 'calc(50% - 160px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Blueprints")]),_vm._v(" "),_c('p',[_vm._v("Blueprints are a universal way to quickly create items. These neuro-graphic images contain all the necessary\n      information regarding the process of creating a particular item from the blueprint.")]),_vm._v(" "),_c('p',[_vm._v("You can view the details of any blueprint by opening its card in your inventory and going to the \"Production\"\n      tab. There you will see a list of all components, the result, and the production time.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('blueprint', 'png')+ ')',
      width: '320px',
      height: '459px',
      marginLeft: 'calc(50% - 160px)',
      })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }