<template>
  <div class="MessagesWrapper" :class="{visible: chatState.npc_animate}" :style="{zoom: interfaceZoom}">

    <app-messages
      v-bind:styles="{background: 'rgba(137, 150, 156, 0.2)', height: '100%', width: '100%', overflow: 'hidden'}"
      v-bind:messages="messages"
      v-bind:font-size="fontSize"
      v-bind:UserMessageIcon="UserMessageIcon"
      v-bind:force="true"
      v-bind:animate="chatState.npc_animate"
      v-bind:color="true"
      v-bind:tag="true"
      v-bind:greetings="texts['default_greetings'][language]"
      v-bind:always-to-bottom="true"
    />

  </div>
</template>

<script>
import Messages from './Messages';

export default {
  name: "MessagesWrapper",
  data() {
    return {
      fontSize: 11,
      UserMessageIcon: false,
    }
  },
  mounted() {
    $(this.$el).resizable({
      minHeight: 150,
      maxHeight: 500,
      minWidth: 220,
      maxWidth: 220,
      handles: "n",
    });
  },
  computed: {
    chatState() {
      return this.$store.getters.getChatState;
    },
    messages() {
      let allMsg = [];

      let localHistory = this.$store.getters.getChatGroup('local');
      let corporationGroup = this.$store.getters.getChatGroup('corporation');
      let groupGroup = this.$store.getters.getChatGroup('group');

      if (localHistory) {
        for (let i in localHistory.history) {
          localHistory.history[i].type = 'local'
          allMsg.push(localHistory.history[i])
        }
      }

      if (corporationGroup) {
        for (let i in corporationGroup.history) {
          corporationGroup.history[i].type = corporationGroup.type;
          allMsg.push(corporationGroup.history[i])
        }
      }

      if (groupGroup) {
        for (let i in groupGroup.history) {
          groupGroup.history[i].type = groupGroup.type;
          allMsg.push(groupGroup.history[i])
        }
      }

      allMsg.sort(function (a, b) {
        let aTime = new Date(a.time);
        let bTime = new Date(b.time);
        return aTime.getTime() - bTime.getTime();
      });

      return allMsg
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Chat')
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    }
  },
  components: {
    AppMessages: Messages,
  }
}
</script>

<style scoped>
.MessagesWrapper {
  position: fixed;
  bottom: 11px;
  left: 35px;
  width: 220px;
  height: 175px;
  pointer-events: none;
  opacity: 0.5;
  z-index: 2;
}

.MessagesWrapper.visible {
  opacity: 1;
}
</style>
<style>
.MessagesWrapper .ui-resizable-n {
  z-index: 90 !important;
  margin-left: 7px;
  height: 7px !important;
  top: -7px !important;
  width: calc(100% + 8px) !important;
  background: repeating-linear-gradient(
    to top,
    transparent,
    rgba(255, 255, 255, 0.3) 1px,
    black 2px,
    rgba(255, 255, 255, 0.3) 1px
  ) !important;
  pointer-events: all !important;
}
</style>
