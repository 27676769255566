import {gameStore} from "../store";
import {fairyStore} from "./create";
import {meteoritesStore} from "./meteorite";
import {Scene} from "../create";

let removedBullets = {} // TODO это не спасает т.к. пули могу войти в туман а потом выйти

function deleteBullet(id, noAddInRemove) {
  // if (!noAddInRemove) removedBullets[id] = true
  let bullet = gameStore.bullets[id]
  if (!bullet || !bullet.sprite) {
    delete gameStore.bullets[id];
    return;
  }

  if (bullet.ping) {
    bullet.ping.destroy();
    bullet.ping = null;
  }

  if (bullet.debugText) {
    bullet.debugText.setVisible(false);
  }

  if (bullet.shadow) {
    bullet.shadow.setVisible(false);
  }

  if (bullet.sprite.RadarMark) {
    bullet.sprite.RadarMark.setVisible(false);
  }

  if (bullet.fairy) {
    bullet.fairy.stop();
    bullet.fairy.setVisible(false);
    fairyStore.push(bullet.fairy)
    bullet.fairy = true
  }

  if (bullet.type_bullet === "meteorite") { // метеориты
    bullet.sprite.stop();
    bullet.sprite.setVisible(false);
    if (bullet.shadow) {
      bullet.shadow.stop();
    }

    Scene.time.addEvent({
      delay: 512,                // ms
      callback: function () {
        meteoritesStore.bullet.push(bullet.sprite)
        if (bullet.shadow) {
          meteoritesStore.shadow.push(bullet.shadow)
        }
      },
      loop: false
    });

    delete gameStore.bullets[id];
    return;
  }

  delete gameStore.bullets[id];

  if (bullet.sprite) {
    bullet.sprite.setVisible(false);

    if (!gameStore.cacheSpriteBullets.hasOwnProperty(bullet.sprite.frame.name)) {
      gameStore.cacheSpriteBullets[bullet.sprite.frame.name] = [];
    }

    gameStore.cacheSpriteBullets[bullet.sprite.frame.name].push(bullet);
  }
}

export {deleteBullet, removedBullets}
