var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: панель снаряжения")]),_vm._v(" "),_c('p',[_vm._v("Панель снаряжения позволяет использовать снаряжение, что бы разместить там снаряжение нужно перетащить его в\n      ячейку панели.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_quick_panel', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Equipment Panel")]),_vm._v(" "),_c('p',[_vm._v("The equipment panel allows you to use equipment. To place equipment there, you need to drag it into a panel\n      slot.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_quick_panel', 'gif')+ ')',
          width: '437px',
          height: '189px',
          marginLeft: 'calc(50% - 218px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }