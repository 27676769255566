<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Фракционный магазин</h2>

      <p> Фракционный магазин - это специальный магазин, который находится на особой базе, принадлежащей фракции игрока.
        (Генератор “Гифебрации”/Орудие “Гончкинс”/Станция отслеживания “Велири”), В фракционном магазине вы можете
        обменять очки фракции, которые вы заработали в
        <router-link to="fraction_war">фракционной войне</router-link>
        , на разные товары, которые могут быть полезны для
        вашего транспорта или вашей фракции. Вы можете купить чертежи, оружие, патроны и другие предметы, которые
        доступны
        только в фракционном магазине.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('fraction_market', 'png')+ ')',
            width: '353px',
            height: '203px',
            marginLeft: 'calc(50% - 171px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Faction Store</h2>

      <p> The faction store is a special shop located on a special base belonging to the player's faction.
        (Generator "Gifebration"/Weapon "Gonchkins"/Tracking Station "Veliri"), In the faction store, you can
        exchange faction points, which you earned in
        <router-link to="fraction_war">faction war</router-link>
        ,
        for various goods that can be useful for
        your transport or your faction. You can buy blueprints, weapons, ammunition, and other items that are
        available
        only in the faction store.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('fraction_market', 'png')+ ')',
            width: '353px',
            height: '203px',
            marginLeft: 'calc(50% - 171px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "FractionMarket",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
