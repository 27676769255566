import {RemoveBuildObject, ResponsePlace, SelectPlaceBuild} from "../../../game/build/select_place_build";
import {gameStore} from "../../../game/store";

export default function corporationRead(msg, store) {
  if (msg.event === "GetCorporations") {
    store.commit({
      type: 'setAllCorporations',
      corporations: msg.data.corporations,
    });
  }

  if (msg.event === "GetCorporation") {
    store.commit({
      type: 'setCorporationState',
      info: msg.data.corporation,
      members: msg.data.members,
      roles: msg.data.roles,
      inviteRequest: msg.data.invite_requests,
      localRights: msg.data.local_roles,
    });

    if (msg.data && msg.data.corporation && msg.data.corporation.hasOwnProperty('id')) {
      gameStore.playerCorporationID = msg.data.corporation.id
    } else {
      gameStore.playerCorporationID = 0
    }
  }

  if (msg.event === "GetCorporationOffice") {
    msg.data.office.storages = msg.data.storages
    store.commit({
      type: 'addCorporationOffice',
      office: msg.data.office,
    });
  }

  if (msg.event === "GetCorporationDeposits") {
    store.commit({
      type: 'setCorporationDeposits',
      deposits: msg.data,
    });
  }

  if (msg.event === "GetCorporationBases") {
    store.commit({
      type: 'setCorporationBases',
      bases: msg.data.bases,
      resources: msg.data.resources,
      modules: msg.data.modules,
      siege: msg.data.siege,
    });
  }

  if (msg.event === "GetRentalPrice") {
    store.commit({
      type: 'setRentalPrice',
      rental_price: msg.data,
    });
  }

  if (msg.event === "GetAvailableWithdrawDeposits") {
    store.commit({
      type: 'setAvailableWithdrawDeposits',
      deposits: msg.data,
    });
  }

  if (msg.event === "GetDepositHistory") {
    store.commit({
      type: 'setDepositHistory',
      deposit_history: msg.data,
    });
  }

  if (msg.event === "GetLocalRight") {
    store.commit({
      type: 'setLocalRight',
      local_right: msg.data,
    });
  }

  if (msg.event === "OpenBuildMenu") {
    store.commit({
      type: 'setBuildMenuBps',
      points: msg.data.points,
      maxEnergy: msg.data.maxEnergy,
      currentEnergy: msg.data.currentEnergy,
      currentLimit: msg.data.currentLimit,
    });
  }

  if (msg.event === "SelectPlaceBuild") {
    SelectPlaceBuild(msg.data.id, msg.data.object, msg.data.base_data, msg.data.circles)
  }

  if (msg.event === "GetPlaceGeoData") {
    ResponsePlace(msg.data.object_place, msg.data.base_data_place, msg.data.zones)
  }

  if (msg.event === "SetMapCorpID") {
    if (gameStore.map && gameStore.map.id === msg.data.map_id) {
      gameStore.mapCorporationID = msg.data.corp_id
    }
  }

  if (msg.event === "PlaceObject") {
    RemoveBuildObject()
  }

  if (msg.event === "GetBaseFillUpPrice") {
    store.commit({
      type: 'setRentalPrice',
      rental_price: msg.data,
    });
  }

  if (msg.event === "GetContacts") {

    gameStore.corporationContacts = {}
    for (let i in msg.data) {
      gameStore.corporationContacts[msg.data[i].id] = msg.data[i].p;
    }

    store.commit({
      type: 'setCorporationContacts',
      contacts: msg.data,
    });
  }

  if (msg.event === "GetLocalPolicy") {
    store.commit({
      type: 'setCorporationLocalPolicy',
      localPolicy: msg.data,
    });
  }

  if (msg.event === "SelectDestroyTarget") {
    store.commit({
      type: 'setSelectDestroyTarget',
      data: msg.data,
    });
  }

  if (msg.event === "GetWars") {
    store.commit({
      type: 'setWars',
      data: msg.data,
    });
  }

  if (msg.event === "GetHeadquartersName") {
    store.commit({
      type: 'addHeadquartersName',
      data: msg.data,
    });
  }

  if (msg.event === "GetCorporationLogs") {
    store.commit({
      type: 'setCorporationLogs',
      logs: msg.data,
    });
  }
}
