<template>
  <div class="itemsPools" id="itemsPool" style="background: rgba(0, 0, 0, 0.5)"
       :class="{select: selectProcessRoot}"
  >
    <div class="pollHead">
      <h3>{{ texts['head'][language] }}</h3>
    </div>

    <div class="RecycleSection" v-for="(items, section) in innerItems" v-if="Object.keys(innerItems).length > 0">

      <div class="nameSection">{{ handbook['categories'][language][section].name }}
        <div class="hideArrowSection"></div>
      </div>

      <app-item-cell v-for="item in items"
                     :key="item.slot.number"
                     @click.native="removeItem(item)"
                     v-bind:itemSlot="item.slot"
                     v-bind:size="38"
                     v-bind:few="!item.recycled"/>
    </div>
    <div v-if="Object.keys(innerItems).length === 0" class="help_zone">
      <p>{{ texts['text_1'][language] }}</p>
    </div>
  </div>
</template>

<script>
import ItemCell from '../Inventory/ItemCell';

export default {
  name: "ItemsDropZone",
  mounted() {
    let app = this;

    // $(app.$el).selectable({
    //   filter: '.InventoryCell',
    //   start: function () {
    //     $('.ui-selected').removeClass('ui-selected')
    //   }
    // });

    $(app.$el).droppable({
      drop: function (event, ui) {
        $('.ui-selected').removeClass('ui-selected');
        let draggable = ui.draggable;

        if (draggable.data("slotData") &&
          (draggable.data("slotData").parent === "storage" ||
            draggable.data("slotData").parent === "squadInventory" ||
            draggable.data("slotData").parent.includes("aInv"))) {

          if (draggable.data("selectedItems") !== undefined) {
            app.$store.dispatch("sendSocketData", JSON.stringify({
              service: "lobby",
              data: {
                event: "PlaceItemsToProcessor",
                storage_slots: draggable.data("selectedItems").slotsNumbers,
                item_source: draggable.data("slotData").parent,
              }
            }));
          } else {
            app.$store.dispatch("sendSocketData", JSON.stringify({
              service: "lobby",
              data: {
                event: "PlaceItemToProcessor",
                storage_slot: Number(draggable.data("slotData").number),
                item_source: draggable.data("slotData").parent,
              }
            }));
          }
        }
      }
    });
  },
  methods: {
    removeItem(item) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "RemoveItemsFromProcessor",
          storage_slots: [Number(item.number)],
          item_source: item.source,
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('ProcessorRoot')
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    recycler() {
      return this.$store.getters.getRecyclerState
    },
    innerItems() {
      let sections = {};

      let need_items_slots = this.$store.getters.getRecyclerState.recycle_slots;

      for (let source in need_items_slots) {
        for (let i in need_items_slots[source]) {

          let itemSlot = need_items_slots[source][i];
          itemSlot.number = i;

          if (!sections.hasOwnProperty(itemSlot.slot.type)) {
            sections[itemSlot.slot.type] = [];
          }
          sections[itemSlot.slot.type].push(itemSlot);
        }
      }

      return sections;
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    selectProcessRoot() {
      if (!(this.missions && this.missions.currentAction)) {
        return false;
      }

      return this.missions.currentAction.type_func_monitor === "process_ore";
    },
  },
  components: {
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.help_zone {
  margin-top: 15%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  opacity: 0.8;
  font-size: 11px;
  text-align: center;
}

.select {
  box-shadow: inset 0 0 3px 2px greenyellow;
  animation: selectAnimate 1500ms infinite !important;
}

@keyframes selectAnimate {
  0% {
    box-shadow: inset 0 0 2px 1px greenyellow;
  }
  50% {
    box-shadow: inset 0 0 4px 3px greenyellow;
  }
  100% {
    box-shadow: inset 0 0 2px 1px greenyellow;
  }
}
</style>

<style>

</style>
