var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Обучение: Переработчик")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_process_1', 'png')+ ')',
          width: '254px',
          height: '398px',
          marginLeft: 'calc(50% - 127px)',
          })}),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_process_2', 'gif')+ ')',
          width: '481px',
          height: '421px',
          marginLeft: 'calc(50% - 240px)',
          })})]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Training: Processor")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_process_1', 'png')+ ')',
          width: '254px',
          height: '398px',
          marginLeft: 'calc(50% - 127px)',
          })}),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('education_process_2', 'gif')+ ')',
          width: '481px',
          height: '421px',
          marginLeft: 'calc(50% - 240px)',
          })})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы открыть переработчик надо нажать кнопку "),_c('span',{staticClass:"importantly"},[_vm._v("\"Переработчик\"")]),_vm._v(" в меню базы.\n      "),_c('span',{staticClass:"importantly"},[_vm._v("Переработчик")]),_vm._v(" позволяет перерабатывать руду и разбирать предметы.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Что бы переработать руду надо ее перетащить из инвентаря или склада в левую часть меню. Новые предметы упадут\n      на\n      "),_c('span',{staticClass:"importantly"},[_vm._v("склад")]),_vm._v(" базы.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To open the processor, you need to press the "),_c('span',{staticClass:"importantly"},[_vm._v("\"Processor\"")]),_vm._v(" button in the base\n      menu.\n      The "),_c('span',{staticClass:"importantly"},[_vm._v("Processor")]),_vm._v(" allows for the processing of ore and disassembling items.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("To process ore, you need to drag it from the inventory or warehouse to the left side of the menu. New items\n      will fall\n      onto the "),_c('span',{staticClass:"importantly"},[_vm._v("warehouse")]),_vm._v(" of the base.")])
}]

export { render, staticRenderFns }