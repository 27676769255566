<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Временные структуры</h2>
      <p>В секторе те или иные сооружения, которые синтеты возвели ради собственных целей. Как правило, подобные
        постройки
        могут подвергаться разрушению, если они препятствуют деятельности игрока, либо же представляют для того реальную
        угрозу.</p>

      <p>Они выполняют разные
        <router-link to="structure_types">функции</router-link>
        , например:
      </p>

      <ul>
        <li><p>Турели - автоматизированные орудия обороны периметра, которые атакуют любую обнаруженную цель в
          зависимости
          от своей программы идентификации.</p>
          <p>Турели имеют множества вариаций, разнясь как от мощности, так и дальности стрельбы.</p>
        </li>
        <li><p>
          Щиты - мобильные защитные поля, которые после своей установки создают энергетический купол, поглощающий
          большую
          часть направленных атак.
        </p>
        </li>
        <li><p>Добывающие установки - автоматизированные механизмы горнодобывающей сферы. Основная деятельность -
          выработка ресурсов из
          близлежащих пластов земли. </p></li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('structures_1', 'png')+ ')',
        width: '300px',
        height: '100px',
        marginLeft: 'calc(50% - 150px)',
        }"/>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Temporary Structures</h2>
      <p>In the sector, there are various structures that synthetics have erected for their own purposes. Typically,
        such buildings can be destroyed if they hinder the player's activity or pose a real threat to them.</p>

      <p>They perform different
        <router-link to="structure_types">functions</router-link>
        , for example:
      </p>

      <ul>
        <li><p>Turrets - automated perimeter defense guns that attack any detected target depending on their
          identification program.</p>
          <p>Turrets have many variations, differing in both power and firing range.</p>
        </li>
        <li><p>Shields - mobile protective fields that, once installed, create an energy dome that absorbs most of the
          directed attacks.</p>
        </li>
        <li><p>Mining installations - automated mechanisms of the mining industry. The main activity is the extraction
          of resources from nearby strata of the earth.</p></li>
      </ul>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('structures_1', 'png')+ ')',
        width: '300px',
        height: '100px',
        marginLeft: 'calc(50% - 150px)',
      }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "Structures",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
