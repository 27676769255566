<template>
  <div id="SquadHead">

    <div id="squadName">
      <span v-if="inventory.stateMS">{{ texts['text_16'][language] }} </span>
      <span>{{ inventory.squadName }}</span>
    </div>

    <div class="deleteButton cursor_hover" v-if="inventory.stateMS"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @click="dialogRemoveSquad"/>
    <div class="renameButton cursor_hover" v-if="inventory.stateMS"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @click="dialogRenameSquad"/>
    <div class="configButton cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @click="openService('BodyConfig', {save: true})"/>
  </div>
</template>

<script>
import Control from '../Window/Control';

export default {
  name: "HangarHead",
  components: {
    AppControl: Control,
  },
  data() {
    return {}
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    dialogRemoveSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.$parent.dialogRemove = !this.$parent.dialogRemove
    },
    dialogRenameSquad() {
      this.playSound('button_press.mp3', 0.3)
      this.$parent.dialogRename = !this.$parent.dialogRename
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
  },
  computed: {
    inventory() {
      return this.$store.getters.getInventory
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
  },
}
</script>

<style scoped>
#SquadHead {
  margin: 0 0 4px;
  background: #dd7034;
  color: hsla(0, 0%, 100%, .8);
  border-radius: 4px 25px 4px 4px;
  font-size: 13px;
  line-height: 19px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px #000;
  font-weight: 700;
  box-shadow: 0 0 2px #000;
  text-indent: 5px;
  position: relative;
  clear: both;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#SquadHead #squadName {
  margin: 0 0 7px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 18px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  padding-left: 10px;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#SquadHead .deleteButton, #SquadHead .renameButton, #SquadHead .configButton {
  height: 17px;
  width: 20px;
  background: url('../../assets/icons/remove_squad.png') left center / contain no-repeat, linear-gradient(1deg, rgb(255, 105, 0), rgb(255, 0, 28) 6px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  float: right;
  margin-right: 0;
  margin-top: 0;
  border-radius: 5px 100% 3px 5px;
  transition: 0.1s;
}

#SquadHead .renameButton, #SquadHead .configButton {
  width: 17px;
  background: url(../../assets/indexImg/rename.png) left center / contain no-repeat, linear-gradient(1deg, rgb(255, 200, 0), rgb(255, 161, 0) 6px);
  border-radius: 5px;
  margin-right: 3px;
}

#SquadHead .deleteButton:active {
  transform: scale(0.9);
}

#SquadHead .deleteButton:hover {
  background: url('../../assets/icons/remove_squad.png') left center / contain no-repeat, linear-gradient(1deg, rgb(255, 207, 0), rgb(255, 150, 1) 6px);
}

#SquadHead .renameButton:active {
  transform: scale(0.9);
}

#SquadHead .renameButton:hover {
  background: url(../../assets/indexImg/rename.png) left center / contain no-repeat, linear-gradient(1deg, rgb(255, 222, 0), rgb(254, 255, 8) 6px);
}

#renameSquadDialog {
  height: 47px;
}

#SquadHead .configButton {
  background: url(../../assets/indexImg/save.png) center / 75% no-repeat, linear-gradient(1deg, rgb(255, 200, 0), rgb(255, 161, 0) 6px);
}

#SquadHead .configButton:active {
  transform: scale(0.9);
}

#SquadHead .configButton:hover {
  background: url(../../assets/indexImg/save.png) center / 75% no-repeat, linear-gradient(1deg, rgb(255, 222, 0), rgb(254, 255, 8) 6px);
}
</style>
