var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.lang === 'RU')?[_c('h2',{staticClass:"head",attrs:{"id":"bodies"}},[_vm._v("Невосполнимый урон")]),_vm._v(" "),_c('p',[_vm._v("Четверть урона, который получает транспорт, уходит в категорию «невосполнимый урон». Это означает, что этот\n      урон уже не может быть восстановлен. Чем больше транспорт получает урон, тем меньше становится его максимальное\n      здоровье. Дебаф растёт вплоть до 66% от всего здоровья.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('permanent_damage_1', 'gif')+ ')',
            width: '193px',
            height: '73px',
            marginLeft: 'calc(50% - 96px)'
          })})]:_vm._e(),_vm._v(" "),(_vm.lang === 'EN')?[_c('h2',{staticClass:"head",attrs:{"id":"bodies"}},[_vm._v("Permanent Damage")]),_vm._v(" "),_c('p',[_vm._v("A quarter of the damage received by the transport goes into the «permanent damage» category. This means that\n      this damage cannot be restored. The more damage the transport receives, the less its maximum health becomes. The\n      debuff increases up to 66% of the total health.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
            backgroundImage: 'url(' + _vm.$parent.getUrlImg('permanent_damage_1', 'gif')+ ')',
            width: '193px',
            height: '73px',
            marginLeft: 'calc(50% - 96px)'
          })})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }