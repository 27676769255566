<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Обучение: выход с базы</h2>

      <p>Что бы добраться до добывающей установки сначала надо покинуть текущую базу. Для этого надо нажать пункт меню
        <span class="importantly">"Выйти с базы"</span> а затем кнопку <span class="importantly">"Покинуть базу"</span>
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_1', 'png')+ ')',
            width: '450px',
            height: '141px',
            marginLeft: 'calc(50% - 225px)',
            }"/>

      <p><span class="importantly">P.S.</span> Управление транспортом осуществляется с помощью клавиш <span
        class="importantly">WASD</span> (если вы играете за зеленых то еще и <span class="importantly">мышкой</span>)
      </p>
      <p><span class="importantly">P.S.2 Shift</span> - ускорение.</p>
      <p><span class="importantly">P.S.3 Space</span> - тормоз.</p>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Exiting the Base</h2>

      <p>To reach the mining installation, you must first leave the current base. To do this, press the menu item
        <span class="importantly">"Exit the Base"</span> and then the button <span
          class="importantly">"Leave the Base"</span>.
      </p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_exit_base_1', 'png')+ ')',
            width: '450px',
            height: '141px',
            marginLeft: 'calc(50% - 225px)',
            }"/>

      <p><span class="importantly">P.S.</span> Vehicle control is carried out using the <span
        class="importantly">WASD</span> keys (if you play for the greens, then also with the <span class="importantly">mouse</span>).
      </p>
      <p><span class="importantly">P.S.2 Shift</span> - acceleration.</p>
      <p><span class="importantly">P.S.3 Space</span> - brake.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationExitBase",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
