var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Органика")]),_vm._v(" "),_c('p',[_vm._v("Органика перерабатывается в углерод, который необходим для создания некоторых компонентов. Также органика\n        может использоваться в чистом виде для создания топлива и бустеров.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_2', 'png')+ ')',
    width: '353px',
    height: '126px',
    marginLeft: 'calc(50% - 176px)',
    })}),_vm._v(" "),_c('p',[_vm._v("Добыча органики осуществляется посредством переработки растений специальным устройством.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_1', 'gif')+ ')',
    width: '400px',
    height: '290px',
    marginLeft: 'calc(50% - 200px)',
    })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Другие ресурсы")]),_vm._v(" "),_c('p',[_vm._v("Помимо органики, существуют еще два вида ресурсов, которые можно собрать только манипулятором. Они\n        используются для создания топлива и бустеров.")]),_vm._v(" "),_c('p',[_vm._v("Эти ресурсы выпадают из растений, если их уничтожить.")]),_vm._v(" "),_c('p',[_vm._v("Смоляная кислота")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_3', 'png')+ ')',
    width: '400px',
    height: '178px',
    marginLeft: 'calc(50% - 200px)',
    })}),_vm._v(" "),_c('p',[_vm._v("Цветок ядрового куста")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
    backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_4', 'png')+ ')',
    width: '400px',
    height: '150px',
    marginLeft: 'calc(50% - 200px)',
    })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Organics")]),_vm._v(" "),_c('p',[_vm._v("Organics are processed into carbon, which is necessary for creating certain components. Additionally,\n        organics can be used in their pure form to create fuel and boosters.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_2', 'png')+ ')',
        width: '353px',
        height: '126px',
        marginLeft: 'calc(50% - 176px)',
        })}),_vm._v(" "),_c('p',[_vm._v("Organics are harvested by processing plants with a special device.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_1', 'gif')+ ')',
        width: '400px',
        height: '290px',
        marginLeft: 'calc(50% - 200px)',
        })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Other Resources")]),_vm._v(" "),_c('p',[_vm._v("Besides organics, there are two other types of resources that can only be collected with a manipulator. They\n        are used to create fuel and boosters.")]),_vm._v(" "),_c('p',[_vm._v("These resources drop from plants when they are destroyed.")]),_vm._v(" "),_c('p',[_vm._v("Resin Acid")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_3', 'png')+ ')',
        width: '400px',
        height: '178px',
        marginLeft: 'calc(50% - 200px)',
        })}),_vm._v(" "),_c('p',[_vm._v("Core Bush Flower")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('organic_4', 'png')+ ')',
        width: '400px',
        height: '150px',
        marginLeft: 'calc(50% - 200px)',
        })})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }