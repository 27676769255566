<template>
  <div class="FriendList" id="FriendList" ref="FriendList" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'FriendList'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"/>

    <div class="tabs">
      <div v-bind:class="{active: tab === 'friend'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('friend')">
        {{ texts['tab_1'][language] }}
      </div>

      <div v-bind:class="{active: tab === 'contacts'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('contacts')">
        {{ texts['tab_2'][language] }}
      </div>

      <div v-bind:class="{active: tab === 'block'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.2)"
           @click="changeTab('block')">
        {{ texts['tab_3'][language] }}
      </div>
    </div>

    <template v-if="tab === 'friend'">
      <div class="search">
        <input v-model="newFriendName" type="text" class="cursor_hover"
               :placeholder="texts['placeholder_1'][language]"/>
        <input type="button" class="cursor_hover"
               :value="texts['button_1'][language]"
               @mouseover="playSound('select_sound.mp3', 0.2)"
               @click="addContact('AddFriend')">
      </div>

      <div class="friends">

        <app-user-line v-for="friend in friends.online"
                       :key="friend.id"
                       v-bind:user="friend"
                       v-bind:parent="'friendList'"
                       v-bind:buttonExit="true"
                       v-bind:meta="{id: friend.id}"
                       v-bind:exitFunc="removeContact"
        />

        <app-user-line v-for="friend in friends.offline"
                       :key="friend.id"
                       v-bind:user="friend"
                       v-bind:parent="'friendList'"
                       v-bind:noActive="true"
                       v-bind:buttonExit="true"
                       v-bind:meta="{id: friend.id}"
                       v-bind:exitFunc="removeContact"
        />

      </div>
    </template>

    <template v-if="tab === 'contacts'">

      <div class="search">
        <input v-model="newFriendName" type="text" class="cursor_hover"
               :placeholder="texts['placeholder_1'][language]"/>

        <input type="button" class="cursor_hover"
               :value="texts['button_2'][language]"
               @mouseover="playSound('select_sound.mp3', 0.2)"
               @click="addContact('AddContact')">
      </div>

      <div class="friends">
        <app-user-line v-for="c in contacts"
                       :key="c.id"
                       v-bind:user="c"
                       v-bind:parent="'friendList'"
                       v-bind:buttonExit="true"
                       v-bind:meta="{id: c.id}"
                       v-bind:exitFunc="removeContact"
        />
      </div>
    </template>

    <template v-if="tab === 'block'">
      <div class="blocks">
        <app-user-line v-for="block in blocks"
                       :key="block.id"
                       v-bind:user="block"
                       v-bind:parent="'friendList'"
                       v-bind:buttonExit="true"
                       v-bind:meta="{id: block.id}"
                       v-bind:exitFunc="removeContact"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import UserLine from '../Chat/UserLine';

export default {
  name: "FriendList",
  data() {
    return {
      newFriendName: null,
      updater: null,
      tab: "friend",
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater)
  },
  mounted() {
    let app = this;

    app.updater = setInterval(function () {
      app.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetContacts",
        }
      }));
    }, 1000)
  },
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)
      this.tab = tab;
    },
    addContact(e) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: e,
          data: this.newFriendName,
        }
      }));
    },
    removeContact(id) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "RemoveContact",
          id: Number(id),
        }
      }));
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('FriendList')
    },
    allContacts() {
      return this.$store.getters.getChatState.friends;
    },
    friends() {
      let onlineUsers = [];
      let offlineUsers = [];

      for (let p of this.allContacts) {
        if (p.type === "friend") {
          if (p.online) {
            onlineUsers.push({id: p.player_id, login: p.player_name})
          } else {
            offlineUsers.push({id: p.player_id, login: p.player_name})
          }
        }
      }

      return {online: onlineUsers, offline: offlineUsers};
    },
    blocks() {
      let blockUsers = []

      for (let p of this.allContacts) {
        if (p.type === "block") {
          blockUsers.push({id: p.player_id, login: p.player_name})
        }
      }

      return blockUsers
    },
    contacts() {
      let contacts = []

      for (let p of this.allContacts) {
        if (p.type === "contact") {
          contacts.push({id: p.player_id, login: p.player_name})
        }
      }

      return contacts
    },
  },
  components: {
    AppControl: Control,
    AppUserLine: UserLine,
  }
}
</script>

<style scoped>
.FriendList {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  min-height: 20px;
  width: 210px;
  z-index: 950;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  height: 207px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: auto;
}

.search {
  float: left;
  width: 100%;
}

.search input {
  width: 100%;
  margin-bottom: 4px;
}

.search input[type="button"] {
  display: block;
  width: calc(100% - 6px);
  pointer-events: auto;
  font-size: 12px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-left: 4px;
}

.search input[type="button"]:hover {
  background: rgba(255, 129, 0, 1);
}

.search input[type="button"]:active {
  transform: scale(0.98);
}

.search input[type=button]:active {
  transform: scale(0.97);
}

.search input[type=text] {
  width: calc(100% - 14px);
  margin-left: 3px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  color: whitesmoke;
}

.friends, .blocks {
  width: 100%;
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: calc(100% - 63px);
  background: rgba(137, 150, 156, 0.3);
}

.blocks {
  height: calc(100% - 19px);
}

.tabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 4px 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tabs div:last-child {
  /*border: 0;*/
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42) !important;
}

.tabs div:hover {
  color: #fff200 !important;
}
</style>
