var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Захват сектора")]),_vm._v(" "),_c('p',[_vm._v("Захват сектора - это способ доказать свою силу и влияние на планете. Это также способ получить доступ к\n        ресурсам и аномалиям, которые находятся на секторе. Для того, чтобы захватить сектор, вам нужно контролировать\n        большинство ключевых точек на карте. Это могут быть стратегические объекты, такие как базы, мосты, заводы и\n        т.д.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_1', 'png')+ ')',
      width: '209px',
      height: '216px',
      marginLeft: 'calc(50% - 104px)',
    })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Каждый сектор имеет определенное количество очков захвата, которые показывают, насколько он подчинен той или\n        иной фракции. Когда все очки захвата принадлежат одной фракции, сектор считается захваченным этой фракцией.\n        Когда все очки захвата не принадлежат никому, сектор считается свободным.")]),_vm._v(" "),_c('p',[_vm._v("Одна захваченная ключевая точка - приносит 1 очко захвата владельцу точки.")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('p',[_vm._v("Если 3 фракции заняли по 1 точке, то все получаю 0 очков. Каждая фракция забрала по 1 очку из общего пула, но\n        т.к. свободных очков нет, то они отняли друг у друга и в итоге у каждого +0")]),_vm._v(" "),_c('p',[_vm._v("Если 1 фракция захватила все точки то сектор будет захвачен за 5 минут (N / 3 очка в секунду).\n        Необязательно ждать в секторе если вы уверены что на вас не нападут.")]),_vm._v(" "),_c('p',[_vm._v("Фракция АПД не захватывает сектора но делает их нейтральными.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_2', 'png')+ ')',
      width: '328px',
      height: '66px',
      marginLeft: 'calc(50% - 164px)',
    })}),_vm._v(" "),_c('p',[_vm._v("Справа от шкалы захвата сектора вы можете видеть текущую фазу войны. Слева от шкалы вы можете видеть текущего\n        владельца сектора.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
      backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_3', 'png')+ ')',
      width: '323px',
      height: '63px',
      marginLeft: 'calc(50% - 161px)',
    })})])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('div',{staticClass:"content_section"},[_c('h2',{staticClass:"head"},[_vm._v("Sector Capture")]),_vm._v(" "),_c('p',[_vm._v("Capturing a sector is a way to prove your strength and influence on the planet. It is also a way to gain\n        access to resources and anomalies located within the sector. To capture a sector, you need to control the\n        majority of key points on the map. These can be strategic objects such as bases, bridges, factories, etc.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_1', 'png')+ ')',
        width: '209px',
        height: '216px',
        marginLeft: 'calc(50% - 104px)',
      })})]),_vm._v(" "),_c('div',{staticClass:"content_section"},[_c('p',[_vm._v("Each sector has a certain number of capture points that show how much it is under the control of a particular\n        faction. When all capture points belong to one faction, the sector is considered captured by that faction.\n        When all capture points belong to no one, the sector is considered free.")]),_vm._v(" "),_c('p',[_vm._v("One captured key point brings 1 capture point to the owner of the point.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("If 3 factions occupy 1 point each, then all get 0 points. Each faction took 1 point from the common pool, but\n        since there are no free points, they took from each other and in the end, each got +0")]),_vm._v(" "),_c('p',[_vm._v("If 1 faction captures all points then the sector will be captured in 5 minutes (N / 3 points per second). It\n        is not necessary to wait in the sector if you are sure that you will not be attacked.")]),_vm._v(" "),_c('p',[_vm._v("The APD faction does not capture sectors but makes them neutral.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_2', 'png')+ ')',
        width: '328px',
        height: '66px',
        marginLeft: 'calc(50% - 164px)',
      })}),_vm._v(" "),_c('p',[_vm._v("To the right of the sector capture scale, you can see the current phase of the war. To the left of the scale,\n        you can see the current owner of the sector.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
        backgroundImage: 'url(' + _vm.$parent.getUrlImg('capture_sector_3', 'png')+ ')',
        width: '323px',
        height: '63px',
        marginLeft: 'calc(50% - 161px)',
      })})])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Одна не захваченная ключевая точка - отнимает по 1му очку у "),_c('span',{staticClass:"importantly"},[_vm._v("каждой")]),_vm._v(" фракции.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("One uncaptured key point takes away 1 point from "),_c('span',{staticClass:"importantly"},[_vm._v("each")]),_vm._v(" faction.")])
}]

export { render, staticRenderFns }