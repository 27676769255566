<template>
  <div class="notify_wrapper">
    <div class="missionNotify" v-if="notify.name === 'craft'">

      <div class="head">
        <div class="notify_time">{{ getDateTime(notify.time) }}</div>
        <div class="head_title">{{ texts['text_10'][language] }}</div>
      </div>

      <p>
        {{ texts['on_base'][language] }} <span class="importantly">{{ getBaseName(notify.base_id) }}</span>
        ({{ texts['sector'][language] }} <span class="importantly">{{ getSectorName(notify.map_id) }}</span>)
        {{ texts['text_11'][language] }}
      </p>

      <div style="float: left">
        <app-item-cell
          v-bind:itemSlot="notify.item"
          v-bind:size="35"
          v-bind:noShowName="true"
        />

        <span class="importantly" style="margin-top: 3px">{{ getItemName(notify.item) }} </span>
      </div>

      <p style="color: wheat; text-indent: 0; margin-bottom: 0">{{ texts['text_12'][language] }}</p>
    </div>

    <div class="missionNotify" v-if="notify.name === 'sell' || notify.name === 'buy'">

      <div class="head">
        <div class="notify_time">{{ getDateTime(notify.time) }}</div>
        <div class="head_title" v-if="notify.name === 'sell'">{{ texts['text_13'][language] }}</div>
        <div class="head_title" v-if="notify.name === 'buy'">{{ texts['text_14'][language] }}</div>
      </div>

      <p>
        {{ texts['on_base'][language] }} <span class="importantly">{{ getBaseName(notify.base_id) }}</span>
        ({{ texts['sector'][language] }} <span class="importantly">{{ getSectorName(notify.map_id) }}</span>)

        <template v-if="notify.name === 'sell'">{{ texts['text_15'][language] }}</template>
        <template v-if="notify.name === 'buy'">{{ texts['text_16'][language] }}</template>
      </p>

      <div style="float: left">
        <app-item-cell
          v-bind:itemSlot="notify.item"
          v-bind:size="35"
          v-bind:noShowName="true"
        />

        <span class="importantly" style="margin-top: 3px">{{ getItemName(notify.item) }} </span>
      </div>

      <p v-if="notify.name === 'sell'" style="margin-bottom: 0; float: left">
        {{ texts['text_17'][language] }} <span class="importantly">{{ (notify.price / 100).toFixed(2) }}</span>
        <span class="cr">cr</span>.
      </p>

      <p v-if="notify.name === 'buy'" style="color: wheat; text-indent: 0; margin-bottom: 0">
        {{ texts['text_18'][language] }}</p>
    </div>

    <div class="missionNotify" v-if="notify.event === 'npc_dialog'">

      <div class="head">
        <div class="notify_time">{{ getDateTime(notify.time) }}</div>
        <div class="head_title">{{ texts['dialog'][language] }}</div>
      </div>

      <p style="text-indent: 0; text-align: left; margin-bottom: 0">
        {{ texts['from'][language] }}: <span class="button_to_unit cursor_hover" @click="focusUnit(notify.from)">
        {{ notify.from_user_name }} </span> <br>
        {{ texts['to'][language] }}: <span class="button_to_unit cursor_hover" @click="focusUnit(notify.to)">
        {{ notify.to_user_name }} </span>
      </p>

      <p v-for="msg in notify.texts" style="margin: 0" v-html="' - ' + msg[language]"/>

      <div v-if="notify.need_answer && notify.to_user === this.currentPlayer.id" class="answer_block">
        <div class="answer cursor_hover" v-for="answer in notify.answers"
             @click="npcDialogAnswer(notify.uuid, answer[language].id)">
          {{ answer[language].text }}
        </div>
      </div>
    </div>

    <div v-if="notify.name === 'bot_request'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['dialog'][language] }}</div>
        </div>

        <p style="text-indent: 0; text-align: left; margin-bottom: 0">
          {{ texts['from'][language] }}: <span class="button_to_unit cursor_hover"
                                               @click="focusUnit(notify.from)"> {{ notify.from_user_name }} </span> <br>
          {{ texts['to'][language] }}: <span class="button_to_unit cursor_hover"
                                             @click="focusUnit(notify.to)"> {{ notify.to_user_name }} </span>
        </p>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>
      </div>
    </div>

    <div v-if="notify.name === 'bot_mission_request'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['dialog'][language] }}</div>
        </div>

        <p style="text-indent: 0; text-align: left; margin-bottom: 0">
          {{ texts['from'][language] }}: <span class="button_to_unit cursor_hover"
                                               @click="focusUnit(notify.from)"> {{ notify.from_user_name }} </span> <br>
          {{ texts['to'][language] }}: <span class="button_to_unit cursor_hover"
                                             @click="focusUnit(notify.to)"> {{ notify.to_user_name }} </span>
        </p>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div class="answer_block">
          <div class="answer cursor_hover" v-for="answer in notify.answers"
               @click="answerInvitation(notify.data, notify.uuid, answer[language].id)">
            {{ answer[language].text }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="notify.name === 'mission_failed'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['attention'][language] }}</div>
        </div>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div class="answer_block">
          <div class="answer cursor_hover" @click="deleteNotify(notify.uuid)">
            {{ texts['close'][language] }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="notify.event === 'trade' || notify.event === 'attention' || notify.event === 'news' ||
    notify.event === 'expedition' || notify.event === 'anomalies' || notify.event === 'fraction_war' ||
    notify.event === 'corporations'">

      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title"> {{ texts['attention'][language] }}</div>
        </div>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div v-if="notify.event === 'attention'" class="answer_block">
          <div class="answer cursor_hover" @click="deleteNotify(notify.uuid)">
            {{ texts['close'][language] }}
          </div>
        </div>

      </div>
    </div>

    <div v-if="notify.name === 'invite'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['request'][language] }}</div>
        </div>

        <p style="text-indent: 0; text-align: left; margin-bottom: 0">
          {{ texts['from'][language] }}: <span class="button_to_unit"> {{ notify.from_user_name }} </span> <br>
          {{ texts['to'][language] }}: <span class="button_to_unit"> {{ notify.to_user_name }} </span>
        </p>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div class="answer_block">
          <div class="answer cursor_hover" v-for="answer in notify.answers"
               @click="answerInvitation(notify.data, notify.uuid, answer[language].id)">
            {{ answer[language].text }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="notify.event === 'translation'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['transfer'][language] }}</div>
        </div>

        <p style="text-indent: 0; text-align: left; margin-bottom: 0">
          {{ texts['from'][language] }}: <span class="button_to_unit"> {{ notify.from_user_name }} </span> <br>
          {{ texts['to'][language] }}: <span class="button_to_unit"> {{ notify.to_user_name }} </span>
        </p>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div class="answer_block">
          <div class="answer cursor_hover" @click="deleteNotify(notify.uuid)">
            {{ texts['close'][language] }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="notify.event === 'action_end'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['attention'][language] }}</div>
        </div>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>

        <div class="answer_block">
          <div class="answer cursor_hover" @click="deleteNotify(notify.uuid)">
            {{ texts['close'][language] }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="notify.event === 'change_relation'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['attention'][language] }}</div>
        </div>

        <p style="margin: 0;"
           v-html="texts['relation_text'][language].replace('%fraction%',notify.name)">
        </p>

        <p style="margin: 0;">
          {{ notify.data > 0 ? '+' + notify.data : notify.data }}%
        </p>

      </div>
    </div>

    <div v-if="notify.event === 'kill_fauna'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">...</div>
        </div>

        <p style="margin: 0; font-weight: bold">
          ТА ЗА ШО?
        </p>
      </div>
    </div>

    <div v-if="notify.event === 'corporation'">
      <div class="missionNotify">

        <div class="head">
          <div class="notify_time">{{ getDateTime(notify.time) }}</div>
          <div class="head_title">{{ texts['attention'][language] }}</div>
        </div>

        <p v-html="notify.text[language]" style="text-indent: 0; text-align: left; margin-top: 0"/>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCell from '../Inventory/ItemCell';

export default {
  name: "Notify",
  props: ["notify"],
  data() {
    return {
      timeOut: null,
    }
  },
  mounted() {
    let app = this;
    if (app.$props.notify.time_out > 0) {
      app.timeOut = setTimeout(function () {
        app.deleteNotify(app.$props.notify.uuid)
      }, app.$props.notify.time_out * 1000)
    }
  },
  destroyed() {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  methods: {
    focusUnit(id) {
      // TODO из за друтского условия в функции FocusUnit(id), нельзя следовать за другими юнитами
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    getDateTime(time) {
      let now = new Date(time * 1000);

      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hour = now.getHours();
      let minute = now.getMinutes();
      let second = now.getSeconds();

      if (month.toString().length === 1) {
        month = '0' + month;
      }
      if (day.toString().length === 1) {
        day = '0' + day;
      }
      if (hour.toString().length === 1) {
        hour = '0' + hour;
      }
      if (minute.toString().length === 1) {
        minute = '0' + minute;
      }
      if (second.toString().length === 1) {
        second = '0' + second;
      }

      return year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
    },
    skillUp(notify) {
      if (this.language === 'RU') {
        return `Уровень <span class="importantly"> ${notify.text[this.language]} </span> повышен до
                <span class="importantly"> ${notify.count} </span> уровня.`
      } else {
        return `Lvl up <span class="importantly"> ${notify.text[this.language]} </span> to
                <span class="importantly"> ${notify.count} </span> level.`
      }
    },
    npcDialogAnswer(uuid, answerID) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "global",
        data: {
          event: "npc_request_answer",
          uuid: uuid,
          id: Number(answerID),
        }
      }));

      this.deleteNotify(uuid)
    },
    deleteNotify(uuid) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "DeleteNotify",
          data: uuid,
        }
      }));
    },
    answerInvitation(data, uuid, id) {

      if (id === -1) {
        this.deleteNotify(uuid)
        return
      }

      this.playSound('button_press.mp3', 0.3)

      let event = "AcceptInvitation"
      if (id !== 1) {
        event = "RejectInvitation"
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: event,
          data: data,
        }
      }));

      this.deleteNotify(uuid)
    },
    getBaseName(id) {
      for (let i in this.handbook.base[this.language]) {
        if (this.handbook.base[this.language][i].id === Number(id)) {
          return this.handbook.base[this.language][i].name
        }
      }

      return "%UNKNOWN_BASE%"
    },
    getSectorName(id) {

      for (let i in this.handbook.sector[this.language]) {
        if (this.handbook.sector[this.language][i].id === Number(id)) {
          return this.handbook.sector[this.language][i].name
        }
      }

      return "%UNKNOWN_SECTOR%"
    },
    getItemName(slot) {
      let name = ''
      if (slot.type === "blueprints") {
        if (slot.item.in_map) {
          name = this.handbook['structure'][this.language][slot.item.name.split('_bp')[0]].name
        } else {
          name = this.handbook[slot.item.item_type][this.language][slot.item.item_name].name
        }
      } else if (slot.type === "credits") {
        name = this.language === "RU" ? "Кредиты" : "Cr."
      } else {
        name = this.handbook[slot.type][this.language][slot.item.name].name
      }

      return name
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('News')
    },
    user() {
      return this.$store.getters.getPlayer
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
  },
  components: {
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.notify_wrapper {
  position: absolute;
  bottom: 17px;
  left: 0;
  background-color: rgba(7, 41, 60, 0.8);
  border-radius: 5px 0 0 5px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  overflow-y: scroll;
  z-index: 1;
  color: white;
  font-size: 12px;
  padding: 5px;
  overflow-x: hidden;
  min-height: 10px;
  max-height: 190px;
  width: 250px;
  box-shadow: 0 0 2px black;
  display: flex;
  flex-direction: column;
}

.notify_wrapper h3 {
  margin: 0;
  text-align: left;
  padding-left: 5px;
  color: yellow;
}

.notify_wrapper h4 {
  margin: 0;
  color: #78ff00;
  font-size: 11px;
  text-align: left;
  padding-left: 5px;
}

.notify_wrapper p {
  text-align: justify;
  padding: 5px;
  margin: 5px 0;
  text-indent: 1.5em;
  font-size: 11px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  float: left;
  width: 100%;
}

.notifyParagraph {
  text-align: justify;
  padding: 5px;
  color: #ff8100;
  margin: 5px 0;
  text-indent: 1.5em;
}

.notify_wrapper a:visited {
  text-decoration: none;
  color: aqua;
}

.notify_wrapper a:hover {
  text-decoration: none;
  color: #0093ff;
}

.notifyIconItem {
  height: 35px;
  width: 35px;
  position: relative;
  margin: 0 4px;
  float: left;
}

.head {
  clear: both;
  margin-bottom: 0;
  margin-top: -3px;
  margin-left: -2px;
}

.notify_time {
  font-size: 8px;
  font-family: 'Audiowide', cursive;
  color: #adacac;
  float: left;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.head_title {
  float: left;
  color: #f1bd00;
  clear: both;
  font-size: 11px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.answer {
  display: block;
  width: 100%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 10ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 18px;
  box-shadow: 0 0 2px #000;
  text-shadow: 1px 1px 1px black;
  float: left;
}

.answer:hover {
  background: rgba(255, 129, 0, 1);
}

.answer:active {
  transform: scale(0.98);
}

.button_to_unit {
  margin: 2px auto 0;
  padding: 1px 7px;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button_to_unit:active {
  transform: scale(0.98);
}

.button_to_unit:hover {
  background: rgba(255, 129, 0, 1);
}

.item_name {

}
</style>
<style>
.missionNotify .InventoryCell {
  margin-top: -1px !important;
}

.missionNotify .QuantityItems {
  bottom: 0 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
</style>
