<template>
  <div class="BodyConfig" id="BodyConfig">

    <div class="error_message" :class="{error_message_visible: applySquadConfigFail}">
      <div style="margin-top: 14%">Не удалось!</div>
    </div>

    <div class="leftPanel">
      <input type="text"
             v-if="inventory.unit && inventory.unit.body"
             v-model="newSaveConfig"
             @click="selectIDConfig(false)"
             class="NameInput cursor_hover">
      <div class="configName" v-for="c in configs" @click="selectIDConfig(c.id)">{{ c.name }}</div>
    </div>

    <div class="rightPanel">
      <div v-if="selectConfig">
        <div class="headRow">
          <h3 class="head">Корпус и трюм</h3>
          <div class="body" :class="{notFound: checkItem('body', selectConfig.body.id)}">
            <app-background-item-cell v-bind:slotItem="{type: 'body', item: selectConfig.body}"/>
          </div>

          <div class="inventory">
            <app-item-cell v-for="slot in selectConfig.inventory"
                           :class="{notFound: checkItem(slot.type, slot.item_id)}"
                           v-bind:size="36"
                           v-bind:itemSlot="slot"/>
          </div>
        </div>

        <div class="equipRow">
          <h3 class="head">Снаряжение</h3>
          <div class="equipType III">
            <div class="equipTypeIcon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/equip_type_3.png').default + ')'}"/>

            <template v-for="slot in selectConfig.weapon_slots">

              <app-item-cell
                class="bodyWeapon"
                :class="{notFound: slot.weapon ? checkItem('weapon', slot.weapon.id) : false}"
                v-bind:itemSlot="{type: 'weapon', item: slot.weapon}"
                v-bind:equipProps="{type: 'weapon', data: slot}"
                v-bind:cursor_pointer="true"
                v-bind:size="32"
                v-bind:type="slot.weapon ? null : 6"
              />

              <app-item-cell
                class="bodyAmmo"
                :class="{notFound: slot.ammo ? checkItem('ammo', slot.ammo.id) : false}"
                v-bind:itemSlot="{type: 'ammo', quantity: slot.ammo_quantity, item: slot.ammo}"
                v-bind:equipProps="{type: 'ammo', data: slot}"
                v-bind:cursor_pointer="true"
                v-bind:size="32"/>

            </template>

            <app-item-cell v-for="slot in selectConfig.equip_slots"
                           :key="slot.number"
                           v-if="slot.type_slot === 3"
                           v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                           v-bind:class="{mining: slot.mining, notFound: slot.equip ? checkItem('equip', slot.equip.id) : false}"
                           v-bind:equipProps="{type: 'equip', data: slot}"
                           v-bind:type="slot.equip ? null : 3"
                           v-bind:cursor_pointer="true"
                           v-bind:size="32"/>
          </div>

          <div class="equipType II">
            <div class="equipTypeIcon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/equip_type_2.png').default + ')'}"/>

            <app-item-cell v-for="slot in selectConfig.equip_slots"
                           :key="slot.number"
                           v-if="slot.type_slot === 2"
                           v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                           v-bind:class="{mining: slot.mining, notFound: slot.equip ? checkItem('equip', slot.equip.id) : false}"
                           v-bind:equipProps="{type: 'equip', data: slot}"
                           v-bind:type="slot.equip ? null : 2"
                           v-bind:cursor_pointer="true"
                           v-bind:size="32"/>
          </div>

          <div class="equipType I">
            <div class="equipTypeIcon"
                 :style="{backgroundImage: 'url(' + require('../../assets/icons/equip_type_1.png').default + ')'}"/>

            <app-item-cell v-for="slot in selectConfig.equip_slots"
                           :key="slot.number"
                           v-if="slot.type_slot === 1"
                           v-bind:itemSlot="{type: 'equip', item: slot.equip}"
                           v-bind:class="{mining: slot.mining, notFound: slot.equip ? checkItem('equip', slot.equip.id) : false}"
                           v-bind:equipProps="{type: 'equip', data: slot}"
                           v-bind:type="slot.equip ? null : 1"
                           v-bind:cursor_pointer="true"
                           v-bind:size="32"/>
          </div>
        </div>

        <div class="barRow">
          <h3 class="head">Панель снаряжения</h3>

          <template v-for="i in 9">
            <div class="emptyCell" v-if="!selectConfig.equip_panel[i]"/>

            <template v-if="selectConfig.equip_panel[i]">
              <div class="manipulator" v-if="selectConfig.equip_panel[i].number === 1"/>

              <template v-if="selectConfig.equip_panel[i].number !== 1">
                <div class="emptyCell" v-if="!getSlotForEquipPanel(selectConfig.equip_panel[i])"/>

                <app-item-cell v-if="getSlotForEquipPanel(selectConfig.equip_panel[i])"
                               :key="selectConfig.equip_panel[i].number"
                               v-bind:itemSlot="getSlotForEquipPanel(selectConfig.equip_panel[i])"
                               v-bind:cursor_pointer="false"
                               v-bind:size="35"/>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>

    <div class="buttonRow">
      <input @mouseover="playSound('select_sound.mp3', 0.3)"
             class="Button cursor_hover" type="button"
             @click="close"
             value="Закрыть">

      <input @mouseover="playSound('select_sound.mp3', 0.3)"
             v-if="save"
             style="float: right"
             class="Button cursor_hover" type="button"
             @click="sendSaveConfig"
             value="Сохранить">

      <input @mouseover="playSound('select_sound.mp3', 0.3)"
             :class="{disable: !inBase || buttonDisable}"
             v-if="!save"
             style="float: right"
             class="Button cursor_hover" type="button"
             @click="loadConfig"
             value="Загрузить">

      <input @mouseover="playSound('select_sound.mp3', 0.3)"
             v-if="!save"
             style="float: right;"
             class="Button remove cursor_hover" type="button"
             @click="removeConfig"
             value="Удалить">
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import ItemCell from '../Inventory/ItemCell';
import {gameStore} from "../../game/store";

export default {
  name: "BodyConfig",
  props: ['meta'],
  data() {
    return {
      save: false,
      newSaveConfig: 'Новая конфигурация',
      selectConfigID: 0,
      buttonDisable: false
    }
  },
  mounted() {
    if (this.$props.meta) {
      this.save = this.$props.meta.save
    }

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "GetSquadConfigs",
      }
    }))
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    close() {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'BodyConfig',
        component: '',
        forceClose: true,
      });
    },
    getSlotForEquipPanel(slot) {
      if (!slot) return;

      if (slot.source === "squadInventory") {
        return this.selectConfig.inventory[slot.slot]
      }

      if (slot.source === "Constructor") {
        if (slot.type_slot === 0) {
          for (let i in this.selectConfig.weapon_slots) {
            if (this.selectConfig.weapon_slots[i].number_slot === slot.slot) return this.selectConfig.weapon_slots[i];
          }
        }

        if ([1, 2, 3].includes(slot.type_slot)) {
          for (let i in this.selectConfig.equip_slots) {
            if (this.selectConfig.equip_slots[i].type_slot === slot.type_slot && this.selectConfig.equip_slots[i].number_slot === slot.slot) {
              return this.selectConfig.equip_slots[i];
            }
          }
        }
      }

      return null
    },
    sendSaveConfig() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "SaveSquadConfigs",
          data: JSON.stringify(this.selectConfig),
          name: this.newSaveConfig,
        }
      }))
    },
    loadConfig() {
      this.buttonDisable = true
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "ApplyConfig",
          id: this.selectConfigID,
        }
      }))
    },
    removeConfig() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "RemoveConfig",
          id: this.selectConfigID,
        }
      }))
    },
    selectIDConfig(id) {
      if (id) {
        this.save = false;
        this.selectConfigID = id;

        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "CheckItems",
            id: this.selectConfigID,
          }
        }))
      } else {
        this.save = true;
      }
    },
    checkItem(type, id) {
      if (id === 0 || this.save) return false;

      for (let s of this.checkItems) {
        if (s.item_id === id && s.type === type) {
          return !s.find
        }
      }

      return true
    }
  },
  computed: {
    inventory() {
      return this.$store.getters.getInventory
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('BodyConfig')
    },
    selectConfig() {
      if (this.save && this.saveConfig) return this.saveConfig;

      for (let c of this.configs) {
        if (c.id === this.selectConfigID) return JSON.parse(c.config)
      }

      return null
    },
    saveConfig() {
      // - корпус
      // - все ячейки 3
      // - все ячейки 2
      // - все ячейки 1
      // - Эквип панель
      // - Инвентарь
      if (!this.inventory.unit || !this.inventory.unit.body) {
        return false
      }

      return {
        body: this.inventory.unit.body,
        weapon_slots: this.inventory.unit.weapon_slots,
        equip_slots: this.inventory.unit.equip_slots,
        equip_panel: gameStore.equipPanel.slots,
        inventory: this.inventory.warehouses["squadInventory"].inventory,
      }
    },
    configs() {
      return this.inventory.squadConfigs
    },
    checkItems() {
      return this.inventory.checkItems
    },
    inBase() {
      return this.$route.path.includes('lobby')
    },
    applySquadConfigFail() {
      let errors = this.$store.getters.getErrors

      if (errors.hasOwnProperty('apply_squad_config_fail') && errors['apply_squad_config_fail'].active) {

        this.buttonDisable = false
        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'apply_squad_config_fail',
            active: false,
          });
        }.bind(this), 1000)

        return true
      }

      return false
    }
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
    AppItemCell: ItemCell,
  }
}
</script>

<style scoped>
.BodyConfig {
  position: absolute;
  top: 20px;
  left: 3px;
  width: calc(100% - 13px);
  height: calc(100% + 10px);
  border-radius: 5px;
  z-index: 12;
  padding: 3px;
  box-shadow: 0 0 2px black;
  border: 1px solid #009aeb7d;
  background: rgba(16, 110, 150, 0.6);
  backdrop-filter: blur(12px);
  pointer-events: auto;
}

.leftPanel {
  float: left;
  height: calc(100% - 20px);
  width: 106px;
  box-shadow: inset 0 0 2px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

.rightPanel {
  height: calc(100% - 20px);
  width: calc(100% - 110px);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px black;
  float: left;
  margin-left: 4px;
}

.head {
  margin: 0 0 0 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 17px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
}

.headRow, .equipRow, .barRow, .buttonRow {
  float: left;
  width: 100%;
  height: 115px;
}

.equipRow {
  height: 140px;
}

.barRow {
  height: 60px;
}

.buttonRow {
  height: 20px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px black;
  float: left;
  margin-top: 2px;
}

.body {
  position: relative;
  float: left;
  height: 90px;
  width: 90px;
  margin: 5px;
  box-shadow: inset 1px 1px 1px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
}

.inventory {
  position: relative;
  float: left;
  height: 90px;
  width: calc(100% - 105px);
  box-shadow: inset 1px 1px 1px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  margin: 5px 5px 5px 0;
  overflow-y: scroll;
}

.equipType {
  clear: both;
}

.equipTypeIcon {
  float: left;
  height: 32px;
  width: 32px;
  margin: 5px 5px 0 5px;
  box-shadow: inset 1px 1px 1px black;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  background-size: contain;
  opacity: 0.8;
}

.mining {
  background: rgba(255, 236, 14, 0.4);
}

.bodyWeapon, .bodyAmmo {
  background: rgba(255, 58, 53, 0.71);
}

.bodyAmmo {
  border-radius: 10px;
}

.Button {
  width: 102px;
  margin: 3px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 15px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.remove {
  background: rgba(255, 76, 76, 0.6);
}

.Button:active {
  transform: scale(0.98);
}

.Button:hover {
  background: rgba(255, 129, 0, 1);
}

.NameInput {
  width: calc(100% - 11px);
  margin-left: 1px;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  margin-top: 1px;
}

.manipulator, .emptyCell {
  border-radius: 5px;
  width: 35px;
  height: 35px;
  display: inline-block;
  background-color: rgba(76, 76, 76, 0.4);
  vertical-align: top;
  margin: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  float: left;
  position: relative;
  overflow: hidden;
  border: 1px solid #959595;
}

.manipulator {
  background-image: url("../../assets/icons/empty_item.png");
  background-size: contain;
}

.configName {
  transition: 0.1s;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  background: #5d5d5d57;
  padding-left: 4px;
  color: #ffe510;
  text-shadow: 1px 1px 1px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.configName:active {
  background: rgba(255, 255, 255, 0.73);
  color: #ff4208;
}

.configName:hover {
  background: rgba(235, 235, 235, 0.34);
  color: #ff7800;
}

.notFound {
  outline: red 2px dashed;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 200ms;
  opacity: 0;
  pointer-events: none;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}
</style>
