<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Обучение: взаимодействия с объектами</h2>

      <p>С некоторыми объектами в открытом мире можно взаимодействовать например забрать квестовый предмет или лутать
        обломки транспорта.</p>

      <p>Что бы начать взаимодействие с объектом надо выбрать на панели снаряжения <span
        class="importantly">"манипулятор"</span>, подъехать к объекту и нажать на него мышкой. Результатом будет
        открывшееся окно объекта с инвентарем.</p>

      <p>Если в инвентаре что то есть на это можно тыкнуть мышкой и оно упадет в трюм.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_open_object_1', 'gif')+ ')',
            width: '480px',
            height: '349px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Interacting with Objects</h2>

      <p>Some objects in the open world can be interacted with, for example, to pick up a quest item or loot
        wreckage of transport.</p>

      <p>To start interacting with an object, you need to select the <span
        class="importantly">"manipulator"</span> on the equipment panel, approach the object and click on it with the
        mouse. The result will be
        an opened object window with inventory.</p>

      <p>If there is something in the inventory, you can click on it with the mouse and it will fall into the hold.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_open_object_1', 'gif')+ ')',
            width: '480px',
            height: '349px',
            marginLeft: 'calc(50% - 240px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationOpenObject",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
