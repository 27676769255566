<template>
  <div id="audioPlayer" ref="audioPlayer" @click.stop="" @mousedown="toUp">
    <div id="progress">
      <input @input="changeProgressTrack" class="cursor_hover" type="range" min="0" max="100" step="1"
             v-model="progress">
    </div>

    <div id="control">
      <div>
        <div id="buttons">
          <div @click="playPrev" class="cursor_hover">
            <img src="https://img.icons8.com/plasticine/100/000000/rewind.png"/>
          </div>
          <div @click="playTrack(false)" class="cursor_hover">
            <img v-if="!statusPlay" src="https://img.icons8.com/plasticine/100/000000/play.png"/>
            <img v-if="statusPlay" src="https://img.icons8.com/plasticine/100/000000/pause.png"/>
          </div>
          <div @click="playNext" class="cursor_hover">
            <img src="https://img.icons8.com/plasticine/100/000000/fast-forward.png"/>
          </div>
        </div>
        <div id="volume">
          <div>
            <img src="https://img.icons8.com/plasticine/100/000000/low-volume.png"/>
          </div>
          <div style="float: left; width: 50px; margin: 0;" class="cursor_hover">
            <input @input="changeVolume($event)" type="range" min="0" max="1" step="0.01" :value="volume">
          </div>
          <div>
            <img src="https://img.icons8.com/plasticine/100/000000/high-volume.png"/>
          </div>
        </div>
      </div>
    </div>

    <div class="button noSelect cursor_hover"
         @mouseover="playSound('select_sound.mp3', 0.3)"
         @click.stop="openService('settings')">
      {{ texts['settings'][language] }}
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import {urls} from "../../const";

export default {
  name: "AudioPlayer",
  data() {
    return {
      statusPlay: false,
      currentTrack: {playList: 'battle', track: -1, meta: null},
      progress: 0,
      progressTrack: null,
      battle: [
        {src: require('../../assets/audio/music/Veliri_1.mp3').default, title: 'Veliri_1', file: null},
        {src: require('../../assets/audio/music/Veliri_2.mp3').default, title: 'Veliri_2', file: null},
        {src: require('../../assets/audio/music/Veliri_3.mp3').default, title: 'Veliri_3', file: null},
        {src: require('../../assets/audio/music/Veliri_4.mp3').default, title: 'Veliri_4', file: null},
        {src: require('../../assets/audio/music/Veliri_5.mp3').default, title: 'Veliri_5', file: null},
        {src: require('../../assets/audio/music/Veliri_6.mp3').default, title: 'Veliri_6', file: null},
        {src: require('../../assets/audio/music/Veliri_7.mp3').default, title: 'Veliri_7', file: null},
        {src: require('../../assets/audio/music/Veliri_8.mp3').default, title: 'Veliri_8', file: null},
        {src: require('../../assets/audio/music/Veliri_9.mp3').default, title: 'Veliri_9', file: null},
        {src: require('../../assets/audio/music/Veliri_10.mp3').default, title: 'Veliri_10', file: null},
        {src: require('../../assets/audio/music/Veliri_11.mp3').default, title: 'Veliri_11', file: null},
        {src: require('../../assets/audio/music/Veliri_12.mp3').default, title: 'Veliri_12', file: null},
        {src: require('../../assets/audio/music/Veliri_13.mp3').default, title: 'Veliri_13', file: null},
      ],
    }
  },
  destroyed() {
    clearInterval(this.progressTrack)
    this.stopTrack();
  },
  mounted() {
    let app = this;

    app.statusPlay = this.$store.getters.getSettings.playMusic;

    app.progressTrack = setInterval(function () {
      if (app.statusPlay) {
        // проверм прогрес трека
        app.progress = app.getPercentProgressTrack();
        if (Math.round(app.progress) >= 100) {
          app.playNext();
        }
      }

      app.currentTrack.playList = 'battle';
    }, 1000)
  },
  methods: {
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    loadFile() {
      if (!this.getCurrentTrack().file) {
        let url = urls.audioPrefix + this.getCurrentTrack().src
        if (url.includes('local-audio:/./')) {
          url = url.replace("local-audio:/./", 'local-audio://')
        }

        this.getCurrentTrack().file = new Audio(url);
        this.changeVolume();
      }
    },
    playTrack(forcePlay) {
      if (this.statusPlay && !forcePlay) {
        this.statusPlay = false;
        this.getCurrentTrack().file.pause();

        this.$store.dispatch('changeSettings', {
          name: "PlayMusic",
          count: 0
        });
      } else {
        this.progress = 0;
        this.statusPlay = true;
        this.loadFile();
        if (this.getCurrentTrack().file) this.getCurrentTrack().file.play();

        this.$store.dispatch('changeSettings', {
          name: "PlayMusic",
          count: 1
        });
      }
    },
    changeVolume(e) {
      if (!e) return
      this.$store.dispatch('changeSettings', {
        name: "VolumeMusic",
        count: e.target.value * 100
      });

      this.$store.commit({
        type: 'setVolume',
        volume: e.target.value,
      });
    },
    changeSFXVolume() {

      this.$store.dispatch('changeSettings', {
        name: "VolumeSfx",
        count: this.SFXVolume * 100
      });

      this.$store.commit({
        type: 'setSFXVolume',
        SFXVolume: this.SFXVolume,
      });
    },
    playNext() {
      this.stopTrack();

      if (this.currentTrack.track === -1) {
        this.currentTrack.track = Math.floor(Math.random() * this[this.currentTrack.playList].length)
      }

      if (this.currentTrack.track + 1 < this[this.currentTrack.playList].length) {
        this.currentTrack.track++;
      } else {
        this.currentTrack.track = 0
      }

      this.playTrack(true);
    },
    playPrev() {
      this.stopTrack();

      if (this.currentTrack.track - 1 >= 0) {
        this.currentTrack.track--;
      } else {
        this.currentTrack.track = this[this.currentTrack.playList].length - 1
      }

      this.playTrack(true);
    },
    randomTrack() {
      this.stopTrack();
      this.currentTrack.track = Math.abs(Math.round(Math.random() * this[this.currentTrack.playList].length - 1));

      if (this.statusPlay) this.playTrack(true);
    },
    stopTrack() {
      this.progress = 0;
      if (this.getCurrentTrack().file) {
        this.getCurrentTrack().file.pause();
        this.getCurrentTrack().file.currentTime = 0;
      }
    },
    getCurrentTrack() {
      if (!this[this.currentTrack.playList][this.currentTrack.track]) {
        return {}
      }
      return this[this.currentTrack.playList][this.currentTrack.track]
    },
    getPercentProgressTrack() {
      if (this.getCurrentTrack().file) {
        return (this.getCurrentTrack().file.currentTime * 100 / this.getCurrentTrack().file.duration)
      } else {
        return 100
      }
    },
    changeProgressTrack() {
      if (this.getCurrentTrack().file) {
        this.getCurrentTrack().file.currentTime = this.progress * this.getCurrentTrack().file.duration / 100;
      }
    },
    changePlayList(playList) {
      this.stopTrack();
      this.currentTrack.playList = playList;
      this.randomTrack();
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser
    },
    volume() {
      let volume = this.$store.getters.getSettings.volume
      if (this.getCurrentTrack().file) this.getCurrentTrack().file.volume = volume;
      return volume
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('AudioPlayer')
    },
  },
  components: {
    AppControl: Control,
  }
}
</script>

<style scoped>
#audioPlayer {
  width: 140px;
  color: white;
  display: block;
  z-index: 11;
  padding: 0 1px 1px 1px;
  box-shadow: 0 0 2px black;
  pointer-events: auto;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

#control {
  width: 100%;
  border-radius: 5px 5px 0 0;
  border: 0;
  display: block;
  background: rgba(0, 154, 235, 0.5);
  height: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  transition: 100ms;
  font-family: 'Comfortaa', cursive;
  padding: 1px 0;
  box-shadow: 0 0 2px black;
}

#buttons div, #volume div {
  height: 14px;
  width: 14px;
  background: rgb(203, 170, 95);
  float: left;
  margin: 2px;
  border-radius: 3px;
  box-shadow: 0 0 2px black;
  position: relative;
}

#volume div {
  background: none;
  box-shadow: none;
}

#volume input, #progress input {
  padding: 0;
  background: none;
}

#volume input {
  float: left;
  width: 50px;
}

#buttons div img, #volume div img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  filter: drop-shadow(0 0 1px black);
}

#buttons div:hover {
  background: rgba(126, 126, 132, 0.31);
}

#buttons div:active {
  transform: scale(0.97);
}

#volume input[type=range] {
  box-shadow: none;
  margin-top: 1px;
  height: 16px;
}

#volume input[type=range]::-ms-thumb {
  border: 0 solid rgba(0, 0, 0, 0);
  width: 3px;
  border-radius: 0;
  background: rgba(255, 143, 53, 0.7);
  height: 4px;
}

#volume input[type=range]::-moz-range-thumb {
  border: 0 solid rgba(0, 0, 0, 0);
  height: 8px;
  width: 3px;
  border-radius: 0;
  background: rgba(255, 143, 53, 0.7);
  box-shadow: 0 0 2px black;
}

#volume input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

#volume input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 12px;
  width: 3px;
  border-radius: 0;
  background: rgba(255, 143, 53, 0.7);
  -webkit-appearance: none;
  margin-top: -0.1px;
}

#volume input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

#progress {
  height: 9px;
  width: 100%;
  line-height: 5px;
}

#progress input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  box-shadow: none;
}

#progress input[type=range]:focus {
  outline: none;
  background: rgba(246, 255, 252, 0.4);
}

#progress input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

#progress input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

#progress input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(0, 130, 195, 0);
}

#progress input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

#progress input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.button {
  width: calc(100% - 0px);
  display: block;
  margin: 1px auto 0;
  pointer-events: auto;
  font-size: 10px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 9px;
  border-radius: 0 0 5px 5px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  line-height: 7px;
  text-shadow: 1px 1px 1px black;
}

.button:active {
  transform: scale(0.98);
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}
</style>
