<template>
  <div class="CellSubMenu" v-if="relationBonuses">

    <app-repair-modal v-if="repairDialog.open"
                      v-bind:slotItem="subMenuProps.slot"
                      v-bind:source="subMenuProps.src"
                      v-bind:slot-inv="subMenuProps.slot.number"
                      v-bind:addStyle="{top: '-1px', left: '-1px', width: 'calc(100% + 2px)', height: 'calc(100% + 2px)'}"/>

    <div v-if="subMenuProps.points && action===''"
         id="InventoryTip"
         v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">
      <div class="wrapper">
        <div class="headSubMenu">
          <div class="iconItem">
            <app-background-item-cell v-bind:slotItem="subMenuProps.slot"/>
          </div>
          <div class="nameItem">{{ itemName }}</div>
        </div>

        <template v-for="point in subMenuProps.points">
          <input v-if="point==='install_weapon'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="InstallWeapon" type="button"
                 :value="texts['button_3'][language]">
          <input v-if="point==='install_ammo'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="InstallAmmo" type="button"
                 :value="texts['button_4'][language]">
          <input v-if="point==='install_body'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="InstallBody" type="button"
                 :value="texts['button_5'][language]">
          <input v-if="point==='install_thorium'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="InstallThorium" type="button"
                 :value="texts['button_6'][language]">
          <input v-if="point==='detail'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="detail" type="button"
                 :value="texts['button_7'][language]">
          <input v-if="point==='split'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="splitDialog" type="button"
                 :value="texts['button_8'][language]">
          <input v-if="point==='throw'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="throwItems" type="button"
                 :value="texts['button_9'][language]">
          <input v-if="point==='destroy'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="throwItems" type="button"
                 :value="texts['button_10'][language]">
          <input v-if="point==='install'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="installDialog" type="button"
                 :value="texts['button_11'][language]">
          <input v-if="point==='cell'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="cellDialog" type="button"
                 :value="texts['button_12'][language]">
          <input v-if="point==='repair'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="repair" type="button"
                 :value="texts['button_13'][language]">
          <input v-if="point==='remove'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="remove" type="button"
                 :value="subMenuProps.slot.type === 'equip' && subMenuProps.slot.item.type_slot === 4 ?
                 texts['button_10'][language]:texts['button_14'][language] ">
          <input v-if="point==='to_hold'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="moveItem(subMenuProps.src, 'squadInventory')" type="button"
                 :value="texts['button_15'][language]">
          <input v-if="point==='to_storage'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="moveItem(subMenuProps.src, 'storage')" type="button"
                 :value="texts['button_16'][language]">
          <input v-if="point==='to_work'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="toWork" type="button"
                 :value="texts['button_17'][language]">
          <input v-if="point==='cancel'"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 class="CellSubMenuButton cursor_hover"
                 @click="cancel" type="button"
                 :value="texts['button_18'][language]">
        </template>
      </div>
    </div>

    <div id="sellDialog"
         v-if="action==='cell'"
         v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">

      <div class="wrapper">
        <div style="float: left; width: 100%;">
          <div class="iconItem">
            <app-background-item-cell v-bind:slotItem="subMenuProps.slot"/>
          </div>

          <div class="input_number_row">
            <div class="title_row">{{ texts['text_4'][language] }}</div>

            <div style="float: right; width: 50%">
              <div class="range_button" style="border-radius: 5px 0 0 5px"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="splitChangeCount(-1, 'cellProps')">
                -1
              </div>
              <input type="number"
                     style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                     v-model="cellProps.count" min="1" max="9999">
              <div class="range_button" style="float: right; margin-right: 5px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="splitChangeCount(1, 'cellProps')">+1
              </div>
            </div>
          </div>

          <div class="input_number_row">
            <div class="title_row">{{ texts['text_5'][language] }}</div>

            <div style="float: right; width: 50%">
              <div class="range_button" style="border-radius: 5px 0 0 5px"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeQuantityPrice(-0.1, 'cellProps')">-.1
              </div>
              <input type="number"
                     style="float: left; width: calc(100% - 45px); margin-top: -1px; margin-right: 0; border-radius: 0;"
                     v-model="cellProps.price" min="1" step="0.1">
              <div class="range_button" style="float: right; margin-right: 5px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeQuantityPrice(0.1, 'cellProps')">+.1
              </div>
            </div>
          </div>

          <div style="width: 100%; float: left; margin-bottom: 2px; margin-top:4px; font-size: 1px;">
              <span style="margin-left: 14px; float: left; line-height: 20px;">
                {{ texts['text_6'][language] }}
              </span>
            <span class="total">{{ (cellProps.count * cellProps.price).toFixed(2) }} cr.</span>
          </div>

          <div style="width: 100%; float: left; margin-bottom: 2px; margin-top:2px; font-size: 1px;">
            <span style="margin-left: 14px; float: left; line-height: 20px;"
                  v-html="texts['text_7'][language].replace('%tax%', ((tax * 100) - reducedMarketTax))"/>
            <span class="total">{{ (getTax() / 100).toFixed(2) }} cr.</span>
          </div>

          <div style="width: 100%; float: left; margin-bottom: 4px; margin-top:2px; font-size: 1px;">
              <span style="margin-left: 14px; float: left; line-height: 20px;">
                {{ texts['text_8'][language] }}
              </span>
            <span class="total">{{
                ((cellProps.count * cellProps.price) - getTax() / 100).toFixed(2)
              }} cr.</span>
          </div>
        </div>

        <div style="width: 100%; float: left;">
          <input type="button"
                 :value="texts['button_19'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="cancel"
                 style="float: left; margin-left: 10px;">
          <input type="button"
                 :value="texts['button_20'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="cell"
                 :class="{disable: (cellProps.count * cellProps.price) - (getTax()/100) <= 0}"
                 style="float: right; margin-right: 10px;">
        </div>
      </div>
    </div>

    <div id="QuantityRange"
         v-if="action==='split'"
         v-bind:style="{ left: subMenuProps.x + 'px', top: subMenuProps.y + 'px' }">
      <div class="wrapper">
        <div>
          <div class="iconItem">
            <app-background-item-cell v-bind:slotItem="subMenuProps.slot"/>
          </div>

          <div style="margin-top: 10px; float: left;">
            <div class="range_button" style="border-radius: 5px 0 0 5px"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="splitChangeCount(-1, 'splitProps')">
              -1
            </div>
            <input type="range" v-model="splitProps.count" min="1" :max="subMenuProps.slot.quantity-1">
            <div class="range_button"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="splitChangeCount(1, 'splitProps')">+1
            </div>
          </div>

          <input type="number" v-model="splitProps.count" min="1" :max="subMenuProps.slot.quantity-1"/>
        </div>

        <div style="width: 150px; float: left;">
          <input type="button"
                 :value="texts['button_21'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="cancel" style="float: left;">
          <input type="button"
                 :value="texts['button_22'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="split" style="float: right; margin-right: 10px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from './BackgroundItemCell'
import Input from "../Chat/Input";
import {GetItemByTypeAndName} from "../../game/utils/get_item_by_type_and_id";
import RepairModal from "../Inventory/RepairModal";

export default {
  name: "CellSubMenu",
  props: ['subMenuProps'],
  data() {
    return {
      // эти компоненты конечно стоит наверно поделить, но мне чет влом
      action: '',
      cellProps: {
        count: 1,
        price: 1,
      },
      splitProps: {
        count: 1,
      },
      repairDialog: {
        open: false,
      },
      closeHandler: null,
      ignoreFirstClick: true,
    }
  },
  mounted() {
    this.closeHandler = this.closeSubMenu(this)
    window.addEventListener("click", this.closeHandler);
    setTimeout(function () {
      this.ignoreFirstClick = false
    }.bind(this), 200)
  },
  destroyed() {
    window.removeEventListener("click", this.closeHandler)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    closeSubMenu(vue) {
      return function wrapperCloseSellSubMenu(event) {
        if (vue.ignoreFirstClick) {
          vue.ignoreFirstClick = false
          return;
        }

        let a = event.target
        if (a && a.className.includes('CellSubMenuButton')) return;

        let els = [];
        while (a) {
          els.unshift(a);
          a = a.parentNode;

          if (a && a.className === 'CellSubMenu') return;
        }

        vue.cancel()
      }
    },
    getTax() {
      let marketTax = this.tax - (this.reducedMarketTax / 100)
      let itemTax = Math.round((this.cellProps.price * 100) * marketTax)

      let tax = this.cellProps.count * itemTax
      if (tax <= 0) {
        return this.cellProps.count
      }

      return tax
    },
    moveItem(src, dst) {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "itemsToDst",
          source: src,
          destination: dst,
          storage_slots: {[Number(this.$props.subMenuProps.slot.number)]: 0},
          strategy: location.href.includes('global') ? 'global' : '',
        }
      }));
      this.cancel()
    },
    splitChangeCount(change, dialogProps) {
      this.playSound('button_press.mp3', 0.3)

      this[dialogProps].count = Number(this[dialogProps].count)
      if (isNaN(this[dialogProps].count)) {
        this[dialogProps].count = 0
      }

      this[dialogProps].count += change
      if (this[dialogProps].count < 1) {
        this[dialogProps].count = 1
      }

      if (this[dialogProps].count > this.$props.subMenuProps.slot.quantity) {
        this[dialogProps].count = Number(this.$props.subMenuProps.slot.quantity)
      }
    },
    changeQuantityPrice(change, dialogProps) {
      this.playSound('button_press.mp3', 0.3)

      this[dialogProps].price = Number(this[dialogProps].price)
      if (isNaN(this[dialogProps].price)) {
        this[dialogProps].price = 0
      }

      this[dialogProps].price += change
      if (this[dialogProps].price < 1) {
        this[dialogProps].price = 1
      }

      this[dialogProps].price = Number(this[dialogProps].price.toFixed(2));
    },
    splitDialog() {
      this.playSound('button_press.mp3', 0.3)
      this.action = 'split';
    },
    installDialog() {
      let box = GetItemByTypeAndName("boxes", this.$props.subMenuProps.slot.item.name)
      if (box.protect) {
        this.$store.commit({
          type: 'toggleWindow',
          id: 'boxPassInstall',
          component: 'boxPass',
          meta: {
            slot: Number(this.$props.subMenuProps.slot.number),
            action: 'install',
          },
          forceOpen: true,
        });
      } else {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "PlaceNewBox",
            src_slot: Number(this.$props.subMenuProps.slot.number),
          }
        }));
      }
      this.cancel()
    },
    toWork() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "SelectBP",
          storage_slots: [Number(this.$props.subMenuProps.slot.number)],
          count: 1,
        }
      }));
      this.cancel()
    },
    InstallWeapon() {
      this.$store.dispatch('setWeapon', {
        slot: this.$props.subMenuProps.slot,
        src: this.$props.subMenuProps.src,
        equip_slot: -1,
      });
      this.cancel()
    },
    InstallAmmo() {
      this.$store.dispatch('setAmmo', {
        slot: this.$props.subMenuProps.slot,
        src: this.$props.subMenuProps.src,
        equip_slot: -1,
      });
      this.cancel()
    },
    InstallBody() {
      this.$store.dispatch('setBody', {
        slot: this.$props.subMenuProps.slot,
        src: this.$props.subMenuProps.src,
      });
      this.cancel()
    },
    InstallThorium() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "FillBody",
          inventory_slot: Number(this.$props.subMenuProps.slot.number),
          source: this.$props.subMenuProps.src,
        }
      }));

      this.cancel()
    },
    cellDialog() {
      this.action = 'cell';
    },
    split() {

      let count = Number(this.splitProps.count)
      if (count >= Number(this.$props.subMenuProps.slot.quantity)) {
        count = Number(this.$props.subMenuProps.slot.quantity) - 1
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "divideItems",
          inventory_slot: Number(this.$props.subMenuProps.slot.number),
          source: this.$props.subMenuProps.src,
          count: count,
        }
      }));

      this.cancel()
    },
    cell() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "market",
        data: {
          event: 'PlaceNewSellOrder',
          slot: Number(this.$props.subMenuProps.slot.number),
          count: Number(this.cellProps.count),
          price: Number(this.cellProps.price * 100),
        }
      }));

      this.cancel()
    },
    repair() {
      this.repairDialog.open = true
    },
    detail() {

      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + this.$props.subMenuProps.slot.type + this.$props.subMenuProps.slot.item_id,
        component: 'detailItem',
        meta: {itemType: this.$props.subMenuProps.slot.type, itemID: this.$props.subMenuProps.slot.item_id},
        forceOpen: true,
      });

      this.cancel()
    },
    throwItems() {
      let throwItems = {};
      let items = {};

      throwItems[Number(this.$props.subMenuProps.slot.number)] = Number(this.$props.subMenuProps.slot.quantity);
      items[Number(this.$props.subMenuProps.slot.number)] = this.$props.subMenuProps.slot;

      if (this.$route.path !== '/global') {
        this.$store.commit({
          type: 'setThrowItemsState',
          open: true,
          slots: throwItems,
          items: items,
          source: this.$props.subMenuProps.src,
        });
      } else {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "inventory",
          data: {
            event: "ThrowItems",
            storage_slots: throwItems,
            source: this.$props.subMenuProps.src,
          }
        }));
      }

      this.cancel()
    },
    remove() {
      if (this.$props.subMenuProps.src === 'Constructor') {

        if (this.$props.subMenuProps.type === 'ammo') {
          this.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: "RemoveMotherShipAmmo",
              equip_slot: Number(this.$props.subMenuProps.data.number_slot),
              destination: "storage",
            }
          }));
        }

        if (this.$props.subMenuProps.type === 'weapon') {
          this.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: "RemoveMotherShipWeapon",
              equip_slot: Number(this.$props.subMenuProps.data.number_slot),
              destination: "storage",
            }
          }));
        }

        if (this.$props.subMenuProps.type === 'equip') {
          this.$store.dispatch("sendSocketData", JSON.stringify({
            service: "inventory",
            data: {
              event: "RemoveMotherShipEquip",
              equip_slot: Number(this.$props.subMenuProps.data.number_slot),
              equip_slot_type: Number(this.$props.subMenuProps.data.type_slot),
              destination: "storage",
            }
          }));
        }

        this.cancel()
      }
    },
    cancel() {
      this.playSound('button_press.mp3', 0.3)
      this.$props.subMenuProps.slot = null;
    },
  },
  computed: {
    itemName() {
      let slot = this.$props.subMenuProps.slot;
      if (!slot) {
        return ''
      }

      if (slot.type === "blueprints") {
        if (slot.item.in_map) {
          return this.handbook['structure'][this.language][slot.item.name.split('_bp')[0]].name
        } else {
          return this.handbook[slot.item.item_type][this.language][slot.item.item_name].name
        }
      }

      return this.handbook[slot.type][this.language][slot.item.name].name
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Inventory')
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    market() {
      return this.$store.getters.getMarket
    },
    reducedMarketTax() {
      if (this.relationBonuses[this.baseStatus.fraction] && this.relationBonuses[this.baseStatus.fraction].hasOwnProperty('reduced_market_tax')) {
        return this.relationBonuses[this.baseStatus.fraction].reduced_market_tax
      }

      return 0
    },
    tax() {
      if (!this.baseStatus || !this.baseStatus.state) return '?';

      if (!this.market.base_market_tax.hasOwnProperty(this.baseStatus.state.id)) {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "market",
          data: {
            event: 'GetBaseTax',
            id: Number(this.baseStatus.state.id),
          }
        }));
      } else {
        return this.market.base_market_tax[this.baseStatus.state.id]
      }

      return '?'
    }
  },
  components: {
    Input,
    AppBackgroundItemCell: BackgroundItemCell,
    AppRepairModal: RepairModal,
  }
}
</script>

<style scoped>
#InventoryTip {
  position: absolute;
  display: inline-block;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  border-radius: 5px;
  min-height: 20px;
  min-width: 80px;
  padding: 3px;
  text-shadow: 1px 1px 2px black, 0 0 1em #696969;
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
}

#InventoryTip input {
  display: block;
  margin: 2px auto 0;
  width: 100%;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 18px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

#sellDialog span {
  font-size: 10px;
  color: bisque;
  text-shadow: 1px 1px 1px black;
}

#sellDialog #sellPrice {
  margin: 0;
  float: right;
  text-align: right;
}

#QuantityRange, #sellDialog {
  position: absolute;
  left: calc(50% - 100px);
  top: 27px;
  width: 200px;
  height: 55px;
  display: block;
  background-size: 100% 2px;
  border-radius: 5px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  background: rgba(8, 138, 210, 0.2);
  z-index: 91;
  filter: drop-shadow(0 0 2px black);
  padding: 3px 3px 5px;
}

#sellDialog {
  height: 144px;
}

#QuantityRange .iconItem, #sellDialog .iconItem {
  position: relative;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  border: 1px solid #959595;
  background-color: #4c4c4c;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: top;
  margin: 7px 5px 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  float: left;
}

#QuantityRange input[type=range], #sellDialog input[type=range] {
  float: left;
  margin-top: -1px;
  width: 70px;
  margin-bottom: 5px;
  padding: 0;
}

#QuantityRange input[type=button], #sellDialog input[type=button] {
  width: 65px;
  margin-top: 3px;
  font-size: 11px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

#QuantityRange input[type=button]:active, #sellDialog input[type="button"]:active, #InventoryTip input:active {
  transform: scale(0.98);
}

#QuantityRange input[type=button]:hover, #sellDialog input[type="button"]:hover, #InventoryTip input:hover {
  background: rgba(255, 129, 0, 1);
}

#QuantityRange input[type=number], #sellDialog input[type=number] {
  float: right;
  margin-top: 10px;
  margin-right: 5px;
  color: #ff7800;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
  width: 27px;
  height: 14px;
  text-indent: 1px;
  text-align: right;
}

#QuantityRange input[type=number]::-webkit-inner-spin-button, #sellDialog input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#QuantityRange input[type=number], #sellDialog input[type=number] {
  -moz-appearance: textfield;
}

.headSubMenu {
  height: 30px;
  min-width: 120px;
}


#InventoryTip .iconItem {
  float: left;
  height: 40px;
  width: 40px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  margin: 0px 3px 5px 0px;
  border-radius: 5px;
}

#InventoryTip .nameItem {
  color: yellow;
  float: left;
  text-shadow: 1px 1px 1px black;
  font-size: 10px;
  margin-top: 5px;
  max-width: calc(100% - 43px);
}

.wrapper {
  height: 100%;
  width: calc(100% - 2px);
  float: left;
  clear: both;
  border: 1px solid rgba(37, 160, 225, 0.5);
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
}

#InventoryTip .wrapper {
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  padding: 3px;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.title_row {
  float: left;
  font-size: 10px;
  color: bisque;
  margin-right: 5px;
  line-height: 15px;
}

.input_number_row {
  float: left;
  width: calc(100% - 48px);
  margin-top: 7px;
}

.total {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  margin: 0;
  float: right;
  text-align: right;
  background-size: 5px 2px;
  background: rgba(127, 127, 127, 0.5);
  border-radius: 5px;
  line-height: 15px;
  box-shadow: inset 0 0 1px 1px rgb(118 118 118);
  border: 0;
  font-size: 11px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}
</style>
