import {gameStore} from "../store";
import {removeAllObj, removeDrone, removeDynamicObject, removeReservoir} from "../radar/object";
import {deleteBullet} from "../bullet/remove";
import {RemoveAllMark} from "../radar/marks";
import {RemoveShield} from "../shield/shield";
import {Designators, removeAimBoxes} from "../interface/server_gun_designator";
import {Scene} from "../create";
import {removeUnit} from "../unit/remove";
import {clearFairyStore} from "../bullet/create";
import {destroyAllSelectedSprite} from "../map_editor/create_select_sprite";
import {removeAvatar, removeThoriumBars} from "../interface/status_bar";
import {resetEquipBarState} from "../interface/equip_bar";
import {ClearAllRopes} from "../rope/render";
import {battleStateReset} from "../interface/battle_state";
import {RemoveBuildObject} from "../build/select_place_build";
import {siegeStateReset} from "../interface/siege_state";
import {DestroyPollen} from "../pollen/pollen";
import {ClearMapItemsCache} from "../map_items/map_items";

let RemoveMap = {ok: true}

function RemoveOldMap() {
  console.log("Remove Map")

  if (RemoveMap.ok) {
    return
  }
  RemoveMap.ok = true

  for (let i in Designators) {
    if (i === 'init') continue;

    if (Designators[i].ammoState.ammoMsg && Designators[i].ammoState.ammoMsg.sprite) {
      Designators[i].ammoState.ammoMsg.sprite.destroy();
    }

    if (Designators[i].ammoState.reloadIcon) Designators[i].ammoState.reloadIcon.destroy();
    if (Designators[i].ammoState.powerBar) Designators[i].ammoState.powerBar.destroy();

    if (Designators[i].targetCircle) Designators[i].targetCircle.destroy();
    if (Designators[i].chaseCircle) Designators[i].chaseCircle.destroy();

    removeAimBoxes(Designators[i])
    delete Designators[i]
  }


  clearFairyStore();
  resetEquipBarState();
  removeAvatar();
  removeThoriumBars();
  ClearAllRopes();
  battleStateReset(true);
  siegeStateReset()
  RemoveBuildObject()

  for (let i in gameStore.mapsState) {
    removeMap(gameStore.mapsState[i])
  }

  if (gameStore.map && gameStore.map.handlers_coordinates) {
    for (let i of gameStore.map.handlers_coordinates) {
      if (i.sprite) i.sprite.destroy();
    }
  }

  for (let i in gameStore.mapEditorState.labels) {
    gameStore.mapEditorState.labels[i].destroy();
    delete gameStore.mapEditorState.labels[i];
  }

  for (let i in gameStore.clouds) {
    gameStore.clouds[i].destroy();
    delete gameStore.clouds[i];
  }

  for (let i in gameStore.bullets) {
    deleteBullet(i)
  }

  for (let i in gameStore.pollens) {
    DestroyPollen(i)
  }

  for (let i in gameStore.units) {
    removeUnit(gameStore.units[i])
  }

  for (let i in gameStore.drones) {
    if (gameStore.drones.hasOwnProperty(i)) {
      removeDrone(gameStore.drones[i], i)
    }
  }

  for (let i in gameStore.spawns) {
    gameStore.spawns[i].destroy();
    delete gameStore.spawns[i];
  }

  for (let i in gameStore.reservoirs) {
    removeReservoir(gameStore.reservoirs[i], false)
  }

  for (let i in gameStore.shields) {
    RemoveShield(gameStore.shields[i].id)
  }

  destroyAllSelectedSprite();
  RemoveAllMark();
  ClearMapItemsCache();
  gameStore.cacheSpriteBullets = {}
  gameStore.mapsState = {};
  gameStore.removeObjects = {};
}

function clearTrash() {
  if (Scene && Scene.children) {
    for (let obj of Scene.children.systems.displayList.list) {

      if (!obj) continue;

      if (obj.type === 'ParticleEmitterManager' && obj.name === "bullet") {
        obj.destroy();
      }

      if (obj.frame && obj.frame.texture && obj.frame.texture.key) {
        if (obj.frame.texture.key.includes("drone_defender_") || obj.frame.texture.key.includes("drone_scout_") ||
          obj.frame.texture.key.includes("craters") || obj.frame.texture.key.includes("bullets")) {
          obj.destroy();
        }
      }
    }
  }
}

function removeMap(mapState) {
  if (mapState.bmdTerrain && mapState.bmdTerrain.bmd) {
    mapState.bmdTerrain.bmd.clear();
    mapState.bmdTerrain.bmd.destroy();

    mapState.bmdTerrain.bmdObject.clear();
    mapState.bmdTerrain.bmdObject.destroy();

    mapState.bmdTerrain.bmdWater.clear();
    mapState.bmdTerrain.bmdWater.destroy();

    for (let i of mapState.bmdTerrain.water) {
      i.destroy();
    }
  }

  removeAllObj();
  for (let obj of mapState.staticObjects) {
    if (obj.objectSprite) {
      removeDynamicObject(obj, false)
    }
  }
  mapState.staticObjects = [];
}

export {RemoveOldMap, clearTrash, RemoveMap}
