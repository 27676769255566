import {gameStore} from "../store";
import {Scene} from "../create";
import {GetOffsetShadowByMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {ShortDirectionRotateTweenGroup} from "../utils/utils";
import {GetGlobalPos} from "../map/gep_global_pos";
import {MoveSprite} from "../utils/move_sprite";
import {FlyMeteorites} from "./meteorite";
import {AddMoveBufferData} from "../utils/add_move_buffer_data";
import {removedBullets} from "./remove";

function AddBulletMoveBufferData(data) {
  if (!gameStore.gameReady) return;
  if (removedBullets[data.id]) {
    return;
  }

  let bullet = gameStore.bullets[data.id];
  if (!bullet) {
    bullet = {}
    gameStore.bullets[data.id] = bullet;
  } else {
    bullet.time_out = 1024
  }

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  data.x = pos.x;
  data.y = pos.y;

  AddMoveBufferData(data, bullet)
}

function FlyBullet(bullet, ms) {

  let data = bullet.bufferMoveTick.shift();
  if (!data) {
    return;
  }

  let infoBullet = gameStore.gameTypes.ammo[data.type_id];
  if (!infoBullet) {
    infoBullet = {t: "", n: "", ac: ""}
  }

  if (infoBullet.name === "ballistics_artillery_bullet_2" || infoBullet.name === "big_lens_1_additional" || infoBullet.type === "meteorite") {
    FlyMeteorites(data, bullet, gameStore.map.id);
    return
  }

  if (!bullet.sprite) {
    return
  }

  let scale = GetSpriteSizeByMapLvl(data.z, 1 / 5, 0.05);
  MoveSprite(bullet.sprite, data.x, data.y, ms, scale.x, false, false);

  let shadowPos = GetOffsetShadowByMapLvl(data.z, data.x, data.y, 0, 2.5, gameStore.map.id);
  MoveSprite(bullet.shadow, shadowPos.x, shadowPos.y, ms, scale.x, false, false);

  if (!Scene.cameras.main.worldView.contains(data.x, data.y)) {

    bullet.sprite.setAngle(data.r)
    bullet.shadow.setAngle(data.r)

    if (bullet.sprite.visible) {
      bullet.sprite.setVisible(false)
      bullet.shadow.setVisible(false)
      if (bullet.fairy) bullet.fairy.setVisible(false)
    }

  } else {

    if (bullet.fairy && bullet.sprite.visible && !bullet.fairy.visible) bullet.fairy.setVisible(true);

    if (!bullet.sprite.visible) {
      bullet.sprite.setVisible(true)
      bullet.shadow.setVisible(true)
      if (bullet.fairy) bullet.fairy.setVisible(true)
    }

    ShortDirectionRotateTweenGroup([bullet.sprite, bullet.shadow], data.r, ms, bullet.sprite.angle)
    if (bullet.fairy) {
      let newAngle = {min: 180 + data.r, max: 180 + data.r}
      bullet.fairy.ops.angle.start = newAngle.min;
      bullet.fairy.ops.angle.end = newAngle.max;
      //if (!(bullet.fairy.emitter.angle.propertyValue.min === newAngle.min && bullet.fairy.emitter.angle.propertyValue.max === newAngle.max)) {
      //  bullet.fairy.emitter.setEmitterAngle(newAngle);
      //}
    }
  }
}

export {FlyBullet, AddBulletMoveBufferData}
