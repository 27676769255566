<template>
  <div>
    <template v-if="lang === 'RU'">
      <h2 class="head" id="bodies">Veliri-5</h2>
      <p>Veliri-5 - это пятый по счёту, но самый потенциальный из всех прочих миров у двойной звёздной системы Veliri.
        Особое расположение пятой планеты позволяет иметь на её поверхности тонкую, но стабильную атмосферу,
        преимущественно состоящую из азотно-кислородной смеси, углекислого газа и водяного пара. </p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('planet', 'jpg')+ ')',
              width: '400px',
              height: '225px',
              marginLeft: 'calc(50% - 200px)'
            }"/>

      <p>Как докладывали первые разведывательные станции синтетов, климат на планете суровый, что выражается резкими
        колебаниями температуры от 75°C в дневные циклы, до -40°C. Тем не менее, на
        Veliri 5 была обнаружена пускай и простейшая, но многочисленная в своём разнообразии форма жизни на основе
        углерода. Эти биомы зачастую можно обнаружить в различных уголках планеты: горные цепи, пустоши, кислотные
        тундры
        и бесплодные равнины. </p>
      <p>После тщательной картографии поверхности и анализа окружающей среды, тета-архивариусы Explores пришли к выводу,
        что былая жизнь и прежний облик Veliri 5 подверглись тотальной стерилизации вследствие неких судьбоносных
        планетарных событий. Предшественники или виновники случившегося, оставили после себя массу реликтовых руин:
        мега-города, храмовые комплексы и километровые монументы.</p>
      <p>Всё это теперь представляет особую ценность для синтетов, что целыми фракциями прибыли на Veliri 5. Но будь вы
        охотником за диковинными артефактами, или же авантюрист-изыскатель, этот мир таит немало тайн, богатств и
        открытий.</p>
    </template>

    <template v-if="lang === 'EN'">
      <h2 class="head" id="bodies">Veliri-5</h2>
      <p>Veliri-5 is the fifth in order, but the most potential of all other worlds in the Veliri binary star system.
        The special location of the fifth planet allows it to have a thin but stable atmosphere on its surface,
        predominantly consisting of a nitrogen-oxygen mixture, carbon dioxide, and water vapor.</p>

      <div class="detailImg" :style="{
              backgroundImage: 'url(' + $parent.getUrlImg('planet', 'jpg')+ ')',
              width: '400px',
              height: '225px',
              marginLeft: 'calc(50% - 200px)'
            }"/>

      <p>As reported by the first synthetic reconnaissance stations, the climate on the planet is harsh, characterized
        by sharp temperature fluctuations from 75°C during the day cycles to -40°C. Nevertheless, on Veliri-5, a simple
        yet diverse form of carbon-based life was discovered. These biomes can often be found in various corners of the
        planet: mountain ranges, wastelands, acidic tundras, and barren plains.</p>
      <p>After thorough cartography of the surface and analysis of the environment, the theta-archivists of Explores
        concluded that the former life and previous appearance of Veliri-5 underwent total sterilization due to some
        fateful planetary events. The predecessors or culprits of what happened left behind a mass of relic ruins:
        mega-cities, temple complexes, and kilometer-long monuments.</p>
      <p>All this now represents a special value for the synthetics, who arrived in whole factions on Veliri-5. But
        whether you are a hunter of exotic artifacts or an adventurer-researcher, this world holds many secrets, riches,
        and discoveries.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Planet",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
