<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Обучение: топливо</h2>

      <p>Транспорту необходимо топливо что бы использовать все свои возможности. Как работает топливо можно почитать на
        этой
        <router-link to="fuel">странице</router-link>
        .
      </p>

      <p>Что бы установить топливо перетащите его на корпус или ячейку.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>

      <p>Остатки топлива надо погрузить в инвентарь с названием "топливо", когда кончится энергия у корпуса то он
        автоматически начнет использовать топливо из этого инвентаря.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_2', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Fuel</h2>

      <p>The vehicle needs fuel to use all its capabilities. You can read how the fuel works on
        <router-link to="fuel">this page</router-link>
        .
      </p>

      <p>What is needed to install the fuel is to drag it onto the hull or cell.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_1', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>

      <p>The remaining fuel should be loaded into the inventory called «fuel», when the hull runs out of energy, it
        will automatically start using fuel from this inventory.</p>
      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_fuel_2', 'gif')+ ')',
            width: '437px',
            height: '189px',
            marginLeft: 'calc(50% - 218px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationFuel",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
