<template>
  <div id="prefabricatedMenuWrapper"
       ref="prefabricatedMenuWrapper"
       @mousedown="toUp"
       v-if="prefabricatedMenu.detail_bps">

    <div class="error_message" :class="{error_message_visible: noUsePrefabricationService}">
      <div style="margin-top: 14%"> {{ texts['error_1'][language] }}</div>
    </div>

    <div class="error_message" :class="{error_message_visible: prefabricatedNoItems}">
      <div style="margin-top: 14%"> {{ texts['error_2'][language] }}</div>
    </div>

    <div class="not_allow"
         v-if="relationBonuses && relationBonuses[baseStatus.fraction] && relationBonuses[baseStatus.fraction].locked_up_work">
      <div class="not_allow_text">
        {{ texts['not_allow'][language] }}
      </div>
    </div>

    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'prefabricatedMenuWrapper'"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 195, width: 400}"/>

    <div class="error_message" :class="{error_message_visible: prefabricatedResultToStorage}">
      <div style="margin-top: 14%" v-html="texts['to_storage'][language]"/>
    </div>

    <div id="itemsPrefabricatedMenu">

      <div v-for="(c, key) in blueprintsByCategory">
        <h4 class="cat_head" @click="tab === key ? tab = '' : tab = key">
          {{ tab === key ? '▼' : '▶' }} {{ handbook['categories'][language][key].name }}
        </h4>

        <template v-if="tab === key" v-for="i in c">

          <div class="detailItemBpBlockRow"
               @click="selectDetail(i)"
               style="pointer-events: auto !important;"
               :class="{disable: prefabricatedMenu.detail_max_counts[i.id] <= 0, selectRow: bpID === i.id}">

            <div class="detailItemBpBlockIcon">
              <app-background-item-cell v-bind:slotItem="{type: i.item_type, item: {name: i.item_name}}"/>
            </div>
            <div class="detailItemBpBlockName">{{ itemName(i) }}
              <span style="color: #fff16f">х{{ i.count }}</span>
            </div>
            <div class="detailItemBpBlockDetailButton cursor_hover" @click.stop="openDetail(i.item_type, i.item_id)"/>
            <div class="count">{{ prefabricatedMenu.detail_max_counts[i.id] }}</div>
          </div>

          <div class="craftPanel" v-if="bpID === i.id">
            <div class="controlPanelSelectDetail" :class="{disable: prefabricatedMenu.detail_max_counts[i.id] <= 0}">

              <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px; margin-left: 4px; width: 23px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(-100)">
                -100
              </div>

              <div class="range_button cursor_hover" style="border-radius: 0; width: 20px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(-10)">
                -10
              </div>

              <div class="range_button cursor_hover" style="border-radius: 0;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(-1)">
                -1
              </div>

              <input class="countCraftSelectDetailInput"
                     v-model="count"
                     @input="setCountSelectDetail($event)"
              />

              <div class="range_button cursor_hover" style="border-radius: 0;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(1)">
                +1
              </div>

              <div class="range_button cursor_hover" style="border-radius: 0; width: 20px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(10)">
                +10
              </div>

              <div class="range_button cursor_hover" style="width: 23px;"
                   @mouseover="playSound('select_sound.mp3', 0.3)"
                   @click="changeCount(100)">
                +100
              </div>

              <label class="craftSelectDetailLabel cursor_hover">
                <span class="taxCraftDetail">{{ texts['tax'][language] }} {{ prefabricatedMenu.tax }}%</span>
                <input class="craftSelectDetail"
                       type="button"
                       :value="texts['button_1'][language]"
                       :class="{disable: prefabricatedMenu.detail_max_counts[i.id] <= 0}"
                       @click="createDetail()"
                       @mouseover="playSound('select_sound.mp3', 0.3)">
              </label>
            </div>

            <div class="needItemsForDetails">
              <h4 class="needItemsForDetailsHead">{{ texts['result'][language] }}</h4>

              <div class="need_item" style="margin: 1px 1px 1px 12px;">
                <app-background-item-cell v-bind:slotItem="{type: i.item_type, item: {name: i.item_name}}"/>
                <div class="need_item_count">{{ count * i.count }}</div>
              </div>
            </div>

            <div class="needItemsForDetails" style="width: 163px">
              <h4 class="needItemsForDetailsHead">{{ texts['need_resources'][language] }}</h4>

              <div class="need_item"
                   v-for="item in prefabricatedMenu.select_detail.needItems"
                   v-if="item.quantity > 0">
                <app-background-item-cell v-bind:slotItem="item"/>
                <div class="need_item_count">{{ item.quantity }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import BackgroundItemCell from '../Inventory/BackgroundItemCell'

export default {
  name: "PrefabricatedMenu",
  data() {
    return {
      updater: null,
      sendRequest: false,
      tab: "",
      bpID: 0,
      count: 0,
    }
  },
  destroyed() {
    clearInterval(this.updater);
  },
  mounted() {
    let app = this;

    app.$store.commit({
      type: 'setPrefabricatedBpsMenu',
      detail_bps: null,
    });

    this.updater = setInterval(function () {

      app.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "OpenPrefabricatedMenu",
        }
      }));

      if (!app.prefabricatedMenu.detail_bps) {
        app.$store.dispatch("sendSocketData", JSON.stringify({
          service: "lobby",
          data: {
            event: "GetDetailBps",
          }
        }));
      }
    }, 250)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    resize(event, ui, el) {

    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
    itemName(item) {
      if (!item) {
        return ''
      }

      return this.handbook[item.item_type][this.language][item.item_name].name
    },
    openDetail(type, id) {
      this.$store.commit({
        type: 'toggleWindow',
        id: 'detailItem' + type + id,
        component: 'detailItem',
        meta: {itemType: type, itemID: id},
        forceOpen: true,
      });
    },
    selectDetail(i) {
      this.playSound('button_press.mp3', 0.3)

      this.bpID === i.id ? this.bpID = 0 : this.bpID = i.id
      this.count = 1

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "GetPrefabricatedNeedItems",
          id: this.bpID,
          count: this.count
        }
      }));
    },
    changeCount(change) {
      this.playSound('button_press.mp3', 0.3)
      this.setCountSelectDetail({target: {value: this.count + change}})
    },
    setCountSelectDetail(e) {
      let app = this;

      let count = Number(e.target.value)
      if (count > app.prefabricatedMenu.detail_max_counts[this.bpID]) {
        count = app.prefabricatedMenu.detail_max_counts[this.bpID]
      }

      this.count = Number(count)
      if (this.count < 0) {
        this.count = 0
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "GetPrefabricatedNeedItems",
          id: this.bpID,
          count: this.count
        }
      }));
    },
    createDetail() {
      this.playSound('button_press.mp3', 0.3)

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "lobby",
        data: {
          event: "CreatePrefabricatedItems",
          id: this.bpID,
          count: this.count,
          tax: this.prefabricatedMenu.tax
        }
      }));

      this.setCountSelectDetail({target: {value: this.count}})
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('PrefabricatedMenu')
    },
    prefabricatedMenu() {
      return this.$store.getters.getPrefabricatedMenuState
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    },
    blueprintsByCategory() {
      let cat = {}
      for (let i in this.prefabricatedMenu.detail_bps) {
        let bp = this.prefabricatedMenu.detail_bps[i];
        if (!cat[bp.item_type]) {
          cat[bp.item_type] = []
        }

        cat[bp.item_type].push(bp)
      }

      let app = this;
      for (let i in cat) {
        cat[i].sort(function (a, b) {
          return app.prefabricatedMenu.detail_max_counts[b.id] - app.prefabricatedMenu.detail_max_counts[a.id]
        });
      }

      return cat
    },
    prefabricatedResultToStorage() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('prefabricated_result_to_storage') && errors['prefabricated_result_to_storage'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'prefabricated_result_to_storage',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    noUsePrefabricationService() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('no_use_prefabrication_service') && errors['no_use_prefabrication_service'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'no_use_prefabrication_service',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
    prefabricatedNoItems() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('prefabricated_no_items') && errors['prefabricated_no_items'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'prefabricated_no_items',
            active: false,
          });
        }.bind(this), 1500)

        return true
      }

      return false
    },
  },
  components: {
    AppControl: Control,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
#prefabricatedMenuWrapper {
  position: absolute;
  display: block;
  border-radius: 5px;
  z-index: 11;
  width: 415px;
  height: 278px;
  top: 50px;
  left: calc(50% - 200px);
  padding: 20px 3px 3px;
  color: #ffe510;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(6px);
  pointer-events: auto;
  font-size: 12px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

#itemsPrefabricatedMenu {
  overflow-x: hidden;
  overflow-y: scroll;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  padding: 2px;
  background: rgba(137, 150, 156, 0.3);
}

.count {
  z-index: 1;
  position: absolute;
  top: 21px;
  left: 0;
  color: wheat;
  font-size: 12px;
  font-weight: bold;
}

.disable {
  opacity: 0.3;
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 6px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.not_allow {
  height: calc(100% - 16px);
  width: 100%;
  position: absolute;
  left: 0;
  top: 17px;
  background: rgba(125, 125, 125, 0.3);
  z-index: 20;
  backdrop-filter: blur(10px);
}

.not_allow_text {
  margin-top: 15%;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  opacity: 0.8;
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 1000ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}

.cat_head {
  margin: 0;
  width: calc(100% - 14px);
  padding: 2px;
  background: #df6f2c;
  padding-left: 15px;
  box-shadow: 0 0 2px black;
  font-size: 12px;
  clear: both;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 13px;
}

.detailItemBpBlockRow {
  background: rgba(0, 0, 0, 0.2);
  float: left;
  width: calc(100% - 4px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 2px;
  transition: 200ms;
  position: relative;
  user-select: none;
}

.detailItemBpBlockRow:hover {
  background: rgba(0, 0, 0, 0.1);
}

.detailItemBpBlockIcon {
  position: relative;
  height: 30px;
  width: 30px;
  float: left;
}

.detailItemBpBlockName {
  float: left;
  line-height: 30px;
  color: white;
  margin-left: 10px;
}

.detailItemBpBlockDetailButton {
  float: right;
  height: 20px;
  width: 20px;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
  margin-top: 4px;
  background-image: url('../../assets/icons/question-mark.png');
  position: absolute;
  right: 5px;
  top: 4px;
  pointer-events: all !important;
}

.detailItemBpBlockDetailButton:active {
  transform: scale(0.95);
}

.detailItemBpBlockDetailButton:hover {
  box-shadow: 0 0 4px white;
  color: white;
}

.craftPanel {
  float: left;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 0;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.2);
}

.selectRow {
  border-bottom: 0;
}

.needItemsForDetails {
  float: right;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 1px black;
  border-radius: 0;
  padding-left: 2px;
  width: 55px;
  margin-right: 2px;
  overflow: hidden;
}

.needItemsForDetailsHead {
  clear: both;
  width: calc(100% - 2px);
  height: 14px;
  font-size: 10px;
  line-height: 14px;
  margin: 0 0 1px -2px;
  padding-left: 3px;
  border: 1px solid #793f1f;
  background: rgba(251, 93, 0, 0.55);
  color: #ff7800;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
}

.controlPanelSelectDetail {
  float: left;
  width: calc(100% - 230px);
}

.countCraftSelectDetailInput {
  float: left;
  width: calc(100% - 130px);
  height: 12px;
  color: #ffe510;
  background: rgb(0, 0, 0, 0.3);
  box-shadow: none;
  line-height: 12px;
  border-radius: 0;
  margin-top: 6px;
}

.craftSelectDetailLabel {
  float: left;
  width: calc(100% - 8px);
  background: rgba(0, 0, 0, 0.24);
  height: 18px;
  padding: 0 2px 4px 4px;
  margin: 6px 0 0 3px;
  border-radius: 3px;
  pointer-events: none;
}

.craftSelectDetailLabel span {
  float: left;
  line-height: 23px;
  margin-left: 4px;
  font-size: 10px;
}

.craftSelectDetail {
  float: right;
  pointer-events: auto;
  width: 60px;
  display: block;
  margin: 3px auto 0;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.need_item {
  height: 30px;
  width: 30px;
  position: relative;
  float: left;
  background: rgba(0, 0, 0, 0.1);
  margin: 1px;
  border-radius: 3px;
  box-shadow: inset 0 0 1px black;
}

.need_item_count {
  font-size: 9px;
  left: 1px;
  top: 1px;
  z-index: 1;
  position: absolute;
  font-weight: bold;
}
</style>
