<template>
  <div class="Corporation" id="Corporation" ref="Corporation" @mousedown="toUp">

    <app-control v-bind:head="texts['window_name_1'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'Corporation'"
                 v-bind:noHeight="true"
                 v-bind:noWidth="true"
                 v-bind:resizeFunc="resize"
                 v-bind:minSize="{height: 245, width: 420}"/>

    <div class="tabs" v-if="corporation.info">
      <div v-bind:class="{active: menu==='search'}" class="cursor_hover" @click="menu = 'search'">
        {{ texts['tab_1'][language] }}
      </div>
      <div v-bind:class="{active: menu==='main'}" class="cursor_hover" @click="menu = 'main'">
        {{ texts['tab_2'][language] }}
      </div>
      <div v-bind:class="{active: menu==='members'}" class="cursor_hover" @click="menu = 'members'">
        {{ texts['tab_3'][language] }}
      </div>
      <div v-bind:class="{active: menu==='roles'}" class="cursor_hover" @click="menu = 'roles'">
        {{ texts['tab_4'][language] }}
      </div>
      <div v-bind:class="{active: menu==='requests'}" class="cursor_hover" @click="menu = 'requests'">
        {{ texts['tab_5'][language] }}
      </div>
      <div v-bind:class="{active: menu==='politics'}" class="cursor_hover" @click="menu = 'politics'">
        {{ texts['tab_6'][language] }}
      </div>
      <div v-bind:class="{active: menu==='wars'}" class="cursor_hover" @click="menu = 'wars'">
        {{ texts['tab_7'][language] }}
      </div>
      <div v-bind:class="{active: menu==='audit'}" class="cursor_hover" @click="menu = 'audit'">
        {{ texts['tab_11'][language] }}
      </div>
    </div>

    <div class="tabs" v-if="!corporation.info">
      <div v-bind:class="{active: menu==='main'}" class="cursor_hover" @click="menu = 'main'">
        {{ texts['tab_8'][language] }}
      </div>
      <div v-bind:class="{active: menu==='search'}" class="cursor_hover" @click="menu = 'search'">
        {{ texts['tab_9'][language] }}
      </div>
      <div v-bind:class="{active: menu==='create'}" class="cursor_hover" @click="menu = 'create'">
        {{ texts['tab_10'][language] }}
      </div>
    </div>

    <div class="wrap">

      <div v-if="!corporation.info && !(menu === 'create' || menu === 'search')" style="height: 100%">
        <div style="height: 100%">
          <span style="color: white; text-shadow: 1px 1px 1px black;">{{ texts['text_1'][language] }}</span>
          <br>
          <br>
          <br>
          <input class="cluster_button cursor_hover"
                 type="button"
                 :value="texts['button_1'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeTab('search')">
          <input class="cluster_button cursor_hover"
                 type="button"
                 :value="texts['button_2'][language]"
                 @mouseover="playSound('select_sound.mp3', 0.3)"
                 @click="changeTab('create')">
        </div>
      </div>

      <app-main v-if="corporation.info && (menu==='main' || menu==='create')"/>
      <app-members v-if="corporation.info && menu==='members'"/>
      <app-roles v-if="corporation.info && menu==='roles'"/>
      <app-requests v-if="corporation.info && menu==='requests'"/>
      <app-policy v-if="corporation.info && menu==='politics'"/>
      <app-wars v-if="corporation.info && menu==='wars'"/>
      <app-audit v-if="corporation.info && menu==='audit'"/>

      <app-create v-if="!corporation.info && menu==='create'"/>
      <app-search v-if="menu==='search'" v-bind:corp_name="meta && meta.corp_name ? meta.corp_name : ''"/>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Main from './Main';
import Members from './Members';
import Create from './Create';
import Search from './Search';
import Roles from './Roles';
import Requests from './Requests';
import Policy from './Policy';
import Wars from './Wars';
import Audit from './Audit';

export default {
  name: "Corporation",
  props: ['meta'],
  data() {
    return {
      updater: null,
      menu: 'main',
    }
  },
  destroyed() {
    if (this.updater) clearInterval(this.updater)
  },
  mounted() {
    if (this.$props.meta && this.$props.meta.corp_name && this.$props.meta.corp_name !== '') {
      this.menu = 'search'
    }
  },
  created() {
    let app = this;
    // app.updater = setInterval(function () {
    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporation",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetAllCorporationOffices",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporationDeposits",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetCorporationBases",
      }
    }));

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetContacts",
      }
    }));
    // }, 1000);
  },
  methods: {
    resize(event, ui, el) {

    },
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    changeTab(tab) {
      this.playSound('button_press.mp3', 0.3)
      this.menu = tab
    },
    toUp() {
      this.$store.commit({
        type: 'setWindowZIndex',
        id: this.$el.id,
      });
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Corporation')
    },
  },
  components: {
    AppControl: Control,
    AppMain: Main,
    AppCreate: Create,
    AppSearch: Search,
    AppMembers: Members,
    AppRoles: Roles,
    AppRequests: Requests,
    AppPolicy: Policy,
    AppWars: Wars,
    AppAudit: Audit,
  }
}
</script>

<style scoped>
.Corporation {
  position: absolute;
  display: inline-block;
  background-size: 100% 2px;
  border-radius: 5px;
  width: 420px;
  z-index: 10;
  top: 30%;
  left: calc(50% - 60px);
  padding: 20px 3px 3px 3px;
  box-shadow: 0 0 2px;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  height: 245px;
  pointer-events: auto;
}

.wrap {
  width: calc(100% - 14px);
  background: rgba(137, 150, 156, 0.3);
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  height: calc(100% - 29px);
  padding: 7px;
  font-size: 12px;
}

.wrap.empty {
  height: calc(100% - 14px);
}

.tabs {
  height: 15px;
  width: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.tabs div:last-child {
  border-left: 0;
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42);
}

.tabs div:hover {
  background: rgb(235, 98, 44);
}

.tabs div:active {
  transform: scale(0.98);
}

.cluster_button {
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: .1s;
  background: rgba(255, 129, 0, .6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  width: 75px;
}

.cluster_button:active {
  transform: scale(.98);
}

.cluster_button:hover {
  background: #ff8100;
}
</style>
