<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <div class="content_section">
        <h2 class="head">Захват сектора</h2>
        <p>Захват сектора - это способ доказать свою силу и влияние на планете. Это также способ получить доступ к
          ресурсам и аномалиям, которые находятся на секторе. Для того, чтобы захватить сектор, вам нужно контролировать
          большинство ключевых точек на карте. Это могут быть стратегические объекты, такие как базы, мосты, заводы и
          т.д.</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_1', 'png')+ ')',
        width: '209px',
        height: '216px',
        marginLeft: 'calc(50% - 104px)',
      }"/>
      </div>
      <div class="content_section">
        <p>Каждый сектор имеет определенное количество очков захвата, которые показывают, насколько он подчинен той или
          иной фракции. Когда все очки захвата принадлежат одной фракции, сектор считается захваченным этой фракцией.
          Когда все очки захвата не принадлежат никому, сектор считается свободным.</p>
        <p>Одна захваченная ключевая точка - приносит 1 очко захвата владельцу точки.</p>
        <p>Одна не захваченная ключевая точка - отнимает по 1му очку у <span class="importantly">каждой</span> фракции.
        </p>
        <p>Если 3 фракции заняли по 1 точке, то все получаю 0 очков. Каждая фракция забрала по 1 очку из общего пула, но
          т.к. свободных очков нет, то они отняли друг у друга и в итоге у каждого +0</p>
        <p>Если 1 фракция захватила все точки то сектор будет захвачен за 5 минут (N / 3 очка в секунду).
          Необязательно ждать в секторе если вы уверены что на вас не нападут.</p>
        <p>Фракция АПД не захватывает сектора но делает их нейтральными.</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_2', 'png')+ ')',
        width: '328px',
        height: '66px',
        marginLeft: 'calc(50% - 164px)',
      }"/>
        <p>Справа от шкалы захвата сектора вы можете видеть текущую фазу войны. Слева от шкалы вы можете видеть текущего
          владельца сектора.</p>
        <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_3', 'png')+ ')',
        width: '323px',
        height: '63px',
        marginLeft: 'calc(50% - 161px)',
      }"/>
      </div>
    </template>
    <template v-if="lang==='EN'">
      <div class="content_section">
        <h2 class="head">Sector Capture</h2>
        <p>Capturing a sector is a way to prove your strength and influence on the planet. It is also a way to gain
          access to resources and anomalies located within the sector. To capture a sector, you need to control the
          majority of key points on the map. These can be strategic objects such as bases, bridges, factories, etc.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_1', 'png')+ ')',
          width: '209px',
          height: '216px',
          marginLeft: 'calc(50% - 104px)',
        }"/>
      </div>
      <div class="content_section">
        <p>Each sector has a certain number of capture points that show how much it is under the control of a particular
          faction. When all capture points belong to one faction, the sector is considered captured by that faction.
          When all capture points belong to no one, the sector is considered free.</p>
        <p>One captured key point brings 1 capture point to the owner of the point.</p>
        <p>One uncaptured key point takes away 1 point from <span class="importantly">each</span> faction.</p>
        <p>If 3 factions occupy 1 point each, then all get 0 points. Each faction took 1 point from the common pool, but
          since there are no free points, they took from each other and in the end, each got +0</p>
        <p>If 1 faction captures all points then the sector will be captured in 5 minutes (N / 3 points per second). It
          is not necessary to wait in the sector if you are sure that you will not be attacked.</p>
        <p>The APD faction does not capture sectors but makes them neutral.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_2', 'png')+ ')',
          width: '328px',
          height: '66px',
          marginLeft: 'calc(50% - 164px)',
        }"/>
        <p>To the right of the sector capture scale, you can see the current phase of the war. To the left of the scale,
          you can see the current owner of the sector.</p>
        <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('capture_sector_3', 'png')+ ')',
          width: '323px',
          height: '63px',
          marginLeft: 'calc(50% - 161px)',
        }"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CaptureSector",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
