<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Ангар</h2>

      <p>Ангар - это меню, которое позволяет вам настраивать ваш транспорт и просматривать все его характеристики. Ангар
        доступен везде для просмотра характеристик, но настраивать транспорт можно только на базе, где есть необходимое
        оборудование.</p>

      <p>В меню <span class="importantly">“Транспорты на базе”</span> вы можете видеть активные транспорты на текущей
        базе
        и быстро переключаться между
        ними. Это удобно, если вы хотите сравнить разные транспорты или использовать разные транспорты для разных
        задач.</p>

      <p>Если вы устанавливаете новый корпус на ваш транспорт, то старый корпус со всем содержимым не пропадает, а
        появляется в меню <span class="importantly">“Транспорты на базе”</span>. Вы можете вернуться к старому корпусу
        выбрав его в этом меню.</p>

      <p>Если вы хотите поменять имя вашего транспорта или переместить его на склад, то используйте две кнопки в
        заголовке. На складе вы можете хранить свои транспорты без ограничений и без риска потери их при смерти.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('hangar_1', 'png')+ ')',
            width: '480px',
            height: '348px',
            marginLeft: 'calc(50% - 240px)',
            }"/>

      <p>Подробнее о
        <router-link to="bodies">корпусах</router-link>
        ,
        <router-link to="equips">снаряжение</router-link>
        и
        <router-link to="weapons">оружие</router-link>
        .
      </p>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Hangar</h2>

      <p>The hangar is a menu that allows you to customize your transport and view all its characteristics. The hangar
        is accessible everywhere for viewing characteristics, but you can only configure your transport at a base where
        the necessary equipment is available.</p>

      <p>In the <span class="importantly">"Transports at Base"</span> menu, you can see the active transports at the
        current base and quickly switch between them. This is convenient if you want to compare different transports or
        use different ones for various tasks.</p>

      <p>If you install a new hull on your transport, the old hull with all its contents doesn't disappear; it appears
        in the <span class="importantly">"Transports at Base"</span> menu. You can revert to the old hull by selecting
        it in this menu.</p>

      <p>If you want to change your transport's name or move it to storage, use the two buttons in the header. In
        storage, you can keep your transports without restrictions and without the risk of losing them upon death.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('hangar_1', 'png')+ ')',
            width: '480px',
            height: '348px',
            marginLeft: 'calc(50% - 240px)',
            }"/>

      <p>Learn more about
        <router-link to="bodies">hulls</router-link>
        ,
        <router-link to="equips">equipment</router-link>
        ,
        and
        <router-link to="weapons">weapons</router-link>
        .
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "Hangar",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
