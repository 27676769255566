import {drawConnectState} from "../interface/status_bar";

let oldTime = null
let internetConnectionStabilitys = []

function calcPing(serverTime) {

  let clientTime = new Date().getTime()

  if (!oldTime) {
    oldTime = {serverTime: serverTime, clientTime: clientTime}
    return
  }

  // console.log("serverTime: ", serverTime, "clientTime: ", clientTime)
  // console.log("serverDiff: ", serverTime - oldTime.serverTime, "clientDiff: ", clientTime - oldTime.clientTime)
  let serverDiff = serverTime - oldTime.serverTime // время выполнения тика на сервере
  let clientDiff = clientTime - oldTime.clientTime // разница по времени от прошлого до текущего пакета
  let internetConnectionStability = clientDiff - serverDiff // время затраченое на пересылку сообщения с учетом сервер тика

  internetConnectionStabilitys.push(internetConnectionStability)
  if (internetConnectionStabilitys.length > 32) {
    internetConnectionStabilitys.shift()
  }

  let avg = Math.round(average(internetConnectionStabilitys))
  // TODO выводить гденить: console.log("internetConnectionStability: ", avg, "state: ", avg < 5 && avg > -5 ? 'normal' : 'bad')
  oldTime = {serverTime: serverTime, clientTime: clientTime}

  let connect, color

  if (avg < 2 && avg > -2) {
    connect = 'good'
    color = 0x00ff00
  } else if (avg < 5 && avg > -5) {
    connect = 'bad'
    color = 0xfff200
  } else {
    connect = 'terrible'
    color = 0xff0000
  }

  if (connect !== 'good') drawConnectState(connect, color)
}

function average(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }
  return sum / arr.length;
}

export {calcPing}
