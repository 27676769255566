var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_section"},[(_vm.lang==='RU')?[_c('h2',{staticClass:"head"},[_vm._v("Глобальная политика")]),_vm._v(" "),_c('p',[_vm._v("Кластеры могут выставлять политики в другим кластерам, но текущий момент это меню позволяет указать список\n      \"Союзников\"/\"Соперников\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('global_policy_1', 'png')+ ')',
          width: '368px',
          height: '401px',
          marginLeft: 'calc(50% - 184px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Этот список необходим для более гибкой установки правил локальной политики, а так же у соперников и союзников\n      появится иконка рядом с ником в мире.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('global_policy_2', 'png')+ ')',
          width: '353px',
          height: '137px',
          marginLeft: 'calc(50% - 176px)',
          })}),_vm._v(" "),_c('p',[_vm._v("Эти списки не вносят никакие директивы для систем правосудия или еще каких, а только информарует игрока что\n      перед ним союзник или соперник.")])]:_vm._e(),_vm._v(" "),(_vm.lang==='EN')?[_c('h2',{staticClass:"head"},[_vm._v("Global Policy")]),_vm._v(" "),_c('p',[_vm._v("Clusters can set policies towards other clusters, but at the moment this menu allows specifying a list of\n      \"Allies\"/\"Rivals\".")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('global_policy_1', 'png')+ ')',
          width: '368px',
          height: '401px',
          marginLeft: 'calc(50% - 184px)',
          })}),_vm._v(" "),_c('p',[_vm._v("This list is necessary for more flexible setting of local policy rules, and also an icon will appear next to\n      the nickname in the world for rivals and allies.")]),_vm._v(" "),_c('div',{staticClass:"detailImg",style:({
          backgroundImage: 'url(' + _vm.$parent.getUrlImg('global_policy_2', 'png')+ ')',
          width: '353px',
          height: '137px',
          marginLeft: 'calc(50% - 176px)',
          })}),_vm._v(" "),_c('p',[_vm._v("These lists do not introduce any directives for the justice system or others, but only inform the player that\n      they are facing an ally or a rival.")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }