<template>
  <div class="content_section">
    <template v-if="lang==='RU'">

      <h2 class="head">Навыки</h2>

      <p>Синтеты, не смотря на свою неорганическую природу, являются существами развивающимися. Как и все прочие, они
        приобретают опыт, учатся на ошибках и приобретают навыки. Всё это позволяет синтету более эффективно
        использовать
        свои ресурсы и возможности.</p>

      <p>Игроку, чтобы узнать возможно ли улучшить собственные навыки, следует перейти в раздел - “Личное дело”. </p>

      <div class="detailImg" :style="{
        backgroundImage: 'url(' + $parent.getUrlImg('skills_menu_1', 'png')+ ')',
        width: '400px',
        height: '281px',
        marginLeft: 'calc(50% - 200px)',
    }"/>

      <p>Для того, чтобы прокачивать навыки, нужно зарабатывать опыт, который получается из разных действий:</p>
      <ul style="float: left">
        <li><p><span class="importantly">Создание предметов</span> - за каждые 10 базовых ресурсов, использованных в
          производстве, вы получаете одно очко опыта.
          Это отражает ваше мастерство в создании полезных вещей из сырья.</p>
        </li>
        <li><p><span class="importantly">Переработка руды</span> - за каждую переработанную руду вы получаете одно очко
          опыта. Это отражает ваше знание
          химических и физических свойств разных минералов.</p>
        </li>
        <li><p><span class="importantly">Создание деталей</span> - за каждые два базовых ресурса, использованных для
          создания деталей, вы получаете одно очко
          опыта. Это отражает вашу способность собирать сложные конструкции из простых элементов.</p>
        </li>
        <li><p><span class="importantly">Добыча руды</span> - за каждый кубический метр извлеченной руды вы получаете
          десять очков опыта. Это отражает ваши
          навыки при работе с горнодобывающим оборудованием и скоростью поиска полезных ископаемых. </p>
        </li>
        <li><p><span class="importantly">Добыча нефти</span> - за каждую бочку нефти вы получаете тридцать очков опыта.
          Это напрямую отображает ваши навыки при эксплуатации нефтяных месторождений.</p>
        </li>
        <li><p><span class="importantly">Выполнение миссии</span> - за каждые пять кредитов, полученных за выполнение
          миссии, вы получаете одно очко опыта.
          Это отображает ваше рвение к выполнению различных поручений и доведению данных дел до конца.</p>
        </li>
        <li><p><span class="importantly">Продажа товаров</span> - за каждые сто кредитов, полученных за продажу товаров,
          вы получаете одно очко опыта. Это
          отражает вашу смекалку в торговле и умение находить выгодные сделки.</p>
        </li>
        <li><p><span class="importantly">Нанесенный урон</span> - за каждые десять единиц урона, нанесенного врагам, вы
          получаете одно очко опыта. Это отображает ваши умения вести бой, метко стрелять и ориентироваться при опасных
          ситуациях. </p>
        </li>
        <li><p><span class="importantly">Убийство</span> - за каждое убийство врага вы получаете пятьдесят очков опыта,
          умноженных на размер корпуса врага
          (50, 100 или 150). Это отражает вашу силу в бою и способность уничтожать противников разного класса.</p>
        </li>
      </ul>
    </template>
    <template v-if="lang==='EN'">
      <h2 class="head">Skills</h2>

      <p>Synthetics, despite their inorganic nature, are evolving beings. Like all others, they gain experience, learn
        from mistakes, and acquire skills. All this allows a synthetic to use its resources and capabilities more
        effectively.</p>

      <p>To find out if it is possible to improve their own skills, the player should go to the "Personal File"
        section.</p>

      <div class="detailImg" :style="{
          backgroundImage: 'url(' + $parent.getUrlImg('skills_menu_1', 'png')+ ')',
          width: '400px',
          height: '281px',
          marginLeft: 'calc(50% - 200px)',
      }"/>

      <p>To level up skills, you need to earn experience, which comes from different actions:</p>
      <ul style="float: left">
        <li><p><span class="importantly">Creating items</span> - for each basic resource used in production, you get one
          experience point. This reflects your mastery in creating useful things from raw materials.</p></li>
        <li><p><span class="importantly">Ore processing</span> - for each processed ore you get one experience point.
          This reflects your knowledge of the chemical and physical properties of different minerals.</p></li>
        <li><p><span class="importantly">Creating parts</span> - for every two basic resources used to create parts, you
          get one experience point. This reflects your ability to assemble complex structures from simple elements.</p>
        </li>
        <li><p><span class="importantly">Mining ore</span> - for every cubic meter of extracted ore you get ten
          experience points. This reflects your skills in working with mining equipment and speed in finding valuable
          minerals.</p></li>
        <li><p><span class="importantly">Oil extraction</span> - for each barrel of oil you get thirty experience
          points. This directly reflects your skills in operating oil fields.</p></li>
        <li><p><span class="importantly">Completing a mission</span> - for every five credits earned for completing a
          mission, you get one experience point. This reflects your zeal in carrying out various assignments and
          bringing these matters to an end.</p></li>
        <li><p><span class="importantly">Selling goods</span> - for every hundred credits earned from selling goods, you
          get one experience point. This reflects your shrewdness in trading and ability to find profitable deals.</p>
        </li>
        <li><p><span class="importantly">Inflicted damage</span> - for every ten units of damage inflicted on enemies,
          you get one experience point. This reflects your skills in combat, accurate shooting, and orientation in
          dangerous situations.</p></li>
        <li><p><span class="importantly">Killing</span> - for each enemy killed you get fifty experience points,
          multiplied by the size of the enemy's hull (50, 100, or 150). This reflects your strength in battle and the
          ability to destroy opponents of different classes.</p></li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "Skills",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
