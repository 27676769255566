<template>
  <div id="News" ref="News">
    <app-control v-bind:head="texts['window_name'][language]"
                 v-bind:move="true"
                 v-bind:close="true"
                 v-bind:refWindow="'News'"
                 v-bind:resizeFunc="resize"
                 v-bind:noWidth=true
                 v-bind:maxSize="{height: null, width: 460}"
                 v-bind:minSize="{height: 231, width: 310}"/>

    <div class="tabs">
      <div v-bind:class="{active: menu==='news'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="selectMenu('news')">
        {{ texts['tab_1'][language] }}
      </div>
      <div v-bind:class="{active: menu==='search'}" class="cursor_hover"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="selectMenu('search')">
        {{ texts['tab_2'][language] }}
      </div>
    </div>

    <template v-if="menu==='news'">
      <div class="art_head">
        <div class="art_back" :style="{backgroundImage:  'url(' + baseBackground.back + ')'}">
          <div class="art_presenter" :style="{backgroundImage:  'url(' + baseBackground.presenter + ')'}"/>
        </div>
      </div>

      <div class="tabs">

        <div v-bind:class="{active: news==='main'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('main')">
          {{ texts['n_tab_1'][language] }}
        </div>

        <div v-bind:class="{active: news==='trade'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('trade')">
          {{ texts['n_tab_2'][language] }}
        </div>

        <div v-bind:class="{active: news==='fraction_war'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('fraction_war')">
          {{ texts['n_tab_3'][language] }}
        </div>

        <div v-bind:class="{active: news==='expedition'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('expedition')">
          {{ texts['n_tab_4'][language] }}
        </div>

        <div v-bind:class="{active: news==='production'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('production')">
          {{ texts['n_tab_5'][language] }}
        </div>

        <div v-bind:class="{active: news==='corporations'}" class="cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="changeNewsChannel('corporations')">
          {{ texts['n_tab_6'][language] }}
        </div>
      </div>

      <div class="wrap">
        <app-notify v-for="notify in notifys"
                    :key="notify.uuid"
                    v-bind:notify="notify"/>
        <div style="height: 0; width: 100%; float: left;"></div>
      </div>
    </template>

    <template v-if="menu==='search'">
      <div class="wrap_search">

        <div class="wrap_search_types">
          <label @click="searchType='person'" class="cursor_hover" :class="{search_active: searchType==='person'}"
                 style="margin-left: 4px">
            {{ texts['search_t_1'][language] }}
          </label>
          <label style="width: 64px" @click="searchType='base'" class="cursor_hover"
                 :class="{search_active: searchType==='base'}">
            {{ texts['search_t_2'][language] }}
          </label>
          <label @click="searchType='product'" class="cursor_hover" :class="{search_active: searchType==='product'}"
                 style="margin-right: 0">
            {{ texts['search_t_3'][language] }}
          </label>
        </div>

        <div class="settings">
          <div v-if="searchType === 'person'">
            <div class="search_input">
              <input type="text" class="cursor_hover" placeholder="search" v-model="search"/>
              <div class="search_button cursor_hover" @click="Search()">{{ texts['button_1'][language] }}</div>
            </div>
          </div>
          <div v-if="searchType === 'base'">
            <div class="type_base_wrapper cursor_hover" @click="searchBase.type='processing_plant'"
                 :class="{type_base_active: searchBase.type==='processing_plant'}">
              <div class="type_base"
                   :style="{backgroundImage: 'url(' + require('../../assets/icons/processing_plant_icon.png').default + ')'}"
                   :title="texts['base_t_1'][language]"/>
            </div>
            <div class="type_base_wrapper cursor_hover" @click="searchBase.type='base'"
                 :class="{type_base_active: searchBase.type==='base'}">
              <div class="type_base"
                   :style="{backgroundImage: 'url(' + require('../../assets/icons/productions.png').default + ')'}"
                   :title="texts['base_t_2'][language]"/>
            </div>
            <div class="type_base_wrapper cursor_hover" @click="searchBase.type='prefabricated_plant'"
                 :class="{type_base_active: searchBase.type==='prefabricated_plant'}">
              <div class="type_base"
                   :style="{backgroundImage: 'url(' + require('../../assets/icons/category_details.png').default + ')'}"
                   :title="texts['base_t_3'][language]"/>
            </div>

            <div class="range_wrapper">
              <div class="head">{{ texts['range_1'][language] }} {{ Math.round(searchBase.tax) }}%</div>
              <div>
                <div class="range">
                  <input class="cursor_hover" @input="setBaseSearch($event, 'tax')" type="range" min="0" max="75"
                         step="1"
                         :value="searchBase.tax">
                </div>
              </div>
            </div>

            <div class="range_wrapper">
              <div class="head">{{ texts['range_2'][language] }} {{ Math.round(searchBase.dist) }}</div>
              <div>
                <div class="range">
                  <input class="cursor_hover" @input="setBaseSearch($event, 'dist')" type="range" min="0" max="10"
                         step="1"
                         :value="searchBase.dist">
                </div>
              </div>
            </div>

            <div class="search_button big cursor_hover" @click="Search()">Найти (0.3 cr.)</div>
          </div>

          <div v-if="searchType === 'product' && products">

            <div class="type_product_wrapper cursor_hover" @click="searchProduct.id=product.id"
                 v-for="product in products"
                 :class="{type_base_active: searchProduct.id===product.id}">
              <app-background-item-cell v-bind:noShowName="true" v-bind:slotItem="{type: 'product', item: product}"/>
            </div>

            <div class="range_wrapper">
              <div class="head">{{ texts['range_2'][language] }} {{ Math.round(searchProduct.dist) }}</div>
              <div>
                <div class="range">
                  <input class="cursor_hover" @input="setProductSearch($event, 'dist')" type="range" min="0" max="10"
                         step="1"
                         :value="searchProduct.dist">
                </div>
              </div>
            </div>

            <div class="input_number_row">
              <div class="title_row">{{ texts['text_1'][language] }}</div>

              <div style="float: right; width: 100px">
                <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(-1, 1, 500, 'count')">-1
                </div>
                <input type="number" class="cursor_hover" v-model="searchProduct.count" min="1" max="500">
                <div class="range_button cursor_hover" style="float: right;"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(1, 1, 500, 'count')">
                  +1
                </div>
              </div>
            </div>

            <div class="input_number_row">
              <div class="title_row">{{ texts['text_2'][language] }}</div>

              <div style="float: right; width: 100px">
                <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(-0.1, 0.1, 50, 'sellPrice')">-.1
                </div>
                <input type="number" class="cursor_hover" v-model="searchProduct.sellPrice" min="0.1" step="0.1"
                       max="50">
                <div class="range_button cursor_hover" style="float: right;"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(0.1, 0.1, 50, 'sellPrice')">
                  +.1
                </div>
              </div>
            </div>

            <div class="input_number_row">
              <div class="title_row">{{ texts['text_3'][language] }}</div>

              <div style="float: right; width: 100px">
                <div class="range_button cursor_hover" style="border-radius: 5px 0 0 5px"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(-0.1, 0.1, 50, 'buyPrice')">-.1
                </div>
                <input type="number" class="cursor_hover" v-model="searchProduct.buyPrice" min="0.1" step="0.1"
                       max="50">
                <div class="range_button cursor_hover" style="float: right;"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="changeQuantity(0.1, 0.1, 50, 'buyPrice')">
                  +.1
                </div>
              </div>
            </div>

            <div class="search_button big cursor_hover" @click="Search()">{{ texts['button_1'][language] }}</div>
          </div>
        </div>

        <div class="result_wrapper" v-if="SearchResult.type && SearchResult.type === searchType">
          <template v-if="SearchResult.type === 'person'">

            <div class="not_found" v-if="SearchResult && SearchResult.data.length === 0">
              {{ texts['nothing'][language] }}
            </div>

            <div class="person_result" v-for="d in SearchResult.data" v-if="d">

              <app-user-line v-bind:user="d.common_info"
                             v-bind:parent="'search'"
              />

              <div class="destination">
                <div>
                  {{ texts['target_1'][language] }} <span class="importantly">
                  {{ handbook['region'][language][d.region_name].name }}</span>

                  <template v-if="d.map_name !== ''">
                    <br> {{ texts['target_2'][language] }} <span class="importantly">
                    {{ handbook['sector'][language][d.map_name].name }}</span>
                  </template>

                  <template v-if="d.base_name !== ''">
                    <br> {{ texts['target_3'][language] }} <span class="importantly">
                    {{ handbook['base'][language][d.base_name].name }}</span>
                  </template>

                  <div class="map_button cursor_hover"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click="openService('GlobalViewMap', d)">
                    {{ texts['button_2'][language] }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="SearchResult.type === 'base' || SearchResult.type === 'product'">

            <div class="not_found" v-if="SearchResult && SearchResult.data.length === 0">
              {{ texts['nothing'][language] }}
            </div>

            <div class="base_result" v-for="d in SearchResult.data"
                 :class="{production: SearchResult.type === 'product'}">

              <div class="base_section" :class="{production: SearchResult.type === 'product'}">
                <div style="float: left; width: 100%;">
                  <div class="icon"
                       :style="{backgroundImage: 'url(' + baseIcon(d) + ')'}">
                    <div class="icon fraction" v-if="d.fraction !== ''"
                         :style="{backgroundImage: 'url(' + getFractionLogo(d) +')'}"/>
                  </div>

                  <div class="baseName">
                    <p>{{ baseName(d) }}</p>
                  </div>

                  <div class="base_tax" v-if="SearchResult.type === 'base'">
                    <p><span style="color: white">{{ texts['text_4'][language] }}</span> {{ d.tax }}%<span
                      style="color: white">, {{ texts['text_5'][language] }}</span> {{ d.dist }}</p>
                  </div>

                  <div class="product_icon" v-if="SearchResult.type === 'product'">
                    <app-background-item-cell v-bind:noShowName="true"
                                              v-bind:slotItem="{type: 'product', item: getProductByID(d.product_id)}"/>
                  </div>

                  <div class="base_tax" v-if="SearchResult.type === 'product'">
                    <p>
                      <span style="color: white">{{ texts['text_6'][language] }}</span> {{ d.dist }},
                      <span style="color: white">{{ texts['text_7'][language] }}</span> {{ d.count }}
                    </p>
                    <p style="margin-top: 16px;font-size: 12px;line-height: 21px;">
                      <span style="color: white">{{ texts['text_8'][language] }}</span>
                      {{ (d.buy_price / 100).toFixed(2) }}
                      <span style="color: rgba(128, 255, 130, 0.85)">cr.</span><br>
                      <span style="color: white">{{ texts['text_9'][language] }}</span>
                      {{ (d.sell_price / 100).toFixed(2) }}
                      <span style="color: rgba(128, 255, 130, 0.85)">cr.</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="destination">
                <div>
                  {{ texts['target_1'][language] }} <span class="importantly">
                  {{ handbook['region'][language][d.region_name].name }}</span>

                  <template v-if="d.map_name !== ''">
                    <br> {{ texts['target_2'][language] }} <span class="importantly">
                    {{ handbook['sector'][language][d.map_name].name }}</span>
                  </template>

                  <div class="map_button cursor_hover"
                       @mouseover="playSound('select_sound.mp3', 0.3)"
                       @click="openService('GlobalViewMap', d)">
                    {{ texts['button_2'][language] }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Notify from '../News/Notify';
import UserLine from "../Chat/UserLine";
import {gameStore} from "../../game/store";
import BackgroundItemCell from '../Inventory/BackgroundItemCell'

export default {
  name: "News",
  data() {
    return {
      news: "main",
      menu: "news",
      updater: null,
      searchType: "person",
      searchBase: {
        type: "processing_plant",
        tax: 75,
        dist: 10,
      },
      searchProduct: {
        id: 4,
        sellPrice: 50,
        buyPrice: 1,
        count: 50,
        dist: 10,
      },
      search: "",
    }
  },
  destroyed() {
    clearInterval(this.updater);
  },
  mounted() {
    let app = this;

    app.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "GetFractionNews",
      }
    }));

    app.updater = setInterval(function () {
      app.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "GetFractionNews",
        }
      }));
    }, 3000)
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    baseIcon(b) {
      if (b.corporation_id && b.corporation_id !== 0) {
        return require('../../assets/map/structures/icon/' + b.sprite + '.png').default
      } else {
        return require('../../assets/map/objects/sector_structure/' + b.sprite + '.png').default
      }
    },
    baseName(b) {
      if (b.corporation_id && b.corporation_id !== 0) {
        return this.corporationBaseNames[b.base_id]
      } else {
        return this.handbook['base'][this.language][b.base_name].name
      }
    },
    setBaseSearch(e, type) {
      this.searchBase[type] = Number(e.target.value)
    },
    setProductSearch(e, type) {
      this.searchProduct[type] = Number(e.target.value)
    },
    getProductByID(id) {
      for (let p of this.products) {
        if (p.id === id) return p
      }
    },
    changeQuantity(change, min, max, type) {
      this.playSound('button_press.mp3', 0.3)

      this.searchProduct[type] = Number(this.searchProduct[type])
      if (isNaN(this.searchProduct[type])) {
        this.searchProduct[type] = 0
      }

      this.searchProduct[type] += change

      if (this.searchProduct[type] > max) {
        this.searchProduct[type] = max
      }

      if (this.searchProduct[type] < min) {
        this.searchProduct[type] = min
      }

      this.searchProduct[type] = Number(this.searchProduct[type].toFixed(2))
    },
    changeNewsChannel(channel) {
      this.playSound('button_press.mp3', 0.3)
      this.news = channel
    },
    resize(event, ui, el) {
      el.find('.wrap').css("height", el.height() - 118);
      el.find('.art_head').css("display", "block");
    },
    selectMenu(menu) {
      this.playSound('button_press.mp3', 0.3)

      this.menu = menu
      setTimeout(function () {
        this.resize(null, null, $(this.$el))
      }.bind(this))
    },
    Search() {
      this.playSound('button_press.mp3', 0.3)

      if (this.searchType === 'person') {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "system",
          data: {
            event: "Search",
            type: this.searchType,
            name: this.search,
          }
        }));
      }

      if (this.searchType === 'base') {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "system",
          data: {
            event: "Search",
            type: this.searchType,
            name: this.searchBase.type,
            count: this.searchBase.tax,
            dist: this.searchBase.dist,
          }
        }));
      }

      if (this.searchType === 'product') {
        this.$store.dispatch("sendSocketData", JSON.stringify({
          service: "system",
          data: {
            event: "Search",
            type: this.searchType,
            id: Number(this.searchProduct.id),
            count: Number(this.searchProduct.count),
            sell_price: Math.round(Number(this.searchProduct.sellPrice * 10)),
            buy_price: Math.round(Number(this.searchProduct.buyPrice * 10)),
            dist: Number(this.searchProduct.dist),
          }
        }));
      }
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)

      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    getBaseTypeName(baseType) {
      return this.$store.getters.getBaseTypeName(baseType, this.language)
    },
    getFractionLogo(b) {

      if (b.corporation_id !== 0) {
        return b.corporation_logo
      }

      if (b.fraction === '' || b.fraction === 'ALL' || b.fraction === 'Empty') {
        return ''
      }

      return require(`../../assets/logo/${b.fraction}.png`).default
    },
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('News')
    },
    corporationBaseNames() {
      return this.$store.getters.getCorporationBaseNames
    },
    SearchResult() {
      let res = this.$store.getters.getSearch

      if (res.type === "person") {
        res.data.sort(function (a, b) {
          return a.common_info.id - b.common_info.id
        });
      }

      if (res.type === "base" || res.type === "product") {
        res.data.sort(function (a, b) {
          return a.dist - b.dist
        });
      }

      return res
    },
    notifys() {

      let notifysArray = [];

      for (let i in this.$store.getters.getFractionNews) {

        let notify = this.$store.getters.getFractionNews[i];
        if (this.news === 'main') {
          notifysArray.push(notify)
        }

        if (this.news === 'expedition' && (notify.event === 'expedition')) {
          notifysArray.push(notify)
        }

        if (this.news === 'fraction_war' && notify.event === 'fraction_war') {
          notifysArray.push(notify)
        }

        if (this.news === 'production' && notify.event === 'anomalies') {
          notifysArray.push(notify)
        }

        if (this.news === 'trade' && notify.event === 'trade') {
          notifysArray.push(notify)
        }

        if (this.news === 'corporations' && notify.event === 'corporation') {
          notifysArray.push(notify)
        }
      }

      return notifysArray.sort(function (a, b) {
        return new Date(b.time * 1000) - new Date(a.time * 1000);
      });
    },
    baseStatus() {
      return this.$store.getters.getBaseStatus
    },
    baseBackground() {
      let id = (this.baseStatus.state.id % 4) + 1
      let back = require("../../assets/bases/news/back_" + id + ".png").default
      let presenter = require("../../assets/bases/news/presenter_" + id + ".png").default

      return {back: back, presenter: presenter}
    },
    products() {
      let sortProducts = [];
      let products = gameStore.gameTypes.product
      for (let i in products) {
        sortProducts.push(products[i])
      }

      sortProducts.sort(function (a, b) {
        return a.default_price - b.default_price;
      });

      return sortProducts
    },
  },
  components: {
    AppControl: Control,
    AppNotify: Notify,
    AppUserLine: UserLine,
    AppBackgroundItemCell: BackgroundItemCell,
  }
}
</script>

<style scoped>
#News {
  pointer-events: auto;
  position: absolute;
  display: block;
  border-radius: 5px;
  z-index: 11;
  width: 310px;
  height: 150px;
  top: 50px;
  left: calc(50% - 200px);
  padding: 20px 3px 3px;
  color: #0cc2fb;
  border: 1px solid #009aeb7d;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
}

.art_head, .art_back {
  height: 75px;
  width: 100%;
  margin-bottom: 4px;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px black;
  margin-top: 2px;
}

.art_back {
  height: 100%;
  background-size: cover;
}

.art_presenter {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.tabs {
  height: 15px;
  width: calc(100% - 7px);
  background: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.tabs div {
  height: 15px;
  float: left;
  font-size: 11px;
  line-height: 15px;
  border-right: 1px solid rgba(191, 191, 191, 0.43);
  padding: 0 3px;
  color: #bfbfbf;
  text-shadow: 1px 1px black;
  transition: 200ms;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.tabs div:last-child {
  border-left: 0;
}

.tabs .active {
  color: #fff200 !important;
  background: rgb(187, 93, 42);
}

.tabs div:hover {
  background: rgb(235, 98, 44);
}

.tabs div:active {
  transform: scale(0.98);
}

.wrap, .wrap_search {
  width: calc(100% - 14px);
  background: rgba(137, 150, 156, 0.3);
  float: left;
  box-shadow: inset 0 0 2px black;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 7px 7px 7px;
  height: 0;
}

.wrap_search {
  height: calc(100% - 22px);
}

.search_input {
  width: calc(100% - 4px);
  float: left;
  margin: 2px;
}

.search_input input {
  width: calc(100% - 100px);
  background: #adc6cd;
  float: left;
}

.wrap_search_types label {
  display: block;
  width: 92px;
  pointer-events: auto;
  font-size: 14px;
  text-align: center;
  transition: 100ms;
  background: rgb(136, 68, 34);
  height: 18px;
  border-radius: 5px;
  color: #fff;
  line-height: 20px;
  box-shadow: 0 0 2px #000;
  float: left;
  margin: 8px 5px 5px 0;
}

.wrap_search_types label:hover {
  background: rgba(255, 129, 0, 1);
}

.wrap_search_types label:active {
  transform: scale(0.98);
}

.settings {
  float: left;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 2px black;
  padding-top: 3px;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  text-shadow: 1px 1px 1px black, 0 0 0 black;
  letter-spacing: 0;
  color: bisque;
}

.result_wrapper {
  box-shadow: inset 0 0 3px black;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 5px;
  float: left;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: left;
  min-height: 10px;
  width: 100%;
  margin-top: 3px;
}

.person_result, .sector_result, .base_result, .product_result {
  padding: 4px 5px 4px 5px;
  background: rgba(0, 0, 0, 0.17);
  margin: 5px 6px;
  border-radius: 5px;
  box-shadow: 0 0 4px black;
  position: relative;
}

.sector_result {

}

.base_result {

}

.product_result {

}

.destination {
  padding: 2px;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  background: #8aaaaa;
  width: calc(100% - 4px);
  min-height: 13px;
  margin: 3px 0 0 0;
}

.destination > div {
  background: #066ea8;
  width: calc(100% - 53px);
  padding-right: 46px;
  height: 100%;
  border-radius: 4px;
  color: white;
  text-align: left;
  line-height: 14px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  box-shadow: inset 0 0 2px black;
  font-size: 9px;
  position: relative;
  padding-left: 7px;
}

.map_button, .search_button {
  position: absolute;
  right: 1px;
  top: -1px;
  width: 43px;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 9px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: calc(100% - 2px);
  border-radius: 2px;
  color: #fff;
  box-shadow: 0 0 2px #000;
  padding-left: 0;
  text-indent: 0;
  line-height: 11px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_button {
  position: unset;
  height: 19px;
  float: left;
  margin: 0 0 0 5px;
  width: 85px;
  font-size: 10px;
  line-height: 20px;
}

.map_button:hover, .search_button:hover {
  background: rgba(255, 129, 0, 1);
}

.map_button:active, .search_button:active {
  transform: scale(0.98);
}

.base_section {
  width: calc(100% - 4px);
  padding: 3px 2px;
  height: 39px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.icon {
  float: left;
  height: 40px;
  width: 40px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 2px black;
}

.icon.fraction {
  height: 20px;
  width: 20px;
  box-shadow: none;
  background-color: unset;
  filter: drop-shadow(0px 0px 1px black);
  margin: 1px;
}

.baseName {
  color: yellow;
  float: left;
  font-size: 10px;
  margin-left: 5px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  width: calc(100% - 45px);
}

.baseName p:first-child {
  font-size: 10px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
}

.baseName p {
  margin: 0;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-size: 12px;
}

.search_active {
  background: rgba(255, 129, 0, 1) !important;
}

.type_base {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: contrast(50%) sepia(100%) hue-rotate(346deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black);
  float: left;
}

.type_base_wrapper, .type_product_wrapper {
  height: 35px;
  width: 35px;
  transition: 0.1s;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  float: left;
  margin: 2px 2px 2px calc(27% - 35px);
  position: relative;
}

.type_base_wrapper:hover, .type_product_wrapper:hover {
  background-color: rgba(8, 138, 210, 0.5);
}

.type_base_wrapper:active, .type_product_wrapper:active {
  transform: scale(0.98);
}

.type_product_wrapper {
  height: 29px;
  width: 29px;
  margin: 2px 2px 2px 3%;
}

.type_base_active {
  background-color: rgba(8, 138, 210, 1);
}

.range_wrapper {
  text-align: justify;
  float: left;
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-top: 10px;
}

.range {
  height: 9px;
  width: 100px;
  line-height: 5px;
  float: right;
}

.range input[type=range] {
  -webkit-appearance: none;
  width: calc(100% - 10px);
  height: 8px;
  box-shadow: none;
}

.range input[type=range]:focus {
  outline: none;
}

.range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 6px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
  position: relative;
  top: -1px
}

.range input[type=range]:focus::-webkit-slider-runnable-track {
}

.range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(246, 255, 252, 0.4);
}

.range input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 2px black;
  border: 0 solid rgba(0, 0, 0, 0);
  height: 4px;
  width: 12px;
  background: #ff8f35;
  -webkit-appearance: none;
  border-radius: 1px;
}

.head, .title_row {
  float: left;
  width: 133px;
  height: 14px;
}

.title_row {
  /*width: 100px;*/
  line-height: 14px;
}

.search_button.big {
  float: right;
  margin: 5px 2px 2px 2px;
}

.input_number_row {
  margin: 7px 2px 2px 2px;
  text-align: justify;
  float: left;
  margin-left: 5px;
  margin-top: 10px;
  width: calc(100% - 10px);
}

.range_button {
  width: 15px;
  height: 14px;
  float: left;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  border-radius: 0 5px 5px 0;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  margin-top: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.range_button:hover {
  background: rgba(255, 129, 0, 1);
}

.range_button:active {
  transform: scale(0.98);
}

.input_number_row input[type=number] {
  float: left;
  width: calc(100% - 40px);
  margin-top: -1px;
  margin-right: 0;
  border-radius: 0;
  height: 14px;
  text-indent: 1px;
  text-align: right;
}

.input_number_row input[type=number]::-webkit-inner-spin-button, #sellDialog input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_number_row input[type=number], #sellDialog input[type=number] {
  -moz-appearance: textfield;
}

.input_number_row input[type=number]:disabled {
  pointer-events: none;
  color: bisque;
  text-shadow: 1px 1px 1px black;
  background: rgba(127, 127, 127, 0.5);
}

.base_tax {
  color: yellow;
  float: left;
  font-size: 10px;
  margin-left: 5px;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  width: calc(100% - 45px);
}

.product_icon {
  height: 40px;
  width: 40px;
  position: absolute;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  left: 5px;
  top: 55px;
}

.base_section.production {
  height: 98px;
}

.base_result.production {
  height: 140px;
}

.not_found {
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  font-size: 14px;
}
</style>
<style>
#News .wrap .notify_wrapper {
  position: relative;
  float: left;
  bottom: unset;
  width: calc(100% - 8px);
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
