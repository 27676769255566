<template>
  <div id="Alerts" ref="Alerts" v-if="Object.keys(notifications).length > 0">
    <div class="AlertsWrapper">
      <template v-for="notification in notifications">
        <div class="alert" v-html="notification.html"/>

        <template v-if="notification.input">
          <div class="inputs">
            <template
              v-if="notification.input.inputType === 'FriendInvite' || notification.input.inputType === 'GroupInvite' || notification.input.inputType ===  'CorpInvite'">
              <input type="button" :value="texts['reject'][lang]" style="float: left"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="rejectInvitation(notification.input.meta.uuid)">
              <input type="button" :value="texts['accept'][lang]" style="float: right"
                     @mouseover="playSound('select_sound.mp3', 0.3)"
                     @click="acceptInvitation(notification.input.meta.uuid)">
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Control from '../Window/Control';
import Input from "../Chat/Input";

export default {
  name: "Alerts",
  components: {
    Input,
    AppControl: Control,
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    acceptInvitation(uuid) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "AcceptInvitation",
          data: uuid,
        }
      }));
    },
    rejectInvitation(uuid) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "RejectInvitation",
          data: uuid,
        }
      }));
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications
    },
    settings() {
      return this.$store.getters.getSettings
    },
    lang() {
      return this.settings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Alerts')
    }
  }
}
</script>

<style scoped>
#Alerts {
  position: absolute;
  min-height: 0;
  min-width: 150px;
  max-width: 250px;
  top: 3px;
  left: 385px;
  padding: 1px 1px 1px 1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(21, 145, 213, 0.4);
  border-radius: 5px;
  z-index: 999;
  pointer-events: auto;
}

.AlertsWrapper {
  overflow: hidden;
  max-height: 100px;
}

.alert {
  min-height: 0;
  box-shadow: 0 0 1px black;
  padding: 5px;
  width: calc(100% - 12px);
  transition: 0.2s;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: rgba(8, 138, 210, 0.3);
  border-radius: 3px;
  color: white;
  margin-top: 3px;
}

.alert:first-child {
  margin-top: 0;
}

.inputs {
  margin-top: 3px;
}

.inputs input {
  transition: 100ms;
  background: rgba(255, 129, 0, 0.75);
  color: white;
}

.inputs input:hover {
  background: rgba(255, 129, 0, 1);
}

.inputs input:active {
  transform: scale(0.98);
}

.inputs input:active {
  transform: scale(0.96);
}

.control_wrapper {
  opacity: 0.5;
}

.control_wrapper:hover {
  opacity: 1;
}
</style>
