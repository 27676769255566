<template>
  <div class="content_section">
    <template v-if="lang==='RU'">
      <h2 class="head">Обучение: Ангар и склад</h2>

      <p>Что бы открыть ангар и склад необходимо нажать <span class="importantly">"Ангар"</span> / <span
        class="importantly">"Склад / Трюм"</span> на меню базы слева.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_hangar_1', 'png')+ ')',
            width: '266px',
            height: '375px',
            marginLeft: 'calc(50% - 133px)',
            }"/>

      <p>После чего откроется 2 меню и пункт задания будет выполнен.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_hangar_2', 'png')+ ')',
            width: '447px',
            height: '198px',
            marginLeft: 'calc(50% - 223px)',
            }"/>
    </template>

    <template v-if="lang==='EN'">
      <h2 class="head">Training: Hangar and Warehouse</h2>

      <p>To open the hangar and warehouse, you need to press <span class="importantly">"Hangar"</span> / <span
        class="importantly">"Warehouse / Hold"</span> on the base menu to the left.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_hangar_1', 'png')+ ')',
            width: '266px',
            height: '375px',
            marginLeft: 'calc(50% - 133px)',
            }"/>

      <p>After which two menus will open and the task item will be completed.</p>

      <div class="detailImg" :style="{
            backgroundImage: 'url(' + $parent.getUrlImg('education_hangar_2', 'png')+ ')',
            width: '447px',
            height: '198px',
            marginLeft: 'calc(50% - 223px)',
            }"/>
    </template>
  </div>
</template>

<script>
export default {
  name: "EducationHangar",
  computed: {
    lang() {
      if (!location.href.includes('wiki') && (location.href.includes('lobby') || location.href.includes('global') || location.href.includes('gate'))) {
        return this.$store.getters.getSettings.Language
      }

      return this.$store.getters.getIndexLanguage
    }
  },
}
</script>

<style scoped>

</style>
