<template>
  <div id="notifyBlock">

    <div class="texture_wrapper"/>

    <div class="wrapNotifyBlock" v-if="notifys.length > 0">
      <template v-for="notify in notifys">

        <div class="pic_wrapper cursor_hover">

          <template v-if="isToUser(notify) || (notify.answers && notify.answers.length > 0)">
            <div class="notify_pic"
                 @click="deleteNotify(notify, true)"
                 @mouseover="setShow(notify.uuid)"
                 @mouseout="slowHide()"
                 :class="{
                   icon: getNotifyImage(notify),
                   toUser: isToUser(notify),
                   danger: notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna'),
                   action_end: notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0),
                   animate: isToUser(notify) || notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna') ||
                   notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0)
                 }"
                 :style="{
                   backgroundColor: getNotifyColor(notify),
                   backgroundImage: getNotifyImage(notify),
                 }">
            </div>

            <app-notify v-if="show(notify)"
                        @mouseover.native="setForce(notify.uuid)"
                        @mouseout.native.stop="forceHide($event)"
                        v-bind:notify="notify"/>
          </template>

          <template v-else>

            <div class="notify_pic"
                 @click="deleteNotify(notify, true)"
                 @mouseover="show2(notify.uuid)"
                 @blur="showNotify = notify.uuid"
                 @focus="showNotify = notify.uuid"
                 @mouseenter="showNotify = notify.uuid"
                 @mousemove="showNotify = notify.uuid"
                 @mouseout="showNotify = ''"
                 :class="{
                   icon: getNotifyImage(notify),
                   toUser: isToUser(notify),
                   danger: notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna'),
                   action_end: notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0),
                   animate: isToUser(notify) || notify.name === 'mission_failed' || (notify.event === 'change_relation' && notify.data < 0 || notify.event === 'kill_fauna') ||
                     notify.event === 'action_end' || (notify.event === 'change_relation' && notify.data > 0)
                 }"
                 :style="{
             backgroundColor: getNotifyColor(notify),
             backgroundImage: getNotifyImage(notify),
           }">
            </div>

            <app-notify v-if="show(notify)" v-bind:notify="notify"
                        @mouseover.native="forceShowNotify = notify.uuid"
                        @blur.native="forceShowNotify = notify.uuid"
                        @focus.native="forceShowNotify = notify.uuid"
                        @mouseenter.native="forceShowNotify = notify.uuid"
                        @mousemove.native="forceShowNotify = notify.uuid"
                        @mouseout.native="forceShowNotify = ''"/>

          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BackgroundItemCell from '../Inventory/BackgroundItemCell';
import Notify from '../News/Notify';
import {IsToUser} from "../../store/ws/readers/global";

export default {
  name: "Notifys",
  data() {
    return {
      showNotify: "",
      forceShowNotify: "",
      timerHide: null,
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    setShow(uuid) {
      if (this.timerHide) clearTimeout(this.timerHide)
      this.playSound('select_sound.mp3', 0.3)
      this.showNotify = uuid
    },
    show2(uuid) {
      this.playSound('select_sound.mp3', 0.3)
      this.showNotify = uuid
    },
    setForce(uuid) {
      this.forceShowNotify = uuid
    },
    slowHide() {
      this.timerHide = setTimeout(function () {
        this.showNotify = ''
      }.bind(this), 300)
    },
    forceHide(e) {
      e.stopPropagation();
      if (e.toElement && e.toElement.className !== 'notify_wrapper') {
        return
      }

      this.forceShowNotify = ''
    },
    isToUser(notify) {
      IsToUser(notify, this.currentPlayer)
    },
    show(notify) {
      if (notify.uuid === this.showNotify || notify.uuid === this.forceShowNotify) {
        return true
      }

      if (notify.event === 'npc_dialog' && notify.need_answer && notify.to_user === this.currentPlayer.id) {
        return true
      }

      if (notify.event === 'attention' || notify.name === 'invite' || notify.event === 'translation' || notify.name === 'bot_mission_request' ||
        notify.name === 'mission_failed' || notify.event === 'action_end') {
        return true
      }

      return false
    },
    deleteNotify(notify, sound) {
      if (sound) this.playSound('button_press.mp3', 0.3)

      if (notify.event === 'npc_dialog' && notify.need_answer && notify.to_user === this.currentPlayer.id) {
        return
      }

      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "system",
        data: {
          event: "DeleteNotify",
          data: notify.uuid,
        }
      }));
    },
    getNotifyImage(notify) {
      if (notify.event === 'kill_fauna') return 'url(' + require('../../assets/icons/kill_fauna.png').default + ')'
      if (notify.event === 'change_relation' && notify.data > 0) return 'url(' + require('../../assets/icons/relations_up.png').default + ')'
      if (notify.event === 'change_relation' && notify.data <= 0) return 'url(' + require('../../assets/icons/relations_down.png').default + ')'

      if (notify.event === 'npc_dialog' || notify.name === 'bot_request' || notify.name === 'bot_mission_request')
        return 'url(' + require('../../assets/icons/notify_dialog_icon.png').default + ')'

      if (notify.name === 'craft') return 'url(' + require('../../assets/icons/base_icon_mini.png').default + ')';

      if (notify.name === 'sell' || notify.name === 'buy') return 'url(' + require('../../assets/icons/credits_mini_notify.png').default + ')';

      return 'url(' + require('../../assets/icons/notify_news_icon.png').default + ')'
    },
    getNotifyColor(notify) {
      return '';

      if (notify.event === 'npc_dialog') return '';

      if (notify.name === 'craft') return '#4ab7b7';
      if (notify.name === 'sell' || notify.name === 'buy') return "#c765db";
      if (notify.name === 'skill_up') return '#ff9700';

      if (notify.event === 'lose_base' || notify.event === 'capture_base_other' && notify.data.lose === '')
        return '#ea743e';

      if ((notify.event === 'capture_base' && notify.data.lose !== '') ||
        notify.event === 'capture_base' && notify.data.lose === '') return "#587adb";

      if (notify.event === 'new_expedition' || notify.event === 'place_expedition' ||
        notify.event === 'destroy_expedition' || notify.event === 'expedition_trader') return '#b3ff00';

      if (notify.event === 'meteorite_rain') {
        return "#d0d02a"
      }

      if (notify.event === 'action_end') {
        return "#00a6ff"
      }

      return "#f3eeee"
    }
  },
  computed: {
    notifys() {

      let notifysArray = [];

      for (let i in this.$store.getters.getChatState.notifys) {
        let notify = this.$store.getters.getChatState.notifys[i];

        let notifyTime = new Date(notify.time * 1000)
        let currentTime = new Date()

        let diffTime = new Date(currentTime.getTime() - notifyTime.getTime())

        if (notify.name !== 'craft' && notify.name !== 'sell' && notify.name !== 'bot_mission_request' && notify.name !== 'mission_failed') {
          if (notify.event === 'npc_dialog' && diffTime.getTime() / 1000 > 30) {
            this.deleteNotify(notify)
            continue
          }

          if (diffTime.getTime() / 1000 > (4 * 60)) {
            this.deleteNotify(notify)
            continue
          }
        }

        notifysArray.push(notify)
      }

      return notifysArray.sort(function (a, b) {
        return new Date(a.time * 1000) - new Date(b.time * 1000);
      });
    },
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
  },
  components: {
    AppBackgroundItemCell: BackgroundItemCell,
    AppNotify: Notify,
  }
}
</script>

<style scoped>
#notifyBlock {
  position: absolute;
  border-radius: 0 5px 0 0;
  min-width: 5px;
  height: 19px;
  left: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  bottom: -1px;
  z-index: 999;
  pointer-events: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  padding: 2px 2px 0 2px;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  border: 1px solid #009aeb7d;
  border-left: 0;
}

#notifyBlock .notify_pic {
  position: relative;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  /*background-color: rgb(255, 240, 0);*/
  /*box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 1px 1px rgba(0, 0, 0, 0.25);*/
  border: 1px solid;
  float: left;
  transition: 0.1s;
}

.pic_wrapper {
  height: 15px;
  width: 15px;
  float: left;
  position: relative;
  margin-left: 1px;
}

#notifyBlock .notify_pic.icon {
  border: 0;
  height: 15px;
  width: 15px;
  background-size: contain;
  background-position: center;
  box-shadow: none;
  background-color: transparent;
  filter: drop-shadow(1px 1px 1px black);
}

#notifyBlock .notify_pic.toUser {
  filter: contrast(65%) sepia(100%) hue-rotate(1deg) brightness(0.8) saturate(800%) drop-shadow(0px 1px 0px black) drop-shadow(1px 1px 1px black);
  border-radius: 2px;
}

#notifyBlock .notify_pic.danger {
  filter: contrast(0%) sepia(100%) hue-rotate(307deg) brightness(0.7) saturate(800%) drop-shadow(0px 1px 0px black) drop-shadow(1px 1px 1px black);
  border-radius: 2px;
}

#notifyBlock .notify_pic.action_end {
  filter: contrast(0%) sepia(100%) hue-rotate(84deg) brightness(1) saturate(800%) drop-shadow(0px 1px 0px black) drop-shadow(1px 1px 1px black);
  border-radius: 2px;
}

#notifyBlock .notify_pic:hover {
  transform: scale(1.15);
}

#notifyBlock .notify_pic:active {
  transform: scale(0.90);
}

.wrapNotifyBlock {
  float: right;
  padding: 1px 1px 1px 0;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  background: rgba(137, 150, 156, 0.3);
}

.texture_wrapper {
  background-image: url("../../assets/interface/frame_12.png");
  height: 23px;
  width: 29px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  z-index: 0;
  top: -3px;
  right: -1px;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.75));
}

.notify_pic.animate {
  -webkit-animation: leaves 1000ms 1; /* Safari 4+ */
  -moz-animation: leaves 1000ms 1; /* Fx 5+ */
  -o-animation: leaves 1000ms 1; /* Opera 12+ */
  animation: leaves 1000ms 1; /* IE 10+, Fx 29+ */
}

@keyframes leaves {
  0% {
    transform: scale(1.0);
    box-shadow: 0 0 1px 1px greenyellow, inset 0 0 1px 1px greenyellow;
  }
  50% {
    transform: scale(2.0);
    box-shadow: 0 0 35px 10px greenyellow, inset 0 0 5px 1px greenyellow;
  }
  100% {
    transform: scale(1.0);
    box-shadow: unset;
  }
}
</style>
